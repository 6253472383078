import {OneLinedText} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const SubjectGridBox = styled(GridBox, {
	width: '100%',
	alignItems: 'center',
	gap: 6,
	gridTemplateColumns: 'auto 1fr',

	'& [data-cheap-box]': {
		alignSelf: 'start',
	},
});

export const SubjectText = styled(OneLinedText, {
	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				width: '100%',
				height: 12,
				borderRadius: 6,
			},
		},
	},
});
