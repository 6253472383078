import {isEqual} from 'underscore';
import {BehaviorSubject, catchError, map, tap, throwError} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2SchoolAdminsStudentsController} from '@esgi/contracts/esgi';
import {studentsStore} from '@esgi/main/libs/school-admin-store';
import {School} from '@esgi/contracts/esgi/types/esgi.schools/schools/get/by-id/school';
import {createTransferStudentForm, teacherClassAssignValidator, TransferStudentForm} from './form';
import {ClassModel, Location, SchoolSpecialist, StudentData, UserModel} from './types';
import {numberArrayToStringArray} from './utils';
import {photo} from './constants';

export class TransferStudentService extends BaseService {
	public school$ = new BehaviorSubject<School>(null);
	public student$ = new BehaviorSubject<StudentData>(null);
	public teacher$ = new BehaviorSubject<UserModel>(null);
	public teachers$ = new BehaviorSubject<UserModel[]>(null);
	public classes$ = new BehaviorSubject<ClassModel[]>(null);
	public schoolSpecialists$ = new BehaviorSubject<SchoolSpecialist[]>(null);

	public isBusy$ = new BehaviorSubject(false);
	public isLoaded$ = new BehaviorSubject(false);
	public isFormPristine$ = new BehaviorSubject(true);

	public form = createTransferStudentForm();

	private studentID: number;
	private initialState: StudentData['location'];
	private controller = new V2SchoolAdminsStudentsController();

	constructor() {
		super();

		this.completeOnDestroy(this.form.controls.teacherID.onChanged).subscribe(({reason, currState}) => {
			if (reason === 'status') {
				return;
			}

			this.form.controls.teacherEntityIDs.value = {groupIDs: [], classIDs: []};

			if (!this.teachers$.value || !currState.value) {
				this.classes$.next(null);
				this.teacher$.next(null);
				return;
			}

			this.setTeacherByID(currState.value);
			this.getClasses(currState.value);
		});

		this.completeOnDestroy(this.teachers$).subscribe(() => {
			if (!this.form.value.teacherID) {
				this.teacher$.next(null);
				return;
			}

			this.setTeacherByID(this.form.value.teacherID);
		});

		this.completeOnDestroy(this.form.onChanged).subscribe(() => {
			this.compareStatesForEquality();
		});
	}

	public init(studentID: number) {
		this.studentID = studentID;

		return this.controller.updateInit({
			studentID: this.studentID,
		}).pipe(tap((student) => {
			const location = student.value.location;
			const model = {
				schoolID: location.schoolID?.toString(),
				teacherID: location.teacherID?.toString() ?? null,
				teacherEntityIDs: {
					classIDs: numberArrayToStringArray(location.classIDs),
					groupIDs: numberArrayToStringArray(location.groupIDs),
				},
				specialistGroupIDs: numberArrayToStringArray(location.specialistGroupIDs),
			};

			this.initialState = this.createDataState(model);
			this.form.value = model;

			this.student$.next(student.value);

			this.handleStudentClassAssignValidation();
			this.getClasses(location.teacherID?.toString());
			this.getSchoolSpecialists();
			this.getTeachers();
			this.getSchool();

			this.isLoaded$.next(true);
		}));
	}

	public save() {
		this.isBusy$.next(true);
		const location = this.createDataState();
		return this.controller.update({
			...this.student$.value,
			location,
			newPhoto: photo,
			createAnyway: true,
		}).pipe(map(() => {
			this.isBusy$.next(false);
			return studentsStore().update((student) => {
				if (student.id === this.student$.value.studentID) {
					return {
						...student,
						schoolID: location.schoolID,
						teacherID: location.teacherID,
						classesIDs: location.classIDs,
						groupsIDs: location.groupIDs,
						specialistGroupsIDs: location.specialistGroupIDs,
					};
				}

				return student;
			});
		}), catchError((error) => {
			this.isBusy$.next(false);
			return throwError(error);
		}));
	}

	private setTeacherByID(teacherID: string) {
		if (!this.teachers$.value || !teacherID) {
			return;
		}

		const selectedTeacher = this.teachers$.value.find((item) => (
			String(item.userID) === String(teacherID)
		));

		this.teacher$.next(selectedTeacher);
	}

	private getSchool() {
		this.controller.school().subscribe((response) => {
			this.school$.next(response.value.school);
		});
	}

	private getTeachers() {
		this.controller.teachers().subscribe((response) => {
			this.teachers$.next(response.users);
		});
	}

	private getClasses(teacherID: string) {
		if (!teacherID) {
			return;
		}

		this.controller.classes({
			teacherID: Number(teacherID),
		}).subscribe((response) => {
			this.classes$.next(response.classes);
		});
	}

	private getSchoolSpecialists() {
		this.controller.schoolsSpecialists().subscribe((response) => {
			this.schoolSpecialists$.next(response.value.users);
		});
	}

	private createDataState(state?: TransferStudentForm['value']) {
		const location = new Location(state ?? this.form.value);

		return {
			...location,
		};
	}

	private compareStatesForEquality() {
		this.isFormPristine$.next(isEqual(this.initialState, this.createDataState()));
	}

	private handleStudentClassAssignValidation() {
		this.form.controls.teacherEntityIDs.validators.push(
			teacherClassAssignValidator(this.form.controls.teacherID)
		);
	}
}