import {FolderType, UserGroupType} from './types';

export const folderNameMaxLength = 64;

export const userGroupTypeByFolderType: Record<FolderType, UserGroupType> = {
	[FolderType.Teacher]: UserGroupType.Teacher,
	[FolderType.Specialist]: UserGroupType.Specialist,
};

export const userFolderTypeByGroupType: Record<Exclude<UserGroupType, UserGroupType.None>, FolderType> = {
	[UserGroupType.Teacher]: FolderType.Teacher,
	[UserGroupType.Specialist]: FolderType.Specialist,
};
