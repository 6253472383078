import {Button, Tooltip} from '@esgi/ui';
import {History, Plus} from '@esgi/ui/icons';
import {Box, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {AddNewSession, GroupBox, SessionInfoItem} from './index.styled';
import {TestSession, Test} from '../../../../types/test-panel';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {maxVisibleSessions} from './constants';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {DateFormatText, Timestamp} from '@esgi/main/kits/common';
import {TestSessionDetailsAlert} from '@esgi/main/features/test-session-details';
import {useCallback, useState} from 'react';
import {isNull} from 'underscore';
import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {SelectedStudent} from '../../../../types/section';

type Props = {
	testInfo: Test;
	testSessions: TestSession[];
	selectedSubject: SubjectModel;
	selectedStudent: SelectedStudent;
	userCanAddTestSession: boolean;
	openAddSessionDialog: VoidFunction;
};

export function TestSessionsContextMenu({
	testInfo,
	testSessions,
	selectedSubject,
	selectedStudent,
	userCanAddTestSession,
	openAddSessionDialog,
}: Props) {
	const [openTestSessionDetailsID, setOpenTestSessionDetailsID] = useState<number | null>(null);

	const handleSessionInfoItemClick = useCallback((id: number) => {
		setOpenTestSessionDetailsID(id);
	}, []);

	const closeOpenTestSessionDetails = useCallback(() => {
		setOpenTestSessionDetailsID(null);
	}, []);

	return (
		<>
			<TestCard.ContextMenu.Root>
				<TestCard.ContextMenu.Trigger>
					<GridBox align='center'>
						<Tooltip>
							<Tooltip.Trigger>
								<Button.Icon withBackgroundHover disabled={!testSessions.length}>
									<History />
								</Button.Icon>
							</Tooltip.Trigger>
							<Tooltip.Content>
								<Text size='small'>Test Session Details</Text>
							</Tooltip.Content>
						</Tooltip>
					</GridBox>
				</TestCard.ContextMenu.Trigger>

				<TestCard.ContextMenu.Content>
					<GroupBox isItemsMoreThanMaxPossible={testSessions.length > maxVisibleSessions}>
						<OverlayScrollbarsComponent
							defer
							options={{
								scrollbars: {autoHide: 'leave'},
							}}
							style={{height: '100%'}}
						>
							{testSessions.map(({id, testDate, correctAnswers}) => (
								<SessionInfoItem key={id} onClick={() => handleSessionInfoItemClick(id)}>
									<GridBox flow='column' gap='4' align='center'>
										<DateFormatText size='medium' font='mono' bold date={testDate} dateFormat='MM/DD/YYYY' />
										<Timestamp date={testDate}>
											<Timestamp.Colorful />
										</Timestamp>
										<TestCard.AnswersInfo
											value={correctAnswers}
											maxValue={testInfo.totalPossible}
											transcriptText={null}
										/>
									</GridBox>
								</SessionInfoItem>
							))}
						</OverlayScrollbarsComponent>
					</GroupBox>
					{userCanAddTestSession && (
						<AddNewSession onClick={openAddSessionDialog}>
							<Plus />
							<Text size='medium' bold color='base'>
								Add new session
							</Text>
						</AddNewSession>
					)}
				</TestCard.ContextMenu.Content>
			</TestCard.ContextMenu.Root>

			<Box
				onClick={(event) => event.stopPropagation()}
				css={{
					width: 0,
					height: 0,
					position: 'absolute',
				}}
			>
				{!isNull(openTestSessionDetailsID) && (
					<TestSessionDetailsAlert
						classID={selectedStudent.classIDs.length === 1 ? selectedStudent.classIDs[0]! : null}
						onAlertClose={closeOpenTestSessionDetails}
						student={{
							id: selectedStudent.id,
							firstName: selectedStudent.firstName,
							lastName: selectedStudent.lastName,
							photoUrl: selectedStudent.avatarUrl,
						}}
						subject={{
							id: selectedSubject.id,
							name: selectedSubject.name,
							type: selectedSubject.type,
						}}
						test={{
							id: testInfo.id,
							contentArea: testInfo.contentArea,
							name: testInfo.name,
							type: testInfo.type,
							color: testInfo.color,
							maxScore: testInfo.totalPossible,
						}}
						testSessionID={openTestSessionDetailsID}
					/>
				)}
			</Box>
		</>
	);
}
