import {Dispatch, useCallback} from 'react';
import {SchoolsData, SchoolsSortableKey, SortableState, SortDirection} from '../../../../types';
import {Table, TableRowCell, tableRowHeight, tableRowsGap} from '../table.styled';
import {CellBox, ResultCheap, TableRow} from './index.styled';
import {schoolsSortableCells} from '../../../../constants';
import {TableHeaderCellSortable} from '../table-header-cell-sortable';
import {OneLinedText} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {Box} from '@esgi/ui/layout';
import {BarChartHorizontal} from '@esgi/main/kits/charts';
import {barChartHeight, baseLineHeight, cellRowGap, pointerSize, progressLineHeight} from './constants';
import {WidgetCard} from '@esgi/main/features/admins/dashboard';

type Props = {
	sortableState: SortableState<SchoolsSortableKey>;
	setSortableState: Dispatch<SortableState<SchoolsSortableKey>>;
	disabledSortableKeys: SchoolsSortableKey[];
};

export function SchoolsTable({sortableState, setSortableState, disabledSortableKeys}: Props) {
	const {visibleData, widgetDataPageSize, pageChange} = WidgetCard.useWidgetCardContext<SchoolsData>();

	const notVisibleItems = widgetDataPageSize - visibleData.length;
	const emptyBoxHeight =
		(tableRowHeight + cellRowGap + barChartHeight) * notVisibleItems + tableRowsGap * (notVisibleItems - 1);

	const showEmptyContentBox = widgetDataPageSize !== visibleData.length;

	const sortableCellClick = useCallback(
		({cellKey, nextSortDirection}: {cellKey: SchoolsSortableKey; nextSortDirection: SortDirection}) => {
			setSortableState({
				key: cellKey,
				direction: nextSortDirection,
			});

			pageChange(0);
		},
		[setSortableState, pageChange],
	);

	return (
		<Table>
			<TableRow>
				{schoolsSortableCells.map(({key, label, justifyContent}) => (
					<TableHeaderCellSortable
						cellKey={key}
						cellName={label}
						onCellClick={sortableCellClick}
						isActive={sortableState.key === key}
						sortDirection={sortableState.direction}
						disableSorting={disabledSortableKeys.includes(key)}
						justifyContent={justifyContent}
						key={key}
					/>
				))}
			</TableRow>

			{visibleData.map(({rowID, school, lowestAchievementStudents, totalStudents, result}) => (
				<CellBox key={rowID}>
					<TableRow>
						<TableRowCell>
							<OneLinedText size='medium' bold color='highContrast'>
								{school}
							</OneLinedText>
						</TableRowCell>

						<TableRowCell justify='end'>
							<Text size='medium' font='mono' bold color='highContrast'>
								{lowestAchievementStudents}
							</Text>
						</TableRowCell>

						<TableRowCell justify='end'>
							<Text size='medium' font='mono' color='mediumContrast'>
								{totalStudents}
							</Text>
						</TableRowCell>

						<ResultCheap backgroundColor='negativeVivid'>
							<Text size='xSmall' font='mono' bold color='vivid'>
								{result}%
							</Text>
						</ResultCheap>
					</TableRow>

					<BarChartHorizontal chartWidth='100%' minValue={0} maxValue={100}>
						<BarChartHorizontal.BaseLine height={baseLineHeight} />
						<BarChartHorizontal.ProgressLine
							startValue={0}
							endValue={result}
							height={progressLineHeight}
							color='negativeVivid'
						/>
						<BarChartHorizontal.Pointer
							size={pointerSize}
							value={result}
							backgroundColor='vivid'
							borderColor='negative'
						/>
					</BarChartHorizontal>
				</CellBox>
			))}

			{showEmptyContentBox && <Box css={{height: emptyBoxHeight}} />}
		</Table>
	);
}
