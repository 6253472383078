import {SkeletonShape} from '@esgi/main/kits/common';
import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	closeAlert: VoidFunction;
};

export function Skeleton({closeAlert}: Props) {
	return (
		<>
			<Alert.Header onCloseIconClick={closeAlert}>
				<SkeletonShape width={100} height={20} />
			</Alert.Header>

			<Alert.Body>
				<SkeletonShape width='100%' height={32} />
			</Alert.Body>

			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<SkeletonShape width={120} height={32} />
					<SkeletonShape width={120} height={32} />
				</GridBox>
			</Alert.Footer>
		</>
	);
}
