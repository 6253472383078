import {useState} from 'react';
import {Page, SingleSelectedExpandablePanel} from '../../../../../_kit';
import {RowDef} from '../../../../types';
import {QuickPanel} from './components/quick-panel';
import {ExpandedPanel} from './components/expanded-panel';
import {Folder} from '@esgi/main/features/school-admin/data';

type Props = {
	onFolderAddClick: VoidFunction;
	userCanAddAndDeleteTeachers: boolean;
	activeFolder: Folder;
	onDeselectFolder: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
};

export function SingleItemExpandablePanel({
	onFolderAddClick,
	userCanAddAndDeleteTeachers,
	activeFolder,
	onDeselectFolder,
	onRemoveFromFolderClick,
}: Props) {
	const {api} = Page.useContext<RowDef>();

	const [isPanelExpanded, setIsPanelExpanded] = useState(false);
	const [selectedRow, setSelectedRow] = useState<RowDef | null>(null);

	return (
		<SingleSelectedExpandablePanel.Root<RowDef>
			gridApi={api}
			onSingleSelectedItemChange={setSelectedRow}
			onPanelExpandableValueChange={setIsPanelExpanded}
		>
			{selectedRow && (
				<>
					<QuickPanel
						selectedRow={selectedRow}
						isPanelExpanded={isPanelExpanded}
						onFolderAddClick={onFolderAddClick}
						gridApi={api}
						userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
						activeFolder={activeFolder}
						onDeselectFolder={onDeselectFolder}
						onRemoveFromFolderClick={onRemoveFromFolderClick}
					/>

					<ExpandedPanel selectedRow={selectedRow} />
				</>
			)}
		</SingleSelectedExpandablePanel.Root>
	);
}
