import {ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback} from 'react';
import {useContextMenuMenuContext} from '../context';
import {ContextMenu} from '@esgi/ui';

type Props = ComponentPropsWithoutRef<typeof ContextMenu.Item>;

export const ContextMenuItem = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-card-context-menu-item', css = {}, children, onClick, ...props}, forwardedRef) => {
		const {setIsContextMenuOpened} = useContextMenuMenuContext();

		const handleClick = useCallback(
			(event: MouseEvent<HTMLDivElement>) => {
				event.stopPropagation();

				setIsContextMenuOpened(false);

				onClick?.(event);
			},
			[onClick, setIsContextMenuOpened],
		);

		return (
			<ContextMenu.Item dataCy={dataCy} css={css} onClick={handleClick} ref={forwardedRef} {...props}>
				{children}
			</ContextMenu.Item>
		);
	},
);
