import * as React from 'react';
import type {SVGProps} from 'react';

export function BorderLeft(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M24 17h-7v7h6a1 1 0 0 0 1-1v-6Zm-1-9h-6v7h7V9a1 1 0 0 0-1-1ZM9.117 25.993A1 1 0 0 0 9 24h6v-7H8v-2h7V8H9l.117-.007A1 1 0 0 0 9 6h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H9l.117-.007Z'
				clipRule='evenodd'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M10 7a1 1 0 0 1-1 1 1 1 0 0 0-.993.883L8 9v14a1 1 0 0 0 .883.993L9 24a1 1 0 1 1 0 2 3 3 0 0 1-2.995-2.824L6 23V9a3 3 0 0 1 2.824-2.995L9 6a1 1 0 0 1 1 1Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
