import {useDisposable} from '@esgi/core/service';
import {useUser} from '@esgi/core/authentication';
import {V2SchoolAdminsFoldersUsersController} from '@esgi/contracts/esgi';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useCallback, useEffect} from 'react';
import {FolderType} from '../types';
import {isNull} from 'underscore';
import {userGroupTypeByFolderType} from '../constants';
import {dispatchAppEvent} from '@esgillc/events';
import {FolderCreatedEvent} from '../events';

type Parameters = {
	type: FolderType;
	onFolderCreated?: VoidFunction;
};

export function useCreateFolder({type, onFolderCreated}: Parameters) {
	const currentUser = useUser();

	const foldersUsersController = useDisposable(
		V2SchoolAdminsFoldersUsersController
	);

	const [createUserFolderData, createUserFolder] = useCancelableRequest(
		foldersUsersController.create
	);

	useEffect(() => {
		if (isAsyncSucceed(createUserFolderData)) {
			onFolderCreated?.();

			dispatchAppEvent(
				FolderCreatedEvent,
				new FolderCreatedEvent({
					type,
					id: createUserFolderData.data.groupID,
				})
			);
		}
	}, [createUserFolderData, onFolderCreated, type]);

	return useCallback(
		({entityIDs, name}: { entityIDs: number[]; name: string }) => {
			if (isNull(currentUser)) {
				return;
			}
			createUserFolder({
				userID: currentUser.userID,
				//@ts-ignore
				groupType: userGroupTypeByFolderType[type],
				name,
				userIDs: entityIDs,
			});
		},
		[createUserFolder, currentUser, type]
	);
}
