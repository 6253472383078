import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		gap: '$5',
		maxWidth: 460,
	},
};

export const AlertHeader = styled(Alert.Header, {
	height: 64,
});

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: '$5',
});

export const FolderNameBox = styled(GridBox, {
	alignItems: 'center',
	gridAutoFlow: 'column',
	gap: 6,
	paddingTop: 8,
	paddingBottom: 6,
	paddingInline: 12,
	justifyContent: 'center',
	borderRadius: 6,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',

	'& > svg': {
		width: 24,
		height: 24,

		'& path': {
			fill: '$negative',
		},
	},
});

export const AlertFooter = styled(Alert.Footer, {
	paddingTop: 'unset',
});
