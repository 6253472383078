import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const LabelBox = styled(Box, {
	display: 'inline-flex',
	paddingTop: 5,
	paddingBottom: 5,
	paddingLeft: 12,
	paddingRight: 12,
	borderRadius: 12,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',
	backgroundColor: '$background',
});
