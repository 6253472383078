import {config, useSpring} from 'react-spring';

export function useLineAnimation({width}: {width: number}) {
	return useSpring({
		from: {
			width: '0%',
		},
		to: {
			width: `${width}%`,
		},
		config: {
			...config.molasses,
			clamp: true,
		},
	});
}
