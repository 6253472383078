import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const navigationBarHeadHeight = 63;

export const headCSS: CSS = {
	padding: 'unset',
	paddingLeft: '$3',
	display: 'grid',
	gap: '$3',
	alignItems: 'center',
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	width: '100%',
	height: navigationBarHeadHeight,
};

export const RightContentBox = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	gap: '$3',
	margin: 'unset',

	'& > button:last-child': {
		boxShadow: '0px 4px 8px 0px rgba(230, 232, 239, 0.48), 0px 2px 12px 0px rgba(0, 0, 0, 0.06)',
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
	},
});

export const SelectedItemIndicator = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	gap: 6,

	'& svg': {
		width: '24px',
		height: '24px',

		'& path': {
			fill: '$mediumContrast',
		},
	},
});
