import {useDisposable} from '@esgi/core/service';
import {AddGroupForm} from './form-data/types';
import {V2DistrictAdminsGroupsController} from '@esgi/contracts/esgi';
import {isAsyncPending, isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';
import {useCallback, useEffect, useState} from 'react';
import {studentsStore, groupsStore, Group} from '@esgi/main/libs/admin-store';
import {Student} from '@esgi/main/libs/store';
import {isNull} from 'underscore';
import {dispatchAppEvent} from '@esgillc/events';
import {GroupCreatedEvent} from '../../events';

type NewGroupDataModel = Omit<Group, 'id'> & {
	studentsIDs: Student['id'][];
};

type Parameters = {
	closeDrawer: VoidFunction;
	addGroupForm: AddGroupForm;
};

export function useSave({closeDrawer, addGroupForm}: Parameters) {
	const groupsController = useDisposable(V2DistrictAdminsGroupsController);

	const [newGroupDataModel, setNewGroupDataModel] = useState<NewGroupDataModel | null>(null);
	const [createdGroupData, createGroup] = useCancelableRequest(groupsController.create);

	useEffect(() => {
		if (isAsyncSucceed(createdGroupData) && !isNull(newGroupDataModel)) {
			const newGroupID = createdGroupData.data.groupID;

			studentsStore().update((item) => {
				if (newGroupDataModel.studentsIDs.includes(item.id)) {
					return {
						...item,
						groupsIDs: [...item.groupsIDs, newGroupID],
					};
				}

				return item;
			});

			groupsStore().add({
				id: newGroupID,
				name: newGroupDataModel.name,
				schoolID: newGroupDataModel.schoolID,
				teacherID: newGroupDataModel.teacherID,
				classID: newGroupDataModel.classID,
			});

			dispatchAppEvent(
				GroupCreatedEvent,
				new GroupCreatedEvent({
					id: newGroupID,
					name: newGroupDataModel.name,
				}),
			);

			closeDrawer();
		}
	}, [closeDrawer, createdGroupData, newGroupDataModel]);

	const saveData = useCallback(() => {
		const {groupName, studentsIDs, teacherClass, schoolID, primaryTeacherID} = addGroupForm.value;

		const selectedSchoolID = schoolID[0];
		const teacherID = primaryTeacherID[0];
		const classID = teacherClass[0];

		if (isUndefined(selectedSchoolID)) {
			throw new Error('selectedSchoolID is undefined');
		}

		if (isUndefined(teacherID)) {
			throw new Error('teacherID is undefined');
		}

		if (isUndefined(classID)) {
			throw new Error('classID is undefined');
		}

		const numberedClassID = Number(classID);

		createGroup({
			classID: numberedClassID,
			studentIDs: studentsIDs,
			name: groupName,
		});

		setNewGroupDataModel({
			studentsIDs,
			classID: numberedClassID,
			name: groupName,
			schoolID: Number(selectedSchoolID),
			teacherID: Number(teacherID),
		});
	}, [addGroupForm, createGroup]);

	return {
		saveData,
		isDataSaving: isAsyncPending(createdGroupData),
	};
}
