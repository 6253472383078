import {Root} from './components/root';
import {Select as SelectUI} from '@esgi/ui/controls';
import {Field} from './components/field';
import {Option} from './components/option.styled';

export const Select = {
	Root,
	Field,
	Content: SelectUI.Content,
	Option,
};
