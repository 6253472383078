import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps, getThemeColor, Skeletonable, ThemeColorKeys} from '@esgi/ui';
import {CheapContent} from './index.styled';

type CheapProps = BaseComponentProps &
	Skeletonable &
	Omit<ComponentPropsWithoutRef<'div'>, 'color'> & {
		color: ThemeColorKeys;
	};

export const CheapBox = forwardRef<HTMLDivElement, CheapProps>(
	({dataCy = 'subject-cheap-box', css = {}, color, skeleton, ...props}, forwaredRef) => (
		<CheapContent
			dataCy={dataCy}
			css={{
				backgroundColor: getThemeColor(color),
				...css,
			}}
			ref={forwaredRef}
			data-cheap-box=''
			{...props}
			skeleton={Boolean(skeleton)}
		/>
	),
);
