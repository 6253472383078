import {Domain} from '@esgi/contracts';
import {testResultsDomain} from './testResults';
import {subjectsDomain} from './subjects';
import {specialistGroupsDomain} from './specialistGroups';
import {foldersDomain} from './folders';

export class modulesDomain extends Domain {
	public testResults = new testResultsDomain(this.clientFactory);
	public subjects = new subjectsDomain(this.clientFactory);
	public specialistGroups = new specialistGroupsDomain(this.clientFactory);
	public folders = new foldersDomain(this.clientFactory);
}
