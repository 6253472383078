import {
	Color,
	PerformanceWidgetViewType,
	WidgetViewSize,
	GradeLevel,
	PeriodTypeNumerable,
	ColorNumerable,
	PeriodType,
	WidgetViewSizeNumerable,
	PerformanceWidgetViewTypeNumerable,
	ContentAreaModel,
} from '@esgi/main/features/admins/dashboard';
import {
	SchoolPerformanceWidgetOptionsContract,
	SchoolPerformanceWidgetOptions,
	TeacherModel,
	SubjectTab,
} from '@esgi/main/features/school-admin/dashboard';
import {isNull} from 'underscore';
import {getAdaptedArraysWidgetData} from '../../helpers/get-adapted-arrays-widget-data';
import {enumNumberToString} from '../enum-number-to-string';

export function parseSchoolPerformanceOptions({
	options,
	teachers,
	subjectTabs,
	gradeLevels,
	contentAreasList,
}: {
	options: SchoolPerformanceWidgetOptionsContract | null;
	teachers: TeacherModel[];
	subjectTabs: SubjectTab[];
	gradeLevels: GradeLevel[];
	contentAreasList: ContentAreaModel[];
}): SchoolPerformanceWidgetOptions | null {
	if (isNull(options)) {
		return null;
	}

	const {contentAreaIDs, gradeLevelIDs, teachersIDs, subjectIDs, viewType, viewSize, periods, candles, ...restOptions} =
		options;

	return {
		...restOptions,
		/**
		 * For the following entities, if the data array is empty, it means all entities are selected:
		 * - contentAreaIDs,
		 * - gradeLevelIDs,
		 * - teachersIDs,
		 * - subjectIDs
		 */
		contentAreaIDs: getAdaptedArraysWidgetData({
			data: contentAreaIDs,
			fullData: contentAreasList,
			iteratedKey: 'id',
		}),
		gradeLevelIDs: getAdaptedArraysWidgetData({
			data: gradeLevelIDs,
			fullData: gradeLevels,
			iteratedKey: 'id',
		}),
		teachersIDs: getAdaptedArraysWidgetData({
			data: teachersIDs,
			fullData: teachers,
			iteratedKey: 'id',
		}),
		subjectIDs: getAdaptedArraysWidgetData({
			data: subjectIDs,
			fullData: subjectTabs,
			iteratedKey: 'id',
		}),
		viewType: enumNumberToString(viewType, PerformanceWidgetViewTypeNumerable) as PerformanceWidgetViewType,
		viewSize: enumNumberToString(viewSize, WidgetViewSizeNumerable) as WidgetViewSize,
		periods: periods.map(({color, datePeriod, dateRange, trackDatesID}) => ({
			dateRange: isNull(dateRange) ? null : dateRange,
			datePeriod: enumNumberToString(datePeriod, PeriodTypeNumerable) as PeriodType,
			trackDatesID,
			color: enumNumberToString(color, ColorNumerable) as Color,
		})),
		candles: !isNull(candles)
			? {
					...candles,
					color: enumNumberToString(candles.color, ColorNumerable) as Color,
				}
			: null,
	};
}
