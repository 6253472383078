import {Form} from '@esgi/ui/form';
import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Drawer} from '@esgi/main/features/teacher/home';

export const DrawerBody = styled(Drawer.Body, {
	gap: 40,
	alignContent: 'flex-start',
});

export const FormWrapper = styled(Form, {
	display: 'grid',
	gap: 40,
});

export const ScrollableWrapper = styled(Box, {
	overflowY: 'auto',
});