import {TestCard} from '@esgi/main/kits/test-results-panel';
import {styled} from '@esgi/ui/theme';

export const ContextMenuItem = styled(TestCard.ContextMenu.Item, {
	padding: 6,
	display: 'grid',
	gridAutoFlow: 'column',
	gap: 6,
	justifyContent: 'start',
});
