import {UserInfo} from '@esgi/core/authentication';
import {Hierarchy, HierarchyInstance, HierarchySnapshot} from '../types';
import {getHierarchyMode} from './get-hierarchy-mode';
import {StudentSort} from '@esgi/core/enums';

type Parameters = {
	currentUser: UserInfo | null;
	hierarchy: Hierarchy;
};

export function buildHierarchyInstance({currentUser, hierarchy}: Parameters): HierarchyInstance {
	const districtID = currentUser?.districtID ?? 0;

	const studentID = hierarchy.studentID ?? 0;
	const studentSort = currentUser?.studentSort ?? StudentSort.FirstName;

	const schoolID = hierarchy.schoolID ?? 0;
	const teacherID = hierarchy.teacherID ?? 0;
	const classID = hierarchy.classID ?? 0;
	const groupID = hierarchy.groupID ?? 0;
	const schoolsGroupID = hierarchy.schoolsGroupID ?? 0;
	const teachersGroupID = hierarchy.teachersGroupID ?? 0;

	const specialistGroupID = hierarchy.specialistGroupID ?? 0;
	const specialistsID = hierarchy.specialistsID ?? 0;
	const specialistType = hierarchy.specialistType ?? 0;

	const preAssessID = hierarchy.preAssessID ?? 0;
	const preAssessGroupID = hierarchy.preAssessGroupID ?? 0;

	const hierarchyMode = getHierarchyMode(hierarchy);

	const snapshot: HierarchySnapshot = {
		classic: {
			studentID,
			groupID,
			classID,
			teacherID,
			schoolID,
			schoolsGroupID,
			teachersGroupID,
			districtID,
			selected: {
				level: 0,
				levelID: 0,
			},
		},
		specialist: {
			studentID,
			filter: {
				schoolID: 0,
				teacherID: 0,
			},
			type: specialistType,
			groupID: specialistGroupID,
			userID: specialistsID,
			districtID,
			groupOfSpecialistsID: 0,
			selected: {
				level: 0,
				levelID: 0,
			},
		},
		preAssess: {
			studentID,
			groupID: preAssessGroupID,
			userID: preAssessID,
			districtID,
			selected: {
				level: 0,
				levelID: 0,
			},
		},
		districtID,
		mode: hierarchyMode,
		studentSort,
	};

	const out = HierarchyInstance.build(hierarchyMode, snapshot.classic, snapshot.specialist, snapshot.preAssess);

	out.studentSort = currentUser?.studentSort ?? StudentSort.FirstName;

	return out.copy();
}
