import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {createSystemSettingsForm} from './form';
import {SystemSettingsInitData} from '../../../../../types';
import {V2TeachersUserSettingsController} from '@esgi/contracts/esgi';
import {userStorage} from '@esgi/core/authentication';
import {IntegrationsClassLinkController, IntegrationsCleverController} from '@esgi/contracts/sso';
import {isEqual} from 'underscore';

export class SystemSettingsService extends BaseService {
	public initData = new BehaviorSubject<SystemSettingsInitData | null>(null);
	public form = createSystemSettingsForm();
	private controller = new V2TeachersUserSettingsController();
	private cleverController = new IntegrationsCleverController();
	private classLinkController = new IntegrationsClassLinkController();

	public init = (data: SystemSettingsInitData) => {

		this.initData.next(data);
		this.form.value = {
			sortBy: [data?.sortBy],
			timeZone: [data?.selectedTimeZone.id],
		};
	};
	public unlinkCleverAccount = () => this.cleverController.linkingUnlink();

	public unlinkClassLinkAccount = () => this.classLinkController.linkingUnlink();

	public compareStatesForEquality = () => {

		const {sortBy, selectedTimeZone} = this.initData.value;

		const initialState = {
			sortBy,
			timeZone: selectedTimeZone.id,
		};

		const currentState = {
			sortBy: this.form.controls.sortBy.value[0],
			timeZone: this.form.controls.timeZone.value[0],
		};

		return !isEqual(currentState, initialState);
	};

	public save = () => {
		const savedData = {
			studentSort: this.form.controls.sortBy.value[0],
			timezone: this.form.controls.timeZone.value[0],
			showNewUIVersion: true,
		};
		userStorage.update({
			studentSort: savedData.studentSort,
			timeZone: savedData.timezone,
		});
		return this.controller.tabsSystemSettingsSave(savedData);
	};

	public override dispose() {
		super.dispose();
		this.controller.dispose();
		this.cleverController.dispose();
		this.classLinkController.dispose();
	}
}
