import {
	ExpandableSelectableBlockTab,
	PublishToSpecialistsNumerable,
} from '@esgi/main/features/admins/subject-management';
import {PublishForSpecialistsTabID} from '../../types';
import {isUndefined} from '@esgi/ui';

type Parameters = {
	isPublishForSpecialistsActive: boolean;
	publishForSpecialistsData: ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[];
};

type Out = {
	publishToSpecialists: PublishToSpecialistsNumerable;
	schoolSpecialists: number[];
};

export function getPublishForSpecialistsSaveData({
	isPublishForSpecialistsActive,
	publishForSpecialistsData,
}: Parameters): Out {
	if (!isPublishForSpecialistsActive) {
		return {
			publishToSpecialists: PublishToSpecialistsNumerable.None,
			schoolSpecialists: [],
		};
	}

	const activeTab = publishForSpecialistsData.find(({isActive}) => isActive);

	if (isUndefined(activeTab)) {
		throw new Error('activeTab is undefined');
	}

	if (activeTab.value === PublishForSpecialistsTabID.All) {
		return {
			publishToSpecialists: PublishToSpecialistsNumerable.All,
			schoolSpecialists: [],
		};
	}

	if (activeTab.value === PublishForSpecialistsTabID.BySpecialist) {
		return {
			publishToSpecialists: PublishToSpecialistsNumerable.IndividualISS,
			schoolSpecialists: activeTab.selectedItems,
		};
	}

	throw new Error('activeTab.value is not correspond the PublishForSpecialistsTabID enum');
}
