import {Dispatch, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {isUndefined, Skeletonable} from '@esgi/ui';
import {TestPanelMode} from '../types/section';
import {PanelContext, PanelContextValue} from '../context';
import {TestPanelTestModel} from '../types/tests';

type Props = PropsWithChildren<
	Skeletonable & {
		tests: TestPanelTestModel[];
		panelMode?: TestPanelMode;
		onPanelModeChanged?: Dispatch<TestPanelMode>;
	}
>;

export function Root({skeleton, children, tests, panelMode: controlledPanelMode, onPanelModeChanged}: Props) {
	const [withRearrangeMode, setWithRearrangeMode] = useState(false);
	const [panelMode, setPanelMode] = useState(controlledPanelMode ?? TestPanelMode.View);

	useEffect(() => {
		if (!isUndefined(controlledPanelMode)) {
			setPanelMode(controlledPanelMode);
		}
	}, [controlledPanelMode]);

	const onSetPanelMode = useCallback(
		(mode: TestPanelMode) => {
			setPanelMode(mode);
			onPanelModeChanged?.(mode);
		},
		[onPanelModeChanged],
	);

	const context = useMemo<PanelContextValue>(
		() => ({
			skeleton: Boolean(skeleton),
			panelMode,
			setPanelMode: onSetPanelMode,
			tests,
			withRearrangeMode,
			setWithRearrangeMode,
		}),
		[onSetPanelMode, panelMode, skeleton, tests, withRearrangeMode],
	);

	return <PanelContext.Provider value={context}>{children}</PanelContext.Provider>;
}
