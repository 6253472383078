import {Tooltip} from '@esgi/ui';
import {Info} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {IconBox, TooltipContentBox} from './index.styled';
import {WidgetCard, WidgetPeriod} from '@esgi/main/features/admins/dashboard';

type Props = {
	period: WidgetPeriod;
};

export function PeriodsDateInfoTip({period}: Props) {
	return (
		<Tooltip>
			<Tooltip.Trigger>
				<IconBox>
					<Info />
				</IconBox>
			</Tooltip.Trigger>

			<Tooltip.Content>
				<TooltipContentBox>
					<Text size='large' color='tertiary'>
						The list of students shown corresponds to the
					</Text>
					<Text size='large' color='tertiary'>
						first timeframe selected:&nbsp;
						<WidgetCard.ViewMode.DatePeriod
							datePeriod={period.datePeriod}
							dateRange={period.dateRange}
							trackDatesID={period.trackDatesID}
							textColor='tertiary'
							textSize='large'
						/>
					</Text>
				</TooltipContentBox>
			</Tooltip.Content>
		</Tooltip>
	);
}
