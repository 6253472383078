import {Drawer, useDrawerClose, useDrawerRef, useRootPageContext} from '@esgi/main/kits/common';
import {IconComponent, Portal} from '@esgi/ui';
import {CheckAll} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {SelectableListItemModel} from '../../types';
import {Box, SelectableList} from '@esgi/ui/layout';
import {JSX, Dispatch, useCallback, useState} from 'react';
import {ContentBox, DrawerBody, SelectAllButton, SelectableItem, SelectedCheap} from './index.styled';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

type Props<T extends string> = {
	onClose: VoidFunction;
	sectionName: string;
	value: T[];
	onSaveSelectedEntity: Dispatch<T[]>;
	items: SelectableListItemModel<T>[];
	allItems: SelectableListItemModel<T>[];
	filterWidget: JSX.Element;
	EntityIcon: IconComponent;
};

export function SelectableEntityDrawer<T extends string>({
	onClose,
	sectionName,
	value,
	onSaveSelectedEntity,
	items,
	allItems,
	filterWidget,
	EntityIcon,
}: Props<T>) {
	const drawerRef = useDrawerRef();
	const closeDrawer = useDrawerClose(drawerRef, onClose);

	const {rootPageContentContainerRef} = useRootPageContext();

	const [entireValue, setEntireValue] = useState(value);

	const onValueChange = useCallback((value: string[]) => {
		setEntireValue(value as T[]);
	}, []);

	const isAllItemsSelected = entireValue.length === allItems.length;

	const selectAllClick = useCallback(() => {
		if (isAllItemsSelected) {
			setEntireValue([]);

			return;
		}

		setEntireValue(allItems.map(({value}) => value));
	}, [allItems, isAllItemsSelected]);

	const onActionButtonClick = useCallback(() => {
		onSaveSelectedEntity(entireValue);

		closeDrawer();
	}, [closeDrawer, entireValue, onSaveSelectedEntity]);

	const isListTouched =
		entireValue.length !== value.length || entireValue.some((selectedValue) => !value.includes(selectedValue));

	return (
		<Portal container={rootPageContentContainerRef.current}>
			<Drawer drawerRef={drawerRef} onClickOutside={closeDrawer}>
				<Drawer.Header
					Icon={EntityIcon}
					sectionName={`Select ${sectionName}`}
					actionButtonText='Select'
					closeDrawer={closeDrawer}
					withActionButton
					onActionButtonClick={onActionButtonClick}
					childrenAlignment='right'
					actionButtonDisabled={!isListTouched || !entireValue.length}
				>
					<SelectedCheap>
						<Text size='xSmall' font='mono' color='primaryVivid'>
							{isAllItemsSelected ? 'All' : entireValue.length}
						</Text>
					</SelectedCheap>
				</Drawer.Header>

				<DrawerBody>
					<Drawer.ContentBlock title={sectionName} withDivider>
						<ContentBox>
							<SelectAllButton color='tertiary' onClick={selectAllClick}>
								<CheckAll />
								<Text size='medium' bold>
									{isAllItemsSelected ? 'Unselect' : 'Select'} All
								</Text>
							</SelectAllButton>
							<Box>{filterWidget}</Box>
							<OverlayScrollbarsComponent defer options={{scrollbars: {autoHide: 'leave'}}}>
								<SelectableList>
									<SelectableList.GroupRoot type='multiple' value={entireValue} onValueChange={onValueChange}>
										<SelectableList.Group>
											{items.map(({value, label}) => (
												<SelectableItem value={value} variant='outlined' key={value}>
													<Text size='large'>{label}</Text>
												</SelectableItem>
											))}
										</SelectableList.Group>
									</SelectableList.GroupRoot>
								</SelectableList>
							</OverlayScrollbarsComponent>
						</ContentBox>
					</Drawer.ContentBlock>
				</DrawerBody>
			</Drawer>
		</Portal>
	);
}
