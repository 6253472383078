import {styled} from '@esgi/ui/theme';
import {maxVisibleSessions} from './constants';
import {TestCard} from '@esgi/main/kits/test-results-panel';

const contextMenuItemHeight = 30;

export const GroupBox = styled(TestCard.ContextMenu.Group, {
	variants: {
		isItemsMoreThanMaxPossible: {
			true: {
				height: maxVisibleSessions * contextMenuItemHeight,
				overflow: 'hidden',
			},
		},
	},
});

export const SessionInfoItem = styled(TestCard.ContextMenu.Item, {
	paddingTop: 7,
	paddingBottom: 7,
	paddingInline: 12,
	cursor: 'pointer',
	height: contextMenuItemHeight,

	'&:last-child': {
		borderBottom: 'none',
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
	},
});

export const AddNewSession = styled(TestCard.ContextMenu.Item, {
	display: 'flex',
	gap: '$1',
	height: 35,
	cursor: 'pointer',

	'& svg': {
		width: 24,
		height: 24,
	},
});
