import {Text} from '@esgi/ui/typography';
import {
	ActionPanel,
	DeleteButton,
	Page,
	RunReportButton,
	RunReportButtonProps,
} from '../../../../_kit';
import {RowDef} from '../../../types';
import {SchoolReportsDialog} from '../../../../_kit/school-reports-dialog';
import {useFlag} from '@esgi/ui';
import {useStudents} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';

export function BottomPanel() {
	const {api, entityName} = Page.useContext<RowDef>();
	const [showDistrictReport, openDistrictReport, closeDistrictReports] =
		useFlag();
	const totalRows = api?.getDisplayedRowCount();
	const selectedRows = api?.getSelectedRows();
	const [{data: students}] = useStudents();

	const runReportButtonProps = useMemo<Partial<RunReportButtonProps>>(() => {
		const allRowsSelected = selectedRows?.length === totalRows;
		const multipleRowsSelected = selectedRows?.length > 1;
		const noStudents = !students.length;

		if (allRowsSelected && noStudents) {
			return {
				title: 'School Reports',
				disabled: true,
				disabledMessage:
					'No students have been added to your school. Please add some before running the report.',
			};
		}

		if (multipleRowsSelected && !allRowsSelected) {
			return {
				title: 'School Reports',
				disabled: true,
			};
		}

		if (allRowsSelected) {
			return {title: 'School Reports'};
		}

		return {title: 'Run Reports'};
	}, [selectedRows?.length, students.length, totalRows]);

	return (
		<ActionPanel.Root api={api} showOnMinSelectedItems={2}>
			<ActionPanel.Header>
				<ActionPanel.ContentBox>
					<ActionPanel.CloseButton />

					<ActionPanel.MainBox>
						<ActionPanel.CounterBox />

						<Text size='xSmall' font='mono' color='primary'>
							{entityName} Selected
						</Text>
					</ActionPanel.MainBox>

					<ActionPanel.ActionButtonsBox>
						<ActionPanel.ShowOnlySelectedCheckbox />
						<ActionPanel.ActionButtonDivider />

						<DeleteButton
							disabled
							tooltipMessage='Select only one item for deletion.'
							whenShowTooltip='disabled'
						/>

						<RunReportButton
							{...runReportButtonProps}
							onClick={openDistrictReport}
						/>
						{showDistrictReport && (
							<SchoolReportsDialog onClose={closeDistrictReports} />
						)}
					</ActionPanel.ActionButtonsBox>
				</ActionPanel.ContentBox>
			</ActionPanel.Header>
		</ActionPanel.Root>
	);
}
