import {PropsWithChildren} from 'react';
import {ChildrenBox, EntireSidebarContainer, EntireSidebarHeader} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

export function EntireSidebar({children}: PropsWithChildren) {
	return (
		<EntireSidebarContainer data-cy='marking-periods-sidebar'>
			<EntireSidebarHeader>
				<Text data-cy='marking-periods-sidebar-header' size='small' color='neutral56'>
					My District Calendar
				</Text>
			</EntireSidebarHeader>

			<ChildrenBox>
				<Text data-cy='my-marking-periods-title' size='small' font='mono' color='lowContrast'>
					My Marking Periods
				</Text>

				<OverlayScrollbarsComponent
					data-cy='marking-periods-items'
					defer
					options={{
						scrollbars: {autoHide: 'leave'},
						paddingAbsolute: true,
					}}
				>
					{children}
				</OverlayScrollbarsComponent>
			</ChildrenBox>
		</EntireSidebarContainer>
	);
}
