import {Dispatch, useCallback, useEffect} from 'react';
import {CardsSortBy} from '../../../../types/section';
import {Checkbox} from '../../../checkbox';
import {CheckboxesBox} from '../../../checkboxes-box';
import {Select} from '../../../select';
import {FiltersBox, SideFilters} from './index.styled';
import {isNull} from 'underscore';
import {isUndefined, Skeletonable} from '@esgi/ui';
import {useSelectsItems} from './use-selects-items';

type Props = Skeletonable<{
	uniqGradeLevelsIDs: number[];
	gradeLevelID: number;
	onGradeLevelIDChange: Dispatch<number>;
	cardsSortBy: CardsSortBy;
	onCardsSortByValueChange: Dispatch<CardsSortBy>;
	showInColor: boolean;
	onShowInColorValueChange: Dispatch<boolean>;
	showEachStudent: boolean;
	onShowEachStudentValueChange: Dispatch<boolean>;
	withoutVariantByEachStudent: boolean;
}>;

export function Filters({
	uniqGradeLevelsIDs,
	gradeLevelID,
	onGradeLevelIDChange,
	cardsSortBy,
	onCardsSortByValueChange,
	showInColor,
	onShowInColorValueChange,
	showEachStudent,
	onShowEachStudentValueChange,
	skeleton,
	withoutVariantByEachStudent,
}: Props) {
	const {gradeLevelsItems, sortByItems} = useSelectsItems({
		showEachStudent,
		uniqGradeLevelsIDs,
	});

	useEffect(() => {
		const sortByItem = sortByItems.find(({value}) => value === cardsSortBy);

		if (isUndefined(sortByItem)) {
			const firstNotHiddenSortItemValue = sortByItems[0]?.value ?? CardsSortBy.Default;

			onCardsSortByValueChange(firstNotHiddenSortItemValue);
		}
	}, [cardsSortBy, onCardsSortByValueChange, sortByItems]);

	const handleGradeLevelIDChange = useCallback(
		(value: string) => {
			onGradeLevelIDChange(Number(value));
		},
		[onGradeLevelIDChange],
	);

	return (
		<FiltersBox>
			<SideFilters>
				{(!isNull(gradeLevelsItems) || skeleton) && (
					<Select
						items={gradeLevelsItems ?? []}
						placeholder='Grade Level'
						selectedValue={String(gradeLevelID)}
						onValueChange={handleGradeLevelIDChange}
						skeleton={skeleton}
					/>
				)}

				<CheckboxesBox>
					<Checkbox
						checked={showInColor}
						onCheckedChange={(value) => onShowInColorValueChange(Boolean(value))}
						label='Color'
						skeleton={Boolean(skeleton)}
					/>

					{!withoutVariantByEachStudent && (
						<Checkbox
							checked={showEachStudent}
							onCheckedChange={(value) => onShowEachStudentValueChange(Boolean(value))}
							label='Show each student'
							skeleton={Boolean(skeleton)}
						/>
					)}
				</CheckboxesBox>
			</SideFilters>

			<SideFilters>
				<Select
					items={sortByItems}
					placeholder='Sort by'
					selectedValue={cardsSortBy}
					onValueChange={onCardsSortByValueChange}
					skeleton={skeleton}
				/>
			</SideFilters>
		</FiltersBox>
	);
}
