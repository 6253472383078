import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(FlexBox, {
	gap: '$3',
	height: '100%',
	alignItems: 'center',
});

export const StatusTextBox = styled(FlexBox, {
	gap: 6,
	alignItems: 'center',
});
