import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, map} from 'rxjs';
import {InitEditGroupResponse} from './types';
import {ClassModel, Student} from '../../types';
import {Group} from '@esgi/main/libs/store';
import {V2TeachersGroupsController} from '@esgi/contracts/esgi';

export class EditGroupService extends BaseService {
	public className$ = new BehaviorSubject<string | null>(null);
	public students$ = new BehaviorSubject<Student[] | null>(null);
	public initialSelectedStudentsIds$ = new BehaviorSubject<Student['id'][] | null>(null);
	public initialGroupName$ = new BehaviorSubject<string | null>(null);

	private controller = 'v2/teachers/modules/groups';
	private teachersGroupsController = new V2TeachersGroupsController();

	public init({classID, groupID}: {classID: ClassModel['id']; groupID: number}) {
		return this.httpClient.ESGIApi.get<InitEditGroupResponse>(this.controller, 'update', {classID, groupID})
			.pipe(
				map(({groupName, studentsInGroup, availableStudents, className}) => {
					this.className$.next(className);
					this.initialGroupName$.next(groupName);

					const initialSelectedStudentsIds = studentsInGroup.map(({id}) => id);
					this.students$.next([...availableStudents, ...studentsInGroup]);
					this.initialSelectedStudentsIds$.next(initialSelectedStudentsIds);

					return {initialSelectedStudentsIds};
				}),
			)
			.asObservable();
	}

	public updateGroup({
		groupName,
		studentIDs,
		groupID,
	}: {
		groupName: string;
		studentIDs: Student['id'][];
		groupID: number;
	}) {
		return this.httpClient.ESGIApi.post(this.controller, 'update', {
			name: groupName,
			studentIDs,
			groupID,
		});
	}

	public deleteGroup({groupID}: {groupID: Group['id']}) {
		return this.teachersGroupsController.remove({groupID});
	}

	public override dispose() {
		super.dispose();
		this.teachersGroupsController.dispose();
	}
}
