import {ControlButton, Wrapper, ImageWrapper} from './index.styled';
import {Expand} from '@esgi/ui';
import {QuestionsSliderItem} from './components/questions-slider-item';
import {TestModel, QuestionModel, CardViewType} from '../../service/types';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTransition} from 'react-spring';
import {
	TestBackgroundChangedEvent,
	TestQuestionListChanged,
} from 'shared/modules/test-details/events';
import * as TestDetailsEvents from 'shared/modules/test-details/events';
import {EventBusManager} from '@esgillc/events';

export type Props = {
	testID: TestModel['testID'];
	imageQuestionID: TestModel['imageQuestionID'];
	questions: QuestionModel[];
	cardView: CardViewType;
};

export function QuestionsSlider({
	questions,
	testID,
	cardView,
	imageQuestionID,
}: Props) {
	const [currentQuestionIdx, setCurrentQuestionIdx] = useState(0);
	const [prevClicked, setPrevClicked] = useState(false);
	const {current: eventBus} = useRef(new EventBusManager());
	const [sliderQuestions, setSliderQuestions] =
		useState<QuestionModel[]>(questions);

	const transitions = useTransition(currentQuestionIdx, {
		key: currentQuestionIdx,
		from: {
			opacity: 0,
			transform: `translate3d(${prevClicked ? -50 : 100}%, 0, 0)`,
		},
		enter: {opacity: 1, transform: 'translate3d(0%, 0, 0)'},
		leave: {
			opacity: 0,
			transform: `translate3d(${prevClicked ? 100 : -50}%, 0, 0)`,
		},
		config: {tension: 280, friction: 30},
	});

	const onPrevClick = useCallback(() => {
		setPrevClicked(true);
		setCurrentQuestionIdx((prev) =>
			prev === 0 ? questions.length - 1 : prev - 1,
		);
	}, [questions]);

	const onNextClick = useCallback(() => {
		setPrevClicked(false);
		setCurrentQuestionIdx((prev) =>
			prev !== questions.length && prev !== questions.length - 1 ? prev + 1 : 0,
		);
	}, [questions]);

	useEffect(() => {
		if (imageQuestionID) {
			setCurrentQuestionIdx(
				questions.findIndex((item) => item.questionID === imageQuestionID),
			);
			return;
		}
		setCurrentQuestionIdx(0);
	}, [imageQuestionID, questions]);

	const updateQuestionList = () => {
		const newSliderQuestions = sliderQuestions.map((question) => {
			const newQuestion = {...question};
			newQuestion.ticks = new Date().getTime();
			newQuestion.pregenerated = false;
			return newQuestion;
		});

		setSliderQuestions(newSliderQuestions);
	};

	const testBackgroundEventHandler = (args: TestBackgroundChangedEvent) => {
		if (args.id !== testID) {
			return;
		}
		updateQuestionList();
	};

	const testQuestionListEventHandler = (args: TestBackgroundChangedEvent) => {
		if (args.id !== testID) {
			return;
		}
		updateQuestionList();
	};

	useEffect(() => {
		eventBus.subscribe(
			TestDetailsEvents.TestBackgroundChangedEvent,
			testBackgroundEventHandler,
		);
		eventBus.subscribe(TestQuestionListChanged, testQuestionListEventHandler);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Wrapper data-cy='questions-slider'>
			<ControlButton
				data-cy='prev-button'
				disabled={questions.length === 1}
				onClick={onPrevClick}
				prev
			>
				<Expand />
			</ControlButton>
			<ImageWrapper
				data-cy='preview-image'
				isListView={cardView === CardViewType.List}
			>
				{transitions((style, currentQuestionIdx) => (
					<QuestionsSliderItem
						currentQuestionIdx={currentQuestionIdx}
						questions={sliderQuestions}
						testID={testID}
						style={style}
					/>
				))}
			</ImageWrapper>
			<ControlButton
				data-cy='next-button'
				disabled={questions.length === 1}
				onClick={onNextClick}
				next
			>
				<Expand />
			</ControlButton>
		</Wrapper>
	);
}
