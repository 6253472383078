import {getThemeColor, ThemeColorKeys} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef, useCallback, useEffect, useMemo} from 'react';
import {animated, useSpring} from 'react-spring';
import {useLoadingContext} from '../context';

type Props = Omit<ComponentPropsWithoutRef<'path'>, 'children' | 'd' | 'fill' | 'stroke' | 'strokeWidth'> & {
	stroke?: ThemeColorKeys;
	strokeWidth?: number;
};

export const Arc = forwardRef<SVGPathElement, Props>(
	({stroke = 'primary', strokeWidth = 3, strokeLinecap = 'round', ...pathProps}, forwardedRef) => {
		const {svgValue, updateShapeOffset, maxShapeOffset} = useLoadingContext();

		useEffect(() => {
			updateShapeOffset({shape: 'arc', offset: strokeWidth});

			return () => {
				updateShapeOffset({shape: 'arc', offset: 0});
			};
		}, [strokeWidth, updateShapeOffset]);

		const circleValues = useMemo(() => {
			const circleSize = svgValue.size - maxShapeOffset;

			return {
				radius: circleSize / 2,
				center: svgValue.center,
			};
		}, [svgValue.size, svgValue.center, maxShapeOffset]);

		const getCoordinatesByAngle = useCallback(
			(angle: number) => {
				const angleRadian = ((angle - 90) * Math.PI) / 180;

				return {
					x: circleValues.center.x + circleValues.radius * Math.cos(angleRadian),
					y: circleValues.center.y + circleValues.radius * Math.sin(angleRadian),
				};
			},
			[circleValues],
		);

		const pathD = useMemo(() => {
			/**
			 * progress in [0, 100]
			 * startValue is 0%, endValue is 20%
			 * startValue and endValue help to set arc width
			 */
			const startValueProgress = 0 / 100;
			const endValueProgress = 20 / 100;

			const startArcAngle = startValueProgress * 360;
			const endArcAngle = endValueProgress * 360;

			const {x: x1, y: y1} = getCoordinatesByAngle(startArcAngle);
			const {x: x2, y: y2} = getCoordinatesByAngle(endArcAngle);

			const startPosition = `M ${x1} ${y1}`;
			const arc = `A ${circleValues.radius} ${circleValues.radius}, 0, 0, 1, ${x2} ${y2}`;

			return `${startPosition} ${arc}`;
		}, [circleValues, getCoordinatesByAngle]);

		const animationArcProps = useSpring({
			from: {
				transform: 'rotateZ(0deg)',
			},
			to: {
				transform: 'rotateZ(360deg)',
			},
			config: {
				duration: 1500,
			},
			loop: true,
		});

		return (
			<animated.g
				style={{
					...animationArcProps,
					transformOrigin: 'center',
				}}
			>
				<rect width={svgValue.size} height={svgValue.size} fill='none' stroke='none' x='0' y='0' />
				<path
					d={pathD}
					fill='none'
					stroke={getThemeColor(stroke)}
					strokeWidth={strokeWidth}
					strokeLinecap={strokeLinecap}
					ref={forwardedRef}
					{...pathProps}
				/>
			</animated.g>
		);
	},
);
