import {Alert} from '@esgi/ui/alert';
import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		gap: '$5',
		minWidth: 'unset',
		width: 460,
	},
};

export const AlertHeader = styled(Alert.Header, {
	height: 64,

	'& > div': {
		gridTemplateColumns: '1fr auto',
		gap: '$4',

		'& > div': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
	},
});

export const SelectedItemsBox = styled(FlexBox, {
	alignItems: 'center',
	gap: 6,
});
