import {AgGridReact, AgGridReactProps} from 'ag-grid-react';
import {IRowNode, GridApi, GridReadyEvent} from 'ag-grid-community';
import {useCallback, useEffect, useId, useMemo, useState} from 'react';
import {rootTableClassName} from '../../constants';
import {Skeleton} from '../skeleton';
import {generateRandomString, join} from '../../../utils';
import {NoRows} from '../no-rows';
import {useThemeContext} from '../../contexts/theme';
import {AgGridContextValue} from '../../contexts/ag-grid-table';
import {useColDefs} from './hooks/use-col-defs';
import {useExternalFilters} from './hooks/use-external-filters';
import {useGetRowClass} from './hooks/use-get-row-class';
import {isNull} from 'underscore';
import {useOverlayScrollbarsData} from './hooks/use-overlay-scrollbars-data';

type Props<TData> = AgGridReactProps<TData> & {
	withoutCustomizationColumn?: boolean;
	selectedAsDisabledNodes?: IRowNode[];
};

export function AgGridTable<TData = any>({
	columnDefs: passedColumnDefs,
	className,
	rowSelection,
	headerHeight = 32,
	rowHeight = 40,
	getRowClass,
	withoutCustomizationColumn = false,
	autoSizePadding = 0,
	isExternalFilterPresent,
	doesExternalFilterPass,
	selectedAsDisabledNodes = [],
	onGridReady,
	rowData,
	loading,
	...agGridProps
}: Props<TData>) {
	const {withoutFirstColumnPadding, withoutLastColumnPadding, cellHorizontalPadding} = useThemeContext();

	const gridTableID = useId();

	const [api, setApi] = useState<GridApi | null>(null);
	const [isAgGridInitialized, setIsAgGridInitialized] = useState(false);

	useEffect(() => {
		if (selectedAsDisabledNodes.length && !isNull(api)) {
			api.refreshCells({
				rowNodes: selectedAsDisabledNodes,
			});
		}
	}, [api, selectedAsDisabledNodes]);

	const rootDynamicTableClassName = useMemo(() => `esgi-ag-grid-${generateRandomString(4)}`, [gridTableID]);

	useOverlayScrollbarsData({isAgGridInitialized, rootTableClassName: rootDynamicTableClassName});

	const handleOnGridReady = useCallback(
		(data: GridReadyEvent) => {
			setApi(data.api);
			setIsAgGridInitialized(true)
			onGridReady?.(data);
		},
		[onGridReady],
	);

	const columnDefs = useColDefs({
		passedColumnDefs,
		rowSelection,
		withoutCustomizationColumn,
	});

	const handleGetRowClass = useGetRowClass({getRowClass});

	const {
		onIsExternalFilterPresent,
		onDoesExternalFilterPass,
		setExternalFilter,
		updateExternalFilter,
		removeExternalFilter,
	} = useExternalFilters({isExternalFilterPresent, doesExternalFilterPass});

	const context = useMemo<AgGridContextValue>(() => {
		return {
			setExternalFilter,
			removeExternalFilter,
			updateExternalFilter,
			selectedAsDisabledNodes,
		};
	}, [removeExternalFilter, setExternalFilter, updateExternalFilter, selectedAsDisabledNodes]);

	return (
		<AgGridReact
			className={join(rootTableClassName, rootDynamicTableClassName, className)}
			rowSelection={rowSelection}
			columnDefs={columnDefs}
			getRowClass={handleGetRowClass}
			headerHeight={headerHeight}
			rowHeight={rowHeight}
			autoSizePadding={autoSizePadding}
			loadingOverlayComponent={({api, context}) => (
				<Skeleton
					api={api}
					cellHorizontalPadding={cellHorizontalPadding}
					context={context}
					headerHeight={headerHeight}
					rowHeight={rowHeight}
					withoutFirstColumnPadding={withoutFirstColumnPadding}
					withoutLastColumnPadding={withoutLastColumnPadding}
				/>
			)}
			noRowsOverlayComponent={NoRows}
			isExternalFilterPresent={onIsExternalFilterPresent}
			doesExternalFilterPass={onDoesExternalFilterPass}
			context={context}
			onGridReady={handleOnGridReady}
			rowData={loading ? [] : rowData}
			loading={loading}
			{...agGridProps}
		/>
	);
}
