import {DistrictCutoff} from './types';

export function getDistrictCutoffEnumValue(value: number) {
	switch (value) {
		case 5:
			return DistrictCutoff.Five;
		case 10:
			return DistrictCutoff.Ten;
		case 15:
			return DistrictCutoff.Fifteen;
		case 20:
			return DistrictCutoff.Twenty;
		case 25:
			return DistrictCutoff.TwentyFive;

		default:
			return DistrictCutoff.None;
	}
}
