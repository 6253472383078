import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'space-between',
	borderBottomStyle: 'solid',
	borderBottomWidth: 1,
	borderBottomColor: '$primaryMuted',
	backgroundColor: '$primaryBackground',
	paddingTop: '$2',
	paddingBottom: '$2',
	paddingInline: '$5',
});

export const FolderNameBox = styled(FlexBox, {
	gap: '$1',
	alignItems: 'center',

	'& > svg': {
		width: 24,
		height: 24,

		'& path': {
			fill: '$primaryVivid',
		},
	},
});
