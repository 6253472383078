import {Dispatch, useCallback, useMemo, useState} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {useStreamEffect} from '@esgi/ui';
import {ElementStatus} from '@esgi/ui/form';
import {
	SchoolStudentsNeedingSupportWidgetOptions,
	SchoolStudentsNeedingSupportWidgetUpdatedValue,
	SchoolStudentsNeedingSupportWidgetViewType,
} from '../../../../types/students-needing-support';
import {FormToggleGroup, GradeLevel, WidgetCard} from '@esgi/main/features/admins/dashboard';
import {AdditionalSettingBox, CardBody, CardBodyDivider} from './index.styled';
import {AdaptedWidgetOptions, DynamicFormName, PeriodsVariant} from './types';
import {getDistrictCutoffEnumValue} from './get-district-cutoff-enum-value';
import {useFormsInit} from './forms-data/use-forms-init';
import {MainSettings} from './components/main-settings';
import {StudentsData} from './components/students-data';
import {ClassesData} from './components/classes-data';
import {periodsVariantItems} from './constants';
import {Widget} from '../../../../types/widget';
import {useSaveData} from './use-save-data';
import {ClassModel, SubjectTab, TeacherModel} from '../../../../../types';

type Props = {
	subjects: SubjectTab[];
	allClassesList: ClassModel[];
	allTeachersList: TeacherModel[];
	onDeleteWidgetByID: Dispatch<Widget['id']>;
	widgetOptions: SchoolStudentsNeedingSupportWidgetOptions;
	onUpdateWidget: Dispatch<SchoolStudentsNeedingSupportWidgetUpdatedValue>;
	gradeLevels: GradeLevel[];
};

export function EditMode({
	subjects,
	allClassesList,
	allTeachersList,
	onDeleteWidgetByID,
	widgetOptions,
	onUpdateWidget,
	gradeLevels,
}: Props) {
	const adaptedWidgetOptions = useMemo<AdaptedWidgetOptions>(() => {
		const {districtCutoff, ...restOptions} = widgetOptions;

		return {
			...restOptions,
			districtCutoff: getDistrictCutoffEnumValue(districtCutoff),
		};
	}, [widgetOptions]);

	const [currentDynamicFormName, setCurrentDynamicFormName] = useState<DynamicFormName>();

	const [widgetViewType, setWidgetViewType] = useState(widgetOptions.viewType);

	const [selectedClassesIDs, setSelectedClassesIDs] = useState(widgetOptions.classIDs.map(String));
	const [selectedTeachersIDs, setSelectedTeachersIDs] = useState(widgetOptions.teachersIDs.map(String));
	const [selectedContentArea, setSelectedContentArea] = useState(widgetOptions.contentAreaIDs.map(String));
	const [selectedSubjectIDs, setSelectedSubjectIDs] = useState(widgetOptions.subjectIDs.map(String));
	const [selectedGradeLevels, setSelectedGradeLevels] = useState(widgetOptions.gradeLevelIDs.map(String));

	const [isCommonFormTouched, setIsCommonFormTouched] = useState(false);

	const [isDynamicFormDataTouched, setIsDynamicFormDataTouched] = useState(false);
	const [isDynamicFormDataValid, setIsDynamicFormDataValid] = useState(true);

	const {
		commonForm,
		studentsSinglePeriodForm,
		studentsTwoPeriodsForm,
		classesForm,
		initialCommonForm,
		initialStudentsTwoPeriodsForm,
		setStudentsSinglePeriodFormInitialData,
		setStudentsTwoPeriodsFormInitialData,
		setClassesFormInitialData,
	} = useFormsInit({widgetOptions: adaptedWidgetOptions});

	const onCurrentDynamicFormNameChange = useCallback(
		(newFormName: DynamicFormName) => {
			setCurrentDynamicFormName((currentName) => {
				switch (currentName) {
					case 'studentsSinglePeriod':
						setStudentsSinglePeriodFormInitialData();
						break;
					case 'studentsTwoPeriods':
						setStudentsTwoPeriodsFormInitialData();
						break;
					case 'classes':
						setClassesFormInitialData();
						break;
				}

				return newFormName;
			});
		},
		[setClassesFormInitialData, setStudentsSinglePeriodFormInitialData, setStudentsTwoPeriodsFormInitialData],
	);

	useStreamEffect(commonForm.controls.viewType.onChanged, ({currState: {value}}) => {
		setWidgetViewType(value);

		if (value === SchoolStudentsNeedingSupportWidgetViewType.Students) {
			commonForm.controls.periodsVariant.value = PeriodsVariant.SinglePeriod;
			commonForm.controls.periodsVariant.status = ElementStatus.valid;
		}

		if (value === SchoolStudentsNeedingSupportWidgetViewType.Classes) {
			commonForm.controls.periodsVariant.value = PeriodsVariant.None;
			commonForm.controls.periodsVariant.status = ElementStatus.disabled;
		}
	});

	useStreamEffect(
		commonForm.onChanged,
		({
			currState: {
				value: {viewType, periodsVariant, districtCutoff},
			},
		}) => {
			const isFormTouched =
				viewType !== widgetOptions.viewType ||
				districtCutoff[0] !== adaptedWidgetOptions.districtCutoff ||
				(periodsVariant !== PeriodsVariant.None && periodsVariant !== initialCommonForm.periodsVariant);

			setIsCommonFormTouched(isFormTouched);
		},
	);

	const isSelectedEntityTouched = useMemo(() => {
		const contentAreaTouched =
			selectedContentArea.length !== widgetOptions.contentAreaIDs.length ||
			selectedContentArea.some((item) => !widgetOptions.contentAreaIDs.includes(Number(item)));

		const subjectsTouched =
			selectedSubjectIDs.length !== widgetOptions.subjectIDs.length ||
			selectedSubjectIDs.some((item) => !widgetOptions.subjectIDs.includes(Number(item)));

		const gradeLevelsTouched =
			selectedGradeLevels.length !== widgetOptions.gradeLevelIDs.length ||
			selectedGradeLevels.some((item) => !widgetOptions.gradeLevelIDs.includes(Number(item)));

		const classesTouched =
			currentDynamicFormName !== 'classes' &&
			(selectedClassesIDs.length !== widgetOptions.classIDs.length ||
				selectedClassesIDs.some((item) => !widgetOptions.classIDs.includes(Number(item))));

		const teachersTouched =
			currentDynamicFormName === 'classes' &&
			(selectedTeachersIDs.length !== widgetOptions.teachersIDs.length ||
				selectedTeachersIDs.some((item) => !widgetOptions.teachersIDs.includes(Number(item))));

		return contentAreaTouched || subjectsTouched || gradeLevelsTouched || classesTouched || teachersTouched;
	}, [
		selectedContentArea,
		widgetOptions,
		selectedSubjectIDs,
		selectedGradeLevels,
		currentDynamicFormName,
		selectedClassesIDs,
		selectedTeachersIDs,
	]);

	const isFormTouched = isCommonFormTouched || isSelectedEntityTouched || isDynamicFormDataTouched;

	const handleSaveWidgetOptions = useSaveData({
		schoolID: widgetOptions.schoolID,
		districtID: widgetOptions.districtID,
		globalSchoolYearID: widgetOptions.globalSchoolYearID,
		commonForm,
		classesForm,
		studentsSinglePeriodForm,
		studentsTwoPeriodsForm,
		selectedContentArea,
		selectedGradeLevels,
		selectedClassesIDs,
		selectedTeachersIDs,
		selectedSubjectIDs,
		onUpdateWidget,
		setIsCommonFormTouched,
		setIsDynamicFormDataTouched,
		setIsDynamicFormDataValid,
		subjects,
		allClassesList,
		allTeachersList,
		setSelectedClassesIDs,
		gradeLevels,
	});

	return (
		<WidgetCard.EditMode
			isChildrenFormTouched={isFormTouched}
			isChildrenFormValid={isDynamicFormDataValid}
			onDeleteWidgetByID={onDeleteWidgetByID}
			onUpdateWidget={handleSaveWidgetOptions}
		>
			<CardBody>
				<MainSettings
					commonForm={commonForm}
					subjects={subjects}
					selectedContentArea={selectedContentArea}
					selectedGradeLevels={selectedGradeLevels}
					selectedSubjectIDs={selectedSubjectIDs}
					setSelectedContentArea={setSelectedContentArea}
					setSelectedGradeLevels={setSelectedGradeLevels}
					setSelectedSubjectIDs={setSelectedSubjectIDs}
					classes={allClassesList}
					teachers={allTeachersList}
					selectedClassesIDs={selectedClassesIDs}
					selectedTeachersIDs={selectedTeachersIDs}
					setSelectedClassesIDs={setSelectedClassesIDs}
					setSelectedTeachersIDs={setSelectedTeachersIDs}
					currentDynamicFormName={currentDynamicFormName}
					gradeLevels={gradeLevels}
				/>

				<CardBodyDivider />

				<AdditionalSettingBox>
					<GridBox gap='3'>
						<WidgetCard.EditMode.FormElement control={commonForm.controls.periodsVariant}>
							<FormToggleGroup items={periodsVariantItems} />
						</WidgetCard.EditMode.FormElement>

						{widgetViewType === SchoolStudentsNeedingSupportWidgetViewType.Students && (
							<StudentsData
								periodsVariantControl={commonForm.controls.periodsVariant}
								studentsSinglePeriodForm={studentsSinglePeriodForm}
								studentsTwoPeriodsForm={studentsTwoPeriodsForm}
								initialStudentsTwoPeriodsForm={initialStudentsTwoPeriodsForm}
								onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
								setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
								setIsDynamicFormDataValid={setIsDynamicFormDataValid}
							/>
						)}

						{widgetViewType === SchoolStudentsNeedingSupportWidgetViewType.Classes && (
							<ClassesData
								form={classesForm}
								onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
								setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
								setIsDynamicFormDataValid={setIsDynamicFormDataValid}
							/>
						)}
					</GridBox>
				</AdditionalSettingBox>
			</CardBody>
		</WidgetCard.EditMode>
	);
}
