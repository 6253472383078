import {styled} from '@esgi/ui/theme';
import {Box, GridBox, NavigationBarOrientation} from '@esgi/ui/layout';

export const PageContainer = styled(GridBox, {
	width: '100vw',
	height: '100vh',
	overflow: 'hidden',

	variants: {
		navigationBarOrientation: {
			[NavigationBarOrientation.Vertical]: {
				gridTemplateColumns: '120px 1fr',
			},
			[NavigationBarOrientation.Horizontal]: {
				gridTemplateRows: 'auto 1fr',
			},
		},
	},
});

export const ContentContainer = styled(Box, {
	width: '100%',
	height: '100%',
	overflow: 'hidden',
	position: 'relative',
});
