import {TabID} from '../../types';
import {TabItem} from './types';

export const items: TabItem[] = [
	{
		value: TabID.ClassInformation,
		label: 'Class Information',
	},
	{
		value: TabID.Students,
		label: 'Students',
	},
];
