import {Folder} from '@esgi/main/features/admins/data';
import {RootPageContext} from '@esgi/main/kits/common';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {createContext, Dispatch, RefObject, useContext} from 'react';
import {isNull} from 'underscore';

export type DataPageContextValue = RootPageContext & {
	pageContentContainerRef: RefObject<HTMLDivElement>;
	districtName: string | null;
	snackbarRef: RefObject<SnackbarManager>;
	entityFolders: Folder[] | null;
	setEntityFolders: Dispatch<Folder[] | null>;
	activeFolder: Folder | null;
	onSetActiveFolder: Dispatch<Folder>;
	onDeselectFolder: VoidFunction;
	onDecreaseFolderCount: (folderID: number) => void;
	setDistrictName: (name: string) => void;
};

export const DataPageContext = createContext<DataPageContextValue | null>(null);

export function useDataPageContext() {
	const context = useContext(DataPageContext);

	if (isNull(context)) {
		throw new Error('useDataPageContext is null');
	}

	return context;
}
