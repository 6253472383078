import {Color, FormColorPicker, Widget, WidgetCard, WidgetFormSelectItem} from '@esgi/main/features/admins/dashboard';
import {FormControl} from '@esgi/ui/form';
import {BoxplotTwoClassesFormColorItem, BoxplotTwoClassesFormClassIDItem} from '../../../../../forms-data/types';
import {useFilteredColorItems} from '../../../use-filtered-color-items';

type Props = {
	excludedColor: Color | null;
	colorControl: FormControl<BoxplotTwoClassesFormColorItem>;
	classIDControl: FormControl<BoxplotTwoClassesFormClassIDItem>;
	placeholder: string;
	classItems: WidgetFormSelectItem<string>[];
};

export function ClassRow({excludedColor, colorControl, classIDControl, placeholder, classItems}: Props) {
	const filteredColorItems = useFilteredColorItems({excludedColor});

	return (
		<WidgetCard.EditMode.PeriodColorBox>
			<WidgetCard.EditMode.FormElement control={colorControl}>
				<FormColorPicker items={filteredColorItems} />
			</WidgetCard.EditMode.FormElement>

			<Widget.FormSelect placeholder={placeholder} items={classItems} control={classIDControl} />
		</WidgetCard.EditMode.PeriodColorBox>
	);
}
