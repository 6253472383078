import {Button} from '@esgi/ui';
import {Card, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardHeader = styled(Card.Header, {
	display: 'grid',
	alignItems: 'center',
	gap: '$4',
	gridTemplateColumns: '1fr auto 1fr',
});

export const WidgetTitleBox = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	gap: '$4',
	alignItems: 'center',
});

export const WidgetTitleIconBox = styled(GridBox, {
	width: 24,
	height: 24,

	'& > svg': {
		width: '100%',
		height: '100%',

		'& > path': {
			fill: '$primary',
		},
	},
});

export const ActionButtonsBox = styled(GridBox, {
	gridColumnStart: 3,
	gap: '$4',
	alignItems: 'center',
	justifyContent: 'end',
	gridAutoFlow: 'column',
});

export const DeleteButton = styled(Button.Icon, {
	color: '$negative',
});

export const SaveButton = styled(Button, {
	paddingTop: 7,
	paddingBottom: 7,
	paddingLeft: 19,
	paddingRight: 19,
	minWidth: 'unset',
});
