import {Observable, tap} from 'rxjs';
import {ValidatorArgs} from '@esgi/ui/form';
import {ValidatorName} from './types';

export function highlightValueValidation(maxValue: number) {
	return function (validationChain: Observable<ValidatorArgs<string>>) {
		return validationChain.pipe(
			tap((validatorArgs) => {
				const value = validatorArgs.field.currentValue;

				if (typeof value === 'string' && value === '') {
					validatorArgs.errors.push(ValidatorName.HighlightValueRequired);
				}

				if (typeof value === 'number' && value > maxValue) {
					validatorArgs.errors.push(ValidatorName.HighlightValueOutOfRange);
				}
			}),
		);
	};
}
