import {styled} from '@esgi/ui/theme';
import {FirstRowColumnBox} from '../first-row-column-box';
import {animated} from 'react-spring';

export const Container = styled(FirstRowColumnBox, {
	width: '100%',
	position: 'relative',
});

export const PointerBox = styled(animated.div, {
	position: 'relative',
	borderRadius: '50%',
	borderStyle: 'solid',
});
