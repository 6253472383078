import React, {Dispatch, useMemo} from 'react';
import {CardBody} from '../components/card-body';
import {CardHeader} from '../components/card-header';
import {CardRoot} from '../components/card-root';
import {AverageProgress, LastSessionInfo} from '../../../../progress-bars';
import {useAnalyticsDataItems} from '../../../../../hooks';
import {EntityType, TestSingleStudentModel} from '../../../../../../../types';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {TestSectionSingleStudent} from '../../../../../types';

type Props = TestSingleStudentModel & {
	onTestCardClicked: Dispatch<TestSingleStudentModel>;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	openTestDetails: VoidFunction;
	isSmallScreen: boolean;
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	withSelfAssess: boolean;
	subjectID: SubjectTab['id'];
	classID: Class['id'];
	statisticEntity: TestSectionSingleStudent['statisticEntity'];
	onStartSelfAssessTestHandler: VoidFunction;
	onStartTestHandler: VoidFunction;
};

export function SingleStudentTestCard({
	onTestCardClicked,
	userCanEditTest,
	userCanRemoveTest,
	openTestDetails,
	isSmallScreen,
	onTestMoveTo,
	onRemoveTest,
	withSelfAssess,
	testInfo,
	analyticsData,
	studentResult,
	studentsResult,
	lastTestSessions,
	subjectID,
	classID,
	statisticEntity,
	onStartSelfAssessTestHandler,
	onStartTestHandler,
}: Props) {
	const {analyticsDataItems} = useAnalyticsDataItems({testInfo, analyticsData});

	const statisticLabel = statisticEntity === 'group' ? 'Group' : 'Class';

	const progressBarsItems = useMemo<React.JSX.Element[]>(
		() => [
			<LastSessionInfo
				value={studentResult?.correctAnswers ?? null}
				maxValue={testInfo.totalPossible}
				testType={testInfo.type}
				rubricAnswers={studentResult?.lastTestSessionRubricAnswers ?? []}
				rubricCriteria={testInfo?.rubricCriteria}
				deltaIndicatorValue={studentResult?.delta ?? null}
				result={studentResult?.result ?? null}
				isSmallScreen={isSmallScreen}
			/>,
			<AverageProgress
				value={studentsResult?.result ?? null}
				maxValue={testInfo.totalPossible}
				testType={testInfo.type}
				deltaIndicatorValue={studentsResult?.delta ?? null}
				result={!studentsResult?.studentsTested ? null : studentsResult.result}
				label={`${statisticLabel} Average`}
				onHoverText={`Difference between ${statisticLabel.toLocaleLowerCase()} and student performance`}
			/>,
		],
		[studentsResult, isSmallScreen, statisticLabel, studentResult, testInfo],
	);

	return (
		<CardRoot
			onClick={() => onTestCardClicked({testInfo, analyticsData, studentResult, studentsResult, lastTestSessions})}
			dataCy='test-card'
		>
			{testInfo && (
				<CardHeader
					withSelfAssess={withSelfAssess}
					withStartTestButton
					testInfo={testInfo}
					onStartTestClicked={onStartTestHandler}
					onSelfAssessStartTestClicked={onStartSelfAssessTestHandler}
					lastTestSessions={lastTestSessions}
					sessionsCount={analyticsData?.sessionCount ?? 0}
					userCanEditTest={userCanEditTest}
					userCanRemoveTest={userCanRemoveTest}
					openTestDetails={openTestDetails}
					onTestMoveTo={onTestMoveTo}
					onRemoveTest={onRemoveTest}
					isSmallScreen={isSmallScreen}
					entityType={EntityType.Student}
					classID={classID}
					subjectID={subjectID}
				/>
			)}
			<CardBody
				testInfo={testInfo}
				progressBars={progressBarsItems}
				textWithTranscriptItems={analyticsDataItems}
				isSmallScreen={isSmallScreen}
				lastTestSessions={lastTestSessions}
				sessionsCount={analyticsData?.sessionCount ?? 0}
				withStartTestButton
				onStartTestClicked={onStartTestHandler}
				onSelfAssessStartTestClicked={onStartSelfAssessTestHandler}
				openTestDetails={openTestDetails}
				userCanEditTest={userCanEditTest}
				userCanRemoveTest={userCanRemoveTest}
				onTestMoveTo={onTestMoveTo}
				onRemoveTest={onRemoveTest}
				entityType={EntityType.Student}
				classID={classID}
				subjectID={subjectID}
				withSelfAssess={testInfo.selfAssessmentTestAllowed}
			/>
		</CardRoot>
	);
}
