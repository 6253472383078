import * as React from 'react';
import type {SVGProps} from 'react';

export function FolderRemove(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path d='M6 8C6 6.89533 6.89533 6 8 6H10.031C11.1943 6 11.6997 6.50333 12.3017 7.708C12.4732 8.05137 12.6015 8.27693 12.7453 8.52971C12.8189 8.65912 12.8966 8.79568 12.9862 8.95882C13.246 9.43187 12.8937 10 12.354 10L9.26461 10C8.89594 10 8.59794 9.70133 8.59794 9.33333C8.59794 8.96533 8.89594 8.66667 9.26461 8.66667H11.3017L11.2626 8.60043C11.2029 8.49955 11.1659 8.43695 11.0937 8.292C10.6963 7.49667 10.5343 7.33333 10.031 7.33333H8C7.632 7.33333 7.33333 7.632 7.33333 8V15.3333C7.33333 16.07 7.93 16.6667 8.66667 16.6667H15.3333C16.07 16.6667 16.6667 16.07 16.6667 15.3333V14.5417C16.6667 14.1735 16.9651 13.875 17.3333 13.875C17.7015 13.875 18 14.1735 18 14.5417V15.3333C18 16.806 16.806 18 15.3333 18H8.66667C7.194 18 6 16.806 6 15.3333V8Z' fill='#F50023'/>
			<path d='M15.4688 11.2312C15.2053 10.9677 15.2053 10.5401 15.4688 10.2766L16.3703 9.37505L15.4688 8.47348C15.2053 8.21002 15.2053 7.78236 15.4688 7.51889C15.7322 7.25542 16.1599 7.25542 16.4234 7.51889L17.3249 8.42045L18.2265 7.51889C18.49 7.25542 18.9176 7.25542 19.1811 7.51889C19.4446 7.78236 19.4446 8.21002 19.1811 8.47348L18.2795 9.37505L19.1811 10.2766C19.4446 10.5401 19.4446 10.9677 19.1811 11.2312C18.9176 11.4947 18.49 11.4947 18.2265 11.2312L17.3249 10.3296L16.4234 11.2312C16.1599 11.4947 15.7322 11.4947 15.4688 11.2312Z' fill='#F50023'/>
		</svg>
	);
}