import {CollapsiblePanel, Folder, FolderType} from '@esgi/main/features/admins/data';
import {useFlag} from '@esgi/ui';
import {Folder as FolderIcon} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {CollapsiblePanelHeader, TitleBox} from './index.styled';
import {RunReportButton} from '../run-report-button';
import {ReportDialog, Reports} from '../reports-dialog';
import {FolderReportModel} from './types';
import {additionalFolderData} from './constants';
import {NoStudentsAlert} from '../no-students-alert';
import {useCallback} from 'react';

type Props = {
	folderID: Folder['id'];
	folderName: string;
	folderType: FolderType;
	isShowPanel: boolean;
	reports: FolderReportModel[];
	studentsCountInFolder: number;
	rowsCountInFolder: number;
};

export function FolderNoSelectedItemsPanel({
	folderID,
	folderName,
	isShowPanel,
	folderType,
	reports,
	studentsCountInFolder,
	rowsCountInFolder,
}: Props) {
	const managerRef = CollapsiblePanel.useRef();

	const [showFolderReports, openFolderReports, closeFolderReports] = useFlag();
	const [showNoStudentsAlert, openNoStudentsAlert, closeNoStudentsAlert] = useFlag();

	const handleRunReportButton = useCallback(() => {
		if (!studentsCountInFolder) {
			openNoStudentsAlert();
			return;
		}

		openFolderReports();
	}, [openFolderReports, openNoStudentsAlert, studentsCountInFolder]);

	if (!isShowPanel) {
		return null;
	}

	const {hierarchyKey, title, noStudentsSinglePrefix, noStudentsMultiplePrefix} = additionalFolderData[folderType];

	return (
		<>
			<CollapsiblePanel.Root managerRef={managerRef}>
				<CollapsiblePanel.QuickPanel>
					<CollapsiblePanelHeader>
						<TitleBox>
							<FolderIcon />
							<Text size='large' color='highContrast'>
								{folderName}
							</Text>
						</TitleBox>
						<RunReportButton title={title} onClick={handleRunReportButton} />
					</CollapsiblePanelHeader>
				</CollapsiblePanel.QuickPanel>
			</CollapsiblePanel.Root>

			{showNoStudentsAlert && (
				<NoStudentsAlert
					onClose={closeNoStudentsAlert}
					prefix={rowsCountInFolder === 1 ? noStudentsSinglePrefix : noStudentsMultiplePrefix}
				/>
			)}

			{showFolderReports && (
				<ReportDialog onClose={closeFolderReports} header={folderName}>
					<ReportDialog.Section title={title}>
						{reports.map(({type, name, description}, index) => {
							const ReportComponent = Reports[type];

							return (
								<ReportComponent
									hierarchy={{
										[hierarchyKey]: folderID,
									}}
									name={name}
									description={description}
									key={`${type}-${index}`}
								/>
							);
						})}
					</ReportDialog.Section>
				</ReportDialog>
			)}
		</>
	);
}
