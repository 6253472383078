import {ColDef} from 'ag-grid-community';
import {useMemo} from 'react';
import {FiltersData, RowDef} from './types';
import {EntitiesFilter} from '@esgi/ui/ag-grid';
import {getStudentsTableColumnDef} from '@esgi/main/kits/admin';
import {useUser} from '@esgi/core/authentication';

export function useColumnDefinitions(filtersData: FiltersData) {
	const currentUser = useUser();

	return useMemo<ColDef<RowDef>[]>(() => {
		const baseColumns: ColDef<RowDef>[] = [
			getStudentsTableColumnDef.firstName({
				currentUser,
				lockVisible: true,
				flex: 2,
				headerComponentParams: {
					description: 'Displays the first name of a student (Required)',
				},
			}),
			getStudentsTableColumnDef.lastName({
				currentUser,
				lockVisible: true,
				flex: 2,
				headerComponentParams: {
					description: 'Displays the last name of a student (Required)',
				},
			}),
			{
				field: 'school',
				headerName: 'School',
				filter: EntitiesFilter,
				filterParams: {
					entities: filtersData.availableSchools,
				},
				flex: 1,
				headerComponentParams: {
					description: `Displays a school's name`,
				},
				sortable: true,
			},			{
				field: 'teacher',
				headerName: 'Teacher',
				filter: EntitiesFilter,
				filterParams: {
					entities: filtersData.availableTeachers,
					emptyEntityName: 'Show Unassigned',
				},
				flex: 1,
				headerComponentParams: {
					description: 'Displays the name of a teacher to whom a student is assigned',
				},
				sortable: true,
			},
			{
				field: 'class',
				headerName: 'Class',
				filter: EntitiesFilter,
				filterParams: {
					entities: filtersData.availableClasses,
				},
				headerComponentParams: {
					description: 'Displays the name of any classes to which a student is assigned',
				},
				flex: 1,
				sortable: true,
			},
			{
				headerName: 'Groups',
				field: 'group',
				filter: EntitiesFilter,
				filterParams: {
					entities: filtersData.availableGroups,
				},
				headerComponentParams: {
					description: 'Displays the name of any groups to which a student is assigned',
				},
				flex: 1,
				sortable: true,
			},
		];

		if (filtersData.availableSpecialists.length > 0) {
			baseColumns.push({
				headerName: 'Specialists',
				field: 'specialist',
				filter: EntitiesFilter,
				filterParams: {
					entities: filtersData.availableSpecialists,
				},
				headerComponentParams: {
					description: 'Displays the specialists names.',
				},
				flex: 1,
				sortable: true,
			});

			baseColumns.push({
				headerName: 'Specialist Groups',
				field: 'specialistGroups',
				filter: EntitiesFilter,
				filterParams: {
					entities: filtersData.availableSpecialistGroups,
				},
				headerComponentParams: {
					description: 'Displays the specialist groups names.',
				},
				flex: 1,
				sortable: true,
			});
		}
		return baseColumns;
	}, [currentUser, filtersData]);
}
