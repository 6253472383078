import {SchoolPerformanceWidgetOptions} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/school-performance-widget-options';
import {SchoolDemographicPerformanceWidgetOptions} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/school-demographic-performance-widget-options';
import {SchoolStudentsNeedingSupportWidgetOptions} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/school-students-needing-support-widget-options';

export {SchoolStudentsNeedingSupportWidgetViewType as SchoolStudentsNeedingSupportWidgetViewTypeNumerable} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/school-students-needing-support-widget-view-type';

export {type SchoolPerformanceWidgetOptions};
export {type SchoolDemographicPerformanceWidgetOptions};
export {type SchoolStudentsNeedingSupportWidgetOptions};

export enum SchoolDashboardWidgetType {
	SchoolPerformance = 'SchoolPerformance',
	SchoolDemographicPerformance = 'SchoolDemographicPerformance',
	SchoolStudentsNeedingSupport = 'SchoolStudentsNeedingSupport',
}

export type AddWidgetEventOptions = {
	schoolPerformanceWidgetOptions: SchoolPerformanceWidgetOptions | null;
	schoolDemographicPerformanceWidgetOptions: SchoolDemographicPerformanceWidgetOptions | null;
	schoolStudentsNeedingSupportWidgetOptions: SchoolStudentsNeedingSupportWidgetOptions | null;
};
