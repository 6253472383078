import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {RootContainer} from '../common.styled';

export const Row = styled(FlexBox, {
	width: '100%',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: 8,
});

export const Column = styled(FlexBox, {
	flexDirection: 'column',
	width: '100%',
	gap: 8,
});

export const InputLabel = styled(Text, {
	paddingLeft: 12,

	variants: {
		clickable: {
			true: {
				cursor: 'pointer',
				color: '$primary',
				textDecoration: 'underline',
			},
		},
		muted: {
			true: {
				color: '$neutral80',
			},
		},
	},
});

export const RootContainerMini = styled(RootContainer, {
	width: 235,
});