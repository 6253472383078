import {useMemo} from 'react';
import {SelectedEntityInfo, SelectedEntityInfoItem} from '@esgi/main/features/admins/dashboard';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';

interface SubjectItem {
	id: number;
	name: string;
}

type Props = {
	subjectIDs: number[];
	subjects: SubjectItem[];
	itemsTranscript?: string;
};

export function SelectedSubjectsInfo({subjectIDs, subjects, itemsTranscript = 'Subjects'}: Props) {
	const subjectsItems = useMemo(() => {
		return subjectIDs
			.map<SelectedEntityInfoItem<SubjectItem['id']> | null>((subjectID) => {
				const subject = subjects.find(({id}) => id === subjectID);

				if (isUndefined(subject)) {
					return null;
				}

				return {
					label: subject.name,
					value: subject.id,
				};
			})
			.filter((item): item is SelectedEntityInfoItem<SubjectItem['id']> => !isNull(item));
	}, [subjects, subjectIDs]);

	return (
		<SelectedEntityInfo
			items={subjectsItems}
			itemsTranscript={itemsTranscript}
			totalPossibleItemsCount={subjects.length}
		/>
	);
}
