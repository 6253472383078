import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const LabelBox = styled(FlexBox, {
	paddingTop: 6,
	paddingBottom: 6,
	paddingRight: 12,
	paddingLeft: 12,
	borderRadius: 12,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$mild',
	backgroundColor: '$background',
	alignItems: 'center',
	textWrap: 'nowrap',
	overflow: 'hidden',
});

export const MultipleLabelBox = styled(GridBox, {
	gap: '6px',
	borderRadius: 12,
	paddingLeft: 12,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$mild',
	backgroundColor: '$background',
	alignItems: 'center',
	gridAutoFlow: 'column',
	textWrap: 'nowrap',
	width: 'fit-content',
});

export const StandardsCounter = styled(GridBox, {
	borderLeft: '1px solid $border',
	borderRadius: '0px 12px 12px 0px',
	cursor: 'pointer',
	paddingTop: 6,
	paddingBottom: 6,
	paddingLeft: 6,
	paddingRight: 12,
	backgroundColor: '$vivid',
});
