import {DragDropContext, DropResult, Droppable, Draggable} from 'react-beautiful-dnd';
import {Dispatch, useCallback, useId} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {TestPanelTestModel} from '../../types/tests';
import {TestInfoLine} from './test-info-item';
import {listGroupCSS, listItemCSS} from './list.styled';
import {DragDrop} from '@esgi/ui/icons';
import {Button} from '@esgi/ui';

type Props = {
	tests: TestPanelTestModel[];
	onDragEnd: Dispatch<TestPanelTestModel[]>;
};

export function Rearrange({tests, onDragEnd}: Props) {
	const droppableId = useId();

	const handleDragEnd = useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return;
			}

			const items = [...tests];

			const [removed] = items.splice(result.source.index, 1);

			if (removed) {
				items.splice(result.destination.index, 0, removed);
			}

			onDragEnd(items);
		},
		[tests, onDragEnd],
	);

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId={droppableId}>
				{(provided) => (
					<GridBox ref={provided.innerRef} {...provided.droppableProps} css={listGroupCSS}>
						{tests.map(({id, name, type, color, contentArea}, index) => (
							<Draggable draggableId={String(id)} index={index} key={String(id)}>
								{(provided) => (
									<GridBox
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										css={listItemCSS}
									>
										<TestInfoLine testColor={color} testContentArea={contentArea} testName={name} testType={type} />

										<Button.Icon withBackgroundHover>
											<DragDrop />
										</Button.Icon>
									</GridBox>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</GridBox>
				)}
			</Droppable>
		</DragDropContext>
	);
}
