import {Text} from '@esgi/ui/typography';
import {Option} from './index.styled';
import {isUndefined} from '@esgi/ui';

type Props = {
	value: string;
	label: string;
	additionalLabel?: string;
	hidden?: boolean;
	disabled?: boolean;
};

export function SelectOption({value, label, additionalLabel, hidden, disabled}: Props) {
	const withAdditionalLabel = !isUndefined(additionalLabel);

	return (
		<Option
			value={value}
			withAdditionalLabel={withAdditionalLabel}
			hidden={Boolean(hidden)}
			disabled={Boolean(disabled)}
		>
			<Text size='medium' bold color='currentColor'>
				{label}
			</Text>

			{withAdditionalLabel && (
				<Text size='small' color='mediumContrast' data-additional-option-text=''>
					{additionalLabel}
				</Text>
			)}
		</Option>
	);
}
