import {Edit, Expand} from '@esgi/ui/icons';
import {
	ActionPanel,
	DeleteButton,
	RunReportButton,
	SingleSelectedExpandablePanel,
	useEntityDeletionState,
} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button, useFlag} from '@esgi/ui';
import {Dispatch, useCallback, useMemo} from 'react';
import {
	AdditionalHeaderContent,
	AdditionalInfo,
	AvatarRoot,
	HeaderContent,
	IconButtonsBox,
	StudentInfoBox,
} from './index.styled';
import {Avatar} from '@esgi/ui/avatar';
import {useUser} from '@esgi/core/authentication';
import {getFullName, getInitials, OneLinedText} from '@esgi/main/kits/common';
import {TitleWithTranscription} from '../title-with-transcription';
import {ReportsFeature} from '../reports-dialog';
import {isNull, isUndefined} from 'underscore';
import {useRemoveStudentInStore} from '@esgi/main/features/school-admin/utils';
import {dispatchAppEvent} from '@esgillc/events';
import {EntityType, RemoveEntity} from '@esgi/main/features/school-admin/data';
import {RefreshDataPanelEvent} from '../../../../../../../layout/events';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
	onEditClicked: Dispatch<number>;
};

export function QuickPanel({selectedRow, isPanelExpanded, onEditClicked}: Props) {
	const currentUser = useUser();
	const [showReports, openReports, closeReports] = useFlag();

	const {entityIDs, showRemoveEntity, onOpenRemoveEntity, onCloseRemoveEntity} = useEntityDeletionState({
		selectedItems: [selectedRow],
	});

	const removeStudentInStore = useRemoveStudentInStore();

	const reportDisableReason = useMemo(() => {
		if (!selectedRow.teacher && !selectedRow.specialist.length) {
			return 'The student is not assigned to any user';
		}

		return null;
	}, [selectedRow]);

	const handleEditClick = useCallback(() => {
		onEditClicked(selectedRow.id);
	}, [onEditClicked, selectedRow]);

	const handleEntityRemove = useCallback(
		(entityIDs: number[]) => {
			if (entityIDs.length >= 2) {
				throw new Error('entityIDs.length >= 2');
			}

			const removedStudentID = entityIDs[0];

			if (!isUndefined(removedStudentID)) {
				removeStudentInStore(removedStudentID, () => {
					dispatchAppEvent(RefreshDataPanelEvent, new RefreshDataPanelEvent());
				});
			}

			onCloseRemoveEntity();
		},
		[onCloseRemoveEntity, removeStudentInStore],
	);

	const studentInitials = useMemo(() => {
		return getInitials({
			firstName: selectedRow.firstName,
			lastName: selectedRow.lastName,
		});
	}, [selectedRow]);

	const studentFullName = useMemo(() => {
		return getFullName({
			firstName: selectedRow.firstName,
			lastName: selectedRow.lastName,
		});
	}, [selectedRow]);

	const teacherFullName = useMemo(() => {
		if (!selectedRow.teacher) {
			return '-';
		}
		return getFullName({
			firstName: selectedRow.teacher.firstName,
			lastName: selectedRow.teacher.lastName,
		});
	}, [selectedRow]);

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<StudentInfoBox>
							<AvatarRoot size='sm'>
								<Avatar.Image src={undefined} />
								<Avatar.Fallback>
									<Text color='primary' size='large'>
										{studentInitials}
									</Text>
								</Avatar.Fallback>
							</AvatarRoot>
							<OneLinedText size='large' color='highContrast'>
								{studentFullName}
							</OneLinedText>

							<AdditionalInfo>
								<TitleWithTranscription title='Teacher' transcript={teacherFullName} />
							</AdditionalInfo>
						</StudentInfoBox>

						<ActionPanel.ActionButtonsBox>
							<IconButtonsBox>
								<DeleteButton
									whenShowTooltip='disabled'
									tooltipMessage='To delete a student, please contact our Customer Support team at support@esgisoftware.com.'
									disabled={!currentUser?.canEditStudents}
									onClick={onOpenRemoveEntity}
									withBackgroundHover
								/>
								<ActionPanel.ActionButtonDivider />

								<Button.Icon withBackgroundHover onClick={handleEditClick}>
									<Edit />
								</Button.Icon>
							</IconButtonsBox>

							<RunReportButton onClick={openReports} disabledMessage={reportDisableReason} />
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<SingleSelectedExpandablePanel.OpenPanelButton
							Icon={Expand}
							transcript='View Student’s Tests'
							disabled={isNull(selectedRow.teacher) && !selectedRow.specialist.length}
						/>
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showRemoveEntity && (
				<RemoveEntity
					entityIDs={entityIDs}
					entityType={EntityType.Student}
					onEntityRemoved={handleEntityRemove}
					onClose={onCloseRemoveEntity}
				/>
			)}

			{showReports && <ReportsFeature onClose={closeReports} student={selectedRow} />}
		</>
	);
}
