import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useEffect, useMemo} from 'react';
import {mapToEnum} from 'shared/utils';
import {
	ByAllStudentsTest,
	ByAllStudentsTestPanelData,
	ByAllStudentsTestResultContract,
	SubjectTypeNumerable,
} from '../../types/test-panel';
import {CardsSortBy, Student} from '../../types/section';
import {isNull} from 'underscore';
import {sortTestCards} from '../../utils/sort-test-cards';
import {sortTestsCardsConfig} from './constants';
import {TestContentArea} from '@esgi/main/kits/common';
import {UserType, useUser} from '@esgi/core/authentication';
import {mapTestTypeNumerableToString} from '../../utils/map-test-type-numerable-to-string';

type Parameters = {
	canRunRequest: boolean;
	students: Student[];
	selectedSubject: SubjectModel | null;
	isShowThisData: boolean;
	activeCardsSortBy: CardsSortBy;
};

export function useByAllStudentsTestsCards({
	canRunRequest,
	students,
	selectedSubject,
	isShowThisData,
	activeCardsSortBy,
}: Parameters) {
	const currentUser = useUser();

	const api = useEsgiApiGateway();

	const request = useMemo(() => {
		if (currentUser.userType === UserType.C) {
			return api.v2.schoolAdmins.modules.testResults.byAllStudents;
		}

		if (currentUser.userType === UserType.D) {
			return api.v2.districtAdmins.modules.testResults.byAllStudents;
		}

		throw new Error('There is no matching user specified to proceed with the testResults request');
	}, [api, currentUser]);

	const [data, fetchData] = useCancelableRequest(request);

	useEffect(() => {
		if (!canRunRequest || isNull(selectedSubject) || !isShowThisData) {
			return;
		}

		fetchData({
			studentIDs: students.map(({id}) => id),
			subjectID: selectedSubject.id,
			subjectType: mapToEnum(selectedSubject.type, SubjectTypeNumerable),
		});
	}, [canRunRequest, fetchData, isShowThisData, selectedSubject, students]);

	return useMemo<ByAllStudentsTestPanelData | null>(() => {
		if (!isShowThisData) {
			return null;
		}

		const isDataLoaded = isAsyncSucceed(data);

		const testsCards = sortTestCards({
			testCards: isDataLoaded
				? data.data.value.tests
						.map<ByAllStudentsTest>(({contentArea, type, result, ...restData}) => ({
							contentArea: contentArea as TestContentArea,
							result: result as ByAllStudentsTestResultContract | null,
							type: mapTestTypeNumerableToString(type),
							...restData,
						}))
						.filter(({result}) => !isNull(result))
				: [],
			activeSorting: activeCardsSortBy,
			config: sortTestsCardsConfig,
		});

		return {
			skeleton: !isDataLoaded,
			testsCards,
		};
	}, [activeCardsSortBy, data, isShowThisData]);
}
