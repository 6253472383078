import {useEffect, useMemo} from 'react';
import {isNull, unique} from 'underscore';
import {RowDef} from './types';
import {
	useSchools,
	useDistrictSpecialists,
	useSchoolSpecialists,
	usePreAssessSpecialists,
	School,
} from '@esgi/main/libs/admin-store';
import {useDisposable} from '@esgi/core/service';
import {V2DistrictAdminsPagesDataAnalyticsController} from '@esgi/contracts/esgi';
import {isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';
import {userTypeOptions} from './constants';
import {SpecialistType} from '@esgi/main/features/admins/data';

type Out = {
	ready: boolean;
	rows: RowDef[];
	schools: School[];
};

export function useData(): Out {
	const analyticController = useDisposable(V2DistrictAdminsPagesDataAnalyticsController);

	const [{data: districtSpecialists, loaded: isDistrictSpecialistsLoaded}] = useDistrictSpecialists();
	const [{data: schoolSpecialists, loaded: isSchoolSpecialistsLoaded}] = useSchoolSpecialists();
	const [{data: preAssessSpecialists, loaded: isPreAssessSpecialistsLoaded}] = usePreAssessSpecialists();

	const [{data: allSchools, loaded: isAllSchoolLoaded}] = useSchools();

	const [districtSpecialistsData, fetchDistrictSpecialistsData] = useCancelableRequest(
		analyticController.districtSpecialists,
	);
	const [schoolsSpecialistsData, fetchSchoolsSpecialistsData] = useCancelableRequest(
		analyticController.schoolsSpecialists,
	);
	const [preAssessData, fetchPreAssessData] = useCancelableRequest(analyticController.preAssess);

	useEffect(() => {
		if (isDistrictSpecialistsLoaded) {
			fetchDistrictSpecialistsData({
				districtSpecialistsIDs: districtSpecialists.map(({id}) => id),
			});
		}
	}, [districtSpecialists, fetchDistrictSpecialistsData, isDistrictSpecialistsLoaded]);

	useEffect(() => {
		if (isSchoolSpecialistsLoaded) {
			fetchSchoolsSpecialistsData({
				schoolSpecialistsIDs: schoolSpecialists.map(({id}) => id),
			});
		}
	}, [fetchSchoolsSpecialistsData, isSchoolSpecialistsLoaded, schoolSpecialists]);

	useEffect(() => {
		if (isPreAssessSpecialistsLoaded) {
			fetchPreAssessData({
				preAssessIDs: preAssessSpecialists.map(({id}) => id),
			});
		}
	}, [fetchPreAssessData, isPreAssessSpecialistsLoaded, preAssessSpecialists]);

	const {rows, schools} = useMemo<{rows: RowDef[]; schools: School[]}>(() => {
		if (
			!isAllSchoolLoaded ||
			!isAsyncSucceed(districtSpecialistsData) ||
			!isAsyncSucceed(schoolsSpecialistsData) ||
			!isAsyncSucceed(preAssessData)
		) {
			return {
				schools: [],
				rows: [],
			};
		}

		const {specialistsAnalytics: districtSpecialistsAnalytics} = districtSpecialistsData.data.value;
		const {specialistsAnalytics: schoolSpecialistsAnalytics} = schoolsSpecialistsData.data.value;
		const {specialistsAnalytics: preAssessAnalytics} = preAssessData.data.value;

		const districtSpecialistsRows = districtSpecialists
			.map<RowDef | null>(({id, firstName, lastName, userName, email, schoolID}) => {
				const school = allSchools.find(({id}) => id === schoolID);
				const analytics = districtSpecialistsAnalytics.find(({specialistID}) => specialistID === id);

				if (isUndefined(analytics)) {
					return null;
				}

				return {
					id,
					firstName,
					lastName,
					userName,
					email,
					schools: !isUndefined(school) ? [school] : [],
					type: userTypeOptions[SpecialistType.District],
					specialistGroups: analytics.groupsCount,
					students: analytics.studentsCount,
				};
			})
			.filter((item): item is RowDef => !isNull(item));

		const schoolSpecialistsRows = schoolSpecialists
			.map<RowDef | null>(({id, firstName, lastName, userName, email, schoolID}) => {
				const school = allSchools.find(({id}) => id === schoolID);
				const analytics = schoolSpecialistsAnalytics.find(({specialistID}) => specialistID === id);

				if (isUndefined(analytics)) {
					return null;
				}

				return {
					id,
					firstName,
					lastName,
					userName,
					email,
					schools: !isUndefined(school) ? [school] : [],
					type: userTypeOptions[SpecialistType.School],
					specialistGroups: analytics.groupsCount,
					students: analytics.studentsCount,
				};
			})
			.filter((item): item is RowDef => !isNull(item));

		const preAssessRows = preAssessSpecialists
			.map<RowDef | null>(({id, firstName, lastName, userName, email, schoolID}) => {
				const school = allSchools.find(({id}) => id === schoolID);
				const analytics = preAssessAnalytics.find(({specialistID}) => specialistID === id);

				if (isUndefined(analytics)) {
					return null;
				}

				return {
					id,
					firstName,
					lastName,
					userName,
					email,
					schools: !isUndefined(school) ? [school] : [],
					type: userTypeOptions[SpecialistType.PA],
					specialistGroups: analytics.groupsCount,
					students: analytics.studentsCount,
				};
			})
			.filter((item): item is RowDef => !isNull(item));

		const rows: RowDef[] = [...districtSpecialistsRows, ...schoolSpecialistsRows, ...preAssessRows];

		return {
			rows,
			schools: unique(rows.map(({schools}) => schools).flat(), ({id}) => id),
		};
	}, [
		allSchools,
		districtSpecialists,
		districtSpecialistsData,
		isAllSchoolLoaded,
		schoolSpecialists,
		schoolsSpecialistsData,
		preAssessSpecialists,
		preAssessData,
	]);

	return {
		ready:
			isDistrictSpecialistsLoaded &&
			isSchoolSpecialistsLoaded &&
			isPreAssessSpecialistsLoaded &&
			isAllSchoolLoaded &&
			isAsyncSucceed(districtSpecialistsData) &&
			isAsyncSucceed(schoolsSpecialistsData) &&
			isAsyncSucceed(preAssessData),
		rows,
		schools,
	};
}
