import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';

type Props = {
	description: string;
	onClose: VoidFunction;
};

export function WarningAlert({description, onClose}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	return (
		<Alert modalManagerRef={alertRef}>
			<Alert.Header withBacklight={false} onCloseIconClick={closeAlert}>
				<Text size='small'>Warning</Text>
			</Alert.Header>

			<Alert.Body>
				<Text size='medium' color='base'>
					{description}
				</Text>
			</Alert.Body>

			<Alert.Footer>
				<Button color='secondary' onClick={closeAlert}>
					<Text size='medium' bold color='base'>
						Ok
					</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
