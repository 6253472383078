import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';

type Props = {
	onClose: VoidFunction,
	prefix: string,
};

export function NoStudentsAlert(props: Props) {
	const ref = Alert.useRef();
	const close = Alert.useClose(ref, props.onClose);

	return (
		<Alert modalManagerRef={ref}>
			<Alert.Header onCloseIconClick={close} withBacklight={false}>
				<Text size='small' color='highContrast'>
					No students
				</Text>
			</Alert.Header>
			<Alert.Body>
				<Text size='small' color='highContrast'>
					No students have been added to {props.prefix}. Please add some before running the report.
				</Text>
			</Alert.Body>
			<Alert.Footer>
				<Button onClick={close}>
					<Text size='medium'>
						OK
					</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}