import {useMemo} from 'react';
import {SchoolStudentsNeedingSupportClassData} from '../../../types/students-needing-support';
import {ClassesData} from '../types';
import {roundNumber} from '../utils/round-number';

export function useClassesItems({
	classesData,
	getClassName,
	getTeacherName,
}: {
	classesData: SchoolStudentsNeedingSupportClassData[];
	getClassName: (classID: number) => string;
	getTeacherName: (classID: number) => string;
}) {
	return useMemo<ClassesData[]>(() => {
		return classesData.map(({classID, totalStudents, lowestAchievementStudents, result}) => ({
			rowID: classID,
			class: getClassName(classID),
			teacher: getTeacherName(classID),
			totalStudents,
			lowestAchievementStudents,
			result: roundNumber(result),
		}));
	}, [classesData, getTeacherName, getClassName]);
}
