import * as $ from 'jquery';
import {AuthenticatorResult, LoginResponse} from './types';
import {ValidationHandler} from './validation/types';
import {AlertHandler} from './validation/alert-handler';
import {RedirectHandler} from './validation/redirect-handler';
import {NoneHandler} from './validation/none-handler';
import {ExpirationHandler} from './validation/expiration-handler';
import {userStorage} from '../current-user/user-storage';
import {StudentSort, UserCredentialStatus, UserCredentialType, UserInfo} from '../current-user/types';
import {UserType} from '../enum';

function removeFromStorage(key: string): void {
	localStorage.removeItem(key);
}

/* The `Authenticator` class is responsible for authenticating a user and storing their information in
local storage. */
export class Authenticator {
	private handlers: {[k: string]: ValidationHandler} = {
		'None': NoneHandler,
		'AdminExpired': ExpirationHandler,
		'Alert': AlertHandler,
		'Redirect': RedirectHandler,
	};

	/**
	 * Authenticate a user and store their information in local storage.
	 * @param {LoginResponse} response - The parameter `response` is of type `LoginResponse` and contains information
	 * about the login response. It includes properties such as `validation`, `user`, `awsBucket`, and
	 * others.
	 * @param {boolean} [isLoggedByCredentials] - The parameter `isLoggedByCredentials` is a boolean value
	 * that indicates whether the user is logged in using their credentials (e.g., username and password).
	 * It is an optional parameter and defaults to `undefined`.
	 * @returns a Promise that resolves to an object of type AuthenticatorResult.
	 */
	public async authenticate(response: LoginResponse, isLoggedByCredentials?: boolean): Promise<AuthenticatorResult> {
		if (response.validation) {
			if(response.validation.type.toLowerCase() === 'none') {
				return {valid: true, redirect: false};
			}

			const handler = this.handlers[response.validation.type];
			await handler(response);
			return {valid: false, redirect: response.validation.type === 'Redirect'};
		}

		const r = response;

		const ctx: UserInfo = {
			accountID: r.user.accountID,
			userType: UserType[r.user.type] as unknown as UserType,
			userGuid: r.user.guid,
			userID: r.user.userID,
			globalSchoolYearID: r.user.selectedGlobalSchoolYearID,
			canEditStudents: r.user.canEditStudents,
			canEditStudentMetaFields: r.user.canEditStudentMetaFields,
			canExploreStudents: r.user.canExploreStudents,
			canViewStudents: r.user.canViewStudents,
			agreementLevelCode: r.user.agreementLevelCode,
			canViewAndModifyTeachers: r.user.canViewAndModifyTeachers,
			canAddAndDeleteTeachers: r.user.canAddAndDeleteTeachers,
			canAddEditTestSessions: r.user.canAddEditTestSessions,
			createDate: r.user.createDate,
			districtID: r.user.districtID,
			firstName: r.user.firstName,
			lastGlobalSchoolYearID: r.lastGlobalSchoolYearID,
			currentGlobalSchoolYearID: r.currentGlobalSchoolYearID,
			lastName: r.user.lastName,
			schoolID: r.user.schoolID,
			ssoRegistrationType: r.user.registrationType,
			stateID: r.user.stateID,
			timeZone: r.user.timeZone,
			email: r.user.email,
			isLoggedByCredentials: isLoggedByCredentials,
			showSelfAssessOption: r.user.showSelfAssessOption,
			screenLockPIN: r.user.screenLockPIN,
			lockScreen: r.user.lockScreen,
			awsEnvBucket: r.awsEnvBucket,
			awsPreviewsBucket: r.awsPreviewsBucket,
			canEditStudentLanguage: r.user.canEditStudentLanguage,
			canTest: r.user.canTest,
			canAddStudents: r.user.canAddStudents,
			correctButtonLabel: r.user.correctButtonLabel,
			credentialID: r.user.credentialID,
			userCredentialStatus: UserCredentialStatus[r.user.userCredentialStatus],
			enableShortcuts: r.user.enableShortcuts,
			showNewUIVersion: r.user.showNewUIVersion,
			studentSort: StudentSort[r.user.studentSort],
			userCredentialType: UserCredentialType[r.user.userCredentialType],
			expirationDate: r.user.expirationDate,
			incorrectButtonLabel: r.user.incorrectButtonLabel,
			avatarUrl: r.user.avatarUrl,
			testResultsIncorrectVerbiage: r.user.testResultsIncorrectVerbiage,
			testResultsCorrectVerbiage: r.user.testResultsCorrectVerbiage,
			canManageClasses: r.user.canManageClasses,
		};

		userStorage.set(ctx);

		removeFromStorage('TeModule');
		removeFromStorage('te_filter_state');
		removeFromStorage('firstClassIsSelectedOnLogin');
		removeFromStorage('firstSpecialistGroupIsSelectedOnLogin');
		$(this).trigger('loginSucceeded');
		return {valid: true, redirect: false};
	}
}
