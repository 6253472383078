import {PerformanceData} from '@esgi/main/features/admins/dashboard';

export type WidgetDataModel = {
	delta: number | null;
	id: number;
	label: string;
	data: [PerformanceData] | [PerformanceData, PerformanceData];
};

export type TemporaryWidgetDataModel = Pick<WidgetDataModel, 'label'> & {
	label: string;
	data: [PerformanceData | null] | [PerformanceData | null, PerformanceData | null];
};

export type NonNullableTemporaryWidgetData = Pick<TemporaryWidgetDataModel, 'label'> & {
	data: [PerformanceData] | [PerformanceData, PerformanceData];
};

export enum WidgetSortBy {
	None = 'None',
	MedianPerformance = 'MedianPerformance',
	ConsistencyPerformance = 'ConsistencyPerformance',
	ScoreSpread = 'ScoreSpread',
	Alphabetical = 'Alphabetical',
	Delta = 'Delta',
}
