import {
	ComponentPropsWithoutRef,
	Dispatch,
	ForwardedRef,
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {GridApi, ColDef, ColGroupDef, GridState} from 'ag-grid-community';
import {PageContentContext, PageContentContextValue} from '../../context';
import {isNull} from 'underscore';
import {Content} from './index.styled';
import {TableCustomizationDrawer} from '@esgi/ui/ag-grid';

type Props<T extends Record<string, unknown>> = Omit<ComponentPropsWithoutRef<typeof Content>, 'skeleton'> & {
	columnDefs: (ColDef<T> | ColGroupDef<T>)[];
	tableRows: T[];
	entityName: string;
	isDataLoaded: boolean;
	onApplyTableCustomization?: (updatedColumns: { name: string, isEnabled: boolean }[]) => void;
	selectAll?: boolean;
	onGridReady?: Dispatch<GridApi<T>>;
	initialState?: GridState | null;
};

function RootImpl<T extends Record<string, unknown>>(
	{
		dataCy = 'data-page-content-root',
		css = {},
		children,
		tableRows,
		columnDefs,
		isDataLoaded,
		entityName,
		selectAll,
		onGridReady,
		initialState,
		onApplyTableCustomization,
		...props
	}: Props<T>,
	forwardedRef: ForwardedRef<HTMLDivElement>,
) {
	const [api, setApi] = useState<GridApi<T> | null>(null);

	useEffect(() => {
		if (api) {
			onGridReady?.(api);

			if (initialState) {
				const {columnVisibility} = initialState;
				if (columnVisibility && columnVisibility.hiddenColIds) {
					const columnState = api.getColumnState();
					const updatedColumnState = columnState.map(col => ({
						...col,
						hide: columnVisibility.hiddenColIds.includes(col.colId),
					}));
					api.applyColumnState({state: updatedColumnState});
				}
			}
		}
	}, [api, initialState, onGridReady]);

	const [withSearchValue, setWithSearchValue] = useState(false);
	const [selectedItems, setSelectedItems] = useState<T[]>([]);

	const onSearchValueChanged = useCallback((value: string) => {
		setWithSearchValue(value !== '');
	}, []);

	const modifiedColDefs = useMemo(() => {
		if (withSearchValue) {
			return columnDefs.map((def) => {
				if ('filter' in def && def.filter) {
					return {
						...def,
						filter: false,
					};
				}
				return def;
			});
		}

		return columnDefs;
	}, [columnDefs, withSearchValue]);

	const firstDataRenderedHandler = useCallback(() => {
		if (selectAll) {
			api?.selectAll();
		}
	}, [api, selectAll]);

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		const onSelectHandler = () => setSelectedItems(api.getSelectedRows());

		api.addEventListener('selectionChanged', onSelectHandler);

		return () => {
			api.removeEventListener('selectionChanged', onSelectHandler);
		};
	}, [api]);

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		api.addEventListener('firstDataRendered', firstDataRenderedHandler);

		return () => {
			api.removeEventListener('firstDataRendered', firstDataRenderedHandler);
		};
	}, [firstDataRenderedHandler, api]);

	const context = useMemo<PageContentContextValue<T>>(
		() => ({
			api,
			setApi,
			selectedItems,
			setSelectedItems,
			entityName,
			columnDefs: modifiedColDefs,
			isDataLoaded,
			tableRows,
			onSearchValueChanged,
			initialState: initialState ?? null,
		}),
		[api, entityName, initialState, isDataLoaded, modifiedColDefs, onSearchValueChanged, selectedItems, tableRows],
	);

	return (
		<PageContentContext.Provider value={context}>
			<Content dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{children}
			</Content>

			{!isNull(api) && <TableCustomizationDrawer tableName={entityName} api={api} onApplyTableCustomization={onApplyTableCustomization}/>}
		</PageContentContext.Provider>
	);
}

export const Root = forwardRef(RootImpl) as <T extends Record<string, unknown>>(
	props: Props<T> & {ref?: ForwardedRef<HTMLDivElement>},
) => ReturnType<typeof RootImpl>;
