import {FolderCUDEventData} from '../types';

export class FolderCreatedEvent {
	constructor(public data: FolderCUDEventData) {}
}

export class FolderUpdatedEvent {
	constructor(public data: FolderCUDEventData) {}
}

export class FolderRemovedEvent {
	constructor(public data: FolderCUDEventData) {}
}
