import React, {useMemo} from 'react';
import {Enter} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {Drawer} from '@esgi/main/features/teacher/home';
import {FormWrapper, ScrollableWrapper} from '../../../index.styled';
import {LocationFormType} from '../../../forms/location';
import {StudentProfileService} from '../../../service';
import {StyledTransferButton} from './index.styled';
import {AssignStudent} from '../../assign-student';
import {StudentProfileMode} from '../../../types';
import {FormElement} from '../../form-element';
import {SelectForm} from '../../select-form';

interface LocationProps {
	service: StudentProfileService;
	form: LocationFormType;
	onOpenTransfer?: VoidFunction;
}

export function Location({service, form, onOpenTransfer}: LocationProps) {
	const studentID = useBehaviorSubject(service.studentID);
	const teacher = useBehaviorSubject(service.teacher);
	const schools = useBehaviorSubject(service.schools);
	const teachers = useBehaviorSubject(service.teachers);
	const classes = useBehaviorSubject(service.classes);
	const schoolSpecialists = useBehaviorSubject(service.schoolSpecialists);
	const districtSpecialists = useBehaviorSubject(service.districtSpecialists);
	const mode = useBehaviorSubject(service.mode);

	const isLocationDisabled = useMemo(() => (
		mode !== StudentProfileMode.add
	), [mode]);

	const schoolOptions = useMemo(() => (
		schools?.map((item) => ({
			id: item.id,
			value: item.name,
		}))
	), [schools]);

	const teacherOptions = useMemo(() => (
		teachers?.map((item) => ({
			id: item.userID,
			value: `${item.firstName} ${item.lastName}`,
		}))
	), [teachers]);

	return (
		<ScrollableWrapper>
			<FormWrapper controller={form}>
				<Drawer.ContentBlock title='Location' withDivider>
					<FormElement control={form.controls.schoolID}>
						<SelectForm placeholder='School' options={schoolOptions} disabled={isLocationDisabled} />
					</FormElement>
					<FormElement control={form.controls.teacherID}>
						<SelectForm placeholder='Primary Teacher' options={teacherOptions} disabled={isLocationDisabled} />
					</FormElement>
					{studentID && (
						<StyledTransferButton color='secondary' onClick={onOpenTransfer}>
							<Enter />
							<Text size='medium' bold>
								Transfer To Another Teacher / School
							</Text>
						</StyledTransferButton>
					)}
				</Drawer.ContentBlock>

				<Drawer.ContentBlock title='Assigned To Classes & Groups' withDivider>
					<AssignStudent
						form={form}
						teacher={teacher}
						classes={classes}
						schoolSpecialists={schoolSpecialists}
						districtSpecialists={districtSpecialists}
					/>
				</Drawer.ContentBlock>
			</FormWrapper>
		</ScrollableWrapper>
	);
}
