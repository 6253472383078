import {ComponentProps, forwardRef} from 'react';
import {Box} from '@esgi/ui/layout';
import {useCollapsiblePanelContext} from '../../contexts/inner-context';
import {Container} from './index.styled';

type Props = ComponentProps<typeof Box>;

export const Panel = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'collapsible-panel-panel', css = {}, children, ...props}, forwardedRef) => {
		const {isPanelExpanded} = useCollapsiblePanelContext();

		if (!isPanelExpanded) {
			return null;
		}

		return (
			<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{children}
			</Container>
		);
	},
);
