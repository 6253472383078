import React from 'react';
import {Subject} from 'rxjs';
import {FormControl, FormElement} from '@esgillc/ui-kit/form';
import {Dropdown, Hint, Option} from '@esgillc/ui-kit/form-control';
import {Buttons} from '@esgillc/ui-kit/button';
import {SubjectModel} from '../../../../../../common/types';
import styles from '../confirm.module.less';

interface Props {
	control: FormControl<SubjectModel[]>;
	subjects: SubjectModel[];
	initialValue: SubjectModel[];
	addSubjectClicked: () => void;
	disableNewSubjectBtn?: boolean;
}

export default class SubjectField extends React.PureComponent<Props> {
	private onDestroy$: Subject<void> = new Subject();

	public componentDidMount() {
		this.props.control.value = this.props.initialValue;
	}

	public render() {
		return <div className={styles.inlineField}>
			<div className={styles.subjectLabel}>Add to a Subject Tab?</div>
			<div className={styles.element}>
				<FormElement control={this.props.control} className={styles.dropdown}>
					<Dropdown placeholder='Select a Subject Tab'
					          optionName='name'
					          multiselect
					          className={styles.dropdown}>
						{this.props.subjects.map(s => <Option key={s.id}
						                                      value={s}>{s.name}</Option>)}
					</Dropdown>
					<Hint className={styles.addSubjectTabHint}>
						<Buttons.Link
							disabled={this.props.disableNewSubjectBtn}
							onClick={this.props.addSubjectClicked}
							className={styles.btn}>
							Create new subject tab
						</Buttons.Link>
					</Hint>
				</FormElement>
			</div>
		</div>;
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
