import {useUser} from '@esgi/core/authentication';
import {TestType} from '@esgi/main/kits/common';
import {ThemeColorKeys} from '@esgi/ui';
import {useMemo} from 'react';
import {isNull} from 'underscore';

type Parameters = {
	type: TestType;
	correctAnswersPercentage: number | null;
	showInColor: boolean;
};

type StatModel = {
	value: string;
	transcript: string;
	color: ThemeColorKeys;
};

type Out = {
	correct: StatModel;
	incorrect: StatModel;
	chartColor: ThemeColorKeys;
	testResultsCorrectVerbiage: string;
};

export function useChartData({type, correctAnswersPercentage, showInColor}: Parameters) {
	const currentUser = useUser();

	return useMemo<Out>(() => {
		if (isNull(currentUser)) {
			throw new Error('currentUser is null');
		}

		const isYNTest = type === TestType.YN;

		const roundedCorrectAnswersPercentage = !isNull(correctAnswersPercentage)
			? Math.round(correctAnswersPercentage)
			: null;

		const incorrectAnswersPercentage = !isNull(roundedCorrectAnswersPercentage)
			? 100 - roundedCorrectAnswersPercentage
			: null;

		const isPositiveResult = !isNull(roundedCorrectAnswersPercentage) ? roundedCorrectAnswersPercentage > 50 : false;

		const testResultsCorrectVerbiage = isYNTest ? currentUser.testResultsCorrectVerbiage : 'Correct';

		return {
			correct: {
				value: !isNull(roundedCorrectAnswersPercentage) ? `${roundedCorrectAnswersPercentage}%` : '-',
				transcript: testResultsCorrectVerbiage,
				color: !showInColor ? 'base' : isPositiveResult ? 'positive' : 'base',
			},
			incorrect: {
				value: !isNull(incorrectAnswersPercentage) ? `${incorrectAnswersPercentage}%` : '-',
				transcript: isYNTest ? currentUser.testResultsIncorrectVerbiage : 'Incorrect',
				color: 'highContrast',
			},
			chartColor: !showInColor ? 'lowContrast' : isPositiveResult ? 'positiveVivid' : 'lowContrast',
			testResultsCorrectVerbiage,
		};
	}, [correctAnswersPercentage, currentUser, showInColor, type]);
}
