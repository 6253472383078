import {isUndefined} from '@esgi/ui';
import {ComponentProps, ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback} from 'react';
import {ButtonIcon} from './index.styled';
import {SelfAssess} from '@esgi/ui/icons';

type Props = Omit<ComponentPropsWithoutRef<typeof ButtonIcon>, 'children'> & {
	children?: ComponentProps<typeof ButtonIcon>['children'] | undefined;
};

export const SelfAssessButton = forwardRef<HTMLButtonElement, Props>(
	({dataCy = 'test-card-self-assess-button', css = {}, onClick, disabled, children, ...props}, forwardedRef) => {
		const handleClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				if (!isUndefined(onClick)) {
					event.preventDefault();
					event.stopPropagation();

					onClick(event);
				}
			},
			[onClick],
		);

		return (
			<ButtonIcon
				dataCy={dataCy}
				css={css}
				onClick={handleClick}
				noClicable={isUndefined(onClick)}
				disabled={disabled}
				ref={forwardedRef}
				{...props}
			>
				{isUndefined(children) ? <SelfAssess /> : children}
			</ButtonIcon>
		);
	},
);
