import {Button, ContextMenu as ContextMenuUI, useFlag} from '@esgi/ui';
import {Delete, More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {useCallback, useState} from 'react';
import {DeleteClassAlert} from './delete-class-alert';
import {Class} from '@esgi/main/libs/admin-store';
import {WarningAlert} from '../../../../../warning-alert';

type Props = {
	closeDrawer: VoidFunction;
	className: Class['name'];
	classID: Class['id'];
	teacherClasses: Class[];
};

export function ContextMenu({closeDrawer, className, classID, teacherClasses}: Props) {
	const [showDeleteClassAlert, openDeleteClassAlert, closeDeleteClassAlert] = useFlag();
	const [showImpossibleDeleteAlert, openImpossibleDeleteAlert, closeImpossibleDeleteAlert] = useFlag();

	const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

	const handleOpenDeleteClassAlert = useCallback(() => {
		teacherClasses.length === 1 ? openImpossibleDeleteAlert() : openDeleteClassAlert();

		setIsContextMenuOpen(false);
	}, [openDeleteClassAlert, openImpossibleDeleteAlert, teacherClasses]);

	return (
		<>
			<ContextMenuUI.Root open={isContextMenuOpen} onOpenChange={setIsContextMenuOpen}>
				<ContextMenuUI.Trigger>
					<Button.Icon withBackgroundHover>
						<More />
					</Button.Icon>
				</ContextMenuUI.Trigger>
				<ContextMenuUI.Content>
					<ContextMenuUI.Group>
						<ContextMenuUI.Item onClick={handleOpenDeleteClassAlert}>
							<Delete />
							<Text size='medium'>Delete</Text>
						</ContextMenuUI.Item>
					</ContextMenuUI.Group>
				</ContextMenuUI.Content>
			</ContextMenuUI.Root>

			{showDeleteClassAlert && (
				<DeleteClassAlert
					onClose={closeDeleteClassAlert}
					onDeleted={closeDrawer}
					className={className}
					classID={classID}
				/>
			)}

			{showImpossibleDeleteAlert && (
				<WarningAlert
					description='Sorry, you must have at least one class in each school year.'
					onClose={closeImpossibleDeleteAlert}
				/>
			)}
		</>
	);
}
