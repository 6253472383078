import React from 'react';
import Preloader from 'shared/page/preloader/preloader';
import AppHistory from 'shared/tools/history/history';
import {Api} from './api';
import {Loader} from '@esgi/deprecated/jquery';
import {WindowTool} from '@esgi/deprecated/utils';
import {RemindPassword} from '../../password-reminder/password-reminder';
import {Modal} from '@esgi/deprecated/knockout';
import {afterLoginHandler} from '../../redirect-module/redirect';
import {Authenticator, LoginResponse} from '@esgi/core/authentication';
import './styles.less';
import {HttpClient} from '@esgi/api';

export class State {
	username: string;
	password: string;
	authCode: string;
	registrationType: string;
	loaded: boolean;
	loading: boolean;
	preloaderFinished: boolean = false;
	registrationAllow: boolean;
	promoCode: string;
	isUserHaveAccount: boolean;
}

export enum Flow {
	Cancel,
	CreateNew,
	Renewal,
}

export default class CleverPage extends React.Component<any, State> {
	private api: Api;
	private loader: Loader;
	private latestResponse: LoginResponse;

	constructor(props: any) {
		super(props);

		const longLoadingText = 'Please wait. This request may take a few seconds to load.';

		this.api = new Api();
		this.loader = new Loader('body', {longLoadingText, delay: 0});

		const promoCode = sessionStorage.getItem('esgi-registration-promo-code') || null;
		const registrationDisallow = sessionStorage.getItem('esgi-registration-disallow') === 'true' || false;

		this.state = {
			password: '',
			username: '',
			loaded: false,
			loading: false,
			authCode: WindowTool.getUrlQueryParameter('authCode'),
			registrationType: WindowTool.getUrlQueryParameter('type'),
			preloaderFinished: false,
			registrationAllow: !registrationDisallow,
			promoCode: promoCode,
			isUserHaveAccount: false,
		};
	}

	public get subHeaderText() {
		if (this.state.isUserHaveAccount) {
			return (
				<div className='center extra-text'>
					We detected that your email is already in our system.<br/>
					Login to ESGI to link your account with Clever.
				</div>
			);
		}
		return (
			<div className='center extra-text'>
				If you already have an account,<br/>
				login to ESGI to link your account with Clever.
			</div>
		);
	}

	public componentDidMount() {
		this.api.init(this.state.authCode, this.state.registrationType)
			.subscribe(({
				exist,
				redirectUrl,
				registrationAllow,
				isAlreadyHaveAccount: isUserHaveAccount,
			}) => {
				if (!exist) {
					AppHistory.navigateOutside(redirectUrl);
				} else {
					this.setState({
						loaded: true,
						registrationAllow: registrationAllow && this.state.registrationAllow,
						isUserHaveAccount,
					});
				}
			}, () => this.setState({loaded: true}));
	}

	public render(): JSX.Element | false | null {
		return (
			<>
				{!this.state.preloaderFinished &&
					<Preloader
						loaded={[this.state.loaded]}
						lifted={() => this.setState({preloaderFinished: true})}
					/>
				}
				<div className='login-form-container'>
					<div className='login-form'>
						<div className='center logo-container'>
							<img
								src='//esgiwebfiles.s3.amazonaws.com/images/clever-esgi-logo.png'
								alt='Logo'
							/>
						</div>
						<div className='center header-text'>Welcome to ESGI!</div>
						{this.subHeaderText}
						<div className='form-group'>
							<label className='control-label'>Username</label>
							<input
								type='text'
								tabIndex={1}
								className='form-control large'
								name='username'
								placeholder='Username'
								value={this.state.username}
								onKeyPress={(ch) => this.keyPressed(ch)}
								onChange={(ch) => this.handleInputChanges(ch)}
							/>
						</div>
						<div className='form-group password-container'>
							<label className='control-label'>Password</label>
							<input
								type='password'
								tabIndex={2}
								name='password'
								className='form-control large'
								placeholder='Password'
								value={this.state.password}
								onKeyPress={(ch) => this.keyPressed(ch)}
								onChange={(ch) => this.handleInputChanges(ch)}
							/>
						</div>
						<div className='password-reminder-button'>
							<a
								href='#'
								tabIndex={3}
								onClick={() => this.openPasswordReminder()}
							>
								Forgot username/password?
							</a>
						</div>
						<div className='action-row'>
							<button
								type='button'
								className='btn btn-primary'
								tabIndex={4}
								onClick={() => this.loginClicked()}
							>
								LOG IN
							</button>
						</div>
					</div>
					{this.state.registrationAllow && !this.state.isUserHaveAccount &&
						<div>
							<hr
								className='hr-text'
								data-content='OR'
							/>
							<div className='reg-container'>
								<button
									type='button'
									className='btn btn-primary'
									tabIndex={5}
									onClick={() => this.registrationClicked()}
								>
									CREATE AN ACCOUNT
								</button>
							</div>
						</div>
					}
					<div className='center extra-text mg-t-30'>
						<span>
							{this.state.isUserHaveAccount
								? 'If you have any questions about this account'
								: 'If you have any questions,'
							}&nbsp;please contact<br/>
						</span>
						<a href='mailto:support@esgisoftware.com'>support@esgisoftware.com</a>
						or learn more at
						<a href='https://www.esgisoftware.com/'>esgisoftware.com</a>
					</div>
				</div>
				<div className='back-btn-container'>
					<a href='/sign-up'>
						<div>
							<svg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
								<path d='M8.7525 1.90245L7.425 0.574951L0 7.99995L7.425 15.4249L8.7525 14.0974L2.655 7.99995L8.7525 1.90245Z' fill='#0088CC'/>
							</svg>
						</div>
						<div>
							<span>Back</span>
						</div>
					</a>
				</div>
			</>
		);
	}

	public openPasswordReminder() {
		const remindPassword = new RemindPassword();

		remindPassword.userName('');
		remindPassword.emailAddress('');
		remindPassword.userNameDisabled(false);
		remindPassword.emailAddressDisabled(false);
		remindPassword.submitButtonDisabled(false);
		remindPassword.userNameErrorMessage('');
		remindPassword.showForm(true);
		remindPassword.showSuccessMessage(false);
		remindPassword.showFailMessage(false);

		const modal = new Modal(remindPassword, {
			showHeader: true,
			showFooter: true,
			className: 'white-header',
			buttons: [],
			title: 'Password Reset',
			allowClose: true,
		});

		modal.load();
	}

	public startLoading() {
		this.loader.mask();
		this.setState({loading: true});
	}

	public endLoading() {
		this.loader.unmask();
		this.setState({loading: false});
	}

	public loginClicked() {
		if (this.state.loading) {
			return;
		}

		if (!this.state.password || !this.state.username) {
			return;
		}

		this.startLoading();

		const data = new FormData();
		data.set('userName', this.state.username);
		data.set('password', this.state.password);

		return HttpClient.default.ESGIApi.post<LoginResponse>(
			'Login',
			'Login',
			data,
		)
			.subscribe(async (r) => {
				this.latestResponse = r;

				const authenticator = new Authenticator();
				const authenticatorResult = await authenticator.authenticate(r);

				if (!authenticatorResult.valid) {
					return;
				}

				this.api.link(this.state.authCode)
					.subscribe(() => afterLoginHandler(r.user), () => this.endLoading());
			}, null, () => this.endLoading());
	}

	public keyPressed({charCode}) {
		if (charCode === 13) {
			this.loginClicked();
		}
	}

	public registrationClicked() {
		if (this.state.loading) {
			return;
		}

		this.startLoading();

		this.api.register(
			this.state.authCode,
			this.state.registrationType,
			this.state.promoCode,
		)
			.subscribe((r) => {
				if (r.token) {
					AppHistory.navigateOutside(`/clever-registration?esgi-token=${r.token}`);
				}
			}, () => this.endLoading());
	}

	public handleInputChanges({target}: any) {
		if (target.name === 'username') {
			this.setState({username: target.value});
		} else {
			this.setState({password: target.value});
		}
	}
}
