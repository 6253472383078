import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';
import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {isNull} from 'underscore';
import {useEffect, useMemo} from 'react';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {TestResultsSubject} from '@esgi/main/kits/admin';

type Parameters = {
	userID: number | null;
};

type Out = [boolean, TestResultsSubject[]];

export function useSubjectsByUserID({userID}: Parameters): Out {
	const currentUser = useUser();
	const api = useEsgiApiGateway();

	const [subjectsData, fetchSubjects] = useCancelableRequest(api.v2.districtAdmins.modules.subjects.personal.byUserId);

	const withFetchedData = !isNull(currentUser) && !isNull(userID);

	useEffect(() => {
		if (withFetchedData) {
			fetchSubjects({
				globalSchoolYearID: currentUser.globalSchoolYearID,
				userID,
			});
		}
	}, [currentUser, fetchSubjects, userID, withFetchedData]);

	return useMemo<Out>(() => {
		if (!withFetchedData) {
			return [true, []];
		}

		const isSubjectsLoaded = isAsyncSucceed(subjectsData);

		if (!isSubjectsLoaded) {
			return [false, []];
		}

		const filteredSubjects = subjectsData.data.value.subjects.filter(({isHidden, hasTests}) => !isHidden && hasTests);

		const activeSubjects = filteredSubjects.map<TestResultsSubject>(({id, name}) => ({
			id,
			name,
			level: SubjectLevel.Teacher,
			type: SubjectType.Personal,
			gradeLevels: [],
		}));

		return [true, activeSubjects];
	}, [subjectsData, withFetchedData]);
}
