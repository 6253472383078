import {DatePickerData, isDateTouched} from '@esgi/main/kits/admin';
import {Period} from '../../../../../types';
import {isNull} from 'underscore';
import {Color} from '@esgi/main/features/admins/dashboard';

export function isPeriodRowTouched({
	initialColor,
	currentColor,
	initialDates,
	currentDates,
}: {
	currentColor: Color | null | undefined;
	initialColor: Color | null | undefined;
	initialDates: Period | null;
	currentDates: DatePickerData;
}) {
	const isColorTouched = currentColor !== initialColor;
	const isDateRangeTouched = isDateTouched(
		currentDates,
		isNull(initialDates)
			? null
			: {
					datePeriod: initialDates.datePeriod,
					trackDatesID: initialDates.trackDatesID,
					dateRange: initialDates.dateRange,
				},
	);

	return isColorTouched || isDateRangeTouched;
}
