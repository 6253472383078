import {Box, Card, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardRoot = styled(Card, {
	display: 'grid',
	gap: '$3',

	variants: {
		clicable: {
			true: {
				cursor: 'pointer',
			},
		},
	},
});

export const CardBody = styled(Card.Body, {
	display: 'grid',
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: 24,
});

export const CardFooter = styled(Card.Footer, {
	display: 'grid',
	alignItems: 'center',
	justifyContent: 'space-between',
	gridAutoFlow: 'column',
	gap: 24,
});

export const ActionButtonsDivider = styled(Box, {
	width: 1,
	height: 20,
	backgroundColor: '$border',
	borderRadius: 1,
});

export const DeltaInfoBox = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: 6,
});
