import {Input} from '@esgi/ui/controls';
import {Search} from '@esgi/ui/icons';
import {ChangeEvent, ComponentPropsWithoutRef, Dispatch, forwardRef, useCallback, useState} from 'react';
import {GridApi, IRowNode} from 'ag-grid-community';
import {isUndefined} from '@esgi/ui/utils';
import {isNull} from 'underscore';
import {CellType} from '../types';
import {useRegisterExternalFilter} from '../hooks/use-register-external-filter';

type InputProps = ComponentPropsWithoutRef<typeof Input.Iconable>;

type Props = Omit<InputProps, 'value' | 'onChange' | 'children'> & {
	gridApi: GridApi | null;
	onValueChanged?: Dispatch<string>;
	searchFilterData?: (node: IRowNode) => boolean;
	children?: InputProps['children'] | undefined;
};

export const SearchInput = forwardRef<HTMLInputElement, Props>(
	(
		{
			dataCy = 'ag-grid-search-input',
			css = {},
			gridApi,
			children,
			placeholder = 'Search',
			searchFilterData,
			onValueChanged,
			...props
		},
		forwardedRef,
	) => {
		const [searchValue, setSearchValue] = useState('');

		const updateExternalFilter = useRegisterExternalFilter({gridApi});

		const handleSearchValueChange = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				const value = event.target.value;

				setSearchValue(value);
				onValueChanged?.(value);

				if (isNull(gridApi)) {
					return;
				}

				const doesExternalFilterPass = (node: IRowNode) => {
					if (!isUndefined(searchFilterData)) {
						return searchFilterData(node);
					}

					const columns = gridApi.getColumns();
					const data = node.data;

					if (isNull(columns) || isUndefined(columns) || isUndefined(data)) {
						return false;
					}

					const primaryColumnFields = columns
						.map<string | null>((item) => {
							const colDef = item.getColDef();
							const colDefType = colDef.type;

							return colDefType === CellType.Primary || colDefType?.includes(CellType.Primary)
								? colDef.field ?? null
								: null;
						})
						.filter((item): item is string => !isNull(item));

					const splitValue = primaryColumnFields
						.map((field) => (typeof data[field] === 'string' ? data[field] : null))
						.filter((item) => !isNull(item))
						.join(' ');

					return splitValue.toLocaleLowerCase().includes(value.toLocaleLowerCase());
				};

				updateExternalFilter({
					isExternalFilterPresent: () => value !== '',
					doesExternalFilterPass,
				});
			},
			[gridApi, onValueChanged, searchFilterData, updateExternalFilter],
		);

		return (
			<Input.Iconable
				dataCy={dataCy}
				css={css}
				value={searchValue}
				onChange={handleSearchValueChange}
				placeholder={placeholder}
				ref={forwardedRef}
				{...props}
			>
				{children ?? <Search />}
			</Input.Iconable>
		);
	},
);
