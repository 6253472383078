import {Button} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {Box, GridBox} from '@esgi/ui/layout';
import {Drawer} from '@esgi/main/kits/common';
import {ItemId} from './types';

export const IconWrapper = styled('span', {
	lineHeight: '12px',
	'& > svg': {
		width: 24,
		height: 24,
		fill: '$neutral56',

		'& > path': {
			fill: '$neutral56',
		},
	},
});

const ContentWrapper = styled(GridBox, {
	gridTemplateAreas: `
    "sidebar header"
    "sidebar content"
  `,
	height: '100%',
	width: '100%',
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: 'auto 1fr',
});

export const DrawerPanel = styled(ContentWrapper, {
	variants: {
		layout: {
			[ItemId.Login]: {},
			[ItemId.PersonalInformation]: {},
			[ItemId.District]: {},
			[ItemId.MarkingPeriods]: {
				gridTemplateAreas: `
					"sidebar markingPeriodSidebar header"
					"sidebar markingPeriodSidebar content"
				`,
				gridTemplateColumns: 'auto auto 1fr',
				gridTemplateRows: 'auto 1fr',
			},
		},
	},
});

export const DrawerPanelHeader = styled(Drawer.PanelHeader, {
	height: 73,

	'& [data-panel-header-trigger]': {
		display: 'none',
	},

	'& [data-children-box]': {
		overflow: 'hidden',

		[`& > ${Text}`]: {
			wordBreak: 'break-all',
		},
	},
});

export const PanelHeaderWrapper = styled(Box, {
	display: 'block',
});

export const PanelActionButton = styled(Button, {
	margin: '0 10px',
});