import * as React from 'react';
import type {SVGProps} from 'react';

export function FilterTable(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.76765 5.23235C4.34391 5.23235 4 5.57603 4 6C4 6.42397 4.34391 6.76765 4.76765 6.76765L7.21865 6.76079C7.64239 6.76079 7.9863 6.41712 7.9863 5.99314C7.9863 5.56917 7.64239 5.22549 7.21865 5.22549L4.76765 5.23235Z'
				fill='#7A7A7A'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3.26765 2.5C2.84391 2.5 2.5 2.84368 2.5 3.26765C2.5 3.69162 2.84391 4.0353 3.26765 4.0353L8.73235 4.0353C9.15609 4.0353 9.5 3.69162 9.5 3.26765C9.5 2.84368 9.15609 2.5 8.73235 2.5L3.26765 2.5Z'
				fill='#7A7A7A'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.76765 7.99314C5.34391 7.99314 5 8.33682 5 8.76079C5 9.18476 5.34391 9.52844 5.76765 9.52844H6.23235C6.65609 9.52844 7 9.18476 7 8.76079C7 8.33682 6.65609 7.99314 6.23235 7.99314L5.76765 7.99314Z'
				fill='#7A7A7A'
			/>
		</svg>
	);
}
