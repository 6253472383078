import {Subscription} from 'rxjs';
import {useCallback, useEffect, useState, useRef} from 'react';
import {DataService} from '../service/service';
import {useSorting} from '../components/table/hooks/use-sorting';
import {TestScopeTabId, TestTypeTabId} from '../components/tabs/types';
import {SubjectModel} from '../types';

interface Props {
	service: DataService,
	subject: SubjectModel | null,
}

export function useDrawerState({service, subject}: Props) {
	const [onlySelfAssess, setOnlySelfAssess] = useState(false);
	const [onlySelected, setOnlySelected] = useState(false);

	const [keyword, setKeyword] = useState('');
	const [testType, setTestType] = useState(TestTypeTabId.All);
	const [scope, setScope] = useState(TestScopeTabId.All);
	const [sorting, setSorting] = useSorting();

	const initRequestSubscription = useRef<Subscription | null>(null);

	const toggleSelfAssessChecked = useCallback(() => {
		setOnlySelfAssess((prev) => !prev);
	}, []);

	const toggleOnlySelectedChecked = useCallback(() => {
		setOnlySelected((prev) => !prev);
	}, []);

	const disableOnlySelected = useCallback(() => {
		setOnlySelected(false);
	}, []);

	const onTestTypeChange = useCallback((type: TestTypeTabId) => {
		if (!service.loaded$) {
			return;
		}

		if (type) {
			setTestType(type);
		} else {
			setTestType(TestTypeTabId.All);
		}
	}, [service.loaded$]);

	const onScopeChange = useCallback((scope: TestScopeTabId) => {
		if (!service.loaded$) {
			return;
		}

		if (scope) {
			setScope(scope);
		} else {
			setScope(TestScopeTabId.All);
		}
	}, [service.loaded$]);

	const fetchData = useCallback(() => {
		initRequestSubscription.current?.unsubscribe();

		initRequestSubscription.current = service.fetchData({
			keyword,
			testType,
			sorting,
			scope,
			onlySelfAssess,
			onlySelected,
		});
	}, [service, keyword, onlySelfAssess, onlySelected, scope, sorting, testType]);

	const onNextPage = useCallback(() => {
		service.setNextPage();
		fetchData();
	}, [service, fetchData]);

	const onPreviousPage = useCallback(() => {
		service.setPreviousPage();
		fetchData();
	}, [service, fetchData]);

	useEffect(() => {
		service.setInitPage();
		fetchData();
	}, [service, fetchData]);

	return {
		testType,
		onTestTypeChange,
		scope,
		onScopeChange,
		onlySelfAssess,
		toggleSelfAssessChecked,
		onlySelected,
		toggleOnlySelectedChecked,
		disableOnlySelected,
		keyword,
		setKeyword,
		sorting,
		setSorting,
		onNextPage,
		onPreviousPage,
	};
}
