import {DistrictReportPanel, Page} from '../../../_kit';
import {useDataPageContext} from '../../../layout/context';
import {BottomPanel} from './components/bottom-panel';
import {SingleItemExpandablePanel} from './components/single-item-expandable-panel';
import {RowDef} from '../../types';

type Props = {
	openAddClassDrawer: VoidFunction;
};

export function Base({openAddClassDrawer}: Props) {
	const {districtName} = useDataPageContext();
	const {api} = Page.useContext<RowDef>();

	return (
		<>
			<Page.Header.Base onAddButtonClick={openAddClassDrawer} />
			<Page.Body.Base />

			<SingleItemExpandablePanel />
			<DistrictReportPanel title={districtName} api={api} />
			<BottomPanel />
		</>
	);
}