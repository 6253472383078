export enum UserGroupType {
	None = 'None',
	Teacher = 'Teacher',
	Specialist = 'Specialist',
}

export enum FolderType {
	Teacher = 'Teacher',
	Specialist = 'Specialist',
}

export type Folder = {
	id: number;
	name: string;
	count: number;
	type: FolderType;
};

export type FolderData = {
	ids: number[];
};

export type FoldersData = {
	folders: Folder[];
};

export type FolderCUDEventData = {
	id: number;
	type: FolderType;
};
