import {
	ColDef,
	ColGroupDef,
} from 'ag-grid-community';
import {isArray} from 'underscore';

export function isColDef<T>(target: ColGroupDef<T> | ColDef<T>): target is ColDef<T> {
	return !('children' in target);
}

export function getDisplayName<T extends object>(entity: T): string {
	if('name' in entity && typeof entity.name === 'string') {
		return entity.name;
	}

	if('firstName' in entity && typeof entity.firstName === 'string') {
		if('lastName' in entity && typeof entity.lastName === 'string') {
			return `${entity.firstName} ${entity.lastName}`;
		}
		return entity.firstName;
	}

	if('displayName' in entity && typeof entity.displayName === 'string') {
		return entity.displayName;
	}

	return '';
}

export function formatValue<T>(value: T | T[]): string {
	if (value === null || value === undefined) {
		return '';
	}
	if (typeof value == 'string') {
		return value;
	}

	if (isArray(value)) {
		return value.map(formatValue).join(', ');
	}

	if (typeof value == 'object' && value) {
		return getDisplayName(value);
	}

	return String(value);
}