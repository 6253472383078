import {Card} from '@esgi/ui/layout';
import {ActionButtonsDivider} from './components/action-buttons-divider';
import {TestIndicator} from './components/test-indicator';
import {ContextMenu} from './components/context-menu';
import {Root} from './components/root';
import {SelfAssessButton} from './components/self-assess-button';
import {StartTestButton} from './components/start-test-button';
import {Statistic} from './components/statistics';
import {TestLabel} from './components/test-label';
import {Title} from './components/title';
import {TwoSplitButtonsBox} from './components/two-split-buttons-box';
import {IconBox} from './components/icon-box.styled';
import {AnswersInfo} from './components/answers-info';
import {MultiLinedTitle} from './components/multi-lined-title';

export const TestCard = {
	Root,
	Header: Card.Header,
	Body: Card.Body,
	Footer: Card.Footer,
	Separator: Card.Separator,
	TestIndicator,
	Title,
	MultiLinedTitle,
	TestLabel,
	IconBox,
	AnswersInfo,
	TwoSplitButtonsBox,
	SelfAssessButton,
	StartTestButton,
	ActionButtonsDivider,
	ContextMenu,
	Statistic,
};
