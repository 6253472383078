import {BaseComponentProps} from '@esgi/ui';
import React, {ComponentPropsWithoutRef, ReactNode, forwardRef, useCallback, useMemo, useState} from 'react';
import {RootBox} from './index.styled';
import {PageMode} from '../../types';
import {DashboardPageContext, DashboardPageContextValue} from '../../context';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> &
	BaseComponentProps & {
		children?: ReactNode;
	};

export const PageRoot = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'district-admin-dashboard-page-root', css = {}, children, ...props}, forwardedRef) => {
		const [pageMode, setPageMode] = useState(PageMode.View);

		const setPageViewMode = useCallback(() => {
			setPageMode(PageMode.View);
		}, []);

		const contextValue = useMemo<DashboardPageContextValue>(
			() => ({
				pageMode,
				setPageMode,
				setPageViewMode,
			}),
			[pageMode, setPageViewMode],
		);

		return (
			<DashboardPageContext.Provider value={contextValue}>
				<RootBox dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
					{children}
				</RootBox>
			</DashboardPageContext.Provider>
		);
	},
);
