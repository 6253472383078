import {Text} from '@esgi/ui/typography';
import {DeltaCheap} from '@esgi/main/features/admins/dashboard';
import {isNull} from 'underscore';

type Props = {
	value: number | null;
};

export function PercentageCheap({value}: Props) {
	if (isNull(value)) {
		return (
			<DeltaCheap backgroundColor='muted'>
				<Text size='xSmall' font='mono' bold color='vivid'>
					-
				</Text>
			</DeltaCheap>
		);
	}

	if (value === 0) {
		return (
			<DeltaCheap backgroundColor='mediumContrast'>
				<Text size='xSmall' font='mono' bold color='vivid'>
					0
				</Text>
			</DeltaCheap>
		);
	}

	if (value < 0) {
		return (
			<DeltaCheap backgroundColor='negativeVivid'>
				<Text size='xSmall' font='mono' bold color='vivid'>
					-{Math.abs(value)}%
				</Text>
			</DeltaCheap>
		);
	}

	return (
		<DeltaCheap backgroundColor='positive'>
			<Text size='xSmall' font='mono' bold color='vivid'>
				+{Math.abs(value)}%
			</Text>
		</DeltaCheap>
	);
}
