import {TestModel} from '@esgi/contracts/esgi/types/esgi.testexplorer/features/search/test-model';
import {TestExplorer} from '@esgi/main/features/test-explorer';
import {ClearSpecificStoragesEvent, SubjectLevel, SubjectType, useSubjects} from '@esgi/main/libs/admin-store';
import {SubjectTab} from '@esgi/main/libs/store';
import {dispatchAppEvent} from '@esgillc/events';
import {districtAdminRoutes} from 'apps/main/libs/core/src/district-admin-routes';
import {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Props as AutoTestCreatorProps} from 'shared/modules/auto-test-creator';

export default function () {
	const navigate = useNavigate();

	const [{data: subjectsData, loaded: isSubjectsLoaded}] = useSubjects();

	const subjects = useMemo(() => {
		return subjectsData.reduce<{
			common: SubjectTab[];
			autoTestCreator: AutoTestCreatorProps['subjects'];
		}>(
			(subjectsResult, subject) => {
				const canDisplay = subject.subjectType !== SubjectType.Stock && subject.level === SubjectLevel.District;
				if (canDisplay) {
					const commonSubject: SubjectTab = {
						id: subject.id,
						name: subject.name,
						type: subject.subjectType,
						level: subject.level,
						hidden: subject.hidden,
						gradeLevels: [],
						tests: subject.tests,
					};
					const autoTestCreatorSubject: AutoTestCreatorProps['subjects'][0] = {
						id: subject.id,
						name: subject.name,
						subjectType: subject.subjectType as any,
						tests: [],
					};
					subjectsResult.common.push(commonSubject);
					subjectsResult.autoTestCreator.push(autoTestCreatorSubject);
				}

				return subjectsResult;
			},
			{
				common: [],
				autoTestCreator: [],
			}
		);
	}, [subjectsData]);

	const onNewSubjectCreate = (selectedTests: TestModel[]) => {
		// TODO: implement new logic of test preselection during subject creation
		// or remove it after discussion with the team
		const searchParams = new URLSearchParams();
		if (selectedTests.length > 0) {
			searchParams.append(
				'testIDs',
				selectedTests.map((selectedTest) => selectedTest.testID).join(',')
			);
		}

		navigate({
			pathname: districtAdminRoutes.subjectManagement,
			search: searchParams.toString(),
		});
	};

	const onSubjectAddedToTests = () => {
		dispatchAppEvent(
			ClearSpecificStoragesEvent,
			new ClearSpecificStoragesEvent(['subjects']),
		);
	};

	return (
		<TestExplorer
			subjects={subjects}
			isSubjectsLoaded={isSubjectsLoaded}
			onNewSubjectCreate={onNewSubjectCreate}
			isNewSubjectCreateDisabled={true}
			onSubjectAddedToTests={onSubjectAddedToTests}
		/>
	);
}
