import {getUser, UserInfo} from '@esgi/core/authentication';
import {
	HierarchyInstance,
	HierarchyMode,
	HierarchySnapshot,
} from 'modules/hierarchy/core/models';
import {Hierarchy, ReportType, SubjectInfo} from './types';
import {useMemo} from 'react';

import {GradeScalesLauncher} from './launchers/grade-scales';
import {GrowthReportLauncher} from './launchers/growth-report';
import {TotalReportLauncher} from './launchers/total-report';
import {StudentsReportLauncher} from './launchers/students-report';
import ItemAnalysisLauncher from './launchers/item-analysis';
import PieChartsLauncher from './launchers/pie-charts';
import {TeacherActivityLauncher} from './launchers/teacher-activity';
import ParentLetterLauncher from './launchers/parent-letter';
import {StudentProgressLauncher} from './launchers/student-progress';
import {StudentDetailLauncher} from './launchers/student-detail-button';
import {UntestedStudentsButton} from './launchers/untested-students';
import {RubricReportLauncher} from './launchers/rubric';

function resolveHierarchyMode(hierarchy: Hierarchy): HierarchyMode {
	if (hierarchy.preAssessID) {
		return HierarchyMode.PreAssess;
	}
	if (hierarchy.specialistsID) {
		return HierarchyMode.Specialist;
	}

	return HierarchyMode.Classic;
}

function buildHierarchyInstance(hierarchy: Hierarchy, user: UserInfo): HierarchyInstance {
	const {districtID, schoolID} = getUser();
	const snapshot = {
		classic: {
			studentID: hierarchy.studentID || 0,
			groupID: hierarchy.groupID || 0,
			classID: hierarchy.classID || 0,
			teacherID: hierarchy.teacherID || 0,
			schoolsGroupID: hierarchy.schoolsGroupID || 0,
			teachersGroupID: hierarchy.teachersGroupID || 0,
			districtID: districtID || 0,
			schoolID: schoolID || 0,
		},
		specialist: {
			studentID: hierarchy.studentID || 0,
			filter: {
				schoolID: schoolID || 0,
				teacherID: 0,
			},
			type: 0,
			groupID: hierarchy.specialistGroupID || 0,
			userID: hierarchy.specialistsID || 0,
			districtID: districtID || 0,
			groupOfSpecialistsID: 0,
		},
		preAssess: {
			studentID: hierarchy.studentID || 0,
			groupID: hierarchy.specialistGroupID || 0,
			userID: hierarchy.preAssessID || 0,
			districtID: districtID || 0,
		},
	} as HierarchySnapshot;

	const out = HierarchyInstance.build(resolveHierarchyMode(hierarchy), snapshot.classic, snapshot.specialist, snapshot.preAssess);
	out.studentSort = user.studentSort;
	return out.copy();
}

type Props = {
	hierarchy: Hierarchy,
	report: ReportType,
	firstSubject: SubjectInfo,
	onClose: VoidFunction,
}

export function OldReport(props: Props) {
	const {
		hierarchy,
		report,
		firstSubject,
		onClose,
	} = props;

	const user = useMemo(() => getUser(), []);
	const hierarchyInstance = useMemo(() => buildHierarchyInstance(hierarchy, user), [hierarchy]);

	switch (report) {
		case ReportType.Grade: return <GradeScalesLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.Growth: return <GrowthReportLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.TotalStudents: return <StudentsReportLauncher globalSchoolYearId={user?.globalSchoolYearID} hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.Total: return <TotalReportLauncher globalSchoolYearId={user?.globalSchoolYearID} hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.ItemAnalysis: return <ItemAnalysisLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.PieCharts: return <PieChartsLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.TeacherActivity: return <TeacherActivityLauncher teachersGroupID={hierarchy.teachersGroupID} schoolsGroupID={hierarchy.schoolsGroupID} onClose={onClose}/>;
		case ReportType.ParentLetter: return <ParentLetterLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.StudentsProgress: return <StudentProgressLauncher hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.StudentsDetail: return <StudentDetailLauncher hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.UntestedStudents: return <UntestedStudentsButton hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.RubricResults: return <RubricReportLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
	}
}