import {useEffect, useState} from 'react';
import {PublishForSpecialistsTabID, RetestSubjectType} from '../../../../types';
import {
	ExpandableSelectableBlockTab,
	GradeLevel,
	SubjectPublishValue,
} from '@esgi/main/features/admins/subject-management';
import {getPublishForSpecialistsInit} from './get-publish-for-specialists-init';
import {useDisposable} from '@esgi/core/service';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {SubjectsSchoolController} from '@esgi/contracts/esgi';
import {retestSubjectToNumerable} from '../../../mapped-enums';
import {isNull} from 'underscore';
import {getPublishForSpecialistsSaveData} from '../../../helpers/get-publish-for-specialists-save-data';
import {getPublishDatesSaveData} from '../../../helpers/get-publish-dates-save-data';
import {
	subjectsStore,
	SubjectLevel as SubjectLevelStore,
	SubjectType as SubjectTypeStore,
	TestScreenType,
	TestContentArea,
	DeployedSubject,
} from '@esgi/main/libs/school-admin-store';
import {getPublishForTeachersSaveData} from '../../../helpers/get-publish-for-teachers-save-data';
import {SubjectSettingDataExtended} from '../../types';
import {initPartialData} from './constants';
import {useFormTouched} from './use-form-touched';
import {NewSubjectData, Parameters} from './types';
import {getPublishForSpecialistsStoreData} from '../../../helpers/get-publish-for-specialists-store-data';

export function useSubjectData({
	isDataLoaded,
	schoolSpecialists,
	gradeLevels,
	selectedTests,
	trackID,
	allTrackDates,
	onSubjectCreated,
}: Parameters) {
	const controller = useDisposable(SubjectsSchoolController);
	const [createdData, saveSubject] = useCancelableRequest(controller.create);

	const [initData, setInitData] = useState<SubjectSettingDataExtended | null>(null);

	const [isActive, setIsActive] = useState(initPartialData.isActive);

	const [subjectName, setSubjectName] = useState(initPartialData.subjectName);

	const [retestSubjectType, setRetestSubjectType] = useState(initPartialData.retestSubjectType);
	const [isShuffleQuestions, setIsShuffleQuestions] = useState(initPartialData.isShuffleQuestions);
	const [selectedGradeLevels, setSelectedGradeLevels] = useState<GradeLevel['id'][]>([]);

	const [publishPeriodValue, setPublishPeriodValue] = useState<SubjectPublishValue>(initPartialData.publishPeriodValue);

	const [isPublishForAllTeachers, setIsPublishForAllTeachers] = useState(initPartialData.isPublishForAllTeachers);

	const [isPublishForSpecialistsActive, setIsPublishForSpecialistsActive] = useState(
		initPartialData.isPublishForSpecialistsActive,
	);
	const [publishForSpecialistsData, setPublishForSpecialistsData] = useState<
		ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[]
	>([]);

	const [newSubjectData, setNewSubjectData] = useState<NewSubjectData | null>(null);

	const isFormTouched = useFormTouched({
		initData,
		currentData: {
			subjectName,
			retestSubjectType,
			isShuffleQuestions,
			publishPeriodValue,
			selectedGradeLevels,
			isPublishForAllTeachers,
			isPublishForSpecialistsActive,
			publishForSpecialistsData,
			isActive,
			selectedTests,
		},
	});

	useEffect(() => {
		if (isDataLoaded) {
			const publishForSpecialistsDataInit = getPublishForSpecialistsInit({schoolSpecialists});
			const selectedGradeLevelsInit = gradeLevels.map(({id}) => id);

			setPublishForSpecialistsData(publishForSpecialistsDataInit);
			setSelectedGradeLevels(selectedGradeLevelsInit);

			setInitData({
				...initPartialData,
				publishForSpecialistsData: publishForSpecialistsDataInit,
				selectedGradeLevels: selectedGradeLevelsInit,
				selectedTests: [],
			});
		}
	}, [gradeLevels, isDataLoaded, schoolSpecialists]);

	useEffect(() => {
		if (isAsyncSucceed(createdData)) {
			if (isNull(newSubjectData)) {
				throw new Error('newSubjectData is null');
			}

			const {id, published, subjectName} = createdData.data;

			const allowedSpecialists = getPublishForSpecialistsStoreData({
				publishToSpecialists: newSubjectData.publishToSpecialists,
				allSchoolSpecialistsIDs: schoolSpecialists.map(({id}) => id),
				selectedSchoolSpecialistsIDs: newSubjectData.selectedSchoolSpecialistsIDs,
			});

			const subjectForStore: DeployedSubject = {
				id,
				gradeLevels: selectedGradeLevels,
				hidden: !isActive,
				isPublished: published,
				name: subjectName,
				subjectType: SubjectTypeStore.Deployed,
				level: SubjectLevelStore.School,
				allowedSpecialists,
				allowedToAllTeachers: isPublishForAllTeachers,
				tests: selectedTests.map(
					({id, color, contentArea, authorID, isWhiteBackground, name, testScreenTypes, testType}) => ({
						id,
						color,
						contentArea: contentArea as TestContentArea,
						creatorID: authorID,
						isWhiteBackground,
						maxScore: 0,
						name,
						testScreenTypes: testScreenTypes as unknown as TestScreenType[],
						type: testType,
					}),
				),
			};

			subjectsStore().add?.(subjectForStore);
			onSubjectCreated(id);
			setNewSubjectData(null);
		}
	}, [createdData]);

	const onSaveSubject = () => {
		if (isNull(trackID)) {
			throw new Error('trackID is null');
		}

		const publishToTeachers = getPublishForTeachersSaveData({isPublishForAllTeachers});

		const {publishToSpecialists, schoolSpecialists} = getPublishForSpecialistsSaveData({
			isPublishForSpecialistsActive,
			publishForSpecialistsData,
		});

		const {publishFromDate, publishToDate, publishType} = getPublishDatesSaveData({allTrackDates, publishPeriodValue});

		saveSubject({
			districtID: undefined as unknown as number,
			globalSchoolYearID: undefined as unknown as number,
			userID: undefined as unknown as number,
			userType: undefined as unknown as number,
			schoolID: undefined as unknown as number,

			isHidden: !isActive,
			name: subjectName,
			forceTestType: (retestSubjectType === RetestSubjectType.Any
				? null
				: retestSubjectToNumerable[retestSubjectType]) as number,
			shuffleQuestions: isShuffleQuestions,
			gradeLevels: selectedGradeLevels,
			publishToTeachers,
			publishToSpecialists,
			schoolSpecialists,
			publishType,
			publishTrackID: trackID,
			publishFromDate,
			publishToDate,
			testIDs: selectedTests.map(({id}) => id),
		});

		setNewSubjectData({
			publishToSpecialists,
			selectedSchoolSpecialistsIDs: schoolSpecialists,
		});
	};

	return {
		isActive,
		setIsActive,
		subjectName,
		setSubjectName,
		retestSubjectType,
		setRetestSubjectType,
		isShuffleQuestions,
		setIsShuffleQuestions,
		publishPeriodValue,
		setPublishPeriodValue,
		setSelectedGradeLevels,
		selectedGradeLevels,
		publishForSpecialistsData,
		setPublishForSpecialistsData,
		isPublishForSpecialistsActive,
		setIsPublishForSpecialistsActive,
		isPublishForAllTeachers,
		setIsPublishForAllTeachers,
		onSaveSubject,
		isDataSaving: isAsyncPending(createdData),
		isFormTouched,
	};
}
