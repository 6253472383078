import {noop} from 'underscore';
import {PageMode} from './types';
import {Dispatch, SetStateAction, createContext, useContext} from 'react';

export type DashboardPageContextValue = {
	pageMode: PageMode;
	setPageMode: Dispatch<SetStateAction<PageMode>>;
	setPageViewMode: VoidFunction;
};

export const DashboardPageContext = createContext<DashboardPageContextValue>({
	pageMode: PageMode.View,
	setPageMode: noop,
	setPageViewMode: noop,
});

export function useDashboardPageContext() {
	return useContext(DashboardPageContext);
}
