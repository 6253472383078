import {SubjectType, SubjectTypeContract} from '../types';

export function getContractSubjectType(subjectType: SubjectType): SubjectTypeContract {
	switch (subjectType) {
		case SubjectType.Deployed:
			return SubjectTypeContract.Deployed;

		case SubjectType.Stock:
			return SubjectTypeContract.Stock;

		case SubjectType.Personal:
			return SubjectTypeContract.Personal;
	}
}
