import {useCallback, useEffect, useMemo} from 'react';
import {Enter} from '@esgi/ui';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {Drawer, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {DrawerBody, ScrollableWrapper, FormWrapper} from './index.styled';
import {StudentDetails} from './components/student-details';
import {AssignStudent} from './components/assign-student';
import {FormElement} from './components/form-element';
import {SelectForm} from './components/select-form';
import {TransferStudentService} from './service';

interface Props {
	studentID: number;
	onClose: VoidFunction;
	onTransferred: VoidFunction;
}

export function TransferStudent({studentID, onClose, onTransferred}: Props) {
	const drawerRef = useDrawerRef();
	const closeDrawer = useDrawerClose(drawerRef, onClose);

	const service = useService(TransferStudentService);

	const school = useBehaviorSubject(service.school$);
	const student = useBehaviorSubject(service.student$);
	const teachers = useBehaviorSubject(service.teachers$);
	const teacher = useBehaviorSubject(service.teacher$);
	const classes = useBehaviorSubject(service.classes$);
	const schoolSpecialists = useBehaviorSubject(service.schoolSpecialists$);

	const isBusy = useBehaviorSubject(service.isBusy$);
	const isLoaded = useBehaviorSubject(service.isLoaded$);
	const isFormPristine = useBehaviorSubject(service.isFormPristine$);

	const teacherOptions = useMemo(() => (
		teachers?.map((item) => ({
			id: item.userID,
			value: `${item.firstName} ${item.lastName}`,
		})) ?? []
	), [teachers]);

	const handleSave = useCallback(() => {
		service.form.validate().subscribe((value) => {
			if (!value.valid) {
				return;
			}

			service.save().subscribe(() => {
				onTransferred();
				closeDrawer();
			});
		});
	}, [service, onTransferred, closeDrawer]);

	useEffect(() => {
		service.init(studentID).subscribe();
	}, [service, studentID]);

	return (
		<Drawer drawerRef={drawerRef} onClickOutside={closeDrawer}>
			<Drawer.Header
				Icon={Enter}
				actionButtonText='Transfer'
				sectionName='Student Transfer'
				actionButtonDisabled={isFormPristine || isBusy || !isLoaded}
				onActionButtonClick={handleSave}
				closeDrawer={closeDrawer}
				withActionButton
			/>
			<DrawerBody>
				<StudentDetails
					school={school}
					studentId={student?.studentID}
					firstName={student?.profile?.firstName}
					lastName={student?.profile?.lastName}
					imageURL={student?.profile?.photo?.imageUrl}
					isLoaded={isLoaded}
				/>
				<ScrollableWrapper>
					<FormWrapper controller={service.form}>
						<Drawer.ContentBlock title='Primary Teacher' withDivider>
							<FormElement control={service.form.controls.teacherID}>
								<SelectForm placeholder='Primary Teacher' options={teacherOptions} />
							</FormElement>
						</Drawer.ContentBlock>
						<Drawer.ContentBlock title='Assigned To Classes & Groups' withDivider>
							<AssignStudent
								form={service.form}
								teacher={teacher}
								classes={classes}
								schoolSpecialists={schoolSpecialists}
							/>
						</Drawer.ContentBlock>
					</FormWrapper>
				</ScrollableWrapper>
			</DrawerBody>
		</Drawer>
	);
}