import {LabelBox} from './index.styled';
import {Text} from '@esgi/ui/typography';

type Props = {
	text: string;
};

export function Label({text}: Props) {
	return (
		<LabelBox>
			<Text size='small' color='highContrast'>
				{text}
			</Text>
		</LabelBox>
	);
}
