import {Button} from '@esgi/ui';
import {Alert} from '@esgi/ui/alert';
import {Avatar} from '@esgi/ui/avatar';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {
	OneLinedText,
	getInitials,
	getFullName,
	TestContentArea,
	TestType,
	TestColorLineIndicator,
	TestTypeLabel,
} from '@esgi/main/kits/common';
import {alertColorConfig} from './constants';
import {AlertBody, alertRootCss, TestInfoBox} from './index.styled';
import {useCallback} from 'react';

type Props = {
	avatarUrl: string | null;
	firstName: string;
	lastName: string;
	testColor: string;
	testContentArea: TestContentArea;
	testName: string;
	testType: TestType;
	onClose: VoidFunction;
	userCanAddTestSession: boolean;
	onAddSessionClick: VoidFunction;
};

export function StudentNotTestedDialog({
	avatarUrl,
	firstName,
	lastName,
	testColor,
	testContentArea,
	testName,
	testType,
	onClose,
	userCanAddTestSession,
	onAddSessionClick,
}: Props) {
	const alert = Alert.useRef();
	const close = Alert.useClose(alert, onClose);

	const handleAddSessionClick = useCallback(() => {
		close();
		onAddSessionClick();
	}, [close, onAddSessionClick]);

	return (
		<Alert modalManagerRef={alert} colorConfig={alertColorConfig} css={alertRootCss}>
			<Alert.Header withBacklight={false} onCloseIconClick={close}>
				<GridBox align='center' flow='column' gap='3'>
					<Avatar.Root size='xs'>
						<Avatar.Image src={avatarUrl ?? undefined} />
						<Avatar.Fallback>
							<Text color='primary' size='large'>
								{getInitials({firstName, lastName})}
							</Text>
						</Avatar.Fallback>
					</Avatar.Root>
					<OneLinedText size='small' data-cy='student-full-name' color='primary'>
						{getFullName({firstName, lastName})}
					</OneLinedText>
				</GridBox>
			</Alert.Header>
			<AlertBody>
				<TestInfoBox>
					<TestColorLineIndicator color={testColor} contentArea={testContentArea} />
					<OneLinedText size='xLarge' color='base'>
						{testName}
					</OneLinedText>
					<TestTypeLabel testType={testType} />
				</TestInfoBox>

				<GridBox dataCy='test-description' gap='1'>
					<Text size='medium' color='neutral40'>
						No data is available for this test, because it has not been completed.
					</Text>
					{userCanAddTestSession && (
						<Text size='medium' color='neutral40'>
							You can add a session manually by choosing an option below.
						</Text>
					)}
				</GridBox>
			</AlertBody>
			<Alert.Footer>
				<Button
					color='secondary'
					data-cy='add-session-manually-button'
					onClick={handleAddSessionClick}
					disabled={!userCanAddTestSession}
				>
					<Text size='medium' data-cy='add-session-manually-button-label' color='base'>
						Add Session Manually
					</Text>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
