import {Button, Tooltip} from '@esgi/ui';
import {Close} from '@esgi/ui/icons';
import {useActionPanelContext} from '../context';
import {Text} from '@esgi/ui/typography';

export function CloseButton() {
	const {deselectItems} = useActionPanelContext();

	return (
		<Tooltip>
			<Tooltip.Trigger>
				<Button.Icon withBackgroundHover onClick={deselectItems}>
					<Close />
				</Button.Icon>
			</Tooltip.Trigger>
			<Tooltip.Content>
				<Text size='large' color='tertiary'>
					Deselect all
				</Text>
			</Tooltip.Content>
		</Tooltip>
	);
}
