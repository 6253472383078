import {highlightMaxValue} from '../../../../constants';
import {ValidatorName} from '../../../../forms-data/types';
import {ErrorTooltipItem} from './types';

export const errorTooltipItems: ErrorTooltipItem[] = [
	{
		showOnError: ValidatorName.HighlightValueRequired,
		message: 'Highlight value required',
	},
	{
		showOnError: ValidatorName.HighlightValueOutOfRange,
		message: `Highlight value out of range 0-${highlightMaxValue}`,
	},
];
