import {CardBody, CardHeader, CardRoot, CardSeparator} from './index.styled';
import {Label} from '../label';
import {Text} from '@esgi/ui/typography';

type Props = {
	title: string;
	description: string;
	labels: string[];
	onClick: VoidFunction;
	isSelected: boolean;
};

export function WidgetCard({onClick, title, description, labels, isSelected}: Props) {
	return (
		<CardRoot onClick={onClick} isSelected={isSelected}>
			<CardHeader>
				<Text size='large' bold color={isSelected ? 'primary' : 'base'}>
					{title}
				</Text>
				<Text size='small' color={isSelected ? 'primary' : 'highContrast'}>
					{description}
				</Text>
			</CardHeader>

			<CardSeparator backgroundColor={isSelected ? 'primaryMuted' : 'border'} />

			<CardBody>
				{labels.map((label, index) => (
					<Label text={label} key={`${label}-${index}`} />
				))}
			</CardBody>
		</CardRoot>
	);
}
