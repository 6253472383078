import {isNull} from 'underscore';
import {DatesPeriodModel} from '../../types';

// Function to find the previous TrackDate within an active Track based on the current track date.
export function getPreviousTrackDate(
	currentTrackDate: DatesPeriodModel | null,
	trackDates: DatesPeriodModel[],
): DatesPeriodModel | null {
	if (isNull(currentTrackDate)) {
		return null;
	}

	const currentIndex = trackDates.findIndex((item) => item.trackDateID === currentTrackDate.trackDateID);

	if (currentIndex >= 1) {
		return trackDates[currentIndex - 1]!;
	}

	return null;
}
