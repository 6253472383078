import {AddWidgetResponse} from '@esgi/main/features/admins/dashboard';
import {AddWidgetEventOptions} from '../drawers/add-widget/types';

export class WidgetCreated {
	constructor(
		public id: AddWidgetResponse['id'],
		public versionID: AddWidgetResponse['id'],
		public name: string,
		public options: AddWidgetEventOptions,
		public lastUpdatedTime: string,
	) {}
}
