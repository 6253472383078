import {AgGrid as AgGridBase} from '@esgi/ui/ag-grid';
import {ComponentProps, useCallback} from 'react';
import {usePageContentContext} from '../context';
import {GridReadyEvent} from 'ag-grid-community';

type Props<T extends Record<string, unknown>> = ComponentProps<typeof AgGridBase.Table<T>>

export function AgGrid<T extends Record<string, unknown>>({
	onGridReady,
	...props
}: Props<T>) {
	const {setApi, columnDefs, initialState, tableRows, isDataLoaded} =
		usePageContentContext<T>();

	const handleGridReadyHandler = useCallback(
		(params: GridReadyEvent) => {
			setApi(params.api);

			onGridReady?.(params);
		},
		[onGridReady, setApi]
	);

	return (
		<AgGridBase withoutLastColumnPadding>
			<AgGridBase.Table
				rowSelection='multiple'
				loading={!isDataLoaded}
				columnDefs={columnDefs}
				rowData={tableRows}
				gridOptions={{initialState: initialState ?? undefined}}
				onGridReady={handleGridReadyHandler}
				pagination
				paginationAutoPageSize={false}
				paginationPageSize={15}
				rowMultiSelectWithClick
				suppressPaginationPanel
				{...props}
			/>
		</AgGridBase>
	);
}
