import * as React from 'react';
import type {SVGProps} from 'react';

export function ContentArea(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.9592 12.769C11.1497 12.2563 11.4966 12 12 12C12.5034 12 12.8503 12.2563 13.0408 12.769L14.949 17.9648C14.983 18.0634 15 18.1554 15 18.2408C15 18.4512 14.9235 18.6303 14.7704 18.7782C14.6173 18.9261 14.432 19 14.2143 19C14.0442 19 13.8912 18.9523 13.7551 18.857C13.619 18.7617 13.5238 18.6385 13.4694 18.4873L13.1735 17.669H10.8163L10.5306 18.4873C10.4796 18.6385 10.3844 18.7617 10.2449 18.857C10.1088 18.9523 9.95578 19 9.78571 19C9.56803 19 9.38265 18.9261 9.22959 18.7782C9.07653 18.6303 9 18.4512 9 18.2408C9 18.1554 9.01701 18.0634 9.05102 17.9648L10.9592 12.769ZM12 14.3366L11.2551 16.4268H12.7347L12 14.3366Z'
				fill='#333333'
			/>
			<path
				d='M20.6266 13.7679C20.4567 13.9226 20.2479 14 20 14C19.7521 14 19.5433 13.9226 19.3734 13.7679C19.2036 13.6131 19.1186 13.3712 19.1186 13.1453C19.1186 12.9194 19.2036 12.66 19.3734 12.501C19.5433 12.3421 19.7521 12.2626 20 12.2626C20.2479 12.2626 20.4567 12.3421 20.6266 12.501C20.7964 12.66 20.8814 12.9194 20.8814 13.1453C20.8814 13.3712 20.7964 13.6131 20.6266 13.7679Z'
				fill='#333333'
			/>
			<path
				d='M17.7272 16.0079C17.5757 15.8698 17.5 15.7004 17.5 15.4997C17.5 15.2989 17.5757 15.1295 17.7272 14.9915C17.8787 14.8534 18.0646 14.7844 18.285 14.7844H21.7921C22.0125 14.7844 22.1984 14.8534 22.3499 14.9915C22.5014 15.1295 22.5771 15.2989 22.5771 15.4997C22.5771 15.7004 22.5014 15.8698 22.3499 16.0079C22.1984 16.1459 22.0125 16.2149 21.7921 16.2149H18.285C18.0646 16.2149 17.8787 16.1459 17.7272 16.0079Z'
				fill='#333333'
			/>
			<path
				d='M20.6266 18.5052C20.4567 18.66 20.2479 18.7374 20 18.7374C19.7521 18.7374 19.5433 18.66 19.3734 18.5052C19.2036 18.3505 19.1186 18.1085 19.1186 17.8827C19.1186 17.6568 19.2036 17.3973 19.3734 17.2384C19.5433 17.0795 19.7521 17 20 17C20.2479 17 20.4567 17.0795 20.6266 17.2384C20.7964 17.3973 20.8814 17.6568 20.8814 17.8827C20.8814 18.1085 20.7964 18.3505 20.6266 18.5052Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.63636 8C7.62818 8 6 9.6915 6 11.7778V19.3333C6 21.4196 7.62818 23.1111 9.63636 23.1111L13.2809 23.1205C13.6682 23.1205 14.0736 23.3057 14.5382 23.7505C14.7191 23.9243 14.9355 24.1415 15.0827 24.3445C15.1031 24.3728 15.1243 24.4042 15.1467 24.4375C15.3046 24.672 15.5272 25.0025 16 25C16.4672 24.9975 16.6569 24.7185 16.8247 24.4717L16.8307 24.4629C16.8571 24.424 16.8829 24.3861 16.9091 24.3502C17.0555 24.1632 17.2309 23.9847 17.4118 23.8109C17.8773 23.3661 18.34 23.1111 18.7273 23.1111H22.3636C24.3718 23.1111 26 21.4196 26 19.3333V11.7778C26 9.6915 24.3718 8 22.3636 8H18.7273C17.7111 8 16.8158 8.44737 16.1592 9.14753C16.0737 9.23879 15.9263 9.23879 15.8408 9.14753C15.1842 8.44737 14.2889 8 13.2727 8H9.63636ZM9.63636 9.88889H13.2727C14.2773 9.88889 14.9714 10.4286 15.5455 11.3056L15.7297 11.6883C15.8387 11.9149 16.1613 11.9149 16.2703 11.6883L16.4545 11.3056C16.9091 10.3611 17.7227 9.88889 18.7273 9.88889H22.3636C23.3682 9.88889 24.1818 10.7342 24.1818 11.7778V19.3333C24.1818 20.3769 23.3682 21.2222 22.3636 21.2222H18.7273C17.3636 21.2222 16.9091 21.6944 16.4545 22.1667C16 22.6389 16 22.6389 15.5455 22.1667C15.0909 21.6944 14.6364 21.2222 13.2727 21.2222H9.63636C8.63182 21.2222 7.81818 20.3769 7.81818 19.3333V11.7778C7.81818 10.7342 8.63182 9.88889 9.63636 9.88889Z'
				fill='#333333'
			/>
		</svg>
	);
}
