import {BaseComponentProps} from '@esgi/ui';
import React, {ComponentPropsWithoutRef, ReactNode, forwardRef} from 'react';
import {RootBox} from './index.styled';
import {Property} from '@stitches/react/types/css';

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> &
	BaseComponentProps & {
		children?: ReactNode;
		gridTemplateColumns: Property.GridTemplateColumns;
	};

export const Page = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'admins-subject-management-page-root', css = {}, gridTemplateColumns, children, ...props},
		forwardedRef,
	) => (
		<RootBox dataCy={dataCy} css={{...css, gridTemplateColumns}} ref={forwardedRef} {...props}>
			{children}
		</RootBox>
	),
);
