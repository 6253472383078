import {Box, GridBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

const titleContainerPaddingInline = 12;

export const Container = styled(GridBox, {
	width: '100%',
	gap: '$3',
	gridTemplateRows: 'auto 1fr',
});

export const TitleContainer = styled(GridBox, {
	paddingTop: 20,
	gap: '$3',
	paddingLeft: titleContainerPaddingInline,
	paddingRight: titleContainerPaddingInline,
	textAlign: 'center',
});

export const SectionTitle = styled(Text, {
	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				width: '50%',
				height: 12,
				borderRadius: 6,
				left: '25%',
			},
		},
	},
});

export const Divider = styled(Box, {
	width: `calc(100% + ${titleContainerPaddingInline}px)`,
	height: 1,
	backgroundColor: '$border',
	marginRight: -titleContainerPaddingInline,
	justifySelf: 'center',
});

export const ChildrenBox = styled(Box, {
	display: 'inline-flex',
});
