import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Title = styled(GridBox, {
	paddingTop: 20,
	gap: '$3',
	textAlign: 'center',

	'&::after': {
		content: '',
		display: 'block',
		width: 'calc(100% - 24px)',
		height: 1,
		backgroundColor: '$border',
		justifySelf: 'center',
	},
});

export const Content = styled(GridBox, {
	gap: '$3',
	gridTemplateColumns: '1fr 1fr',
});
