import {ChangeEvent, useCallback, useMemo, useState} from 'react';
import {EditableSessionTimeInfo} from '../../editable-session-time-info';
import {TestSession} from '../../../../../types/view-edit-session-details';

export function useEditableCommonState({sessionInfo}: {sessionInfo: TestSession}) {
	const {defaultSessionNote} = useMemo(
		() => ({
			defaultSessionNote: sessionInfo.notes ?? '',
		}),
		[sessionInfo],
	);

	const dateTimeRef = EditableSessionTimeInfo.useRef();

	const [sessionTimeInfoTouched, setSessionTimeInfoTouched] = useState(false);
	const [isSessionTimeInfoValid, setIsSessionTimeInfoValid] = useState(false);

	const [sessionNote, setSessionNote] = useState(defaultSessionNote);

	const onUpdateSessionNote = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
		const value = event.target.value;

		setSessionNote(value);
	}, []);

	const isCommonDataTouched = defaultSessionNote !== sessionNote || sessionTimeInfoTouched;

	const isCommonDataValid = isSessionTimeInfoValid;

	return {
		dateTimeRef,
		setIsSessionTimeInfoValid,
		setSessionTimeInfoTouched,
		sessionNote,
		onUpdateSessionNote,
		isCommonDataTouched,
		isCommonDataValid,
		sessionTimeInfoTouched,
	};
}
