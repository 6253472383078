import {isEqual} from 'underscore';
import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {createParentLetterForm} from './form';
import {ParentLetterData} from '../../../../../types';
import {LogoModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/district-admin/parent-letter/logo-model';

export class ParentLetterService extends BaseService {
	public form = createParentLetterForm();

	private readonly initData = new BehaviorSubject<Pick<ParentLetterData, 'body' | 'defaultBody' | 'logo'>>(null);

	public init(body: string, defaultBody: string, logo: ParentLetterData['logo']) {
		this.initData.next({body, defaultBody, logo});
		this.form.value = {
			body,
			logo: this.getLogoBaseHash(logo),
		};
	}

	public compareStatesForEquality() {
		const {body, logo} = this.initData.value;

		const initialState = {
			body: this.getStringToCompare(body),
			logo: this.getLogoBaseHash(logo),
		};

		const currentState = {
			body: this.getStringToCompare(this.form.controls.body.value),
			logo: this.form.controls.logo.value,
		};

		return !isEqual(currentState, initialState);
	}

	public compareParentLetterForDefault() {
		const {defaultBody} = this.initData.value;

		const initialState = {
			body: this.getStringToCompare(defaultBody),
		};

		const currentState = {
			body: this.getStringToCompare(this.form.controls.body.value),
		};

		return !isEqual(currentState, initialState);
	}

	private getLogoBaseHash(logo: LogoModel) {
		if (!logo) {
			return null;
		}

		return `data:${logo.contentType};base64,${logo.base64}`;
	}

	private getStringToCompare(value: string) {
		return JSON.stringify(value).replace(/\\r/g, '');
	}
}