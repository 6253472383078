import {Text} from '@esgi/ui/typography';
import {SelectField} from './index.styled';
import {Select as SelectUI} from '@esgi/ui/controls';
import {OptionItem} from './types';
import {Skeletonable} from '@esgi/ui';

type Props<T extends string> = Skeletonable<{
	selectedValue: T | undefined;
	items: OptionItem<T>[];
	onValueChange: (value: T) => void;
	placeholder: string;
}>;

export function Select<T extends string>({selectedValue, onValueChange, items, placeholder, skeleton}: Props<T>) {
	return (
		<SelectUI.Root value={selectedValue} onValueChange={onValueChange}>
			<SelectField placeholder={placeholder} skeleton={skeleton} />
			<SelectUI.Content>
				{items.map(({label, value}) => (
					<SelectUI.Option value={value} key={value}>
						<Text size='medium' bold>
							{label}
						</Text>
					</SelectUI.Option>
				))}
			</SelectUI.Content>
		</SelectUI.Root>
	);
}
