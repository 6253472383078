import {forwardRef} from 'react';
import {TabProps} from './types';
import {useSubjectPanelContext} from '../../context';
import {TabID} from '../../types/section';
import {Teacher as TeacherCheap} from '../cheap/teacher';
import {BaseTabContent} from './base-tab-content';
import {SubjectLevel, subjectsDescriptionByLevel} from '@esgi/main/kits/common';

export const Teacher = forwardRef<HTMLDivElement, TabProps>(
	(
		{
			dataCy = 'school-tab-content',
			css = {},
			tabContent = <TeacherCheap />,
			tabDisabled,
			tabDescription = subjectsDescriptionByLevel[SubjectLevel.Teacher],
			tabApplyDisabledStyles,
			...boxProps
		},
		forwardedRef,
	) => {
		const {
			entities: {teacherSubjects},
		} = useSubjectPanelContext();

		return (
			<BaseTabContent
				dataCy={dataCy}
				css={css}
				tabID={TabID.Teacher}
				tabContent={tabContent}
				description={tabDescription}
				applyDisabledStyles={tabApplyDisabledStyles}
				disabled={tabDisabled}
				subjects={teacherSubjects}
				ref={forwardedRef}
				{...boxProps}
			/>
		);
	},
);
