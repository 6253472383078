import {folderNameMaxLength} from './constants';
import {useCreateFolder} from './hooks/use-create-folder';
import {useFetchFolderByID} from './hooks/use-fetch-folder-by-id';
import {useFetchFolders} from './hooks/use-fetch-folders';
import {useRemoveFolder} from './hooks/use-remove-folder';
import {useUpdateFolder} from './hooks/use-update-folder';

export const FolderManagement = {
	useCreateFolder,
	useUpdateFolder,
	useFetchFolders,
	useFetchFolderByID,
	useRemoveFolder,
	folderNameMaxLength,
};

export {FolderType, type Folder, type FolderCUDEventData} from './types';

export * from './events';
