import {Property} from '@stitches/react/types/css';
import {Dispatch} from 'react';

export enum SortDirection {
	Asc = 'asc',
	Desc = 'desc',
}

export type SortableState<T extends string> = {
	key: T;
	direction: SortDirection;
};

export type SortableItem<T extends string> = {
	key: T;
	label: string;
	justifyContent?: Property.JustifyContent | undefined;
};

type TableData<T = unknown> = T & {
	rowID: number;
};

export type CommonStudentData<T = unknown> = T &
	TableData<{
		studentID: number;
		student: string;
		teacher: string | null;
		school: string;
		grade: string | null;
	}>;

export type StudentsSinglePeriodData = CommonStudentData<{
	avg: number;
}>;

export type StudentsTwoPeriodData = CommonStudentData<{
	avgFirst: number | null;
	avgSecond: number | null;
	delta: number | null;
}>;

export type SchoolsData = {
	rowID: number;
	school: string;
	totalStudents: number;
	lowestAchievementStudents: number;
	result: number;
};

type SortableKey<T extends TableData> = keyof Omit<T, 'rowID'>;

export type StudentsSinglePeriodSortableKey = SortableKey<StudentsSinglePeriodData>;
export type StudentsTwoPeriodsSortableKey = SortableKey<StudentsTwoPeriodData>;
export type SchoolsSortableKey = SortableKey<SchoolsData>;

export type TableSortableInfo<T extends string> = {
	sortableState: SortableState<T>;
	setSortableState: Dispatch<SortableState<T>>;
	disabledSortableKeys: T[];
};

export enum ViewTableType {
	StudentsOnePeriod = 'StudentsOnePeriod',
	StudentsTwoPeriods = 'StudentsTwoPeriods',
	Schools = 'Schools',
}

export type ViewTableStudentsOnePeriodInfo = {
	type: ViewTableType.StudentsOnePeriod;
} & TableSortableInfo<StudentsSinglePeriodSortableKey>;

export type ViewTableStudentsTwoPeriodsInfo = {
	type: ViewTableType.StudentsTwoPeriods;
} & TableSortableInfo<StudentsTwoPeriodsSortableKey>;

export type ViewTableSchoolsInfo = {
	type: ViewTableType.Schools;
} & TableSortableInfo<SchoolsSortableKey>;

export type ViewTableInfo = ViewTableStudentsOnePeriodInfo | ViewTableStudentsTwoPeriodsInfo | ViewTableSchoolsInfo;
