import {
	ExpandableSelectableBlockTab,
	GradeLevel,
	SubjectPublishValue,
} from '@esgi/main/features/admins/subject-management';

export enum RetestSubjectType {
	TestIncorrect = 'TestIncorrect',
	TestAll = 'TestAll',
	Any = 'Any',
}

export enum PublishForSpecialistsTabID {
	All = 'All',
	BySpecialist = 'BySpecialist',
}

export type SubjectSettingData<P = unknown> = P & {
	subjectName: string;
	retestSubjectType: RetestSubjectType;
	isShuffleQuestions: boolean;
	publishPeriodValue: SubjectPublishValue;
	selectedGradeLevels: GradeLevel['id'][];
	isPublishForAllTeachers: boolean;
	isPublishForSpecialistsActive: boolean;
	publishForSpecialistsData: ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[];
};
