import {useState} from 'react';
import {SingleSelectedExpandablePanel} from '../../../../../_kit';
import {RowDef} from '../../../../types';
import {GridApi} from 'ag-grid-community';
import {QuickPanel} from './components/quick-panel';
import {ExpandedPanel} from './components/expanded-panel';

type Props = {
	gridApi: GridApi<Readonly<RowDef>> | null;
	onFolderAddClick: VoidFunction;
	tableRowsLength: number
};

export function SingleItemExpandablePanel({gridApi, onFolderAddClick, tableRowsLength}: Props) {
	const [isPanelExpanded, setIsPanelExpanded] = useState(false);
	const [selectedRow, setSelectedRow] = useState<RowDef | null>(null);
	const selectedRows = gridApi?.getSelectedRows();

	if (tableRowsLength === 1 && selectedRows?.length === tableRowsLength) {
		return null;
	}

	return (
		<SingleSelectedExpandablePanel.Root<RowDef>
			gridApi={gridApi}
			onSingleSelectedItemChange={setSelectedRow}
			onPanelExpandableValueChange={setIsPanelExpanded}
		>
			{selectedRow && (
				<>
					<QuickPanel selectedRow={selectedRow} isPanelExpanded={isPanelExpanded} onFolderAddClick={onFolderAddClick} />
					<ExpandedPanel selectedRow={selectedRow} />
				</>
			)}
		</SingleSelectedExpandablePanel.Root>
	);
}
