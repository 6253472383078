import {BaseButton} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Button = styled(BaseButton, {
	display: 'grid',
	gap: 6,
	borderRadius: 6,
	borderStyle: 'dashed',
	borderWidth: 1,
	borderColor: '$lowContrast',
	paddingTop: 7,
	paddingBottom: 7,
	paddingInline: 8,
	color: '$base',
	width: '100%',
	justifyContent: 'start',
	alignItems: 'center',
	gridAutoFlow: 'column',
	boxSizing: 'border-box',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},

	[`& ${Text}`]: {
		color: 'currentColor',
	},

	'&:hover:not(:disabled)': {
		color: '$secondary',
		borderColor: '$secondary',
		backgroundColor: '$secondaryBackground',
	},

	'&:disabled': {
		color: '$lowContrast',
		borderColor: '$border',
	},

	variants: {
		isActive: {
			true: {
				color: '$primary',
				borderColor: '$primaryHighlight',
				backgroundColor: '$primaryBackground',
			},
		},
	},
});
