import React from 'react';
import moment from 'moment';
import {sortBy, findIndex} from 'underscore';
import {dispatchAppEvent, EventBusManager} from '@esgillc/events';
import {SubjectCreatedEvent, TestAddedToSubjectEvent} from 'api/entities/events/subject';
import {SubjectType, TestType, ValidationStatus} from '@esgi/core/enums';
import {Question, ContentArea, Subject, Test, ViewMode} from 'shared/modules/test-details/models';
import {AddTestToSubject, State as AddTestToSubjectState} from 'shared/modules/add-test-to-subject/component';
import {Subject as SubjectEntity} from 'api/entities/subject';
import {Test as TestEntity} from 'api/entities/test';
import {TestName, State as TestNameState} from 'shared/modules/test-details/components/test-name/component';
import {TestData, State as TestDataState} from 'shared/modules/test-details/components/test-data/component';
import {Modal, ModalBody, ModalFooter, ModalHeader} from '@esgi/deprecated/react';
import {Button} from '@esgi/deprecated/elements/buttons/default';
import {Primary} from '@esgi/deprecated/elements/buttons/primary';
import {QuestionPanel, State as QuestionPanelState} from 'shared/modules/test-details/components/question-panel/component';
import {OldAlerts} from '@esgi/deprecated/knockout';
import {CopyTest, State as CopyTestState} from 'shared/modules/test-details/components/dialogs/copy-test/component';
import {Loader} from '@esgi/deprecated/jquery';
import {Question as SelfAssessQuestion} from '@esgi/selfassess';
import {Api} from 'shared/modules/test-details/api/api';
import {TestBackgroundChangedEvent, TestChanged, TestCopied, TestCreated, TestDeleted, TestPublished, TestQuestionListChanged, TypeChanged, ResetRequested, CloseQuestionBankEvent, ForceCloseQuestionBankEvent} from 'shared/modules/test-details/events';
import './test-details.less';
import {SsoTracker} from '@esgi/core/tracker';
import {OnHoverTooltip, HoverTooltip} from '@esgillc/ui-kit/tooltip';
import {imageQueueService} from 'pages/test-explorer/services/image-queue-service';
import {RemoveStandardDialog} from '@esgi/ui';

class State {
	loaded: boolean;
	testId: number = 0;
	test: Test;
	defaultTest: Test;
	subjects: Subject[];
	shared: boolean = true;
	readOnly: boolean;
	contentAreas: ContentArea[];
	autoSaved: boolean = false;
	saving: boolean = false;
	changeBackgroundAlert: boolean = false;
	testName: TestNameState = new TestNameState();
	testData: TestDataState = new TestDataState();
	questionPanel: QuestionPanelState = new QuestionPanelState();
	addTestToSubject: AddTestToSubjectState = null;
	copyTest: CopyTestState;
	testWasCopied: boolean;
	addTestToSubjectOpen: boolean = false;
	needSaveTestAfterAddToSubject: boolean = false;
	canModifyTestID: boolean;
	createDate: string;
	totalPossibleTouched: boolean = false;
	isWhiteBackground: boolean = false;
	hasSAVersion: boolean = false;
	isHidden: boolean = false;
	isHiddenChanged: boolean = false;
	showRemoveStandardDialog: boolean = false;
	verificationModalWasShown: boolean = false;


	constructor(testId?: number) {
		this.testId = testId ?? 0;
	}
}

class Props {
	testID?: number;
	firstName: string;
	lastName: string;
	userID: number;
	onTestCreated?: (closeModal?: boolean) => void;
	disableCopyTest?: boolean;
	subjectsForModal?: any = [];
	canAddToSubject?: boolean;
	typeInitially?: TestType;
	close: (totalPossible?, changedTest?, testCreated?: boolean) => void;
	viewMode?: ViewMode;
	onHiddenChanged?: (testId: number, isHidden: boolean) => void;
	// NOTE: needs to pass parameters by selectedStateStandardType and selectedStateStandardIDs
	// to filtering test initialization data
	selectedStateStandardType?: number | null;
	selectedStateStandardIDs?: number[];
	disableNewSubjectBtn?: boolean = false;
}

export default class TestDetails extends React.PureComponent<Props> {
	private readonly eventBus = new EventBusManager();
	private saveButtonRef: HTMLDivElement = null;
	private testData: TestData;
	private questionPanel: QuestionPanel;
	private testName: TestName;
	private draftTestName: string;
	private questionListChanged: boolean = false;
	loader: Loader;
	state: State;

	constructor(props?: Props) {
		super(props);
		this.state = new State();
		this.bindScope();
	}

	private onStandardAffectingChange = () => {
		if (!this.state.testData.stateStandards?.length || this.state.verificationModalWasShown) {
			return;
		}
		this.setState({showRemoveStandardDialog: true});
	};

	private onRemoveStandardDialogClose = () => {
		this.setState({showRemoveStandardDialog: false});
	};

	private onRemoveStandardDialogContinue = () => {
		this.setState({
			showRemoveStandardDialog: false,
			verificationModalWasShown: true,
		});
	};

	private bindScope() {
		this.saveTest = this.saveTest.bind(this);
		this.preSaveTest = this.preSaveTest.bind(this);
		this.toggleModalVisibility = this.toggleModalVisibility.bind(this);
	}

	private onCloseQuestionBankEventHandler = ({selfAssessQuestions, questions}: CloseQuestionBankEvent) => {
		this.toggleModalVisibility();
		if (!questions || !selfAssessQuestions) {
			return;
		}

		this.setState((prev: State) => ({
			...prev,
			test: {
				...prev.test,
				selfAssessQuestions: selfAssessQuestions.map<SelfAssessQuestion>((question) => ({
					...question,
					...(JSON.parse(question.template) as SelfAssessQuestion),
				})),
			},
			questionPanel: {
				...prev.questionPanel,
				questions: questions.map<Question>((question) => ({
					...question,
					defStateStandard: question.stateStandard,
					defStateStandardID: question.stateStandardID,
					questionImageIsProcessing: false,
					defContentAreaID: prev.testData.contentAreaId,
					contentAreaID: prev.testData.contentAreaId,
				})),
			},
		}));
	};

	private onForceCloseQuestionBankEventHandler = () => {
		this.props.close();
	};

	componentDidMount() {
		this.initTestData();

		this.eventBus.subscribe(CloseQuestionBankEvent, this.onCloseQuestionBankEventHandler);
		this.eventBus.subscribe(ForceCloseQuestionBankEvent, this.onForceCloseQuestionBankEventHandler);

		this.eventBus.subscribe(TypeChanged, this.onTypeChanged);
		this.eventBus.subscribe(TestCopied, this.testCopied);
		this.eventBus.subscribe(TestAddedToSubjectEvent, this.onTestAddToSubject);
		this.eventBus.subscribe(SubjectCreatedEvent, this.onSubjectCreated);

		this.updateManual();

		this.loader = new Loader('#question-panel-for-loading', {
			delay: 0,
			longLoadingText:
				'Please wait. Changing the background color may take longer depending on the number of questions.',
		});
	}

	updateManual(attempt: number = 0): void {
		const manual = (window as any).inline_manual_player;
		if (manual) {
			manual.update();
		} else {
			if (attempt < 50) {
				setTimeout(() => this.updateManual(attempt + 1), 100);
			}
		}
	}

	componentWillUnmount() {
		this.eventBus.destroy();
		imageQueueService.clear();
	}

	get isNew() {
		return !this.props.testID;
	}

	private initTestData = (testId?: number) => {
		const dataRequest = {
			testId: testId ?? this.props.testID ?? 0,
			stateID: this.props.selectedStateStandardType,
			stateStandardIDs: this.props.selectedStateStandardIDs,
		};

		Api.init(dataRequest)
			.withCustomErrorHandler((response, errorHandlingStrategy) => {
				console.log('Init test details request error');
				this.setState({loaded: true});
			})
			.subscribe((r) => {
				const testInfo = r.testInfo;
				const testData = Test.FromResponse(testInfo);

				const contentAreas = testInfo.contentAreas;

				// Shared option is enabled by default for new tests
				const shared = !dataRequest.testId ? true : testInfo.shared;
				const questions = testData.questions;
				const createDate =
					(dataRequest.testId &&
						moment(testData.createDate).format('MM/DD/YYYY')) ||
					'';
				const newQuestionPanel = {...this.state.questionPanel};

				questions.forEach((x) => {
					x.defContentAreaID = x.defContentAreaID;
					x.defStateStandard = x.stateStandard;
					x.defStateStandardID = x.stateStandardID;
				});
				newQuestionPanel.questions = questions;
				newQuestionPanel.questions = testData.questions;
				newQuestionPanel.mostRecentColors = r.mostRecentColors;
				newQuestionPanel.lastQuestionXml = r.testInfo.lastQuestionXml;
				newQuestionPanel.testIntroTile.modifyTicks =
					testData.testIntroModifyTicks;
				newQuestionPanel.testIntroTile.readonly =
					!testData.canEditTestIntroPage;
				newQuestionPanel.testIntroTile.testId = testData.id;
				newQuestionPanel.testIntroTile.pageId = testData.testIntroPageID;
				newQuestionPanel.testIntroTile.pregenerated =
					testData.testIntroPregenerated;
				newQuestionPanel.viewMode =
					this.props.viewMode ?? ViewMode.TeacherOneOnOne;

				if (!this.props.testID && this.props.typeInitially) {
					testData.type = this.props.typeInitially;
				}

				if (testInfo.selfAssessQuestions?.length) {
					testData.selfAssessQuestions = sortBy(
						testData.selfAssessQuestions,
						(x) => findIndex(questions, (y) => x.id === y.id),
					);
				}

				this.setState({
					defaultTest: testData,
					test: testData,
					testId: dataRequest.testId,
					contentAreas: contentAreas,
					readOnly: testInfo.readonly,
					shared: shared,
					createDate: createDate,
					defQuestionPanel: newQuestionPanel,
					questionPanel: newQuestionPanel,
					isWhiteBackground: testData.isWhiteBackground,
					canModifyTestID: r.canModifyTestID,
					loaded: true,
					hasSAVersion:
						testInfo.selfAssessQuestions?.length > 0 ??
						this.props.viewMode === ViewMode.SelfAssess,
				});
			});
	};

	private onTypeChanged = (newType: TestType) => {
		const updTest = Object.assign({}, this.state.test);
		updTest.type = newType;

		const newState = {...this.state};
		newState.test = updTest;
		newState.questionPanel.validation.valid = ValidationStatus.Valid;
		newState.testName.validation.valid = ValidationStatus.Valid;
		newState.testData.description.validation.valid = ValidationStatus.Valid;

		this.setState(newState);
	};

	private onTestAddToSubject = (args: TestAddedToSubjectEvent) => {
		this.selectSubject(args.subjectID);

		if (this.state.addTestToSubject && this.state.addTestToSubjectOpen) {
			this.closeAddTestToSubject();
		}

		if (this.state.needSaveTestAfterAddToSubject) {
			this.setState({needSaveTestAfterAddToSubject: false});
			this.saveTestAndClose();
		}
	};

	private onSubjectCreated = (args: SubjectCreatedEvent) => {
		this.updateSubjects(
			args.id,
			args.properties.name,
			args.properties.subjectType,
		);
		this.addToSubject(args.id, args.properties.subjectType);
	};

	get loaded(): boolean {
		return this.state && this.state.loaded;
	}

	private saveTest = (afterSaveCallback?, saveAsDraft?: boolean) => {
		const contentArea = this.state.contentAreas.find(
			(ca) => ca.id === this.state.testData.contentAreaId,
		);

		if (this.state.testId !== 0 && !this.state.test.draft && !this.dirty) {
			if (afterSaveCallback) {
				afterSaveCallback(this.state.testId, null, this.state, {
					testID: this.state.test.id,
					name: this.state.test.name,
					description: this.state.test.description,
					contentAreaName: (contentArea && contentArea.name) || this.state.contentAreas[0].name,
					gradeLevels: this.state.testData.selectedGradeLevels,
					starred: this.state.test.starred,
					numberOfQuestions: this.state.questionPanel.questions.length,
					totalPossible: this.state.test.totalPossible,
					imageQuestionID: this.state.questionPanel?.questions?.find(item => item.imageQuestion)?.id,
					stateStandardIDs: this.state.test.stateStandardIDs,
					color: this.state.testData.color.selectedColor || this.state.test.color,
					isWhiteBackground: this.state.isWhiteBackground,
				});
			}
			return;
		}
		this.setState({saving: true});

		const saveRequest = {
			testID: this.state.testId === 0 ? null : this.state.testId,
			testIDN: this.state.testData.testIdn,
			color: this.state.testData.color.selectedColor || this.state.test.color,
			name: this.state.testName.name,
			orderNum: this.state.test.orderNum,
			contentAreaId:
				(contentArea && contentArea.id) || this.state.contentAreas[0].id,
			contentAreaName:
				(contentArea && contentArea.name) || this.state.contentAreas[0].name,
			shared: this.state.shared,
			stateStandardId: this.state.test.stateStandardId,
			stateStandardName: this.state.test.stateStandard,
			stateStandardIDs: this.state.test.stateStandardIDs,
			description: this.state.testData.description.value,
			gradeLevels: this.state.testData.selectedGradeLevels,
			type: this.state.test.type,
			totalPossible: this.state.questionPanel.totalPossibleState.value,
			isWhiteBackground: this.state.isWhiteBackground,
			createSAVersion: this.props.viewMode === ViewMode.SelfAssess,
		};

		Api.saveTest(saveRequest)
			.withCustomErrorHandler((response, errorHandlingStrategy) => {
				this.setState({saving: false});
			})
			.subscribe((response) => {
				let draft =
					!!saveAsDraft || this.state.test.draft || !this.state.test.id;
				if (
					this.hasQuestions &&
					!saveAsDraft &&
					(draft || this.state.autoSaved)
				) {
					this.publishTest(response.testID);
					draft = false;
				}

				if (draft) {
					this.draftTestName = response.name;
				}

				let createDate = this.state.createDate;
				if (!this.state.testId) {
					this.eventBus.dispatch(
						TestCreated,
						TestCreated(
							response.testID,
							saveRequest.type,
							this.state.testName.name,
							draft,
						),
					);
					createDate = moment(response.createDate).format('MM/DD/YYYY');
				} else {
					this.eventBus.dispatch(
						TestChanged,
						new TestChanged(
							saveRequest.testID,
							saveRequest.name,
							saveRequest.color,
							saveRequest.description,
							saveRequest.gradeLevels,
							saveRequest.contentAreaName,
							saveRequest.stateStandardName,
							this.state.questionPanel.questions.length,
							draft,
							saveRequest.type,
							saveRequest.isWhiteBackground,
						),
					);
				}

				const newTestName = {...this.state.testName};
				newTestName.dirty = false;

				const newTestData = {...this.state.testData};
				newTestName.dirty = false;

				const newQuestionPanel = {...this.state.questionPanel};
				newQuestionPanel.dirty = false;

				this.setState({
					testId: response.testID,
					testName: newTestName,
					testData: newTestData,
					questionPanel: newQuestionPanel,
					saving: false,
					shared: this.state.shared,
					createDate: createDate,
				});

				if (afterSaveCallback) {
					afterSaveCallback(response.testID, response.name, this.state, {...saveRequest, imageQuestionID: this.state.questionPanel?.questions?.find(item => item.imageQuestion)?.id, numberOfQuestions: this.state.questionPanel?.questions?.length});
				}
			});
	};

	private reset = () => {
		this.setState({
			test: this.state.defaultTest,
			shared: this.state.shared,
		});
		const args = new ResetRequested();
		args.test = this.state.defaultTest;
		args.shared = this.state.shared;
		this.eventBus.dispatch(ResetRequested, args);
	};

	private preSaveTest = (afterSaveCallback?) => {
		this.setState({autoSaved: true}, () => this.saveTest(afterSaveCallback));
	};

	private saveDraft() {
		this.saveTest(() => {
			this.closeModal(null, true);
		}, true);
	}

	private publishTest = (testId: number) => {
		const publishRequest = {
			testID: testId,
		};

		Api.publishTest(publishRequest).subscribe(() => {
			SsoTracker.trackEvent({
				trackingEvent: 'Tests Created',
			});

			this.eventBus.dispatch(
				TestPublished,
				TestPublished(
					testId,
					this.state.testName.name,
					this.owner,
					this.state.createDate,
					this.state.questionPanel.questions,
					this.state.testData.color.selectedColor,
					TestType[this.state.test.type],
					this.state.contentAreas.find(
						(ca) => ca.id === this.state.testData.contentAreaId,
					).name,
					this.state.testData.description.value,
					this.state.testData.selectedGradeLevels,
					this.state.testData.stateStandard,
					this.state.isWhiteBackground,
				),
			);
		});
	};

	private deleteTest(testId: number) {
		const deleteRequest = {
			testID: testId,
		};

		Api.deleteTest(deleteRequest).subscribe(() => {
			this.eventBus.dispatch(TestDeleted, TestDeleted(testId));
		});
	}

	private starSwitch() {
		if (this.state.test.starred) {
			TestEntity.unstar(this.state.testId).subscribe(() => {
				const test = {...this.state.test};
				test.starred = !test.starred;
				this.setState({test: test});
			});
		} else {
			TestEntity.star(this.state.testId).subscribe(() => {
				const test = {...this.state.test};
				test.starred = !test.starred;
				this.setState({test: test});
			});
		}
	}

	private hideSwitch() {
		if (this.state.test.hidden) {
			TestEntity.unhide(this.state.testId).subscribe(() => {
				const test = {...this.state.test};
				test.hidden = !test.hidden;
				this.props.onHiddenChanged(test.id, false);
				this.setState({test: test, isHiddenChanged: true});
			});
		} else {
			TestEntity.hide(this.state.testId).subscribe(() => {
				const test = {...this.state.test};
				test.hidden = !test.hidden;
				this.props.onHiddenChanged(test.id, true);
				this.setState({test: test, isHiddenChanged: true});
			});
		}
	}

	private openCopyTestModal = () => {
		this.setState({copyTest: new CopyTestState()});
	};

	private renderCopyTest() {
		return (
			(this.state.copyTest && (
				<CopyTest
					hasSelfAssess={!!this.state.test.selfAssessQuestions.length}
					testId={this.state.testId}
					close={() => this.setState({copyTest: null})}
					onSave={() => this.setState({testWasCopied: true})}
					onChange={(ch, cb) => this.setState({copyTest: ch}, cb)}
					name={this.state.testName.name + ' (copy)'}
					state={this.state.copyTest}
					stateStandard={this.state.testData.stateStandard}
					color={this.state.testData.color.selectedColor}
					contentArea={
						this.state.contentAreas.find(
							(ca) => ca.id === this.state.testData.contentAreaId,
						).name
					}
					gradeLevels={this.state.testData.selectedGradeLevels}
					testType={TestType[this.state.test.type]}
					owner={this.owner}
					description={this.state.testData.description.value}
					questions={this.state.questionPanel.questions}
					isWhiteBackground={this.state.isWhiteBackground}
					createdDate={this.state.createDate}
					stateStandardIDs={this.state.testData.stateStandardIDs}
				/>
			)) ||
			null
		);
	}

	private testCopied = (args: TestCopied.Args) => {
		this.setState({copyTest: null});
		OldAlerts.bsconfirm({
			title: 'Your new test has been created.',
			message: 'Would you like to view the details now?',
			className: 'test-copy-open-copied',
			modal: {
				buttons: [
					{
						title: 'CANCEL',
						className: 'btn btn-primary btn-transparent ',
						closeModal: true,
					},
					{
						title: 'VIEW DETAILS',
						closeModal: true,
						className: 'btn btn-primary btn-transparent',
						onClick: () => {
							// Set copied test name to TestName input
							if (args.copiedName) {
								const newTestName = {...this.state.testName};
								newTestName.name = args.copiedName;
								this.setState({testName: newTestName});
							}

							const updTestData = {...this.state.testData};
							this.setState({
								testData: updTestData,
								questionPanel: {
									...this.state.questionPanel,
									viewMode: ViewMode.TeacherOneOnOne,
								},
							});

							this.initTestData(args.testId);
						},
					},
				],
			},
		});
	};

	private testBackgroundChanged() {
		if (
			this.state.questionPanel.questions.length > 0 ||
			this.state.questionPanel.testIntroTile.testId > 0
		) {
			this.setState({changeBackgroundAlert: true});
		} else if (this.state.testId) {
			Api.updateBackgroundColor({
				testID: this.state.testId,
				isWhiteBackground: !this.state.isWhiteBackground,
			});
			this.setState({isWhiteBackground: !this.state.isWhiteBackground});
		} else {
			this.setState({isWhiteBackground: !this.state.isWhiteBackground});
		}
	}

	private changeTestBackground() {
		const newState = {...this.state};

		newState.questionPanel.questions = this.state.questionPanel.questions.map(
			(x) => {
				const q = {...x};
				q.questionImageIsProcessing = true;
				q.questionImagePregenerated = false;
				return q;
			},
		);

		newState.questionPanel.testIntroTile.imageIsProcessing = true;
		newState.questionPanel.testIntroTile.pregenerated = false;
		newState.changeBackgroundAlert = false;

		this.setState(newState, () => {
			if (this.state.testId > 0) {
				this.loader.mask();
				Api.updateBackgroundColor({
					testID: this.state.testId,
					isWhiteBackground: !this.state.isWhiteBackground,
				}).subscribe(() => {
					const newState = {...this.state};

					newState.questionPanel.questions =
						this.state.questionPanel.questions.map((x) => {
							const q = {...x};
							q.questionImagePregenerated = false;
							q.questionImageIsProcessing = false;
							q.modifyTicks = new Date().getTime();
							return q;
						});

					newState.questionPanel.testIntroTile.imageIsProcessing = false;
					newState.questionPanel.testIntroTile.modifyTicks =
						new Date().getTime();
					newState.isWhiteBackground = !this.state.isWhiteBackground;

					this.loader.unmask();

					this.setState(newState, () =>
						dispatchAppEvent(
							TestBackgroundChangedEvent,
							new TestBackgroundChangedEvent(
								this.state.testId,
								this.state.isWhiteBackground,
							),
						),
					);
				});
			}
		});
	}

	private updateMostRecentColors(colors: string[]) {
		Api.updateMostRecentColors({
			userID: this.props.userID,
			colors: colors,
		}).subscribe(() => {
			const newState = {...this.state.questionPanel};
			newState.mostRecentColors = colors;
			this.setState({questionPanel: newState});
		});
	}

	private addToSubject(subjectId: number, subjectType: SubjectType) {
		return SubjectEntity.addTest(subjectId, subjectType, this.state.testId);
	}

	private selectSubject = (subjectId: number) => {
		const subjects = this.state.subjects.map((s) => {
			if (s.id === subjectId) {
				s.selected = true;
			}

			return s;
		});

		this.setState({subjects: subjects});
	};

	private updateSubjects = (
		subjectId: number,
		name: string,
		type: SubjectType,
	) => {
		const {subjects} = this.state;
		const newSubject = new Subject();
		newSubject.id = subjectId;
		newSubject.name = name;
		newSubject.type = type;
		newSubject.selected = false;

		const newSubjects = subjects.concat([newSubject]);
		this.setState({subjects: newSubjects});
	};

	get dirty(): boolean {
		return (
			(this.state.testName && this.state.testName.dirty) ||
			(this.state.testData && this.state.testData.dirty) ||
			(this.state.questionPanel && this.state.questionPanel.dirty) ||
			(this.state.test?.type === TestType.Score &&
				this.state.questionPanel.totalPossibleState &&
				this.state.questionPanel.totalPossibleState.dirty)
		);
	}

	get valid(): boolean {
		if (this.state.testName.validation.valid === ValidationStatus.Invalid) {
			return false;
		}

		if (
			this.state.test.type === TestType.Score &&
			this.state.questionPanel.totalPossibleState &&
			this.state.questionPanel.totalPossibleState.dirty &&
			!this.state.questionPanel.totalPossibleState.valid
		) {
			return false;
		}

		return true;
	}

	get dirtyAsDraft(): boolean {
		return this.dirty;
	}

	get validAsDraft(): boolean {
		return (
			this.state.testData.testIdnCorrect &&
			(this.state.testName.validation.valid === ValidationStatus.Valid ||
				!!this.draftTestName) &&
			(this.state.test?.type != TestType.Score ||
				this.state.questionPanel.totalPossibleState.valid) &&
			this.state.questionPanel.questions.length > 0
		);
	}

	get canSave(): boolean {
		if (
			this.state.test &&
			this.state.testName.name &&
			this.state.questionPanel.questions.length
		) {
			if (
				this.state.test.type === TestType.Score &&
				!this.state.totalPossibleTouched &&
				!this.state.questionPanel.totalPossibleState.value
			) {
				this.setState({totalPossibleTouched: true});
			}
		}

		return this.dirty && this.valid;
	}

	get canSaveAsDraft(): boolean {
		return this.dirtyAsDraft && this.validAsDraft;
	}

	get hasQuestions(): boolean {
		const questions = this.state.questionPanel.questions;
		return questions && questions.length > 0;
	}

	get needDraftConfirm(): boolean {
		return this.canSaveAsDraft;
	}

	get needConfirm(): boolean {
		return this.canSave;
	}

	get needDelete(): boolean {
		return this.state.autoSaved && this.state.testId !== 0;
	}

	get isSaving(): boolean {
		return (
			this.state.saving ||
			(this.state.questionPanel && this.state.questionPanel.saving)
		);
	}

	get owner(): string {
		return (
			this.state.test.testOwner ||
			this.props.firstName + ' ' + this.props.lastName
		);
	}

	get displaySelfAssessArea(): boolean {
		return (
			this.state.test.selfAssessQuestions?.length > 0 ||
			this.props.viewMode === ViewMode.SelfAssess
		);
	}

	private closeClick() {
		if (this.state.readOnly) {
			this.closeModal();
		}

		if (this.needDraftConfirm) {
			this.showConfirmCancelDialog();
		} else {
			// Delete test only if it is just saved
			if (this.needDelete) {
				this.deleteTest(this.state.testId);
			}
			this.closeModal();
		}
	}

	private async validate() {
		const valids = [];
		valids.push(await this.testData.validate());
		valids.push(await this.questionPanel.validate());
		valids.push(await this.testName.validate());

		return valids.reduce((a, b) => a && b);
	}

	private async saveClick() {
		const valid = await this.validate();
		if (!valid) {
			return;
		}

		if (!this.valid) {
			return;
		}

		if (this.hasQuestions && this.props.subjectsForModal && this.isNew) {
			this.openAddTestToSubject();
		} else if (this.needConfirm && !this.hasQuestions) {
			this.showConfirmSaveDialog();
		} else {
			this.saveTestAndClose();
		}
	}

	private closeModal(changedTest?, savedAsDraft?: boolean) {
		if (this.questionListChanged) {
			this.eventBus.dispatch(
				TestQuestionListChanged,
				new TestQuestionListChanged(this.state.testId),
			);
		}

		if ((this.isNew && changedTest) || this.state.testWasCopied || savedAsDraft) {
			this.props.onTestCreated?.();
		}

		if (this.props.close) {
			this.props.close(this.state.questionPanel.totalPossibleState.value, this.state.testWasCopied ? null : changedTest);
		}
		this.eventBus.dispatch(TestQuestionListChanged, new TestQuestionListChanged(this.state.testId));
	}

	private saveTestAndClose() {
		this.saveTest((testID, respName, state, changedTest) => {
			this.closeModal(changedTest);
		});
	}

	private openAddTestToSubject() {
		this.setState({
			addTestToSubject: new AddTestToSubjectState(),
			addTestToSubjectOpen: true,
			needSaveTestAfterAddToSubject: true,
		});
	}

	private closeAddTestToSubject() {
		// addTestToSubject is not set to null if closeAddTestToSubject is called from event handler,
		// so we need this additional addTestToSubjectOpen flag which works fine.
		this.setState({addTestToSubject: null, addTestToSubjectOpen: false});
	}

	private addTestToSubjectLater() {
		this.closeAddTestToSubject();
		this.saveTestAndClose();
		if (this.props.onTestCreated) {
			this.props.onTestCreated();
		}
	}

	private clearSS = () => {
		this.setState({
			testData: {
				...this.state.testData,
				stateStandardID: null,
				stateStandard: null,
				stateStandards: null,
				stateStandardIDs: null,
			},
			defaultTest: {
				...this.state.defaultTest,
				stateStandardID: null,
				stateStandard: null,
				stateStandards: null,
				stateStandardIDs: null,
			},
			test: {
				...this.state.test,
				stateStandardID: null,
				stateStandard: null,
				stateStandards: null,
				stateStandardIDs: null,
			},
		});
	};

	private onStateStandardDetached() {
		const questions = this.state.questionPanel.questions;
		questions.forEach((question) => {
			if (question.stateStandardID !== null) {
				question.stateStandardID = null;
				Api.updateQuestionStateStandard({
					questionID: question.id,
					stateStandardID: question.stateStandardID,
				}).subscribe();
			}
		});
		const questionPanel = this.state.questionPanel;
		questionPanel.questions = questions;
		this.setState({
			questionPanel: questionPanel,
			testData: {
				...this.state.testData,
				stateStandardID: null,
				stateStandard: null,
				stateStandards: null,
				stateStandardIDs: null,
			},
			defaultTest: {
				...this.state.defaultTest,
				stateStandardID: null,
				stateStandard: null,
				stateStandards: null,
				stateStandardIDs: null,
			},
			test: {
				...this.state.test,
				stateStandardID: null,
				stateStandard: null,
				stateStandards: null,
				stateStandardIDs: null,
			},
		});
	}

	private showConfirmCancelDialog() {
		if (!this.hasQuestions) {
			OldAlerts.bsconfirm({
				className: 'confirm-dialog',
				message: 'Do you want to save the test as a draft?',
				modal: {
					buttons: [
						{
							title: 'No, discard',
							closeModal: true,
							onClick: () => {
								// Delete test only if it is just saved
								if (this.needDelete) {
									this.deleteTest(this.state.testId);
								}
								this.closeModal();
							},
							cancel: true,
						},
						{
							title: 'Yes, save as draft',
							closeModal: true,
							onClick: () => {
								this.saveDraft();
							},
						},
					],
				},
			});
		} else if (
			this.state.testId === 0 ||
			this.state.autoSaved ||
			this.state.test.draft
		) {
			let buttons;
			let message;

			if (this.state.testData.description.value && this.state.testName.name) {
				message =
					'If you don`t save, the test will remain a Draft and cannot be used for assessment.';
				buttons = [
					{
						className: 'btn btn-primary btn-transparent',
						title: 'Keep as Draft',
						closeModal: true,
						onClick: () => {
							this.saveDraft();
						},
					},
					{
						className: 'btn btn-primary btn-transparent',
						title: 'Yes, save',
						closeModal: true,
						onClick: () => {
							this.saveTestAndClose();
						},
					},
				];
			} else {
				const getMessagePart = () => {
					if (
						!this.state.testName.name &&
						!this.state.testData.description.value
					) {
						return 'Test Name and Description ';
					} else if (!this.state.testData.description.value) {
						return 'Description ';
					} else if (!this.state.testName.name) {
						return 'Test Name ';
					}
				};

				message = `<p>We don't want you to lose your work, so we will be saving this test as a Draft.</p>
                               <p>Select <b>Go Back</b> and enter a ${getMessagePart()} to save this test for assessments.</p>
                               <p>Select <b>Save as Draft</b> to save this test to your 'My Drafts' tests in Test Explorer.</p>`;

				buttons = [
					{
						title: 'Go back',
						closeModal: true,
					},
					{
						title: 'Save as draft',
						closeModal: true,
						onClick: () => {
							this.saveDraft();
						},
					},
				];
			}

			OldAlerts.bsconfirm({
				title: 'Save My Test?',
				className: 'save-test-alert confirm-dialog',
				message: message,
				modal: {
					buttons: buttons,
				},
			});
		} else {
			this.closeModal();
		}
	}

	private toggleModalVisibility = () => {
		this.setState((prev: State) => ({...prev, isHidden: !prev.isHidden}));
	};

	private deleteQuestionHandler = (questionId: number) => {
		this.setState((prev: State) => ({
			...prev,
			test: {
				...prev.test,
				selfAssessQuestions: prev.test.selfAssessQuestions.filter(
					(x) => x.id !== questionId,
				),
			},
		}));
	};

	private showConfirmSaveDialog() {
		OldAlerts.bsconfirm({
			className: 'confirm-dialog',
			message: 'You do not have any questions saved to the test.',
			modal: {
				buttons: [
					{
						title: 'ADD QUESTIONS',
						closeModal: true,
					},
					{
						title: 'SAVE AS DRAFT',
						closeModal: true,
						onClick: () => {
							this.saveDraft();
						},
					},
				],
			},
		});
	}

	private renderAddToSubject() {
		if (!this.state.addTestToSubject || !this.state.addTestToSubjectOpen) {
			return null;
		}

		return (
			<AddTestToSubject
				state={this.state.addTestToSubject}
				title={() => (
					<span>
						Would you like to add
						<span className='test-name'>
							<i>&nbsp;{this.state.testName.name}&nbsp;&nbsp;</i>
						</span>
						to a Subject Tab?
					</span>
				)}
				onChange={(ch, cb) => this.setState({addTestToSubject: ch}, cb)}
				mySubjects={this.props.subjectsForModal.filter(
					(s) => !s.Tests.some((t) => t.TestID == this.state.testId),
				)}
				testName={this.state.testName.name}
				testId={this.state.testId}
				close={() => this.addTestToSubjectLater()}
				saved={() => {
					this.props.onTestCreated?.();
				}}
				disableNewSubjectBtn={this.props.disableNewSubjectBtn}
			/>
		);
	}

	render() {
		const isNew = this.state.testId == 0 || this.state.autoSaved;
		return (
			<>
				{this.state.changeBackgroundAlert && (
					<Modal
						animate={true}
						className={
							'mx-question-dirty-modal white-header responsive alert-modal-white'
						}
					>
						<ModalHeader>
							<h3>Change background color</h3>
						</ModalHeader>
						<ModalBody>
							<p>
								Do you wish to change the background color of this test's
								questions? Please note that existing elements may display
								differently with a new background.
							</p>
							<p>
								There may be an extended delay to complete the change for tests
								with a large number of questions.You can close the test during
								this process and the change will appear the next time you open
								the test.
							</p>
						</ModalBody>
						<ModalFooter>
							<button
								className='btn btn-primary btn-transparent'
								onClick={() => this.setState({changeBackgroundAlert: false})}
							>
								NO
							</button>
							<button
								className='btn btn-primary btn-transparent'
								onClick={() => this.changeTestBackground()}
							>
								Yes
							</button>
						</ModalFooter>
					</Modal>
				)}
				<Modal
					animate={true}
					loading={!this.loaded}
					className={`test-details ${this.state.isHidden && 'hidden'}`}
				>
					<ModalBody>
						{this.state.test && (
							<>
								{isNew && <span className='create-title'>Create Test</span>}
								<TestName
									hasSelfAssessVersion={this.displaySelfAssessArea}
									ref={(r) => (this.testName = r)}
									state={this.state.testName}
									onChange={(ch, cb) => this.setState({testName: ch}, cb)}
									name={this.state.test.name}
									owner={this.owner}
									createDate={this.state.createDate}
									starred={this.state.test.starred}
									hide={this.state.test.hidden}
									canEdit={!this.state.test.readonly}
									starClickHandler={() => this.starSwitch()}
									hideClickHandler={() => this.hideSwitch()}
									copyTestClickHandler={() => this.openCopyTestModal()}
									testType={this.state.test.type}
									isNew={isNew}
									draft={this.state.test.draft}
									canCopy={!this.props.disableCopyTest}
									testId={this.state.testId}
								/>

								<div className='test-data-container'>
									<TestData
										isSelfAssess={this.props.viewMode === ViewMode.SelfAssess}
										ref={(ref) => (this.testData = ref)}
										state={this.state.testData}
										onChange={(ch, cb) => this.setState({testData: ch}, cb)}
										testId={this.state.testId}
										isNew={isNew}
										readOnly={this.state.readOnly}
										testType={this.state.test.type}
										testIdn={this.state.test.testIDN}
										description={this.state.test.description}
										contentAreas={this.state.contentAreas}
										contentAreaId={this.state.test.contentAreaID}
										stateStandard={this.state.test.stateStandard}
										stateStandardId={this.state.test.stateStandardId}
										stateStandards={this.state.test.stateStandards}
										stateStandardIDs={this.state.test.stateStandardIDs}
										testColor={this.state.test.color}
										selectedGradeLevels={this.state.test.gradeLevelIDs}
										draft={this.state.test.draft}
										shared={this.state.shared}
										canUnshare={this.state.test.canUnshare}
										canModifyTestID={this.state.canModifyTestID}
										shareChangedHandler={(shared: boolean) =>
											this.setState({shared: shared})
										}
										questionsCount={
											this.state.questionPanel.questions
												? this.state.questionPanel.questions.length
												: 0
										}
										canAddToSubject={
											this.props.canAddToSubject == null
												? true
												: this.props.canAddToSubject
										}
										testName={this.state.testName?.name}
										stateStandardDetached={() => this.onStateStandardDetached()}
										totalPossible={
											this.state.questionPanel?.totalPossibleState?.value
										}
										onStandardAffectingChange={this.onStandardAffectingChange}
										disableNewSubjectBtn={this.props.disableNewSubjectBtn}
									/>
									<QuestionPanel
										viewMode={this.props.viewMode}
										onQuestionDelete={this.deleteQuestionHandler}
										displaySelfAssessArea={this.displaySelfAssessArea}
										selfAssessQuestions={this.state.test.selfAssessQuestions}
										mostRecentColorsChanged={(colors) =>
											this.updateMostRecentColors(colors)
										}
										ref={(r) => (this.questionPanel = r)}
										state={this.state.questionPanel}
										onChange={(ch, cb) =>
											this.setState({questionPanel: ch}, cb)
										}
										testId={this.state.testId}
										canEdit={!this.state.readOnly}
										verificationModalWasShown={this.state.verificationModalWasShown}
										testType={this.state.test.type}
										testName={
											(this.state.test && this.state.test.name) ||
											this.state.testName.name ||
											this.draftTestName
										}
										saveTest={this.preSaveTest}
										totalPossible={this.state.test.totalPossible}
										totalPossibleTouched={this.state.totalPossibleTouched}
										contentAreaId={this.state.test.contentAreaID}
										isWhiteBackground={this.state.isWhiteBackground}
										backgroundChanged={() => this.testBackgroundChanged()}
										questionListChanged={() =>
											(this.questionListChanged = true)
										}
										gradeLevelIDs={this.state.test.gradeLevelIDs}
										contentArea={this.state.test.contentArea}
										stateStandard={this.state.test.stateStandard}
										toggleModalVisibility={this.toggleModalVisibility}
										onStandardAffectingChange={this.onStandardAffectingChange}
										clearSS={this.clearSS}
										stateStandardIDs={this.state.test.stateStandardIDs}
									/>
								</div>
								{this.renderAddToSubject()}
								{this.renderCopyTest()}
							</>
						)}
					</ModalBody>
					<ModalFooter>
						<div className='test-details-footer'>
							<div className='item'>
								{!this.state.readOnly && (
									<>
										<Button onClick={this.reset} title={'Reset'} />
										<OnHoverTooltip message='Restores previously saved test information (standards, description, etc.). Any recent changes will be lost'>
											<i className='fa fa-question-circle warning'></i>
										</OnHoverTooltip>
									</>
								)}
							</div>
							<div className='item'>
								<div className='save-close'>
									<Button
										disabled={this.isSaving}
										onClick={() => this.closeClick()}
										title={this.state.readOnly ? 'Close' : 'Cancel'}
									/>
									{!this.state.readOnly && (
										<div ref={(r) => (this.saveButtonRef = r)}>
											<Primary
												className='save-btn'
												disabled={this.isSaving}
												onClick={() => this.saveClick()}
											>
												<span>
													{this.state.saving && (
														<i className='fa fa-circle-o-notch fa-spin' />
													)}
													Save and Close
												</span>
											</Primary>

											{this.saveButtonRef &&
												!!(
													this.saveButtonRef.offsetLeft ||
													this.saveButtonRef.offsetTop
												) &&
												!this.valid && (
													<HoverTooltip
														element={this.saveButtonRef}
														placement={'right'}
														container='body'
													>
														Complete all required fields
													</HoverTooltip>
												)}
										</div>
									)}
								</div>
							</div>
						</div>
					</ModalFooter>
				</Modal>
				{this.state.showRemoveStandardDialog && <RemoveStandardDialog
					onClose={this.onRemoveStandardDialogClose}
					onContinueEditing={this.onRemoveStandardDialogContinue}
				/>}
			</>
		);
	}
}
