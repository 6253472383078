import {useMemo} from 'react';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui';
import {SelectedEntityInfo, SelectedEntityInfoItem} from '@esgi/main/features/admins/dashboard';
import {ClassModel} from '../../types';

type Props = {
	classIDs: number[];
	allClassesList: ClassModel[];
};

export function SelectedClassesInfo({classIDs, allClassesList}: Props) {
	const classesItems = useMemo(() => {
		return classIDs
			.map<SelectedEntityInfoItem<number> | null>((id) => {
				const classModel = allClassesList.find(({classID}) => classID === id);

				if (isUndefined(classModel)) {
					return null;
				}

				return {
					label: classModel.className,
					value: classModel.classID,
				};
			})
			.filter((item): item is SelectedEntityInfoItem<number> => !isNull(item));
	}, [allClassesList, classIDs]);

	return (
		<SelectedEntityInfo
			items={classesItems}
			itemsTranscript='Classes'
			totalPossibleItemsCount={allClassesList.length}
		/>
	);
}
