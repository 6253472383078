import React, {useCallback, useState} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Drawer} from '@esgi/main/kits/common';
import {ToggleGroup} from '@esgi/ui/controls';
import {useStreamEffect} from '@esgi/ui/utils';
import {ClassModel} from '@esgi/contracts/esgi/types/esgi.students/queries/esgi.apigateway/modules/forms/student/class-model';
import {SchoolSpecialist} from '@esgi/contracts/esgi/types/sso.accounts/features/esgi.districtadmin/schools-specialist/get-by-school-id/school-specialist';
import {DistrictSpecialist} from '@esgi/contracts/esgi/types/sso.accounts/features/esgi.districtadmin/district-specialist/get-by-district-id/district-specialist';
import {StyledCheap, StyledContainer, StyledPanel} from './index.styled';
import {AssignStudentType, UserModel} from '../../types';
import {BySpecialist} from './components/by-specialist';
import {ByTeacher} from './components/by-teacher';
import {TransferStudentForm} from '../../form';
import {FormElement} from '../form-element';

interface Props {
	form: TransferStudentForm;
	teacher: UserModel;
	classes: ClassModel[];
	schoolSpecialists: SchoolSpecialist[];
	districtSpecialists: DistrictSpecialist[];
}

export function AssignStudent({form, teacher, classes, schoolSpecialists, districtSpecialists}: Props) {
	const [teacherCount, setTeacherCount] = useState(() => (
		form.value.teacherEntityIDs.groupIDs.length + form.value.teacherEntityIDs.classIDs.length
	));

	const [specialistCount, setSpecialistCount] = useState(() => (
		form.value.specialistGroupIDs.length
	));

	const [type, setType] = useState(AssignStudentType.Teacher);

	const handleTypeChange = useCallback((value: AssignStudentType) => {
		if (!value) {
			return;
		}

		setType(value);
	}, []);

	useStreamEffect(form.controls.teacherEntityIDs.onChanged, (changed) => {
		setTeacherCount(changed.currState.value.groupIDs.length + changed.currState.value.classIDs.length);
	});

	useStreamEffect(form.controls.specialistGroupIDs.onChanged, (changed) => {
		setSpecialistCount(changed.currState.value.length);
	});

	return (
		<StyledContainer>
			<ToggleGroup.Root value={type} onValueChange={handleTypeChange}>
				<ToggleGroup.Content>
					<ToggleGroup.Item value={AssignStudentType.Teacher}>
						<Text size='medium' bold>
							By Teacher
						</Text>
						<StyledCheap custom>
							<Text size='small' font='mono' color='primary'>
								{teacherCount}
							</Text>
						</StyledCheap>
					</ToggleGroup.Item>
					<ToggleGroup.Item value={AssignStudentType.Specialist}>
						<Text size='medium' bold>
							By Specialist
						</Text>
						<StyledCheap custom>
							<Text size='small' font='mono' color='primary'>
								{specialistCount}
							</Text>
						</StyledCheap>
					</ToggleGroup.Item>
				</ToggleGroup.Content>
			</ToggleGroup.Root>
			{type === AssignStudentType.Teacher && classes && teacher && (
				<FormElement control={form.controls.teacherEntityIDs} disableValidateOnChange>
					<Drawer.ContentBlock title='Primary Teacher Groups'>
						<ByTeacher teacher={teacher} classes={classes} />
					</Drawer.ContentBlock>
				</FormElement>
			)}
			{type === AssignStudentType.Specialist && (
				<FormElement control={form.controls.specialistGroupIDs}>
					<GridBox gap={4}>
						{schoolSpecialists?.length > 0 && (
							<Drawer.ContentBlock title='School Specialists'>
								<StyledPanel>
									<BySpecialist specialistList={schoolSpecialists} />
								</StyledPanel>
							</Drawer.ContentBlock>
						)}
						<Drawer.ContentBlock title='District Specialists'>
							<StyledPanel>
								<BySpecialist specialistList={districtSpecialists} />
							</StyledPanel>
						</Drawer.ContentBlock>
					</GridBox>
				</FormElement>
			)}
		</StyledContainer>
	);
}