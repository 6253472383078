import React, {useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {SelectTitleWrapper} from '../index.styled';
import {isNull} from 'underscore';
import {calendarCurrent} from './constants';
import {DatesPeriodModel, PeriodType, SchoolYearModel, SelectOptionModel} from '../../../types';
import {enumRemap} from 'shared/utils';
import {CurrentTrackDate} from '../../../hooks/use-current-track-dates/types';
import {isUndefined} from '@esgi/ui';
import {SelectOption} from '../select-option';

interface Props {
	currentGlobalActiveSchoolYear: SchoolYearModel | null;
	currentTrackDate: DatesPeriodModel | null;
	currentTracks: CurrentTrackDate[];
}

export function CurrentTabContent({currentGlobalActiveSchoolYear, currentTrackDate, currentTracks}: Props) {
	const currentMarkingPeriod = useMemo<SelectOptionModel | null>(() => {
		if (isNull(currentTrackDate)) {
			return null;
		}

		const currentTrack = currentTracks.find(({id}) => id === currentTrackDate.trackDateID);

		if (isUndefined(currentTrack)) {
			return null;
		}

		return {
			value: enumRemap(PeriodType.CurrentMarkingPeriod, PeriodType),
			label: currentTrack.name,
		};
	}, [currentTrackDate, currentTracks]);

	return (
		<>
			<SelectTitleWrapper>
				<Text size='small' color='lowContrast'>
					Calendar
				</Text>
			</SelectTitleWrapper>

			{calendarCurrent.map(({value, label}) => (
				<SelectOption value={value} label={label} key={label} />
			))}

			{!isNull(currentMarkingPeriod) && (
				<>
					<SelectTitleWrapper>
						<Text size='small' color='lowContrast'>
							Marking Periods
						</Text>
					</SelectTitleWrapper>

					<SelectOption
						value={currentMarkingPeriod.value}
						label='Current Marking Period'
						additionalLabel={currentMarkingPeriod.label}
					/>
				</>
			)}

			{!isNull(currentGlobalActiveSchoolYear) && (
				<>
					<SelectTitleWrapper>
						<Text size='small' color='lowContrast'>
							School Years
						</Text>
					</SelectTitleWrapper>

					<SelectOption
						value={enumRemap(PeriodType.CurrentSchoolYear, PeriodType)}
						label='Current School Year'
						additionalLabel={currentGlobalActiveSchoolYear.name}
					/>
				</>
			)}
		</>
	);
}
