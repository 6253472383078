import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ClassesBox = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: 6,
	alignItems: 'center',
});

export const Dot = styled(Box, {
	width: 1,
	height: 1,
	borderRadius: '50%',
	backgroundColor: '$highContrast',
});
