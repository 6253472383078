import {SettingsTab, TabItemsGroup} from '../../types';

export const tabItems: TabItemsGroup = {
	general: [
		{
			value: SettingsTab.SystemSettings,
			name: 'System Settings',
		},
		{
			value: SettingsTab.TeacherSettings,
			name: 'Teacher Settings',
		},
	],
};


export const initialSettingsTabsData = {
	[SettingsTab.SystemSettings]: false,
	[SettingsTab.TeacherSettings]: false,
};
