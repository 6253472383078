import {SpecialistGroup, Student, User} from '@esgi/main/libs/admin-store';
import {isUndefined, useStreamEffect} from '@esgi/ui';
import {ElementStatus, FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {UserType} from '../../types';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {DistrictSpecialistForm} from './types';
import {groupNameMaxLength} from '../../../components/group-name-input/constants';
import {enumRemap} from 'shared/utils';

type Parameters = {
	specialistGroups: SpecialistGroup[];
	districtSpecialists: User[];
	isDistrictSpecialistsLoaded: boolean;
	setIsFormValid: Dispatch<boolean>;
	setIsFormTouched: Dispatch<boolean>;
};

export function useDistrictSpecialistForm({
	specialistGroups,
	setIsFormTouched,
	setIsFormValid,
	districtSpecialists,
	isDistrictSpecialistsLoaded,
}: Parameters) {
	const [formInactiveMessage, setFormInactiveMessage] = useState<string | null>(null);

	const form: DistrictSpecialistForm = useForm(
		() =>
			new FormGroup({
				districtSpecialistID: new FormControl<string[]>([], {validators: [Validators.required()]}),
				groupName: new FormControl('', {
					initialValidateStatus: ElementStatus.disabled,
				}),
				studentsIDs: new FormControl<Student['id'][]>([]),
			}),
	);

	useEffect(() => {
		if (isDistrictSpecialistsLoaded && !districtSpecialists.length) {
			form.status = ElementStatus.disabled;

			setFormInactiveMessage('There are no district specialists in the district');
		}
	}, [districtSpecialists, form, isDistrictSpecialistsLoaded]);

	useStreamEffect(form.controls.districtSpecialistID.onChanged, ({currState: {value}, reason}) => {
		const districtSpecialistID = value[0];

		if (!isUndefined(districtSpecialistID) && reason === 'value') {
			const numberedDistrictSpecialistID = Number(districtSpecialistID);

			const districtSpecialistGroups = specialistGroups.filter(
				({specialistID, userType}) =>
					enumRemap(userType, UserType) === UserType.ISD && specialistID === numberedDistrictSpecialistID,
			);

			form.controls.groupName.validators.length = 0;

			form.controls.groupName.validators.push(
				Validators.required(),
				Validators.length.max(groupNameMaxLength),
				Validators.isDublicateValue(districtSpecialistGroups.map(({name}) => name)),
			);

			form.controls.groupName.status = ElementStatus.untouched;

			form.validate().subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		}
	});

	const checkFormOnTouchValid = useCallback(() => {
		const {groupName, districtSpecialistID, studentsIDs} = form.value;

		const isFormTouched = groupName !== '' || !isUndefined(districtSpecialistID[0]) || Boolean(studentsIDs.length);

		setIsFormTouched(isFormTouched);

		form.validate(true).subscribe(({valid}) => {
			setIsFormValid(valid);

			if (isUndefined(form.value.districtSpecialistID[0])) {
				form.controls.groupName.status = ElementStatus.disabled;
			}
		});
	}, [form, setIsFormTouched, setIsFormValid]);

	useStreamEffect(form.onChanged, checkFormOnTouchValid);

	return {
		form,
		checkFormOnTouchValid,
		formInactiveMessage,
	};
}
