import {PublishToSpecialistsNumerable} from '@esgi/main/features/admins/subject-management';

type Parameters = {
	publishToSpecialists: PublishToSpecialistsNumerable;
	selectedSchoolSpecialistsIDs: number[];
	allSchoolSpecialistsIDs: number[];
};

export function getPublishForSpecialistsStoreData({
	publishToSpecialists,
	selectedSchoolSpecialistsIDs,
	allSchoolSpecialistsIDs,
}: Parameters): number[] {
	if (publishToSpecialists === PublishToSpecialistsNumerable.None) {
		return [];
	}

	if (publishToSpecialists === PublishToSpecialistsNumerable.All) {
		return [...allSchoolSpecialistsIDs];
	}

	if (publishToSpecialists === PublishToSpecialistsNumerable.IndividualISS) {
		return [...selectedSchoolSpecialistsIDs];
	}

	return [];
}
