import {HideIconBox, SubjectGridBox, SubjectText} from './index.styled';
import {Hide} from '@esgi/ui/icons';
import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {District as DistrictCheap} from '../cheap/district';
import {School as SchoolCheap} from '../cheap/school';
import {Teacher as TeacherCheap} from '../cheap/teacher';
import {useSubjectPanelContext} from '../../context';
import {SubjectLevel} from '@esgi/main/kits/common';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<typeof SubjectGridBox>, 'children' | 'skeleton'> & {
		subjectLevel: SubjectLevel;
		subjectName: string;
		hidden: boolean;
	};

export const SubjectName = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'subject-name', css = {}, subjectLevel, subjectName, hidden, ...props}, forwardedRef) => {
		const {showSubjectsCheap, skeleton} = useSubjectPanelContext();

		return (
			<SubjectGridBox
				dataCy={dataCy}
				css={css}
				withCheapBox={showSubjectsCheap}
				withHideIcon={hidden}
				ref={forwardedRef}
				{...props}
			>
				{showSubjectsCheap && (
					<>
						{subjectLevel === SubjectLevel.District && <DistrictCheap />}
						{subjectLevel === SubjectLevel.School && <SchoolCheap />}
						{subjectLevel === SubjectLevel.Teacher && <TeacherCheap />}
					</>
				)}

				{hidden && (
					<HideIconBox skeleton={skeleton}>
						<Hide width={20} height={20} />
					</HideIconBox>
				)}

				<SubjectText
					data-cy={`${dataCy}-title`}
					size='medium'
					bold
					color={hidden ? 'muted' : 'base'}
					skeleton={skeleton}
				>
					{!skeleton && subjectName}
				</SubjectText>
			</SubjectGridBox>
		);
	},
);
