import {useMemo} from 'react';
import {RubricAnswer, RubricCriterion} from '../../../../../types/test-panel';
import {GridProgressBarItem} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {isNull} from 'underscore';
import {TestCard} from '@esgi/main/kits/test-results-panel';

type Props = {
	rubricAnswers: RubricAnswer[] | null;
	totalPossible: number;
	rubricCriteria: RubricCriterion[];
	correctAnswers: number | null;
	resultDelta: number | null;
};

export function LastSessionBarRubric({
	rubricAnswers,
	totalPossible,
	rubricCriteria,
	correctAnswers,
	resultDelta,
}: Props) {
	const items = useMemo(() => {
		const levelsCount = totalPossible / rubricCriteria.length;

		if (!isNull(rubricAnswers)) {
			return rubricAnswers.map<GridProgressBarItem>(({score}) => ({
				value: score,
				maxValue: levelsCount,
			}));
		}

		const emptyBarItem: GridProgressBarItem = {
			value: 0,
			maxValue: levelsCount,
		};

		return new Array(rubricCriteria.length).fill(emptyBarItem);
	}, [rubricAnswers, rubricCriteria, totalPossible]);

	return (
		<TestCard.Statistic.Charts.GridProgressBar items={items} progressBarTitle='Last Session Result'>
			<GridBox gapY='1'>
				<TestCard.AnswersInfo value={correctAnswers} maxValue={totalPossible} transcriptText='Last Score' />
				<TestCard.Statistic.Delta value={resultDelta} deltaTranscript='Since Last Test' />
			</GridBox>
		</TestCard.Statistic.Charts.GridProgressBar>
	);
}
