import {isNull} from 'underscore';
import {Avatar} from '@esgi/ui/avatar';
import {Delete, Upload} from '@esgi/ui/icons';
import {MouseEvent, useCallback, useRef, useState} from 'react';
import {Button, CroppedImage} from '@esgi/ui';
import {CropImageAlert} from './components/crop-image-alert';
import {DeleteImageAlert} from './components/delete-image-alert';
import {FileInputHidden} from './components/file-input-hidden';

type Props = {
	logo: CroppedImage;
	setLogo: (value: CroppedImage | null) => void;
};

export function WithLogo({logo, setLogo}: Props) {
	const [uploadedImage, setUploadedImage] = useState<string | null>(null);

	const [openCropImageUpdateAlert, setOpenCropImageUpdateAlert] = useState(false);
	const [openCropImageNewAlert, setOpenCropImageNewAlert] = useState(false);
	const [openDeleteImageAlert, setOpenDeleteImageAlert] = useState(false);

	const inputFileRef = useRef<HTMLInputElement>(null);

	const handleUploadFileClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		inputFileRef.current?.click();
	}, []);

	const handleDeleteFileClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();

		setOpenDeleteImageAlert(true);
	}, []);

	const onSetUploadedImage = useCallback((image: string) => {
		setUploadedImage(image);
		setOpenCropImageNewAlert(true);
	}, []);

	const isLocalImage = logo.image?.startsWith('data:');

	return (
		<>
			<Avatar.Root size='xl' borderType='solid' css={{borderColor: '$primary'}}>
				<Avatar.Image src={logo.croppedImage ?? undefined} />

				<Avatar.HoverLayer
					onClick={() => {
						if (isLocalImage) {
							setOpenCropImageUpdateAlert(true);
						}
					}}
					css={{
						cursor: isLocalImage ? 'pointer' : 'default',
					}}
				>
					<Button.Icon withBackgroundHover onClick={handleUploadFileClick}>
						<Upload />
					</Button.Icon>

					<Button.Icon withBackgroundHover onClick={handleDeleteFileClick}>
						<Delete />
					</Button.Icon>
				</Avatar.HoverLayer>
			</Avatar.Root>

			<FileInputHidden inputFileRef={inputFileRef} setUploadedImage={onSetUploadedImage} />

			{openCropImageUpdateAlert && (
				<CropImageAlert
					image={logo}
					onCloseAlert={() => setOpenCropImageUpdateAlert(false)}
					onSaveImage={(image) => {
						setLogo(image);
						setOpenCropImageUpdateAlert(false);
					}}
				/>
			)}

			{openCropImageNewAlert && !isNull(uploadedImage) && (
				<CropImageAlert
					image={{
						image: uploadedImage,
					}}
					onCloseAlert={() => {
						setOpenCropImageNewAlert(false);
						setUploadedImage(null);
					}}
					onSaveImage={(image) => {
						setLogo(image);
						setOpenCropImageNewAlert(false);
						setUploadedImage(null);
					}}
				/>
			)}

			{openDeleteImageAlert && (
				<DeleteImageAlert onCloseAlert={() => setOpenDeleteImageAlert(false)} onDeleteImage={setLogo} />
			)}
		</>
	);
}
