import {OneLinedText} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const SubjectGridBox = styled(GridBox, {
	width: '100%',
	alignItems: 'center',
	gap: 6,

	variants: {
		withHideIcon: {
			true: {},
			false: {},
		},

		withChildren: {
			true: {},
			false: {},
		},
	},

	compoundVariants: [
		{
			withHideIcon: true,
			withChildren: true,
			css: {
				gridTemplateColumns: '1fr auto auto',
			},
		},
		{
			withHideIcon: false,
			withChildren: true,
			css: {
				gridTemplateColumns: '1fr auto',
			},
		},
		{
			withHideIcon: true,
			withChildren: false,
			css: {
				gridTemplateColumns: '1fr auto',
			},
		},
		{
			withHideIcon: false,
			withChildren: false,
			css: {
				gridTemplateColumns: '1fr',
			},
		},
	],
});

export const HideIconBox = styled(GridBox, {
	alignSelf: 'start',

	'& svg': {
		width: 24,
		height: 24,

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				borderRadius: '50%',
			},
		},

		isActiveSubject: {
			false: {
				'& svg': {
					color: '$negative',
				},
			},

			true: {
				'& svg': {
					color: '$primary',
				},
			},
		},
	},
});

export const SubjectText = styled(OneLinedText, {
	wordBreak: 'break-word',

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				width: '100%',
				height: 12,
				borderRadius: 6,
			},
		},
	},
});
