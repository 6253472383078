import {
	DemographicPerformanceWidgetOptions,
	DemographicPerformanceWidgetOptionsContract,
} from '@esgi/main/features/district-admin/dashboard';
import {isNull} from 'underscore';
import {getAdaptedArraysWidgetData} from '../../helpers/get-adapted-arrays-widget-data';
import {enumNumberToString} from '../enum-number-to-string';
import {
	Color,
	ColorNumerable,
	ContentAreaModel,
	DemographicGroupType,
	DemographicGroupTypeNumerable,
	DeployedSubject,
	GradeLevel,
	PerformanceWidgetViewType,
	PerformanceWidgetViewTypeNumerable,
	PeriodType,
	PeriodTypeNumerable,
	SchoolModel,
	WidgetViewSize,
	WidgetViewSizeNumerable,
} from '@esgi/main/features/admins/dashboard';

export function parseDemographicPerformanceOptions({
	options,
	schools: schoolsList,
	deployedSubjects,
	gradeLevels,
	contentAreasList,
}: {
	options: DemographicPerformanceWidgetOptionsContract | null;
	schools: SchoolModel[];
	deployedSubjects: DeployedSubject[];
	gradeLevels: GradeLevel[];
	contentAreasList: ContentAreaModel[];
}): DemographicPerformanceWidgetOptions | null {
	if (isNull(options)) {
		return null;
	}

	const {
		contentAreaIDs,
		gradeLevelIDs,
		schoolIDs,
		subjectIDs,
		viewType,
		viewSize,
		demographicGroup,
		periods,
		candles,
		schools,
		...restOptions
	} = options;

	return {
		...restOptions,

		/**
		 * For the following entities, if the data array is empty, it means all entities are selected:
		 * - contentAreaIDs,
		 * - gradeLevelIDs,
		 * - schoolIDs,
		 * - subjectIDs
		 */

		contentAreaIDs: getAdaptedArraysWidgetData({
			data: contentAreaIDs,
			fullData: contentAreasList,
			iteratedKey: 'id',
		}),
		gradeLevelIDs: getAdaptedArraysWidgetData({
			data: gradeLevelIDs,
			fullData: gradeLevels,
			iteratedKey: 'id',
		}),
		schoolIDs: getAdaptedArraysWidgetData({
			data: schoolIDs,
			fullData: schoolsList,
			iteratedKey: 'id',
		}),
		subjectIDs: getAdaptedArraysWidgetData({
			data: subjectIDs,
			fullData: deployedSubjects,
			iteratedKey: 'id',
		}),
		viewType: enumNumberToString(viewType, PerformanceWidgetViewTypeNumerable) as PerformanceWidgetViewType,
		viewSize: enumNumberToString(viewSize, WidgetViewSizeNumerable) as WidgetViewSize,
		demographicGroup: enumNumberToString(demographicGroup, DemographicGroupTypeNumerable) as DemographicGroupType,
		candles: !isNull(options.candles)
			? {
					...candles,
					color: enumNumberToString(candles.color, ColorNumerable) as Color,
				}
			: null,
		schools: schools.map(({color, id}) => ({
			id,
			color: enumNumberToString(color, ColorNumerable) as Color,
		})),
		periods: periods.map(({color, datePeriod, dateRange, trackDatesID}) => ({
			dateRange: isNull(dateRange) ? null : dateRange,
			datePeriod: enumNumberToString(datePeriod, PeriodTypeNumerable) as PeriodType,
			trackDatesID,
			color: enumNumberToString(color, ColorNumerable) as Color,
		})),
	};
}
