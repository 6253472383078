import {ContentAreaModel, GradeLevel, WidgetContract} from '@esgi/main/features/admins/dashboard';
import {Widget, SubjectTab, ClassModel, TeacherModel} from '@esgi/main/features/school-admin/dashboard';
import {parseSchoolDemographicPerformanceOptions} from './widget-options/parse-school-demographic-performance-options';
import {parseSchoolStudentsNeedingSupportOptions} from './widget-options/parse-school-students-needing-support-options';
import {parseSchoolPerformanceOptions} from './widget-options/parse-school-performance-options';

export function parseInitWidgetsData({
	widgets,
	subjectTabs,
	classes,
	teachers,
	gradeLevels,
	contentAreasList,
}: {
	widgets: WidgetContract[];
	subjectTabs: SubjectTab[];
	classes: ClassModel[];
	teachers: TeacherModel[];
	gradeLevels: GradeLevel[];
	contentAreasList: ContentAreaModel[];
}) {
	return widgets.map<Widget>(
		({
			id,
			name,
			options: {
				schoolPerformanceWidgetOptions,
				schoolStudentsNeedingSupportWidgetOptions,
				schoolDemographicPerformanceWidgetOptions,
			},
			versionID,
			lastUpdatedTime,
		}) => ({
			id,
			name,
			versionID,
			lastUpdatedTime,
			options: {
				schoolPerformanceWidgetOptions: parseSchoolPerformanceOptions({
					options: schoolPerformanceWidgetOptions,
					subjectTabs,
					teachers,
					gradeLevels,
					contentAreasList,
				}),
				schoolStudentsNeedingSupportWidgetOptions: parseSchoolStudentsNeedingSupportOptions({
					options: schoolStudentsNeedingSupportWidgetOptions,
					subjectTabs,
					classes,
					teachers,
					gradeLevels,
					contentAreasList,
				}),
				schoolDemographicPerformanceWidgetOptions: parseSchoolDemographicPerformanceOptions({
					options: schoolDemographicPerformanceWidgetOptions,
					subjectTabs,
					classes,
					gradeLevels,
					contentAreasList,
				}),
				districtPerformanceWidgetOptions: null,
				demographicPerformanceWidgetOptions: null,
				lowestAchievementWidgetOptions: null,
			},
		}),
	);
}
