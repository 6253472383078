import {FormControl} from '@esgi/ui/form';
import {Checkbox} from '@esgi/ui/form-controls';
import {SectionContent, SectionDescription, CheckboxFormElementWrapper} from './index.styled';

interface Props {
	checkboxControl: FormControl;
	description?: string;
	dataCy?: string;
}

export function SingleCheckboxSection({checkboxControl, description, dataCy}: Props) {
	return (
		<SectionContent dataCy={dataCy}>
			<CheckboxFormElementWrapper control={checkboxControl}>
				{(state) => (
					<Checkbox label={state.value ? 'Enabled' : 'Disabled'} />
				)}
			</CheckboxFormElementWrapper>
			<SectionDescription data-cy='description' size='small' font='mono' color='neutral24'>
				{description}
			</SectionDescription>
		</SectionContent>
	);
}
