import {styled} from '@esgi/ui/theme';
import {Box, ExpandablePanel, FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {animated} from 'react-spring';

export const ExpandablePanelRoot = styled(animated(ExpandablePanel), {
	'&[data-expandable-panel-open=true]': {
		backgroundColor: '$neutral94',
		backgroundImage: 'linear-gradient(332deg, #FAFAFA 17.26%, #F0F0F0 65.48%)',

		'& > div': {
			height: 'calc(100% - 40px)',
		},
	},

	variants: {
		collapsed: {
			true: {
				cursor: 'pointer',
			},
		},
	},
});

export const ExpandablePanelContent = styled(ExpandablePanel.Content, {
	display: 'block',
	height: '100%',
	overflow: 'hidden',
});

export const NavigationItem = styled(FlexBox, {
	userSelect: 'none',
	width: '232px',
	padding: 6,
	cursor: 'pointer',
	color: '$neutral40',
	backgroundColor: '$vivid',
	transition: 'color .3s, background-color .3s, border-color .3s',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',
	borderRadius: 6,
	alignItems: 'center',
	':first-child': {
		marginTop: 0,
	},
	'&:hover': {
		color: '$secondary',
		backgroundColor: '$secondaryBackground',
		borderColor: '$secondaryMuted',
	},
	'& > svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',
		alignSelf: 'start',
		marginRight: '6px',

		'& path': {
			fill: 'currentColor',
		},
	},

	[`& > ${Text}`]: {
		color: 'currentColor',
	},
	variants: {
		active: {
			true: {
				color: '$secondary',
				borderColor: '$secondaryMuted',
			},
		},
	},
});

export const SectionBox = styled(Box, {
	width: 24,
	height: 24,

	'& svg': {
		width: '100%',
		height: '100%',

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		fillColor: {
			primary: {
				color: '$primary',
			},

			secondary: {
				color: '$secondary',
			},
		},
	},
});

export const Title = styled(FlexBox, {
	paddingLeft: 6,
	marginBottom: 24,
	justifyContent: 'start',
	alignItems: 'center',

	'& svg': {
		'& path': {
			fill: '$neutral56',
		},
	},
});