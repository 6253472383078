import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Divider} from './index.styled';

type Props = ComponentPropsWithoutRef<typeof Divider>;

export const ChartsDivider = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-card-statistics-charts-divider', css = {}, ...props}, forwardedRef) => (
		<Divider dataCy={dataCy} css={css} ref={forwardedRef} {...props} />
	),
);
