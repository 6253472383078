import {ToggleGroup} from '@esgi/ui/controls';
import {Dispatch} from 'react';
import {ToggleGroupContent, IconBox} from './index.styled';
import {TabItem} from './types';
import {OneLinedText} from '@esgi/main/kits/common';

type Props<T extends string> = {
	value: T;
	setValue: Dispatch<T>;
	items: TabItem<T>[];
	withoutIdenticalItems?: boolean;
	disabled?: boolean | undefined;
};

export function Tabs<T extends string>({
	value,
	setValue,
	items,
	withoutIdenticalItems = false,
	disabled: isTabsDisabled,
}: Props<T>) {
	return (
		<ToggleGroup.Root value={value} onValueChange={setValue} disabled={Boolean(isTabsDisabled)}>
			<ToggleGroupContent data-tabs='' withoutIdenticalItems={withoutIdenticalItems}>
				{items.map((item) => (
					<ToggleGroup.Item
						key={item.value}
						value={item.value}
						disabled={item.value === value}
						applyDisabledStyles={!!isTabsDisabled}
					>
						{typeof item.content === 'string' ? (
							<OneLinedText size='medium' bold>
								{item.content}
							</OneLinedText>
						) : (
							<IconBox>
								<item.content />
							</IconBox>
						)}
					</ToggleGroup.Item>
				))}
			</ToggleGroupContent>
		</ToggleGroup.Root>
	);
}
