import {Button} from '@esgi/ui';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const ButtonIconable = styled(Button.Icon, {
	variants: {
		active: {
			true: {
				color: '$primary',
			},
		},

		skeleton: {
			true: {
				...SkeletonStyles,
				borderRadius: '50%',
			},
		},
	},
});
