import {Drawer, OneLinedText, useDrawerClose, useDrawerRef, useRootPageContext} from '@esgi/main/kits/common';
import {IconComponent, Portal} from '@esgi/ui';
import {CheckAll} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {SelectableListItemModel} from '../types';
import {SelectableList} from '@esgi/ui/layout';
import {Dispatch, useCallback, useState} from 'react';
import {DrawerBody, SelectAllButton, SelectableItem, SelectableListGroup} from './index.styled';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

type Props<T extends string> = {
	onClose: VoidFunction;
	title: string;
	value: T[];
	onSaveSelectedEntity: Dispatch<T[]>;
	allItems: SelectableListItemModel<T>[];
	EntityIcon: IconComponent;
};

export function SelectableEntityDrawer<T extends string>({
	onClose,
	title,
	value,
	onSaveSelectedEntity,
	allItems,
	EntityIcon,
}: Props<T>) {
	const drawerRef = useDrawerRef();
	const closeDrawer = useDrawerClose(drawerRef, onClose);

	const {rootPageContentContainerRef} = useRootPageContext();

	const [entireValue, setEntireValue] = useState(value);

	const onValueChange = useCallback((value: string[]) => {
		setEntireValue(value as T[]);
	}, []);

	const isAllItemsSelected = entireValue.length === allItems.length;

	const selectAllClick = useCallback(() => {
		if (isAllItemsSelected) {
			setEntireValue([]);

			return;
		}

		setEntireValue(allItems.map(({value}) => value));
	}, [allItems, isAllItemsSelected]);

	const onActionButtonClick = useCallback(() => {
		onSaveSelectedEntity(entireValue);

		closeDrawer();
	}, [closeDrawer, entireValue, onSaveSelectedEntity]);

	const isListTouched =
		entireValue.length !== value.length || entireValue.some((selectedValue) => !value.includes(selectedValue));

	return (
		<Portal container={rootPageContentContainerRef.current}>
			<Drawer drawerRef={drawerRef} onClickOutside={closeDrawer}>
				<Drawer.Header
					Icon={EntityIcon}
					sectionName={title}
					actionButtonText='Select'
					closeDrawer={closeDrawer}
					withActionButton
					onActionButtonClick={onActionButtonClick}
					actionButtonDisabled={!isListTouched || !entireValue.length}
				/>

				<DrawerBody>
					<SelectAllButton color='tertiary' onClick={selectAllClick}>
						<CheckAll />
						<Text size='medium' bold>
							{isAllItemsSelected ? 'Unselect' : 'Select'} All
						</Text>
					</SelectAllButton>
					<OverlayScrollbarsComponent defer options={{scrollbars: {autoHide: 'leave'}}}>
						<SelectableList>
							<SelectableList.GroupRoot type='multiple' value={entireValue} onValueChange={onValueChange}>
								<SelectableListGroup>
									{allItems.map(({value, label}) => (
										<SelectableItem value={value} variant='outlined' key={value}>
											<OneLinedText size='large'>{label}</OneLinedText>
										</SelectableItem>
									))}
								</SelectableListGroup>
							</SelectableList.GroupRoot>
						</SelectableList>
					</OverlayScrollbarsComponent>
				</DrawerBody>
			</Drawer>
		</Portal>
	);
}
