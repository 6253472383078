import {SpecialistGroup} from '@esgi/main/libs/admin-store';
import {useDrawerState} from '../use-drawer-state';
import {useState} from 'react';
import {TabID} from '../types';
import {Drawer, OneLinedText, UnsavedChangesConfirmation} from '@esgi/main/kits/common';
import {DrawerSkeleton} from '../../../../kit';
import {Divider, Header, HeaderButtons, HeaderTitle} from '../../components/header.styled';
import {Close, Edit as EditIcon, StudentsList} from '@esgi/ui/icons';
import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Body} from '../../components/body';
import {GroupInformationContent} from './components/group-information-content';
import {isNull} from 'underscore';
import {FormRoot} from '../../components/form.styled';
import {StudentsTabContent} from '../../components/students-tab-content';
import {useData} from './hooks/use-data';
import {useSave} from './hooks/use-save';
import {useEditForm} from './form/use-edit-form';
import {ContextMenu} from './components/context-menu';

type Props = {
	specialistGroupID: SpecialistGroup['id'];
	onClose: VoidFunction;
};

export function Edit({specialistGroupID, onClose}: Props) {
	const {
		drawerRef,
		onDrawerClose,
		closeDrawer,
		closeUnsavedChangesConfirmation,
		showUnsavedChangesConfirmation,
		setIsFormTouched,
		setIsFormValid,
		isFormTouched,
		isFormValid,
		withoutResetSingleStudentAlert,
		setWithoutResetSingleStudentAlert,
		specialistGroups,
		schoolSpecialists,
		districtSpecialists,
		schools,
		studentsList,
		skeleton,
	} = useDrawerState({onClose});

	const [activeTab, setActiveTab] = useState(TabID.GroupInformation);

	const {specialistTabID, currentSpecialist, specialistGroup, specialistGroupsList, allStudents} = useData({
		districtSpecialists,
		schoolSpecialists,
		skeleton,
		specialistGroupID,
		specialistGroups,
		studentsList,
	});

	const {initialSpecialistGroupName, initialStudentsIDs, form} = useEditForm({
		setIsFormTouched,
		setIsFormValid,
		studentsList,
		specialistGroupsList,
		specialistGroup,
	});

	const {isDataSaving, onEditSpecialistGroup} = useSave({form, initialStudentsIDs, specialistGroupID, closeDrawer, specialistID: currentSpecialist?.id ?? null});

	return (
		<>
			<Drawer drawerRef={drawerRef} onClickOutside={onDrawerClose}>
				{skeleton || isNull(currentSpecialist) || isNull(specialistTabID) || isNull(specialistGroup) ? (
					<DrawerSkeleton onClose={closeDrawer} />
				) : (
					<>
						<Header>
							<HeaderTitle>
								<EditIcon />
								<OneLinedText size='small' color='highContrast'>
									{initialSpecialistGroupName}
								</OneLinedText>
							</HeaderTitle>
							<HeaderButtons>
								<ContextMenu closeDrawer={closeDrawer} specialistGroupID={specialistGroupID} />

								<Button
									color='primary'
									onClick={onEditSpecialistGroup}
									disabled={!isFormValid || !isFormTouched || isDataSaving}
								>
									<Text size='large'>Save</Text>
								</Button>

								<Button.Icon withBackgroundHover onClick={onDrawerClose}>
									<Close />
								</Button.Icon>
							</HeaderButtons>

							<Divider />
						</Header>

						<Body
							activeTabID={activeTab}
							setActiveTabID={setActiveTab}
							districtSpecialistFormInactiveMessage={null}
							schoolSpecialistFormInactiveMessage={null}
						>
							<FormRoot controller={form}>
								{activeTab === TabID.GroupInformation && (
									<GroupInformationContent
										currentSpecialist={currentSpecialist}
										groupNameControl={form.controls.groupName}
										schools={schools}
										specialistTabID={specialistTabID}
									/>
								)}

								{activeTab === TabID.Students && (
									<StudentsTabContent
										setWithoutResetSingleStudentAlert={setWithoutResetSingleStudentAlert}
										withoutResetSingleStudentAlert={withoutResetSingleStudentAlert}
										allStudents={allStudents}
										studentsIDsControl={form.controls.studentsIDs}
										errorMessage={null}
										currentSpecialistGroup={specialistGroup}
										addStudentsAlertEntity={{
											name: specialistGroup?.name,
											Icon: StudentsList,
										}}
									/>
								)}
							</FormRoot>
						</Body>
					</>
				)}
			</Drawer>

			{showUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onClose={closeUnsavedChangesConfirmation} onCloseAnyway={closeDrawer} />
			)}
		</>
	);
}
