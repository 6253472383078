import {isNull} from 'underscore';
import {GridBox} from '@esgi/ui/layout';
import {SelectedSubjectsInfo} from '../../../../components/selected-subjects-info';
import {SelectedTeachersInfo} from '../../../../components/selected-teachers-info';
import {SubjectTab, TeacherModel} from '../../../../../types';
import {
	Candles,
	ChartCandleItem,
	GradeLevel,
	PerformanceWidgetViewType,
	PieChartItem,
	Widget,
	WidgetCard,
	WidgetPeriod,
} from '@esgi/main/features/admins/dashboard';
import {useVisibleItems} from './use-visible-items';
import {WidgetDataModel} from '../../types';
import {CardBody} from './index.styled';

type Props = {
	viewType: PerformanceWidgetViewType;
	candles: Candles | null;
	averageValue: number;
	showAllItemsAverage: boolean;
	periods: WidgetPeriod[];
	teachersIDs: number[];
	allTeachersList: TeacherModel[];
	contentAreaIDs: number[];
	subjectIDs: number[];
	subjects: SubjectTab[];
	gradeLevelIDs: number[];
	gradeLevels: GradeLevel[];
};

export function ViewMode({
	viewType,
	candles,
	showAllItemsAverage,
	averageValue,
	periods,
	subjects,
	allTeachersList,
	contentAreaIDs,
	gradeLevelIDs,
	teachersIDs,
	subjectIDs,
	gradeLevels,
}: Props) {
	const {visibleData, widgetDataPageSize} = WidgetCard.useWidgetCardContext<WidgetDataModel>();

	const visibleItems = useVisibleItems({
		avgValue: averageValue,
		viewType,
		visibleWidgetData: visibleData,
		widgetChartPageSize: widgetDataPageSize,
		periods,
	});

	return (
		<CardBody>
			<GridBox align='center' justify='between' gap='6' flow='column'>
				<GridBox align='center' gap='2' flow='column'>
					<SelectedTeachersInfo allTeachersList={allTeachersList} teachersIDs={teachersIDs} />
					<Widget.SelectedEntityInfoDivider />

					<SelectedSubjectsInfo subjectIDs={subjectIDs} subjects={subjects} />
					<Widget.SelectedEntityInfoDivider />

					<WidgetCard.ViewMode.SelectedContentAreasInfo contentAreaIDs={contentAreaIDs} />
					<Widget.SelectedEntityInfoDivider />

					<Widget.SelectedGradeLevelsInfo gradeLevelIDs={gradeLevelIDs} gradeLevels={gradeLevels} />
				</GridBox>

				<Widget.PeriodDatesInfo periodsData={periods} />
			</GridBox>

			{viewType === PerformanceWidgetViewType.Candles && (
				<WidgetCard.ViewMode.Chart.Candle
					items={visibleItems as (ChartCandleItem | null)[]}
					allItemsAverage={averageValue}
					showAllItemsAverage={showAllItemsAverage}
					highlight={isNull(candles) ? null : {color: candles.color, value: candles.highlightIfAbove}}
				/>
			)}

			{viewType === PerformanceWidgetViewType.Piechart && (
				<WidgetCard.ViewMode.Chart.PieChart
					items={visibleItems as (PieChartItem | null)[]}
					widgetChartPageSize={widgetDataPageSize}
					averageValue={averageValue}
					showAverageValue={showAllItemsAverage}
				/>
			)}
		</CardBody>
	);
}
