import {Dispatch, useState} from 'react';
import {Page, SingleSelectedExpandablePanel} from '../../../../../_kit';
import {RowDef} from '../../../../types';
import {QuickPanel} from './components/quick-panel';
import {FullActionPanel} from '../../../../components/full-action-panel';
import {isNull} from 'underscore';

type Props = {
	onEditClicked: Dispatch<number>;
};

export function SingleItemExpandablePanel({onEditClicked}: Props) {
	const {api} = Page.useContext<RowDef>();

	const [isPanelExpanded, setIsPanelExpanded] = useState(false);
	const [selectedRow, setSelectedRow] = useState<RowDef | null>(null);

	return (
		<SingleSelectedExpandablePanel.Root<RowDef>
			gridApi={api}
			onSingleSelectedItemChange={setSelectedRow}
			onPanelExpandableValueChange={setIsPanelExpanded}
		>
			{selectedRow && (
				<>
					<QuickPanel selectedRow={selectedRow} isPanelExpanded={isPanelExpanded} onEditClicked={onEditClicked} />

					<SingleSelectedExpandablePanel.ExpandedPanel>
						<FullActionPanel
							student={{
								id: selectedRow.id,
								firstName: selectedRow.firstName,
								lastName: selectedRow.lastName,
								teacherID: !isNull(selectedRow.teacher) ? selectedRow.teacher.id : null,
								schoolID: selectedRow.school?.id ?? null,
								classIDs: selectedRow.class.map(({id}) => id),
								groupsIDs: selectedRow.group.map(({id}) => id),
								avatarUrl: null,
								gradeLevel: selectedRow.gradeLevelID,
							}}
						/>
					</SingleSelectedExpandablePanel.ExpandedPanel>
				</>
			)}
		</SingleSelectedExpandablePanel.Root>
	);
}
