import {GradeLevel} from '@esgi/main/kits/common';

export const noTestsMessage = 'No tests to display';
export const selectSubjectMessage = 'Please select a subject';
export const noStudentsMessage: [string, ...string[]] = [
	'No students have been added.',
	'Please add some before running the report.',
];

export const allGradeLevel: GradeLevel = {
	id: -1,
	name: 'All',
	shortName: 'All',
};
