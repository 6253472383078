import {useCallback, useEffect} from 'react';
import {useDisposable} from '@esgi/core/service';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {V2DistrictAdminsActionPanelController} from '@esgi/contracts/esgi';
import {entityControllerMap} from '../constants';
import {EntityType} from '../types';

interface Parameters {
	entityIDs: number[];
	entityType: EntityType;
	onRemoved?: VoidFunction;
}

export function useRemoveEntity({entityIDs, entityType, onRemoved}: Parameters) {
	const controller = useDisposable(V2DistrictAdminsActionPanelController);

	const [removeEntityData, removeEntity] = useCancelableRequest(controller[entityControllerMap[entityType]]);

	useEffect(() => {
		if (!isAsyncSucceed(removeEntityData)) {
			return;
		}

		onRemoved?.();
	}, [removeEntityData]);

	return useCallback(() => {
		removeEntity({iDs: entityIDs});
	}, [entityIDs, removeEntity]);
}