import {RemoveTeacher, OnTeacherRemoved} from '@esgi/main/features/school-admin/data';
import {DeleteButton} from '../../_kit';
import {useCallback} from 'react';
import {User} from '@esgi/main/libs/school-admin-store';
import {RowDef} from '../types';

type Props = {
	selectedRow: RowDef;
	onRemoved: OnTeacherRemoved;
	userCanAddAndDeleteTeachers: boolean;
};

export function DeleteTeacherButtonSingleSelect({userCanAddAndDeleteTeachers, onRemoved, selectedRow}: Props) {
	const removeTeacherManager = RemoveTeacher.useManagerRef();

	const runRemoveItem = useCallback(
		(id: User['id']) => {
			removeTeacherManager.current?.runRemove({teacherID: id});
		},
		[removeTeacherManager],
	);

	return (
		<>
			<DeleteButton
				disabled={!userCanAddAndDeleteTeachers}
				tooltipMessage='To delete the Teacher, please contact Customer Support support@esgisoftware.com'
				whenShowTooltip='disabled'
				onClick={() => runRemoveItem(selectedRow.id)}
			/>

			<RemoveTeacher managerRef={removeTeacherManager} onRemoved={onRemoved} />
		</>
	);
}
