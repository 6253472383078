import {Card, GridBox} from '@esgi/ui/layout';
import {Dispatch} from 'react';
import {Text} from '@esgi/ui/typography';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {TestSingleStudentModel} from '../../../../../../../../types';
import {CardHeader} from '../../components/card-header';
import {CardContextMenu} from '../../../../components/card-context-menu';
import {SessionsContextMenu} from '../../../../components/sessions-context-menu';
import {Tooltip} from '@esgi/ui';
import {CardBody} from './components/card-body';
import {CardFooter} from './components/card-footer';
import {onCardHoverText} from '../../../constants';
import {ActionButtonsDivider, CardRoot} from '../../components/components.styled';

type Props = Pick<TestSingleStudentModel, 'testInfo' | 'studentResult' | 'analyticsData' | 'lastTestSessions'> & {
	subjectID: SubjectTab['id'];
	showSelfAssessOption: boolean;
	classID: Class['id'];
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	openTestDetails: VoidFunction;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	onTestCardClicked: VoidFunction;
	onStartSelfAssessTestHandler: VoidFunction;
	onStartTestHandler: VoidFunction;
};

export function SingleStudentCard({
	testInfo,
	studentResult,
	analyticsData,
	lastTestSessions,
	showSelfAssessOption,
	subjectID,
	classID,
	onRemoveTest,
	onTestMoveTo,
	openTestDetails,
	userCanEditTest,
	userCanRemoveTest,
	onTestCardClicked,
	onStartSelfAssessTestHandler,
	onStartTestHandler,
}: Props) {
	return (
		<CardRoot onClick={onTestCardClicked} clicable dataCy='test-card'>
			<Tooltip>
				<Tooltip.Trigger>
					<GridBox gap={3}>
						<CardHeader testID={testInfo.id} testName={testInfo.name} testType={testInfo.type} standards={testInfo.stateStandards}/>

						<Card.Separator />

						<CardBody studentResult={studentResult} testInfo={testInfo} />
					</GridBox>
				</Tooltip.Trigger>
				<Tooltip.Content side='top'>
					<Text size='large' color='tertiary'>
						{onCardHoverText}
					</Text>
				</Tooltip.Content>
			</Tooltip>

			<Card.Separator />

			<CardFooter
				showSelfAssessOption={showSelfAssessOption}
				selfAssessmentTestAllowed={testInfo.selfAssessmentTestAllowed}
				onStartSelfAssessTestHandler={onStartSelfAssessTestHandler}
				onStartTestHandler={onStartTestHandler}
			>
				<CardContextMenu
					onRemoveTest={onRemoveTest}
					onTestMoveTo={onTestMoveTo}
					subjectID={subjectID}
					testInfo={testInfo}
					openTestDetails={openTestDetails}
					userCanEditTest={userCanEditTest}
					userCanRemoveTest={userCanRemoveTest}
				/>

				<ActionButtonsDivider />

				<SessionsContextMenu
					classID={classID}
					lastTestSessions={lastTestSessions}
					maxScore={testInfo.totalPossible}
					sessionsCount={analyticsData?.sessionCount ?? 0}
					subjectID={subjectID}
					testID={testInfo.id}
				/>
			</CardFooter>
		</CardRoot>
	);
}
