import {useEffect, useState} from 'react';
import {DatesPeriodModel, SchoolYearModel} from '../../types';
import {useDisposable} from '@esgi/core/service';
import {V2SchoolYearsController} from '@esgi/contracts/esgi';
import {isAsyncIdle, isAsyncPending, isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';
import {getPreviousTrackDate} from './helpers';
import {useUserCurrentTrack} from '../use-user-current-track';
import {isNull} from 'underscore';

export function useInitData() {
	const {skeleton: isCurrentTrackDataLoading, currentTrack, currentTrackDate} = useUserCurrentTrack();

	const [previousTrackDate, setPreviousTrackDate] = useState<DatesPeriodModel | null>(null);
	const [currentGlobalActiveSchoolYear, setCurrentGlobalActiveSchoolYear] = useState<SchoolYearModel | null>(null);

	const schoolYearController = useDisposable(V2SchoolYearsController);

	const [schoolYearInitData, fetchSchoolYearData] = useCancelableRequest(schoolYearController.init);

	useEffect(() => {
		if (isCurrentTrackDataLoading || isNull(currentTrack)) {
			return;
		}

		const previousTrackDate = getPreviousTrackDate(currentTrackDate, currentTrack.trackDates);

		setPreviousTrackDate(previousTrackDate);
	}, [currentTrack, currentTrackDate, isCurrentTrackDataLoading]);

	useEffect(() => {
		if (isAsyncIdle(schoolYearInitData)) {
			fetchSchoolYearData();
			return;
		}

		if (isAsyncSucceed(schoolYearInitData)) {
			const {currentGlobalSchoolYearID, schoolYears} = schoolYearInitData.data;

			const currentGlobalActiveSchoolYear = schoolYears.find(
				({globalSchoolYearID}) => globalSchoolYearID === currentGlobalSchoolYearID,
			);

			if (isUndefined(currentGlobalActiveSchoolYear)) {
				return;
			}

			setCurrentGlobalActiveSchoolYear(currentGlobalActiveSchoolYear);

			return;
		}
	}, [fetchSchoolYearData, schoolYearInitData]);

	return {
		currentTrack,
		currentTrackDate,
		previousTrackDate,
		currentGlobalActiveSchoolYear,
		skeleton: isAsyncPending(schoolYearInitData) || isCurrentTrackDataLoading,
	};
}
