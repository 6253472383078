import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {useState} from 'react';
import {Select} from '@esgi/ui/controls';
import {DistrictSubject} from '@esgi/main/kits/subject-selection-panel';
import {OnMoveToTest} from '../../../../types';

type Props = {
	currentSubjectID: DistrictSubject['id'];
	testName: string;
	districtSubjects: DistrictSubject[];
	onClose: VoidFunction;
	onSave: OnMoveToTest;
};

export function MoveToAlert({districtSubjects, currentSubjectID, testName, onClose, onSave}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [selectedSubjectID, setSelectedSubjectID] = useState(String(currentSubjectID));

	const saveAndClose = () => {
		onSave({
			previousSubjectID: currentSubjectID,
			newSubjectID: Number(selectedSubjectID),
		});

		closeAlert();
	};

	return (
		<Alert
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					width: 510,
				},
			}}
		>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large'>Move "{testName}" to a Subject Tab</Text>
			</Alert.Header>
			<Alert.Body>
				<Select.Root value={selectedSubjectID} onValueChange={setSelectedSubjectID}>
					<Select.Field placeholder='Subject' />
					<Select.Content>
						{districtSubjects.map(({id, name}) => (
							<Select.Option value={String(id)} key={id}>
								<Text size='medium' bold>
									{name}
								</Text>
							</Select.Option>
						))}
					</Select.Content>
				</Select.Root>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>
					<Button color='secondary' onClick={saveAndClose} disabled={currentSubjectID === Number(selectedSubjectID)}>
						<Text size='medium' bold color='base'>
							Save
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
