import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {CardsSortBy, Student} from '../types/section';
import {TestPanelData} from '../types/test-panel';
import {useByAllStudentsTestsCards} from './use-by-all-students-tests-cards';
import {useByEachStudentTestsCards} from './use-by-each-student-tests-cards';

type Parameters = {
	students: Student[];
	selectedSubject: SubjectModel | null;
	showEachStudent: boolean;
	skeleton: boolean;
	activeCardsSortBy: CardsSortBy;
};

export function useTestsCards({
	selectedSubject,
	showEachStudent,
	students,
	skeleton,
	activeCardsSortBy,
}: Parameters): TestPanelData {
	const byAllStudentsData = useByAllStudentsTestsCards({
		students,
		selectedSubject,
		canRunRequest: !skeleton && !!students.length,
		isShowThisData: !showEachStudent,
		activeCardsSortBy,
	});

	const byEachStudentData = useByEachStudentTestsCards({
		students,
		selectedSubject,
		canRunRequest: !skeleton && !!students.length,
		isShowThisData: showEachStudent,
		activeCardsSortBy,
	});

	return {
		byAllStudentsData,
		byEachStudentData,
	};
}
