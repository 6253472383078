import {Container, Divider} from './index.styled';
import {useCallback, useEffect} from 'react';
import {SubjectsPanel} from './components/subject-panel';
import {isNull} from 'underscore';
import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {useSubjects} from './hooks/use-subjects';
import {Skeletonable} from '@esgi/ui';
import {AdditionalEntity, Student, Subject} from './types/section';
import {EmptyContent} from './components/empty-content';
import {noStudentsMessage, noTestsMessage, selectSubjectMessage} from './constants';
import {TestsInfoPanel} from './components/test-info-panel';
import {usePanelState} from './hooks/use-panel-state';
import {useTestsCards} from './hooks/use-tests-cards';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = Skeletonable & {
	allSubjects: Subject[];
	students: Student[];
	hierarchy: Hierarchy;
	additionalInfo?: AdditionalEntity | undefined;
	withoutVariantByEachStudent?: boolean;
};

export function TestResults({
	skeleton,
	allSubjects,
	students,
	hierarchy,
	additionalInfo,
	withoutVariantByEachStudent = false,
}: Props) {
	const {
		uniqGradeLevelsIDs,
		selectedGradeLevelID,
		setSelectedGradeLevelID,
		cardsSortBy,
		setCardsSortBy,
		showInColor,
		setShowInColor,
		showEachStudent,
		setShowEachStudent,
		selectedSubject,
		setSelectedSubject,
	} = usePanelState({allSubjects});

	const {firstSubjectID, subjectsEntities, hasSubjects, getSubjectByID} = useSubjects({
		allSubjects,
		selectedGradeLevelID,
	});

	const testPanelData = useTestsCards({
		selectedSubject,
		showEachStudent,
		skeleton: Boolean(skeleton),
		students,
		activeCardsSortBy: cardsSortBy,
	});

	const setSelectedSubjectByID = useCallback(
		(subjectID: SubjectModel['id'] | null) => {
			setSelectedSubject(isNull(subjectID) ? null : getSubjectByID(subjectID));
		},
		[getSubjectByID, setSelectedSubject],
	);

	useEffect(() => {
		if (isNull(selectedSubject) || isNull(getSubjectByID(selectedSubject.id))) {
			setSelectedSubjectByID(firstSubjectID);
		}
	}, [firstSubjectID, getSubjectByID, selectedSubject, setSelectedSubjectByID]);

	const renderTestInfoPanel = () => {
		if (!hasSubjects && !skeleton) {
			return <EmptyContent message={noTestsMessage} />;
		}

		if (isNull(selectedSubject) && !skeleton) {
			return <EmptyContent message={[noTestsMessage, selectSubjectMessage]} />;
		}

		if (!students.length && !skeleton) {
			return <EmptyContent message={noStudentsMessage} />;
		}

		return (
			<TestsInfoPanel
				uniqGradeLevelsIDs={uniqGradeLevelsIDs}
				gradeLevelID={selectedGradeLevelID}
				onGradeLevelIDChange={setSelectedGradeLevelID}
				cardsSortBy={cardsSortBy}
				onCardsSortByValueChange={setCardsSortBy}
				showInColor={showInColor}
				onShowInColorValueChange={setShowInColor}
				showEachStudent={showEachStudent}
				onShowEachStudentValueChange={setShowEachStudent}
				testPanelData={testPanelData}
				students={students}
				fullPanelSkeleton={Boolean(skeleton)}
				additionalInfo={additionalInfo ?? null}
				hierarchy={hierarchy}
				selectedGradeLevelID={selectedGradeLevelID}
				selectedSubject={selectedSubject}
				withoutVariantByEachStudent={withoutVariantByEachStudent}
			/>
		);
	};

	return (
		<Container>
			<SubjectsPanel
				selectedSubjectID={isNull(selectedSubject) ? null : selectedSubject.id}
				subjectsEntities={subjectsEntities}
				setSelectedSubjectID={setSelectedSubjectByID}
				skeleton={skeleton}
			/>

			<Divider />

			{renderTestInfoPanel()}
		</Container>
	);
}
