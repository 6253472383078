import {HttpClient} from '@esgi/api';
import {BehaviorSubject, merge, Observable, of, Subject} from 'rxjs';
import {debounceTime, map, takeUntil, tap} from 'rxjs/operators';
import {SubjectType} from '@esgi/core/enums';
import {BaseService} from '@esgi/core/service';
import {single} from 'shared/utils/rxjs';
import {sortBy} from 'underscore';
import {RubricAnswer, RubricModel} from '../common/types';
import {LevelDisplayMode} from '../session-details/types';
import {InitModel, SessionStatus} from './types';
import {buildAnswers, getInfo} from '../common/utils';
import {ShareScreenSession} from 'shared/modules/testing/share-screen-session/share-screen-session';

export default class TestingService extends BaseService {
	public testModel: RubricModel;
	public studentName: string;
	public cacheAvailable: boolean = false;
	public onLostConnection: Subject<void> = new Subject<void>();
	public sessionStatus: BehaviorSubject<SessionStatus> = new BehaviorSubject<SessionStatus>(SessionStatus.None);
	public answers: BehaviorSubject<RubricAnswer[]> = new BehaviorSubject<RubricAnswer[]>([]);
	public summaryNotes: BehaviorSubject<string> = new BehaviorSubject<string>('');
	public testSessionID: number;

	private readonly controller = 'assessment/test-screen/rubric';

	private userID: number;
	private testID: number;
	private studentID: number;
	private sessionGuid: string = '';
	private startTime: Date;
	private shareScreenClient: ShareScreenSession;

	public init(testID: number, studentID: number, userID: number): Observable<boolean> {
		this.testID = testID;
		this.studentID = studentID;
		this.userID = userID;

		return this.checkShareScreenStarted();
	}

	public runTest(): Observable<InitModel> {
		return HttpClient.default.ESGIApi.post<InitModel>(this.controller, 'run-test', {
			rubricID: this.testID,
			studentID: this.studentID,
		}).pipe(map(r => {
			r.rubric.levelDisplayType = LevelDisplayMode[r.rubric.levelDisplayType as any as string];
			r.sessionStatus = SessionStatus[r.sessionStatus as any as string];
			return r;
		})).pipe(tap(r => {
			this.testModel = {
				...r.rubric,
				criteriaModels: sortBy(r.rubric.criteriaModels, (c) => c.order),
				levelModels: sortBy(r.rubric.levelModels, (l) => l.score).reverse(),
			} as RubricModel;
			this.studentName = r.studentName;
			this.answers.next(buildAnswers(r.answers, r.rubric));
			this.cacheAvailable = r.cacheAvailable;
			this.summaryNotes.next(r.summaryNotes || '');
			this.handleSessionStatus(r.sessionStatus);
		})).asObservable();
	}

	public checkShareScreenStarted(): Observable<boolean>{
		return new Observable<boolean>(observer => {
			if (localStorage.shareScreenSessionCreated) {
				this.shareScreenClient = new ShareScreenSession();

				this.shareScreenClient.events.connectionFail(() => {
					observer.next(false);
				});

				this.shareScreenClient.events.shared((event, data) => {
					const shared = !!data;
					if (!shared) {
						localStorage.removeItem('shareScreenSessionCreated');
					}
					observer.next(shared && data.codeApplyed);
				});

				this.shareScreenClient.isShared(this.userID);
			} else {
				observer.next(false);
			}
		});
	}

	public endShareScreenSession() {
		this.shareScreenClient.endSession(this.userID.toString());
		localStorage.removeItem('shareScreenSessionCreated');
	}

	public startTesting(): void {
		this.startTime = new Date();
		this.updateSessionGuid().subscribe(() => this.initAutoSave());
	}

	public updateSessionGuid() {
		const data = {
			rubricID: this.testID,
			studentID: this.studentID,
		};

		return HttpClient.default.ESGIApi
			.post<{ sessionKey: string, sessionStatus: SessionStatus }>(this.controller, 'update-test-session-guid', data)
			.pipe(
				takeUntil(this.destroy$),
				map(r => {
					r.sessionStatus = SessionStatus[r.sessionStatus as any as string];
					return r;
				}),
				tap(r => {
					this.handleSessionStatus(r.sessionStatus);
					this.sessionGuid = r.sessionKey;
				}),
			);
	}

	public selectCard(id: number): void {
		let answers = [...this.answers.value];
		const {description, level} = getInfo(this.testModel, answers).byDescription(id);
		const {answer} = getInfo(this.testModel, answers).byCriteria(description.criteriaID);

		if (answer) {
			answers = answers.filter(a => a.criteriaID !== description.criteriaID);
			if (answer.score !== undefined ) {
				if (answer.score === level.score) {
					answer.score = undefined;
				} else {
					answer.score = level.score;
				}
			} else {
				answer.score = level.score;
			}
			answers.push(answer);
		}

		this.answers.next([...answers]);
	}

	public updateNote(criteriaID: number, value: string): void {
		this.answers.next(this.answers.value.map(a => {
			if (a.criteriaID === criteriaID) {
				return {
					...a,
					notes: value,
				};
			}
			return {...a};
		}));
	}

	public endTest(): Observable<void> {
		const duration = new Date().getTime() - this.startTime.getTime();
		const data = {
			duration,
			cacheAvailable: this.cacheAvailable,
			...this.getState(),
		};
		return this.httpClient.ESGIApi.post<{testSessionID: number}>(
			this.controller,
			'end-test',
			data,
		).withCustomErrorHandler((error, strategy) => {
			if ([0, 400].includes(error.status)) {
				strategy.stopPropagation();
				this.onLostConnection.next();
			}
		}).pipe(map((r) => {
			this.testSessionID = r.testSessionID;
		})).asObservable();
	}

	public cancelTesting(): Observable<void> {
		if (!this.cacheAvailable) {
			return of();
		}

		const data = {
			rubricID: this.testID,
			studentID: this.studentID,
			sessionKey: this.sessionGuid,
		};
		return this.httpClient.ESGIApi.post<void>(this.controller, 'cancel-test', data).asObservable();
	}

	public resetAnswers(): void {
		this.answers.next(buildAnswers([], this.testModel));
	}

	public exportSession(subjectID: number, subjectType: SubjectType, classID: number): Observable<Blob> {
		if (this.testSessionID) {
			const date = new Date();
			const day = date.getDate();
			const month = date.getMonth() + 1;
			const year = date.getFullYear();
			const filename = `Test_Session_Details_${year}_${month}_${day}.pdf`;

			return this.httpClient.ESGIApi.file(this.controller, 'export', filename, {
				rubricID: this.testID,
				studentID: this.studentID,
				subjectID,
				subjectType,
				classID,
				filename: filename,
				testSessionIDs: [this.testSessionID],
			}).pipe(takeUntil(this.destroy$));
		}

		return single();
	}

	private initAutoSave() {
		if (!this.cacheAvailable) {
			return;
		}
		merge(this.answers, this.summaryNotes.pipe(debounceTime(1000)))
			.pipe(
				takeUntil(this.destroy$),
				map(() => this.getState()),
				debounceTime(100),
			)
			.subscribe((data) => {
				HttpClient.default.ESGIApi.post<{ sessionStatus: SessionStatus }>(this.controller, 'store-test-session', data)
					.pipe(
						takeUntil(this.destroy$),
						map(r => SessionStatus[r.sessionStatus as any as string]),
						tap(this.handleSessionStatus),
					)
					.subscribe();
			});
	}

	private getState() {
		return {
			rubricID: this.testID,
			studentID: this.studentID,
			answers: this.answers.value,
			summaryNotes: this.summaryNotes.value,
			sessionKey: this.sessionGuid,
		};
	}

	private handleSessionStatus = (sessionStatus: SessionStatus) => this.sessionStatus.next(sessionStatus);
}
