import {Button} from '@esgi/ui';
import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const StyledInfoBlockContainer = styled(Box, {
	marginBottom: 20,
});

export const StyledButton = styled(Button, {
	padding: '8px 32px',
	alignItems: 'center',
	marginTop: -12,

	'& > svg': {
		width: '24px',
		height: '24px',
	},
});