import {Drawer, getFullName, OneLinedText} from '@esgi/main/kits/common';
import {Student, User} from '@esgi/main/libs/admin-store';
import {FormControl} from '@esgi/ui/form';
import {AddStudentButton, AddStudentsBox, ContentBox, ErrorBox, StudentItem, StudentsListBox} from './index.styled';
import {Dispatch, useCallback, useState} from 'react';
import {ClearAll, Close, Plus} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {Button, useStreamEffect} from '@esgi/ui';
import {isNull} from 'underscore';
import {AddStudentsAlert} from '@esgi/main/kits/admin';
import {ResetSingleStudentAlert} from './components/reset-single-student-alert';
import {ResetAllStudentsAlert} from './components/reset-all-students-alert';
import {Group} from '@esgi/main/libs/store';
import {useAddStudentAlertData} from './use-add-student-alert-data';

type CurrentGroup = {
	id: Group['id'];
	name: string;
};

type Props = {
	studentsIDsControl: FormControl<Student['id'][]>;
	allStudents: Student[];
	primaryTeacherID: User['id'] | null;
	withoutResetSingleStudentAlert: boolean;
	setWithoutResetSingleStudentAlert: Dispatch<boolean>;
	currentGroup: CurrentGroup | null;
};

export function StudentsTabContent({
	studentsIDsControl,
	allStudents,
	primaryTeacherID,
	withoutResetSingleStudentAlert,
	setWithoutResetSingleStudentAlert,
	currentGroup,
}: Props) {
	const [activeStudents, setActiveStudents] = useState<Student[]>(
		allStudents.filter(({id}) => studentsIDsControl.value.includes(id)),
	);

	const [showAddStudentsAlert, setShowAddStudentsAlert] = useState(false);

	const [showResetAllStudentsAlert, setShowResetAllStudentsAlert] = useState(false);
	const [resetSingleStudent, setResetSingleStudent] = useState<Student | null>(null);

	useStreamEffect(studentsIDsControl.onChanged, ({currState: {value}}) => {
		setActiveStudents(allStudents.filter(({id}) => value.includes(id)));
	});

	const resetAllStudents = useCallback(() => {
		studentsIDsControl.value = [];
	}, [studentsIDsControl]);

	const resetStudentByID = useCallback(
		(id: Student['id']) => {
			const currentStudentsIDs = studentsIDsControl.value;

			studentsIDsControl.value = currentStudentsIDs.filter((studentID) => studentID !== id);
		},
		[studentsIDsControl],
	);

	const onAddStudents = useCallback(
		(ids: Student['id'][]) => {
			studentsIDsControl.value = ids;
		},
		[studentsIDsControl],
	);

	const openAddStudentsAlert = useCallback(() => {
		setShowAddStudentsAlert(true);
	}, []);

	const closeAddStudentsAlert = useCallback(() => {
		setShowAddStudentsAlert(false);
	}, []);

	const openResetAllStudentsAlert = useCallback(() => {
		setShowResetAllStudentsAlert(true);
	}, []);

	const closeResetAllStudentsAlert = useCallback(() => {
		setShowResetAllStudentsAlert(false);
	}, []);

	const handleResetSingleStudent = useCallback(
		(student: Student) => {
			if (withoutResetSingleStudentAlert) {
				resetStudentByID(student.id);

				return;
			}

			setResetSingleStudent(student);
		},
		[resetStudentByID, withoutResetSingleStudentAlert],
	);

	const closeResetSingleStudentAlert = useCallback(() => {
		setResetSingleStudent(null);
	}, []);

	const withoutPrimaryTeacherID = isNull(primaryTeacherID);

	const addStudentAlertData = useAddStudentAlertData({
		activeStudents,
		groupID: !isNull(currentGroup) ? currentGroup.id : null,
		primaryTeacherID,
	});

	return (
		<>
			<Drawer.ContentBlock title='Students List' withDivider>
				<ContentBox>
					<AddStudentsBox withClearAllButton={Boolean(activeStudents.length)}>
						<AddStudentButton color='secondary' onClick={openAddStudentsAlert} disabled={withoutPrimaryTeacherID}>
							<Plus />
							<Text size='medium' bold>
								Add Students
							</Text>
						</AddStudentButton>

						{Boolean(activeStudents.length) && (
							<Button.Icon withBackgroundHover onClick={openResetAllStudentsAlert}>
								<ClearAll />
							</Button.Icon>
						)}
					</AddStudentsBox>

					{withoutPrimaryTeacherID && (
						<ErrorBox>
							<Text size='xSmall' font='mono' bold color='negative'>
								You have to assign the primary teacher to add students
							</Text>
						</ErrorBox>
					)}

					{Boolean(activeStudents.length) && !withoutPrimaryTeacherID && (
						<StudentsListBox>
							{activeStudents.map((student) => (
								<StudentItem key={student.id}>
									<OneLinedText size='medium' color='base'>
										{getFullName({
											firstName: student.firstName,
											lastName: student.lastName,
										})}
									</OneLinedText>
									<Button.Icon withBackgroundHover onClick={() => handleResetSingleStudent(student)}>
										<Close />
									</Button.Icon>
								</StudentItem>
							))}
						</StudentsListBox>
					)}
				</ContentBox>
			</Drawer.ContentBlock>

			{showAddStudentsAlert && !withoutPrimaryTeacherID && (
				<AddStudentsAlert
					onClose={closeAddStudentsAlert}
					columnDefs={addStudentAlertData.colDefs}
					selectedItemsIDs={addStudentAlertData.selectedItemsIDs}
					onAdd={onAddStudents}
					rowsData={addStudentAlertData.rowsData}
					disabledRowsAsSelectedIDs={addStudentAlertData.disabledRowsAsSelectedIDs}
					entity={addStudentAlertData.entity}
					skeleton={addStudentAlertData.skeleton}
				/>
			)}

			{showResetAllStudentsAlert && (
				<ResetAllStudentsAlert
					onClose={closeResetAllStudentsAlert}
					onRemove={resetAllStudents}
					groupName={currentGroup?.name}
				/>
			)}

			{!isNull(resetSingleStudent) && (
				<ResetSingleStudentAlert
					onClose={closeResetSingleStudentAlert}
					onRemove={() => resetStudentByID(resetSingleStudent.id)}
					firstName={resetSingleStudent.firstName}
					lastName={resetSingleStudent.lastName}
					checkboxValue={withoutResetSingleStudentAlert}
					setCheckboxValue={setWithoutResetSingleStudentAlert}
				/>
			)}
		</>
	);
}
