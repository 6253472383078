import {Text} from '@esgi/ui/typography';
import {DeltaCheapBox} from './index.styled';

type Props = {
	delta: number;
	transparent: boolean;
};

export function DeltaCheap({delta, transparent}: Props) {
	if (delta === 0) {
		return (
			<DeltaCheapBox backgroundColor='mediumContrast' transparent={transparent}>
				<Text size='xSmall' font='mono' bold color='vivid'>
					0
				</Text>
			</DeltaCheapBox>
		);
	}

	if (delta < 0) {
		return (
			<DeltaCheapBox backgroundColor='mediumContrast' transparent={transparent}>
				<Text size='xSmall' font='mono' bold color='vivid'>
					-{Math.abs(delta)}%
				</Text>
			</DeltaCheapBox>
		);
	}

	return (
		<DeltaCheapBox backgroundColor='positive' transparent={transparent}>
			<Text size='xSmall' font='mono' bold color='vivid'>
				+{Math.abs(delta)}%
			</Text>
		</DeltaCheapBox>
	);
}
