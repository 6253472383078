import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';

export const Container = styled(FlexBox, {
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
	height: '100%',
});

export const TextContainer = styled(Box, {
	padding: '12px 20px',
	background: '$surface',
	borderRadius: 6,
	border: '1px solid $surface',
});