import {styled} from '@esgi/ui/theme';
import {GridBox, SelectableList} from '@esgi/ui/layout';
import {Drawer} from '@esgi/main/kits/common';
import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

export const SelectedCheap = styled(GridBox, {
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$primaryMuted',
	backgroundColor: '$primarySurface',
	borderRadius: 4,
	alignItems: 'center',
	justifyContent: 'center',
	padding: 2,
	paddingBottom: 2,
	paddingLeft: 3,
	paddingRight: 3,
});

export const DrawerBody = styled(Drawer.Body, {
	paddingTop: 20,
});

export const ContentBox = styled(GridBox, {
	gridTemplateRows: 'auto auto 1fr',
	gap: '$3',
	overflow: 'hidden',
});

export const SelectAllButton = styled(Button, {
	justifySelf: 'start',
	minWidth: 'unset',
});

export const SelectableItem = styled(SelectableList.Item, {
	paddingTop: 9,
	paddingBottom: 9,

	'&:not(:last-child)': {
		marginBottom: '$2',
	},

	[`> ${Text}`]: {
		wordBreak: 'break-all',
	},
});
