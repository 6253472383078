import {useOverlayScrollbars} from 'overlayscrollbars-react';
import {useEffect} from 'react';
import {isNull} from 'underscore';

type Parameters = {
	isAgGridInitialized: boolean;
	rootTableClassName: string;
};

export function useOverlayScrollbarsData({isAgGridInitialized, rootTableClassName}: Parameters) {
	const [initializeHorizontal, osInstanceHorizontal] = useOverlayScrollbars({
		defer: true,
		options: {
			overflow: {
				x: 'scroll',
				y: 'hidden',
			},
			scrollbars: {
				autoHide: 'leave',
				visibility: 'visible',
			},
		},
	});

	const [initializeVertical, osInstanceVertical] = useOverlayScrollbars({
		defer: true,
		options: {
			overflow: {
				x: 'hidden',
				y: 'scroll',
			},
			scrollbars: {
				autoHide: 'leave',
				visibility: 'visible',
			},
		},
	});

	useEffect(() => {
		if (!isAgGridInitialized) {
			return;
		}

		const gridTableRoot = document.querySelector(`.${rootTableClassName}`);

		if (isNull(gridTableRoot)) {
			return;
		}

		const scrollbarContainer: HTMLElement | null = gridTableRoot.querySelector('.ag-body');
		const viewportHorizontal: HTMLElement | null = gridTableRoot.querySelector('.ag-center-cols-viewport');
		const viewportVertical: HTMLElement | null = gridTableRoot.querySelector('.ag-body-viewport');

		if (isNull(scrollbarContainer) || isNull(viewportHorizontal) || isNull(viewportVertical)) {
			return;
		}

		initializeHorizontal({
			target: viewportHorizontal,
			elements: {
				viewport: viewportHorizontal,
			},
			scrollbars: {
				slot: scrollbarContainer,
			},
		});

		initializeVertical({
			target: viewportVertical,
			elements: {
				viewport: viewportVertical,
			},
			scrollbars: {
				slot: scrollbarContainer,
			},
		});

		const agGridHorizontalScrollbar: HTMLElement | null = gridTableRoot.querySelector('.ag-body-horizontal-scroll');
		const agGridVertiacalScrollbar: HTMLElement | null = gridTableRoot.querySelector('.ag-body-vertical-scroll');

		if (!isNull(agGridHorizontalScrollbar)) {
			agGridHorizontalScrollbar.style.display = 'none';
		}

		if (!isNull(agGridVertiacalScrollbar)) {
			agGridVertiacalScrollbar.style.display = 'none';
		}

		return () => {
			osInstanceHorizontal()?.destroy();
			osInstanceVertical()?.destroy();
		};
	}, [
		isAgGridInitialized,
		initializeHorizontal,
		initializeVertical,
		osInstanceHorizontal,
		osInstanceVertical,
		rootTableClassName,
	]);
}
