import {GridBox} from '@esgi/ui/layout';
import {MainSettingsBox} from './index.styled';
import {IFormControl} from '@esgi/ui/form';
import {ContentArea, Subject} from '@esgi/ui/icons';
import {Dispatch} from 'react';
import {sizeItems, viewTypeItems} from './constants';
import {useDynamicAdaptedItems} from './use-dynamic-adapted-items';
import {
	DeployedSubject,
	FormToggleGroup,
	GradeLevel,
	PerformanceWidgetViewType,
	SchoolModel,
	SelectDrawerable,
	WidgetCard,
	WidgetViewSize,
} from '@esgi/main/features/admins/dashboard';

type Props = {
	viewTypeControl: IFormControl<PerformanceWidgetViewType>;
	sizeControl: IFormControl<WidgetViewSize>;
	subjects: DeployedSubject[];
	selectedContentArea: string[];
	setSelectedContentArea: Dispatch<string[]>;
	selectedSubjectIDs: string[];
	setSelectedSubjectIDs: Dispatch<string[]>;
	selectedGradeLevels: string[];
	setSelectedGradeLevels: Dispatch<string[]>;
	schools: SchoolModel[];
	selectedSchoolsIDs: string[];
	setSelectedSchoolsIDs: Dispatch<string[]>;
	gradeLevels: GradeLevel[];
};

export function MainSettings({
	viewTypeControl,
	sizeControl,
	subjects,
	selectedContentArea,
	selectedGradeLevels,
	selectedSubjectIDs,
	setSelectedContentArea,
	setSelectedGradeLevels,
	setSelectedSubjectIDs,
	schools,
	selectedSchoolsIDs,
	setSelectedSchoolsIDs,
	gradeLevels,
}: Props) {
	const {schoolsAdaptedItems, subjectsAdaptedItems, contentAreasAdaptedItems, gradeLevelsAdaptedItems} =
		useDynamicAdaptedItems({
			schools,
			subjects,
			gradeLevels,
		});

	return (
		<MainSettingsBox>
			<GridBox flow='column' gap='2'>
				<WidgetCard.EditMode.FormElement control={viewTypeControl}>
					<FormToggleGroup items={viewTypeItems} />
				</WidgetCard.EditMode.FormElement>

				<WidgetCard.EditMode.FormElement control={sizeControl}>
					<FormToggleGroup items={sizeItems} />
				</WidgetCard.EditMode.FormElement>
			</GridBox>

			<GridBox gap='2'>
				<SelectDrawerable
					placeholder='Schools'
					sectionName='Schools'
					value={selectedSchoolsIDs}
					onSaveSelectedEntity={setSelectedSchoolsIDs}
					selectedValueTranscript='Schools'
					allItems={schoolsAdaptedItems}
					EntityIcon={Subject}
				/>

				<SelectDrawerable
					placeholder='Content Areas'
					sectionName='Content Areas'
					value={selectedContentArea}
					onSaveSelectedEntity={setSelectedContentArea}
					selectedValueTranscript='Content Areas'
					allItems={contentAreasAdaptedItems}
					EntityIcon={ContentArea}
				/>

				<SelectDrawerable
					placeholder='Subject Tabs'
					sectionName='Subjects'
					value={selectedSubjectIDs}
					onSaveSelectedEntity={setSelectedSubjectIDs}
					selectedValueTranscript='Subjects'
					allItems={subjectsAdaptedItems}
					EntityIcon={Subject}
				/>

				<SelectDrawerable
					placeholder='Grade Levels'
					sectionName='Grade Levels'
					value={selectedGradeLevels}
					onSaveSelectedEntity={setSelectedGradeLevels}
					selectedValueTranscript='Grade Levels'
					allItems={gradeLevelsAdaptedItems}
					EntityIcon={Subject}
				/>
			</GridBox>
		</MainSettingsBox>
	);
}
