import {Text} from '@esgi/ui/typography';
import {Container} from './index.styled';

type Props = {
	title: string;
	transcript: string;
};

export function TitleWithTranscription({title, transcript}: Props) {
	return (
		<Container>
			<Text size='xSmall' font='mono' color='mediumContrast'>
				{title}:
			</Text>
			<Text size='xSmall' font='mono' color='base'>
				{transcript}
			</Text>
		</Container>
	);
}
