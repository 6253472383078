import {Button} from '@esgi/ui';
import {Delete, Edit, Eye, More, Skip} from '@esgi/ui/icons';
import {ContextMenuItem} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {UserInfo} from '@esgi/core/authentication';
import {lazyComponent} from '@esgi/core/react';
import {useCallback, useState} from 'react';
import {Test} from '../../../../types/test-panel';
import {isNull} from 'underscore';
import {RemoveAlert} from './components/remove-alert';
import {MoveToAlert} from './components/move-to-alert';
import {DistrictSubject, SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {SubjectLevel, TestType} from '@esgi/main/kits/common';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {Box} from '@esgi/ui/layout';
import {OnMoveToTest} from '../../types';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));
const RubricDetails = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

type Props = {
	currentUser: Readonly<UserInfo>;
	selectedSubject: SubjectModel;
	testInfo: Test;
	districtSubjects: DistrictSubject[];
	onMoveToTest: OnMoveToTest;
	onRemoveTest: VoidFunction;
};

export function TestCardContextMenu({
	currentUser,
	selectedSubject,
	testInfo,
	districtSubjects,
	onMoveToTest,
	onRemoveTest,
}: Props) {
	const [isRemoveAlertOpen, setIsRemoveAlertOpen] = useState(false);
	const [isMoveToAlertOpen, setIsMoveToAlertOpen] = useState(false);
	const [isTestDetailsOpen, setIsTestDetailsOpen] = useState(false);

	const openRemoveAlert = useCallback(() => {
		setIsRemoveAlertOpen(true);
	}, []);

	const closeRemoveAlert = useCallback(() => {
		setIsRemoveAlertOpen(false);
	}, []);

	const openMoveToAlert = useCallback(() => {
		setIsMoveToAlertOpen(true);
	}, []);

	const closeMoveToAlert = useCallback(() => {
		setIsMoveToAlertOpen(false);
	}, []);

	const openCloseTestDetails = useCallback(() => {
		setIsTestDetailsOpen(true);
	}, []);

	const handleCloseTestDetails = useCallback(() => {
		setIsTestDetailsOpen(false);
	}, []);

	const userCanEditTest = currentUser.userID === testInfo.creatorID;
	const canUserMoveTest = selectedSubject.level === SubjectLevel.District && districtSubjects.length >= 2;
	const canUserRemoveTest = selectedSubject.level === SubjectLevel.District;

	return (
		<>
			<TestCard.ContextMenu.Root>
				<TestCard.ContextMenu.Trigger>
					<Button.Icon withBackgroundHover>
						<More />
					</Button.Icon>
				</TestCard.ContextMenu.Trigger>

				<TestCard.ContextMenu.Content>
					<TestCard.ContextMenu.Group>
						{canUserMoveTest && (
							<ContextMenuItem onClick={openMoveToAlert}>
								<Skip />
								<Text size='medium'>Move to...</Text>
							</ContextMenuItem>
						)}
						{canUserRemoveTest && (
							<ContextMenuItem onClick={openRemoveAlert}>
								<Delete />
								<Text size='medium'>Remove...</Text>
							</ContextMenuItem>
						)}
						<ContextMenuItem onClick={openCloseTestDetails}>
							{userCanEditTest ? <Edit /> : <Eye />}
							<Text size='medium'>{userCanEditTest ? 'Edit' : 'View'} Test</Text>
						</ContextMenuItem>
					</TestCard.ContextMenu.Group>
				</TestCard.ContextMenu.Content>
			</TestCard.ContextMenu.Root>

			<Box
				onClick={(event) => event.stopPropagation()}
				css={{
					width: 0,
					height: 0,
					position: 'absolute',
				}}
			>
				{isTestDetailsOpen &&
					(testInfo.type === TestType.Score || testInfo.type === TestType.YN) &&
					!isNull(currentUser) && (
						<TestDetails
							testID={testInfo.id}
							firstName={currentUser.firstName}
							lastName={currentUser.lastName}
							userID={currentUser.userID}
							close={handleCloseTestDetails}
							disableCopyTest
						/>
					)}

				{isTestDetailsOpen && testInfo.type === TestType.Rubric && (
					<RubricDetails testID={testInfo.id} disableCopy disableAddToSubject close={handleCloseTestDetails} />
				)}

				{isRemoveAlertOpen && (
					<RemoveAlert
						testName={testInfo.name}
						subjectName={selectedSubject.name}
						onClose={closeRemoveAlert}
						onConfirm={onRemoveTest}
					/>
				)}

				{isMoveToAlertOpen && (
					<MoveToAlert
						currentSubjectID={selectedSubject.id}
						testName={testInfo.name}
						districtSubjects={districtSubjects}
						onSave={onMoveToTest}
						onClose={closeMoveToAlert}
					/>
				)}
			</Box>
		</>
	);
}
