import {PanelDivider} from '../../../components/panels.styled';
import {GridBox} from '@esgi/ui/layout';
import {SettingPanel} from '@esgi/main/features/admins/subject-management';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {Container} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {SelectedSubject} from '../../../types';

type Props = {
	subject: SelectedSubject;
};

export function SettingsPanel({subject}: Props) {
	return (
		<Container>
			<SettingPanel.Root
				subjectLevel={SubjectLevel.Teacher}
				subjectName={subject.name}
				subjectType={SubjectType.Personal}
			>
				<GridBox display='inlineGrid' flow='column' align='center' justify='start' gap='2'>
					<SettingPanel.SubjectName />
					<SettingPanel.Title size='small' color='mediumContrast'>
						Subject Tab Settings
					</SettingPanel.Title>
				</GridBox>

				<PanelDivider />

				<Text size='small' color='highContrast'>
					Teacher subject tabs cannot be modified or customized. No tests can be added, and existing tests cannot be
					edited.
				</Text>
			</SettingPanel.Root>
		</Container>
	);
}
