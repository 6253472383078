import React, {createRef, ReactNode} from 'react';
import {OverlayScrollbars} from 'overlayscrollbars';
import {combineLatest, Subject} from 'rxjs';
import {debounceTime, skip, takeUntil} from 'rxjs/operators';
import {scrollYToTop} from '@esgi/deprecated/ui-kit/matrix';
import {Modal, ModalBody, ModalManagerRef} from '@esgi/deprecated/ui-kit/modal';
import {ServiceLoader} from '@esgillc/ui-kit/loader';
import {
	RubricEditForm,
	CriteriaActiveView,
	DescriptionActiveView,
	LevelActiveView,
	validateCriteria,
	validateDescriptions,
	validateLevels,
} from '../../../common/rubric-edit-form';
import LocalSavingService, {TestSnapshot} from '../../services/local-save/local-save';
import RubricCreatingService from '../../services/rubric-creating-service';
import RestoreDialog from './components/restore-dialog/restore-dialog';
import CompleteDialog from '../complete-dialog/complete-dialog';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import Preview from '../preview-dialog/preview';
import {Options} from 'modules/assets/tests/rubric/common/rubric-edit-form/types';
import styles from './creator.module.less';

class ValidationState {
	criteriaValid: boolean = false;
	levelsValid: boolean = false;
	levelsStepVisited: boolean = false;
}

class State extends ValidationState {
	currentStep: number = 1;
	maxVisited: number = 1;
	showPreview: boolean = false;
	showLoader: boolean = false;
	unsavedTestModel: TestSnapshot;
	showCompleteDialog: boolean = false;
}

interface Props {
	canAddToSubject: boolean;
	onBackClicked: () => void;
	onCloseClicked: () => void;
	onSaveClicked: () => void;
	disableNewSubjectBtn?: boolean;
}

export default class RubricCreator extends React.PureComponent<Props, State> {
	private onDestroy$: Subject<void> = new Subject();
	private modalManagerRef: ModalManagerRef = createRef();

	private criteriaToRef: CriteriaActiveView[] = [];
	private levelsToRef: LevelActiveView[] = [];
	private descriptionsToRef: DescriptionActiveView[] = [];

	private osInstance: OverlayScrollbars;

	private testCreatingService: RubricCreatingService = new RubricCreatingService();
	private localSaveService: LocalSavingService = new LocalSavingService();

	public state = new State();

	private isAvailable = (number: number) => {
		switch (number) {
			case 1:
				return true;
			case 2:
				return this.state.criteriaValid;
			case 3:
				return this.state.criteriaValid && this.state.levelsValid && this.state.levelsStepVisited;
			default:
				return true;
		}
	};

	public componentDidMount() {
		this.localSaveService.init().subscribe(() => {
			this.localSaveService.getTest().subscribe(t => {
				if (t) {
					const hasCriteria = t.criteriaModels.some(c => !!c.name.trim());
					if (hasCriteria) {
						this.setState({unsavedTestModel: t});
					}
				}
			});

			combineLatest(
				this.testCreatingService.criteria$,
				this.testCreatingService.levelDisplayMode$,
				this.testCreatingService.levels$,
				this.testCreatingService.descriptions$,
				this.testCreatingService.testInfo$,
			).pipe(
				takeUntil(this.onDestroy$),
				debounceTime(100),
				skip(1),
			).subscribe(() => {
				this.localSaveService.saveTest(this.testCreatingService.serialize()).subscribe();
			});
		});

		this.testCreatingService.criteriaValid$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => this.setState({criteriaValid: v}));

		this.testCreatingService.levelsValid$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe(v => this.setState({levelsValid: v}));
	}

	private get options(): Options {
		const currentStep = this.state.currentStep;
		const maxVisitedStep = this.state.maxVisited;
		return {
			criteriaOptions: {
				editMode: currentStep === 1,
				elementClassName: currentStep === 1 && styles.active,
			},
			levelsOptions: {
				editMode: currentStep === 2,
				hide: maxVisitedStep < 2 && currentStep < 2,
				canChangeLevelMode: true,
			},
			descriptionsOptions: {
				editMode: currentStep === 3,
				hide: maxVisitedStep < 3 && currentStep < 3,
				elementClassName: currentStep === 3 && styles.active,
			},
		};
	}

	public render() {
		return <>
			<Modal modalManagerRef={this.modalManagerRef} onCatchError={() => this.props.onCloseClicked()}>
				<Header criteriaValid={this.state.criteriaValid}
				        levelsValid={this.state.levelsValid}
				        levelsStepVisited={this.state.levelsStepVisited}
				        currentStep={this.state.currentStep}
				        isAvailable={this.isAvailable}
				        onStepClicked={(step) => this.nextStep(step)}/>
				<ModalBody className={styles.body}>
					<RubricEditForm options={this.options}
					          overlayScrollRef={(ref) => this.osInstance = ref}
					          rubricService={this.testCreatingService}
					          onCriteriaRendered={this.onCriteriaRendered}
					          onLevelsRendered={this.onLevelRendered}
					          onDescriptionsRendered={this.onDescriptionsRendered}
					/>
				</ModalBody>
				<Footer isAvailable={this.isAvailable}
				        currentStep={this.state.currentStep}
				        onSaveClicked={this.openCompleteStep}
				        onPrevStepClicked={() => this.nextStep(this.state.currentStep - 1)}
				        onNextStepClicked={() => this.nextStep(this.state.currentStep + 1)}
				        onPreviewClicked={() => this.openPreview()}
				        onCancelClicked={() => this.modalManagerRef.current.close(this.props.onBackClicked)}/>
			</Modal>
			{this.renderPreview()}
			{this.renderRestoreDialog()}
			{this.renderCompleteDialog()}
			<ServiceLoader trackingService={this.testCreatingService} fullscreen/>
		</>;
	}

	private renderCompleteDialog(): ReactNode {
		if (this.state.showCompleteDialog) {
			return <CompleteDialog canAddToSubject={this.props.canAddToSubject}
			                       testDataService={this.testCreatingService}
			                       backClicked={() => this.setState({showCompleteDialog: false})}
			                       confirmClicked={this.onSaveClicked}
														 disableNewSubjectBtn={this.props.disableNewSubjectBtn}/>;
		}
	}

	private onCriteriaRendered = (id: number, ref: CriteriaActiveView) => {
		this.criteriaToRef[id] = ref;
	};

	private onLevelRendered = (id: number, ref: LevelActiveView) => {
		this.levelsToRef[id] = ref;
	};

	private onDescriptionsRendered = (id: number, ref: DescriptionActiveView) => {
		this.descriptionsToRef[id] = ref;
	};

	private nextStep(step?: number) {
		if (step === this.state.currentStep) {
			return;
		}

		if (step && step < this.state.currentStep) {
			this.setState({currentStep: step}, () => this.osInstance && scrollYToTop(this.osInstance));
			return;
		}

		let canMoveForward = true;

		if (step > this.state.currentStep) {
			canMoveForward = this.canGoToStep(step);
		}

		if (canMoveForward) {
			this.setState({
				currentStep: step,
				maxVisited: this.state.maxVisited < step ? step : this.state.maxVisited,
				levelsStepVisited: this.state.levelsStepVisited || step >= 2,
			}, () => this.osInstance && scrollYToTop(this.osInstance));
		}
	}

	private canGoToStep(step: number): boolean {
		let canMoveForward = true;
		if (step > 1) {
			canMoveForward = validateCriteria({
				criteria: this.testCreatingService.criteria$.value,
				scrollToIncorrect: this.state.currentStep === 1 ? {
					osInstance: this.osInstance,
					itemToRef: this.criteriaToRef,
				} : null,
			});
		}
		if (canMoveForward && step > 2) {
			canMoveForward = validateLevels({
				levels: this.testCreatingService.levels$.value,
				displayMode: this.testCreatingService.levelDisplayMode$.value,
				scrollToIncorrect: this.state.currentStep === 2 ? {
					osInstance: this.osInstance,
					itemToRef: this.levelsToRef,
				} : null,
			});
		}
		return canMoveForward;
	}

	private openCompleteStep = (): void => {
		const descriptionValid = validateDescriptions({
			criteria: this.testCreatingService.criteria$.value,
			levels: this.testCreatingService.levels$.value,
			descriptions: this.testCreatingService.descriptions$.value,
			scrollToIncorrect: {
				osInstance: this.osInstance,
				itemToRef: this.descriptionsToRef,
			},
		});

		if (descriptionValid) {
			this.setState({showCompleteDialog: true});
		}
	};

	private onSaveClicked = (): void => {
		this.setState({showLoader: true});

		this.testCreatingService.save().subscribe(() => {
			this.modalManagerRef.current.close(this.props.onSaveClicked);
		});
	};

	private renderPreview(): ReactNode {
		if (this.state.showPreview) {
			return <Preview rubricService={this.testCreatingService}
			                onBackClicked={() => this.setState({showPreview: false})}
			                onSaveClicked={() => this.setState({showCompleteDialog: true})}/>;
		}
	}

	private renderRestoreDialog(): ReactNode {
		if (this.state.unsavedTestModel) {
			return <RestoreDialog testModel={this.state.unsavedTestModel}
			                      onRestoreClicked={() => this.restoreTestFromUnsavedCopy()}
			                      onCancelClicked={() => this.setState({unsavedTestModel: null}, () => this.localSaveService.removeTest().subscribe())}/>;
		}
	}

	private restoreTestFromUnsavedCopy() {
		this.testCreatingService.initFromModel(this.state.unsavedTestModel);
		this.setState({unsavedTestModel: null});
	}

	private openPreview() {
		const descriptionValid = validateDescriptions({
			criteria: this.testCreatingService.criteria$.value,
			levels: this.testCreatingService.levels$.value,
			descriptions: this.testCreatingService.descriptions$.value,
			scrollToIncorrect: {
				osInstance: this.osInstance,
				itemToRef: this.descriptionsToRef,
			},
		});

		if (descriptionValid) {
			this.setState({showPreview: true});
		}
	}

	public componentWillUnmount() {
		this.localSaveService.removeTest().subscribe();
		this.localSaveService.destroy();
		this.testCreatingService.destroy();
		this.onDestroy$.next();
	}
}
