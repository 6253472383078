import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {chartCellWidth, chartCellsGap} from './constants';

export const ChartContainer = styled(GridBox, {
	position: 'relative',
	gridTemplateColumns: 'auto 1fr',
	alignItems: 'center',
	gap: '$2',
	overflow: 'hidden',
});

export const ChartCellsBox = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	gap: chartCellsGap,
	gridColumn: '2 / auto',
	paddingRight: 20,

	variants: {
		type: {
			candleChartBar: {
				gridRowStart: 1,
				alignItems: 'center',
			},

			candleChartTranscript: {
				alignItems: 'start',
			},

			overlay: {
				position: 'absolute',
				gridRowStart: 1,
				width: '100%',
				height: '100%',
				zIndex: -1,
			},
		},
	},
});

export const ChartCell = styled(GridBox, {
	width: chartCellWidth,
	gridAutoFlow: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: 2,
});

export const ChartTranscriptCell = styled(GridBox, {
	width: chartCellWidth,
	gap: '$2',
	gridTemplateRows: '24px 16px',
});
