import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(FlexBox, {
	width: '100%',
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
});

export const EmptyContentBox = styled(GridBox, {
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$vivid',
	backgroundColor: '$background',
	paddingTop: 11,
	paddingBottom: 11,
	paddingInline: 20,
});
