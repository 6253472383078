import {IndicatorType} from '@esgi/ui/controls';
import {Dispatch, useCallback, useState} from 'react';
import {subjectNameErrorMessage, subjectNameMaxLength} from './constants';

type Parameters = {
	setValue: Dispatch<string>;
	forbiddenSubjectsNames: string[];
};

export function useUpdateSubjectName({setValue, forbiddenSubjectsNames}: Parameters) {
	const [subjectNameStatus, setSubjectNameStatus] = useState(IndicatorType.None);
	const [subjectNameError, setSubjectNameError] = useState<string | null>(null);

	const onSubjectNameUpdate = useCallback(
		(value: string) => {
			setValue(value);

			if (!value) {
				setSubjectNameStatus(IndicatorType.Failed);
				setSubjectNameError(subjectNameErrorMessage.required);

				return;
			}

			if (value.length > subjectNameMaxLength) {
				setSubjectNameStatus(IndicatorType.Failed);
				setSubjectNameError(subjectNameErrorMessage.maxLength);

				return;
			}

			if (forbiddenSubjectsNames.some((name) => name.toLocaleLowerCase() === value.toLocaleLowerCase())) {
				setSubjectNameStatus(IndicatorType.Failed);
				setSubjectNameError(subjectNameErrorMessage.duplicate);

				return;
			}

			setSubjectNameStatus(IndicatorType.Succeeded);
			setSubjectNameError(null);
		},
		[forbiddenSubjectsNames, setValue],
	);

	return {
		subjectNameError,
		subjectNameStatus,
		onSubjectNameUpdate,
	};
}
