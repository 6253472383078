import {SubjectLevel} from '@esgi/main/kits/common';
import {SchoolSubject} from '@esgi/main/kits/subject-selection-panel';
import {useSubjects, DeployedSubject, isDeployedSubject} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';

type Out = [boolean, SchoolSubject[]];

export function useSubjectsFromStore() {
	const [{data: subjects, loaded: isSubjectsLoaded}] = useSubjects();

	return useMemo<Out>(() => {
		if (!isSubjectsLoaded) {
			return [false, []];
		}

		const filteredDeployedSubjects = subjects.filter((item): item is DeployedSubject => {
			if (isDeployedSubject(item)) {
				const {isPublished, tests, hidden, allowedToAllTeachers} = item;

				return isPublished && Boolean(tests.length) && !hidden && allowedToAllTeachers;
			}

			return false;
		});

		const activeSubjects = filteredDeployedSubjects.map<SchoolSubject>(({id, name, subjectType, gradeLevels}) => ({
			id,
			name,
			type: subjectType,
			level: SubjectLevel.School,
			gradeLevels,
			hidden: false,
		}));

		return [true, activeSubjects];
	}, [isSubjectsLoaded, subjects]);
}
