import {
	LowestAchievementMetricData,
	LowestAchievementMetricDataContract,
} from '@esgi/main/features/district-admin/dashboard';
import {isNull} from 'underscore';

export function parseLowestAchievementMetricData(
	data: LowestAchievementMetricDataContract,
): LowestAchievementMetricData | null {
	if (isNull(data)) {
		return null;
	}

	return data as unknown as LowestAchievementMetricData;
}
