import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/school-admin/data';
import {RowDef} from '../../../../../types';
import {ReportDialog, Reports} from '../../../../../../_kit';

type Props = {
	student: RowDef;
	onClose: VoidFunction;
};

export function ReportsFeature({student, onClose}: Props) {
	const hierarchy = useMemo<Hierarchy>(
		() => ({
			teacherID: student.teacher?.id,
			classID: student.class[0]?.id,
			groupID: student.group[0]?.id,
			specialistsID: student.specialist[0]?.id,
			specialistGroupID: student.specialistGroups[0]?.id,
			studentID: student.id,
		}),
		[student]
	);

	return (
		<ReportDialog
			onClose={onClose}
			header={`${student.firstName} ${student.lastName}`}
			title='Student Reports'
		>
			<Reports.ParentLetter hierarchy={hierarchy} />
			<Reports.StudentDetail hierarchy={hierarchy} />
			<Reports.StudentProgress hierarchy={hierarchy} />
		</ReportDialog>
	);
}
