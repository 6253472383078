import {useEffect} from 'react';
import {config, useSpring} from 'react-spring';

export function usePanelAnimation() {
	const [panelStyles, panelApi] = useSpring(() => ({
		from: {width: 0, opacity: 0},
		config: {
			...config.gentle,
			clamp: true,
		},
	}));

	useEffect(() => {
		panelApi.start({
			from: {width: 0, opacity: 0},
			to: {width: 273, opacity: 1},
		});
	}, []);

	return panelStyles;
}
