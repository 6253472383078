import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const AlertBody = styled(Alert.Body, {
	display: 'grid !important',
});

export const EmptyTextBox = styled(GridBox, {
	height: 40,
	alignItems: 'center',
	justifyContent: 'center',
});
