import {useMemo} from 'react';
import {ClassModel} from '../../../../../../../../../types';
import {ExcludedClassIDs} from './types';
import {WidgetFormSelectItem} from '@esgi/main/features/admins/dashboard';

export function useClassItems({
	allClassesList,
	excludedClassIDs,
}: {
	allClassesList: ClassModel[];
	excludedClassIDs: ExcludedClassIDs;
}) {
	return useMemo(() => {
		const firstClassItems: WidgetFormSelectItem<string>[] = [];
		const secondClassItems: WidgetFormSelectItem<string>[] = [];

		allClassesList.forEach(({classID, className}) => {
			const idString = String(classID);
			const formClassItem: WidgetFormSelectItem<string> = {
				value: idString,
				label: className,
			};

			if (idString !== excludedClassIDs.secondClassID) {
				firstClassItems.push(formClassItem);
			}

			if (idString !== excludedClassIDs.firstClassID) {
				secondClassItems.push(formClassItem);
			}
		});

		return {
			firstClassItems,
			secondClassItems,
		};
	}, [allClassesList, excludedClassIDs]);
}
