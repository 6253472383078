import {SortingComparatorCb} from '@esgi/main/kits/test-results-panel';
import {AdaptedTestCard} from '../../../../types';

export const lastTestedComparator: SortingComparatorCb<AdaptedTestCard> = (a, b) => {
	const aValue = a.analyticsData?.lastSessionDate;
	const bValue = b.analyticsData?.lastSessionDate;

	if (aValue && bValue) {
		return new Date(bValue).getTime() - new Date(aValue).getTime();
	}

	if (!aValue && bValue) {
		return 1;
	}

	if (aValue && !bValue) {
		return -1;
	}

	return 0;
};
