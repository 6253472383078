import {Text} from '@esgi/ui/typography';
import {Container} from './index.styled';

type Props = {
	messages: string[];
};

export function DataMessagesInfoBox({messages}: Props) {
	return (
		<Container>
			{messages.map((message) => (
				<Text size='small' font='mono' color='highContrast' key={message}>
					{message}
				</Text>
			))}
		</Container>
	);
}
