import {CollapsiblePanel} from '@esgi/main/features/admins/data';
import {styled} from '@esgi/ui/theme';

export const QuickPanelContainer = styled(CollapsiblePanel.QuickPanel, {
	variants: {
		isPanelExpanded: {
			false: {
				display: 'grid',
				rowGap: 20,
				backgroundColor: '$surface',
				paddingBottom: 20,
			},
		},
	},
});
