import {EnhancedStore} from '@reduxjs/toolkit';
import {HookMiddleware} from '../react';
import {BehaviorSubject, filter, Observable, Subject, takeUntil, tap} from 'rxjs';
import {BaseEntity, createStoreSlice, State} from '../slice-utils';
import {Disposable} from '@esgi/core/service';

type SliceType<T extends BaseEntity> = ReturnType<typeof createStoreSlice<T>>['slice'];

type SliceActions<T extends SliceType<A>['actions'], A extends BaseEntity> = {
	[K in keyof T]: (payload: T[K] extends (...args: infer P) => void ? P[0] : never) => void;
};

export function serviceAdapter<
	T extends BaseEntity,
>(slice: SliceType<T>, store: EnhancedStore, ...hookMiddlewares: HookMiddleware<State<T>, (typeof slice)['actions']>[]) {
	return function (): SliceActions<typeof slice.actions, T> & {get: () => Observable<State<T>>} & Disposable {
		const actions: SliceActions<typeof slice.actions, T> = {} as any;
		for (const actionKey in slice.actions) {
			actions[actionKey] = function () {
				const action = slice.actions[actionKey];
				store.dispatch(action(...arguments));
			};
		}

		const onDispose = new Subject<void>();
		const state = new BehaviorSubject<State<T> | null>(store.getState()[slice.name]);

		const unsub = store.subscribe(() => {
			const sliceState = store.getState();
			if (state.closed) {
				unsub();
				return;
			}
			state.next(sliceState[slice.name]);
		});

		function get(): Observable<State<T>> {
			return state.pipe(
				takeUntil(onDispose),
				filter(Boolean),
				tap(() => {
					const state = store.getState();
					for (const middleware of hookMiddlewares) {
						middleware(state[slice.name], undefined as any);
					}
				})
			) as Observable<State<T>>;
		}
		return {
			get,
			...actions,
			dispose: () => {
				onDispose.next();
				onDispose.complete();
			},
		};
	};
}