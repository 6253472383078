import {Question, TestInfo, TestSession} from '../../../../../../types/view-edit-session-details';
import {Student, AnswerState} from '../../../../../../types/common';
import {AlertBody} from '../../../components.styled';
import {QuestionRowViewMode} from '../../../question-row-view-mode';
import {SessionTimeInfo} from '../../../session-time-info';
import {CorrectAnswerIcon} from './correct-answer-icon';
import {IncorrectAnswerIcon} from './incorrect-answer-icon';
import {NotTestedAnswerIcon} from './not-tested-answer-icon';
import {FilterState} from '../types';
import {useCallback, useMemo, useState} from 'react';
import {Textarea} from '@esgi/ui/controls';
import {noop} from 'underscore';
import {TestNameWithBar} from './test-name-with-bar';
import {FilterContainer} from '../index.styled';
import {AlertFooter} from '../../../alert-footer';
import {FilterButton} from './filter-button';
import {useFilterItems} from '../use-filter-items';
import {AlertHeader} from '../../../alert-header';
import {AlertHeaderDeletedSession} from '../../../alert-header/deleted-session';
import {AlertFooterDeletedSession} from '../../../alert-footer-deleted-session';
import {QuestionsContainer} from '../../../../../../components';
import {concatNotTestedQuestions} from '../utils';

type Props = {
	canEdit: boolean;
	sessionInfo: TestSession;
	questionTitlesById: Record<Question['id'], Question['label']>;
	testInfo: TestInfo;
	onCloseAlert: VoidFunction;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	student: Student;
	onDeleteSession: VoidFunction;
	onRestoreSession: VoidFunction;
	onDownloadSession: VoidFunction;
};

export function ViewModeContent({
	canEdit,
	sessionInfo,
	questionTitlesById,
	testInfo,
	onCloseAlert,
	setIsEditMode,
	student,
	onDeleteSession,
	onRestoreSession,
	onDownloadSession,
}: Props) {
	const [selectedAnswerStates, setSelectedAnswerStates] = useState<FilterState>({
		[AnswerState.Correct]: true,
		[AnswerState.Incorrect]: true,
		[AnswerState.NotTested]: true,
	});

	const toggleAnswerState = useCallback((state: AnswerState) => {
		setSelectedAnswerStates((currentValue) => ({
			...currentValue,
			[state]: !currentValue[state],
		}));
	}, []);

	const filteredSessionAnswers = useMemo(() => {
		return concatNotTestedQuestions(testInfo, sessionInfo.answers).filter(
			({answerState}) => selectedAnswerStates[answerState],
		);
	}, [selectedAnswerStates, sessionInfo.answers, testInfo]);

	const {filterItems, correctAnswers} = useFilterItems({sessionInfo});

	return (
		<>
			{sessionInfo.isDeleted ? (
				<AlertHeaderDeletedSession onCloseAlert={onCloseAlert} student={student} />
			) : (
				<AlertHeader
					canEdit={canEdit}
					isEditMode={false}
					onCloseAlert={onCloseAlert}
					onDeleteSession={onDeleteSession}
					onEditModeClick={() => setIsEditMode(true)}
					student={student}
				/>
			)}

			<AlertBody>
				<TestNameWithBar testInfo={testInfo} correctAnswers={correctAnswers} />

				<FilterContainer>
					{filterItems.map(({answerState, countAnswers}, index) => (
						<FilterButton
							answerState={answerState}
							countAnswers={countAnswers}
							disabled={false}
							isFilterActive={Boolean(selectedAnswerStates[answerState])}
							onClick={toggleAnswerState}
							key={index}
						/>
					))}
				</FilterContainer>

				<SessionTimeInfo duration={sessionInfo.duration} testDate={sessionInfo.testDate} />

				<QuestionsContainer>
					{filteredSessionAnswers.map(({answerState, comment, questionID}, index) => (
						<QuestionRowViewMode
							questionNumber={index + 1}
							questionTitle={questionTitlesById[questionID] ?? ''}
							comment={comment}
							withQuestionUnderline={index !== filteredSessionAnswers.length - 1}
							key={questionID}
						>
							{answerState === AnswerState.Correct && <CorrectAnswerIcon />}
							{answerState === AnswerState.Incorrect && <IncorrectAnswerIcon />}
							{answerState === AnswerState.NotTested && <NotTestedAnswerIcon />}
						</QuestionRowViewMode>
					))}
				</QuestionsContainer>
				<Textarea
					placeholder='Summary Note'
					value={sessionInfo.notes ?? ''}
					disabled
					onChange={noop}
					dataCy='summary-note'
				/>
			</AlertBody>

			{sessionInfo.isDeleted ? (
				canEdit && <AlertFooterDeletedSession onRestoreSession={onRestoreSession} />
			) : (
				<AlertFooter isEditMode={false} onDownload={onDownloadSession} />
			)}
		</>
	);
}
