import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {SelectedEntityInfoItem} from './types';
import {GridBox} from '@esgi/ui/layout';
import {Key} from 'react';
import {OneLinedText} from '@esgi/main/kits/common';

type Props<T extends Key> = {
	items: SelectedEntityInfoItem<T>[];
	totalPossibleItemsCount: number;
	itemsTranscript: string;
};

export {type SelectedEntityInfoItem} from './types';

export function SelectedEntityInfo<T extends Key>({items, totalPossibleItemsCount, itemsTranscript}: Props<T>) {
	if (totalPossibleItemsCount === items.length) {
		return (
			<Text size='small' color='highContrast'>
				{`All ${itemsTranscript}`}
			</Text>
		);
	}

	if (items.length === 1) {
		return (
			<OneLinedText size='small' color='highContrast'>
				{items[0]!.label}
			</OneLinedText>
		);
	}

	return (
		<Tooltip>
			<Tooltip.Trigger>
				<Text size='small' color='highContrast'>
					{`${items.length} ${itemsTranscript}`}
				</Text>
			</Tooltip.Trigger>

			<Tooltip.Content>
				<GridBox gap='1'>
					{items.map(({value, label}) => (
						<Text size='xSmall' font='mono' color='tertiary' key={value}>
							{label}
						</Text>
					))}
				</GridBox>
			</Tooltip.Content>
		</Tooltip>
	);
}
