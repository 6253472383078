import {Plus} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {Button} from './index.styled';
import {ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback} from 'react';
import {usePanelContext} from '../../../../context';
import {PanelMode} from '../../../../types/section';

type Props = Omit<ComponentPropsWithoutRef<typeof Button>, 'children' | 'skeleton'>;

export const AddSubjectButton = forwardRef<HTMLButtonElement, Props>(
	({dataCy = 'subjects-panel-add-subject-button', css = {}, disabled, onClick, ...props}, forwardedRed) => {
		const {panelMode, setPanelMode, skeleton} = usePanelContext();

		const isAddMode = panelMode === PanelMode.Add;

		const handleButtonClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				if (isAddMode) {
					return;
				}

				setPanelMode(PanelMode.Add);
				onClick?.(event);
			},
			[isAddMode, onClick, setPanelMode],
		);

		return (
			<Button
				dataCy={dataCy}
				css={css}
				isActive={isAddMode}
				disabled={panelMode === PanelMode.Rearrange || disabled || skeleton}
				skeleton={skeleton}
				onClick={handleButtonClick}
				ref={forwardedRed}
				{...props}
			>
				<Plus />
				<Text size='medium' bold>
					Add Subject Tab
				</Text>
			</Button>
		);
	},
);
