import {generatePeriod} from './generate-period';
import {SchoolYearType} from './types';

export const trackDateLabels: Record<SchoolYearType, string[]> = {
	[SchoolYearType.Semester]: generatePeriod('S', 2),
	[SchoolYearType.Trimester]: generatePeriod('T', 3),
	[SchoolYearType.Quarter]: generatePeriod('Q', 4),
	[SchoolYearType.Periods5]: generatePeriod('P', 5),
	[SchoolYearType.Periods6]: generatePeriod('P', 6),
	[SchoolYearType.Periods7]: generatePeriod('P', 7),
	[SchoolYearType.Periods8]: generatePeriod('P', 8),
	[SchoolYearType.Periods9]: generatePeriod('P', 9),
};
