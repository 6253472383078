import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';
import {ShowStudentIDType} from '../../../../../types';

export type ReportsSettingsFormType = FormGroup<{
	reportShowStudentID: FormControl<ShowStudentIDType>,
},
	ControlValue<{
		reportShowStudentID: FormControl<ShowStudentIDType>,
	}>>

export const createReportsSettingsForm = ():
	ReportsSettingsFormType => new FormGroup({
	reportShowStudentID: new FormControl<ShowStudentIDType>(ShowStudentIDType.None),
});
