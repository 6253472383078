import {IFormControl} from '@esgi/ui/form';
import {Select} from '@esgi/ui/form-controls';
import {Text} from '@esgi/ui/typography';
import {ErrorTooltip, ErrorTooltipItem} from '../error-tooltip';
import {FormSelectItem} from './types';
import {FormElementRoot} from './index.styled';

type Props<T extends string, E extends string> = {
	placeholder: string;
	control: IFormControl<T[]>;
	items: FormSelectItem<T>[];
	errorItems?: ErrorTooltipItem<E>[];
};

export function FormElementSelect<T extends string, E extends string>({
	placeholder,
	control,
	items,
	errorItems,
}: Props<T, E>) {
	return (
		<FormElementRoot control={control}>
			<Select.Root>
				<Select.Field placeholder={placeholder} />
				<Select.Content>
					{items.map(({value, label}) => (
						<Select.Option value={value} key={value}>
							<Text size='medium' bold>
								{label}
							</Text>
						</Select.Option>
					))}
				</Select.Content>
			</Select.Root>

			{errorItems?.map(({showOnError, message}) => (
				<ErrorTooltip showOnError={showOnError} message={message} key={showOnError} />
			))}
		</FormElementRoot>
	);
}
