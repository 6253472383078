//TODO: Utils entry point.
import {StudentCUDEventData} from './types';

export class StudentCreatedEvent {
	constructor(public data: StudentCUDEventData) {}
}

export class StudentDeletedEvent {
	constructor(public data: StudentCUDEventData) {}
}
