import * as React from 'react';
import type {SVGProps} from 'react';

export function Students(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.625 9.33333C10.7611 9.33333 9.25 10.8258 9.25 12.6667C9.25 14.5075 10.7611 16 12.625 16C13.3248 16 13.9877 15.7892 14.5498 15.4008C14.9317 15.1375 15.0277 14.6067 14.7607 14.2292C14.4937 13.8517 13.9825 13.7833 13.6005 14.0467C13.3197 14.2408 12.9762 14.3333 12.625 14.3333C11.693 14.3333 10.9375 13.5875 10.9375 12.6667C10.9375 11.7458 11.693 11 12.625 11C12.9002 11 13.152 11.06 13.3896 11.1825C13.803 11.395 14.3348 11.2517 14.5498 10.8433C14.7648 10.4358 14.5941 9.90999 14.1807 9.69832C13.7039 9.45332 13.1729 9.33333 12.625 9.33333ZM10.1465 17.12C8.59896 17.7508 7.5625 19.2967 7.5625 21V22.6667C7.5625 23.1267 7.94025 23.5 8.40625 23.5H11.7812C12.2473 23.5 12.625 23.1267 12.625 22.6667C12.625 22.2067 12.2473 21.8333 11.7812 21.8333H9.25V21C9.25 20.0633 9.81253 19.2033 10.4591 18.825C11.0672 19.0783 11.7105 19.27 12.2825 19.3175C12.7469 19.3567 13.1657 19.0108 13.2051 18.5517C13.2445 18.0933 12.9089 17.705 12.4446 17.6658C12.0742 17.635 11.6694 17.525 11.2012 17.3283C11.0881 17.2808 11.0225 17.2433 10.8057 17.1458C10.5932 17.05 10.3623 17.0317 10.1465 17.12Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.9531 8.5C16.8562 8.5 15.1562 10.1792 15.1562 12.25C15.1562 14.3208 16.8562 16 18.9531 16C21.0501 16 22.75 14.3208 22.75 12.25C22.75 10.1792 21.0501 8.5 18.9531 8.5ZM18.9531 10.1667C20.1181 10.1667 21.0625 11.0992 21.0625 12.25C21.0625 13.4008 20.1181 14.3333 18.9531 14.3333C17.7882 14.3333 16.8438 13.4008 16.8438 12.25C16.8438 11.0992 17.7882 10.1667 18.9531 10.1667ZM16.0527 17.12C14.5052 17.7508 13.4687 19.2967 13.4687 21V22.6667C13.4687 23.1267 13.8465 23.5 14.3125 23.5H23.5938C24.0598 23.5 24.4375 23.1267 24.4375 22.6667V21C24.4375 19.3367 23.3953 17.8283 21.8535 17.1717C21.6365 17.0792 21.3836 17.0767 21.168 17.1717C20.4214 17.5025 19.6941 17.6667 18.9531 17.6667C18.2147 17.6667 17.4848 17.4933 16.712 17.1458C16.4994 17.05 16.2686 17.0317 16.0527 17.12ZM16.3772 18.825C17.1743 19.1233 18.1354 19.3333 18.9531 19.3333C19.7644 19.3333 20.6858 19.1375 21.4668 18.8525C22.2284 19.2808 22.75 20.0825 22.75 21V21.8333H15.1562V21C15.1562 20.0292 15.7097 19.2108 16.3772 18.825Z'
				fill='#333333'
			/>
		</svg>
	);
}
