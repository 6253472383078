import moment from 'moment';
import {UserInfo, UserType} from '../authentication';

declare global {
	interface Window {
		InlineManual: InlineManualType;
		inline_manual_player: {
			showPanel(): void;
		}
	}
}

export const inlineManualKeyMap: Partial<Record<UserType, string>> = {
	[UserType.T]: 'bdd8a27aa2f237b4f0bc9ad2301e3dc0',
	[UserType.D]: 'bed1e043b03bd7b7132257762c03811f',
	// TODO: replace with correct school admin key below
	[UserType.C]: 'bed1e043b03bd7b7132257762c03811f',
};

export const inlineManualLoaderUrl = 'https://cdn.inlinemanual.com/inm/loader/loader';
export const inlineManualDefaultOptions: InlineManualOptions = {
	language: 'en',
};

export interface InlineManualTracking {
	uid: number;
	name?: string;
	roles?: string[];
	sso_registration_type?: string;
	created?: number;
	districtID?: number;
	is_linked?: boolean;
}

export interface InlineManualOptions {
	language: string;
}

export interface InlineManualType {
	(...params: any[]): void;
	q?: any[];
	l: number;
	snippet: InlineManualVersion;
}

export interface InlineManualVersion {
	version: string;
}

export function getTrackingDataModel(user: UserInfo): InlineManualTracking {
	return {
		uid: user.userID,
		name: `${user.firstName} ${user.lastName}`,
		roles: [UserType[user.userType]],
		created: moment(user.createDate).unix(),
		districtID: user.districtID,
		// eslint-disable-next-line camelcase
		is_linked: user.agreementLevelCode === 'D',
		// eslint-disable-next-line camelcase
		sso_registration_type: user.ssoRegistrationType,
	};
}