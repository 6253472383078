import {ComponentProps} from 'react';
import {Cheap} from '@esgi/ui';
import {SubjectLevel} from '@esgi/main/libs/store';
import {subjectPrefixByLevel, SubjectLevel as SubjectLevelCommon} from '@esgi/main/kits/common';

export const cheapContent: Partial<
	Record<
		SubjectLevel,
		{
			cheapTitle: string;
			cheapColor: NonNullable<ComponentProps<typeof Cheap>['color']>;
		}
	>
> = {
	[SubjectLevel.District]: {
		cheapTitle: subjectPrefixByLevel[SubjectLevelCommon.District],
		cheapColor: 'secondary',
	},
	[SubjectLevel.School]: {
		cheapTitle: subjectPrefixByLevel[SubjectLevelCommon.School],
		cheapColor: 'primary',
	},
	[SubjectLevel.Teacher]: {
		cheapTitle: subjectPrefixByLevel[SubjectLevelCommon.Teacher],
		cheapColor: 'neutral',
	},
};
