import {TestType} from '@esgi/main/kits/common';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useMemo} from 'react';
import {isNull} from 'underscore';

type Props = {
	testType: TestType;
	analyticsDataTotal: number | null;
	totalPossible: number;
	rubricCriteriaCount: number;
};

type AnalyticsItem = {
	value: number;
	transcript: string;
};

export function TotalPossibleAnalytic({testType, analyticsDataTotal, totalPossible, rubricCriteriaCount}: Props) {
	const analyticsItem = useMemo<AnalyticsItem | null>(() => {
		if (testType === TestType.YN) {
			return {
				value: analyticsDataTotal ?? totalPossible,
				transcript: 'Questions',
			};
		}

		if (testType === TestType.Rubric) {
			return {
				value: rubricCriteriaCount,
				transcript: 'Criteria',
			};
		}

		if (testType === TestType.Score) {
			return {
				value: 1,
				transcript: 'Question',
			};
		}

		return null;
	}, [analyticsDataTotal, rubricCriteriaCount, testType, totalPossible]);

	if (isNull(analyticsItem)) {
		return <Box />;
	}

	return (
		<TestCard.Statistic.InfoWithTranscript transcript={analyticsItem.transcript}>
			<Text size='medium' font='mono' bold color='base'>
				{analyticsItem.value}
			</Text>
		</TestCard.Statistic.InfoWithTranscript>
	);
}
