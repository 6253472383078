import {Class, Student, User} from '@esgi/main/libs/admin-store';
import {generateRandomString, isUndefined, useStreamEffect} from '@esgi/ui';
import {ElementStatus, FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {Dispatch, useState} from 'react';
import {classNameMaxLength} from '../../constants';
import {AddClassForm} from './types';

type Parameters = {
	classes: Class[];
	teachers: User[];
	setIsFormValid: Dispatch<boolean>;
	setIsFormTouched: Dispatch<boolean>;
};

export function useAddForm({classes, teachers, setIsFormValid, setIsFormTouched}: Parameters) {
	const [selectedSchoolID, setSelectedSchoolID] = useState<number | null>(null);
	const [selectedPrimaryTeacherID, setSelectedPrimaryTeacherID] = useState<User['id'] | null>(null);
	const [activePrimaryTeachersList, setActivePrimaryTeachersList] = useState<User[]>([]);
	const [primaryTeacherUniqueKey, setPrimaryTeacherUniqueKey] = useState(generateRandomString());

	const form: AddClassForm = useForm(
		() =>
			new FormGroup({
				schoolID: new FormControl<string[]>([], {validators: [Validators.required()]}),
				primaryTeacherID: new FormControl<string[]>([], {
					validators: [Validators.required()],
					initialValidateStatus: ElementStatus.disabled,
				}),
				className: new FormControl('', {
					initialValidateStatus: ElementStatus.disabled,
				}),
				studentsIDs: new FormControl<Student['id'][]>([]),
			}),
	);

	useStreamEffect(form.controls.schoolID.onChanged, ({currState: {value}, reason}) => {
		const schoolID = value[0];

		if (!isUndefined(schoolID) && reason === 'value') {
			const numberedSchoolID = Number(schoolID);
			const teachersInSchool = teachers.filter((teacher) => teacher.schoolID === numberedSchoolID);

			setActivePrimaryTeachersList(teachersInSchool);
			setPrimaryTeacherUniqueKey(generateRandomString());

			form.controls.primaryTeacherID.value = [];
			form.controls.primaryTeacherID.status = ElementStatus.untouched;
			setSelectedPrimaryTeacherID(null);

			form.controls.className.status = ElementStatus.disabled;

			form.validate(true).subscribe(({valid}) => {
				setIsFormValid(valid);
			});

			setSelectedSchoolID(numberedSchoolID);
		}
	});

	useStreamEffect(form.controls.primaryTeacherID.onChanged, ({currState: {value}, reason}) => {
		const primaryTeacherID = value[0];

		if (!isUndefined(primaryTeacherID) && reason === 'value') {
			const numberedPrimaryTeacherID = Number(primaryTeacherID);

			const teacherClasses = classes.filter(({teacherID}) => teacherID === numberedPrimaryTeacherID);

			form.controls.className.validators.length = 0;

			form.controls.className.validators.push(
				Validators.required(),
				Validators.length.max(classNameMaxLength),
				Validators.isDublicateValue(teacherClasses.map(({name}) => name)),
			);

			form.controls.className.status = ElementStatus.untouched;

			form.validate().subscribe(({valid}) => {
				setIsFormValid(valid);
			});

			setSelectedPrimaryTeacherID(numberedPrimaryTeacherID);
		}
	});

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {studentsIDs, className, ...value},
			},
		}) => {
			const schoolID = value.schoolID[0];
			const primaryTeacherID = value.primaryTeacherID[0];

			const isFormTouched =
				className !== '' || !isUndefined(schoolID) || !isUndefined(primaryTeacherID) || Boolean(studentsIDs.length);

			setIsFormTouched(isFormTouched);

			form.validate(true).subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		},
	);

	return {
		form,
		primaryTeacherUniqueKey,
		activePrimaryTeachersList,
		selectedPrimaryTeacherID,
		selectedSchoolID,
	};
}
