import {DateFormatText} from '@esgi/main/kits/common';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {Text} from '@esgi/ui/typography';
import {isNull} from 'underscore';

type Props = {
	testDate: Date | string | null;
};

export function LastDateAnalytic({testDate}: Props) {
	return (
		<TestCard.Statistic.InfoWithTranscript transcript='Last Test Date'>
			{!isNull(testDate) ? (
				<DateFormatText date={testDate} dateFormat='MM/DD/YYYY' size='medium' font='mono' bold color='base' />
			) : (
				<Text size='medium' font='mono' bold color='base'>
					-
				</Text>
			)}
		</TestCard.Statistic.InfoWithTranscript>
	);
}
