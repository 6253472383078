import {Text} from '@esgi/ui/typography';
import {
	ItemsAverageInfoLine,
	ItemsAverageInfoValueBox,
	ItemsAverageInfoValueCheap,
	Overlay,
	itemsAverageInfoValueCheapHeight,
} from './index.styled';

type Props = {
	value: number;
};

export function ItemsAverageInfo({value}: Props) {
	return (
		<Overlay>
			<ItemsAverageInfoLine
				css={{
					bottom: `${value}%`,
				}}
			/>

			<ItemsAverageInfoValueBox
				css={{
					bottom: `calc(${value}% - ${itemsAverageInfoValueCheapHeight / 2}px)`,
				}}
				// if value more than 95, the "Avg" label hidden, and we should render under the value box
				isAvgLabelVisible={value < 95}
			>
				<Text size='xSmall' font='mono' bold color='primaryVivid'>
					Avg
				</Text>

				<ItemsAverageInfoValueCheap>
					<Text size='xSmall' font='mono' bold color='primaryVivid'>
						{value}%
					</Text>
				</ItemsAverageInfoValueCheap>
			</ItemsAverageInfoValueBox>
		</Overlay>
	);
}
