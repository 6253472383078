import {ComponentPropsWithoutRef, Dispatch, forwardRef, useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {BaseComponentProps, Button} from '@esgi/ui';
import {PanelMode, TabID} from '../../../../types/section';
import {SubjectModel} from '../../../../types/subject';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {usePanelContext} from '../../../../context';
import {Rearrange} from '../../../lists/rearrange';
import {Content} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {useTabsContext} from '../../../../../tabs/context';
import {isNull} from 'underscore';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<typeof Content>, 'children'> & {
		subjects: SubjectModel[];
		onSaveClick: Dispatch<SubjectModel[]>;
	};

export const RearrangeMode = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'tab-rearrange-mode', css = {}, subjects, onSaveClick, ...contentProps}, forwardedRef) => {
		const [controlledSubjects, setControlledSubjects] = useState<SubjectModel[]>([]);

		const {panelMode, setPanelMode, addTabWithRearrangeMode, removeTabWithRearrangeMode} = usePanelContext();
		const {activeTab} = useTabsContext<TabID>();

		const isRearrangeMode = panelMode === PanelMode.Rearrange;

		useEffect(() => {
			if (isNull(activeTab)) {
				return;
			}

			addTabWithRearrangeMode(activeTab);

			return () => removeTabWithRearrangeMode(activeTab);
		}, [activeTab]);

		useLayoutEffect(() => {
			setControlledSubjects(isRearrangeMode ? subjects : []);
		}, [isRearrangeMode, subjects]);

		const handleSaveClick = useCallback(() => {
			onSaveClick(controlledSubjects);
			setPanelMode(PanelMode.View);
		}, [onSaveClick, controlledSubjects, setPanelMode]);

		if (isRearrangeMode) {
			return (
				<Content dataCy={dataCy} css={css} ref={forwardedRef} {...contentProps}>
					<OverlayScrollbarsComponent
						defer
						options={{
							scrollbars: {autoHide: 'leave'},
						}}
						style={{height: 'calc(100% + 0px)'}}
					>
						<Rearrange subjects={controlledSubjects} onDragEnd={setControlledSubjects} />
					</OverlayScrollbarsComponent>

					<Button color='secondary' onClick={handleSaveClick}>
						<Text size='medium' bold color='base'>
							Save Changes
						</Text>
					</Button>
				</Content>
			);
		}

		return null;
	},
);
