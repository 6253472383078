import {Folder} from '@esgi/ui/icons';
import {Drawer, StudentsSearchableList} from '@esgi/main/features/teacher/home';
import {Group, useGroups} from '@esgi/main/libs/store';
import {DrawerBody} from '../drawer-body.styled';
import {useCallback, useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {EditGroupService} from './service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {ClassModel, Student} from '../../types';
import {dispatchAppEvent, useEventEffect} from '@esgillc/events';
import {EditGroup as EditGroupEvent, RemoveGroup} from '../../events';
import {GroupNameForm} from '../group-name-form';
import {Input} from '@esgi/ui/controls';
import {GroupContextMenu} from './components/group-context-menu';
import {isUndefined} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';
import {AddStudent} from '../../../drawer-with-student-via-profile/events';

type Props = {
	onLoaded: (value: boolean) => void;
	initialClassID: ClassModel['id'];
	groupID: number;
	schoolYear: number;
	onFormTouched: (value: boolean) => void;
	onAddStudentByRef?: VoidFunction;
};

export function EditGroup({onLoaded, initialClassID, groupID, schoolYear, onFormTouched, onAddStudentByRef}: Props) {
	const forceDrawerClose = Drawer.useForceDrawerClose();

	const user = useUser();

	useEffect(() => {
		if(user?.globalSchoolYearID !== schoolYear) {
			forceDrawerClose();
		}
	}, [user?.globalSchoolYearID, schoolYear]);

	const [{data: groupList, loaded: isGroupListLoaded}] = useGroups();

	const [isFormSubmitting, setIsFormSubmitting] = useState(false);

	const [groupName, setGroupName] = useState<string | null>(null);
	const [isGroupNameFormValid, setIsGroupNameFormValid] = useState(false);
	const [isGroupNameFormTouched, setIsGroupNameFormTouched] = useState(false);

	const [selectedStudentIds, setSelectedStudentIds] = useState<Student['id'][]>([]);
	const [selectedStudentIdsChanged, setSelectedStudentIdsChanged] = useState(false);

	const dataService = useService(EditGroupService);

	const studentsList = useBehaviorSubject(dataService.students$);
	const className = useBehaviorSubject(dataService.className$);
	const initialGroupName = useBehaviorSubject(dataService.initialGroupName$);
	const initialSelectedStudentsIds = useBehaviorSubject(dataService.initialSelectedStudentsIds$);

	useEffect(() => {
		onLoaded(false);

		if (!isGroupListLoaded) {
			return;
		}

		dataService.init({classID: initialClassID, groupID}).subscribe(({initialSelectedStudentsIds}) => {
			setSelectedStudentIds(initialSelectedStudentsIds);
			onLoaded(true);
		});
	}, [isGroupListLoaded]);

	useEffect(() => {
		onFormTouched(isGroupNameFormTouched || selectedStudentIdsChanged);
	}, [isGroupNameFormTouched || selectedStudentIdsChanged]);

	const handleSaveGroup = () => {
		if (groupName) {
			setIsFormSubmitting(true);

			dataService.updateGroup({groupName, studentIDs: selectedStudentIds, groupID}).subscribe({
				next: () => {
					const updatedGroup: Group = {
						classID: initialClassID,
						id: groupID,
						name: groupName,
						studentIDs: selectedStudentIds,
					};

					dispatchAppEvent(EditGroupEvent, new EditGroupEvent(updatedGroup));
				},
				complete: () => {
					setIsFormSubmitting(false);
					forceDrawerClose();
				},
			});
		}
	};

	useEventEffect(AddStudent, (event) => {
		const currentStudents = studentsList ?? [];

		const {id, firstName, lastName} = event.studentsData.student;
		const updatedStudents = [...currentStudents, {id, firstName, lastName}];

		const updatedSelectedStudentIds = [...selectedStudentIds, event.studentsData.student.id];
		setSelectedStudentIds(updatedSelectedStudentIds);

		dataService.students$.next(updatedStudents);
	});

	const onDeleteGroup = useCallback(() => {
		dataService.deleteGroup({groupID}).subscribe({
			next: () => {
				const contractedGroup = groupList.find(({id}) => id === groupID);

				if (!isUndefined(contractedGroup)) {
					dispatchAppEvent(RemoveGroup, new RemoveGroup(contractedGroup));
				}
			},
			complete: () => {
				forceDrawerClose();
			},
		});
	}, [dataService, forceDrawerClose, groupID, groupList]);

	const isActionButtonDisabled =
		!((isGroupNameFormValid && isGroupNameFormTouched) || selectedStudentIdsChanged) || isFormSubmitting;

	return (
		<>
			<Drawer.Header
				Icon={Folder}
				sectionName='Edit Group'
				withActionButton
				actionButtonDisabled={isActionButtonDisabled}
				actionButtonText='Save Changes'
				onActionButtonClick={handleSaveGroup}
			>
				<GroupContextMenu groupName={initialGroupName ?? ''} onDeleteGroup={onDeleteGroup} />
			</Drawer.Header>
			<DrawerBody>
				<Drawer.ContentBlock title='Details'>
					<Input placeholder='Class' value={className ?? ''} disabled />
					<GroupNameForm
						initialGroupName={initialGroupName ?? ''}
						setIsFormValid={setIsGroupNameFormValid}
						setGroupName={setGroupName}
						onFormTouched={setIsGroupNameFormTouched}
					/>
				</Drawer.ContentBlock>
				<Drawer.ContentBlock title='Select Students' titleBold>
					<StudentsSearchableList
						students={studentsList ?? []}
						selectedStudentIds={selectedStudentIds}
						setSelectedStudentIds={setSelectedStudentIds}
						initialSelectedStudentIds={initialSelectedStudentsIds}
						setSelectedValueChanged={setSelectedStudentIdsChanged}
						onAddStudentByRef={onAddStudentByRef}
					/>
				</Drawer.ContentBlock>
			</DrawerBody>
		</>
	);
}
