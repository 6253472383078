import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const StyledContainer = styled(Box, {
	position: 'relative',

	'& > svg': {
		position: 'absolute',
		top: 4,
		right: 4,
		height: 10,
		width: 10,
	},

	'& > svg > path': {
		fill: '$negative',
	},

	variants: {
		valid: {
			true: {
				'& > svg > path': {
					fill: '$positive',
				},
			},
		},
	},
});