import {
	Class,
	Group,
	User,
	Student,
	useClasses,
	useStudents,
	useTeachers,
	useGroups,
} from '@esgi/main/libs/admin-store';
import {Entity, StudentRowModel} from '@esgi/main/kits/admin';
import {useMemo} from 'react';
import {ColDef} from 'ag-grid-community';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {getFullName} from '@esgi/main/kits/common';
import {StudentsList} from '@esgi/ui/icons';
import {EntitiesFilter} from '@esgi/ui/ag-grid';

type RowModel = StudentRowModel & {
	primaryTeacher: User | null;
	classes: Class[];
	groups: Group[];
};

type Parameters = {
	primaryTeacherSchoolID: number | null;
	primaryTeacherID: User['id'] | null;
	classID: Class['id'] | null;
	activeStudents: Student[];
};

export function useAddStudentAlertData({
	primaryTeacherSchoolID,
	primaryTeacherID,
	classID,
	activeStudents,
}: Parameters) {
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();
	const [{data: classes, loaded: isClassesLoaded}] = useClasses();
	const [{data: teachers, loaded: isTeachersLoaded}] = useTeachers();
	const [{data: groups, loaded: isGroupsLoaded}] = useGroups();

	const colDefs = useMemo<ColDef<RowModel>[]>(
		() => [
			{
				field: 'primaryTeacher',
				width: 160,
				sortable: true,
				headerName: 'Primary Teacher',
				filter: false,
				resizable: false,
				cellDataType: 'text',
			},
			{
				field: 'classes',
				width: 160,
				sortable: true,
				filter: EntitiesFilter,
				filterParams: {
					entities: classes.filter(({teacherID}) => teacherID === primaryTeacherID),
				},
				headerName: 'Class',
				resizable: false,
				cellDataType: 'text',
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return '-';
					}

					const classes = data.classes;

					return classes.length ? classes.map(({name}) => name).join(', ') : '-';
				},
			},
			{
				field: 'groups',
				width: 160,
				sortable: true,
				filter: EntitiesFilter,
				filterParams: {
					entities: groups.filter(({teacherID}) => teacherID === primaryTeacherID),
				},
				headerName: 'Groups',
				resizable: false,
				cellDataType: 'text',
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return '-';
					}

					const groups = data.groups;

					return groups.length ? groups.map(({name}) => name).join(', ') : '-';
				},
			},
		],
		[classes, groups, primaryTeacherID],
	);

	const selectedItemsIDs = useMemo(() => activeStudents.map(({id}) => id), [activeStudents]);

	const {rowsData, disabledRowsAsSelectedIDs} = useMemo(() => {
		const disabledRowsAsSelectedIDs: RowModel['id'][] = [];

		const rowsData = students
			.filter(
				({teacherID, schoolID}) =>
					teacherID === primaryTeacherID || (schoolID === primaryTeacherSchoolID && isNull(teacherID)),
			)
			.map<RowModel>(({firstName, lastName, classesIDs, groupsIDs, teacherID, id}) => {
				const filteredClasses = classes.filter(({id}) => classesIDs.includes(id));

				if (filteredClasses.some(({id}) => id === classID) && selectedItemsIDs.includes(id)) {
					disabledRowsAsSelectedIDs.push(id);
				}

				return {
					id,
					firstName,
					lastName,
					classes: filteredClasses,
					groups: groups.filter(({id}) => groupsIDs.includes(id)),
					primaryTeacher: teachers.find(({id}) => id === teacherID) ?? null,
				};
			});

		return {
			disabledRowsAsSelectedIDs,
			rowsData,
		};
	}, [classID, classes, groups, primaryTeacherID, primaryTeacherSchoolID, selectedItemsIDs, students, teachers]);

	const entity = useMemo<Entity | null>(() => {
		const storageClass = classes.find(({id}) => id === classID);

		if (isUndefined(storageClass)) {
			return null;
		}

		return {
			name: storageClass.name,
			Icon: StudentsList,
		};
	}, [classID, classes]);

	return {
		colDefs,
		rowsData,
		entity,
		disabledRowsAsSelectedIDs,
		selectedItemsIDs,
		skeleton: !isStudentsLoaded || !isClassesLoaded || !isTeachersLoaded || !isGroupsLoaded,
	};
}
