import {useEffect, useMemo, useState} from 'react';
import {GradeScale} from '@esgi/ui';
import {SelectableList} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {TestContentArea} from '@esgi/main/kits/common';
import {TestStatusIndicator} from '../test-status-indicator';
import {TestStatus, ReportTest, TestSession} from '../../../../types';
import {ItemCustomizationPanel} from '../item-customization-panel';
import {
	SelectableListTriggerLabel,
	TestColorIndicator,
	SelectableListTriggerContent,
	SelectableListRoot,
	SelectableListTrigger,
	CounterText,
	CounterBox,
	IconBox,
	CustomTrigger,
	ItemTagAndTitle,
	TestCheckbox,
	CheckboxWrapper,
	TriggerWrapper,
	Options,
} from './index.styled';
import {useUser} from '@esgi/core/authentication';
import {TestTypeLabel} from '../../../../../test-type-label';
import {TestType} from '@esgi/main/libs/core';
import {Test} from '../../../../../../../../../features/teacher/student-progress-report/src/kit/tests-control/types';

interface Props {
	id: number;
	name: string;
	type: TestType;
	sessions: TestSession[];
	selectedSessionData: TestSession
	contentArea: TestContentArea;
	isChecked: boolean;
	isListOpen: boolean;
	isDisabled: boolean;
	maxScore?: number;
	setSelected: (itemId: ReportTest['id']) => void;
	onChangeTestsData?: (testID: number, session: TestSession) => void;
}

function TestResult (props: {session: TestSession, type: TestType, maxScore?: number}) {
	if (props.type === TestType.YN) {
		return <CounterBox>
			<CounterText size='small' font='mono' bold type='correct' zero={!props.session.correct}>{props.session.correct}</CounterText>
			<CounterText size='small' font='mono' bold type='incorrect' zero={!props.session.incorrect}>{props.session.incorrect}</CounterText>
			<CounterText size='small' font='mono' bold type='untested' zero={!props.session.untested}>{props.session.untested}</CounterText>
		</CounterBox>;
	}
	if (props.type === TestType.Score || props.type === TestType.Rubric) {
		return <CounterBox>
			<CounterText size='small' font='mono' bold type='untested'>{props.session.correct}</CounterText>
			<CounterText size='small' font='mono' bold type='untested'>/</CounterText>
			<CounterText size='small' font='mono' bold type='untested'>{props.maxScore}</CounterText>
		</CounterBox>;
	}
	return null;
}

export function SelectableLisItemContent(props: Props) {

	const {
		id,
		name,
		type,
		sessions,
		isDisabled,
		isChecked,
		isListOpen,
		maxScore,
		setSelected,
		onChangeTestsData,
		selectedSessionData,
	} = props;

	const currentUser = useUser();

	const [isItemOpen, setItemOpen] = useState(false);
	const [isTestTouched, setTestTouched] = useState<boolean>(false);

	const getTestStatus = useMemo(() => {
		if (isTestTouched) {
			return TestStatus.Saved;
		}

		if ((isListOpen || isItemOpen) && isChecked) {
			return TestStatus.InProgress;
		}

		if (isChecked) {
			return TestStatus.Active;
		}

		return TestStatus.Default;

	}, [isTestTouched, isChecked, isItemOpen, isListOpen]);

	const handelChangeSelectedSession = (session: TestSession) => {
		setTestTouched(true);
		setItemOpen(false);
		onChangeTestsData(id, session);
	};

	useEffect(() => {
		setItemOpen(isListOpen && isChecked);
	}, [isListOpen, isChecked]);

	return <SelectableListRoot isListOpen={isItemOpen} onListOpenChange={setItemOpen}>
		<SelectableList.GroupRoot type='multiple'>

			<CustomTrigger isChecked={isChecked}>
				<ItemTagAndTitle>
					<CheckboxWrapper onClick={(e) => e.stopPropagation()}>
						<TestCheckbox checked={isChecked} onCheckedChange={() => setSelected(id)} disabled={isDisabled}/>
					</CheckboxWrapper>

					<SelectableListTriggerContent>
						<TestColorIndicator testID={id}/>
						<SelectableListTriggerLabel disabled={isDisabled} size='large' data-trigger-label>{name}</SelectableListTriggerLabel>
						<TestTypeLabel testType={type} css={{marginRight: 12}} />
					</SelectableListTriggerContent>
				</ItemTagAndTitle>

				<Options>
					<IconBox>
						{isDisabled
							? <Text size='small' color='muted'>No Results</Text>
							: <TestResult session={selectedSessionData} type={type} maxScore={maxScore} />
						}
					</IconBox>

					<SelectableList.StyledButton disabled={isDisabled}>
						<GradeScale width={24} height={24}/>
					</SelectableList.StyledButton>

					<TriggerWrapper>
						<SelectableListTrigger onClick={() => setItemOpen(prev => !prev)} isListOpen={isItemOpen}>
							<TestStatusIndicator status={getTestStatus}/>
						</SelectableListTrigger>
					</TriggerWrapper>
				</Options>
			</CustomTrigger>
			<SelectableList.Group>
				{!isDisabled && selectedSessionData?.sessionID && <ItemCustomizationPanel
					selectedSession={selectedSessionData}
					sessions={sessions}
					onChangeSession={handelChangeSelectedSession}
					testResultsIncorrectVerbiage={currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect'}
				/>}
			</SelectableList.Group>
		</SelectableList.GroupRoot>
	</SelectableListRoot>;
}

