import {useMemo} from 'react';
import {ColDef, ValueFormatterParams, ColGroupDef} from 'ag-grid-community';
import {AgGridReactProps} from 'ag-grid-react';
import {formatValue, isColDef} from '../../../utils';
import {HeaderCell} from '../../header-cell';
import {TableCell} from '../../table-cell';
import {isUndefined} from '@esgi/ui/utils';
import {selectableColDef} from '../../selection-column';
import {settingUpPanelColDef} from '../../setting-up-column';
import {useThemeContext} from '../../../contexts/theme';

type Parameters = {
	withoutCustomizationColumn?: boolean;
	passedColumnDefs: AgGridReactProps['columnDefs'] | undefined;
	rowSelection: AgGridReactProps['rowSelection'] | undefined;
};

export function useColDefs({passedColumnDefs, rowSelection, withoutCustomizationColumn}: Parameters) {
	const {withoutFirstColumnPadding, withoutLastColumnPadding, cellHorizontalPadding} = useThemeContext();

	return useMemo<(ColDef | ColGroupDef)[]>(() => {
		const updatedCols = (passedColumnDefs ?? []).map<ColDef | ColGroupDef>((item) => {
			if (!isColDef(item)) {
				return {...item};
			}

			return {
				headerComponent: HeaderCell,
				cellRenderer: TableCell,
				valueFormatter: (params: ValueFormatterParams) => {
					const {value} = params;
					const possibleName = formatValue(value);
					if (!possibleName) {
						return '-';
					}

					return possibleName;
				},
				...item,
			};
		});

		if (!isUndefined(rowSelection)) {
			updatedCols.unshift(
				selectableColDef({
					cellHorizontalPadding,
					withoutFirstColumnPadding,
					withoutLastColumnPadding,
					rowSelection,
				}),
			);
		}

		if (!withoutCustomizationColumn) {
			updatedCols.push(
				settingUpPanelColDef({cellHorizontalPadding, withoutFirstColumnPadding, withoutLastColumnPadding}),
			);
		}

		return updatedCols;
	}, [
		cellHorizontalPadding,
		passedColumnDefs,
		rowSelection,
		withoutCustomizationColumn,
		withoutFirstColumnPadding,
		withoutLastColumnPadding,
	]);
}
