import {Button} from '@esgi/ui';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ContentBox = styled(GridBox, {
	gap: 20,
});

export const AddStudentsBox = styled(GridBox, {
	variants: {
		withClearAllButton: {
			true: {
				gap: '$4',
				alignItems: 'center',
				gridTemplateColumns: '1fr auto',
				paddingRight: 6,
			},
		},
	},
});

export const AddStudentButton = styled(Button, {
	paddingTop: 9,
	paddingBottom: 9,
});

export const ErrorBox = styled(FlexBox, {
	paddingTop: 4,
	paddingBottom: 4,
	paddingInline: 8,
	borderRadius: 3,
	backgroundColor: '$negativeBackground',
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$negativeMuted',
	justifyContent: 'center',
});

export const StudentsListBox = styled(FlexBox, {
	gap: '$1',
	flexDirection: 'column',
});

export const StudentItem = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	gap: '$4',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingTop: 6,
	paddingBottom: 6,
	paddingLeft: 12,
	paddingRight: 6,
});
