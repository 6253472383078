import {Alert} from '@esgi/ui/alert';
import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const AlertFooter = styled(Alert.Footer, {
	variants: {
		rewriteProperties: {
			true: {
				paddingTop: 'unset',
				justifyContent: 'space-between',
			},
		},
	},

	defaultVariants: {
		rewriteProperties: true,
	},
});

export const SelectedStudentsBox = styled(FlexBox, {
	gap: '$4',
	alignItems: 'center',
});
