import {DownloadVariantItem} from './types';

export const downloadVariantItems: DownloadVariantItem[] = [
	{
		extension: 'pdf',
		label: 'One PDF File',
	},
	{
		extension: 'zip',
		label: 'Zip',
	},
];
