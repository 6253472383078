import {Avatar} from '@esgi/ui/avatar';
import {UploadSecondaryIcon} from './index.styled';
import {Upload} from '@esgi/ui/icons';
import {useCallback, useRef, useState} from 'react';
import {CroppedImage} from '@esgi/ui';
import {CropImageAlert} from '../components/crop-image-alert';
import {isNull} from 'underscore';
import {FileInputHidden} from '../components/file-input-hidden';

type Props = {
	setLogo: (value: CroppedImage) => void;
};

export function NoLogo({setLogo}: Props) {
	const [openCropImageAlert, setOpenCropImageAlert] = useState(false);
	const [uploadedImage, setUploadedImage] = useState<string | null>(null);

	const inputFileRef = useRef<HTMLInputElement>(null);

	const openUploadImageFile = useCallback(() => {
		inputFileRef.current?.click();
	}, []);

	const onCloseAlert = useCallback(() => {
		setUploadedImage(null);
		setOpenCropImageAlert(false);
	}, []);

	const onSetUploadedImage = useCallback((image: string) => {
		setUploadedImage(image);
		setOpenCropImageAlert(true);
	}, []);

	return (
		<>
			<Avatar.Root size='xl' borderType='dashed' css={{borderColor: '$mild'}}>
				<Avatar.Fallback>
					<Upload width={24} height={24} />
				</Avatar.Fallback>

				<Avatar.HoverLayer onClick={openUploadImageFile}>
					<UploadSecondaryIcon width={24} height={24} />
				</Avatar.HoverLayer>
			</Avatar.Root>

			<FileInputHidden inputFileRef={inputFileRef} setUploadedImage={onSetUploadedImage} />

			{openCropImageAlert && !isNull(uploadedImage) && (
				<CropImageAlert image={{image: uploadedImage}} onCloseAlert={onCloseAlert} onSaveImage={setLogo} />
			)}
		</>
	);
}
