import {SubjectPublishType, SubjectPublishValue} from '../../types';
import {TabID} from '../types';

export function getInitialTabID(initialValue: SubjectPublishValue) {
	if (initialValue.type === SubjectPublishType.Indefinitely) {
		return TabID.Indefinitely;
	}

	if (initialValue.type === SubjectPublishType.SchoolYear || initialValue.type === SubjectPublishType.MarkingPeriod) {
		return TabID.Period;
	}

	if (initialValue.type === SubjectPublishType.CustomDateRange) {
		return TabID.CustomDateRange;
	}

	return TabID.None;
}
