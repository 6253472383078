export const stringArrayToNumberArray = (stringArray?: string[]): number[] => {
	if (!stringArray?.length) {
		return [];
	}

	return stringArray.map(item => Number(item));
};


export const extractBase64String = (base64String?: string): string | null => {
	if (!base64String) {
		return null;
	}

	const pattern = /base64,(.*)$/;
	const matches = base64String.match(pattern);

	return matches ? matches[1] : base64String;
};

export const toISOStringWithoutTimeZone = (date?: Date) => {
	if (!date) {
		return null;
	}

	function pad(num: number) {
		if (num < 10) {
			return '0' + num;
		}

		return num;
	}

	return date.getFullYear() +
		'-' + pad(date.getMonth() + 1) +
		'-' + pad(date.getDate()) +
		'T' + pad(date.getHours()) +
		':' + pad(date.getMinutes()) +
		':' + pad(date.getSeconds()) +
		'.' + (date.getMilliseconds() / 1000).toFixed(3).slice(2, 5);
};
