import {ErrorTooltipItem} from '../../../../components/error-tooltip';

export const teacherSelectErrorItems: ErrorTooltipItem<string>[] = [
	{
		showOnError: 'required',
		message: 'Teacher is required',
	},
];

export const teacherClassesSelectErrorItems: ErrorTooltipItem<string>[] = [
	{
		showOnError: 'required',
		message: 'Class is required',
	},
];
