import {ViewMode} from './view-mode';
import {WidgetSortBy} from '../types';
import {Dispatch, SetStateAction, useLayoutEffect} from 'react';
import {
	DemographicPerformanceWidgetOptions,
	DemographicPerformanceWidgetUpdatedValue,
} from '../../../types/demographic-performance';
import {EditMode} from './edit-mode';
import {Widget} from '../../../types/widget';
import {
	DeployedSubject,
	GradeLevel,
	SchoolModel,
	SortOption,
	WidgetCard,
	WidgetMode,
} from '@esgi/main/features/admins/dashboard';

type Props = {
	allAvgValue: number;
	widgetOptions: DemographicPerformanceWidgetOptions;
	subjects: DeployedSubject[];
	allSchoolsList: SchoolModel[];
	sortableItems: SortOption<WidgetSortBy>[];
	widgetSortBy: WidgetSortBy;
	setWidgetSortBy: Dispatch<SetStateAction<WidgetSortBy>>;
	onDeleteWidgetByID: Dispatch<Widget['id']>;
	onUpdateWidget: Dispatch<DemographicPerformanceWidgetUpdatedValue>;
	gradeLevels: GradeLevel[];
};

export function Content({
	allAvgValue,
	widgetOptions,
	allSchoolsList,
	subjects,
	widgetSortBy,
	setWidgetSortBy,
	sortableItems,
	onDeleteWidgetByID,
	onUpdateWidget,
	gradeLevels,
}: Props) {
	const {pageChange, widgetDataPageSize, widgetMode} = WidgetCard.useWidgetCardContext();

	useLayoutEffect(() => {
		pageChange(0);
	}, [widgetSortBy, widgetDataPageSize]);

	if (widgetMode === WidgetMode.Edit) {
		return (
			<EditMode
				allSchoolsList={allSchoolsList}
				onDeleteWidgetByID={onDeleteWidgetByID}
				subjects={subjects}
				widgetOptions={widgetOptions}
				onUpdateWidget={onUpdateWidget}
				gradeLevels={gradeLevels}
			/>
		);
	}

	return (
		<WidgetCard.ViewMode<WidgetSortBy>
			sortableItems={sortableItems}
			setWidgetSortBy={setWidgetSortBy}
			widgetSortBy={widgetSortBy}
		>
			<ViewMode
				viewType={widgetOptions.viewType}
				averageValue={allAvgValue}
				showAllItemsAverage={widgetOptions.showAvg}
				candles={widgetOptions.candles}
				periods={widgetOptions.periods}
				compareTwoSchools={widgetOptions.schools}
				allSchoolsList={allSchoolsList}
				subjects={subjects}
				contentAreaIDs={widgetOptions.contentAreaIDs}
				gradeLevelIDs={widgetOptions.gradeLevelIDs}
				schoolIDs={widgetOptions.schoolIDs}
				subjectIDs={widgetOptions.subjectIDs}
				gradeLevels={gradeLevels}
			/>
		</WidgetCard.ViewMode>
	);
}
