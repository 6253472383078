import {Requiered} from '@esgi/ui/icons';
import {IndicatorType, Input} from '@esgi/ui/controls';
import {ChangeEvent, Dispatch, useCallback} from 'react';
import {RequiredInputBox} from './index.styled';
import {usePanelContext} from '../../context';

type Props = {
	value: string;
	setValue: Dispatch<string>;
	placeholder: string;
	status: IndicatorType;
	errorMessage?: string | undefined;
	required?: boolean;
	disabled?: boolean;
};

export function InputCleanableIndicator({
	value,
	setValue,
	placeholder,
	status,
	errorMessage,
	required,
	disabled,
}: Props) {
	const {skeleton} = usePanelContext();

	const onChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;

			setValue(value);
		},
		[setValue],
	);

	const onClearClick = useCallback(() => {
		setValue('');
	}, [setValue]);

	return (
		<RequiredInputBox>
			<Input.CleanableIndicator
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				onClearClick={onClearClick}
				status={status}
				error={errorMessage ?? undefined}
				skeleton={skeleton}
				disabled={disabled}
			/>

			{!skeleton && !value && status === IndicatorType.None && required && <Requiered />}
		</RequiredInputBox>
	);
}
