import {useCallback, useEffect, useId} from 'react';
import {ExternalFilter} from '../types';
import {GridApi} from 'ag-grid-community';
import {AgGridContextValue} from '../contexts/ag-grid-table';

export function useRegisterExternalFilter({gridApi}: {gridApi: GridApi | null}) {
	const filterID = useId();

	useEffect(() => {
		const context = (gridApi?.getGridOption('context') ?? null) as AgGridContextValue | null;

		context?.setExternalFilter({
			id: filterID,
			isExternalFilterPresent: () => false,
			doesExternalFilterPass: () => true,
		});

		return () => {
			context?.removeExternalFilter(filterID);
		};
	}, [gridApi]);

	return useCallback(
		(filter: ExternalFilter) => {
			const context = (gridApi?.getGridOption('context') ?? null) as AgGridContextValue | null;

			context?.updateExternalFilter(filterID, {...filter});

			gridApi?.onFilterChanged();
		},
		[filterID, gridApi],
	);
}
