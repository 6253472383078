import {useCallback, useMemo} from 'react';
import {useSubjectsByUserID} from './hooks/use-subjects-by-user-id';
import {useSubjectsFromStore} from './hooks/use-subjects-from-store';
import {MappedSubjectsData} from '../../types/subjects-panel';
import {SubjectModel, Entities} from '@esgi/main/kits/subject-selection-panel';
import {useSubjectsByDistrictID} from './hooks/use-subjects-by-district-id';

type Parameters = {
	teacherID: number | null;
};

export function useSubjects({teacherID}: Parameters) {
	const [isSubjectsFromStoreReady, subjectsFromStore] = useSubjectsFromStore();
	const [isSubjectsByUserIDReady, subjectsByUserID] = useSubjectsByUserID({teacherID});
	const [isDistrictSubjectsByIDReady, districtSubjectsByID] = useSubjectsByDistrictID();

	const {firstSelectedSubjectID, subjectsEntities, hasSubjects, isSubjectsLoaded} = useMemo<MappedSubjectsData>(() => {
		const entities: Entities = {
			districtSubjects: districtSubjectsByID,
			schoolSubjects: subjectsFromStore,
			teacherSubjects: subjectsByUserID,
		};

		const firstSelectedSubjectID: SubjectModel['id'] | null =
			entities.districtSubjects[0]?.id ?? entities.schoolSubjects[0]?.id ?? entities.teacherSubjects[0]?.id ?? null;

		return {
			firstSelectedSubjectID,
			subjectsEntities: entities,
			hasSubjects: Object.values(entities).some((entity) => entity.length),
			isSubjectsLoaded: isSubjectsFromStoreReady && isSubjectsByUserIDReady && isDistrictSubjectsByIDReady,
		};
	}, [
		districtSubjectsByID,
		isDistrictSubjectsByIDReady,
		isSubjectsByUserIDReady,
		isSubjectsFromStoreReady,
		subjectsByUserID,
		subjectsFromStore,
	]);

	const getSubjectByID = useCallback(
		(id: SubjectModel['id']) =>
			[
				...subjectsEntities.districtSubjects,
				...subjectsEntities.schoolSubjects,
				...subjectsEntities.teacherSubjects,
			].find((subject) => subject.id === id) ?? null,
		[subjectsEntities],
	);

	return {
		isSubjectsLoaded,
		subjectsEntities,
		firstSelectedSubjectID,
		hasSubjects,
		getSubjectByID,
	};
}
