import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {panelsPaddingBottom} from '../constants';

const sectionPaddingInline = 20;

export const PanelContainer = styled(GridBox, {
	height: '100%',
	gap: 20,
	gridTemplateRows: '30px auto 1fr',
	paddingTop: 18,
	paddingBottom: panelsPaddingBottom,
	paddingInline: sectionPaddingInline,
	overflow: 'hidden',
});

export const PanelDivider = styled(Box, {
	width: `calc(100% + ${2 * sectionPaddingInline}px)`,
	height: 1,
	marginLeft: -sectionPaddingInline,
	backgroundColor: '$border',
});
