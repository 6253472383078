import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const IconBox = styled(FlexBox, {
	width: 24,
	height: 24,
	alignItems: 'center',
	justifyContent: 'center',

	'& > svg': {
		width: '100%',
		height: '100%',
	},
});
