import {useCallback, useEffect, useRef, useState} from 'react';
import {CreateTrackRequest, SchoolYearModel, TrackModel, UpdateTrackRequestParams} from '../../../../../../../types';
import {TrackList} from '../components/track-list';
import {EntireSidebar} from '../components/entire-sidebar';
import {Mode} from './types';
import {EditMode, EditModeRef} from './components/edit-mode';
import {AddMargingPeriodButton} from './components/add-marging-period-button';
import {AddMode} from './components/add-mode';
import {ActiveAddItem} from './components/active-add-item';
import {OnTrackClick} from '../../types';
import {Observable} from 'rxjs';

type Props = {
	tracks: TrackModel[];
	visibleTrack: TrackModel;
	activeUserTrackId: TrackModel['trackID'];
	onTrackClick: OnTrackClick;
	schoolYearTypes: SchoolYearModel[] | null;
	onOpenNoChangesConfirmation: (onCloseAlert: VoidFunction) => void;
	updateTrackRequest: (args: UpdateTrackRequestParams) => Observable<void>;
	createTrackRequest: CreateTrackRequest;
	setVisibleTrack: (track: TrackModel) => void;
	setActiveTrackId: (trackId: TrackModel['trackID']) => void;
};

export function AddEditMode({
	onTrackClick,
	visibleTrack,
	activeUserTrackId,
	tracks,
	schoolYearTypes,
	onOpenNoChangesConfirmation,
	updateTrackRequest,
	createTrackRequest,
	setVisibleTrack,
	setActiveTrackId,
}: Props) {
	const [currentMode, setCurrentMode] = useState(Mode.Edit);

	const editModeRef = useRef<EditModeRef>();

	useEffect(() => {
		if (currentMode !== Mode.Add) {
			return;
		}

		setCurrentMode(Mode.Edit);
	}, [visibleTrack]);

	const handleTrackClick = useCallback((track: TrackModel) => {
		onTrackClick({
			track,
			openNoChangesConfirmation:
				currentMode === Mode.Add
				|| Boolean(currentMode === Mode.Edit && editModeRef.current?.isMarkingPeriodTouched),
		});
	}, [currentMode, onTrackClick]);

	const handleCloseAddMode = useCallback(() => {
		onOpenNoChangesConfirmation(() => setCurrentMode(Mode.Edit));
	}, [onOpenNoChangesConfirmation]);

	return (
		<>
			<EntireSidebar>
				{currentMode === Mode.Edit && <AddMargingPeriodButton onClick={() => setCurrentMode(Mode.Add)} />}
				{currentMode === Mode.Add && <ActiveAddItem onCloseIconClick={handleCloseAddMode} />}
				<TrackList
					onTrackClick={handleTrackClick}
					tracks={tracks}
					visibleTrackId={currentMode === Mode.Edit ? visibleTrack.trackID : -1}
					activeUserTrackId={activeUserTrackId}
				/>
			</EntireSidebar>
			{currentMode === Mode.Edit && (
				<EditMode
					editableTrack={visibleTrack}
					schoolYearTypes={schoolYearTypes}
					editModeRef={editModeRef}
					activeUserTrackId={activeUserTrackId}
					setActiveTrackId={setActiveTrackId}
					updateTrackRequest={updateTrackRequest}
				/>
			)}
			{currentMode === Mode.Add && (
				<AddMode
					schoolYearTypes={schoolYearTypes}
					createTrackRequest={createTrackRequest}
					setActiveTrackId={setActiveTrackId}
					setVisibleTrack={setVisibleTrack}
				/>
			)}
		</>
	);
}
