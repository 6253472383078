import {NavigationBarOrientation} from '@esgi/ui/layout';
import {ThemeProvider} from '@esgi/ui/theme';
import {useMatchMedia} from '@esgi/ui/utils';
import {NavigationPanelBaseProps} from './types';
import {VerticalPanel} from './components/variants/vertical-panel';
import {HorizontalPanel} from './components/variants/horizontal-panel';

export function NavigationPanel(props: NavigationPanelBaseProps) {
	const isPortrait = useMatchMedia('portrait-tablet');
	const isLandscape = useMatchMedia('landscape-tablet');
	const orientation =
		isLandscape || isPortrait ? NavigationBarOrientation.Horizontal : NavigationBarOrientation.Vertical;

	return (
		<ThemeProvider>
			{orientation === NavigationBarOrientation.Horizontal && <HorizontalPanel {...props} />}
			{orientation === NavigationBarOrientation.Vertical && <VerticalPanel {...props} />}
		</ThemeProvider>
	);
}
