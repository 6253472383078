import {AmlifyManager} from '@esgi/aws';
import {useUser} from '@esgi/core/authentication';
import {useEffect, useState} from 'react';
import {lastValueFrom, map, Observable} from 'rxjs';
import {isNull} from 'underscore';
import {AppSyncAuthenticateResponse} from './types';
import {V2DistrictAdminsAppSyncAuthenticateController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';

const storageKey = 'da_appsync_token';
const userType = 'district-admin';

const getTokenGenerator = (authenticateRequest: Observable<AppSyncAuthenticateResponse>) => async (force?: boolean) => {
	const storageToken = localStorage.getItem(storageKey);

	if (force || !storageToken) {
		const {token} = await lastValueFrom(authenticateRequest);

		localStorage.setItem(storageKey, token);

		return token;
	}

	return storageToken;
};

export function useInitAppSync() {
	const currentUser = useUser();
	const appSyncAuthenticateController = useDisposable(V2DistrictAdminsAppSyncAuthenticateController);

	const [isAppSyncInitialized, setIsAppSyncInitialized] = useState(false);

	useEffect(() => {
		if (isNull(currentUser)) {
			throw new Error('User is null');
		}

		const authenticateRequest = appSyncAuthenticateController.authenticate();
		const tokenGenerator = getTokenGenerator(authenticateRequest);

		const subscription = authenticateRequest
			.pipe(
				map(({token, graphQLUrl}) => {
					localStorage.setItem(storageKey, token);

					return {
						tokenGenerator,
						graphQLUrl,
						identityGenerator: () => ({
							userID: currentUser.userID,
							userType,
						}),
					};
				}),
			)
			.subscribe((config) => {
				AmlifyManager.init(config);
				setIsAppSyncInitialized(true);
			});

		return () => {
			subscription.unsubscribe();
			localStorage.removeItem(storageKey);
		};
	}, []);

	return {
		isAppSyncInitialized,
	};
}
