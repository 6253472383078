import {AddTestsDrawerTestModel} from '@esgi/main/features/admins/subject-management';
import {Subject, subjectsStore, TestContentArea, TestScreenType} from '@esgi/main/libs/admin-store';
import {useCallback} from 'react';
import {addTestsToStorageTestType} from '../../mapped-enums';

type Parameters = {
	subjectFromStore: Subject;
};

export function useAddTests({subjectFromStore}: Parameters) {
	return useCallback(
		(tests: AddTestsDrawerTestModel[]) => {
			subjectsStore().update?.((item: Subject) => {
				if (item.id === subjectFromStore.id) {
					const mappedNewTests = tests.map<Subject['tests'][0]>(
						({id, name, color, contentArea, authorID, isWhiteBackground, testScreenTypes, testType}) => ({
							id,
							name,
							color,
							contentArea: contentArea as TestContentArea,
							creatorID: authorID,
							isWhiteBackground,
							maxScore: 0,
							testScreenTypes: testScreenTypes as unknown as TestScreenType[],
							type: addTestsToStorageTestType[testType],
						}),
					);

					return {
						...item,
						tests: [...item.tests, ...mappedNewTests],
					};
				}

				return item;
			});
		},
		[subjectFromStore],
	);
}
