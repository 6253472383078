import {CounterBox as CounterBoxBase} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

import {useActionPanelContext} from '../context';

export function CounterBox() {
	const {selectedItemsCount} = useActionPanelContext();

	return (
		<CounterBoxBase color='blue'>
			<Text size='small' font='mono' color='primaryVivid'>
				{selectedItemsCount}
			</Text>
		</CounterBoxBase>
	);
}
