import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {ToggleGroupContent, ToggleGroupTitle} from './index.styled';
import {useTabsContext} from '../../context';
import {ComponentPropsWithoutRef, ForwardedRef, forwardRef, useCallback} from 'react';

type Props = Omit<ComponentPropsWithoutRef<typeof ToggleGroup.Root>, 'onValueChange' | 'value' | 'orientation'>;

function TabsBarInner<Tab extends string>(
	{dataCy = 'tabs-bar', css = {}, ...toggleGroupProps}: Props,
	ref: ForwardedRef<HTMLDivElement>,
) {
	const {activeTab, setActiveTab, availableTabs, skeleton} = useTabsContext<Tab>();

	const onValueChange = useCallback(
		(newTab: Tab) => {
			setActiveTab(newTab);
		},
		[setActiveTab],
	);

	return (
		<ToggleGroup.Root
			dataCy={dataCy}
			css={css}
			value={activeTab ?? undefined}
			ref={ref}
			onValueChange={onValueChange}
			orientation='horizontal'
			{...toggleGroupProps}
		>
			{!availableTabs.length ? null : (
				<>
					<ToggleGroupContent dataCy={`${dataCy}-content`} skeleton={skeleton}>
						{availableTabs.map(({tabID, tabContent, description, applyDisabledStyles = false, disabled}) => (
							<ToggleGroup.Item
								value={tabID}
								valueDescription={description}
								disabled={activeTab === tabID || disabled}
								applyDisabledStyles={applyDisabledStyles}
								key={tabID}
							>
								{typeof tabContent === 'string' ? (
									<Text size='medium' bold>
										{tabContent}
									</Text>
								) : (
									tabContent
								)}
							</ToggleGroup.Item>
						))}
					</ToggleGroupContent>
					<ToggleGroupTitle dataCy={`${dataCy}-title`} skeleton={skeleton}>
						{({description}) => (
							<Text size='small' font='mono' data-cy={`${dataCy}-description`}>
								{!skeleton && description}
							</Text>
						)}
					</ToggleGroupTitle>
				</>
			)}
		</ToggleGroup.Root>
	);
}

export const TabsBar = forwardRef(TabsBarInner);
