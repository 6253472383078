import {Class, Group, School, SpecialistGroup, User} from '@esgi/main/libs/admin-store';
import {UserType} from '@esgi/core/authentication';
import {StudentRowModel} from '@esgi/main/kits/admin';

export type SchoolsRowDef = {
	id: number;
	name: string;
	teachers: number;
	specialists: number;
	students: number;
	classRatio: number;
};

export type TeachersRowDef = {
	id: number;
	firstName: string;
	lastName: string;
	userName: string;
	email: string;
	school: School;
	classes: number;
	groups: number;
	students: number;
};

export enum SpecialistType {
	District = 'District',
	School = 'School',
	PA = 'Pre-Assess',
}

export type UserTypeOption = {
	id: UserType;
	name: string;
};

export type SpecialistsRowDef = {
	id: number;
	firstName: string;
	lastName: string;
	type: UserTypeOption;
	userName: string;
	email: string;
	schools: School[];
	specialistGroups: number;
	students: number;
};

export type ClassesRowDef = {
	id: number;
	name: string;
	school: School;
	teacher: User;
	groups: Group[];
	students: number;
};

export type GroupsRowDef = {
	id: number;
	name: string;
	school: School;
	teacher: User;
	class: Class;
	students: number;
};

export type SpecialistGroupsRowDef = {
	id: number;
	name: string;
	school: School | null;
	specialist: User;
	type: UserTypeOption;
	students: number;
};

export type StudentsRowDef = StudentRowModel & {
	school: Readonly<School | null>;
	teacher: Readonly<User | null>;
	class: Readonly<Class>[];
	group: Readonly<Group>[];
	specialist: Readonly<User>[];
	specialistGroups: Readonly<SpecialistGroup>[];
	gradeLevelID: number;
};
