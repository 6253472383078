import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {useUser} from '@esgi/core/authentication';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useEffect, useMemo} from 'react';
import {isNull} from 'underscore';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {SchoolSubject} from '@esgi/main/kits/subject-selection-panel';

type Parameters = {
	schoolID: number | null;
	availableGradeLevel: number;
};

type Out = [boolean, SchoolSubject[]];

export function useSubjectsBySchoolID({schoolID, availableGradeLevel}: Parameters): Out {
	const currentUser = useUser();
	const api = useEsgiApiGateway();

	const [subjectsData, fetchSubjects] = useCancelableRequest(api.v2.districtAdmins.modules.subjects.bySchoolId);

	const withFetchedData = !isNull(currentUser) && !isNull(schoolID);

	useEffect(() => {
		if (withFetchedData) {
			fetchSubjects({
				globalSchoolYearID: currentUser.globalSchoolYearID,
				schoolID,
			});
		}
	}, [currentUser, fetchSubjects, schoolID, withFetchedData]);

	return useMemo<Out>(() => {
		if (!withFetchedData) {
			return [true, []];
		}

		const isSubjectsLoaded = isAsyncSucceed(subjectsData);

		if (!isSubjectsLoaded) {
			return [false, []];
		}

		const filteredSubjects = subjectsData.data.value.deployedSubjects.filter(
			({hidden, hasTests, gradeLevels, published}) =>
				published && !hidden && hasTests && gradeLevels.includes(availableGradeLevel),
		);

		const activeSubjects = filteredSubjects.map<SchoolSubject>(({id, name, gradeLevels}) => ({
			id,
			name,
			level: SubjectLevel.School,
			type: SubjectType.Deployed,
			gradeLevels,
			hidden: false,
		}));

		return [true, activeSubjects];
	}, [availableGradeLevel, subjectsData, withFetchedData]);
}
