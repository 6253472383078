import {Select} from '@esgi/ui/controls';
import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const SelectField = styled(Select.Field, {
	position: 'relative',
	cursor: 'pointer',
});

export const SchoolYearLabelBox = styled(FlexBox, {
	position: 'absolute',
	left: 6,
	bottom: -13,
	paddingTop: 2,
	paddingBottom: 2,
	paddingInline: 6,
	borderRadius: 3,
	boxShadow: '0px 2px 6px 0px rgba(230, 232, 239, 0.24), 0px 4px 12px 0px rgba(0, 0, 0, 0.04)',
	borderStyle: 'solid',
	borderWidth: 1,

	variants: {
		variant: {
			primary: {
				borderColor: '$primaryHighlight',
				backgroundColor: '$primaryBackground',
				color: '$primary',
			},

			tertiary: {
				borderColor: '$tertiaryHighlight',
				backgroundColor: '$tertiaryBackground',
				color: '$tertiary',
			},
		},
	},
});
