import {SharedComponent, SharedProps} from '@esgi/deprecated/react';
import {OnHoverTooltip} from '@esgillc/ui-kit/tooltip';
import {SubjectType, TestType, ValidationStatus} from '@esgi/core/enums';
import {
	Checkbox,
	ColorPicker,
	CustomDropdown,
	IFormControlValidatorResult,
	State as ColorPickerState,
	TextInput,
} from '@esgi/deprecated/elements';
import {SuggestedGradeLevel} from 'api/entities/suggested-grade-level';
import {ModalWindow} from '@esgi/deprecated/knockout';
import {StateStandardsLibrary} from 'shared/modules/state-standards-library';
import {ContentArea, StateStandardModel} from 'shared/modules/test-details/models';
import {isIOS} from '@esgillc/ui-kit/utils';
import {
	Description,
	State as DescriptionState,
} from 'shared/modules/test-details/components/test-data/description/component';
import Enumerable from 'linq';
import {CheckBoxDropDown, InModel as CheckBoxDropDownInModel} from '@esgi/deprecated/elements/checkbox-dropdown';
import {ResetRequested, TestSubjectsChanged, TypeChanged} from 'shared/modules/test-details/events';
import './component.less';
import {Api} from 'shared/modules/test-details/api/api';
import {userStorage} from '@esgi/core/authentication';
import {contentAreaDefaultColors, SubjectTabField} from 'modules/assets/tests/kits/test-details';
import {BaseRadio} from '@esgi/deprecated/ui-kit/radio';
import {dispatchAppEvent} from '@esgillc/events';

export class State {
	dirty: boolean = false;

	testIdn: string;
	testIdnCorrect: boolean = true;
	testIdnTouched: boolean = false;
	typeChangePending: boolean = false;

	contentAreaId: number;
	stateStandardId: number;
	stateStandard: string;
	stateStandards: StateStandardModel[] | null;
	stateStandardIDs: number[] | null;
	selectedGradeLevels: number[] = [];

	defaultColor: ColorPickerState = new ColorPickerState();
	color: ColorPickerState = new ColorPickerState();

	validation: IFormControlValidatorResult = {valid: true, message: null};
	description: DescriptionState = new DescriptionState();
}

export class Props extends SharedProps<State> {
	testId: number;
	isNew: boolean;
	isSelfAssess: boolean;
	readOnly: boolean;
	testType: TestType;
	testIdn: string;
	description: string;
	contentAreas: ContentArea[];
	contentAreaId: number;
	stateStandard: string;
	stateStandardId: number;
	stateStandards: StateStandardModel[];
	stateStandardIDs: number[];
	testColor: string;
	selectedGradeLevels: number[];
	draft: boolean;
	shared: boolean;
	canUnshare: boolean;
	shareChangedHandler: (shared: boolean) => void;
	questionsCount: number;
	canAddToSubject: boolean;
	subjectCreated?: (Id: number, name: string, subjectType: SubjectType) => void;
	stateStandardDetached?: () => void;
	canModifyTestID: boolean;
	testName: string;
	totalPossible?: number;
	onStandardAffectingChange: () => void;
	disableNewSubjectBtn?: boolean;
}

export class TestData extends SharedComponent<State, Props> {
	private currentUser = userStorage.get();

	private isIOS: boolean = isIOS();
	private description: Description;
	private ynSelected = (this.props.testType == TestType.YN);

	private testTypeVerbiages: { [key: number]: string } = {
		[TestType[TestType.YN]]: 'Yes/No',
		[TestType[TestType.Score]]: 'Single Score',
	};

	private maxDescriptionLength: number = 140;
	private contentAreas: { [key: number]: string } = {};

	constructor(props?: Props) {
		super(props);

		this.state = new State();
		this.setState({
			testIdn: props.testIdn,
			contentAreaId: props.contentAreaId,
			stateStandardId: props.stateStandardId,
			stateStandard: props.stateStandard,
			stateStandards: props.stateStandards,
			stateStandardIDs: props.stateStandardIDs,
			selectedGradeLevels: props.selectedGradeLevels.sort(),
			color: new ColorPickerState(props.testColor),
			defaultColor: new ColorPickerState(props.testColor),
		});
	}

	public async validate() {
		const valids = [];
		valids.push(await this.description.validate());

		return valids.reduce((a, b) => a && b);
	}

	private dirties: { [key: string]: boolean } = {};

	private setFieldDirty(fieldName: string, dirtyValue: boolean) {
		this.dirties[fieldName] = dirtyValue;

		let componentDirty = dirtyValue;

		if (!componentDirty) {
			for (const key in this.dirties) {
				if (!this.dirties.hasOwnProperty(key)) {
					continue;
				}

				if (this.dirties[key]) {
					componentDirty = true;
					break;
				}
			}
		}

		this.setState({dirty: componentDirty});
	}

	private fieldNames = {
		idn: 'idn',
		stateStandart: 'state',
		gradeScales: 'grade',
		description: 'dscr',
		contentArea: 'contentArea',
		color: 'color',
		shared: 'shared',
	};

	componentDidMount() {
		this.subscribe(ResetRequested, arg => this.onResetRequested(arg));
		if (this.props.contentAreas) {
			this.props.contentAreas.forEach((ca) => {
				this.contentAreas[ca.id] = ca.name;
			});
		}

		if (this.props.testIdn) {
			this.checkIdn(this.props.testIdn.toString(), this.idnValidator());
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.testIdn != prevProps.testIdn) {
			this.setState({
				testIdn: this.props.testIdn || '',
			});
		}
	}

	private checkIdnInterval: any;

	private onResetRequested(arg: ResetRequested) {
		const defDescription = this.state.description;
		defDescription.value = arg.test.description ?? '';
		this.setFieldDirty(this.fieldNames.color, this.props.testColor !== this.state.defaultColor.selectedColor);
		this.setState({
			contentAreaId: arg.test.contentAreaID,
			stateStandardId: arg.test.stateStandardId,
			stateStandard: arg.test.stateStandard,
			selectedGradeLevels: arg.test.gradeLevelIDs.sort(),
			description: defDescription,
			color: this.state.defaultColor,
		}, () => {
			this.setFieldDirty(this.fieldNames.stateStandart, (this.props.contentAreaId !== arg.test.contentAreaID
				|| this.props.stateStandardId !== arg.test.stateStandardId
				|| this.props.stateStandard !== arg.test.stateStandard));

		});
		this.shareTestChanged(arg.shared);
	}

	private checkIdn(idn: string, validator: IFormControlValidatorResult) {
		this.setState({
			testIdn: idn,
		}, () => {
			this.setFieldDirty(this.fieldNames.idn, idn !== this.props.testIdn);
		});

		clearTimeout(this.checkIdnInterval);

		this.checkIdnInterval = setTimeout((scope) => {
			const request = {
				idn: idn,
				testID: scope.props.testId,
			};

			Api.checkTestIdnExist(request).withCustomErrorHandler((response, errorHandlingStrategy) => {
				return;
			}).subscribe((response) => {
				scope.setState({
					testIdnCorrect: !response.exists,
					testIdnTouched: response.exists,
					validation: validator,
				});
				return;
			});
		}, 1000, this);
	}

	get selectedContentArea() {
		return Enumerable.from(this.props.contentAreas)
			.where(ca => ca.id === this.state.contentAreaId)
			.firstOrDefault();
	}

	get disabledTestType() {
		return this.props.testType == TestType.YN && this.props.questionsCount > 1;
	}

	private stateStandardClicked() {
		const stateStandardslibrary = new StateStandardsLibrary({
			selectedContentAreaId: this.state.contentAreaId,
			selectedStateStandardId: this.state.stateStandardId,
			selectedStateStandardText: this.state.stateStandard,
			stateId: this.currentUser.stateID,
		});

		const modal = new ModalWindow(stateStandardslibrary,
			{
				allowClose: true,
				showHeader: true,
				showFooter: true,
				className: 'state-standard-modal',
				title: 'State Standards Library',
				buttons: [
					{
						title: 'Add',
						className: 'btn btn-primary btn-sm',
						submit: true,
						align: 'right',
						onClick: stateStandardslibrary.view.okClicked,
						closeModal: false,

					},
					{
						title: 'Close',
						className: 'btn btn-sm ',
						submit: true,
						align: 'right',
						icon: 'fa fa-times',
						closeModal: true,
						cancel: true,
					},
				],
			});

		stateStandardslibrary.events.onUpdate((event, changes) => {

			this.setState({
				contentAreaId: changes.contentAreaId,
				stateStandardId: changes.stateStandardId,
				stateStandard: changes.stateStandard,
			}, () => {
				this.setFieldDirty(this.fieldNames.stateStandart, (this.props.contentAreaId !== changes.contentAreaId
					|| this.props.stateStandardId !== changes.stateStandardId
					|| this.props.stateStandard !== changes.stateStandard));

			});
			this.updateContentAreaColor(changes.contentAreaId);
		});

		stateStandardslibrary.events.onClosed(() => {
			modal.close();
		});

		modal.load();
	}

	private clearSS() {
		this.setState({
			stateStandardId: null,
			stateStandard: null,
			stateStandards: null,
			stateStandardIDs: null,
		}, () => {
			this.setFieldDirty(this.fieldNames.stateStandart, !!this.props.contentAreaId);
		});
	}

	private onStandardAffectingChange() {
		if (!this.props.stateStandardIDs?.length) {
			return;
		}
		this.props.onStandardAffectingChange();
	}

	private selectGrade = (id: number, value: boolean) => {
		this.clearSS();
		this.props.stateStandardDetached();

		let updSelectedGradeLevels = [...this.state.selectedGradeLevels.sort((a, b) => a - b)];
		if (!value) {
			updSelectedGradeLevels = updSelectedGradeLevels.filter(x => x !== id);
		} else {
			updSelectedGradeLevels.push(id);
		}

		this.setState({
			selectedGradeLevels: updSelectedGradeLevels,
		}, () => {
			this.setFieldDirty(this.fieldNames.gradeScales, JSON.stringify(this.props.selectedGradeLevels.sort((a, b) => a - b)) !== JSON.stringify(updSelectedGradeLevels));
		});
	};

	private descriptionChanged(text: string) {
		const valid = this.state.description.validation.valid;
		if (valid === ValidationStatus.Valid) {
			this.setFieldDirty(this.fieldNames.description, this.props.description?.trim() !== text.trim());
		}
	}

	private contentAreaChanged(contentAreaId: string) {
		const contentAreaIdNumber = parseInt(contentAreaId);
		if(this.state.contentAreaId !== contentAreaIdNumber) {
			this.setState({
				stateStandardId: null,
				stateStandard: null,
				stateStandards: null,
				stateStandardIDs: null,
				contentAreaId: contentAreaIdNumber,
			});
			this.props.stateStandardDetached();
		}

		this.setFieldDirty(this.fieldNames.contentArea, (this.props.contentAreaId != contentAreaIdNumber));
		this.updateContentAreaColor(contentAreaIdNumber);
	}

	private colorChanged(changes: ColorPickerState, callBack: any) {
		this.setFieldDirty(this.fieldNames.color, this.props.testColor !== changes.selectedColor);
		this.setState({color: changes}, callBack);
	}

	private updateContentAreaColor(contentAreaId: number) {
		const contentAreaColor = contentAreaDefaultColors[contentAreaId];

		const newColor = {...this.state.color};
		newColor.selectedColor = contentAreaColor.color;
		newColor.selectedColorTitle = contentAreaColor.title;

		this.setState({color: newColor});
	}

	private shareTestChanged(value: boolean) {
		this.setFieldDirty(this.fieldNames.shared, this.props.shared !== value);
		this.props.shareChangedHandler(value);
	}

	private idnValidator(): IFormControlValidatorResult {
		return {
			valid: this.state.testIdnCorrect,
			message: 'This ID already exists.',
		};
	}

	private subjectCreated = (subjectID: number, name: string, type: SubjectType) => {
		this.props.subjectCreated && this.props.subjectCreated(subjectID, name, type);
	};

	private getGradeLevelsLabel = (defaultLabel: string) => {
		let text = '';
		const sorted = this.state.selectedGradeLevels.sort((a, b) => a - b);
		for (let i = 0; i < sorted.length; i++) {
			text += SuggestedGradeLevel.list.find(gr => gr.id === sorted[i]).shortName;
			if (i !== this.state.selectedGradeLevels.length - 1) {
				text += ', ';
			}
		}
		return text || defaultLabel;
	};

	private getTruncatedText(text: string, length: number) {
		if (text.length > length) {
			return text.substr(0, length) + '...';
		}
		return text;
	}

	private testTypeChanged(testType: TestType) {
		if(this.props.testType !== testType){
			const isYnTest = testType === TestType.YN;
			this.ynSelected = isYnTest;
			const totalPossible = isYnTest ?
				null : this.props.totalPossible;
			this.dispatch(TypeChanged, testType);
			if(this.props.testId > 0){
				this.setState(prev=>({...prev, typeChangePending: true}));
				Api.updateTestType({
					testID: this.props.testId,
					type: testType,
					totalPossible,
				}).subscribe(resp=>{
					this.setState(prev=>({...prev, typeChangePending: false}));
					return resp;
				});
			}
		}

	}

	private onSubjectTabFieldClose(subjectIDs: number[]) {
		dispatchAppEvent(TestSubjectsChanged, new TestSubjectsChanged(subjectIDs));
	}

	get testTypeTitle(): JSX.Element {
		const title = <span>{!this.props.readOnly && this.asterisk}{this.isIOS && ' Type:' || ' Test Type:'}</span>;
		return title;
	}

	get contentAreaTitle() {
		return this.isIOS && 'Content:' || 'Content Area:';
	}

	get gradeLevelTitle() {
		return this.isIOS && 'Grade:' || 'Add/Edit Grade:';
	}

	get stateStandartTitle() {
		return this.isIOS && 'Standard:' || 'State Standard:';
	}

	get subjectTabTitle() {
		return this.isIOS && 'Subject(s):' || 'Subject Tab(s):';
	}

	get descriptionTitle() {
		const title = <span>{!this.props.readOnly && this.asterisk} Description:</span>;
		return title;
	}

	get asterisk() {
		const className = 'asterisk ' + (this.props.isNew && 'red' || '');
		return <span className={className}>*</span>;
	}

	get showStateStandardField() {
		return this.state.stateStandard;
	}

	render() {
		const {typeChangePending} = this.state;
		const gradeLevelsLabel = this.getGradeLevelsLabel('');
		return (
			<div className='test-data'>
				<div className='test-data-block'>
					<div className='test-data-item'>
						<div className='control-label test-type'>
							{this.testTypeTitle}
							<a
								href='https://support.esgisoftware.com/hc/en-us/articles/209160406-Creating-Tests'
								className='test-type-help'
								target='_blank'
								rel='noreferrer'
							>
								<svg
									width='12'
									height='12'
									viewBox='0 0 12 12'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M5.83333 0C2.61333 0 0 2.61333 0 5.83333C0 9.05333 2.61333 11.6667 5.83333 11.6667C9.05333 11.6667 11.6667 9.05333 11.6667 5.83333C11.6667 2.61333 9.05333 0 5.83333 0ZM6.41667 9.91667H5.25V8.75H6.41667V9.91667ZM7.62417 5.39583L7.09917 5.9325C6.67917 6.35833 6.41667 6.70833 6.41667 7.58333H5.25V7.29167C5.25 6.65 5.5125 6.06667 5.9325 5.64083L6.65583 4.90583C6.87167 4.69583 7 4.40417 7 4.08333C7 3.44167 6.475 2.91667 5.83333 2.91667C5.19167 2.91667 4.66667 3.44167 4.66667 4.08333H3.5C3.5 2.79417 4.54417 1.75 5.83333 1.75C7.1225 1.75 8.16667 2.79417 8.16667 4.08333C8.16667 4.59667 7.95667 5.06333 7.62417 5.39583Z'
										fill='#828282'
									/>
								</svg>
							</a>
						</div>
						{this.props.isNew && !this.props.isSelfAssess ? (
							<div className='item-value test-type-radio'>
								<BaseRadio
									value={+(this.props.testType == TestType.YN)}
									checked={this.ynSelected}
									id={'ynRadioId'}
									onClick={() => this.testTypeChanged(TestType.YN)}
									disabled={this.disabledTestType || typeChangePending}
								>
									Yes/No
								</BaseRadio>
								<OnHoverTooltip
									message={
										this.disabledTestType
											? 'Single Score test types only allow for 1 question per test. Please delete questions if you want to select this option.'
											: null
									}
								>
									<div>
										<BaseRadio
											value={+(this.props.testType == TestType.Score)}
											id={'scoreRadioId'}
											checked={!this.ynSelected}
											onClick={() => this.testTypeChanged(TestType.Score)}
											disabled={this.disabledTestType || typeChangePending}
										>
											Single Score
										</BaseRadio>
									</div>
								</OnHoverTooltip>
							</div>
						) : (
							<span className='item-value test-type-text'>
								{this.testTypeVerbiages[
									isNaN(this.props.testType)
										? this.props.testType
										: TestType[this.props.testType]
								]}
							</span>
						)}
					</div>
					<div className='test-data-item description'>
						<span className='control-label'>{this.descriptionTitle}</span>
						{this.props.readOnly ? (
							<div className='item-value'>
								{this.getTruncatedText(
									this.props.description,
									this.maxDescriptionLength,
								)}
							</div>
						) : (
							<Description
								ref={(r) => (this.description = r)}
								state={this.state.description}
								onChange={(ch, cb) => {
									this.setState({description: ch}, cb);
								}}
								onTexAreaChanged={(text: string) =>
									this.descriptionChanged(text)
								}
								readOnly={this.props.readOnly}
								isNew={this.props.isNew}
								description={this.props.description}
							/>
						)}
					</div>
					<div className='test-data-item align-items-center'>
						<span className='control-label'>{this.contentAreaTitle}</span>
						{this.props.readOnly ? (
							<span className='item-value'>
								{this.selectedContentArea
									? this.selectedContentArea.name
									: null}
							</span>
						) : (
							<>
								{this.props.contentAreas.length > 0 && (
									<div className='item-value content-area' onClick={this.onStandardAffectingChange.bind(this)}>
										<CustomDropdown
											items={this.props.contentAreas.map((item) => ({
												key: item.id,
												value: item.id,
												title: item.name,
											}))}
											value={
												this.state.contentAreaId ??
												this.props.contentAreaId ??
												this.props.contentAreas[0].id
											}
											onSelect={(item) => this.contentAreaChanged(item.value)}
										/>
									</div>
								)}
								<ColorPicker
									state={this.state.color}
									onChange={(ch, cb) => this.colorChanged(ch, cb)}
									disabled={this.props.readOnly}
									hideTitle={true}
								/>
							</>
						)}
					</div>

					{(!this.props.readOnly || gradeLevelsLabel) && (
						<div className='test-data-item align-items-center'>
							{(this.props.readOnly && (
								<>
									<span className='control-label'>Grade:</span>
									<div className='item-value'>
										<OnHoverTooltip message={gradeLevelsLabel}>
											<span>{gradeLevelsLabel}</span>
										</OnHoverTooltip>
									</div>
								</>
							)) || (
								<>
									<span className='control-label'>{this.gradeLevelTitle}</span>
									<div className='item-value grade' onClick={this.onStandardAffectingChange.bind(this)}>
										<CheckBoxDropDown
											label={this.getGradeLevelsLabel('Grade Level')}
											values={SuggestedGradeLevel.list.map(
												(gl) => new CheckBoxDropDownInModel(gl.id, gl.name),
											)}
											onSelectGrade={this.selectGrade}
											selectedValues={this.state.selectedGradeLevels}
										/>
									</div>
								</>
							)}
						</div>
					)}

					{this.showStateStandardField && (
						<div className='test-data-item align-items-center'>
							<span className='control-label'>{this.stateStandartTitle}</span>

							{this.props.readOnly ? (
								<OnHoverTooltip message={this.props.stateStandard}>
									<span className='item-value'>{this.props.stateStandard}</span>
								</OnHoverTooltip>
							) : (
								<div className='item-value state-standard-field'>
									<a
										className='ss-content'
										href='#'
									>
										{this.state.stateStandard ? (
											<OnHoverTooltip message={this.state.stateStandard}>
												<span>{this.state.stateStandard}</span>
											</OnHoverTooltip>
										) : (
											<span>Add State Standard</span>
										)}
									</a>
								</div>
							)}
						</div>
					)}

					{!this.props.readOnly && (
						<div className='test-data-item align-items-center'>
							<span className='control-label'>Share Test:</span>

							{this.props.canUnshare ? (
								<div className='item-value'>
									<Checkbox
										id='shareDistrict'
										label={'Share with District'}
										checked={this.props.shared}
										onClick={(value: boolean) => this.shareTestChanged(value)}
									/>
								</div>
							) : (
								<span className='item-value'>
									{this.props.shared ? 'Share with District' : ''}
								</span>
							)}
						</div>
					)}

					{this.props.canModifyTestID &&
						(!this.props.readOnly || this.state.testIdn) && (
							<div className='test-data-item align-items-center'>
								<span className='control-label'>Test ID:</span>
								{this.props.readOnly ? (
									<span className='item-value test-id'>
										{this.state.testIdn}
									</span>
								) : (
									<TextInput
										className='item-value'
										value={this.state.testIdn}
										inputClassName='testId-input'
										onEdit={(value, validation) =>
											this.checkIdn(value, validation)
										}
										validator={() => this.idnValidator()}
										touched={this.state.testIdnTouched}
										validationPlacement={'right'}
										placeholder='Test ID'
									/>
								)}
							</div>
						)}
				</div>
				{this.props.testId > 0 &&
					this.props.canAddToSubject &&
					!this.props.draft && (
						<SubjectTabField
							testID={this.props.testId}
							testName={this.props.testName}
							onSave={(subjects) => this.onSubjectTabFieldClose(subjects)}
							disableNewSubjectBtn={this.props.disableNewSubjectBtn}
						/>
					)}
			</div>
		);
	}
}
