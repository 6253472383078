import {MouseEvent, useCallback} from 'react';
import {CustomHeaderProps} from 'ag-grid-react';
import {CheckAll} from '@esgi/ui/icons';
import {Button} from '@esgi/ui';
import {Container} from './index.styled';

export function HeaderCellRenderer({api}: CustomHeaderProps) {
	const handleClick = useCallback(
		(event: MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation();

			const rows = api.getRenderedNodes();

			const isEverySelected = rows.filter((row) => row.selectable).every((row) => row.isSelected());

			isEverySelected ? api.deselectAllOnCurrentPage() : api.selectAllOnCurrentPage();
		},
		[api],
	);

	return (
		<Container>
			<Button.Icon withBackgroundHover onClick={handleClick}>
				<CheckAll />
			</Button.Icon>
		</Container>
	);
}
