import {
	TestInfo,
	TestSession,
	UpdateScoreSessionRequestParams,
	UpdateYNScoreSessionResponse,
} from '../../../../../types/view-edit-session-details';
import {ViewModeContent} from './components/view-mode-content';
import {EditModeContent} from './components/edit-mode-content';
import {Observable} from 'rxjs';
import {useCallback} from 'react';
import {Student} from '../../../../../types/common';

type Props = {
	canEdit: boolean;
	isEditMode: boolean;
	onCloseAlert: VoidFunction;
	sessionInfo: TestSession;
	testInfo: TestInfo;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	student: Student;
	onDeleteSession: VoidFunction;
	onRestoreSession: VoidFunction;
	onDownloadSession: VoidFunction;
	updateScoreSession: (args: UpdateScoreSessionRequestParams) => Observable<UpdateYNScoreSessionResponse>;
};

export function SingleScoreTestSession({
	canEdit,
	isEditMode,
	onCloseAlert,
	sessionInfo,
	testInfo,
	setIsEditMode,
	student,
	onDeleteSession,
	onDownloadSession,
	onRestoreSession,
	updateScoreSession,
}: Props) {
	const onUpdateTestSession = useCallback(
		({
			score,
			notes,
			testDateTouched,
			testDate,
		}: {
			score: number;
			notes: string;
			testDateTouched: boolean;
			testDate: string;
		}) => {
			updateScoreSession({
				studentID: student.id,
				testID: sessionInfo.testID,
				testSessionID: sessionInfo.id,
				notes,
				testDate,
				testDateTouched,
				score,
			}).subscribe({
				complete: () => {
					setIsEditMode(false);
				},
			});
		},
		[sessionInfo.id, sessionInfo.testID, setIsEditMode, student.id, updateScoreSession],
	);

	if (isEditMode) {
		return (
			<EditModeContent
				canEdit={canEdit}
				sessionInfo={sessionInfo}
				testInfo={testInfo}
				onCloseAlert={onCloseAlert}
				setIsEditMode={setIsEditMode}
				student={student}
				onUpdateTestSession={onUpdateTestSession}
			/>
		);
	}

	return (
		<ViewModeContent
			canEdit={canEdit}
			sessionInfo={sessionInfo}
			testInfo={testInfo}
			setIsEditMode={setIsEditMode}
			onCloseAlert={onCloseAlert}
			student={student}
			onDeleteSession={onDeleteSession}
			onDownloadSession={onDownloadSession}
			onRestoreSession={onRestoreSession}
		/>
	);
}
