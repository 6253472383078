import {isNull} from 'underscore';
import {useFormTouched as useFormTouchedBase} from '../../../hooks/use-form-touched';
import {SubjectSettingDataExtended} from '../../types';

type Parameters = {
	initData: SubjectSettingDataExtended | null;
	currentData: SubjectSettingDataExtended;
};

export function useFormTouched({initData, currentData}: Parameters) {
	const isFormTouchedBase = useFormTouchedBase({
		initData,
		currentData,
	});

	if (isNull(initData)) {
		return false;
	}

	return (
		initData.isActive !== currentData.isActive ||
		initData.selectedTests.length !== currentData.selectedTests.length ||
		isFormTouchedBase
	);
}
