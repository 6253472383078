import {isNull} from 'underscore';
import {
	SchoolStudentsNeedingSupportMetricData,
	SchoolStudentsNeedingSupportMetricDataContract,
} from '@esgi/main/features/school-admin/dashboard';

export function parseSchoolStudentsNeedingSupportData(
	data: SchoolStudentsNeedingSupportMetricDataContract,
): SchoolStudentsNeedingSupportMetricData | null {
	if (isNull(data)) {
		return null;
	}

	return data as unknown as SchoolStudentsNeedingSupportMetricData;
}
