import {isUndefined} from '@esgi/ui';
import {CardsSortBy} from '../types/section';

export type SortingConfig<T> = Partial<
	Record<
		CardsSortBy,
		{
			comparator: keyof T | ((a: T, b: T) => number);
		}
	>
>;
export function sortTestCards<T>({
	testCards,
	config,
	activeSorting,
}: {
	testCards: T[];
	config: SortingConfig<T>;
	activeSorting: CardsSortBy;
}): T[] {
	const activeSortConfigItem = config[activeSorting];

	if (isUndefined(activeSortConfigItem)) {
		return testCards;
	}

	const {comparator} = activeSortConfigItem;

	const copiedTests = [...testCards];

	if (typeof comparator === 'function') {
		return copiedTests.sort(comparator);
	}

	return copiedTests.sort((a, b) => {
		const aValue = a[comparator];
		const bValue = b[comparator];

		if (typeof aValue === 'string' && typeof bValue === 'string') {
			return aValue.toLocaleLowerCase().localeCompare(bValue.toLocaleLowerCase());
		}

		if (typeof aValue === 'number' && typeof bValue === 'number') {
			return aValue - bValue;
		}

		return 0;
	});
}
