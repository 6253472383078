import {SelfAssess} from '@esgi/ui';
import {ActionButtons, ActionButtonsDivider} from './index.styled';
import {SessionsContextMenu} from '../../../../components/sessions-context-menu';
import {CardContextMenu} from '../../../../components/card-context-menu';
import {GridBox} from '@esgi/ui/layout';
import React, {Dispatch} from 'react';
import {EntityType, TestSession, TestSingleStudentModel} from '../../../../../../../../types';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {ClassGroupActionButtonsProps, StudentActionButtonsProps} from './types';
import {SelfAssessButton} from '../../../../components/self-assess-button';
import {TwoSplitButtonsBox} from '../../../../components/two-split-buttons-box';
import {StartTestButton} from '../../../../components/start-test-button';

type Props = {
	lastTestSessions: TestSession[];
	sessionsCount: number;
	openTestDetails: VoidFunction;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	isSmallScreen?: boolean;
	testInfo: TestSingleStudentModel['testInfo'],
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	subjectID: SubjectTab['id'];
	classID: Class['id'];
} & (StudentActionButtonsProps | ClassGroupActionButtonsProps);

export function CardActionButtons({
	lastTestSessions,
	sessionsCount,
	openTestDetails,
	userCanEditTest,
	userCanRemoveTest,
	isSmallScreen = false,
	testInfo,
	onRemoveTest,
	onTestMoveTo,
	subjectID,
	classID,
	...props
}: Props) {
	return (
		<ActionButtons isSmallScreen={isSmallScreen} dataCy='action-buttons'>
			<GridBox align='center' flow='column' gap={3}>
				<SessionsContextMenu
					lastTestSessions={lastTestSessions}
					sessionsCount={sessionsCount}
					maxScore={testInfo.totalPossible}
					subjectID={subjectID}
					classID={classID}
					testID={testInfo.id}
				/>
				<ActionButtonsDivider />

				<CardContextMenu
					openTestDetails={openTestDetails}
					userCanEditTest={userCanEditTest}
					userCanRemoveTest={userCanRemoveTest}
					testInfo={testInfo}
					onRemoveTest={onRemoveTest}
					onTestMoveTo={onTestMoveTo}
					subjectID={subjectID}
				/>

				{props.entityType === EntityType.ClassGroup && props.withSelfAssess && (
					<SelfAssessButton>
						<SelfAssess />
					</SelfAssessButton>
				)}
			</GridBox>

			{props.entityType === EntityType.Student && (props.withSelfAssess || props.withStartTestButton) && (
				<TwoSplitButtonsBox>
					{props.withSelfAssess && (
						<SelfAssessButton onClick={props.onSelfAssessStartTestClicked}>
							<SelfAssess />
						</SelfAssessButton>
					)}
					{props.withStartTestButton && <StartTestButton onClick={props.onStartTestClicked} />}
				</TwoSplitButtonsBox>
			)}
		</ActionButtons>
	);
}
