import React, {useEffect} from 'react';
import {ReportType, SubjectType} from '@esgi/core/enums';
import {lazyClass} from '@esgi/core/react';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {SsoTracker} from '@esgi/core/tracker';
import {UserType} from '@esgi/core/authentication';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';

const totalReportModal = lazyClass(() => import('shared/modules/reports/total-report/total-report-modal'));

function resolvePrefix(hierarchy: HierarchyInstance) {
	if (hierarchy.mode === HierarchyMode.Classic) {
		const selected = hierarchy.classic.selected;
		switch (selected.level) {
			case ClassicHierarchyLevel.Class:
			case ClassicHierarchyLevel.Student:
				return 'Class';
			case ClassicHierarchyLevel.Group:
				return 'Group';
			case ClassicHierarchyLevel.Teacher:
				return ''; //not supported
			case ClassicHierarchyLevel.School:
				return 'School';
			case ClassicHierarchyLevel.District:
				return 'District';
			case ClassicHierarchyLevel.SchoolsGroup:
				return 'School Group';
			case ClassicHierarchyLevel.TeachersGroup:
				return 'Teacher Group';
		}
	}

	if (hierarchy.mode === HierarchyMode.Specialist) {
		const s = hierarchy.specialist;
		const selected = hierarchy.specialist.selected;

		if (s.userID === 0) {
			if (selected.level === SpecialistHierarchyLevel.GroupOfSpecialists) {
				return 'Specialist Group';
			}
			if (s.type === UserType.ISS && s.filter.schoolID > 0) {
				return 'School';
			} else {
				return 'District';
			}
		} else {
			return 'Group';
		}
	}

	if (hierarchy.mode === HierarchyMode.PreAssess) {
		const pa = hierarchy.preAssess;

		if (pa.userID === 0) {
			return 'District';
		} else {
			return 'Group';
		}
	}

	return '';
}

function resolveType(hierarchy: HierarchyInstance): { type: ReportType, id: number } {
	if (hierarchy.mode === HierarchyMode.Classic) {
		const selected = hierarchy.classic.selected;
		switch (selected.level) {
			case ClassicHierarchyLevel.Class:
				return {type: ReportType.Class, id: selected.levelID};
			case ClassicHierarchyLevel.Student:
				if (hierarchy.classic.groupID) {
					return {type: ReportType.Group, id: hierarchy.classic.groupID};
				} else {
					return {type: ReportType.Class, id: hierarchy.classic.classID};
				}
			case ClassicHierarchyLevel.Group:
				return {type: ReportType.Group, id: selected.levelID};
			case ClassicHierarchyLevel.Teacher:
				return {type: ReportType.Teacher, id: selected.levelID};
			case ClassicHierarchyLevel.School:
				return {type: ReportType.StudentsSchool, id: selected.levelID};
			case ClassicHierarchyLevel.District:
				return {type: ReportType.StudentsDistrict, id: selected.levelID};
			case ClassicHierarchyLevel.SchoolsGroup:
				return {type: ReportType.StudentsSchoolsGroup, id: selected.levelID};
			case ClassicHierarchyLevel.TeachersGroup:
				return {type: ReportType.StudentsTeachersGroup, id: selected.levelID};
		}
	}

	if (hierarchy.mode === HierarchyMode.Specialist) {
		const s = hierarchy.specialist;

		if (s.userID === 0) {
			if (hierarchy.specialist.groupOfSpecialistsID > 0) {
				return {type: ReportType.GroupOfSpecialistsStudents, id: hierarchy.specialist.groupOfSpecialistsID};
			}
			if (s.type === UserType.ISS && s.filter.schoolID > 0) {
				return {type: ReportType.SchoolSpecialistStudents, id: s.filter.schoolID};
			} else {
				return {type: ReportType.DistrictSpecialistStudents, id: s.districtID};
			}
		} else {
			return {type: ReportType.SpecialistGroup, id: s.groupID};
		}
	}

	if (hierarchy.mode === HierarchyMode.PreAssess) {
		const pa = hierarchy.preAssess;

		if (pa.userID === 0) {
			return {type: ReportType.DistrictPreassessStudents, id: pa.districtID};
		} else {
			return {type: ReportType.SpecialistGroup, id: pa.groupID};
		}
	}

	return {type: ReportType.None, id: 0};
}

type Props = {
	hierarchy: HierarchyInstance;
	subjectId: number;
	subjectType: SubjectType;
	globalSchoolYearId: number;
	onClose: VoidFunction;
}

export function StudentsReportLauncher(props: Props) {
	useEffect(() => {
		SsoTracker.trackEvent({
			trackingEvent: resolvePrefix(props.hierarchy) + 'TotalsReport',
		});

		const type = resolveType(props.hierarchy);
		const snapshot = props.hierarchy.snapshot;
		const changesCollector = new ChangesCollector({
			id: props.subjectId,
			type: props.subjectType,
		}, snapshot);
		totalReportModal.load().then(trm => {
			const ctr = new trm({
				reportId: type.id,
				reportType: type.type,
				globalSchoolYearId: props.globalSchoolYearId,
				subjectId: props.subjectId,
				subjectType: props.subjectType,
			}, changesCollector, snapshot);

			ctr.load();
			ctr.events.onClosed(props.onClose);
		});
	}, []);
	return <></>;
}