import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {EntireSectionBlock} from '../entire-section-block';

export const Container = styled(EntireSectionBlock, {
	gap: '$2',
	height: 'max-content',

	'& [data-tabs]': {
		backgroundColor: '$background',
	},
});

export const Divider = styled(Box, {
	width: '100%',
	height: 1,
	backgroundColor: '$border',
});

export const SelectableItemsBox = styled(GridBox, {
	gap: '$1',
	maxHeight: 280,
});
