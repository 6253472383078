import {useSpecialistGroups, SpecialistGroup} from '@esgi/main/libs/admin-store';
import {isUndefined} from '@esgi/ui';
import {useCallback, useImperativeHandle, useState} from 'react';
import {DeleteSpecialistGroupAlert} from './components/delete-specialist-group-alert';
import {ImpossibleDeleteSpecialistGroupAlert} from './components/impossible-delete-specialist-group-alert';
import {isNull} from 'underscore';
import {RemoveSpecialistGroupManagerRef} from './types';

type Props = {
	managerRef: RemoveSpecialistGroupManagerRef;
	onRemoved: VoidFunction;
};

export function RemoveSpecialistGroupImpl({onRemoved, managerRef}: Props) {
	const [{data: specialistGroups}] = useSpecialistGroups();

	const [potentialRemovedSpecialistGroup, setPotentialRemovedSpecialistGroup] = useState<SpecialistGroup | null>(null);

	const [showImpossibleDeleteAlert, setShowImpossibleDeleteAlert] = useState(false);

	const closeDeleteAlert = useCallback(() => {
		setPotentialRemovedSpecialistGroup(null);
	}, []);

	const closeImpossibleDeleteAlert = useCallback(() => {
		setShowImpossibleDeleteAlert(false);
	}, []);

	useImperativeHandle(managerRef, () => ({
		runRemove: ({specialistGroupID}) => {
			const specialistGroup = specialistGroups.find(({id}) => id === specialistGroupID);

			if (isUndefined(specialistGroup)) {
				throw new Error('specialistGroup is not found in admin store');
			}

			const specialistGroupsList = specialistGroups.filter(
				({specialistID}) => specialistID === specialistGroup.specialistID,
			);

			if (specialistGroupsList.length === 1) {
				setShowImpossibleDeleteAlert(true);
				return;
			}

			setPotentialRemovedSpecialistGroup(specialistGroup);
		},
	}));

	return (
		<>
			{!isNull(potentialRemovedSpecialistGroup) && (
				<DeleteSpecialistGroupAlert
					onClose={closeDeleteAlert}
					potentialRemovedSpecialistGroup={potentialRemovedSpecialistGroup}
					onRemoved={onRemoved}
				/>
			)}

			{showImpossibleDeleteAlert && <ImpossibleDeleteSpecialistGroupAlert onClose={closeImpossibleDeleteAlert} />}
		</>
	);
}
