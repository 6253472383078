import {routes} from '@esgi/main/libs/core';
import {AdditionalElementType, NavigateItem} from './types';
import {menuIconByPath, menuLabelByRoute} from '../constants';

export const navigateItems: (NavigateItem | AdditionalElementType)[] = [
	{
		to: routes.districtAdmin.dashboard,
		label: menuLabelByRoute.dashboard,
		Icon: menuIconByPath.dashboard,
	},
	{
		to: routes.districtAdmin.data.root,
		label: menuLabelByRoute.data,
		Icon: menuIconByPath.data,
	},

	{
		to: routes.districtAdmin.subjectManagement,
		label: menuLabelByRoute.subjectManagement,
		Icon: menuIconByPath.subjectManagement,
	},

	AdditionalElementType.Divider,

	{
		to: routes.districtAdmin.testExplorer,
		label: menuLabelByRoute.testExplorer,
		Icon: menuIconByPath.testExplorer,
	},

	AdditionalElementType.Divider,

	{
		to: routes.districtAdmin.studentManager,
		label: menuLabelByRoute.studentManager,
		Icon: menuIconByPath.studentManager,
	},
];
