import {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {getThemeColor, ThemeColorKeys} from '@esgi/ui';
import {Property} from '@stitches/react/types/css';
import {useOpacityAnimation} from '../../hooks/use-opacity-animation';
import {Container, LineBox} from './index.styled';
import {useBarChartContext} from '../../context';

type Props = Omit<ComponentPropsWithoutRef<typeof Container>, 'children'> & {
	width: Property.Width;
	height: Property.Height;
	value: number;
	color?: ThemeColorKeys;
	borderRadius?: Property.BorderRadius;
};

export const VerticalLine = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'bar-chart-vertical-line',
			css = {},
			width,
			height,
			value,
			color = 'highContrast',
			borderRadius = 1,
			...divProps
		},
		forwardedRef,
	) => {
		const {getValueProgress} = useBarChartContext();

		const {valueProgress} = useMemo(() => {
			const valueProgress = getValueProgress(value);

			return {
				valueProgress,
			};
		}, [getValueProgress, value]);

		const animationProps = useOpacityAnimation();

		return (
			<Container dataCy={dataCy} css={css} ref={forwardedRef} {...divProps}>
				<LineBox
					style={animationProps}
					css={{
						left: `calc(${valueProgress * 100}% - ${typeof width === 'number' ? `${width / 2}px` : width} )`,
						width: width,
						height: height,
						backgroundColor: getThemeColor(color),
						borderRadius,
					}}
				/>
			</Container>
		);
	},
);
