import {ErrorTooltipItem} from '../../../../components/error-tooltip';
import {classNameMaxLength} from '../../../constants';

export const schoolSelectErrorItems: ErrorTooltipItem<string>[] = [
	{
		showOnError: 'required',
		message: 'School is required',
	},
];

export const teacherSelectErrorItems: ErrorTooltipItem<string>[] = [
	{
		showOnError: 'required',
		message: 'Teacher is required',
	},
];

export const classNameErrorItems: ErrorTooltipItem<string>[] = [
	{
		showOnError: 'required',
		message: 'Not less than 1 character',
	},
	{
		showOnError: 'isDublicateValue',
		message: 'This class name is already taken. Please enter a unique class name',
	},
	{
		showOnError: 'length-max',
		message: `No more than ${classNameMaxLength} characters`,
	},
];
