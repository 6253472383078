import {Dispatch, useCallback, useMemo, useState} from 'react';
import {AdaptedWidgetOptions, DynamicFormName, PeriodsVariant} from './types';
import {Widget} from '../../../../types/widget';
import {
	LowestAchievementWidgetOptions,
	LowestAchievementWidgetUpdatedValue,
	LowestAchievementWidgetViewType,
} from '../../../../types/lowest-achievement';
import {useFormsInit} from './forms-data/use-forms-init';
import {useStreamEffect} from '@esgi/ui';
import {ElementStatus} from '@esgi/ui/form';
import {AdditionalSettingBox, CardBody, CardBodyDivider} from './index.styled';
import {MainSettings} from './components/main-settings';
import {GridBox} from '@esgi/ui/layout';
import {periodsVariantItems} from './constants';
import {SchoolsData} from './components/schools-data';
import {StudentsData} from './components/students-data';
import {useSaveData} from './use-save-data';
import {getDistrictCutoffEnumValue} from './get-district-cutoff-enum-value';
import {
	DeployedSubject,
	FormToggleGroup,
	GradeLevel,
	SchoolModel,
	WidgetCard,
} from '@esgi/main/features/admins/dashboard';

type Props = {
	subjects: DeployedSubject[];
	allSchoolsList: SchoolModel[];
	onDeleteWidgetByID: Dispatch<Widget['id']>;
	widgetOptions: LowestAchievementWidgetOptions;
	onUpdateWidget: Dispatch<LowestAchievementWidgetUpdatedValue>;
	gradeLevels: GradeLevel[];
};

export function EditMode({
	subjects,
	allSchoolsList,
	onDeleteWidgetByID,
	widgetOptions,
	onUpdateWidget,
	gradeLevels,
}: Props) {
	const adaptedWidgetOptions = useMemo<AdaptedWidgetOptions>(() => {
		const {districtCutoff, ...restOptions} = widgetOptions;

		return {
			...restOptions,
			districtCutoff: getDistrictCutoffEnumValue(districtCutoff),
		};
	}, [widgetOptions]);

	const [currentDynamicFormName, setCurrentDynamicFormName] = useState<DynamicFormName>();

	const [widgetViewType, setWidgetViewType] = useState(widgetOptions.viewType);

	const [selectedSchoolsIDs, setSelectedSchoolsIDs] = useState(widgetOptions.schoolIDs.map(String));
	const [selectedContentArea, setSelectedContentArea] = useState(widgetOptions.contentAreaIDs.map(String));
	const [selectedSubjectIDs, setSelectedSubjectIDs] = useState(widgetOptions.subjectIDs.map(String));
	const [selectedGradeLevels, setSelectedGradeLevels] = useState(widgetOptions.gradeLevelIDs.map(String));

	const [isCommonFormTouched, setIsCommonFormTouched] = useState(false);

	const [isDynamicFormDataTouched, setIsDynamicFormDataTouched] = useState(false);
	const [isDynamicFormDataValid, setIsDynamicFormDataValid] = useState(true);

	const {
		commonForm,
		studentsSinglePeriodForm,
		studentsTwoPeriodsForm,
		schoolsForm,
		initialCommonForm,
		initialStudentsTwoPeriodsForm,
		setStudentsSinglePeriodFormInitialData,
		setStudentsTwoPeriodsFormInitialData,
		setSchoolsFormInitialData,
	} = useFormsInit({widgetOptions: adaptedWidgetOptions});

	const onCurrentDynamicFormNameChange = useCallback(
		(newFormName: DynamicFormName) => {
			setCurrentDynamicFormName((currentName) => {
				switch (currentName) {
					case 'studentsSinglePeriod':
						setStudentsSinglePeriodFormInitialData();
						break;
					case 'studentsTwoPeriods':
						setStudentsTwoPeriodsFormInitialData();
						break;
					case 'schools':
						setSchoolsFormInitialData();
						break;
				}

				return newFormName;
			});
		},
		[setSchoolsFormInitialData, setStudentsSinglePeriodFormInitialData, setStudentsTwoPeriodsFormInitialData],
	);

	useStreamEffect(commonForm.controls.viewType.onChanged, ({currState: {value}}) => {
		setWidgetViewType(value);

		if (value === LowestAchievementWidgetViewType.Students) {
			commonForm.controls.periodsVariant.value = PeriodsVariant.SinglePeriod;
			commonForm.controls.periodsVariant.status = ElementStatus.valid;
		}

		if (value === LowestAchievementWidgetViewType.Schools) {
			commonForm.controls.periodsVariant.value = PeriodsVariant.None;
			commonForm.controls.periodsVariant.status = ElementStatus.disabled;
		}
	});

	useStreamEffect(
		commonForm.onChanged,
		({
			currState: {
				value: {viewType, periodsVariant, districtCutoff},
			},
		}) => {
			const isFormTouched =
				viewType !== widgetOptions.viewType ||
				districtCutoff[0] !== adaptedWidgetOptions.districtCutoff ||
				(periodsVariant !== PeriodsVariant.None && periodsVariant !== initialCommonForm.periodsVariant);

			setIsCommonFormTouched(isFormTouched);
		},
	);

	const isSelectedEntityTouched = useMemo(() => {
		const contentAreaTouched =
			selectedContentArea.length !== widgetOptions.contentAreaIDs.length ||
			selectedContentArea.some((item) => !widgetOptions.contentAreaIDs.includes(Number(item)));

		const subjectsTouched =
			selectedSubjectIDs.length !== widgetOptions.subjectIDs.length ||
			selectedSubjectIDs.some((item) => !widgetOptions.subjectIDs.includes(Number(item)));

		const gradeLevelsTouched =
			selectedGradeLevels.length !== widgetOptions.gradeLevelIDs.length ||
			selectedGradeLevels.some((item) => !widgetOptions.gradeLevelIDs.includes(Number(item)));

		const schoolsTouched =
			currentDynamicFormName !== 'schools' &&
			(selectedSchoolsIDs.length !== widgetOptions.schoolIDs.length ||
				selectedSchoolsIDs.some((item) => !widgetOptions.schoolIDs.includes(Number(item))));

		return contentAreaTouched || subjectsTouched || gradeLevelsTouched || schoolsTouched;
	}, [
		currentDynamicFormName,
		selectedContentArea,
		selectedGradeLevels,
		selectedSchoolsIDs,
		selectedSubjectIDs,
		widgetOptions,
	]);

	const isFormTouched = isCommonFormTouched || isSelectedEntityTouched || isDynamicFormDataTouched;

	const handleSaveWidgetOptions = useSaveData({
		districtID: widgetOptions.districtID,
		globalSchoolYearID: widgetOptions.globalSchoolYearID,
		commonForm,
		schoolsForm,
		studentsSinglePeriodForm,
		studentsTwoPeriodsForm,
		selectedContentArea,
		selectedGradeLevels,
		selectedSchoolsIDs,
		selectedSubjectIDs,
		onUpdateWidget,
		setIsCommonFormTouched,
		setIsDynamicFormDataTouched,
		setIsDynamicFormDataValid,
		subjects,
		allSchoolsList,
		setSelectedSchoolsIDs,
		gradeLevels,
	});

	return (
		<WidgetCard.EditMode
			isChildrenFormTouched={isFormTouched}
			isChildrenFormValid={isDynamicFormDataValid}
			onDeleteWidgetByID={onDeleteWidgetByID}
			onUpdateWidget={handleSaveWidgetOptions}
		>
			<CardBody>
				<MainSettings
					commonForm={commonForm}
					subjects={subjects}
					selectedContentArea={selectedContentArea}
					selectedGradeLevels={selectedGradeLevels}
					selectedSubjectIDs={selectedSubjectIDs}
					setSelectedContentArea={setSelectedContentArea}
					setSelectedGradeLevels={setSelectedGradeLevels}
					setSelectedSubjectIDs={setSelectedSubjectIDs}
					schools={allSchoolsList}
					selectedSchoolsIDs={selectedSchoolsIDs}
					setSelectedSchoolsIDs={setSelectedSchoolsIDs}
					currentDynamicFormName={currentDynamicFormName}
					gradeLevels={gradeLevels}
				/>

				<CardBodyDivider />

				<AdditionalSettingBox>
					<GridBox gap='3'>
						<WidgetCard.EditMode.FormElement control={commonForm.controls.periodsVariant}>
							<FormToggleGroup items={periodsVariantItems} />
						</WidgetCard.EditMode.FormElement>

						{widgetViewType === LowestAchievementWidgetViewType.Students && (
							<StudentsData
								periodsVariantControl={commonForm.controls.periodsVariant}
								studentsSinglePeriodForm={studentsSinglePeriodForm}
								studentsTwoPeriodsForm={studentsTwoPeriodsForm}
								initialStudentsTwoPeriodsForm={initialStudentsTwoPeriodsForm}
								onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
								setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
								setIsDynamicFormDataValid={setIsDynamicFormDataValid}
							/>
						)}
						{widgetViewType === LowestAchievementWidgetViewType.Schools && (
							<SchoolsData
								form={schoolsForm}
								onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
								setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
								setIsDynamicFormDataValid={setIsDynamicFormDataValid}
							/>
						)}
					</GridBox>
				</AdditionalSettingBox>
			</CardBody>
		</WidgetCard.EditMode>
	);
}
