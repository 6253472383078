import {Widget} from './widget';
import {LowestAchievementWidgetOptions as LowestAchievementWidgetOptionsContract} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/lowest-achievement-widget-options';
import {LowestAchievementStudentData as LowestAchievementStudentDataContract} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/metrics/lowest-achievement-student-data';
import {LowestAchievementMetricData as LowestAchievementMetricDataContract} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/metrics/lowest-achievement-metric-data';
import {Teacher, WidgetPeriod} from '@esgi/main/features/admins/dashboard';

export {LowestAchievementWidgetViewType as LowestAchievementWidgetViewTypeNumerable} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/lowest-achievement-widget-view-type';
export {type LowestAchievementSchoolData} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/metrics/lowest-achievement-school-data';

export {type LowestAchievementWidgetOptionsContract, type LowestAchievementMetricDataContract};

export enum LowestAchievementWidgetViewType {
	None = 'None',
	Students = 'Students',
	Schools = 'Schools',
}

export type LowestAchievementWidgetOptions = Omit<LowestAchievementWidgetOptionsContract, 'viewType' | 'periods'> & {
	viewType: LowestAchievementWidgetViewType;
	periods: WidgetPeriod[];
};

export type LowestAchievementStudentData = Omit<LowestAchievementStudentDataContract, 'teacher'> & {
	teacher: Teacher | null;
};

export type LowestAchievementMetricData = Omit<LowestAchievementMetricDataContract, 'studentsData'> & {
	studentsData: LowestAchievementStudentData[];
};

export type LowestAchievementWidgetUpdatedValue = Pick<Widget, 'id' | 'name'> & {
	options: LowestAchievementWidgetOptions;
	selectedArraysEntity: Record<
		keyof Pick<LowestAchievementWidgetOptions, 'schoolIDs' | 'contentAreaIDs' | 'gradeLevelIDs' | 'subjectIDs'>,
		'all' | 'part'
	>;
};
