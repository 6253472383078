import {
	ClassesSortableKey,
	SortableItem,
	StudentsSinglePeriodSortableKey,
	StudentsTwoPeriodsSortableKey,
} from './types';

export const studentsViewDataPageSize = 7;
export const classesViewDataPageSize = 5;

export const studentsOnePeriodSortableCells: SortableItem<StudentsSinglePeriodSortableKey>[] = [
	{
		key: 'student',
		label: 'Student',
	},
	{
		key: 'teacher',
		label: 'Teacher',
	},
	{
		key: 'class',
		label: 'Class',
	},
	{
		key: 'grade',
		label: 'Grade',
		justifyContent: 'end',
	},
	{
		key: 'avg',
		label: 'Avg. Result',
		justifyContent: 'end',
	},
];

export const studentsTwoPeriodsSortableCells: SortableItem<StudentsTwoPeriodsSortableKey>[] = [
	{
		key: 'student',
		label: 'Student',
	},
	{
		key: 'teacher',
		label: 'Teacher',
	},
	{
		key: 'class',
		label: 'Class',
	},
	{
		key: 'grade',
		label: 'Grade',
		justifyContent: 'end',
	},
	{
		key: 'avgFirst',
		label: 'Avg. Result',
		justifyContent: 'end',
	},
	{
		key: 'avgSecond',
		label: 'Avg. Result',
		justifyContent: 'end',
	},
	{
		key: 'delta',
		label: '∆',
		justifyContent: 'end',
	},
];

export const classSortableCells: SortableItem<ClassesSortableKey>[] = [
	{
		key: 'class',
		label: 'Class',
	},
	{
		key: 'teacher',
		label: 'Teacher',
	},
	{
		key: 'lowestAchievementStudents',
		label: 'Students In Need',
		justifyContent: 'end',
	},
	{
		key: 'totalStudents',
		label: 'Total Students',
		justifyContent: 'end',
	},
	{
		key: 'result',
		label: 'Class %',
		justifyContent: 'end',
	},
];
