import {useMemo, useState} from 'react';
import {getFullName, SubjectLevel} from '@esgi/main/kits/common';
import {GradeLevel, SelectableListItemModel, WidgetCard} from '@esgi/main/features/admins/dashboard';
import {SubjectTab, TeacherModel} from '../../../../../../../types';

export function useDynamicAdaptedItems({
	teachers,
	subjects,
	gradeLevels,
}: {
	teachers: TeacherModel[];
	subjects: SubjectTab[];
	gradeLevels: GradeLevel[];
}) {
	const [subjectLevel, setSubjectLevel] = useState(SubjectLevel.School);

	const {allContentAreas} = WidgetCard.useWidgetCardContext();

	const teachersAdaptedItems = useMemo(
		() =>
			teachers.map<SelectableListItemModel<string>>(({id, firstName, lastName}) => ({
				value: String(id),
				label: getFullName({firstName, lastName}),
			})),
		[teachers],
	);

	const subjectsAdaptedItems = useMemo(
		() =>
			subjects.map<SelectableListItemModel<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[subjects],
	);

	const subjectsFilteredAdaptedItems = useMemo(
		() =>
			subjects
				.filter((item) => item.level === subjectLevel)
				.map<SelectableListItemModel<string>>(({id, name}) => ({
					value: String(id),
					label: name,
				})),
		[subjectLevel, subjects],
	);

	const contentAreasAdaptedItems = useMemo(
		() =>
			allContentAreas.map<SelectableListItemModel<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[allContentAreas],
	);

	const gradeLevelsAdaptedItems = useMemo(
		() =>
			gradeLevels.map<SelectableListItemModel<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[gradeLevels],
	);

	return {
		subjectLevel,
		setSubjectLevel,
		teachersAdaptedItems,
		subjectsAdaptedItems,
		subjectsFilteredAdaptedItems,
		contentAreasAdaptedItems,
		gradeLevelsAdaptedItems,
	};
}
