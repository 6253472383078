import {Select} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';
import {Sort} from '@esgi/ui';
import {SortDirection} from '@esgi/contracts/esgi/types/esgi.enums/sort-direction';

export const SelectField = styled(Select.Field, {
	maxHeight: 40,
});

export const SelectOption = styled(Select.Option, {
	cursor: 'pointer',
	variants: {
		displayDefaultLabel: {
			true: {
				display: 'flex',
				justifyContent: 'space-between',

				'&::after': {
					content: 'Default',
					color: '$primaryMuted',
				},
			},
		},
	},
});

export const SortIcon = styled(Sort, {
	cursor: 'pointer',
	transition: 'transform .3s ease, fill .3s',
	width: 24,
	height: 24,
	marginRight: 8,
	variants: {
		direction: {
			[SortDirection.Asc]: {
				transform: 'rotateX(180deg)',
			},
			[SortDirection.Desc]: {
				transform: 'rotateX(0deg)',
			},
			[SortDirection.None]: {
				transform: 'rotateX(0deg)',
			},
		},
		disabled: {
			true: {
				cursor: 'auto',
				'& path': {
					fill: '$lowContrast',
				},
			},
		},
	},
});
