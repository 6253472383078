import {SettingsTab, TabItemsGroup} from '../../types';

export const tabItems: TabItemsGroup = {
	general: [
		{
			value: SettingsTab.SystemSettings,
			name: 'System Settings',
		},
	],

	teachersAndStudents: [
		{
			value: SettingsTab.Teacher,
			name: 'Teacher',
		},
		{
			value: SettingsTab.Reports,
			name: 'Reports',
		},
		{
			value: SettingsTab.CustomizeParentLetter,
			name: 'Customize Parent Letter',
		},
	],

	export: [
		{
			value: SettingsTab.ExportData,
			name: 'Export Data',
		},
	],
};


export const initialSettingsTabsData = {
	[SettingsTab.SystemSettings]: false,
	[SettingsTab.Teacher]: false,
	[SettingsTab.Reports]: false,
	[SettingsTab.CustomizeParentLetter]: false,
	[SettingsTab.ExportData]: false,
};
