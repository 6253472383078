import {ComponentPropsWithoutRef, Dispatch, forwardRef, useCallback, useEffect, useState} from 'react';
import {Container, StatusTextBox} from './index.styled';
import {CounterBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Repeat} from '@esgi/ui/icons';
import {Button} from '@esgi/ui';
import {isNull} from 'underscore';
import {GridApi} from 'ag-grid-community';

type Props = Omit<ComponentPropsWithoutRef<typeof Container>, 'children'> & {
	gridApi: GridApi | null;
	hideOnFiltersCountZero?: boolean;
	onAppliedFiltersCountChanged?: Dispatch<number>;
};

export const AppliedFiltersInfo = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'applied-filters-info',
			css = {},
			gridApi,
			onAppliedFiltersCountChanged,
			hideOnFiltersCountZero = true,
			...props
		},
		forwardedRef,
	) => {
		const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

		const updateAppliedFiltersCount = useCallback(
			(count: number) => {
				setAppliedFiltersCount(count);
				onAppliedFiltersCountChanged?.(count);
			},
			[onAppliedFiltersCountChanged],
		);

		useEffect(() => {
			if (isNull(gridApi)) {
				return;
			}

			const filterChangedHandler = () => {
				const columns = gridApi.getColumns();

				if (isNull(columns)) {
					updateAppliedFiltersCount(0);
					return;
				}

				let filterAppliedCounter = 0;

				for (const column of columns) {
					const filterModel = gridApi.getColumnFilterModel(column);

					if (filterModel) {
						filterAppliedCounter++;
					}
				}

				updateAppliedFiltersCount(filterAppliedCounter);
			};

			gridApi.addEventListener('filterChanged', filterChangedHandler);

			return () => {
				gridApi.removeEventListener('filterChanged', filterChangedHandler);
			};
		}, [gridApi, updateAppliedFiltersCount]);

		const resetFilters = useCallback(() => {
			if (!isNull(gridApi)) {
				gridApi.setFilterModel(null);
			}
		}, [gridApi]);

		if (!appliedFiltersCount && hideOnFiltersCountZero) {
			return null;
		}

		return (
			<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				<StatusTextBox>
					<CounterBox color='blue'>
						<Text size='small' font='mono'>
							{appliedFiltersCount}
						</Text>
					</CounterBox>
					<Text size='xSmall' font='mono' color='primary'>
						Filters Applied
					</Text>
				</StatusTextBox>

				<Button.Icon onClick={resetFilters} disabled={!appliedFiltersCount}>
					<Repeat />
				</Button.Icon>
			</Container>
		);
	},
);
