import {FormControl, FormGroup, useForm} from '@esgi/ui/form';
import {AdditionalSettingBox, CardBody, CardBodyDivider} from './index.styled';
import {isUndefined, useStreamEffect} from '@esgi/ui';
import {Dispatch, useMemo, useRef, useState} from 'react';
import {MainSettings} from './components/main-settings';
import {CandleChartData} from './components/candle-chart-data';
import {PiechartData} from './components/piechart-data';
import {DynamicFormDataRef} from './types';
import {Widget} from '../../../../types/widget';
import {
	DistrictPerformanceWidgetOptions,
	DistrictPerformanceWidgetUpdatedValue,
} from '../../../../types/district-performance';
import {FlexBox} from '@esgi/ui/layout';
import {
	CheckboxesBox,
	CheckboxFormElement,
	DeployedSubject,
	GradeLevel,
	OnUpdateWidget,
	PerformanceWidgetViewType,
	SchoolModel,
	WidgetCard,
} from '@esgi/main/features/admins/dashboard';

type Props = {
	subjects: DeployedSubject[];
	allSchoolsList: SchoolModel[];
	onDeleteWidgetByID: Dispatch<Widget['id']>;
	widgetOptions: DistrictPerformanceWidgetOptions;
	onUpdateWidget: Dispatch<DistrictPerformanceWidgetUpdatedValue>;
	gradeLevels: GradeLevel[];
};

export function EditMode({
	subjects,
	allSchoolsList,
	onDeleteWidgetByID,
	widgetOptions,
	onUpdateWidget,
	gradeLevels,
}: Props) {
	const {allContentAreas} = WidgetCard.useWidgetCardContext();

	const [widgetViewType, setWidgetViewType] = useState(widgetOptions.viewType);

	const [selectedContentArea, setSelectedContentArea] = useState(widgetOptions.contentAreaIDs.map(String));
	const [selectedSubjectIDs, setSelectedSubjectIDs] = useState(widgetOptions.subjectIDs.map(String));
	const [selectedGradeLevels, setSelectedGradeLevels] = useState(widgetOptions.gradeLevelIDs.map(String));
	const [selectedSchoolsIDs, setSelectedSchoolsIDs] = useState(widgetOptions.schoolIDs.map(String));

	const [isCommonFormTouched, setIsCommonFormTouched] = useState(false);
	const [isCommonFormValid, setIsCommonFormValid] = useState(true);

	const [isDynamicFormDataTouched, setIsDynamicFormDataTouched] = useState(false);
	const [isDynamicFormDataValid, setIsDynamicFormDataValid] = useState(true);

	const dynamicFormDataRef = useRef<DynamicFormDataRef | undefined>();

	const commonForm = useForm(
		() =>
			new FormGroup({
				viewType: new FormControl(widgetOptions.viewType),
				size: new FormControl(widgetOptions.viewSize),
				isShowAverageValue: new FormControl(widgetOptions.showAllSchoolsAvg),
			}),
	);

	useStreamEffect(commonForm.controls.viewType.onChanged, ({currState: {value}}) => {
		setWidgetViewType(value);
	});

	useStreamEffect(
		commonForm.onChanged,
		({
			currState: {
				value: {isShowAverageValue, size, viewType},
			},
		}) => {
			const isFormTouched =
				isShowAverageValue !== widgetOptions.showAllSchoolsAvg ||
				size !== widgetOptions.viewSize ||
				viewType !== widgetOptions.viewType;

			setIsCommonFormTouched(isFormTouched);

			commonForm.validate(true).subscribe(({valid}) => {
				setIsCommonFormValid(valid);
			});
		},
	);

	const isSelectedEntityTouched = useMemo(() => {
		const contentAreaTouched =
			selectedContentArea.length !== widgetOptions.contentAreaIDs.length ||
			selectedContentArea.some((item) => !widgetOptions.contentAreaIDs.includes(Number(item)));

		const subjectsTouched =
			selectedSubjectIDs.length !== widgetOptions.subjectIDs.length ||
			selectedSubjectIDs.some((item) => !widgetOptions.subjectIDs.includes(Number(item)));

		const gradeLevelsTouched =
			selectedGradeLevels.length !== widgetOptions.gradeLevelIDs.length ||
			selectedGradeLevels.some((item) => !widgetOptions.gradeLevelIDs.includes(Number(item)));

		const schoolsTouched =
			selectedSchoolsIDs.length !== widgetOptions.schoolIDs.length ||
			selectedSchoolsIDs.some((item) => !widgetOptions.schoolIDs.includes(Number(item)));

		return contentAreaTouched || subjectsTouched || gradeLevelsTouched || schoolsTouched;
	}, [selectedContentArea, selectedGradeLevels, selectedSchoolsIDs, selectedSubjectIDs, widgetOptions]);

	const isFormTouched = isCommonFormTouched || isSelectedEntityTouched || isDynamicFormDataTouched;
	const isFormValid = isCommonFormValid && isDynamicFormDataValid;

	const onSaveWidgetData: OnUpdateWidget = ({id, name}) => {
		const dynamicData = dynamicFormDataRef.current?.getData();

		if (!isUndefined(dynamicData)) {
			const {periods, candles} = dynamicData;

			const contentAreaIDs = selectedContentArea.map(Number);
			const subjectIDs = selectedSubjectIDs.map(Number);
			const gradeLevelIDs = selectedGradeLevels.map(Number);
			const schoolIDs = selectedSchoolsIDs.map(Number);

			onUpdateWidget({
				id,
				name,
				options: {
					districtID: widgetOptions.districtID,
					globalSchoolYearID: widgetOptions.globalSchoolYearID,
					viewType: commonForm.controls.viewType.value,
					viewSize: commonForm.controls.size.value,
					showAllSchoolsAvg: commonForm.controls.isShowAverageValue.value,
					contentAreaIDs,
					subjectIDs,
					gradeLevelIDs,
					schoolIDs,
					periods,
					candles,
				},
				selectedArraysEntity: {
					contentAreaIDs: allContentAreas.length === contentAreaIDs.length ? 'all' : 'part',
					gradeLevelIDs: gradeLevels.length === gradeLevelIDs.length ? 'all' : 'part',
					schoolIDs: allSchoolsList.length === schoolIDs.length ? 'all' : 'part',
					subjectIDs: subjects.length === subjectIDs.length ? 'all' : 'part',
				},
			});

			setIsCommonFormTouched(false);
			setIsCommonFormValid(true);

			setIsDynamicFormDataTouched(false);
			setIsDynamicFormDataValid(true);
		}
	};

	return (
		<WidgetCard.EditMode
			isChildrenFormTouched={isFormTouched}
			isChildrenFormValid={isFormValid}
			onDeleteWidgetByID={onDeleteWidgetByID}
			onUpdateWidget={onSaveWidgetData}
		>
			<CardBody>
				<MainSettings
					sizeControl={commonForm.controls.size}
					subjects={subjects}
					viewTypeControl={commonForm.controls.viewType}
					selectedContentArea={selectedContentArea}
					selectedGradeLevels={selectedGradeLevels}
					selectedSubjectIDs={selectedSubjectIDs}
					setSelectedContentArea={setSelectedContentArea}
					setSelectedGradeLevels={setSelectedGradeLevels}
					setSelectedSubjectIDs={setSelectedSubjectIDs}
					schools={allSchoolsList}
					selectedSchoolsIDs={selectedSchoolsIDs}
					setSelectedSchoolsIDs={setSelectedSchoolsIDs}
					gradeLevels={gradeLevels}
				/>

				<CardBodyDivider />

				<AdditionalSettingBox>
					{widgetViewType === PerformanceWidgetViewType.Candles && (
						<CandleChartData
							periods={widgetOptions.periods}
							setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
							setIsDynamicFormDataValid={setIsDynamicFormDataValid}
							dynamicFormDataRef={dynamicFormDataRef}
							candles={widgetOptions.candles}
						/>
					)}

					{widgetViewType === PerformanceWidgetViewType.Piechart && widgetOptions.periods[0] && (
						<PiechartData
							periodData={widgetOptions.periods[0]}
							setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
							setIsDynamicFormDataValid={setIsDynamicFormDataValid}
							dynamicFormDataRef={dynamicFormDataRef}
						/>
					)}

					<CheckboxesBox>
						<WidgetCard.EditMode.FormElement control={commonForm.controls.isShowAverageValue}>
							<FlexBox justify='between'>
								<CheckboxFormElement label='Show All Schools Average' />
								{widgetViewType === PerformanceWidgetViewType.Piechart && (
									<WidgetCard.EditMode.AverageHint.Piechart
										avgResultName='Average across all schools'
										chartName='1st school'
										description='The outer circle represents the value for a specific school, while the inner blue circle shows the average for all schools. This average is calculated by summing the values for all schools and dividing the total by the number of schools.'
										resultName='School result'
									/>
								)}
							</FlexBox>
						</WidgetCard.EditMode.FormElement>
					</CheckboxesBox>
				</AdditionalSettingBox>
			</CardBody>
		</WidgetCard.EditMode>
	);
}
