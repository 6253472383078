import {AddWidgetModel} from '@esgi/main/features/admins/dashboard';
import {WidgetType} from './types';

export const widgets: AddWidgetModel<WidgetType>[] = [
	{
		title: 'District Performance',
		description: 'Average performance for all students across schools in %',
		labels: [
			'Boxplot',
			'Pie Chart',
			'Subject Tab',
			'Content Area',
			'Grade Level',
			'School Selection',
			'Date Range',
			'Timeframe Comparison',
		],
		type: WidgetType.DistrictPerformance,
	},
	{
		title: 'Demographic Performance',
		description: 'Average performance across schools in % based on race',
		labels: [
			'Boxplot',
			'Pie Chart',
			'Subject Tab',
			'Content Area',
			'Grade Level',
			'School Selection',
			'Date Range',
			'Timeframe Comparison',
			'Schools Comparison',
		],
		type: WidgetType.DemographicPerformance,
	},
	{
		title: 'Students Needing Support',
		description: 'Students most in need of support across schools or as a student list',
		labels: ['Subject Tab', 'Content Area', 'Grade Level', 'School Selection', 'Date Range', 'Timeframe Comparison'],
		type: WidgetType.LowestAchievement,
	},
];

export const widgetDateFormat = 'YYYY-MM-DD';
