import {PeriodType} from '../../types';
import {TabPeriods} from './types';

export const pastTabPeriods: TabPeriods = [
	PeriodType.Yesterday,
	PeriodType.PreviousWeek,
	PeriodType.PreviousMonth,
	PeriodType.PreviousMarkingPeriod,
	PeriodType.PreviousSelectedMarkingPeriod,
];

export const currentTabPeriods: TabPeriods = [
	PeriodType.Today,
	PeriodType.ThisWeek,
	PeriodType.ThisMonth,
	PeriodType.CurrentMarkingPeriod,
	PeriodType.CurrentSchoolYear,
];

export const datePickerTabPeriods: TabPeriods = [PeriodType.Manual];
