import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const Content = styled(FlexBox, {
	alignItems: 'center',
	alignContent: 'center',
	gap: 12,
	flexWrap: 'wrap',
});

export const ContentHeader = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	margin: '16px 0',
	padding: '20px 12px 12px 12px',
	borderBottom: '1px solid $border',
});

export const Body = styled(FlexBox, {
	flexDirection: 'column',
	maxWidth: 444,
	userSelect: 'none',
});