import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {AlertBody, AlertFooter, AlertHeader, alertRootCss, GroupNameBox} from './index.styled';
import {Button, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {Students} from '@esgi/ui/icons';
import {OneLinedText} from '@esgi/main/kits/common';
import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {specialistGroupsStore, studentsStore, SpecialistGroup} from '@esgi/main/libs/school-admin-store';
import {useCallback, useEffect, useState} from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {SpecialistGroupRemovedEvent} from '../../events';

type Props = {
	onRemoved: VoidFunction;
	onClose: VoidFunction;
	potentialRemovedSpecialistGroup: SpecialistGroup;
};

export function DeleteSpecialistGroupAlert({onClose, onRemoved, potentialRemovedSpecialistGroup}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const api = useEsgiApiGateway();

	const [isSpecialistGroupRemoving, setIsSpecialistGroupRemoving] = useState(false);

	const [deletedSpecialistGroupData, deleteSpecialistGroup] = useCancelableRequest(
		api.v2.schoolAdmins.modules.specialistGroups.remove,
	);

	useEffect(() => {
		if (isAsyncSucceed(deletedSpecialistGroupData)) {
			const groupsStore = specialistGroupsStore();
			const studentsStorage = studentsStore();

			groupsStore.remove((item) => item.id === potentialRemovedSpecialistGroup.id);

			studentsStorage.update((item) => {
				if (item.specialistGroupsIDs.includes(potentialRemovedSpecialistGroup.id)) {
					return {
						...item,
						specialistGroupsIDs: item.specialistGroupsIDs.filter((id) => id !== potentialRemovedSpecialistGroup.id),
					};
				}

				return item;
			});

			dispatchAppEvent(
				SpecialistGroupRemovedEvent,
				new SpecialistGroupRemovedEvent({
					id: potentialRemovedSpecialistGroup.id,
					name: potentialRemovedSpecialistGroup.name,
				}),
			);

			onClose();
			onRemoved();
			setIsSpecialistGroupRemoving(false);
		}
	}, [deletedSpecialistGroupData, onClose, onRemoved, potentialRemovedSpecialistGroup]);

	const removeSpecialistGroup = useCallback(() => {
		setIsSpecialistGroupRemoving(true);

		deleteSpecialistGroup({
			specialistGroupID: potentialRemovedSpecialistGroup.id,
		});
	}, [deleteSpecialistGroup, potentialRemovedSpecialistGroup]);

	return (
		<Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative} css={alertRootCss}>
			<AlertHeader withBacklight={false} onCloseIconClick={closeAlert}>
				<Text size='small'>Delete Specialist Group?</Text>

				<GroupNameBox>
					<Students />
					<OneLinedText size='small' color='highContrast'>
						{potentialRemovedSpecialistGroup.name}
					</OneLinedText>
				</GroupNameBox>
			</AlertHeader>

			<AlertBody>
				<Text size='small' color='highContrast'>
					Are you sure you want to delete the following specialist group?
				</Text>
				<Text size='small' color='highContrast'>
					This will NOT delete students and their data. The students still will be available and can be added to another
					specialist group.
				</Text>
			</AlertBody>

			<AlertFooter>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>

					<Button color='warn' onClick={removeSpecialistGroup} disabled={isSpecialistGroupRemoving}>
						<Text size='medium' bold color='negative'>
							Delete
						</Text>
					</Button>
				</GridBox>
			</AlertFooter>
		</Alert>
	);
}
