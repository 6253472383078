import {Dispatch, SetStateAction} from 'react';
import {DatePickerData} from '@esgi/main/kits/admin';
import {DemographicPerformanceWidgetUpdatedValue} from '../../../../types/demographic-performance';
import {Color} from '@esgi/main/features/admins/dashboard';

export enum BoxplotCompareVariant {
	None = 'None',
	SinglePeriod = 'SinglePeriod',
	CompareTwoPeriods = 'CompareTwoPeriods',
	CompareTwoClasses = 'CompareTwoClasses',
}

export type Period = {
	color: Color;
} & DatePickerData;

export type Class = {
	color: Color;
	classID: number;
};

export type DynamicFormName =
	| 'boxplotSinglePeriod'
	| 'boxplotCompareTwoPeriods'
	| 'boxplotCompareTwoClasses'
	| 'piecharts';

export type DynamicFormProps<P = unknown> = P & {
	setIsDynamicFormDataTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataValid: Dispatch<SetStateAction<boolean>>;
	onCurrentDynamicFormNameChange: Dispatch<DynamicFormName>;
};

export type CommonUpdatedValue = Pick<DemographicPerformanceWidgetUpdatedValue, 'id' | 'name'> & {
	options: Pick<
		DemographicPerformanceWidgetUpdatedValue['options'],
		| 'districtID'
		| 'schoolID'
		| 'globalSchoolYearID'
		| 'contentAreaIDs'
		| 'subjectIDs'
		| 'gradeLevelIDs'
		| 'showAvg'
		| 'viewType'
		| 'viewSize'
		| 'demographicGroup'
	>;
};

export type CommonSelectedArraysEntity = Pick<
	DemographicPerformanceWidgetUpdatedValue['selectedArraysEntity'],
	'contentAreaIDs' | 'gradeLevelIDs' | 'subjectIDs'
>;
