import {isNull} from 'underscore';
import {CardsSortBy} from '../../types/section';
import {ByAllStudentsTest} from '../../types/test-panel';
import {SortingConfig} from '../../utils/sort-test-cards';

export const sortTestsCardsConfig: SortingConfig<ByAllStudentsTest> = {
	[CardsSortBy.TestName]: {
		comparator: 'name',
	},
	[CardsSortBy.StudentTestedPercentage]: {
		comparator: (a, b) => {
			if (isNull(a.result)) {
				return 1;
			}

			if (isNull(b.result)) {
				return -1;
			}

			const aPercent = a.result.studentsTested / a.result.totalStudents;
			const bPercent = b.result.studentsTested / b.result.totalStudents;

			if (aPercent === bPercent) {
				return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
			}

			return aPercent - bPercent;
		},
	},
};
