import {DeployedSubject, isDeployedSubject, useSubjects} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';
import {TestResultsSubject} from '@esgi/main/kits/admin';
import {AdditionalStoreSubjectsFilter} from '../types';
import {isNull} from 'underscore';

type Parameters = {
	targetGradeLevels: number[];
	additionalStoreSubjectsFilter: AdditionalStoreSubjectsFilter | null;
	userID: number | null;
};

type Out = [boolean, TestResultsSubject[]];

export function useSubjectsFromStore({targetGradeLevels, additionalStoreSubjectsFilter, userID}: Parameters): Out {
	const [{data: subjects, loaded: isSubjectsLoaded}] = useSubjects();

	return useMemo<Out>(() => {
		if (!isSubjectsLoaded) {
			return [false, []];
		}

		const filteredDeployedSubjects = subjects.filter((item): item is DeployedSubject => {
			if (isDeployedSubject(item)) {
				const {isPublished, tests, hidden, gradeLevels, allowedToAllTeachers, allowedSpecialists} = item;

				const filterByAllowedSchools =
					additionalStoreSubjectsFilter === 'checkOnAllowedToAllTeachers' && !isNull(userID)
						? allowedToAllTeachers
						: true;

				const filterByAllowedSpecialists =
					additionalStoreSubjectsFilter === 'userIDAsSpecialist' && !isNull(userID)
						? allowedSpecialists.includes(userID)
						: true;

				return (
					isPublished &&
					Boolean(tests.length) &&
					!hidden &&
					gradeLevels.some((gradeLevel) => targetGradeLevels.includes(gradeLevel)) &&
					filterByAllowedSchools &&
					filterByAllowedSpecialists
				);
			}

			return false;
		});

		const activeSubjects = filteredDeployedSubjects.map<TestResultsSubject>(
			({id, name, subjectType, level, gradeLevels}) => ({
				id,
				name,
				type: subjectType,
				level,
				gradeLevels,
			}),
		);

		return [true, activeSubjects];
	}, [additionalStoreSubjectsFilter, isSubjectsLoaded, subjects, targetGradeLevels, userID]);
}
