import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {TabContent} from './index.styled';
import {TabInfo} from '../../../../../tabs';
import {PanelMode, TabID} from '../../../../types/section';
import {usePanelContext} from '../../../../context';

type Props = BaseComponentProps & Omit<ComponentPropsWithoutRef<'div'>, 'disabled'> & Omit<TabInfo<TabID>, 'disabled'>;

export const Root = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'tab-content-root', css = {}, tabID, tabContent, children, ...boxProps}, forwardedRef) => {
		const {panelMode} = usePanelContext();

		return (
			<TabContent
				dataCy={dataCy}
				css={css}
				tabID={tabID}
				tabContent={tabContent}
				disabled={panelMode === PanelMode.Rearrange}
				ref={forwardedRef}
				{...boxProps}
			>
				{children}
			</TabContent>
		);
	},
);
