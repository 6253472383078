import {useMemo} from 'react';
import {unique} from 'underscore';
import {useDisposable, useEndpointResult} from '@esgi/core/service';
import {V2SchoolAdminsPagesDataAnalyticsController, V2SchoolAdminsUserAccountController} from '@esgi/contracts/esgi';
import {RowDef} from './types';
import {useClasses, useGroups, useTeachers} from '@esgi/main/libs/school-admin-store';

export function useData() {
	const [{data: allTeachers}] = useTeachers();
	const [{data: allClasses}] = useClasses();
	const [{data: groups, loaded: isGroupsLoaded}] = useGroups();

	const analyticController = useDisposable(V2SchoolAdminsPagesDataAnalyticsController);
	const analyticsData = useEndpointResult(() => {
		if(isGroupsLoaded) {
			return analyticController.groups({groupIDs: groups.map(g => g.id)});
		}
	}, [groups, isGroupsLoaded]);

	const userAccountController = useDisposable(V2SchoolAdminsUserAccountController);

	const userControllerData = useEndpointResult(() => {
		if (isGroupsLoaded) {
			return userAccountController.init();
		}
	}, [groups, isGroupsLoaded]);

	const schoolName = useMemo(() => {
		return userControllerData?.user.schoolName;
	}, [userControllerData]);

	const {rows, classes, teachers} = useMemo(() => {
		const rows = groups.map((group) => {
			const teacher = allTeachers.find(t => t.id === group.teacherID);
			const classEntity = allClasses.find(c => c.id === group.classID);
			const analytic = analyticsData?.value?.groupsAnalytics?.find(a => a.groupID === group.id);

			return {
				id: group.id,
				name: group.name,
				teacher,
				class: classEntity,
				students: analytic?.studentsCount,
			} as RowDef;
		});
		return {
			rows,
			classes: unique(rows.map(r => r.class)).filter(Boolean),
			teachers: unique(rows.map(r => r.teacher)).filter(Boolean),
		};
	}, [groups, allTeachers, allClasses, analyticsData]);

	return {
		ready: isGroupsLoaded,
		rows,
		teachers,
		schoolName,
		classes,
	};
}