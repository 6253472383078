import {ContextMenu} from '@esgi/ui';
import {ComponentProps, useCallback, useMemo, useState} from 'react';
import {ContextMenuMenuContext, ContextMenuMenuContextValue} from '../context';

type Props = Omit<ComponentProps<typeof ContextMenu.Root>, 'open'>;

export function Root({onOpenChange, ...props}: Props) {
	const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);

	const handleOpenChange = useCallback(
		(value: boolean) => {
			setIsContextMenuOpened(value);
			onOpenChange?.(value);
		},
		[onOpenChange],
	);

	const context = useMemo<ContextMenuMenuContextValue>(
		() => ({
			setIsContextMenuOpened: handleOpenChange,
		}),
		[handleOpenChange],
	);

	return (
		<ContextMenuMenuContext.Provider value={context}>
			<ContextMenu.Root open={isContextMenuOpened} onOpenChange={handleOpenChange} {...props} />
		</ContextMenuMenuContext.Provider>
	);
}
