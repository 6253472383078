import {ExpandablePanelContent, ExpandablePanelRoot, NavigationItem, SectionBox, Title} from './styled';
import {Text} from '@esgi/ui/typography';
import {
	AppliedTo,
	Users,
	User,
	File,
	StudentProgress,
	StudentCard,
	Reports,
	FieldConnector,
	RubricReport,
} from '@esgi/ui/icons';
import {ExpandablePanel, GridBox} from '@esgi/ui/layout';
import {useLocation, useNavigate} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
import {Tooltip} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {routes} from '@esgi/main/libs/core';
import {InvalidTrackAlert} from '../invalid-track-alert';
import {ReportValidator} from '../../service';
import {useService} from '@esgi/core/service';

export function ReportsPanel() {
	const currentUser = useUser();

	const navigate = useNavigate();
	const {pathname} = useLocation();

	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(pathname === routes.teacher.reports.root);
	}, [pathname]);

	const navigateToActivity = useCallback(
		(path: string) => {
			navigate(path);
		},
		[navigate],
	);

	const [isOpenReportValidationAlert, setIsOpenReportValidationAlert] = useState(false);
	const [schoolYearName, setSchoolYearName] = useState('');
	const service = useService(ReportValidator);
	const navigateWithValidation = useCallback(
		(path: string) => {
			service.validate()
				.subscribe((resp) => {
					const result = resp.value;
					if (result.isValid) {
						navigate(path);
						return;
					}

					setIsOpenReportValidationAlert(true);
					setSchoolYearName(result.schoolYearName);
				});
		},
		[navigate, service],
	);

	const handleCloseAlert = useCallback(() => setIsOpenReportValidationAlert(false), []);

	const handlePanelClick = useCallback(() => {
		if (!show) {
			navigate(routes.teacher.reports.root);
		}
	}, [navigate, show]);

	return (
		<ExpandablePanelRoot open={show} onClick={handlePanelClick} collapsed={!show} css={{zIndex: 1}}>
			{({panelIsHovered, open}) => {
				return (
					<>
						{isOpenReportValidationAlert && (
							<InvalidTrackAlert
								close={handleCloseAlert}
								schoolYearName={schoolYearName}
							/>
						)}
						{!open && <ExpandablePanel.Trigger />}
						<ExpandablePanel.Placeholder>
							<SectionBox fillColor={panelIsHovered ? 'secondary' : 'primary'}>
								<AppliedTo data-cy='activities-icon' width={24} height={24} />
							</SectionBox>
						</ExpandablePanel.Placeholder>
						<ExpandablePanelContent dataCy='student-reports-panel'>
							<OverlayScrollbarsComponent
								defer
								options={{scrollbars: {autoHide: 'leave'}}}
								style={{height: '100%'}}
							>
								<Title>
									<User width={24} height={24} />
									<Text size='small' color='neutral56'>
										Student Reports
									</Text>
								</Title>
								<GridBox flow='row' gapY='2'>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												dataCy='navigation-item-parent-letter'
												onClick={() => navigateToActivity('/reports/parent-letter')}
											>
												<File />
												<Text size='large'>Parent Letter</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											Print student results with personalized messaging for parent review
										</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												dataCy='navigation-item-student-detail'
												onClick={() => navigateWithValidation(routes.teacher.reports.studentDetail)}
											>
												<StudentCard />
												<Text size='large'>Student Detail</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											Comprehensive breakdown of {currentUser?.testResultsCorrectVerbiage ?? 'correct'}/
											{currentUser?.testResultsIncorrectVerbiage ?? 'incorrect'} items, scoring, and student progress
											over time
										</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												dataCy='navigation-item-student-progress'
												onClick={() => navigateWithValidation(routes.teacher.reports.studentProgress)}
											>
												<StudentProgress />
												<Text size='large'>Student Progress</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											View student progress as raw scores, percentages, or grades
										</Tooltip.Content>
									</Tooltip>
								</GridBox>
								<Title css={{marginTop: 40}}>
									<Users width={24} height={24} />
									<Text size='small' color='neutral56'>
										Class Reports
									</Text>
								</Title>
								<GridBox flow='row' gapY='2'>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												dataCy='navigation-item-class-grades'
												onClick={() => navigateToActivity('/reports/class-grades')}
											>
												<Users />
												<Text size='large'>Class Grades</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											Apply customizable grade scales to student scores
										</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												dataCy='navigation-item-class-totals'
												onClick={() => navigateToActivity('/reports/class-total')}
											>
												<Users />
												<Text size='large'>Class Totals</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>
											Review and compare student scores for a subject tab
										</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												dataCy='navigation-item-item-analysis'
												onClick={() => navigateToActivity('/reports/item-analysis')}
											>
												<Reports />
												<Text size='large'>Item Analysis</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>Bar chart based on least and most known items</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												dataCy='navigation-item-pie-charts'
												onClick={() => navigateToActivity('/reports/pie-charts')}
											>
												<FieldConnector />
												<Text size='large'>Pie Charts</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>Printable report of homepage pie charts</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												dataCy='navigation-item-rubric-results'
												onClick={() => navigateWithValidation(routes.teacher.reports.rubric)}
											>
												<RubricReport />
												<Text size='large'>Rubric Results</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>Rubric results</Tooltip.Content>
									</Tooltip>
									<Tooltip delayDuration={400}>
										<Tooltip.Trigger>
											<NavigationItem
												dataCy='navigation-item-untested-students'
												onClick={() => navigateToActivity(routes.teacher.reports.untestedStudents)}
											>
												<Users />
												<Text size='large'>Untested Students</Text>
											</NavigationItem>
										</Tooltip.Trigger>
										<Tooltip.Content variant='default'>Identify untested students to administer testing</Tooltip.Content>
									</Tooltip>
								</GridBox>
							</OverlayScrollbarsComponent>
						</ExpandablePanelContent>
					</>
				);
			}}
		</ExpandablePanelRoot>
	);
}
