import {map} from 'rxjs';
import {Api} from '@esgi/contracts/esgi';
import {TeachersBucket, SpecialistsBucket} from './types';
import {createStoreSlice} from '../slice-utils';
import {BucketApi} from './utils';

/*Teachers bucket*/

const teachersBucket = new BucketApi<TeachersBucket>(() => new Api().v2.schoolAdmins.storage.teachersBucket().pipe(map(v => v.value)));

export const teachersSlice = createStoreSlice(
	'teachers',
	(invalidateCache) => teachersBucket.fetch('teachers', invalidateCache),
);

export const classesSlice = createStoreSlice(
	'classes',
	(invalidateCache) => teachersBucket.fetch('classes', invalidateCache),
);

export const groupsSlice = createStoreSlice(
	'groups',
	(invalidateCache) => teachersBucket.fetch('groups', invalidateCache),
);

/*Specialists bucket*/

const specialistsBucket = new BucketApi<SpecialistsBucket>(() => new Api().v2.schoolAdmins.storage.specialistsBucket().pipe(map(v => v.value)));

export const schoolSpecialistsSlice = createStoreSlice(
	'schoolSpecialists',
	(invalidateCache) => specialistsBucket.fetch('schoolSpecialists', invalidateCache),
);

export const specialistGroupsSlice = createStoreSlice(
	'specialistGroups',
	(invalidateCache) => specialistsBucket.fetch('specialistGroups', invalidateCache),
);