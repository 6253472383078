import {DemographicPerformance} from './variant/demographic-performance';
import {DistrictPerformance} from './variant/district-performance';
import {LowestAchievement} from './variant/lowest-achievement';

export const DistrictAdminWidget = {
	DistrictPerformance,
	DemographicPerformance,
	LowestAchievement,
};

export * from './types/widget';
export * from './types/district-performance';
export * from './types/demographic-performance';
export * from './types/lowest-achievement';
export * from './types/school-admin-widgets';
