import {Box} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const CheapContent = styled(Box, {
	all: 'unset',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingTop: 1,
	paddingBottom: 1,
	paddingInline: 3,
	borderColor: '$surface',
	borderStyle: 'solid',
	borderWidth: 1,
	borderRadius: 5,
	userSelect: 'none',

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
			},
		},
	},
});
