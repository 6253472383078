import {ContextMenu, Tooltip} from '@esgi/ui';
import {History} from '@esgi/ui/icons';
import {ButtonIconable} from '../../../header/components/button-iconable.styled';
import {AllSessionsItem, ContextMenuItem} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {TestSession} from '../../../../../../types';
import {TimeStamp} from '../../../../../../kit';
import {AnswersInfo} from '../../../answers-info';
import {Box, GridBox} from '@esgi/ui/layout';
import {MouseEvent, useCallback, useState} from 'react';
import {isNull} from 'underscore';
import {TestSessionDetailsAlert} from '@esgi/main/features/teacher/test-session-details';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {useTestSectionContext} from '../../../../context/test-section-context';

const maxVisibleSessions = 4;

type Props = {
	lastTestSessions: TestSession[];
	sessionsCount: number;
	maxScore: number;
	subjectID: SubjectTab['id'];
	classID: Class['id'];
	testID: number;
};

export function SessionsContextMenu({
	maxScore,
	sessionsCount,
	lastTestSessions,
	subjectID,
	classID,
	testID,
}: Props) {
	const {allSessionsClick} = useTestSectionContext();

	const [isContextMenuOpened, setIsContextMenuOpened] = useState(false);

	const [openedTestSessionDetailsID, setOpenedTestSessionDetailsID] = useState<number | null>(null);

	const onCloseTestSessionDetailsAlert = useCallback(() => {
		setOpenedTestSessionDetailsID(null);
	}, []);

	const handleSessionClick = useCallback((event: MouseEvent<HTMLDivElement>, sessionID: number) => {
		event.stopPropagation();

		setIsContextMenuOpened(false);

		setOpenedTestSessionDetailsID(sessionID);
	}, []);

	return (
		<Box
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			<ContextMenu.Root open={isContextMenuOpened} onOpenChange={setIsContextMenuOpened} data-cy='sessions-menu'>
				<ContextMenu.Trigger asChild={false} dataCy='sessions-menu-trigger'>
					<Tooltip delayDuration={400}>
						<Tooltip.Trigger>
							<ButtonIconable withBackgroundHover disabled={!lastTestSessions.length}>
								<History />
							</ButtonIconable>
						</Tooltip.Trigger>
						<Tooltip.Content>
							<Text size='small'>Test Session Details</Text>
						</Tooltip.Content>
					</Tooltip>
				</ContextMenu.Trigger>
				<ContextMenu.Content>
					<ContextMenu.Group>
						{lastTestSessions.slice(0, maxVisibleSessions).map(({id, testDate, correctAnswers}) => (
							<ContextMenuItem key={id} dataCy='sessions-menu-item' onClick={(event) => handleSessionClick(event, id)}>
								<GridBox flow='column' gap='4' align='center'>
									<TimeStamp dateTime={testDate} />
									<AnswersInfo value={correctAnswers} maxValue={maxScore} />
								</GridBox>
							</ContextMenuItem>
						))}
						{sessionsCount > maxVisibleSessions && (
							<AllSessionsItem data-cy='all-sessions-button' onClick={() => allSessionsClick({testID})}>
								<History />
								<Text size='small'>
									All Sessions...
								</Text>
							</AllSessionsItem>
						)}
					</ContextMenu.Group>
				</ContextMenu.Content>
			</ContextMenu.Root>
			{!isNull(openedTestSessionDetailsID) && (
				<TestSessionDetailsAlert
					testSessionID={openedTestSessionDetailsID}
					onAlertClose={onCloseTestSessionDetailsAlert}
					classID={classID}
					subjectID={subjectID}
				/>
			)}
		</Box>
	);
}
