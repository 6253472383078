import {useDisposable} from '@esgi/core/service';
import {V2SchoolAdminsPagesDataStateController} from '@esgi/contracts/esgi';
import {useEffect, useState, useCallback} from 'react';
import {StateEntity} from '../types/navigation-states';
import {useLocationState} from './use-navigate-to-data-page';
import {GridState} from 'ag-grid-community';
import {DataPageState} from '@esgi/contracts/esgi/types/sso.accounts/models/user-page-states/admins/data-page-state';
import {Column} from '@esgi/contracts/esgi/types/sso.accounts/models/user-page-states/admins/column';
import {UpdateStateRequest} from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/school-admins/pages/data/update-state-request';
import {isAsyncFailed, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';

const getHiddenColumns = (tableName: string, state: DataPageState) => {
	const table = state.tables.find((table) => table.name === tableName);
	if (!table) {
		return [];
	}

	return table.columns.reduce<string[]>((hiddenColumns, column) => {
		if (!column.isEnabled) {
			hiddenColumns.push(column.name);
		}
		return hiddenColumns;
	}, []);
};

export function useDataPageState<T>(tableName: string) {
	const pagesDataStateController = useDisposable(
		V2SchoolAdminsPagesDataStateController
	);
	const [pagesDataState, getPagesDataState] = useCancelableRequest(
		pagesDataStateController.init
	);
	const [, updatePagesDataState] = useCancelableRequest(
		pagesDataStateController.update
	);
	const locationState = useLocationState<StateEntity<T>>();

	const [isInitialStateReady, setIsInitialStateReady] = useState(false);
	const [initialState, setInitialState] = useState<GridState>(() => {
		return {
			filter: locationState && {
				filterModel: locationState,
			},
			columnVisibility: {
				hiddenColIds: [],
			},
		};
	});

	const setHiddenColums = useCallback(
		(state: DataPageState) => {
			setInitialState((currentInitialState) => {
				return {
					...currentInitialState,
					columnVisibility: {
						hiddenColIds: getHiddenColumns(tableName, state),
					},
				};
			});
		},
		[tableName]
	);

	const onApplyTableCustomization = useCallback(
		(columns: Column[]) => {
			const updateStateRequest: UpdateStateRequest = {
				state: {tables: [{name: tableName, columns}]},
			};

			setHiddenColums(updateStateRequest.state);
			updatePagesDataState(updateStateRequest);
		},
		[setHiddenColums, tableName, updatePagesDataState]
	);

	useEffect(() => {
		if (isAsyncSucceed(pagesDataState)) {
			if (pagesDataState.data.state?.tables?.length) {
				setHiddenColums(pagesDataState.data.state);
			}
			setIsInitialStateReady(true);
		}
		if (isAsyncFailed(pagesDataState)) {
			setIsInitialStateReady(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pagesDataState]);

	useEffect(() => {
		getPagesDataState();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isInitialStateReady,
		initialState,
		onApplyTableCustomization,
	};
}
