import {useDisposable} from '@esgi/core/service';
import {useUser} from '@esgi/core/authentication';
import {V2DistrictAdminsFoldersSchoolsController, V2DistrictAdminsFoldersUsersController} from '@esgi/contracts/esgi';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useCallback, useEffect} from 'react';
import {FolderType} from '../types';
import {isNull} from 'underscore';
import {userGroupTypeByFolderType} from '../constants';
import {dispatchAppEvent} from '@esgillc/events';
import {FolderCreatedEvent} from '../events';

type Parameters = {
	type: FolderType;
	onFolderCreated?: VoidFunction;
};

export function useCreateFolder({type, onFolderCreated}: Parameters) {
	const currentUser = useUser();

	const foldersSchoolsController = useDisposable(V2DistrictAdminsFoldersSchoolsController);
	const foldersUsersController = useDisposable(V2DistrictAdminsFoldersUsersController);

	const [createSchoolFolderData, createSchoolFolder] = useCancelableRequest(foldersSchoolsController.create);
	const [createUserFolderData, createUserFolder] = useCancelableRequest(foldersUsersController.create);

	useEffect(() => {
		if (isAsyncSucceed(createSchoolFolderData)) {
			onFolderCreated?.();

			dispatchAppEvent(
				FolderCreatedEvent,
				new FolderCreatedEvent({
					type: FolderType.Schools,
					id: createSchoolFolderData.data.groupID,
				}),
			);
		}
	}, [createSchoolFolderData, onFolderCreated]);

	useEffect(() => {
		if (isAsyncSucceed(createUserFolderData)) {
			onFolderCreated?.();

			dispatchAppEvent(
				FolderCreatedEvent,
				new FolderCreatedEvent({
					type,
					id: createUserFolderData.data.groupID,
				}),
			);
		}
	}, [createUserFolderData, onFolderCreated, type]);

	return useCallback(
		({entityIDs, name}: {entityIDs: number[]; name: string}) => {
			if (isNull(currentUser)) {
				return;
			}

			if (type === FolderType.Schools) {
				createSchoolFolder({
					userID: currentUser.userID,
					name,
					schoolIDs: entityIDs,
				});

				return;
			}

			createUserFolder({
				userID: currentUser.userID,
				//@ts-ignore
				groupType: userGroupTypeByFolderType[type],
				name,
				userIDs: entityIDs,
			});
		},
		[createSchoolFolder, createUserFolder, currentUser, type],
	);
}
