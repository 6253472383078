import {isNull} from 'underscore';
import {
	Color,
	GradeLevel,
	ColorNumerable,
	PeriodType,
	PeriodTypeNumerable,
	ContentAreaModel,
} from '@esgi/main/features/admins/dashboard';
import {
	ClassModel,
	SchoolStudentsNeedingSupportWidgetOptions,
	SchoolStudentsNeedingSupportWidgetOptionsContract,
	SchoolStudentsNeedingSupportWidgetViewType,
	SchoolStudentsNeedingSupportWidgetViewTypeNumerable,
	SubjectTab,
	TeacherModel,
} from '@esgi/main/features/school-admin/dashboard';
import {enumNumberToString} from '../enum-number-to-string';
import {getAdaptedArraysWidgetData} from '../../helpers/get-adapted-arrays-widget-data';

export function parseSchoolStudentsNeedingSupportOptions({
	options,
	classes,
	teachers,
	subjectTabs,
	gradeLevels,
	contentAreasList,
}: {
	options: SchoolStudentsNeedingSupportWidgetOptionsContract | null;
	classes: ClassModel[];
	teachers: TeacherModel[];
	subjectTabs: SubjectTab[];
	gradeLevels: GradeLevel[];
	contentAreasList: ContentAreaModel[];
}): SchoolStudentsNeedingSupportWidgetOptions | null {
	if (isNull(options)) {
		return null;
	}

	const {contentAreaIDs, gradeLevelIDs, classIDs, teachersIDs, subjectIDs, viewType, periods, ...restOptions} = options;

	return {
		...restOptions,
		/**
		 * For the following entities, if the data array is empty, it means all entities are selected:
		 * - contentAreaIDs,
		 * - gradeLevelIDs,
		 * - classIDs,
		 * - teacherIDs
		 * - subjectIDs
		 */
		contentAreaIDs: getAdaptedArraysWidgetData({
			data: contentAreaIDs,
			fullData: contentAreasList,
			iteratedKey: 'id',
		}),
		gradeLevelIDs: getAdaptedArraysWidgetData({
			data: gradeLevelIDs,
			fullData: gradeLevels,
			iteratedKey: 'id',
		}),
		classIDs: getAdaptedArraysWidgetData({
			data: classIDs,
			fullData: classes,
			iteratedKey: 'classID',
		}),
		teachersIDs: getAdaptedArraysWidgetData({
			data: teachersIDs,
			fullData: teachers,
			iteratedKey: 'id',
		}),
		subjectIDs: getAdaptedArraysWidgetData({
			data: subjectIDs,
			fullData: subjectTabs,
			iteratedKey: 'id',
		}),
		viewType: enumNumberToString(
			viewType,
			SchoolStudentsNeedingSupportWidgetViewTypeNumerable,
		) as SchoolStudentsNeedingSupportWidgetViewType,
		periods: periods.map(({color, datePeriod, dateRange, trackDatesID}) => ({
			trackDatesID,
			dateRange: isNull(dateRange) ? null : dateRange,
			datePeriod: enumNumberToString(datePeriod, PeriodTypeNumerable) as PeriodType,
			color: enumNumberToString(color, ColorNumerable) as Color,
		})),
	};
}
