import {School, useFlag} from '@esgi/ui';
import {RunReportButton} from '../run-report-button';
import {ActionPanel} from '../action-panel';
import {Text} from '@esgi/ui/typography';
import {SchoolReportsDialog} from '../school-reports-dialog';
import {GridApi} from 'ag-grid-community';
import {CollapsiblePanel} from '@esgi/main/features/admins/data';
import {HeaderContent} from './index.styled';
import {useEffect, useState} from 'react';
import {isNull} from 'underscore';

type Props<T extends Record<string, unknown>> = {
	api: GridApi<T> | null;
	schoolName: string;
	totalStudents: number;
};

export function SchoolReportPanel<T extends Record<string, unknown>>({schoolName, api, totalStudents}: Props<T>) {
	const [showSchoolReports, openSchoolReports, closeSchoolReports] = useFlag();
	const [showPanel, openPanel, closePanel] = useFlag();

	const [selectedItemsCount, setSelectedItems] = useState(0);

	const managerRef = CollapsiblePanel.useRef();
	const closeActionPanel = CollapsiblePanel.useClose(managerRef, () => {
		closePanel();
	});

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		const handler = () => {
			const selectedRows = api.getSelectedRows();
			setSelectedItems(selectedRows?.length || 0);
		};

		api.addEventListener('selectionChanged', handler);

		return () => api.removeEventListener('selectionChanged', handler);
	}, [api]);

	useEffect(() => {
		if (!selectedItemsCount) {
			openPanel();
			return;
		}

		closeActionPanel();
	}, [closeActionPanel, openPanel, selectedItemsCount]);

	if (!showPanel) {
		return null;
	}

	return (
		<>
			<CollapsiblePanel.Root managerRef={managerRef}>
				<CollapsiblePanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<School />
							<Text size='large' color='highContrast'>
								{schoolName}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<RunReportButton
								title='School Reports'
								onClick={openSchoolReports}
								disabled={!totalStudents}
								disabledMessage={
									totalStudents
										? null
										: 'No students have been added to your school. Please add some before running the report.'
								}
							/>
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</CollapsiblePanel.Header>
			</CollapsiblePanel.Root>

			{showSchoolReports && <SchoolReportsDialog onClose={closeSchoolReports} />}
		</>
	);
}
