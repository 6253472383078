import {createContext, useContext} from 'react';

export type BarChartContextValue = {
	getValueProgress: (value: number) => number;
};

export const BarChartContext = createContext<BarChartContextValue>({
	getValueProgress: () => 0,
});

export function useBarChartContext() {
	return useContext(BarChartContext);
}
