import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RowDef} from '../../../../types';
import {ReportDialog, Reports} from '../../../../../_kit';

type Props = {
	group: RowDef,
	onClose: VoidFunction,
}

export function ReportsFeature({group, onClose}: Props) {
	const hierarchy = useMemo<Hierarchy>(() => ({
		specialistsID: group.specialist.id,
		specialistGroupID: group.id,
	}), [group]);

	return (
		<ReportDialog
			onClose={onClose}
			header={group.name}
			title='Student and Specialist Group Reports'
		>
			<Reports.ParentLetter hierarchy={hierarchy}/>
			<Reports.StudentDetail hierarchy={hierarchy}/>
			<Reports.StudentProgress hierarchy={hierarchy}/>
			<Reports.Totals prefix='Specialist Group' hierarchy={hierarchy}/>
			<Reports.GradeScale prefix='Specialist Group' hierarchy={hierarchy}/>
			<Reports.ItemAnalysis hierarchy={hierarchy}/>
			<Reports.PieCharts hierarchy={hierarchy}/>
			<Reports.RubricResults hierarchy={hierarchy}/>
			<Reports.UntestedStudents hierarchy={hierarchy}/>
		</ReportDialog>
	);
}