import {IRowNode} from 'ag-grid-community';

export type PossibleEntity = {
	id: number | string;
};

export enum CellType {
	Primary = 'Primary',
	RightAligned = 'RightAligned',
	Numeric = 'Numeric',
}

export type ExternalFilter = {
	isExternalFilterPresent: () => boolean;
	doesExternalFilterPass: (node: IRowNode) => boolean;
};

export type ExternalFilterState = ExternalFilter & {
	id: string;
};
