import {BehaviorSubject, forkJoin, map, tap} from 'rxjs';
import {isUndefined} from '@esgi/ui';
import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';
import {V2SchoolAdminsUserAccountController, V2TracksController} from '@esgi/contracts/esgi';
import {NewPhotoContract, SavePersonalDataBody, SchoolYearModel, TrackModel, UserCountry, UserModel, UserPhoto, UserState} from './types';

export class UserAccountService extends BaseService {
	public schoolYearTypes$ = new BehaviorSubject<SchoolYearModel[] | null>(null);

	public user$ = new BehaviorSubject<UserModel | null>(null);
	public countries$ = new BehaviorSubject<UserCountry[] | null>(null);
	public states$ = new BehaviorSubject<UserState[] | null>(null);

	public activeUserTrackId$ = new BehaviorSubject<TrackModel['trackID'] | null>(null);
	public tracks$ = new BehaviorSubject<TrackModel[] | null>(null);

	public isUserDataLoading$ = new BehaviorSubject(false);

	private controller = new V2SchoolAdminsUserAccountController();
	private tracksController = new V2TracksController();

	public init() {
		return this.controller.init().pipe(
			tap(({user, countries, states, tracksInfo: {selectedTrackID, tracks, schoolYearTypes}}) => {
				const currentTrack = tracks.find(({trackID}) => trackID === selectedTrackID) ?? tracks[0];

				if (isUndefined(currentTrack)) {
					throw new Error('Current Track is not existed');
				}

				this.user$.next(user as UserModel);
				this.countries$.next(countries);
				this.states$.next(states);

				this.activeUserTrackId$.next(selectedTrackID);
				this.tracks$.next(tracks as TrackModel[]);

				this.schoolYearTypes$.next(schoolYearTypes);
			}),
		);
	}

	public setActiveTrackId(trackId: TrackModel['trackID']) {
		this.tracksController.setAsActive({trackID: trackId})
			.pipe(tap(() => {
				this.activeUserTrackId$.next(trackId);
			})).subscribe();
	}

	public savePersonalData({countryID, newPhoto, ...body}: SavePersonalDataBody) {
		this.isUserDataLoading$.next(true);

		const currentUserValue = this.user$.value;

		forkJoin([
			this.controller.tabsPersonalSave({...body, newPhoto: newPhoto as NewPhotoContract}),
			this.getStatesByCountryID(countryID),
		]).subscribe(([{avatarUrl, avatarCropUrl}, states]) => {
			if (currentUserValue) {
				let photo: UserPhoto | null = currentUserValue.photo;

				if (newPhoto?.remove === true) {
					photo = null;
				}

				if (newPhoto?.remove === false) {
					const {crop} = newPhoto;

					photo = {
						imageUrl: avatarUrl,
						imageCropUrl: avatarCropUrl,
						crop,
					};
				}

				this.states$.next(states);
				this.user$.next({
					...currentUserValue,
					...body,
					photo,
					countryID,
				});


				userStorage.update({
					firstName: body.firstName,
					lastName: body.lastName,
					stateID: Number(body.stateID),
					avatarUrl: photo?.imageCropUrl ?? null,
				});

				this.isUserDataLoading$.next(false);
			}
		});
	}

	public getStatesByCountryID(countryID: UserCountry['countryID']) {
		return this.httpClient.ESGIApi.get<{id: number; name: string}[]>('profiles/common', 'get-states-by-country-id', {
			countryID,
		}).pipe(map((response) => response.map<UserState>(({id, name}) => ({stateID: id, name}))));
	}

	public override dispose() {
		this.controller.dispose();
		this.tracksController.dispose();
	}
}
