import {useCallback, useState} from 'react';
import {useColumnDefinitions} from './use-column-definitions';
import {useData} from './use-data';
import {Base} from './variant/base';
import {ClassDrawer, useDataPageState} from '@esgi/main/features/school-admin/data';
import {Page} from '../_kit';

export default function () {
	const entityName = 'Classes';
	const {ready, rows, groups, teachers} = useData();
	const colDefs = useColumnDefinitions(rows, teachers, groups);
	const [isOpenAddClassDrawer, setIsOpenAddClassDrawer] = useState(false);
	const {isInitialStateReady, initialState, onApplyTableCustomization} = useDataPageState(entityName);

	const closeAddClassDrawer = useCallback(() => {
		setIsOpenAddClassDrawer(false);
	}, []);

	const openAddClassDrawer = useCallback(() => {
		setIsOpenAddClassDrawer(true);
	}, []);

	return (
		<Page.Root
			columnDefs={colDefs}
			entityName={entityName}
			tableRows={rows}
			isDataLoaded={ready && isInitialStateReady}
			initialState={initialState}
			onApplyTableCustomization={onApplyTableCustomization}
		>
			<Base openAddClassDrawer={openAddClassDrawer} />
			{isOpenAddClassDrawer && (
				<ClassDrawer.Add onClose={closeAddClassDrawer} />
			)}
		</Page.Root>
	);
}
