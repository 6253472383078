import {SubjectType, TestContentArea, TestType} from '@esgi/main/kits/common';

export enum AnswerState {
	Incorrect = 'Incorrect',
	Correct = 'Correct',
	NotTested = 'NotTested',
}

export enum TestScreenType {
	None = 'None',
	OneToOne = 'OneToOne',
	SelfAssessment = 'SelfAssessment',
}

export type Test = {
	id: number;
	contentArea: TestContentArea;
	name: string;
	type: TestType;
	color: string;
	maxScore: number;
};

export type Student = {
	id: number;
	firstName: string;
	lastName: string;
	photoUrl: string | null;
};

export type Subject = {
	id: number;
	name: string;
	type: SubjectType;
};
