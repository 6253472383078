import {Widget} from './widget';
import {SchoolStudentsNeedingSupportWidgetOptions as SchoolStudentsNeedingSupportWidgetOptionsContract} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/school-students-needing-support-widget-options';
import {SchoolStudentsNeedingSupportStudentData as SchoolStudentsNeedingSupportStudentDataContract} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/metrics/school-admin/school-students-needing-support-student-data';
import {SchoolStudentsNeedingSupportMetricData as SchoolStudentsNeedingSupportMetricDataContract} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/metrics/school-admin/school-students-needing-support-metric-data';
import {Teacher, WidgetPeriod} from '@esgi/main/features/admins/dashboard';

export {SchoolStudentsNeedingSupportWidgetViewType as SchoolStudentsNeedingSupportWidgetViewTypeNumerable} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/school-students-needing-support-widget-view-type';
export type {SchoolStudentsNeedingSupportClassData} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/metrics/school-admin/school-students-needing-support-class-data';

export {type SchoolStudentsNeedingSupportWidgetOptionsContract, type SchoolStudentsNeedingSupportMetricDataContract};

export enum SchoolStudentsNeedingSupportWidgetViewType {
	None = 'None',
	Students = 'Students',
	Classes = 'Classes',
}

export type SchoolStudentsNeedingSupportWidgetOptions = Omit<
	SchoolStudentsNeedingSupportWidgetOptionsContract,
	'viewType' | 'periods'
> & {
	viewType: SchoolStudentsNeedingSupportWidgetViewType;
	periods: WidgetPeriod[];
};

export type SchoolStudentsNeedingSupportStudentData = Omit<
	SchoolStudentsNeedingSupportStudentDataContract,
	'teacher'
> & {
	teacher: Teacher | null;
};

export type SchoolStudentsNeedingSupportMetricData = Omit<
	SchoolStudentsNeedingSupportMetricDataContract,
	'studentsData'
> & {
	studentsData: SchoolStudentsNeedingSupportStudentData[];
};

type SchoolStudentsNeedingSupportSelectableEntities = Pick<
	SchoolStudentsNeedingSupportWidgetOptions,
	'classIDs' | 'teachersIDs' | 'contentAreaIDs' | 'subjectIDs' | 'gradeLevelIDs'
>;

export type SchoolStudentsNeedingSupportWidgetUpdatedValue = Pick<Widget, 'id' | 'name'> & {
	options: SchoolStudentsNeedingSupportWidgetOptions;
	selectedArraysEntity: Record<keyof SchoolStudentsNeedingSupportSelectableEntities, 'all' | 'part'>;
};
