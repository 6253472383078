import {Root} from './components/root';
import {TabContent} from './components/tab-content';
import {TabsBar} from './components/tabs-bar';

export const Tabs = {
	Root,
	TabContent,
	TabsBar,
};

export {type TabInfo} from './types';
