import {DemographicPerformance} from './variant/demographic-performance';
import {SchoolPerformance} from './variant/school-performance';
import {SchoolStudentsNeedingSupport} from './variant/students-needing-support';

export const SchoolAdminWidget = {
	SchoolPerformance,
	DemographicPerformance,
	SchoolStudentsNeedingSupport,
};

export * from './types/widget';
export * from './types/school-performance';
export * from './types/demographic-performance';
export * from './types/students-needing-support';
