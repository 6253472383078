import {ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback} from 'react';
import {Text} from '@esgi/ui/typography';
import {Button, IconComponent, useFlag} from '@esgi/ui';
import {WarningAlert} from '@esgi/main/features/school-admin/data';
import {useSingleSelectedExpandablePanelContext} from '../../context';
import {PanelButton} from './index.styled';

type Props = ComponentPropsWithoutRef<typeof Button> & {
	Icon: IconComponent;
	transcript: string;
	warningDescription?: string;
	canExpandPanel?: boolean;
};

export const OpenPanelButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			dataCy = 'single-selected-expandable-panel-open-panel-button',
			css = {},
			color = 'secondary',
			onClick,
			Icon,
			transcript,
			warningDescription,
			canExpandPanel = true,
			...props
		},
		forwardedRef,
	) => {
		const {expandPanel} = useSingleSelectedExpandablePanelContext();

		const [showWarning, openWarning, closeWarning] = useFlag();

		const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
			expandPanel();
			onClick?.(event);
		}, [onClick, expandPanel]);

		if (canExpandPanel) {
			return (
				<PanelButton dataCy={dataCy} css={css} color={color} onClick={handleClick} ref={forwardedRef} {...props}>
					<Icon />
					<Text size='medium' bold color='base'>
						{transcript}
					</Text>
				</PanelButton>
			);
		}

		return (
			<>
				<PanelButton color='secondary' onClick={openWarning}>
					<Icon />
					<Text size='medium' bold color='base'>
						{transcript}
					</Text>
				</PanelButton>

				{showWarning && (
					<WarningAlert
						onClose={closeWarning}
						description={warningDescription}
					/>
				)}
			</>
		);
	},
);
