import {useEffect, useState} from 'react';
import {PublishForSpecialistsTabID, PublishForTeacherTabID, RetestSubjectType} from '../../../../types';
import {
	ExpandableSelectableBlockTab,
	GradeLevel,
	SubjectPublishValue,
} from '@esgi/main/features/admins/subject-management';
import {getPublishForTeacherInit} from './helpers/get-publish-for-teacher-init';
import {getPublishForSpecialistsInit} from './helpers/get-publish-for-specialists-init';
import {useDisposable} from '@esgi/core/service';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {SubjectsDistrictController} from '@esgi/contracts/esgi';
import {retestSubjectToNumerable} from '../../../mapped-enums';
import {isNull} from 'underscore';
import {getPublishForSpecialistsSaveData} from '../../../helpers/get-publish-for-specialists-save-data';
import {getPublishForTeachersSaveData} from '../../../helpers/get-publish-for-teachers-save-data';
import {getPublishDatesSaveData} from '../../../helpers/get-publish-dates-save-data';
import {
	TestContentArea,
	TestScreenType,
	DeployedSubject,
	subjectsStore,
	SubjectLevel as SubjectLevelStore,
	SubjectType as SubjectTypeStore,
} from '@esgi/main/libs/admin-store';
import {getPublishForTeachersStoreData} from '../../../helpers/get-publish-for-teachers-store-data';
import {getPublishForSpecialistsStoreData} from '../../../helpers/get-publish-for-specialists-store-data';
import {NewSubjectData, Parameters} from './types';
import {SubjectSettingDataExtended} from '../../types';
import {useFormTouched} from './use-form-touched';
import {initPartialData} from './constants';

export function useSubjectData({
	isDataLoaded,
	teachersSchools,
	gradeLevels,
	districtSpecialists,
	schoolSpecialistsSchools,
	selectedTests,
	trackID,
	allTrackDates,
	onSubjectCreated,
	schoolSpecialists,
}: Parameters) {
	const controller = useDisposable(SubjectsDistrictController);
	const [createdData, saveSubject] = useCancelableRequest(controller.create);

	const [initData, setInitData] = useState<SubjectSettingDataExtended | null>(null);

	const [isActive, setIsActive] = useState(initPartialData.isActive);

	const [subjectName, setSubjectName] = useState(initPartialData.subjectName);

	const [retestSubjectType, setRetestSubjectType] = useState(initPartialData.retestSubjectType);
	const [isShuffleQuestions, setIsShuffleQuestions] = useState(initPartialData.isShuffleQuestions);
	const [selectedGradeLevels, setSelectedGradeLevels] = useState<GradeLevel['id'][]>([]);

	const [publishPeriodValue, setPublishPeriodValue] = useState<SubjectPublishValue>(initPartialData.publishPeriodValue);

	const [isPublishForTeacherActive, setIsPublishForTeacherActive] = useState(initPartialData.isPublishForTeacherActive);
	const [publishForTeacherData, setPublishForTeacherData] = useState<
		ExpandableSelectableBlockTab<PublishForTeacherTabID>[]
	>([]);

	const [isPublishForSpecialistsActive, setIsPublishForSpecialistsActive] = useState(
		initPartialData.isPublishForSpecialistsActive,
	);
	const [publishForSpecialistsData, setPublishForSpecialistsData] = useState<
		ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[]
	>([]);

	const [newSubjectData, setNewSubjectData] = useState<NewSubjectData | null>(null);

	const isFormTouched = useFormTouched({
		initData,
		currentData: {
			subjectName,
			retestSubjectType,
			isShuffleQuestions,
			publishPeriodValue,
			selectedGradeLevels,
			isPublishForTeacherActive,
			publishForTeacherData,
			isPublishForSpecialistsActive,
			publishForSpecialistsData,
			isActive,
			selectedTests,
		},
	});

	useEffect(() => {
		if (isDataLoaded) {
			const publishForTeacherDataInit = getPublishForTeacherInit({teachersSchools});
			const publishForSpecialistsDataInit = getPublishForSpecialistsInit({
				districtSpecialists,
				schoolSpecialistsSchools,
			});
			const selectedGradeLevelsInit = gradeLevels.map(({id}) => id);

			setPublishForTeacherData(publishForTeacherDataInit);
			setPublishForSpecialistsData(publishForSpecialistsDataInit);
			setSelectedGradeLevels(selectedGradeLevelsInit);

			setInitData({
				...initPartialData,
				publishForTeacherData: publishForTeacherDataInit,
				publishForSpecialistsData: publishForSpecialistsDataInit,
				selectedGradeLevels: selectedGradeLevelsInit,
				selectedTests: [],
			});
		}
	}, [districtSpecialists, gradeLevels, isDataLoaded, schoolSpecialistsSchools, teachersSchools]);

	useEffect(() => {
		if (isAsyncSucceed(createdData)) {
			if (isNull(newSubjectData)) {
				throw new Error('newSubjectData is null');
			}

			const {id, published, subjectName} = createdData.data;

			const allowedSchools = getPublishForTeachersStoreData({
				publishToTeachers: newSubjectData.publishToTeachers,
				selectedSchoolsIDs: newSubjectData.selectedSchoolsIDs,
				allSchoolsIDs: teachersSchools.map(({id}) => id),
			});

			const allowedSpecialists = getPublishForSpecialistsStoreData({
				publishToSpecialists: newSubjectData.publishToSpecialists,
				allSpecialistsIDs: [...districtSpecialists.map(({id}) => id), ...schoolSpecialists.map(({id}) => id)],
				selectedDistrictSpecialistsIDs: newSubjectData.selectedDistrictSpecialistsIDs,
				selectedSchoolSpecialistsSchoolsIDs: newSubjectData.selectedSchoolSpecialistsSchoolsIDs,
				allSchoolSpecialists: schoolSpecialists,
			});

			const subjectForStore: DeployedSubject = {
				id,
				gradeLevels: selectedGradeLevels,
				hidden: !isActive,
				isPublished: published,
				name: subjectName,
				subjectType: SubjectTypeStore.Deployed,
				level: SubjectLevelStore.District,
				allowedSchools,
				allowedSpecialists,
				tests: selectedTests.map(
					({id, color, contentArea, authorID, isWhiteBackground, name, testScreenTypes, testType}) => ({
						id,
						color,
						contentArea: contentArea as TestContentArea,
						creatorID: authorID,
						isWhiteBackground,
						maxScore: 0,
						name,
						testScreenTypes: testScreenTypes as unknown as TestScreenType[],
						type: testType,
					}),
				),
			};

			subjectsStore().add?.(subjectForStore);
			onSubjectCreated(id);
			setNewSubjectData(null);
		}
	}, [createdData]);

	const onSaveSubject = () => {
		if (isNull(trackID)) {
			throw new Error('trackID is null');
		}

		const publishForTeachersSaveData = getPublishForTeachersSaveData({
			publishForTeacherData,
			isPublishForTeacherActive,
		});

		const publishForSpecialistsSaveData = getPublishForSpecialistsSaveData({
			isPublishForSpecialistsActive,
			publishForSpecialistsData,
		});

		const {publishFromDate, publishToDate, publishType} = getPublishDatesSaveData({allTrackDates, publishPeriodValue});

		saveSubject({
			districtID: undefined as unknown as number,
			globalSchoolYearID: undefined as unknown as number,
			userID: undefined as unknown as number,
			userType: undefined as unknown as number,
			isHidden: !isActive,
			name: subjectName,
			forceTestType: (retestSubjectType === RetestSubjectType.Any
				? null
				: retestSubjectToNumerable[retestSubjectType]) as number,
			shuffleQuestions: isShuffleQuestions,
			gradeLevels: selectedGradeLevels,
			publishToTeachers: publishForTeachersSaveData.publishToTeachers,
			schools: publishForTeachersSaveData.schools,
			publishToSpecialists: publishForSpecialistsSaveData.publishToSpecialists,
			schoolSpecialists: publishForSpecialistsSaveData.schoolSpecialistsSchools,
			districtSpecialists: publishForSpecialistsSaveData.districtSpecialists,
			publishType,
			publishTrackID: trackID,
			publishFromDate,
			publishToDate,
			testIDs: selectedTests.map(({id}) => id),
		});

		setNewSubjectData({
			publishToTeachers: publishForTeachersSaveData.publishToTeachers,
			selectedSchoolsIDs: publishForTeachersSaveData.schools ?? [],
			publishToSpecialists: publishForSpecialistsSaveData.publishToSpecialists,
			selectedDistrictSpecialistsIDs: publishForSpecialistsSaveData.districtSpecialists,
			selectedSchoolSpecialistsSchoolsIDs: publishForSpecialistsSaveData.schoolSpecialistsSchools,
		});
	};

	return {
		isActive,
		setIsActive,
		subjectName,
		setSubjectName,
		retestSubjectType,
		setRetestSubjectType,
		isShuffleQuestions,
		setIsShuffleQuestions,
		publishPeriodValue,
		setPublishPeriodValue,
		setSelectedGradeLevels,
		selectedGradeLevels,
		publishForTeacherData,
		setPublishForTeacherData,
		isPublishForTeacherActive,
		setIsPublishForTeacherActive,
		publishForSpecialistsData,
		setPublishForSpecialistsData,
		isPublishForSpecialistsActive,
		setIsPublishForSpecialistsActive,
		onSaveSubject,
		isDataSaving: isAsyncPending(createdData),
		isFormTouched,
	};
}
