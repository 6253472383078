import {Box, FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(FlexBox, {
	flexDirection: 'column',
	gap: '$2',
});

export const TitleBox = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '$3',
	paddingTop: 20,
	paddingInline: '$3',
});

export const TitleUnderline = styled(Box, {
	width: '100%',
	height: 1,
	backgroundColor: '$border',
});
