import {Box, FlexBox, NavigationBar, useNavigationBarContext} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {UserInfo} from '../../../../types';
import {AdditionalElementType, useNavigateItems} from '../../use-navigate-items';
import {NavLink} from 'react-router-dom';
import {NavigationBarItem} from '../../../nav-item';
import {SchoolYearSelect} from '@esgi/main/features/common/school-year-select';

interface Props {
	onSchoolYearChanged: VoidFunction;
	userInfo: UserInfo;
}

export function HorizontalPanelContent({onSchoolYearChanged, userInfo}: Props) {
	const {expanded} = useNavigationBarContext();

	const navigateItems = useNavigateItems({canExploreStudents: userInfo.canExploreStudents});

	return (
		<NavigationBar.Content css={{marginTop: '0'}}>
			{expanded && (
				<>
					<Box>
						<SchoolYearSelect onSchoolYearChanged={onSchoolYearChanged} />
					</Box>
					<FlexBox css={{justifyContent: 'space-between', alignItems: 'center', overflowY: 'auto'}}>
						{navigateItems.map((item, index) => {
							if (item === AdditionalElementType.Divider) {
								return <NavigationBar.Divider css={{marginBottom: 0}} key={`${item}-${index}`} />;
							}

							const {dataCy, iconDataCy, to, label, permitted = true, Icon} = item;

							if (!permitted) {
								return null;
							}

							return (
								<NavLink to={to} style={{all: 'unset'}} key={dataCy}>
									{({isActive}) => (
										<NavigationBarItem dataCy={dataCy} active={isActive}>
											<Icon data-cy={iconDataCy} width={24} height={24} />
											<Text data-cy='label' size='small'>
												{label}
											</Text>
										</NavigationBarItem>
									)}
								</NavLink>
							);
						})}
					</FlexBox>
				</>
			)}
		</NavigationBar.Content>
	);
}
