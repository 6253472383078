import {Drawer, useDrawerClose, useDrawerRef, useRootPageContext} from '@esgi/main/kits/common';
import {IconComponent, Portal} from '@esgi/ui';
import {SubjectPublishValue, TrackDate} from '../types';
import {Dispatch, useCallback} from 'react';
import {DrawerBody} from './index.styled';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {TabID} from './types';
import {CustomDateRangeTab} from './components/custom-date-range-tab';
import {PeriodTab} from './components/period-tab';
import {useSave} from './hooks/use-save';
import {useDrawerData} from './hooks/use-drawer-data';
import {Tabs} from '../../../../kit';
import {tabs} from './constants';

type Props = {
	onClose: VoidFunction;
	title: string;
	value: SubjectPublishValue;
	onSave: Dispatch<SubjectPublishValue>;
	trackID: number | null;
	allTrackDates: TrackDate[];
	EntityIcon: IconComponent;
};

export function PeriodsDrawer({
	onClose,
	title,
	value: initialValue,
	onSave,
	allTrackDates,
	EntityIcon,
	trackID,
}: Props) {
	const drawerRef = useDrawerRef();
	const closeDrawer = useDrawerClose(drawerRef, onClose);

	const {rootPageContentContainerRef} = useRootPageContext();

	const {
		tabID,
		setTabID,
		periodTabID,
		setPeriodTabID,
		selectedTrackDatesIDs,
		setSelectedTrackDatesIDs,
		customDateRange,
		setCustomDateRange,
		filteredTrackDates,
		isActionButtonActive,
	} = useDrawerData({allTrackDates, initialValue, trackID});

	const onSetTabID = useCallback(
		(value: string) => {
			setTabID(value as TabID);
		},
		[setTabID],
	);

	const onActionButtonClick = useSave({
		closeDrawer,
		customDateRange,
		onSave,
		periodTabID,
		selectedTrackDatesIDs,
		tabID,
	});

	return (
		<Portal container={rootPageContentContainerRef.current}>
			<Drawer drawerRef={drawerRef} onClickOutside={closeDrawer}>
				<Drawer.Header
					Icon={EntityIcon}
					sectionName={title}
					actionButtonText='Select'
					closeDrawer={closeDrawer}
					withActionButton
					onActionButtonClick={onActionButtonClick}
					actionButtonDisabled={!isActionButtonActive}
				/>

				<DrawerBody>
					<Tabs value={tabID} setValue={onSetTabID} items={tabs} />
					<OverlayScrollbarsComponent defer options={{scrollbars: {autoHide: 'leave'}}}>
						{tabID === TabID.Period && (
							<PeriodTab
								periodTypeID={periodTabID}
								setPeriodTabID={setPeriodTabID}
								visibleTrackDates={filteredTrackDates}
								selectedTrackDatesIDs={selectedTrackDatesIDs}
								setSelectedTrackDatesIDs={setSelectedTrackDatesIDs}
							/>
						)}

						{tabID === TabID.CustomDateRange && (
							<CustomDateRangeTab value={customDateRange} setValue={setCustomDateRange} />
						)}
					</OverlayScrollbarsComponent>
				</DrawerBody>
			</Drawer>
		</Portal>
	);
}
