import {Prev, Enter} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {StyledButton, StyledCheap, StyledIconDot} from './index.styled';

interface Props {
	pageIndex: number;
	pageCount: number;
	testsCount: number;
	onNextPage: () => void;
	onPreviousPage: () => void;
}

export function Pagination({pageIndex, pageCount, testsCount, onNextPage, onPreviousPage}: Props) {
	const isPreviousButtonDisabled = pageIndex === 1;
	const isNextButtonDisabled = pageIndex === pageCount || testsCount === 0;

	return (
		<FlexBox justify='between'>
			<GridBox flow='column' align='center' gap={2}>
				<StyledCheap color='neutral'>
					<Text font='mono' size='small'>
						{testsCount}
					</Text>
				</StyledCheap>
				<Text font='mono' size='small' color='mediumContrast'>
					Tests
				</Text>
			</GridBox>
			<GridBox flow='column' align='center' gap={3}>
				<Text font='mono' size='xSmall' color='mediumContrast'>
					Page
				</Text>
				<FlexBox align='center'>
					<Text font='mono' size='small' bold>
						{pageIndex}
					</Text>
					<StyledIconDot height={12} width={12} />
					<Text color='mediumContrast' font='mono' size='small' bold>
						{pageCount}
					</Text>
				</FlexBox>
				<StyledButton color='secondary' onClick={onPreviousPage} disabled={isPreviousButtonDisabled}>
					<Prev height={24} width={24} />
				</StyledButton>
				<StyledButton color='secondary' onClick={onNextPage} disabled={isNextButtonDisabled}>
					<Enter height={24} width={24} />
				</StyledButton>
			</GridBox>
		</FlexBox>
	);
}