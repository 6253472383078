import * as React from 'react';
import type {SVGProps} from 'react';

export function Student(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.9844 8.5C13.8874 8.5 12.1875 10.1792 12.1875 12.25C12.1875 14.3208 13.8874 16 15.9844 16C18.0813 16 19.7812 14.3208 19.7812 12.25C19.7812 10.1792 18.0813 8.5 15.9844 8.5ZM15.9844 10.1667C17.1493 10.1667 18.0938 11.0992 18.0938 12.25C18.0938 13.4008 17.1493 14.3333 15.9844 14.3333C14.8194 14.3333 13.875 13.4008 13.875 12.25C13.875 11.0992 14.8194 10.1667 15.9844 10.1667ZM13.084 17.12C11.5365 17.7508 10.5 19.2967 10.5 21V22.6667C10.5 23.1267 10.8777 23.5 11.3438 23.5H20.625C21.091 23.5 21.4688 23.1267 21.4688 22.6667V21C21.4688 19.3367 20.4265 17.8283 18.8848 17.1717C18.6678 17.0792 18.4149 17.0767 18.1992 17.1717C17.4527 17.5025 16.7254 17.6667 15.9844 17.6667C15.2459 17.6667 14.5161 17.4933 13.7432 17.1458C13.5307 17.05 13.2998 17.0317 13.084 17.12ZM13.4084 18.825C14.2056 19.1233 15.1667 19.3333 15.9844 19.3333C16.7956 19.3333 17.717 19.1375 18.4981 18.8525C19.2596 19.2808 19.7812 20.0825 19.7812 21V21.8333H12.1875V21C12.1875 20.0292 12.7409 19.2108 13.4084 18.825Z'
				fill='#333333'
			/>
		</svg>
	);
}
