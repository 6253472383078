import {ReportDialog, Reports} from '../../../../../../_kit';
import {useMemo} from 'react';
import {RowDef} from '../../../../../types';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {NoStudentsInSchoolAlert} from '../../../../../components/no-students-in-school-alert';

type Props = {
	school: RowDef;
	onClose: VoidFunction;
};

export function SchoolReportsDialog({onClose, school}: Props) {
	const hierarchy = useMemo<Hierarchy>(() => ({schoolID: school.id}), [school]);

	if (!school.students) {
		return <NoStudentsInSchoolAlert onClose={onClose} />;
	}

	return (
		<ReportDialog onClose={onClose} header={school.name}>
			<ReportDialog.Section title='School Reports'>
				<Reports.GradeScale
					name='School Grades'
					description='Apply customizable grade scales to school scores'
					hierarchy={hierarchy}
				/>
				<Reports.TotalStudents
					name='School Students'
					description='Review and compare student scores for a subject tab at the School level'
					hierarchy={hierarchy}
				/>
				<Reports.Totals
					name='School Totals'
					description='Review and compare class scores for a subject tab'
					hierarchy={hierarchy}
				/>
				<Reports.ItemAnalysis hierarchy={hierarchy} />
				<Reports.PieCharts hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
