import {Alert} from '@esgi/ui/alert';
import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const AlertBody = styled(Alert.Body, {
	width: '100%',
	justifyItems: 'center',
});

export const BodyContainer = styled(GridBox, {
	width: '100%',
	justifyItems: 'center',
	gap: '$4',
});

export const Description = styled(Box, {
	width: '100%',
});

export const InfoIconWrapper = styled(Box, {
	'& > svg': {
		width: '24px',
		height: '24px',
		fill: '$neutral40',

		'& > path': {
			fill: '$neutral40',
		},
	},
});

export const Container = styled(GridBox, {
	justifyItems: 'center',
	gap: '$4',
});
