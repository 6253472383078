import {ViewMode} from './view-mode';
import {GradeLevel, SortOption, WidgetCard, WidgetMode} from '@esgi/main/features/admins/dashboard';
import {ClassModel, SubjectTab, TeacherModel} from '../../../../types';
import {WidgetSortBy} from '../types';
import {Dispatch, SetStateAction, useLayoutEffect} from 'react';
import {
	DemographicPerformanceWidgetOptions,
	DemographicPerformanceWidgetUpdatedValue,
} from '../../../types/demographic-performance';
import {EditMode} from './edit-mode';
import {Widget} from '../../../types/widget';

type Props = {
	allClassesAvgValue: number;
	widgetOptions: DemographicPerformanceWidgetOptions;
	subjects: SubjectTab[];
	allClassesList: ClassModel[];
	allTeachersList: TeacherModel[];
	sortableItems: SortOption<WidgetSortBy>[];
	widgetSortBy: WidgetSortBy;
	setWidgetSortBy: Dispatch<SetStateAction<WidgetSortBy>>;
	onDeleteWidgetByID: Dispatch<Widget['id']>;
	onUpdateWidget: Dispatch<DemographicPerformanceWidgetUpdatedValue>;
	gradeLevels: GradeLevel[];
};

export function Content({
	allClassesAvgValue,
	widgetOptions,
	allClassesList,
	allTeachersList,
	subjects,
	widgetSortBy,
	setWidgetSortBy,
	sortableItems,
	onDeleteWidgetByID,
	onUpdateWidget,
	gradeLevels,
}: Props) {
	const {pageChange, widgetDataPageSize, widgetMode} = WidgetCard.useWidgetCardContext();

	useLayoutEffect(() => {
		pageChange(0);
	}, [widgetSortBy, widgetDataPageSize]);

	if (widgetMode === WidgetMode.Edit) {
		return (
			<EditMode
				allClassesList={allClassesList}
				allTeachersList={allTeachersList}
				onDeleteWidgetByID={onDeleteWidgetByID}
				subjects={subjects}
				widgetOptions={widgetOptions}
				onUpdateWidget={onUpdateWidget}
				gradeLevels={gradeLevels}
			/>
		);
	}

	return (
		<WidgetCard.ViewMode<WidgetSortBy>
			sortableItems={sortableItems}
			setWidgetSortBy={setWidgetSortBy}
			widgetSortBy={widgetSortBy}
		>
			<ViewMode
				viewType={widgetOptions.viewType}
				averageValue={allClassesAvgValue}
				showAllItemsAverage={widgetOptions.showAvg}
				candles={widgetOptions.candles}
				periods={widgetOptions.periods}
				compareTwoClasses={widgetOptions.classes}
				allClassesList={allClassesList}
				subjects={subjects}
				contentAreaIDs={widgetOptions.contentAreaIDs}
				gradeLevelIDs={widgetOptions.gradeLevelIDs}
				classIDs={widgetOptions.classIDs}
				subjectIDs={widgetOptions.subjectIDs}
				gradeLevels={gradeLevels}
			/>
		</WidgetCard.ViewMode>
	);
}
