import {TestResults} from '@esgi/main/kits/admin';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {RowDef} from '../../../../../types';
import {useTestResultsData} from '../../../../use-test-results-data';

type Props = {
	selectedRow: RowDef;
};

export function ExpandedPanel({selectedRow}: Props) {
	const {skeleton, students, subjects, hierarchy, additionalInfo} = useTestResultsData({selectedRow});

	return (
		<SingleSelectedExpandablePanel.ExpandedPanel>
			<TestResults
				skeleton={skeleton}
				allSubjects={subjects}
				students={students}
				hierarchy={hierarchy}
				additionalInfo={additionalInfo}
				withoutVariantByEachStudent
			/>
		</SingleSelectedExpandablePanel.ExpandedPanel>
	);
}
