import {useCallback, useState} from 'react';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {EntityManagement, EntityType} from '../kit';
import {entityModalContent} from './constants';

type Props = {
	entityIDs: number[];
	entityType: EntityType;
	onClose: VoidFunction;
	onEntityRemoved?: (entityIDs: number[]) => void;
};

export function RemoveEntity({entityIDs, entityType, onClose, onEntityRemoved}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [isBusy, setIsBusy] = useState(false);

	const handleEntityRemoved = useCallback(() => {
		onEntityRemoved?.(entityIDs);
		closeAlert();
	}, [entityIDs, closeAlert, onEntityRemoved]);

	const removeEntity = EntityManagement.useRemoveEntity({
		entityIDs: entityIDs,
		entityType: entityType,
		onRemoved: handleEntityRemoved,
	});

	const handleRemoveEntity = useCallback(() => {
		setIsBusy(true);
		removeEntity();
	}, [removeEntity]);

	return (
		<Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative}>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large'>{entityModalContent[entityType].headerTitle}</Text>
			</Alert.Header>

			<Alert.Body>
				<Text size='medium' color='highContrast'>
					{entityModalContent[entityType].bodyText}
				</Text>
			</Alert.Body>

			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>

					<Button color='secondary' onClick={handleRemoveEntity} disabled={isBusy}>
						<Text size='medium' bold color='negative'>
							{entityModalContent[entityType].deleteButtonText}
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
