import {useLayoutEffect, useMemo} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {GradeScaleLauncher} from 'shared/modules/grade-scale/grade-scale-launcher/grade-scale-launcher';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import {SsoTracker} from '@esgi/core/tracker';
import {GradeScaleHierarchyLevel} from 'shared/modules/reports/grade-scale-report/models';
import {UserType} from '@esgi/core/authentication';

type Props = {
	hierarchy: HierarchyInstance;
	subjectID: number;
	subjectType: SubjectType;
	onClose: VoidFunction;
}

function resolveGradeScalesLevel(hierarchy: HierarchyInstance) {
	if (hierarchy.mode === HierarchyMode.Classic) {
		const s = hierarchy.classic.selected;
		switch (s.level) {
			case ClassicHierarchyLevel.Student:
				return hierarchy.classic.groupID > 0 ? GradeScaleHierarchyLevel.Group : GradeScaleHierarchyLevel.Class;
			case ClassicHierarchyLevel.Teacher:
			case ClassicHierarchyLevel.Class:
				return GradeScaleHierarchyLevel.Class;
			case ClassicHierarchyLevel.Group:
				return GradeScaleHierarchyLevel.Group;
			case ClassicHierarchyLevel.School:
				return GradeScaleHierarchyLevel.StudentsSchool;
			case ClassicHierarchyLevel.District:
				return GradeScaleHierarchyLevel.StudentsDistrict;
			case ClassicHierarchyLevel.SchoolsGroup:
				return GradeScaleHierarchyLevel.SchoolsGroup;
			case ClassicHierarchyLevel.TeachersGroup:
				return GradeScaleHierarchyLevel.TeachersGroup;
		}
	}

	if (hierarchy.mode === HierarchyMode.Specialist) {
		const s = hierarchy.specialist;
		const selected = hierarchy.specialist.selected;

		if (s.userID === 0) {
			if (selected.level === SpecialistHierarchyLevel.GroupOfSpecialists) {
				return GradeScaleHierarchyLevel.GroupOfSpecialists;
			}
			if (s.type === UserType.ISS && s.filter.schoolID > 0) {
				return GradeScaleHierarchyLevel.SchoolSpecialistStudents;
			} else {
				return GradeScaleHierarchyLevel.StudentsDistrict;
			}
		} else {
			return GradeScaleHierarchyLevel.Group;
		}
	}

	if (hierarchy.mode === HierarchyMode.PreAssess) {
		const pa = hierarchy.preAssess;

		if (pa.userID === 0) {
			return GradeScaleHierarchyLevel.StudentsDistrict;
		} else {
			return GradeScaleHierarchyLevel.Group;
		}
	}

	return GradeScaleHierarchyLevel.None;
}

export function GradeScalesLauncher(props: Props) {
	const gradeScaleLevel = useMemo(() => resolveGradeScalesLevel(props.hierarchy), [props.hierarchy]);

	useLayoutEffect(() => {
		SsoTracker.trackEvent({
			trackingEvent: gradeScaleLevel + 'GradeReport',
		});
		const changes = new ChangesCollector({
			id: props.subjectID,
			type: props.subjectType,
		}, props.hierarchy.snapshot);
		const launcher = new GradeScaleLauncher(gradeScaleLevel, props.subjectID, props.subjectType, props.hierarchy.snapshot);
		launcher.events.homepageStateChanged((e, reportChanges) => {
			changes.applyChanges({
				classID: reportChanges.classID,
				groupID: reportChanges.groupID,
				subjectTabID: reportChanges.subjectTabID,
				subjectTabType: reportChanges.subjectTabType,
				schoolID: reportChanges.schoolID,
				teacherID: reportChanges.teacherID,
			});
		});

		launcher.events.onClose(() => props.onClose());
		launcher.events.closed(() => props.onClose());

		launcher.load();
	});

	return <></>;
}