import {isUndefined, ProgressBar, ThemeColorKeys} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {isNull} from 'underscore';

type Props = ComponentPropsWithoutRef<typeof GridBox> & {
	progressBarTitle?: string;
	progressBarSize?: number;
	progressBarcircleFillColor?: ThemeColorKeys;
	progressBarCircleStrokeColor?: ThemeColorKeys;
	progressBarChartStrokeWidth?: number;
	progressBarProgressColor?: ThemeColorKeys;
	value: number | null;
	maxValue: number;
};

export const RingFullFilled = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'test-card-progress-ring-full-filled',
			css = {},
			progressBarTitle,
			progressBarSize = 60,
			progressBarcircleFillColor = 'background',
			progressBarCircleStrokeColor = 'border',
			progressBarChartStrokeWidth = 1,
			progressBarProgressColor,
			value,
			maxValue,
			children,
			...props
		},
		forwardedRef,
	) => {
		const isProgressSuccess: boolean = isNull(value) ? false : value / maxValue > 0.5;

		return (
			<GridBox dataCy={dataCy} css={css} gap={4} display='inlineGrid' ref={forwardedRef} {...props}>
				<GridBox gap='3' flow='column'>
					<ProgressBar value={value ?? 0} maxValue={maxValue}>
						<ProgressBar.RingFullFilled
							size={progressBarSize}
							circleFillColor={progressBarcircleFillColor}
							circleStrokeColor={progressBarCircleStrokeColor}
							chartStrokeWidth={progressBarChartStrokeWidth}
							progressColor={
								!isUndefined(progressBarProgressColor)
									? progressBarProgressColor
									: isProgressSuccess
									? 'positiveVivid'
									: 'lowContrast'
							}
						/>
					</ProgressBar>
					{children}
				</GridBox>

				{progressBarTitle && (
					<Text size='small' font='mono' color='mediumContrast'>
						{progressBarTitle}
					</Text>
				)}
			</GridBox>
		);
	},
);
