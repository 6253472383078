export enum TestType {
	None,
	YN = 1,
	Score = 2,
	Rubric = 3,
	General = 4,
}

export enum AnswerState {
	Incorrect = 0,
	Correct = 1,
	NotTested = 2
}


export enum SessionType {
	TestIncorrect = 0,
	TestAll = 1,
	TestSubset = 2,
}

export * from '@esgi/contracts/esgi/types/esgi.enums/subject-type';

export enum SubjectPublishType {
	Off = 0,
	Indefinitely = 1,
	SchoolYear = 2,
	MarkingPeriod = 3,
	CustomDateRange = 4
}

export enum SubjectLevel {
	Teacher,
	School,
	District
}

export enum SortDirection {
	None,
	Asc,
	Desc
}


export enum ValidationStatus {

	// this status is used to designate has not yet validated components
	Unknown,
	Valid,
	Invalid
}

export enum ReportType {
	None,
	District,
	School,
	Class,
	Group,
	StudentsSchool,
	StudentsDistrict,
	Teacher,
	SpecialistGroup,
	SchoolSpecialist,
	SchoolSpecialistStudents,
	DistrictSpecialist,
	DistrictSpecialistStudents,
	DistrictPreassessStudents,
	DistrictPreassess,
	SchoolsGroup,
	TeachersGroup,
	StudentsSchoolsGroup,
	StudentsTeachersGroup,
	GroupOfSpecialists,
	GroupOfSpecialistsStudents
}

export enum MoveUserStatus {
	None = 0,
	WaitingUserAction = 1,
	UserWarned = 2,
	UserLoggedOut = 3,
	ProcessStarted = 4,
	ProcessFinished = 5,
	UserNotifiedOnLogin = 6
}

export enum TestScreenType {
	None = 'None',
	OneToOne = 'OneToOne',
	SelfAssessment = 'SelfAssessment',
}

export enum StudentSort {
	FirstName = 'FirstName',
	LastName = 'LastName',
}