import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Select} from '@esgi/ui/controls';

export const StyledSelectField = styled(Select.Field, {
	height: 40,

	'& [data-value-content]': {
		'& [data-additional-option-text]': {
			display: 'none',
		},
	},
});

export const SelectContent = styled(Select.Content, {
	minWidth: 'max-content',
});

export const StyledContentContainer = styled(Box, {
	borderBottomLeftRadius: 8,
	borderBottomRightRadius: 8,
	overflow: 'hidden',
});

export const TabContentWrapper = styled(GridBox, {
	variants: {
		hide: {
			true: {
				display: 'none',
			},
		},
	},
});
