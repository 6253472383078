import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ActionButtonsDivider, AddIconableButton, LastUpdateBox, LinkButton} from './index.styled';
import {Timestamp} from '../../../../../timestamp';
import {DragDropMode, Info, Plus} from '@esgi/ui/icons';
import {Button, Tooltip} from '@esgi/ui';
import {useDashboardPageContext} from '../../../../context';
import {PageMode} from '../../../../types';
import {useCallback} from 'react';
import {PageTitle} from '../../components/page-title';
import {RearrangeNotActiveMessage} from './types';
import {DateFormatText} from '@esgi/main/kits/common';
import {useConvertDateToUserTz} from '../../../../../hooks';

type Props = {
	lastDateUpdate: string | Date;
	onAddClick: VoidFunction;
	rearrangeNotActiveMessages: RearrangeNotActiveMessage[];
};

export function ViewMode({onAddClick, lastDateUpdate, rearrangeNotActiveMessages}: Props) {
	const {setPageMode} = useDashboardPageContext();
	const formattedLastDateUpdate = useConvertDateToUserTz(`${lastDateUpdate}Z`);

	const handleRearrangeButtonClick = useCallback(() => {
		setPageMode(PageMode.Rearrange);
	}, [setPageMode]);

	const handleAddButtonClick = useCallback(() => {
		setPageMode(PageMode.Add);
		onAddClick();
	}, [setPageMode, onAddClick]);

	return (
		<>
			<PageTitle />

			<GridBox flow='column' gap='4' align='center'>
				<LastUpdateBox>
					<Text size='small' font='mono' color='mediumContrast'>
						Last Updated:
					</Text>
					<DateFormatText
						date={formattedLastDateUpdate}
						dateFormat='MM/DD/YY'
						size='small'
						font='mono'
						bold
						color='base'
					/>
					<Timestamp date={formattedLastDateUpdate}>
						<Timestamp.Colorful backgroundColor='lowContrast' timeTextColor='highContrast' />
					</Timestamp>
				</LastUpdateBox>

				<GridBox flow='column' gap='4' align='center'>
					<LinkButton to='https://support.esgisoftware.com/hc/en-us/articles/32397093793293-New-Administrator-Experience' target='_blank'>
						<Info />
					</LinkButton>

					<ActionButtonsDivider />

					<Tooltip open={!rearrangeNotActiveMessages.length ? false : undefined}>
						<Tooltip.Trigger>
							<Button.Icon
								withBackgroundHover
								onClick={handleRearrangeButtonClick}
								disabled={Boolean(rearrangeNotActiveMessages.length)}
							>
								<DragDropMode />
							</Button.Icon>
						</Tooltip.Trigger>
						<Tooltip.Content>
							<GridBox gap='1'>
								{rearrangeNotActiveMessages.map(({id, message}) => (
									<Text size='xSmall' font='mono' color='tertiary' key={id}>
										{message}
									</Text>
								))}
							</GridBox>
						</Tooltip.Content>
					</Tooltip>

					<AddIconableButton onClick={handleAddButtonClick}>
						<Plus />
					</AddIconableButton>
				</GridBox>
			</GridBox>
		</>
	);
}
