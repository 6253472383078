import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';

export const Container = styled(GridBox, {
	'& > div': {
		display: 'grid',
		textAlign: 'unset',
		marginBottom: 'unset',
	},
});
