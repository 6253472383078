import {HttpClient} from '@esgi/api';

export class Api {
	public init(authCode: string, registrationType: string) {
		return HttpClient.default.SSO.get<{ exist: boolean, redirectUrl: string, registrationAllow: boolean, isAlreadyHaveAccount: boolean }>('Integrations/Clever', 'auth/by-not-linked', {
			authCode: authCode,
			registrationType: registrationType,
		});
	}

	public link(authCode: string) {
		const data = new FormData();
		data.set('authCode', authCode);
		return HttpClient.default.SSO.post('Integrations/Clever', 'linking/link', data);
	}

	public register(authCode: string, registrationType: string, promoCode: string) {
		return HttpClient.default.SSO.post<{ token: string }>('Registration/Create', 'Clever', {
			authCode: authCode,
			registrationType: registrationType,
			promoCode: promoCode,
		});
	}
}