import React, {useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {useFormElementContext, ElementStatus} from '@esgillc/ui-kit/form';
import {ClassModel} from '@esgi/contracts/esgi/types/esgi.students/queries/esgi.apigateway/modules/forms/student/class-model';
import {StyledPanel, StyledPanelHeader} from '../../index.styled';
import {ErrorTooltip} from '../../../error-tooltip/error-tooltip';
import {errorTooltipItemList} from '../../../../constants';
import {SelectableClass} from '../selectable-class';
import {UserModel} from '../../../../types';

interface Props {
	teacher: UserModel;
	classes: ClassModel[];
}

export function ByTeacher({teacher, classes}: Props) {
	const {status, value, setValue, setRef} = useFormElementContext();

	const isInvalid = status === ElementStatus.invalid;
	const classIDs = useMemo(() => value.classIDs, [value]);
	const groupIDs = useMemo(() => value.groupIDs, [value]);

	return (
		<StyledPanel ref={setRef} isError={isInvalid}>
			<StyledPanelHeader>
				<Text size='medium' color='primary' bold>
					{`${teacher.firstName} ${teacher.lastName}`}
				</Text>
			</StyledPanelHeader>
			{classes.map((classItem) => (
				<SelectableClass key={classItem.classID} item={classItem} classIDs={classIDs} groupIDs={groupIDs} onChange={setValue} />
			))}
			{isInvalid && errorTooltipItemList.map(({showOnError, message}, index) => (
				<ErrorTooltip showOnError={showOnError} message={message} key={index} />
			))}
		</StyledPanel>
	);
}