import {StudentSort} from '@esgi/core/authentication';
import {BaseService} from '@esgi/core/service';
import moment from 'moment';
import {Extension, ObjectEntries, SubjectTypeNumerable} from '../types';
import {BehaviorSubject} from 'rxjs';
import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {mapToEnum} from 'shared/utils';
import {AdditionalEntity, Student} from '../../../../../types/section';
import {getFullName} from '@esgi/main/kits/common';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui';
import {SsoTracker} from '@esgi/core/tracker';
import {HierarchyInstance} from '@esgi/main/features/admins/utils';

type DownloadOptions = {
	testResultsCorrectVerbiage: string;
	testResultsIncorrectVerbiage: string;
	sortBy: StudentSort;
	showEachStudent: boolean;
	printInColor: boolean;
	gradeLevelID: number;
	selectedSubject: SubjectModel;
	students: Student[];
	hierarchyInstance: HierarchyInstance;
	extension: Extension;
	additionalInfo: AdditionalEntity | null;
};

export class Service extends BaseService {
	public readonly isDownloading$ = new BehaviorSubject(false);

	public download({
		options: {extension, selectedSubject, students, additionalInfo, hierarchyInstance, ...restOptions},
	}: {
		options: DownloadOptions;
	}) {
		this.isDownloading$.next(true);

		const formattedDate = moment().format('YYYY-MM-DD');
		const filename = `Pie_Charts_${formattedDate}.${extension}`;

		const singleStudentData = students.length === 1 ? students[0]! : null;

		const additionalOptions = this.parseAdditionalInfo(additionalInfo);

		const studentName = !isNull(singleStudentData)
			? getFullName({firstName: singleStudentData.firstName, lastName: singleStudentData.lastName})
			: '';

		const subjects = [
			{
				subjectID: selectedSubject.id,
				subjectType: mapToEnum(selectedSubject.type, SubjectTypeNumerable),
				subjectName: selectedSubject.name,
			},
		];

		this.httpClient.ESGIApi.file('/reports/pie-charts', 'downloadPdf', filename, {
			allTeachersMode: false,
			hierarchyUpdated: true,
			subjectName: selectedSubject.name,
			subjects,
			studentID: singleStudentData?.id ?? 0,
			studentName,
			districtName: '',
			hierarchy: hierarchyInstance,
			...additionalOptions,
			...restOptions,
		}).subscribe({
			next: () => this.isDownloading$.next(false),
			error: () => this.isDownloading$.next(false),
		});

		const eventType = extension === 'zip' ? 'PDF Bulk' : 'PDF Standard';
		SsoTracker.trackEvent({
			trackingEvent: eventType,
			data: {reportType: 'PieCharts'},
		});
	}

	private parseAdditionalInfo(additionalInfo: AdditionalEntity | null) {
		const baseData = {
			schoolIDs: [] as number[],
			schoolName: '',
			teacherIDs: [] as number[],
			teacherName: '',
			classID: 0,
			className: '',
			groupID: 0,
			groupName: '',
			specialistGroupID: 0,
			specialistGroupName: '',
		};

		if (isNull(additionalInfo)) {
			return baseData;
		}

		(Object.entries(additionalInfo) as ObjectEntries<AdditionalEntity>).forEach((entry) => {
			if (isUndefined(entry) || isUndefined(entry[1])) {
				return;
			}

			const [key, info] = entry;

			switch (key) {
				case 'school':
					baseData.schoolIDs = [info.id];
					baseData.schoolName = info.name;
					break;

				case 'teacher':
					baseData.teacherIDs = [info.id];
					baseData.teacherName = info.name;
					break;

				case 'class':
					baseData.classID = info.id;
					baseData.className = info.name;
					break;

				case 'group':
					baseData.groupID = info.id;
					baseData.groupName = info.name;
					break;

				case 'specialistGroup':
					baseData.specialistGroupID = info.id;
					baseData.specialistGroupName = info.name;
					break;
			}
		});

		return baseData;
	}
}
