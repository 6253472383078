import {SelectableList} from '@esgi/ui/layout';
import {SubjectModel} from '../../types/subject';
import {Dispatch} from 'react';
import {ItemContent} from './item-content';
import {listGroupCSS} from './list.styled';

type Props = {
	subjects: SubjectModel[];
	onDragEnd: Dispatch<SubjectModel[]>;
};

export function Rearrange({subjects, onDragEnd}: Props) {
	return (
		<SelectableList>
			<SelectableList.GroupRoot type='single'>
				<SelectableList.GroupDroppable onDragEnd={onDragEnd} droppableItems={subjects} css={listGroupCSS}>
					{subjects.map(({id, name, hidden}, index) => (
						<SelectableList.ItemDraggable
							value={String(id)}
							draggableId={String(id)}
							index={index}
							key={id}
							setTextCurrentColor={false}
							variant='outlined'
						>
							<ItemContent subjectName={name} hidden={hidden} subjectID={id} />
						</SelectableList.ItemDraggable>
					))}
				</SelectableList.GroupDroppable>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
