import {TrackDateModelState, TrackType} from '../../../../../../../types';

export type AddEditModeContentRef = {
	getContentData: () => {
		markingPeriodName: string;
		markingPeriodType: TrackType;
		datesInRowsState: TrackDateModelState[];
	};
	isDatesChanged: boolean;
};

export enum Mode {
	Edit = 'Edit',
	Add = 'Add',
}
