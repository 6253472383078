import {useMemo} from 'react';
import {ActionsBox, Header, SelfAssessButton, SelfAssessIconBox} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {Iep, SelfAssess} from '@esgi/ui/icons';
import {TestSessionsContextMenu} from '../test-sessions-context-menu';
import {TestCardContextMenu} from '../test-card-context-menu';
import {useUser} from '@esgi/core/authentication';
import {Test, TestSession} from '../../../../types/test-panel';
import {isNull} from 'underscore';
import {SelectedStudent} from '../../../../types/section';
import {MultipleLabels} from '../../../../../multiple-labels';
import {SchoolSubject, SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {OnMoveToTest} from '../../types';

type Props = {
	testInfo: Test;
	testSessions: TestSession[];
	selectedSubject: SubjectModel;
	selectedStudent: SelectedStudent;
	schoolSubjects: SchoolSubject[];
	onMoveToTest: OnMoveToTest;
	onRemoveTest: VoidFunction;
	userCanAddTestSession: boolean;
	openAddSessionDialog: VoidFunction;
};

export function CardHeader({
	testInfo,
	testSessions,
	selectedSubject,
	selectedStudent,
	schoolSubjects,
	onMoveToTest,
	onRemoveTest,
	userCanAddTestSession,
	openAddSessionDialog,
}: Props) {
	const currentUser = useUser();

	const labels = useMemo(() => (
		testInfo.stateStandards.map((s) => s.name)
	), [testInfo]);

	return (
		<Header>
			<TestCard.Title size='xxLarge' color='base'>
				{testInfo.name}
			</TestCard.Title>

			<GridBox flow='column' gap={3}>
				<TestCard.TestLabel />

				{labels.length > 0 && (
					<MultipleLabels items={labels} />
				)}
			</GridBox>

			<ActionsBox>
				{testInfo.hasIEPGoals && (
					<>
						<TestCard.IconBox>
							<Iep />
						</TestCard.IconBox>
						<TestCard.ActionButtonsDivider />
					</>
				)}

				<TestSessionsContextMenu
					testInfo={testInfo}
					testSessions={testSessions}
					selectedStudent={selectedStudent}
					selectedSubject={selectedSubject}
					userCanAddTestSession={userCanAddTestSession}
					openAddSessionDialog={openAddSessionDialog}
				/>

				{!isNull(currentUser) && (
					<>
						<TestCard.ActionButtonsDivider />

						<TestCardContextMenu
							currentUser={currentUser}
							testInfo={testInfo}
							selectedSubject={selectedSubject}
							schoolSubjects={schoolSubjects}
							onMoveToTest={onMoveToTest}
							onRemoveTest={onRemoveTest}
						/>
					</>
				)}

				{Boolean(currentUser?.showSelfAssessOption) &&
					testInfo.selfAssessmentTestAllowed && (
						<SelfAssessButton>
							<SelfAssessIconBox>
								<SelfAssess />
							</SelfAssessIconBox>
						</SelfAssessButton>
					)}
			</ActionsBox>
		</Header>
	);
}
