import {ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback, useEffect} from 'react';
import {RearrangeButton as RearrangeButtonBase} from '../../../kit';
import {usePanelContext} from '../../context';
import {TestPanelMode} from '../../types/section';

type Props = Omit<ComponentPropsWithoutRef<typeof RearrangeButtonBase>, 'isActive' | 'tooltipText' | 'skeleton'>;

export const RearrangeButton = forwardRef<HTMLButtonElement, Props>(
	({dataCy = 'tests-panel-rearrange-button', css = {}, onClick, disabled, ...props}, forwardedRed) => {
		const {panelMode, skeleton, setPanelMode, setWithRearrangeMode, tests} = usePanelContext();

		const isRearrangeMode = panelMode === TestPanelMode.Rearrange;

		const handleButtonClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				setPanelMode(isRearrangeMode ? TestPanelMode.View : TestPanelMode.Rearrange);

				onClick?.(event);
			},
			[isRearrangeMode, onClick, setPanelMode],
		);

		useEffect(() => {
			setWithRearrangeMode(true);

			return () => {
				setWithRearrangeMode(false);

				if (isRearrangeMode) {
					setPanelMode(TestPanelMode.View);
				}
			};
		}, [isRearrangeMode, setPanelMode, setWithRearrangeMode]);

		return (
			<RearrangeButtonBase
				dataCy={dataCy}
				css={css}
				isActive={isRearrangeMode}
				disabled={panelMode === TestPanelMode.Add || !tests.length || disabled || skeleton}
				skeleton={skeleton}
				onClick={handleButtonClick}
				tooltipText='Rearrange'
				ref={forwardedRed}
				{...props}
			/>
		);
	},
);
