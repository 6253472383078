import {PieChartIcon} from '@esgi/ui/icons';
import {NotPanelButton, PanelButton} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {useFlag} from '@esgi/ui';
import {WarningAlert} from '@esgi/main/features/admins/data';

type Props = {
	canExpandPanel: boolean;
};

const transcript = 'View Tests Results';

export function OpenPanelButton({canExpandPanel}: Props) {
	const [showWarning, openWarning, closeWarning] = useFlag();

	if (canExpandPanel) {
		return <PanelButton Icon={PieChartIcon} transcript={transcript} />;
	}

	return (
		<>
			<NotPanelButton color='secondary' onClick={openWarning}>
				<PieChartIcon />
				<Text size='medium' bold color='base'>
					{transcript}
				</Text>
			</NotPanelButton>

			{showWarning && (
				<WarningAlert
					onClose={closeWarning}
					description='No students have been added to your class. Please add some before running the report.'
				/>
			)}
		</>
	);
}
