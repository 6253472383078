import {DashboardWidgetOptions} from './types';

const nullableWidgetOptions: Record<keyof DashboardWidgetOptions, null> = {
	schoolPerformanceWidgetOptions: null,
	schoolStudentsNeedingSupportWidgetOptions: null,
	schoolDemographicPerformanceWidgetOptions: null,
	districtPerformanceWidgetOptions: null,
	demographicPerformanceWidgetOptions: null,
	lowestAchievementWidgetOptions: null,
};

export const dashboarWidgetOptionsAllNullable = nullableWidgetOptions as unknown as DashboardWidgetOptions;
