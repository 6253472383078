import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const tableRowsGap = 16;
export const tableRowHeight = 16;

export const Table = styled(GridBox, {
	rowGap: tableRowsGap,
});

export const TableRow = styled(GridBox, {
	alignItems: 'center',
	columnGap: '$3',
	height: tableRowHeight,
});

export const TableRowCell = styled(FlexBox, {
	overflow: 'hidden',

	variants: {
		cursor: {
			pointer: {
				cursor: 'pointer',
			},
		},
	},
});
