import {useMemo} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {CardViewType, TestModel} from '../../service/types';
import {ListView} from './components/list-view';
import {GridView} from './components/grid-view';
import {SubjectTab} from '@esgi/main/libs/store';

type Props = BaseComponentProps &
	Omit<TestModel & {subjects: SubjectTab[]}, 'gradeLevels'> & {
		selectedStandardType: number;
		selectedStandardsIDs: number[];
		cardView: CardViewType;
		onTestSelect: (testID: TestModel['testID']) => void;
		onAddToFavoriteClick: VoidFunction;
		isHidden: boolean;
		showTestDetails: VoidFunction;
		selected: boolean;
		showAddToFavorites: boolean;
		gradeLevels: string[];
		isSmallScreen: boolean;
	};

export function Card({cardView, isSmallScreen, ...props}: Props) {
	const stateStandardNames = useMemo(() => {
		return props.stateStandards.map((standard) => standard.name);
	}, [props.stateStandards]);

	if (cardView === CardViewType.Grid || isSmallScreen) {
		return (
			<GridView {...props} stateStandardNames={stateStandardNames} cardView={cardView} isSmallScreen={isSmallScreen} />
		);
	}

	if (cardView === CardViewType.List) {
		return <ListView {...props} stateStandardNames={stateStandardNames} cardView={cardView} />;
	}

	throw new Error('cardView not correspond with the CardViewType enum');
}
