import {Class, Group, User, Student, useTeachers, useClasses, useStudents, useGroups} from '@esgi/main/libs/school-admin-store';
import {Entity as AddStudentsAlertEntity, StudentRowModel} from '@esgi/main/kits/admin';
import {useMemo} from 'react';
import {ColDef} from 'ag-grid-community';
import {isNull, isUndefined} from 'underscore';
import {getFullName} from '@esgi/main/kits/common';
import {StudentsList} from '@esgi/ui/icons';
import {EntitiesFilter} from '@esgi/ui/ag-grid';

type RowModel = StudentRowModel & {
	primaryTeacher: User | null;
	classes: Class[];
	groups: Group[];
};

export function useAddStudentAlertData({
	primaryTeacherID,
	groupID,
	activeStudents,
}: {
	primaryTeacherID: User['id'] | null;
	groupID: Group['id'] | null;
	activeStudents: Student[];
}) {
	const [{data: teachers, loaded: isTeachersLoaded}] = useTeachers();
	const [{data: classes, loaded: isClassesLoaded}] = useClasses();
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();
	const [{data: groups, loaded: isGroupsLoaded}] = useGroups();

	const {primaryTeacherStudents, uniqClassesIDs, uniqGroupsIDs} = useMemo(() => {
		const filteredStudents = students.filter(({teacherID}) => (teacherID === primaryTeacherID));

		return {
			primaryTeacherStudents: filteredStudents,
			uniqClassesIDs: [...new Set(filteredStudents.flatMap(({classesIDs}) => classesIDs))],
			uniqGroupsIDs: [...new Set(filteredStudents.flatMap(({groupsIDs}) => groupsIDs))],
		};
	}, [primaryTeacherID, students]);

	const colDefs = useMemo<ColDef<RowModel>[]>(
		() => [
			{
				field: 'primaryTeacher',
				width: 160,
				sortable: false,
				headerName: 'Primary Teacher',
				filter: false,
				resizable: false,
				cellDataType: 'text',
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return 'Unassigned';
					}

					const primaryTeacher = data.primaryTeacher;

					return isNull(primaryTeacher)
						? 'Unassigned'
						: getFullName({
								firstName: primaryTeacher.firstName,
								lastName: primaryTeacher.lastName,
						  });
				},
			},
			{
				field: 'classes',
				width: 160,
				sortable: true,
				filter: EntitiesFilter,
				filterParams: {
					entities: classes.filter(({id}) => uniqClassesIDs.includes(id)),
				},
				headerName: 'Class',
				resizable: false,
				cellDataType: 'text',
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return '-';
					}

					const classes = data.classes;

					return classes.length ? classes.map(({name}) => name).join(', ') : '-';
				},
			},
			{
				field: 'groups',
				width: 160,
				sortable: true,
				filter: EntitiesFilter,
				filterParams: {
					entities: groups.filter(({id}) => uniqGroupsIDs.includes(id)),
				},
				headerName: 'Groups',
				resizable: false,
				cellDataType: 'text',
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return '-';
					}

					const groups = data.groups;

					return groups.length ? groups.map(({name}) => name).join(', ') : '-';
				},
			},
		],
		[classes, groups, uniqClassesIDs, uniqGroupsIDs],
	);

	const selectedItemsIDs = useMemo(() => activeStudents.map(({id}) => id), [activeStudents]);

	const {rowsData, disabledRowsAsSelectedIDs} = useMemo(() => {
		const disabledRowsAsSelectedIDs: RowModel['id'][] = [];

		const rowsData = primaryTeacherStudents.map<RowModel>(
			({firstName, lastName, classesIDs, groupsIDs, teacherID, id}) => {
				const filteredGroups = groups.filter(({id}) => groupsIDs.includes(id));

				if (filteredGroups.some(({id}) => id === groupID) && selectedItemsIDs.includes(id)) {
					disabledRowsAsSelectedIDs.push(id);
				}

				return {
					id,
					firstName,
					lastName,
					classes: classes.filter(({id}) => classesIDs.includes(id)),
					groups: filteredGroups,
					primaryTeacher: teachers.find(({id}) => id === teacherID) ?? null,
				};
			},
		);

		return {
			disabledRowsAsSelectedIDs,
			rowsData,
		};
	}, [classes, groupID, groups, primaryTeacherStudents, selectedItemsIDs, teachers]);

	const entity = useMemo<AddStudentsAlertEntity | null>(() => {
		const storageGroup = groups.find(({id}) => id === groupID);

		if (isUndefined(storageGroup)) {
			return null;
		}

		return {
			name: storageGroup.name,
			Icon: StudentsList,
		};
	}, [groupID, groups]);

	return {
		colDefs,
		rowsData,
		entity,
		disabledRowsAsSelectedIDs,
		selectedItemsIDs,
		skeleton: !isStudentsLoaded || !isClassesLoaded || !isTeachersLoaded || !isGroupsLoaded,
	};
}
