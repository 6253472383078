import React, {useEffect, useState} from 'react';
import {Page} from './page';
import {isNull} from 'underscore';

export default function RenewalPage() {
	const [containerNode, setContainerNode] = useState<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!isNull(containerNode)) {
			try {
				const page = new Page();
				page.init(containerNode);

				return () => page.destroy();
			} catch (e) {}
		}
	}, [containerNode]);

	return (
		<>
			<div
				style={{backgroundColor: '#FFF', height: '100%', overflowY: 'auto'}}
				ref={(node) => {
					setContainerNode(node);
				}}
			>
				<div style={{minHeight: 450, paddingTop: 15}} data-bind='render: renewal'></div>
			</div>
		</>
	);
}
