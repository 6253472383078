import {useMemo} from 'react';
import {useClasses} from '@esgi/main/libs/admin-store';
import {Folder} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RowDef} from '../../../../../types';
import {NoStudentsAlert, ReportDialog, Reports} from '../../../../../../_kit';

type Props = {
	folder: Folder;
	teacher: RowDef;
	onClose: VoidFunction;
};

export function ReportsFeature({teacher, onClose, folder}: Props) {
	const [{data: classes, loaded: classesLoaded}] = useClasses();

	const teacherLevelHierarchy = useMemo<Hierarchy>(
		() => ({
			schoolID: teacher?.school?.id,
			teacherID: teacher?.id,
			teachersGroupID: folder.id,
		}),
		[teacher, folder],
	);

	const classLevelHierarchy = useMemo<Hierarchy>(() => {
		if (teacher && classesLoaded) {
			const firstClass = classes.find((c) => c.teacherID === teacher.id);
			return {
				schoolID: teacher.school?.id,
				teacherID: teacher.id,
				classID: firstClass.id,
				teachersGroupID: folder.id,
			};
		}
	}, [teacher, classesLoaded, classes, folder]);

	if (!teacher.students) {
		return <NoStudentsAlert onClose={onClose} />;
	}

	return (
		<ReportDialog header={`${teacher.firstName} ${teacher.lastName}`} loading={!classesLoaded} onClose={onClose}>
			<ReportDialog.Section title='Class Reports'>
				<Reports.ParentLetter hierarchy={classLevelHierarchy} />
				<Reports.StudentDetail hierarchy={classLevelHierarchy} />
				<Reports.StudentProgress hierarchy={classLevelHierarchy} />
				<Reports.Totals prefix='Class' hierarchy={classLevelHierarchy} />
				<Reports.GradeScale prefix='Class' hierarchy={classLevelHierarchy} />
				<Reports.ItemAnalysis hierarchy={teacherLevelHierarchy} />
				<Reports.PieCharts hierarchy={teacherLevelHierarchy} />
				<Reports.RubricResults hierarchy={classLevelHierarchy} />
				<Reports.UntestedStudents hierarchy={teacherLevelHierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
