import {Dispatch, createContext, useContext} from 'react';
import {isNull} from 'underscore';
import {Pagination} from '../kit/pagination';
import {WidgetMode} from './types';
import {TrackModel} from '@esgi/main/kits/admin';
import {ContentAreaModel} from '../types';

export type WidgetCardContextValue<T extends Partial<Record<keyof T, unknown>>> = Pick<
	ReturnType<typeof Pagination.usePagination>,
	'currentPageIndex' | 'nextPage' | 'pageChange' | 'totalPages' | 'previousPage'
> & {
	id: number;
	name: string;
	setEditMode: VoidFunction;
	setViewMode: VoidFunction;
	visibleData: T[];
	widgetMode: WidgetMode;
	widgetDataPageSize: number;
	setWidgetDataPageSize: Dispatch<number>;
	totalDataLength: number;
	isDataСounting: boolean;
	currentTrack: TrackModel | null;
	allContentAreas: ContentAreaModel[];
	lastUpdatedTime: string;
};

export const WidgetCardContext = createContext<WidgetCardContextValue<{}> | null>(null);

export function useWidgetCardContext<T extends Partial<Record<keyof T, unknown>>>() {
	const context = useContext<WidgetCardContextValue<T>>(
		WidgetCardContext as unknown as React.Context<WidgetCardContextValue<T>>,
	);

	if (isNull(context)) {
		throw new Error('useWidgetCardContext must be used under WidgetCardContext');
	}

	return context;
}
