import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ComponentProps, forwardRef} from 'react';
import {isNull} from 'underscore';

type Props = Omit<ComponentProps<typeof GridBox>, 'children'> & {
	value: number | null;
	maxValue: number;
	transcriptText?: string | null;
};

export const AnswersInfo = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-card-answers-info', css = {}, value, maxValue, transcriptText, ...props}, forwardedRef) => (
		<GridBox
			dataCy={dataCy}
			css={{
				gap: 2,
				...css,
			}}
			ref={forwardedRef}
			{...props}
		>
			<FlexBox align='baseline' dataCy='answers-info'>
				<Text size='medium' bold font='mono' color='base'>
					{value ?? '-'}
				</Text>
				<Text size='xSmall' font='mono' color='mediumContrast'>
					/{maxValue}
				</Text>
			</FlexBox>
			{!isNull(transcriptText) && (
				<Text size='xSmall' font='mono' color='mediumContrast'>
					{transcriptText}
				</Text>
			)}
		</GridBox>
	),
);
