import {ExpandableSelectableBlockTab, PublishToTeachersNumerable} from '@esgi/main/features/admins/subject-management';
import {PublishForTeacherTabID} from '../../types';
import {isUndefined} from '@esgi/ui';

type Parameters = {
	isPublishForTeacherActive: boolean;
	publishForTeacherData: ExpandableSelectableBlockTab<PublishForTeacherTabID>[];
};

type Out = {
	schools: number[];
	publishToTeachers: PublishToTeachersNumerable;
};

export function getPublishForTeachersSaveData({isPublishForTeacherActive, publishForTeacherData}: Parameters): Out {
	if (!isPublishForTeacherActive) {
		return {
			schools: [],
			publishToTeachers: PublishToTeachersNumerable.None,
		};
	}

	const activeTab = publishForTeacherData.find(({isActive}) => isActive);

	if (isUndefined(activeTab)) {
		throw new Error('activeTab is undefined');
	}

	if (activeTab.value === PublishForTeacherTabID.All) {
		return {
			schools: undefined as unknown as number[],
			publishToTeachers: PublishToTeachersNumerable.All,
		};
	}

	if (activeTab.value === PublishForTeacherTabID.BySchool) {
		return {
			schools: activeTab.selectedItems,
			publishToTeachers: PublishToTeachersNumerable.Schools,
		};
	}

	throw new Error('activeTab.value is not correspond the PublishForTeacherTabID enum');
}
