import {ThemeColorKeys, getThemeColor} from '@esgi/ui';

type Props = {
	strokeColor: ThemeColorKeys;
};

export function CartesianLine({strokeColor}: Props) {
	return (
		<svg width='100%' height='1' xmlns='http://www.w3.org/2000/svg'>
			<line
				x1={0}
				y1={0}
				x2='100%'
				y2={0}
				strokeMiterlimit={1}
				strokeLinejoin='bevel'
				strokeWidth={1}
				strokeDasharray='1 2'
				stroke={getThemeColor(strokeColor)}
			/>
		</svg>
	);
}
