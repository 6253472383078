import {styled} from '@esgi/ui/theme';
import {Table as BaseTable, TableRow as TableRowBase} from '../../../table.styled';
import {GridBox} from '@esgi/ui/layout';

const deltaCellWidth = 32;

export const Table = styled(BaseTable, {
	paddingBottom: '$4',
});

export const TableRow = styled(TableRowBase, {
	gridTemplateColumns: `1fr 1fr 1fr 48px 80px 80px ${deltaCellWidth}px`,
});

export const DeltaCell = styled(GridBox, {
	alignItems: 'center',
	justifyContent: 'center',

	'& > div': {
		width: deltaCellWidth,
		height: 16,
	},
});
