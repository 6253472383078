import {useMemo, useState} from 'react';
import {CardsSortBy, Subject} from '../types/section';
import {allGradeLevel} from '../constants';
import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';

type Parameters = {
	allSubjects: Subject[];
};

export function usePanelState({allSubjects}: Parameters) {
	const uniqGradeLevelsIDs = useMemo(
		() => [...new Set(allSubjects.flatMap(({gradeLevels}) => gradeLevels))],
		[allSubjects],
	);

	const [selectedGradeLevelID, setSelectedGradeLevelID] = useState(allGradeLevel.id);
	const [cardsSortBy, setCardsSortBy] = useState(CardsSortBy.Default);
	const [showInColor, setShowInColor] = useState(true);
	const [showEachStudent, setShowEachStudent] = useState(false);

	const [selectedSubject, setSelectedSubject] = useState<SubjectModel | null>(null);

	return {
		uniqGradeLevelsIDs,
		selectedGradeLevelID,
		setSelectedGradeLevelID,
		cardsSortBy,
		setCardsSortBy,
		showInColor,
		setShowInColor,
		showEachStudent,
		setShowEachStudent,
		selectedSubject,
		setSelectedSubject,
	};
}
