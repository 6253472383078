import {TestCard} from '@esgi/main/kits/test-results-panel';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Header = styled(TestCard.Header, {
	display: 'grid',
	gap: '$3',
	alignItems: 'center',

	variants: {
		withStateStandarts: {
			true: {
				gridTemplateColumns: 'repeat(3, auto) 1fr',
			},

			false: {
				gridTemplateColumns: 'repeat(2, auto) 1fr',
			},
		},
	},
});

export const ActionsBox = styled(GridBox, {
	gridAutoFlow: 'column',
	gridAutoColumns: 'max-content',
	alignItems: 'center',
	gap: '$3',
	justifySelf: 'end',
});

export const SelfAssessIconBox = styled(FlexBox, {
	borderStyle: 'solid',
	borderWidth: 2,
	borderColor: 'currentColor',
	borderRadius: '50%',
	overflow: 'hidden',
});

export const SelfAssessButton = styled(TestCard.SelfAssessButton, {
	'& svg': {
		transform: 'scale(1.6) translateY(3px) translateX(1px)',
	},
});
