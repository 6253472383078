import {ContentBox, UserNameBox} from '../../../components/content-box.styled';
import {DisabledInput} from '../../../components/disabled-input';
import {nameTitleOptions} from './constants';
import {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {NameTitle, SavePersonalDataBody, UserCountry, UserPhoto, UserState} from '../../../../../types';
import {Input} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {ObservableBuilder} from '@esgi/api';
import {PanelContent} from '../../../components/panels.styled';
import {OptionItem, Select} from '@esgi/main/features/teacher/home';
import {Drawer} from '@esgi/main/kits/common';
import {PanelHeaderTitle} from '../../../components/panel-header-title';
import {ExpirationDate} from '../../../../expiration-date';
import {Logout} from '../../../../logout';
import {CroppedImage} from '@esgi/ui';
import {isNull} from 'underscore';
import {NoAvatar} from '../user-avatar-variant/no-avatar';
import {WithAvatar} from '../user-avatar-variant/with-avatar';
import {getPhotoData} from './helpers/get-photo-data';
import {isPhotoAndControlledAvatarEqual} from './helpers/is-photo-and-controlled-avatar-equal';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {DrawerPanelHeader} from '../../index.styled';

type Props = {
	firstName: string;
	lastName: string;
	email: string;
	nameTitle: NameTitle;
	countryId: UserCountry['countryID'];
	countries: UserCountry[];
	stateId: UserState['stateID'];
	states: UserState[];
	getStatesByCountryID: (countryID: number) => ObservableBuilder<UserState[]>;
	savePersonalData: (body: SavePersonalDataBody) => void;
	photo: UserPhoto | null;
	expirationDate: Date;
	isUserDataLoading: boolean;
};

export function PersonalInformationContent({
	firstName,
	lastName,
	email,
	nameTitle: initialNameTitle,
	countryId,
	stateId,
	countries,
	states,
	getStatesByCountryID,
	savePersonalData,
	photo,
	expirationDate,
	isUserDataLoading,
}: Props) {
	const [nameTitle, setNameTitle] = useState(initialNameTitle);
	const [controlledFirstName, setControlledFirstName] = useState(firstName);
	const [controlledLastName, setControlledLastName] = useState(lastName);
	const [selectedCountryId, setSelectedCountryId] = useState(String(countryId));
	const [selectedStateId, setSelectedStateId] = useState(String(stateId));

	const [controlledUserAvatar, setControlledUserAvatar] = useState<CroppedImage | null>(null);

	useEffect(() => {
		setControlledUserAvatar(isNull(photo)
			? null
			: {image: photo.imageUrl, crop: photo.crop, croppedImage: photo.imageCropUrl},
		);
	}, [photo]);

	const getUpdatedStates = useCallback((states: UserState[]) => (
		states.map<OptionItem>(({stateID, name}) => ({
			label: name,
			value: String(stateID),
		}))
	), []);

	const cacheStatesByCountryId = useRef(
		new Map<string, OptionItem[]>([[String(countryId), getUpdatedStates(states)]])
	);

	const handleNameChange = useCallback((event: ChangeEvent<HTMLInputElement>, nameType: 'firstName' | 'lastName') => {
		const value = event.target.value;

		if (nameType === 'firstName') {
			setControlledFirstName(value);
		}

		if (nameType === 'lastName') {
			setControlledLastName(value);
		}
	}, []);

	const updatedCountryList = useMemo(() => (
		countries.map<OptionItem>(({countryID, name}) => ({
			label: name,
			value: String(countryID),
		}))
	), [countries]);

	const isFieldsEmpty = useMemo(() => (
		!controlledFirstName?.trim() || !controlledLastName?.trim()
	), [controlledFirstName, controlledLastName]);

	const isActionButtonActive =
		controlledFirstName !== firstName ||
		controlledLastName !== lastName ||
		nameTitle !== initialNameTitle ||
		selectedCountryId !== String(countryId) ||
		selectedStateId !== String(stateId) ||
		!isPhotoAndControlledAvatarEqual({photo, controlledUserAvatar});

	const handleCountryIdChange = useCallback((value: string) => {
		setSelectedCountryId(value);

		if (value === String(countryId)) {
			setSelectedStateId(String(stateId));
			return;
		}

		const stateFromCache = cacheStatesByCountryId.current.get(value);

		if (stateFromCache) {
			setSelectedStateId(String(stateFromCache[0]?.value ?? ''));
			return;
		}

		getStatesByCountryID(Number(value)).subscribe({
			next: (states) => {
				cacheStatesByCountryId.current.set(value, getUpdatedStates(states));
				setSelectedStateId(String(states[0]?.stateID ?? ''));
			},
		});
	}, [countryId, getStatesByCountryID, stateId, getUpdatedStates]);

	const handleSave = () => {
		const {newPhoto} = getPhotoData({
			currentPhoto: photo,
			newPhoto: controlledUserAvatar,
		});

		savePersonalData({
			firstName: controlledFirstName,
			lastName: controlledLastName,
			stateID: Number(selectedStateId),
			title: nameTitle,
			countryID: Number(selectedCountryId),
			newPhoto,
		});
	};

	return (
		<>
			<DrawerPanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={handleSave}
				actionButtonDisabled={isFieldsEmpty || !isActionButtonActive || isUserDataLoading}
			>
				<PanelHeaderTitle title='Personal Information' />
			</DrawerPanelHeader>
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<PanelContent>
					<ContentBox>
						<Drawer.ContentBlock title='Profile Picture'>
							<GridBox dataCy='profile-picture-box' justify='center'>
								{isNull(controlledUserAvatar) ? (
									<NoAvatar setUserAvatar={setControlledUserAvatar} />
								) : (
									<WithAvatar setUserAvatar={setControlledUserAvatar} userAvatar={controlledUserAvatar} />
								)}
							</GridBox>
						</Drawer.ContentBlock>
						<Drawer.ContentBlock title='Name'>
							<UserNameBox>
								<Select<NameTitle>
									dataCy='user-title-select'
									items={nameTitleOptions}
									onValueChange={setNameTitle}
									placeholder='Title'
									selectedValue={nameTitle}
								/>
								<Input
									dataCy='first-name-input'
									placeholder='First Name'
									value={controlledFirstName}
									onChange={(event) => handleNameChange(event, 'firstName')}
								/>
								<Input
									dataCy='last-name-input'
									placeholder='Last Name'
									value={controlledLastName}
									onChange={(event) => handleNameChange(event, 'lastName')}
								/>
							</UserNameBox>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Location'>
							<GridBox dataCy='location-box' columns='2' gap='3'>
								<Select
									dataCy='location-country-select'
									items={updatedCountryList}
									onValueChange={handleCountryIdChange}
									placeholder='Country'
									selectedValue={selectedCountryId}
								/>
								<Select
									dataCy='location-state-select'
									items={cacheStatesByCountryId.current.get(selectedCountryId) ?? []}
									onValueChange={setSelectedStateId}
									placeholder='State'
									selectedValue={selectedStateId}
								/>
							</GridBox>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Email'>
							<DisabledInput
								value={email}
								placeholder='Email'
								inputTranscript='To change your email, please contact our Customer Support team or your system administrator.'
								dataCy='email-disabled-input'
							/>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Expiration Date'>
							<ExpirationDate date={expirationDate} />
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Terminate Current Session'>
							<Logout />
						</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>
			</OverlayScrollbarsComponent>
		</>
	);
}
