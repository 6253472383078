import {
	LowestAchievementWidgetOptionsContract,
	LowestAchievementWidgetOptions,
	LowestAchievementWidgetViewType,
	LowestAchievementWidgetViewTypeNumerable,
} from '@esgi/main/features/district-admin/dashboard';
import {isNull} from 'underscore';
import {getAdaptedArraysWidgetData} from '../../helpers/get-adapted-arrays-widget-data';
import {enumNumberToString} from '../enum-number-to-string';
import {
	Color,
	ColorNumerable,
	ContentAreaModel,
	DeployedSubject,
	GradeLevel,
	PeriodType,
	PeriodTypeNumerable,
	SchoolModel,
} from '@esgi/main/features/admins/dashboard';

export function parseLowestAchievementOptions({
	options,
	schools,
	deployedSubjects,
	gradeLevels,
	contentAreasList,
}: {
	options: LowestAchievementWidgetOptionsContract | null;
	schools: SchoolModel[];
	deployedSubjects: DeployedSubject[];
	gradeLevels: GradeLevel[];
	contentAreasList: ContentAreaModel[];
}): LowestAchievementWidgetOptions | null {
	if (isNull(options)) {
		return null;
	}

	const {contentAreaIDs, gradeLevelIDs, schoolIDs, subjectIDs, viewType, periods, ...restOptions} = options;

	return {
		...restOptions,
		/**
		 * For the following entities, if the data array is empty, it means all entities are selected:
		 * - contentAreaIDs,
		 * - gradeLevelIDs,
		 * - schoolIDs,
		 * - subjectIDs
		 */
		contentAreaIDs: getAdaptedArraysWidgetData({
			data: contentAreaIDs,
			fullData: contentAreasList,
			iteratedKey: 'id',
		}),
		gradeLevelIDs: getAdaptedArraysWidgetData({
			data: gradeLevelIDs,
			fullData: gradeLevels,
			iteratedKey: 'id',
		}),
		schoolIDs: getAdaptedArraysWidgetData({
			data: schoolIDs,
			fullData: schools,
			iteratedKey: 'id',
		}),
		subjectIDs: getAdaptedArraysWidgetData({
			data: subjectIDs,
			fullData: deployedSubjects,
			iteratedKey: 'id',
		}),
		viewType: enumNumberToString(viewType, LowestAchievementWidgetViewTypeNumerable) as LowestAchievementWidgetViewType,
		periods: periods.map(({color, datePeriod, dateRange, trackDatesID}) => ({
			dateRange: isNull(dateRange) ? null : dateRange,
			datePeriod: enumNumberToString(datePeriod, PeriodTypeNumerable) as PeriodType,
			trackDatesID,
			color: enumNumberToString(color, ColorNumerable) as Color,
		})),
	};
}
