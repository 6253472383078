import {GridBox} from '@esgi/ui/layout';
import {MainSettingsBox} from './index.styled';
import {districtCutoffItems, viewTypeItems} from './constants';
import {ContentArea, Subject} from '@esgi/ui/icons';
import {Dispatch} from 'react';
import {CommonForm} from '../../forms-data/types';
import {DynamicFormName} from '../../types';
import {useDynamicAdaptedItems} from './use-dynamic-adapted-items';
import {
	DeployedSubject,
	FormToggleGroup,
	GradeLevel,
	SchoolModel,
	SelectDrawerable,
	Widget,
	WidgetCard,
} from '@esgi/main/features/admins/dashboard';

type Props = {
	commonForm: CommonForm;
	subjects: DeployedSubject[];
	selectedContentArea: string[];
	setSelectedContentArea: Dispatch<string[]>;
	selectedSubjectIDs: string[];
	setSelectedSubjectIDs: Dispatch<string[]>;
	selectedGradeLevels: string[];
	setSelectedGradeLevels: Dispatch<string[]>;
	schools: SchoolModel[];
	selectedSchoolsIDs: string[];
	setSelectedSchoolsIDs: Dispatch<string[]>;
	currentDynamicFormName: DynamicFormName | undefined;
	gradeLevels: GradeLevel[];
};

export function MainSettings({
	commonForm,
	subjects,
	selectedContentArea,
	selectedGradeLevels,
	selectedSubjectIDs,
	setSelectedContentArea,
	setSelectedGradeLevels,
	setSelectedSubjectIDs,
	schools,
	selectedSchoolsIDs,
	setSelectedSchoolsIDs,
	currentDynamicFormName,
	gradeLevels,
}: Props) {
	const {allContentAreas} = WidgetCard.useWidgetCardContext();
	const {schoolsAdaptedItems, subjectsAdaptedItems, gradeLevelsAdaptedItems, contentAreasAdaptedItems} =
		useDynamicAdaptedItems({schools, subjects, gradeLevels});

	return (
		<MainSettingsBox>
			<GridBox flow='column' gap='2'>
				<WidgetCard.EditMode.FormElement control={commonForm.controls.viewType}>
					<FormToggleGroup items={viewTypeItems} />
				</WidgetCard.EditMode.FormElement>
			</GridBox>

			<GridBox gap='2'>
				<Widget.FormSelect
					placeholder='District Cutoff'
					items={districtCutoffItems}
					control={commonForm.controls.districtCutoff}
				/>

				<SelectDrawerable
					placeholder='Schools'
					sectionName='Schools'
					value={selectedSchoolsIDs}
					onSaveSelectedEntity={setSelectedSchoolsIDs}
					selectedValueTranscript='Schools'
					allItems={schoolsAdaptedItems}
					EntityIcon={Subject}
					disabled={currentDynamicFormName === 'schools'}
					disabledMessage='All'
				/>

				<SelectDrawerable
					placeholder='Content Areas'
					sectionName='Content Areas'
					value={selectedContentArea}
					onSaveSelectedEntity={setSelectedContentArea}
					selectedValueTranscript='Content Areas'
					allItems={contentAreasAdaptedItems}
					EntityIcon={ContentArea}
				/>

				<SelectDrawerable
					placeholder='Subject Tabs'
					sectionName='Subjects'
					value={selectedSubjectIDs}
					onSaveSelectedEntity={setSelectedSubjectIDs}
					selectedValueTranscript='Subjects'
					allItems={subjectsAdaptedItems}
					EntityIcon={Subject}
				/>

				<SelectDrawerable
					placeholder='Grade Levels'
					sectionName='Grade Levels'
					value={selectedGradeLevels}
					onSaveSelectedEntity={setSelectedGradeLevels}
					selectedValueTranscript='Grade Levels'
					allItems={gradeLevelsAdaptedItems}
					EntityIcon={Subject}
				/>
			</GridBox>
		</MainSettingsBox>
	);
}
