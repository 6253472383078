import {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {getThemeColor, ThemeColorKeys} from '@esgi/ui';
import {Property} from '@stitches/react/types/css';
import {useBarChartContext} from '../../context';
import {Container, ProgressBox} from './index.styled';
import {useLineAnimation} from '../../hooks/use-line-animation';

type Props = Omit<ComponentPropsWithoutRef<typeof Container>, 'children'> & {
	height: Property.Height;
	color: ThemeColorKeys;
	borderRadius?: Property.BorderRadius;
	startValue: number;
	endValue: number;
};

export const ProgressLine = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'bar-chart-progress-line', css = {}, height, color, borderRadius = 2, startValue, endValue, ...divProps},
		forwardedRef,
	) => {
		const {getValueProgress} = useBarChartContext();

		const {startProgress, progress} = useMemo(() => {
			const startProgress = getValueProgress(startValue);
			const endProgress = getValueProgress(endValue);

			const progress = endProgress - startProgress;

			return {
				startProgress,
				progress,
			};
		}, [endValue, getValueProgress, startValue]);

		const animationProps = useLineAnimation({width: progress * 100});

		return (
			<Container
				dataCy={dataCy}
				css={{
					height,
					...css,
				}}
				ref={forwardedRef}
				{...divProps}
			>
				<ProgressBox
					style={animationProps}
					css={{
						left: `${startProgress * 100}%`,
						backgroundColor: getThemeColor(color),
						borderRadius,
					}}
				/>
			</Container>
		);
	},
);
