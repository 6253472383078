import {DatePickerData} from '@esgi/main/kits/admin';
import {FormControl, FormGroup} from '@esgi/ui/form';
import {BoxplotTwoPeriodsFormColorItem} from '../../../../../forms-data/types';
import {useFilteredColorItems} from '../../../use-filtered-color-items';
import {FormColorPicker, DatePickerForm, WidgetCard, Color} from '@esgi/main/features/admins/dashboard';

type Props = {
	form: FormGroup<any, any>;
	dateRangeControl: FormControl<DatePickerData>;
	colorControl: FormControl<BoxplotTwoPeriodsFormColorItem>;
	excludedColor: Color | null;
};

export function PeriodRow({form, colorControl, excludedColor, dateRangeControl}: Props) {
	const filteredColorItems = useFilteredColorItems({excludedColor});

	return (
		<WidgetCard.EditMode.PeriodColorBox>
			<WidgetCard.EditMode.FormElement control={colorControl}>
				<FormColorPicker items={filteredColorItems} />
			</WidgetCard.EditMode.FormElement>
			<WidgetCard.EditMode.FormElement control={dateRangeControl}>
				<DatePickerForm form={form} />
			</WidgetCard.EditMode.FormElement>
		</WidgetCard.EditMode.PeriodColorBox>
	);
}
