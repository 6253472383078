import {ComponentPropsWithoutRef, forwardRef, ReactElement, SVGProps} from 'react';
import {Button, Tooltip} from '@esgi/ui';
import {Folder} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';

type Props = Omit<ComponentPropsWithoutRef<typeof Button.Icon>, 'children'> & {
	children?: ReactElement<SVGProps<SVGSVGElement>>;
	tipText?: string;
};

export const AddToFolderButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			dataCy = 'add-to-folder-button',
			css = {},
			withBackgroundHover = true,
			children,
			tipText = 'Create/Add to Folder',
			...props
		},
		forwardedRef,
	) => (
		<Tooltip>
			<Tooltip.Trigger>
				<Button.Icon dataCy={dataCy} css={css} withBackgroundHover={withBackgroundHover} ref={forwardedRef} {...props}>
					{children ?? <Folder />}
				</Button.Icon>
			</Tooltip.Trigger>
			<Tooltip.Content variant='default'>
				<Text size='large'>{tipText}</Text>
			</Tooltip.Content>
		</Tooltip>
	),
);
