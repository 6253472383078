import {useCallback, useMemo, useState} from 'react';

interface Entity {
	id: number;
}

interface Parameters {
	selectedItems: Entity[]
}

export function useEntityDeletionState({selectedItems}: Parameters) {
	const [showRemoveEntity, setShowRemoveEntity] = useState(false);
	const [showRemoveEntityUnavailable, setShowRemoveEntityUnavailable] = useState(false);

	const entityIDs = useMemo(() => (
		selectedItems.map((item) => item.id)
	), [selectedItems]);

	const handleOpenRemoveEntity = useCallback(() => {
		setShowRemoveEntity(true);
	}, []);

	const handleCloseRemoveEntity = useCallback(() => {
		setShowRemoveEntity(false);
	}, []);

	const handleOpenRemoveEntityUnavailable = useCallback(() => {
		setShowRemoveEntityUnavailable(true);
	}, []);

	const handleCloseRemoveEntityUnavailable = useCallback(() => {
		setShowRemoveEntityUnavailable(false);
	}, []);

	return {
		entityIDs,
		showRemoveEntity,
		showRemoveEntityUnavailable,
		onOpenRemoveEntity: handleOpenRemoveEntity,
		onCloseRemoveEntity: handleCloseRemoveEntity,
		onOpenRemoveEntityUnavailable: handleOpenRemoveEntityUnavailable,
		onCloseRemoveEntityUnavailable: handleCloseRemoveEntityUnavailable,
	};
}