import {Dispatch, useCallback, useEffect, useMemo} from 'react';
import {Widget} from '@esgi/main/features/district-admin/dashboard';
import {useGridStack as useGridStackBase, GridStackOptions} from '@esgi/ui/gridstack';
import {getGridstackItemPosition} from '../../utils/get-gridstack-item-position';
import {isUndefined} from '@esgi/ui';
import {cardsGap, rearrangeHandleClassName} from '../../constants';
import {gridStackStaticInitOptions, minRows} from './constants';
import {drag} from './event-handlers/drag';
import {getItemPosition} from './utils/get-item-position';
import {sortBy} from 'underscore';
import {widgetCardHeight} from '@esgi/main/features/admins/dashboard';

export function useGridStack({
	itemsCountInRow,
	widgetsList,
	isRearrangeMode,
	updateWidgetsOrdering,
}: {
	itemsCountInRow: number;
	widgetsList: Widget[];
	isRearrangeMode: boolean;
	updateWidgetsOrdering: Dispatch<Widget['id'][]>;
}) {
	const {adaptedGridStackItems, addCardPosition} = useMemo(() => {
		const calculateItemPosition = getGridstackItemPosition(itemsCountInRow);

		const adaptedGridStackItems = widgetsList.map((widgetModel, index) => {
			const position = getItemPosition({options: widgetModel.options, itemIndex: index, calculateItemPosition});

			return {
				...widgetModel,
				...position,
			};
		});

		const addCardPosition = calculateItemPosition({itemIndex: adaptedGridStackItems.length});

		return {
			adaptedGridStackItems,
			addCardPosition,
		};
	}, [itemsCountInRow, widgetsList]);

	const gridStackInstanceOptions = useMemo(() => {
		let fullSizesWidgets = 0;

		const maxY = Math.max(
			...[...adaptedGridStackItems, addCardPosition].map(({y, w}) => {
				if (w === itemsCountInRow) {
					fullSizesWidgets++;
				}

				return y;
			}),
		);

		const options: GridStackOptions = {
			...gridStackStaticInitOptions,
			cellHeight: 2 * cardsGap + widgetCardHeight,
			column: itemsCountInRow,
			maxRow: Math.max(maxY + fullSizesWidgets + 1, minRows),
			disableDrag: !isRearrangeMode,
		};

		return options;
	}, [adaptedGridStackItems, isRearrangeMode, itemsCountInRow, addCardPosition]);

	const {
		gridStackContainerRef,
		gridStackInstance,
		itemsIndexPosition,
		isItemsPositionsChanged,
		itemsWithRef,
		draggedItemID,
		gridStackManagerRef,
	} = useGridStackBase({
		items: adaptedGridStackItems,
		gridStackInstanceOptions,
	});

	useEffect(() => {
		if (!isUndefined(gridStackInstance)) {
			gridStackInstance.on('drag', (_, element) => {
				drag({element, gridStackInstance});
			});
		}
	}, [gridStackInstance]);

	useEffect(() => {
		gridStackManagerRef.current?.updateItemsIndexPosition();
	}, [isRearrangeMode]);

	const onSaveWidgetsOrdering = useCallback(
		() =>
			updateWidgetsOrdering(sortBy(Object.entries(itemsIndexPosition), ([, index]) => index).map(([id]) => Number(id))),
		[itemsIndexPosition, updateWidgetsOrdering],
	);

	return {
		gridStackContainerRef,
		itemsIndexPosition,
		widgetItemsWithRef: itemsWithRef,
		draggedWidgetID: draggedItemID,
		rearrangeHandleClassName,
		isItemsPositionsChanged,
		onSaveWidgetsOrdering,
		addCardPosition,
	};
}
