import {Content, SkeletonCard} from './index.styled';
import {Card} from '../card';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import React, {useCallback} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {NoTestsMessage} from '../../constants';
import {CardViewType, FilterScope, TestModel} from '../../service/types';
import {GradeLevelsMap} from '@esgi/main/kits/common';
import {TestType} from '@esgi/main/libs/core';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {useUser} from '@esgi/core/authentication';
import {SubjectTab} from '@esgi/main/libs/store';
import {NoItems, Skeleton} from '@esgi/main/features/standards-drawer';
import {OverlayScrollbars} from 'overlayscrollbars';

type Props = BaseComponentProps & {
	selectedStandardType: number;
	selectedStandardsIDs: number[];
	tests: Array<TestModel & {subjects: SubjectTab[]}>;
	selectedTestIDs: TestModel['testID'][];
	showOnlySelectedTests: boolean;
	onTestSelect: (testID: TestModel['testID']) => void;
	onAddToFavoriteClick: (test: TestModel) => void;
	showTestDetails: (testID: TestModel['testID'], testType: TestType) => (changedTest: Partial<TestModel>) => void;
	onNextPage: VoidFunction;
	skeleton: boolean;
	isTestsFetching: boolean;
	scope: FilterScope;
	cardView: CardViewType;
	isSmallScreen: boolean;
	osRef: React.MutableRefObject<OverlayScrollbarsComponentRef<'div'>>;
};

export function TestsList({
	selectedStandardsIDs,
	selectedStandardType,
	tests,
	selectedTestIDs,
	onTestSelect,
	onAddToFavoriteClick,
	onNextPage,
	skeleton,
	scope,
	cardView,
	showTestDetails,
	osRef,
	isSmallScreen,
	isTestsFetching,
}: Props) {
	const user = useUser();

	const handleVisibility = useCallback(
		(instance: OverlayScrollbars) => {
			const elements = instance.elements();
			const state = instance.state();

			const currentScrollPosition = elements.viewport.scrollTop;
			const overflowAmountY = state.overflowAmount.y;
			const thresholdPosition = 100;

			const isMakeRequestByScrollPosition = currentScrollPosition + thresholdPosition > overflowAmountY;

			if (!skeleton && !isTestsFetching && isMakeRequestByScrollPosition) {
				onNextPage();
			}
		},
		[isTestsFetching, onNextPage, skeleton],
	);

	if (!skeleton && !isTestsFetching && !tests.length) {
		if (scope === FilterScope.DistrictTests) {
			const userIsNotLinked = user?.agreementLevelCode === 'T';

			return <NoItems message={NoTestsMessage[userIsNotLinked ? FilterScope.DistrictTests : FilterScope.AllTests]} />;
		}

		return <NoItems message={NoTestsMessage[scope]} />;
	}

	const renderTests = () => {
		if (skeleton) {
			return <Skeleton />;
		}

		return tests.map((test) => (
			<Card
				{...test}
				selectedStandardsIDs={selectedStandardsIDs}
				selectedStandardType={selectedStandardType}
				key={test.testID + test.createDate}
				selected={selectedTestIDs.includes(test.testID)}
				cardView={cardView}
				onTestSelect={onTestSelect}
				showTestDetails={() => showTestDetails(test.testID, test.type)(null)}
				onAddToFavoriteClick={() => onAddToFavoriteClick(test)}
				gradeLevels={test.gradeLevels.map((level) => GradeLevelsMap[level]?.shortName ?? '')}
				showAddToFavorites={scope !== FilterScope.DraftTests}
				isHidden={test.hidden}
				isSmallScreen={isSmallScreen}
			/>
		));
	};

	return (
		<OverlayScrollbarsComponent
			ref={osRef}
			defer
			options={{scrollbars: {autoHide: 'leave'}}}
			events={{
				scroll: handleVisibility,
			}}
		>
			<Content gridView={cardView === CardViewType.Grid}>
				{renderTests()}
				{isTestsFetching && <SkeletonCard />}
			</Content>
		</OverlayScrollbarsComponent>
	);
}
