import {
	SortOption,
	StudentLunchStatusNumerable,
	StudentRaceNumerable,
	StudentSpecialProgramNumerable,
} from '@esgi/main/features/admins/dashboard';
import {WidgetSortBy} from './types';

export const studentSpecialProgramLabel: Record<StudentSpecialProgramNumerable, string> = {
	[StudentSpecialProgramNumerable.GiftedTalented]: 'Gifted/Talented',
	[StudentSpecialProgramNumerable.SPED]: 'SPED',
	[StudentSpecialProgramNumerable.RTI]: 'RTI',
	[StudentSpecialProgramNumerable.ELLESL]: 'ELL/ESL',
	[StudentSpecialProgramNumerable.FiveOuFour]: '504',
};

export const studentLunchStatusLabel: Record<StudentLunchStatusNumerable, string> = {
	[StudentLunchStatusNumerable.Free]: 'Free',
	[StudentLunchStatusNumerable.Reduced]: 'Reduced',
	[StudentLunchStatusNumerable.Other]: 'Other',
};

export const studentRaceLabel: Record<StudentRaceNumerable, string> = {
	[StudentRaceNumerable.Asian]: 'Asian',
	[StudentRaceNumerable.BlackOrAfricanAmerican]: 'Black or African American',
	[StudentRaceNumerable.HispanicOrLatino]: 'Hispanic or Latino',
	[StudentRaceNumerable.Multiethnic]: 'Multi-ethnic',
	[StudentRaceNumerable.NativeAmericanOrAlaskaNative]: 'Native American or Alaska Native',
	[StudentRaceNumerable.NativeHawaiianOrPacificIslander]: 'Native Hawaiian or Pacific Islander',
	[StudentRaceNumerable.WhiteOrCaucasian]: 'White or Caucasian',
	[StudentRaceNumerable.MiddleEasternOrNorthAfrican]: 'Middle Eastern or North African',
	[StudentRaceNumerable.Other]: 'Other',
};

export const singleChartSortOption: SortOption<WidgetSortBy>[] = [
	{
		value: WidgetSortBy.Alphabetical,
		label: 'Alphabetical Order',
	},
	{
		value: WidgetSortBy.MedianPerformance,
		label: 'Median Performance',
	},
	{
		value: WidgetSortBy.ConsistencyPerformance,
		label: 'Consistency in Performance',
	},
	{
		value: WidgetSortBy.ScoreSpread,
		label: 'Score Spread (Range)',
	},
];

export const multipleChartSortOption: SortOption<WidgetSortBy>[] = [
	{
		value: WidgetSortBy.Alphabetical,
		label: 'Alphabetical Order',
	},
	{
		value: WidgetSortBy.Delta,
		label: 'Delta',
	},
];
