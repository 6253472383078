import {PropsWithChildren, useCallback} from 'react';
import {SortBy, Sorting, SortDirection} from '../../../../service/types';
import {HeaderCell as StyledHeaderCell, Text} from '../../index.styled';
import {FieldType} from '../../types';
import {SortIndicator} from './index.styled';

type Props = {
	name: FieldType,
	sorting: Sorting,
	onClick: VoidFunction,
	disabled?: boolean,
} & PropsWithChildren;

export function HeaderCell({name, sorting, onClick, disabled, children}: Props) {
	const active = sorting.by === SortBy[name] && !disabled;

	const handleClick = useCallback(() => {
		if (!disabled) {
			onClick();
		}
	}, [disabled, onClick]);

	return (
		<StyledHeaderCell dataCy='header-cell'>
			{children}
			<Text
				size='small'
				color={active ? 'primary' : 'lowContrast'}
				disabled={disabled}
				onClick={handleClick}
			>
				{name}
			</Text>
			<SortIndicator
				disabled={!active}
				direction={!active ? SortDirection.None : sorting.direction}
				onClick={onClick}
			/>
		</StyledHeaderCell>
	);
}