import {DashboardMetricData} from '@esgi/main/features/district-admin/dashboard';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';

export function getWidgetData<Key extends keyof DashboardMetricData>({
	allData,
	dataKey,
}: {
	allData: DashboardMetricData | null | undefined;
	dataKey: Key;
}): 'isDataCounting' | DashboardMetricData[Key] | null {
	if (isUndefined(allData)) {
		return null;
	}

	if (isNull(allData)) {
		return 'isDataCounting';
	}

	return allData[dataKey];
}
