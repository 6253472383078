import {intersection} from 'underscore';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {Correct, Expand} from '@esgi/ui';
import {GridBox, SelectableList} from '@esgi/ui/layout';
import {SchoolSpecialist} from '@esgi/contracts/esgi/types/sso.accounts/features/esgi.districtadmin/schools-specialist/get-by-school-id/school-specialist';
import {DistrictSpecialist} from '@esgi/contracts/esgi/types/sso.accounts/features/esgi.districtadmin/district-specialist/get-by-district-id/district-specialist';
import {StyledIconBefore, StyledSelectableListItem, StyledSelectableListTrigger} from './index.styled';

interface Props {
	item: SchoolSpecialist | DistrictSpecialist;
	groupIDs: string[];
	onChange: (groupIDs: string[]) => void;
}

export function SelectableGroup({item, groupIDs, onChange}: Props) {
	const [isListOpen, setIsListOpen] = useState(false);

	const isGroupExpandable = useMemo(() => (
		item.groups.length > 0
	), [item.groups]);

	const handleToggleOpenList = useCallback(() => {
		setIsListOpen((value) => !value);
	}, []);

	const hasIntersectionGroups = useCallback((selectedGroups: string[]) => {
		const groupStringIDs = item.groups.map(value => String(value.groupID));
		return intersection(groupStringIDs, selectedGroups).length > 0;
	}, [item.groups]);

	const handleChangeGroupIDs = useCallback((groups: string[]) => {
		onChange(groups);
	}, [onChange]);

	useEffect(() => {
		if (!hasIntersectionGroups(groupIDs)) {
			return;
		}

		setIsListOpen(true);
	}, [hasIntersectionGroups]);

	return (
		<SelectableList>
			<SelectableList.GroupRoot type='multiple' value={groupIDs} onValueChange={handleChangeGroupIDs}>
				<StyledSelectableListTrigger selected={false} openList={isListOpen} onClick={handleToggleOpenList} expanded={isListOpen} custom>
					<Text size='small' font='mono'>
						{`${item.firstName} ${item.lastName}`}
					</Text>
					{isGroupExpandable && (
						<Expand />
					)}
				</StyledSelectableListTrigger>
				<SelectableList.Group>
					{item.groups.map((group, index) => (
						<StyledSelectableListItem key={group.groupID} value={String(group.groupID)} withActiveBackground={false} custom>
							{(selected) => (
								<GridBox flow='column' align='center' justify='start' gap={2}>
									<StyledIconBefore checked={selected}>
										{selected && <Correct />}
									</StyledIconBefore>
									<Text size='small' font='mono'>
										{group.name}
									</Text>
								</GridBox>
							)}
						</StyledSelectableListItem>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}