import {useMemo} from 'react';
import {SelectedEntityInfo, SelectedEntityInfoItem} from '../../kit';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {GradeLevel} from '../../types';

type Props = {
	gradeLevelIDs: number[];
	gradeLevels: GradeLevel[];
};

export function SelectedGradeLevelsInfo({gradeLevelIDs, gradeLevels}: Props) {
	const gradeLevelItems = useMemo(() => {
		return gradeLevelIDs
			.map<SelectedEntityInfoItem<GradeLevel['id']> | null>((gradeLevelID) => {
				const gradelevel = gradeLevels.find(({id}) => id === gradeLevelID);

				if (isUndefined(gradelevel)) {
					return null;
				}

				return {
					label: gradelevel.name,
					value: gradelevel.id,
				};
			})
			.filter((item): item is SelectedEntityInfoItem<GradeLevel['id']> => !isNull(item));
	}, [gradeLevelIDs, gradeLevels]);

	return (
		<SelectedEntityInfo
			items={gradeLevelItems}
			itemsTranscript='Grade Levels'
			totalPossibleItemsCount={gradeLevels.length}
		/>
	);
}
