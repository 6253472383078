import {ErrorTooltipItem} from '../../../../../components/error-tooltip';

export const schoolSelectErrorItems: ErrorTooltipItem<string>[] = [
	{
		showOnError: 'required',
		message: 'School is required',
	},
];

export const specialistsSelectErrorItems: ErrorTooltipItem<string>[] = [
	{
		showOnError: 'required',
		message: 'Teacher is required',
	},
];
