import {ContextMenu} from '@esgi/ui';
import {Box, Card} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const CardHeader = styled(Card.Header, {
	display: 'grid',
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: '$4',
	minHeight: 32,
});

export const ButtonsDivider = styled(Box, {
	width: 1,
	height: 20,
	borderRadius: 1,
	backgroundColor: '$border',
});

export const SortingOption = styled(ContextMenu.Item, {
	padding: 8,
	display: 'grid',
	alignItems: 'center',
	gap: '$2',
	gridTemplateColumns: 'auto 1fr',

	variants: {
		isActive: {
			true: {
				backgroundColor: '$primaryBackground',

				[`> ${Text}`]: {
					color: '$primary',
				},
			},
		},
	},
});
