import {FolderReportModel} from '../../../../_kit';

export const folderReports: FolderReportModel[] = [
	{
		type: 'GradeScale',
		name: 'Specialist Group Grades',
		description: 'Apply customizable grade scales to student scores',
	},
	{
		type: 'Growth',
		name: 'Specialist Group Growth',
		description: 'View growth on tests in a Subject Tab',
	},
	{
		type: 'TotalStudents',
		name: 'Specialist Group Students',
		description: 'Sortable Subject Tab report of growth over time',
	},
	{
		type: 'Totals',
		name: 'Specialist Group Totals',
		description: 'Review and compare teacher scores for a subject tab',
	},
	{
		type: 'ItemAnalysis',
		name: 'Item Analysis',
		description: 'Analyze data based on least and most known items',
	},
	{
		type: 'PieCharts',
		name: 'Pie Charts',
		description: 'Access a printable report of homepage pie charts',
	},
	{
		type: 'UntestedStudents',
		name: 'Untested Students',
		description: 'Identify untested students to administer assessments',
	},
];
