import {useMemo} from 'react';
import {isUndefined, unique} from 'underscore';
import {UserType} from '@esgi/core/authentication';
import {
	useSchools,
	useDistrictSpecialists,
	useSchoolSpecialists,
	useSpecialistGroups,
	usePreAssessSpecialists,
} from '@esgi/main/libs/admin-store';
import {useDisposable, useEndpointResult} from '@esgi/core/service';
import {V2DistrictAdminsPagesDataAnalyticsController} from '@esgi/contracts/esgi';

import {RowDef} from './types';
import {UserTypeOptions} from './constants';

export function useData() {
	const [{data: allSchools}] = useSchools();
	const [{data: districtSpecialists}] = useDistrictSpecialists();
	const [{data: schoolSpecialists}] = useSchoolSpecialists();
	const [{data: preAssessSpecialists}] = usePreAssessSpecialists();
	const [{data: specialistGroups, loaded: groupsLoaded}] = useSpecialistGroups();

	const analyticController = useDisposable(V2DistrictAdminsPagesDataAnalyticsController);
	const analyticsData = useEndpointResult(() => {
		if (groupsLoaded) {
			return analyticController.specialistGroups({specialistGroupIDs: specialistGroups.map((g) => g.id)});
		}
	}, [specialistGroups, groupsLoaded]);

	const {rows, schools, specialists} = useMemo(() => {
		const allSpecialists = [...districtSpecialists, ...schoolSpecialists, ...preAssessSpecialists];
		const rows = specialistGroups.map<RowDef>((group) => {
			const school = allSchools.find((s) => group.schoolID === s.id);
			const specialist = allSpecialists.find((s) => s.id === group.specialistID);
			const analytics = analyticsData?.value?.specialistGroupsAnalytics?.find((g) => g.groupID === group.id);

			if (isUndefined(specialist)) {
				throw new Error('specialist is undefined');
			}

			return {
				id: group.id,
				name: group.name,
				school: school ?? null,
				specialist,
				type:
					group.userType === UserType.ISD
						? UserTypeOptions.ISD
						: group.userType === UserType.ISS
						? UserTypeOptions.ISS
						: UserTypeOptions.PA,
				students: analytics?.studentsCount ?? 0,
			};
		});
		return {
			rows,
			schools: unique(rows.map((r) => r.school)).filter(Boolean),
			specialists: unique(rows.map((r) => r.specialist)).filter(Boolean),
		};
	}, [districtSpecialists, schoolSpecialists, preAssessSpecialists, specialistGroups, allSchools, analyticsData]);

	return {
		ready: groupsLoaded,
		rows,
		schools,
		specialists,
	};
}
