import {AgGrid as AgGridBase} from '@esgi/ui/ag-grid';
import {ComponentProps, useCallback, useEffect, useMemo} from 'react';
import {usePageContentContext} from '../context';
import {GridReadyEvent, GridState} from 'ag-grid-community';

type Props<T extends Record<string, unknown>> = ComponentProps<typeof AgGridBase.Table<T>>

export function AgGrid<T extends Record<string, unknown>>({onGridReady, ...props}: Props<T>) {
	const {setApi, api, columnDefs, initialState, tableRows, isDataLoaded} = usePageContentContext<T>();

	const handleGridReadyHandler = useCallback(
		(params: GridReadyEvent) => {
			setApi(params.api);

			onGridReady?.(params);
		},
		[onGridReady, setApi],
	);

	// useEffect(() => {
	// 	if (api) {
	// 		const stateUpdatedHandler = () => {
	// 			const {filter, sort, columnVisibility} = api.getState();
	// 			sessionStorage.setItem(location.pathname, JSON.stringify({filter, sort, columnVisibility}));
	// 		};

	// 		api.addEventListener('stateUpdated', stateUpdatedHandler);

	// 		return () => {
	// 			api.removeEventListener('stateUpdated', stateUpdatedHandler);
	// 		};
	// 	}
	// }, [api]);

	// const mixedInitialState = useMemo<GridState>(() => {
	// 	const state = sessionStorage.getItem(location.pathname);
	// 	const passedValue = initialState || {};

	// 	if(initialState) {
	// 		return initialState;
	// 	}

	// 	if (state) {
	// 		try {
	// 			const storeValue = JSON.parse(state);
	// 			return storeValue;
	// 		} catch (e) {
	// 			console.error(e);
	// 		}
	// 	}
	// 	return passedValue;
	// }, []);

	return (
		<AgGridBase withoutLastColumnPadding>
			<AgGridBase.Table
				rowSelection='multiple'
				loading={!isDataLoaded}
				columnDefs={columnDefs}
				rowData={tableRows}
				gridOptions={{initialState: initialState ?? undefined}}
				onGridReady={handleGridReadyHandler}
				pagination
				paginationAutoPageSize={false}
				paginationPageSize={15}
				rowMultiSelectWithClick
				suppressPaginationPanel
				{...props}
			/>
		</AgGridBase>
	);
}
