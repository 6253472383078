import {Alert} from '@esgi/ui/alert';
import {AddYesNoSession} from './components/variant-test/add-yes-no-session';
import {AddSingleScoreSession} from './components/variant-test/add-single-score-session';
import {AddRubricSession} from './components/variant-test/add-rubric-session';
import {DefaultSkeleton} from './components/default-skeleton';
import {Student, Test} from '../../types/common';
import {TestType} from '@esgi/main/kits/common';

type Props = {
	onAlertClose?: VoidFunction;
	test: Test;
	student: Student;
	skeleton?: boolean;
};

export function AddSessionDetails({test, onAlertClose, student, skeleton}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onAlertClose);

	const getContent = () => {
		if (skeleton) {
			return <DefaultSkeleton onCloseAlert={closeAlert} />;
		}

		if (test.type === TestType.YN) {
			return <AddYesNoSession student={student} onCloseAlert={closeAlert} test={test} />;
		}

		if (test.type === TestType.Score) {
			return <AddSingleScoreSession student={student} onCloseAlert={closeAlert} test={test} />;
		}

		if (test.type === TestType.Rubric) {
			return <AddRubricSession student={student} onCloseAlert={closeAlert} test={test} />;
		}

		return null;
	};

	return (
		<Alert
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					width: 510,
					maxHeight: 'calc(100% - 40px)',
					gridTemplateRows: 'auto 1fr auto',
					overflow: 'hidden',
				},
			}}
			dataCy='session-details-alert'
		>
			{getContent()}
		</Alert>
	);
}
