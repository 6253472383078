import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {useTestPanelContext} from '../../../../context';
import {SelectField} from './index.styled';

type Props = Omit<ComponentPropsWithoutRef<typeof SelectField>, 'skeleton'>;

export const Field = forwardRef<HTMLButtonElement, Props>(
	({dataCy = 'test-panel-select-field', css = {}, ...props}, forwardedRef) => {
		const {skeleton} = useTestPanelContext();

		return <SelectField dataCy={dataCy} css={css} ref={forwardedRef} {...props} skeleton={skeleton} />;
	},
);
