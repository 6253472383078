import {useMemo} from 'react';
import {isNull} from 'underscore';
import {useFilterGroup} from '../../hooks/use-filter-group';
import {TabID} from './types';
import {TestPanelCard} from '../../../../types';
import {TestType} from '@esgi/main/kits/common';

type Item = {
	value: TabID;
	label: string;
};

export function useTabs<T extends Partial<Record<never, unknown>>>({testCards}: {testCards: TestPanelCard<T>[]}) {
	const items = useMemo(() => {
		const items: Record<Exclude<TestType, TestType.None>, Item | null> = {
			[TestType.YN]: null,
			[TestType.Rubric]: null,
			[TestType.Score]: null,
			[TestType.General]: null,
		};

		for (const {type} of testCards) {
			if (type === TestType.YN && isNull(items[type])) {
				items[type] = {
					value: TabID.YesNo,
					label: 'Yes/No',
				};
			}

			if (type === TestType.Rubric && isNull(items[type])) {
				items[type] = {
					value: TabID.Rubric,
					label: 'Rubric',
				};
			}

			if (type === TestType.Score && isNull(items[type])) {
				items[type] = {
					value: TabID.SingleScore,
					label: 'Single Score',
				};
			}

			if (Object.values(items).every((item) => !isNull(item))) {
				break;
			}
		}

		return Object.values(items).filter((item) => !isNull(item));
	}, [testCards]);

	const {activeItem, setActiveItem} = useFilterGroup({
		defaultItem: TabID.All,
		items: items.map(({value}) => value),
		filterCb: ({testCard: {type}, activeItem}) =>
			activeItem === TabID.All ||
			(activeItem === TabID.Rubric && type === TestType.Rubric) ||
			(activeItem === TabID.SingleScore && type === TestType.Score) ||
			(activeItem === TabID.YesNo && type === TestType.YN),
	});

	return {
		activeItem,
		setActiveItem,
		items,
	};
}
