import {Button} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const ButtonIcon = styled(Button.Icon, {
	width: 36,
	height: 36,
	alignItems: 'center',
	borderRadius: 8,
	backgroundColor: '$primaryMuted',
	color: '$primary',

	'& svg': {
		width: 24,
		height: 24,
	},

	variants: {
		noClicable: {
			true: {
				cursor: 'default',

				'&:hover:not(:disabled)': {
					color: '$primary',
				},
			},
		},
	},
});
