import {ComponentPropsWithoutRef, ForwardedRef, forwardRef, useEffect, useMemo, useState} from 'react';
import {FilterGroupsState, SortingComparatorCb} from '../types';
import {FiltersContext, FiltersContextValue} from '../context';
import {useTestPanelContext} from '../../../context';
import {TestPanelCard} from '../../../types';
import {GridBox} from '@esgi/ui/layout';
import {isNull} from 'underscore';

type Props = ComponentPropsWithoutRef<typeof GridBox>;

export const Root = forwardRef(
	(
		{dataCy = 'test-panel-filters-root', css = {}, gap = '3', flow = 'column', children, ...rootProps}: Props,
		forwardedRef: ForwardedRef<HTMLDivElement>,
	) => {
		const {allTestCards, setFilteredCards} = useTestPanelContext<TestPanelCard>();

		const [filterGroups, setFilterGroups] = useState<FilterGroupsState<TestPanelCard, string>>({});
		const [sortableComparator, setSortableComparator] = useState<SortingComparatorCb<TestPanelCard> | null>(null);

		useEffect(() => {
			const filteredCards = Object.values(filterGroups).reduce(
				(currentItems, {activeItem, filterCb}) =>
					currentItems.filter((item, index) =>
						filterCb({
							activeItem,
							index,
							testCard: item,
						}),
					),
				allTestCards,
			);

			if (!isNull(sortableComparator)) {
				filteredCards.sort(sortableComparator);
			}

			setFilteredCards(filteredCards);
		}, [allTestCards, filterGroups, sortableComparator]);

		const context = useMemo<FiltersContextValue<TestPanelCard, string>>(
			() => ({
				setFilterGroups,
				setSortableComparator,
			}),
			[],
		);

		return (
			<FiltersContext.Provider value={context}>
				<GridBox dataCy={dataCy} css={css} gap={gap} flow={flow} ref={forwardedRef} {...rootProps}>
					{children}
				</GridBox>
			</FiltersContext.Provider>
		);
	},
);
