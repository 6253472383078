import {DistrictSubject, SchoolSubject, TeacherSubject} from './subject';

export enum TabID {
	All = 'All',
	Teacher = 'Teacher',
	School = 'School',
	District = 'District',
}

export type Entities = {
	teacherSubjects: TeacherSubject[];
	schoolSubjects: SchoolSubject[];
	districtSubjects: DistrictSubject[];
};
