import {useEffect, useMemo} from 'react';
import {isNull} from 'underscore';
import {RowDef} from './types';
import {useDisposable, useEndpointResult} from '@esgi/core/service';
import {V2SchoolAdminsPagesDataAnalyticsController, V2SchoolAdminsUserAccountController} from '@esgi/contracts/esgi';
import {isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';
import {useSchoolSpecialists} from '@esgi/main/libs/school-admin-store';

type Out = {
	ready: boolean;
	rows: RowDef[];
	schoolName: string;
};

export function useData(): Out {
	const analyticController = useDisposable(
		V2SchoolAdminsPagesDataAnalyticsController
	);

	const [{data: schoolSpecialists, loaded: schoolSpecialistsLoaded}] = useSchoolSpecialists();

	const [schoolsSpecialistsData, fetchSchoolsSpecialistsData] =
		useCancelableRequest(analyticController.schoolsSpecialists);

	useEffect(() => {
		if (schoolSpecialistsLoaded) {
			fetchSchoolsSpecialistsData({
				schoolSpecialistsIDs: schoolSpecialists.map(({id}) => id),
			});
		}
	}, [
		fetchSchoolsSpecialistsData,
		schoolSpecialistsLoaded,
		schoolSpecialists,
	]);

	const userAccountController = useDisposable(V2SchoolAdminsUserAccountController);

	const userControllerData = useEndpointResult(() => {
		if (schoolSpecialistsLoaded) {
			return userAccountController.init();
		}
	}, [schoolSpecialists, schoolSpecialistsLoaded]);

	const schoolName = useMemo(() => {
		return userControllerData?.user.schoolName;
	}, [userControllerData]);

	const {rows} = useMemo<{ rows: RowDef[] }>(() => {
		if (!isAsyncSucceed(schoolsSpecialistsData)) {
			return {
				schools: [],
				rows: [],
			};
		}

		const {specialistsAnalytics: schoolSpecialistsAnalytics} =
			schoolsSpecialistsData.data.value;

		const schoolSpecialistsRows = schoolSpecialists
			.map<RowDef | null>(({id, firstName, lastName, userName, email}) => {
				const analytics = schoolSpecialistsAnalytics.find(
					({specialistID}) => specialistID === id
				);

				if (isUndefined(analytics)) {
					return null;
				}

				return {
					id,
					firstName,
					lastName,
					userName,
					email,
					specialistGroups: analytics.groupsCount,
					students: analytics.studentsCount,
				};
			})
			.filter((item): item is RowDef => !isNull(item));

		const rows: RowDef[] = schoolSpecialistsRows;

		return {
			rows,
		};
	}, [schoolSpecialists, schoolsSpecialistsData]);

	return {
		ready: schoolSpecialistsLoaded && isAsyncSucceed(schoolsSpecialistsData),
		schoolName,
		rows,
	};
}
