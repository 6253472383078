import {TestResults} from '@esgi/main/kits/admin';
import {SingleSelectedExpandablePanel} from '../../../../../../_kit';
import {useTestResultsData} from '../../../../use-test-results-data';
import {RowDef} from '../../../../../types';

type Props = {
	selectedRow: RowDef;
};

export function ExpandedPanel({selectedRow}: Props) {
	const {skeleton, students, subjects, hierarchy} = useTestResultsData({
		selectedRow,
	});

	return (
		<SingleSelectedExpandablePanel.ExpandedPanel>
			<TestResults skeleton={skeleton} allSubjects={subjects} students={students} hierarchy={hierarchy} withoutVariantByEachStudent />
		</SingleSelectedExpandablePanel.ExpandedPanel>
	);
}
