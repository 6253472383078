import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';

export const RootContainer = styled(Box, {
	width: '256px',
	padding: '12px',
	borderRadius: 12,
	backgroundColor: '#F5F5F5',
});

export const ContentContainer = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'column',
	gap: 12,
});

export const HeaderContainer = styled(FlexBox, {
	width: '100%',
	padding: 8,
	justifyContent: 'space-between',
	alignItems: 'center',
});

export const FiltersBox = styled(Box, {
	display: 'inline-grid',
	gap: '$3',
});

export const ListContainer = styled(FlexBox, {
	width: '100%',
	padding: 8,
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: 4,
	borderRadius: 8,
	backgroundColor: '$neutral98',
	overflow: 'hidden',
	maxHeight: 300,
});

export const ActionsContainer = styled(FlexBox, {
	gap: 8,
	width: '100%',
	overflow: 'hidden',
	justifyContent: 'space-between',

	'> button': {
		width: '100%',
		minWidth: 'auto',
	},
});

export const ScrollInnerContainer = styled(FlexBox, {
	flexDirection: 'column',
	width: '100%',
	overflow: 'hidden',
});
