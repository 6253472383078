import {Domain} from '@esgi/contracts';
import {schoolAdminsDomain} from './schoolAdmins';
import {profilesDomain} from './profiles';
import {districtAdminsDomain} from './districtAdmins';

export class v2Domain extends Domain {
	public schoolAdmins = new schoolAdminsDomain(this.clientFactory);
	public profiles = new profilesDomain(this.clientFactory);
	public districtAdmins = new districtAdminsDomain(this.clientFactory);
}
