import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gridTemplateColumns: '272px auto 1fr',
	backgroundColor: '$background',
	overflow: 'hidden',
	height: '100%',
});

export const Divider = styled(Box, {
	width: 1,
	height: '100%',
	backgroundColor: '$border',
});
