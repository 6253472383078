import {SubjectsDistrictController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {GradeLevel, ItemNumerable, TrackDate} from '@esgi/main/features/admins/subject-management';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useEffect, useMemo, useState} from 'react';
import {InitSubjectData} from '../types';
import {useDistrictSpecialists, useSchools, useSchoolSpecialists} from '@esgi/main/libs/admin-store';
import {getFullName} from '@esgi/main/kits/common';

type Parameters = {
	subjectID: number;
};

export function useInitData({subjectID}: Parameters) {
	const [{data: schoolsData, loaded: isSchoolsLoaded}] = useSchools();
	const [{data: districtSpecialistsStore, loaded: isDistrictSpecialistsStoreLoaded}] = useDistrictSpecialists();
	const [{data: schoolSpecialists, loaded: isSchoolSpecialistsLoaded}] = useSchoolSpecialists();

	const controller = useDisposable(SubjectsDistrictController);

	const [initData, init] = useCancelableRequest(controller.initEdit);

	const [trackID, setTrackID] = useState<number | null>(null);
	const [allTrackDates, setAllTrackDates] = useState<TrackDate[]>([]);
	const [gradeLevels, setGradeLevels] = useState<GradeLevel[]>([]);

	const [schoolSpecialistsSchools, setSchoolSpecialistsSchools] = useState<ItemNumerable[]>([]);

	const [initSubjectData, setInitSubjectData] = useState<InitSubjectData | null>(null);

	useEffect(() => {
		init({
			districtID: undefined as unknown as number,
			globalSchoolYearID: undefined as unknown as number,
			subjectID,
		});
	}, [subjectID]);

	useEffect(() => {
		if (isAsyncSucceed(initData)) {
			const {gradeLevels, trackDates, tracks, schoolSpecialistsSchools} = initData.data.commonModel;

			setTrackID(tracks[0]?.id ?? null);

			setAllTrackDates(
				trackDates.map(({trackID, trackDateID, dateFrom, dateTo}) => ({trackID, trackDateID, dateFrom, dateTo})),
			);

			setGradeLevels(gradeLevels.map(({gradeLevelID, name, shortName}) => ({id: gradeLevelID, name, shortName})));
			setSchoolSpecialistsSchools(schoolSpecialistsSchools.map(({id, name}) => ({id, name})));
			setInitSubjectData(initData.data.editModel);
		}
	}, [initData]);

	const districtSpecialists = useMemo<ItemNumerable[]>(
		() => districtSpecialistsStore.map(({id, firstName, lastName}) => ({id, name: getFullName({firstName, lastName})})),
		[districtSpecialistsStore],
	);

	const teachersSchools = useMemo<ItemNumerable[]>(() => schoolsData.map(({id, name}) => ({id, name})), [schoolsData]);

	return {
		trackID,
		allTrackDates,
		gradeLevels,
		teachersSchools,
		schoolSpecialistsSchools,
		districtSpecialists,
		schoolSpecialists,
		isDataLoaded:
			isAsyncSucceed(initData) && isSchoolsLoaded && isDistrictSpecialistsStoreLoaded && isSchoolSpecialistsLoaded,
		initSubjectData,
	};
}
