import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {AlertBody, AlertFooter, AlertHeader, alertRootCss, SpecialistGroupNameBox} from './index.styled';
import {Button, isUndefined} from '@esgi/ui';
import {Students} from '@esgi/ui/icons';
import {OneLinedText} from '@esgi/main/kits/common';

type Props = {
	onRemove: VoidFunction;
	onClose: VoidFunction;
	specialistGroupName?: string | undefined;
};

export function ResetAllStudentsAlert({onClose, onRemove, specialistGroupName}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const handleRemoveClick = () => {
		onRemove();
		closeAlert();
	};

	return (
		<Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative} css={alertRootCss}>
			<AlertHeader withBacklight={false} onCloseIconClick={closeAlert}>
				<Text size='small'>Remove all students</Text>

				{!isUndefined(specialistGroupName) && (
					<SpecialistGroupNameBox>
						<Students />
						<OneLinedText size='small' color='highContrast'>
							{specialistGroupName}
						</OneLinedText>
					</SpecialistGroupNameBox>
				)}
			</AlertHeader>

			<AlertBody>
				<Text size='small' color='highContrast'>
					You are about to remove all students from the selected spec.group. This cannot be undone when the changes are
					saved.
				</Text>
			</AlertBody>

			<AlertFooter>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>

					<Button color='warn' onClick={handleRemoveClick}>
						<Text size='medium' bold color='negative'>
							Remove
						</Text>
					</Button>
				</GridBox>
			</AlertFooter>
		</Alert>
	);
}
