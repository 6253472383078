import {SelectableList as SelectableListUi} from '@esgi/ui/layout';
import {useSubjectPanelContext} from '../../context';
import {SubjectModel} from '../../types/subject';
import {SubjectName} from '../subject-name';
import {getListValue} from './get-list-value';
import {skeletonableMockSubjects} from './constants';

type Props = {
	subjects: SubjectModel[];
};

export function SelectableList({subjects}: Props) {
	const {selectedSubjectID, setSelectedSubjectID, skeleton} = useSubjectPanelContext();

	return (
		<SelectableListUi>
			<SelectableListUi.GroupRoot type='single' value={getListValue(selectedSubjectID)}>
				<SelectableListUi.Group>
					{(skeleton ? skeletonableMockSubjects : subjects).map(({id, name, level, hidden}) => (
						<SelectableListUi.Item
							value={String(id)}
							key={skeleton ? `${id}-skeleton` : id}
							forceSelect={selectedSubjectID !== id}
							onClick={() => {
								if (!skeleton) {
									setSelectedSubjectID(id);
								}
							}}
							setTextCurrentColor={false}
						>
							<SubjectName subjectLevel={level} subjectName={name} hidden={hidden} />
						</SelectableListUi.Item>
					))}
				</SelectableListUi.Group>
			</SelectableListUi.GroupRoot>
		</SelectableListUi>
	);
}
