import {ColDef} from 'ag-grid-community';
import {HeaderCell} from './components/header-cell';

export function settingUpPanelColDef({
	cellHorizontalPadding,
	withoutFirstColumnPadding,
	withoutLastColumnPadding,
}: {
	cellHorizontalPadding: number;
	withoutFirstColumnPadding: boolean;
	withoutLastColumnPadding: boolean;
}): ColDef<any> {
	return {
		width: (() => {
			let colWidth = 30 + 2 * cellHorizontalPadding;

			if (withoutFirstColumnPadding) {
				colWidth -= cellHorizontalPadding;
			}

			if (withoutLastColumnPadding) {
				colWidth -= cellHorizontalPadding;
			}

			return colWidth;
		})(),
		resizable: false,
		sortable: false,
		headerComponent: HeaderCell,
	};
}
