import {Button} from '@esgi/ui';
import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Link} from 'react-router-dom';

export const LastUpdateBox = styled(GridBox, {
	alignItems: 'center',
	justifyContent: 'center',
	gridAutoFlow: 'column',
	borderRadius: 6,
	backgroundColor: '$surface',
	gap: '$2',
	padding: '$3',
});

export const ActionButtonsDivider = styled(Box, {
	width: 1.5,
	height: 20,
	borderRadius: 1,
	backgroundColor: '$border',
});

export const AddIconableButton = styled(Button.Icon, {
	transition: 'width .3s, height .3s, border-radius .3s, background-color .3s',
	width: 28,
	height: 28,
	borderRadius: 6,
	backgroundColor: '$surface',

	'&:hover': {
		width: 28,
		height: 28,
		borderRadius: '50%',
		backgroundColor: '$secondaryBackground',
	},
});

export const LinkButton = styled(Link, {
	all: 'unset',
	display: 'inline-flex',
	textDecoration: 'none',
	boxSizing: 'border-box',
	outline: 'none',
	backgroundColor: 'transparent',
	justifyContent: 'center',
	cursor: 'pointer',

	width: 24,
	height: 24,
	color: '$base',

	'& svg': {
		width: '100%',
		height: '100%',
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});
