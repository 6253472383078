import * as React from 'react';
import type {SVGProps} from 'react';

export function CompareSchools(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M16 4.5C15.5858 4.5 15.25 4.83579 15.25 5.25V26.75C15.25 27.1642 15.5858 27.5 16 27.5C16.4142 27.5 16.75 27.1642 16.75 26.75V5.25C16.75 4.83579 16.4142 4.5 16 4.5Z'
				fill='#333333'
			/>
			<path
				d='M7 9.77737C7 9.34826 7.34845 8.99999 7.77778 8.99999H13.0222C13.1327 8.99999 13.2222 9.08954 13.2222 9.19999V10.3547C13.2222 10.4652 13.1327 10.5547 13.0222 10.5547H9.33333V21.438H10.8889V20.6606C10.8889 19.4398 11.8271 18.4383 13.0223 18.337C13.1324 18.3276 13.2222 18.4181 13.2222 18.5285V19.6833C13.2222 19.7937 13.1312 19.8806 13.0244 19.9087C12.6908 19.9962 12.4444 20.2999 12.4444 20.6606V22.2154C12.4444 22.6445 12.096 22.9928 11.6667 22.9928H8.55556C8.12622 22.9928 7.77778 22.6445 7.77778 22.2154V10.5547C7.34845 10.5547 7 10.2065 7 9.77737Z'
				fill='#333333'
			/>
			<path
				d='M11.6659 13.6667C11.2364 13.6667 10.8881 13.3184 10.8881 12.8889C10.8881 12.4593 11.2364 12.1111 11.6659 12.1111C12.0955 12.1111 12.4437 12.4593 12.4437 12.8889C12.4437 13.3184 12.0955 13.6667 11.6659 13.6667Z'
				fill='#333333'
			/>
			<path
				d='M11.6659 16.7778C11.2364 16.7778 10.8881 16.4295 10.8881 16C10.8881 15.5704 11.2364 15.2222 11.6659 15.2222C12.0955 15.2222 12.4437 15.5704 12.4437 16C12.4437 16.4295 12.0955 16.7778 11.6659 16.7778Z'
				fill='#333333'
			/>
			<path
				d='M25.0023 9.77737C25.0023 9.34826 24.6539 8.99999 24.2245 8.99999H18.9801C18.8696 8.99999 18.7801 9.08954 18.7801 9.19999V10.3547C18.7801 10.4652 18.8696 10.5547 18.9801 10.5547H22.669V21.438H21.1134V20.6606C21.1134 19.4398 20.1752 18.4383 18.98 18.337C18.8699 18.3276 18.7801 18.4181 18.7801 18.5285V19.6833C18.7801 19.7937 18.8711 19.8806 18.9779 19.9087C19.3116 19.9962 19.5579 20.2999 19.5579 20.6606V22.2154C19.5579 22.6445 19.9063 22.9928 20.3356 22.9928H23.4468C23.8761 22.9928 24.2245 22.6445 24.2245 22.2154V10.5547C24.6539 10.5547 25.0023 10.2065 25.0023 9.77737Z'
				fill='#333333'
			/>
			<path
				d='M20.3364 13.6667C20.766 13.6667 21.1142 13.3184 21.1142 12.8889C21.1142 12.4593 20.766 12.1111 20.3364 12.1111C19.9068 12.1111 19.5586 12.4593 19.5586 12.8889C19.5586 13.3184 19.9068 13.6667 20.3364 13.6667Z'
				fill='#333333'
			/>
			<path
				d='M20.3364 16.7778C20.766 16.7778 21.1142 16.4295 21.1142 16C21.1142 15.5704 20.766 15.2222 20.3364 15.2222C19.9068 15.2222 19.5586 15.5704 19.5586 16C19.5586 16.4295 19.9068 16.7778 20.3364 16.7778Z'
				fill='#333333'
			/>
		</svg>
	);
}
