import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Content = styled(Box, {
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	position: 'relative',
	overflow: 'hidden',
});
