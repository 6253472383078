import React from 'react';
import {NavigationBar, NavigationBarOrientation} from '@esgi/ui/layout';
import {HorizontalPanelHead} from './components/horizontal-panel-head';
import {HorizontalPanelContent} from './components/horizontal-panel-content';
import {HorizontalPanelBottom} from './components/horizontal-panel-bottom';
import {NavigationPanelBaseProps} from '../../../types';

export function HorizontalPanel({
	onSettingsClick,
	onAvatarClick,
	onCastClick,
	userInfo,
	onHelpClick,
	onSchoolYearChanged,
}: NavigationPanelBaseProps) {
	return (
		<NavigationBar.Root dataCy='horizontal-panel' orientation={NavigationBarOrientation.Horizontal} closeOnOutsideClick>
			<HorizontalPanelHead />

			<HorizontalPanelContent userInfo={userInfo} onSchoolYearChanged={onSchoolYearChanged} />

			<HorizontalPanelBottom
				onCastClick={onCastClick}
				onSettingsClick={onSettingsClick}
				onAvatarClick={onAvatarClick}
				onHelpClick={onHelpClick}
				userInfo={userInfo}
			/>
		</NavigationBar.Root>
	);
}
