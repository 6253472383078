import {useCallback} from 'react';
import {LowestAchievementMetricData} from '../../../types/lowest-achievement';
import {schoolsSortableCells, studentsOnePeriodSortableCells, studentsTwoPeriodsSortableCells} from '../constants';
import {useTableDataState} from './use-table-data-state';
import {useStudentsOnePeriodItems} from './use-students-one-period-items';
import {useStudentsTwoPeriodsItems} from './use-students-two-periods-items';
import {useSchoolsItems} from './use-schools-items';
import {GradeLevel, SchoolModel} from '@esgi/main/features/admins/dashboard';

export function useViewTableInfo({
	widgetData,
	allSchoolsList,
	gradeLevels,
}: {
	widgetData: LowestAchievementMetricData | null;
	allSchoolsList: SchoolModel[];
	gradeLevels: GradeLevel[];
}) {
	const getSchoolName = useCallback(
		(schoolID: number) => allSchoolsList.find(({id}) => id === schoolID)?.name ?? '',
		[allSchoolsList],
	);

	const getGradeShortName = useCallback(
		(gradeID: GradeLevel['id']) => gradeLevels.find(({id}) => id === gradeID)?.shortName ?? null,
		[gradeLevels],
	);

	const studentsOnePeriodItems = useStudentsOnePeriodItems({
		studentsData: widgetData?.studentsData ?? [],
		getSchoolName,
		getGradeShortName,
	});

	const studentsTwoPeriodsItems = useStudentsTwoPeriodsItems({
		studentsData: widgetData?.studentsData ?? [],
		getSchoolName,
		getGradeShortName,
	});

	const schoolsItems = useSchoolsItems({
		schoolsData: widgetData?.schoolsData ?? [],
		getSchoolName,
	});

	const studentsOnePeriodInfo = useTableDataState({
		sortableCells: studentsOnePeriodSortableCells,
		initialSortableKey: 'avg',
		items: studentsOnePeriodItems,
		additionalSortDataKey: 'student',
	});

	const studentsTwoPeriodsInfo = useTableDataState({
		sortableCells: studentsTwoPeriodsSortableCells,
		initialSortableKey: 'avgSecond',
		items: studentsTwoPeriodsItems,
		additionalSortDataKey: 'student',
	});

	const schoolsInfo = useTableDataState({
		sortableCells: schoolsSortableCells,
		initialSortableKey: 'result',
		items: schoolsItems,
		additionalSortDataKey: 'school',
	});

	return {
		studentsOnePeriodInfo,
		studentsTwoPeriodsInfo,
		schoolsInfo,
	};
}
