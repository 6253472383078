export enum SubjectPublishType {
	Indefinitely = 'Indefinitely',
	SchoolYear = 'SchoolYear',
	MarkingPeriod = 'MarkingPeriod',
	CustomDateRange = 'CustomDateRange',
	Off = 'Off',
}

export type TrackDate = {
	dateFrom: string;
	dateTo: string;
	trackDateID: number;
	trackID: number;
};

export type SubjectPublishValue = {
	type: SubjectPublishType;
	trackDatesIDs: number[];
	dateFrom: string | null;
	dateTo: string | null;
};
