import {useCallback, useState} from 'react';
import {More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {useUser} from '@esgi/core/authentication';
import {ContextMenu, Button, Tooltip} from '@esgi/ui';
import {ContextMenuItem} from './index.styled';

interface Props {
	onDeleteStudent?: VoidFunction;
	isInitialized: boolean;
}

export function MoreOptions({onDeleteStudent, isInitialized}: Props) {
	const user = useUser();
	const [isOpen, setOpen] = useState(false);

	const isDeleteDisabled = !user?.canEditStudents;

	const handleDelete = useCallback(() => {
		setOpen(false);
		onDeleteStudent();
	}, [onDeleteStudent]);

	return (
		<ContextMenu.Root open={isOpen} onOpenChange={setOpen}>
			<ContextMenu.Trigger>
				<Button.Icon skeleton={!isInitialized} withBackgroundHover>
					<More width={24} height={24}/>
				</Button.Icon>
			</ContextMenu.Trigger>
			<ContextMenu.Content>
				<ContextMenu.Group>
					<Tooltip open={isDeleteDisabled ? undefined : false}>
						<Tooltip.Trigger>
							<ContextMenuItem onClick={handleDelete} disabled={isDeleteDisabled}>
								<Text size='medium'>Delete</Text>
							</ContextMenuItem>
						</Tooltip.Trigger>
						<Tooltip.Content>
							To delete a student, please contact our Customer Support team at support@esgisoftware.com.
						</Tooltip.Content>
					</Tooltip>
				</ContextMenu.Group>
			</ContextMenu.Content>
		</ContextMenu.Root>
	);
}

