import {useCallback, useState} from 'react';

export function useFlag(initial: boolean = false): [boolean, VoidFunction, VoidFunction] {
	const [flag, setFlag] = useState(initial);
	const setTrue = useCallback(() => {
		setFlag(true);
	}, []);

	const setFalse = useCallback(() => {
		setFlag(false);
	}, []);
	return [flag, setTrue, setFalse];
}