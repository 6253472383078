import {isEqual} from 'underscore';
import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {createTeacherSettingsForm} from './form';
import {SettingsData} from '../../../../../types';

export class TeacherSettingsService extends BaseService {
	public form = createTeacherSettingsForm();

	private readonly initData = new BehaviorSubject<SettingsData>(null);

	public init(data: SettingsData) {
		this.initData.next(data);
		this.form.value = {
			showExpiredUsers: data.showExpiredUsers,
		};
	}

	public compareStatesForEquality() {
		const {showExpiredUsers} = this.initData.value;

		const initialState = {
			showExpiredUsers,
		};

		const currentState = {
			showExpiredUsers: this.form.controls.showExpiredUsers.value,
		};

		return !isEqual(currentState, initialState);
	}
}
