import {TestContentArea, TestType} from '@esgi/main/kits/common';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {ChartsInfoDivider, Header, TestCardRoot} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {useChartData} from './use-chart-data';
import {ChartInfoTranscript} from './components/chart-info-transcript';
import {PropsWithChildren, useMemo} from 'react';
import {TestPanelCardContext, TestPanelCardContextValue} from './context';

type Props = PropsWithChildren<{
	contentArea: TestContentArea;
	color: string;
	type: TestType;
	name: string;
	correctAnswersPercentage: number | null;
	showInColor: boolean;
}>;

export function TestPanelCard({
	color,
	contentArea,
	type,
	name,
	correctAnswersPercentage,
	showInColor,
	children,
}: Props) {
	const chartData = useChartData({correctAnswersPercentage, showInColor, type});

	const contextValue = useMemo<TestPanelCardContextValue>(
		() => ({
			testResultsCorrectVerbiage: chartData.testResultsCorrectVerbiage,
			testType: type,
		}),
		[chartData, type],
	);

	return (
		<TestPanelCardContext.Provider value={contextValue}>
			<TestCardRoot contentArea={contentArea} testColor={color} testType={type}>
				<Header>
					<TestCard.MultiLinedTitle size='medium' bold color='base' maxLines={2}>
						{name}
					</TestCard.MultiLinedTitle>
				</Header>

				<TestCard.Separator />

				<TestCard.Body>
					<TestCard.Statistic.Charts.RingFullFilled
						value={correctAnswersPercentage}
						maxValue={100}
						progressBarProgressColor={chartData.chartColor}
					>
						<GridBox gapX='3' flow='column' align='center'>
							<ChartInfoTranscript
								color={chartData.correct.color}
								value={chartData.correct.value}
								transcript={chartData.correct.transcript}
							/>

							<ChartsInfoDivider />

							<ChartInfoTranscript
								color={chartData.incorrect.color}
								value={chartData.incorrect.value}
								transcript={chartData.incorrect.transcript}
							/>
						</GridBox>
					</TestCard.Statistic.Charts.RingFullFilled>
				</TestCard.Body>

				<TestCard.Separator />

				{children}
			</TestCardRoot>
		</TestPanelCardContext.Provider>
	);
}
