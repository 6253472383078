import {useMemo} from 'react';
import {FiltersData, RowDef} from './types';
import {unique} from 'underscore';
import {
	useClasses,
	useGroups,
	useSchoolSpecialists,
	useSpecialistGroups,
	useStudents,
	useTeachers,
} from '@esgi/main/libs/school-admin-store';
import {useDisposable, useEndpointResult} from '../../../../../../../libs/core/src/service';
import {V2SchoolAdminsUserAccountController} from '@esgi/contracts/esgi';

export function useData(): {ready: boolean; rows: RowDef[]; schoolName} & FiltersData {
	const [{data: allTeachers, loaded: allTeachersLoaded}] = useTeachers();
	const [{data: allClasses, loaded: allClassesLoaded}] = useClasses();
	const [{data: allGroups, loaded: allGroupsLoaded}] = useGroups();
	const [{data: schoolSpecialists, loaded: schoolSpecialistsLoaded}] = useSchoolSpecialists();
	const [{data: allSpecialistGroups, loaded: allSpecialistGroupsLoaded}] = useSpecialistGroups();
	const [{data: students, loaded: studentsLoaded}] = useStudents();

	const userAccountController = useDisposable(V2SchoolAdminsUserAccountController);

	const userControllerData = useEndpointResult(() => {
		if (studentsLoaded) {
			return userAccountController.init();
		}
	}, [students, studentsLoaded]);

	const schoolName = useMemo(() => {
		return userControllerData?.user.schoolName;
	}, [userControllerData]);

	const mappedData = useMemo(() => {
		const allSpecialists = schoolSpecialists;
		const rows = (students ?? [])?.map<RowDef>((student) => {
			const teacher = allTeachers.find((teacher) => teacher.id === student.teacherID);
			const classes = allClasses.filter((c) => student.classesIDs.includes(c.id));
			const groups = allGroups.filter((group) => student.groupsIDs.includes(group.id));

			const specialists = allSpecialists.filter((specialist) => student.specialistsIDs.includes(specialist.id));
			const specialistGroups = allSpecialistGroups.filter((group) => student.specialistGroupsIDs.includes(group.id));

			return {
				id: student.id,
				firstName: student.firstName,
				lastName: student.lastName,
				teacher: teacher ?? null,
				class: classes,
				group: groups,
				specialist: specialists,
				specialistGroups: specialistGroups,
			};
		});

		return {
			rows,
			availableTeachers: unique(rows.map((r) => r.teacher)).filter((item) => !!item),
			availableClasses: unique(rows.map((r) => r.class).flat()).filter(Boolean),
			availableGroups: unique(rows.map((r) => r.group).flat()).filter(Boolean),
			availableSpecialists: unique(rows.map((r) => r.specialist).flat()).filter(Boolean),
			availableSpecialistGroups: unique(rows.map((r) => r.specialistGroups).flat()).filter(Boolean),
		};
	}, [schoolSpecialists, students, allTeachers, allClasses, allGroups, allSpecialistGroups]);

	return {
		ready:
			allTeachersLoaded &&
			allClassesLoaded &&
			allGroupsLoaded &&
			schoolSpecialistsLoaded &&
			allSpecialistGroupsLoaded &&
			studentsLoaded,
		schoolName,
		...mappedData,
	};
}
