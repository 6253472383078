import {StudentSort} from '@esgi/core/authentication';
import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';

export type SystemSettingsFormType = FormGroup<{
	sortBy: FormControl<StudentSort[]>,
	timeZone: FormControl<string[]>,
},
	ControlValue<{
		sortBy: FormControl<StudentSort[]>,
		timeZone: FormControl<string[]>,
	}>>

export const createSystemSettingsForm = ():
	SystemSettingsFormType => new FormGroup({
	sortBy: new FormControl([]),
	timeZone: new FormControl([]),
});
