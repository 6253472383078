import {styled} from '@esgi/ui/theme';
import {Form} from '@esgi/ui/form';

export const formRowGap = 16;

export const FormRoot = styled(Form, {
	display: 'grid',
	rowGap: formRowGap,
	gridTemplateRows: 'auto auto 1fr',
	height: '100%',
});
