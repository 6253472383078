import React, {Dispatch, useCallback, useState} from 'react';
import {enumRemap} from 'shared/utils';
import {ThemeProvider} from '@esgi/ui/theme';
import {Select} from '@esgi/ui/controls';
import {isUndefined} from '@esgi/ui';
import {SelectContent, StyledContentContainer, StyledSelectField, TabContentWrapper} from './index.styled';
import {DatePickerData, PeriodType, SelectTabs} from './types';
import {isNull} from 'underscore';
import {useCurrentTrackDates} from './hooks/use-current-track-dates';
import {useInitData} from './hooks/use-init-data';
import {useTabs} from './hooks/use-tabs';
import {Tabs} from './components/tabs';
import {CurrentTabContent, DatePickerTabContent, PastTabContent} from './components/tab-contents';
import {isTrackDateOption} from './utils/select-options/is-track-date-option';
import {generateTrackDateOption} from './utils/select-options/generate-track-date-option';
import {useUser} from '@esgi/core/authentication';

type Props = DatePickerData & {
	onChange: Dispatch<DatePickerData>;
};

export function DatePickerWidget({trackDatesID, dateRange, datePeriod, onChange}: Props) {
	const currentUser = useUser();

	const {currentTrack, currentTrackDate, previousTrackDate, currentGlobalActiveSchoolYear, skeleton} = useInitData();

	const {activeTab, setActiveTab} = useTabs({
		selectedPeriod: datePeriod,
		selectedTrackDateID: trackDatesID,
	});

	const [isOpen, setIsOpen] = useState(false);

	const [value, setValue] = useState<string>(() => {
		if (trackDatesID !== 0) {
			return generateTrackDateOption(trackDatesID);
		}

		if (!isNull(dateRange)) {
			return enumRemap(PeriodType.Manual, PeriodType);
		}

		return typeof datePeriod === 'number' ? enumRemap(datePeriod, PeriodType) : datePeriod;
	});

	const currentTracks = useCurrentTrackDates({currentTrack});

	const closeSelectContent = useCallback(() => {
		setIsOpen(false);
	}, []);

	const handleSelect = useCallback(
		(value: string) => {
			setValue(value);
			closeSelectContent();

			if (isTrackDateOption(value)) {
				const [, trackID] = value.split('-');

				if (isUndefined(trackID)) {
					throw new Error('trackID is undefined');
				}

				onChange({
					datePeriod: PeriodType.PreviousSelectedMarkingPeriod,
					dateRange: null,
					trackDatesID: Number(trackID),
				});

				return;
			}

			onChange({
				datePeriod: enumRemap(value, PeriodType),
				dateRange: null,
				trackDatesID: 0,
			});
		},
		[onChange, closeSelectContent],
	);

	const isUserInCurrentGlobalSchoolYear =
		currentGlobalActiveSchoolYear?.globalSchoolYearID === currentUser?.globalSchoolYearID;

	return (
		<ThemeProvider>
			<Select.Root open={isOpen} onOpenChange={setIsOpen} onValueChange={handleSelect} value={value}>
				<StyledSelectField placeholder='Date Range' skeleton={skeleton} />

				<SelectContent>
					<StyledContentContainer>
						<Tabs
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							isUserInCurrentGlobalSchoolYear={isUserInCurrentGlobalSchoolYear}
						/>

						<TabContentWrapper hide={activeTab !== SelectTabs.Past}>
							<PastTabContent
								currentTracks={currentTracks}
								previousTrackDate={previousTrackDate}
								isUserInCurrentGlobalSchoolYear={isUserInCurrentGlobalSchoolYear}
							/>
						</TabContentWrapper>

						<TabContentWrapper hide={activeTab !== SelectTabs.Current}>
							<CurrentTabContent
								currentGlobalActiveSchoolYear={currentGlobalActiveSchoolYear}
								currentTrackDate={currentTrackDate}
								currentTracks={currentTracks}
							/>
						</TabContentWrapper>

						<TabContentWrapper hide={activeTab !== SelectTabs.DatePicker}>
							<DatePickerTabContent
								dateRange={dateRange}
								onChange={onChange}
								setSelectValue={setValue}
								closeSelectContent={closeSelectContent}
								currentTrack={currentTrack}
							/>
						</TabContentWrapper>
					</StyledContentContainer>
				</SelectContent>
			</Select.Root>
		</ThemeProvider>
	);
}
