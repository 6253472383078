import {useCallback} from 'react';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {DataService} from '../service/service';
import {Test} from '../service/types';

export function useTestSelection(service: DataService): {
	selectedTests: Test[],
	select: (testID: Test) => void,
	toggleAll: VoidFunction,
	unselectAll: VoidFunction,
} {
	const selectedTests = useBehaviorSubject(service.selectedTests$);

	const select = useCallback((test: Test) => {
		const selectedTestIDs = selectedTests.map((item) => item.id);
		if (selectedTestIDs.includes(test.id)) {
			service.setSelectedTests(selectedTests.filter((s) => s.id !== test.id));
			return;
		}

		service.setSelectedTests([...selectedTests, test]);
	}, [selectedTests, service]);

	const toggleAll = useCallback(() => {
		const allSelected = selectedTests.length === service.tests$.value.length;
		if (allSelected) {
			service.setSelectedTests([]);
			return;
		}

		service.setSelectedTests([...service.tests$.value]);
	}, [selectedTests, service]);

	const unselectAll = useCallback(() => {
		service.setSelectedTests([]);
	}, [service]);

	return {
		selectedTests,
		select,
		toggleAll,
		unselectAll,
	};
}