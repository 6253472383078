import {Dispatch} from 'react';
import {SubjectPublishType, SubjectPublishValue} from '../../types';
import {PeriodTabID, TabID} from '../types';
import {isUndefined} from '@esgi/ui';
import moment from 'moment';

type Parameters = {
	onSave: Dispatch<SubjectPublishValue>;
	closeDrawer: VoidFunction;
	tabID: TabID;
	periodTabID: PeriodTabID;
	customDateRange: Date[];
	selectedTrackDatesIDs: number[];
};

export function useSave({onSave, closeDrawer, tabID, periodTabID, customDateRange, selectedTrackDatesIDs}: Parameters) {
	return function () {
		if (tabID === TabID.Indefinitely) {
			onSave({
				type: SubjectPublishType.Indefinitely,
				dateFrom: null,
				dateTo: null,
				trackDatesIDs: [],
			});
		}

		if (tabID === TabID.Period) {
			if (periodTabID === PeriodTabID.SchoolYear) {
				onSave({
					type: SubjectPublishType.SchoolYear,
					dateFrom: null,
					dateTo: null,
					trackDatesIDs: [],
				});
			}

			if (periodTabID === PeriodTabID.MarkingPeriod) {
				onSave({
					type: SubjectPublishType.MarkingPeriod,
					dateFrom: null,
					dateTo: null,
					trackDatesIDs: selectedTrackDatesIDs,
				});
			}
		}

		if (tabID === TabID.CustomDateRange) {
			const dateFrom = customDateRange[0];
			const dateTo = customDateRange[1];

			if (isUndefined(dateFrom) && isUndefined(dateTo)) {
				throw new Error('date range invalid');
			}

			onSave({
				type: SubjectPublishType.CustomDateRange,
				dateFrom: moment(dateFrom).format('L'),
				dateTo: moment(dateTo).format('L'),
				trackDatesIDs: [],
			});
		}

		closeDrawer();
	};
}
