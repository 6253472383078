import {DatesPeriodModel} from '../../types';

// Function to find the active TrackDate within an active Track based on the current date.
export function getCurrentTrackDate(trackDates: DatesPeriodModel[]): DatesPeriodModel | null {
	const currentDate = new Date();

	const currentTrack = trackDates.find((item) => {
		const dateFrom = new Date(item.dateFrom);
		const dateTo = new Date(item.dateTo);

		return currentDate >= dateFrom && currentDate <= dateTo;
	});

	return currentTrack ?? null;
}
