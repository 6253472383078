import {ToggleGroup} from '@esgi/ui/controls';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	padding: 3,
	gridAutoColumns: '1fr',
	minHeight: 40,

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
			},
		},
	},
});
