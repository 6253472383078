import {useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {useCallback, useState} from 'react';
import {TabID} from '../types';
import {useTeachers, useClasses, useGroups, useStudents} from '@esgi/main/libs/school-admin-store';

export function useDrawerState({onClose}: {onClose: VoidFunction}) {
	const [{data: teachers, loaded: isTeachersLoaded}] = useTeachers();
	const [{data: classes, loaded: isClassesLoaded}] = useClasses();
	const [{data: groups, loaded: isGroupsLoaded}] = useGroups();
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const drawerRef = useDrawerRef();
	const closeDrawer = useDrawerClose(drawerRef, onClose);

	const [activeTab, setActiveTab] = useState(TabID.GroupInformation);

	const [withoutResetSingleStudentAlert, setWithoutResetSingleStudentAlert] = useState(false);

	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isFormValid, setIsFormValid] = useState(true);

	const [showUnsavedChangesConfirmation, setShowUnsavedChangesConfirmation] = useState(false);

	const openUnsavedChangesConfirmation = useCallback(() => {
		setShowUnsavedChangesConfirmation(true);
	}, []);

	const closeUnsavedChangesConfirmation = useCallback(() => {
		setShowUnsavedChangesConfirmation(false);
	}, []);

	const onDrawerClose = useCallback(() => {
		if (isFormTouched) {
			openUnsavedChangesConfirmation();

			return;
		}

		closeDrawer();
	}, [closeDrawer, isFormTouched, openUnsavedChangesConfirmation]);

	return {
		drawerRef,
		activeTab,
		setActiveTab,
		isFormTouched,
		setIsFormTouched,
		isFormValid,
		setIsFormValid,
		showUnsavedChangesConfirmation,
		closeUnsavedChangesConfirmation,
		closeDrawer,
		onDrawerClose,
		withoutResetSingleStudentAlert,
		setWithoutResetSingleStudentAlert,
		skeleton: !isTeachersLoaded || !isClassesLoaded || !isGroupsLoaded || !isStudentsLoaded,
		teachers,
		classes,
		groups,
		students,
	};
}
