import {useRootPageContext} from '@esgi/main/kits/common';
import {WidgetType} from './types';
import {useService} from '@esgi/core/service';
import {Service} from './service';
import {widgets} from './constants';
import {dispatchAppEvent} from '@esgillc/events';
import {WidgetCreated} from '../../events';
import {AddWidgetBase, OnWidgetCreate} from '@esgi/main/features/admins/dashboard';
import {tap} from 'rxjs';
import {useEffect, useState} from 'react';

type Props = {
	onClose: VoidFunction;
};

export function AddWidget({onClose}: Props) {
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const {rootPageContentContainerRef} = useRootPageContext();

	const service = useService(Service);

	useEffect(() => {
		service.init().subscribe(() => {
			setIsDataLoaded(true);
		});
	}, []);

	const handleSaveWidget: OnWidgetCreate<WidgetType> = ({districtID, globalSchoolYearID, selectedWidgetType}) => {
		return service.createWidget({districtID, globalSchoolYearID, selectedWidgetType}).pipe(
			tap(({id, name, versionID, options, lastUpdatedTime}) => {
				dispatchAppEvent(WidgetCreated, new WidgetCreated(id, versionID, name, options, lastUpdatedTime));
			}),
		);
	};

	return (
		<AddWidgetBase
			container={rootPageContentContainerRef.current}
			onClose={onClose}
			onWidgetCreate={handleSaveWidget}
			widgets={widgets}
			skeleton={!isDataLoaded}
		/>
	);
}
