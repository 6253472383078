import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const IconBox = styled(GridBox, {
	width: 24,
	height: 24,

	'& svg': {
		width: '100%',
		height: '100%',
		fill: 'currentColor',
		transition: 'fill .3s',

		'& path': {
			fill: 'currentColor',
			transition: 'fill .3s',
		},
	},
});

export const ContentBox = styled(GridBox, {
	minHeight: 40,
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	paddingTop: 5,
	paddingBottom: 5,
	paddingLeft: 12,
	paddingRight: 12,
	gridAutoFlow: 'column',
	gap: '$4',
	justifyContent: 'space-between',
	alignItems: 'center',
	transition: 'background-color .3s, border-color .3s',

	'& [data-placeholder]': {
		color: '$mediumContrast',
		transition: 'color .3s',
	},

	'& [data-transcript]': {
		color: '$primary',
		transition: 'color .3s',
	},

	[`> ${IconBox}`]: {
		color: '$base',
	},

	variants: {
		isDisabled: {
			true: {
				backgroundColor: '$background',
				borderColor: '$border',

				'& [data-placeholder]': {
					color: '$lowContrast',
				},

				'& [data-transcript]': {
					color: '$mediumContrast',
				},
			},

			false: {
				backgroundColor: '$surface',
				borderColor: '$primaryMuted',
				cursor: 'pointer',

				'&:hover': {
					backgroundColor: '$secondaryBackground',
					borderColor: '$secondaryMuted',

					'& [data-placeholder]': {
						color: '$secondaryVivid',
					},

					'& [data-transcript]': {
						color: '$secondary',
					},

					[`> ${IconBox}`]: {
						color: '$secondary',
					},
				},
			},
		},
	},
});
