export const parentLetterTags = [
	{
		name: 'Student First Name',
		value: '<Student_First_Name>',
	},
	{
		name: 'Student Last Name',
		value: '<Student_Last_Name>',
	},
	{
		name: 'Student ID',
		value: '<Student_ID>',
	},
	{
		name: 'Teacher Title',
		value: '<Teacher_Title>',
	},
	{
		name: 'Teacher First Name',
		value: '<Teacher_First_Name>',
	},
	{
		name: 'Teacher Last Name',
		value: '<Teacher_Last_Name>',
	},
];