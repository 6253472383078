import {ChartCell, ChartCellsBox, ChartContainer, ChartTranscriptCell} from './index.styled';
import {useCallback, useRef, useState} from 'react';
import {isNull} from 'underscore';
import {CartesianGridValues} from './components/cartesian-grid-values';
import {CartesianGrid} from './components/cartesian-grid';
import {CandleChartItem} from './components/candle-chart-item';
import {DeltaCheap} from './components/delta-cheap';
import {ChartCandleHighlight, ChartCandleItem, HoveredCandleData} from './types';
import useResizeObserver from '@react-hook/resize-observer';
import {chartCellWidth, chartCellsGap} from './constants';
import {MultiLinedText} from '../../../kit';
import {BoxplotChartCandleProps} from '@esgi/ui';
import {chartColor} from '../../constants';
import {useWidgetCardContext} from '../../context';
import {Color} from '../../../types';

type Props = {
	items: (ChartCandleItem | null)[];
	allItemsAverage: number;
	showAllItemsAverage: boolean;
	highlight: ChartCandleHighlight | null;
};

export function ChartCandle({items, allItemsAverage, showAllItemsAverage, highlight}: Props) {
	const {setWidgetDataPageSize} = useWidgetCardContext();

	const [hoveredCandleData, setHoveredCandleData] = useState<HoveredCandleData | null>(null);

	const chartOverlayRef = useRef<HTMLDivElement>(null);

	useResizeObserver(chartOverlayRef, ({contentRect: {width}}) => {
		// chartCellWidth * n + chartCellsGap * (n - 1) = width
		const itemsCount = (width + chartCellsGap) / (chartCellWidth + chartCellsGap);

		setWidgetDataPageSize(Math.max(Math.floor(itemsCount), 1));
	});

	const getCandleColors = useCallback(
		({
			isMultipleCandles,
			multipleCandlesColor,
			quartile2,
		}: {
			isMultipleCandles: boolean;
			multipleCandlesColor: Color;
			quartile2: number;
		}): {
			activeQuartileBarColor: BoxplotChartCandleProps['activeQuartileBarColor'];
			nonActiveQuartileBarColor: BoxplotChartCandleProps['nonActiveQuartileBarColor'];
		} => {
			if (isMultipleCandles) {
				if (multipleCandlesColor === Color.None) {
					throw new Error('multipleCandlesColor: Color is None');
				}

				return {
					activeQuartileBarColor: chartColor[multipleCandlesColor].activeQuartileBarColor,
					nonActiveQuartileBarColor: chartColor[multipleCandlesColor].nonActiveQuartileBarColor,
				};
			}

			if (isNull(highlight) || highlight.color === Color.None) {
				const highlightIfAbove = 50;
				const isPositiveResult = quartile2 >= highlightIfAbove;

				return {
					activeQuartileBarColor: isPositiveResult ? 'positive' : 'highContrast',
					nonActiveQuartileBarColor: isPositiveResult ? 'positiveVivid' : 'lowContrast',
				};
			}

			const isPositiveResult = quartile2 >= highlight.value;

			return {
				activeQuartileBarColor: isPositiveResult ? chartColor[highlight.color].activeQuartileBarColor : 'highContrast',
				nonActiveQuartileBarColor: isPositiveResult
					? chartColor[highlight.color].nonActiveQuartileBarColor
					: 'lowContrast',
			};
		},
		[highlight],
	);

	return (
		<ChartContainer>
			<CartesianGridValues hoveredCandleData={hoveredCandleData} />

			<CartesianGrid
				allAverageValue={allItemsAverage}
				showAllAverageLine={showAllItemsAverage}
				hoveredCandleData={hoveredCandleData}
			/>

			<>
				<ChartCellsBox type='candleChartBar'>
					{items.map((item, itemCellIndex) => {
						if (isNull(item)) {
							return <ChartCell key={`empty-chart-cell-${itemCellIndex}`} />;
						}

						const {id, title, data} = item;
						const isMultipleCandles = data.length >= 2;

						return (
							<ChartCell key={id}>
								{data.map(
									(
										{chartMinValue, chartMaxValue, quartile1, quartile2, quartile3, multipleCandlesColor},
										periodIndex,
									) => {
										const {activeQuartileBarColor, nonActiveQuartileBarColor} = getCandleColors({
											isMultipleCandles,
											multipleCandlesColor,
											quartile2,
										});

										return (
											<CandleChartItem
												hoveredCandleData={hoveredCandleData}
												setHoveredCandleData={setHoveredCandleData}
												chartMinValue={chartMinValue}
												chartMaxValue={chartMaxValue}
												quartile1={quartile1}
												quartile2={quartile2}
												quartile3={quartile3}
												onHoverTranscriptText={title}
												activeQuartileBarColor={activeQuartileBarColor}
												nonActiveQuartileBarColor={nonActiveQuartileBarColor}
												itemCellIndex={itemCellIndex}
												periodIndex={periodIndex}
												key={`${itemCellIndex}-${periodIndex}`}
											/>
										);
									},
								)}
							</ChartCell>
						);
					})}
				</ChartCellsBox>

				<ChartCellsBox type='candleChartTranscript'>
					{items.map((item, index) => {
						if (isNull(item)) {
							return <ChartTranscriptCell key={`empty-cell-transcript-${index}`} />;
						}

						const {id, title, delta} = item;

						return (
							<ChartTranscriptCell key={id}>
								<MultiLinedText size='xSmall' font='mono' color='highContrast' maxLines={2}>
									{title}
								</MultiLinedText>

								{!isNull(delta) && <DeltaCheap delta={delta} transparent={!isNull(hoveredCandleData)} />}
							</ChartTranscriptCell>
						);
					})}
				</ChartCellsBox>
			</>

			<ChartCellsBox type='overlay' ref={chartOverlayRef} />
		</ChartContainer>
	);
}
