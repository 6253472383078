import {isUndefined} from '@esgi/ui';
import {useCallback, useEffect, useId, useState} from 'react';
import {useFiltersContext} from '../context';
import {FilterGroupCb} from '../types';
import {TestPanelCard} from '../../../types';

type Props<T extends TestPanelCard, I extends string> = {
	defaultItem: I;
	items: I[];
	filterCb: FilterGroupCb<T, I>;
};

export function useFilterGroup<T extends TestPanelCard, I extends string>({defaultItem, filterCb}: Props<T, I>) {
	const groupName = useId();

	const {setFilterGroups} = useFiltersContext<T, I>();

	const [activeItem, setActiveItem] = useState<I>(defaultItem);

	useEffect(() => {
		setFilterGroups((currentState) => ({
			...currentState,
			[groupName]: {
				activeItem,
				filterCb,
			},
		}));

		return () => {
			setFilterGroups((currentState) => {
				delete currentState[groupName];

				return {
					...currentState,
				};
			});
		};
	}, []);

	const updateActiveItem = useCallback(
		(itemValue: I) => {
			setActiveItem(itemValue);

			setFilterGroups((currentState) => {
				const filterGroup = currentState[groupName];

				if (isUndefined(filterGroup)) {
					return currentState;
				}

				return {
					...currentState,
					[groupName]: {
						...filterGroup,
						activeItem: itemValue,
					},
				};
			});
		},
		[groupName, setFilterGroups],
	);

	return {
		activeItem,
		setActiveItem: updateActiveItem,
	};
}
