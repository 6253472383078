import {useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {useFormElementContext} from '@esgillc/ui-kit/form';
import {SchoolSpecialist} from '@esgi/contracts/esgi/types/sso.accounts/features/esgi.districtadmin/schools-specialist/get-by-school-id/school-specialist';
import {DistrictSpecialist} from '@esgi/contracts/esgi/types/sso.accounts/features/esgi.districtadmin/district-specialist/get-by-district-id/district-specialist';
import {SelectableGroup} from '../selectable-group';

interface Props {
	specialistList: (SchoolSpecialist | DistrictSpecialist)[];
}

export function BySpecialist({specialistList}: Props) {
	const {value, setValue} = useFormElementContext();

	const list = useMemo(() => (
		specialistList?.filter((item) => item.groups.length > 0) ?? []
	), [specialistList]);

	if (list?.length === 0) {
		return (
			<Text size='small' font='mono'>
				There are no specified groups for specialists.
			</Text>
		);
	}

	return (
		<>
			{list?.map((specialist) => (
				<SelectableGroup key={specialist.id} item={specialist} groupIDs={value} onChange={setValue} />
			))}
		</>
	);
}