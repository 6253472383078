import {Dispatch} from 'react';
import {ViewMode} from './view-mode';
import {EditMode} from './edit-mode';
import {ViewTableInfo} from '../types';
import {Widget} from '../../../types/widget';
import {GradeLevel, WidgetCard, WidgetMode} from '@esgi/main/features/admins/dashboard';
import {
	SchoolStudentsNeedingSupportWidgetOptions,
	SchoolStudentsNeedingSupportWidgetUpdatedValue,
} from '../../../types/students-needing-support';
import {ClassModel, SubjectTab, TeacherModel} from '../../../../types';

type Props = {
	widgetOptions: SchoolStudentsNeedingSupportWidgetOptions;
	subjects: SubjectTab[];
	allClassesList: ClassModel[];
	allTeachersList: TeacherModel[];
	onDeleteWidgetByID: Dispatch<Widget['id']>;
	onUpdateWidget: Dispatch<SchoolStudentsNeedingSupportWidgetUpdatedValue>;
	tableInfo: ViewTableInfo;
	gradeLevels: GradeLevel[];
	rootPageContainer: Element | DocumentFragment | null;
};

export function Content({
	widgetOptions,
	subjects,
	allClassesList,
	allTeachersList,
	onDeleteWidgetByID,
	tableInfo,
	onUpdateWidget,
	gradeLevels,
	rootPageContainer,
}: Props) {
	const {widgetMode} = WidgetCard.useWidgetCardContext();

	if (widgetMode === WidgetMode.Edit) {
		return (
			<EditMode
				allClassesList={allClassesList}
				allTeachersList={allTeachersList}
				onDeleteWidgetByID={onDeleteWidgetByID}
				subjects={subjects}
				widgetOptions={widgetOptions}
				onUpdateWidget={onUpdateWidget}
				gradeLevels={gradeLevels}
			/>
		);
	}

	return (
		<WidgetCard.ViewMode>
			<ViewMode
				subjects={subjects}
				allClassesList={allClassesList}
				contentAreaIDs={widgetOptions.contentAreaIDs}
				gradeLevelIDs={widgetOptions.gradeLevelIDs}
				classIDs={widgetOptions.classIDs}
				subjectIDs={widgetOptions.subjectIDs}
				periods={widgetOptions.periods}
				tableInfo={tableInfo}
				gradeLevels={gradeLevels}
				rootPageContainer={rootPageContainer}
			/>
		</WidgetCard.ViewMode>
	);
}
