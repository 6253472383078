import {CollapsiblePanel} from '@esgi/main/features/admins/data';
import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CollapsiblePanelHeader = styled(CollapsiblePanel.Header, {
	'& [data-header-content-wrapper]': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
});

export const TitleBox = styled(FlexBox, {
	gap: 6,
	alignItems: 'center',

	'& svg': {
		width: 24,
		height: 24,
	},
});
