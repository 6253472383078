import {Button, Tooltip} from '@esgi/ui';
import {Info} from '@esgi/ui/icons';
import {ChartInfoContainer, ChartTitle, TooltipContent} from './index.styled';
import {GridBox} from '@esgi/ui/layout';
import {IconInfo} from './icon-info';
import {Text} from '@esgi/ui/typography';

type Props = {
	avgResultName: string;
	resultName: string;
	chartName: string;
	description: string;
};

export function Piechart({avgResultName, chartName, description, resultName}: Props) {
	return (
		<Tooltip>
			<Tooltip.Trigger>
				<Button.Icon withBackgroundHover>
					<Info />
				</Button.Icon>
			</Tooltip.Trigger>
			<TooltipContent side='top'>
				<GridBox gapY='4'>
					<ChartInfoContainer>
						<Text size='xSmall' bold color='primaryVivid' data-avg-name=''>
							{avgResultName}
						</Text>
						<IconInfo />
						<Text size='xSmall' bold color='highContrast' data-result=''>
							{resultName}
						</Text>
						<ChartTitle size='xSmall' font='mono' color='highContrast'>
							{chartName}
						</ChartTitle>
					</ChartInfoContainer>
					<Text size='xSmall' bold color='highContrast'>
						{description}
					</Text>
				</GridBox>
			</TooltipContent>
		</Tooltip>
	);
}
