import {SelectableList} from '@esgi/ui/layout';
import {getListValue} from './get-list-value';
import {usePanelContext} from '../../../context';
import {SubjectModel} from '../../../types/subject';
import {ItemContent} from '../item-content';
import {listGroupCSS} from '../list.styled';

type Props = {
	subjects: SubjectModel[];
	hiddenIconText?: string | undefined;
};

export function Default({subjects, hiddenIconText}: Props) {
	const {selectedSubjectID, setSelectedSubjectID} = usePanelContext();

	return (
		<SelectableList>
			<SelectableList.GroupRoot type='single' value={getListValue(selectedSubjectID)}>
				<SelectableList.Group css={listGroupCSS}>
					{subjects.map(({id, name, hidden}) => (
						<SelectableList.Item
							value={String(id)}
							key={id}
							forceSelect={selectedSubjectID !== id}
							onClick={() => setSelectedSubjectID(id)}
							setTextCurrentColor={false}
							variant='outlined'
						>
							<ItemContent subjectName={name} hidden={hidden} hiddenIconText={hiddenIconText} subjectID={id} />
						</SelectableList.Item>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
