import {FolderAdd, FolderRemove, PieChartIcon, Teacher} from '@esgi/ui/icons';
import {
	ActionPanel,
	NegativeButton,
	RunReportButton,
	SingleSelectedExpandablePanel,
} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button, useFlag} from '@esgi/ui';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {getFullName} from '@esgi/main/kits/common';
import {DeleteSpecialistsButton} from '../../../../../../components/delete-specialists-button';
import {ReportsFeature} from '../../../../../../components/reports';

type Props = {
	isPanelExpanded: boolean;
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
};

export function QuickPanel({
	selectedRow,
	onFolderAddClick,
	isPanelExpanded,
	onRemoveFromFolderClick,
}: Props) {
	const [showReports, openReports, closeReports] = useFlag();

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<Teacher />
							<Text size='large' color='highContrast'>
								{getFullName({
									firstName: selectedRow.firstName,
									lastName: selectedRow.lastName,
								})}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteSpecialistsButton />
							<ActionPanel.ActionButtonDivider />

							<Button.Icon withBackgroundHover onClick={onFolderAddClick}>
								<FolderAdd />
							</Button.Icon>

							<NegativeButton onClick={onRemoveFromFolderClick}>
								<FolderRemove />
							</NegativeButton>

							<RunReportButton
								onClick={openReports}
								disabled={!selectedRow.students}
								disabledMessage={
									selectedRow.students
										? undefined
										: 'No students have been added to your class. Please add some before running the report.'
								}
							/>
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<SingleSelectedExpandablePanel.OpenPanelButton
							Icon={PieChartIcon}
							transcript='View Tests Results'
							warningDescription='No students have been added to your specialist. Please add some before running the report.'
							canExpandPanel={!!selectedRow.students}
						/>
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showReports && (
				<ReportsFeature onClose={closeReports} specialist={selectedRow} />
			)}
		</>
	);
}
