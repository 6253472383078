import {Button as ButtonBase} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const Button = styled(ButtonBase, {
	paddingTop: 7,
	paddingBottom: 7,
	paddingLeft: 19,
	paddingRight: 19,
	minWidth: 'unset',
});
