import {createContext, useContext} from 'react';
import {isNull} from 'underscore';

export type TabsContextValue = {
	activeValue: string | undefined;
};

export const TabsContext = createContext<TabsContextValue | null>(null);

export function useTabsContext() {
	const context = useContext(TabsContext);

	if (isNull(context)) {
		throw new Error('useTabsContext is null');
	}

	return context;
}
