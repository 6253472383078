import {ExpandableSelectableBlockTab, ItemNumerable} from '@esgi/main/features/admins/subject-management';
import {PublishForTeacherTabID} from '../../../../../types';
import {publishToTeachetsTitle} from '../../../../constants';

type Parameters = {
	teachersSchools: ItemNumerable[];
};

export function getPublishForTeacherInit({
	teachersSchools,
}: Parameters): ExpandableSelectableBlockTab<PublishForTeacherTabID>[] {
	return [
		{
			value: PublishForTeacherTabID.All,
			label: publishToTeachetsTitle[PublishForTeacherTabID.All],
			isActive: true,
			allItems: [],
			selectedItems: [],
		},
		{
			value: PublishForTeacherTabID.BySchool,
			label: publishToTeachetsTitle[PublishForTeacherTabID.BySchool],
			isActive: false,
			allItems: teachersSchools,
			selectedItems: teachersSchools.map(({id}) => id),
		},
	];
}
