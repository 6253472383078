import {StudentRace} from '@esgi/contracts/esgi/types/esgi.students/enums/student-race';
import {StudentLunchStatus} from '@esgi/contracts/esgi/types/esgi.students/enums/student-lunch-status';
import {StudentSpecialProgram} from '@esgi/contracts/esgi/types/esgi.students/enums/student-special-program';
import {Response} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/modules/students/profile/update/init/response';
import {stringArrayToNumberArray, toISOStringWithoutTimeZone} from './utils';
import {InformationFormType} from './forms/information';
import {LocationFormType} from './forms/location';
import {ProfileFormType} from './forms/profile';

export {StudentRace, StudentLunchStatus, StudentSpecialProgram};

export {type UserModel} from '@esgi/contracts/esgi/types/esgi.accounts/queries/esgi.apigateway/modules/forms/student/users/user-model';
export type ProfileState = Response['profile'] & Response['location'] & Response['information'];

export enum FieldValidation {
	Required = 'required',
	AssignRequired = 'AssignRequired'
}

export enum AssignStudentType {
	Teacher = 'Teacher',
	Specialist = 'Specialist',
}

export enum StudentProfileMode {
	add,
	edit,
}
export enum StudentGenders {
	Female = 'F',
	Male = 'M',
	Other = 'U',
}

export enum StudentProfileTab {
	Profile = 'profile',
	Location = 'location',
	Information = 'information',
}

export interface TabValidation {
	valid: boolean;
}

export interface TeacherEntityGroups {
	groupIDs: string[];
	classIDs: string[]
}

export class DefaultPhoto {
	public imageUrl = '';
	public imageCropUrl = '';
	public crop = {x: 0, y: 0, zoom: 0};
}

export class Profile {
	public firstName: string;
	public lastName: string;
	public gender: string;
	public birthDate: string;
	public studentIDN: string;
	public exportIDN: string;
	public gradeLevelID: number;
	public languageID: number;
	public credential: Credential = undefined;
	public photo: Photo | null;

	constructor(profile: ProfileFormType['value'], photo: NewPhoto) {
		this.firstName = profile.firstName;
		this.lastName = profile.lastName;
		this.gender = profile.gender[0];
		this.birthDate = toISOStringWithoutTimeZone(profile.birthDate[0]) || '';
		this.studentIDN = profile.studentIDN || null;
		this.exportIDN = profile.exportIDN || null;
		this.gradeLevelID = Number(profile.gradeLevel[0]);
		this.languageID = Number(profile.language[0]);
		this.photo = photo?.imageUrl ? photo : new DefaultPhoto();
	}
}

export class Location {
	public districtID: number = undefined;
	public schoolID: number;
	public globalSchoolYearID: number = undefined;
	public teacherID: number;
	public specialistID: number = undefined;
	public classIDs: number[];
	public groupIDs: number[];
	public specialistGroupIDs: number[];

	constructor(location: LocationFormType['value']) {
		this.schoolID = location.schoolID ? Number(location.schoolID) : undefined;
		this.teacherID = location.teacherID ? Number(location.teacherID) : undefined;
		this.classIDs = stringArrayToNumberArray(location.teacherEntityIDs.classIDs);
		this.groupIDs = stringArrayToNumberArray(location.teacherEntityIDs.groupIDs);
		this.specialistGroupIDs = stringArrayToNumberArray(location.specialistGroupIDs);
	}
}

export class Information {
	public race: StudentRace;
	public lunchStatus: StudentLunchStatus;
	public studentSpecialPrograms: StudentSpecialProgram[];
	public comments: string;

	constructor(information: InformationFormType['value']) {
		this.race = information.race?.[0];
		this.lunchStatus = information.lunchStatus[0];
		this.studentSpecialPrograms = information.studentSpecialPrograms;
		this.comments = information.comments;
	}
}

export interface Photo {
	imageUrl: string;
	imageCropUrl: string;
	crop: CropParams;
}

export interface NewPhoto {
	imageUrl: string;
	imageCropUrl: string;
	crop: CropParams;
	remove?: boolean;
}

export class Credential {
	constructor(
		public userName: string,
		public password: string,
	) {
	}
}

export interface Dictionary {
	genders: DictionaryItem[];
	gradeLevels: DictionaryItem[];
	languages: DictionaryItem[];
	lunchStatuses: DictionaryItem<StudentLunchStatus>[];
	races: DictionaryItem<StudentRace>[];
	specialPrograms: DictionaryItem<StudentSpecialProgram>[];
}

export interface DictionaryItem<IDType = string> {
	id: IDType,
	name: string,
	valueName?: string
}

export interface Schools {
	schoolID: number;
	name: string;
}

export interface GroupItem {
	id: number;
	name: string;
	studentIDs: number[];
	classID: number;
	disabled?: boolean;
}

export interface ImageData {
	image: string,
	croppedImage: string,
	cropParameters: CropParams
}

export interface CropParams {
	x: number,
	y: number,
	zoom: number
}

export class StudentModel {
	constructor(
		public id: number,
		public firstName: string,
		public lastName: string,
		public photoUrl: string,
		public gradeLevelID: number,
		public hasCredentials: boolean,
		public studentIDN: string,
		public languageID: number,
	) {
	}
}

export class Student {
	id: number;
	firstName: string;
	lastName: string;
	gradeLevelID: number;
	photoUrl: string;
	hasCredentials: boolean;
}
