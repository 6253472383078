import {UserResponse} from '@esgi/core/authentication';
import {environment, Environments} from '@esgi/core/environments';
import {RoutesMap} from '@esgi/main/libs/core';

export function afterLoginHandler(user: UserResponse, returnPage?: string) {
	if (user.type === 'C' || user.type === 'D') {
		if (environment?.country === 'ca') {
			document.location.href = RoutesMap.adminHome;
		} else {
			document.location.href = '/dashboard';
		}
		return;
	}

	if (user.type === 'PA') {
		sessionStorage.setItem('paLoggedIn', 'true');
	}

	if (returnPage && environment.environmentType === Environments.Local) {
		document.location.href = returnPage;
		return;
	}

	document.location.href = RoutesMap.home;
}
