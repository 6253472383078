import {isNull} from 'underscore';
import {CenteredText, ErrorBox} from './index.styled';

type Props = {
	message: string | null;
};

export function ErrorMessageBox({message}: Props) {
	if (isNull(message)) {
		return null;
	}

	return (
		<ErrorBox>
			<CenteredText size='xSmall' font='mono' bold color='negative'>
				{message}
			</CenteredText>
		</ErrorBox>
	);
}
