import {ThemeColorKeys} from '@esgi/ui';
import {Box} from './index.styled';
import {Text} from '@esgi/ui/typography';

type Props = {
	color: ThemeColorKeys;
	value: string;
	transcript: string;
};

export function ChartInfoTranscript({color, value, transcript}: Props) {
	return (
		<Box>
			<Text size='small' font='mono' color={color}>
				{value}
			</Text>
			<Text size='small' font='mono' color={color}>
				{transcript}
			</Text>
		</Box>
	);
}
