import moment from 'moment';
import {useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {StyledDaysContainer, DaysCount} from './index.styled';

interface Props {
	date: string;
}

export function Timestamp({date}: Props) {
	const {formattedDate, daysUntil} = useMemo(() => {
		const difference = new Date(date).getTime() - new Date().getTime();
		const differenceDays = Math.floor(difference / (1000 * 60 * 60 * 24));

		return {
			formattedDate: moment(date).format('MM/DD/YYYY'),
			daysUntil: Math.max(differenceDays, 0),
		};
	}, [date]);

	return (
		<GridBox flow='column' align='center' gap={2}>
			<Text size='small' font='mono' color='base'>{formattedDate}</Text>
			<StyledDaysContainer align='center'>
				<DaysCount size='small'>{daysUntil}</DaysCount>
				<Text size='small' color='surface'>Days</Text>
			</StyledDaysContainer>
		</GridBox>
	);
}