import {Dispatch, useMemo, useState} from 'react';
import {useUser} from '@esgi/core/authentication';
import {generateRandomString, isUndefined, useStreamEffect} from '@esgi/ui';
import {Class, Group, Student, User} from '@esgi/main/libs/school-admin-store';
import {ElementStatus, FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {groupNameMaxLength} from '../../../components/group-name-input/constants';
import {AddGroupForm} from './types';

type Parameters = {
	classes: Class[];
	groups: Group[];
	teachers: User[];
	setIsFormValid: Dispatch<boolean>;
	setIsFormTouched: Dispatch<boolean>;
};

export function useAddForm({setIsFormValid, setIsFormTouched, teachers, classes, groups}: Parameters) {
	const user = useUser();

	const [selectedPrimaryTeacherID, setSelectedPrimaryTeacherID] = useState<User['id'] | null>(null);

	const [activeClassesList, setActiveClassesList] = useState<Class[]>([]);
	const [classListUniqueKey, setClassListUniqueKey] = useState(generateRandomString());

	const form: AddGroupForm = useForm(() => (
		new FormGroup({
			primaryTeacherID: new FormControl<string[]>([], {
				validators: [Validators.required()],
			}),
			teacherClass: new FormControl<string[]>([], {
				validators: [Validators.required()],
				initialValidateStatus: ElementStatus.disabled,
			}),
			groupName: new FormControl('', {
				initialValidateStatus: ElementStatus.disabled,
			}),
			studentsIDs: new FormControl<Student['id'][]>([]),
		})
	));

	const activePrimaryTeachersList = useMemo(() => {
		return teachers.filter((teacher) => teacher.schoolID === Number(user?.schoolID));
	}, [teachers, user?.schoolID]);

	useStreamEffect(form.controls.primaryTeacherID.onChanged, ({currState: {value}, reason}) => {
		const primaryTeacherID = value[0];

		if (!isUndefined(primaryTeacherID) && reason === 'value') {
			const numberedPrimaryTeacherID = Number(primaryTeacherID);

			const teacherClasses = classes.filter(({teacherID}) => teacherID === numberedPrimaryTeacherID);

			form.controls.teacherClass.value = [];
			form.controls.teacherClass.status = ElementStatus.untouched;

			setActiveClassesList(teacherClasses);
			setClassListUniqueKey(generateRandomString());

			form.controls.groupName.status = ElementStatus.disabled;

			form.validate().subscribe(({valid}) => {
				setIsFormValid(valid);
			});

			setSelectedPrimaryTeacherID(numberedPrimaryTeacherID);
		}
	});

	useStreamEffect(form.controls.teacherClass.onChanged, ({currState: {value}, reason}) => {
		const teacherClassID = value[0];

		if (!isUndefined(teacherClassID) && reason === 'value') {
			const groupsInClass = groups.filter(({classID}) => classID === Number(teacherClassID));

			form.controls.groupName.validators.length = 0;

			form.controls.groupName.validators.push(
				Validators.required(),
				Validators.length.max(groupNameMaxLength),
				Validators.isDublicateValue(groupsInClass.map(({name}) => name)),
			);

			form.controls.groupName.status = ElementStatus.untouched;

			form.validate().subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		}
	});

	useStreamEffect(form.onChanged, ({
		currState: {value: {studentsIDs, groupName, primaryTeacherID, teacherClass}},
	}) => {
		const isFormTouched =
				groupName !== '' ||
				!isUndefined(primaryTeacherID[0]) ||
				!isUndefined(teacherClass[0]) ||
				Boolean(studentsIDs.length);

		setIsFormTouched(isFormTouched);

		form.validate(true).subscribe(({valid}) => {
			setIsFormValid(valid);
		});
	});

	return {
		form,
		activePrimaryTeachersList,
		activeClassesList,
		classListUniqueKey,
		selectedPrimaryTeacherID,
	};
}
