import {
	AssignmentIcon,
	Cast,
	PieChartIcon,
	File,
	Growth as GrowthIcon,
	GradeScale as GradeScaleIcon,
	Preview,
	RubricReport,
	StudentProgress as StudentProgressIcon,
	StudentCard,
	Students,
	Users,
	Specialist,
	PreAssess,
} from '@esgi/ui/icons';
import {ReportModel} from './types';
import {ReportBase} from './components/report-base';
import {ReportType} from '@esgi/main/features/admins/data';

function ParentLetter({
	name = 'Parent Letter',
	description = 'Print student results with personalized messaging for parent review',
	hierarchy,
}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={File}
			reportType={ReportType.ParentLetter}
			hierarchy={hierarchy}
		/>
	);
}

function Growth({name = 'Growth', description = 'View growth on tests in a Subject Tab', hierarchy}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={GrowthIcon}
			reportType={ReportType.Growth}
			hierarchy={hierarchy}
		/>
	);
}

function TotalStudents({
	name = 'Total Students',
	description = 'Review and compare student scores for a subject tab at the District level',
	hierarchy,
}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={Students}
			reportType={ReportType.TotalStudents}
			hierarchy={hierarchy}
		/>
	);
}

function StudentDetail({
	name = 'Student Detail',
	description = 'Analyze a comprehensive breakdown of correct/incorrect items, scoring, and student progress over time',
	hierarchy,
}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={StudentCard}
			reportType={ReportType.StudentsDetail}
			hierarchy={hierarchy}
		/>
	);
}

function StudentProgress({
	name = 'Student Progress',
	description = 'Review and compare student scores for a subject tab at the District level',
	hierarchy,
}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={StudentProgressIcon}
			reportType={ReportType.StudentsProgress}
			hierarchy={hierarchy}
		/>
	);
}

function Totals({
	prefix,
	name = `${prefix} Totals`,
	description = 'Review and compare student scores for a subject tab',
	hierarchy,
}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={AssignmentIcon}
			reportType={ReportType.Total}
			hierarchy={hierarchy}
		/>
	);
}

function GradeScale({
	prefix,
	name = `${prefix} Grades`,
	description = 'Apply customizable grade scales to student scores',
	hierarchy,
}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={GradeScaleIcon}
			reportType={ReportType.Grade}
			hierarchy={hierarchy}
		/>
	);
}

function ItemAnalysis({
	name = 'Item Analysis',
	description = 'Analyze data based on least and most known items',
	hierarchy,
}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={Preview}
			reportType={ReportType.ItemAnalysis}
			hierarchy={hierarchy}
		/>
	);
}

function PieCharts({
	name = 'Pie Charts',
	description = 'Access a printable report of homepage pie charts',
	hierarchy,
}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={PieChartIcon}
			reportType={ReportType.PieCharts}
			hierarchy={hierarchy}
		/>
	);
}

function RubricResults({
	name = 'Rubric Results',
	description = 'View results from rubric tests',
	hierarchy,
}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={RubricReport}
			reportType={ReportType.RubricResults}
			hierarchy={hierarchy}
		/>
	);
}

function UntestedStudents({
	name = 'Untested Students',
	description = 'Identify untested students to administer assessments',
	hierarchy,
}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={Users}
			reportType={ReportType.UntestedStudents}
			hierarchy={hierarchy}
		/>
	);
}

function UsageReport({name = '', description = '', hierarchy}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={Cast}
			reportType={ReportType.TeacherActivity}
			hierarchy={hierarchy}
		/>
	);
}

function PreAssessActivityReport({name = '', description = '', hierarchy}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={PreAssess}
			reportType={ReportType.PreAssessActivity}
			hierarchy={hierarchy}
		/>
	);
}

function SpecialistActivityReport({name = '', description = '', hierarchy}: ReportModel) {
	return (
		<ReportBase
			name={name}
			description={description}
			Icon={Specialist}
			reportType={ReportType.SpecialistActivity}
			hierarchy={hierarchy}
		/>
	);
}

export const Reports = {
	ParentLetter,
	StudentDetail,
	StudentProgress,
	Totals,
	GradeScale,
	ItemAnalysis,
	PieCharts,
	RubricResults,
	UntestedStudents,
	Growth,
	TotalStudents,
	UsageReport,
	PreAssessActivityReport,
	SpecialistActivityReport,
};
