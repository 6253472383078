export {type OutModel as SettingsData} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/school-admin/init/out-model';

export enum SettingsTab {
	SystemSettings = 'systemSettings',
	TeacherSettings = 'TeacherSettings',
}

export interface TabItem {
	value: SettingsTab;
	name: string;
}

export interface TabItemsGroup {
	general: TabItem[];
}

export type SettingsTabTouchedData = {
	[tab in SettingsTab]: boolean;
}
