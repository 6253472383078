import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';

export const Root = styled(FlexBox, {
	width: '100%',
	flexDirection: 'column',
	gap: 20,
});

export const Header = styled(FlexBox, {
	width: '100%',
	justifyContent: 'space-between',
	padding: '18px 20px',
});

export const HeaderTitle = styled(FlexBox, {
	alignItems: 'center',
	gap: 4,
});

export const HeaderActions = styled(FlexBox, {
	alignItems: 'center',
	gap: 16,
	padding: '6px 0',
});

export const Content = styled(FlexBox, {
	width: '100%',
	flexDirection: 'column',
	alignItems: 'center',
	gap: 12,
	padding: '0 20px',
});

export const ContentTitle = styled(FlexBox, {
	width: '100%',
	justifyContent: 'center',
	padding: '20px 0px 12px 0px',
	borderBottom: '1px solid #EBEBEB',
});

export const ContentTitleWrapper = styled(FlexBox, {
	width: '100%',
	padding: '0px 12px',
	justifyContent: 'center',
});

export const SelectAllButton = styled(Button, {
	justifySelf: 'start',
	minWidth: 'unset',
});

export const ItemHeader = styled(FlexBox, {
	padding: '0px 6px',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: 8,
});

export const CheckboxWrapper = styled(FlexBox, {
	height: '24px',
	padding: '2px',
	justifyContent: 'center',
	alignItems: 'center',
	fontSize: '24px',

	'& path': {
		fill: 'currentColor',
	},
});

export const ItemDescription = styled(FlexBox, {
	padding: 6,
	alignItems: 'center',
	gap: 8,
});

export const Item = styled(FlexBox, {
	padding: '8px 6px',
	borderRadius: '6px',
	width: '100%',
	flexDirection: 'column',
	userSelect: 'none',

	[`& > ${ItemDescription}`]: {
		color: '#7A7A7A !important',
	},

	variants: {
		disabled: {
			true: {
				backgroundColor: '$neutral96',
				border: '1px solid $neutral92',
				color: '$neutral64',
				cursor: 'default',

				[`& > ${ItemHeader}`]: {
					color: '#A3A3A3 !important',
				},

				[`& > ${CheckboxWrapper}`]: {
					color: '#A3A3A3 !important',
				},

				[`& > ${ItemDescription}`]: {
					color: '#A3A3A3 !important',
				},

			},
		},
		active: {
			true: {
				border: '1px solid #CDE7F4',
				backgroundColor: '#EFF7FB',
				color: '$primary',
				cursor: 'pointer',

				[`& > ${CheckboxWrapper}`]: {
					color: '$primary !important',
				},

			},
			false: {
				backgroundColor: '#FCFCFC',
				border: '1px solid #EBEBEB',
				color: '$neutral64',
				cursor: 'pointer',
				boxShadow: '0px 2px 6px 0px rgba(230, 232, 239, 0.24), 0px 4px 12px 0px rgba(0, 0, 0, 0.04)',
			},
		},
	},
});

export const List = styled(FlexBox, {
	flexDirection: 'column',
	alignItems: 'flex-start',
	gap: 8,
	width: '100%',
});