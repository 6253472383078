import {TabItem} from '../components/tabs/types';
import {SpecialistTabID} from './types';

export const specialistGroupTabItems: TabItem<SpecialistTabID>[] = [
	{
		value: SpecialistTabID.District,
		label: 'District Specialist',
	},
	{
		value: SpecialistTabID.School,
		label: 'School Specialist',
	},
];
