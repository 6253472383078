import {useCallback, useRef} from 'react';
import {Drawer, UnsavedChangesConfirmation, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {StudentProfile} from './components/student-profile';
import {ModalOwnerContainer} from './index.styled';
import {FormTouchedRef} from './types';
import {useFlag} from '@esgi/ui';

interface Props {
	studentID: string;
	onOpenTransferStudent?: VoidFunction;
	onClose: VoidFunction;
}

export function AddEditStudent({studentID, onOpenTransferStudent, onClose}: Props) {
	const drawerRef = useDrawerRef();

	const closeDrawer = useDrawerClose(drawerRef, onClose);
	const handleOpenTransfer = useDrawerClose(drawerRef, onOpenTransferStudent);

	const [showUnsavedChangesConfirmation, openUnsavedChangesConfirmation, closeUnsavedChangesConfirmation] = useFlag();

	const studentDrawerModalsOwner = useRef();
	const formTouchedRef = useRef<FormTouchedRef>();

	const onDrawerClose = useCallback(() => {
		if (formTouchedRef.current?.isFormTouched) {
			openUnsavedChangesConfirmation();
			return;
		}

		closeDrawer();
	}, [closeDrawer, openUnsavedChangesConfirmation]);

	return (
		<>
			<Drawer drawerRef={drawerRef} onClickOutside={onDrawerClose}>
				<StudentProfile
					id={studentID}
					modalsOwner={studentDrawerModalsOwner}
					formTouchedRef={formTouchedRef}
					onOpenTransferStudent={handleOpenTransfer}
					onDrawerClose={onDrawerClose}
					onDrawerForceClose={closeDrawer}
				/>
			</Drawer>
			<ModalOwnerContainer ref={studentDrawerModalsOwner} />

			{showUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onClose={closeUnsavedChangesConfirmation} onCloseAnyway={closeDrawer} />
			)}
		</>
	);
}

