import {createContext, useContext} from 'react';
import {isNull} from 'underscore';
import {GridApi} from 'ag-grid-community';

export type ActionPanelContextValue<T extends Record<string, unknown>> = {
	api: GridApi<T> | null;
	selectedItemsCount: number;
	deselectItems: VoidFunction;
};

export const ActionPanelContext = createContext<ActionPanelContextValue<any> | null>(null);

export function useActionPanelContext<T extends Record<string, unknown>>() {
	const context = useContext(ActionPanelContext);

	if (isNull(context)) {
		throw new Error('useActionPanelContext is null');
	}

	return context as unknown as ActionPanelContextValue<T>;
}
