import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';

export type ParentLetterFormType = FormGroup<{
	body: FormControl<string>,
	logo: FormControl<string>,
},
	ControlValue<{
		body: FormControl<string>,
		logo: FormControl<string>,
	}>>

export const createParentLetterForm = ():
	ParentLetterFormType => new FormGroup({
	body: new FormControl(''),
	logo: new FormControl(null),
});
