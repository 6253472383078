import {isNull} from 'underscore';
import {useMemo} from 'react';
import {getFullName} from '@esgi/main/kits/common';
import {SchoolStudentsNeedingSupportStudentData} from '../../../types/students-needing-support';
import {StudentsSinglePeriodData} from '../types';
import {roundNumber} from '../utils/round-number';
import {GradeLevel} from '@esgi/main/features/admins/dashboard';

export function useStudentsOnePeriodItems({
	studentsData,
	getClassName,
	getGradeShortName,
}: {
	studentsData: SchoolStudentsNeedingSupportStudentData[];
	getClassName: (id: number | null) => string;
	getGradeShortName: (gradeID: GradeLevel['id']) => string | null;
}) {
	return useMemo<StudentsSinglePeriodData[]>(() => {
		return studentsData.map(({student, teacher, avgResults}) => ({
			rowID: student.id,
			studentID: student.id,
			student: getFullName({
				firstName: student.firstName,
				lastName: student.lastName,
			}),
			teacher: !isNull(teacher)
				? getFullName({
						firstName: teacher.firstName,
						lastName: teacher.lastName,
					})
				: null,
			class: getClassName(student.classIDs[0] ?? null),
			grade: getGradeShortName(student.gradeLevelID),
			avg: roundNumber(avgResults[0] ?? 0, {precise: 1}),
		}));
	}, [getGradeShortName, getClassName, studentsData]);
}
