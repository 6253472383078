import {SubjectPublishType} from '@esgi/main/features/admins/subject-management';
import {RetestSubjectType} from '../../../../types';
import {SubjectSettingDataExtended} from '../../types';

export const initPartialData: Pick<
	SubjectSettingDataExtended,
	| 'isActive'
	| 'subjectName'
	| 'retestSubjectType'
	| 'isShuffleQuestions'
	| 'publishPeriodValue'
	| 'isPublishForTeacherActive'
	| 'isPublishForSpecialistsActive'
> = {
	isActive: true,
	subjectName: '',
	retestSubjectType: RetestSubjectType.Any,
	isShuffleQuestions: false,
	publishPeriodValue: {
		type: SubjectPublishType.Indefinitely,
		dateFrom: null,
		dateTo: null,
		trackDatesIDs: [],
	},
	isPublishForTeacherActive: true,
	isPublishForSpecialistsActive: true,
};
