import {StudentSort} from '@esgi/core/authentication';
import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';

export type SystemSettingsFormType = FormGroup<{
	sortBy: FormControl<StudentSort[]>,
	timeZoneID: FormControl<string[]>,
}, ControlValue<{
	sortBy: FormControl<StudentSort[]>,
	timeZoneID: FormControl<string[]>,
}>>

export const createSystemSettingsForm = (): SystemSettingsFormType => (
	new FormGroup({
		sortBy: new FormControl([]),
		timeZoneID: new FormControl([]),
	})
);
