import {OpenPanelButton} from './components/open-panel-button';
import {QuickPanel} from './components/quick-panel';
import {Root} from './components/root';
import {QuickPanelHeader} from './components/quick-panel-header';
import {ExpandedPanel} from './components/expanded-panel.styled';

export const SingleSelectedExpandablePanel = {
	Root,
	QuickPanel: Object.assign(QuickPanel, {
		Header: QuickPanelHeader,
	}),
	ExpandedPanel,
	OpenPanelButton,
};
