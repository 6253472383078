import {BurgerButton, IconComponent, isUndefined} from '@esgi/ui';
import {Question, Setting} from '@esgi/ui/icons';
import {GridBox, NavigationBar, useNavigationBarContext} from '@esgi/ui/layout';
import {LogoWithCaption} from '../../../../components/logo-with-caption';
import {useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {routes} from '@esgi/main/libs/core';
import {menuIconByPath, menuLabelByRoute} from '../../../../constants';
import {isNull} from 'underscore';
import {Text} from '@esgi/ui/typography';
import {RightContentBox, SelectedItemIndicator, headCSS} from './index.styled';
import {PanelButton} from '../../../../components/panel-button';
import {UserAccountButton} from '../../../../components/user-account-button';

type Props = {
	onSettingsClick: VoidFunction;
	userFirstName: string;
	userLastName: string;
	userAvatarSrc: string | null;
	onAvatarClick: VoidFunction;
	onHelpClick: VoidFunction;
};

export function PanelHeader({
	onSettingsClick,
	userFirstName,
	userLastName,
	userAvatarSrc,
	onAvatarClick,
	onHelpClick,
}: Props) {
	const {expanded, setExpanded} = useNavigationBarContext();

	const {pathname} = useLocation();

	const [activeRouteData, setActiveRouteData] = useState<{
		Icon: IconComponent;
		label: string;
	} | null>(null);

	useEffect(() => {
		const currentRoute = Object.entries(routes.districtAdmin).find(([_, route]) => {
			if (typeof route === 'string') {
				return pathname.startsWith(route);
			}

			return Object.entries(route).find(([_, nestedRoute]) => pathname.startsWith(nestedRoute));
		});

		const currentRouteKey = currentRoute?.[0];

		if (
			isUndefined(currentRouteKey) ||
			isUndefined(menuIconByPath[currentRouteKey]) ||
			isUndefined(menuLabelByRoute[currentRouteKey])
		) {
			setActiveRouteData(null);
			return;
		}

		setActiveRouteData({
			Icon: menuIconByPath[currentRouteKey],
			label: menuLabelByRoute[currentRouteKey],
		});
	}, [pathname]);

	return (
		<NavigationBar.Head css={headCSS}>
			<GridBox gap='3' align='center' flow='column'>
				<BurgerButton expanded={expanded} setExpanded={setExpanded} />

				<LogoWithCaption />

				{!isNull(activeRouteData) && (
					<SelectedItemIndicator>
						<activeRouteData.Icon />
						<Text size='small' color='mediumContrast'>
							{activeRouteData.label}
						</Text>
					</SelectedItemIndicator>
				)}
			</GridBox>

			<RightContentBox>
				<PanelButton size='small' onClick={onSettingsClick}>
					<Setting />
				</PanelButton>
				<UserAccountButton
					size='md'
					firstName={userFirstName}
					lastName={userLastName}
					avatarSrc={userAvatarSrc}
					onClick={onAvatarClick}
				/>
				<PanelButton size='small' variant='orange' onClick={onHelpClick}>
					<Question />
				</PanelButton>
			</RightContentBox>
		</NavigationBar.Head>
	);
}
