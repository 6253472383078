import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/school-admin/data';
import {RowDef} from '../types';
import {ReportDialog, Reports} from '../../_kit';
import {NoStudentsAlert} from '../../_kit/no-students-alert';

type Props = {
	specialist: RowDef;
	onClose: VoidFunction;
};

export function ReportsFeature({specialist, onClose}: Props) {
	const hierarchy = useMemo<Hierarchy>(
		() => ({
			specialistsID: specialist.id,
			specialistGroupID: specialist.specialistGroups[0],
		}),
		[specialist]
	);

	if (!specialist.students) {
		return (
			<NoStudentsAlert
				onClose={onClose}
				prefix={`${specialist.firstName} ${specialist.lastName} group`}
			/>
		);
	}

	return (
		<ReportDialog
			onClose={onClose}
			header={`${specialist.firstName} ${specialist.lastName}`}
			title='Specialist Group Reports'
		>
			<Reports.ItemAnalysis hierarchy={hierarchy} />
			<Reports.PieCharts hierarchy={hierarchy} />
			<Reports.UntestedStudents hierarchy={hierarchy} />
		</ReportDialog>
	);
}
