import {styled} from '@esgi/ui/theme';
import {ToggleGroup} from '@esgi/ui/controls';

export const TabsControllerRoot = styled(ToggleGroup.Root, {
	padding: '2px',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '6px',
	width: '100%',
	marginBottom: '40px',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	gridAutoColumns: '1fr',
});

export const Item = styled(ToggleGroup.Item, {
	paddingTop: 9,
	paddingBottom: 9,
});
