import {useUser} from '@esgi/core/authentication';
import {formatInTimeZone} from 'date-fns-tz';
import {useMemo} from 'react';
import {isNull} from 'underscore';

export function useConvertDateToUserTz(date: string | Date) {
	const currentUser = useUser();

	return useMemo(() => {
		if (isNull(currentUser)) {
			return date;
		}

		return formatInTimeZone(date, currentUser.timeZone, 'yyyy-MM-dd HH:mm');
	}, [currentUser, date]);
}
