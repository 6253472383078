import {BehaviorSubject, tap} from 'rxjs';
import {Import} from 'api/import-api';
import {EventBusManager} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import {UserSettingsDistrictAdminController} from '@esgi/contracts/esgi';
import {StudentSort} from '@esgi/contracts/esgi/types/esgi.enums/student-sort';
import {Events as BackgroundDownloadManagerEvents} from 'shared/background-download/events';
import {IntegrationsClassLinkController, IntegrationsCleverController} from '@esgi/contracts/sso';
import {DownloadImportFilesResponse} from 'shared/modules/user-settings/district-administrator/types';
import {ShowStudentID} from '@esgi/contracts/esgi/types/esgi.reports/enums/district-settings/show-student-id';
import {ParentLetterData, SettingsData} from './types';

export class SettingsService extends BaseService {
	public readonly loaded$ = new BehaviorSubject(false);

	public readonly settingsData$ = new BehaviorSubject<SettingsData>(null);
	public readonly parentLetterData$ = new BehaviorSubject<ParentLetterData>(null);

	private readonly settingsController = new UserSettingsDistrictAdminController();

	private readonly cleverController = new IntegrationsCleverController();
	private readonly classLinkController = new IntegrationsClassLinkController();

	private readonly importApi = new Import();
	private readonly eventBusManager = new EventBusManager();

	public init() {
		this.settingsController.init().subscribe((response) => {
			this.settingsData$.next(response);
			this.loaded$.next(true);
		});

		this.settingsController.parentLetterInit().subscribe((response) => {
			this.parentLetterData$.next(response);
		});
	}

	public saveSettings(data: Partial<SettingsData>) {
		this.settingsData$.next({...this.settingsData$.value, ...data});

		return this.settingsController.update({
			userID: undefined,
			userType: undefined,
			districtID: undefined,
			allowTeachersRenew: undefined,
			showExpiredUsers: this.settingsData$.value.showExpiredUsers,
			sortBy: this.settingsData$.value.sortBy as unknown as StudentSort,
			timeZone: this.settingsData$.value.selectedTimeZone.id,
			reportShowStudentID: this.settingsData$.value.reportShowStudentID as unknown as ShowStudentID,
		});
	}

	public async saveParentLetter(body: ParentLetterData['body'], logoBase64: string, isDefaultBody: boolean) {
		let logo = null;

		if (logoBase64) {
			const image = new Image();
			image.src = logoBase64;
			await image.decode();

			logo = {
				base64: logoBase64.substring(logoBase64.indexOf(';base64') + ';base64,'.length),
				contentType: logoBase64.substring('data:'.length, logoBase64.indexOf(';base64')),
				height: image.height,
				width: image.width,
			};
		}

		this.parentLetterData$.next({...this.parentLetterData$.value, body, logo});
		return this.settingsController.parentLetterSave({
			userID: undefined,
			districtID: undefined,
			templateID: this.parentLetterData$.value.templateID,
			bodyIsDefault: isDefaultBody,
			body,
			logo,
		}).pipe(tap((response) => {
			this.parentLetterData$.next({...this.parentLetterData$.value, templateID: response.templateID});
		}));
	}

	public publishParentLetter(isPublish: boolean) {
		this.parentLetterData$.next({...this.parentLetterData$.value, published: isPublish});

		this.settingsController.parentLetterPublish({
			userID: undefined,
			districtID: undefined,
			templateID: this.parentLetterData$.value.templateID,
			published: isPublish,
		}).pipe(tap((response) => {
			this.parentLetterData$.next({...this.parentLetterData$.value, templateID: response.templateID});
		})).subscribe();
	}

	public unlinkCleverAccount() {
		this.cleverController.linkingUnlink()
			.subscribe({
				next: () => {
					this.settingsData$.next({...this.settingsData$.value, isCleverAccountLinked: false});
				},
			});
	}

	public unlinkClassLinkAccount() {
		return this.classLinkController.linkingUnlink()
			.subscribe({
				next: () => {
					this.settingsData$.next({...this.settingsData$.value, isOneClickAccountLinked: false});
				},
			});
	}

	public exportRosterClickHandler(globalSchoolYearId: number) {
		this.importApi.downloadImportFiles({globalSchoolYearId}).then((data) => {
			const args: BackgroundDownloadManagerEvents.StartImportArgs = {fileID: (data as DownloadImportFilesResponse).id};
			this.eventBusManager.dispatch(BackgroundDownloadManagerEvents.StartImport, args);
		}).catch((err) => {
			console.error(err);
		});
	}

	public override dispose() {
		super.dispose();
		this.settingsController.dispose();
		this.cleverController.dispose();
		this.classLinkController.dispose();
	}
}