import {Dispatch} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {IFormControl} from '@esgi/ui/form';
import {ContentArea, Subject, Teacher} from '@esgi/ui/icons';
import {
	FormToggleGroup,
	GradeLevel,
	PerformanceWidgetViewType,
	SelectDrawerable,
	WidgetCard,
	WidgetViewSize,
} from '@esgi/main/features/admins/dashboard';
import {SubjectTab, TeacherModel} from '../../../../../../../types';
import {useDynamicAdaptedItems} from './use-dynamic-adapted-items';
import {sizeItems, viewTypeItems} from './constants';
import {MainSettingsBox} from './index.styled';
import {SelectDrawerableFilterable} from '../../../../../../components/select-drawerable-filterable';

type Props = {
	viewTypeControl: IFormControl<PerformanceWidgetViewType>;
	sizeControl: IFormControl<WidgetViewSize>;
	subjects: SubjectTab[];
	selectedContentArea: string[];
	setSelectedContentArea: Dispatch<string[]>;
	selectedSubjectIDs: string[];
	setSelectedSubjectIDs: Dispatch<string[]>;
	selectedGradeLevels: string[];
	setSelectedGradeLevels: Dispatch<string[]>;
	teachers: TeacherModel[];
	selectedTeachersIDs: string[];
	setSelectedTeachersIDs: Dispatch<string[]>;
	gradeLevels: GradeLevel[];
};

export function MainSettings({
	viewTypeControl,
	sizeControl,
	subjects,
	selectedContentArea,
	selectedGradeLevels,
	selectedSubjectIDs,
	setSelectedContentArea,
	setSelectedGradeLevels,
	setSelectedSubjectIDs,
	teachers,
	selectedTeachersIDs,
	setSelectedTeachersIDs,
	gradeLevels,
}: Props) {
	const {
		subjectLevel,
		setSubjectLevel,
		teachersAdaptedItems,
		subjectsAdaptedItems,
		subjectsFilteredAdaptedItems,
		contentAreasAdaptedItems,
		gradeLevelsAdaptedItems,
	} = useDynamicAdaptedItems({teachers, subjects, gradeLevels});

	return (
		<MainSettingsBox>
			<GridBox flow='column' gap='2'>
				<WidgetCard.EditMode.FormElement control={viewTypeControl}>
					<FormToggleGroup items={viewTypeItems} />
				</WidgetCard.EditMode.FormElement>

				<WidgetCard.EditMode.FormElement control={sizeControl}>
					<FormToggleGroup items={sizeItems} />
				</WidgetCard.EditMode.FormElement>
			</GridBox>

			<GridBox gap='2'>
				<SelectDrawerable
					placeholder='Teachers'
					sectionName='Teachers'
					value={selectedTeachersIDs}
					onSaveSelectedEntity={setSelectedTeachersIDs}
					selectedValueTranscript='Teachers'
					allItems={teachersAdaptedItems}
					EntityIcon={Teacher}
				/>

				<SelectDrawerable
					placeholder='Content Areas'
					sectionName='Content Areas'
					value={selectedContentArea}
					onSaveSelectedEntity={setSelectedContentArea}
					selectedValueTranscript='Content Areas'
					allItems={contentAreasAdaptedItems}
					EntityIcon={ContentArea}
				/>

				<SelectDrawerableFilterable
					placeholder='Subject Tabs'
					sectionName='Subjects'
					value={selectedSubjectIDs}
					onSaveSelectedEntity={setSelectedSubjectIDs}
					selectedValueTranscript='Subjects'
					items={subjectsFilteredAdaptedItems}
					allItems={subjectsAdaptedItems}
					filterWidget={
						<SelectDrawerableFilterable.SubjectLevelFilter
							selectedSubjectLevel={subjectLevel}
							onSubjectLevelSelect={setSubjectLevel}
						/>
					}
					EntityIcon={Subject}
				/>

				<SelectDrawerable
					placeholder='Grade Levels'
					sectionName='Grade Levels'
					value={selectedGradeLevels}
					onSaveSelectedEntity={setSelectedGradeLevels}
					selectedValueTranscript='Grade Levels'
					allItems={gradeLevelsAdaptedItems}
					EntityIcon={Subject}
				/>
			</GridBox>
		</MainSettingsBox>
	);
}
