import {ExpandableSelectableBlockTab, ItemNumerable} from '@esgi/main/features/admins/subject-management';
import {PublishForSpecialistsTabID} from '../../../../../types';
import {publishToSpecialistsTitle} from '../../../../constants';

type Parameters = {
	schoolSpecialistsSchools: ItemNumerable[];
	districtSpecialists: ItemNumerable[];
};

export function getPublishForSpecialistsInit({
	schoolSpecialistsSchools,
	districtSpecialists,
}: Parameters): ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[] {
	return [
		{
			value: PublishForSpecialistsTabID.All,
			label: publishToSpecialistsTitle[PublishForSpecialistsTabID.All],
			isActive: true,
			allItems: [],
			selectedItems: [],
		},
		{
			value: PublishForSpecialistsTabID.BySchool,
			label: publishToSpecialistsTitle[PublishForSpecialistsTabID.BySchool],
			isActive: false,
			allItems: schoolSpecialistsSchools,
			selectedItems: schoolSpecialistsSchools.map(({id}) => id),
		},
		{
			value: PublishForSpecialistsTabID.BySpecialist,
			label: publishToSpecialistsTitle[PublishForSpecialistsTabID.BySpecialist],
			isActive: false,
			allItems: districtSpecialists,
			selectedItems: districtSpecialists.map(({id}) => id),
		},
	];
}
