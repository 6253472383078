import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {cartesianGridBoxHeight, cartesianGridItemsGap, cartesianGridValues} from '../../../../constants';

export const itemsAverageInfoValueCheapHeight = 16;

export const Overlay = styled(Box, {
	position: 'absolute',
	width: '100%',
	height: (cartesianGridValues.length - 1) * (cartesianGridBoxHeight + cartesianGridItemsGap),
	top: 0,
	bottom: 0,
	margin: 'auto',
});

export const ItemsAverageInfoLine = styled(Box, {
	position: 'absolute',
	width: '100%',
	height: 1,
	backgroundColor: '$primaryVivid',
});

export const ItemsAverageInfoValueBox = styled(GridBox, {
	position: 'absolute',
	right: 0,
	zIndex: 3,

	[`> ${Text}`]: {
		position: 'absolute',
		top: -16,
		width: '100%',
		textAlign: 'center',
	},

	variants: {
		isAvgLabelVisible: {
			true: {
				[`> ${Text}`]: {
					top: -16,
				},
			},

			false: {
				[`> ${Text}`]: {
					top: 16,
				},
			},
		},
	},
});

export const ItemsAverageInfoValueCheap = styled(GridBox, {
	width: 24,
	height: itemsAverageInfoValueCheapHeight,
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: 3,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$primaryMuted',
	backgroundColor: '$primaryBackground',
});
