import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';

export type TeacherSettingsFormType = FormGroup<{
	showExpiredUsers: FormControl<boolean>,
},
	ControlValue<{
		showExpiredUsers: FormControl<boolean>,
	}>>

export const createTeacherSettingsForm = ():
	TeacherSettingsFormType => new FormGroup({
	showExpiredUsers: new FormControl(false),
});
