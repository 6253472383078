import {createContext, useContext} from 'react';
import {isNull} from 'underscore';

export type ThemeContextValue = {
	cellHorizontalPadding: number;
	withoutFirstColumnPadding: boolean;
	withoutLastColumnPadding: boolean;
};

export const ThemeContext = createContext<ThemeContextValue | null>(null);

export function useThemeContext() {
	const context = useContext(ThemeContext);

	if (isNull(context)) {
		throw new Error('useThemeContext is null');
	}

	return context;
}
