import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';

export const Wrapper = styled(FlexBox, {
	alignSelf: 'stretch',
	borderRadius: 6,
	width: 216,
	padding: '8px 6px 8px 6px',
	border: `1px solid $border`,
	flexDirection: 'column',
	boxShadow: '0px 2px 6px 0px rgba(230, 232, 239, 0.24), 0px 4px 12px 0px rgba(0, 0, 0, 0.04)',
	':hover': {
		cursor: 'pointer',
		currentColor: '$secondary',
	},
});

export const Header = styled(FlexBox, {
	padding: 4,
	gap: 8,
	alignItems: 'center',
	justifyContent: 'start',
});

export const Bottom = styled(FlexBox, {
	justifyContent: 'start',
	gap: 4,
	padding: 6,
});