import {Dispatch, useCallback} from 'react';
import {
	SortableState,
	SortDirection,
	StudentsTwoPeriodData,
	StudentsTwoPeriodsSortableKey,
} from '../../../../../../types';
import {TableRowCell} from '../../../table.styled';
import {DeltaCell, Table, TableRow} from './index.styled';
import {TableHeaderCellSortable} from '../../../table-header-cell-sortable';
import {OneLinedText} from '@esgi/main/kits/common';
import {JustifyEndOneLinedText} from '../../../justify-end-one-lined-text';
import {Box} from '@esgi/ui/layout';
import {PercentageCheap} from '../../../percentage-cheap';
import {Text} from '@esgi/ui/typography';
import {studentsTwoPeriodsSortableCells} from '../../../../../../constants';
import {isNull} from 'underscore';
import {Color, WidgetCard, WidgetPeriod} from '@esgi/main/features/admins/dashboard';

type Props = {
	sortableState: SortableState<StudentsTwoPeriodsSortableKey>;
	setSortableState: Dispatch<SortableState<StudentsTwoPeriodsSortableKey>>;
	disabledSortableKeys: StudentsTwoPeriodsSortableKey[];
	periods: WidgetPeriod[];
	showEmptyContentBox: boolean;
	emptyBoxHeight: number;
	onStudentClick: Dispatch<number | null>;
};

export function TwoPeriods({
	setSortableState,
	sortableState,
	disabledSortableKeys,
	periods,
	showEmptyContentBox,
	emptyBoxHeight,
	onStudentClick,
}: Props) {
	const {visibleData, pageChange} = WidgetCard.useWidgetCardContext<StudentsTwoPeriodData>();

	const getDeltaColor = useCallback(
		({periodIndex}: {periodIndex: number}) => {
			const color: Color = periods[periodIndex]?.color ?? Color.None;

			return color === Color.None ? 'highContrast' : WidgetCard.chartColor[color].activeQuartileBarColor;
		},
		[periods],
	);

	const sortableCellClick = useCallback(
		({cellKey, nextSortDirection}: {cellKey: StudentsTwoPeriodsSortableKey; nextSortDirection: SortDirection}) => {
			setSortableState({
				key: cellKey,
				direction: nextSortDirection,
			});

			pageChange(0);
		},
		[setSortableState, pageChange],
	);

	return (
		<Table>
			<TableRow>
				{studentsTwoPeriodsSortableCells.map(({key, label, justifyContent}) => (
					<TableHeaderCellSortable
						cellKey={key}
						cellName={label}
						onCellClick={sortableCellClick}
						isActive={sortableState.key === key}
						sortDirection={sortableState.direction}
						disableSorting={disabledSortableKeys.includes(key)}
						justifyContent={justifyContent}
						key={key}
					/>
				))}
			</TableRow>

			{visibleData.map(({studentID, student, teacher, school, grade, rowID, avgFirst, avgSecond, delta}) => (
				<TableRow key={rowID}>
					<TableRowCell onClick={() => onStudentClick(studentID)} cursor='pointer'>
						<OneLinedText size='medium' bold color='primary'>
							{student}
						</OneLinedText>
					</TableRowCell>

					<TableRowCell>
						<OneLinedText size='medium' color='base'>
							{teacher ?? <>&mdash;</>}
						</OneLinedText>
					</TableRowCell>

					<TableRowCell>
						<OneLinedText size='medium' color='base'>
							{school}
						</OneLinedText>
					</TableRowCell>

					<JustifyEndOneLinedText size='medium' color='base'>
						{grade ?? <>&mdash;</>}
					</JustifyEndOneLinedText>

					<TableRowCell justify='end'>
						<Text size='medium' font='mono' bold color={getDeltaColor({periodIndex: 0})}>
							{!isNull(avgFirst) ? avgFirst.toFixed(1) : '-'}
						</Text>
					</TableRowCell>

					<TableRowCell justify='end'>
						<Text size='medium' font='mono' bold color={getDeltaColor({periodIndex: 1})}>
							{!isNull(avgSecond) ? avgSecond.toFixed(1) : '-'}
						</Text>
					</TableRowCell>

					<DeltaCell>
						<PercentageCheap value={delta} />
					</DeltaCell>
				</TableRow>
			))}

			{showEmptyContentBox && <Box css={{height: emptyBoxHeight}} />}
		</Table>
	);
}
