import {useRef, useState} from 'react';
import {widgetCardMinWidth} from '../../constants';
import useResizeObserver from '@react-hook/resize-observer';
import {cardsGap} from '../constants';

export function useWidgetsInfo() {
	const [maxPossibleWidgetWidth, setMaxPossibleWidgetWidth] = useState(widgetCardMinWidth);
	const [itemsCount, setItemsCount] = useState(2);

	const pageBodyRef = useRef<HTMLDivElement>(null);

	useResizeObserver(pageBodyRef, ({contentRect: {width}}) => {
		const fullWidgetsGap = 2 * cardsGap;
		const fullWidgetSize = widgetCardMinWidth + fullWidgetsGap;

		const itemsDirtyCount = Math.floor(width / fullWidgetSize);
		const itemsCount = Math.max(itemsDirtyCount, 2);

		setItemsCount(itemsCount);

		setMaxPossibleWidgetWidth(width / itemsCount - 2 * cardsGap);
	});

	return {
		pageBodyRef,
		itemsCountInRow: itemsCount,
		maxPossibleWidgetWidth,
	};
}
