import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, ForwardedRef, forwardRef, useMemo, useState} from 'react';
import {PanelRoot} from './index.styled';
import {TestPanelCard} from '../../types';
import {TestPanelContext, TestPanelContextValue} from '../../context';
import {Property} from '@stitches/react/types/css';

type Props<T extends TestPanelCard> = BaseComponentProps &
	ComponentPropsWithoutRef<typeof PanelRoot> & {
		testCards: T[];
		skeletonTestCard?: Partial<{
			count: number;
			maxHeight: Property.Height;
		}>;
	};

export const Root = forwardRef(
	<T extends TestPanelCard>(
		{
			dataCy = 'test-panel-root',
			css = {},
			children,
			testCards: allTestCards,
			skeleton,
			skeletonTestCard = {},
			...rootProps
		}: Props<T>,
		forwardedRef: ForwardedRef<HTMLDivElement>,
	) => {
		const [filteredCards, setFilteredCards] = useState(allTestCards);

		const context = useMemo<TestPanelContextValue<T>>(
			() => ({
				allTestCards,
				filteredCards,
				setFilteredCards,
				skeleton: Boolean(skeleton),
				skeletonTestCard: {
					count: 10,
					maxHeight: 160,
					...skeletonTestCard,
				},
			}),
			[allTestCards, filteredCards, skeleton, skeletonTestCard],
		);

		return (
			<TestPanelContext.Provider value={context}>
				<PanelRoot dataCy={dataCy} css={css} ref={forwardedRef} {...rootProps}>
					{children}
				</PanelRoot>
			</TestPanelContext.Provider>
		);
	},
);
