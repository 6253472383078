import {useCallback, useEffect, useState} from 'react';
import {Form} from '@esgillc/ui-kit/form';
import {Text} from '@esgi/ui/typography';
import {Drawer} from '@esgi/main/kits/common';
import {Select} from '@esgi/ui/form-controls';
import {useService} from '@esgi/core/service';
import {environment} from '@esgi/core/environments';
import AppHistory from 'shared/tools/history/history';
import {SsoLinksService} from 'shared/modules/user-settings/components/sso-links/service';
import {BoxArrowUpRight, useStreamEffect, ClassLink, UnassignLink, Clever, useBehaviorSubject} from '@esgi/ui';
import {DrawerPanelHeader, FormElementWrapper} from '../../../../../index.styled';
import {ContentWrapper, PanelContent, StyledButton} from '../../../index.styled';
import {SettingsService} from '../../../../../service';
import {SettingsTab} from '../../../../../types';
import {LogoLinkContainer} from './index.styled';
import {SystemSettingsService} from './service';
import {userStorage} from '@esgi/core/authentication';

interface Props {
	service: SettingsService;
	onCloseDrawer: VoidFunction;
	onUpdateTabTouchedData: (tab: SettingsTab, isTouched: boolean) => void;
}

export function SystemSettings({service, onCloseDrawer, onUpdateTabTouchedData}: Props) {
	const settingsData = useBehaviorSubject(service.settingsData$);

	const isOneClickAccountLinked = settingsData?.isOneClickAccountLinked;
	const isCleverAccountLinked = settingsData?.isCleverAccountLinked;

	const systemSettingsService = useService(SystemSettingsService);

	const ssoLinksService = useService(SsoLinksService);

	const [isDisableButton, setIsDisableButton] = useState(true);

	const onCleverLinkClick = useCallback(() => {
		AppHistory.navigateOutside(`${environment.ssoApiURL}/integrations/clever/authesgi`);
	}, []);

	const onClassLinkClick = useCallback(() => {
		ssoLinksService.getClassLink().subscribe((response) => {
			AppHistory.navigateOutside(response.url);
		});
	}, [ssoLinksService]);

	const onCleverUnlink = useCallback(() => {
		service.unlinkCleverAccount();
	}, [service]);

	const onClassUnlink = useCallback(() => {
		service.unlinkClassLinkAccount();
	}, [service]);

	const handleSave = useCallback(() => {
		const sortBy = systemSettingsService.form.value.sortBy[0];
		const selectedTimeZone = settingsData.timeZones.find(
			(item) => item.id === systemSettingsService.form.value.timeZoneID[0],
		);

		service
			.saveSettings({
				sortBy,
				selectedTimeZone,
			})
			.subscribe(() => {
				userStorage.update({studentSort: sortBy, timeZone: selectedTimeZone.id});
				onUpdateTabTouchedData(SettingsTab.SystemSettings, false);
				setIsDisableButton(true);
			});
	}, [service, systemSettingsService, settingsData, onUpdateTabTouchedData]);

	useStreamEffect(systemSettingsService.form.onChanged, () => {
		const isTouched = systemSettingsService.compareStatesForEquality();

		setIsDisableButton(!isTouched);
		onUpdateTabTouchedData(SettingsTab.SystemSettings, isTouched);
	});

	useEffect(() => {
		systemSettingsService.init(settingsData);
	}, [systemSettingsService, settingsData]);

	return (
		<Form controller={systemSettingsService.form}>
			<DrawerPanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={handleSave}
				actionButtonDisabled={isDisableButton}
				onClose={onCloseDrawer}
			>
				<Text data-cy='system-settings-title' size='large' color='neutral24' bold>
					System Settings
				</Text>
			</DrawerPanelHeader>
			<PanelContent>
				<ContentWrapper dataCy='sort-students-block'>
					<Drawer.ContentBlock title='Sort Students' withDivider>
						<FormElementWrapper control={systemSettingsService.form.controls.sortBy}>
							<Select.Root dataCy='sort-students-dropdown'>
								<Select.Field placeholder='Sort Students' />
								<Select.Content>
									{settingsData?.sortByVariants.map((s) => (
										<Select.Option key={s.value} value={s.value.toString()}>
											<Text size='medium' bold>
												{s.name}
											</Text>
										</Select.Option>
									))}
								</Select.Content>
							</Select.Root>
						</FormElementWrapper>
					</Drawer.ContentBlock>
					<Drawer.ContentBlock title='Time Zone' withDivider>
						<FormElementWrapper control={systemSettingsService.form.controls.timeZoneID}>
							<Select.Root dataCy='time-zone-dropdown'>
								<Select.Field placeholder='Time Zone' />
								<Select.Content placeholder='Time Zone'>
									{settingsData?.timeZones.map((t) => (
										<Select.Option key={t.id} value={t.id}>
											<Text size='medium' bold>
												{t.name}
											</Text>
										</Select.Option>
									))}
								</Select.Content>
							</Select.Root>
						</FormElementWrapper>
					</Drawer.ContentBlock>
					<Drawer.ContentBlock title='Single Sign On' withDivider>
						{isCleverAccountLinked ? (
							<LogoLinkContainer onClick={onCleverUnlink} dataCy='link-clever-desable-button'>
								<Clever />
								<UnassignLink />
							</LogoLinkContainer>
						) : (
							<StyledButton color='secondary' onClick={onCleverLinkClick} dataCy='link-clever-button'>
								<BoxArrowUpRight />
								<Text size='medium' color='base'>
									Link to Clever account
								</Text>
							</StyledButton>
						)}
						{isOneClickAccountLinked ? (
							<LogoLinkContainer onClick={onClassUnlink} dataCy='link-classic-disable-button'>
								<ClassLink />
								<UnassignLink />
							</LogoLinkContainer>
						) : (
							<StyledButton color='secondary' onClick={onClassLinkClick} dataCy='link-classic-button'>
								<BoxArrowUpRight />
								<Text size='medium' color='base'>
									Link to ClassLink account
								</Text>
							</StyledButton>
						)}
					</Drawer.ContentBlock>
				</ContentWrapper>
			</PanelContent>
		</Form>
	);
}
