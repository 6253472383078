import {Card, FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const TestCardFullIndicator = styled(FlexBox, {
	gridColumnStart: 1,
	gridRowStart: 1,
});

export const Container = styled(Card, {
	display: 'grid',
	rowGap: '$3',

	[`&:has(> ${TestCardFullIndicator})`]: {
		gridTemplateColumns: 'auto 1fr',
		columnGap: '$4',
	},

	variants: {
		isCardClicable: {
			true: {
				cursor: 'pointer',
			},
		},
	},
});
