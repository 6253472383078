import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gap: 2,

	'& > svg:nth-child(2)': {
		justifySelf: 'end',
		position: 'relative',
		right: 11,
	},
});
