import {useFlag} from '@esgi/ui';
import {Entities} from '@esgi/main/features/admins/subject-management';
import {Dispatch, useCallback, useMemo, useState} from 'react';
import {MappedSubjects, Out, SubjectTestsByID} from './types';
import {useRearrangeSchoolSubjects} from './hooks/use-rearrange-school-subjects';
import {useStoreSubjects} from './hooks/use-store-subjects';
import {useActiveTeacherSubjects} from './hooks/use-active-teacher-subjects';
import {User} from '@esgi/main/libs/school-admin-store';
import {SelectedSubject} from '../types';
import {isNull} from 'underscore';
import {useDistrictSubjects} from './hooks/use-district-subjects';

type Parameters = {
	selectedTeacherID: User['id'] | null;
	setSelectedSubjectID: Dispatch<number | null>;
};

export function useSubjects({setSelectedSubjectID, selectedTeacherID}: Parameters): Out {
	const [onceSettled, setOnceSettled] = useFlag();

	const [subjectTestsByID, setSubjectTestsByID] = useState<SubjectTestsByID>({});

	const {isStoreSubjectsLoaded, storeSubjects, allSchoolSubjectsNames, getSubjectFromStoreByID} = useStoreSubjects();

	const [isDistrictSubjectsLoaded, districtSubjects] = useDistrictSubjects({setSubjectTestsByID});

	const [isSelectedTeacherSubjectsLoaded, selectedTeacherSubjects] = useActiveTeacherSubjects({
		teacherID: selectedTeacherID,
		setSubjectTestsByID,
	});

	const {isSubjectsLoaded, subjectsEntities} = useMemo<MappedSubjects>(() => {
		const entities: Entities = {
			districtSubjects: [],
			schoolSubjects: [],
			stockSubjects: [],
			teacherSubjects: [],
		};

		if (!isStoreSubjectsLoaded || !isDistrictSubjectsLoaded) {
			return {
				isSubjectsLoaded: false,
				subjectsEntities: entities,
			};
		}

		entities.schoolSubjects = storeSubjects;
		entities.districtSubjects = districtSubjects;
		entities.teacherSubjects = selectedTeacherSubjects;

		if (!onceSettled) {
			setSelectedSubjectID(entities.schoolSubjects[0]?.id ?? null);
			setOnceSettled();
		}

		return {
			isSubjectsLoaded: true,
			subjectsEntities: entities,
		};
	}, [
		districtSubjects,
		isDistrictSubjectsLoaded,
		isStoreSubjectsLoaded,
		onceSettled,
		selectedTeacherSubjects,
		setOnceSettled,
		setSelectedSubjectID,
		storeSubjects,
	]);

	const onRearrangeSchoolSubjects = useRearrangeSchoolSubjects();

	const getEntitySubjectByID = useCallback(
		(id: number | null): SelectedSubject | null => {
			const selectedEntitySubject =
				[
					...subjectsEntities.schoolSubjects,
					...subjectsEntities.districtSubjects,
					...subjectsEntities.teacherSubjects,
				].find((subject) => subject.id === id) ?? null;

			if (isNull(selectedEntitySubject)) {
				return null;
			}

			const {id: selectedSubjectID, name, level} = selectedEntitySubject;

			return {
				id: selectedSubjectID,
				name,
				level,
				tests: subjectTestsByID[selectedSubjectID] ?? [],
			};
		},
		[subjectTestsByID, subjectsEntities],
	);

	return {
		isSubjectsLoaded,
		isSelectedTeacherSubjectsLoaded,
		subjectsEntities,
		getSubjectFromStoreByID,
		allSchoolSubjectsNames,
		onRearrangeSchoolSubjects,
		getEntitySubjectByID,
	};
}
