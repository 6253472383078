import * as React from 'react';
import type {SVGProps} from 'react';

export function Incorrect(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				d='M19.742 19.742a.882.882 0 0 1-1.248 0L16 17.247l-2.495 2.495a.882.882 0 0 1-1.247-1.248L14.753 16l-2.495-2.495a.882.882 0 0 1 1.248-1.247L16 14.753l2.494-2.495a.882.882 0 0 1 1.248 1.248L17.247 16l2.495 2.494a.882.882 0 0 1 0 1.248Z'
			/>
		</svg>
	);
}
