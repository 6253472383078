import {Observable, switchMap, tap, debounceTime, map} from 'rxjs';
import {V2SchoolAdminsStudentsController} from '@esgi/contracts/esgi';
import {ControlValue, FormControl, FormGroup, ValidatorArgs, Validators} from '@esgi/ui/form';
import {StudentGenders} from '../types';

export type ProfileFormType = FormGroup<{
	firstName: FormControl<string>;
	lastName: FormControl<string>;
	studentIDN: FormControl<string>;
	exportIDN: FormControl<string>;
	gradeLevel: FormControl<string[]>;
	language: FormControl<string[]>;
	gender: FormControl<string[]>;
	birthDate: FormControl<Date[]>;
}, ControlValue<{
	firstName: FormControl<string>;
	lastName: FormControl<string>;
	studentIDN: FormControl<string>;
	exportIDN: FormControl<string>;
	gradeLevel: FormControl<string[]>;
	language: FormControl<string[]>;
	gender: FormControl<string[]>;
	birthDate: FormControl<Date[]>;
}>>;

export const birthDateValidator = () => {
	return (validationChain: Observable<ValidatorArgs<string>>) => {
	  return validationChain.pipe(
			tap((validatorArgs) => {
				if (validatorArgs.field.currentValue && new Date(validatorArgs.field.currentValue?.[0]) > new Date()) {
					validatorArgs.errors.push('invalid-date');
				}

				return validatorArgs;
			})
	  );
	};
};

export const studentIDNValidator = (
	controller: V2SchoolAdminsStudentsController,
	studentID: number,
	globalSchoolYearID: number,
) => {
	const checkStudentIDN = (studentIDN: string) => {
		return controller.checkExistStudent({
			studentIDN,
			studentID,
			globalSchoolYearID,
		});
	};

	return (validationChain: Observable<ValidatorArgs<string>>) => {
		return validationChain.pipe(
			debounceTime(300),
			switchMap((validatorArgs) => {
				if (!validatorArgs.field.currentValue) {
					return new Observable<ValidatorArgs<string>>(subscriber => subscriber.next(validatorArgs));
				}
				return checkStudentIDN(validatorArgs.field.currentValue).pipe(map((student) => {
					if (student.studentID) {
						validatorArgs.errors.push('idn-in-use');
					}

					return validatorArgs;
				}));
			}),
		);
	};
};

export const createProfileTabForm = (): ProfileFormType => (
	new FormGroup({
		firstName: new FormControl('', {
			validators: [Validators.required(), Validators.length.min(2), Validators.length.max(128)],
		}),
		lastName: new FormControl('', {
			validators: [Validators.required(), Validators.length.min(2), Validators.length.max(128)],
		}),
		studentIDN: new FormControl('', {validators: []}),
		exportIDN: new FormControl(''),
		gradeLevel: new FormControl(['4'], {validators: [Validators.required()]}),
		language: new FormControl(['2'], {validators: [Validators.required()]}),
		gender: new FormControl([StudentGenders.Other.toString()], {validators: [Validators.required()]}),
		birthDate: new FormControl([], {validators: [birthDateValidator()]}),
	})
);
