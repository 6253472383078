import {useUser} from '@esgi/core/authentication';
import {useCallback, useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {isAsyncPending, isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';
import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {specialistGroupsStore, studentsStore, SpecialistGroup, Student} from '@esgi/main/libs/admin-store';
import {SpecialistTabID, UserType} from '../../types';
import {DistrictSpecialistForm, SchoolSpecialistForm} from '../forms-data/types';
import {enumRemap} from 'shared/utils';
import {dispatchAppEvent} from '@esgillc/events';
import {SpecialistGroupCreatedEvent} from '../../../events';

export type NewGroupDataModel = Omit<SpecialistGroup, 'id' | 'userType' | 'schoolID'> & {
	userType: UserType.ISD | UserType.ISS;
	schoolID: number | null;
	studentsIDs: Student['id'][];
};

type Parameters = {
	closeDrawer: VoidFunction;
	specialistTabID: SpecialistTabID;
	districtSpecialistForm: DistrictSpecialistForm;
	schoolSpecialistForm: SchoolSpecialistForm;
};

export function useSave({closeDrawer, specialistTabID, districtSpecialistForm, schoolSpecialistForm}: Parameters) {
	const currentUser = useUser();

	const api = useEsgiApiGateway();

	const [newGroupDataModel, setNewGroupDataModel] = useState<NewGroupDataModel | null>(null);
	const [createdSpecialistGroupData, createSpecialistGroup] = useCancelableRequest(
		api.v2.districtAdmins.modules.specialistGroups.create,
	);

	useEffect(() => {
		if (isAsyncSucceed(createdSpecialistGroupData) && !isNull(newGroupDataModel)) {
			const newSpecialistGroupID = createdSpecialistGroupData.data.value.id;

			specialistGroupsStore().add({
				id: newSpecialistGroupID,
				name: newGroupDataModel.name,
				schoolID: newGroupDataModel.schoolID,
				specialistID: newGroupDataModel.specialistID,
				userType: newGroupDataModel.userType,
			});

			studentsStore().update((item) => {
				if (newGroupDataModel.studentsIDs.includes(item.id)) {
					return {
						...item,
						specialistGroupsIDs: [...item.specialistGroupsIDs, newSpecialistGroupID],
					};
				}

				return item;
			});

			dispatchAppEvent(
				SpecialistGroupCreatedEvent,
				new SpecialistGroupCreatedEvent({
					id: newSpecialistGroupID,
					name: newGroupDataModel.name,
				}),
			);

			closeDrawer();
		}
	}, [closeDrawer, createdSpecialistGroupData, newGroupDataModel]);

	const saveData = useCallback(() => {
		if (isNull(currentUser)) {
			setNewGroupDataModel(null);
			return;
		}

		if (specialistTabID === SpecialistTabID.District) {
			const {districtSpecialistID, groupName, studentsIDs} = districtSpecialistForm.value;

			const specialistID = isUndefined(districtSpecialistID[0]) ? undefined : Number(districtSpecialistID[0]);

			if (isUndefined(specialistID)) {
				throw new Error('specialistID is undefined');
			}

			createSpecialistGroup({
				globalSchoolYearID: currentUser.globalSchoolYearID,
				name: groupName,
				studentIDs: studentsIDs,
				userID: specialistID,
			});

			setNewGroupDataModel({
				name: groupName,
				schoolID: null,
				specialistID,
				userType: enumRemap(UserType.ISD, UserType),
				studentsIDs,
			});

			return;
		}

		if (specialistTabID === SpecialistTabID.School) {
			const {schoolSpecialistID, groupName, studentsIDs, schoolID} = schoolSpecialistForm.value;

			const specialistSchoolID = isUndefined(schoolID[0]) ? undefined : Number(schoolID[0]);
			const specialistID = isUndefined(schoolSpecialistID[0]) ? undefined : Number(schoolSpecialistID[0]);

			if (isUndefined(specialistSchoolID)) {
				throw new Error('specialistSchoolID is undefined');
			}

			if (isUndefined(specialistID)) {
				throw new Error('specialistID is undefined');
			}

			createSpecialistGroup({
				globalSchoolYearID: currentUser.globalSchoolYearID,
				name: groupName,
				studentIDs: studentsIDs,
				userID: specialistID,
			});

			setNewGroupDataModel({
				name: groupName,
				schoolID: specialistSchoolID,
				specialistID,
				userType: enumRemap(UserType.ISS, UserType),
				studentsIDs,
			});

			return;
		}

		setNewGroupDataModel(null);
	}, [createSpecialistGroup, currentUser, districtSpecialistForm, schoolSpecialistForm, specialistTabID]);

	return {
		saveData,
		isDataSaving: isAsyncPending(createdSpecialistGroupData),
	};
}
