import {styled} from '@esgi/ui/theme';
import {Box, FlexBox} from '@esgi/ui/layout';
import {animated} from 'react-spring';

const panelContentPadding = 20;
const panelBackgroundColor = '$background';

export const PanelBox = styled(animated.div, {
	backgroundColor: panelBackgroundColor,
	boxShadow: '0px 0px 8px 0px rgba(222, 227, 247, 0.40), 0px 0px 10px 0px rgba(0, 0, 0, 0.06)',
	borderRightWidth: 1,
	borderRightStyle: 'solid',
	borderRightColor: '$border',
	zIndex: 1,
	paddingTop: panelContentPadding,
	paddingBottom: panelContentPadding,
});

export const PanelContent = styled(FlexBox, {
	gap: '$5',
	flexDirection: 'column',
	paddingInline: panelContentPadding,
});

export const Title = styled(FlexBox, {
	gap: '$1',
	paddingTop: 4,
	paddingInline: 6,
	alignItems: 'center',

	'& svg': {
		width: 24,
		height: 24,

		'& path': {
			fill: '$mediumContrast',
		},
	},
});

export const EntityItemsBox = styled(FlexBox, {
	flexDirection: 'column',
	gap: '$2',
});

export const NotVisibleItemsDivider = styled(Box, {
	backgroundColor: panelBackgroundColor,
	height: 8,
});

export const Divider = styled(Box, {
	width: `calc(100% + 2 * ${panelContentPadding}px)`,
	marginLeft: -panelContentPadding,
	height: 1,
	borderRadius: 1,
	backgroundColor: '$border',
});
