import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Container = styled(GridBox, {
	width: '100%',
	height: '100%',
	position: 'absolute',
	top: 0,
	left: 0,
	background: 'rgba(245, 245, 245, 0.8)',
	borderRadius: 12,
	borderStyle: 'dashed',
	borderWidth: 1,
	borderColor: '$highlight',
	padding: 20,
	transition: 'background .3s, border-color .3s, box-shadow .3s, border-style .3s',

	variants: {
		isHover: {
			true: {
				borderColor: '$secondary',
				background: 'rgba(253, 251, 254, 0.8)',
				boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 12px 0px rgba(230, 219, 238, 0.40)',
			},
		},

		isDragging: {
			true: {
				borderStyle: 'solid',
				borderColor: '$primaryVivid',
				background: 'rgba(251, 253, 254, 0.8)',
				boxShadow: '0px 4px 8px 0px rgba(230, 232, 239, 0.48), 0px 2px 12px 0px rgba(0, 0, 0, 0.06)',
			},
		},
	},
});

export const ItemNumberBox = styled(GridBox, {
	paddingTop: 6,
	paddingBottom: 6,
	paddingLeft: 25,
	paddingRight: 25,
	borderRadius: 8,
	background: '$highlight',
	alignSelf: 'start',
	justifySelf: 'center',
	transition: 'background-color .3s',

	[`> ${Text}`]: {
		fontWeight: 500,
		fontSize: 32,
	},

	variants: {
		isHover: {
			true: {
				backgroundColor: '$secondaryHighlight',
			},
		},

		isDragging: {
			true: {
				backgroundColor: '$primaryHighlight',
			},
		},
	},
});

export const RearrangeButton = styled(GridBox, {
	width: 40,
	height: 40,
	cursor: 'grab',
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	margin: 'auto',
	backgroundColor: '$surface',
	color: '$base',
	borderRadius: 6,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',
	transition: 'background-color .3s, color .3s, border-color .3s, box-shadow .3s',
	boxShadow: '0px 2px 6px 0px rgba(230, 232, 239, 0.24), 0px 4px 12px 0px rgba(0, 0, 0, 0.04)',

	'& > svg': {
		width: 24,
		height: 24,
	},

	'& > svg, & > svg *': {
		fill: 'currentColor',
	},

	variants: {
		isHover: {
			true: {
				backgroundColor: '$secondaryBackground',
				borderColor: '$secondaryMuted',
				color: '$secondary',
				boxShadow: '0px 4px 12px 0px rgba(230, 219, 238, 0.40), 0px 4px 12px 0px rgba(0, 0, 0, 0.06)',
			},
		},

		isDragging: {
			true: {
				backgroundColor: '$surface',
				borderColor: '$primaryMuted',
				color: '$primary',
				boxShadow: `0px 2px 12px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(230, 232, 239, 0.48)`,
				cursor: 'grabbing',
			},
		},
	},
});
