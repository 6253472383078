import {NavigationPageKey} from './types';
import {IconComponent} from '@esgi/ui';
import {BorderLeft, Task, Library, Multigenerate, UserList} from '@esgi/ui/icons';

export const menuLabelByRoute: Record<keyof NavigationPageKey, string> = {
	dashboard: 'Dashboard',
	data: 'Data',
	subjectManagement: 'Subject Tabs',
	testExplorer: 'Test Explorer',
	studentManager: 'Student Manager',
};

export const menuIconByPath: Record<keyof NavigationPageKey, IconComponent> = {
	dashboard: BorderLeft,
	data: Multigenerate,
	subjectManagement: Task,
	testExplorer: Library,
	studentManager: UserList,
};
