import {FormControl} from '@esgi/ui/form';
import {Class} from '../../types';
import {BoxplotTwoClassesFormColorItem, BoxplotTwoClassesFormClassIDItem} from '../types';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui';
import {ClassModel} from '../../../../../../../types';
import {Widget} from '@esgi/main/features/admins/dashboard';

export function setClassValueForBoxplotTwoClassesForm({
	classValue,
	classColorControl,
	classIDControl,
	defaultItemsIndex,
	allClassesList,
}: {
	classValue: Class | null;
	classColorControl: FormControl<BoxplotTwoClassesFormColorItem>;
	classIDControl: FormControl<BoxplotTwoClassesFormClassIDItem>;
	defaultItemsIndex: number;
	allClassesList: ClassModel[];
}) {
	if (!isNull(classValue)) {
		const {color, classID} = classValue;

		classColorControl.value = [color];
		classIDControl.value = [String(classID)];

		return;
	}

	classColorControl.value = [Widget.colorItems[defaultItemsIndex]?.value ?? null];
	classIDControl.value = [
		!isUndefined(allClassesList[defaultItemsIndex]) ? String(allClassesList[defaultItemsIndex]!.classID) : null,
	];
}
