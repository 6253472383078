import {AdaptedTestCard} from '../../../../types';
import {Text} from '@esgi/ui/typography';
import {SortByOption} from './types';
import {lastTestedComparator} from './constants';
import {TestPanel} from '@esgi/main/kits/test-results-panel';

export function SortableOptions() {
	const {activeItem, setActiveItem, sortableItems} = TestPanel.Filters.useSortingData<AdaptedTestCard, SortByOption>({
		defaultItem: SortByOption.Default,
		items: {
			[SortByOption.Default]: {
				label: 'Default',
			},
			[SortByOption.TestName]: {
				label: 'Test Name',
				comparator: TestPanel.Filters.SortingComparator.testName,
			},
			[SortByOption.LastTested]: {
				label: 'Last Tested',
				comparator: lastTestedComparator,
			},
			[SortByOption.ContentArea]: {
				label: 'Content Area',
				comparator: TestPanel.Filters.SortingComparator.contentArea,
			},
			[SortByOption.NumberOfQuestions]: {
				label: 'Number of Questions',
				comparator: TestPanel.Filters.SortingComparator.totalPossible,
			},
		},
	});

	return (
		<TestPanel.Select.Root value={activeItem} onValueChange={setActiveItem}>
			<TestPanel.Select.Field placeholder='Sort By' />
			<TestPanel.Select.Content>
				{sortableItems.map(({label, value}) => (
					<TestPanel.Select.Option value={value} key={value}>
						<Text size='medium' bold>
							{label}
						</Text>
					</TestPanel.Select.Option>
				))}
			</TestPanel.Select.Content>
		</TestPanel.Select.Root>
	);
}
