import {useCallback, useEffect, useState} from 'react';
import {Form} from '@esgillc/ui-kit/form';
import {Text} from '@esgi/ui/typography';
import {Drawer} from '@esgi/main/kits/common';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {useService} from '../../../../../../../../../../../../../libs/core/src/service';
import {SingleCheckboxSection} from '../../../../single-checkbox-section';
import {ContentWrapper, PanelContent} from '../../../index.styled';
import {DrawerPanelHeader} from '../../../../../index.styled';
import {SettingsService} from '../../../../../service';
import {TeacherSettingsService} from './service';
import {SettingsTab} from '../../../../../types';

interface Props {
	service: SettingsService;
	onCloseDrawer: VoidFunction;
	onUpdateTabTouchedData: (tab: SettingsTab, isTouched: boolean) => void;
}

export function Teacher({service, onCloseDrawer, onUpdateTabTouchedData}: Props) {
	const settingsData = useBehaviorSubject(service.settingsData$);

	const teacherSettingsService = useService(TeacherSettingsService);

	const [isDisableButton, setIsDisableButton] = useState(true);

	const handleSave = useCallback(() => {
		service.saveSettings({
			showExpiredUsers: teacherSettingsService.form.value.showExpiredUsers,
		}).subscribe(() => {
			onUpdateTabTouchedData(SettingsTab.Teacher, false);
			setIsDisableButton(true);
		});
	}, [service, teacherSettingsService, onUpdateTabTouchedData]);

	useStreamEffect(teacherSettingsService.form.onChanged, () => {
		const isTouched = teacherSettingsService.compareStatesForEquality();

		setIsDisableButton(!isTouched);
		onUpdateTabTouchedData(SettingsTab.Teacher, isTouched);
	});

	useEffect(() => {
		teacherSettingsService.init(settingsData);
	}, [teacherSettingsService, settingsData]);

	return (
		<Form controller={teacherSettingsService.form}>
			<DrawerPanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={handleSave}
				actionButtonDisabled={isDisableButton}
				onClose={onCloseDrawer}
			>
				<Text data-cy='teacher-settings-title' size='large' color='neutral24' bold>
					Teacher Settings
				</Text>
			</DrawerPanelHeader>

			<PanelContent>
				<ContentWrapper dataCy='teacher-settings-block'>
					<Drawer.ContentBlock title='Show Expired Users' withDivider>
						<SingleCheckboxSection
							checkboxControl={teacherSettingsService.form.controls.showExpiredUsers}
							description='When disabled, teachers with an expiration date which has passed are not displayed to administrators on the Data page. When enabled, all users are displayed.'
						/>
					</Drawer.ContentBlock>
				</ContentWrapper>
			</PanelContent>
		</Form>
	);
}