import {AgGrid} from '@esgi/ui/ag-grid';
import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const SearchInfoBox = styled(FlexBox, {
	alignItems: 'center',
	gap: '$4',
});

export const InputSearch = styled(AgGrid.SearchInput, {
	width: 232,
});

export const FiltersPaginationContainer = styled(FlexBox, {
	alignItems: 'center',
	gap: 33,
});
