import {Group, Student} from '@esgi/main/libs/admin-store';
import {FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {Dispatch, useEffect} from 'react';
import {isNull} from 'underscore';
import {useStreamEffect} from '@esgi/ui';
import {groupNameMaxLength} from '../../../components/group-name-input/constants';
import {EditGroupForm} from './types';

type Parameters = {
	setIsFormValid: Dispatch<boolean>;
	setIsFormTouched: Dispatch<boolean>;
	skeleton: boolean;
	currentGroup: Group | null;
	studentsIDsInGroup: Student['id'][];
	allGroupsInCurrentClass: Group[];
};

export function useEditForm({
	setIsFormTouched,
	setIsFormValid,
	skeleton,
	currentGroup,
	studentsIDsInGroup,
	allGroupsInCurrentClass,
}: Parameters) {
	const form: EditGroupForm = useForm(
		() =>
			new FormGroup({
				groupName: new FormControl(''),
				studentsIDs: new FormControl<Student['id'][]>([]),
			}),
	);

	useEffect(() => {
		if (skeleton || isNull(currentGroup)) {
			return;
		}

		form.controls.studentsIDs.value = studentsIDsInGroup;

		form.controls.groupName.value = currentGroup.name;
		form.controls.groupName.validators.length = 0;

		form.controls.groupName.validators.push(
			Validators.required(),
			Validators.length.max(groupNameMaxLength),
			Validators.isDublicateValue(
				allGroupsInCurrentClass
					.map(({name, id}) => (id === currentGroup.id ? null : name))
					.filter((item): item is string => !isNull(item)),
			),
		);
	}, [allGroupsInCurrentClass, currentGroup, skeleton, studentsIDsInGroup]);

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {groupName, studentsIDs},
			},
		}) => {
			if (isNull(currentGroup)) {
				return;
			}

			const isFormTouched =
				groupName !== currentGroup.name ||
				studentsIDs.length !== studentsIDsInGroup.length ||
				studentsIDs.some((id) => !studentsIDsInGroup.includes(id));

			setIsFormTouched(isFormTouched);

			form.validate(true).subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		},
	);

	return {
		form,
	};
}
