import {useMemo} from 'react';
import {ReportDialog, Reports} from '../../../../../../_kit';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useUser} from '@esgi/core/authentication';
import {RowDef} from '../../../../../types';

type Props = {
	selectedGroup: RowDef;
	onClose: VoidFunction;
};

export function ReportsDialog({onClose, selectedGroup}: Props) {
	const user = useUser();

	const hierarchy = useMemo<Hierarchy>(() => ({
		districtID: user?.districtID,
		schoolID: user?.schoolID,
		teacherID: selectedGroup.teacher.id,
		classID: selectedGroup.class.id,
		groupID: selectedGroup.id,
	}), [selectedGroup]);

	return (
		<ReportDialog
			header={selectedGroup.name}
			title='Student and Group Reports'
			onClose={onClose}
		>
			<Reports.ParentLetter hierarchy={hierarchy} />
			<Reports.StudentDetail hierarchy={hierarchy} />
			<Reports.StudentProgress hierarchy={hierarchy} />
			<Reports.Totals prefix='Group' hierarchy={hierarchy} />
			<Reports.GradeScale prefix='Group' hierarchy={hierarchy} />
			<Reports.ItemAnalysis hierarchy={hierarchy} />
			<Reports.PieCharts hierarchy={hierarchy} />
			<Reports.RubricResults hierarchy={hierarchy} />
			<Reports.UntestedStudents hierarchy={hierarchy} />
		</ReportDialog>
	);
}