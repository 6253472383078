import {CustomHeaderProps} from 'ag-grid-react';
import {useCallback, useEffect, useState, MouseEvent} from 'react';
import {Text} from '@esgi/ui/typography';

import {SortDirection} from 'ag-grid-community';

import {isNull} from 'underscore';
import {Cell, FilterBox, MainBox, SortBox} from './index.styled';
import {FilterTable, SortTable} from '@esgi/ui/icons';
import {useCellContentAlign} from '../../hooks/use-cell-content-align';

export function HeaderCell({
	api,
	column,
	showFilter,
	enableSorting,
	setSort,
	displayName,
	enableFilterButton,
}: CustomHeaderProps) {
	const [activeSortDirection, setActiveSortDirection] = useState<SortDirection>(column.getSort() ?? null);
	const [isFilterActive, setIsFilterActive] = useState(column.isFilterActive());

	useEffect(() => {
		const sortChangedHandler = () => {
			const currentSortDirection = column.getSort();

			setActiveSortDirection(currentSortDirection ?? null);
			api.paginationGoToFirstPage();
		};

		const filterActiveChangedHandler = () => {
			setIsFilterActive(column.isFilterActive());
		};

		api.addEventListener('sortChanged', sortChangedHandler);
		column.addEventListener('filterActiveChanged', filterActiveChangedHandler);

		return () => {
			api.removeEventListener('sortChanged', sortChangedHandler);
			column.removeEventListener('filterActiveChanged', filterActiveChangedHandler);
		};
	}, [api, column]);

	const handleCellClick = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			if (!enableSorting) {
				return;
			}

			if (activeSortDirection === 'desc') {
				setSort('asc', event.shiftKey);
			}

			if (activeSortDirection === 'asc') {
				setSort(null, event.shiftKey);
			}

			if (isNull(activeSortDirection)) {
				setSort('desc', event.shiftKey);
			}
		},
		[activeSortDirection, enableSorting, setSort],
	);

	const handleFilterClick = useCallback(
		(event: MouseEvent<HTMLDivElement>) => {
			event.stopPropagation();
			showFilter(event.currentTarget);
		},
		[showFilter],
	);

	const cellJustify = useCellContentAlign(column.getColDef());

	return (
		<Cell role='presentation' justify={cellJustify}>
			<MainBox
				sortable={Boolean(enableSorting)}
				activeSortDirection={!isNull(activeSortDirection) ? activeSortDirection : 'none'}
				onClick={handleCellClick}
			>
				<Text data-ref='eText' size='small'>
					{displayName}
				</Text>

				{enableSorting && (
					<SortBox>
						<SortTable />
					</SortBox>
				)}
			</MainBox>

			{enableFilterButton && (
				<FilterBox onClick={handleFilterClick} isActive={isFilterActive}>
					<FilterTable />
				</FilterBox>
			)}
		</Cell>
	);
}
