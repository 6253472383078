import {AddTestsDrawerSubjectType, SessionTypeNumerable} from '@esgi/main/features/admins/subject-management';
import {SubjectType} from '@esgi/main/libs/admin-store';
import {RetestSubjectType} from '../types';

export const addTestsToStorageTestType: Record<AddTestsDrawerSubjectType, SubjectType> = {
	[AddTestsDrawerSubjectType.Deployed]: SubjectType.Deployed,
	[AddTestsDrawerSubjectType.Personal]: SubjectType.Personal,
	[AddTestsDrawerSubjectType.Stock]: SubjectType.Stock,
};

export const storageToAddTestsTestType: Record<SubjectType, AddTestsDrawerSubjectType> = {
	[SubjectType.Deployed]: AddTestsDrawerSubjectType.Deployed,
	[SubjectType.Personal]: AddTestsDrawerSubjectType.Personal,
	[SubjectType.Stock]: AddTestsDrawerSubjectType.Stock,
};

export const retestSubjectToNumerable: Record<RetestSubjectType, SessionTypeNumerable | null> = {
	[RetestSubjectType.TestAll]: SessionTypeNumerable.TestAll,
	[RetestSubjectType.TestIncorrect]: SessionTypeNumerable.TestIncorrect,
	[RetestSubjectType.Any]: null,
};

export const retestSubjectFromNumerable: Record<SessionTypeNumerable, RetestSubjectType | null> = {
	[SessionTypeNumerable.TestAll]: RetestSubjectType.TestAll,
	[SessionTypeNumerable.TestIncorrect]: RetestSubjectType.TestIncorrect,
	[SessionTypeNumerable.TestSubset]: null,
};
