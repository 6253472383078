import {useState} from 'react';
import {SingleSelectedExpandablePanel} from '../../../../../_kit';
import {RowDef} from '../../../../types';
import {GridApi} from 'ag-grid-community';
import {QuickPanel} from './components/quick-panel';
import {ExpandedPanel} from './components/expanded-panel';
import {Folder} from '@esgi/main/features/admins/data';

type Props = {
	gridApi: GridApi<Readonly<RowDef>> | null;
	onFolderAddClick: VoidFunction;
	activeFolder: Folder;
	onRemoveFromFolderClick: VoidFunction;
};

export function SingleItemExpandablePanel({gridApi, onFolderAddClick, activeFolder, onRemoveFromFolderClick}: Props) {
	const [isPanelExpanded, setIsPanelExpanded] = useState(false);
	const [selectedRow, setSelectedRow] = useState<RowDef | null>(null);

	return (
		<SingleSelectedExpandablePanel.Root<RowDef>
			gridApi={gridApi}
			onSingleSelectedItemChange={setSelectedRow}
			onPanelExpandableValueChange={setIsPanelExpanded}
		>
			{selectedRow && (
				<>
					<QuickPanel
						selectedRow={selectedRow}
						isPanelExpanded={isPanelExpanded}
						onFolderAddClick={onFolderAddClick}
						activeFolder={activeFolder}
						onRemoveFromFolderClick={onRemoveFromFolderClick}
					/>

					<ExpandedPanel selectedRow={selectedRow} />
				</>
			)}
		</SingleSelectedExpandablePanel.Root>
	);
}
