import {School, User} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {FormElementSelect} from '../../../../../../components/form-element-select';
import {FormSelectItem} from '../../../../../../components/form-element-select/types';
import {getFullName} from '@esgi/main/kits/common';
import {schoolSelectErrorItems, specialistsSelectErrorItems} from './constants';
import {SchoolSpecialistForm} from '../../../../forms-data/types';
import {ErrorMessageBox} from '../../../../../../components/error-message-box';

type Props = {
	schoolsWithSpecialists: School[];
	form: SchoolSpecialistForm;
	activeSchoolSpecialistsList: User[];
	schoolSpecialistUniqueKey: string;
	formInactiveMessage: string | null;
};

export function SchoolSpecialistContent({
	schoolsWithSpecialists,
	form,
	activeSchoolSpecialistsList,
	schoolSpecialistUniqueKey,
	formInactiveMessage,
}: Props) {
	const schoolSelectItem = useMemo(
		() => schoolsWithSpecialists.map<FormSelectItem<string>>(({id, name}) => ({value: String(id), label: name})),
		[schoolsWithSpecialists],
	);

	const schoolSpecialistsSelectItems = useMemo(
		() =>
			activeSchoolSpecialistsList.map<FormSelectItem<string>>(({id, firstName, lastName}) => ({
				value: String(id),
				label: getFullName({firstName, lastName}),
			})),
		[activeSchoolSpecialistsList],
	);

	return (
		<>
			<ErrorMessageBox message={formInactiveMessage} />

			<FormElementSelect
				placeholder='School'
				control={form.controls.schoolID}
				items={schoolSelectItem}
				errorItems={schoolSelectErrorItems}
			/>

			<FormElementSelect
				placeholder='School Specialist'
				control={form.controls.schoolSpecialistID}
				items={schoolSpecialistsSelectItems}
				errorItems={specialistsSelectErrorItems}
				key={schoolSpecialistUniqueKey}
			/>
		</>
	);
}
