import {FolderAdd, FolderRemove, School} from '@esgi/ui/icons';
import {ActionPanel, NegativeButton, RunReportButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {DeleteSchoolsButton} from '../../../../../../components/delete-schools-button';
import {Button, useFlag} from '@esgi/ui';
import {Folder} from '@esgi/main/features/admins/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {SchoolReportsDialog} from '../school-reports-dialog';
import {OpenPanelButton} from '../../../../../../components/open-panel-button';

type Props = {
	isPanelExpanded: boolean;
	selectedRow: RowDef;
	activeFolder: Folder;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
};

export function QuickPanel({
	selectedRow,
	onFolderAddClick,
	isPanelExpanded,
	activeFolder,
	onRemoveFromFolderClick,
}: Props) {
	const [showSchoolReports, openSchoolReports, closeSchoolReports] = useFlag();

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<School />
							<Text size='large' color='highContrast'>
								{selectedRow.name}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteSchoolsButton selectionType='single' />
							<ActionPanel.ActionButtonDivider />

							<Button.Icon withBackgroundHover onClick={onFolderAddClick}>
								<FolderAdd />
							</Button.Icon>

							<NegativeButton onClick={onRemoveFromFolderClick}>
								<FolderRemove />
							</NegativeButton>

							<RunReportButton onClick={openSchoolReports} />
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<OpenPanelButton canExpandPanel={!!selectedRow.students} />
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showSchoolReports && (
				<SchoolReportsDialog folder={activeFolder} school={selectedRow} onClose={closeSchoolReports} />
			)}
		</>
	);
}
