import {useCallback} from 'react';
import {classesStore, Group, groupsStore, Student, studentsStore} from '@esgi/main/libs/admin-store';

export function useRemoveClassInStore() {
	return useCallback((classID: number, cb?: VoidFunction) => {
		classesStore().removeById?.(classID);

		groupsStore().remove?.((item: Group): boolean => item.classID === classID);

		studentsStore().update?.((item: Student): Student => {
			if (item.classesIDs.includes(classID)) {
				return {
					...item,
					classesIDs: item.classesIDs.filter((id) => id !== classID),
				};
			}

			return item;
		});

		cb?.();
	}, []);
}
