import {Domain} from '@esgi/contracts';
import {storageDomain} from './storage';
import {pagesDomain} from './pages';
import {modulesDomain} from './modules';

export class districtAdminsDomain extends Domain {
	public storage = new storageDomain(this.clientFactory);
	public pages = new pagesDomain(this.clientFactory);
	public modules = new modulesDomain(this.clientFactory);
}
