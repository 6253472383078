import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps, Skeletonable} from '@esgi/ui';
import {PanelMode} from '../../../../types/section';
import {SubjectModel} from '../../../../types/subject';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Text} from '@esgi/ui/typography';
import {usePanelContext} from '../../../../context';
import {EmptyContentBox} from '../../../empty-content-box';
import {Skeleton as SkeletonList} from '../../../lists/skeleton';
import {Default as DefaultList} from '../../../lists/default';
import {Content} from './index.styled';

type Props = BaseComponentProps &
	Skeletonable &
	Omit<ComponentPropsWithoutRef<typeof Content>, 'children'> & {
		subjects: SubjectModel[];
		hiddenIconText?: string;
	};

export const ViewMode = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'tab-view-mode', css = {}, subjects, hiddenIconText, skeleton, ...contentProps}, forwardedRef) => {
		const {skeleton: panelSkeleton, panelMode} = usePanelContext();

		const renderList = () => {
			if (panelSkeleton || skeleton) {
				return <SkeletonList />;
			}

			if (!subjects.length) {
				return (
					<EmptyContentBox justify='center'>
						<Text size='small' font='mono' color='mediumContrast'>
							No subjects to display
						</Text>
					</EmptyContentBox>
				);
			}

			return <DefaultList subjects={subjects} hiddenIconText={hiddenIconText} />;
		};

		if (panelMode === PanelMode.Add || panelMode === PanelMode.View) {
			return (
				<Content dataCy={dataCy} css={css} ref={forwardedRef} {...contentProps}>
					<OverlayScrollbarsComponent
						defer
						options={{
							scrollbars: {autoHide: 'leave'},
						}}
						style={{height: 'calc(100% + 0px)'}}
					>
						{renderList()}
					</OverlayScrollbarsComponent>
				</Content>
			);
		}

		return null;
	},
);
