import {isNull} from 'underscore';
import {useCallback, useEffect} from 'react';
import {useStreamEffect} from '@esgi/ui';
import {ElementStatus} from '@esgi/ui/form';
import {isDateTouched} from '@esgi/main/kits/admin';
import {BoxplotSinglePeriodForm, BoxplotSinglePeriodInitialFormData} from '../../../../forms-data/types';
import {DynamicFormProps} from '../../../../types';
import {InputsBox} from '../../../../index.styled';
import {errorTooltipItems} from './constants';
import {
	CheckboxesBox,
	CheckboxFormElement,
	DatePickerForm,
	ErrorTooltip,
	FormColorPicker,
	FormInputPercantage,
	Widget,
	WidgetCard,
} from '@esgi/main/features/admins/dashboard';

type Props = DynamicFormProps<{
	singlePeriodForm: BoxplotSinglePeriodForm;
	initialBoxplotSinglePeriodForm: BoxplotSinglePeriodInitialFormData;
}>;

export function SinglePeriod({
	singlePeriodForm,
	initialBoxplotSinglePeriodForm,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	onCurrentDynamicFormNameChange,
}: Props) {
	const handleCheckForm = useCallback(() => {
		const {date, highlightColor, highlightValue, isHighlightCandles} = singlePeriodForm.value;

		const isCurrentDateTouched = isDateTouched(date, initialBoxplotSinglePeriodForm.date);
		const isHighlightCandlesTouched = isHighlightCandles !== !isNull(initialBoxplotSinglePeriodForm.highlight);

		singlePeriodForm.validate(true).subscribe(({valid}) => {
			setIsDynamicFormDataValid(valid);
		});

		if (isHighlightCandles) {
			const highlightValueTouched =
				highlightValue !== initialBoxplotSinglePeriodForm.highlight?.highlightIfAbove ||
				highlightColor[0] !== initialBoxplotSinglePeriodForm.highlight.color;

			setIsDynamicFormDataTouched(isCurrentDateTouched || isHighlightCandlesTouched || highlightValueTouched);
		} else {
			setIsDynamicFormDataTouched(isCurrentDateTouched || isHighlightCandlesTouched);
		}
	}, [initialBoxplotSinglePeriodForm, setIsDynamicFormDataTouched, setIsDynamicFormDataValid, singlePeriodForm]);

	useStreamEffect(singlePeriodForm.controls.isHighlightCandles.onChanged, ({currState: {value}}) => {
		const highlightDataStatus = value ? ElementStatus.valid : ElementStatus.disabled;

		singlePeriodForm.controls.highlightColor.status = highlightDataStatus;
		singlePeriodForm.controls.highlightValue.status = highlightDataStatus;

		singlePeriodForm.controls.highlightColor.validate().subscribe();
		singlePeriodForm.controls.highlightValue.validate().subscribe();
	});

	useEffect(() => {
		handleCheckForm();
		onCurrentDynamicFormNameChange('boxplotSinglePeriod');
	}, [handleCheckForm, onCurrentDynamicFormNameChange]);

	useStreamEffect(singlePeriodForm.onChanged, handleCheckForm);

	return (
		<InputsBox>
			<WidgetCard.EditMode.FormElement control={singlePeriodForm.controls.date}>
				<DatePickerForm form={singlePeriodForm} />
			</WidgetCard.EditMode.FormElement>

			<CheckboxesBox>
				<WidgetCard.EditMode.FormElement control={singlePeriodForm.controls.isHighlightCandles}>
					<CheckboxFormElement label='Highlight Candles' />
				</WidgetCard.EditMode.FormElement>
			</CheckboxesBox>

			<WidgetCard.EditMode.PeriodColorBox>
				<WidgetCard.EditMode.FormElement control={singlePeriodForm.controls.highlightColor}>
					<FormColorPicker items={Widget.colorItems} />
				</WidgetCard.EditMode.FormElement>

				<WidgetCard.EditMode.FormElement control={singlePeriodForm.controls.highlightValue}>
					<FormInputPercantage placeholder='Highlight If Above' />

					{errorTooltipItems.map(({showOnError, message}) => (
						<ErrorTooltip showOnError={showOnError} message={message} key={showOnError} />
					))}
				</WidgetCard.EditMode.FormElement>
			</WidgetCard.EditMode.PeriodColorBox>
		</InputsBox>
	);
}
