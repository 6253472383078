import {WarningAlert} from '@esgi/main/features/admins/data';

type Props = {
	onClose: VoidFunction;
	prefix?: string;
};

export function NoStudentsAlert({prefix = 'your class', onClose}: Props) {
	return (
		<WarningAlert
			description={`No students have been added to ${prefix}. Please add some before running the report.`}
			onClose={onClose}
		/>
	);
}
