import {RearrangeNotActiveMessage} from '@esgi/main/features/admins/dashboard';
import {widgetCardsGap} from '../constants';

export const cardsGap = widgetCardsGap / 2;

export const rearrangeHandleClassName = 'rearrange-button';

export const noWidgetsMessage: RearrangeNotActiveMessage = {
	id: 'noWidgets',
	message: 'Please add at least one widget before enabling rearrange mode.',
};

export const someWidgetDataLoadingMessage: RearrangeNotActiveMessage = {
	id: 'widgetsDataIsLoading',
	message: 'Please wait for the widget data to load before enabling rearrange mode.',
};

export const someWidgetInEditModeMessage: RearrangeNotActiveMessage = {
	id: 'widgetsInEditMode',
	message: 'Please save your changes before enabling rearrange mode.',
};
