import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {Subject} from '@esgi/main/libs/admin-store';
import {Entities} from '@esgi/main/features/admins/subject-management';

type Parameters = {
	subjects: Subject[];
	entities: Entities;
};

export function updateEntities({subjects, entities}: Parameters) {
	subjects.forEach(({id, hidden, name, level, subjectType}) => {
		if (level !== SubjectLevel.District) {
			return;
		}

		const subject = {
			id,
			name,
			hidden,
		};

		if (subjectType === SubjectType.Deployed) {
			entities.districtSubjects.push({
				...subject,
				level: SubjectLevel.District,
				type: SubjectType.Deployed,
			});

			return;
		}

		if (subjectType === SubjectType.Stock) {
			entities.stockSubjects.push({
				...subject,
				level: SubjectLevel.District,
				type: SubjectType.Stock,
			});

			return;
		}
	});
}
