import {CSS} from '@stitches/react';

export const listGroupCSS: CSS = {
	display: 'grid',
	gap: '$2',
	gridAutoRows: 40,

	'& > *': {
		display: 'grid',
		alignItems: 'center',
	},
};
