import {useMemo} from 'react';
import {useUser} from '@esgi/core/authentication';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {Folder} from '@esgi/main/features/school-admin/data';
import {useClasses} from '@esgi/main/libs/school-admin-store';
import {ReportDialog, Reports} from '../../../../../../_kit';
import {RowDef} from '../../../../../types';

type Props = {
	folder: Folder,
	teacher: RowDef,
	onClose: VoidFunction,
}

export function ReportsFeature({teacher, onClose, folder}: Props) {
	const user = useUser();
	const [{data: classes, loaded: classesLoaded}] = useClasses();

	const teacherLevelHierarchy = useMemo<Hierarchy>(() => ({
		schoolID: user?.schoolID,
		teacherID: teacher?.id,
		teachersGroupID: folder.id,
	}), [user, teacher, folder]);

	const classLevelHierarchy = useMemo<Hierarchy>(() => {
		if(teacher && classesLoaded) {
			const firstClass = classes.find(c => c.teacherID === teacher.id);
			return {
				schoolID: user?.schoolID,
				teacherID: teacher.id,
				classID: firstClass.id,
				teachersGroupID: folder.id,
			};
		}
	}, [teacher, classesLoaded, classes, user, folder]);

	return (
		<ReportDialog
			header={`${teacher.firstName} ${teacher.lastName}`}
			title='Class Reports'
			loading={!classesLoaded}
			onClose={onClose}
		>
			<Reports.ParentLetter hierarchy={classLevelHierarchy}/>
			<Reports.StudentDetail hierarchy={classLevelHierarchy}/>
			<Reports.StudentProgress hierarchy={classLevelHierarchy}/>
			<Reports.Totals prefix='Class' hierarchy={classLevelHierarchy}/>
			<Reports.GradeScale prefix='Class' hierarchy={classLevelHierarchy}/>
			<Reports.ItemAnalysis hierarchy={teacherLevelHierarchy}/>
			<Reports.PieCharts hierarchy={teacherLevelHierarchy}/>
			<Reports.RubricResults hierarchy={classLevelHierarchy}/>
			<Reports.UntestedStudents hierarchy={teacherLevelHierarchy}/>
		</ReportDialog>
	);
}