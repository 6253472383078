import {AssetsSubjectsPersonalController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {getContractSubjectType, Subject, subjectsStore} from '@esgi/main/libs/school-admin-store';
import {isAsyncPending, useCancelableRequest} from '@esgi/ui';
import {useCallback, useEffect, useState} from 'react';

type Parameters = {
	subjectFromStore: Subject;
};

export function useStoreSubjectVisability({subjectFromStore}: Parameters) {
	const controller = useDisposable(AssetsSubjectsPersonalController);

	const [hideSubjectData, hideSubject] = useCancelableRequest(controller.hide);
	const [unhideSubjectData, unhideSubject] = useCancelableRequest(controller.unhide);

	const [isActive, setIsActive] = useState(!subjectFromStore.hidden);

	useEffect(() => {
		setIsActive(!subjectFromStore.hidden);
	}, [subjectFromStore.hidden]);

	const onSetIsActive = useCallback(
		(newValue: boolean) => {
			setIsActive(newValue);

			if (newValue) {
				unhideSubject({
					subjectID: subjectFromStore.id,
					subjectType: getContractSubjectType(subjectFromStore.subjectType),
				});
			} else {
				hideSubject({
					subjectID: subjectFromStore.id,
					subjectType: getContractSubjectType(subjectFromStore.subjectType),
				});
			}

			subjectsStore().update?.((item: Subject) => {
				if (item.id === subjectFromStore.id) {
					return {
						...item,
						hidden: !newValue,
					};
				}

				return item;
			});
		},
		[hideSubject, subjectFromStore.id, subjectFromStore.subjectType, unhideSubject],
	);

	return {
		isActive,
		setIsActive: onSetIsActive,
		isVisabilityUpdating: isAsyncPending(hideSubjectData) || isAsyncPending(unhideSubjectData),
	};
}
