import {Root as PanelRoot} from './components/root';
import {RearrangeButton} from './components/rearrange-button';

import {Tabs} from '../tabs';
import {Root as TabsContentRoot} from './components/tabs/components/root';
import {AddSubjectButton as TabsContentAddButton} from './components/tabs/components/add-subject-button';
import {ViewMode as TabsContentViewMode} from './components/tabs/variant/view-mode';
import {RearrangeMode as TabsContentRearrangeMode} from './components/tabs/variant/rearrange-mode';

import {Default as ListDefault} from './components/lists/default';
import {ItemContent as ListItemContent} from './components/lists/item-content';
import {Rearrange as ListRearrange} from './components/lists/rearrange';
import {Skeleton as ListSkeleton} from './components/lists/skeleton';

import {EmptyContentBox} from './components/empty-content-box';
import {Root as SectionTitleRoot} from './components/section-title/root';
import {Icon as SectionTitleIcon} from './components/section-title/icon';
import {Title} from './components/section-title/title';

export const SubjectPanel = {
	Root: PanelRoot,
	RearrangeButton,
	SectionTitle: {
		Root: SectionTitleRoot,
		Icon: SectionTitleIcon,
		Title,
	},
	Tabs: {
		Bar: Tabs.TabsBar,
		Content: {
			Root: TabsContentRoot,
			AddSubjectButton: TabsContentAddButton,
			ViewMode: TabsContentViewMode,
			RearrangeMode: TabsContentRearrangeMode,
		},
	},
	List: {
		Default: ListDefault,
		Rearrange: ListRearrange,
		Skeleton: ListSkeleton,
		ItemContent: ListItemContent,
	},
	EmptyContentBox,
};

export * from './types/section';
export * from './types/subject';
