import {isNull} from 'underscore';
import {useDisposable} from '@esgi/core/service';
import {V2SchoolAdminsFoldersUsersController} from '@esgi/contracts/esgi';
import {
	AsyncResponse,
	AsyncStatus,
	isAsyncFailed,
	isAsyncPending,
	isAsyncSucceed,
	useCancelableRequest,
} from '@esgi/ui';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {Folder, FoldersData, FolderType} from '../types';
import {userFolderTypeByGroupType, userGroupTypeByFolderType} from '../constants';

const dataIdle: AsyncResponse<FoldersData> = {
	asyncStatus: AsyncStatus.Idle,
	value: null,
	errors: null,
};

export function useFetchFolders(): [AsyncResponse<FoldersData>, Dispatch<FolderType>] {
	const [folderType, setFolderType] = useState<FolderType | null>(null);

	const [data, setData] = useState<AsyncResponse<FoldersData>>(dataIdle);

	const foldersUsersController = useDisposable(V2SchoolAdminsFoldersUsersController);

	const [usersFoldersData, fetchUsersFolders] = useCancelableRequest(foldersUsersController.get);

	useEffect(() => {
		setData(() => {
			if (isAsyncPending(usersFoldersData)) {
				return {
					asyncStatus: AsyncStatus.Pending,
					value: null,
					errors: null,
				};
			}

			if (isAsyncFailed(usersFoldersData)) {
				return {
					asyncStatus: AsyncStatus.Failed,
					data: undefined,
					error: usersFoldersData.error,
				};
			}

			if (!isNull(folderType) && isAsyncSucceed(usersFoldersData)) {
				return {
					asyncStatus: AsyncStatus.Succeeded,
					data: {
						folders: usersFoldersData.data.value.usersGroups
							//@ts-ignore
							.filter(({groupType}) => userGroupTypeByFolderType[folderType] === groupType)
							.map<Folder>(({groupID, name, totalCount, groupType}) => ({
								id: groupID,
								name,
								count: totalCount,
								type: userFolderTypeByGroupType[groupType],
							})),
					},
				};
			}

			return dataIdle;
		});
	}, [folderType, usersFoldersData]);

	const fetchFolders = useCallback(
		(type: FolderType) => {
			setFolderType(type);
			fetchUsersFolders();
		},
		[fetchUsersFolders],
	);

	return [data, fetchFolders];
}
