import {isNull} from 'underscore';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {isUndefined} from '@esgi/ui';
import {
	LowestAchievementWidgetUpdatedValue,
	LowestAchievementWidgetViewType,
} from '../../../../types/lowest-achievement';
import {CommonSelectedArraysEntity, CommonUpdatedValue, DistrictCutoff, PeriodsVariant} from './types';
import {CommonForm, StudentsTwoPeriodsForm, DatePickerSingleForm} from './forms-data/types';
import {districtCutoffNumberByType} from './constants';
import {Widget} from '../../../../types/widget';
import {enumRemap} from 'shared/utils';
import {PeriodType} from '@esgi/main/kits/admin';
import {
	Color,
	DeployedSubject,
	GradeLevel,
	OnUpdateWidget,
	SchoolModel,
	WidgetCard,
} from '@esgi/main/features/admins/dashboard';

export function useSaveData({
	districtID,
	globalSchoolYearID,
	commonForm,
	schoolsForm,
	studentsSinglePeriodForm,
	studentsTwoPeriodsForm,
	selectedContentArea,
	selectedGradeLevels,
	selectedSchoolsIDs,
	selectedSubjectIDs,
	onUpdateWidget,
	setIsCommonFormTouched,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	subjects,
	allSchoolsList,
	setSelectedSchoolsIDs,
	gradeLevels,
}: {
	districtID: number;
	globalSchoolYearID: number;
	commonForm: CommonForm;
	schoolsForm: DatePickerSingleForm;
	studentsSinglePeriodForm: DatePickerSingleForm;
	studentsTwoPeriodsForm: StudentsTwoPeriodsForm;
	selectedContentArea: string[];
	selectedSubjectIDs: string[];
	selectedGradeLevels: string[];
	selectedSchoolsIDs: string[];
	onUpdateWidget: Dispatch<LowestAchievementWidgetUpdatedValue>;
	setIsCommonFormTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataValid: Dispatch<SetStateAction<boolean>>;
	allSchoolsList: SchoolModel[];
	subjects: DeployedSubject[];
	setSelectedSchoolsIDs: Dispatch<SetStateAction<string[]>>;
	gradeLevels: GradeLevel[];
}) {
	const {allContentAreas} = WidgetCard.useWidgetCardContext();

	const handleUpdateWidget = useCallback(
		(value: LowestAchievementWidgetUpdatedValue) => {
			onUpdateWidget(value);

			setIsCommonFormTouched(false);

			setIsDynamicFormDataTouched(false);
			setIsDynamicFormDataValid(true);
		},
		[onUpdateWidget, setIsCommonFormTouched, setIsDynamicFormDataTouched, setIsDynamicFormDataValid],
	);

	const getCommonUpdatedValue = useCallback(
		({id, name}: {id: Widget['id']; name: string}) => {
			const {viewType, districtCutoff} = commonForm.value;

			const viewTypeValue = viewType[0];
			const districtCutoffValue = districtCutoff[0];

			if (isUndefined(viewTypeValue)) {
				throw new Error('View Type is undefined');
			}

			if (isUndefined(districtCutoffValue)) {
				throw new Error('District Cutoff is undefined');
			}

			if (isNull(districtCutoffValue)) {
				throw new Error('District Cutoff is null');
			}

			if (districtCutoffValue === DistrictCutoff.None) {
				throw new Error('District Cutoff is None');
			}

			const contentAreaIDs = selectedContentArea.map(Number);
			const subjectIDs = selectedSubjectIDs.map(Number);
			const gradeLevelIDs = selectedGradeLevels.map(Number);

			const commonUpdatedValue: CommonUpdatedValue = {
				id,
				name,
				options: {
					districtID,
					globalSchoolYearID,
					districtCutoff: districtCutoffNumberByType[districtCutoffValue],
					contentAreaIDs,
					subjectIDs,
					gradeLevelIDs,
					viewType,
				},
			};

			const commonSelectedArraysEntity: CommonSelectedArraysEntity = {
				contentAreaIDs: allContentAreas.length === contentAreaIDs.length ? 'all' : 'part',
				gradeLevelIDs: gradeLevels.length === gradeLevelIDs.length ? 'all' : 'part',
				subjectIDs: subjects.length === subjectIDs.length ? 'all' : 'part',
			};

			return {commonUpdatedValue, commonSelectedArraysEntity};
		},
		[
			allContentAreas,
			commonForm,
			districtID,
			globalSchoolYearID,
			selectedContentArea,
			selectedGradeLevels,
			selectedSubjectIDs,
			subjects,
			gradeLevels,
		],
	);

	const saveStudentsSinglePeriodForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {date} = studentsSinglePeriodForm.value;

			const schoolIDs = selectedSchoolsIDs.map(Number);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					schoolIDs,
					periods: [
						{
							color: Color.None,
							trackDatesID: date.trackDatesID,
							dateRange: date.dateRange,
							datePeriod: enumRemap(date.datePeriod, PeriodType),
						},
					],
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					schoolIDs: allSchoolsList.length === schoolIDs.length ? 'all' : 'part',
				},
			});
		},
		[allSchoolsList, handleUpdateWidget, selectedSchoolsIDs, studentsSinglePeriodForm.value],
	);

	const saveStudentsTwoPeriodsForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {firstPeriodColor, firstPeriodDate, secondPeriodColor, secondPeriodDate} = studentsTwoPeriodsForm.value;

			const firstPeriodColorValue = firstPeriodColor[0];
			const secondPeriodColorValue = secondPeriodColor[0];

			if (isUndefined(firstPeriodColorValue)) {
				throw new Error('WidgetViewType.Students -> Two Periods: firstPeriodColorValue is undefined');
			}

			if (isNull(firstPeriodColorValue)) {
				throw new Error('WidgetViewType.Students -> Two Periods: firstPeriodColorValue is null');
			}

			if (isUndefined(secondPeriodColorValue)) {
				throw new Error('WidgetViewType.Students -> Two Periods: secondPeriodColorValue is undefined');
			}

			if (isNull(secondPeriodColorValue)) {
				throw new Error('WidgetViewType.Students -> Two Periods: secondPeriodColorValue is null');
			}

			const schoolIDs = selectedSchoolsIDs.map(Number);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					schoolIDs,
					periods: [
						{
							color: firstPeriodColorValue,
							trackDatesID: firstPeriodDate.trackDatesID,
							dateRange: firstPeriodDate.dateRange,
							datePeriod: enumRemap(firstPeriodDate.datePeriod, PeriodType),
						},
						{
							color: secondPeriodColorValue,
							trackDatesID: secondPeriodDate.trackDatesID,
							dateRange: secondPeriodDate.dateRange,
							datePeriod: enumRemap(secondPeriodDate.datePeriod, PeriodType),
						},
					],
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					schoolIDs: allSchoolsList.length === schoolIDs.length ? 'all' : 'part',
				},
			});
		},
		[allSchoolsList, handleUpdateWidget, selectedSchoolsIDs, studentsTwoPeriodsForm.value],
	);

	const saveSchoolsForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {date} = schoolsForm.value;

			const schoolIDs = allSchoolsList.map(({id}) => id);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					schoolIDs,
					periods: [
						{
							color: Color.None,
							trackDatesID: date.trackDatesID,
							dateRange: date.dateRange,
							datePeriod: enumRemap(date.datePeriod, PeriodType),
						},
					],
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					schoolIDs: 'all',
				},
			});

			setSelectedSchoolsIDs(schoolIDs.map(String));
		},
		[allSchoolsList, handleUpdateWidget, schoolsForm.value, setSelectedSchoolsIDs],
	);

	return useCallback<OnUpdateWidget>(
		({id, name}) => {
			const {commonUpdatedValue, commonSelectedArraysEntity} = getCommonUpdatedValue({id, name});

			const {viewType, periodsVariant} = commonForm.value;

			if (viewType === LowestAchievementWidgetViewType.Students) {
				if (periodsVariant === PeriodsVariant.SinglePeriod) {
					saveStudentsSinglePeriodForm({commonUpdatedValue, commonSelectedArraysEntity});
				}

				if (periodsVariant === PeriodsVariant.TwoPeriods) {
					saveStudentsTwoPeriodsForm({commonUpdatedValue, commonSelectedArraysEntity});
				}
			}

			if (viewType === LowestAchievementWidgetViewType.Schools) {
				saveSchoolsForm({commonUpdatedValue, commonSelectedArraysEntity});
			}
		},
		[
			commonForm.value,
			getCommonUpdatedValue,
			saveSchoolsForm,
			saveStudentsSinglePeriodForm,
			saveStudentsTwoPeriodsForm,
		],
	);
}
