import * as React from 'react';
import type {SVGProps} from 'react';

export function D(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 14.8684C9 16.694 10.4799 18.174 12.3055 18.174H20.1785C21.0082 17.3476 21.0082 17.3476 21.0082 17.3476C21.0082 17.3476 21.0082 17.3476 20.1784 16.5212H12.3055C11.3927 16.5212 10.6528 15.7812 10.6528 14.8684V10.7365C10.6528 10.2801 10.2828 9.91016 9.82638 9.91016C9.36998 9.91016 9 10.2801 9 10.7365V14.8684Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.4574 22.0639C17.1346 21.7412 17.1346 21.218 17.4574 20.8952L21.0049 17.3477L17.4574 13.8001C17.1346 13.4774 17.1346 12.9541 17.4574 12.6314C17.7801 12.3087 18.3033 12.3087 18.626 12.6314L22.758 16.7633C23.0807 17.0861 23.0807 17.6093 22.758 17.932L18.626 22.0639C18.3033 22.3866 17.7801 22.3866 17.4574 22.0639Z'
				fill='#333333'
			/>
		</svg>
	);
}
