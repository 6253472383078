import {Dispatch, PropsWithChildren} from 'react';
import {TabID} from '../../variant/types';
import {Drawer} from '@esgi/main/kits/common';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Content} from './index.styled';
import {Tabs} from '../tabs';
import {tabItems} from './constants';
import {isNull} from 'underscore';
import {ErrorMessageBox} from '../error-message-box';

type Props = PropsWithChildren<{
	activeTabID: TabID;
	setActiveTabID: Dispatch<TabID>;
	schoolSpecialistFormInactiveMessage: string | null;
}>;

export {contentRowGap} from './index.styled';

export function Body({
	activeTabID,
	setActiveTabID,
	children,
	schoolSpecialistFormInactiveMessage,
}: Props) {
	return (
		<Drawer.Body>
			<OverlayScrollbarsComponent
				defer
				style={{height: 'calc(100% + 0px)'}}
				options={{
					scrollbars: {autoHide: 'leave'},
				}}
			>
				<Content>
					{!isNull(schoolSpecialistFormInactiveMessage) ? (
						<ErrorMessageBox message='There are no specialists in the school' />
					) : (
						<>
							<Tabs activeTabID={activeTabID} setActiveTabID={setActiveTabID} items={tabItems} />
							{children}
						</>
					)}
				</Content>
			</OverlayScrollbarsComponent>
		</Drawer.Body>
	);
}
