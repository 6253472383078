import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {CounterBox, GridBox} from '@esgi/ui/layout';
import {
	AlertBody,
	AlertBodyContent,
	AlertFooter,
	AlertHeader,
	alertRootCss,
	FolderNameBox,
	RemoveIconBox,
	SelectedItemsBox,
} from './index.styled';
import {Button} from '@esgi/ui';
import {Folder, FolderRemove} from '@esgi/ui/icons';
import {Arrow} from './components/arrow';
import {useCallback, useState} from 'react';
import {FolderManagement, FolderType} from '../kit';
import {OneLinedText} from '@esgi/main/kits/common';
import {folderEntityName} from './constants';

type Props = {
	onClose: VoidFunction;
	folderName: string;
	folderType: FolderType;
	folderID: number;
	allEntityIDs: number[];
	potentialRemovedEntityIDs: number[];
};

export function RemoveEntitiesFromFolder({
	onClose,
	allEntityIDs,
	potentialRemovedEntityIDs,
	folderID,
	folderName,
	folderType,
}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [isFolderUpdating, setIsFolderUpdating] = useState(false);

	const updateFolder = FolderManagement.useUpdateFolder({type: folderType, onFolderUpdated: closeAlert});

	const handleUpdateFolder = useCallback(() => {
		setIsFolderUpdating(true);

		updateFolder({
			entityIDs: allEntityIDs.filter((id) => !potentialRemovedEntityIDs.includes(id)),
			id: folderID,
			name: folderName,
		});
	}, [allEntityIDs, folderID, folderName, potentialRemovedEntityIDs, updateFolder]);

	return (
		<Alert modalManagerRef={alertRef} css={alertRootCss}>
			<AlertHeader withBacklight={false} onCloseIconClick={closeAlert}>
				<Text size='small'>Remove {folderEntityName[folderType]} from a Folder</Text>

				<SelectedItemsBox>
					<Text size='xSmall' font='mono' color='primary'>
						{folderEntityName[folderType]} Selected:
					</Text>

					<CounterBox color='blue'>
						<Text size='small' font='mono' color='primaryVivid'>
							{potentialRemovedEntityIDs.length}
						</Text>
					</CounterBox>
				</SelectedItemsBox>
			</AlertHeader>

			<AlertBody>
				<AlertBodyContent>
					<FolderNameBox>
						<Folder />
						<OneLinedText size='large' color='mediumContrast'>
							{folderName}
						</OneLinedText>
					</FolderNameBox>

					<Arrow />

					<RemoveIconBox>
						<FolderRemove />
					</RemoveIconBox>
				</AlertBodyContent>
			</AlertBody>

			<AlertFooter>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>

					<Button color='warn' onClick={handleUpdateFolder} disabled={isFolderUpdating}>
						<Text size='medium' bold color='negative'>
							Remove
						</Text>
					</Button>
				</GridBox>
			</AlertFooter>
		</Alert>
	);
}
