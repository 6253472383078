import {TabID, PanelMode, Page} from '@esgi/main/features/admins/subject-management';
import {Subject, User, useTeachers} from '@esgi/main/libs/school-admin-store';
import {useCallback, useEffect, useLayoutEffect, useState} from 'react';
import {useSubjects} from './use-subjects';
import {SubjectsPanel} from './components/subjects-panel';
import {Teacher} from './variant/teacher';
import {SelectedSubject} from './types';
import {isNull} from 'underscore';
import {SubjectLevel} from '@esgi/main/kits/common';
import {District} from './variant/district';
import {School} from './variant/school';

export default function () {
	const [{data: teachersList, loaded: isTeachersLoaded}] = useTeachers();

	const [activeTabID, setActiveTabID] = useState<TabID | null>(TabID.School);

	const [selectedSubjectID, setSelectedSubjectID] = useState<number | null>(null);
	const [subjectsPanelMode, setSubjectsPanelMode] = useState(PanelMode.View);

	const [selectedSchoolSubject, setSelectedSchoolSubject] = useState<Subject | null>(null);
	const [schoolSubjectEditTouched, setSchoolSubjectEditTouched] = useState(false);
	const [schoolNewSubjectEditTouched, setSchoolNewSubjectEditTouched] = useState(false);

	const [selectedDistrictSubject, setSelectedDistrictSubject] = useState<SelectedSubject | null>(null);

	const [selectedTeacherID, setSelectedTeacherID] = useState<User['id'] | null>(null);
	const [selectedTeacherSubject, setSelectedTeacherSubject] = useState<SelectedSubject | null>(null);

	const {
		isSubjectsLoaded,
		isSelectedTeacherSubjectsLoaded,
		subjectsEntities,
		allSchoolSubjectsNames,
		getSubjectFromStoreByID,
		onRearrangeSchoolSubjects,
		getEntitySubjectByID,
	} = useSubjects({
		setSelectedSubjectID,
		selectedTeacherID,
	});

	useLayoutEffect(() => {
		if (isTeachersLoaded) {
			setSelectedTeacherID(teachersList[0]?.id ?? null);
		}
	}, [isTeachersLoaded, teachersList]);

	useEffect(() => {
		const selectedSubject = getEntitySubjectByID(selectedSubjectID);
		const selectedSubjectFromStore = getSubjectFromStoreByID(selectedSubjectID);

		if (isNull(selectedSubject)) {
			setSelectedSchoolSubject(null);
			setSelectedDistrictSubject(null);
			setSelectedTeacherSubject(null);

			return;
		}

		if (selectedSubject.level === SubjectLevel.School) {
			setSelectedSchoolSubject(selectedSubjectFromStore);
			setSelectedDistrictSubject(null);
			setSelectedTeacherSubject(null);

			return;
		}

		if (selectedSubject.level === SubjectLevel.District) {
			setSelectedSchoolSubject(null);
			setSelectedDistrictSubject(selectedSubject);
			setSelectedTeacherSubject(null);

			return;
		}

		if (selectedSubject.level === SubjectLevel.Teacher) {
			setSelectedSchoolSubject(null);
			setSelectedDistrictSubject(null);
			setSelectedTeacherSubject(selectedSubject);

			return;
		}
	}, [getEntitySubjectByID, getSubjectFromStoreByID, selectedSubjectID]);

	useEffect(() => {
		if (isSelectedTeacherSubjectsLoaded && activeTabID === TabID.Teacher) {
			setSelectedSubjectID(subjectsEntities.teacherSubjects[0]?.id ?? null);
		}
	}, [activeTabID, isSelectedTeacherSubjectsLoaded, subjectsEntities.teacherSubjects]);

	const onSetSelectedSubjectID = useCallback((id: number | null) => {
		setSelectedSubjectID(id);
		setSubjectsPanelMode(PanelMode.View);
	}, []);

	const onSubjectsPanelModeChange = useCallback((mode: PanelMode) => {
		if (mode === PanelMode.Add) {
			setSelectedSubjectID(null);
		}

		setSubjectsPanelMode(mode);
	}, []);

	const onActiveTabChanged = useCallback(
		(tabID: TabID | null) => {
			setActiveTabID(tabID);

			if (tabID === TabID.School) {
				setSelectedSubjectID(subjectsEntities.schoolSubjects[0]?.id ?? null);
				return;
			}

			if (tabID === TabID.District) {
				setSelectedSubjectID(subjectsEntities.districtSubjects[0]?.id ?? null);
				return;
			}

			if (tabID === TabID.Teacher) {
				setSelectedSubjectID(subjectsEntities.teacherSubjects[0]?.id ?? null);
				return;
			}

			setSelectedSubjectID(null);
		},
		[subjectsEntities],
	);

	const onSubjectRemoved = useCallback(() => {
		onSetSelectedSubjectID(subjectsEntities.schoolSubjects[0]?.id ?? null);
	}, [onSetSelectedSubjectID, subjectsEntities]);

	return (
		<Page gridTemplateColumns='272px 1fr 1fr'>
			<SubjectsPanel
				skeleton={!isSubjectsLoaded || !isTeachersLoaded}
				entities={subjectsEntities}
				onActiveTabChanged={onActiveTabChanged}
				selectedSubjectID={selectedSubjectID}
				setSelectedSubjectID={onSetSelectedSubjectID}
				subjectsPanelMode={subjectsPanelMode}
				onSubjectsPanelModeChange={onSubjectsPanelModeChange}
				onRearrangeSchoolSubjects={onRearrangeSchoolSubjects}
				isSubjectHasUnsavedChanges={schoolSubjectEditTouched || schoolNewSubjectEditTouched}
				selectedTeacherID={selectedTeacherID}
				setSelectedTeacherID={setSelectedTeacherID}
				teachersList={teachersList}
				isSelectedTeacherSubjectsLoaded={isSelectedTeacherSubjectsLoaded}
			/>

			{(() => {
				if (activeTabID === TabID.School) {
					if (subjectsPanelMode === PanelMode.Add) {
						return (
							<School
								type='add'
								allSchoolSubjectsNames={allSchoolSubjectsNames}
								onSubjectCreated={onSetSelectedSubjectID}
								onIsFormTouchedValueChanged={setSchoolNewSubjectEditTouched}
							/>
						);
					}

					if (!isNull(selectedSchoolSubject)) {
						return (
							<School
								type='edit'
								allSchoolSubjectsNames={allSchoolSubjectsNames}
								subjectFromStore={selectedSchoolSubject}
								onSubjectRemoved={onSubjectRemoved}
								onIsFormTouchedValueChanged={setSchoolSubjectEditTouched}
							/>
						);
					}

					return null;
				}

				if (activeTabID === TabID.District && !isNull(selectedDistrictSubject)) {
					return <District subject={selectedDistrictSubject} />;
				}

				if (activeTabID === TabID.Teacher && !isNull(selectedTeacherSubject)) {
					return <Teacher subject={selectedTeacherSubject} />;
				}

				return null;
			})()}
		</Page>
	);
}
