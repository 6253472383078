import React from 'react';
import {Avatar} from '@esgi/ui/avatar';
import {FlexBox} from '@esgi/ui/layout';
import {School} from '@esgi/contracts/esgi/types/esgi.schools/schools/get/by-id/school';
import {StudentInfoRowValue, StudentName, StudentInfo, StudentInfoRow, Label} from './index.styled';

interface Props {
	school: School;
	studentId?: number;
	firstName?: string;
	lastName?: string;
	imageURL?: string;
	isLoaded: boolean;
}

export function StudentDetails({school, studentId, firstName, lastName, imageURL, isLoaded}: Props) {
	return (
		<FlexBox align='center'>
			<Avatar.Root size='xl' borderColor='primary' skeleton={!isLoaded}>
				<Avatar.Image src={imageURL} />
				<Avatar.Fallback />
			</Avatar.Root>
			<StudentInfo>
				<StudentName size='large' skeleton={!isLoaded}>
					{`${firstName} ${lastName}`}
				</StudentName>
				<StudentInfoRow skeleton={!isLoaded}>
					<Label size='xSmall' font='mono' color='lowContrast'>ID:</Label>
					<StudentInfoRowValue size='xSmall' font='mono'>{studentId}</StudentInfoRowValue>
				</StudentInfoRow>
				{school && (
					<StudentInfoRow skeleton={!isLoaded}>
						<Label size='xSmall' font='mono' color='lowContrast'>School:</Label>
						<StudentInfoRowValue size='xSmall' font='mono'>{school.name}</StudentInfoRowValue>
					</StudentInfoRow>
				)}
			</StudentInfo>
		</FlexBox>
	);
}

