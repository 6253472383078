import {SectionBox} from './index.styled';
import {BaseComponentProps, getThemeColor, IconComponent, ThemeColorKeys} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {useSubjectPanelContext} from '../../../context';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<typeof SectionBox>, 'children' | 'skeleton'> & {
		fill: ThemeColorKeys;
		Component: IconComponent;
	};

export const Icon = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'subject-selection-panel-section-title-icon', css = {}, fill, Component, ...boxProps}, forwardedRef) => {
		const {skeleton} = useSubjectPanelContext();

		return (
			<SectionBox
				dataCy={dataCy}
				css={{
					color: getThemeColor(fill),
					...css,
				}}
				ref={forwardedRef}
				{...boxProps}
				skeleton={skeleton}
			>
				<Component />
			</SectionBox>
		);
	},
);
