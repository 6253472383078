import {Text} from '@esgi/ui/typography';
import {Button} from './index.styled';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Tooltip} from '@esgi/ui';

type Props = Omit<ComponentPropsWithoutRef<typeof Button>, 'children'> & {
	title?: string;
	disabledMessage?: string | null;
};

export const RunReportButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			dataCy = 'run-report-button',
			css = {},
			color = 'primary',
			onClick,
			title = 'Run Reports',
			disabledMessage,
			disabled,
			...props
		},
		forwardedRef,
	) => {
		const withDisabledMessage = typeof disabledMessage === 'string';

		return (
			<Tooltip open={withDisabledMessage ? undefined : false}>
				<Tooltip.Trigger>
					<Button
						dataCy={dataCy}
						css={css}
						color={color}
						onClick={onClick}
						disabled={withDisabledMessage || Boolean(disabled)}
						ref={forwardedRef}
						{...props}
					>
						<Text size='large' color='surface'>
							{title}
						</Text>
					</Button>
				</Tooltip.Trigger>

				<Tooltip.Content variant='default'>
					<Text size='small'>{disabledMessage}</Text>
				</Tooltip.Content>
			</Tooltip>
		);
	},
);
