import {TeacherCUDEventData} from './types';

export class TeacherCreatedEvent {
	constructor(public data: TeacherCUDEventData) {}
}

export class TeacherUpdatedEvent {
	constructor(public data: TeacherCUDEventData) {}
}

export class TeacherDeletedEvent {
	constructor(public data: TeacherCUDEventData) {}
}
