import {useMemo} from 'react';
import {SelectedEntityInfo, SelectedEntityInfoItem} from '../../kit';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {SchoolModel} from '../../types';

type Props = {
	schoolIDs: number[];
	allSchoolsList: SchoolModel[];
};

export function SelectedSchoolsInfo({schoolIDs, allSchoolsList}: Props) {
	const schoolsItems = useMemo(() => {
		return schoolIDs
			.map<SelectedEntityInfoItem<number> | null>((schoolID) => {
				const school = allSchoolsList.find(({id}) => id === schoolID);

				if (isUndefined(school)) {
					return null;
				}

				return {
					label: school.name,
					value: school.id,
				};
			})
			.filter((item): item is SelectedEntityInfoItem<number> => !isNull(item));
	}, [allSchoolsList, schoolIDs]);

	return (
		<SelectedEntityInfo
			items={schoolsItems}
			itemsTranscript='Schools'
			totalPossibleItemsCount={allSchoolsList.length}
		/>
	);
}
