import {ColDef} from 'ag-grid-community';
import {useMemo} from 'react';
import {min, max} from 'underscore';
import {RowDef} from './types';
import {CellType, NumberFilter} from '@esgi/ui/ag-grid';

export function useColumnDefinitions(rows: RowDef[]) {
	return useMemo<ColDef<RowDef>[]>(
		() => [
			{
				field: 'name',
				headerName: 'School',
				lockVisible: true,
				flex: 3,
				headerComponentParams: {
					description: `Displays a school's name (Required)`,
				},
				filter: false,
				sortable: true,
				type: CellType.Primary,
				sort: 'asc',
			},
			{
				field: 'teachers',
				headerName: 'Teachers',
				filter: NumberFilter,
				filterParams: {
					min: min(rows.map((r) => r.teachers)),
					max: max(rows.map((r) => r.teachers)),
				},
				flex: 1,
				headerComponentParams: {
					description: 'Displays the number of active teachers in the school',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
			{
				field: 'students',
				headerName: 'Students',
				filter: NumberFilter,
				filterParams: {
					min: min(rows.map((r) => r.students)),
					max: max(rows.map((r) => r.students)),
				},
				flex: 1,
				headerComponentParams: {
					description: 'Displays the number of active students in the school',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
			{
				field: 'specialists',
				headerName: 'Specialists',
				filter: NumberFilter,
				filterParams: {
					min: min(rows.map((r) => r.specialists)),
					max: max(rows.map((r) => r.specialists)),
				},
				flex: 1,
				headerComponentParams: {
					description: 'Displays the number of active District and School Specialists in the school',
				},
				sortable: true,
				type: [CellType.RightAligned, CellType.Numeric],
			},
			{
				field: 'classRatio',
				headerName: 'Class Ratio',
				valueFormatter: (params) => (params.value === undefined ? '-' : (params.value || 0) + ':1'),
				filter: NumberFilter,
				filterParams: {
					min: min(rows.map((r) => r.classRatio)),
					max: max(rows.map((r) => r.classRatio)),
				},
				flex: 1,
				headerComponentParams: {
					description: 'Displays the average number of students per teacher',
				},
				sortable: true,
				type: CellType.RightAligned,
			},
		],
		[rows],
	);
}
