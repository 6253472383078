import * as React from 'react';
import type {SVGProps} from 'react';

export function ChartCandle(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				d='M16.65 7.0001C16.65 6.64111 16.359 6.3501 16 6.3501C15.641 6.3501 15.35 6.64111 15.35 7.0001V13.379L13.1916 15.5374C12.9361 15.7929 12.9361 16.2071 13.1916 16.4626L15.35 18.621V25.0001C15.35 25.3591 15.641 25.6501 16 25.6501C16.359 25.6501 16.65 25.3591 16.65 25.0001V18.621L18.8084 16.4626C19.0639 16.2071 19.0639 15.7929 18.8084 15.5374L16.65 13.379V7.0001Z'
				fill='#333333'
			/>
		</svg>
	);
}
