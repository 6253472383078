import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const PeriodColorBox = styled(GridBox, {
	gap: '$2',
	gridTemplateColumns: '64px 1fr',

	'& input': {
		minWidth: 'unset',
	},
});
