import {
	IDoesFilterPassParams,
} from 'ag-grid-community';

import {CustomFilterProps} from 'ag-grid-react';
import {useCallback, useEffect, useId, useMemo, useRef, useState} from 'react';
import {PossibleEntity} from '../../../types';
import {KeyType} from './types';
import {isArray, isEqual} from 'underscore';
import {getDisplayName as defaultGetDisplayName, isColDef} from '../../../utils';

export function useEntitiesFilter(filterParams: CustomFilterProps) {
	const {model, onModelChange, getValue, colDef} = filterParams;

	const emptyEntityID = useId();

	const [entities, setEntities] = useState<PossibleEntity[]>([]);
	const [searchKeyWord, setSearchKeyWord] = useState<string>('');
	const getDisplayName = useRef<(entity: PossibleEntity) => string>(defaultGetDisplayName);

	const applyFilter = useCallback((ids: KeyType[]) => {
		const allEntriesIDs = entities.map((entry: PossibleEntity) => entry.id).sort();
		if (isEqual(allEntriesIDs, ids.sort())) {
			onModelChange(undefined);
		} else {
			onModelChange([...ids]);
		}
	}, [entities, onModelChange]);

	const doesFilterPass = useCallback(
		(params: IDoesFilterPassParams) => {
			const {node} = params;

			const filterText: string[] = entities.filter(e => e.id !== emptyEntityID && model.includes(e.id)).map(e => getDisplayName.current(e).toLowerCase());
			const isEmptyEntitySelected = model.includes(emptyEntityID);

			if(isColDef(colDef)) {
				const data = node.data;
				const field = colDef.field;
				const idProperty = 'id';
				if(field && field in data) {
					const fieldData = data[field];

					if(fieldData && idProperty in fieldData) {
						const id = fieldData[idProperty];
						return model.includes(id);
					}

					if(Array.isArray(fieldData) && fieldData.every(data => idProperty in data)) {
						const fieldDataIDs = fieldData.map((data) => data[idProperty]);
						return fieldDataIDs.some((id) => model.includes(id));
					}
				}
			}

			if(!getValue(node) && model.length) {
				return isEmptyEntitySelected;
			}

			const value: string = getValue(node).toString().toLowerCase();
			return filterText.includes(value);
		},
		[entities, colDef, getValue, model],
	);

	useEffect(() => {
		const err = `Invalid parameter passed. Check column definition object. Property 'entities' in filter parameters should be an array of KeyValue from @esgi/ui/ag-grid.`;
		const possibleArr = colDef.filterParams?.entities?.filter(Boolean);
		if (possibleArr !== undefined) {
			if (isArray(possibleArr)) {
				if (possibleArr.every(p => 'id' in p)) {
					setEntities(possibleArr);
				}

				const emptyEntityName = colDef.filterParams?.emptyEntityName;

				if(emptyEntityName) {
					if(typeof emptyEntityName !== 'string') {
						throw new Error(`Invalid parameter passed. Check column definition object. Property 'emptyEntityName' in filter parameters should be a string of KeyValue from @esgi/ui/ag-grid.`);
					}

					possibleArr.push({
						id: emptyEntityID,
						name: emptyEntityName,
					});
				}

				return;
			}
			throw new Error(err);
		} else {
			setEntities([]);
		}
	}, [colDef.filterParams?.entities, colDef.filterParams?.emptyEntityName]);

	const {entities: filteredByKeywordEntities, emptyEntity} = useMemo(() => {
		const keyword = searchKeyWord.trim().toLowerCase();

		const emptyEntity = entities.find(({id}) => emptyEntityID === id) ?? null;
		const filteredAllEntity = entities.filter(({id}) => emptyEntityID !== id);

		if (keyword) {
			return {
				entities: filteredAllEntity.filter((e) => getDisplayName.current(e).toLowerCase().includes(keyword)),
				emptyEntity,
			};
		}

		return {
			entities: filteredAllEntity,
			emptyEntity,
		};
	}, [emptyEntityID, entities, searchKeyWord]);

	return {
		entities: filteredByKeywordEntities,
		searchKeyWord,
		setSearchKeyWord,
		doesFilterPass,
		applyFilter,
		getDisplayName: getDisplayName.current,
		emptyEntity,
	};
}