import {Input} from '@esgi/ui/form-controls';
import {useFormElementContext} from '@esgillc/ui-kit/form';
import {getError} from '../../utils';
import {StyledContainer} from './index.styled';

interface Props {
	name: string;
	loading?: boolean;
	disabled?: boolean;
}

export function InputField({name, loading, disabled}: Props) {
	const state = useFormElementContext();

	return (
		<StyledContainer>
			<Input.CleanableIndicator skeleton={loading} placeholder={name} disabled={disabled} error={getError(name, state)} />
		</StyledContainer>
	);
}