import {useRef, useState} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Snackbar} from '@esgi/ui/snackbar';
import {Drawer} from '@esgi/main/kits/common';
import {PanelContent} from '../../components/panels.styled';
import {DisabledInput} from '../../components/disabled-input';
import {ContentBox} from '../../components/content-box.styled';
import {ChangePasswordForm} from '../../components/change-password';
import {PanelHeaderTitle} from '../../components/panel-header-title';
import {PanelActionButton, PanelHeaderWrapper, DrawerPanelHeader} from '../index.styled';

type Props = {
	userName: string;
	email: string;
};

export function LoginContent({userName}: Props) {
	const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
	const [isAllowChangePassword, setAllowChangePassword] = useState(false);
	const snackbarRef = Snackbar.useRef();
	const onSetNewPassword = useRef(null);

	const openChangePasswordForm = () => {
		setShowChangePasswordForm(true);
	};

	const handleSetNewPassword = () => {
		onSetNewPassword.current();
	};

	const handleKeepCurrentPassword = () => {
		setShowChangePasswordForm(false);
		snackbarRef.current.showSnackbar('Your password remains unchanged');
	};

	return (
		<>
			<DrawerPanelHeader>
				<PanelHeaderWrapper>
					<PanelHeaderTitle title='Login Information' />
					{showChangePasswordForm &&
						<>
							<PanelActionButton color='secondary' onClick={handleKeepCurrentPassword}>
								<Text size='large' bold>
									Keep Current Password
								</Text>
							</PanelActionButton>
							<PanelActionButton color='primary' onClick={handleSetNewPassword} disabled={!isAllowChangePassword}>
								<Text size='large' bold>
									Set New Password
								</Text>
							</PanelActionButton>
						</>
					}
				</PanelHeaderWrapper>
			</DrawerPanelHeader>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<PanelContent>
					<ContentBox>
						{!showChangePasswordForm &&
							<Drawer.ContentBlock title='Login Credentials'>
								<DisabledInput
									value={userName}
									placeholder='Username'
									inputTranscript='To change your username, please contact our Customer Support team or your system administrator.'
									dataCy='username-disabled-input'
								/>
							</Drawer.ContentBlock>
						}
						<Drawer.ContentBlock title='Password'>
							{!showChangePasswordForm &&
								<Button color='secondary' onClick={openChangePasswordForm}>
									<Text size='medium' bold>
										Change Password
									</Text>
								</Button>
							}
							{showChangePasswordForm &&
								<ChangePasswordForm
									setAllowChangePassword={setAllowChangePassword}
									onSetNewPassword={onSetNewPassword}
									setShowChangePasswordForm={setShowChangePasswordForm}
								/>
							}
						</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>
				<Snackbar snackbarRef={snackbarRef} />
			</OverlayScrollbarsComponent>
		</>
	);
}
