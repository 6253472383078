import {DataContainer, LayoutContainer} from './index.styled';
import React, {useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {DataPanel} from './components/data-panel';
import {Outlet, useSearchParams} from 'react-router-dom';
import {Snackbar} from '@esgi/ui/snackbar';
import {useRootPageContext} from '@esgi/main/kits/common';
import {folderID} from './constants';
import {isUndefined} from '@esgi/ui';
import {DataPageContext, DataPageContextValue} from './context';
import {isNull} from 'underscore';
import {CollapsiblePanel, Folder} from '@esgi/main/features/admins/data';

export function DataLayout() {
	const rootPageContext = useRootPageContext();
	const snackbarRef = Snackbar.useRef();

	const [searchParams, updateSearchParams] = useSearchParams();

	const [districtName, setDistrictName] = useState<string | null>(null);
	const [entityFolders, setEntityFolders] = useState<Folder[] | null>(null);
	const [activeFolder, setActiveFolder] = useState<Folder | null>(null);

	const pageContentContainerRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		const currentFolderID = searchParams.get(folderID);

		if (isNull(entityFolders) || isNull(currentFolderID)) {
			setActiveFolder(null);
			return;
		}

		const folder = entityFolders.find(({id}) => id === Number(currentFolderID));

		if (isUndefined(folder)) {
			setActiveFolder(null);
			return;
		}

		setActiveFolder(folder);
	}, [entityFolders, searchParams]);

	const onSetActiveFolder = useCallback(
		(value: Folder) => {
			searchParams.set(folderID, String(value.id));
			updateSearchParams(searchParams);
		},
		[searchParams, updateSearchParams],
	);

	const onDeselectFolder = useCallback(() => {
		searchParams.delete(folderID);
		updateSearchParams(searchParams);
	}, [searchParams, updateSearchParams]);

	const onDecreaseFolderCount = useCallback((folderID: number) => {
		setEntityFolders((currentEntityFolders) => {
			return currentEntityFolders.reduce<Folder[]>((result, currentEntityFolder) => {
				let newEntityFolder = currentEntityFolder;
				if (currentEntityFolder.id === folderID) {
					newEntityFolder = {
						...currentEntityFolder,
						count: currentEntityFolder.count - 1,
					};
				}
				result.push(newEntityFolder);
				return result;
			}, []);
		});
	}, []);

	const context = useMemo<DataPageContextValue>(
		() => ({
			districtName,
			setDistrictName,
			snackbarRef,
			pageContentContainerRef,
			activeFolder,
			entityFolders,
			onSetActiveFolder,
			setEntityFolders,
			onDeselectFolder,
			onDecreaseFolderCount,
			...rootPageContext,
		}),
		[
			activeFolder,
			districtName,
			entityFolders,
			onDecreaseFolderCount,
			onDeselectFolder,
			onSetActiveFolder,
			rootPageContext,
			snackbarRef,
		],
	);

	return (
		<CollapsiblePanel.AppRoot>
			<DataPageContext.Provider value={context}>
				<LayoutContainer>
					<DataPanel onDistrictNameChanged={setDistrictName} />
					<DataContainer ref={pageContentContainerRef}>
						<Outlet />
					</DataContainer>
					<Snackbar snackbarRef={snackbarRef} />
				</LayoutContainer>
			</DataPageContext.Provider>
		</CollapsiblePanel.AppRoot>
	);
}
