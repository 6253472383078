import {FiltersRoot, FiltersSelectsContainer} from './index.styled';
import {TestedOptionFilter} from './components/tested-option-filter';
import {SortableOptions} from './components/sortable-options';
import {TestPanel} from '@esgi/main/kits/test-results-panel';

export function TestCardsFilters() {
	return (
		<FiltersRoot>
			<TestPanel.Filters.TestTypeFilterTabs />

			<FiltersSelectsContainer>
				<TestedOptionFilter />
				<SortableOptions />
			</FiltersSelectsContainer>
		</FiltersRoot>
	);
}
