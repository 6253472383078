import {ManageSubjectsAndTestsController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {TestPanelTestModel} from '@esgi/main/features/admins/subject-management';
import {Subject, subjectsStore} from '@esgi/main/libs/admin-store';
import {useCancelableRequest} from '@esgi/ui';
import {useCallback} from 'react';

type Parameters = {
	subjectFromStore: Subject;
};

export function useRearrangeTests({subjectFromStore}: Parameters) {
	const subjectsController = useDisposable(ManageSubjectsAndTestsController);

	const [, reorderTests] = useCancelableRequest(subjectsController.reorderTests);

	return useCallback(
		(reorderedTests: TestPanelTestModel[]) => {
			reorderTests({
				subjectID: subjectFromStore.id,
				testIds: reorderedTests.map(({id}) => id),
			});

			subjectsStore().update?.((item: Subject) => {
				if (item.id === subjectFromStore.id) {
					const shuffledTests = reorderedTests.map(({id}) => item.tests.find((test) => id === test.id)).filter(Boolean);

					return {
						...item,
						tests: shuffledTests,
					};
				}

				return item;
			});
		},
		[reorderTests, subjectFromStore],
	);
}
