import {useState} from 'react';
import {Page, SingleSelectedExpandablePanel} from '../../../../../_kit';
import {RowDef} from '../../../../types';
import {QuickPanel} from './components/quick-panel';
import {ExpandedPanel} from './components/expanded-panel';

export function SingleItemExpandablePanel() {
	const {api} = Page.useContext<RowDef>();

	const [isPanelExpanded, setIsPanelExpanded] = useState(false);
	const [selectedRow, setSelectedRow] = useState<RowDef | null>(null);

	return (
		<SingleSelectedExpandablePanel.Root<RowDef>
			gridApi={api}
			onSingleSelectedItemChange={setSelectedRow}
			onPanelExpandableValueChange={setIsPanelExpanded}
		>
			{selectedRow && (
				<>
					<QuickPanel selectedRow={selectedRow} isPanelExpanded={isPanelExpanded} />
					<ExpandedPanel selectedRow={selectedRow} />
				</>
			)}
		</SingleSelectedExpandablePanel.Root>
	);
}
