import {useCallback} from 'react';
import {isUndefined} from 'underscore';
import {getFullName} from '@esgi/main/kits/common';
import {classSortableCells, studentsOnePeriodSortableCells, studentsTwoPeriodsSortableCells} from '../constants';
import {SchoolStudentsNeedingSupportMetricData} from '../../../types/students-needing-support';
import {useStudentsTwoPeriodsItems} from './use-students-two-periods-items';
import {useStudentsOnePeriodItems} from './use-students-one-period-items';
import {GradeLevel} from '@esgi/main/features/admins/dashboard';
import {ClassModel, TeacherModel} from '../../../../types';
import {useTableDataState} from './use-table-data-state';
import {useClassesItems} from './use-classes-items';

export function useViewTableInfo({
	widgetData,
	allClassesList,
	allTeachersList,
	gradeLevels,
}: {
	widgetData: SchoolStudentsNeedingSupportMetricData | null;
	allClassesList: ClassModel[];
	allTeachersList: TeacherModel[];
	gradeLevels: GradeLevel[];
}) {
	const getClass = useCallback(
		(id: number | null) => {
			return allClassesList.find(({classID}) => id === classID);
		},
		[allClassesList],
	);

	const getClassName = useCallback(
		(id: number | null) => {
			const classModel = getClass(id);
			if (isUndefined(classModel)) {
				return '';
			}

			return classModel.className;
		},
		[getClass],
	);

	const getTeacherName = useCallback(
		(id: number) => {
			const classModel = getClass(id);
			if (isUndefined(classModel)) {
				return '';
			}

			const teacher = allTeachersList.find(({id}) => id === classModel.userID);
			if (isUndefined(teacher)) {
				return '';
			}

			return getFullName({
				firstName: teacher.firstName,
				lastName: teacher.lastName,
			});
		},
		[getClass, allTeachersList],
	);

	const getGradeShortName = useCallback(
		(gradeID: GradeLevel['id']) => gradeLevels.find(({id}) => id === gradeID)?.shortName ?? null,
		[gradeLevels],
	);

	const studentsOnePeriodItems = useStudentsOnePeriodItems({
		studentsData: widgetData?.studentsData ?? [],
		getClassName,
		getGradeShortName,
	});

	const studentsTwoPeriodsItems = useStudentsTwoPeriodsItems({
		studentsData: widgetData?.studentsData ?? [],
		getClassName,
		getGradeShortName,
	});

	const classesItems = useClassesItems({
		classesData: widgetData?.classesData ?? [],
		getClassName,
		getTeacherName,
	});

	const studentsOnePeriodInfo = useTableDataState({
		sortableCells: studentsOnePeriodSortableCells,
		initialSortableKey: 'avg',
		items: studentsOnePeriodItems,
		additionalSortDataKey: 'student',
	});

	const studentsTwoPeriodsInfo = useTableDataState({
		sortableCells: studentsTwoPeriodsSortableCells,
		initialSortableKey: 'avgSecond',
		items: studentsTwoPeriodsItems,
		additionalSortDataKey: 'student',
	});

	const classesInfo = useTableDataState({
		sortableCells: classSortableCells,
		initialSortableKey: 'result',
		items: classesItems,
		additionalSortDataKey: 'class',
	});

	return {
		studentsOnePeriodInfo,
		studentsTwoPeriodsInfo,
		classesInfo,
	};
}
