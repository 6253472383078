import React, {useCallback, useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {SubjectType} from '@esgi/main/libs/store';
import {TabID, PanelMode, Page} from '@esgi/main/features/admins/subject-management';
import {useSubjects} from './hooks/use-subjects';
import {SubjectsPanel} from './components/subjects-panel';
import {Subject} from '@esgi/main/libs/admin-store';
import {SubjectLevel} from '@esgi/main/kits/common';
import {Stock} from './variant/stock';
import {District} from './variant/district';

export default function () {
	const [selectedSubjectID, setSelectedSubjectID] = useState<number | null>(null);
	const [subjectsPanelMode, setSubjectsPanelMode] = useState(PanelMode.View);

	const [selectedSubjectFromStore, setSelectedSubjectFromStore] = useState<Subject | null>(null);

	const [districtSubjectEditTouched, setDistrictSubjectEditTouched] = useState(false);
	const [districtNewSubjectTouched, setDistrictNewSubjectTouched] = useState(false);

	const {
		isSubjectsLoaded,
		subjectsEntities,
		onRearrangeDistrictSubjects,
		getSubjectFromStoreByID,
		allDistrictSubjectsNames,
	} = useSubjects({
		setSelectedSubjectID,
	});

	useEffect(() => {
		setSelectedSubjectFromStore(getSubjectFromStoreByID(selectedSubjectID));
	}, [getSubjectFromStoreByID, selectedSubjectID]);

	const onSetSelectedSubjectID = useCallback((id: number | null) => {
		setSelectedSubjectID(id);
		setSubjectsPanelMode(PanelMode.View);
	}, []);

	const onActiveTabChanged = useCallback(
		(tabID: TabID | null) => {
			if (isNull(tabID)) {
				return;
			}

			if (tabID === TabID.District) {
				setSelectedSubjectID(subjectsEntities.districtSubjects[0]?.id ?? null);
				return;
			}

			if (tabID === TabID.Stock) {
				setSelectedSubjectID(subjectsEntities.stockSubjects[0]?.id ?? null);
				setSubjectsPanelMode(PanelMode.View);
				return;
			}

			setSelectedSubjectID(null);
		},
		[subjectsEntities],
	);

	const onSubjectsPanelModeChange = useCallback((mode: PanelMode) => {
		if (mode === PanelMode.Add) {
			setSelectedSubjectID(null);
		}

		setSubjectsPanelMode(mode);
	}, []);

	const onSubjectRemoved = useCallback(() => {
		onSetSelectedSubjectID(subjectsEntities.districtSubjects[0]?.id ?? null);
	}, [onSetSelectedSubjectID, subjectsEntities]);

	return (
		<Page gridTemplateColumns='272px 1fr 1fr'>
			<SubjectsPanel
				skeleton={!isSubjectsLoaded}
				entities={subjectsEntities}
				onActiveTabChanged={onActiveTabChanged}
				selectedSubjectID={selectedSubjectID}
				setSelectedSubjectID={onSetSelectedSubjectID}
				subjectsPanelMode={subjectsPanelMode}
				onSubjectsPanelModeChange={onSubjectsPanelModeChange}
				onRearrangeDistrictSubjects={onRearrangeDistrictSubjects}
				isSubjectHasUnsavedChanges={districtSubjectEditTouched || districtNewSubjectTouched}
			/>

			{(() => {
				if (subjectsPanelMode === PanelMode.Add) {
					return (
						<District
							type='add'
							allDistrictSubjectsNames={allDistrictSubjectsNames}
							onSubjectCreated={onSetSelectedSubjectID}
							onIsFormTouchedValueChanged={setDistrictNewSubjectTouched}
						/>
					);
				}

				if (!isNull(selectedSubjectFromStore) && selectedSubjectFromStore.level === SubjectLevel.District) {
					if (selectedSubjectFromStore.subjectType === SubjectType.Deployed) {
						return (
							<District
								type='edit'
								allDistrictSubjectsNames={allDistrictSubjectsNames}
								subjectFromStore={selectedSubjectFromStore}
								onSubjectRemoved={onSubjectRemoved}
								onIsFormTouchedValueChanged={setDistrictSubjectEditTouched}
							/>
						);
					}

					if (selectedSubjectFromStore.subjectType === SubjectType.Stock) {
						return <Stock subjectFromStore={selectedSubjectFromStore} />;
					}

					return null;
				}
			})()}
		</Page>
	);
}
