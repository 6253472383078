import {ComponentPropsWithoutRef, forwardRef, useCallback, useMemo} from 'react';
import {BarChartContext, BarChartContextValue} from '../context';
import {GridBox} from '@esgi/ui/layout';
import {Property} from '@stitches/react/types/css';

type Props = Omit<ComponentPropsWithoutRef<typeof GridBox>, 'skeleton'> & {
	chartWidth: Property.Width;
	minValue: number;
	maxValue: number;
};

export const Root = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'bar-chart-root', css = {}, minValue, maxValue, children, chartWidth, ...divProps}, forwardedRef) => {
		const getValueProgress = useCallback(
			(value: number) => {
				return (value - minValue) / (maxValue - minValue);
			},
			[maxValue, minValue],
		);

		const contextValue = useMemo<BarChartContextValue>(
			() => ({
				getValueProgress,
			}),
			[getValueProgress],
		);

		return (
			<BarChartContext.Provider value={contextValue}>
				<GridBox dataCy={dataCy} css={{...css, width: chartWidth}} ref={forwardedRef} align='center' {...divProps}>
					{children}
				</GridBox>
			</BarChartContext.Provider>
		);
	},
);
