export const numberArrayToStringArray = (array?: number[]): string[] => {
	if (!array?.length) {
		return [];
	}

	return array.map(item => String(item));
};

export const stringArrayToNumberArray = (array?: string[]): number[] => {
	if (!array?.length) {
		return [];
	}

	return array.map(item => Number(item));
};
