import {isNull} from 'underscore';
import {
	Candles,
	ChartCandleItem,
	GradeLevel,
	PerformanceWidgetViewType,
	PieChartItem,
	Widget,
	WidgetCard,
	WidgetPeriod,
} from '@esgi/main/features/admins/dashboard';
import {ClassModel, SubjectTab} from '../../../../../types';
import {WidgetDataModel} from '../../types';
import {CardBody} from './index.styled';
import {useVisibleItems} from './use-visible-items';
import {GridBox} from '@esgi/ui/layout';
import {CompareTwoClassesInfo, CompareTwoClassesInfoItem} from './components/compare-two-classes-info';
import {useMemo} from 'react';
import {PerformanceWidgetClass} from '../../../../types/widget';
import {SelectedSubjectsInfo} from '../../../../components/selected-subjects-info';
import {SelectedClassesInfo} from '../../../../components/selected-classes-info';

type Props = {
	viewType: PerformanceWidgetViewType;
	candles: Candles | null;
	averageValue: number;
	showAllItemsAverage: boolean;
	compareTwoClasses: PerformanceWidgetClass[];
	periods: WidgetPeriod[];
	classIDs: number[];
	allClassesList: ClassModel[];
	contentAreaIDs: number[];
	subjectIDs: number[];
	subjects: SubjectTab[];
	gradeLevelIDs: number[];
	gradeLevels: GradeLevel[];
};

export function ViewMode({
	viewType,
	candles,
	showAllItemsAverage,
	averageValue,
	compareTwoClasses,
	periods,
	subjects,
	allClassesList,
	contentAreaIDs,
	gradeLevelIDs,
	classIDs,
	subjectIDs,
	gradeLevels,
}: Props) {
	const {visibleData, widgetDataPageSize} = WidgetCard.useWidgetCardContext<WidgetDataModel>();

	const isCompareTwoClasses = compareTwoClasses.length === 2;

	const visibleItems = useVisibleItems({
		avgValue: averageValue,
		viewType,
		visibleWidgetData: visibleData,
		widgetChartPageSize: widgetDataPageSize,
		compareTwoClasses,
		periods,
	});

	const compareTwoClassesInfoItems = useMemo(
		() =>
			compareTwoClasses.map<CompareTwoClassesInfoItem>(({color, id}) => {
				const classModel = allClassesList.find(({classID}) => id === classID);

				return {
					color,
					id,
					label: classModel?.className ?? '',
				};
			}),
		[allClassesList, compareTwoClasses],
	);

	return (
		<CardBody>
			<GridBox align='center' justify='between' gap='3' flow='column'>
				<GridBox align='center' gap='2' flow='column'>
					{!isCompareTwoClasses && (
						<>
							<SelectedClassesInfo allClassesList={allClassesList} classIDs={classIDs} />
							<Widget.SelectedEntityInfoDivider />
						</>
					)}

					<SelectedSubjectsInfo subjectIDs={subjectIDs} subjects={subjects} itemsTranscript='Subject Tabs' />
					<Widget.SelectedEntityInfoDivider />

					<WidgetCard.ViewMode.SelectedContentAreasInfo contentAreaIDs={contentAreaIDs} />
					<Widget.SelectedEntityInfoDivider />

					<Widget.SelectedGradeLevelsInfo gradeLevelIDs={gradeLevelIDs} gradeLevels={gradeLevels} />
				</GridBox>

				<GridBox align='center' gap='2' flow='column'>
					<Widget.PeriodDatesInfo periodsData={periods} withLastDot={isCompareTwoClasses} />
					{isCompareTwoClasses && <CompareTwoClassesInfo data={compareTwoClassesInfoItems} />}
				</GridBox>
			</GridBox>

			{viewType === PerformanceWidgetViewType.Candles && (
				<WidgetCard.ViewMode.Chart.Candle
					items={visibleItems as (ChartCandleItem | null)[]}
					allItemsAverage={averageValue}
					showAllItemsAverage={showAllItemsAverage}
					highlight={
						isNull(candles)
							? null
							: {
									color: candles.color,
									value: candles.highlightIfAbove,
								}
					}
				/>
			)}

			{viewType === PerformanceWidgetViewType.Piechart && (
				<WidgetCard.ViewMode.Chart.PieChart
					items={visibleItems as (PieChartItem | null)[]}
					widgetChartPageSize={widgetDataPageSize}
					averageValue={averageValue}
					showAverageValue={showAllItemsAverage}
				/>
			)}
		</CardBody>
	);
}
