import {useCallback, useEffect, useState} from 'react';
import {useStreamEffect} from '@esgi/ui';
import {isDateTouched} from '@esgi/main/kits/admin';
import {BoxplotTwoSchoolsForm, BoxplotTwoSchoolsInitialFormData} from '../../../../forms-data/types';
import {ExcludedColors, ExcludedSchoolIDs} from './types';
import {DynamicFormProps} from '../../../../types';
import {InputsBox} from '../../../../index.styled';
import {useSchoolItems} from './use-school-items';
import {SchoolRow} from './components/school-row';
import {Color, DatePickerForm, SchoolModel, WidgetCard} from '@esgi/main/features/admins/dashboard';

type Props = DynamicFormProps<{
	twoSchoolsForm: BoxplotTwoSchoolsForm;
	allSchoolsList: SchoolModel[];
	initialBoxplotTwoSchoolsForm: BoxplotTwoSchoolsInitialFormData;
}>;

export function TwoSchools({
	twoSchoolsForm,
	allSchoolsList,
	initialBoxplotTwoSchoolsForm,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	onCurrentDynamicFormNameChange,
}: Props) {
	const [excludedColors, setExcludedColors] = useState<ExcludedColors>({
		firstSchoolColor: twoSchoolsForm.controls.firstSchoolColor.value[0] ?? null,
		secondSchoolColor: twoSchoolsForm.controls.secondSchoolColor.value[0] ?? null,
	});

	const [excludedSchoolIDs, setExcludedSchoolIDs] = useState<ExcludedSchoolIDs>({
		firstSchoolID: twoSchoolsForm.controls.firstSchoolID.value[0] ?? null,
		secondSchoolID: twoSchoolsForm.controls.secondSchoolID.value[0] ?? null,
	});

	const updateExcludedColors = useCallback(({key, newValue}: {key: keyof ExcludedColors; newValue: Color | null}) => {
		setExcludedColors((currentState) => ({
			...currentState,
			[key]: newValue,
		}));
	}, []);

	const updateExcludedSchoolID = useCallback(
		({key, newValue}: {key: keyof ExcludedSchoolIDs; newValue: string | null}) => {
			setExcludedSchoolIDs((currentState) => ({
				...currentState,
				[key]: newValue,
			}));
		},
		[],
	);

	const {firstSchoolItems, secondSchoolItems} = useSchoolItems({allSchoolsList, excludedSchoolIDs});

	useStreamEffect(twoSchoolsForm.controls.firstSchoolColor.onChanged, ({currState: {value}}) => {
		updateExcludedColors({
			key: 'firstSchoolColor',
			newValue: value[0] ?? null,
		});
	});

	useStreamEffect(twoSchoolsForm.controls.secondSchoolColor.onChanged, ({currState: {value}}) => {
		updateExcludedColors({
			key: 'secondSchoolColor',
			newValue: value[0] ?? null,
		});
	});

	useStreamEffect(twoSchoolsForm.controls.firstSchoolID.onChanged, ({currState: {value}}) => {
		updateExcludedSchoolID({
			key: 'firstSchoolID',
			newValue: value[0] ?? null,
		});
	});

	useStreamEffect(twoSchoolsForm.controls.secondSchoolID.onChanged, ({currState: {value}}) => {
		updateExcludedSchoolID({
			key: 'secondSchoolID',
			newValue: value[0] ?? null,
		});
	});

	const handleValidate = useCallback(() => {
		const {firstSchoolColor, secondSchoolColor, date, firstSchoolID, secondSchoolID} = twoSchoolsForm.value;

		const isPeriodTouched = isDateTouched(date, initialBoxplotTwoSchoolsForm.date);

		const isFirstSchoolTouched =
			firstSchoolColor[0] !== initialBoxplotTwoSchoolsForm.firstSchool?.color ||
			firstSchoolID[0] !==
				String(initialBoxplotTwoSchoolsForm.firstSchool ? initialBoxplotTwoSchoolsForm.firstSchool.schoolID : '');

		const isSecondSchoolTouched =
			secondSchoolColor[0] !== initialBoxplotTwoSchoolsForm.secondSchool?.color ||
			secondSchoolID[0] !==
				String(initialBoxplotTwoSchoolsForm.secondSchool ? initialBoxplotTwoSchoolsForm.secondSchool.schoolID : '');

		setIsDynamicFormDataTouched(isPeriodTouched || isFirstSchoolTouched || isSecondSchoolTouched);

		twoSchoolsForm.validate(true).subscribe(({valid}) => {
			setIsDynamicFormDataValid(valid);
		});
	}, [initialBoxplotTwoSchoolsForm, setIsDynamicFormDataTouched, setIsDynamicFormDataValid, twoSchoolsForm]);

	useEffect(() => {
		handleValidate();
		onCurrentDynamicFormNameChange('boxplotCompareTwoSchools');
	}, [handleValidate, onCurrentDynamicFormNameChange]);

	useStreamEffect(twoSchoolsForm.onChanged, handleValidate);

	return (
		<InputsBox>
			<WidgetCard.EditMode.FormElement control={twoSchoolsForm.controls.date}>
				<DatePickerForm form={twoSchoolsForm} />
			</WidgetCard.EditMode.FormElement>

			<SchoolRow
				colorControl={twoSchoolsForm.controls.firstSchoolColor}
				excludedColor={excludedColors.secondSchoolColor}
				placeholder='School A'
				schoolIDControl={twoSchoolsForm.controls.firstSchoolID}
				schoolItems={firstSchoolItems}
			/>

			<SchoolRow
				colorControl={twoSchoolsForm.controls.secondSchoolColor}
				excludedColor={excludedColors.firstSchoolColor}
				placeholder='School B'
				schoolIDControl={twoSchoolsForm.controls.secondSchoolID}
				schoolItems={secondSchoolItems}
			/>
		</InputsBox>
	);
}
