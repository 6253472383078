import {useMemo} from 'react';
import {WidgetDataModel} from '../../types';
import {PerformanceWidgetClass} from '../../../../types/widget';
import {
	ChartCandleItem,
	Color,
	PerformanceWidgetViewType,
	PieChartItem,
	WidgetPeriod,
} from '@esgi/main/features/admins/dashboard';

type Params = {
	widgetChartPageSize: number;
	visibleWidgetData: WidgetDataModel[];
	viewType: PerformanceWidgetViewType;
	avgValue: number;
	compareTwoClasses: PerformanceWidgetClass[];
	periods: WidgetPeriod[];
};

export function useVisibleItems({
	widgetChartPageSize,
	visibleWidgetData,
	viewType,
	avgValue,
	compareTwoClasses,
	periods,
}: Params) {
	return useMemo(() => {
		const visibleItems = visibleWidgetData;

		if (viewType === PerformanceWidgetViewType.Candles) {
			const filteredItems = visibleItems.map<ChartCandleItem>(({delta, id, data, label}) => ({
				id,
				title: label,
				delta,
				avgValue,
				data: data.map(({min, max, firstQuartile, median, thirdQuartile}, dataIndex) => ({
					chartMinValue: min,
					chartMaxValue: max,
					quartile1: firstQuartile,
					quartile2: median,
					quartile3: thirdQuartile,
					multipleCandlesColor: compareTwoClasses[dataIndex]?.color ?? periods[dataIndex]?.color ?? Color.None,
				})),
			}));

			const items: (ChartCandleItem | null)[] = [
				...filteredItems,
				...new Array(widgetChartPageSize - visibleItems.length).fill(null),
			];

			return items;
		}

		if (viewType === PerformanceWidgetViewType.Piechart) {
			const filteredItems = visibleItems.map<PieChartItem>(({label, data}) => ({
				title: label,
				avgValue: data[0].avg,
			}));

			const items: (PieChartItem | null)[] = [
				...filteredItems,
				...new Array(widgetChartPageSize - visibleItems.length).fill(null),
			];

			return items;
		}

		throw new Error('widgetModel: view type not correspond with PerformanceWidgetViewType');
	}, [avgValue, compareTwoClasses, periods, viewType, visibleWidgetData, widgetChartPageSize]);
}
