import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CheckboxesBox = styled(FlexBox, {
	gap: '$2',
	alignItems: 'center',
	paddingTop: 8,
	paddingBottom: 8,
	paddingLeft: 8,
	paddingRight: 6,
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$surface',
});
