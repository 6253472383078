import {useCallback, useState} from 'react';
import {SpecialistTabID, TabID} from '../../types';
import {StudentsTabContentProps} from '../../../components/students-tab-content';
import {isUndefined} from '@esgi/ui';
import {StudentsList} from '@esgi/ui/icons';
import {Student} from '@esgi/main/libs/admin-store';
import {DistrictSpecialistForm, SchoolSpecialistForm} from '../forms-data/types';

type Parameters = {
	studentsList: Student[];
	schoolSpecialistForm: SchoolSpecialistForm;
	checkSchoolSpecialistForm: VoidFunction;
	districtSpecialistForm: DistrictSpecialistForm;
	checkDistrictSpecialistForm: VoidFunction;
};

export function useTabs({
	studentsList,
	schoolSpecialistForm,
	checkSchoolSpecialistForm,
	districtSpecialistForm,
	checkDistrictSpecialistForm,
}: Parameters) {
	const [activeTab, setActiveTab] = useState(TabID.GroupInformation);
	const [specialistTabID, setSpecialistTabID] = useState(SpecialistTabID.District);
	const [studentsTabData, setStudentsTabData] = useState<Pick<
		StudentsTabContentProps,
		'allStudents' | 'studentsIDsControl' | 'errorMessage' | 'addStudentsAlertEntity'
	> | null>(null);

	const onTabIDChange = useCallback(
		(value: TabID) => {
			if (value === TabID.Students) {
				if (specialistTabID === SpecialistTabID.District) {
					setStudentsTabData({
						allStudents: studentsList,
						studentsIDsControl: districtSpecialistForm.controls.studentsIDs,
						addStudentsAlertEntity: {
							name: 'New District Specialist Group',
							Icon: StudentsList,
						},
						errorMessage: isUndefined(districtSpecialistForm.value.districtSpecialistID[0])
							? 'You have to assign the district specialist to add students'
							: null,
					});
				}

				if (specialistTabID === SpecialistTabID.School) {
					const selectedSchoolID = schoolSpecialistForm.value.schoolID[0];
					const schoolSpecialistID = schoolSpecialistForm.value.schoolSpecialistID[0];

					setStudentsTabData({
						allStudents: studentsList.filter(({schoolID}) => schoolID === Number(selectedSchoolID)),
						studentsIDsControl: schoolSpecialistForm.controls.studentsIDs,
						addStudentsAlertEntity: {
							name: 'New School Specialist Group',
							Icon: StudentsList,
						},
						errorMessage:
							isUndefined(selectedSchoolID) || isUndefined(schoolSpecialistID)
								? 'You have to select the school and assign the school specialist to add students'
								: null,
					});
				}

				setActiveTab(TabID.Students);
				return;
			}

			setStudentsTabData(null);
			setActiveTab(value);
		},
		[districtSpecialistForm, schoolSpecialistForm, specialistTabID, studentsList],
	);

	const onSpecialistTabIDChange = useCallback(
		(value: SpecialistTabID) => {
			setSpecialistTabID(value);

			if (value === SpecialistTabID.District) {
				checkDistrictSpecialistForm();
			}

			if (value === SpecialistTabID.School) {
				checkSchoolSpecialistForm();
			}
		},
		[checkDistrictSpecialistForm, checkSchoolSpecialistForm],
	);

	return {
		activeTab,
		onTabIDChange,
		specialistTabID,
		onSpecialistTabIDChange,
		studentsTabData,
	};
}
