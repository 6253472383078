import {SubjectsDistrictController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {GradeLevel, ItemNumerable, TrackDate} from '@esgi/main/features/admins/subject-management';
import {getFullName} from '@esgi/main/kits/common';
import {useSchools, useDistrictSpecialists, useSchoolSpecialists} from '@esgi/main/libs/admin-store';
import {isAsyncIdle, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useEffect, useMemo, useState} from 'react';

export function useInitData() {
	const [{data: schoolsData, loaded: isSchoolsLoaded}] = useSchools();
	const [{data: districtSpecialistsStore, loaded: isDistrictSpecialistsStoreLoaded}] = useDistrictSpecialists();
	const [{data: schoolSpecialists, loaded: isSchoolSpecialistsLoaded}] = useSchoolSpecialists();

	const controller = useDisposable(SubjectsDistrictController);

	const [initData, init] = useCancelableRequest(controller.init);

	const [trackID, setTrackID] = useState<number | null>(null);
	const [allTrackDates, setAllTrackDates] = useState<TrackDate[]>([]);
	const [gradeLevels, setGradeLevels] = useState<GradeLevel[]>([]);

	const [schoolSpecialistsSchools, setSchoolSpecialistsSchools] = useState<ItemNumerable[]>([]);

	useEffect(() => {
		if (isAsyncIdle(initData)) {
			init();
			return;
		}

		if (isAsyncSucceed(initData)) {
			const {gradeLevels, trackDates, tracks, schoolSpecialistsSchools} = initData.data;

			setTrackID(tracks[0]?.id ?? null);

			setAllTrackDates(
				trackDates.map(({trackID, trackDateID, dateFrom, dateTo}) => ({trackID, trackDateID, dateFrom, dateTo})),
			);

			setGradeLevels(gradeLevels.map(({gradeLevelID, name, shortName}) => ({id: gradeLevelID, name, shortName})));
			setSchoolSpecialistsSchools(schoolSpecialistsSchools.map(({id, name}) => ({id, name})));
		}
	}, [init, initData]);

	const districtSpecialists = useMemo<ItemNumerable[]>(
		() => districtSpecialistsStore.map(({id, firstName, lastName}) => ({id, name: getFullName({firstName, lastName})})),
		[districtSpecialistsStore],
	);

	const teachersSchools = useMemo<ItemNumerable[]>(() => schoolsData.map(({id, name}) => ({id, name})), [schoolsData]);

	return {
		trackID,
		allTrackDates,
		gradeLevels,
		teachersSchools,
		schoolSpecialistsSchools,
		districtSpecialists,
		schoolSpecialists,
		isDataLoaded:
			isAsyncSucceed(initData) && isSchoolsLoaded && isDistrictSpecialistsStoreLoaded && isSchoolSpecialistsLoaded,
	};
}
