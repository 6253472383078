import {ChangeEvent, useCallback, useMemo, useState} from 'react';
import {useEditableCommonState} from '../../../hooks/use-editable-common-state';
import {TestInfo, TestSession} from '../../../../../../../types/view-edit-session-details';

export function useEditModeState({
	sessionInfo,
	testInfo,
	onUpdateTestSession,
}: {
	sessionInfo: TestSession;
	testInfo: TestInfo;
	onUpdateTestSession: (args: {score: number; notes: string; testDateTouched: boolean; testDate: string}) => void;
}) {
	const {
		dateTimeRef,
		setIsSessionTimeInfoValid,
		setSessionTimeInfoTouched,
		sessionNote,
		onUpdateSessionNote,
		isCommonDataTouched,
		isCommonDataValid,
		sessionTimeInfoTouched,
	} = useEditableCommonState({
		sessionInfo,
	});

	const {defaultScore} = useMemo(
		() => ({
			defaultScore: sessionInfo.correctAnswers,
		}),
		[sessionInfo],
	);

	const [scoreValue, setScoreValue] = useState(String(defaultScore));
	const [scoreError, setScoreError] = useState<string>();

	const handleUpdateScoreValue = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;

			const onlyNumberRegex = /^\d+$/;

			if (value === '' || onlyNumberRegex.test(value)) {
				setScoreValue(value);

				if (value === '') {
					setScoreError('Score required');
					return;
				}

				const numberedValue = Number(value);

				if (numberedValue > testInfo.totalPossible) {
					setScoreError(`Score out of range 0-${testInfo.totalPossible}`);
					return;
				}

				setScoreError(undefined);
			}
		},
		[testInfo.totalPossible],
	);

	const handleSave = useCallback(() => {
		const dateTime = dateTimeRef.current?.getFullDate();

		onUpdateTestSession({
			testDateTouched: sessionTimeInfoTouched,
			testDate: dateTime ?? sessionInfo.testDate,
			notes: sessionNote,
			score: Number(scoreValue),
		});
	}, [dateTimeRef, onUpdateTestSession, scoreValue, sessionInfo.testDate, sessionNote, sessionTimeInfoTouched]);

	const isDataTouched = scoreValue !== String(defaultScore) || isCommonDataTouched;

	const isDataValid = isCommonDataValid && !scoreError;

	return {
		isDataTouched,
		setSessionTimeInfoTouched,
		setIsSessionTimeInfoValid,
		dateTimeRef,
		scoreError,
		scoreValue,
		handleUpdateScoreValue,
		sessionNote,
		onUpdateSessionNote,
		isDataValid,
		handleSave,
	};
}
