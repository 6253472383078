import {GridBox} from '@esgi/ui/layout';
import {usePanelContext} from '../../context';
import {TestInfoLine} from './test-info-item';
import {listGroupCSS, listItemCSS} from './list.styled';
import {TestPanelTestModel} from '../../types/tests';
import {Dispatch, useCallback, useState} from 'react';
import {Button, isUndefined} from '@esgi/ui';
import {Close} from '@esgi/ui/icons';
import {TestType} from '@esgi/main/kits/common';
import {isNull} from 'underscore';
import {useUser} from '@esgi/core/authentication';
import {lazyComponent} from '@esgi/core/react';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));
const RubricDetails = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

type Props = {
	onTestDeleteClick?: Dispatch<TestPanelTestModel['id']> | undefined;
};

export type TestDetailsState = {
	id: TestPanelTestModel['id'];
	type: TestType;
};

export function Default({onTestDeleteClick}: Props) {
	const currentUser = useUser();
	const {tests} = usePanelContext();

	const [testDetailsState, setTestDetailsState] = useState<TestDetailsState | null>(null);

	const closeTestDetails = useCallback(() => {
		setTestDetailsState(null);
	}, []);

	return (
		<>
			<GridBox css={listGroupCSS}>
				{tests.map(({id, name, type, color, contentArea}) => (
					<GridBox key={id} css={listItemCSS}>
						<TestInfoLine
							testColor={color}
							testContentArea={contentArea}
							testName={name}
							testType={type}
							onClick={() => setTestDetailsState({id, type})}
						/>

						{!isUndefined(onTestDeleteClick) && (
							<Button.Icon withBackgroundHover onClick={() => onTestDeleteClick(id)}>
								<Close />
							</Button.Icon>
						)}
					</GridBox>
				))}
			</GridBox>

			{!isNull(testDetailsState) &&
				!isNull(currentUser) &&
				(testDetailsState.type === TestType.Score || testDetailsState.type === TestType.YN) && (
					<TestDetails
						testID={testDetailsState.id}
						firstName={currentUser.firstName}
						lastName={currentUser.lastName}
						userID={currentUser.userID}
						close={closeTestDetails}
					/>
				)}

			{!isNull(testDetailsState) && testDetailsState.type === TestType.Rubric && (
				<RubricDetails testID={testDetailsState.id} close={closeTestDetails} disableAddToSubject />
			)}
		</>
	);
}
