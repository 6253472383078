import {JSX, type SVGProps, useCallback} from 'react';
import {Header, Wrapper, Bottom} from './report-button.styled';
import {Text} from '@esgi/ui/typography';
import {SkeletonVariant} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

const SkeletonText = styled(Text, {
	variants: {
		...SkeletonVariant,
	},
});

type ReportButtonProps = {
	onClick?: VoidFunction;
	icon: (props: SVGProps<SVGSVGElement>) => JSX.Element,
	name: string,
	description: string,
	skeleton?: boolean,
};

export function ReportButton(props: ReportButtonProps) {
	const onClick = useCallback(() => {
		if(!props.skeleton) {
			props.onClick();
		}
	}, [props.onClick, props.skeleton]);

	return <Wrapper onClick={onClick}>
		<Header>
			{props.icon({height: 24, width: 24})}
			<SkeletonText size='large' bold color='base' skeleton={props.skeleton}>
				{props.name}
			</SkeletonText>
		</Header>
		<Bottom>
			<SkeletonText size='small' color='mediumContrast' skeleton={props.skeleton}>
				{props.description}
			</SkeletonText>
		</Bottom>
	</Wrapper>;
}