import {RowDef} from '../../types';
import {DistrictReportPanel, Page} from '../../../_kit';
import {BottomPanel} from './components/bottom-panel';
import {SingleItemExpandablePanel} from './components/single-item-expandable-panel';
import {useDataPageContext} from '../../../layout/context';

type Props = {
	onOpenAddDrawer: VoidFunction;
};

export function BaseContent({onOpenAddDrawer}: Props) {
	const {api} = Page.useContext<RowDef>();
	const {districtName} = useDataPageContext();

	return (
		<>
			<Page.Header.Base onAddButtonClick={onOpenAddDrawer} />
			<Page.Body.Base />

			<DistrictReportPanel api={api} title={districtName} />
			<SingleItemExpandablePanel />
			<BottomPanel />
		</>
	);
}
