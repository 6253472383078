import {PieChart} from '@esgi/ui';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const piechartsRowGap = 20;
export const piechartsColumnGap = 32;

const itemsGridPosition: CSS = {
	gridRowStart: 1,
	gridColumnStart: 1,
};

export const ChartContainer = styled(GridBox, {
	alignItems: 'center',
	rowGap: piechartsRowGap,
	columnGap: piechartsColumnGap,
	overflow: 'hidden',
	position: 'relative',
	textAlign: 'center',
	gridAutoRows: '1fr',
});

export const ChartOverlayBox = styled(FlexBox, {
	position: 'absolute',
	width: '100%',
	height: '100%',
	zIndex: -1,
});

export const ChartDataBox = styled(GridBox, {
	gap: '$2',
	justifyItems: 'center',
	gridTemplateRows: 'auto 24px',
});

export const ChartsInfoBox = styled(GridBox, {
	justifyItems: 'center',
	alignItems: 'center',
});

export const MainChartRoot = styled(PieChart, itemsGridPosition);
export const AverageChartRoot = styled(PieChart, itemsGridPosition);

export const ChartResultsBox = styled(GridBox, itemsGridPosition, {
	justifyItems: 'center',
	gap: 6,
});

export const AverageValueBox = styled(FlexBox, {
	gap: '$1',
	color: '$primaryVivid',
});

export const AverageSymbol = styled(Box, {
	display: 'inline-flex',
	position: 'relative',

	'&::after': {
		content: '',
		display: 'inline-flex',
		position: 'absolute',
		width: 3,
		height: 1,
		backgroundColor: 'currentColor',
		top: 2,
		right: -1.5,
	},
});

export const ChartValueBox = styled(FlexBox, {
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,
	borderRadius: 2,
});
