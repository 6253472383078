import {Button, IconComponent} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback} from 'react';
import {useSingleSelectedExpandablePanelContext} from '../context';
import {Text} from '@esgi/ui/typography';

type Props = ComponentPropsWithoutRef<typeof Button> & {
	Icon: IconComponent;
	transcript: string;
};

export const OpenPanelButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			dataCy = 'single-selected-expandable-panel-open-panel-button',
			css = {},
			color = 'secondary',
			onClick,
			Icon,
			transcript,
			...props
		},
		forwardedRef,
	) => {
		const {expandPanel} = useSingleSelectedExpandablePanelContext();

		const handleClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				expandPanel();
				onClick?.(event);
			},
			[onClick, expandPanel],
		);

		return (
			<Button dataCy={dataCy} css={css} color={color} onClick={handleClick} ref={forwardedRef} {...props}>
				<Icon />
				<Text size='medium' bold color='base'>
					{transcript}
				</Text>
			</Button>
		);
	},
);
