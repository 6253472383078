import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';
import {useTimestampContext} from '../../context';
import {BaseComponentProps} from '@esgi/ui';
import {Container} from './index.styled';

type Props = BaseComponentProps & Omit<VariantProps<typeof Text>, 'children'>;

export function TimestampText({dataCy = 'admin-timestamp-text', css = {}, ...textProps}: Props) {
	const {time, meridiem} = useTimestampContext();

	return (
		<Container dataCy={dataCy} css={css}>
			<Text {...textProps} data-time-text=''>
				{' '}
				{time}
			</Text>
			<Text {...textProps} data-meridiem-text=''>
				{meridiem}
			</Text>
		</Container>
	);
}
