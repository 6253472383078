import {ComponentProps} from 'react';
import {Button} from '@esgi/ui';
import {FlexBox} from '@esgi/ui/layout';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Label, ButtonLabel} from '../../index.styled';

interface Props {
	onDelete: VoidFunction;
	onCancel: VoidFunction;
	portalProps?: ComponentProps<typeof Alert>['portalProps']
}

export function DeleteStudentAlert({onDelete, onCancel, portalProps}: Props) {
	const alertRef = Alert.useRef();
	const cancelClicked = Alert.useClose(alertRef, onCancel);

	return (
		<Alert portalProps={portalProps} colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
			<Alert.Header withBacklight withCloseIcon={false} onCloseIconClick={onCancel}>
				<Label size='large'>Delete Student?</Label>
			</Alert.Header>
			<Alert.Body>
				<FlexBox direction='column'>
					<Label size='medium' color='neutral24'>
						Are you sure you wish to delete the following student from the system?
					</Label>
					<Label size='medium' color='neutral24'>Doing so will also remove all associated data which is not recoverable.</Label>
				</FlexBox>
			</Alert.Body>
			<Alert.Footer align='center' gapX={3}>
				<Button color='tertiary' onClick={cancelClicked}>
					<ButtonLabel>Cancel</ButtonLabel>
				</Button>
				<Button onClick={onDelete} color='secondary'>
					<ButtonLabel>Delete</ButtonLabel>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
