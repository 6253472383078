import {ChartCandle, ChartPie, SizeDefault, SizeFull} from '@esgi/ui/icons';
import {
	PerformanceWidgetViewType,
	ToggleGroupItemModel,
	WidgetViewSize,
	WidgetFormSelectItem,
	DemographicGroupType,
} from '@esgi/main/features/admins/dashboard';

export const viewTypeItems: ToggleGroupItemModel<PerformanceWidgetViewType>[] = [
	{
		value: PerformanceWidgetViewType.Candles,
		label: 'Boxplots',
		Icon: ChartCandle,
	},
	{
		value: PerformanceWidgetViewType.Piechart,
		label: 'Pie Charts',
		Icon: ChartPie,
	},
];

export const sizeItems: ToggleGroupItemModel<WidgetViewSize>[] = [
	{
		value: WidgetViewSize.Default,
		label: 'Default',
		Icon: SizeDefault,
	},
	{
		value: WidgetViewSize.FullSize,
		label: 'Full Size',
		Icon: SizeFull,
	},
];

export const demographicGroups: WidgetFormSelectItem<DemographicGroupType>[] = [
	{
		value: DemographicGroupType.LunchStatus,
		label: 'Lunch Status',
	},
	{
		value: DemographicGroupType.SpecialProgram,
		label: 'Special Programs',
	},
	{
		value: DemographicGroupType.RaceEthnicity,
		label: 'Race & Ethnicity',
	},
];
