import {CollapsiblePanel} from '@esgi/main/features/admins/data';
import {ComponentPropsWithoutRef, forwardRef} from 'react';

type Props = ComponentPropsWithoutRef<typeof CollapsiblePanel.Header>;

export const Header = forwardRef<HTMLDivElement, Props>(
	({headerBgColor = 'primaryBackground', ...props}, forwardedRef) => (
		<CollapsiblePanel.Header headerBgColor={headerBgColor} ref={forwardedRef} {...props} />
	),
);
