import {isUndefined} from '@esgi/ui';
import moment from 'moment';
import {dateFormat} from '../constants';
import {DatePickerData} from '../types';

function isTwoDatesEqual({date1, date2}: {date1: Date | string | undefined; date2: Date | string | undefined}) {
	if (isUndefined(date1) || isUndefined(date2)) {
		return date1 === date2;
	}

	const date1Format = moment(date1).format(dateFormat);
	const date2Format = moment(date2).format(dateFormat);

	return moment(date1Format).diff(moment(date2Format), 'days') === 0;
}

export function isDateTouched(currentDate: DatePickerData, initialDate: DatePickerData | null) {
	return (
		!isTwoDatesEqual({date1: currentDate.dateRange?.dateFrom, date2: initialDate?.dateRange?.dateFrom}) ||
		!isTwoDatesEqual({date1: currentDate.dateRange?.dateTo, date2: initialDate?.dateRange?.dateTo}) ||
		currentDate.trackDatesID !== initialDate?.trackDatesID ||
		currentDate.datePeriod !== initialDate?.datePeriod
	);
}
