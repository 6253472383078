import {Fragment} from 'react';
import {OneLinedText} from '@esgi/main/kits/common';
import {PerformanceWidgetClass} from '../../../../../../types/widget';
import {Dot, ClassesBox} from './index.styled';
import {Color, WidgetCard} from '@esgi/main/features/admins/dashboard';

export type CompareTwoClassesInfoItem = PerformanceWidgetClass & {
	label: string;
};

type Props = {
	data: CompareTwoClassesInfoItem[];
};

export function CompareTwoClassesInfo({data}: Props) {
	return (
		<ClassesBox>
			{data.map(({id, color, label}, index, iteratedData) => (
				<Fragment key={id}>
					<OneLinedText
						size='small'
						color={color === Color.None ? 'highContrast' : WidgetCard.chartColor[color].activeQuartileBarColor}
					>
						{label}
					</OneLinedText>

					{iteratedData[index + 1] && <Dot />}
				</Fragment>
			))}
		</ClassesBox>
	);
}
