import {Edit, PieChartIcon, StudentsList} from '@esgi/ui/icons';
import {
	ActionPanel,
	DeleteButton,
	Page,
	RunReportButton,
	SingleSelectedExpandablePanel,
	useEntityDeletionState,
} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button, isUndefined, Portal, useFlag} from '@esgi/ui';
import {
	ClassDrawer,
	EntityType,
	RemoveEntity,
	useNavigateToGroupsPage,
	useNavigateToStudentsPage,
	WarningAlert,
} from '@esgi/main/features/school-admin/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {useDataPageContext} from '../../../../../../../layout/context';
import {ClassesReportsDialog} from '../reports-dialog';
import {useCallback} from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {useRemoveClassInStore} from '@esgi/main/features/school-admin/utils';
import {RefreshDataPanelEvent} from '../../../../../../../layout/events';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
};

export function QuickPanel({selectedRow, isPanelExpanded}: Props) {
	const {tableRows} = Page.useContext<RowDef>();
	const {rootPageContentContainerRef} = useDataPageContext();

	const [showReports, openReports, closeReports] = useFlag();
	const [showEdit, openEdit, closeEdit] = useFlag();

	const {
		entityIDs,
		showRemoveEntity,
		showRemoveEntityUnavailable,
		onOpenRemoveEntity,
		onCloseRemoveEntity,
		onOpenRemoveEntityUnavailable,
		onCloseRemoveEntityUnavailable,
	} = useEntityDeletionState({
		selectedItems: [selectedRow],
	});

	const goToGroupsPage = useNavigateToGroupsPage();
	const goToStudentsPage = useNavigateToStudentsPage();

	const removeClassInStore = useRemoveClassInStore();

	const navigateToGroups = useCallback(() => {
		goToGroupsPage({
			class: [selectedRow.id],
		});
	}, [goToGroupsPage, selectedRow]);

	const navigateToStudents = useCallback(() => {
		goToStudentsPage({
			class: [selectedRow.id],
		});
	}, [goToStudentsPage, selectedRow]);

	const handleOpenRemoveEntity = useCallback(() => {
		const teacherOfSelectedClassID = selectedRow.teacher.id;

		const allTeachersClasses = tableRows.filter(({teacher}) => teacherOfSelectedClassID === teacher.id);

		if (allTeachersClasses.length > 1) {
			onOpenRemoveEntity();
			return;
		}

		onOpenRemoveEntityUnavailable();
	}, [onOpenRemoveEntity, onOpenRemoveEntityUnavailable, selectedRow, tableRows]);

	const handleEntityRemove = useCallback(
		(entityIDs: number[]) => {
			if (entityIDs.length >= 2) {
				throw new Error('entityIDs.length >= 2');
			}

			const removedClassID = entityIDs[0];

			if (!isUndefined(removedClassID)) {
				removeClassInStore(removedClassID, () => {
					dispatchAppEvent(RefreshDataPanelEvent, new RefreshDataPanelEvent());
				});
			}

			onCloseRemoveEntity();
		},
		[onCloseRemoveEntity, removeClassInStore],
	);

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<StudentsList />
							<Text size='large' color='highContrast'>
								{selectedRow.name}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteButton onClick={handleOpenRemoveEntity} />
							<ActionPanel.ActionButtonDivider />

							<Button.Icon onClick={openEdit}>
								<Edit />
							</Button.Icon>

							<RunReportButton
								onClick={openReports}
								disabled={!selectedRow.students}
								disabledMessage={
									selectedRow.students
										? undefined
										: 'No students have been added to your class. Please add some before running the report.'
								}
							/>
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<ActionPanel.Navigation.Container>
							<ActionPanel.Navigation.Card
								onClick={navigateToGroups}
								title='Groups'
								count={selectedRow.groups.length}
							/>
							<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
						</ActionPanel.Navigation.Container>

						<SingleSelectedExpandablePanel.OpenPanelButton
							Icon={PieChartIcon}
							transcript='View Tests Results'
							warningDescription='No students have been added to your class. Please add some before running the report.'
							canExpandPanel={!!selectedRow.students}
						/>
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showReports && <ClassesReportsDialog onClose={closeReports} selectedClass={selectedRow} />}

			{showEdit && (
				<Portal container={rootPageContentContainerRef.current}>
					<ClassDrawer.Edit
						onClose={closeEdit}
						activeClassID={selectedRow.id}
						primaryTeacherID={selectedRow.teacher.id}
					/>
				</Portal>
			)}

			{showRemoveEntityUnavailable && (
				<WarningAlert
					description='Sorry, you must have at least one class in each school year.'
					onClose={onCloseRemoveEntityUnavailable}
				/>
			)}

			{showRemoveEntity && (
				<RemoveEntity
					entityIDs={entityIDs}
					entityType={EntityType.Class}
					onEntityRemoved={handleEntityRemove}
					onClose={onCloseRemoveEntity}
				/>
			)}
		</>
	);
}
