import {ComponentProps, useMemo, useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {Tooltip} from '@esgi/ui/tooltip';
import {TooltipContent, TooltipContentText, TriggerText} from './styled';
import {Text} from '@esgi/ui/typography';
import {Property} from '@stitches/react/types/css';

type TextSize = ComponentProps<typeof Text>['size'];

type Props = Omit<ComponentProps<typeof Text>, 'size'> & {
	maxLines: number;
	size: NonNullable<TextSize>;
};

export function MultiLinedText({css = {}, maxLines, size, ...textProps}: Props) {
	const [isTextTruncated, setIsTextTruncated] = useState(false);

	const textRef = useRef<HTMLElement>(null);

	useResizeObserver(textRef, ({target: {scrollWidth, clientWidth, scrollHeight, clientHeight}}) => {
		setIsTextTruncated(clientWidth < scrollWidth || clientHeight < scrollHeight);
	});

	const adaptedTextLineHeight = useMemo<Property.LineHeight | undefined>(() => {
		if (size === 'xSmall') {
			return '13px';
		}

		return;
	}, [size]);

	return (
		<Tooltip open={!isTextTruncated ? false : undefined}>
			<Tooltip.Trigger>
				<TriggerText
					ref={textRef}
					css={{
						...css,
						lineHeight: adaptedTextLineHeight,
						'-webkit-line-clamp': maxLines,
					}}
					size={size}
					{...textProps}
				/>
			</Tooltip.Trigger>
			<TooltipContent>
				<TooltipContentText size='large'>{textProps.children}</TooltipContentText>
			</TooltipContent>
		</Tooltip>
	);
}
