import {useMemo, useState} from 'react';
import {getFullName, SubjectLevel} from '@esgi/main/kits/common';
import {GradeLevel, SelectableListItemModel, WidgetCard} from '@esgi/main/features/admins/dashboard';
import {ClassModel, SubjectTab, TeacherModel} from '../../../../../../../types';

export function useDynamicAdaptedItems({
	classes,
	teachers,
	subjects,
	gradeLevels,
}: {
	classes: ClassModel[];
	teachers: TeacherModel[];
	subjects: SubjectTab[];
	gradeLevels: GradeLevel[];
}) {
	const {allContentAreas} = WidgetCard.useWidgetCardContext();

	const [selectedTeacherID, setSelectedTeacherID] = useState(String(teachers[0]?.id));
	const [selectedSubjectLevel, setSelectedSubjectLevel] = useState(SubjectLevel.School);

	const classesAdaptedItems = useMemo(
		() =>
			classes.map<SelectableListItemModel<string>>(({classID, className}) => ({
				value: String(classID),
				label: className,
			})),
		[classes],
	);

	const classesFilteredAdaptedItems = useMemo(
		() =>
			classes
				.filter((item) => String(item.userID) === selectedTeacherID)
				.map<SelectableListItemModel<string>>(({classID, className}) => ({
					value: String(classID),
					label: className,
				})),
		[classes, selectedTeacherID],
	);

	const teachersAdaptedItems = useMemo(
		() =>
			teachers.map<SelectableListItemModel<string>>(({id, firstName, lastName}) => ({
				value: String(id),
				label: getFullName({firstName, lastName}),
			})),
		[teachers],
	);

	const subjectsAdaptedItems = useMemo(
		() =>
			subjects.map<SelectableListItemModel<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[subjects],
	);

	const subjectsFilteredAdaptedItems = useMemo(
		() =>
			subjects
				.filter((item) => item.level === selectedSubjectLevel)
				.map<SelectableListItemModel<string>>(({id, name}) => ({
					value: String(id),
					label: name,
				})),
		[subjects, selectedSubjectLevel],
	);

	const gradeLevelsAdaptedItems = useMemo(
		() =>
			gradeLevels.map<SelectableListItemModel<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[gradeLevels],
	);

	const contentAreasAdaptedItems = useMemo(
		() =>
			allContentAreas.map<SelectableListItemModel<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[allContentAreas],
	);

	return {
		selectedTeacherID,
		setSelectedTeacherID,
		selectedSubjectLevel,
		setSelectedSubjectLevel,
		classesAdaptedItems,
		classesFilteredAdaptedItems,
		teachersAdaptedItems,
		subjectsAdaptedItems,
		subjectsFilteredAdaptedItems,
		gradeLevelsAdaptedItems,
		contentAreasAdaptedItems,
	};
}
