import {Dispatch, useMemo, useRef, useState} from 'react';
import {FlexBox} from '@esgi/ui/layout';
import {isUndefined, useStreamEffect} from '@esgi/ui';
import {FormControl, FormGroup, useForm} from '@esgi/ui/form';
import {
	SchoolPerformanceWidgetOptions,
	SchoolPerformanceWidgetUpdatedValue,
} from '../../../../types/school-performance';
import {
	CheckboxFormElement,
	CheckboxesBox,
	GradeLevel,
	OnUpdateWidget,
	PerformanceWidgetViewType,
	WidgetCard,
} from '@esgi/main/features/admins/dashboard';
import {TeacherModel, SubjectTab} from '../../../../../types';
import {AdditionalSettingBox, CardBody, CardBodyDivider} from './index.styled';
import {CandleChartData} from './components/candle-chart-data';
import {PiechartData} from './components/piechart-data';
import {MainSettings} from './components/main-settings';
import {Widget} from '../../../../types/widget';
import {DynamicFormDataRef} from './types';

type Props = {
	subjects: SubjectTab[];
	allTeachersList: TeacherModel[];
	onDeleteWidgetByID: Dispatch<Widget['id']>;
	widgetOptions: SchoolPerformanceWidgetOptions;
	onUpdateWidget: Dispatch<SchoolPerformanceWidgetUpdatedValue>;
	gradeLevels: GradeLevel[];
};

export function EditMode({
	subjects,
	allTeachersList,
	onDeleteWidgetByID,
	widgetOptions,
	onUpdateWidget,
	gradeLevels,
}: Props) {
	const {allContentAreas} = WidgetCard.useWidgetCardContext();

	const [widgetViewType, setWidgetViewType] = useState(widgetOptions.viewType);

	const [selectedContentArea, setSelectedContentArea] = useState(widgetOptions.contentAreaIDs.map(String));
	const [selectedSubjectIDs, setSelectedSubjectIDs] = useState(widgetOptions.subjectIDs.map(String));
	const [selectedGradeLevels, setSelectedGradeLevels] = useState(widgetOptions.gradeLevelIDs.map(String));
	const [selectedTeachersIDs, setSelectedTeachersIDs] = useState(widgetOptions.teachersIDs.map(String));

	const [isCommonFormTouched, setIsCommonFormTouched] = useState(false);
	const [isCommonFormValid, setIsCommonFormValid] = useState(true);

	const [isDynamicFormDataTouched, setIsDynamicFormDataTouched] = useState(false);
	const [isDynamicFormDataValid, setIsDynamicFormDataValid] = useState(true);

	const dynamicFormDataRef = useRef<DynamicFormDataRef | undefined>();

	const commonForm = useForm(
		() =>
			new FormGroup({
				viewType: new FormControl(widgetOptions.viewType),
				size: new FormControl(widgetOptions.viewSize),
				isShowAverageValue: new FormControl(widgetOptions.showAllTeachersAvg),
			}),
	);

	useStreamEffect(commonForm.controls.viewType.onChanged, ({currState: {value}}) => {
		setWidgetViewType(value);
	});

	useStreamEffect(
		commonForm.onChanged,
		({
			currState: {
				value: {isShowAverageValue, size, viewType},
			},
		}) => {
			const isFormTouched =
				isShowAverageValue !== widgetOptions.showAllTeachersAvg ||
				size !== widgetOptions.viewSize ||
				viewType !== widgetOptions.viewType;

			setIsCommonFormTouched(isFormTouched);

			commonForm.validate(true).subscribe(({valid}) => {
				setIsCommonFormValid(valid);
			});
		},
	);

	const isSelectedEntityTouched = useMemo(() => {
		const contentAreaTouched =
			selectedContentArea.length !== widgetOptions.contentAreaIDs.length ||
			selectedContentArea.some((item) => !widgetOptions.contentAreaIDs.includes(Number(item)));

		const subjectsTouched =
			selectedSubjectIDs.length !== widgetOptions.subjectIDs.length ||
			selectedSubjectIDs.some((item) => !widgetOptions.subjectIDs.includes(Number(item)));

		const gradeLevelsTouched =
			selectedGradeLevels.length !== widgetOptions.gradeLevelIDs.length ||
			selectedGradeLevels.some((item) => !widgetOptions.gradeLevelIDs.includes(Number(item)));

		const teachersTouched =
			selectedTeachersIDs.length !== widgetOptions.teachersIDs.length ||
			selectedTeachersIDs.some((item) => !widgetOptions.teachersIDs.includes(Number(item)));

		return contentAreaTouched || subjectsTouched || gradeLevelsTouched || teachersTouched;
	}, [selectedContentArea, selectedGradeLevels, selectedTeachersIDs, selectedSubjectIDs, widgetOptions]);

	const isFormTouched = isCommonFormTouched || isSelectedEntityTouched || isDynamicFormDataTouched;
	const isFormValid = isCommonFormValid && isDynamicFormDataValid;

	const onSaveWidgetData: OnUpdateWidget = ({id, name}) => {
		const dynamicData = dynamicFormDataRef.current?.getData();

		if (!isUndefined(dynamicData)) {
			const {periods, candles} = dynamicData;

			const contentAreaIDs = selectedContentArea.map(Number);
			const subjectIDs = selectedSubjectIDs.map(Number);
			const gradeLevelIDs = selectedGradeLevels.map(Number);
			const teachersIDs = selectedTeachersIDs.map(Number);

			onUpdateWidget({
				id,
				name,
				options: {
					schoolID: widgetOptions.schoolID,
					districtID: widgetOptions.districtID,
					globalSchoolYearID: widgetOptions.globalSchoolYearID,
					viewType: commonForm.controls.viewType.value,
					viewSize: commonForm.controls.size.value,
					showAllTeachersAvg: commonForm.controls.isShowAverageValue.value,
					contentAreaIDs,
					subjectIDs,
					gradeLevelIDs,
					teachersIDs,
					periods,
					candles,
				},
				selectedArraysEntity: {
					contentAreaIDs: allContentAreas.length === contentAreaIDs.length ? 'all' : 'part',
					gradeLevelIDs: gradeLevels.length === gradeLevelIDs.length ? 'all' : 'part',
					teachersIDs: allTeachersList.length === teachersIDs.length ? 'all' : 'part',
					subjectIDs: subjects.length === subjectIDs.length ? 'all' : 'part',
				},
			});

			setIsCommonFormTouched(false);
			setIsCommonFormValid(true);

			setIsDynamicFormDataTouched(false);
			setIsDynamicFormDataValid(true);
		}
	};

	return (
		<WidgetCard.EditMode
			isChildrenFormTouched={isFormTouched}
			isChildrenFormValid={isFormValid}
			onDeleteWidgetByID={onDeleteWidgetByID}
			onUpdateWidget={onSaveWidgetData}
		>
			<CardBody>
				<MainSettings
					sizeControl={commonForm.controls.size}
					subjects={subjects}
					viewTypeControl={commonForm.controls.viewType}
					selectedContentArea={selectedContentArea}
					selectedGradeLevels={selectedGradeLevels}
					selectedSubjectIDs={selectedSubjectIDs}
					setSelectedContentArea={setSelectedContentArea}
					setSelectedGradeLevels={setSelectedGradeLevels}
					setSelectedSubjectIDs={setSelectedSubjectIDs}
					teachers={allTeachersList}
					selectedTeachersIDs={selectedTeachersIDs}
					setSelectedTeachersIDs={setSelectedTeachersIDs}
					gradeLevels={gradeLevels}
				/>

				<CardBodyDivider />

				<AdditionalSettingBox>
					{widgetViewType === PerformanceWidgetViewType.Candles && (
						<CandleChartData
							periods={widgetOptions.periods}
							setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
							setIsDynamicFormDataValid={setIsDynamicFormDataValid}
							dynamicFormDataRef={dynamicFormDataRef}
							candles={widgetOptions.candles}
						/>
					)}

					{widgetViewType === PerformanceWidgetViewType.Piechart && widgetOptions.periods[0] && (
						<PiechartData
							periodData={widgetOptions.periods[0]}
							setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
							setIsDynamicFormDataValid={setIsDynamicFormDataValid}
							dynamicFormDataRef={dynamicFormDataRef}
						/>
					)}

					<CheckboxesBox>
						<WidgetCard.EditMode.FormElement control={commonForm.controls.isShowAverageValue}>
							<FlexBox justify='between'>
								<CheckboxFormElement label='Show All Teachers Average' />
								{widgetViewType === PerformanceWidgetViewType.Piechart && (
									<WidgetCard.EditMode.AverageHint.Piechart
										avgResultName='Average across all teachers'
										chartName='1st teacher'
										description='The outer circle represents the value for a specific teacher, while the inner blue circle shows the average for all teachers. This average is calculated by summing the values for all teachers and dividing the total by the number of teachers.'
										resultName='Teacher result'
									/>
								)}
							</FlexBox>
						</WidgetCard.EditMode.FormElement>
					</CheckboxesBox>
				</AdditionalSettingBox>
			</CardBody>
		</WidgetCard.EditMode>
	);
}
