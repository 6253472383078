import {User} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';
import {FormElementSelect} from '../../../../../components/form-element-select';
import {FormSelectItem} from '../../../../../components/form-element-select/types';
import {getFullName} from '@esgi/main/kits/common';
import {specialistsSelectErrorItems} from './constants';
import {SchoolSpecialistForm} from '../../../forms-data/types';
import {ErrorMessageBox} from '../../../../../components/error-message-box';

type Props = {
	form: SchoolSpecialistForm;
	schoolSpecialistsList: User[];
	formInactiveMessage: string | null;
};

export function SchoolSpecialistContent({
	form,
	schoolSpecialistsList,
	formInactiveMessage,
}: Props) {
	const schoolSpecialistsSelectItems = useMemo(
		() =>
			schoolSpecialistsList.map<FormSelectItem<string>>(({id, firstName, lastName}) => ({
				value: String(id),
				label: getFullName({firstName, lastName}),
			})),
		[schoolSpecialistsList],
	);

	return (
		<>
			<ErrorMessageBox message={formInactiveMessage} />
			<FormElementSelect
				placeholder='School Specialist'
				control={form.controls.schoolSpecialistID}
				items={schoolSpecialistsSelectItems}
				errorItems={specialistsSelectErrorItems}
			/>
		</>
	);
}
