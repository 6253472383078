import {TabID} from '../../../../types';
import {TabItem} from './types';

export const items: TabItem[] = [
	{
		value: TabID.GroupInformation,
		label: 'Group Information',
	},
	{
		value: TabID.Students,
		label: 'Students',
	},
];
