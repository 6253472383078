import {ContextMenu} from '@esgi/ui';
import {FlexBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const Container = styled(FlexBox, {
	gap: '$4',
	justifyContent: 'space-between',
	alignItems: 'center',
});

export const PanelTitle = styled(FlexBox, {
	gap: 2,
	alignItems: 'center',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'none',

		'& path': {
			fill: '$mediumContrast',
		},
	},

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
			},
		},
	},
});

export const DownloadContextMenuItem = styled(ContextMenu.Item, {
	padding: 6,
	cursor: 'pointer',
});
