import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const HeaderContent = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	gap: '$4',
	alignItems: 'center',
});

export const InfoBox = styled(GridBox, {
	columnGap: '$3',
	alignItems: 'center',
});

export const AdditionalInfo = styled(FlexBox, {
	gap: '$3',
});

export const AdditionalHeaderContent = styled(GridBox, {
	paddingInline: 20,
	gap: 20,
});
