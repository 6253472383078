import {OneLinedText} from '@esgi/main/kits/common';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const TextStyled = styled(OneLinedText, {
	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				width: '50%',
				height: 12,
				borderRadius: 6,
			},
		},
	},
});
