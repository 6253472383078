import {CompareDates, Datepicker} from '@esgi/ui/icons';
import {ToggleGroupItemModel} from '@esgi/main/features/admins/dashboard';
import {SchoolCutoff, PeriodsVariant} from './types';

export const periodsVariantItems: ToggleGroupItemModel<PeriodsVariant>[] = [
	{
		value: PeriodsVariant.SinglePeriod,
		label: 'Single Period',
		Icon: Datepicker,
	},
	{
		value: PeriodsVariant.TwoPeriods,
		label: 'Two Periods',
		Icon: CompareDates,
	},
];

export const districtCutoffNumberByType: Record<Exclude<SchoolCutoff, SchoolCutoff.None>, number> = {
	[SchoolCutoff.Five]: 5,
	[SchoolCutoff.Ten]: 10,
	[SchoolCutoff.Fifteen]: 15,
	[SchoolCutoff.Twenty]: 20,
	[SchoolCutoff.TwentyFive]: 25,
};
