import {styled} from '@esgi/ui/theme';
import {FlexBox, SelectableList} from '@esgi/ui/layout';

export const StyledSelectableListTrigger = styled(SelectableList.Trigger, {
	variants: {
		custom: {
			true: {
				border: 'none',
				borderRadius: 4,
				background: 'unset',

				'& > button > button': {
					padding: 0,
				},
			},
		},
		expanded: {
			true: {
				'& > svg': {
					transform: 'scale3d(1, -1, 1)',
				},
			},
		},
	},
});

export const StyledSelectableTriggerButton = styled(SelectableList.StyledButton, {
	height: 20,
	width: 20,
	placeSelf: 'unset',
	borderRadius: 0,
});

export const StyledSelectableListItem = styled(SelectableList.Item, {
	variants: {
		custom: {
			true: {
				padding: '8px 12px 8px 32px',
				border: 'none',
				borderRadius: 0,
				background: 'unset',
			},
		},
	},
});

export const StyledIconBefore = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	width: '20px',
	height: '20px',
	borderRadius: '4px',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$muted',

	variants: {
		checked: {
			true: {
				borderColor: '$primaryMuted',

				'& > svg > path': {
					fill: '$primary',
					stroke: '$primary',
				},
			},
		},
	},
});