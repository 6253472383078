import {Alert} from '@esgi/ui/alert';
import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		width: 1080,
		maxWidth: 'calc(100% - 40px)',
		height: 680,
		maxHeight: 'calc(100% - 40px)',
		gap: 20,
		gridTemplateRows: 'auto 1fr auto auto',
		backgroundColor: '$surface',
	},
};

export const Divider = styled(Box, {
	width: '100%',
	height: 1,
	backgroundColor: '$border',
});

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: 20,
	gridTemplateRows: 'auto 1fr',
	overflow: 'hidden',
});
