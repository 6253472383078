import {styled} from '@esgi/ui/theme';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const LayoutContainer = styled(FlexBox, {
	width: '100%',
	height: '100%',
	overflow: 'hidden',
});

export const EmptyBodyContainer = styled(FlexBox, {
	background: 'linear-gradient(126deg, #F0F0F0 0.11%, #EBEBEB 71.15%)',
	width: '100%',
	height: '100%',
	justifyContent: 'center',
	alignItems: 'center',
});

export const EmptyBodyText = styled(Text, {
	borderRadius: '6px',
	padding: '12px 20px',
	border: '1px solid $surface',
	background: '$background',
	color: '$neutral40',
});

export const DataContainer = styled(FlexBox, {
	flexDirection: 'column',
	width: '100%',
	height: '100%',
	backgroundColor: '$border',
	position: 'relative',
});

export const Page = styled(GridBox, {
	width: '100%',
	height: '100%',
});

export const Content = styled(GridBox, {
	padding: '$2',
	borderRadius: 4,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$lowContrast',
});
