import {isEqual} from 'underscore';
import {BehaviorSubject, catchError, tap, throwError} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';
import {teachersStore, User} from '@esgi/main/libs/admin-store';
import {showSnackbarNotification} from '@esgillc/ui-kit/snackbar';
import {OutModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/profiles/teacher/edit/out-model';
import {V2DistrictAdminsActionPanelController, V2DistrictAdminsTeacherController} from '@esgi/contracts/esgi';
import {createEditTeacherForm, teacherImportIDNValidator, teacherExportIDNValidator} from './form';

export class EditTeacherService extends BaseService {
	public readonly isDataLoaded$ = new BehaviorSubject<boolean>(false);
	public readonly isBusy$ = new BehaviorSubject<boolean>(false);

	public form = createEditTeacherForm();

	private teacherID: number;
	private initData: OutModel = null;
	private user = userStorage.get();
	private teacherController = new V2DistrictAdminsTeacherController();
	private actionPanelController = new V2DistrictAdminsActionPanelController();

	public init(teacherID: number) {
		this.teacherID = teacherID;

		return this.getTeacher().pipe(tap(() => this.pushValidators()));
	}

	public compareStatesForEquality() {
		const initialState = {
			title: this.initData.title,
			email: this.initData.email,
			firstName: this.initData.firstName,
			lastName: this.initData.lastName,
			importID: this.initData.importID,
			exportID: this.initData.exportID,
		};

		const currentState = {
			title: this.form.value.title[0],
			email: this.form.value.email,
			firstName: this.form.value.firstName,
			lastName: this.form.value.lastName,
			importID: this.form.value.importID,
			exportID: this.form.value.exportID,
		};

		return !isEqual(currentState, initialState);
	}

	public editTeacher() {
		this.isBusy$.next(true);
		return this.teacherController.update({
			userID: this.teacherID,
			title: this.form.value.title[0],
			firstName: this.form.value.firstName,
			lastName: this.form.value.lastName,
			email: this.form.value.email,
			importID: this.form.value.importID,
			exportID: this.form.value.exportID,
		}).pipe(tap(() => {
			this.isBusy$.next(false);
			const newTeacher: Partial<User> = {
				id: this.teacherID,
				firstName: this.form.value.firstName,
				lastName: this.form.value.lastName,
				email: this.form.value.email,
			};
			return teachersStore().update((value) => {
				if (value.id !== newTeacher.id) {
					return value;
				}

				return {
					...value,
					...newTeacher,
				};
			});
		}), catchError((error) => {
			this.isBusy$.next(false);
			return throwError(error);
		}));
	}

	public resetPassword() {
		this.isBusy$.next(true);
		return this.teacherController.resetPassword({
			userID: this.teacherID,
		}).pipe(tap(() => {
			this.isBusy$.next(false);
			showSnackbarNotification('Password reset email sent successfully.');
		}), catchError((error) => {
			this.isBusy$.next(false);
			return throwError(error);
		}));
	}

	public override dispose() {
		this.teacherController.dispose();
		this.actionPanelController.dispose();
	}

	private pushValidators() {
		this.form.controls.importID.validators.push(teacherImportIDNValidator(this.teacherController, this.user.districtID, this.teacherID));
		this.form.controls.exportID.validators.push(teacherExportIDNValidator(this.teacherController, this.user.districtID, this.teacherID));
	}

	private getTeacher() {
		return this.teacherController.initEdit({userID: this.teacherID}).pipe(tap((response) => {
			this.initData = response;
			this.form.value = {
				firstName: response.firstName,
				lastName: response.lastName,
				title: response.title ? [response.title] : [],
				email: response.email,
				accountID: response.accountID,
				exportID: response.exportID,
				importID: response.importID,
				school: response.schoolName,
				expirationDate: response.expirationDate,
				maxStudents: response.maxStudents,
			};

			this.isDataLoaded$.next(true);
		}));
	}
}