import {useDisposable} from '@esgi/core/service';
import {useUser} from '@esgi/core/authentication';
import {V2SchoolAdminsFoldersUsersController} from '@esgi/contracts/esgi';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useCallback, useEffect} from 'react';
import {FolderType} from '../types';
import {isNull} from 'underscore';
import {dispatchAppEvent} from '@esgillc/events';
import {FolderUpdatedEvent} from '../events';

type Parameters = {
	type: FolderType;
	onFolderUpdated?: VoidFunction;
};

export function useUpdateFolder({type, onFolderUpdated}: Parameters) {
	const currentUser = useUser();

	const foldersUsersController = useDisposable(V2SchoolAdminsFoldersUsersController);

	const [updateUserFolderData, updateUserFolder] = useCancelableRequest(foldersUsersController.update);

	useEffect(() => {
		if (isAsyncSucceed(updateUserFolderData)) {
			onFolderUpdated?.();

			dispatchAppEvent(
				FolderUpdatedEvent,
				new FolderUpdatedEvent({
					type,
					id: updateUserFolderData.data.groupID,
				}),
			);
		}
	}, [onFolderUpdated, type, updateUserFolderData]);

	return useCallback(
		({entityIDs, name, id}: {entityIDs: number[]; name: string; id: number}) => {
			if (isNull(currentUser)) {
				return;
			}

			updateUserFolder({
				// globalSchoolYearID: currentUser.globalSchoolYearID,
				groupID: id,
				name,
				userIDs: entityIDs,
			});
		},
		[currentUser, updateUserFolder],
	);
}
