import {environment} from '@esgi/core/environments';
import {Renderable} from '@esgi/deprecated/knockout';
import {FieldContainer, FieldTool} from '../../../kit/component';
import {
	EmailInputComponent,
	PasswordInputComponent,
	PCInputComponent,
	UsernameInputComponent,
} from '../../components';
import {resizeToWindow} from '../../utils';
import {InitData} from '../../registration';
import {AcUserInfoModel, FlowResultModel, PurchaseUserInfoModel, TrialUserInfoModel} from '../../types';
import {TrialTemplate} from './templates';

import './trial.less';

export class TrialFlow extends Renderable {
	email: EmailInputComponent;
	pc: PCInputComponent;
	username: UsernameInputComponent;
	password: PasswordInputComponent;
	fields: Array<FieldContainer>;
	userCreating: KnockoutObservable<boolean> = ko.observable(false);

	constructor(opts?: InitData) {
		super();
		this.userCreating(false);
		this.createFields(opts);
	}

	template = () => {
		resizeToWindow();
		return TrialTemplate.Render();
	};

	createFields(opts?: InitData): void {
		this.email = new EmailInputComponent('', true);
		this.username = new UsernameInputComponent();
		this.password = new PasswordInputComponent();
		this.pc = new PCInputComponent(opts && opts.promoCode);
		this.email.validationResult.subscribe((v) => {
			if (!this.username.serialize() && !v.isExist && v.isValid) {
				this.username.trySetUserName(v.value);
			}
		});
		this.pc.events.acFlowClicked(() => this.eventTriggers.acFlowClicked());
		this.fields = new Array<FieldContainer>();
		this.fields.push(...[this.email, this.username, this.password, this.pc]);
	}

	clearValidationResult(): void {
		this.email.removeTooltip();
		this.username.removeTooltip();
		this.password.removeTooltip();
		this.pc.removeTooltip();
	}

	isValid(): JQueryPromise<boolean> {
		return $.whenAll([...this.fields.map((f) => f.validate())]).pipe((r: Array<boolean>) => {
			const indexOfFirstFailed = r.indexOf(false);
			if (indexOfFirstFailed !== -1) {
				FieldTool.scrollToField(this.fields[indexOfFirstFailed]);
			}
			return r.reduce((a, b) => a && b);
		});
	}

	serialize(): FlowResultModel<TrialUserInfoModel> {
		return <FlowResultModel<TrialUserInfoModel>>{
			model: {
				email: this.email.serialize().trim(),
				userName: this.username.serialize().trim(),
				password: this.password.serialize(),
				promoCode: this.pc.serialize(),
			},
		};
	}

	events = {
		cancel: (callback) => {
			$(this).on('cancel', callback);
		},
		next: (callback) => {
			$(this).on('next', callback);
		},
		purchaseClicked: (callback: (event: Event, params: any) => void) => {
			$(this).on('purchaseClicked', callback);
		},
		acFlowClicked: (callback: (event: Event, params: any) => void) => {
			$(this).on('acFlowClicked', callback);
		},
	};
	private eventTriggers = {
		cancel: () => {
			$(this).trigger('cancel');
		},
		next: () => {
			this.userCreating(true);
			this.isValid().done((r) => {
				if (r) {
					$(this).trigger('next', {data: this.serialize()});
				} else {
					this.userCreating(false);
				}
			});
		},
		purchaseClicked: () => {
			const pcInfo = this.pc.promoCodeInfo();
			const model = <PurchaseUserInfoModel>{
				email: this.email.validation.valid() ? this.email.serialize() : '',
				password: this.password.validation.valid() ? this.password.serialize() : '',
				userName: this.username.validation.valid() ? this.username.serialize() : '',
			};
			if (pcInfo) {
				model.promoCode = pcInfo.valid ? pcInfo.value : '';
			}
			$(this).trigger('purchaseClicked', {data: model});
		},
		acFlowClicked: () => {
			const pcInfo = this.pc.promoCodeInfo();
			const model = <AcUserInfoModel>{
				email: this.email.validation.valid() ? this.email.serialize() : '',
				password: this.password.validation.valid() ? this.password.serialize() : '',
				userName: this.username.validation.valid() ? this.username.serialize() : '',
			};
			if (pcInfo && pcInfo.isActivationCode) {
				model.activationCode = pcInfo.value;
			}
			$(this).trigger('acFlowClicked', {data: model});
		},
		signUpWithCleverClicked: () => {
			const promoCode = this.pc.serialize();
			if (promoCode) {
				sessionStorage.setItem('esgi-registration-promo-code', promoCode);
			}
			sessionStorage.setItem('esgi-registration-disallow', 'false');
			location.href = `${environment.ssoApiURL}/integrations/clever/auth/get-user-url`;

		},
	};
}
