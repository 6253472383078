import * as React from 'react';
import type {SVGProps} from 'react';

export function SizeFull(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				d='M7 9.5C7 8.67157 7.67157 8 8.5 8H23.5C24.3284 8 25 8.67157 25 9.5V14.5C25 15.3284 24.3284 16 23.5 16H8.5C7.67157 16 7 15.3284 7 14.5V9.5Z'
				fill='#333333'
			/>
			<path
				d='M7 19.65C7 19.291 7.29101 19 7.65 19H24.35C24.709 19 25 19.291 25 19.65C25 20.009 24.709 20.3 24.35 20.3H7.65C7.29102 20.3 7 20.009 7 19.65Z'
				fill='#333333'
			/>
			<path
				d='M7.65 23C7.29101 23 7 23.291 7 23.65C7 24.009 7.29102 24.3 7.65 24.3H24.35C24.709 24.3 25 24.009 25 23.65C25 23.291 24.709 23 24.35 23H7.65Z'
				fill='#333333'
			/>
		</svg>
	);
}
