import {tap} from 'rxjs';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {colorConfigInEditMode} from './constants';
import {YesNoTestSession} from './components/variant-mode/yes-no-test-session';
import {SingleScoreTestSession} from './components/variant-mode/single-score-test-session';
import {RubricTestSession} from './components/variant-mode/rubric-test-session';
import {SkeletonTestSession} from './components/variant-mode/skeleton-test-session';
import {useService} from '@esgi/core/service';
import {Service} from './service';
import {useBehaviorSubject} from '@esgi/ui';
import {isNull} from 'underscore';
import {ReportErrorEvent} from '@esgi/core/react';
import {dispatchAppEvent} from '@esgillc/events';
import {UserType, useUser} from '@esgi/core/authentication';
import {
	TestInfo,
	UpdateScoreSessionRequestParams,
	UpdateYNSessionRequestParams,
} from '../../types/view-edit-session-details';
import {Student, Subject, Test} from '../../types/common';
import {TestSessionTimeConflict} from '../../components';
import {TestType} from '@esgi/main/kits/common';

type Props = {
	onAlertClose: VoidFunction;
	testSessionID: number;
	classID: number | null;
	subject: Subject;
	test: Test;
	student: Student;
	skeleton?: boolean;
};

export function TestSessionDetailsAlert({
	onAlertClose,
	testSessionID,
	classID,
	subject,
	student,
	skeleton,
	test,
}: Props) {
	const currentUser = useUser();

	const [isDataLoaded, setIsDataLoaded] = useState(true);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isTimeConflict, setIsTimeConflict] = useState(false);

	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onAlertClose);

	const service = useService(Service);

	const testSession = useBehaviorSubject(service.testSession$);
	const questions = useBehaviorSubject(service.questions$);
	const rubricCriteria = useBehaviorSubject(service.rubricCriteria$);

	useEffect(() => {
		setIsDataLoaded(false);

		service.init({testSessionID}).subscribe(() => {
			setIsDataLoaded(true);
		});
	}, [testSessionID]);

	const testInfo = useMemo(() => {
		if (isNull(testSession)) {
			return null;
		}

		const testInfo: TestInfo = {
			id: test.id,
			name: test.name,
			testType: test.type,
			contentArea: test.contentArea,
			color: test.color,
			questions,
			rubricCriteria,
			totalPossible: test.maxScore,
		};

		return testInfo;
	}, [questions, rubricCriteria, test, testSession]);

	const onUpdateYNSession = useCallback(
		(params: UpdateYNSessionRequestParams) => {
			return service.updateYNSession(params).pipe(
				tap((response) => {
					if (response.isSuccess) {
						return;
					}

					setIsTimeConflict(true);
				}),
			);
		},
		[service],
	);

	const onUpdateScoreSession = useCallback(
		(params: UpdateScoreSessionRequestParams) => {
			return service.updateScoreSession(params).pipe(
				tap((response) => {
					if (response.isSuccess) {
						return;
					}

					setIsTimeConflict(true);
				}),
			);
		},
		[service],
	);

	const onDeleteSession = useCallback(() => {
		service.deleteSessionRequest({testSessionID}).subscribe(closeAlert);
	}, [closeAlert, service, testSessionID]);

	const onRestoreSession = useCallback(() => {
		service.restoreSession({testSessionID});
	}, [service, testSessionID]);

	const onDownloadSession = useCallback(() => {
		if (isNull(testInfo) || isNull(testSession)) {
			return;
		}

		if (testInfo.testType === TestType.YN || testInfo.testType === TestType.Score) {
			service
				.downloadYNScoreSessionDetails({
					testName: testInfo.name,
					studentFirstName: student.firstName,
					studentLastName: student.lastName,
					classID: isNull(classID) ? 0 : classID,
					studentID: student.id,
					testID: testSession.testID,
					sessionID: testSession.id,
					subjectName: subject.name,
				})
				.subscribe({
					error: () => {
						dispatchAppEvent(ReportErrorEvent, new ReportErrorEvent('Unable to load pdf file.'));
					},
				});
		}

		if (testInfo.testType === TestType.Rubric) {
			service
				.downloadRubricSessionDetails({
					classID: isNull(classID) ? 0 : classID,
					sessionID: testSession.id,
					studentID: student.id,
					subjectID: subject.id,
					subjectType: subject.type,
					testID: testSession.testID,
				})
				.subscribe();
		}
	}, [classID, service, student, subject, testInfo, testSession]);

	const canEdit = useMemo(() => {
		if (isNull(currentUser)) {
			return false;
		}

		if (!currentUser.canAddEditTestSessions || !testSession) {
			return false;
		}

		if (currentUser.userType === UserType.T) {
			return currentUser.userID === testSession.testedBy || testSession.testedBy === null;
		} else {
			return currentUser.userID === testSession.testedBy;
		}
	}, [currentUser, testSession]);

	const getAlertContent = () => {
		if (!isDataLoaded || skeleton) {
			return <SkeletonTestSession canEdit={canEdit} onCloseAlert={closeAlert} />;
		}

		if (isNull(testSession) || isNull(testInfo)) {
			return null;
		}

		switch (testInfo.testType) {
			case TestType.YN:
				return (
					<YesNoTestSession
						testInfo={testInfo}
						sessionInfo={testSession}
						canEdit={canEdit}
						isEditMode={isEditMode}
						onCloseAlert={closeAlert}
						setIsEditMode={setIsEditMode}
						student={student}
						onDeleteSession={onDeleteSession}
						onRestoreSession={onRestoreSession}
						updateYNSession={onUpdateYNSession}
						onDownloadSession={onDownloadSession}
					/>
				);
			case TestType.Score:
				return (
					<SingleScoreTestSession
						testInfo={testInfo}
						sessionInfo={testSession}
						canEdit={canEdit}
						isEditMode={isEditMode}
						onCloseAlert={closeAlert}
						setIsEditMode={setIsEditMode}
						student={student}
						onDeleteSession={onDeleteSession}
						onDownloadSession={onDownloadSession}
						onRestoreSession={onRestoreSession}
						updateScoreSession={onUpdateScoreSession}
					/>
				);
			case TestType.Rubric:
				return (
					<RubricTestSession
						testInfo={testInfo}
						sessionInfo={testSession}
						canEdit={canEdit}
						isEditMode={isEditMode}
						onCloseAlert={closeAlert}
						setIsEditMode={setIsEditMode}
						student={student}
						onDeleteSession={onDeleteSession}
						onRestoreSession={onRestoreSession}
						onDownloadSession={onDownloadSession}
						updateRubricSession={service.updateRubricSession.bind(service)}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<>
			{isTimeConflict && <TestSessionTimeConflict onClose={() => setIsTimeConflict(false)} />}
			<Alert
				colorConfig={isEditMode ? colorConfigInEditMode : alertColorsConfig.neutral}
				modalManagerRef={alertRef}
				css={{
					'& [data-alert-content]': {
						width: 510,
						maxHeight: 'calc(100% - 40px)',
						gridTemplateRows: 'auto 1fr auto',
						overflow: 'hidden',
					},
				}}
				dataCy='session-details-alert'
			>
				{getAlertContent()}
			</Alert>
		</>
	);
}
