import {V2SchoolAdminsSubjectManagementController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {DistrictSubject} from '@esgi/main/features/admins/subject-management';
import {SubjectLevel, SubjectType, TestContentArea, TestType} from '@esgi/main/kits/common';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {Dispatch, SetStateAction, useEffect, useMemo} from 'react';
import {SubjectTestsByID} from '../types';

type Parameters = {
	setSubjectTestsByID: Dispatch<SetStateAction<SubjectTestsByID>>;
};

type Out = [boolean, DistrictSubject[]];

export function useDistrictSubjects({setSubjectTestsByID}: Parameters) {
	const controller = useDisposable(V2SchoolAdminsSubjectManagementController);

	const [subjectsData, fetchSubjects] = useCancelableRequest(controller.init);

	useEffect(() => {
		fetchSubjects({
			teacherID: 0,
		});
	}, []);

	return useMemo<Out>(() => {
		const isSubjectsLoading = isAsyncPending(subjectsData);
		const isSubjectsLoaded = isAsyncSucceed(subjectsData);

		if (!isSubjectsLoaded || isSubjectsLoading) {
			return [false, []];
		}

		const districtSubjects = subjectsData.data.subjects.district;

		const outSubjects = districtSubjects.map<DistrictSubject>(({id, name, hidden}) => ({
			id,
			name,
			hidden,
			level: SubjectLevel.District,
			type: SubjectType.Deployed,
		}));

		setSubjectTestsByID((currentState) => {
			districtSubjects.forEach(({id, tests}) => {
				currentState[id] = tests.map(({id, name, color, contentArea, type}) => ({
					id,
					name,
					color,
					contentArea: contentArea as TestContentArea,
					type: type as unknown as TestType,
				}));
			});

			return {
				...currentState,
			};
		});

		return [true, outSubjects];
	}, [setSubjectTestsByID, subjectsData]);
}
