import {Edit, FolderAdd, Teacher} from '@esgi/ui/icons';
import {ActionPanel, RunReportButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button, Tooltip, useFlag} from '@esgi/ui';
import {
	AddToFolderButton,
	useNavigateToClassesPage,
	useNavigateToGroupsPage,
	useNavigateToStudentsPage,
} from '@esgi/main/features/admins/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {getFullName} from '@esgi/main/kits/common';
import {DeleteTeacherButtonSingleSelect} from '../../../../../../components/delete-teacher-button-single-select';
import {GridApi} from 'ag-grid-community';
import {Dispatch, useCallback} from 'react';
import {ReportsFeature} from '../reports';
import {OpenPanelButton} from '../../../../../../components/open-panel-button';

type Props = {
	gridApi: GridApi<Readonly<RowDef>> | null;
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	isPanelExpanded: boolean;
	userCanAddAndDeleteTeachers: boolean;
	editButtonDisabled: boolean;
	onEditClick: Dispatch<RowDef>;
};

export function QuickPanel({
	selectedRow,
	onFolderAddClick,
	isPanelExpanded,
	gridApi,
	editButtonDisabled,
	onEditClick,
	userCanAddAndDeleteTeachers,
}: Props) {
	const [showReportsDialog, openReport, closeReport] = useFlag();

	const goToClassesPage = useNavigateToClassesPage();
	const goToGroupsPage = useNavigateToGroupsPage();
	const goToStudentsPage = useNavigateToStudentsPage();

	const onTeacherRemoved = useCallback(() => {
		gridApi?.deselectAll();
	}, [gridApi]);

	const navigateToGroups = useCallback(() => {
		goToGroupsPage({
			teacher: [selectedRow.id],
		});
	}, [goToGroupsPage, selectedRow]);

	const navigateToClasses = useCallback(() => {
		goToClassesPage({
			teacher: [selectedRow.id],
		});
	}, [goToClassesPage, selectedRow]);

	const navigateToStudents = useCallback(() => {
		goToStudentsPage({
			teacher: [selectedRow.id],
		});
	}, [goToStudentsPage, selectedRow]);

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<Teacher />

							<Text size='large' color='highContrast'>
								{getFullName({
									firstName: selectedRow.firstName,
									lastName: selectedRow.lastName,
								})}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteTeacherButtonSingleSelect
								onRemoved={onTeacherRemoved}
								userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
								selectedRow={selectedRow}
							/>
							<ActionPanel.ActionButtonDivider />

							<AddToFolderButton onClick={onFolderAddClick}>
								<FolderAdd />
							</AddToFolderButton>

							<ActionPanel.ActionButtonDivider />

							<Tooltip open={editButtonDisabled ? undefined : false}>
								<Tooltip.Trigger>
									<Button.Icon onClick={() => onEditClick(selectedRow)} disabled={editButtonDisabled}>
										<Edit />
									</Button.Icon>
								</Tooltip.Trigger>
								<Tooltip.Content>
									<Text size='large' color='tertiary'>
										To edit the Teacher, please contact Customer Support support@esgisoftware.com
									</Text>
								</Tooltip.Content>
							</Tooltip>

							<RunReportButton onClick={openReport} />
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<ActionPanel.Navigation.Container>
							<ActionPanel.Navigation.Card onClick={navigateToClasses} title='Classes' count={selectedRow.classes} />
							<ActionPanel.Navigation.Card onClick={navigateToGroups} title='Groups' count={selectedRow.groups} />
							<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
						</ActionPanel.Navigation.Container>

						<OpenPanelButton canExpandPanel={!!selectedRow.students} />
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showReportsDialog && <ReportsFeature onClose={closeReport} teacher={selectedRow} />}
		</>
	);
}
