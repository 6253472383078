import {useMemo} from 'react';
import {
	QuestionModel,
	QuestionSlider,
	QuestionFooter,
	QuestionHeader,
	QuestionLayout,
} from 'modules/assessments';

type Props = {
	index: number;
	questions: QuestionModel[];
	minIndex: number;
	maxIndex: number;
	isWhiteBackground: boolean;
}

export function StudentScreenSlider({minIndex, maxIndex, questions, index, isWhiteBackground}: Props): JSX.Element {

	return <QuestionLayout whiteBackground = {isWhiteBackground}>
		<QuestionHeader/>
		<QuestionSlider minIndex={minIndex} maxIndex={maxIndex} questions={questions} currentIndex={index}/>
		<QuestionFooter/>
	</QuestionLayout>;
}
