import {V2SchoolYearsController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {OnSchoolYearChanged, SchoolYearFullModel} from '../types';
import {userStorage} from '@esgi/core/authentication';
import {dispatchAppEvent} from '@esgillc/events';
import {SchoolYearChangedEvent} from 'modules/school-year';

type Parameters = {
	activeSchoolYearID: number | null;
	setActiveSchoolYearID: Dispatch<number | null>;
	schoolYears: SchoolYearFullModel[];
	onSchoolYearChanged?: OnSchoolYearChanged | undefined;
};

export function useSwitchSchoolYear({
	activeSchoolYearID,
	setActiveSchoolYearID,
	schoolYears,
	onSchoolYearChanged,
}: Parameters) {
	const schoolYearController = useDisposable(V2SchoolYearsController);

	const [switchedSchoolYearData, switchSchoolYear] = useCancelableRequest(schoolYearController.makeSwitch);
	const [hasUserActualSchoolYearID, setHasUserActualSchoolYearID] = useState(true);

	useEffect(() => {
		if (isAsyncSucceed(switchedSchoolYearData) && !isNull(activeSchoolYearID) && !hasUserActualSchoolYearID) {
			const selectedSchoolYear = schoolYears.find(({globalSchoolYearID}) => globalSchoolYearID === activeSchoolYearID)!;

			userStorage.update({globalSchoolYearID: activeSchoolYearID});
			dispatchAppEvent(SchoolYearChangedEvent, {schoolYearID: activeSchoolYearID});

			onSchoolYearChanged?.({schoolYear: selectedSchoolYear});
			setHasUserActualSchoolYearID(true);
		}
	}, [activeSchoolYearID, hasUserActualSchoolYearID, onSchoolYearChanged, schoolYears, switchedSchoolYearData]);

	const handleSchoolYearChange = useCallback(
		(schoolYearID: string) => {
			const id = Number(schoolYearID);

			switchSchoolYear({globalSchoolYearID: id});
			setActiveSchoolYearID(id);

			setHasUserActualSchoolYearID(false);
		},
		[setActiveSchoolYearID, switchSchoolYear],
	);

	return {
		isSchoolYearSwitching: isAsyncPending(switchedSchoolYearData),
		handleSchoolYearChange,
	};
}
