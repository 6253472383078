import {groupsStore, Student, studentsStore} from '@esgi/main/libs/school-admin-store';
import {useCallback} from 'react';

export function useRemoveGroupInStore() {
	return useCallback((groupID: number, cb?: VoidFunction) => {
		groupsStore().removeById?.(groupID);

		studentsStore().update?.((item: Student): Student => {
			if (item.groupsIDs.includes(groupID)) {
				return {
					...item,
					groupsIDs: item.groupsIDs.filter((id) => id !== groupID),
				};
			}

			return item;
		});

		cb?.();
	}, []);
}
