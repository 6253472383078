import {Dispatch} from 'react';
import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {Item, ToggleGroupContent} from './index.styled';
import {items} from './constants';
import {TabID} from '../../../../types';

interface TabsProps {
	activeTabID: TabID;
	setActiveTabID: Dispatch<TabID>;
}

export function Tabs({activeTabID, setActiveTabID}: TabsProps) {
	return (
		<ToggleGroup.Root value={activeTabID} onValueChange={setActiveTabID}>
			<ToggleGroupContent>
				{items.map(({value, label}) => (
					<Item value={value} key={value}>
						<Text size='medium' bold>
							{label}
						</Text>
					</Item>
				))}
			</ToggleGroupContent>
		</ToggleGroup.Root>
	);
}
