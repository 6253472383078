import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {ToggleGroupContent} from './index.styled';
import {useTabsContext} from '../../context';
import {ComponentPropsWithoutRef, ForwardedRef, forwardRef, useCallback} from 'react';

type Props = Omit<ComponentPropsWithoutRef<typeof ToggleGroup.Root>, 'onValueChange' | 'value' | 'orientation'>;

function TabsBarInner<Tab extends string>(
	{dataCy = 'tabs-bar', css = {}, ...toggleGroupProps}: Props,
	ref: ForwardedRef<HTMLDivElement>,
) {
	const {activeTab, setActiveTab, availableTabs, skeleton} = useTabsContext<Tab>();

	const onValueChange = useCallback(
		(newTab: Tab) => {
			setActiveTab(newTab);
		},
		[setActiveTab],
	);

	return (
		<ToggleGroup.Root
			dataCy={dataCy}
			css={css}
			value={activeTab ?? undefined}
			ref={ref}
			onValueChange={onValueChange}
			orientation='horizontal'
			{...toggleGroupProps}
		>
			{!availableTabs.length ? null : (
				<ToggleGroupContent dataCy={`${dataCy}-content`} skeleton={skeleton}>
					{availableTabs.map(({tabID, tabContent, disabled}) => {
						const isTabActive = activeTab === tabID;

						return (
							<ToggleGroup.Item
								value={tabID}
								disabled={isTabActive || disabled}
								applyDisabledStyles={isTabActive ? false : disabled}
								key={tabID}
							>
								{typeof tabContent === 'string' ? (
									<Text size='small' bold>
										{tabContent}
									</Text>
								) : (
									tabContent
								)}
							</ToggleGroup.Item>
						);
					})}
				</ToggleGroupContent>
			)}
		</ToggleGroup.Root>
	);
}

export const TabsBar = forwardRef(TabsBarInner);
