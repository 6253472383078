import {Subject} from '@esgi/main/libs/school-admin-store';
import {Dispatch, useCallback, useEffect, useMemo, useState} from 'react';
import {SettingsPanel} from '../../components/settings-panel';
import {TestsPanel} from '../../components/tests-panel';
import {AddTestsDrawer, TestPanelMode} from '@esgi/main/features/admins/subject-management';
import {useFlag} from '@esgi/ui';
import {useInitData} from './hooks/use-init-data';
import {useSubjectData} from './hooks/use-subject-data';
import {useDeleteSubject} from './hooks/use-delete-subject';
import {useRearrangeTests} from './hooks/use-rearrange-tests';
import {useDeleteTest} from './hooks/use-delete-test';
import {useAddTests} from './hooks/use-add-tests';
import {storageToAddTestsTestType} from '../mapped-enums';
import {useStoreSubjectVisability} from './hooks/use-store-subject-visability';
import {useTestsPanelTests} from './hooks/use-tests-panel-tests';

type Props = {
	subjectFromStore: Subject;
	allSchoolSubjectsNames: string[];
	onSubjectRemoved: VoidFunction;
	onIsFormTouchedValueChanged: Dispatch<boolean>;
};

export function Edit({allSchoolSubjectsNames, onIsFormTouchedValueChanged, onSubjectRemoved, subjectFromStore}: Props) {
	const [testsPanelMode, setTestsPanelMode] = useState(TestPanelMode.View);

	const [isAddTestsDrawerOpen, openAddTestsDrawer, closeAddTestsDrawer] = useFlag();

	const {allTrackDates, gradeLevels, isDataLoaded, schoolSpecialists, trackID, initSubjectData} = useInitData({
		subjectID: subjectFromStore.id,
	});

	const {
		subjectName,
		setSubjectName,
		retestSubjectType,
		setRetestSubjectType,
		isShuffleQuestions,
		setIsShuffleQuestions,
		publishPeriodValue,
		setPublishPeriodValue,
		setSelectedGradeLevels,
		selectedGradeLevels,
		isPublishForAllTeachers,
		setIsPublishForAllTeachers,
		publishForSpecialistsData,
		setPublishForSpecialistsData,
		isPublishForSpecialistsActive,
		setIsPublishForSpecialistsActive,
		onUpdateSubject,
		isDataSaving,
		isFormTouched,
	} = useSubjectData({
		allTrackDates,
		schoolSpecialists,
		initSubjectData,
		isDataLoaded,
		subjectID: subjectFromStore.id,
		trackID,
	});

	const {isActive, setIsActive, isVisabilityUpdating} = useStoreSubjectVisability({subjectFromStore});

	const {handleRemoveSubject, isSubjectRemoving} = useDeleteSubject({onSubjectRemoved, subjectFromStore});

	const forbiddenSubjectsNames = useMemo(
		() =>
			allSchoolSubjectsNames.filter((name) => name.toLocaleLowerCase() !== subjectFromStore.name.toLocaleLowerCase()),
		[allSchoolSubjectsNames, subjectFromStore.name],
	);

	const tests = useTestsPanelTests({subjectFromStore});
	const onRearrangeTests = useRearrangeTests({subjectFromStore});
	const onTestDeleteClick = useDeleteTest({subjectFromStore});
	const onAddTests = useAddTests({subjectFromStore});

	const onCloseAddTestsDrawer = useCallback(() => {
		closeAddTestsDrawer();
		setTestsPanelMode(TestPanelMode.View);
	}, [closeAddTestsDrawer]);

	useEffect(() => {
		onIsFormTouchedValueChanged(isFormTouched);

		return () => {
			onIsFormTouchedValueChanged(false);
		};
	}, [isFormTouched, onIsFormTouchedValueChanged]);

	return (
		<>
			<SettingsPanel
				skeleton={!isDataLoaded || isSubjectRemoving}
				subjectName={subjectFromStore.name}
				saveButtonText='Save Changes'
				subjectNameValue={subjectName}
				setSubjectNameValue={setSubjectName}
				forbiddenSubjectsNames={forbiddenSubjectsNames}
				isActive={isActive}
				setIsActive={setIsActive}
				retestSubjectType={retestSubjectType}
				setRetestSubjectType={setRetestSubjectType}
				isShuffleQuestions={isShuffleQuestions}
				setIsShuffleQuestions={setIsShuffleQuestions}
				trackID={trackID}
				gradeLevels={gradeLevels}
				selectedGradeLevels={selectedGradeLevels}
				setSelectedGradeLevels={setSelectedGradeLevels}
				allTrackDates={allTrackDates}
				publishPeriodValue={publishPeriodValue}
				setPublishPeriodValue={setPublishPeriodValue}
				isPublishForAllTeachers={isPublishForAllTeachers}
				setIsPublishForAllTeachers={setIsPublishForAllTeachers}
				isPublishForSpecialistsActive={isPublishForSpecialistsActive}
				setIsPublishForSpecialistsActive={setIsPublishForSpecialistsActive}
				publishForSpecialistsData={publishForSpecialistsData}
				setPublishForSpecialistsData={setPublishForSpecialistsData}
				onSaveSubject={onUpdateSubject}
				isDataSaving={isSubjectRemoving || isDataSaving}
				deactivateIfNotActive
				onRemoveSubject={handleRemoveSubject}
				isVisabilityUpdating={isVisabilityUpdating}
				isFormTouched={isFormTouched}
			/>

			<TestsPanel
				tests={tests}
				onRearrangeTests={onRearrangeTests}
				onTestDeleteClick={onTestDeleteClick}
				onAddButtonClick={openAddTestsDrawer}
				testsPanelMode={testsPanelMode}
				setTestsPanelMode={setTestsPanelMode}
			/>

			{isAddTestsDrawerOpen && (
				<AddTestsDrawer
					onClose={onCloseAddTestsDrawer}
					subject={{
						id: subjectFromStore.id,
						name: subjectFromStore.name,
						subjectType: storageToAddTestsTestType[subjectFromStore.subjectType],
					}}
					onAddTests={onAddTests}
				/>
			)}
		</>
	);
}
