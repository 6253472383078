import {Select} from '@esgi/ui/controls';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const PlaceholderItem = styled(Select.Option, {
	position: 'absolute',
	zIndex: -1,
});

export const PlaceholderItemCircle = styled(Box, {
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$muted',
	width: 12,
	height: 12,
	borderRadius: '50%',
});

export const ItemBox = styled(GridBox, {
	gridAutoFlow: 'column',
	gap: 6,
});

export const ItemColorBorder = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	width: 12,
	height: 12,
	borderRadius: '50%',
});

export const ItemColorFill = styled(Box, {
	width: 6,
	height: 6,
	borderRadius: '50%',
});
