import {useSubjectsFromStore} from './hooks/use-subjects-from-store';
import {useSubjectsBySchoolID} from './hooks/use-subjects-by-school-id';
import {useSubjectsByUserID} from './hooks/use-subjects-by-user-id';
import {TestResultsSubject} from '@esgi/main/kits/admin';
import {useMemo} from 'react';
import {AdditionalStoreSubjectsFilter} from './types';

type Student<P = unknown> = P & {gradeLevelID: number};

type Parameters = {students: Student[]} & Partial<{
	schoolID: number | undefined;
	userID: number;
	additionalStoreSubjectsFilter: AdditionalStoreSubjectsFilter | null;
}>;

type Out = {
	isReady: boolean;
	subjects: TestResultsSubject[];
};

export function useTestResultsSubject({schoolID, userID, students, additionalStoreSubjectsFilter}: Parameters): Out {
	const targetGradeLevels = useMemo(() => {
		const gradeLevels = new Set<number>();

		students.forEach(({gradeLevelID}) => gradeLevels.add(gradeLevelID));

		return [...gradeLevels];
	}, [students]);

	const [isSubjectsFromStoreReady, subjectsFromStore] = useSubjectsFromStore({
		schoolID: schoolID ?? null,
		targetGradeLevels,
		additionalStoreSubjectsFilter: additionalStoreSubjectsFilter ?? null,
		userID: userID ?? null,
	});

	const [isSubjectsBySchoolIDReady, subjectsBySchoolBy] = useSubjectsBySchoolID({
		schoolID: schoolID ?? null,
		targetGradeLevels,
	});

	const [isSubjectsByUserIDReady, subjectsByUserBy] = useSubjectsByUserID({userID: userID ?? null});

	return {
		isReady: isSubjectsFromStoreReady && isSubjectsBySchoolIDReady && isSubjectsByUserIDReady,
		subjects: [...subjectsFromStore, ...subjectsBySchoolBy, ...subjectsByUserBy],
	};
}
