import React, {useCallback} from 'react';
import {ColorPicker, ColorPickerProps} from './color-picker';
import {ElementStatus, useFormElementContext} from '@esgi/ui/form';

type Props<T extends string> = Omit<ColorPickerProps<T>, 'value' | 'setValue' | 'disabled'>;

export function FormColorPicker<T extends string>({items}: Props<T>) {
	const {status, setValue, value} = useFormElementContext(
		{
			array: true,
		},
		FormColorPicker,
	);

	const onValueChange = useCallback(
		(value: T | null) => {
			setValue([value]);
		},
		[setValue],
	);

	return (
		<ColorPicker items={items} setValue={onValueChange} value={value[0]} disabled={status === ElementStatus.disabled} />
	);
}
