import {Dispatch} from 'react';
import {DistrictSubject, SchoolSubject, StockSubjects, TeacherSubject} from './subject';

export enum TabID {
	School = 'School',
	District = 'District',
	Teacher = 'Teacher',
	Stock = 'Stock',
}

export type Entities = {
	teacherSubjects: TeacherSubject[];
	schoolSubjects: SchoolSubject[];
	districtSubjects: DistrictSubject[];
	stockSubjects: StockSubjects[];
};

export enum PanelMode {
	View = 'View',
	Add = 'Add',
	Rearrange = 'Rearrange',
}

export type UpdateTabWithRearrangeContent = Dispatch<{tabID: TabID; value: 'add' | 'remove'}>;
