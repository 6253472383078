import {AddWidgetModel} from '@esgi/main/features/admins/dashboard';
import {SchoolDashboardWidgetType} from './types';

export const widgets: AddWidgetModel<SchoolDashboardWidgetType>[] = [
	{
		title: 'School Performance',
		description: 'View average performance for all students across schools by percentage.',
		labels: [
			'Boxplot',
			'Pie Chart',
			'Subject Tab',
			'Content Area',
			'Grade Level',
			'Teacher Selection',
			'Date Range',
			'Timeframe Comparison',
		],
		type: SchoolDashboardWidgetType.SchoolPerformance,
	},
	{
		title: 'Demographic Performance',
		description: 'View average performance across schools based on specific demographic groups.',
		labels: [
			'Boxplot',
			'Pie Chart',
			'Subject Tab',
			'Content Area',
			'Grade Level',
			'Class Selection',
			'Date Range',
			'Timeframe Comparison',
			'School Comparison',
		],
		type: SchoolDashboardWidgetType.SchoolDemographicPerformance,
	},
	{
		title: 'Students Needing Support',
		description: 'Students most in need of support across schools or as a student list.',
		labels: ['Subject Tab', 'Content Area', 'Grade Level', 'Class Selection', 'Date Range', 'Timeframe Comparison'],
		type: SchoolDashboardWidgetType.SchoolStudentsNeedingSupport,
	},
];

export const widgetDateFormat = 'YYYY-MM-DD';
