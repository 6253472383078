import {GridBox} from '@esgi/ui/layout';
import {CardFooter as CardFooterContainer} from '../../../components/components.styled';
import {PropsWithChildren} from 'react';
import {selfAssessButtonWidth} from '../../constants';
import {SelfAssess} from '@esgi/ui/icons';
import {SelfAssessButton} from '../../../../../components/self-assess-button';
import {TestGroupStudentsModel} from '../../../../../../../../../types';

type Props = Pick<TestGroupStudentsModel, 'testInfo'> &
	PropsWithChildren<{
		disableSelfAssessButton?: boolean;
	}>;

export function CardFooter({children, disableSelfAssessButton, testInfo}: Props) {
	return (
		<CardFooterContainer>
			<GridBox flow='column' gap='3' align='center'>
				{children}
			</GridBox>

			{testInfo.selfAssessmentTestAllowed && (
				<SelfAssessButton width={selfAssessButtonWidth} disabled={Boolean(disableSelfAssessButton)}>
					<SelfAssess />
				</SelfAssessButton>
			)}
		</CardFooterContainer>
	);
}
