import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react/types/css-util';
import {animated} from 'react-spring';

const styles: CSS = {
	gridRow: 1,
	gridColumn: 1,
};

export const FirstRowColumnBox = styled(Box, styles);

export const FirstRowColumnAnimatedBox = styled(animated.div, styles);
