import {OneLinedText} from '@esgi/main/kits/common';
import {ComponentProps, useMemo} from 'react';
import {Property} from '@stitches/react/types/css';
import {useTestCardContext} from '../context';

type TextSize = ComponentProps<typeof OneLinedText>['size'];
type TextColor = ComponentProps<typeof OneLinedText>['color'];

type Props = Omit<ComponentProps<typeof OneLinedText>, 'size'> & {
	size: NonNullable<TextSize>;
	colorOnCardHover?: TextColor;
};

export function Title({
	dataCy = 'test-card-title',
	css = {},
	size,
	color = 'base',
	colorOnCardHover = 'secondary',
	...props
}: Props) {
	const {isCardHovered} = useTestCardContext();

	const adaptedTextLineHeight = useMemo<Property.LineHeight | undefined>(() => {
		if (size === 'xSmall') {
			return '13px';
		}

		return;
	}, [size]);

	return (
		<OneLinedText
			dataCy={dataCy}
			css={{
				lineHeight: adaptedTextLineHeight,
				transition: 'color .3s',
				...css,
			}}
			size={size}
			color={isCardHovered ? colorOnCardHover : color}
			{...props}
		/>
	);
}
