import {WidgetPeriod} from '@esgi/main/features/admins/dashboard';
import moment from 'moment';
import {isNull} from 'underscore';

export function getAdaptedPeriods(periods: WidgetPeriod[]) {
	const dateFormat = 'YYYY-MM-DD';

	return periods.map<WidgetPeriod>(({dateRange, color, datePeriod, trackDatesID}) => ({
		trackDatesID,
		color,
		datePeriod,
		dateRange: isNull(dateRange)
			? null
			: {
					dateFrom: moment(dateRange.dateFrom).format(dateFormat),
					dateTo: moment(dateRange.dateTo).format(dateFormat),
				},
	}));
}
