import {createContext, Dispatch, useContext} from 'react';
import {isNull} from 'underscore';

export type ContextMenuMenuContextValue = {
	setIsContextMenuOpened: Dispatch<boolean>;
};

export const ContextMenuMenuContext = createContext<ContextMenuMenuContextValue | null>(null);

export function useContextMenuMenuContext() {
	const context = useContext(ContextMenuMenuContext);

	if (isNull(context)) {
		throw new Error('useContextMenuMenuContext is null');
	}

	return context;
}
