import {ComponentProps, forwardRef} from 'react';
import {useTestCardContext} from '../context';
import {TestTypeLabel} from '@esgi/main/kits/common';

type Props = Omit<ComponentProps<typeof TestTypeLabel>, 'testType'>;

export const TestLabel = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-card-label', css = {}, ...props}, forwardedRef) => {
		const {testType} = useTestCardContext();

		return <TestTypeLabel dataCy={dataCy} css={css} testType={testType} ref={forwardedRef} {...props} />;
	},
);
