import {User, useTeachers} from '@esgi/main/libs/admin-store';
import {OnTeacherRemoved, RemoveTeacherManagerRef} from './types';
import {useCallback, useImperativeHandle, useState} from 'react';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {DeleteTeacherAlert} from './components/delete-teacher-alert';
import {useUser} from '@esgi/core/authentication';

type Props = {
	managerRef: RemoveTeacherManagerRef;
	onRemoved: OnTeacherRemoved;
};

export function RemoveTeacherImpl({managerRef, onRemoved}: Props) {
	const currentUser = useUser();

	const [{data: teachersList}] = useTeachers();

	const [potentialRemovedTeacher, setPotentialRemovedTeacher] = useState<User | null>(null);

	const closeDeleteAlert = useCallback(() => {
		setPotentialRemovedTeacher(null);
	}, []);

	useImperativeHandle(managerRef, () => ({
		runRemove: ({teacherID}) => {
			if (!currentUser?.canAddAndDeleteTeachers) {
				throw new Error(`User hasn't permissions to delete a teacher`);
			}

			const teacher = teachersList.find(({id}) => id === teacherID);

			if (isUndefined(teacher)) {
				throw new Error('teacher is not found in admin store');
			}

			setPotentialRemovedTeacher(teacher);
		},
	}));

	if (isNull(potentialRemovedTeacher)) {
		return null;
	}

	return (
		<DeleteTeacherAlert
			onClose={closeDeleteAlert}
			onRemoved={onRemoved}
			potentialRemovedTeacher={potentialRemovedTeacher}
		/>
	);
}
