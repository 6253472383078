import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {ButtonIconable} from './index.styled';
import {BaseComponentProps, Tooltip} from '@esgi/ui';
import {DragDropMode} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<typeof ButtonIconable>, 'children'> & {
		isActive: boolean;
		tooltipText?: string;
	};

export const RearrangeButton = forwardRef<HTMLButtonElement, Props>(
	({dataCy = 'rearrange-button', css = {}, isActive, tooltipText, disabled, skeleton, ...props}, forwardedRed) => (
		<Tooltip open={tooltipText && !skeleton ? undefined : false}>
			<Tooltip.Trigger>
				<ButtonIconable
					dataCy={dataCy}
					css={css}
					disabled={Boolean(disabled || skeleton)}
					active={isActive}
					skeleton={Boolean(skeleton)}
					withBackgroundHover
					ref={forwardedRed}
					{...props}
				>
					<DragDropMode />
				</ButtonIconable>
			</Tooltip.Trigger>
			<Tooltip.Content variant='secondary'>
				<Text size='small'>{tooltipText}</Text>
			</Tooltip.Content>
		</Tooltip>
	),
);
