export function enumNumberToString<T extends Record<string, string | number>>(
	value: string | number,
	numerableEnum: T,
): string {
	if (typeof value === 'string') {
		return value;
	}

	return numerableEnum[value] as string;
}
