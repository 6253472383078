import {ToggleGroup} from '@esgi/ui/controls';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const ContentStyled = styled(ToggleGroup.Content, {
	padding: 3,

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
			},
		},
	},
});
