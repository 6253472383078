import {UserType} from '../enum';

export enum StudentSort {
	FirstName = 'FirstName',
	LastName = 'LastName',
}

export enum UserCredentialStatus {
	None= 'None',
	Active= 'Active',
	Inactive= 'Inactive',
}

export enum UserCredentialType {
	None = 'None',
	Primary = 'Primary',
	Secondary = 'Secondary',
}

/* NEW */
/**
 * The above type represents the information of a user, including their personal details, permissions,
 * and settings.
 * @property {number} districtID - The district ID of the user.
 * @property {number} globalSchoolYearID - The ID of the current global school year.
 * @property {number} lastGlobalSchoolYearID - The last global school year ID refers to the ID of the
 * previous school year in the system. It is used to track the previous school year's data and make
 * comparisons or references to it if needed.
 * @property {number} currentGlobalSchoolYearID - The current global school year ID for the user.
 * @property {number} schoolID - The school ID of the user.
 * @property {number} stateID - The state ID of the user.
 * @property {number} userID - The unique identifier for the user.
 * @property {UserType} userType - The `userType` property is of type `UserType`, which is an enum that
 * represents the type of user. It can have one of the following values:
 * @property {string} firstName - The first name of the user.
 * @property {string} lastName - The last name of the user.
 * @property {string} userGuid - The userGuid property is a unique identifier for the user. It is
 * typically used for authentication and authorization purposes.
 * @property {string} awsEnvBucket - The `awsEnvBucket` property is a string that represents the name
 * of the AWS environment bucket associated with the user.
 * @property {string} awsPreviewsBucket - The `awsPreviewsBucket` property is a string that represents the name
 * of the AWS bucket with questions previews.
 * @property {boolean} canEditStudents - A boolean value indicating whether the user has permission to
 * edit student information.
 * @property {boolean} canEditStudentMetaFields - A boolean value indicating whether the user can edit
 * meta fields for students.
 * @property {string} timeZone - The `timeZone` property represents the time zone of the user. It is a
 * string that specifies the user's time zone, such as "America/New_York" or "Europe/London".
 * @property {boolean} canViewStudents - A boolean value indicating whether the user can view student
 * information.
 * @property {boolean} canExploreStudents - A boolean value indicating whether the user can explore
 * student data.
 * @property {boolean} canViewAndModifyTeachers - This property indicates whether the user has the
 * ability to view and modify teachers.
 * @property {boolean} canAddAndDeleteTeachers - This property indicates whether the user has the
 * ability to add and delete teachers.
 * @property agreementLevelCode - The `agreementLevelCode` property is a string that represents the
 * agreement level code for the user. It is one of the keys of the `UserType` enum.
 * @property {string} ssoRegistrationType - The `ssoRegistrationType` property is a string that
 * represents the type of Single Sign-On (SSO) registration for the user. It indicates how the user was
 * registered and authenticated in the system.
 * @property {string} createDate - The date when the user account was created.
 * @property {string} email - The email property represents the email address of the user.
 * @property {boolean} isLoggedByCredentials - A boolean value indicating whether the user is logged in
 * using their credentials (username and password).
 * @property {boolean} showSelfAssessOption - A boolean value indicating whether the user has the
 * option to view and complete self-assessment tasks.
 * @property {string} screenLockPIN - The `screenLockPIN` property is a string that represents the PIN
 * code used for locking the screen.
 * @property {boolean} lockScreen - A boolean value indicating whether the user's screen is locked or
 * not.
 * @property {boolean} canEditStudentLanguage - A boolean value indicating whether the user can edit
 * language field for student.
 * @property {string | null} userPhoto - The `userPhoto` property contain user photo URL,
 * if this property is `null`, user hasn't avatar.
 */
export type UserInfo = {
	accountID: string;
	firstName: string;
	lastName: string;
	awsEnvBucket: string;
	awsPreviewsBucket: string;
	schoolID: number;
	districtID: number;
	email: string;
	userGuid: string;
	userType: UserType;
	createDate: string;
	enableShortcuts: boolean;
	isLoggedByCredentials: boolean;
	studentSort: StudentSort;
	timeZone: string;
	correctButtonLabel: string;
	incorrectButtonLabel: string;
	stateID: number;
	ssoRegistrationType: string;
	userID: number;
	lastGlobalSchoolYearID: number;
	currentGlobalSchoolYearID: number;
	agreementLevelCode: string;
	credentialID: number;
	userCredentialStatus: UserCredentialStatus;
	userCredentialType: UserCredentialType;
	showSelfAssessOption: boolean;
	screenLockPIN: string;
	lockScreen: boolean;
	canTest: boolean;
	canEditStudents: boolean;
	canEditStudentMetaFields: boolean;
	canAddStudents: boolean;
	canExploreStudents: boolean;
	canViewAndModifyTeachers: boolean;
	canAddAndDeleteTeachers: boolean;
	canEditStudentLanguage: boolean;
	canViewStudents: boolean;
	canAddEditTestSessions: boolean;
	globalSchoolYearID: number;
	expirationDate: string;
	showNewUIVersion: boolean;
	avatarUrl: string | null;
	testResultsIncorrectVerbiage: string;
	testResultsCorrectVerbiage: string;
	canManageClasses: boolean;
}

/* DEPRECATED */

export interface IUserContext {
	DistrictID?: number;
	GlobalSchoolYearID: number;
	LastGlobalSchoolYearID: number;
	CurrentGlobalSchoolYearID: number;
	SchoolID: number;
	StateID: number;
	UserID: number;
	UserType: 'D' | 'C' | 'T' | 'ISS' | 'ISD' | 'PA';
	FirstName: string;
	LastName: string;
	UserGuid: string;
	AwsEnvBucket: string;
	AwsPreviewsBucket: string;
	CanEditStudents: boolean;
	TimeZone: string;
	CanExploreStudents: boolean;
	CanViewAndModifyTeachers: boolean;
	CanAddAndDeleteTeachers: boolean;
	SsoRegistrationType: string;
	CreateDate: string;
	Email: string;
	IsLoggedByCredentials: boolean;
	ShowSelfAssessOption: boolean;
	ScreenLockPIN: string;
	LockScreen: boolean;
}
