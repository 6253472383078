import {Container, TitleBox, TitleUnderline} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {FolderNavigationItem} from './components/folder-navigation-item';
import {Folder} from '@esgi/main/features/school-admin/data';

type Props = {
	folders: Folder[];
	entityName: string;
};

export function FoldersSection({folders, entityName}: Props) {
	return (
		<Container>
			<TitleBox>
				<Text size='small' font='mono' color='mediumContrast'>
					{entityName} Folders
				</Text>
				<TitleUnderline />
			</TitleBox>

			{folders.map((folder) => (
				<FolderNavigationItem {...folder} key={folder.id} />
			))}
		</Container>
	);
}
