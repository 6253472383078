import {isUndefined} from '@esgi/ui';
import {GridItemHTMLElement, GridStack, GridStackNode} from 'gridstack';

function hasItemsInTheRow({
	allItems,
	element: {gridstackNode},
}: {
	allItems: GridItemHTMLElement[];
	element: Omit<GridItemHTMLElement, 'gridstackNode'> & {
		gridstackNode: GridStackNode;
	};
}) {
	const x = gridstackNode.x ?? 0;
	const y = gridstackNode.y ?? 0;
	const id = gridstackNode.id;

	return allItems.some((item) => {
		const itemX = item.gridstackNode?.x ?? 0;
		const itemY = item.gridstackNode?.y ?? 0;
		const itemID = item.gridstackNode?.id;

		return itemID !== id && itemY === y && itemX < x;
	});
}

export function drag({element, gridStackInstance}: {element: GridItemHTMLElement; gridStackInstance: GridStack}) {
	if (isUndefined(element.gridstackNode)) {
		return;
	}

	const allItems = gridStackInstance.getGridItems();

	const existsItemsInTheRow = hasItemsInTheRow({allItems, element: {...element, gridstackNode: element.gridstackNode}});

	if (!existsItemsInTheRow) {
		element.gridstackNode.x = 0;
	}

	allItems.forEach((iteratedItem) => {
		if (isUndefined(iteratedItem.gridstackNode) || iteratedItem.gridstackNode.id === element.gridstackNode?.id) {
			return;
		}

		const existsItemsInTheRow = hasItemsInTheRow({
			allItems,
			element: {...element, gridstackNode: iteratedItem.gridstackNode},
		});

		if (!existsItemsInTheRow) {
			gridStackInstance.update(iteratedItem, {
				...iteratedItem.gridstackNode,
				x: 0,
			});
		}
	});
}
