import {Fragment, Suspense} from 'react';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {lazyComponent} from '@esgi/core/react';
import {useFakeChangesCollector} from './utils';

const PieChartReport = lazyComponent(() => import('shared/modules/reports/pie-chart/report'));

type Props = {
	subjectID: number;
	subjectType: number;
	hierarchy: HierarchyInstance;
	onClose: VoidFunction;
}

export default function PieChartsLauncher(props: Props) {
	const changesCollector = useFakeChangesCollector(props.hierarchy);

	return <Suspense fallback={<Fragment/>}>
		<PieChartReport
			hierarchy={props.hierarchy.snapshot}
			close={props.onClose}
			changeCollector={changesCollector}
			subjectID={props.subjectID}
		/>
	</Suspense>;
}
