import {useCallback} from 'react';
import {useFlag} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {useUser} from '@esgi/core/authentication';
import {Folder as FolderIcon} from '@esgi/ui/icons';
import {
	Folder,
	FolderType,
} from '@esgi/main/features/school-admin/data';
import {
	CollapsiblePanel,
} from '@esgi/main/features/admins/data';
import {CollapsiblePanelHeader, TitleBox} from './index.styled';
import {ReportDialog, Reports} from '../reports-dialog';
import {RunReportButton} from '../run-report-button';
import {NoStudentsAlert} from '../no-students-alert';
import {additionalFolderData} from './constants';
import {FolderReportModel} from './types';

type Props = {
	folderID: Folder['id'];
	folderName: string;
	folderType: FolderType;
	isShowPanel: boolean;
	reports: FolderReportModel[];
	studentsCountInFolder: number;
	rowsCountInFolder: number;
};

export function FolderNoSelectedItemsPanel({
	folderID,
	folderName,
	isShowPanel,
	folderType,
	reports,
	studentsCountInFolder,
	rowsCountInFolder,
}: Props) {
	const user = useUser();

	const managerRef = CollapsiblePanel.useRef();

	const [showFolderReports, openFolderReports, closeFolderReports] = useFlag();
	const [showNoStudentsAlert, openNoStudentsAlert, closeNoStudentsAlert] =
		useFlag();

	const handleRunReportButton = useCallback(() => {
		if (!studentsCountInFolder) {
			openNoStudentsAlert();
			return;
		}

		openFolderReports();
	}, [openFolderReports, openNoStudentsAlert, studentsCountInFolder]);

	if (!isShowPanel) {
		return null;
	}

	const {
		hierarchyKey,
		title,
		noStudentsSinglePrefix,
		noStudentsMultiplePrefix,
	} = additionalFolderData[folderType];

	return (
		<>
			<CollapsiblePanel.Root managerRef={managerRef}>
				<CollapsiblePanel.QuickPanel>
					<CollapsiblePanelHeader>
						<TitleBox>
							<FolderIcon />
							<Text size='large' color='highContrast'>
								{folderName}
							</Text>
						</TitleBox>
						<RunReportButton
							title={title}
							onClick={handleRunReportButton}
							disabled={!studentsCountInFolder}
							disabledMessage={
								studentsCountInFolder > 0
									? undefined
									: 'No students have been added to your class. Please add some before running the report.'
							}
						/>
					</CollapsiblePanelHeader>
				</CollapsiblePanel.QuickPanel>
			</CollapsiblePanel.Root>

			{showNoStudentsAlert && (
				<NoStudentsAlert
					onClose={closeNoStudentsAlert}
					prefix={
						rowsCountInFolder === 1
							? noStudentsSinglePrefix
							: noStudentsMultiplePrefix
					}
				/>
			)}

			{showFolderReports && (
				<ReportDialog
					onClose={closeFolderReports}
					header={folderName}
					title={title}
				>
					{reports.map(({type, name, description}, index) => {
						const ReportComponent = Reports[type];

						return (
							<ReportComponent
								hierarchy={{
									schoolID: user?.schoolID,
									[hierarchyKey]: folderID,
								}}
								name={name}
								description={description}
								key={`${type}-${index}`}
							/>
						);
					})}
				</ReportDialog>
			)}
		</>
	);
}
