import {DashboardWidgetOptions} from '@esgi/main/features/district-admin/dashboard';
import {isNull} from 'underscore';
import {getGridstackItemPosition} from '../../../utils/get-gridstack-item-position';
import {WidgetViewSize} from '@esgi/main/features/admins/dashboard';

export function getItemPosition({
	options: {districtPerformanceWidgetOptions, demographicPerformanceWidgetOptions, lowestAchievementWidgetOptions},
	itemIndex,
	calculateItemPosition,
}: {
	options: DashboardWidgetOptions;
	itemIndex: number;
	calculateItemPosition: ReturnType<typeof getGridstackItemPosition>;
}) {
	if (!isNull(districtPerformanceWidgetOptions)) {
		return calculateItemPosition({
			itemIndex,
			isFullSize: districtPerformanceWidgetOptions.viewSize === WidgetViewSize.FullSize,
		});
	}

	if (!isNull(demographicPerformanceWidgetOptions)) {
		return calculateItemPosition({
			itemIndex,
			isFullSize: demographicPerformanceWidgetOptions.viewSize === WidgetViewSize.FullSize,
		});
	}

	if (!isNull(lowestAchievementWidgetOptions)) {
		return calculateItemPosition({itemIndex});
	}

	throw new Error('Option type is not unattainable');
}
