import {isNull} from 'underscore';
import {useCallback, useEffect} from 'react';
import {DatePickerData} from '@esgi/main/kits/admin';
import {ElementStatus, FormControl, FormGroup, useForm} from '@esgi/ui/form';
import {
	BoxplotSinglePeriodForm,
	BoxplotSinglePeriodFormColorItem,
	BoxplotTwoPeriodsForm,
	BoxplotTwoPeriodsFormColorItem,
	BoxplotTwoClassesForm,
	BoxplotTwoClassesFormColorItem,
	BoxplotTwoClassesFormClassIDItem,
	CommonForm,
	CommonFormDemographicGroupItem,
	PiechartsForm,
} from './types';
import {
	useInitialBoxplotSinglePeriodForm,
	useInitialBoxplotTwoPeriodsForm,
	useInitialBoxplotTwoClassesForm,
	useInitialCommonForm,
	useInitialPiechartsForm,
} from './use-initial-form-data';
import {setPeriodsValueForBoxplotTwoPeriodsForm} from './helpers/set-periods-value-for-boxplot-two-periods-form';
import {setClassValueForBoxplotTwoClassesForm} from './helpers/set-class-value-for-boxplot-two-classes-form';
import {ClassModel} from '../../../../../../types';
import {setFormControlsValidStatus} from './helpers/set-form-controls-valid-status';
import {datePickerDataForInitialization} from './constants';
import {highlightValueValidation} from './validators';
import {BoxplotCompareVariant} from '../types';
import {highlightMaxValue} from '../constants';
import {PerformanceWidgetViewType, Widget} from '@esgi/main/features/admins/dashboard';
import {DemographicPerformanceWidgetOptions} from '../../../../../types/demographic-performance';

export function useFormsInit({
	widgetOptions,
	allClassesList,
}: {
	widgetOptions: DemographicPerformanceWidgetOptions;
	allClassesList: ClassModel[];
}) {
	const initialCommonForm = useInitialCommonForm({widgetOptions});
	const initialBoxplotSinglePeriodForm = useInitialBoxplotSinglePeriodForm({widgetOptions});
	const initialBoxplotTwoPeriodsForm = useInitialBoxplotTwoPeriodsForm({widgetOptions});
	const initialBoxplotTwoClassesForm = useInitialBoxplotTwoClassesForm({widgetOptions});

	const initialPiechartsForm = useInitialPiechartsForm({widgetOptions});

	const commonForm: CommonForm = useForm(
		() =>
			new FormGroup({
				viewType: new FormControl(widgetOptions.viewType),
				size: new FormControl(widgetOptions.viewSize),
				isShowAverageValue: new FormControl(widgetOptions.showAvg),
				demographicGroup: new FormControl<CommonFormDemographicGroupItem>([widgetOptions.demographicGroup]),
				boxplotCompareVariant: new FormControl<BoxplotCompareVariant>(BoxplotCompareVariant.None, {
					initialValidateStatus:
						widgetOptions.viewType === PerformanceWidgetViewType.Piechart
							? ElementStatus.disabled
							: ElementStatus.valid,
				}),
			}),
	);

	const boxplotSinglePeriodForm: BoxplotSinglePeriodForm = useForm(
		() =>
			new FormGroup({
				date: new FormControl<DatePickerData>(datePickerDataForInitialization),
				isHighlightCandles: new FormControl(false),
				highlightColor: new FormControl<BoxplotSinglePeriodFormColorItem>([]),
				highlightValue: new FormControl<number | ''>('', {
					validators: [highlightValueValidation(highlightMaxValue)],
				}),
			}),
	);

	const boxplotTwoPeriodsForm: BoxplotTwoPeriodsForm = useForm(
		() =>
			new FormGroup({
				firstPeriodColor: new FormControl<BoxplotTwoPeriodsFormColorItem>([null]),
				firstPeriodDate: new FormControl<DatePickerData>(datePickerDataForInitialization),
				secondPeriodColor: new FormControl<BoxplotTwoPeriodsFormColorItem>([null]),
				secondPeriodDate: new FormControl<DatePickerData>(datePickerDataForInitialization),
			}),
	);

	const boxplotTwoClassesForm: BoxplotTwoClassesForm = useForm(
		() =>
			new FormGroup({
				date: new FormControl<DatePickerData>(datePickerDataForInitialization),
				firstClassID: new FormControl<BoxplotTwoClassesFormClassIDItem>([null]),
				firstClassColor: new FormControl<BoxplotTwoClassesFormColorItem>([null]),
				secondClassID: new FormControl<BoxplotTwoClassesFormClassIDItem>([null]),
				secondClassColor: new FormControl<BoxplotTwoClassesFormColorItem>([null]),
			}),
	);

	const piechartsForm: PiechartsForm = useForm(
		() =>
			new FormGroup({
				date: new FormControl<DatePickerData>(datePickerDataForInitialization),
			}),
	);

	const setBoxplotSinglePeriodFormInitialData = useCallback(() => {
		setFormControlsValidStatus(boxplotSinglePeriodForm);

		const {date, isHighlightCandles, highlightColor, highlightValue} = boxplotSinglePeriodForm.controls;
		const {date: dateInitial, highlight} = initialBoxplotSinglePeriodForm;

		const isHighlighed = !isNull(highlight);
		const highlightDataStatus = isHighlighed ? ElementStatus.valid : ElementStatus.disabled;

		date.value = dateInitial;
		isHighlightCandles.value = isHighlighed;

		highlightColor.status = highlightDataStatus;
		highlightValue.status = highlightDataStatus;

		if (isHighlighed) {
			const {color, highlightIfAbove} = highlight;

			highlightColor.value = [color];
			highlightValue.value = highlightIfAbove;
		} else {
			highlightColor.value = [Widget.colorItems[0]?.value ?? null];
			highlightValue.value = 50;
		}
	}, [boxplotSinglePeriodForm, initialBoxplotSinglePeriodForm]);

	const setBoxplotTwoPeriodsFormInitialData = useCallback(() => {
		setFormControlsValidStatus(boxplotTwoPeriodsForm);

		const {firstPeriodColor, firstPeriodDate, secondPeriodColor, secondPeriodDate} = boxplotTwoPeriodsForm.controls;
		const {firstPeriod, secondPeriod} = initialBoxplotTwoPeriodsForm;

		setPeriodsValueForBoxplotTwoPeriodsForm({
			periodValue: firstPeriod,
			colorControl: firstPeriodColor,
			dateRangeControl: firstPeriodDate,
			defaultColorItemsIndex: 0,
		});

		setPeriodsValueForBoxplotTwoPeriodsForm({
			periodValue: secondPeriod,
			colorControl: secondPeriodColor,
			dateRangeControl: secondPeriodDate,
			defaultColorItemsIndex: 1,
		});
	}, [boxplotTwoPeriodsForm, initialBoxplotTwoPeriodsForm]);

	const setBoxplotTwoClassesFormInitialData = useCallback(() => {
		setFormControlsValidStatus(boxplotTwoClassesForm);

		const {date, firstClassColor, firstClassID, secondClassColor, secondClassID} = boxplotTwoClassesForm.controls;
		const {date: dateValue, firstClass, secondClass} = initialBoxplotTwoClassesForm;

		date.value = dateValue;

		setClassValueForBoxplotTwoClassesForm({
			classValue: firstClass,
			classColorControl: firstClassColor,
			classIDControl: firstClassID,
			defaultItemsIndex: 0,
			allClassesList,
		});

		setClassValueForBoxplotTwoClassesForm({
			classValue: secondClass,
			classColorControl: secondClassColor,
			classIDControl: secondClassID,
			defaultItemsIndex: 1,
			allClassesList,
		});
	}, [allClassesList, boxplotTwoClassesForm, initialBoxplotTwoClassesForm]);

	const setPiechartsFormInitialData = useCallback(() => {
		setFormControlsValidStatus(piechartsForm);

		const {date} = piechartsForm.controls;
		const {date: dateValue} = initialPiechartsForm;

		date.value = dateValue;
	}, [initialPiechartsForm, piechartsForm]);

	useEffect(() => {
		const {boxplotCompareVariant} = commonForm.controls;

		boxplotCompareVariant.value = initialCommonForm.boxplotCompareVariant;
	}, [commonForm.controls, initialCommonForm]);

	useEffect(setBoxplotSinglePeriodFormInitialData, [initialBoxplotSinglePeriodForm]);
	useEffect(setBoxplotTwoPeriodsFormInitialData, [initialBoxplotTwoPeriodsForm]);
	useEffect(setBoxplotTwoClassesFormInitialData, [initialBoxplotTwoClassesForm]);
	useEffect(setPiechartsFormInitialData, [initialPiechartsForm]);

	return {
		commonForm,
		boxplotSinglePeriodForm,
		boxplotTwoPeriodsForm,
		boxplotTwoClassesForm,
		piechartsForm,
		initialCommonForm,
		initialBoxplotSinglePeriodForm,
		initialBoxplotTwoPeriodsForm,
		initialBoxplotTwoClassesForm,
		setBoxplotSinglePeriodFormInitialData,
		setBoxplotTwoPeriodsFormInitialData,
		setBoxplotTwoClassesFormInitialData,
		setPiechartsFormInitialData,
	};
}
