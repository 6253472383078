import React from 'react';
import {CardBodyBox, ProgressBarsDivider, ProgressBarsWrapper} from './index.styled';
import {Box, Card, GridBox} from '@esgi/ui/layout';
import {TextWithTranscript, TextWithTranscriptProps} from '../text-with-transcript';
import {isNull} from 'underscore';
import {CardActionButtons} from '../card-action-buttons';
import {TestSession, TestSingleStudentModel} from '@esgi/main/features/teacher/home';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {ClassGroupActionButtonsProps, StudentActionButtonsProps} from '../card-action-buttons/types';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {onCardHoverText} from '../../../constants';

type Props = {
	textWithTranscriptItems: (TextWithTranscriptProps | null)[];
	progressBars: React.JSX.Element[];
	isSmallScreen: boolean;
	lastTestSessions: TestSession[];
	sessionsCount: number;
	openTestDetails: VoidFunction;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	testInfo: TestSingleStudentModel['testInfo'],
	onTestMoveTo: (args: {previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}) => void;
	onRemoveTest: (args: {subjectID: SubjectTab['id']; testID: number}) => void;
	subjectID: SubjectTab['id'];
	classID: Class['id'];
} & (StudentActionButtonsProps | ClassGroupActionButtonsProps);

export function CardBody({
	textWithTranscriptItems,
	progressBars,
	isSmallScreen,
	lastTestSessions,
	sessionsCount,
	openTestDetails,
	userCanEditTest,
	userCanRemoveTest,
	testInfo,
	onRemoveTest,
	onTestMoveTo,
	subjectID,
	classID,
	...props
}: Props) {
	return (
		<Tooltip>
			<Tooltip.Trigger>
				<GridBox gap={2}>
					<CardBodyBox withPadding={!isSmallScreen}>
				<ProgressBarsWrapper>
					{progressBars.map((progressBarComponent, index) => (
						<React.Fragment key={index}>
							{progressBarComponent}
							{index !== progressBars.length - 1 && !isSmallScreen && <ProgressBarsDivider />}
						</React.Fragment>
					))}
				</ProgressBarsWrapper>

				<GridBox gapX='8' gapY='4' rows='2' columns='2'>
					{textWithTranscriptItems.map((itemProps, index) =>
						isNull(itemProps) ? <Box key={index} /> : <TextWithTranscript {...itemProps} key={index} />,
					)}
				</GridBox>
			</CardBodyBox>
					{isSmallScreen && (
						<GridBox gap={2}>
							<Card.Separator />
							<CardActionButtons
								testInfo={testInfo}
								lastTestSessions={lastTestSessions}
								sessionsCount={sessionsCount}
								openTestDetails={openTestDetails}
								userCanEditTest={userCanEditTest}
								userCanRemoveTest={userCanRemoveTest}
								onTestMoveTo={onTestMoveTo}
								onRemoveTest={onRemoveTest}
								isSmallScreen
								classID={classID}
								subjectID={subjectID}
								{...props}
							/>
						</GridBox>
					)}
				</GridBox>
			</Tooltip.Trigger>
			<Tooltip.Content side='top'>
				<Text size='large' color='tertiary'>
					{onCardHoverText}
				</Text>
			</Tooltip.Content>
		</Tooltip>
	);
}
