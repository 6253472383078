import {TestResultsAdditionalEntity, TestResultsStudent} from '@esgi/main/kits/admin';
import {useStudents} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {RowDef} from '../types';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useTestResultsSubject} from '../../_kit';

type Parameters = {
	selectedRow: RowDef;
};

export function useTestResultsData({selectedRow}: Parameters) {
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const filteredStudents = useMemo(
		() =>
			students
				.filter(
					({schoolID, teacherID, specialistsIDs}) =>
						selectedRow.id === schoolID && Boolean(teacherID || specialistsIDs.length),
				)
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {isReady: isTestResultsSubjectReady, subjects: testResultsSubjects} = useTestResultsSubject({
		schoolID: selectedRow.id,
		students: filteredStudents,
		additionalStoreSubjectsFilter: 'schools',
	});

	const hierarchy = useMemo<Hierarchy>(
		() => ({
			schoolID: selectedRow.id,
		}),
		[selectedRow],
	);

	const additionalInfo = useMemo<TestResultsAdditionalEntity>(
		() => ({
			school: {
				id: selectedRow.id,
				name: selectedRow.name,
			},
		}),
		[selectedRow],
	);

	return {
		subjects: testResultsSubjects,
		students: filteredStudents,
		skeleton: !isTestResultsSubjectReady || !isStudentsLoaded,
		hierarchy,
		additionalInfo,
	};
}
