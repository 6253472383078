import {Move} from '@esgi/ui/icons';
import {Container, ItemNumberBox, RearrangeButton} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {useCallback, useState} from 'react';

type Props = {
	isDragging: boolean;
	itemNumber: number;
	rearrangeHandleClassName: string;
};

export function RearrangeWidgetSubstrate({isDragging, itemNumber, rearrangeHandleClassName}: Props) {
	const [isHovered, setIsHovered] = useState(false);

	const handleMouseEnter = useCallback(() => {
		setIsHovered(true);
	}, []);

	const handleMouseLeave = useCallback(() => {
		setIsHovered(false);
	}, []);

	return (
		<Container isDragging={isDragging} isHover={isHovered}>
			<ItemNumberBox align='center' justify='center' isDragging={isDragging} isHover={isHovered}>
				<Text size='xxLarge' color='vivid'>
					{String(itemNumber).padStart(2, '0')}
				</Text>
			</ItemNumberBox>

			<RearrangeButton
				className={rearrangeHandleClassName}
				align='center'
				justify='center'
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				isDragging={isDragging}
				isHover={isHovered}
			>
				<Move />
			</RearrangeButton>
		</Container>
	);
}
