import {Dispatch} from 'react';
import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {SubjectLevel} from '@esgi/main/libs/store';
import {StyledCheap} from './index.styled';
import {subjectPrefixByLevel, SubjectLevel as SubjectLevelCommon} from '@esgi/main/kits/common';

interface Props {
	selectedSubjectLevel: SubjectLevel;
	onSubjectLevelSelect: Dispatch<SubjectLevel>;
}

export function SubjectLevelFilter({selectedSubjectLevel, onSubjectLevelSelect}: Props) {
	return (
		<ToggleGroup.Root value={selectedSubjectLevel} onValueChange={onSubjectLevelSelect}>
			<ToggleGroup.Content>
				<ToggleGroup.Item value={SubjectLevel.School}>
					<StyledCheap color='primary'>
						<Text size='medium'>{subjectPrefixByLevel[SubjectLevelCommon.School]}</Text>
					</StyledCheap>
				</ToggleGroup.Item>
				<ToggleGroup.Item value={SubjectLevel.District}>
					<StyledCheap color='secondary'>
						<Text size='medium'>{subjectPrefixByLevel[SubjectLevelCommon.District]}</Text>
					</StyledCheap>
				</ToggleGroup.Item>
			</ToggleGroup.Content>
		</ToggleGroup.Root>
	);
}
