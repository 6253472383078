import {IconComponent, useFlag} from '@esgi/ui';
import {Dispatch, useMemo} from 'react';
import {SelectableEntityDrawer} from './selectable-entity-drawer';
import {SelectableListItemModel} from './types';
import {SelectDrawerableBase} from '../../../kit/select-drawerable-base';
import {usePanelContext} from '../../context';

type Props<T extends string> = {
	placeholder: string;
	drawerTitle: string;
	value: T[];
	onSaveSelectedEntity: Dispatch<T[]>;
	selectedValueTranscript: string;
	allItems: SelectableListItemModel<T>[];
	EntityIcon: IconComponent;
	disabled?: boolean;
};

export function SelectDrawerable<T extends string>({
	placeholder,
	drawerTitle,
	value,
	onSaveSelectedEntity,
	selectedValueTranscript,
	allItems,
	EntityIcon,
	disabled,
}: Props<T>) {
	const {skeleton} = usePanelContext();

	const [isDrawerOpen, openDrawer, closeDrawer] = useFlag();

	const valueTranscript = useMemo(() => {
		if (value.length === allItems.length) {
			return 'All';
		}

		if (value.length === 1) {
			return allItems.find((item) => item.value === value[0])?.label ?? '';
		}

		return `${value.length} ${selectedValueTranscript}`;
	}, [allItems, selectedValueTranscript, value]);

	return (
		<>
			<SelectDrawerableBase
				placeholder={placeholder}
				valueTranscript={valueTranscript}
				disabled={Boolean(disabled)}
				onClick={openDrawer}
				skeleton={skeleton}
			/>

			{isDrawerOpen && (
				<SelectableEntityDrawer
					EntityIcon={EntityIcon}
					allItems={allItems}
					title={drawerTitle}
					onClose={closeDrawer}
					value={value}
					onSaveSelectedEntity={onSaveSelectedEntity}
				/>
			)}
		</>
	);
}
