import {useMemo} from 'react';
import {CurrentTrackDate} from './types';
import {TrackModel} from '../../types';
import {isNull} from 'underscore';
import {trackDateLabels} from './constants';

type Parameters = {
	currentTrack: TrackModel | null;
};

export function useCurrentTrackDates({currentTrack}: Parameters) {
	return useMemo<CurrentTrackDate[]>(() => {
		if (isNull(currentTrack)) {
			return [];
		}

		const tracksLabels = trackDateLabels[currentTrack.schoolYearTypeID] ?? [];

		return currentTrack.trackDates.map(({trackDateID}, index) => ({
			id: trackDateID,
			name: tracksLabels[index] ?? '',
		}));
	}, [currentTrack]);
}
