import {isUndefined} from '@esgi/ui';
import {Hierarchy, HierarchyMode} from '../types';

export function getHierarchyMode({preAssessID, specialistsID}: Hierarchy): HierarchyMode {
	if (!isUndefined(specialistsID)) {
		return HierarchyMode.Specialist;
	}

	if (!isUndefined(preAssessID)) {
		return HierarchyMode.PreAssess;
	}

	return HierarchyMode.Classic;
}
