import {User} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {FormElementSelect} from '../../../../../../components/form-element-select';
import {getFullName} from '@esgi/main/kits/common';
import {FormSelectItem} from '../../../../../../components/form-element-select/types';
import {districtSpecialistSelectErrorItems} from './constants';
import {DistrictSpecialistForm} from '../../../../forms-data/types';
import {ErrorMessageBox} from '../../../../../../components/error-message-box';

type Props = {
	districtSpecialists: User[];
	form: DistrictSpecialistForm;
	formInactiveMessage: string | null;
};

export function DistrictSpecialistContent({districtSpecialists, form, formInactiveMessage}: Props) {
	const districtSpecialistsItems = useMemo(
		() =>
			districtSpecialists.map<FormSelectItem<string>>(({id, firstName, lastName}) => ({
				value: String(id),
				label: getFullName({firstName, lastName}),
			})),
		[districtSpecialists],
	);

	return (
		<>
			<ErrorMessageBox message={formInactiveMessage} />

			<FormElementSelect
				placeholder='District Specialist'
				control={form.controls.districtSpecialistID}
				items={districtSpecialistsItems}
				errorItems={districtSpecialistSelectErrorItems}
			/>
		</>
	);
}
