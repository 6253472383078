import {DeleteButton} from '../../_kit';

type Props = {
	isMultipleSelected?: boolean;
};

export function DeleteSpecialistsButton({isMultipleSelected}: Props) {
	return (
		<DeleteButton
			disabled
			tooltipMessage={
				isMultipleSelected
					? 'Select only one item for deletion.'
					: 'To delete the Specialist, please contact Customer Support support@esgisoftware.com'
			}
			whenShowTooltip='disabled'
		/>
	);
}
