import {useCallback, useEffect} from 'react';
import {DatePickerData} from '@esgi/main/kits/admin';
import {ElementStatus, FormControl, FormGroup, useForm} from '@esgi/ui/form';
import {
	useInitialCommonForm,
	useInitialSchoolsForm,
	useInitialStudentsSinglePeriodForm,
	useInitialStudentsTwoPeriodsForm,
} from './use-initial-form-data';
import {
	CommonForm,
	DistrictCutoffItem,
	StudentsTwoPeriodsForm,
	StudentsTwoPeriodsFormColorItem,
	DatePickerSingleForm,
} from './types';
import {setPeriodsValueForStudentsTwoPeriodsForm} from './helpers/set-periods-value-for-students-two-periods-form';
import {LowestAchievementWidgetViewType} from '../../../../../types/lowest-achievement';
import {setFormControlsValidStatus} from './helpers/set-form-controls-valid-status';
import {AdaptedWidgetOptions, PeriodsVariant} from '../types';
import {datePickerDataForInitialization} from './constants';

export function useFormsInit({widgetOptions}: {widgetOptions: AdaptedWidgetOptions}) {
	const initialCommonForm = useInitialCommonForm({widgetOptions});

	const initialStudentsSinglePeriodForm = useInitialStudentsSinglePeriodForm({widgetOptions});
	const initialStudentsTwoPeriodsForm = useInitialStudentsTwoPeriodsForm({widgetOptions});

	const initialSchoolsForm = useInitialSchoolsForm({widgetOptions});

	const commonForm: CommonForm = useForm(
		() =>
			new FormGroup({
				viewType: new FormControl(widgetOptions.viewType),
				periodsVariant: new FormControl<PeriodsVariant>(PeriodsVariant.None, {
					initialValidateStatus:
						widgetOptions.viewType === LowestAchievementWidgetViewType.Schools
							? ElementStatus.disabled
							: ElementStatus.valid,
				}),
				districtCutoff: new FormControl<DistrictCutoffItem>([widgetOptions.districtCutoff]),
			}),
	);

	const studentsSinglePeriodForm: DatePickerSingleForm = useForm(
		() =>
			new FormGroup({
				date: new FormControl<DatePickerData>(datePickerDataForInitialization),
			}),
	);

	const studentsTwoPeriodsForm: StudentsTwoPeriodsForm = useForm(
		() =>
			new FormGroup({
				firstPeriodColor: new FormControl<StudentsTwoPeriodsFormColorItem>([null]),
				firstPeriodDate: new FormControl<DatePickerData>(datePickerDataForInitialization),
				secondPeriodColor: new FormControl<StudentsTwoPeriodsFormColorItem>([null]),
				secondPeriodDate: new FormControl<DatePickerData>(datePickerDataForInitialization),
			}),
	);

	const schoolsForm: DatePickerSingleForm = useForm(
		() =>
			new FormGroup({
				date: new FormControl<DatePickerData>(datePickerDataForInitialization),
			}),
	);

	const setStudentsSinglePeriodFormInitialData = useCallback(() => {
		setFormControlsValidStatus(studentsSinglePeriodForm);

		const {date} = studentsSinglePeriodForm.controls;

		date.value = initialStudentsSinglePeriodForm;
	}, [initialStudentsSinglePeriodForm, studentsSinglePeriodForm]);

	const setStudentsTwoPeriodsFormInitialData = useCallback(() => {
		setFormControlsValidStatus(studentsTwoPeriodsForm);

		const {firstPeriodColor, firstPeriodDate, secondPeriodColor, secondPeriodDate} = studentsTwoPeriodsForm.controls;
		const {firstPeriod, secondPeriod} = initialStudentsTwoPeriodsForm;

		setPeriodsValueForStudentsTwoPeriodsForm({
			periodValue: firstPeriod,
			colorControl: firstPeriodColor,
			dateRangeControl: firstPeriodDate,
			defaultColorItemsIndex: 0,
		});

		setPeriodsValueForStudentsTwoPeriodsForm({
			periodValue: secondPeriod,
			colorControl: secondPeriodColor,
			dateRangeControl: secondPeriodDate,
			defaultColorItemsIndex: 1,
		});
	}, [initialStudentsTwoPeriodsForm, studentsTwoPeriodsForm]);

	const setSchoolsFormInitialData = useCallback(() => {
		setFormControlsValidStatus(schoolsForm);

		const {date} = schoolsForm.controls;

		date.value = initialSchoolsForm;
	}, [initialSchoolsForm, schoolsForm]);

	useEffect(() => {
		const {periodsVariant} = commonForm.controls;

		periodsVariant.value = initialCommonForm.periodsVariant;
	}, [initialCommonForm]);

	useEffect(setStudentsSinglePeriodFormInitialData, [initialStudentsSinglePeriodForm]);
	useEffect(setStudentsTwoPeriodsFormInitialData, [initialStudentsTwoPeriodsForm]);
	useEffect(setSchoolsFormInitialData, [initialSchoolsForm]);

	return {
		commonForm,
		studentsSinglePeriodForm,
		studentsTwoPeriodsForm,
		schoolsForm,
		initialCommonForm,
		initialStudentsTwoPeriodsForm,
		setStudentsSinglePeriodFormInitialData,
		setStudentsTwoPeriodsFormInitialData,
		setSchoolsFormInitialData,
	};
}
