import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RowDef} from '../../../../types';
import {NoStudentsAlert, ReportDialog, Reports} from '../../../../../_kit';
import {UserType} from '@esgi/core/authentication';
import {UserType as UserTypeStorage} from '@esgi/main/libs/admin-store';

type Props = {
	group: RowDef;
	onClose: VoidFunction;
};

export function ReportsFeature({group, onClose}: Props) {
	const hierarchy = useMemo<Hierarchy>(() => {
		const specialistType = group.specialist.userType;

		return {
			specialistsID: group.specialist.id,
			specialistGroupID: group.id,
			...(specialistType === UserTypeStorage.ISD && {
				specialistType: UserType.ISD,
			}),
			...(specialistType === UserTypeStorage.ISS && {
				specialistType: UserType.ISS,
			}),
		};
	}, [group]);

	if (!group.students) {
		return <NoStudentsAlert onClose={onClose} />;
	}

	return (
		<ReportDialog onClose={onClose} header={group.name}>
			<ReportDialog.Section title='Student Reports'>
				<Reports.ParentLetter hierarchy={hierarchy} />
				<Reports.StudentDetail hierarchy={hierarchy} />
				<Reports.StudentProgress hierarchy={hierarchy} />
			</ReportDialog.Section>
			<ReportDialog.Section title='Specialist Group Reports'>
				<Reports.Totals prefix='Specialist Group' hierarchy={hierarchy} />
				<Reports.GradeScale prefix='Specialist Group' hierarchy={hierarchy} />
				<Reports.ItemAnalysis hierarchy={hierarchy} />
				<Reports.PieCharts hierarchy={hierarchy} />
				<Reports.RubricResults hierarchy={hierarchy} />
				<Reports.UntestedStudents hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
