import {ChangeEvent, Dispatch, SetStateAction, useCallback, useState} from 'react';
import {Input} from '@esgi/main/features/admins/dashboard';
import {HighlightState} from '../types';

type Props = {
	highlightState: HighlightState;
	setHighlightState: Dispatch<SetStateAction<HighlightState>>;
	disabled: boolean;
};

export function InputPercantage({highlightState, setHighlightState, disabled}: Props) {
	const [isHighlightInputFocused, setIsHighlightInputFocused] = useState(false);

	const handleUpdateScoreValue = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const fullValue = event.target.value;

			const value = fullValue.replace('%', '').trim();

			const onlyNumberRegex = /^\d+$/;
			const maxValue = 100;

			if (value === '' || onlyNumberRegex.test(value)) {
				if (value === '') {
					setHighlightState((currentState) => ({
						...currentState,
						value,
						validationMessage: 'Highlight value required',
					}));

					return;
				}

				const numberedValue = Number(value);

				if (numberedValue > maxValue) {
					setHighlightState((currentState) => ({
						...currentState,
						value,
						validationMessage: `Highlight value out of range 0-${maxValue}`,
					}));

					return;
				}

				setHighlightState((currentState) => ({
					...currentState,
					value,
					validationMessage: null,
				}));
			}
		},
		[setHighlightState],
	);

	const handleHighlightInputFocus = useCallback(() => {
		setIsHighlightInputFocused(true);
	}, []);

	const handleHighlightInputBlur = useCallback(() => {
		setIsHighlightInputFocused(false);
	}, []);

	return (
		<Input
			placeholder='Highlight If Above'
			value={isHighlightInputFocused || !highlightState.value ? highlightState.value : `${highlightState.value}%`}
			onChange={handleUpdateScoreValue}
			error={highlightState.validationMessage ?? undefined}
			disabled={disabled}
			onBlur={handleHighlightInputBlur}
			onFocus={handleHighlightInputFocus}
		/>
	);
}
