import {Button, Tooltip} from '@esgi/ui';
import {Close} from '@esgi/ui/icons';
import {ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback} from 'react';
import {useSingleSelectedExpandablePanelContext} from '../context';
import {Text} from '@esgi/ui/typography';

type Props = Omit<ComponentPropsWithoutRef<typeof Button.Icon>, 'children'> & {
	tooltipText: string;
};

export const ClosePanelButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			dataCy = 'single-selected-expandable-panel-close-panel-button',
			css = {},
			color = 'secondary',
			onClick,
			withBackgroundHover = true,
			tooltipText,
			...props
		},
		forwardedRef,
	) => {
		const {closePanel} = useSingleSelectedExpandablePanelContext();

		const handleClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				closePanel();
				onClick?.(event);
			},
			[onClick, closePanel],
		);

		return (
			<Tooltip>
				<Tooltip.Trigger>
					<Button.Icon
						dataCy={dataCy}
						css={css}
						withBackgroundHover={withBackgroundHover}
						onClick={handleClick}
						ref={forwardedRef}
						{...props}
					>
						<Close />
					</Button.Icon>
				</Tooltip.Trigger>
				<Tooltip.Content>
					<Text size='large' color='tertiary'>
						{tooltipText}
					</Text>
				</Tooltip.Content>
			</Tooltip>
		);
	},
);
