import {Dispatch, SetStateAction} from 'react';
import {DatePickerData} from '@esgi/main/kits/admin';
import {
	SchoolStudentsNeedingSupportWidgetOptions,
	SchoolStudentsNeedingSupportWidgetUpdatedValue,
} from '../../../../types/students-needing-support';
import {Color} from '@esgi/main/features/admins/dashboard';

export enum SchoolCutoff {
	None = 'None',
	Five = 'Five',
	Ten = 'Ten',
	Fifteen = 'Fifteen',
	Twenty = 'Twenty',
	TwentyFive = 'TwentyFive',
}

export type AdaptedWidgetOptions = Omit<SchoolStudentsNeedingSupportWidgetOptions, 'districtCutoff'> & {
	districtCutoff: SchoolCutoff;
};

export type DynamicFormName = 'studentsSinglePeriod' | 'studentsTwoPeriods' | 'classes';

export enum PeriodsVariant {
	None = 'None',
	SinglePeriod = 'SinglePeriod',
	TwoPeriods = 'TwoPeriods',
}

export type Period = DatePickerData & {
	color: Color;
};

export type DynamicFormProps<P = unknown> = P & {
	setIsDynamicFormDataTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataValid: Dispatch<SetStateAction<boolean>>;
	onCurrentDynamicFormNameChange: Dispatch<DynamicFormName>;
};

export type CommonUpdatedValue = Pick<SchoolStudentsNeedingSupportWidgetUpdatedValue, 'id' | 'name'> & {
	options: Pick<
		SchoolStudentsNeedingSupportWidgetUpdatedValue['options'],
		| 'districtID'
		| 'schoolID'
		| 'globalSchoolYearID'
		| 'districtCutoff'
		| 'contentAreaIDs'
		| 'subjectIDs'
		| 'gradeLevelIDs'
		| 'viewType'
	>;
};

export type CommonSelectedArraysEntity = Pick<
	SchoolStudentsNeedingSupportWidgetUpdatedValue['selectedArraysEntity'],
	'contentAreaIDs' | 'gradeLevelIDs' | 'subjectIDs'
>;
