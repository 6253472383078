import {DatePickerData} from '@esgi/main/kits/admin';
import {ControlValue, FormControl, FormGroup} from '@esgi/ui/form';
import {BoxplotCompareVariant, Period, School} from '../types';
import {
	Color,
	DemographicGroupType,
	PerformanceWidgetViewType,
	WidgetViewSize,
} from '@esgi/main/features/admins/dashboard';

export enum ValidatorName {
	DateRangeValidFormat = 'DateRangeValidFormat',
	HighlightValueRequired = 'HighlightValueRequired',
	HighlightValueOutOfRange = 'HighlightValueOutOfRange',
}

export type CommonInitialFormData = {
	boxplotCompareVariant: BoxplotCompareVariant;
};

export type PiechartsInitialFormData = {
	date: DatePickerData;
};

export type BoxplotSinglePeriodInitialFormData = {
	date: DatePickerData;
	highlight: {
		highlightIfAbove: number;
		color: Color;
	} | null;
};

export type BoxplotTwoPeriodsInitialFormData = {
	firstPeriod: Period | null;
	secondPeriod: Period | null;
};

export type BoxplotTwoSchoolsInitialFormData = {
	firstSchool: School | null;
	secondSchool: School | null;
	date: DatePickerData;
};

export type CommonFormDemographicGroupItem = (DemographicGroupType | null)[];

export type CommonForm = FormGroup<
	{
		viewType: FormControl<PerformanceWidgetViewType>;
		size: FormControl<WidgetViewSize>;
		isShowAverageValue: FormControl<boolean>;
		demographicGroup: FormControl<CommonFormDemographicGroupItem>;
		boxplotCompareVariant: FormControl<BoxplotCompareVariant>;
	},
	ControlValue<{
		viewType: FormControl<PerformanceWidgetViewType>;
		size: FormControl<WidgetViewSize>;
		isShowAverageValue: FormControl<boolean>;
		demographicGroup: FormControl<CommonFormDemographicGroupItem>;
		boxplotCompareVariant: FormControl<BoxplotCompareVariant>;
	}>
>;

export type BoxplotSinglePeriodFormColorItem = (Color | null)[];

export type BoxplotSinglePeriodForm = FormGroup<
	{
		date: FormControl<DatePickerData>;
		isHighlightCandles: FormControl<boolean>;
		highlightColor: FormControl<BoxplotSinglePeriodFormColorItem>;
		highlightValue: FormControl<number | ''>;
	},
	ControlValue<{
		date: FormControl<DatePickerData>;
		isHighlightCandles: FormControl<boolean>;
		highlightColor: FormControl<BoxplotSinglePeriodFormColorItem>;
		highlightValue: FormControl<number>;
	}>
>;

export type BoxplotTwoPeriodsFormColorItem = (Color | null)[];

export type BoxplotTwoPeriodsForm = FormGroup<
	{
		firstPeriodColor: FormControl<BoxplotTwoPeriodsFormColorItem>;
		firstPeriodDate: FormControl<DatePickerData>;
		secondPeriodColor: FormControl<BoxplotTwoPeriodsFormColorItem>;
		secondPeriodDate: FormControl<DatePickerData>;
	},
	ControlValue<{
		firstPeriodColor: FormControl<BoxplotTwoPeriodsFormColorItem>;
		firstPeriodDate: FormControl<DatePickerData>;
		secondPeriodColor: FormControl<BoxplotTwoPeriodsFormColorItem>;
		secondPeriodDate: FormControl<DatePickerData>;
	}>
>;

export type BoxplotTwoSchoolsFormColorItem = (Color | null)[];
export type BoxplotTwoSchoolsFormSchoolIDItem = (string | null)[];

export type BoxplotTwoSchoolsForm = FormGroup<
	{
		date: FormControl<DatePickerData>;
		firstSchoolID: FormControl<BoxplotTwoSchoolsFormSchoolIDItem>;
		firstSchoolColor: FormControl<BoxplotTwoSchoolsFormColorItem>;
		secondSchoolID: FormControl<BoxplotTwoSchoolsFormSchoolIDItem>;
		secondSchoolColor: FormControl<BoxplotTwoSchoolsFormColorItem>;
	},
	ControlValue<{
		date: FormControl<DatePickerData>;
		firstSchoolID: FormControl<BoxplotTwoSchoolsFormSchoolIDItem>;
		firstSchoolColor: FormControl<BoxplotTwoSchoolsFormColorItem>;
		secondSchoolID: FormControl<BoxplotTwoSchoolsFormSchoolIDItem>;
		secondSchoolColor: FormControl<BoxplotTwoSchoolsFormColorItem>;
	}>
>;

export type PiechartsForm = FormGroup<
	{
		date: FormControl<DatePickerData>;
	},
	ControlValue<{
		date: FormControl<DatePickerData>;
	}>
>;
