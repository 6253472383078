import {Text} from '@esgi/ui/typography';
import {ToggleGroupContent, ToggleGroupItem, ToggleGroupRoot} from './index.styled';
import {ToggleGroupItemModel} from '../../types';
import {Dispatch} from 'react';
import {IconWrapper} from '../../index.styled';

export {type ToggleGroupItemModel};

type Props<T extends string> = {
	items: ToggleGroupItemModel<T>[];
	value?: T;
	onValueChange?: Dispatch<T>;
	disabled?: boolean;
};

export function ToggleGroup<T extends string>({
	items,
	value,
	onValueChange,
	disabled: isToggleGroupDisabled,
}: Props<T>) {
	return (
		<ToggleGroupRoot value={value} onValueChange={onValueChange} disabled={Boolean(isToggleGroupDisabled)}>
			<ToggleGroupContent>
				{items.map(({value: itemValue, label, Icon}) => {
					const isItemSelected = itemValue === value;

					return (
						<ToggleGroupItem
							value={itemValue}
							disabled={isItemSelected || isToggleGroupDisabled}
							applyDisabledStyles={isToggleGroupDisabled ? true : !isItemSelected}
							key={itemValue}
						>
							<IconWrapper isItemSelected={isItemSelected} isItemDisabled={isToggleGroupDisabled}>
								<Icon />
							</IconWrapper>
							<Text size='small'>{label}</Text>
						</ToggleGroupItem>
					);
				})}
			</ToggleGroupContent>
		</ToggleGroupRoot>
	);
}
