import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {FilterScope, TestModel} from '../service/types';
import {TestExplorerService} from '../service/service';
import {useBehaviorSubject} from '@esgi/ui';
import {Snackbar} from '@esgi/ui/snackbar';
import {TestType} from '@esgi/main/libs/core';
import {OverlayScrollbarsComponentRef} from 'overlayscrollbars-react/types/OverlayScrollbarsComponent';
import {isUndefined} from 'underscore';
import {ClearStorageEvent} from '@esgi/main/libs/store';
import {dispatchAppEvent} from '@esgillc/events';
import {useSearchParams} from 'react-router-dom';

interface Props {
	service: TestExplorerService
	selectedContentAreaIDs: number[]
	selectedGradeLevelIDs: number[]
}

export function useTestExplorerState({service, selectedContentAreaIDs, selectedGradeLevelIDs}: Props) {
	const snackbarRef = Snackbar.useRef();
	const osRef = useRef<OverlayScrollbarsComponentRef>(null);

	const selectedSubject = useBehaviorSubject(service.selectedSubject$);
	const cardView = useBehaviorSubject(service.cardViewType);

	const [showAddSeriesDrawer, setShowAddSeriesDrawer] = useState(false);
	const [showAddAuthorsDrawer, setShowAddAuthorsDrawer] = useState(false);
	const [showAddToSubjectDrawer, setShowAddToSubjectDrawer] = useState(false);

	const [showFiltersPanel, setShowFiltersPanel] = useState(false);

	const [showTestCreatorLauncher, setShowTestCreatorLauncher] = useState(false);
	const [showAutoTestCreatorLauncher, setShowAutoTestCreatorLauncher] = useState(false);
	const [showImageGallery, setShowImageGallery] = useState(false);

	const [showTestDetailsModal, setShowTestDetailsModal] = useState(false);
	const [selectedTestDetails, setSelectedTestDetails] = useState<{
		testID: TestModel['testID'] | null;
		type: TestModel['type'] | null;
	}>({
		testID: null,
		type: null,
	});
	const [showCloseSelectedBarAlert, setShowCloseSelectedBarAlert] = useState(false);

	const [, setSearchParams] = useSearchParams();

	const scope = useBehaviorSubject(service.scope$);
	const sortBy = useBehaviorSubject(service.sortBy$);
	const hasDrafts = useBehaviorSubject(service.hasDrafts$);

	const tests = useBehaviorSubject(service.tests$);
	const showOnlySelectedTests = useBehaviorSubject(service.showOnlySelectedTests$);
	const addSelectedToFavoritesChecked = useBehaviorSubject(service.addSelectedToFavoritesChecked$);
	const selectedTestIDs = useBehaviorSubject(service.selectedTestIDs$);

	const testsCount = useBehaviorSubject(service.testsCount$);
	const isPageStateInitialized = useBehaviorSubject(service.isPageStateInitialized$);
	const isTestsFetching = useBehaviorSubject(service.isTestsFetching$);
	const drawerIsLoaded = useBehaviorSubject(service.drawerIsLoaded$);
	const hasMoreTests = useBehaviorSubject(service.hasMoreTests$);
	const showOverlimitAlert = useBehaviorSubject(service.hasOverlimit$);

	const notedAuthors = useBehaviorSubject(service.notedAuthors$);
	const notedSeries = useBehaviorSubject(service.notedSeries$);

	const selectedAdministration = useBehaviorSubject(service.selectedAdministration$);
	const showHidden = useBehaviorSubject(service.showHidden$);
	const selectedTestTypes = useBehaviorSubject(service.selectedTestTypes$);
	const selectedNotedSeries = useBehaviorSubject(service.selectedNotedSeries$);
	const selectedNotedSeriesIDs = useBehaviorSubject(service.selectedNotedSeriesIDs$);
	const selectedNotedAuthors = useBehaviorSubject(service.selectedNotedAuthors$);
	const selectedNotedAuthorsIDs = useBehaviorSubject(service.selectedNotedAuthorsIDs$);
	const selectedSortDirection = useBehaviorSubject(service.selectedSortDirection$);


	const visibleTests = useMemo(() =>
		showOnlySelectedTests
			? tests.filter(({testID}) => selectedTestIDs.includes(testID))
			: tests,
	[selectedTestIDs, showOnlySelectedTests, tests]);

	const subjectSelectingTests = useMemo(() => tests.filter(({testID}) => selectedTestIDs.includes(testID)),
		[selectedTestIDs, tests]);

	const onNextPage = useCallback(() => {
		if (hasMoreTests && !showOnlySelectedTests && !showAddToSubjectDrawer && isPageStateInitialized) {
			service.currentPageIndex$.next(service.currentPageIndex$.value + 1);
		}
	}, [isPageStateInitialized, hasMoreTests, service.currentPageIndex$, showAddToSubjectDrawer, showOnlySelectedTests]) ;

	const onCardViewChange= useCallback((view: string) => {
		service.cardViewType.next(Number(view));
		service.updatePageState(Number(view));
	}, [service]);

	const onTestCreated = useCallback((closeModal = true) => {
		service.tests$.next([]);
		setTimeout(() => {
			service.getTests(1).subscribe(({value: {tests, count, hasDrafts}}) => {
				service.onTestsLoaded(tests, count, hasDrafts);
				if (closeModal) {
					setSelectedTestDetails({testID: null, type: null});
					setShowTestDetailsModal(false);
				}
			});
		}, 2000);
	}, [service]);

	const showTestCreatorLauncherToggle = useCallback((testCreated?: boolean) => {
		if (typeof testCreated === 'boolean' && testCreated) {
			onTestCreated();
		}
		setShowTestCreatorLauncher(prev => !prev);
	}, [onTestCreated]);

	const showAutoTestCreatorToggle = useCallback((testCreated?: boolean) => {
		if (typeof testCreated === 'boolean' && testCreated) {
			onTestCreated();
		}
		setShowAutoTestCreatorLauncher(prev => !prev);
	}, [onTestCreated]);
	const showImageGalleryToggle = useCallback(() => setShowImageGallery(prev => !prev), []);
	const showFiltersPanelToggle = useCallback(() => setShowFiltersPanel(prev => !prev), []);
	const showFiltersPanelHide = useCallback(() => setShowFiltersPanel(false), []);

	const showAddSeriesDrawerToggle = useCallback(() => setShowAddSeriesDrawer(prev => !prev), []);
	const showAddAuthorsDrawerToggle = useCallback(() => setShowAddAuthorsDrawer(prev => !prev), []);
	const showCloseSelectedBarAlertToggle = useCallback(() => setShowCloseSelectedBarAlert(!showCloseSelectedBarAlert), [showCloseSelectedBarAlert]);
	const showOverlimitAlertToggle = useCallback(() => service.hasOverlimit$.next(!showOverlimitAlert), [showOverlimitAlert]);

	const showAddToSubjectDrawerToggle = useCallback(() => {
		if (showAddToSubjectDrawer) {
			service.showOnlySelectedTests$.next(false);
			service.selectedSubject$.next(null);
		}
		setShowAddToSubjectDrawer(prev => !prev);
		service.drawerIsLoaded$.next(true);
	}, [service, showAddToSubjectDrawer]);

	const showTestDetailsModalToggle = useCallback((testID: TestModel['testID'] | null, type: TestType | null) => (changedTest?: Partial<TestModel> & {hidden?: boolean}) => {
		if (testID === null && changedTest) {
			dispatchAppEvent(ClearStorageEvent);
			if (scope === FilterScope.DraftTests) {
				service.tests$.next(tests?.filter(test => test.testID !== changedTest?.testID));
			} else {
				service.tests$.next(tests?.map(test => test.testID === changedTest?.testID ? {...test, ...changedTest} : test));
			}
		}
		setSelectedTestDetails({testID, type});
		setShowTestDetailsModal(Boolean(testID));

		// NOTE: generate a test link to share between users
		if (testID && type) {
			setSearchParams(
				new URLSearchParams({
					testID: testID.toString(),
					testType: type.toString(),
				}),
			);
		} else {
			setSearchParams({});
		}
	}, [scope, service.tests$, setSearchParams, tests]);

	const alreadyInFavorites = useMemo(() =>
		visibleTests.filter(({testID}) => selectedTestIDs.includes(testID)).every(({starred}) => starred),
	[visibleTests, selectedTestIDs]);

	const allTestsHidden = useMemo(() =>
		visibleTests.filter(({testID}) => selectedTestIDs.includes(testID)).every(({hidden}) => hidden),
	[visibleTests, selectedTestIDs]);

	useEffect(() => {
		const osInstance = osRef.current?.osInstance();
		const elements = osInstance?.elements();
		if (!isUndefined(elements)) {
			const {viewport} = elements;
			viewport.scrollTo({top: 0});
		}
	}, [
		scope,
		sortBy,
		selectedTestTypes,
		selectedContentAreaIDs,
		selectedNotedAuthorsIDs,
		selectedNotedSeriesIDs,
		selectedAdministration,
		selectedGradeLevelIDs,
		showHidden,
	]);

	return {
		selectedSubject,
		scope,
		hasDrafts,
		sortBy,
		cardView,
		onCardViewChange,
		tests,
		showOnlySelectedTests,
		addSelectedToFavoritesChecked: addSelectedToFavoritesChecked || alreadyInFavorites,
		hideTestsChecked: allTestsHidden,
		selectedTestIDs,
		visibleTests,
		subjectSelectingTests,
		testsCount,
		onNextPage,
		isPageStateInitialized,
		isTestsFetching,
		drawerIsLoaded,
		showTestCreatorLauncher,
		showTestCreatorLauncherToggle,
		showAutoTestCreatorLauncher,
		showAutoTestCreatorToggle,
		showImageGallery,
		showImageGalleryToggle,
		showFiltersPanel,
		showFiltersPanelToggle,
		showFiltersPanelHide,
		notedAuthors,
		notedSeries,
		selectedAdministration,
		showHidden,
		selectedTestTypes,
		selectedNotedSeries,
		selectedNotedAuthors,
		selectedNotedSeriesIDs,
		selectedNotedAuthorsIDs,
		selectedSortDirection,
		showAddSeriesDrawer,
		showAddSeriesDrawerToggle,
		showAddAuthorsDrawer,
		showAddAuthorsDrawerToggle,
		showAddToSubjectDrawer,
		showAddToSubjectDrawerToggle,
		selectedTestDetails,
		showTestDetailsModal,
		showTestDetailsModalToggle,
		showCloseSelectedBarAlert,
		showCloseSelectedBarAlertToggle,
		showOverlimitAlert,
		showOverlimitAlertToggle,
		onTestCreated,
		snackbarRef,
		osRef,
	};
}