import {useDisposable} from '@esgi/core/service';
import {V2DistrictAdminsFoldersSchoolsController, V2DistrictAdminsFoldersUsersController} from '@esgi/contracts/esgi';
import {
	AsyncResponse,
	AsyncStatus,
	isAsyncFailed,
	isAsyncPending,
	isAsyncSucceed,
	useCancelableRequest,
} from '@esgi/ui';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {FolderData, FolderType} from '../types';

const dataIdle: AsyncResponse<FolderData> = {
	asyncStatus: AsyncStatus.Idle,
	value: null,
	errors: null,
};

type Parameters = {
	type: FolderType;
};

export function useFetchFolderByID({type}: Parameters): [AsyncResponse<FolderData>, Dispatch<number>] {
	const [data, setData] = useState<AsyncResponse<FolderData>>(dataIdle);

	const foldersSchoolsController = useDisposable(V2DistrictAdminsFoldersSchoolsController);
	const foldersUsersController = useDisposable(V2DistrictAdminsFoldersUsersController);

	const [schoolFolder, fetchSchoolFolder] = useCancelableRequest(foldersSchoolsController.byFolder);
	const [userFolderData, fetchUserFolder] = useCancelableRequest(foldersUsersController.byFolder);

	useEffect(() => {
		if (type !== FolderType.Schools) {
			return;
		}

		setData(() => {
			if (isAsyncPending(schoolFolder)) {
				return {
					asyncStatus: AsyncStatus.Pending,
					value: null,
					errors: null,
				};
			}

			if (isAsyncFailed(schoolFolder)) {
				return {
					asyncStatus: AsyncStatus.Failed,
					data: undefined,
					error: schoolFolder.error,
				};
			}

			if (isAsyncSucceed(schoolFolder)) {
				return {
					asyncStatus: AsyncStatus.Succeeded,
					data: {
						ids: schoolFolder.data.value.schools.map(({id}) => id),
					},
				};
			}

			return dataIdle;
		});
	}, [schoolFolder, type]);

	useEffect(() => {
		if (type === FolderType.Schools) {
			return;
		}

		setData(() => {
			if (isAsyncPending(userFolderData)) {
				return {
					asyncStatus: AsyncStatus.Pending,
					value: null,
					errors: null,
				};
			}

			if (isAsyncFailed(userFolderData)) {
				return {
					asyncStatus: AsyncStatus.Failed,
					data: undefined,
					error: userFolderData.error,
				};
			}

			if (isAsyncSucceed(userFolderData)) {
				return {
					asyncStatus: AsyncStatus.Succeeded,
					data: {
						ids: userFolderData.data.value.userIDs,
					},
				};
			}

			return dataIdle;
		});
	}, [type, userFolderData]);

	const fetchFolders = useCallback(
		(folderID: number) => {
			if (type === FolderType.Schools) {
				fetchSchoolFolder({folderID});
				return;
			}

			fetchUserFolder({folderID});
		},
		[fetchSchoolFolder, fetchUserFolder, type],
	);

	return [data, fetchFolders];
}
