import {PieChart, ThemeColorKeys, getThemeColor} from '@esgi/ui';
import {
	MainChartRoot,
	ChartContainer,
	ChartOverlayBox,
	ChartResultsBox,
	ChartValueBox,
	AverageChartRoot,
	piechartsColumnGap,
	ChartsInfoBox,
	ChartDataBox,
	AverageSymbol,
	AverageValueBox,
} from './index.styled';
import {PieChartItem} from './types';
import {useMemo, useRef} from 'react';
import {
	averageChartSize,
	averageChartProgressLineSize,
	piechartsRowsCount,
	mainChartSize,
	mainChartPointerRadius,
} from './constants';
import useResizeObserver from '@react-hook/resize-observer';
import {Text} from '@esgi/ui/typography';
import {Box} from '@esgi/ui/layout';
import {isNull} from 'underscore';
import {MultiLinedText} from '../../../kit';
import {useWidgetCardContext} from '../../context';

type Props = {
	items: (PieChartItem | null)[];
	widgetChartPageSize: number;
	averageValue: number;
	showAverageValue: boolean;
};

export function ChartPiechart({items, widgetChartPageSize, averageValue, showAverageValue}: Props) {
	const {setWidgetDataPageSize} = useWidgetCardContext();

	const chartOverlayRef = useRef<HTMLDivElement>(null);

	useResizeObserver(chartOverlayRef, ({contentRect: {width}}) => {
		const piechartFullSize = Math.max(averageChartSize, mainChartSize);

		// piechartFullSize * n + piechartsColumnGap * (n - 1) = width
		const itemsCount = (width + piechartsColumnGap) / (piechartFullSize + piechartsColumnGap);

		setWidgetDataPageSize(Math.max(Math.floor(itemsCount) * piechartsRowsCount, 1));
	});

	const {averageRounedValue, progressLineValues} = useMemo(() => {
		const averageRounedValue = Math.round(averageValue);

		return {
			averageRounedValue,
			progressLineValues: [0, averageRounedValue],
		};
	}, [averageValue]);

	return (
		<ChartContainer
			css={{
				gridTemplateColumns: `repeat(${widgetChartPageSize / piechartsRowsCount}, 1fr)`,
			}}
		>
			<>
				{items.map((item, index) => {
					if (isNull(item)) {
						return <Box key={index} />;
					}

					const {title, avgValue} = item;
					const isPositiveResult = avgValue >= 50;

					const progressColor: ThemeColorKeys = isPositiveResult ? 'green' : 'highContrast';

					return (
						<ChartDataBox key={index}>
							<ChartsInfoBox>
								<MainChartRoot minValue={0} maxValue={100} size={mainChartSize}>
									<PieChart.Ring strokeWidth={1} stroke='border' />
									<PieChart.ArcProgress startValue={0} endValue={avgValue} strokeWidth={3} stroke={progressColor} />
									<PieChart.Pointer
										value={avgValue}
										stroke={progressColor}
										r={mainChartPointerRadius}
										fill='vivid'
										strokeWidth={1.5}
									/>
								</MainChartRoot>

								{showAverageValue && (
									<AverageChartRoot minValue={0} maxValue={100} size={averageChartSize}>
										<PieChart.Ring strokeWidth={1} stroke='primaryMuted' />
										<PieChart.ArcProgress
											startValue={0}
											endValue={averageRounedValue}
											strokeWidth={1}
											stroke='primaryVivid'
										/>
										{progressLineValues.map((value, index) => (
											<PieChart.ProgressLine
												width={averageChartProgressLineSize}
												height={averageChartProgressLineSize}
												value={value}
												stroke='primaryVivid'
												key={`${index}-${value}`}
											/>
										))}
									</AverageChartRoot>
								)}

								<ChartResultsBox>
									{showAverageValue && (
										<AverageValueBox>
											<AverageSymbol>
												<Text size='xSmall' font='mono' bold color='currentColor'>
													x
												</Text>
											</AverageSymbol>
											<Text size='xSmall' font='mono' bold color='currentColor'>
												{`${averageRounedValue.toFixed(1)}%`}
											</Text>
										</AverageValueBox>
									)}
									<ChartValueBox css={{backgroundColor: getThemeColor(progressColor)}}>
										<Text size='xSmall' font='mono' bold color='vivid'>
											{`${avgValue.toFixed(1)}%`}
										</Text>
									</ChartValueBox>
								</ChartResultsBox>
							</ChartsInfoBox>

							<MultiLinedText size='xSmall' font='mono' color='highContrast' maxLines={2}>
								{title}
							</MultiLinedText>
						</ChartDataBox>
					);
				})}
			</>

			<ChartOverlayBox ref={chartOverlayRef} />
		</ChartContainer>
	);
}
