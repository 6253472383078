import {useDisposable} from '@esgi/core/service';
import {V2DistrictAdminsClassesController} from '@esgi/contracts/esgi';
import {useCallback, useEffect, useState} from 'react';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {classesStore, studentsStore, Class, Student, User} from '@esgi/main/libs/admin-store';
import {dispatchAppEvent} from '@esgillc/events';
import {ClassUpdatedEvent} from '../../events';
import {EditClassForm} from './form-data/types';
import {isNull} from 'underscore';

export type UpdatedClassDataModel = Pick<Class, 'id' | 'name'> & {
	studentsIDs: Student['id'][];
};

type Parameters = {
	closeDrawer: VoidFunction;
	form: EditClassForm;
	classID: Class['id'];
	initialStudentsIDs: Student['id'][];
	primaryTeacherID: User['id'];
};

export function useSave({closeDrawer, form, classID, initialStudentsIDs, primaryTeacherID}: Parameters) {
	const classesController = useDisposable(V2DistrictAdminsClassesController);

	const [updatedClassDataModel, setUpdatedClassDataModel] = useState<UpdatedClassDataModel | null>(null);
	const [updateClassData, updateClass] = useCancelableRequest(classesController.update);

	useEffect(() => {
		if (isAsyncSucceed(updateClassData) && !isNull(updatedClassDataModel)) {
			classesStore().update?.((item: Class): Class => {
				if (item.id === classID) {
					return {
						...item,
						name: updatedClassDataModel.name,
					};
				}

				return item;
			});

			studentsStore().update?.((item: Student): Student => {
				if (initialStudentsIDs.includes(item.id) && !updatedClassDataModel.studentsIDs.includes(item.id)) {
					return {
						...item,
						classesIDs: item.classesIDs.filter((id) => id !== classID),
					};
				}

				if (updatedClassDataModel.studentsIDs.includes(item.id)) {
					return {
						...item,
						classesIDs: [...new Set([...item.specialistGroupsIDs, classID])],
						teacherID: primaryTeacherID,
					};
				}

				return item;
			});

			dispatchAppEvent(
				ClassUpdatedEvent,
				new ClassUpdatedEvent({
					id: classID,
					name: updatedClassDataModel.name,
				}),
			);

			closeDrawer();
		}
	}, [classID, closeDrawer, initialStudentsIDs, primaryTeacherID, updateClassData, updatedClassDataModel]);

	const saveData = useCallback(() => {
		const {className, studentsIDs} = form.value;

		updateClass({
			className,
			classID,
			studentIDs: studentsIDs,
		});

		setUpdatedClassDataModel({
			id: classID,
			name: className,
			studentsIDs,
		});
	}, [classID, form, updateClass]);

	return {
		saveData,
		isDataSaving: isAsyncPending(updateClassData),
	};
}
