import {Download, Subject} from '@esgi/ui/icons';
import {Container, DownloadContextMenuItem, PanelTitle} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Button, ContextMenu, Skeletonable} from '@esgi/ui';
import {useDownload} from './use-download';
import {Loader} from '@esgillc/ui-kit/loader';
import {AdditionalEntity, Student} from '../../../../types/section';
import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {isNull} from 'underscore';
import {downloadVariantItems} from './constants';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = Skeletonable<{
	showInColor: boolean;
	showEachStudent: boolean;
	additionalInfo: AdditionalEntity | null;
	students: Student[];
	selectedGradeLevelID: number;
	selectedSubject: SubjectModel | null;
	hierarchy: Hierarchy;
}>;

export function Header({
	skeleton,
	hierarchy,
	showEachStudent,
	showInColor,
	additionalInfo,
	students,
	selectedGradeLevelID,
	selectedSubject,
}: Props) {


	const {onDownload, isDownloading} = useDownload({
		hierarchy,
		printInColor: showInColor,
		selectedGradeLevelID,
		showEachStudent,
		selectedSubject,
		students,
		additionalInfo,
	});

	return (
		<Container>
			<PanelTitle skeleton={Boolean(skeleton)}>
				<Subject />
				<Text size='small' color='mediumContrast'>
					Tests Results
				</Text>
			</PanelTitle>

			<>
				<Loader show={isDownloading} fullscreen />

				<ContextMenu.Root>
					<ContextMenu.Trigger disabled={skeleton || isDownloading || !students.length || isNull(selectedSubject)}>
						<Button color='secondary' skeleton={skeleton}>
							<Download />
							<Text size='medium' bold>
								Download
							</Text>
						</Button>
					</ContextMenu.Trigger>

					<ContextMenu.Content>
						{downloadVariantItems.map(({extension, label}) => (
							<DownloadContextMenuItem onClick={() => onDownload(extension)} key={extension}>
								<Text size='medium'>{label}</Text>
							</DownloadContextMenuItem>
						))}
					</ContextMenu.Content>
				</ContextMenu.Root>
			</>
		</Container>
	);
}
