import {useMemo} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {InitModel} from 'modules/reports/parent-letter/models';
import {ChangesCollector} from 'shared/modules/reports/utils/changes-collector';
import * as BaseLauncherModule from 'modules/reports/parent-letter/launcher';

const BaseLauncher = BaseLauncherModule.default;

class Props {
	hierarchy: HierarchyInstance;
	subjectID: number;
	subjectType: SubjectType;
	onClose: VoidFunction;
}

export default function ParentLetterLauncher({subjectType, subjectID, hierarchy, onClose}: Props) {
	const changesCollector = useMemo(() => new ChangesCollector({
		id: subjectID,
		type: subjectType,
	}, hierarchy.snapshot), [subjectID, subjectType, hierarchy]);

	const selectedItems = useMemo(() => new InitModel(changesCollector.groupID.initialValue, changesCollector.studentID.initialValue, subjectID, subjectType), []);

	return <BaseLauncher initModel={selectedItems}
	                     changesCollector={changesCollector}
	                     onClosed={onClose}
	                     hierarchy={hierarchy.snapshot}/>;
}
