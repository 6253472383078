import {Dispatch, useCallback, useMemo, useState} from 'react';
import {Widget} from '../../../../types/widget';

import {ElementStatus} from '@esgi/ui/form';
import {
	DemographicPerformanceWidgetOptions,
	DemographicPerformanceWidgetUpdatedValue,
} from '../../../../types/demographic-performance';
import {AdditionalSettingBox, CardBody, CardBodyDivider} from './index.styled';
import {MainSettings} from './components/main-settings';
import {useStreamEffect} from '@esgi/ui';
import {useFormsInit} from './forms-data/use-forms-init';
import {BoxplotData} from './components/boxplot-data';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {BoxplotCompareVariant, DynamicFormName} from './types';
import {PiechartsData} from './components/piecharts-data';
import {useSaveWidget} from './hooks/use-save-widget';
import {useBoxplotCompareVariantItems} from './hooks/use-boxplot-compare-variant-items';
import {
	CheckboxFormElement,
	CheckboxesBox,
	SchoolModel,
	FormToggleGroup,
	DeployedSubject,
	GradeLevel,
	PerformanceWidgetViewType,
	WidgetCard,
} from '@esgi/main/features/admins/dashboard';

type Props = {
	subjects: DeployedSubject[];
	allSchoolsList: SchoolModel[];
	onDeleteWidgetByID: Dispatch<Widget['id']>;
	widgetOptions: DemographicPerformanceWidgetOptions;
	onUpdateWidget: Dispatch<DemographicPerformanceWidgetUpdatedValue>;
	gradeLevels: GradeLevel[];
};

export function EditMode({
	subjects,
	allSchoolsList,
	onDeleteWidgetByID,
	widgetOptions,
	onUpdateWidget,
	gradeLevels,
}: Props) {
	const [currentDynamicFormName, setCurrentDynamicFormName] = useState<DynamicFormName>();

	const [widgetViewType, setWidgetViewType] = useState(widgetOptions.viewType);

	const [selectedContentArea, setSelectedContentArea] = useState(widgetOptions.contentAreaIDs.map(String));
	const [selectedSubjectIDs, setSelectedSubjectIDs] = useState(widgetOptions.subjectIDs.map(String));
	const [selectedGradeLevels, setSelectedGradeLevels] = useState(widgetOptions.gradeLevelIDs.map(String));
	const [selectedSchoolsIDs, setSelectedSchoolsIDs] = useState(widgetOptions.schoolIDs.map(String));

	const [isCommonFormTouched, setIsCommonFormTouched] = useState(false);

	const [isDynamicFormDataTouched, setIsDynamicFormDataTouched] = useState(false);
	const [isDynamicFormDataValid, setIsDynamicFormDataValid] = useState(true);

	const {
		commonForm,
		boxplotSinglePeriodForm,
		boxplotTwoPeriodsForm,
		boxplotTwoSchoolsForm,
		piechartsForm,
		initialCommonForm,
		initialBoxplotSinglePeriodForm,
		initialBoxplotTwoPeriodsForm,
		initialBoxplotTwoSchoolsForm,
		setBoxplotSinglePeriodFormInitialData,
		setBoxplotTwoPeriodsFormInitialData,
		setBoxplotTwoSchoolsFormInitialData,
		setPiechartsFormInitialData,
	} = useFormsInit({widgetOptions, allSchoolsList});

	const boxplotCompareVariantItems = useBoxplotCompareVariantItems({allSchoolsList, commonForm});

	const onCurrentDynamicFormNameChange = useCallback(
		(newFormName: DynamicFormName) => {
			setCurrentDynamicFormName((currentName) => {
				switch (currentName) {
					case 'boxplotSinglePeriod':
						setBoxplotSinglePeriodFormInitialData();
						break;
					case 'boxplotCompareTwoPeriods':
						setBoxplotTwoPeriodsFormInitialData();
						break;
					case 'boxplotCompareTwoSchools':
						setBoxplotTwoSchoolsFormInitialData();
						break;
					case 'piecharts':
						setPiechartsFormInitialData();
						break;
				}

				return newFormName;
			});
		},
		[
			setBoxplotSinglePeriodFormInitialData,
			setBoxplotTwoPeriodsFormInitialData,
			setBoxplotTwoSchoolsFormInitialData,
			setPiechartsFormInitialData,
		],
	);

	useStreamEffect(commonForm.controls.viewType.onChanged, ({currState: {value}}) => {
		setWidgetViewType(value);

		if (value === PerformanceWidgetViewType.Candles) {
			commonForm.controls.boxplotCompareVariant.value = BoxplotCompareVariant.SinglePeriod;
			commonForm.controls.boxplotCompareVariant.status = ElementStatus.valid;
		}

		if (value === PerformanceWidgetViewType.Piechart) {
			commonForm.controls.boxplotCompareVariant.value = BoxplotCompareVariant.None;
			commonForm.controls.boxplotCompareVariant.status = ElementStatus.disabled;
		}
	});

	useStreamEffect(
		commonForm.onChanged,
		({
			currState: {
				value: {isShowAverageValue, size, viewType, boxplotCompareVariant, demographicGroup},
			},
		}) => {
			const isFormTouched =
				demographicGroup[0] !== widgetOptions.demographicGroup ||
				isShowAverageValue !== widgetOptions.showAvg ||
				size !== widgetOptions.viewSize ||
				viewType !== widgetOptions.viewType ||
				(boxplotCompareVariant !== BoxplotCompareVariant.None &&
					boxplotCompareVariant !== initialCommonForm.boxplotCompareVariant);

			setIsCommonFormTouched(isFormTouched);
		},
	);

	const isSelectedEntityTouched = useMemo(() => {
		const contentAreaTouched =
			selectedContentArea.length !== widgetOptions.contentAreaIDs.length ||
			selectedContentArea.some((item) => !widgetOptions.contentAreaIDs.includes(Number(item)));

		const subjectsTouched =
			selectedSubjectIDs.length !== widgetOptions.subjectIDs.length ||
			selectedSubjectIDs.some((item) => !widgetOptions.subjectIDs.includes(Number(item)));

		const gradeLevelsTouched =
			selectedGradeLevels.length !== widgetOptions.gradeLevelIDs.length ||
			selectedGradeLevels.some((item) => !widgetOptions.gradeLevelIDs.includes(Number(item)));

		const schoolsTouched =
			currentDynamicFormName !== 'boxplotCompareTwoSchools' &&
			(selectedSchoolsIDs.length !== widgetOptions.schoolIDs.length ||
				selectedSchoolsIDs.some((item) => !widgetOptions.schoolIDs.includes(Number(item))));

		return contentAreaTouched || subjectsTouched || gradeLevelsTouched || schoolsTouched;
	}, [
		currentDynamicFormName,
		selectedContentArea,
		selectedGradeLevels,
		selectedSchoolsIDs,
		selectedSubjectIDs,
		widgetOptions,
	]);

	const isFormTouched = isCommonFormTouched || isSelectedEntityTouched || isDynamicFormDataTouched;

	const handleSaveWidgetOptions = useSaveWidget({
		onUpdateWidget,
		districtID: widgetOptions.districtID,
		globalSchoolYearID: widgetOptions.globalSchoolYearID,
		setIsCommonFormTouched,
		setIsDynamicFormDataTouched,
		setIsDynamicFormDataValid,
		selectedContentArea,
		selectedGradeLevels,
		selectedSubjectIDs,
		selectedSchoolsIDs,
		commonForm,
		boxplotSinglePeriodForm,
		boxplotTwoPeriodsForm,
		boxplotTwoSchoolsForm,
		piechartsForm,
		setSelectedSchoolsIDs,
		allSchoolsList,
		subjects,
		gradeLevels,
	});

	return (
		<WidgetCard.EditMode
			isChildrenFormTouched={isFormTouched}
			isChildrenFormValid={isDynamicFormDataValid}
			onDeleteWidgetByID={onDeleteWidgetByID}
			onUpdateWidget={handleSaveWidgetOptions}
		>
			<CardBody>
				<MainSettings
					commonForm={commonForm}
					subjects={subjects}
					selectedContentArea={selectedContentArea}
					selectedGradeLevels={selectedGradeLevels}
					selectedSubjectIDs={selectedSubjectIDs}
					setSelectedContentArea={setSelectedContentArea}
					setSelectedGradeLevels={setSelectedGradeLevels}
					setSelectedSubjectIDs={setSelectedSubjectIDs}
					schools={allSchoolsList}
					selectedSchoolsIDs={selectedSchoolsIDs}
					setSelectedSchoolsIDs={setSelectedSchoolsIDs}
					gradeLevels={gradeLevels}
				/>

				<CardBodyDivider />

				<AdditionalSettingBox>
					<GridBox gap='3'>
						<WidgetCard.EditMode.FormElement control={commonForm.controls.boxplotCompareVariant}>
							<FormToggleGroup items={boxplotCompareVariantItems} />
						</WidgetCard.EditMode.FormElement>

						{widgetViewType === PerformanceWidgetViewType.Candles && (
							<BoxplotData
								singlePeriodForm={boxplotSinglePeriodForm}
								twoPeriodsForm={boxplotTwoPeriodsForm}
								twoSchoolsForm={boxplotTwoSchoolsForm}
								allSchoolsList={allSchoolsList}
								boxplotCompareVariant={commonForm.controls.boxplotCompareVariant}
								setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
								setIsDynamicFormDataValid={setIsDynamicFormDataValid}
								initialBoxplotSinglePeriodForm={initialBoxplotSinglePeriodForm}
								initialBoxplotTwoPeriodsForm={initialBoxplotTwoPeriodsForm}
								initialBoxplotTwoSchoolsForm={initialBoxplotTwoSchoolsForm}
								onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
							/>
						)}

						{widgetViewType === PerformanceWidgetViewType.Piechart && (
							<PiechartsData
								setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
								setIsDynamicFormDataValid={setIsDynamicFormDataValid}
								piechartsForm={piechartsForm}
								onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
							/>
						)}
					</GridBox>

					<CheckboxesBox>
						<WidgetCard.EditMode.FormElement control={commonForm.controls.isShowAverageValue}>
							<FlexBox justify='between'>
								<CheckboxFormElement label='Show Average' />
								{widgetViewType === PerformanceWidgetViewType.Piechart && (
									<WidgetCard.EditMode.AverageHint.Piechart
										avgResultName='Average across all schools'
										chartName='Demographic Group'
										description='The outer circle represents the value for a specific demographic group, while the inner blue circle shows the average for all students across all schools. This average is calculated by summing the values for all schools and dividing the total by the number of schools.'
										resultName='Demographic group result'
									/>
								)}
							</FlexBox>
						</WidgetCard.EditMode.FormElement>
					</CheckboxesBox>
				</AdditionalSettingBox>
			</CardBody>
		</WidgetCard.EditMode>
	);
}
