import {FormElement as FormElementUIKit, FormElementProps} from '@esgi/ui/form';
import {Container} from './index.styled';

export function FormElement<T = any>(props: FormElementProps<T>) {
	return (
		<Container>
			<FormElementUIKit {...props} />
		</Container>
	);
}
