import moment from 'moment';
import {memo, useCallback} from 'react';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {
	TestColorLineIndicator,
	TestContentArea,
} from '@esgi/main/kits/common';
import {Row} from '../../index.styled';
import {Test} from '../../../../service/types';
import {TestDetailsState} from '../body/types';
import {useTableRowStyles} from '../../hooks/use-table-row-styles';
import {
	Cell,
	NameCell,
	NameCheckbox,
	Timestamp,
	LableBox,
	TimestampContainer,
	StyledText,
} from './index.styled';
import {MultipleLabels} from '../multiple-labels';

type Props = {
	selected: boolean;
	testInfo: Test;
	onTestSelect: (test: Test) => void;
	onTestNameClick: (args: TestDetailsState) => void;
	stateStandards: Test['stateStandards'];
};

enum TestTypeLabel {
	YN = 'Yes/No',
	Rubric = 'Rubric',
	Score = 'Single Score',
}

function TestRowImpl({
	testInfo,
	onTestSelect,
	selected,
	onTestNameClick,
	stateStandards,
}: Props) {
	const {
		id,
		name,
		author,
		questions,
		created,
		testType,
		disabled,
		contentArea,
		color,
	} = testInfo;

	const styles = useTableRowStyles(false, selected);

	const onCheckedChange = useCallback(() => {
		if (disabled) {
			return;
		}

		onTestSelect(testInfo);
	}, [testInfo, disabled, onTestSelect]);

	const handleTestNameClick = useCallback(() => {
		onTestNameClick({
			testId: id,
			testType,
		});
	}, [id, onTestNameClick, testType]);

	return (
		<Row css={styles} key={id} dataCy='test-row'>
			<NameCell dataCy='name-cell'>
				<NameCheckbox
					disabled={disabled}
					checked={selected}
					active={selected}
					onCheckedChange={onCheckedChange}
				/>
				<TestColorLineIndicator
					contentArea={contentArea as TestContentArea}
					color={color}
				/>
				<GridBox gap='3' flow='column' align='center' justify='start'>
					<Button.Text onClick={handleTestNameClick}>
						<StyledText size='medium' selected={selected} disabled={disabled}>
							{name}
						</StyledText>
					</Button.Text>
					<LableBox dataCy='test-type'>
						<StyledText size='small' color='neutral40' disabled={disabled}>
							{TestTypeLabel[testType]}
						</StyledText>
					</LableBox>
					{stateStandards.length > 0 && (
						<MultipleLabels items={stateStandards.map((s) => s.name)} />
					)}
				</GridBox>
			</NameCell>
			<Cell dataCy='author-cell'>
				<StyledText size='small' color='neutral40' disabled={disabled}>
					{author}
				</StyledText>
			</Cell>
			<Cell dataCy='questions-cell'>
				<StyledText size='small' color='neutral40' disabled={disabled}>
					{questions}
				</StyledText>
			</Cell>
			<Cell dataCy='created-cell'>
				<TimestampContainer>
					<Timestamp>
						<StyledText size='small' color='neutral40' disabled={disabled}>
							{moment(created).format('MM/DD/YY')}
						</StyledText>
					</Timestamp>
				</TimestampContainer>
			</Cell>
		</Row>
	);
}

export const TestRow = memo(TestRowImpl);
