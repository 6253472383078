import {styled} from '@esgi/ui/theme';
import {Table as BaseTable, TableRow as TableRowBase} from '../../../table.styled';

export const Table = styled(BaseTable, {
	paddingBottom: '$4',
});

export const TableRow = styled(TableRowBase, {
	gridTemplateColumns: '1fr 1fr 1fr 48px 80px',
});
