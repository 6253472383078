import {createContext, Dispatch, useContext} from 'react';
import {isNull} from 'underscore';
import {SkeletonTestCard, TestPanelCard} from './types';

export type TestPanelContextValue<T extends TestPanelCard> = {
	allTestCards: T[];
	filteredCards: T[];
	setFilteredCards: Dispatch<T[]>;
	skeleton: boolean;
	skeletonTestCard: SkeletonTestCard;
};

export const TestPanelContext = createContext<TestPanelContextValue<any> | null>(null);

export function useTestPanelContext<T extends TestPanelCard>() {
	const context = useContext(TestPanelContext);

	if (isNull(context)) {
		throw new Error('useTestPanelContext is null');
	}

	return context as unknown as TestPanelContextValue<T>;
}
