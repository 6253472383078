import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {CSS} from '@stitches/react';

export const toggleGroupRoot: CSS = {
	display: 'grid',
};

export const toggleGroupContent: CSS = {
	gridAutoColumns: '1fr',
};

export const IconWrapper = styled(Box, {
	display: 'inline-flex',
	color: '$base',

	'& svg': {
		width: 24,
		height: 24,

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		isItemSelected: {
			true: {
				color: '$primary',
			},
		},

		isItemDisabled: {
			true: {
				color: '$lowContrast',
			},
		},
	},
});

export const toggleGroupItem: CSS = {
	display: 'grid',
	gap: '$1',
	justifyItems: 'center',
	padding: 7,

	'&[data-state="off"]': {
		'&:hover': {
			[`> ${IconWrapper}`]: {
				color: '$secondary',
			},
		},

		'&[data-disabled]': {
			[`> ${Text}`]: {
				color: '$lowContrast',
			},
		},
	},
};
