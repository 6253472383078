import React, {useEffect, useState} from 'react';
import {Drawer, UnsavedChangesConfirmation, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {SettingsTabTouchedData} from './types';
import {Panel} from './components/full-setup-settings-panel';
import {initialSettingsTabsData} from './components/full-setup-settings-panel/constants';
import {StyledDrawerWrapper} from './index.styled';
import {useService} from '../../../../../../../../libs/core/src/service';
import {SettingsService} from './service';

type Props = {
	onClose: VoidFunction;
};

export function SettingsDrawer(props: Props) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, props.onClose);

	const service = useService(SettingsService);

	const isLoaded = useBehaviorSubject(service.loaded$);

	const [isUnsavedChangesAlertOpen, setUnsavedChangesAlertOpen] = useState(false);
	const [tabsTouchedData, setTabsTouchedData] = useState<SettingsTabTouchedData>(initialSettingsTabsData);

	useEffect(() => {
		service.init();
	}, [service]);

	const handelCloseDrawer = (isTouched: boolean) => {
		if (isTouched) {
			setUnsavedChangesAlertOpen(true);
			return;
		}

		close();
	};

	return (
		<StyledDrawerWrapper>
			<Drawer width='100%' drawerRef={drawerRef}>
				{isLoaded && (
					<Panel
						service={service}
						onCloseDrawer={handelCloseDrawer}
						tabsTouchedData={tabsTouchedData}
						onChangeTabsTouchedData={setTabsTouchedData}
					/>
				)}
				{isUnsavedChangesAlertOpen &&
					<UnsavedChangesConfirmation
						onCloseAnyway={close}
						onClose={() => setUnsavedChangesAlertOpen(false)}
					/>
				}
			</Drawer>
		</StyledDrawerWrapper>
	);
}
