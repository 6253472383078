import {CSS} from '@stitches/react';

export const rootCSS: CSS = {
	borderRight: 'none',
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	borderBottomColor: '$border',
	backgroundColor: '$surface',
	boxShadow: '0px 0px 8px 0px rgba(222, 227, 247, 0.40), 0px 0px 10px 0px rgba(0, 0, 0, 0.06)',
};

export const contentCSS: CSS = {
	display: 'grid',
	gridAutoFlow: 'column',
	gap: '$3',
	alignItems: 'center',
	justifyContent: 'start',
	paddingLeft: 12,
	paddingRight: 12,
	height: '100%',
	gridAutoColumns: 'max-content',

	'& > *': {
		marginBottom: 'unset',
	},
};
