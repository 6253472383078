import {V2SchoolAdminsActionPanelController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {Group} from '@esgi/main/libs/school-admin-store';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {dispatchAppEvent} from '@esgillc/events';
import {useCallback, useEffect} from 'react';
import {GroupDeletedEvent} from '../../../../events';
import {useRemoveGroupInStore} from '@esgi/main/features/school-admin/utils';

type Parameters = {
	currentGroup: Group;
	onRemoved: VoidFunction;
};

export function useRemoveGroup({currentGroup, onRemoved}: Parameters) {
	const adminsActionPanelController = useDisposable(V2SchoolAdminsActionPanelController);

	const [removedGroupData, removeGroup] = useCancelableRequest(adminsActionPanelController.groupsDelete);

	const removeGroupInStore = useRemoveGroupInStore();

	useEffect(() => {
		if (isAsyncSucceed(removedGroupData)) {
			removeGroupInStore(currentGroup.id, () => {
				dispatchAppEvent(
					GroupDeletedEvent,
					new GroupDeletedEvent({
						id: currentGroup.id,
						name: currentGroup.name,
					}),
				);

				onRemoved();
			});
		}
	}, [currentGroup, onRemoved, removeGroupInStore, removedGroupData]);

	const onRemoveGroup = useCallback(() => {
		removeGroup({
			iDs: [currentGroup.id],
		});
	}, [currentGroup, removeGroup]);

	return {
		onRemoveGroup,
		isGroupRemoving: isAsyncPending(removedGroupData),
	};
}
