import {EntityType} from '../kit';
import {EntityModalContent} from './types';

export const entityModalContent: Record<EntityType, EntityModalContent> = {
	[EntityType.Class]: {
		headerTitle: 'Delete Class?',
		bodyText:
			'Are you sure you want to delete the following class? This will NOT delete students and their data. The students still will be available and can be added to another class.',
		deleteButtonText: 'Delete',
	},
	[EntityType.Group]: {
		headerTitle: 'Delete Group?',
		bodyText:
			'Are you sure you want to delete the following group? This will NOT delete students and their data. The students still will be available and can be added to another group.',
		deleteButtonText: 'Delete',
	},
	[EntityType.Student]: {
		headerTitle: 'Delete Student?',
		bodyText:
			'Are you sure you wish to delete the following student from the system? Doing so will also remove all associated data which is not recoverable.',
		deleteButtonText: 'Delete',
	},
};
