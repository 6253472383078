import {
	Class,
	User,
	Student,
	School,
	SpecialistGroup,
	useSchools,
	useTeachers,
	useClasses,
	useSpecialistGroups,
	useSchoolSpecialists,
	useDistrictSpecialists,
} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {ColDef} from 'ag-grid-community';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {getFullName, GradeLevel, gradeLevelDictionary} from '@esgi/main/kits/common';
import {EntitiesFilter} from '@esgi/ui/ag-grid';
import {StudentRowModel} from '@esgi/main/kits/admin';

type RowModel = StudentRowModel & {
	school: School | null;
	primaryTeacher: User | null;
	classes: Class[];
	specialists: User[];
	specialistGroups: SpecialistGroup[];
	grade: GradeLevel | null;
};

export function useAddStudentAlertData({
	allStudents,
	activeStudents,
	currentSpecialistGroupID,
}: {
	allStudents: Student[];
	activeStudents: Student[];
	currentSpecialistGroupID: SpecialistGroup['id'] | null;
}) {
	const [{data: schools, loaded: isSchoolsLoaded}] = useSchools();
	const [{data: teachers, loaded: isTeachersLoaded}] = useTeachers();
	const [{data: classes, loaded: isClassesLoaded}] = useClasses();
	const [{data: districtSpecialists, loaded: isDistrictSpecialistsLoaded}] = useDistrictSpecialists();
	const [{data: schoolSpecialists, loaded: schoolSpecialistsLoaded}] = useSchoolSpecialists();
	const [{data: specialistGroups, loaded: specialistGroupsLoaded}] = useSpecialistGroups();

	const {
		uniqSchoolsIDs,
		uniqTeacherIDs,
		uniqClassesIDs,
		uniqSpecialistsIDs,
		uniqSpecialistGroupsIDs,
		uniqGradeLevelsIDs,
	} = useMemo(() => {
		return {
			uniqSchoolsIDs: [...new Set(allStudents.map(({schoolID}) => schoolID))],
			uniqTeacherIDs: [...new Set(allStudents.map(({teacherID}) => teacherID))],
			uniqClassesIDs: [...new Set(allStudents.flatMap(({classesIDs}) => classesIDs))],
			uniqSpecialistsIDs: [...new Set(allStudents.flatMap(({specialistsIDs}) => specialistsIDs))],
			uniqSpecialistGroupsIDs: [...new Set(allStudents.flatMap(({specialistGroupsIDs}) => specialistGroupsIDs))],
			uniqGradeLevelsIDs: [...new Set(allStudents.map(({gradeLevelID}) => gradeLevelID))],
		};
	}, [allStudents]);

	const splittedSpecialists = useMemo<User[]>(
		() => [...districtSpecialists, ...schoolSpecialists],
		[districtSpecialists, schoolSpecialists],
	);

	const colDefs = useMemo<ColDef<RowModel>[]>(
		() => [
			{
				field: 'school',
				width: 120,
				sortable: true,
				filter: EntitiesFilter,
				filterParams: {
					entities: schools.filter(({id}) => uniqSchoolsIDs.includes(id)),
				},
				headerName: 'School',
				resizable: false,
				cellDataType: 'text',
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return '-';
					}

					return isNull(data.school) ? '-' : data.school.name;
				},
			},
			{
				field: 'primaryTeacher',
				width: 120,
				sortable: true,
				headerName: 'Primary Teacher',
				filter: EntitiesFilter,
				filterParams: {
					entities: teachers.filter(({id}) => uniqTeacherIDs.includes(id)),
					emptyEntityName: 'Show Unassigned',
				},
				resizable: false,
				cellDataType: 'text',
			},
			{
				field: 'classes',
				width: 120,
				sortable: true,
				filter: EntitiesFilter,
				filterParams: {
					entities: classes.filter(({id}) => uniqClassesIDs.includes(id)),
				},
				headerName: 'Class',
				resizable: false,
				cellDataType: 'text',
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return '-';
					}

					const classes = data.classes;

					return classes.length ? classes.map(({name}) => name).join(', ') : '-';
				},
			},
			{
				field: 'specialists',
				width: 120,
				sortable: true,
				filter: EntitiesFilter,
				filterParams: {
					entities: splittedSpecialists.filter(({id}) => uniqSpecialistsIDs.includes(id)),
				},
				headerName: 'Specialist',
				resizable: false,
				cellDataType: 'text',
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return '-';
					}

					const specialistGroups = data.specialists;

					return specialistGroups.length
						? specialistGroups
								.map(({firstName, lastName}) =>
									getFullName({
										firstName,
										lastName,
									}),
								)
								.join(', ')
						: '-';
				},
			},
			{
				field: 'specialistGroups',
				width: 120,
				sortable: true,
				filter: EntitiesFilter,
				filterParams: {
					entities: specialistGroups.filter(({id}) => uniqSpecialistGroupsIDs.includes(id)),
				},
				headerName: 'Specialist Group',
				resizable: false,
				cellDataType: 'text',
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return '-';
					}

					const specialistGroups = data.specialistGroups;

					return specialistGroups.length ? specialistGroups.map(({name}) => name).join(', ') : '-';
				},
			},
			{
				field: 'grade',
				width: 90,
				sortable: true,
				filter: EntitiesFilter,
				filterParams: {
					entities: Object.values(gradeLevelDictionary).filter(({id}) => uniqGradeLevelsIDs.includes(id)),
				},
				headerName: 'Grade',
				resizable: false,
				cellDataType: 'text',
				valueGetter: ({data}) => {
					if (isUndefined(data)) {
						return '-';
					}

					const studentGrade = data.grade;

					return isNull(studentGrade) ? '-' : studentGrade.name;
				},
			},
		],
		[
			classes,
			schools,
			specialistGroups,
			splittedSpecialists,
			teachers,
			uniqClassesIDs,
			uniqGradeLevelsIDs,
			uniqSchoolsIDs,
			uniqSpecialistGroupsIDs,
			uniqSpecialistsIDs,
			uniqTeacherIDs,
		],
	);

	const selectedItemsIDs = useMemo(() => activeStudents.map(({id}) => id), [activeStudents]);

	const {rowsData, disabledRowsAsSelectedIDs} = useMemo(() => {
		const disabledRowsAsSelectedIDs: RowModel['id'][] = [];

		const rowsData = allStudents.map<RowModel>(
			({
				firstName,
				lastName,
				teacherID,
				id,
				schoolID,
				classesIDs,
				specialistGroupsIDs,
				specialistsIDs,
				gradeLevelID,
			}) => {
				const filteredSpecialistGroups = specialistGroups.filter(({id}) => specialistGroupsIDs.includes(id));

				if (filteredSpecialistGroups.some(({id}) => id === currentSpecialistGroupID) && selectedItemsIDs.includes(id)) {
					disabledRowsAsSelectedIDs.push(id);
				}

				return {
					id,
					firstName,
					lastName,
					school: schools.find(({id}) => id === schoolID) ?? null,
					primaryTeacher: teachers.find(({id}) => id === teacherID) ?? null,
					classes: classes.filter(({id}) => classesIDs.includes(id)),
					specialists: splittedSpecialists.filter(({id}) => specialistsIDs.includes(id)),
					specialistGroups: filteredSpecialistGroups,
					grade: gradeLevelDictionary[gradeLevelID] ?? null,
				};
			},
		);

		return {
			disabledRowsAsSelectedIDs,
			rowsData,
		};
	}, [
		allStudents,
		classes,
		currentSpecialistGroupID,
		schools,
		selectedItemsIDs,
		specialistGroups,
		splittedSpecialists,
		teachers,
	]);

	return {
		colDefs,
		rowsData,
		disabledRowsAsSelectedIDs,
		selectedItemsIDs,
		skeleton:
			!isSchoolsLoaded ||
			!isTeachersLoaded ||
			!isClassesLoaded ||
			!isDistrictSpecialistsLoaded ||
			!schoolSpecialistsLoaded ||
			!specialistGroupsLoaded,
	};
}
