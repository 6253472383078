import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Checkbox} from '@esgi/ui/controls';
import {CheckAll} from '@esgi/ui/icons';

export const ItemText = styled(Text, {
	display: 'block',
	'&:hover': {
		color: '$primary',
		cursor: 'pointer',
	},

	variants: {
		checked: {
			true: {
				color: '$primary',
			},
		},
	},
});

export const EntityItem = styled(FlexBox, {
	gap: 8,
	width: '100%',
});

export const CheckboxStyled = styled(Checkbox, {
	overflow: 'hidden',

	'> div': {
		width: '16px',
		height: '16px',
		maxWidth: '16px',
		minWidth: '16px',
	},

	'> span': {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	},
});

export const AllCheckedIcon = styled(CheckAll, {
	variants: {
		checked: {
			true: {
				'& path': {
					fill: '$primary !important',
				},
			},
		},
	},
});

export const NoItemsContainer = styled(FlexBox, {
	width: '100%',
	justifyContent: 'center',
});

export const TextWrapper = styled(FlexBox, {
	width: '100%',
	justifyContent: 'start',
	alignItems: 'center',
	overflow: 'hidden',
});