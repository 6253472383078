import {TestType} from '@esgi/main/kits/common';
import {TestPanelCard} from '../../types';
import {SortingComparatorCb} from './types';

export function testNameComparator<T extends TestPanelCard>(a: T, b: T): number {
	return a.name.trim().localeCompare(b.name.trim());
}

export function contentAreaComparator<T extends TestPanelCard>(a: T, b: T): number {
	return a.contentArea.localeCompare(b.contentArea);
}

export function totalPossibleComparator<T extends TestPanelCard>(a: T, b: T): number {
	const aValue = a.type === TestType.Score ? 1 : a.totalPossible;
	const bValue = b.type === TestType.Score ? 1 : b.totalPossible;

	return aValue - bValue;
}

export const SortingComparator: Record<
	'testName' | 'contentArea' | 'totalPossible',
	SortingComparatorCb<TestPanelCard>
> = {
	testName: testNameComparator,
	contentArea: contentAreaComparator,
	totalPossible: totalPossibleComparator,
};
