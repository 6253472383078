import {useMemo} from 'react';
import {isNull} from 'underscore';
import {getFullName} from '@esgi/main/kits/common';
import {SchoolStudentsNeedingSupportStudentData} from '../../../types/students-needing-support';
import {roundNumber} from '../utils/round-number';
import {StudentsTwoPeriodData} from '../types';
import {GradeLevel} from '@esgi/main/features/admins/dashboard';

export function useStudentsTwoPeriodsItems({
	studentsData,
	getClassName,
	getGradeShortName,
}: {
	studentsData: SchoolStudentsNeedingSupportStudentData[];
	getClassName: (id: number | null) => string;
	getGradeShortName: (gradeID: GradeLevel['id']) => string | null;
}) {
	return useMemo<StudentsTwoPeriodData[]>(() => {
		return studentsData.map(({student, teacher, avgResults, delta}) => {
			const avgFirst = avgResults[0] ? roundNumber(avgResults[0], {precise: 1}) : null;
			const avgSecond = avgResults[1] ? roundNumber(avgResults[1], {precise: 1}) : null;

			const deltaValue = isNull(avgFirst) || isNull(avgSecond) ? null : roundNumber(delta);

			return {
				rowID: student.id,
				studentID: student.id,
				student: getFullName({
					firstName: student.firstName,
					lastName: student.lastName,
				}),
				teacher: !isNull(teacher)
					? getFullName({
							firstName: teacher.firstName,
							lastName: teacher.lastName,
						})
					: null,
				class: getClassName(student.classIDs[0] ?? null),
				grade: getGradeShortName(student.gradeLevelID),
				avgFirst,
				avgSecond,
				delta: deltaValue,
			};
		});
	}, [getGradeShortName, getClassName, studentsData]);
}
