import {Avatar} from '@esgi/ui/avatar';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {CSS} from '@stitches/react';

export const avatarRootCSS: CSS = {
	borderColor: '$border',
};

export const AvatarFallbackContentBox = styled(GridBox, {
	display: 'grid',
	gap: '$1',
	justifyItems: 'center',

	'& > svg': {
		width: 24,
		height: 24,
	},
});

export const AvatarFallback = styled(Avatar.Fallback, {
	transition: 'background-color .3s',

	[`& > ${AvatarFallbackContentBox}`]: {
		'& > svg': {
			'& > *': {
				transition: 'fill .3s',
			},
		},

		[`& > ${Text}`]: {
			transition: 'color .3s',
		},
	},

	'&:hover': {
		backgroundColor: '$secondaryMuted',

		[`& > ${AvatarFallbackContentBox}`]: {
			'& > svg': {
				'& > *': {
					fill: '$secondary',
				},
			},

			[`& > ${Text}`]: {
				color: '$secondary',
			},
		},
	},
});

export const AvatarHoverLayer = styled(Avatar.HoverLayer, {
	borderRadius: 'inherit',

	'&:hover': {
		background: '$secondaryMuted',
		mixBlendMode: 'multiply',
	},
});
