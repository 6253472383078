import {
	IAfterGuiAttachedParams,
	IDoesFilterPassParams,
} from 'ag-grid-community';
import {useCallback, useState} from 'react';
import {CustomFilterProps, useGridFilter} from 'ag-grid-react';
import {CustomFilterCallbacks} from 'ag-grid-react/dist/types/src/shared/customComp/interfaces';
import {formatName} from './utils';

const emptyFunction = () => {
};

export function useAgGridFilter(filterParams: CustomFilterProps, callbacks: CustomFilterCallbacks) {
	const [visible, setVisible] = useState(false);

	const [name, setName] = useState(() => formatName(filterParams.colDef?.headerName || filterParams.colDef.field || ''));

	const [onCloseCallback, setOnCloseCallback] = useState<{ current: VoidFunction }>(() => ({current: emptyFunction}));


	const afterGuiAttached = useCallback((params?: IAfterGuiAttachedParams) => {
		if (params && params.hidePopup) {
			setOnCloseCallback({current: params.hidePopup});
		}
		setVisible(true);
		callbacks?.afterGuiAttached?.(params);
	}, [callbacks.afterGuiAttached]);

	const afterGuiDetached = useCallback(() => {
		setOnCloseCallback({current: emptyFunction});
		setVisible(false);
		callbacks?.afterGuiDetached?.();
	}, [callbacks.afterGuiDetached]);

	const doesFilterPass = useCallback((params: IDoesFilterPassParams) => {
		if (filterParams.model) {
			return callbacks.doesFilterPass(params);
		} else {
			return true;
		}
	}, [callbacks.doesFilterPass, filterParams.model]);


	useGridFilter({
		getModelAsString: callbacks.getModelAsString,
		onNewRowsLoaded: callbacks.onNewRowsLoaded,
		onAnyFilterChanged: callbacks.onAnyFilterChanged,

		doesFilterPass,
		afterGuiAttached,
		afterGuiDetached,
	});

	return {
		columnName: name,
		filterPopupVisible: visible,
		closePopup: onCloseCallback.current,
	};
}