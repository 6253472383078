import {Dispatch} from 'react';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/controls';
import {SelectableListItemModel} from '../../types';

interface Props {
	teachers: SelectableListItemModel<string>[];
	selectedTeacher: string;
	onTeacherSelect: Dispatch<string>;
}

export function TeacherFilter({teachers, selectedTeacher, onTeacherSelect}: Props) {
	return (
		<Select.Root value={selectedTeacher} onValueChange={onTeacherSelect}>
			<Select.Field placeholder='Teacher' />
			<Select.Content>
				{teachers.map(({value, label}) => (
					<Select.Option value={value} key={value}>
						<Text size='medium' bold>
							{label}
						</Text>
					</Select.Option>
				))}
			</Select.Content>
		</Select.Root>
	);
}
