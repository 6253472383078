import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Close} from '@esgi/ui/icons';
import {Button} from '@esgi/ui';
import {useDashboardPageContext} from '../../../../context';
import {SaveButton} from './index.styled';
import {useCallback, useState} from 'react';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';

type Props = {
	onSaveClick: VoidFunction;
	isSaveDisabled: boolean;
};

export function RearrangeMode({onSaveClick, isSaveDisabled}: Props) {
	const {setPageViewMode} = useDashboardPageContext();

	const [isUnsavedChangesConfirmation, setIsUnsavedChangesConfirmation] = useState(false);

	const handleSaveClick = useCallback(() => {
		onSaveClick();
		setPageViewMode();
	}, [onSaveClick, setPageViewMode]);

	const handleCloseRearrangeMode = useCallback(() => {
		if (!isSaveDisabled) {
			setIsUnsavedChangesConfirmation(true);
			return;
		}

		setPageViewMode();
	}, [isSaveDisabled, setPageViewMode]);

	const onCloseUnsavedChangesConfirmation = useCallback(() => {
		setIsUnsavedChangesConfirmation(false);
	}, []);

	return (
		<>
			<Text size='small' color='primary'>
				Rearrange Mode
			</Text>

			<GridBox flow='column' gap='4' align='center'>
				<SaveButton color='secondary' onClick={handleSaveClick} disabled={isSaveDisabled}>
					<Text size='medium' bold color='base'>
						Save
					</Text>
				</SaveButton>

				<Button.Icon withBackgroundHover onClick={handleCloseRearrangeMode}>
					<Close />
				</Button.Icon>
			</GridBox>

			{isUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onCloseAnyway={setPageViewMode} onClose={onCloseUnsavedChangesConfirmation} />
			)}
		</>
	);
}
