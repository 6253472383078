import {StyledImage} from './index.styled';
import {QuestionModel, TestModel} from '../../../../service/types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {notAvailableSrc} from '../../constants';
import {imageQueueService} from 'pages/test-explorer/services/image-queue-service';
import {createQuestionImageUrlBuilder} from 'shared/tools/question-image-url-builder/question-image-url-builder';
import {SpringValue} from 'react-spring';

type Props = {
	testID: TestModel['testID'],
	currentQuestionIdx: number,
	questions: QuestionModel[],
	style: {opacity: SpringValue<number>, transform: SpringValue<string>}
}

export function QuestionsSliderItem({questions, currentQuestionIdx, testID, style}: Props) {
	const imgRef = useRef<HTMLImageElement>(null);

	const urlBuilder = useMemo(() => createQuestionImageUrlBuilder(), []);

	const [imageLoadingErrorTimes, setImageLoadingErrorTimes] = useState(0);

	const url = useMemo(() => {
		const currentQuestion = questions[currentQuestionIdx];
		if (!currentQuestion){
			return notAvailableSrc;
		}

		if (!currentQuestion.pregenerated) {
			return null;
		}

		return urlBuilder.medium(currentQuestion?.questionID, testID, currentQuestion?.pregenerated, currentQuestion?.ticks);
	}, [questions, currentQuestionIdx, urlBuilder, testID]);

	const onImageLoadingError = useCallback(() => {
		if (imageLoadingErrorTimes === 0) {
			const url = urlBuilder.medium(questions[currentQuestionIdx]?.questionID, testID, false, questions[currentQuestionIdx]?.ticks);
			imageQueueService.addToQueue(url, imgRef.current);
		} else {
			imgRef.current.src = notAvailableSrc;
		}

		setImageLoadingErrorTimes(prev => prev + 1);
	}, [currentQuestionIdx, imageLoadingErrorTimes, questions, testID, urlBuilder]);

	useEffect(() => {
		const question = questions[currentQuestionIdx + 1];
		if (question?.pregenerated) {
			new Image().src = urlBuilder.medium(question.questionID, testID, question.pregenerated, question.ticks);
		}
	}, [currentQuestionIdx, questions, testID, urlBuilder]);

	useEffect(() => {
		const question = questions[currentQuestionIdx];
		if (question?.pregenerated || !imgRef.current) {
			return;
		}

		const url = urlBuilder.medium(question?.questionID, testID, question?.pregenerated, question?.ticks);
		imageQueueService.addToQueue(url, imgRef.current);
	}, [currentQuestionIdx, questions, testID, urlBuilder]);

	return (
		<StyledImage
			ref={imgRef}
			src={url}
			onError={onImageLoadingError}
			style={style}
			loading='lazy'
		/>
	);
}
