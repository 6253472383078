import {StudentBox, StudentNameBox} from '../index.styled';
import {SkeletonShape} from '@esgi/main/kits/common';
import {CardsContainer} from '../../cards-container.styled';

export function SkeletonData() {
	return (
		<>
			{new Array(10).fill(null).map((_, index) => (
				<StudentBox key={index}>
					<StudentNameBox>
						<SkeletonShape width={24} height={24} shape='round' />
						<SkeletonShape width={70} height={12} />
					</StudentNameBox>

					<CardsContainer>
						{new Array(20).fill(20).map((_, index) => (
							<SkeletonShape width='unset' height={185} key={index} />
						))}
					</CardsContainer>
				</StudentBox>
			))}
		</>
	);
}
