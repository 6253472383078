import {Color} from '@esgi/main/features/admins/dashboard';
import {DatePickerData, isDateTouched} from '@esgi/main/kits/admin';

export function isPeriodRowTouched({
	initialColor,
	currentColor,
	initialDates,
	currentDates,
}: {
	currentColor: Color | null | undefined;
	initialColor: Color | null | undefined;
	initialDates: DatePickerData;
	currentDates: DatePickerData;
}) {
	const isColorTouched = currentColor !== initialColor;
	const isDateRangeTouched = isDateTouched(currentDates, initialDates);

	return isColorTouched || isDateRangeTouched;
}
