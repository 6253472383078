import {useMemo} from 'react';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui';
import {getFullName} from '@esgi/main/kits/common';
import {SelectedEntityInfo, SelectedEntityInfoItem} from '@esgi/main/features/admins/dashboard';
import {TeacherModel} from '../../types';

type Props = {
	teachersIDs: number[];
	allTeachersList: TeacherModel[];
};

export function SelectedTeachersInfo({teachersIDs, allTeachersList}: Props) {
	const teachersItems = useMemo(() => {
		return teachersIDs
			.map<SelectedEntityInfoItem<number> | null>((teacherID) => {
				const teacherModel = allTeachersList.find(({id}) => id === teacherID);

				if (isUndefined(teacherModel)) {
					return null;
				}

				return {
					label: getFullName({firstName: teacherModel.firstName, lastName: teacherModel.lastName}),
					value: teacherModel.id,
				};
			})
			.filter((item): item is SelectedEntityInfoItem<number> => !isNull(item));
	}, [allTeachersList, teachersIDs]);

	return (
		<SelectedEntityInfo
			items={teachersItems}
			itemsTranscript='Teachers'
			totalPossibleItemsCount={allTeachersList.length}
		/>
	);
}
