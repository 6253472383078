import {Drawer, getFullName} from '@esgi/main/kits/common';
import {EditGroupForm} from '../form-data/types';
import {GroupNameInput} from '../../../components/group-name-input';
import {Input} from '@esgi/ui/controls';
import {Class, School, User} from '@esgi/main/libs/admin-store';

type Props = {
	form: EditGroupForm;
	school: School;
	currentClass: Class;
	primaryTeacher: User;
};

export function GroupInformationTabContent({form, school, currentClass, primaryTeacher}: Props) {
	return (
		<>
			<Drawer.ContentBlock title='Location' withDivider>
				<Input placeholder='School' value={school.name} disabled />
				<Input
					placeholder='Primary Teacher'
					value={getFullName({
						firstName: primaryTeacher.firstName,
						lastName: primaryTeacher.lastName,
					})}
					disabled
				/>
				<Input placeholder='Class' value={currentClass.name} disabled />
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Group Information' withDivider>
				<GroupNameInput groupNameControl={form.controls.groupName} />
			</Drawer.ContentBlock>
		</>
	);
}
