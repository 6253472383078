import {ComponentPropsWithoutRef, PropsWithChildren, forwardRef} from 'react';
import {ChildrenBox, Container, Divider, SectionTitle, TitleContainer} from './index.styled';
import {BaseComponentProps} from '@esgi/ui';
import {usePanelContext} from '../../context';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<typeof Container>, 'children' | 'title' | 'skeleton'> &
	NonNullable<PropsWithChildren> & {
		sectionTitle: string;
		withoutDivider?: boolean;
	};

export const SectionBlock = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'section-block', css = {}, withoutDivider = false, children, sectionTitle, ...props}, forwardedRef) => {
		const {skeleton} = usePanelContext();

		return (
			<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				<TitleContainer>
					<SectionTitle size='small' font='mono' color='mediumContrast' skeleton={skeleton}>
						{sectionTitle}
					</SectionTitle>

					{!withoutDivider && <Divider />}
				</TitleContainer>

				<ChildrenBox data-children-box=''>{children}</ChildrenBox>
			</Container>
		);
	},
);
