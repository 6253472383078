import {useCallback, useState} from 'react';
import {AddEditStudent, TransferStudent} from '@esgi/main/features/admins/student-profile-drawer';
import {DistrictReportPanel, Page} from '../../../../_kit';
import {BottomPanel} from './bottom-panel';
import {RowDef} from '../../../types';
import {SingleItemExpandablePanel} from './single-item-expandable-panel';
import {useDataPageContext} from '../../../../layout/context';
import {Portal} from '@esgi/ui';

interface Props {
	onOpenAddDrawer: VoidFunction;
}

export function Content({onOpenAddDrawer}: Props) {
	const {api} = Page.useContext<RowDef>();

	const [editStudentID, setEditStudentID] = useState<number>(null);
	const [transferStudentID, setTransferStudentID] = useState<number>(null);
	const {districtName, pageContentContainerRef} = useDataPageContext();

	const handleOpenTransferStudent = useCallback(() => {
		if (!editStudentID) {
			return;
		}

		setEditStudentID(null);
		setTransferStudentID(editStudentID);
	}, [editStudentID]);

	return (
		<>
			<Page.Header.Base onAddButtonClick={onOpenAddDrawer} />
			<Page.Body.Base />

			<SingleItemExpandablePanel onEditClicked={setEditStudentID} />
			<DistrictReportPanel api={api} title={districtName} />
			<BottomPanel />

			{editStudentID && (
				<Portal container={pageContentContainerRef.current}>
					<AddEditStudent
						studentID={String(editStudentID)}
						onOpenTransferStudent={handleOpenTransferStudent}
						onClose={() => setEditStudentID(null)}
					/>
				</Portal>
			)}

			{transferStudentID && (
				<Portal container={pageContentContainerRef.current}>
					<TransferStudent
						studentID={transferStudentID}
						onClose={() => setTransferStudentID(null)}
						onTransfered={() => setEditStudentID(transferStudentID)}
					/>
				</Portal>
			)}
		</>
	);
}
