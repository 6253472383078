import {lazyComponent} from '@esgi/core/react';
import {withErrorHandling} from '@esgillc/ui-kit/core';

export {DataLayout} from './data/layout';

export const Root = withErrorHandling(lazyComponent(() => import('./page-with-navigation')));
export const Dashboard = withErrorHandling(lazyComponent(() => import('./dashboard')));
export const SubjectManagement = withErrorHandling(lazyComponent(() => import('./subject-management')));
export const TestExplorer = withErrorHandling(lazyComponent(() => import('./test-explorer')));
export const StudentManager = withErrorHandling(lazyComponent(() => import('./student-manager')));
export const NotFound = withErrorHandling(lazyComponent(() => import('./not-found')));

export * from './data';
