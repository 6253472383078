import {styled} from '@esgi/ui/theme';
import {DeltaCheap} from '../../../../../kit';

export const DeltaCheapBox = styled(DeltaCheap, {
	justifySelf: 'center',

	variants: {
		transparent: {
			true: {
				opacity: 0,
			},
		},
	},
});
