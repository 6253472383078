import {ComponentProps} from 'react';
import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Label, ButtonLabel} from '../../index.styled';

interface Props {
	onDelete: VoidFunction;
	onCancel: VoidFunction;
	portalProps?: ComponentProps<typeof Alert>['portalProps']
}

export function DeletePhotoAlert({onDelete, onCancel, portalProps}: Props) {
	const alertRef = Alert.useRef();

	const deleteClicked = Alert.useClose(alertRef, onDelete);
	const cancelClicked = Alert.useClose(alertRef, onCancel);

	return (
		<Alert portalProps={portalProps} colorConfig={alertColorsConfig.negative} modalManagerRef={alertRef}>
			<Alert.Header withBacklight onCloseIconClick={cancelClicked}>
				<Text>Delete Student Photo</Text>
			</Alert.Header>
			<Alert.Body>
				<Label size='medium'>
					Are you sure you want to delete the photo?
				</Label>
			</Alert.Body>
			<Alert.Footer align='center' gapX={3}>
				<Button color='tertiary' onClick={cancelClicked}>
					<ButtonLabel>Cancel</ButtonLabel>
				</Button>
				<Button onClick={deleteClicked} color='secondary'>
					<ButtonLabel>Delete</ButtonLabel>
				</Button>
			</Alert.Footer>
		</Alert>
	);
}
