import {
	Question,
	TestInfo,
	TestSession,
	UpdateYNSessionRequestParams,
	UpdateYNScoreSessionResponse,
} from '../../../../../types/view-edit-session-details';
import {useCallback, useMemo} from 'react';
import {ViewModeContent} from './components/view-mode-content';
import {EditModeContent} from './components/edit-mode-content';
import {Observable} from 'rxjs';
import {Student} from '../../../../../types/common';

type Props = {
	canEdit: boolean;
	isEditMode: boolean;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	onCloseAlert: VoidFunction;
	sessionInfo: TestSession;
	testInfo: TestInfo;
	student: Student;
	onDeleteSession: VoidFunction;
	onRestoreSession: VoidFunction;
	updateYNSession: (args: UpdateYNSessionRequestParams) => Observable<UpdateYNScoreSessionResponse>;
	onDownloadSession: VoidFunction;
};

export function YesNoTestSession({
	canEdit,
	isEditMode,
	onCloseAlert,
	sessionInfo,
	testInfo,
	setIsEditMode,
	student,
	onDeleteSession,
	onRestoreSession,
	updateYNSession,
	onDownloadSession,
}: Props) {
	const questionTitlesById = useMemo(() => {
		const questionTitlesById: Record<Question['id'], Question['label']> = {};

		testInfo.questions.forEach(({id, label}) => (questionTitlesById[id] = label));

		return questionTitlesById;
	}, [testInfo]);

	const onUpdateTestSession = useCallback(
		({
			answers,
			notes,
			testDateTouched,
			testDate,
		}: {
			answers: UpdateYNSessionRequestParams['answers'];
			notes: string;
			testDateTouched: boolean;
			testDate: string;
		}) => {
			updateYNSession({
				studentID: student.id,
				testID: sessionInfo.testID,
				testSessionID: sessionInfo.id,
				answers,
				notes,
				testDate,
				testDateTouched,
			}).subscribe({
				complete: () => {
					setIsEditMode(false);
				},
			});
		},
		[sessionInfo.id, sessionInfo.testID, setIsEditMode, student.id, updateYNSession],
	);

	if (isEditMode && !sessionInfo.isDeleted) {
		return (
			<EditModeContent
				canEdit={canEdit}
				sessionInfo={sessionInfo}
				questionTitlesById={questionTitlesById}
				testInfo={testInfo}
				onCloseAlert={onCloseAlert}
				setIsEditMode={setIsEditMode}
				student={student}
				onUpdateTestSession={onUpdateTestSession}
			/>
		);
	}

	return (
		<ViewModeContent
			canEdit={canEdit}
			sessionInfo={sessionInfo}
			questionTitlesById={questionTitlesById}
			testInfo={testInfo}
			onCloseAlert={onCloseAlert}
			setIsEditMode={setIsEditMode}
			student={student}
			onDeleteSession={onDeleteSession}
			onRestoreSession={onRestoreSession}
			onDownloadSession={onDownloadSession}
		/>
	);
}
