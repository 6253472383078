import {Dispatch} from 'react';
import {EntireSectionBlock} from '../../../../entire-section-block';
import {PeriodTabID} from '../../types';
import {GridBox} from '@esgi/ui/layout';
import {RadioGroup} from '@esgi/ui/controls';
import {items} from './constants';
import {Text} from '@esgi/ui/typography';
import {TrackDate} from '../../../types';
import {MarkingPeriodsBox} from './index.styled';
import {Checkbox} from '../../../../checkbox.styled';

type Props = {
	periodTypeID: PeriodTabID;
	setPeriodTabID: Dispatch<PeriodTabID>;
	selectedTrackDatesIDs: number[];
	setSelectedTrackDatesIDs: Dispatch<number[]>;
	visibleTrackDates: TrackDate[];
};

export function PeriodTab({
	periodTypeID,
	setPeriodTabID,
	selectedTrackDatesIDs,
	setSelectedTrackDatesIDs,
	visibleTrackDates,
}: Props) {
	const onSelectedTrackIDChanged = (id: number, checked: boolean) => {
		setSelectedTrackDatesIDs(
			checked ? [...selectedTrackDatesIDs, id] : selectedTrackDatesIDs.filter((item) => item !== id),
		);
	};

	return (
		<GridBox gap='2'>
			<EntireSectionBlock>
				<RadioGroup.Root value={periodTypeID} onValueChange={setPeriodTabID}>
					<RadioGroup.Content orientation='vertical'>
						{items.map(({value, label}) => (
							<RadioGroup.Item key={value} value={value}>
								<Text size='small' font='mono' color='currentColor'>
									{label}
								</Text>
							</RadioGroup.Item>
						))}
					</RadioGroup.Content>
				</RadioGroup.Root>
			</EntireSectionBlock>

			{periodTypeID === PeriodTabID.MarkingPeriod && (
				<MarkingPeriodsBox>
					{visibleTrackDates.map(({trackDateID}, index) => (
						<Checkbox
							checked={selectedTrackDatesIDs.includes(trackDateID)}
							onCheckedChange={(value) => onSelectedTrackIDChanged(trackDateID, Boolean(value))}
							label={`Q${index + 1}`}
							key={trackDateID}
						/>
					))}
				</MarkingPeriodsBox>
			)}
		</GridBox>
	);
}
