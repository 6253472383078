import {ManageSubjectsAndTestsController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {Subject, subjectsStore, getContractSubjectType} from '@esgi/main/libs/admin-store';
import {useCancelableRequest} from '@esgi/ui';
import {useCallback} from 'react';

type Parameters = {
	subjectFromStore: Subject;
};

export function useDeleteTest({subjectFromStore}: Parameters) {
	const subjectsController = useDisposable(ManageSubjectsAndTestsController);

	const [, removeTest] = useCancelableRequest(subjectsController.removeTest);

	return useCallback(
		(testID: number) => {
			removeTest({
				subjectID: subjectFromStore.id,
				subjectType: getContractSubjectType(subjectFromStore.subjectType),
				testID,
			});

			subjectsStore().update?.((item: Subject) => {
				if (item.id === subjectFromStore.id) {
					return {
						...item,
						tests: item.tests.filter(({id}) => id !== testID),
					};
				}

				return item;
			});
		},
		[removeTest, subjectFromStore],
	);
}
