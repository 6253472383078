import {FolderManagement} from '../../../kit';
import {ErrorTooltipItem} from './types';

export const nameFolderErrors: ErrorTooltipItem[] = [
	{
		showOnError: 'required',
		message: 'At least one character required',
	},
	{
		showOnError: 'length-max',
		message: `Max length is ${FolderManagement.folderNameMaxLength} characters`,
	},
	{
		showOnError: 'isDublicateValue',
		message: 'Duplicate name',
	},
];
