import {Portal} from '@esgi/ui';
import {useDataPageContext} from '../../../layout/context';
import {CollapsiblePanel} from '@esgi/main/features/admins/data';
import {GridApi} from 'ag-grid-community';
import {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {isNull} from 'underscore';
import {ActionPanelContext, ActionPanelContextValue} from '../context';

type Props<T extends Record<string, unknown>> = PropsWithChildren<{
	api: GridApi<T> | null;
	showOnMinSelectedItems?: number;
	showOnMaxSelextedItems?: number
}>;

export function Root<T extends Record<string, unknown>>({api, children, showOnMinSelectedItems = 1, showOnMaxSelextedItems}: Props<T>) {
	const {pageContentContainerRef} = useDataPageContext();

	const [selectedItemsCount, setSelectedItems] = useState(0);
	const [showPanel, setShowPanel] = useState(false);

	const managerRef = CollapsiblePanel.useRef();
	const closeActionPanel = CollapsiblePanel.useClose(managerRef, () => {
		setShowPanel(false);
	});

	const deselectItems = useCallback(() => {
		api?.deselectAll();
		api?.updateGridOptions({isExternalFilterPresent: () => false});
	}, [api]);

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		const handler = () => {
			const selectedRows = api.getSelectedRows();
			setSelectedItems(selectedRows?.length || 0);
		};

		api.addEventListener('selectionChanged', handler);

		return () => api.removeEventListener('selectionChanged', handler);
	}, [api]);

	useEffect(() => {
		if (
			selectedItemsCount < showOnMinSelectedItems ||
			(Number.isInteger(showOnMaxSelextedItems) &&
				selectedItemsCount > showOnMaxSelextedItems)
		) {
			closeActionPanel();
			return;
		}

		setShowPanel(true);
	}, [selectedItemsCount, showOnMinSelectedItems, showOnMaxSelextedItems]);

	const context = useMemo<ActionPanelContextValue<T>>(
		() => ({api, selectedItemsCount, deselectItems}),
		[api, deselectItems, selectedItemsCount],
	);

	if (!showPanel) {
		return null;
	}

	return (
		<Portal container={pageContentContainerRef.current}>
			<ActionPanelContext.Provider value={context}>
				<CollapsiblePanel.Root managerRef={managerRef}>
					<CollapsiblePanel.QuickPanel>{children}</CollapsiblePanel.QuickPanel>
				</CollapsiblePanel.Root>
			</ActionPanelContext.Provider>
		</Portal>
	);
}
