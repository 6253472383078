import {PageHeaderRoot} from './components/page-header-root';
import {RearrangeMode} from './variant/rearrange-mode';
import {SkeletonMode} from './variant/skeleton-mode';
import {ViewMode} from './variant/view-mode';

export const PageHeader = Object.assign(PageHeaderRoot, {
	ViewMode,
	RearrangeMode,
	Skeleton: SkeletonMode,
});

export {type RearrangeNotActiveMessage} from './variant/view-mode/types';
