import {NoStudentsAlert, ReportDialog, Reports} from '../../../../../../_kit';
import {useMemo} from 'react';
import {RowDef} from '../../../../../types';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	selectedClass: RowDef;
	onClose: VoidFunction;
};

export function ClassesReportsDialog({selectedClass, onClose}: Props) {
	const hierarchy = useMemo<Hierarchy>(
		() => ({
			schoolID: selectedClass.school.id,
			teacherID: selectedClass.teacher.id,
			classID: selectedClass.id,
		}),
		[selectedClass],
	);

	if (!selectedClass.students) {
		return <NoStudentsAlert onClose={onClose} />;
	}

	return (
		<ReportDialog header={selectedClass.name} onClose={onClose}>
			<ReportDialog.Section title='Student Reports'>
				<Reports.ParentLetter hierarchy={hierarchy} />
				<Reports.StudentDetail hierarchy={hierarchy} />
				<Reports.StudentProgress hierarchy={hierarchy} />
			</ReportDialog.Section>

			<ReportDialog.Section title='Class Reports'>
				<Reports.Totals prefix='Class' hierarchy={hierarchy} />
				<Reports.GradeScale prefix='Class' hierarchy={hierarchy} />
				<Reports.ItemAnalysis hierarchy={hierarchy} />
				<Reports.PieCharts hierarchy={hierarchy} />
				<Reports.RubricResults hierarchy={hierarchy} />
				<Reports.UntestedStudents hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
