import React, {useMemo} from 'react';
import {Avatar} from '@esgi/ui/avatar';
import {Delete, Upload} from '@esgi/ui';
import {School} from '@esgi/contracts/esgi/types/esgi.schools/schools/get/by-district-id/school';
import {StudentInfoRowValue, StudentName, StudentInfo, StudentDetailsContainer, ButtonIcon, StudentInfoRow} from './index.styled';
import {Label} from '../../index.styled';

interface Props {
	schools: School[];
	schoolID: number;
	fullName?: string;
	studentId?: string;
	isAddMode: boolean;
	imageURL?: string;
	onDeleteImage: VoidFunction;
	onUploadPhotoOpen: VoidFunction;
	isInitialized: boolean;
}

export function StudentDetails({schools, schoolID, studentId, imageURL, fullName, isAddMode, onDeleteImage, onUploadPhotoOpen, isInitialized}: Props) {
	const school = useMemo(() => {
		return schools?.find((item) => item.id === schoolID);
	}, [schools, schoolID]);

	return (
		<StudentDetailsContainer>
			<Avatar.Root size='xl' borderColor='primary' skeleton={!isInitialized}>
				<Avatar.Image src={imageURL}/>
				<Avatar.Fallback>
					<Upload width={24} height={24}/>
					{imageURL && <Delete width={24} height={24}/>}
				</Avatar.Fallback>
				<Avatar.HoverLayer>
					<ButtonIcon onClick={onUploadPhotoOpen}>
						<Upload/>
					</ButtonIcon>
					{imageURL && (
						<ButtonIcon onClick={onDeleteImage}>
							<Delete/>
						</ButtonIcon>
					)}
				</Avatar.HoverLayer>
			</Avatar.Root>
			<StudentInfo>
				<StudentName size='large' skeleton={!isInitialized}>
					{isAddMode ? 'Upload a Photo' : fullName}
				</StudentName>
				{studentId && (
					<StudentInfoRow skeleton={!isInitialized}>
						<Label size='xSmall' font='mono' color='lowContrast'>ID:</Label>
						<StudentInfoRowValue size='xSmall' font='mono'>{studentId}</StudentInfoRowValue>
					</StudentInfoRow>
				)}
				{school && (
					<StudentInfoRow skeleton={!isInitialized}>
						<Label size='xSmall' font='mono' color='lowContrast'>School:</Label>
						<StudentInfoRowValue size='xSmall' font='mono'>{school.name}</StudentInfoRowValue>
					</StudentInfoRow>
				)}
			</StudentInfo>
		</StudentDetailsContainer>
	);
}

