import {Fragment} from 'react';
import {Dot, PeriodDatesBox} from './index.styled';
import {WidgetCard} from '../../../widget-card';
import {Color, WidgetPeriod} from '../../../types';

type Props = {
	periodsData: WidgetPeriod[];
	withLastDot?: boolean;
};

export function PeriodDatesInfo({periodsData, withLastDot}: Props) {
	return (
		<PeriodDatesBox>
			{periodsData.map(({color, dateRange, datePeriod, trackDatesID}, index, iteratedPeriods) => {
				return (
					<Fragment key={`${index}-${color}-${dateRange?.dateFrom}-${dateRange?.dateTo}-${datePeriod}-${trackDatesID}`}>
						<WidgetCard.ViewMode.DatePeriod
							datePeriod={datePeriod}
							dateRange={dateRange}
							trackDatesID={trackDatesID}
							textColor={color === Color.None ? 'highContrast' : WidgetCard.chartColor[color]?.activeQuartileBarColor}
						/>

						{(iteratedPeriods[index + 1] || Boolean(withLastDot)) && <Dot />}
					</Fragment>
				);
			})}
		</PeriodDatesBox>
	);
}
