import {Button} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const SaveButton = styled(Button, {
	minWidth: 'unset',
	paddingTop: 9,
	paddingBottom: 9,
	paddingLeft: 13,
	paddingRight: 13,
});
