import {GridStack} from 'gridstack';
import {createRef, RefObject, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {GridStackManagerRef, GridStackWidgetItem, ID, ItemWithRef, GridStackOptions} from '../types';

import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/gridstack-extra.min.css';

type Props<T extends Record<string, unknown>> = {
	items: GridStackWidgetItem<T>[];
	gridStackInstanceOptions: GridStackOptions;
};

export function useGridStack<T extends Record<string, unknown>>({items, gridStackInstanceOptions}: Props<T>) {
	const gridStackContainerRef = useRef<HTMLDivElement>(null);
	const gridStackInstanceRef = useRef<GridStack>();
	const gridStackManagerRef = useRef<GridStackManagerRef>();

	const [isItemsPositionsChanged, setIsItemsPositionsChanged] = useState(false);

	const [draggedItemID, setDraggedItemID] = useState<ID | null>(null);

	const [itemsIndexPosition, setItemsIndexPosition] = useState<Record<ID, number>>({});
	const {current: itemsRefs} = useRef<Record<ID, RefObject<HTMLDivElement>>>({});

	const itemsWithRef = useMemo(() => {
		const itemsWithRef: ItemWithRef<T>[] = [];

		items.forEach((item) => {
			const itemRef = itemsRefs[item.id] ?? createRef<HTMLDivElement>();

			itemsRefs[item.id] = itemRef;

			itemsWithRef.push({
				...item,
				itemRef,
			});
		});

		const notExistedRefsIDs = Object.keys(itemsRefs).filter((id) => !items.some((item) => item.id === Number(id)));

		notExistedRefsIDs.forEach((refID) => {
			const numberedRefID = Number(refID);

			if (!isUndefined(itemsRefs[numberedRefID]) && !isNull(itemsRefs[numberedRefID].current)) {
				delete itemsRefs[numberedRefID];
			}
		});

		return itemsWithRef;
	}, [items]);

	const updateItemsIndexPosition = useCallback(() => {
		const gridStackInstance = gridStackInstanceRef.current;

		if (isUndefined(gridStackInstance)) {
			return;
		}

		const ids: ID[] = [];

		gridStackInstance.save(true, false, ({id}) => {
			ids.push(Number(id));
		});

		setItemsIndexPosition(
			ids.reduce<Record<ID, number>>((currentState, id, index) => {
				currentState[id] = index;

				return currentState;
			}, {}),
		);
	}, []);

	useEffect(() => {
		if (!isNull(gridStackContainerRef.current)) {
			const gridStackInstance = GridStack.init(gridStackInstanceOptions, gridStackContainerRef.current);

			gridStackInstanceRef.current = gridStackInstance;

			gridStackInstance.on('dragstart', (_, element) => {
				if (isUndefined(element.gridstackNode)) {
					return;
				}

				const id = element.gridstackNode.id;

				setDraggedItemID(isUndefined(id) ? null : Number(id));
			});

			gridStackInstance.on('drag', updateItemsIndexPosition);

			gridStackInstance.on('dragstop', () => {
				updateItemsIndexPosition();
				setDraggedItemID(null);
			});

			gridStackInstance.batchUpdate();
			gridStackInstance.removeAll(true);

			itemsWithRef.forEach(({id, itemRef, ...gridStackItem}) => {
				if (itemRef.current) {
					gridStackInstance.addWidget(itemRef.current, {
						id: String(id),
						...gridStackItem,
					});
				}
			});

			gridStackInstance.batchUpdate(false);
		}

		return () => {
			if (!isNull(gridStackContainerRef.current)) {
				const classes = gridStackContainerRef.current.classList;

				classes.forEach((className) => {
					if (className.startsWith('gs-')) {
						classes.remove(className);
					}
				});
			}

			gridStackInstanceRef.current?.offAll();
			gridStackInstanceRef.current?.destroy(false);
		};
	}, [gridStackInstanceOptions, itemsWithRef]);

	useEffect(() => {
		setItemsIndexPosition(
			items.reduce<Record<number, number>>((currentState, {id}, index) => {
				currentState[id] = index;

				return currentState;
			}, {}),
		);
	}, [items]);

	useEffect(() => {
		setIsItemsPositionsChanged(items.some(({id}, index) => itemsIndexPosition[id] !== index));
	}, [items, itemsIndexPosition]);

	useImperativeHandle(
		gridStackManagerRef,
		() => ({
			updateItemsIndexPosition,
		}),
		[updateItemsIndexPosition],
	);

	return {
		gridStackContainerRef,
		gridStackInstance: gridStackInstanceRef.current,
		isItemsPositionsChanged,
		itemsIndexPosition,
		itemsWithRef,
		draggedItemID,
		gridStackManagerRef,
	};
}
