import {isUndefined, ProgressBar} from '@esgi/ui';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {isNull} from 'underscore';

type Props = ComponentPropsWithoutRef<typeof GridBox> & {
	progressBarTitle?: string;
	progressBarSize?: number;
	progressBarCircleStrokeWidth?: number;
	percentageType: boolean;
	value: number | null;
	maxValue: number;
};

export const ProgressWithPointer = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'test-card-progress-with-pointer',
			css = {},
			progressBarSize = 48,
			progressBarTitle,
			progressBarCircleStrokeWidth = 6,
			children,
			percentageType,
			value,
			maxValue,
			...props
		},
		forwardedRef,
	) => {
		const {progressValueTranscript, decimalValue} = useMemo(() => {
			if (isNull(value)) {
				return {
					progressValueTranscript: '-',
					decimalValue: null,
				};
			}

			if (percentageType) {
				return {
					progressValueTranscript: `${Math.round(value)}%`,
					decimalValue: null,
				};
			}

			const [progressValueTranscript, decimalValue] = value.toFixed(1).split('.');

			return {
				progressValueTranscript,
				decimalValue: isUndefined(decimalValue) || decimalValue === '0' ? null : decimalValue,
			};
		}, [value, percentageType]);

		const isProgressSuccess: boolean = isNull(value) ? false : value / maxValue > 0.5;

		return (
			<GridBox dataCy={dataCy} css={css} gap={4} display='inlineGrid' ref={forwardedRef} {...props}>
				<GridBox gap='3' flow='column'>
					<ProgressBar value={value ?? 0} maxValue={maxValue}>
						<ProgressBar.RingWithPointer
							size={progressBarSize}
							pointerFillColor={isProgressSuccess ? 'positive' : 'highContrast'}
							progressValueColor={isProgressSuccess ? 'positiveVivid' : 'lowContrast'}
							circleStrokeWidth={progressBarCircleStrokeWidth}
						>
							<FlexBox align={decimalValue ? 'baseline' : 'center'}>
								<Text size='medium' bold font='mono' color={isProgressSuccess ? 'green' : 'base'}>
									{progressValueTranscript}
								</Text>
								{typeof decimalValue === 'string' && (
									<Text size='xSmall' bold font='mono' color={isProgressSuccess ? 'green' : 'base'}>
										{'.'}
										{decimalValue}
									</Text>
								)}
							</FlexBox>
						</ProgressBar.RingWithPointer>
					</ProgressBar>
					{children}
				</GridBox>

				{progressBarTitle && (
					<Text size='small' font='mono' color='mediumContrast'>
						{progressBarTitle}
					</Text>
				)}
			</GridBox>
		);
	},
);
