import {ComponentPropsWithoutRef, Dispatch, forwardRef, useMemo} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {TrackModel} from '@esgi/main/kits/admin';
import {
	SchoolStudentsNeedingSupportMetricData,
	SchoolStudentsNeedingSupportWidgetOptions,
	SchoolStudentsNeedingSupportWidgetUpdatedValue,
	SchoolStudentsNeedingSupportWidgetViewType,
} from '../../types/students-needing-support';
import {ContentAreaModel, GradeLevel, WidgetCard, WidgetMode} from '@esgi/main/features/admins/dashboard';
import {classesViewDataPageSize, studentsViewDataPageSize} from './constants';
import {useViewTableInfo} from './hooks/use-view-table-info';
import {ViewTableInfo, ViewTableType} from './types';
import {Content} from './components/content';
import {Widget} from '../../types/widget';
import {ClassModel, SubjectTab, TeacherModel} from '../../../types';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'id'> &
	Pick<Widget, 'id' | 'name'> & {
		widgetData: SchoolStudentsNeedingSupportMetricData | 'dataCounting';
		widgetOptions: SchoolStudentsNeedingSupportWidgetOptions;
		subjects: SubjectTab[];
		maxPossibleCardWidth: number;
		allClassesList: ClassModel[];
		allTeachersList: TeacherModel[];
		onDeleteWidgetByID: Dispatch<Widget['id']>;
		onUpdateWidget: Dispatch<SchoolStudentsNeedingSupportWidgetUpdatedValue>;
		defaultWidgetMode?: WidgetMode;
		viewModeChanged: Dispatch<{id: Widget['id']; mode: WidgetMode}>;
		gradeLevels: GradeLevel[];
		currentTrack: TrackModel | null;
		allContentAreas: ContentAreaModel[];
		lastUpdatedTime: string;
		rootPageContainer: Element | DocumentFragment | null;
	};

export const SchoolStudentsNeedingSupport = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'school-admin-students-needing-support-widget-view-edit',
			css = {},
			id,
			name,
			widgetData,
			widgetOptions,
			subjects,
			maxPossibleCardWidth,
			allClassesList,
			allTeachersList,
			onDeleteWidgetByID,
			onUpdateWidget,
			defaultWidgetMode,
			viewModeChanged,
			gradeLevels,
			currentTrack,
			allContentAreas,
			lastUpdatedTime,
			rootPageContainer,
			...componentRootProps
		},
		forwardedRef,
	) => {
		const isDataCounting = widgetData === 'dataCounting';

		const {studentsOnePeriodInfo, studentsTwoPeriodsInfo, classesInfo} = useViewTableInfo({
			widgetData: isDataCounting ? null : widgetData,
			allClassesList,
			allTeachersList,
			gradeLevels,
		});

		const {items, tableInfo, dataPageSize} = useMemo(() => {
			if (
				widgetOptions.viewType === SchoolStudentsNeedingSupportWidgetViewType.Students &&
				widgetOptions.periods.length === 1
			) {
				const tableInfo: ViewTableInfo = {
					type: ViewTableType.StudentsOnePeriod,
					disabledSortableKeys: studentsOnePeriodInfo.disabledSortableKeys,
					sortableState: studentsOnePeriodInfo.sortableState,
					setSortableState: studentsOnePeriodInfo.setSortableState,
				};

				return {
					tableInfo,
					items: studentsOnePeriodInfo.items,
					dataPageSize: studentsViewDataPageSize,
				};
			}

			if (
				widgetOptions.viewType === SchoolStudentsNeedingSupportWidgetViewType.Students &&
				widgetOptions.periods.length === 2
			) {
				const tableInfo: ViewTableInfo = {
					type: ViewTableType.StudentsTwoPeriods,
					disabledSortableKeys: studentsTwoPeriodsInfo.disabledSortableKeys,
					sortableState: studentsTwoPeriodsInfo.sortableState,
					setSortableState: studentsTwoPeriodsInfo.setSortableState,
				};

				return {
					tableInfo,
					items: studentsTwoPeriodsInfo.items,
					dataPageSize: studentsViewDataPageSize,
				};
			}

			if (widgetOptions.viewType === SchoolStudentsNeedingSupportWidgetViewType.Classes) {
				const tableInfo: ViewTableInfo = {
					type: ViewTableType.Classes,
					disabledSortableKeys: classesInfo.disabledSortableKeys,
					sortableState: classesInfo.sortableState,
					setSortableState: classesInfo.setSortableState,
				};

				return {
					tableInfo,
					items: classesInfo.items,
					dataPageSize: classesViewDataPageSize,
				};
			}

			throw new Error('View type does not correspond with existing variants');
		}, [classesInfo, studentsOnePeriodInfo, studentsTwoPeriodsInfo, widgetOptions]);

		return (
			<WidgetCard
				dataCy={dataCy}
				css={css}
				ref={forwardedRef}
				id={id}
				name={name}
				maxPossibleCardWidth={maxPossibleCardWidth}
				widgetData={items}
				dataPageSize={dataPageSize}
				defaultWidgetMode={defaultWidgetMode}
				viewModeChanged={viewModeChanged}
				isDataСounting={isDataCounting}
				currentTrack={currentTrack}
				allContentAreas={allContentAreas}
				lastUpdatedTime={lastUpdatedTime}
				{...componentRootProps}
			>
				<Content
					widgetOptions={widgetOptions}
					allClassesList={allClassesList}
					allTeachersList={allTeachersList}
					onDeleteWidgetByID={onDeleteWidgetByID}
					subjects={subjects}
					tableInfo={tableInfo}
					onUpdateWidget={onUpdateWidget}
					gradeLevels={gradeLevels}
					rootPageContainer={rootPageContainer}
				/>
			</WidgetCard>
		);
	},
);
