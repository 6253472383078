import {Checkbox} from './components/checkbox.styled';
import {EntireSectionBlock} from './components/entire-section-block';
import {ExpandableSelectableBlock} from './components/expandable-selectable-block';
import {InputCleanableIndicator} from './components/input-cleanable-indicator';
import {PublishPeriodDrawerable} from './components/publish-period-drawerable';
import {RemoveIconButton} from './components/remove-icon-button';
import {Root} from './components/root';
import {SaveChangesButton} from './components/save-changes-button';
import {SectionBlock} from './components/section-block';
import {SelectDrawerable} from './components/select-drawerable';
import {SubjectName} from './components/subject-name';
import {Title} from './components/title';

export const SettingPanel = {
	Root,
	Title,
	SectionBlock: Object.assign(SectionBlock, {
		EntireBlock: EntireSectionBlock,
	}),
	RemoveButton: RemoveIconButton,
	SubjectName,
	SaveChangesButton,
	Checkbox,
	SelectDrawerable,
	InputCleanableIndicator,
	PublishPeriodDrawerable,
	ExpandableSelectableBlock,
};

export {type SelectableListItemModel} from './components/select-drawerable/types';

export {
	SubjectPublishType,
	type SubjectPublishValue,
	type TrackDate,
} from './components/publish-period-drawerable/types';

export {type ExpandableSelectableBlockTab} from './components/expandable-selectable-block/types';
