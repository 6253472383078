import * as React from 'react';
import type {SVGProps} from 'react';

export function PieChartIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16 7.8499C11.4989 7.8499 7.85003 11.4988 7.85003 15.9999C7.85003 20.501 11.4989 24.1499 16 24.1499C20.5012 24.1499 24.15 20.501 24.15 15.9999C24.15 11.4988 20.5012 7.8499 16 7.8499ZM6.15003 15.9999C6.15003 10.5599 10.56 6.1499 16 6.1499C21.44 6.1499 25.85 10.5599 25.85 15.9999C25.85 21.4399 21.44 25.8499 16 25.8499C10.56 25.8499 6.15003 21.4399 6.15003 15.9999Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.97173 8.21003C11.6975 6.87453 13.8179 6.1499 16 6.1499C16.2255 6.1499 16.4417 6.23946 16.6011 6.39886C16.7605 6.55827 16.85 6.77447 16.85 6.9999V14.4703L23.1936 10.5249C23.385 10.4058 23.6159 10.3677 23.8354 10.4189C24.055 10.47 24.2452 10.6063 24.3643 10.7978C25.5167 12.6508 26.0213 14.834 25.7986 17.0048C25.576 19.1755 24.6389 21.2109 23.1343 22.7914C21.6297 24.372 19.6429 25.4081 17.4857 25.7372C15.3285 26.0663 13.123 25.6698 11.2156 24.6099C9.30816 23.5499 7.80669 21.8866 6.94687 19.881C6.08706 17.8754 5.91759 15.641 6.46507 13.5286C7.01255 11.4163 8.24598 9.54553 9.97173 8.21003ZM15.15 7.89434C13.6488 8.05175 12.2147 8.62387 11.0122 9.55447C9.58424 10.6595 8.56369 12.2074 8.1107 13.9552C7.65771 15.703 7.79793 17.5517 8.50935 19.2112C9.22077 20.8706 10.4631 22.2469 12.0413 23.1239C13.6196 24.0009 15.4444 24.329 17.2293 24.0567C19.0141 23.7843 20.6581 22.927 21.903 21.6193C23.1479 20.3116 23.9233 18.6275 24.1075 16.8313C24.2626 15.3187 23.991 13.7987 23.3319 12.4408L16.4489 16.7217C16.1868 16.8847 15.857 16.8928 15.5872 16.7429C15.3174 16.593 15.15 16.3086 15.15 15.9999V7.89434Z'
				fill='#333333'
			/>
		</svg>
	);
}
