import {User} from '@esgi/main/libs/school-admin-store';
import {SchoolSpecialistContent} from './school-specialist-content';
import {FormGroup, useForm} from '@esgi/ui/form';
import {FormRoot} from '../../../../components/form.styled';
import {Drawer} from '@esgi/main/kits/common';
import {SchoolSpecialistForm} from '../../forms-data/types';
import {GroupNameInput} from '../../../../components/group-name-input';

type Props = {
	schoolSpecialistForm: SchoolSpecialistForm;
	schoolSpecialistsList: User[];
	schoolSpecialistFormInactiveMessage: string | null;
};

export function GroupInformationContent({
	schoolSpecialistForm,
	schoolSpecialistsList,
	schoolSpecialistFormInactiveMessage,
}: Props) {
	const mockForm = useForm(() => new FormGroup({}));

	return (
		<FormRoot controller={mockForm}>
			<Drawer.ContentBlock title='Specialist' withDivider>
				<SchoolSpecialistContent
					form={schoolSpecialistForm}
					schoolSpecialistsList={schoolSpecialistsList}
					formInactiveMessage={schoolSpecialistFormInactiveMessage}
				/>
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Specialist Group Information' withDivider>
				<GroupNameInput groupNameControl={schoolSpecialistForm.controls.groupName} />
			</Drawer.ContentBlock>
		</FormRoot>
	);
}
