import {BoxplotChart} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const BoxplotChartRoot = styled(BoxplotChart, {
	transition: 'opacity .3s',

	variants: {
		withOpacity: {
			true: {
				opacity: '0.3',
			},
		},
	},
});

export const tooltipContentCss: CSS = {
	display: 'grid',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
	borderRadius: 4,
	backgroundColor: '$secondarySurface',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$secondaryMuted',
	color: '$secondary',
	padding: 4,
	width: 96,
	minHeight: 32,
};
