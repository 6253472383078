import {GridBox} from '@esgi/ui/layout';
import {CardBody} from './index.styled';
import {StudentsTable} from './components/students-table';
import {ViewTableInfo, ViewTableType} from '../../types';
import {SchoolsTable} from './components/schools-table';
import {PeriodsDateInfoTip} from './components/periods-date-info-tip';
import {
	DeployedSubject,
	GradeLevel,
	SchoolModel,
	Widget,
	WidgetCard,
	WidgetPeriod,
} from '@esgi/main/features/admins/dashboard';
import {SelectedSubjectsInfo} from '../../../../components/selected-subjects-info';

type Props = {
	schoolIDs: number[];
	allSchoolsList: SchoolModel[];
	contentAreaIDs: number[];
	subjectIDs: number[];
	subjects: DeployedSubject[];
	gradeLevelIDs: number[];
	periods: WidgetPeriod[];
	tableInfo: ViewTableInfo;
	gradeLevels: GradeLevel[];
	rootPageContainer: Element | DocumentFragment | null;
};

export function ViewMode({
	schoolIDs,
	allSchoolsList,
	contentAreaIDs,
	subjectIDs,
	subjects,
	gradeLevelIDs,
	periods,
	tableInfo,
	gradeLevels,
	rootPageContainer,
}: Props) {
	return (
		<CardBody>
			<GridBox align='center' justify='between' gap='6' flow='column'>
				<GridBox align='center' gap='2' flow='column'>
					<Widget.SelectedSchoolsInfo allSchoolsList={allSchoolsList} schoolIDs={schoolIDs} />
					<Widget.SelectedEntityInfoDivider />

					<SelectedSubjectsInfo subjectIDs={subjectIDs} subjects={subjects} />
					<Widget.SelectedEntityInfoDivider />

					<WidgetCard.ViewMode.SelectedContentAreasInfo contentAreaIDs={contentAreaIDs} />
					<Widget.SelectedEntityInfoDivider />

					<Widget.SelectedGradeLevelsInfo gradeLevelIDs={gradeLevelIDs} gradeLevels={gradeLevels} />
				</GridBox>

				<GridBox align='center' gap='2' flow='column'>
					<Widget.PeriodDatesInfo periodsData={periods} />
					{periods.length === 2 && <PeriodsDateInfoTip period={periods[0]!} />}
				</GridBox>
			</GridBox>

			{(tableInfo.type === ViewTableType.StudentsOnePeriod || tableInfo.type === ViewTableType.StudentsTwoPeriods) && (
				<StudentsTable tableInfo={tableInfo} periods={periods} rootPageContainer={rootPageContainer} />
			)}

			{tableInfo.type === ViewTableType.Schools && (
				<SchoolsTable
					sortableState={tableInfo.sortableState}
					disabledSortableKeys={tableInfo.disabledSortableKeys}
					setSortableState={tableInfo.setSortableState}
				/>
			)}
		</CardBody>
	);
}
