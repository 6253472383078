import {Text} from '@esgi/ui/typography';
import {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {VariantProps} from '@stitches/react';
import {isNull} from 'underscore';
import {Container, IndicatorBox} from './index.styled';

type Props = Omit<ComponentPropsWithoutRef<typeof Container>, 'children'> & {
	value: number | null;
	percentageType?: boolean;
	deltaTranscript?: string;
	decimals?: number;
};

type ValueItem = {
	formattedValue: string;
	valueColor: NonNullable<VariantProps<typeof Text>['color']>;
};

export const Delta = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'test-card-delta', css = {}, value, percentageType = false, deltaTranscript, decimals = 1, ...props},
		forwardedRef,
	) => {
		const {formattedValue, valueColor} = useMemo<ValueItem>(() => {
			if (isNull(value)) {
				return {
					formattedValue: '-',
					valueColor: 'lowContrast',
				};
			}

			if (value > 0) {
				const formattedValue = percentageType ? Math.round(value) : value.toFixed(decimals);

				return {
					formattedValue: `+${formattedValue}`,
					valueColor: 'positive',
				};
			}

			if (value < 0) {
				const absValue = Math.abs(value);
				const formattedValue = percentageType ? Math.round(absValue) : absValue.toFixed(decimals);

				return {
					formattedValue: `-${formattedValue}`,
					valueColor: 'negativeVivid',
				};
			}

			return {
				formattedValue: '0',
				valueColor: 'muted',
			};
		}, [value, percentageType, decimals]);

		return (
			<Container display='inlineGrid' ref={forwardedRef} {...props}>
				<IndicatorBox css={{backgroundColor: `$${valueColor}`}}>
					<Text size='small' font='mono' bold color='vivid'>
						{formattedValue}
						{percentageType && !isNull(value) && '%'}
					</Text>
				</IndicatorBox>
				{deltaTranscript && (
					<Text size='xSmall' font='mono' color={valueColor}>
						{deltaTranscript}
					</Text>
				)}
			</Container>
		);
	},
);
