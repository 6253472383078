import {
	Drawer,
	SubjectName,
	TestColorLineIndicator,
	TestContentArea,
	useDrawerClose,
	useDrawerRef,
} from '@esgi/main/kits/common';
import {Button, Close, Plus} from '@esgi/ui';
import {RefObject, useCallback, useMemo} from 'react';
import {TestModel} from '../../service/types';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {GridBox, SelectableList} from '@esgi/ui/layout';
import {
	Wrapper,
	SubTitle,
	TestListItem,
	Section,
	SelectableListItem,
} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {SubjectTab} from '@esgi/main/libs/store';
import {SnackbarManager} from '@esgi/ui/snackbar';
import {TestExplorerService} from '../../service/service';
import {SubjectCreatedEvent} from '@esgi/main/features/teacher/personal-subject-dialog/events';
import {useEventEffect} from '@esgillc/events';
import {AddButton} from '@esgi/main/features/standards-drawer';
import {UserType, useUser} from '@esgi/core/authentication';

type Props = {
	service: TestExplorerService;
	subjects: SubjectTab[];
	tests: TestModel[];
	selectedSubject: SubjectTab;
	onClose: VoidFunction;
	onSubjectSelect: (subject: SubjectTab) => void;
	onTestSelect: (testID: TestModel['testID']) => void;
	loaded: boolean;
	snackbarRef: RefObject<SnackbarManager>;
	onNewSubjectCreate: (selectedTests: TestModel[]) => void;
	isNewSubjectCreateDisabled: boolean;
	onSubjectAddedToTests: () => void
};

export function AddToSubjectDrawer({
	service,
	tests,
	selectedSubject,
	onClose,
	onTestSelect,
	onSubjectSelect,
	snackbarRef,
	subjects,
	onNewSubjectCreate,
	isNewSubjectCreateDisabled,
	onSubjectAddedToTests,
}: Props) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, onClose);
	const currentUser = useUser();
	
	const descriptionMessage = useMemo(() => {
		return currentUser?.userType === UserType.T 
			? subjects?.length 
				? '' 
				: 'You currently have 0 subject tabs. You can create a new subject tab by selecting the Create New Subject Tab button below. Once your new tab appears in the list above, you may add tests to it.' 
			: subjects?.length 
				? 'To create a subject tab, go to the ‘Subject Tabs’ page. When the tab appears in the list above, you may add the tests to it.'
				: 'You currently have 0 subject tabs. To create a subject tab, go to the ‘Subject Tabs’ page. When the tab appears in the list above, you may add the tests to it.';
	}, [currentUser, subjects]);

	const onSubjectAddToTests = useCallback(() => {
		if (!selectedSubject) {
			return;
		}
		service.onAddToSubject().subscribe(() => {
			snackbarRef.current?.showSnackbar(
				`You have added selected tests to ${selectedSubject.name} subject`,
			);
			close();
			onSubjectAddedToTests();
		});
	}, [close, onSubjectAddedToTests, selectedSubject, service, snackbarRef]);

	const onTestRemoveClick = useCallback(
		(testID: TestModel['testID']) => {
			onTestSelect(testID);
			if (tests.length === 1) {
				onClose();
			}
		},
		[onClose, onTestSelect, tests.length],
	);

	const onNewSubjectCreateWrapper = useCallback(() => {
		onNewSubjectCreate(tests);
	}, [onNewSubjectCreate, tests]);

	useEventEffect(SubjectCreatedEvent, ({newSubject}) => {
		onSubjectSelect(newSubject);
	});

	return (
		<Drawer
			data-cy='add-to-subject-tab-drawer'
			width={400}
			drawerRef={drawerRef}
		>
			<Drawer.Header
				data-cy='add-to-subject-tab-header'
				Icon={Plus}
				sectionName='Add to Subject Tab'
				actionButtonDisabled={!selectedSubject}
				withActionButton
				onActionButtonClick={onSubjectAddToTests}
				actionButtonText='Add'
				closeDrawer={close}
				childrenAlignment='right'
			/>
			<Drawer.Body>
				<Wrapper>
					<GridBox>
						<Section>
							<SubTitle
								data-cy='subtitle'
								size='small'
								font='mono'
								color='mediumContrast'
							>
								Selected Tests
							</SubTitle>
							<OverlayScrollbarsComponent
								style={{height: 'calc(100% + 0px)'}}
								defer
								options={{scrollbars: {autoHide: 'leave'}}}
							>
								<GridBox gap={2}>
									{tests.map(({testID, contentArea, name, color}) => (
										<TestListItem data-cy='selected-tests-item' key={testID}>
											<GridBox
												flow='column'
												justify='start'
												align='center'
												gap={2}
											>
												<TestColorLineIndicator
													color={color || null}
													contentArea={contentArea as TestContentArea}
												/>
												<Text data-cy='selected-test-name' size='large'>
													{name}
												</Text>
											</GridBox>
											<Button.Icon onClick={() => onTestRemoveClick(testID)}>
												<Close data-cy='remove-test' height={24} width={24} />
											</Button.Icon>
										</TestListItem>
									))}
								</GridBox>
							</OverlayScrollbarsComponent>
						</Section>
						<Section>
							<SubTitle
								data-cy='subtitle'
								size='small'
								font='mono'
								color='mediumContrast'
							>
								Subject Tabs
							</SubTitle>
							<OverlayScrollbarsComponent
								style={{height: 'calc(100% + 0px)'}}
								defer
								options={{scrollbars: {autoHide: 'leave'}}}
							>
								<GridBox gap={2}>
									<SelectableList data-cy='subject-tab-list'>
										<SelectableList.GroupRoot
											type='single'
											value={String(selectedSubject?.id)}
										>
											<SelectableList.Group>
												{subjects?.map((subject) => (
													<SelectableListItem
														value={String(subject.id)}
														key={subject.id}
														onClick={() => onSubjectSelect(subject)}
													>
														<SubjectName
															subjectLevel={subject.level}
															noWrap
															size='medium'
															bold
														>
															{subject.name}
														</SubjectName>
													</SelectableListItem>
												))}
											</SelectableList.Group>
										</SelectableList.GroupRoot>
									</SelectableList>
								</GridBox>
							</OverlayScrollbarsComponent>
						</Section>
					</GridBox>
					<Section>
						<Text
							data-cy='create-new-subject-tab-description'
							size='medium'
							color='highContrast'
						>
							{descriptionMessage}
						</Text>
						{!isNewSubjectCreateDisabled && 
							<AddButton // temporarily hidden, see comment in the task ESGI-30776?focusedCommentId=240359
								label='Create New Subject Tab'
								borderType='secondary'
								onClick={onNewSubjectCreateWrapper}
								disabled={isNewSubjectCreateDisabled}
							/>
						}
					</Section>
				</Wrapper>
			</Drawer.Body>
		</Drawer>
	);
}
