import {OneLinedText, TestTypeLabel, TestType, TestColorLineIndicator, TestContentArea} from '@esgi/main/kits/common';
import {PropsWithChildren} from 'react';
import {ChildrenBox, Container, TestInfoBox} from './index.styled';

type Props = PropsWithChildren<{
	name: string;
	color: string;
	contentArea: TestContentArea;
	type: TestType;
}>;

export function TestName({name, color, contentArea, type, children}: Props) {
	return (
		<Container>
			<TestInfoBox>
				<TestColorLineIndicator color={color} contentArea={contentArea} />
				<OneLinedText size='xLarge' color='base' data-cy='test-name'>
					{name}
				</OneLinedText>
				<TestTypeLabel testType={type} />
			</TestInfoBox>

			{children && <ChildrenBox>{children}</ChildrenBox>}
		</Container>
	);
}
