import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gap: 20,
});

export const EmptyContentContainer = styled(GridBox, {
	width: '100%',
	height: '100%',
});

export const StudentBox = styled(GridBox, {
	width: '100%',
	gap: '$3',
});

export const StudentNameBox = styled(FlexBox, {
	gap: 2,
	alignItems: 'center',
	color: '$mediumContrast',

	'& svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& fill': {
			fill: 'currentColor',
		},
	},
});
