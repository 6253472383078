import {GridBox} from '@esgi/ui/layout';
import {StudentManager} from '@esgi/main/old';

export default function () {
	return (
		<GridBox
			align='center'
			justify='center'
			css={{
				width: '100%',
				height: '100%',
			}}
		>
			<StudentManager excludeLayout excludeSYSwitch/>
		</GridBox>
	);
}
