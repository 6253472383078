import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {ToggleGroup} from '@esgi/ui/controls';

export const StyledContainer = styled(Box, {
	padding: 8,
	backgroundColor: '$surface',
});

export const StyledToggleGroupContent = styled(ToggleGroup.Content, {
	backgroundColor: '$background',
	gridTemplateColumns: '1fr 1fr auto',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	'& svg': {
		width: 20,
		height: 20,
	},
});
