import {Button} from './index.styled';
import {ComponentPropsWithoutRef, forwardRef, useCallback, useMemo, useState} from 'react';
import {Delete} from '@esgi/ui/icons';
import {isUndefined, Tooltip, useFlag} from '@esgi/ui';
import {isNull} from 'underscore';
import {Text} from '@esgi/ui/typography';
import {DeleteSubjectAlert} from '../delete-subject-alert';

type TooltipedButton = {
	tooltipMessage: string;
	whenShowTooltip: 'disabled';
};

type NonTooltipedButton = {
	tooltipMessage?: undefined;
};

type Props = Omit<ComponentPropsWithoutRef<typeof Button>, 'children' | 'onClick'> &
	(TooltipedButton | NonTooltipedButton) & {
		onDeleted: VoidFunction;
	};

export const RemoveIconButton = forwardRef<HTMLButtonElement, Props>(
	(
		{dataCy = 'remove-icon-button', css = {}, withBackgroundHover = true, disabled, onDeleted, ...props},
		forwardedRef,
	) => {
		const [isTooltipOpen, setIsTooltipOpen] = useState(false);

		const [isDeleteSubjectAlertOpen, openDeleteSubjectAlert, closeDeleteSubjectAlert] = useFlag();

		const {restProps, tooltipMessage, whenShowTooltip} = useMemo(() => {
			if (!isUndefined(props.tooltipMessage) && 'whenShowTooltip' in props) {
				const {tooltipMessage, whenShowTooltip, ...restProps} = props;

				return {
					restProps,
					tooltipMessage,
					whenShowTooltip,
				};
			}

			const {tooltipMessage, ...restProps} = props;

			return {
				restProps,
				tooltipMessage: null,
				whenShowTooltip: null,
			};
		}, [props]);

		const onTooltipOpenChanged = useCallback(
			(value: boolean) => {
				if (isNull(tooltipMessage) || isNull(whenShowTooltip)) {
					setIsTooltipOpen(false);
					return;
				}

				if (disabled && whenShowTooltip === 'disabled') {
					setIsTooltipOpen(value);
					return;
				}

				setIsTooltipOpen(false);
			},
			[disabled, tooltipMessage, whenShowTooltip],
		);

		return (
			<>
				<Tooltip open={isTooltipOpen} onOpenChange={onTooltipOpenChanged}>
					<Tooltip.Trigger>
						<Button
							dataCy={dataCy}
							css={css}
							withBackgroundHover={withBackgroundHover}
							onClick={openDeleteSubjectAlert}
							disabled={disabled}
							ref={forwardedRef}
							{...restProps}
						>
							<Delete />
						</Button>
					</Tooltip.Trigger>
					<Tooltip.Content>
						<Text size='large' color='tertiary'>
							{tooltipMessage}
						</Text>
					</Tooltip.Content>
				</Tooltip>

				{isDeleteSubjectAlertOpen && <DeleteSubjectAlert onClose={closeDeleteSubjectAlert} onDeleted={onDeleted} />}
			</>
		);
	},
);
