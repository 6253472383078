import {createContext, Dispatch, useContext} from 'react';
import {isNull} from 'underscore';

export type AppCollapsiblePanelContextValue = {
	queue: string[];
	enqueue: Dispatch<string>;
	dequeue: Dispatch<string>;
};

export const AppCollapsiblePanelContext = createContext<AppCollapsiblePanelContextValue | null>(null);

export function useAppCollapsiblePanelContext() {
	const context = useContext(AppCollapsiblePanelContext);

	if (isNull(context)) {
		throw new Error('useAppCollapsiblePanelContext is null');
	}

	return context;
}
