import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: '$4',
	paddingTop: 18,
	paddingBottom: 18,
	paddingLeft: 20,
	paddingRight: 20,
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	borderBottomColor: '$border',
	backgroundColor: '$background',
});
