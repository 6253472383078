import {Button, Download, Skeletonable} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {useCallback} from 'react';
import {Alert} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';

type EditableModeProps = {
	isEditMode: true;
	isSaveDisabled: boolean;
	onSave: VoidFunction;
};

type NonEditableModeProps = {
	isEditMode: false;
	onDownload: VoidFunction;
};

type Props = Skeletonable & (EditableModeProps | NonEditableModeProps);

export function AlertFooter({skeleton, ...props}: Props) {
	const handleDownloadClick = useCallback(() => {
		if (props.isEditMode === false) {
			props.onDownload();
		}
	}, [props]);

	const handleSaveClick = useCallback(() => {
		if (props.isEditMode === true) {
			props.onSave();
		}
	}, [props]);

	return (
		<Alert.Footer display='grid' flow='column' align='center' justify='between' gap='4'>
			<Button
				color='tertiary'
				disabled={props.isEditMode || skeleton}
				skeleton={skeleton}
				onClick={handleDownloadClick}
			>
				<Download />
				<Text size='medium' bold data-cy='download'>
					Download
				</Text>
			</Button>
			<GridBox flow='column' align='center' justify='start' gap='4'>
				<Button
					color='secondary'
					disabled={!props.isEditMode || (props.isEditMode === true && props.isSaveDisabled) || skeleton}
					skeleton={skeleton}
					onClick={handleSaveClick}
				>
					<Text size='medium' bold data-cy='save'>
						Save
					</Text>
				</Button>
			</GridBox>
		</Alert.Footer>
	);
}
