import {Fragment} from 'react';
import {Dot, SchoolsBox} from './index.styled';
import {OneLinedText} from '@esgi/main/kits/common';
import {PerformanceWidgetSchool} from '../../../../../../types/widget';
import {Color, WidgetCard} from '@esgi/main/features/admins/dashboard';

export type CompareTwoSchoolsInfoItem = PerformanceWidgetSchool & {
	label: string;
};

type Props = {
	data: CompareTwoSchoolsInfoItem[];
};

export function CompareTwoSchoolsInfo({data}: Props) {
	return (
		<SchoolsBox>
			{data.map(({id, color, label}, index, iteratedData) => (
				<Fragment key={id}>
					<OneLinedText
						size='small'
						color={color === Color.None ? 'highContrast' : WidgetCard.chartColor[color].activeQuartileBarColor}
					>
						{label}
					</OneLinedText>

					{iteratedData[index + 1] && <Dot />}
				</Fragment>
			))}
		</SchoolsBox>
	);
}
