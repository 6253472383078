import {Avatar} from '@esgi/ui/avatar';
import {AlertHeaderStyled, HeaderDivider, IconButton, avatarRootCss} from './index.styled';
import {Button, ContextMenu, Skeletonable} from '@esgi/ui';
import {Delete, Edit, More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {useCallback} from 'react';
import {HeaderTitle} from './components/header-title';
import {HeaderTitleSkeleton} from './components/header-title-skeleton';
import {Student} from '../../../../types/common';
import {getInitials} from '@esgi/main/kits/common';
import {isNull} from 'underscore';

type NonEditableProps = {
	isEditMode: false;
	onDeleteSession: VoidFunction;
};

type EditableProps = {
	isEditMode: true;
};

type Props = Skeletonable & {
	canEdit: boolean;
	student: Student;
	onEditModeClick: VoidFunction;
	onCloseAlert: VoidFunction;
} & (EditableProps | NonEditableProps);

export function AlertHeader({student, onEditModeClick, skeleton, onCloseAlert, ...props}: Props) {
	const handleDeleteSession = useCallback(() => {
		if (props.isEditMode === false) {
			props.onDeleteSession();
		}
	}, [props]);

	return (
		<AlertHeaderStyled withBacklight={false} onCloseIconClick={onCloseAlert}>
			<GridBox flow='column' align='center' justify='between' gap='4'>
				<GridBox flow='column' gap='3' align='center' justify='start'>
					<Avatar.Root size='xs' css={avatarRootCss} skeleton={skeleton}>
						<Avatar.Image src={isNull(student.photoUrl) ? undefined : student.photoUrl} />
						<Avatar.Fallback>
							<Text size='small' color='neutral16' data-cy='initials'>
								{getInitials({
									firstName: student.firstName,
									lastName: student.lastName,
								})}
							</Text>
						</Avatar.Fallback>
					</Avatar.Root>

					<GridBox flow='column' gap='2' align='center' justify='start'>
						{skeleton ? (
							<HeaderTitleSkeleton />
						) : (
							<HeaderTitle
								isEditMode={props.isEditMode}
								firstName={student.firstName}
								lastName={student.lastName}
								data-cy='fullname'
							/>
						)}
					</GridBox>
				</GridBox>

				{props.canEdit && (
					<GridBox flow='column' gap='4' align='center' justify='start'>
						<ContextMenu.Root>
							<ContextMenu.Trigger disabled={skeleton}>
								<Button.Icon skeleton={skeleton} data-cy='more-button'>
									<More />
								</Button.Icon>
							</ContextMenu.Trigger>
							<ContextMenu.Content>
								<ContextMenu.Group>
									<ContextMenu.Item disabled={props.isEditMode} onClick={handleDeleteSession}>
										<Delete />
										<Text size='medium' data-cy='delete-session'>
											Delete a session
										</Text>
									</ContextMenu.Item>
								</ContextMenu.Group>
							</ContextMenu.Content>
						</ContextMenu.Root>
						<IconButton
							onClick={onEditModeClick}
							isEditMode={props.isEditMode}
							disabled={skeleton}
							skeleton={skeleton}
							data-cy='edit-button'
						>
							<Edit />
						</IconButton>
						<HeaderDivider />
					</GridBox>
				)}
			</GridBox>
		</AlertHeaderStyled>
	);
}
