import {Dispatch, PropsWithChildren, useCallback, useMemo, useState} from 'react';
import {TabsContext, TabsContextValue} from '../context';
import {TabInfo, UpdateTab} from '../types';
import {Skeletonable} from '@esgi/ui';

type Props<Tab extends string> = Skeletonable &
	PropsWithChildren<{
		initialTab?: Tab | undefined;
		onActiveTabChanged?: Dispatch<Tab | null> | undefined;
	}>;

export function Root<Tab extends string>({initialTab, onActiveTabChanged, children, skeleton}: Props<Tab>) {
	const [activeTab, setActiveTab] = useState(initialTab ?? null);
	const [availableTabs, setAvailableTabs] = useState<TabInfo<Tab>[]>([]);

	const onSetActiveTab = useCallback(
		(tab: Tab | null) => {
			setActiveTab(tab);
			onActiveTabChanged?.(tab);
		},
		[onActiveTabChanged],
	);

	const addTab = useCallback((newTab: TabInfo<Tab>) => {
		setAvailableTabs((currentTabs) => [...currentTabs, newTab]);
	}, []);

	const removeTab = useCallback((tabID: Tab) => {
		setAvailableTabs((currentTabs) => currentTabs.filter((tab) => tab.tabID !== tabID));
	}, []);

	const updateTab = useCallback<UpdateTab<Tab>>(({tabID, data}) => {
		setAvailableTabs((currentTabs) =>
			currentTabs.map((currentTab) => (currentTab.tabID === tabID ? {...currentTab, ...data} : currentTab)),
		);
	}, []);

	const context = useMemo<TabsContextValue<Tab>>(
		() => ({
			activeTab,
			setActiveTab: onSetActiveTab,
			addTab,
			removeTab,
			updateTab,
			availableTabs,
			skeleton: Boolean(skeleton),
		}),
		[activeTab, addTab, availableTabs, onSetActiveTab, removeTab, skeleton, updateTab],
	);

	return <TabsContext.Provider value={context}>{children}</TabsContext.Provider>;
}
