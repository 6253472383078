import {Drawer, UnsavedChangesConfirmation} from '@esgi/main/kits/common';
import {DrawerSkeleton} from '@esgi/main/features/admins/data';
import {useDrawerState} from '../use-drawer-state';
import {HeaderTitle} from './index.styled';
import {Close, Plus} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {Divider, Header, HeaderButtons} from '../../components/header.styled';
import {Body} from '../../components/body';
import {TabID} from '../types';
import {GroupInformationContent} from './components/group-information-content';
import {useSchoolSpecialistForm} from './forms-data/use-school-specialist-form';
import {StudentsTabContent} from '../../components/students-tab-content';
import {isNull} from 'underscore';
import {useTabs} from './hooks/use-tabs';
import {useSave} from './hooks/use-save';

type Props = {
	onClose: VoidFunction;
};

export function Add({onClose}: Props) {
	const {
		drawerRef,
		onDrawerClose,
		closeDrawer,
		closeUnsavedChangesConfirmation,
		showUnsavedChangesConfirmation,
		setIsFormTouched,
		setIsFormValid,
		isFormTouched,
		isFormValid,
		withoutResetSingleStudentAlert,
		setWithoutResetSingleStudentAlert,
		specialistGroups,
		schoolSpecialists,
		studentsList,
		skeleton,
	} = useDrawerState({onClose});

	const {
		form: schoolSpecialistForm,
		checkFormOnTouchValid: checkSchoolSpecialistForm,
		formInactiveMessage: schoolSpecialistFormInactiveMessage,
	} = useSchoolSpecialistForm({
		schoolSpecialists,
		setIsFormTouched,
		setIsFormValid,
		specialistGroups,
	});

	const {activeTab, onTabIDChange, studentsTabData} = useTabs({
		checkSchoolSpecialistForm,
		schoolSpecialistForm,
		studentsList,
	});

	const {saveData, isDataSaving} = useSave({
		closeDrawer,
		schoolSpecialistForm,
	});

	return (
		<>
			<Drawer drawerRef={drawerRef} onClickOutside={onDrawerClose}>
				{skeleton ? (
					<DrawerSkeleton onClose={closeDrawer} />
				) : (
					<>
						<Header>
							<HeaderTitle>
								<Plus />
								<Text size='small' color='mediumContrast'>
									Add New Specialist Group
								</Text>
							</HeaderTitle>
							<HeaderButtons>
								<Button color='primary' onClick={saveData} disabled={!isFormValid || !isFormTouched || isDataSaving}>
									<Text size='large'>Add</Text>
								</Button>

								<Button.Icon withBackgroundHover onClick={onDrawerClose}>
									<Close />
								</Button.Icon>
							</HeaderButtons>

							<Divider />
						</Header>

						<Body
							activeTabID={activeTab}
							setActiveTabID={onTabIDChange}
							schoolSpecialistFormInactiveMessage={schoolSpecialistFormInactiveMessage}
						>
							{activeTab === TabID.GroupInformation && (
								<GroupInformationContent
									schoolSpecialistForm={schoolSpecialistForm}
									schoolSpecialistsList={schoolSpecialists}
									schoolSpecialistFormInactiveMessage={schoolSpecialistFormInactiveMessage}
								/>
							)}

							{activeTab === TabID.Students && !isNull(studentsTabData) && (
								<StudentsTabContent
									setWithoutResetSingleStudentAlert={setWithoutResetSingleStudentAlert}
									withoutResetSingleStudentAlert={withoutResetSingleStudentAlert}
									allStudents={studentsTabData.allStudents}
									studentsIDsControl={studentsTabData.studentsIDsControl}
									errorMessage={studentsTabData.errorMessage}
									currentSpecialistGroup={null}
									addStudentsAlertEntity={studentsTabData.addStudentsAlertEntity}
								/>
							)}
						</Body>
					</>
				)}
			</Drawer>

			{showUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onClose={closeUnsavedChangesConfirmation} onCloseAnyway={closeDrawer} />
			)}
		</>
	);
}
