import {useCallback, useState} from 'react';
import {TabID} from '../../types';
import {StudentsTabContentProps} from '../../../components/students-tab-content';
import {isUndefined} from '@esgi/ui';
import {StudentsList} from '@esgi/ui/icons';
import {Student} from '@esgi/main/libs/school-admin-store';
import {SchoolSpecialistForm} from '../forms-data/types';

type Parameters = {
	studentsList: Student[];
	schoolSpecialistForm: SchoolSpecialistForm;
	checkSchoolSpecialistForm: VoidFunction;
};

export function useTabs({
	studentsList,
	schoolSpecialistForm,
	checkSchoolSpecialistForm,
}: Parameters) {
	const [activeTab, setActiveTab] = useState(TabID.GroupInformation);
	const [studentsTabData, setStudentsTabData] = useState<Pick<
		StudentsTabContentProps,
		'allStudents' | 'studentsIDsControl' | 'errorMessage' | 'addStudentsAlertEntity'
	> | null>(null);

	const onTabIDChange = useCallback(
		(value: TabID) => {
			if (value === TabID.Students) {
				const schoolSpecialistID = schoolSpecialistForm.value.schoolSpecialistID[0];

				setStudentsTabData({
					allStudents: studentsList,
					studentsIDsControl: schoolSpecialistForm.controls.studentsIDs,
					addStudentsAlertEntity: {
						name: 'New School Specialist Group',
						Icon: StudentsList,
					},
					errorMessage:
						isUndefined(schoolSpecialistID)
							? 'You have to assign the school specialist to add students'
							: null,
				});

				setActiveTab(TabID.Students);
				return;
			}

			setStudentsTabData(null);
			setActiveTab(value);
		},
		[schoolSpecialistForm, studentsList],
	);

	return {
		activeTab,
		onTabIDChange,
		studentsTabData,
	};
}
