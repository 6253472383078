import {LoadingCircle} from '../../../../../../kit';

export function Loader() {
	return (
		<LoadingCircle>
			<LoadingCircle.Ring />
			<LoadingCircle.Arc />
		</LoadingCircle>
	);
}
