import {SortOption} from '@esgi/main/features/admins/dashboard';
import {WidgetSortBy} from './types';

export const singleChartSortOption: SortOption<WidgetSortBy>[] = [
	{
		value: WidgetSortBy.Alphabetical,
		label: 'Alphabetical Order',
	},
	{
		value: WidgetSortBy.MedianPerformance,
		label: 'Median Performance',
	},
	{
		value: WidgetSortBy.ConsistencyPerformance,
		label: 'Consistency in Performance',
	},
	{
		value: WidgetSortBy.ScoreSpread,
		label: 'Score Spread (Range)',
	},
];

export const multipleChartSortOption: SortOption<WidgetSortBy>[] = [
	{
		value: WidgetSortBy.Alphabetical,
		label: 'Alphabetical Order',
	},
	{
		value: WidgetSortBy.Delta,
		label: 'Delta',
	},
];
