import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const IconBox = styled(Box, {
	width: 16,
	height: 16,

	'& > svg': {
		width: '100%',
		height: '100%',

		'& path': {
			fill: '$base',
		},
	},
});

export const TooltipContentBox = styled(GridBox, {
	gap: '1',
	textAlign: 'center',

	'& [data-widget-card-period-date]': {
		display: 'inline-grid',
	},
});
