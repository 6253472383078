import {useCallback, useMemo} from 'react';
import {SubjectLevel} from '@esgi/main/kits/common';
import {SubjectModel, Entities} from '@esgi/main/kits/subject-selection-panel';
import {Subject} from '../types/section';
import {allGradeLevel} from '../constants';

type Parameters = {
	allSubjects: Subject[];
	selectedGradeLevelID: number;
};

export function useSubjects({allSubjects, selectedGradeLevelID}: Parameters) {
	const {firstSubjectID, subjectsEntities, hasSubjects} = useMemo(() => {
		const entities: Entities = {
			districtSubjects: [],
			schoolSubjects: [],
			teacherSubjects: [],
		};

		allSubjects.forEach(({level, id, name, type, gradeLevels}) => {
			if (selectedGradeLevelID !== allGradeLevel.id && !gradeLevels.includes(selectedGradeLevelID)) {
				return;
			}

			const entitySubject: SubjectModel = {
				id,
				name,
				hidden: false,
				type,
				level,
			};

			if (entitySubject.level === SubjectLevel.District) {
				entities.districtSubjects.push({
					...entitySubject,
					level: SubjectLevel.District,
				});

				return;
			}

			if (entitySubject.level === SubjectLevel.School) {
				entities.schoolSubjects.push({...entitySubject, level: SubjectLevel.School});

				return;
			}

			if (entitySubject.level === SubjectLevel.Teacher) {
				entities.teacherSubjects.push({...entitySubject, level: SubjectLevel.Teacher});
			}
		});

		const firstSubjectID: SubjectModel['id'] | null =
			entities.districtSubjects[0]?.id ?? entities.schoolSubjects[0]?.id ?? entities.teacherSubjects[0]?.id ?? null;

		return {
			firstSubjectID,
			subjectsEntities: entities,
			hasSubjects: Object.values(entities).some((entity) => entity.length),
		};
	}, [allSubjects, selectedGradeLevelID]);

	const getSubjectByID = useCallback(
		(id: SubjectModel['id']) =>
			[
				...subjectsEntities.districtSubjects,
				...subjectsEntities.schoolSubjects,
				...subjectsEntities.teacherSubjects,
			].find((subject) => subject.id === id) ?? null,
		[subjectsEntities],
	);

	return {
		subjectsEntities,
		firstSubjectID,
		hasSubjects,
		getSubjectByID,
	};
}
