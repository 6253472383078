import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container, FolderNameBox} from './index.styled';
import {Close, Folder as FolderIcon} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';

type Props = Omit<ComponentPropsWithoutRef<typeof Container>, 'children'> & {
	folderName: string;
	onClose: VoidFunction;
};

export const Folder = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'data-page-sub-header-folder', css = {}, folderName, onClose, ...props}, forwardedRef) => (
		<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
			<FolderNameBox>
				<FolderIcon />
				<Text color='primaryVivid' size='small'>
					{folderName}
				</Text>
			</FolderNameBox>

			<Button.Icon onClick={onClose}>
				<Close />
			</Button.Icon>
		</Container>
	),
);
