import {
	ExpandableSelectableBlockTab,
	ItemNumerable,
	PublishToSpecialistsNumerable,
} from '@esgi/main/features/admins/subject-management';
import {PublishForSpecialistsTabID} from '../../../../../types';
import {InitSubjectData} from '../../../types';
import {mapToEnum} from 'shared/utils';

type Parameters = {
	schoolSpecialists: ItemNumerable[];
	initSubjectData: InitSubjectData;
};

export function getPublishForSpecialistsInit({
	schoolSpecialists,
	initSubjectData,
}: Parameters): ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[] {
	const mappedvalue = mapToEnum(initSubjectData.publishToSpecialists, PublishToSpecialistsNumerable);

	const isBySpecialist = mappedvalue === PublishToSpecialistsNumerable.IndividualISS;

	return [
		{
			value: PublishForSpecialistsTabID.All,
			label: 'All',
			isActive: mappedvalue === PublishToSpecialistsNumerable.All || mappedvalue === PublishToSpecialistsNumerable.None,
			allItems: [],
			selectedItems: [],
		},
		{
			value: PublishForSpecialistsTabID.BySpecialist,
			label: 'By Specialist',
			isActive: isBySpecialist,
			allItems: schoolSpecialists,
			selectedItems: isBySpecialist ? initSubjectData.selectedSchoolSpecialists : schoolSpecialists.map(({id}) => id),
		},
	];
}
