import {createContext, Dispatch, useContext} from 'react';
import {isNull} from 'underscore';
import {TestPanelTestModel} from './types/tests';
import {TestPanelMode} from './types/section';

export type PanelContextValue = {
	tests: TestPanelTestModel[];
	panelMode: TestPanelMode;
	setPanelMode: Dispatch<TestPanelMode>;
	skeleton: boolean;
	withRearrangeMode: boolean;
	setWithRearrangeMode: Dispatch<boolean>;
};

export const PanelContext = createContext<PanelContextValue | null>(null);

export function usePanelContext() {
	const context = useContext(PanelContext);

	if (isNull(context)) {
		throw new Error('usePanelPanelContext is null');
	}

	return context;
}
