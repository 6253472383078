import {useCallback, useEffect, useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/controls';
import {Drawer} from '@esgi/main/kits/common';
import {useService} from '@esgi/core/service';
import {BoxArrowUpRight, Upload} from '@esgi/ui';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import ModalExport from 'shared/modules/export/modal/modal-export';
import BackgroundDownloadManager from 'shared/background-download/background-download-manager';
import {ContentWrapper, PanelContent, StyledButton} from '../../../index.styled';
import {DrawerPanelHeader} from '../../../../../index.styled';
import {SettingsService} from '../../../../../service';
import {ExportDataService} from './service';

interface Props {
	service: SettingsService;
	onCloseDrawer: VoidFunction;
}

export function ExportData({service, onCloseDrawer}: Props) {
	const exportDataService = useService(ExportDataService);

	const currentSchoolYear = useBehaviorSubject(exportDataService.currentSchoolYear$);
	const schoolYears = useBehaviorSubject(exportDataService.schoolYears$);

	const [showExportModal, setShowExportModal] = useState(false);

	const handleExportRosterClick = useCallback(() => {
		service.exportRosterClickHandler(currentSchoolYear);
	}, [service, currentSchoolYear]);

	const handleExportTestDataClick = useCallback(() => {
		setShowExportModal(true);
	}, []);

	useEffect(() => {
		exportDataService.init();
	}, [exportDataService]);

	return (
		<>
			<DrawerPanelHeader onClose={onCloseDrawer}>
				<Text data-cy='export-data-title' size='large' color='neutral24' bold>
					Export Data
				</Text>
			</DrawerPanelHeader>
			<PanelContent>
				<ContentWrapper dataCy='export-data-block'>
					<Drawer.ContentBlock title='Export' withDivider>
						<StyledButton color='secondary' onClick={handleExportTestDataClick}>
							<BoxArrowUpRight />
							<Text size='medium' color='base'>
								Export Test Data
							</Text>
						</StyledButton>
					</Drawer.ContentBlock>
					<Drawer.ContentBlock title='School Year Roster' withDivider>
						<Select.Root dataCy='school-year-dropdown' value={currentSchoolYear?.toString()} onValueChange={(value) => exportDataService.setSchoolYear(value)}>
							<Select.Field placeholder='School Year' />
							<Select.Content placeholder='School Year'>
								{schoolYears.map(({name, globalSchoolYearID}) => (
									<Select.Option key={globalSchoolYearID} value={String(globalSchoolYearID)}>
										<Text size='medium' bold>
											{name}
										</Text>
									</Select.Option>
								))}
							</Select.Content>
						</Select.Root>
						<StyledButton color='secondary' onClick={handleExportRosterClick}>
							<Upload />
							<Text size='medium' color='base'>
								Export Roster for School Year
							</Text>
						</StyledButton>
					</Drawer.ContentBlock>
				</ContentWrapper>
			</PanelContent>
			<BackgroundDownloadManager />
			{showExportModal && (
				<div data-cy='modal-export'>
					<ModalExport close={() => setShowExportModal(false)} />
				</div>
			)}
		</>
	);
}