import React, {useMemo} from 'react';
import {Avatar} from '@esgi/ui/avatar';
import {FlexBox} from '@esgi/ui/layout';
import {School} from '@esgi/contracts/esgi/types/esgi.schools/schools/get/by-district-id/school';
import {StudentInfoRowValue, StudentName, StudentInfo, StudentInfoRow, Label} from './index.styled';

interface Props {
	schools: School[];
	schoolID: number;
	studentId?: number;
	firstName?: string;
	lastName?: string;
	imageURL?: string;
	isLoaded: boolean;
}

export function StudentDetails({schools, schoolID, studentId, firstName, lastName, imageURL, isLoaded}: Props) {
	const school = useMemo(() => (
		schools?.find((item) => item.id === schoolID)
	), [schools, schoolID]);

	const schoolName = useMemo(() => (
		school?.name ?? '—'
	), [school]);

	return (
		<FlexBox align='center'>
			<Avatar.Root size='xl' borderColor='primary' skeleton={!isLoaded}>
				<Avatar.Image src={imageURL} />
				<Avatar.Fallback />
			</Avatar.Root>
			<StudentInfo>
				<StudentName size='large' skeleton={!isLoaded}>
					{`${firstName} ${lastName}`}
				</StudentName>
				<StudentInfoRow skeleton={!isLoaded}>
					<Label size='xSmall' font='mono' color='lowContrast'>ID:</Label>
					<StudentInfoRowValue size='xSmall' font='mono'>{studentId}</StudentInfoRowValue>
				</StudentInfoRow>
				<StudentInfoRow skeleton={!isLoaded}>
					<Label size='xSmall' font='mono' color='lowContrast'>School:</Label>
					<StudentInfoRowValue size='xSmall' font='mono'>{schoolName}</StudentInfoRowValue>
				</StudentInfoRow>
			</StudentInfo>
		</FlexBox>
	);
}

