import {GridBox} from '@esgi/ui/layout';
import {SelectedSubjectsInfo} from '../../../../components/selected-subjects-info';
import {SelectedClassesInfo} from '../../../../components/selected-classes-info';
import {GradeLevel, Widget, WidgetCard, WidgetPeriod} from '@esgi/main/features/admins/dashboard';
import {ClassModel, SubjectTab} from '../../../../../types';
import {PeriodsDateInfoTip} from './components/periods-date-info-tip';
import {StudentsTable} from './components/students-table';
import {ViewTableInfo, ViewTableType} from '../../types';
import {ClassesTable} from './components/classes-table';
import {CardBody} from './index.styled';

type Props = {
	classIDs: number[];
	allClassesList: ClassModel[];
	contentAreaIDs: number[];
	subjectIDs: number[];
	subjects: SubjectTab[];
	gradeLevelIDs: number[];
	periods: WidgetPeriod[];
	tableInfo: ViewTableInfo;
	gradeLevels: GradeLevel[];
	rootPageContainer: Element | DocumentFragment | null;
};

export function ViewMode({
	classIDs,
	allClassesList,
	contentAreaIDs,
	subjectIDs,
	subjects,
	gradeLevelIDs,
	periods,
	tableInfo,
	gradeLevels,
	rootPageContainer,
}: Props) {
	return (
		<CardBody>
			<GridBox align='center' justify='between' gap='6' flow='column'>
				<GridBox align='center' gap='2' flow='column'>
					<SelectedClassesInfo allClassesList={allClassesList} classIDs={classIDs} />
					<Widget.SelectedEntityInfoDivider />

					<SelectedSubjectsInfo subjectIDs={subjectIDs} subjects={subjects} />
					<Widget.SelectedEntityInfoDivider />

					<WidgetCard.ViewMode.SelectedContentAreasInfo contentAreaIDs={contentAreaIDs} />
					<Widget.SelectedEntityInfoDivider />

					<Widget.SelectedGradeLevelsInfo gradeLevelIDs={gradeLevelIDs} gradeLevels={gradeLevels} />
				</GridBox>

				<GridBox align='center' gap='2' flow='column'>
					<Widget.PeriodDatesInfo periodsData={periods} />
					{periods.length === 2 && <PeriodsDateInfoTip period={periods[0]!} />}
				</GridBox>
			</GridBox>

			{(tableInfo.type === ViewTableType.StudentsOnePeriod || tableInfo.type === ViewTableType.StudentsTwoPeriods) && (
				<StudentsTable tableInfo={tableInfo} periods={periods} rootPageContainer={rootPageContainer} />
			)}

			{tableInfo.type === ViewTableType.Classes && (
				<ClassesTable
					sortableState={tableInfo.sortableState}
					disabledSortableKeys={tableInfo.disabledSortableKeys}
					setSortableState={tableInfo.setSortableState}
				/>
			)}
		</CardBody>
	);
}
