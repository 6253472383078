import {studentsStore} from '@esgi/main/libs/admin-store';
import {useCallback} from 'react';

export function useRemoveStudentInStore() {
	return useCallback((studentID: number, cb?: VoidFunction) => {
		studentsStore().removeById?.(studentID);

		cb?.();
	}, []);
}
