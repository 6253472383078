import {FlexBox, GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ComponentProps, forwardRef} from 'react';

type Props = ComponentProps<typeof GridBox> & {
	value: number;
	maxValue: number;
	transcriptText?: string;
};

export const AnswersInfo = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'test-session-details-answers-info', css = {}, value, maxValue, transcriptText, ...props},
		forwardedRef,
	) => (
		<GridBox
			dataCy={dataCy}
			css={{
				gap: 2,
				...css,
			}}
			ref={forwardedRef}
			{...props}
		>
			<FlexBox align='end' dataCy='answers-info'>
				<Text size='medium' bold font='mono' color='base'>
					{value}
				</Text>
				<Text size='xSmall' font='mono' color='lowContrast'>
					/{maxValue}
				</Text>
			</FlexBox>
			{transcriptText && (
				<Text size='xSmall' font='mono' color='mediumContrast'>
					{transcriptText}
				</Text>
			)}
		</GridBox>
	),
);
