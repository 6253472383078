import {
	RubricCriterion,
	TestInfo,
	TestSession,
	UpdateRubricSessionRequestParams,
	UpdateRubricSessionResponse,
} from '../../../../../types/view-edit-session-details';
import {ViewModeContent} from './components/view-mode-content';
import {useCallback, useMemo} from 'react';
import {EditModeContent} from './components/edit-mode-content';
import {Observable} from 'rxjs';
import {Student} from '../../../../../types/common';

type Props = {
	canEdit: boolean;
	onCloseAlert: VoidFunction;
	sessionInfo: TestSession;
	testInfo: TestInfo;
	setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
	isEditMode: boolean;
	student: Student;
	onDeleteSession: VoidFunction;
	onRestoreSession: VoidFunction;
	onDownloadSession: VoidFunction;
	updateRubricSession: (args: UpdateRubricSessionRequestParams) => Observable<UpdateRubricSessionResponse>;
};

export function RubricTestSession({
	canEdit,
	onCloseAlert,
	sessionInfo,
	testInfo,
	isEditMode,
	setIsEditMode,
	student,
	onDeleteSession,
	onRestoreSession,
	onDownloadSession,
	updateRubricSession,
}: Props) {
	const questionTitlesById = useMemo(() => {
		const questionTitlesById: Record<RubricCriterion['id'], RubricCriterion['name']> = {};

		testInfo.rubricCriteria.forEach(({id, name}) => (questionTitlesById[id] = name));

		return questionTitlesById;
	}, [testInfo]);

	const onUpdateTestSession = useCallback(
		({
			summaryNotes,
			testDate,
			answers,
		}: {
			summaryNotes: string;
			testDate: string;
			answers: TestSession['rubricAnswers'];
		}) => {
			updateRubricSession({
				studentID: student.id,
				testID: sessionInfo.testID,
				testSessionID: sessionInfo.id,
				testDate,
				summaryNotes,
				answers,
			}).subscribe({
				complete: () => {
					setIsEditMode(false);
				},
			});
		},
		[sessionInfo, setIsEditMode, student, updateRubricSession],
	);

	if (isEditMode && !sessionInfo.isDeleted) {
		return (
			<EditModeContent
				canEdit={canEdit}
				sessionInfo={sessionInfo}
				testInfo={testInfo}
				questionTitlesById={questionTitlesById}
				onCloseAlert={onCloseAlert}
				setIsEditMode={setIsEditMode}
				student={student}
				onUpdateTestSession={onUpdateTestSession}
			/>
		);
	}

	return (
		<ViewModeContent
			canEdit={canEdit}
			sessionInfo={sessionInfo}
			testInfo={testInfo}
			questionTitlesById={questionTitlesById}
			onCloseAlert={onCloseAlert}
			setIsEditMode={setIsEditMode}
			student={student}
			onDeleteSession={onDeleteSession}
			onRestoreSession={onRestoreSession}
			onDownloadSession={onDownloadSession}
		/>
	);
}
