import {IAfterGuiAttachedParams, IDoesFilterPassParams} from 'ag-grid-community';
import {CustomFilterProps, useGridFilter} from 'ag-grid-react';
import {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react';
import {min, max} from 'underscore';
import {Button} from '@esgi/ui';
import {Close} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';

import {ActionsContainer, ContentContainer, HeaderContainer} from '../common.styled';
import {Input} from '@esgi/ui/controls';
import {Box} from '@esgi/ui/layout';
import {formatName} from '../utils';
import {Column, InputLabel, RootContainerMini, Row} from './styled';

export type NumberFilterParams = {
	min: number,
	max: number,
};

type KeysMatching<T, V> = {[K in keyof T]-?: T[K] extends V ? K : never}[keyof T];

export function numberFilterParams<T extends (number | object)>(...args: T extends number ? [values: T[]] : [values: T[], filed: KeysMatching<T, number>]): NumberFilterParams {
	const [values, field] = args;
	let array = values;
	if(field) {
		array = values.map(v => v[field]);
	}
	return {
		min: min(array) as number,
		max: max(array) as number,
	};
}

type Props = CustomFilterProps;

function formatNumber(x: number): string {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function NumberFilter({model, onModelChange, getValue, colDef}: Props) {
	const [onCloseCallback, setOnCloseCallback] = useState<{
		current: VoidFunction | undefined
	}>(() => ({current: undefined}));

	const dataMin = colDef?.filterParams?.min || 0;
	const dataMax = colDef?.filterParams?.max || 0;

	const [name, setName] = useState(() => formatName(colDef?.headerName || colDef.field || ''));
	const [min, setMin] = useState<number>(dataMin);
	const [max, setMax] = useState<number>(dataMax);

	const touched = useMemo(() => dataMin !== min || dataMax !== max, [min, max, dataMin, dataMax]);

	useEffect(() => {
		if (colDef?.filterParams) {
			const {min, max} = colDef.filterParams;
			if (typeof min !== 'number') {
				throw new Error(`Invalid parameter passed. Check column definition object. Property 'min' in filter parameters of '${name}' column should be a number.`);
			}
			if (typeof max !== 'number') {
				throw new Error(`Invalid parameter passed. Check column definition object. Property 'min' in filter parameters of '${name}' column should be a number.`);
			}
			setMin(min);
			setMax(max);
		}
	}, [dataMin, dataMax, name]);

	const doesFilterPass = useCallback(
		(params: IDoesFilterPassParams) => {
			if (!model) {
				return true;
			}
			const {min, max} = model;
			const {node} = params;
			const value: number = Number(getValue(node));
			return max >= value && value >= min;
		},
		[model, getValue],
	);

	const afterGuiAttached = useCallback((params?: IAfterGuiAttachedParams) => {
		if (params && params.hidePopup) {
			setOnCloseCallback({current: params.hidePopup});
		}
	}, []);

	useGridFilter({
		doesFilterPass,
		afterGuiAttached,
	});

	const apply = useCallback(() => {
		if(touched) {
			onModelChange({min, max});
		} else {
			onModelChange(undefined);
		}
		onCloseCallback!.current!();
	}, [max, min, onModelChange, onCloseCallback, touched]);

	const onMinChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const newMin = Number(e.target.value);
		setMin(newMin);
		if (!Number.isNaN(newMin)) {
			setMax(prev => prev < newMin ? newMin : prev);
		}
	}, []);

	const onMaxChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		const newMax = Number(e.target.value);
		setMax(newMax);
		if (!Number.isNaN(newMax)) {
			setMin(prev => prev > newMax ? newMax : prev);
		}
	}, []);

	const onResetClicked = useCallback(() => {
		setMin(dataMin);
		setMax(dataMax);
	}, [dataMin, dataMax]);

	const minTouched = dataMin !== min;
	const maxTouched = dataMax !== max;

	const resetMin = useCallback(() => setMin(dataMin), [dataMin]);
	const resetMax = useCallback(() => setMax(dataMax), [dataMax]);

	return <RootContainerMini>
		<ContentContainer>
			<HeaderContainer>
				<Box style={{width: 26}}/>
				<Text size='small' color='mediumContrast'>{name}</Text>
				<Button.Icon onClick={onCloseCallback.current}>
					<Close/>
				</Button.Icon>
			</HeaderContainer>
			<Row>
				<Column>
					<InputLabel size='small' color='mediumContrast'>From</InputLabel>
					<Input type='number' variantType='mini' value={min} min={dataMin} max={dataMax}
					       onChange={onMinChanged}/>
					<InputLabel size='small' muted={!minTouched} clickable={minTouched}
					            onClick={resetMin}>{formatNumber(dataMin)} Min</InputLabel>
				</Column>
				<Box>
					<svg width='21' height='8' viewBox='0 0 21 8' fill='none'>
						<rect x='0.5' y='1.5' width='1' height='5' rx='0.5' fill='#CCCCCC'/>
						<path
							d='M20.8536 4.35355C21.0488 4.15829 21.0488 3.84171 20.8536 3.64645L17.6716 0.464466C17.4763 0.269204 17.1597 0.269204 16.9645 0.464466C16.7692 0.659728 16.7692 0.976311 16.9645 1.17157L19.7929 4L16.9645 6.82843C16.7692 7.02369 16.7692 7.34027 16.9645 7.53553C17.1597 7.7308 17.4763 7.7308 17.6716 7.53553L20.8536 4.35355ZM1.5 4.5H20.5V3.5H1.5V4.5Z'
							fill='#CCCCCC'/>
					</svg>
				</Box>
				<Column>
					<InputLabel size='small' color='mediumContrast'>To</InputLabel>
					<Input type='number' variantType='mini' value={max} min={dataMin} max={dataMax}
					       onChange={onMaxChanged}/>
					<InputLabel size='small' muted={!maxTouched} clickable={maxTouched}
					            onClick={resetMax}>{formatNumber(dataMax)} Max</InputLabel>
				</Column>
			</Row>
			<ActionsContainer>
				<Button color='tertiary' disabled={!touched} onClick={onResetClicked}>
					<Text size='medium' bold>Reset</Text>
				</Button>
				<Button color='secondary' onClick={apply}>
					<Text size='medium' bold>Apply</Text>
				</Button>
			</ActionsContainer>
		</ContentContainer>
	</RootContainerMini>;
}