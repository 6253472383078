import {styled} from '@esgi/ui/theme';
import {HeaderTitle as HeaderTitleBase} from '../../components/header.styled';

export const HeaderTitle = styled(HeaderTitleBase, {
	'& > svg': {
		fill: '$mediumContrast',

		'& path': {
			fill: '$mediumContrast',
		},
	},
});
