import {useDisposable} from '@esgi/core/service';
import {useUser} from '@esgi/core/authentication';
import {V2DistrictAdminsFoldersSchoolsController, V2DistrictAdminsFoldersUsersController} from '@esgi/contracts/esgi';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useCallback, useEffect} from 'react';
import {FolderType} from '../types';
import {isNull} from 'underscore';
import {dispatchAppEvent} from '@esgillc/events';
import {FolderUpdatedEvent} from '../events';

type Parameters = {
	type: FolderType;
	onFolderUpdated?: VoidFunction;
};

export function useUpdateFolder({type, onFolderUpdated}: Parameters) {
	const currentUser = useUser();

	const foldersSchoolsController = useDisposable(V2DistrictAdminsFoldersSchoolsController);
	const foldersUsersController = useDisposable(V2DistrictAdminsFoldersUsersController);

	const [updateSchoolFolderData, updateSchoolFolder] = useCancelableRequest(foldersSchoolsController.update);
	const [updateUserFolderData, updateUserFolder] = useCancelableRequest(foldersUsersController.update);

	useEffect(() => {
		if (isAsyncSucceed(updateSchoolFolderData)) {
			onFolderUpdated?.();

			dispatchAppEvent(
				FolderUpdatedEvent,
				new FolderUpdatedEvent({
					type: FolderType.Schools,
					id: updateSchoolFolderData.data.groupID,
				}),
			);
		}
	}, [onFolderUpdated, updateSchoolFolderData]);

	useEffect(() => {
		if (isAsyncSucceed(updateUserFolderData)) {
			onFolderUpdated?.();

			dispatchAppEvent(
				FolderUpdatedEvent,
				new FolderUpdatedEvent({
					type,
					id: updateUserFolderData.data.groupID,
				}),
			);
		}
	}, [onFolderUpdated, type, updateUserFolderData]);

	return useCallback(
		({entityIDs, name, id}: {entityIDs: number[]; name: string; id: number}) => {
			if (isNull(currentUser)) {
				return;
			}

			if (type === FolderType.Schools) {
				updateSchoolFolder({
					districtID: currentUser.districtID,
					groupID: id,
					groupName: name,
					schoolIDs: entityIDs,
				});

				return;
			}

			updateUserFolder({
				globalSchoolYearID: currentUser.globalSchoolYearID,
				groupID: id,
				name,
				userIDs: entityIDs,
			});
		},
		[currentUser, type, updateUserFolder, updateSchoolFolder],
	);
}
