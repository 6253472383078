import {Dispatch, useCallback, useMemo, useRef, useState} from 'react';
import {DatePickerData, PeriodType} from '../types';
import {isNull} from 'underscore';
import {isDateTouched} from '../utils/validate-dates';

type Parameters = {
	value: DatePickerData | null;
	onChanged?: Dispatch<DatePickerData>;
};

export function useDatePickerWidget({value, onChanged}: Parameters) {
	const initialData = useRef<DatePickerData | null>(null);

	const [state, setState] = useState(() => {
		const initialState: DatePickerData = isNull(value)
			? {
					datePeriod: PeriodType.None,
					dateRange: null,
					trackDatesID: 0,
			  }
			: value;

		initialData.current = initialState;

		return initialState;
	});

	const isTouched = useMemo(() => {
		return isDateTouched(state, initialData.current);
	}, [state, initialData]);

	const handleChange = useCallback(
		(data: DatePickerData) => {
			setState({...state, ...data});

			onChanged?.({...state, ...data});
		},
		[state, onChanged],
	);

	return {
		state,
		handleChange,
		isTouched,
	};
}
