import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const FiltersBox = styled(FlexBox, {
	gap: '$3',
	alignItems: 'center',
	justifyContent: 'space-between',
});

export const SideFilters = styled(FlexBox, {
	gap: '$3',
	alignItems: 'center',
});
