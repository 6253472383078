import {useMemo} from 'react';
import {unique} from 'underscore';

import {useDisposable, useEndpointResult} from '@esgi/core/service';
import {V2SchoolAdminsPagesDataAnalyticsController, V2SchoolAdminsUserAccountController} from '@esgi/contracts/esgi';

import {RowDef} from './types';
import {useClasses, useGroups, useTeachers} from '@esgi/main/libs/school-admin-store';

export function useData() {
	const [{data: allTeachers}] = useTeachers();
	const [{data: allGroups}] = useGroups();
	const [{data: classes, loaded: isClassesLoaded}] = useClasses();

	const analyticController = useDisposable(V2SchoolAdminsPagesDataAnalyticsController);
	const analyticsData = useEndpointResult(() => {
		if (isClassesLoaded) {
			return analyticController.classes({classIDs: classes.map((c) => c.id)});
		}
	}, [classes, isClassesLoaded]);

	const userAccountController = useDisposable(V2SchoolAdminsUserAccountController);

	const userControllerData = useEndpointResult(() => {
		if (isClassesLoaded) {
			return userAccountController.init();
		}
	}, [classes, isClassesLoaded]);

	const schoolName = useMemo(() => {
		return userControllerData?.user.schoolName;
	}, [userControllerData]);

	const {rows, groups, teachers} = useMemo(() => {
		const rows = classes.map((classEntity) => {
			const teacher = allTeachers.find((t) => t.id === classEntity.teacherID);
			const groups = allGroups.filter((g) => g.classID === classEntity.id) || [];
			const analytic = analyticsData?.value?.classAnalytics?.find((a) => a.classID === classEntity.id);

			const rowDef: RowDef = {
				id: classEntity.id,
				name: classEntity.name,
				teacher: teacher ?? null,
				groups,
				students: analytic?.studentsCount ?? 0,
			};

			return rowDef;
		});
		return {
			rows,
			groups: unique(rows.map((r) => r.groups).flat()).filter(Boolean),
			teachers: unique(rows.map((r) => r.teacher)).filter(Boolean),
		};
	}, [classes, allGroups, allTeachers, analyticsData]);

	return {
		ready: isClassesLoaded,
		rows,
		teachers,
		groups,
		schoolName,
	};
}
