import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container} from './index.styled';
import {BaseComponentProps} from '@esgi/ui';

type Props = BaseComponentProps & ComponentPropsWithoutRef<'div'>;

export const TwoSplitButtonsBox = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-card-two-split-buttons-box', css = {}, children, ...props}, forwardedRef) => (
		<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
			{children}
		</Container>
	),
);
