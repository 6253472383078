import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {AlertBody, AlertFooter, AlertHeader, alertRootCss} from './index.styled';
import {Button} from '@esgi/ui';
import {useCallback} from 'react';
import {SubjectName} from '../subject-name';

type Props = {
	onDeleted: VoidFunction;
	onClose: VoidFunction;
};

export function DeleteSubjectAlert({onClose, onDeleted}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const handleDeleted = useCallback(() => {
		onDeleted();
		closeAlert();
	}, [closeAlert, onDeleted]);

	return (
		<Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative} css={alertRootCss}>
			<AlertHeader withBacklight={false} onCloseIconClick={closeAlert}>
				<Text size='small'>Delete Subject Tab?</Text>

				<SubjectName />
			</AlertHeader>

			<AlertBody>
				<Text size='small' color='highContrast'>
					Are you sure you want to delete this Subject Tab?
				</Text>
				<Text size='small' color='highContrast'>
					This action cannot be undone. All tests and their results will be lost.
				</Text>
			</AlertBody>

			<AlertFooter>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>

					<Button color='warn' onClick={handleDeleted}>
						<Text size='medium' bold color='negative'>
							Delete
						</Text>
					</Button>
				</GridBox>
			</AlertFooter>
		</Alert>
	);
}
