import * as React from 'react';
import type {SVGProps} from 'react';

export function SortDescending(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.1281 16.872C22.6094 16.872 23 16.4816 23 16C23 15.5185 22.6094 15.1281 22.1281 15.1281L18.6403 15.1281C18.159 15.1281 17.7684 15.5185 17.7684 16C17.7684 16.4816 18.159 16.872 18.6403 16.872H22.1281ZM22.1281 11.6404C22.6094 11.6404 23 11.25 23 10.7684C23 10.2869 22.6094 9.89649 22.1281 9.89649L18.6403 9.89648C18.159 9.89648 17.7684 10.2868 17.7684 10.7684C17.7684 11.25 18.159 11.6404 18.6403 11.6404L22.1281 11.6404Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.1281 22.1036C22.6094 22.1036 23 21.7132 23 21.2316C23 20.7501 22.6094 20.3597 22.1281 20.3597L9.92098 20.3597C9.43967 20.3597 9.04905 20.7501 9.04905 21.2316C9.04905 21.7132 9.43967 22.1036 9.92098 22.1036L22.1281 22.1036Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.25367 14.917L11.7199 17.3832C11.9475 17.6108 12.2696 17.6863 12.5599 17.6096C12.7312 17.579 12.8955 17.4981 13.0278 17.3658L15.494 14.8996C15.8345 14.5591 15.8345 14.007 15.494 13.6665C15.1535 13.326 14.6014 13.326 14.2609 13.6665L13.1827 14.7441L13.1738 10.7684C13.1738 10.2869 12.7834 9.89648 12.3019 9.89648C11.8203 9.89648 11.4299 10.2869 11.4299 10.7684L11.4388 14.636L10.4868 13.6839C10.1463 13.3434 9.59419 13.3434 9.25367 13.6839C8.91316 14.0244 8.91316 14.5765 9.25367 14.917Z'
				fill='#333333'
			/>
		</svg>
	);
}
