import {useCallback, useEffect, useState} from 'react';
import {useStreamEffect} from '@esgi/ui';
import {StudentsTwoPeriodsForm, StudentsTwoPeriodsInitialFormData} from '../../../../forms-data/types';
import {isPeriodRowTouched} from './helpers/is-period-row-touched';
import {DynamicFormProps} from '../../../../types';
import {PeriodRow} from './components/period-row';
import {ExcludedColors} from './types';
import {Color} from '@esgi/main/features/admins/dashboard';

type Props = DynamicFormProps<{
	form: StudentsTwoPeriodsForm;
	initialForm: StudentsTwoPeriodsInitialFormData;
}>;

export function TwoPeriods({
	form,
	initialForm,
	setIsDynamicFormDataValid,
	setIsDynamicFormDataTouched,
	onCurrentDynamicFormNameChange,
}: Props) {
	const [excludedColors, setExcludedColors] = useState<ExcludedColors>({
		firstPeriodColor: form.controls.firstPeriodColor.value[0] ?? null,
		secondPeriodColor: form.controls.secondPeriodColor.value[0] ?? null,
	});

	const updateExcludedColors = useCallback(({key, newValue}: {key: keyof ExcludedColors; newValue: Color | null}) => {
		setExcludedColors((currentState) => ({
			...currentState,
			[key]: newValue,
		}));
	}, []);

	useStreamEffect(form.controls.firstPeriodColor.onChanged, ({currState: {value}}) => {
		updateExcludedColors({
			key: 'firstPeriodColor',
			newValue: value[0] ?? null,
		});
	});

	useStreamEffect(form.controls.secondPeriodColor.onChanged, ({currState: {value}}) => {
		updateExcludedColors({
			key: 'secondPeriodColor',
			newValue: value[0] ?? null,
		});
	});

	const handleValidate = useCallback(() => {
		const {firstPeriodColor, secondPeriodColor, firstPeriodDate, secondPeriodDate} = form.value;

		const isFirstPeriodTouched = isPeriodRowTouched({
			initialColor: initialForm.firstPeriod.color,
			initialDates: {
				datePeriod: initialForm.firstPeriod.datePeriod,
				trackDatesID: initialForm.firstPeriod.trackDatesID,
				dateRange: initialForm.firstPeriod.dateRange,
			},
			currentColor: firstPeriodColor[0],
			currentDates: firstPeriodDate,
		});

		const isSecondPeriodTouched = isPeriodRowTouched({
			initialColor: initialForm.secondPeriod.color,
			initialDates: {
				datePeriod: initialForm.secondPeriod.datePeriod,
				trackDatesID: initialForm.secondPeriod.trackDatesID,
				dateRange: initialForm.secondPeriod.dateRange,
			},
			currentColor: secondPeriodColor[0],
			currentDates: secondPeriodDate,
		});

		form.validate(true).subscribe(({valid}) => {
			setIsDynamicFormDataValid(valid);
		});

		setIsDynamicFormDataTouched(isFirstPeriodTouched || isSecondPeriodTouched);
	}, [form, initialForm, setIsDynamicFormDataTouched, setIsDynamicFormDataValid]);

	useEffect(() => {
		handleValidate();
		onCurrentDynamicFormNameChange('studentsTwoPeriods');
	}, []);

	useStreamEffect(form.onChanged, handleValidate);

	return (
		<>
			<PeriodRow
				form={form}
				colorControl={form.controls.firstPeriodColor}
				dateRangeControl={form.controls.firstPeriodDate}
				excludedColor={excludedColors.secondPeriodColor}
			/>
			<PeriodRow
				form={form}
				colorControl={form.controls.secondPeriodColor}
				dateRangeControl={form.controls.secondPeriodDate}
				excludedColor={excludedColors.firstPeriodColor}
			/>
		</>
	);
}
