import {isNull} from 'underscore';
import {CardsSortBy} from '../../types/section';
import {ByEachStudentTest} from '../../types/test-panel';
import {SortingConfig} from '../../utils/sort-test-cards';

export const sortTestsCardsConfig: SortingConfig<ByEachStudentTest> = {
	[CardsSortBy.TestName]: {
		comparator: 'name',
	},
	[CardsSortBy.StudentTestedPercentage]: {
		comparator: (a, b) => {
			if (isNull(a.result)) {
				return 1;
			}

			if (isNull(b.result)) {
				return -1;
			}

			const aValue = a.result.correctScore;
			const bValue = b.result.correctScore;

			if (aValue === bValue) {
				return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
			}

			return aValue - bValue;
		},
	},
	[CardsSortBy.LastTested]: {
		comparator: (a, b) => {
			if (isNull(a.result)) {
				return 1;
			}

			if (isNull(b.result)) {
				return -1;
			}

			const aDateTime = new Date(a.result.lastTestDate).getTime();
			const bDateTime = new Date(b.result.lastTestDate).getTime();

			if (aDateTime === bDateTime) {
				return a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase());
			}

			return aDateTime - bDateTime;
		},
	},
};
