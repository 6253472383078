import {isUndefined} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {SchoolPerformanceWidgetOptions} from '../../../../../../types/school-performance';
import {BoxplotCompareVariant, DynamicFormProps} from '../../types';
import {MultiplePeriods} from './components/multiple-periods';
import {SinglePeriod} from './components/single-period';
import {DynamicComparePeriodsFormRef} from './types';
import {ToggleGroup} from '@esgi/main/features/admins/dashboard';
import {periodItems} from './constants';

type Props = DynamicFormProps<Pick<SchoolPerformanceWidgetOptions, 'periods' | 'candles'>>;

export function CandleChartData({
	periods,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	candles,
	dynamicFormDataRef,
}: Props) {
	const [isComparePeriodsFormTouched, setIsComparePeriodsFormTouched] = useState(false);

	const initialComparePeriodsValue = useMemo(
		() => (periods.length === 2 ? BoxplotCompareVariant.CompareTwoPeriods : BoxplotCompareVariant.SinglePeriod),
		[periods.length],
	);

	const [comparePeriodsValue, setComparePeriodsValue] = useState(initialComparePeriodsValue);

	const dynamicComparePeriodsFormRef = useRef<DynamicComparePeriodsFormRef | undefined>();

	useEffect(() => {
		setIsDynamicFormDataTouched(isComparePeriodsFormTouched || comparePeriodsValue !== initialComparePeriodsValue);
	}, [comparePeriodsValue, initialComparePeriodsValue, isComparePeriodsFormTouched]);

	const handleComparePeriodsValueChange = useCallback((value: BoxplotCompareVariant) => {
		setComparePeriodsValue(value);
	}, []);

	useImperativeHandle(dynamicFormDataRef, () => ({
		getData: () => {
			if (isUndefined(dynamicComparePeriodsFormRef.current)) {
				return {
					periods: [],
					candles: null,
				};
			}

			const {periods, candles} = dynamicComparePeriodsFormRef.current.getPeriodsFormData();

			return {
				periods,
				candles,
			};
		},
	}));

	return (
		<GridBox gap='3'>
			<ToggleGroup items={periodItems} value={comparePeriodsValue} onValueChange={handleComparePeriodsValueChange} />

			{comparePeriodsValue === BoxplotCompareVariant.SinglePeriod && (
				<SinglePeriod
					periodData={periods[0]}
					highlight={candles}
					setIsFormTouched={setIsComparePeriodsFormTouched}
					setIsFormValid={setIsDynamicFormDataValid}
					dynamicFormDataRef={dynamicComparePeriodsFormRef}
				/>
			)}

			{comparePeriodsValue === BoxplotCompareVariant.CompareTwoPeriods && (
				<MultiplePeriods
					periodsData={[periods[0] ?? null, periods[1] ?? null]}
					setIsFormTouched={setIsComparePeriodsFormTouched}
					setIsFormValid={setIsDynamicFormDataValid}
					dynamicFormDataRef={dynamicComparePeriodsFormRef}
				/>
			)}
		</GridBox>
	);
}
