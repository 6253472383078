import {WidgetItemPosition} from '../types';

export function getGridstackItemPosition(columnsCount: number) {
	let currentX = 0;
	let currentY = 0;

	return function ({itemIndex, isFullSize}: {itemIndex: number; isFullSize?: boolean}): WidgetItemPosition {
		if (isFullSize) {
			if (currentX !== 0 && itemIndex !== 0) {
				currentY++;
			}

			const data: WidgetItemPosition = {
				x: 0,
				y: currentY,
				w: columnsCount,
			};

			currentX = 0;
			currentY++;

			return data;
		}

		const data: WidgetItemPosition = {
			x: currentX,
			y: currentY,
			w: 1,
		};

		currentX++;

		if (currentX >= columnsCount) {
			currentX = 0;
			currentY++;
		}

		return data;
	};
}
