import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {AlertBody, AlertFooter, AlertHeader, alertRootCss, GroupNameBox} from './index.styled';
import {Button} from '@esgi/ui';
import {Students} from '@esgi/ui/icons';
import {OneLinedText} from '@esgi/main/kits/common';
import {useRemoveGroup} from './use-remove-group';
import {Group} from '@esgi/main/libs/admin-store';

type Props = {
	onDeleted: VoidFunction;
	onClose: VoidFunction;
	currentGroup: Group;
};

export function DeleteGroupAlert({onClose, onDeleted, currentGroup}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const {onRemoveGroup, isGroupRemoving} = useRemoveGroup({
		currentGroup,
		onRemoved: onDeleted,
	});

	return (
		<Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative} css={alertRootCss}>
			<AlertHeader withBacklight={false} onCloseIconClick={closeAlert}>
				<Text size='small'>Delete Group?</Text>

				<GroupNameBox>
					<Students />
					<OneLinedText size='small' color='highContrast'>
						{currentGroup.name}
					</OneLinedText>
				</GroupNameBox>
			</AlertHeader>

			<AlertBody>
				<Text size='small' color='highContrast'>
					Are you sure you want to delete the following group?
				</Text>
				<Text size='small' color='highContrast'>
					This will NOT delete students and their data. The students still will be available and can be added to another
					group.
				</Text>
			</AlertBody>

			<AlertFooter>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>

					<Button color='warn' onClick={onRemoveGroup} disabled={isGroupRemoving}>
						<Text size='medium' bold color='negative'>
							Delete
						</Text>
					</Button>
				</GridBox>
			</AlertFooter>
		</Alert>
	);
}
