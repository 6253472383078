import {isNull, noop} from 'underscore';
import {DistrictReportPanel, Page} from '../../../../_kit';
import {RowDef} from '../../../types';
import {BottomPanel} from './bottom-panel';
import {AddEntitiesIntoFolder, FolderType} from '@esgi/main/features/admins/data';
import {useDataPageContext} from '../../../../layout/context';
import {addButtonDisabledTooltipMessage} from '../../constants';
import {SingleItemExpandablePanel} from './single-item-expandable-panel';
import {FolderValidationAlert} from './folder-validation-alert';
import {useCallback, useState} from 'react';
import {UserType} from '@esgi/core/authentication';

export function Content() {
	const {entityFolders, districtName} = useDataPageContext();
	const {selectedItems, api} = Page.useContext<RowDef>();

	const [addToFolderEntityIDs, setAddToFolderEntityIDs] = useState<number[] | null>(null);
	const [folderValidationAlert, setFolderValidationAlert] = useState<{isOnlyPreAssessUsers: boolean} | null>(null);

	const onFolderAddClick = useCallback(() => {
		if (selectedItems.every(({type}) => type.id === UserType.PA)) {
			setFolderValidationAlert({isOnlyPreAssessUsers: true});
			return;
		}

		if (selectedItems.some(({type}) => type.id === UserType.PA)) {
			setFolderValidationAlert({isOnlyPreAssessUsers: false});
			return;
		}

		setAddToFolderEntityIDs(selectedItems.map(({id}) => id));
	}, [selectedItems]);

	const onAddWithoutPreAssess = useCallback(() => {
		setAddToFolderEntityIDs(selectedItems.filter(({type}) => type.id !== UserType.PA).map(({id}) => id));
	}, [selectedItems]);

	const closeFolderValidationAlert = useCallback(() => {
		setFolderValidationAlert(null);
	}, []);

	const closeAddEntitiesIntoFolder = useCallback(() => {
		setAddToFolderEntityIDs(null);
	}, []);

	return (
		<>
			<Page.Header.Base
				onAddButtonClick={noop}
				addButtonDisabled
				addButtonDisabledTooltipMessage={addButtonDisabledTooltipMessage}
			/>
			<Page.Body.Base />

			<DistrictReportPanel api={api} title={districtName} />
			<SingleItemExpandablePanel gridApi={api} onFolderAddClick={onFolderAddClick} />
			<BottomPanel onFolderAddClick={onFolderAddClick} />

			{!isNull(folderValidationAlert) && (
				<FolderValidationAlert
					onClose={closeFolderValidationAlert}
					onAddWithoutPreAssess={onAddWithoutPreAssess}
					isOnlyPreAssessUsers={folderValidationAlert.isOnlyPreAssessUsers}
				/>
			)}

			{!isNull(addToFolderEntityIDs) && (
				<AddEntitiesIntoFolder
					addEntityIDs={addToFolderEntityIDs}
					folders={(entityFolders ?? []).map(({id, name}) => ({id, name}))}
					initialFolderID={null}
					onCloseAlert={closeAddEntitiesIntoFolder}
					folderType={FolderType.Specialist}
				/>
			)}
		</>
	);
}
