import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BaseComponentProps, getThemeColor, ThemeColorKeys} from '@esgi/ui';
import {CheapContent} from './index.styled';
import {useSubjectPanelContext} from '../../../context';

type CheapProps = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'color'> & {
		color: ThemeColorKeys;
	};

export const CheapBox = forwardRef<HTMLDivElement, CheapProps>(
	({dataCy = 'cheap-box', css = {}, color, ...props}, forwaredRef) => {
		const {skeleton} = useSubjectPanelContext();

		return (
			<CheapContent
				dataCy={dataCy}
				css={{
					backgroundColor: getThemeColor(color),
					...css,
				}}
				ref={forwaredRef}
				data-cheap-box=''
				{...props}
				skeleton={skeleton}
			/>
		);
	},
);
