import {
	DeployedSubject,
	GradeLevel,
	SchoolModel,
	SelectableListItemModel,
	WidgetCard,
} from '@esgi/main/features/admins/dashboard';
import {useMemo} from 'react';

export function useDynamicAdaptedItems({
	schools,
	subjects,
	gradeLevels,
}: {
	subjects: DeployedSubject[];
	schools: SchoolModel[];
	gradeLevels: GradeLevel[];
}) {
	const {allContentAreas} = WidgetCard.useWidgetCardContext();

	const schoolsAdaptedItems = useMemo(
		() =>
			schools.map<SelectableListItemModel<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[schools],
	);

	const subjectsAdaptedItems = useMemo(
		() =>
			subjects.map<SelectableListItemModel<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[subjects],
	);

	const contentAreasAdaptedItems = useMemo(
		() =>
			allContentAreas.map<SelectableListItemModel<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[allContentAreas],
	);

	const gradeLevelsAdaptedItems = useMemo(
		() =>
			gradeLevels.map<SelectableListItemModel<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[gradeLevels],
	);

	return {
		schoolsAdaptedItems,
		subjectsAdaptedItems,
		contentAreasAdaptedItems,
		gradeLevelsAdaptedItems,
	};
}
