import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {useUser} from '@esgi/core/authentication';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useEffect, useMemo} from 'react';
import {isNull} from 'underscore';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {TestResultsSubject} from '@esgi/main/kits/admin';

type Parameters = {
	schoolID: number | null;
	targetGradeLevels: number[];
};

type Out = [boolean, TestResultsSubject[]];

export function useSubjectsBySchoolID({schoolID, targetGradeLevels}: Parameters): Out {
	const currentUser = useUser();
	const api = useEsgiApiGateway();

	const [subjectsData, fetchSubjects] = useCancelableRequest(api.v2.districtAdmins.modules.subjects.bySchoolId);

	const withFetchedData = !isNull(currentUser) && !isNull(schoolID);

	useEffect(() => {
		if (withFetchedData) {
			fetchSubjects({
				globalSchoolYearID: currentUser.globalSchoolYearID,
				schoolID,
			});
		}
	}, [currentUser, fetchSubjects, schoolID, withFetchedData]);

	return useMemo<Out>(() => {
		if (!withFetchedData) {
			return [true, []];
		}

		const isSubjectsLoaded = isAsyncSucceed(subjectsData);

		if (!isSubjectsLoaded) {
			return [false, []];
		}

		const filteredSubjects = subjectsData.data.value.deployedSubjects.filter(
			({hidden, hasTests, gradeLevels, published}) =>
				published && !hidden && hasTests && gradeLevels.some((gradeLevel) => targetGradeLevels.includes(gradeLevel)),
		);

		const activeSubjects = filteredSubjects.map<TestResultsSubject>(({id, name, gradeLevels}) => ({
			id,
			name,
			level: SubjectLevel.School,
			type: SubjectType.Deployed,
			gradeLevels,
		}));

		return [true, activeSubjects];
	}, [subjectsData, targetGradeLevels, withFetchedData]);
}
