import {useCallback, useEffect, useState} from 'react';
import {Form} from '@esgillc/ui-kit/form';
import {Text} from '@esgi/ui/typography';
import {FormElement} from '@esgi/ui/form';
import {Drawer} from '@esgi/main/kits/common';
import {RadioGroup} from '@esgi/ui/form-controls';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {SingleCheckboxSectionState} from '../../../../single-checkbox-section-state';
import {useService} from '../../../../../../../../../../../../../libs/core/src/service';
import {SettingsTab, ShowStudentIDType} from '../../../../../types';
import {ContentWrapper, PanelContent} from '../../../index.styled';
import {DrawerPanelHeader} from '../../../../../index.styled';
import {SettingsService} from '../../../../../service';
import {ReportsSettingsService} from './service';
import {showStudentIDs} from './model';
import {ShowStudentID} from '@esgi/contracts/esgi/types/esgi.reports/enums/district-settings/show-student-id';

interface Props {
	service: SettingsService;
	onCloseDrawer: VoidFunction;
	onUpdateTabTouchedData: (tab: SettingsTab, isTouched: boolean) => void;
}

export function Reports({service, onCloseDrawer, onUpdateTabTouchedData}: Props) {
	const settingsData = useBehaviorSubject(service.settingsData$);

	const reportsSettingsService = useService(ReportsSettingsService);

	const [isDisableButton, setIsDisableButton] = useState(true);
	const [isShowStudentID, setIsShowStudentID] = useState(false);

	const handleShowStudentIDChange = useCallback((isChecked: boolean) => {
		setIsShowStudentID(isChecked);

		if (isChecked) {
			reportsSettingsService.form.controls.reportShowStudentID.value = ShowStudentIDType.StudentID;
			return;
		}

		reportsSettingsService.form.controls.reportShowStudentID.value = ShowStudentIDType.None;
	}, [reportsSettingsService]);

	const handleSave = useCallback(() => {
		service.saveSettings({
			reportShowStudentID: reportsSettingsService.form.value.reportShowStudentID as unknown as ShowStudentID,
		}).subscribe(() => {
			onUpdateTabTouchedData(SettingsTab.Reports, false);
			setIsDisableButton(true);
		});
	}, [service, reportsSettingsService, onUpdateTabTouchedData]);

	useStreamEffect(reportsSettingsService.form.onChanged, () => {
		const isTouched = reportsSettingsService.compareStatesForEquality();

		setIsDisableButton(!isTouched);
		onUpdateTabTouchedData(SettingsTab.Reports, isTouched);
	});

	useEffect(() => {
		reportsSettingsService.init(settingsData);
		setIsShowStudentID(settingsData.reportShowStudentID !== ShowStudentIDType.None as unknown);
	}, [reportsSettingsService, settingsData]);

	return (
		<Form controller={reportsSettingsService.form}>
			<DrawerPanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={handleSave}
				actionButtonDisabled={isDisableButton}
				onClose={onCloseDrawer}
			>
				<Text data-cy='reports-settings-title' size='large' color='neutral24' bold>
					Reports Settings
				</Text>
			</DrawerPanelHeader>

			<PanelContent>
				<ContentWrapper dataCy='reports-settings-block'>
					<Drawer.ContentBlock title='Show Student ID' withDivider>
						<SingleCheckboxSectionState
							checked={isShowStudentID}
							onValueChange={handleShowStudentIDChange}
							description={'When enabled, this setting allows users to see either the Student ID or Export ID, including ID indication on exported reports.\n\nChoose Export ID if you are an importing district that imports via Clever or ClassLink.'}
						>
							<FormElement control={reportsSettingsService.form.controls.reportShowStudentID}>
								<RadioGroup.Root disabled={!isShowStudentID}>
									<RadioGroup.Content>
										{showStudentIDs.map(({value, text}) => (
											<RadioGroup.Item value={value}>
												{text}
											</RadioGroup.Item>
										))}
									</RadioGroup.Content>
								</RadioGroup.Root>
							</FormElement>
						</SingleCheckboxSectionState>
					</Drawer.ContentBlock>
				</ContentWrapper>
			</PanelContent>
		</Form>
	);
}