import {Drawer, getFullName, OneLinedText} from '@esgi/main/kits/common';
import {AddStudentButton, AddStudentsBox, ContentBox, StudentItem, StudentsListBox} from './index.styled';
import {ClearAll, Close, Plus} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {Button, useStreamEffect} from '@esgi/ui';
import {SpecialistGroup, Student} from '@esgi/main/libs/admin-store';
import {Dispatch, useCallback, useState} from 'react';
import {AddStudentsAlert, Entity as AddStudentsAlertEntity} from '@esgi/main/kits/admin';
import {ResetAllStudentsAlert} from './components/reset-all-students-alert';
import {isNull} from 'underscore';
import {ResetSingleStudentAlert} from './components/reset-single-student-alert';
import {StudentsIDsControl} from '../../variant/types';
import {useAddStudentAlertData} from './use-add-student-alert-data';
import {ErrorMessageBox} from '../error-message-box';

type CurrentSpecialistGroup = {
	id: SpecialistGroup['id'];
	name: string;
};

export type StudentsTabContentProps = {
	allStudents: Student[];
	studentsIDsControl: StudentsIDsControl;
	withoutResetSingleStudentAlert: boolean;
	setWithoutResetSingleStudentAlert: Dispatch<boolean>;
	errorMessage: string | null;
	currentSpecialistGroup: CurrentSpecialistGroup | null;
	addStudentsAlertEntity: AddStudentsAlertEntity;
};

export function StudentsTabContent({
	allStudents,
	studentsIDsControl,
	withoutResetSingleStudentAlert,
	setWithoutResetSingleStudentAlert,
	errorMessage,
	currentSpecialistGroup,
	addStudentsAlertEntity,
}: StudentsTabContentProps) {
	const [activeStudents, setActiveStudents] = useState<Student[]>(
		allStudents.filter(({id}) => studentsIDsControl.value.includes(id)),
	);

	const [showAddStudentsAlert, setShowAddStudentsAlert] = useState(false);
	const [showResetAllStudentsAlert, setShowResetAllStudentsAlert] = useState(false);
	const [resetSingleStudent, setResetSingleStudent] = useState<Student | null>(null);

	const openAddStudentsAlert = useCallback(() => {
		setShowAddStudentsAlert(true);
	}, []);

	const closeAddStudentsAlert = useCallback(() => {
		setShowAddStudentsAlert(false);
	}, []);

	const openResetAllStudentsAlert = useCallback(() => {
		setShowResetAllStudentsAlert(true);
	}, []);

	const closeResetAllStudentsAlert = useCallback(() => {
		setShowResetAllStudentsAlert(false);
	}, []);

	useStreamEffect(studentsIDsControl.onChanged, ({currState: {value}}) => {
		setActiveStudents(allStudents.filter(({id}) => value.includes(id)));
	});

	const onAddStudents = useCallback(
		(ids: Student['id'][]) => {
			studentsIDsControl.value = ids;
		},
		[studentsIDsControl],
	);

	const resetAllStudents = useCallback(() => {
		studentsIDsControl.value = [];
	}, [studentsIDsControl]);

	const resetStudentByID = useCallback(
		(id: Student['id']) => {
			const currentStudentsIDs = studentsIDsControl.value;

			studentsIDsControl.value = currentStudentsIDs.filter((studentID) => studentID !== id);
		},
		[studentsIDsControl],
	);

	const handleResetSingleStudent = useCallback(
		(student: Student) => {
			if (withoutResetSingleStudentAlert) {
				resetStudentByID(student.id);

				return;
			}

			setResetSingleStudent(student);
		},
		[resetStudentByID, withoutResetSingleStudentAlert],
	);

	const closeResetSingleStudentAlert = useCallback(() => {
		setResetSingleStudent(null);
	}, []);

	const addStudentAlertData = useAddStudentAlertData({
		allStudents,
		activeStudents,
		currentSpecialistGroupID: isNull(currentSpecialistGroup) ? null : currentSpecialistGroup.id,
	});

	return (
		<>
			<Drawer.ContentBlock title='Students List' withDivider>
				<ContentBox>
					<AddStudentsBox withClearAllButton={Boolean(activeStudents.length)}>
						<AddStudentButton color='secondary' onClick={openAddStudentsAlert} disabled={!isNull(errorMessage)}>
							<Plus />
							<Text size='medium' bold>
								Add Students
							</Text>
						</AddStudentButton>

						{Boolean(activeStudents.length) && (
							<Button.Icon withBackgroundHover onClick={openResetAllStudentsAlert}>
								<ClearAll />
							</Button.Icon>
						)}
					</AddStudentsBox>

					<ErrorMessageBox message={errorMessage} />

					{Boolean(activeStudents.length) && (
						<StudentsListBox>
							{activeStudents.map((student) => (
								<StudentItem key={student.id}>
									<OneLinedText size='medium' color='base'>
										{getFullName({
											firstName: student.firstName,
											lastName: student.lastName,
										})}
									</OneLinedText>
									<Button.Icon withBackgroundHover onClick={() => handleResetSingleStudent(student)}>
										<Close />
									</Button.Icon>
								</StudentItem>
							))}
						</StudentsListBox>
					)}
				</ContentBox>
			</Drawer.ContentBlock>

			{showAddStudentsAlert && isNull(errorMessage) && (
				<AddStudentsAlert
					onClose={closeAddStudentsAlert}
					columnDefs={addStudentAlertData.colDefs}
					selectedItemsIDs={addStudentAlertData.selectedItemsIDs}
					onAdd={onAddStudents}
					rowsData={addStudentAlertData.rowsData}
					disabledRowsAsSelectedIDs={addStudentAlertData.disabledRowsAsSelectedIDs}
					entity={addStudentsAlertEntity}
					skeleton={addStudentAlertData.skeleton}
				/>
			)}

			{showResetAllStudentsAlert && (
				<ResetAllStudentsAlert
					onClose={closeResetAllStudentsAlert}
					onRemove={resetAllStudents}
					specialistGroupName={currentSpecialistGroup?.name}
				/>
			)}

			{!isNull(resetSingleStudent) && (
				<ResetSingleStudentAlert
					onClose={closeResetSingleStudentAlert}
					onRemove={() => resetStudentByID(resetSingleStudent.id)}
					firstName={resetSingleStudent.firstName}
					lastName={resetSingleStudent.lastName}
					checkboxValue={withoutResetSingleStudentAlert}
					setCheckboxValue={setWithoutResetSingleStudentAlert}
				/>
			)}
		</>
	);
}
