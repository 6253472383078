import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {Button, Hide, Tooltip} from '@esgi/ui';
import {Checkbox as CheckboxUI} from '@esgi/ui/controls';
import {OneLinedText} from '@esgi/main/kits/common';

export const Container = styled(GridBox, {
	backgroundColor: '$vivid',
	borderRadius: '12px',

	variants: {
		selected: {
			true: {
				backgroundColor: '$primarySurface',
			},
		},
	},
});

export const Header = styled(GridBox, {
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	borderBottom: '1px solid $border',
	padding: '13px',
	gap: '$3',
});

export const InfoWrapper = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	gap: '$2',
});

export const Content = styled(GridBox, {
	gridAutoFlow: 'column',
	gridTemplateColumns: '272px 1fr',
	padding: '12px',
	alignItems: 'center',
	gap: '$3',
	variants: {
		gridView: {
			true: {
				gridTemplateColumns: '216px 1fr',
			},
		},
	},
});

export const StyledButton = styled(Button.Icon, {
	padding: '4px',
	border: '1px solid $border',
	borderRadius: '8px',
	width: '36px',
	height: '36px',

	variants: {
		checked: {
			true: {
				'&:not(:hover)': {
					backgroundColor: '$primaryBackground',
					border: '1px solid $primaryHighlight',
					'& svg': {
						'& path': {
							fill: '$primary',
						},
					},
				},
			},
		},
	},
});

export const StyledImage = styled('img', {
	width: '100%',
	height: '100px',
	borderRadius: '6px',
	cursor: 'pointer',
});

export const ImageWrapper = styled(GridBox, {
	padding: '10px 24px',
});

export const Footer = styled(GridBox, {
	gridAutoFlow: 'column',
	padding: 12,
	borderTop: '1px solid $border',
	gap: '$5',

	variants: {
		isSmallScreen: {
			true: {
				gap: '$9',
				justifyContent: 'start',
			},
		},
	},
});

export const Divider = styled(Box, {
	width: 1.5,
	height: '100%',
	backgroundColor: '$border',
});

export const Title = styled(OneLinedText, {
	cursor: 'pointer',
	height: '22px',
	'&:hover': {
		color: '$secondary',
	},
});

export const Wrapper = styled(GridBox, {
	gap: '$4',
	gridTemplateRows: '24px repeat(2, auto)',
});

export const Checkbox = styled(CheckboxUI, {
	padding: 4,
});

export const IconWrapper = styled(FlexBox, {
	border: '2px solid $highContrast',
	borderRadius: '50%',
	overflow: 'hidden',

	'& > svg': {
		width: '18px',
		height: '18px',
		fill: '$highContrast',
		transform: 'scale(1.6) translateY(2px) translateX(1px)',

		'& > path': {
			fill: '$highContrast',
		},
	},
});

export const TooltipContent = styled(Tooltip.Content, {
	width: 'var(--radix-tooltip-trigger-width)',
	wordBreak: 'break-word',
});

export const HiddenIcon = styled(Hide, {
	width: '24px',
	height: '24px',

	'& path': {
		fill: '$mediumContrast',
	},
});