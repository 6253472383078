import {AdaptedTestCard} from '../../../../types';
import {TestedOption} from './types';
import {testedOptionItems} from './constants';
import {Text} from '@esgi/ui/typography';
import {TestPanel} from '@esgi/main/kits/test-results-panel';

export function TestedOptionFilter() {
	const {activeItem, setActiveItem} = TestPanel.Filters.useFilterGroup<AdaptedTestCard, TestedOption>({
		defaultItem: TestedOption.Any,
		items: testedOptionItems.map(({value}) => value),
		filterCb: ({activeItem, testCard: {analyticsData}}) =>
			activeItem === TestedOption.Any ||
			(activeItem === TestedOption.Yes && Boolean(analyticsData?.sessionCount)) ||
			(activeItem === TestedOption.No && !analyticsData?.sessionCount),
	});

	return (
		<TestPanel.Select.Root value={activeItem} onValueChange={setActiveItem}>
			<TestPanel.Select.Field placeholder='Tested' />
			<TestPanel.Select.Content>
				{testedOptionItems.map(({label, value}) => (
					<TestPanel.Select.Option value={value} key={value} displayDefaultLabel={value === TestedOption.Any}>
						<Text size='medium' bold>
							{label}
						</Text>
					</TestPanel.Select.Option>
				))}
			</TestPanel.Select.Content>
		</TestPanel.Select.Root>
	);
}
