export enum LoadingState {
	None,
	Loading,
	Loaded,
}

export type BaseEntity = {
	id: number;
}

export type State<T extends BaseEntity> = {
	data: T[],
	loadingState: LoadingState,
	loaded: boolean,
}