import React from 'react';
import {countPerPage} from '../../../../model';
import {SkeletonRow} from '../../index.styled';

const SkeletonRowArray = new Array(countPerPage).fill('');

export function Skeleton() {
	return (
		<>
			{SkeletonRowArray.map((_, index) => <SkeletonRow key={index}/>)}
		</>
	);
}