import {SubjectLevel} from './types';

export const subjectPrefixByLevel: Record<SubjectLevel, string> = {
	[SubjectLevel.District]: 'D',
	[SubjectLevel.School]: 'S',
	[SubjectLevel.Teacher]: 'P',
};

export const subjectsDescriptionByLevel: Record<SubjectLevel, string> = {
	[SubjectLevel.District]: 'District Subjects',
	[SubjectLevel.School]: 'School Subjects',
	[SubjectLevel.Teacher]: 'Personal Subjects',
};

export const stockSubjectPrefix = 'S';
