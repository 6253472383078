import {Tooltip} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const TooltipContent = styled(Tooltip.Content, {
	padding: '20px 16px 14px 16px !important',
	borderRadius: '12px !important',
	backgroundColor: '$surface !important',
	userSelect: 'none',
	maxWidth: 316,
});

export const ChartInfoContainer = styled(GridBox, {
	columnGap: 6,
	rowGap: '$3',
	gridTemplateColumns: '70px 1fr 59px',

	'> [data-avg-name]': {
		position: 'relative',
		top: 35,
		textAlign: 'right',
	},

	'> [data-result]': {
		position: 'relative',
		top: 50,
	},
});

export const ChartTitle = styled(Text, {
	gridColumn: '1 / -1',
	textAlign: 'center',
});
