import {ComponentPropsWithoutRef, ForwardedRef, forwardRef, useMemo} from 'react';
import {ToggleGroup} from '@esgi/ui/controls';
import {TabsContext, TabsContextValue} from '../context';

type Props = Omit<ComponentPropsWithoutRef<typeof ToggleGroup.Root>, 'skeleton'>;

function RootInner(
	{dataCy = 'test-panel-tabs-root', css = {}, value, ...props}: Props,
	forwardedRef: ForwardedRef<HTMLDivElement>,
) {
	const context = useMemo<TabsContextValue>(() => ({activeValue: value}), [value]);

	return (
		<TabsContext.Provider value={context}>
			<ToggleGroup.Root dataCy={dataCy} css={css} value={value} ref={forwardedRef} {...props} />
		</TabsContext.Provider>
	);
}

export const Root = forwardRef(RootInner);
