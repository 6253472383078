import {ComponentProps, forwardRef, useRef} from 'react';
import {Box} from '@esgi/ui/layout';
import {useComposedRefs} from '@esgi/ui';
import useResizeObserver from '@react-hook/resize-observer';
import {useCollapsiblePanelContext} from '../contexts/inner-context';

type Props = ComponentProps<typeof Box>;

export const QuickPanel = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'collapsible-panel-quick-panel', css = {}, children, ...props}, forwardedRef) => {
		const {setQuickPanelHeight} = useCollapsiblePanelContext();

		const innerRef = useRef<HTMLDivElement>(null);
		const refs = useComposedRefs(innerRef, forwardedRef);

		useResizeObserver(innerRef, ({contentRect}) => setQuickPanelHeight(contentRect.height));

		return (
			<Box dataCy={dataCy} css={css} ref={refs} {...props}>
				{children}
			</Box>
		);
	},
);
