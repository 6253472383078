import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {SubjectPanel} from '@esgi/main/features/admins/subject-management';
import {panelsPaddingBottom} from '../../constants';

export const Container = styled(GridBox, {
	height: '100%',
	gap: 20,
	gridAutoFlow: 'row',
	alignContent: 'start',
	paddingTop: 24,
	paddingBottom: panelsPaddingBottom,
	paddingInline: 20,
	overflow: 'hidden',
	boxShadow: '0px 0px 8px 0px rgba(222, 227, 247, 0.40), 0px 0px 10px 0px rgba(0, 0, 0, 0.06)',
});

export const TitleContainer = styled(SubjectPanel.SectionTitle.Root, {
	display: 'grid',

	variants: {
		isRearrangeMode: {
			true: {
				gridTemplateColumns: 'auto 1fr auto auto',
			},

			false: {
				gridTemplateColumns: 'auto 1fr auto',
			},
		},
	},
});
