import {useMemo} from 'react';
import {unique} from 'underscore';

import {useSchools, useTeachers, useGroups, useClasses} from '@esgi/main/libs/admin-store';
import {useDisposable, useEndpointResult} from '@esgi/core/service';
import {V2DistrictAdminsPagesDataAnalyticsController} from '@esgi/contracts/esgi';
import {RowDef} from './types';

export function useData() {
	const [{data: allSchools}] = useSchools();
	const [{data: allTeachers}] = useTeachers();
	const [{data: groups, loaded: groupsLoaded}] = useGroups();
	const [{data: allClasses}] = useClasses();

	const analyticController = useDisposable(V2DistrictAdminsPagesDataAnalyticsController);
	const analyticsData = useEndpointResult(() => {
		if(groupsLoaded) {
			return analyticController.groups({groupIDs: groups.map(g => g.id)});
		}
	}, [groups, groupsLoaded]);

	const {rows, schools, classes, teachers} = useMemo(() => {
		const rows = groups.map((group) => {
			const teacher = allTeachers.find(t => t.id === group.teacherID);
			const school = allSchools.find(s => s.id === group.schoolID);
			const classEntity = allClasses.find(c => c.id === group.classID);
			const analytic = analyticsData?.value?.groupsAnalytics?.find(a => a.groupID === group.id);

			return {
				id: group.id,
				name: group.name,
				school,
				teacher,
				class: classEntity,
				students: analytic?.studentsCount,
			} as RowDef;
		});
		return {
			rows,
			classes: unique(rows.map(r => r.class)).filter(Boolean),
			schools: unique(rows.map(r => r.school)).filter(Boolean),
			teachers: unique(rows.map(r => r.teacher)).filter(Boolean),
		};
	}, [groups, allSchools, allTeachers, allClasses, analyticsData]);

	return {
		ready: groupsLoaded,
		rows,
		schools,
		teachers,
		classes,
	};
}