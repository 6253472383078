import {isEqual} from 'underscore';
import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {createSystemSettingsForm} from './form';
import {SettingsData} from '../../../../../types';

export class SystemSettingsService extends BaseService {
	public form = createSystemSettingsForm();

	private readonly initData = new BehaviorSubject<SettingsData>(null);

	public init(data: SettingsData) {
		this.initData.next(data);
		this.form.value = {
			sortBy: [data?.sortBy],
			timeZone: [data?.selectedTimeZone.id],
		};
	}

	public compareStatesForEquality() {
		const {sortBy, selectedTimeZone} = this.initData.value;

		const initialState = {
			sortBy,
			timeZone: selectedTimeZone.id,
		};

		const currentState = {
			sortBy: this.form.controls.sortBy.value[0],
			timeZone: this.form.controls.timeZone.value[0],
		};

		return !isEqual(currentState, initialState);
	}
}
