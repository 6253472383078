import {Skeletonable} from '@esgi/ui';
import {ByAllStudentsTest} from '../../../../types/test-panel';
import {CardsContainer} from '../cards-container.styled';
import {TestCard} from './test-card';
import {SkeletonShape} from '@esgi/main/kits/common';
import {EmptyContent} from '../../../empty-content';
import {EmptyContentContainer} from './index.styled';

type Props = Skeletonable & {
	testsCards: ByAllStudentsTest[];
	showInColor: boolean;
	allStudentsCount: number;
};

export function ByAllStudents({testsCards, showInColor, allStudentsCount, skeleton}: Props) {
	if (skeleton) {
		return (
			<CardsContainer>
				{new Array(20).fill(20).map((_, index) => (
					<SkeletonShape width='unset' height={185} key={index} />
				))}
			</CardsContainer>
		);
	}

	if (!testsCards.length) {
		return (
			<EmptyContentContainer>
				<EmptyContent message='There is no student assessment data for this filter' />
			</EmptyContentContainer>
		);
	}

	return (
		<CardsContainer>
			{testsCards.map(({id, maxScore, name, type, result, color, contentArea}) => (
				<TestCard
					showInColor={showInColor}
					allStudentsCount={allStudentsCount}
					color={color}
					contentArea={contentArea}
					type={type}
					name={name}
					totalPossible={(result?.totalStudents ?? 1) * maxScore}
					result={result}
					key={id}
				/>
			))}
		</CardsContainer>
	);
}
