import {SubjectModel, Entities, SubjectPanel} from '@esgi/main/kits/subject-selection-panel';
import {Container, SubjectsTabsBar} from './index.styled';
import {Subject} from '@esgi/ui/icons';
import {Dispatch} from 'react';

type Props = {
	isSubjectsLoaded: boolean;
	selectedSubjectID: SubjectModel['id'] | null;
	subjectsEntities: Entities;
	setSelectedSubjectID: Dispatch<number | null>;
};

export function SubjectsPanel({
	selectedSubjectID,
	isSubjectsLoaded,
	subjectsEntities,
	setSelectedSubjectID,
}: Props) {
	return (
		<Container>
			<SubjectPanel.Root
				entities={subjectsEntities}
				skeleton={!isSubjectsLoaded}
				selectedSubjectID={selectedSubjectID}
				onSelectedSubjectIDChanged={setSelectedSubjectID}
			>
				<SubjectPanel.SectionTitle.Root>
					<SubjectPanel.SectionTitle.Icon fill='mediumContrast' Component={Subject} />
					<SubjectPanel.SectionTitle.Title size='small' color='mediumContrast'>
						Subject Tabs
					</SubjectPanel.SectionTitle.Title>
				</SubjectPanel.SectionTitle.Root>

				<SubjectsTabsBar />

				<SubjectPanel.Tabs.Content.All />
				<SubjectPanel.Tabs.Content.District />
				<SubjectPanel.Tabs.Content.School />
				<SubjectPanel.Tabs.Content.Teacher />
			</SubjectPanel.Root>
		</Container>
	);
}
