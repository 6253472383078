import {useMemo} from 'react';

import {
	useSchools,
	useTeachers,
	usePreAssessSpecialists,
	useClasses,
	useGroups,
	useSchoolSpecialists,
	useDistrictSpecialists,
	useSpecialistGroups,
	useStudents,
} from '@esgi/main/libs/admin-store';

import {FiltersData, RowDef} from './types';
import {unique} from 'underscore';

export function useData(): {ready: boolean; rows: RowDef[]} & FiltersData {
	const [{data: allSchools, loaded: allSchoolsLoaded}] = useSchools();
	const [{data: allTeachers, loaded: allTeachersLoaded}] = useTeachers();
	const [{data: allPreAssess, loaded: allPreAssessLoaded}] = usePreAssessSpecialists();
	const [{data: allClasses, loaded: allClassesLoaded}] = useClasses();
	const [{data: allGroups, loaded: allGroupsLoaded}] = useGroups();
	const [{data: schoolSpecialists, loaded: schoolSpecialistsLoaded}] = useSchoolSpecialists();
	const [{data: districtSpecialists, loaded: districtSpecialistsLoaded}] = useDistrictSpecialists();
	const [{data: allSpecialistGroups, loaded: allSpecialistGroupsLoaded}] = useSpecialistGroups();
	const [{data: students, loaded: studentsLoaded}] = useStudents();

	const mappedData = useMemo(() => {
		const allSpecialists = [...schoolSpecialists, ...districtSpecialists, ...allPreAssess];
		const rows = students.map((student) => {
			const school = allSchools.find((school) => school.id === student.schoolID);
			const teacher = allTeachers.find((teacher) => teacher.id === student.teacherID);
			const classes = allClasses.filter((c) => student.classesIDs.includes(c.id));
			const groups = allGroups.filter((group) => student.groupsIDs.includes(group.id));

			const specialists = allSpecialists.filter((specialist) => student.specialistsIDs.includes(specialist.id));
			const specialistGroups = allSpecialistGroups.filter((group) => student.specialistGroupsIDs.includes(group.id));

			const rowDef: RowDef = {
				id: student.id,
				firstName: student.firstName,
				lastName: student.lastName,
				school: school ?? null,
				teacher: teacher ?? null,
				class: classes,
				group: groups,
				specialist: specialists,
				specialistGroups: specialistGroups,
				gradeLevelID: student.gradeLevelID,
			};

			return rowDef;
		});

		return {
			rows,
			availableSchools: unique(rows.map((r) => r.school)).filter((item) => !!item),
			availableTeachers: unique(rows.map((r) => r.teacher)).filter((item) => !!item),
			availableClasses: unique(rows.map((r) => r.class).flat()).filter((item) => !!item),
			availableGroups: unique(rows.map((r) => r.group).flat()).filter((item) => !!item),
			availableSpecialists: unique(rows.map((r) => r.specialist).flat()).filter((item) => !!item),
			availableSpecialistGroups: unique(rows.map((r) => r.specialistGroups).flat()).filter((item) => !!item),
		};
	}, [
		schoolSpecialists,
		districtSpecialists,
		allPreAssess,
		students,
		allSchools,
		allTeachers,
		allClasses,
		allGroups,
		allSpecialistGroups,
	]);

	return {
		ready:
			allSchoolsLoaded &&
			allTeachersLoaded &&
			allPreAssessLoaded &&
			allClassesLoaded &&
			allGroupsLoaded &&
			schoolSpecialistsLoaded &&
			districtSpecialistsLoaded &&
			allSpecialistGroupsLoaded &&
			studentsLoaded,
		...mappedData,
	};
}
