import {BaseComponentProps, isUndefined, Skeletonable} from '@esgi/ui';
import {
	Children,
	ComponentPropsWithoutRef,
	forwardRef,
	isValidElement,
	MouseEvent,
	useCallback,
	useMemo,
	useState,
} from 'react';
import {TestContentArea, TestType} from '@esgi/main/kits/common';
import {TestCardContext, TestCardContextValue} from '../../context';
import {Container, TestCardFullIndicator} from './index.styled';
import {TestIndicator} from '../test-indicator';

type Props = BaseComponentProps &
	Skeletonable &
	ComponentPropsWithoutRef<'div'> & {
		contentArea: TestContentArea;
		testColor: string;
		testType: TestType;
		isCardClicable?: boolean;
	};

export const Root = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'test-card-root',
			css = {},
			skeleton,
			contentArea,
			testColor,
			testType,
			onMouseEnter,
			onMouseLeave,
			onClick,
			isCardClicable,
			children,
			...props
		},
		forwardedRef,
	) => {
		const [isCardHovered, setIsCardHovered] = useState(false);

		const handleMouseEnter = useCallback(
			(event: MouseEvent<HTMLDivElement>) => {
				setIsCardHovered(true);

				onMouseEnter?.(event);
			},
			[onMouseEnter],
		);

		const handleMouseLeave = useCallback(
			(event: MouseEvent<HTMLDivElement>) => {
				setIsCardHovered(false);

				onMouseLeave?.(event);
			},
			[onMouseLeave],
		);

		const context = useMemo<TestCardContextValue>(
			() => ({
				contentArea,
				testColor,
				testType,
				isCardHovered,
			}),
			[contentArea, testColor, testType, isCardHovered],
		);

		return (
			<TestCardContext.Provider value={context}>
				<Container
					dataCy={dataCy}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					onClick={onClick}
					isCardClicable={!isUndefined(isCardClicable) ? isCardClicable : !isUndefined(onClick)}
					ref={forwardedRef}
					{...props}
				>
					{!skeleton &&
						Children.map(children, (child) => {
							if (isValidElement(child)) {
								const childType = child.type;

								if (
									typeof childType !== 'string' &&
									'displayName' in childType &&
									childType.displayName === TestIndicator.displayName
								) {
									return (
										<TestCardFullIndicator css={{gridRowEnd: Children.count(children)}}>{child}</TestCardFullIndicator>
									);
								}
							}

							return child;
						})}
				</Container>
			</TestCardContext.Provider>
		);
	},
);
