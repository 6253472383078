import {styled} from '@esgi/ui/theme';
import {TestsPanel} from '@esgi/main/features/admins/subject-management';
import {Button} from '@esgi/ui';

export const TitleContainer = styled(TestsPanel.SectionTitle.Root, {
	display: 'flex',
	justifyContent: 'space-between',
});

export const AddTestsButton = styled(Button, {
	width: '100%',
});
