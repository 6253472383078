import {useLocation} from 'react-router-dom';
import {Text} from '@esgi/ui/typography';
import {Search} from '@esgi/ui/icons';
import {Container, ContentBox} from './index.styled';

export default function () {
	const {pathname} = useLocation();

	return (
		<Container>
			<ContentBox>
				<Search />
				<Text size='xxLarge'>Not found</Text>
				<Text size='small'>Page {pathname} not exist.</Text>
			</ContentBox>
		</Container>
	);
}
