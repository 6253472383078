import {ColDef} from 'ag-grid-community';
import {HeaderCellRenderer} from './components/header-cell-renderer';
import {CellCheckbox} from '../cell-checkbox';
import {AgGridReactProps} from 'ag-grid-react';

export function selectableColDef({
	cellHorizontalPadding,
	withoutFirstColumnPadding,
	withoutLastColumnPadding,
	rowSelection,
}: {
	cellHorizontalPadding: number;
	withoutFirstColumnPadding: boolean;
	withoutLastColumnPadding: boolean;
	rowSelection: AgGridReactProps['rowSelection'];
}): ColDef<any> {
	return {
		width: (() => {
			let colWidth = 24 + 2 * cellHorizontalPadding;

			if (withoutFirstColumnPadding) {
				colWidth -= cellHorizontalPadding;
			}

			if (withoutLastColumnPadding) {
				colWidth -= cellHorizontalPadding;
			}

			return colWidth;
		})(),
		sortable: false,
		resizable: false,
		cellRenderer: CellCheckbox,
		headerComponent: rowSelection === 'multiple' ? HeaderCellRenderer : undefined,
	};
}
