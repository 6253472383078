import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef, Ref, useCallback, useImperativeHandle, useRef, useState} from 'react';
import {OverlayScrollbarsComponent, OverlayScrollbarsComponentRef} from 'overlayscrollbars-react';
import {Container} from './index.styled';
import {PageBodyManagerRef} from './types';

type Props = ComponentPropsWithoutRef<'div'> &
	BaseComponentProps & {
		managerRef?: Ref<PageBodyManagerRef | undefined>;
	};

export const PageBody = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'district-admin-dashboard-page-body', css = {}, children, managerRef, ...props}, forwardedRef) => {
		const [isOSRefInitialized, setIsOSRefInitialized] = useState(false);

		const osRef = useRef<OverlayScrollbarsComponentRef<'div'>>(null);

		const goToBottom = useCallback(
			(top: number) => {
				const osInstance = osRef.current?.osInstance();

				if (isOSRefInitialized && osInstance) {
					const {scrollOffsetElement} = osInstance.elements();

					scrollOffsetElement.scrollTo({
						behavior: 'smooth',
						top,
					});
				}
			},
			[isOSRefInitialized],
		);

		useImperativeHandle(
			managerRef,
			() => ({
				goToBottom,
			}),
			[goToBottom],
		);

		return (
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {
						autoHide: 'leave',
					},
				}}
				ref={osRef}
				events={{
					initialized: () => {
						setIsOSRefInitialized(true);
					},
				}}
				style={{height: 'calc(100% + 0px)'}}
			>
				<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
					{children}
				</Container>
			</OverlayScrollbarsComponent>
		);
	},
);
