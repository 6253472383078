import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback} from 'react';
import {isUndefined} from 'underscore';

type Props = ComponentPropsWithoutRef<typeof Button>;

export const StartTestButton = forwardRef<HTMLButtonElement, Props>(
	(
		{dataCy = 'test-card-start-test-button', css = {}, onClick, disabled, children, uppercase = true, ...props},
		forwardedRef,
	) => {
		const handleClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				event.stopPropagation();
				event.preventDefault();

				onClick?.(event);
			},
			[onClick],
		);

		return (
			<Button
				dataCy={dataCy}
				css={css}
				onClick={handleClick}
				disabled={disabled}
				uppercase={uppercase}
				ref={forwardedRef}
				{...props}
			>
				{isUndefined(children) ? (
					<Text size='large' color='vivid'>
						Test
					</Text>
				) : (
					children
				)}
			</Button>
		);
	},
);
