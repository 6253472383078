import {useCallback, useEffect, useState} from 'react';
import {Input} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useService} from '@esgi/core/service';
import {SubjectLevel} from '@esgi/main/libs/store';
import {Tabs} from '@esgi/main/features/teacher/home';
import {AddTests, Search, useBehaviorSubject} from '@esgi/ui';
import {Drawer, ScreenSize, SubjectName, useDrawerClose, useDrawerRef, useRootPageContext} from '@esgi/main/kits/common';
import {Test} from './service/types';
import {Table} from './components/table';
import {DataService} from './service/service';
import {Pagination} from './components/pagination';
import {SubjectModel} from './types';
import {useDrawerState} from './hooks/use-drawer-state';
import {useTabItems} from './components/tabs/use-tab-items';
import {SelectionPanel} from './components/selection-panel';
import {useTestSelection} from './hooks/use-tests-selection';
import {Checkbox, CheckboxBox} from './components/checkbox.styled';
import {TestScopeTabId, TestTypeTabId} from './components/tabs/types';
import {testScopeTabItems, testTypeTabItems} from './components/tabs/constants';
import {Container, Description, Divider, StyledSelectionPanelContainer, TabsFiltersWrapper} from './index.styled';
import {isNull} from 'underscore';

interface Props {
	subject: SubjectModel | null,
	onAddTests: (list: Test[]) => void;
	onClose: VoidFunction,
	initialSelectedTestsIDs?: number[]
}

export function AddTestsDrawer({subject, onAddTests, onClose, initialSelectedTestsIDs}: Props) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, onClose);

	const service = useService(DataService);
	const tests = useBehaviorSubject(service.tests$);
	const loaded = useBehaviorSubject(service.loaded$);
	const pageIndex = useBehaviorSubject(service.pageIndex$);
	const pageCount = useBehaviorSubject(service.pageCount$);
	const testsCount = useBehaviorSubject(service.testsCount$);
	const initialized = useBehaviorSubject(service.initialized$);

	const {
		testType,
		onTestTypeChange,
		scope,
		onScopeChange,
		onlySelfAssess,
		toggleSelfAssessChecked,
		onlySelected,
		toggleOnlySelectedChecked,
		disableOnlySelected,
		keyword,
		setKeyword,
		sorting,
		setSorting,
		onNextPage,
		onPreviousPage,
	} = useDrawerState({service, subject});

	const {
		selectedTests,
		select,
		toggleAll,
		unselectAll,
	} = useTestSelection(service);

	const {screenSize} = useRootPageContext();

	const testTypeTabs = useTabItems<TestTypeTabId>(testTypeTabItems);
	const testScopeTabs = useTabItems<TestScopeTabId>(testScopeTabItems);

	const onAddSelectedTabs = useCallback(() => {
		if (isNull(subject)) {
			onAddTests(selectedTests);
			close();
			return;
		}

		service.addSelectedTabs(subject.id, subject.subjectType).subscribe({
			next: () => {
				unselectAll();
				onAddTests(selectedTests);
				close();
			},
		});
	}, [close, onAddTests, selectedTests, service, subject, unselectAll]);

	const [debouncedKeyword, setDebouncedKeyword] = useState('');

	useEffect(() => {
		if (onlySelected && selectedTests.length > 0) {
			return;
		}

		// guard to prevent case when we have 0 selected tests and onlySelected flag enabled.
		disableOnlySelected();
	}, [onlySelected, selectedTests, disableOnlySelected]);

	useEffect(() => {
		const delayKeywordTimeoutId = setTimeout(() => {
			setKeyword(debouncedKeyword);
		}, 500);

		return () => clearTimeout(delayKeywordTimeoutId);
	}, [debouncedKeyword, setKeyword]);

	useEffect(() => {
		if (isNull(subject)) {
			service.setSubjectTestIDs(initialSelectedTestsIDs ?? []);
			return;
		}

		service.getSubjectTestIDs(subject.id, subject.subjectType);
	}, []);

	return (
		<Drawer width={screenSize === ScreenSize.PortraitTablet ? '100%' : 1050} drawerRef={drawerRef} onClickOutside={close}>
			<Drawer.Header
				Icon={AddTests}
				sectionName='Add Tests'
				closeDrawer={close}
				childrenAlignment='left'
			>
				<GridBox flow='column' gap='4' align='center'>
					{subject && (
						<>
							<Divider />
							<SubjectName subjectLevel={SubjectLevel.District} size='large' noWrap>
								{subject.name}
							</SubjectName>
						</>
					)}
				</GridBox>
			</Drawer.Header>
			<Drawer.Body>
				<Container>
					<Description>
						<Text size='small' color='neutral40'>Description:</Text>
						<TabsFiltersWrapper>
							<Input.Iconable
								placeholder='Search'
								value={debouncedKeyword}
								onChange={(e) => setDebouncedKeyword(e.target.value)}
								dataCy='search-input'
							>
								<Search/>
							</Input.Iconable>
							<Tabs<TestTypeTabId>
								activeTab={testType}
								setActiveTab={onTestTypeChange}
								tabItems={testTypeTabs}
								showTitle={false}
								dataCy='test-type-toggle'
							/>
							<Tabs<TestScopeTabId>
								activeTab={scope}
								setActiveTab={onScopeChange}
								tabItems={testScopeTabs}
								showTitle={false}
								disabled={onlySelected}
								dataCy='test-scope-toggle'
							/>
							<CheckboxBox dataCy='self-assess-checkbox'>
								<Checkbox
									selected={onlySelfAssess}
									value='selfAssess'
									label='Self-Assess'
									checked={onlySelfAssess}
									onCheckedChange={toggleSelfAssessChecked}
								/>
							</CheckboxBox>
						</TabsFiltersWrapper>
						<Pagination
							pageIndex={pageIndex}
							pageCount={pageCount}
							testsCount={testsCount}
							onNextPage={onNextPage}
							onPreviousPage={onPreviousPage}
						/>
					</Description>
					<Table
						tests={tests}
						selectedTests={selectedTests}
						loaded={loaded}
						toggleAll={toggleAll}
						sorting={sorting}
						disabledSorting={onlySelected}
						setSorting={setSorting}
						onTestSelect={select}
						keyword={keyword}
					/>
				</Container>
			</Drawer.Body>
			{!!selectedTests.length && (
				<StyledSelectionPanelContainer>
					<SelectionPanel
						isLoading={!loaded}
						showOnlySelected={onlySelected}
						selectedCount={selectedTests.length}
						onClear={unselectAll}
						onAddSelection={onAddSelectedTabs}
						onShowOnlySelectedClick={toggleOnlySelectedChecked}
					/>
				</StyledSelectionPanelContainer>
			)}
		</Drawer>
	);
}