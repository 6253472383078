import {styled} from '@esgi/ui/theme';
import {Box, GridBox, SelectableList} from '@esgi/ui/layout';

export const Container = styled(GridBox, {
	height: '100%',
	gridTemplateRows: 'auto 1fr',
	gap: 20,
	overflow: 'hidden',
});

export const Divider = styled(Box, {
	width: 1.5,
	height: 20,
	backgroundColor: '$surface',
});

export const ListItem = styled(SelectableList.Item, {
	marginTop: 8,
	marginBottom: 8,

	'&:first-child': {
		marginTop: 0,
	},

	'&:last-child': {
		marginBottom: 0,
	},
});

export const Description = styled(GridBox, {
	gap: 20,
});

export const TabsFiltersWrapper = styled(GridBox, {
	gap: '$3',
	alignItems: 'center',
	gridTemplateColumns: '1fr auto auto auto',

	'& > *:last-child': {
		placeSelf: 'end',
	},
});

export const StyledSelectionPanelContainer = styled(Box, {
	position: 'absolute',
	left: 0,
	bottom: 0,
	width: '100%',
});