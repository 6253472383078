import {UserType} from '@esgi/core/authentication';
import {SpecialistType, UserTypeOption} from '@esgi/main/features/admins/data';

export const userTypeOptions: Record<SpecialistType, UserTypeOption> = {
	[SpecialistType.District]: {
		id: UserType.ISD,
		name: 'District',
	},
	[SpecialistType.School]: {
		id: UserType.ISS,
		name: 'School',
	},
	[SpecialistType.PA]: {
		id: UserType.PA,
		name: 'Pre-Assess',
	},
};
