import {PeriodType, useCurrentDatePickerValue} from '@esgi/main/kits/admin';
import {Text} from '@esgi/ui/typography';
import {ComponentProps} from 'react';
import {mapToEnum} from 'shared/utils';
import {useWidgetCardContext} from '../context';
import {WidgetPeriod} from '../../types';

type Props = Pick<WidgetPeriod, 'datePeriod' | 'dateRange' | 'trackDatesID'> & {
	textColor: NonNullable<ComponentProps<typeof Text>['color']>;
	textSize?: ComponentProps<typeof Text>['size'];
};

export function DatePeriod({datePeriod, dateRange, trackDatesID, textColor, textSize = 'small'}: Props) {
	const {currentTrack} = useWidgetCardContext();

	const selectedDateValue = useCurrentDatePickerValue({
		datePeriod: mapToEnum(datePeriod, PeriodType),
		dateRange,
		trackDatesID,
		currentTrack,
	});

	return (
		<Text size={textSize} color={textColor}>
			{selectedDateValue}
		</Text>
	);
}
