import {useUser} from '@esgi/core/authentication';
import {ReportType, SubjectInfo} from './types';

import {GradeScalesLauncher} from './launchers/grade-scales';
import {GrowthReportLauncher} from './launchers/growth-report';
import {TotalReportLauncher} from './launchers/total-report';
import {StudentsReportLauncher} from './launchers/students-report';
import ItemAnalysisLauncher from './launchers/item-analysis';
import PieChartsLauncher from './launchers/pie-charts';
import {TeacherActivityLauncher} from './launchers/teacher-activity';
import ParentLetterLauncher from './launchers/parent-letter';
import {StudentProgressLauncher} from './launchers/student-progress';
import {StudentDetailLauncher} from './launchers/student-detail-button';
import {UntestedStudentsButton} from './launchers/untested-students';
import {RubricReportLauncher} from './launchers/rubric';
import {PreAssessActivityLauncher} from './launchers/preassess-activity';
import {SpecialistActivityLauncher} from './launchers/specialist-activity';
import {Hierarchy, useHierarchyInstance} from '@esgi/main/features/admins/utils';

type Props = {
	hierarchy: Hierarchy,
	report: ReportType,
	firstSubject: SubjectInfo,
	onClose: VoidFunction,
}

export function OldReport(props: Props) {
	const {
		hierarchy,
		report,
		firstSubject,
		onClose,
	} = props;

	const user = useUser();
	const hierarchyInstance = useHierarchyInstance({hierarchy});

	switch (report) {
		case ReportType.Grade: return <GradeScalesLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.Growth: return <GrowthReportLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.TotalStudents: return <StudentsReportLauncher globalSchoolYearId={user?.globalSchoolYearID} hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.Total: return <TotalReportLauncher globalSchoolYearId={user?.globalSchoolYearID} hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.ItemAnalysis: return <ItemAnalysisLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.PieCharts: return <PieChartsLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.TeacherActivity: return <TeacherActivityLauncher teachersGroupID={hierarchy.teachersGroupID} schoolsGroupID={hierarchy.schoolsGroupID} onClose={onClose}/>;
		case ReportType.ParentLetter: return <ParentLetterLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.StudentsProgress: return <StudentProgressLauncher hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.StudentsDetail: return <StudentDetailLauncher hierarchy={hierarchyInstance} subjectId={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.UntestedStudents: return <UntestedStudentsButton hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.RubricResults: return <RubricReportLauncher hierarchy={hierarchyInstance} subjectID={firstSubject.id} subjectType={firstSubject.type as any} onClose={onClose}/>;
		case ReportType.PreAssessActivity: return <PreAssessActivityLauncher onClose={onClose}/>;
		case ReportType.SpecialistActivity: return <SpecialistActivityLauncher groupID={hierarchyInstance.specialist.groupID} onClose={onClose}/>;
	}
}