import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Header = styled(GridBox, {
	alignItems: 'center',
	gridTemplateColumns: 'auto 1fr',
	justifyContent: 'space-between',
	paddingTop: '$4',
	paddingBottom: '$4',
	paddingInline: 20,
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	borderBottomColor: '$border',
	backgroundColor: '$background',
});

export const ActionButtonsBox = styled(GridBox, {
	gap: '$4',
	alignItems: 'center',
	justifySelf: 'end',
	gridAutoFlow: 'column',
});

export const AddButton = styled(Button, {
	width: 32,
	height: 32,
	padding: 0,
	minWidth: 'unset',
	alignItems: 'center',

	'> svg': {
		margin: 'unset !important',
	},
});
