import {FolderReportModel} from '../../../../_kit';

export const folderReports: FolderReportModel[] = [
	{
		type: 'GradeScale',
		name: 'School Group Grades',
		description: 'Apply customizable grade scales to school scores',
	},
	{
		type: 'Growth',
		name: 'School Group Growth',
		description: 'View growth on tests in a Subject Tab',
	},
	{
		type: 'TotalStudents',
		name: 'School Group Students',
		description: 'Review and compare student scores for a subject tab at the District level',
	},
	{
		type: 'Totals',
		name: 'School Group Totals',
		description: 'Review and compare school scores for a subject tab',
	},
	{
		type: 'ItemAnalysis',
		name: 'Item Analysis',
		description: 'Analyze data based on least and most known items',
	},
	{
		type: 'PieCharts',
		name: 'Pie Charts',
		description: 'Access a printable report of homepage pie charts',
	},
	{
		type: 'UsageReport',
		name: 'Teacher Activity',
		description: 'View teacher usage of ESGI',
	},
];
