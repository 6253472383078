import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Form} from '@esgillc/ui-kit/form';

export const ParentLetterPage = styled(GridBox, {
	width: '100%',
	height: '100%',
	gridTemplateColumns: 'auto 1fr 400px',
	overflow: 'hidden',
});

export const LeftSidebar = styled(GridBox, {
	'div': {
		maxWidth: '256px',
	},
});

export const FormWrapper = styled(Form, {
	height: '100%',
});

export const SettingsContainer = styled(GridBox, {
	height: '100%',
	alignContent: 'space-between',
});
