import {useLocation, useNavigate} from 'react-router-dom';
import {DataBase} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {routes} from '@esgi/main/libs/core';
import {Fragment, useEffect, useState} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Divider, EntityItemsBox, PanelBox, NotVisibleItemsDivider, Title, PanelContent} from './index.styled';
import {useData} from './hooks/use-data';
import {usePanelAnimation} from './hooks/use-panel-animation';
import {ActiveEntity, ObjectEntries} from './types';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {FoldersSection} from './components/folders-section';
import {NavigationItem} from './components/navigation-item';

export function DataPanel() {
	const navigate = useNavigate();
	const {pathname} = useLocation();

	const [activeEntity, setActiveEntity] = useState<ActiveEntity | null>(null);
	const {entities, folders} = useData({
		activeEntityType: isNull(activeEntity) ? null : activeEntity.type,
	});

	const panelStyles = usePanelAnimation();

	useEffect(() => {
		if (pathname === routes.schoolAdmin.data.root) {
			navigate(routes.schoolAdmin.data.teachers);
		}
	}, [pathname]);

	useEffect(() => {
		const dataRouteEntity = (
			Object.entries(routes.schoolAdmin.data) as ObjectEntries<typeof routes.schoolAdmin.data>
		).find(([key, path]) => key !== 'root' && pathname.includes(path));

		const entity = entities.find(({type}) => type === dataRouteEntity?.[0]);

		if (isUndefined(entity)) {
			setActiveEntity(null);

			return;
		}

		setActiveEntity({
			type: entity.type,
			name: entity.name,
		});
	}, [entities, pathname]);

	return (
		<PanelBox style={panelStyles}>
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
				}}
				style={{height: '100%'}}
			>
				<PanelContent>
					<Title>
						<DataBase />
						<Text size='small' color='mediumContrast'>
							Data
						</Text>
					</Title>
					<EntityItemsBox>
						{entities.map(({type, name, count}, index) => {
							const path = routes.schoolAdmin.data[type];

							return (
								<Fragment key={type}>
									<NavigationItem
										name={name}
										count={count}
										isActive={pathname === path}
										onClick={() => navigate(path)}
									/>
									{[1, 4, 5].includes(index) && <NotVisibleItemsDivider />}
								</Fragment>
							);
						})}
					</EntityItemsBox>

					{!isNull(folders) && !isNull(activeEntity) && Boolean(folders.length) && (
						<>
							<Divider />
							<FoldersSection entityName={activeEntity.name} folders={folders} />
						</>
					)}
				</PanelContent>
			</OverlayScrollbarsComponent>
		</PanelBox>
	);
}
