import {Drawer, getFullName} from '@esgi/main/kits/common';
import {FormElementSelect} from '../../../../components/form-element-select';
import {User} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';
import {FormSelectItem} from '../../../../components/form-element-select/types';
import {classNameErrorItems, teacherSelectErrorItems} from './constants';
import {FormElement, IFormControl} from '@esgi/ui/form';
import {ErrorTooltip} from '../../../../components/error-tooltip';
import {Input as FormInput} from '@esgi/ui/form-controls';

type Props = {
	teachers: User[];
	primaryTeacherIDControl: IFormControl<string[]>;
	classNameControl: IFormControl<string>;
};

export function ClassInformationTabContent({
	teachers,
	classNameControl,
	primaryTeacherIDControl,
}: Props) {
	const teachersSelectItem = useMemo(
		() =>
			teachers.map<FormSelectItem<string>>(
				({id, firstName, lastName}) => ({
					value: String(id),
					label: getFullName({firstName, lastName}),
				})
			),
		[teachers]
	);

	return (
		<>
			<Drawer.ContentBlock title='Primary Teacher' withDivider>
				<FormElementSelect
					placeholder='Primary Teacher'
					control={primaryTeacherIDControl}
					items={teachersSelectItem}
					errorItems={teacherSelectErrorItems}
				/>
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Class Information' withDivider>
				<FormElement control={classNameControl}>
					<FormInput.CleanableIndicator placeholder='Class Name' />

					{classNameErrorItems.map(({showOnError, message}) => (
						<ErrorTooltip
							showOnError={showOnError}
							message={message}
							key={showOnError}
						/>
					))}
				</FormElement>
			</Drawer.ContentBlock>
		</>
	);
}
