import {isNull} from 'underscore';
import {OnSchoolYearChanged} from './types';
import {Select} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {useInitData} from './hooks/use-init-data';
import {useSwitchSchoolYear} from './hooks/use-switch-school-year';
import {useState} from 'react';
import {SchoolYearLabelBox, SelectField} from './index.styled';

type Props = {
	dataCy?: string;
	onSchoolYearChanged?: OnSchoolYearChanged;
};

export function SchoolYearSelect({dataCy = 'school-years', onSchoolYearChanged}: Props) {
	const [isSelectOpen, setIsSelectOpen] = useState(false);
	const [activeSchoolYearID, setActiveSchoolYearID] = useState<number | null>(null);

	const {loading: isInitDataLoading, schoolYears, currentGlobalSchoolYearID} = useInitData({setActiveSchoolYearID});
	const {isSchoolYearSwitching, handleSchoolYearChange} = useSwitchSchoolYear({
		activeSchoolYearID,
		setActiveSchoolYearID,
		onSchoolYearChanged,
		schoolYears,
	});

	const isSelectedCurrentGlobalSchoolYearID = activeSchoolYearID === currentGlobalSchoolYearID;
	const isSchoolYearLabelVisible = !isNull(activeSchoolYearID) && !isNull(currentGlobalSchoolYearID) && !isSelectOpen;

	return (
		<Select.Root
			dataCy={dataCy}
			onValueChange={handleSchoolYearChange}
			value={isNull(activeSchoolYearID) ? undefined : String(activeSchoolYearID)}
			open={isSelectOpen}
			onOpenChange={setIsSelectOpen}
		>
			<SelectField placeholder='Year' skeleton={isInitDataLoading || isSchoolYearSwitching}>
				{isSchoolYearLabelVisible && (
					<SchoolYearLabelBox variant={isSelectedCurrentGlobalSchoolYearID ? 'primary' : 'tertiary'}>
						<Text size='xSmall' font='mono' bold color='currentColor'>
							{isSelectedCurrentGlobalSchoolYearID ? 'Current' : 'Past'}
						</Text>
					</SchoolYearLabelBox>
				)}
			</SelectField>
			<Select.Content>
				{schoolYears.map(({globalSchoolYearID, shortName}) => (
					<Select.Option value={String(globalSchoolYearID)} key={globalSchoolYearID}>
						<Text data-cy='year' size='medium' bold>
							{shortName}
						</Text>
					</Select.Option>
				))}
			</Select.Content>
		</Select.Root>
	);
}
