import {useMemo} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {useUser} from '@esgi/core/authentication';
import {isNull} from 'underscore';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {TestType} from '@esgi/main/kits/common';

type Props = {
	correctAnswers: number | null;
	result: number | null;
	resultDelta: number | null;
	totalPossible: number;
	testType: Exclude<TestType, TestType.Rubric>;
};

export function LastSessionBar({correctAnswers, resultDelta, testType, totalPossible, result}: Props) {
	const currentUser = useUser();

	const answersInfoCaption = useMemo<string | null>(() => {
		if (isNull(currentUser)) {
			return null;
		}

		return testType === TestType.YN ? `${currentUser.testResultsCorrectVerbiage} answers` : 'Last Score';
	}, [currentUser, testType]);

	return (
		<TestCard.Statistic.Charts.ProgressWithPointer
			percentageType
			value={result}
			maxValue={100}
			progressBarTitle='Last Session Result'
		>
			<GridBox gapY='1'>
				<TestCard.AnswersInfo value={correctAnswers} maxValue={totalPossible} transcriptText={answersInfoCaption} />
				<TestCard.Statistic.Delta value={resultDelta} percentageType deltaTranscript='Since Last Test' />
			</GridBox>
		</TestCard.Statistic.Charts.ProgressWithPointer>
	);
}
