import {createContext, Dispatch, useContext} from 'react';
import {isNull} from 'underscore';

export type CollapsiblePanelContextValue = {
	setQuickPanelHeight: Dispatch<number>;
	isPanelExpanded: boolean;
};

export const CollapsiblePanelContext = createContext<CollapsiblePanelContextValue | null>(null);

export function useCollapsiblePanelContext() {
	const context = useContext(CollapsiblePanelContext);

	if (isNull(context)) {
		throw new Error('useCollapsiblePanelContext is null');
	}

	return context;
}
