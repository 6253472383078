import {createContext, Dispatch, useContext} from 'react';
import {isNull} from 'underscore';
import {Entities} from './types/section';
import {SubjectModel} from './types/subject';

export type SubjectPanelContextValue = {
	entities: Entities;
	selectedSubjectID: SubjectModel['id'] | null;
	setSelectedSubjectID: Dispatch<number | null>;
	showSubjectsCheap: boolean;
	skeleton: boolean;
};

export const SubjectPanelContext = createContext<SubjectPanelContextValue | null>(null);

export function useSubjectPanelContext() {
	const context = useContext(SubjectPanelContext);

	if (isNull(context)) {
		throw new Error('useSubjectPanelContext is null');
	}

	return context;
}
