import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	onDeleteWidget: VoidFunction;
	onCloseAlert: VoidFunction;
};

export function DeleteWidgetAlert({onCloseAlert, onDeleteWidget}: Props) {
	const alertRef = Alert.useRef();

	const handleCloseAlert = Alert.useClose(alertRef, onCloseAlert);

	const handleDeleteAndCloseWidgetAlert = () => {
		onDeleteWidget();
		handleCloseAlert();
	};

	return (
		<Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative}>
			<Alert.Header onCloseIconClick={handleCloseAlert}>
				<Text size='large' color='negative'>
					Delete widget?
				</Text>
			</Alert.Header>
			<Alert.Body>
				<GridBox>
					<Text size='medium' color='highContrast'>
						Are you sure you want to delete this widget?
					</Text>
					<Text size='medium' color='highContrast'>
						This action cannot be undone.
					</Text>
				</GridBox>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={handleDeleteAndCloseWidgetAlert}>
						<Text size='medium' bold color='base'>
							Confirm
						</Text>
					</Button>
					<Button color='secondary' onClick={handleCloseAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
