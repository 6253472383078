import {SchoolStudentsNeedingSupportWidgetOptions, SchoolStudentsNeedingSupportWidgetViewTypeNumerable} from '../types';
import {isNull} from 'underscore';
import {ColorNumerable, DatePeriod, PeriodTypeNumerable} from '@esgi/main/features/admins/dashboard';

export function getSchoolStudentsNeedingSupportInitOptions({
	districtID,
	schoolID,
	globalSchoolYearID,
	dateRange,
}: {
	districtID: number;
	schoolID: number;
	globalSchoolYearID: number;
	dateRange: DatePeriod | null;
}): SchoolStudentsNeedingSupportWidgetOptions {
	return {
		districtID,
		schoolID,
		globalSchoolYearID,
		districtCutoff: 25,
		viewType: SchoolStudentsNeedingSupportWidgetViewTypeNumerable.Students,
		periods: [
			{
				color: ColorNumerable.None,
				datePeriod: isNull(dateRange) ? PeriodTypeNumerable.CurrentMarkingPeriod : PeriodTypeNumerable.Manual,
				dateRange: dateRange as unknown as DatePeriod,
				trackDatesID: 0,
			},
		],
		classIDs: [],
		teachersIDs: [],
		contentAreaIDs: [],
		subjectIDs: [],
		gradeLevelIDs: [],
	};
}
