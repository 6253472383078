import {BehaviorSubject, catchError, map, of, throwError} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {userStorage} from '@esgi/core/authentication';
import {ClearSpecificStoragesEvent} from '@esgi/main/libs/school-admin-store';
import {V2SchoolAdminsStorageController, V2SchoolAdminsTeacherController} from '@esgi/contracts/esgi';
import {createAddTeacherForm, teacherExportIDNValidator, teacherImportIDNValidator, teacherNameValidator} from './form';
import {dispatchAppEvent} from '@esgillc/events';
import {TeacherCreatedEvent} from './events';

export class AddTeacherService extends BaseService {
	public readonly isDataLoaded$ = new BehaviorSubject<boolean>(true);
	public readonly isBusy$ = new BehaviorSubject<boolean>(false);

	public form = createAddTeacherForm();

	private user = userStorage.get();
	private controller = new V2SchoolAdminsStorageController();
	private teacherController = new V2SchoolAdminsTeacherController();

	public init() {
		this.pushValidators();
	}

	public addTeacher() {
		this.isBusy$.next(true);
		const {title, firstName, lastName, email, accountID: userName, importID, exportID} = this.form.value;

		return this.teacherController
			.create({
				title: title[0],
				firstName,
				lastName,
				email,
				userName,
				importID,
				exportID,
			})
			.pipe(
				map(({value: {teacherID}}) => {
					this.isBusy$.next(false);

					dispatchAppEvent(
						TeacherCreatedEvent,
						new TeacherCreatedEvent({
							id: teacherID,
						}),
					);

					dispatchAppEvent(ClearSpecificStoragesEvent, new ClearSpecificStoragesEvent(['teachers']));

					return of();
				}),
				catchError((error) => {
					this.isBusy$.next(false);
					return throwError(error);
				}),
			);
	}

	public override dispose() {
		this.controller.dispose();
		this.teacherController.dispose();
	}

	private pushValidators() {
		this.form.controls.importID.validators.push(
			teacherImportIDNValidator(this.teacherController, this.user.districtID),
		);
		this.form.controls.exportID.validators.push(
			teacherExportIDNValidator(this.teacherController, this.user.districtID),
		);
		this.form.controls.accountID.validators.push(teacherNameValidator(this.teacherController));
	}
}
