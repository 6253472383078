import {isAsyncSucceed} from '@esgi/ui';
import {Page} from '../../../_kit';
import {RowDef} from '../../types';
import {ColDef} from 'ag-grid-community';
import {useEffect, useMemo} from 'react';
import {entityName} from '../constants';
import {Content} from './components/content';
import {Folder, FolderManagement, useDataPageState} from '@esgi/main/features/admins/data';

type Props = {
	allTableRows: RowDef[];
	isDataLoaded: boolean;
	columnDefs: ColDef<RowDef>[];
	activeFolder: Folder;
};

export function Folder({allTableRows, isDataLoaded, columnDefs, activeFolder}: Props) {
	const [folderData, fetchFolderByID] = FolderManagement.useFetchFolderByID({type: activeFolder.type});

	const isDataFullLoaded = isDataLoaded && isAsyncSucceed(folderData);
	const {isInitialStateReady, initialState, onApplyTableCustomization} = useDataPageState(entityName);

	useEffect(() => {
		fetchFolderByID(activeFolder.id);
	}, [activeFolder]);

	const filteredData = useMemo<RowDef[]>(() => {
		if (isDataFullLoaded) {
			const ids = folderData.data.ids;

			return allTableRows.filter(({id}) => ids.includes(id));
		}

		return [];
	}, [allTableRows, folderData, isDataFullLoaded]);

	return (
		<Page.Root
			columnDefs={columnDefs}
			entityName={entityName}
			tableRows={filteredData}
			isDataLoaded={isDataFullLoaded && isInitialStateReady}
			initialState={initialState}
			onApplyTableCustomization={onApplyTableCustomization}
		>
			<Content activeFolder={activeFolder} allFolderRows={filteredData} />
		</Page.Root>
	);
}
