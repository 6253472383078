import {PageBody} from './components/page-body';
import {pageBodyPaddingBottom, pageBodyPaddingInline} from './components/page-body/constants';
import {usePageBodyManagerRef} from './components/page-body/use-page-body-manager-ref';
import {PageHeader} from './components/page-header';
import {PageRoot} from './components/page-root';
import {useDashboardPageContext} from './context';

export const Page = Object.assign(PageRoot, {
	Header: PageHeader,
	Body: Object.assign(PageBody, {
		paddingInline: pageBodyPaddingInline,
		paddingBottom: pageBodyPaddingBottom,
		useManagerRef: usePageBodyManagerRef,
	}),
	usePageContext: useDashboardPageContext,
});

export {PageMode} from './types';
export {type RearrangeNotActiveMessage} from './components/page-header/variant/view-mode/types';
