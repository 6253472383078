import {Class, Group, Student, User} from '@esgi/main/libs/admin-store';
import {generateRandomString, isUndefined, useStreamEffect} from '@esgi/ui';
import {ElementStatus, FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {Dispatch, useState} from 'react';
import {AddGroupForm} from './types';
import {groupNameMaxLength} from '../../../components/group-name-input/constants';

type Parameters = {
	classes: Class[];
	groups: Group[];
	teachers: User[];
	setIsFormValid: Dispatch<boolean>;
	setIsFormTouched: Dispatch<boolean>;
};

export function useAddForm({setIsFormValid, setIsFormTouched, teachers, classes, groups}: Parameters) {
	const [selectedPrimaryTeacherID, setSelectedPrimaryTeacherID] = useState<User['id'] | null>(null);

	const [activePrimaryTeachersList, setActivePrimaryTeachersList] = useState<User[]>([]);
	const [primaryTeacherUniqueKey, setPrimaryTeacherUniqueKey] = useState(generateRandomString());

	const [activeClassesList, setActiveClassesList] = useState<Class[]>([]);
	const [classListUniqueKey, setClassListUniqueKey] = useState(generateRandomString());

	const form: AddGroupForm = useForm(
		() =>
			new FormGroup({
				schoolID: new FormControl<string[]>([], {validators: [Validators.required()]}),
				primaryTeacherID: new FormControl<string[]>([], {
					validators: [Validators.required()],
					initialValidateStatus: ElementStatus.disabled,
				}),
				teacherClass: new FormControl<string[]>([], {
					validators: [Validators.required()],
					initialValidateStatus: ElementStatus.disabled,
				}),
				groupName: new FormControl('', {
					initialValidateStatus: ElementStatus.disabled,
				}),
				studentsIDs: new FormControl<Student['id'][]>([]),
			}),
	);

	useStreamEffect(form.controls.schoolID.onChanged, ({currState: {value}, reason}) => {
		const schoolID = value[0];

		if (!isUndefined(schoolID) && reason === 'value') {
			const teachersInSchool = teachers.filter((teacher) => teacher.schoolID === Number(schoolID));

			form.controls.primaryTeacherID.value = [];
			setSelectedPrimaryTeacherID(null);

			form.controls.teacherClass.value = [];

			setActivePrimaryTeachersList(teachersInSchool);
			setPrimaryTeacherUniqueKey(generateRandomString());

			form.controls.primaryTeacherID.status = ElementStatus.untouched;
			form.controls.teacherClass.status = ElementStatus.disabled;
			form.controls.groupName.status = ElementStatus.disabled;

			form.validate().subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		}
	});

	useStreamEffect(form.controls.primaryTeacherID.onChanged, ({currState: {value}, reason}) => {
		const primaryTeacherID = value[0];

		if (!isUndefined(primaryTeacherID) && reason === 'value') {
			const numberedPrimaryTeacherID = Number(primaryTeacherID);

			const teacherClasses = classes.filter(({teacherID}) => teacherID === numberedPrimaryTeacherID);

			form.controls.teacherClass.value = [];
			form.controls.teacherClass.status = ElementStatus.untouched;

			setActiveClassesList(teacherClasses);
			setClassListUniqueKey(generateRandomString());

			form.controls.groupName.status = ElementStatus.disabled;

			form.validate().subscribe(({valid}) => {
				setIsFormValid(valid);
			});

			setSelectedPrimaryTeacherID(numberedPrimaryTeacherID);
		}
	});

	useStreamEffect(form.controls.teacherClass.onChanged, ({currState: {value}, reason}) => {
		const teacherClassID = value[0];

		if (!isUndefined(teacherClassID) && reason === 'value') {
			const groupsInClass = groups.filter(({classID}) => classID === Number(teacherClassID));

			form.controls.groupName.validators.length = 0;

			form.controls.groupName.validators.push(
				Validators.required(),
				Validators.length.max(groupNameMaxLength),
				Validators.isDublicateValue(groupsInClass.map(({name}) => name)),
			);

			form.controls.groupName.status = ElementStatus.untouched;

			form.validate().subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		}
	});

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {studentsIDs, groupName, primaryTeacherID, schoolID, teacherClass},
			},
		}) => {
			const isFormTouched =
				groupName !== '' ||
				!isUndefined(schoolID[0]) ||
				!isUndefined(primaryTeacherID[0]) ||
				!isUndefined(teacherClass[0]) ||
				Boolean(studentsIDs.length);

			setIsFormTouched(isFormTouched);

			form.validate(true).subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		},
	);

	return {
		form,
		primaryTeacherUniqueKey,
		activePrimaryTeachersList,
		activeClassesList,
		classListUniqueKey,
		selectedPrimaryTeacherID,
	};
}
