export type StudentRowModel = {
	[key: string]: unknown;

	id: number;
	firstName: string;
	lastName: string;
};

export enum StudentColID {
	FirstName = 'FirstName',
	LastName = 'LastName',
}
