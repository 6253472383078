import {Tooltip} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

const containerPadding = 8;
const containerColor = '$surface';
const containerBorderSize = 1;

export const Container = styled(GridBox, {
	padding: containerPadding,
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: containerBorderSize,
	borderColor: '$border',
	backgroundColor: containerColor,
	position: 'relative',

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				pointerEvents: 'none',
			},
		},
	},
});

export const TitleBox = styled(GridBox, {
	gridAutoFlow: 'column',
	alignItems: 'center',
	gap: '$1',
	position: 'absolute',
	zIndex: 1,
	top: 0,
	left: containerPadding,
	transform: 'translateY(-50%)',
	paddingInline: '$1',

	'&::after': {
		content: '',
		display: 'block',
		width: '100%',
		height: '50%',
		position: 'absolute',
		zIndex: -1,
		left: 0,
		top: `calc(50% - ${containerBorderSize}px)`,
		backgroundColor: containerColor,
	},
});

export const TooltipContent = styled(Tooltip.Content, {
	maxWidth: 250,
});
