import {isNull} from 'underscore';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {isUndefined} from '@esgi/ui';
import {
	BoxplotSinglePeriodForm,
	BoxplotTwoPeriodsForm,
	BoxplotTwoClassesForm,
	CommonForm,
	PiechartsForm,
} from '../forms-data/types';
import {ClassModel, SubjectTab} from '../../../../../../types';
import {BoxplotCompareVariant, CommonSelectedArraysEntity, CommonUpdatedValue} from '../types';
import {
	Color,
	GradeLevel,
	OnUpdateWidget,
	PerformanceWidgetViewType,
	WidgetCard,
} from '@esgi/main/features/admins/dashboard';
import {Widget} from '../../../../../types/widget';
import {enumRemap} from 'shared/utils';
import {PeriodType} from '@esgi/main/kits/admin';
import {DemographicPerformanceWidgetUpdatedValue} from '../../../../../types/demographic-performance';

export function useSaveWidget({
	onUpdateWidget,
	schoolID,
	districtID,
	globalSchoolYearID,
	setIsCommonFormTouched,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	selectedContentArea,
	selectedGradeLevels,
	selectedSubjectIDs,
	selectedClassesIDs,
	commonForm,
	boxplotSinglePeriodForm,
	boxplotTwoPeriodsForm,
	boxplotTwoClassesForm,
	piechartsForm,
	setSelectedClassesIDs,
	allClassesList,
	subjects,
	gradeLevels,
}: {
	onUpdateWidget: Dispatch<DemographicPerformanceWidgetUpdatedValue>;
	schoolID: number;
	districtID: number;
	globalSchoolYearID: number;
	setIsCommonFormTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataValid: Dispatch<SetStateAction<boolean>>;
	selectedContentArea: string[];
	selectedSubjectIDs: string[];
	selectedGradeLevels: string[];
	selectedClassesIDs: string[];
	commonForm: CommonForm;
	boxplotSinglePeriodForm: BoxplotSinglePeriodForm;
	boxplotTwoPeriodsForm: BoxplotTwoPeriodsForm;
	boxplotTwoClassesForm: BoxplotTwoClassesForm;
	piechartsForm: PiechartsForm;
	setSelectedClassesIDs: Dispatch<SetStateAction<string[]>>;
	subjects: SubjectTab[];
	allClassesList: ClassModel[];
	gradeLevels: GradeLevel[];
}) {
	const {allContentAreas} = WidgetCard.useWidgetCardContext();

	const handleUpdateWidget = useCallback(
		(value: DemographicPerformanceWidgetUpdatedValue) => {
			onUpdateWidget(value);

			setIsCommonFormTouched(false);

			setIsDynamicFormDataTouched(false);
			setIsDynamicFormDataValid(true);
		},
		[onUpdateWidget, setIsCommonFormTouched, setIsDynamicFormDataTouched, setIsDynamicFormDataValid],
	);

	const getCommonUpdatedValue = useCallback(
		({id, name}: {id: Widget['id']; name: string}) => {
			const {viewType, isShowAverageValue, size, demographicGroup} = commonForm.value;

			const demographicGroupValue = demographicGroup[0];

			if (isUndefined(demographicGroupValue)) {
				throw new Error('Demographic Group is undefined');
			}

			if (isNull(demographicGroupValue)) {
				throw new Error('Demographic Group is null');
			}

			const contentAreaIDs = selectedContentArea.map(Number);
			const subjectIDs = selectedSubjectIDs.map(Number);
			const gradeLevelIDs = selectedGradeLevels.map(Number);

			const commonUpdatedValue: CommonUpdatedValue = {
				id,
				name,
				options: {
					schoolID,
					districtID,
					globalSchoolYearID,
					contentAreaIDs,
					subjectIDs,
					gradeLevelIDs,
					showAvg: isShowAverageValue,
					viewType,
					viewSize: size,
					demographicGroup: demographicGroupValue,
				},
			};

			const commonSelectedArraysEntity: CommonSelectedArraysEntity = {
				contentAreaIDs: allContentAreas.length === contentAreaIDs.length ? 'all' : 'part',
				gradeLevelIDs: gradeLevels.length === gradeLevelIDs.length ? 'all' : 'part',
				subjectIDs: subjects.length === subjectIDs.length ? 'all' : 'part',
			};

			return {commonUpdatedValue, commonSelectedArraysEntity};
		},
		[
			commonForm.value,
			selectedContentArea,
			selectedSubjectIDs,
			selectedGradeLevels,
			schoolID,
			districtID,
			globalSchoolYearID,
			allContentAreas.length,
			gradeLevels.length,
			subjects.length,
		],
	);

	const saveBoxplotSinglePeriodForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {date, highlightColor, highlightValue, isHighlightCandles} = boxplotSinglePeriodForm.value;

			const highlightColorValue = highlightColor[0];

			if (isUndefined(highlightColorValue)) {
				throw new Error('BoxplotCompareVariant.SinglePeriod: highlightColorValue is undefined');
			}

			const classIDs = selectedClassesIDs.map(Number);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					classIDs,
					periods: [
						{
							color: Color.None,
							trackDatesID: date.trackDatesID,
							dateRange: date.dateRange,
							datePeriod: enumRemap(date.datePeriod, PeriodType),
						},
					],
					classes: [],
					candles:
						isHighlightCandles && !isNull(highlightColorValue)
							? {color: highlightColorValue, highlightIfAbove: highlightValue}
							: null,
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					classIDs: allClassesList.length === classIDs.length ? 'all' : 'part',
				},
			});
		},
		[allClassesList.length, boxplotSinglePeriodForm.value, handleUpdateWidget, selectedClassesIDs],
	);

	const saveBoxplotTwoPeriodsForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {firstPeriodColor, firstPeriodDate, secondPeriodColor, secondPeriodDate} = boxplotTwoPeriodsForm.value;

			const firstPeriodColorValue = firstPeriodColor[0];
			const secondPeriodColorValue = secondPeriodColor[0];

			if (isUndefined(firstPeriodColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoPeriods: firstPeriodColorValue is undefined');
			}

			if (isNull(firstPeriodColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoPeriods: firstPeriodColorValue is null');
			}

			if (isUndefined(secondPeriodColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoPeriods: secondPeriodColorValue is undefined');
			}

			if (isNull(secondPeriodColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoPeriods: secondPeriodColorValue is null');
			}

			const classIDs = selectedClassesIDs.map(Number);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					classIDs,
					periods: [
						{
							color: firstPeriodColorValue,
							trackDatesID: firstPeriodDate.trackDatesID,
							dateRange: firstPeriodDate.dateRange,
							datePeriod: enumRemap(firstPeriodDate.datePeriod, PeriodType),
						},
						{
							color: secondPeriodColorValue,
							trackDatesID: secondPeriodDate.trackDatesID,
							dateRange: secondPeriodDate.dateRange,
							datePeriod: enumRemap(secondPeriodDate.datePeriod, PeriodType),
						},
					],
					classes: [],
					candles: null,
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					classIDs: allClassesList.length === classIDs.length ? 'all' : 'part',
				},
			});
		},
		[allClassesList, boxplotTwoPeriodsForm.value, handleUpdateWidget, selectedClassesIDs],
	);

	const saveBoxplotTwoClassesForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {date, firstClassColor, firstClassID, secondClassColor, secondClassID} = boxplotTwoClassesForm.value;

			const firstClassColorValue = firstClassColor[0];
			const firstClassIDValue = firstClassID[0];

			const secondClassColorValue = secondClassColor[0];
			const secondClassIDValue = secondClassID[0];

			if (isUndefined(firstClassColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoClasses: firstClassColorValue is undefined');
			}

			if (isNull(firstClassColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoClasses: firstClassColorValue is null');
			}

			if (isUndefined(secondClassColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoSClasses: secondClassColorValue is undefined');
			}

			if (isNull(secondClassColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoClasses: secondClassColorValue is null');
			}

			if (isUndefined(firstClassIDValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoClasses: firstClassIDValue is undefined');
			}

			if (isNull(firstClassIDValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoClasses: firstClassIDValue is null');
			}

			if (isUndefined(secondClassIDValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoClasses: secondClassIDValue is undefined');
			}

			if (isNull(secondClassIDValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoClasses: secondClassIDValue is null');
			}

			const firstClassIDNumberedValue = Number(firstClassIDValue);
			const secondClassIDNumberedValue = Number(secondClassIDValue);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					classIDs: [firstClassIDNumberedValue, secondClassIDNumberedValue],
					periods: [
						{
							color: Color.None,
							trackDatesID: date.trackDatesID,
							dateRange: date.dateRange,
							datePeriod: enumRemap(date.datePeriod, PeriodType),
						},
					],
					classes: [
						{
							id: firstClassIDNumberedValue,
							color: firstClassColorValue,
						},
						{
							id: secondClassIDNumberedValue,
							color: secondClassColorValue,
						},
					],
					candles: null,
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					classIDs: 'part',
				},
			});

			setSelectedClassesIDs([firstClassIDValue, secondClassIDValue]);
		},
		[boxplotTwoClassesForm, handleUpdateWidget, setSelectedClassesIDs],
	);

	const savePiechartsForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {date} = piechartsForm.value;

			const classIDs = selectedClassesIDs.map(Number);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					classIDs,
					periods: [
						{
							color: Color.None,
							trackDatesID: date.trackDatesID,
							dateRange: date.dateRange,
							datePeriod: enumRemap(date.datePeriod, PeriodType),
						},
					],
					classes: [],
					candles: null,
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					classIDs: allClassesList.length === classIDs.length ? 'all' : 'part',
				},
			});
		},
		[allClassesList.length, handleUpdateWidget, piechartsForm.value, selectedClassesIDs],
	);

	return useCallback<OnUpdateWidget>(
		({id, name}) => {
			const {commonUpdatedValue, commonSelectedArraysEntity} = getCommonUpdatedValue({id, name});

			const {viewType, boxplotCompareVariant} = commonForm.value;

			if (viewType === PerformanceWidgetViewType.Candles) {
				if (boxplotCompareVariant === BoxplotCompareVariant.SinglePeriod) {
					saveBoxplotSinglePeriodForm({commonUpdatedValue, commonSelectedArraysEntity});
				}

				if (boxplotCompareVariant === BoxplotCompareVariant.CompareTwoPeriods) {
					saveBoxplotTwoPeriodsForm({commonUpdatedValue, commonSelectedArraysEntity});
				}

				if (boxplotCompareVariant === BoxplotCompareVariant.CompareTwoClasses) {
					saveBoxplotTwoClassesForm({commonUpdatedValue, commonSelectedArraysEntity});
				}
			}

			if (viewType === PerformanceWidgetViewType.Piechart) {
				savePiechartsForm({commonUpdatedValue, commonSelectedArraysEntity});
			}
		},
		[
			commonForm,
			getCommonUpdatedValue,
			saveBoxplotSinglePeriodForm,
			saveBoxplotTwoPeriodsForm,
			saveBoxplotTwoClassesForm,
			savePiechartsForm,
		],
	);
}
