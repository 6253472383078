import {AddTestsDrawer, AddTestsDrawerTestModel, TestPanelMode} from '@esgi/main/features/admins/subject-management';
import {useFlag} from '@esgi/ui';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {TestsPanel} from '../../components/tests-panel';
import {useTestsPanel} from './hooks/use-tests-panel';
import {useInitData} from './hooks/use-init-data';
import {useSubjectData} from './hooks/use-subject-data';
import {SettingsPanel} from '../../components/settings-panel';

type Props = {
	allDistrictSubjectsNames: string[];
	onSubjectCreated: Dispatch<number>;
	onIsFormTouchedValueChanged: Dispatch<boolean>;
};

export function Add({allDistrictSubjectsNames, onSubjectCreated, onIsFormTouchedValueChanged}: Props) {
	const [testsPanelMode, setTestsPanelMode] = useState(TestPanelMode.View);
	const [selectedTests, setSelectedTests] = useState<AddTestsDrawerTestModel[]>([]);

	const [isAddTestsDrawerOpen, openAddTestsDrawer, closeAddTestsDrawer] = useFlag();

	const {
		isDataLoaded,
		trackID,
		allTrackDates,
		gradeLevels,
		teachersSchools,
		schoolSpecialistsSchools,
		districtSpecialists,
		schoolSpecialists,
	} = useInitData();

	const {adaptedTestsPanelTests, onTestDeleteClick, onRearrangeTests, onAddTests} = useTestsPanel({
		selectedTests,
		setSelectedTests,
	});

	const {
		isActive,
		setIsActive,
		subjectName,
		setSubjectName,
		retestSubjectType,
		setRetestSubjectType,
		isShuffleQuestions,
		setIsShuffleQuestions,
		publishPeriodValue,
		setPublishPeriodValue,
		setSelectedGradeLevels,
		selectedGradeLevels,
		publishForTeacherData,
		setPublishForTeacherData,
		isPublishForTeacherActive,
		setIsPublishForTeacherActive,
		publishForSpecialistsData,
		setPublishForSpecialistsData,
		isPublishForSpecialistsActive,
		setIsPublishForSpecialistsActive,
		onSaveSubject,
		isDataSaving,
		isFormTouched,
	} = useSubjectData({
		districtSpecialists,
		schoolSpecialists,
		gradeLevels,
		isDataLoaded,
		schoolSpecialistsSchools,
		teachersSchools,
		selectedTests,
		trackID,
		allTrackDates,
		onSubjectCreated,
	});

	useEffect(() => {
		onIsFormTouchedValueChanged(isFormTouched);

		return () => {
			onIsFormTouchedValueChanged(false);
		};
	}, [isFormTouched, onIsFormTouchedValueChanged]);

	const onCloseAddTestsDrawer = useCallback(() => {
		closeAddTestsDrawer();
		setTestsPanelMode(TestPanelMode.View);
	}, [closeAddTestsDrawer]);

	return (
		<>
			<SettingsPanel
				skeleton={!isDataLoaded}
				subjectName='New Subject Tab'
				saveButtonText='Add'
				subjectNameValue={subjectName}
				setSubjectNameValue={setSubjectName}
				forbiddenSubjectsNames={allDistrictSubjectsNames}
				isActive={isActive}
				setIsActive={setIsActive}
				retestSubjectType={retestSubjectType}
				setRetestSubjectType={setRetestSubjectType}
				isShuffleQuestions={isShuffleQuestions}
				setIsShuffleQuestions={setIsShuffleQuestions}
				trackID={trackID}
				gradeLevels={gradeLevels}
				selectedGradeLevels={selectedGradeLevels}
				setSelectedGradeLevels={setSelectedGradeLevels}
				allTrackDates={allTrackDates}
				publishPeriodValue={publishPeriodValue}
				setPublishPeriodValue={setPublishPeriodValue}
				isPublishForTeacherActive={isPublishForTeacherActive}
				setIsPublishForTeacherActive={setIsPublishForTeacherActive}
				publishForTeacherData={publishForTeacherData}
				setPublishForTeacherData={setPublishForTeacherData}
				isPublishForSpecialistsActive={isPublishForSpecialistsActive}
				setIsPublishForSpecialistsActive={setIsPublishForSpecialistsActive}
				publishForSpecialistsData={publishForSpecialistsData}
				setPublishForSpecialistsData={setPublishForSpecialistsData}
				onSaveSubject={onSaveSubject}
				isDataSaving={isDataSaving}
			/>

			<TestsPanel
				tests={adaptedTestsPanelTests}
				onRearrangeTests={onRearrangeTests}
				onTestDeleteClick={onTestDeleteClick}
				onAddButtonClick={openAddTestsDrawer}
				testsPanelMode={testsPanelMode}
				setTestsPanelMode={setTestsPanelMode}
				skeleton={!isDataLoaded}
			/>

			{isAddTestsDrawerOpen && (
				<AddTestsDrawer
					onClose={onCloseAddTestsDrawer}
					subject={null}
					onAddTests={onAddTests}
					initialSelectedTestsIDs={selectedTests.map(({id}) => id)}
				/>
			)}
		</>
	);
}
