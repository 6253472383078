import {Checkbox as CheckboxBase} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const Checkbox = styled(CheckboxBase, {
	'& > div': {
		minWidth: 'unset',
		width: 16,
		height: 16,
		borderColor: '$primaryHighlight',
		backgroundColor: '$background',
		transition: 'background-color .3s, border-color .3s',
	},

	'& span': {
		color: '$highContrast',
	},

	'& > div:has(> span[data-state=checked][data-disabled])': {
		backgroundColor: '$surface',

		'& svg': {
			fill: '$lowContrast',

			'& path': {
				fill: '$lowContrast',
			},
		},
	},

	'&[data-state=checked]': {
		'& > div': {
			backgroundColor: '$primaryBackground',
		},
	},

	'&:hover': {
		'&[data-state=checked]': {
			'& > div': {
				borderColor: '$secondaryHighlight',
				backgroundColor: '$secondaryBackground',

				'& path': {
					fill: '$secondary',
				},
			},
		},

		'&:not([data-disabled]) > div': {
			borderColor: '$secondaryHighlight',
			backgroundColor: '$secondaryBackground',
		},
	},
});
