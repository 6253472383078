import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const RootBox = styled(GridBox, {
	gridTemplateRows: 'auto 1fr',
	rowGap: '$4',
	background: 'linear-gradient(126deg, #F0F0F0 0.11%, #EBEBEB 71.15%)',
	overflow: 'hidden',
	height: '100%',
});
