import {Text} from '@esgi/ui/typography';
import {EmptyContentBox} from './index.styled';

type Props = {
	message: string | [string, ...string[]];
};

export function EmptyContent({message}: Props) {
	return (
		<EmptyContentBox>
			{(typeof message === 'string' ? [message] : message).map((value) => (
				<Text size='small' font='mono' color='mediumContrast' key={value}>
					{value}
				</Text>
			))}
		</EmptyContentBox>
	);
}
