import {School, SpecialistGroup, Student, User} from '@esgi/main/libs/admin-store';
import {generateRandomString, isUndefined, useStreamEffect} from '@esgi/ui';
import {ElementStatus, FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {UserType} from '../../types';
import {Dispatch, useCallback, useEffect, useMemo, useState} from 'react';
import {SchoolSpecialistForm} from './types';
import {groupNameMaxLength} from '../../../components/group-name-input/constants';
import {isNull} from 'underscore';
import {enumRemap} from 'shared/utils';

type Parameters = {
	schoolSpecialists: User[];
	specialistGroups: SpecialistGroup[];
	setIsFormValid: Dispatch<boolean>;
	setIsFormTouched: Dispatch<boolean>;
	schools: School[];
	isSchoolsLoaded: boolean;
};

export function useSchoolSpecialistForm({
	schoolSpecialists,
	specialistGroups,
	setIsFormTouched,
	setIsFormValid,
	schools,
	isSchoolsLoaded,
}: Parameters) {
	const [formInactiveMessage, setFormInactiveMessage] = useState<string | null>(null);

	const [activeSchoolSpecialistsList, setActiveSchoolSpecialistsList] = useState<User[]>([]);
	const [schoolSpecialistUniqueKey, setSchoolSpecialistUniqueKey] = useState(generateRandomString());

	const schoolsWithSpecialists = useMemo(() => {
		const uniqSchoolsIDs = [
			...new Set(schoolSpecialists.filter(({schoolID}) => !isNull(schoolID)).map(({schoolID}) => schoolID)),
		];

		return schools.filter(({id}) => uniqSchoolsIDs.includes(id));
	}, [schoolSpecialists, schools]);

	const form: SchoolSpecialistForm = useForm(
		() =>
			new FormGroup({
				schoolID: new FormControl<string[]>([], {validators: [Validators.required()]}),
				schoolSpecialistID: new FormControl<string[]>([], {
					validators: [Validators.required()],
					initialValidateStatus: ElementStatus.disabled,
				}),
				groupName: new FormControl('', {
					initialValidateStatus: ElementStatus.disabled,
				}),
				studentsIDs: new FormControl<Student['id'][]>([]),
			}),
	);

	useEffect(() => {
		if (isSchoolsLoaded && !schoolsWithSpecialists.length) {
			form.status = ElementStatus.disabled;

			setFormInactiveMessage('There are no school specialists in the district');
		}
	}, [form, isSchoolsLoaded, schoolsWithSpecialists]);

	useStreamEffect(form.controls.schoolID.onChanged, ({currState: {value}, reason}) => {
		const schoolID = value[0];

		if (!isUndefined(schoolID) && reason === 'value') {
			const numberedSchoolID = Number(schoolID);

			const specialistsInSchool = schoolSpecialists.filter((specialist) => specialist.schoolID === numberedSchoolID);

			setActiveSchoolSpecialistsList(specialistsInSchool);
			setSchoolSpecialistUniqueKey(generateRandomString());

			form.controls.schoolSpecialistID.value = [];
			form.controls.schoolSpecialistID.status = ElementStatus.untouched;

			form.controls.groupName.status = ElementStatus.disabled;

			setIsFormValid(false);
		}
	});

	useStreamEffect(form.controls.schoolSpecialistID.onChanged, ({currState: {value}, reason}) => {
		const schoolSpecialistID = value[0];

		if (!isUndefined(schoolSpecialistID) && reason === 'value') {
			const numberedSchoolSpecialistID = Number(schoolSpecialistID);

			const schoolSpecialistGroups = specialistGroups.filter(
				({specialistID, userType}) =>
					enumRemap(userType, UserType) === UserType.ISS && specialistID === numberedSchoolSpecialistID,
			);

			form.controls.groupName.validators.length = 0;

			form.controls.groupName.validators.push(
				Validators.required(),
				Validators.length.max(groupNameMaxLength),
				Validators.isDublicateValue(schoolSpecialistGroups.map(({name}) => name)),
			);

			form.controls.groupName.status = ElementStatus.untouched;

			form.validate().subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		}
	});

	const checkFormOnTouchValid = useCallback(() => {
		const {groupName, schoolID, schoolSpecialistID, studentsIDs} = form.value;

		const isFormTouched =
			groupName !== '' ||
			!isUndefined(schoolID[0]) ||
			!isUndefined(schoolSpecialistID[0]) ||
			Boolean(studentsIDs.length);

		setIsFormTouched(isFormTouched);

		form.validate(true).subscribe(({valid}) => {
			setIsFormValid(valid);

			if (isUndefined(form.value.schoolID[0])) {
				form.controls.schoolSpecialistID.status = ElementStatus.disabled;
			}

			if (
				form.controls.schoolSpecialistID.status === ElementStatus.disabled ||
				isUndefined(form.value.schoolSpecialistID[0])
			) {
				form.controls.groupName.status = ElementStatus.disabled;
			}
		});
	}, [form, setIsFormTouched, setIsFormValid]);

	useStreamEffect(form.onChanged, checkFormOnTouchValid);

	return {
		form,
		schoolSpecialistUniqueKey,
		activeSchoolSpecialistsList,
		checkFormOnTouchValid,
		schoolsWithSpecialists,
		formInactiveMessage,
	};
}
