import {Drawer, UnsavedChangesConfirmation} from '@esgi/main/kits/common';
import {Close, Plus} from '@esgi/ui/icons';
import {HeaderTitle} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {TabID} from '../../types';
import {Divider, Header, HeaderButtons} from '../../components/header.styled';
import {Body} from '../../components/body';
import {FormRoot} from '../../components/form.styled';
import {useDrawerState} from '../use-drawer-state';
import {StudentsTabContent} from '../../components/students-tab-content';
import {useSave} from './use-save';
import {ClassInformationTabContent} from './components/class-information-tab-content';
import {Skeleton} from '../../components/skeleton';
import {useAddForm} from './form-data/use-add-form';

type Props = {
	onClose: VoidFunction;
};

export function Add({onClose}: Props) {
	const {
		drawerRef,
		onDrawerClose,
		closeDrawer,
		closeUnsavedChangesConfirmation,
		showUnsavedChangesConfirmation,
		classes,
		activeTab,
		setActiveTab,
		setIsFormTouched,
		setIsFormValid,
		isFormTouched,
		isFormValid,
		teachers,
		withoutResetSingleStudentAlert,
		setWithoutResetSingleStudentAlert,
		students,
		skeleton,
	} = useDrawerState({onClose});

	const {form, selectedPrimaryTeacherID} = useAddForm({
		classes,
		setIsFormTouched,
		setIsFormValid,
	});

	const {saveData, isDataSaving} = useSave({closeDrawer, form});

	return (
		<>
			<Drawer drawerRef={drawerRef} onClickOutside={onDrawerClose}>
				{skeleton ? (
					<Skeleton onClose={closeDrawer} />
				) : (
					<>
						<Header>
							<HeaderTitle>
								<Plus />
								<Text size='small' color='mediumContrast'>
									Add New Class
								</Text>
							</HeaderTitle>
							<HeaderButtons>
								<Button color='primary' onClick={saveData} disabled={!isFormValid || !isFormTouched || isDataSaving}>
									<Text size='large'>Add</Text>
								</Button>

								<Button.Icon withBackgroundHover onClick={onDrawerClose}>
									<Close />
								</Button.Icon>
							</HeaderButtons>

							<Divider />
						</Header>

						<Body activeTabID={activeTab} setActiveTabID={setActiveTab}>
							<FormRoot controller={form}>
								{activeTab === TabID.ClassInformation && (
									<ClassInformationTabContent
										teachers={teachers}
										primaryTeacherIDControl={form.controls.primaryTeacherID}
										classNameControl={form.controls.className}
									/>
								)}

								{activeTab === TabID.Students && (
									<StudentsTabContent
										studentsIDsControl={form.controls.studentsIDs}
										withoutResetSingleStudentAlert={withoutResetSingleStudentAlert}
										setWithoutResetSingleStudentAlert={setWithoutResetSingleStudentAlert}
										primaryTeacherID={selectedPrimaryTeacherID}
										allStudents={students}
										currentClass={null}
									/>
								)}
							</FormRoot>
						</Body>
					</>
				)}
			</Drawer>

			{showUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onClose={closeUnsavedChangesConfirmation} onCloseAnyway={closeDrawer} />
			)}
		</>
	);
}
