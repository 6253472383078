import {StyledButton} from './index.styled';
import {ReactElement, ComponentPropsWithoutRef, forwardRef} from 'react';

type Props = Omit<ComponentPropsWithoutRef<typeof StyledButton>, 'children'> & {
	children: ReactElement<React.SVGProps<SVGSVGElement>>;
};

export const PanelButton = forwardRef<HTMLButtonElement, Props>(
	({dataCy = 'panel-button', css = {}, size = 'default', variant = 'default', children, ...props}, forwardedRef) => (
		<StyledButton variant={variant} size={size} {...props} ref={forwardedRef}>
			{children}
		</StyledButton>
	),
);
