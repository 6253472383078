import {enumRemap} from 'shared/utils';
import {PeriodType, SelectOptionModel} from '../../../types';

export const calendarPast: SelectOptionModel[] = [
	{
		value: enumRemap(PeriodType.Yesterday, PeriodType),
		label: 'Yesterday',
	},
	{
		value: enumRemap(PeriodType.PreviousWeek, PeriodType),
		label: 'Previous Week',
	},
	{
		value: enumRemap(PeriodType.PreviousMonth, PeriodType),
		label: 'Previous Month',
	},
];
