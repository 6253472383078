import {useCallback, useEffect} from 'react';
import {isUndefined} from '@esgi/ui';
import {routes} from '@esgi/main/libs/core';
import {UserType} from '@esgi/core/authentication';
import {useInlineManual} from '@esgi/core/inline-manual';
import {useLocation} from 'react-router-dom';
import {NavigationBarOrientation} from '@esgi/ui/layout';
import {HorizontalPanel} from './variant/horizontal-panel';
import {VerticalPanel} from './variant/vertical-panel';
import {menuLabelByRoute} from './constants';
import {useOpenDrawer} from '../../drawers';

type Props = {
	orientation: NavigationBarOrientation;
	onSchoolYearChanged: VoidFunction;
	userFirstName: string;
	userLastName: string;
	userAvatarSrc: string | null;
};

export function NavigationPanel({orientation, onSchoolYearChanged, userFirstName, userLastName, userAvatarSrc}: Props) {
	const {pathname} = useLocation();

	const {showInlineManual} = useInlineManual({userType: UserType.D});

	const openDrawer = useOpenDrawer();

	const openUserAccountForm = useCallback(() => openDrawer('user-account'), [openDrawer]);
	const openSettingsForm = useCallback(() => openDrawer('settings'), [openDrawer]);

	useEffect(() => {
		const currentRoute = Object.entries(routes.districtAdmin).find(([_, route]) => {
			if (typeof route === 'string') {
				return pathname.startsWith(route);
			}

			return Object.entries(route).find(([_, nestedRoute]) => pathname.startsWith(nestedRoute));
		});

		if (isUndefined(currentRoute)) {
			document.title = 'ESGI';

			return;
		}

		document.title = menuLabelByRoute[currentRoute[0]];
	}, [pathname]);

	if (orientation === NavigationBarOrientation.Horizontal) {
		return (
			<HorizontalPanel
				onSchoolYearChanged={onSchoolYearChanged}
				onSettingsClick={openSettingsForm}
				userFirstName={userFirstName}
				userLastName={userLastName}
				userAvatarSrc={userAvatarSrc}
				onAvatarClick={openUserAccountForm}
				onHelpClick={showInlineManual}
			/>
		);
	}

	if (orientation === NavigationBarOrientation.Vertical) {
		return (
			<VerticalPanel
				onSchoolYearChanged={onSchoolYearChanged}
				onSettingsClick={openSettingsForm}
				userFirstName={userFirstName}
				userLastName={userLastName}
				userAvatarSrc={userAvatarSrc}
				onAvatarClick={openUserAccountForm}
				onHelpClick={showInlineManual}
			/>
		);
	}

	throw new Error('<NavigationPanel />: the property "orientation" is not matched with enum NavigationBarOrientation;');
}
