import {Checkbox} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	height: '100% ',
	alignItems: 'center',
	justifyContent: 'center',
});

export const CheckboxStyled = styled(Checkbox, {
	padding: 'unset',

	'& > div': {
		minWidth: 'unset',
		width: 16,
		height: 16,
		borderColor: '$primaryHighlight',
		backgroundColor: '$background',
		transition: 'background-color .3s, border-color .3s',
	},

	'& > div:has(> span[data-state=checked][data-disabled])': {
		backgroundColor: '$surface',

		'& svg': {
			fill: '$lowContrast',

			'& path': {
				fill: '$lowContrast',
			},
		},
	},

	'&[data-state=checked]': {
		'& > div': {
			backgroundColor: '$primaryBackground',
		},
	},

	variants: {
		hovered: {
			true: {
				'&[data-state=checked]': {
					'& > div': {
						borderColor: '$secondaryMuted',
						backgroundColor: '$secondaryMuted',

						'& path': {
							fill: '$secondary',
						},
					},
				},

				'&:not([data-disabled]) > div': {
					borderColor: '$secondaryMuted',
					backgroundColor: '$secondaryMuted',
				},
			},

			false: {
				'&[data-state=unchecked]': {
					'& > div': {
						backgroundColor: '$background',
					},
				},
			},
		},
	},
});
