import {ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback, useEffect} from 'react';
import {RearrangeButton as RearrangeButtonBase} from '../../../kit';
import {usePanelContext} from '../context';
import {PanelMode, TabID} from '../types/section';
import {useTabsContext} from '../../tabs/context';
import {isNull} from 'underscore';

type Props = Omit<ComponentPropsWithoutRef<typeof RearrangeButtonBase>, 'isActive' | 'tooltipText' | 'skeleton'>;

export const RearrangeButton = forwardRef<HTMLButtonElement, Props>(
	({dataCy = 'subjects-panel-rearrange-button', css = {}, onClick, disabled, ...props}, forwardedRed) => {
		const {panelMode, skeleton, setPanelMode, tabsIDsWithRearrangeContent} = usePanelContext();
		const {activeTab} = useTabsContext<TabID>();

		const isRearrangeMode = panelMode === PanelMode.Rearrange;

		const handleButtonClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				setPanelMode(isRearrangeMode ? PanelMode.View : PanelMode.Rearrange);

				onClick?.(event);
			},
			[isRearrangeMode, onClick, setPanelMode],
		);

		const withRearrangeButton = !isNull(activeTab) && tabsIDsWithRearrangeContent.includes(activeTab);

		useEffect(() => {
			if (!withRearrangeButton && panelMode === PanelMode.Rearrange) {
				setPanelMode(PanelMode.View);
			}
		}, [withRearrangeButton]);

		if (!withRearrangeButton) {
			return null;
		}

		return (
			<RearrangeButtonBase
				dataCy={dataCy}
				css={css}
				isActive={isRearrangeMode}
				disabled={panelMode === PanelMode.Add || disabled || skeleton}
				skeleton={skeleton}
				onClick={handleButtonClick}
				tooltipText='Rearrange'
				ref={forwardedRed}
				{...props}
			/>
		);
	},
);
