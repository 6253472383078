import {useCallback, useEffect, useMemo, useState} from 'react';
import {Input} from '@esgi/ui/controls';
import {Text} from '@esgi/ui/typography';
import {Drawer} from '@esgi/main/kits/common';
import {useService} from '@esgi/core/service';
import {Form, FormElement} from '@esgillc/ui-kit/form';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Box, GridBox, InfoBlock} from '@esgi/ui/layout';
import {DroppableTextEditor} from '@esgi/ui/form-controls';
import {CroppedImage, Incorrect, Reset, Upload, useStreamEffect} from '@esgi/ui';
import {ContentWrapper, PanelContent, StyledButton} from '../../../index.styled';
import {DrawerPanelHeader} from '../../../../../index.styled';
import {SettingsService} from '../../../../../service';
import {StyledTagsContainer} from './index.styled';
import {SettingsTab} from '../../../../../types';
import {NoLogo} from './components/logo/no-logo';
import {ParentLetterService} from './service';
import {parentLetterTags} from './model';
import {WithLogo} from './components/logo/with-logo';

interface Props {
	service: SettingsService;
	onCloseDrawer: VoidFunction;
	onUpdateTabTouchedData: (tab: SettingsTab, isTouched: boolean) => void;
}

export function ParentLetter({service, onCloseDrawer, onUpdateTabTouchedData}: Props) {
	const parentLetterService = useService(ParentLetterService);

	const parentLetterData = useBehaviorSubject(service.parentLetterData$);

	const [isDefaultBody, setIsDefaultBody] = useState(false);
	const [isDisableButton, setIsDisableButton] = useState(true);

	const handleSetDefaultParentLetter = useCallback(() => {
		parentLetterService.form.controls.body.value = parentLetterData.defaultBody;
	}, [parentLetterService, parentLetterData]);

	const handleSetLogo = useCallback((image: CroppedImage) => {
		if (!image) {
			parentLetterService.form.controls.logo.value = null;
			return;
		}

		parentLetterService.form.controls.logo.value = image.croppedImage;
	}, [parentLetterService]);

	const handleSave = useCallback(async () => {
		const response = await service.saveParentLetter(
			parentLetterService.form.value.body,
			parentLetterService.form.value.logo,
			isDefaultBody,
		);

		response.subscribe(() => {
			setIsDisableButton(true);
			onUpdateTabTouchedData(SettingsTab.CustomizeParentLetter, false);
		});
	}, [service, parentLetterService, isDefaultBody, onUpdateTabTouchedData]);

	const handlePublish = useCallback(() => {
		service.publishParentLetter(true);
	}, [service]);

	const handleUnpublish = useCallback(() => {
		service.publishParentLetter(false);
	}, [service]);

	useStreamEffect(parentLetterService.form.onChanged, () => {
		const isTouched = parentLetterService.compareStatesForEquality();
		const isDifferent = parentLetterService.compareParentLetterForDefault();

		setIsDefaultBody(!isDifferent);
		setIsDisableButton(!isTouched);
		onUpdateTabTouchedData(SettingsTab.CustomizeParentLetter, isTouched);
	});

	useEffect(() => {
		parentLetterService.init(parentLetterData.body, parentLetterData.defaultBody, parentLetterData.logo);
	}, [parentLetterService, parentLetterData.body, parentLetterData.defaultBody, parentLetterData.logo]);

	return (
		<Form controller={parentLetterService.form}>
			<DrawerPanelHeader
				withActionButton
				actionButtonText='Save Changes'
				onActionButtonClick={handleSave}
				actionButtonDisabled={isDisableButton}
				onClose={onCloseDrawer}
			>
				<Text data-cy='reports-settings-title' size='large' color='neutral24' bold>
					Parent Letter Settings
				</Text>
			</DrawerPanelHeader>

			<PanelContent>
				<ContentWrapper dataCy='reports-settings-block'>
					<Drawer.ContentBlock title='Parent Letter Template' withDivider>
							<FormElement control={parentLetterService.form.controls.logo}>
								{(state) => (
									state.value ? (
										<GridBox flow='column' align='center' justify='start' gap={3}>
											<WithLogo
												logo={{image: state.value, croppedImage: state.value}}
												setLogo={handleSetLogo}
											/>
											<Text size='medium' color='base'>
												School Logo
											</Text>
										</GridBox>
									) : (
										<GridBox flow='column' align='center' justify='start' gap={3}>
											<NoLogo setLogo={handleSetLogo} />
											<Text size='medium' color='base'>
												Upload a School Logo
											</Text>
										</GridBox>
									)
								)}
							</FormElement>
						<StyledButton color='secondary' onClick={handleSetDefaultParentLetter} disabled={isDefaultBody}>
							<Reset />
							<Text size='medium' color='base'>
								Revert To Default
							</Text>
						</StyledButton>
						<Input placeholder='Template Name' value={isDefaultBody ? 'Default Template' : 'Custom Template'} disabled />
						<FormElement control={parentLetterService.form.controls.body}>
							<DroppableTextEditor placeholder='Message' tags={parentLetterTags}>
								<DroppableTextEditor.TextBox />
								<StyledTagsContainer>
									{parentLetterTags.map((item) => (
										<DroppableTextEditor.DraggableTag key={item.value} tagData={item} />
									))}
								</StyledTagsContainer>
							</DroppableTextEditor>
						</FormElement>
					</Drawer.ContentBlock>
					<Drawer.ContentBlock title='Publish' withDivider>
						<InfoBlock>
							<InfoBlock.Item title='Current State:'>
								<Box>
									{parentLetterData?.published ? (
										<Text size='small' font='mono' color='positive'>Published</Text>
									) : (
										<Text size='small' font='mono' color='negative'>Not Published</Text>
									)}
								</Box>
							</InfoBlock.Item>
						</InfoBlock>
						<GridBox gap={2} flow='column'>
							<StyledButton color='secondary' onClick={handlePublish} disabled={parentLetterData.published}>
								<Upload />
								<Text size='medium' color='base'>
									Publish to District
								</Text>
							</StyledButton>
							<StyledButton color='secondary' onClick={handleUnpublish} disabled={!parentLetterData.published}>
								<Incorrect />
								<Text size='medium' color='base'>
									Unpublish
								</Text>
							</StyledButton>
						</GridBox>
					</Drawer.ContentBlock>
				</ContentWrapper>
			</PanelContent>
		</Form>
	);
}