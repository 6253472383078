import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {CounterBox} from '@esgi/ui/layout';
import {Folder, FormType} from './types';
import {AlertHeader, alertRootCss, SelectedItemsBox} from './index.styled';
import {useCallback, useState} from 'react';
import {AlertSubHeader} from './components/alert-sub-header';
import {ExistingFolder} from './variant/existing-folder';
import {NewFolder} from './variant/new-folder';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';
import {FolderType} from '../kit';
import {useFoldersVocabulary} from './use-folders-vocabulary';
import {folderEntityName} from './constants';

type Props = {
	onCloseAlert: VoidFunction;
	folders: Folder[];
	initialFolderID: Folder['id'] | null;
	addEntityIDs: number[];
	folderType: FolderType;
};

export function AddEntitiesIntoFolder({onCloseAlert, addEntityIDs, initialFolderID, folders, folderType}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onCloseAlert);

	const [formType, setFormType] = useState(FormType.ExistingFolder);
	const [isFormDataTouched, setIsFormDataTouched] = useState(false);

	const [showUnsavedChangesConfirmation, setShowUnsavedChangesConfirmation] = useState(false);

	const [foldersVocabulary, fetchFolderByID] = useFoldersVocabulary({folderType, initialFolderID});

	const onAlertClose = useCallback(() => {
		if (isFormDataTouched) {
			setShowUnsavedChangesConfirmation(true);

			return;
		}

		closeAlert();
	}, [closeAlert, isFormDataTouched]);

	const closeUnsavedChangesConfirmation = useCallback(() => {
		setShowUnsavedChangesConfirmation(false);
	}, []);

	return (
		<>
			<Alert modalManagerRef={alertRef} css={alertRootCss}>
				<AlertHeader withBacklight={false} onCloseIconClick={onAlertClose}>
					<Text size='small' color='base'>
						Add {folderEntityName[folderType]} to a Folder
					</Text>

					<SelectedItemsBox>
						<Text size='xSmall' font='mono' color='primary'>
							{folderEntityName[folderType]} Selected:
						</Text>

						<CounterBox color='blue'>
							<Text size='small' font='mono' color='primaryVivid'>
								{addEntityIDs.length}
							</Text>
						</CounterBox>
					</SelectedItemsBox>
				</AlertHeader>

				<AlertSubHeader activeForm={formType} setActiveForm={setFormType} />

				{formType === FormType.ExistingFolder && (
					<ExistingFolder
						folders={folders}
						initialFolderID={initialFolderID}
						onFormTouchChanged={setIsFormDataTouched}
						onAlertClose={closeAlert}
						onCheckFormCloseAlert={onAlertClose}
						folderType={folderType}
						addEntityIDs={addEntityIDs}
						foldersVocabulary={foldersVocabulary}
						fetchFolderByID={fetchFolderByID}
					/>
				)}
				{formType === FormType.NewFolder && (
					<NewFolder
						folders={folders}
						onFormTouchChanged={setIsFormDataTouched}
						addEntityIDs={addEntityIDs}
						folderType={folderType}
						onAlertClose={closeAlert}
						onCheckFormCloseAlert={onAlertClose}
					/>
				)}
			</Alert>

			{showUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onClose={closeUnsavedChangesConfirmation} onCloseAnyway={closeAlert} />
			)}
		</>
	);
}
