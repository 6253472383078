import {DashboardMetricData, Widget} from '@esgi/main/features/school-admin/dashboard';

export {type ResultWithBaseResponse as InitWidgetsDataResponse} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/metrics/get/by-dashboard-version-id/result-with-base-response';
export {type ResultWithBaseResponse as FetchWidgetDataByIDResponse} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/get/by-id/result-with-base-response';

export enum AppSyncType {
	DashboardVersionCompleted = 'DashboardVersionCompleted',
	DashboardWidgetRecalculated = 'DashboardWidgetRecalculated',
}

export type DashboardVersionCompleted = {
	Timestamp: string;
	DashboardVersionID: number;
	Type: AppSyncType.DashboardVersionCompleted;
};

export type DashboardWidgetRecalculated = {
	WidgetID: number;
	WidgetVersionID: number;
	Data: DashboardMetricData;
	Type: AppSyncType.DashboardWidgetRecalculated;
};

export interface InitWidgetsDataParameters {
	dashboardVersionID: number;
}

export type FetchWidgetDataByIDParameters = Pick<Widget, 'id' | 'versionID'>;
