import {Box} from '@esgi/ui/layout';
import {Button} from '@esgi/ui';
import {Plus} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {CardBody, CardRoot} from './index.styled';
import {GridStackItemContent} from '@esgi/ui/gridstack';
import {forwardRef} from 'react';

type Props = {
	onAddWidgetClick: VoidFunction;
	x: number;
	y: number;
};

export const AddWidgetCard = forwardRef<HTMLDivElement, Props>(({x, y, onAddWidgetClick}, forwardedRef) => (
	<Box className='grid-stack-item' gs-x={x} gs-y={y} ref={forwardedRef}>
		<GridStackItemContent>
			<CardRoot>
				<CardBody>
					<Button color='secondary' onClick={onAddWidgetClick}>
						<Plus />
						<Text size='medium' bold>
							Add Widget
						</Text>
					</Button>
				</CardBody>
			</CardRoot>
		</GridStackItemContent>
	</Box>
));
