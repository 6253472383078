import {BaseComponentProps} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {TextStyled} from './index.styled';
import {usePanelContext} from '../../context';

type Props = BaseComponentProps & ComponentPropsWithoutRef<typeof Text>;

export const Title = forwardRef<HTMLSpanElement, Props>(
	({dataCy = 'title', css = {}, children, ...textProps}, forwardedRef) => {
		const {skeleton} = usePanelContext();

		return (
			<TextStyled data-cy={dataCy} css={css} ref={forwardedRef} {...textProps} skeleton={skeleton}>
				{children}
			</TextStyled>
		);
	},
);
