import {OneLinedText, TestColorLineIndicator, TestContentArea, TestType, TestTypeLabel} from '@esgi/main/kits/common';
import {Container} from './index.styled';

type Props = {
	name: string;
	color: string;
	contentArea: TestContentArea;
	type: TestType;
};

export function TestName({name, color, contentArea, type}: Props) {
	return (
		<Container>
			<TestColorLineIndicator color={color} contentArea={contentArea} />
			<OneLinedText size='xLarge' color='base' data-cy='test-name'>
				{name}
			</OneLinedText>
			<TestTypeLabel testType={type} />
		</Container>
	);
}
