import {isNull} from 'underscore';
import {TestCard as TestResultsPanelTestCard} from '@esgi/main/kits/test-results-panel';
import {Text} from '@esgi/ui/typography';
import {TestPanelCard} from '../../../../test-panel-card';
import {CorrectAnswersInfo} from '../correct-answers-info';
import {TestContentArea, TestType} from '@esgi/main/kits/common';
import {ByAllStudentsTest} from '../../../../types/test-panel';

type Props = Pick<ByAllStudentsTest, 'result'> & {
	showInColor: boolean;
	allStudentsCount: number;
	color: string;
	contentArea: TestContentArea;
	type: TestType;
	name: string;
	totalPossible: number;
};

export function TestCard({
	showInColor,
	allStudentsCount,
	color,
	contentArea,
	type,
	name,
	totalPossible,
	result,
}: Props) {
	return (
		<TestPanelCard
			color={color}
			contentArea={contentArea}
			type={type}
			name={name}
			correctAnswersPercentage={result?.correctAnswers ? (result.correctAnswers / totalPossible) * 100 : null}
			showInColor={showInColor}
		>
			<TestResultsPanelTestCard.Footer>
				<TestResultsPanelTestCard.Statistic.Charts.AnalyticsBox>
					{isNull(result) ? (
						<TestResultsPanelTestCard.Statistic.InfoWithTranscript transcript='Student tested' justifyItems='start'>
							<Text size='medium' font='mono' bold color='base'>
								0
							</Text>
						</TestResultsPanelTestCard.Statistic.InfoWithTranscript>
					) : (
						<TestResultsPanelTestCard.AnswersInfo
							value={result.studentsTested}
							maxValue={allStudentsCount}
							transcriptText='Student tested'
						/>
					)}

					<CorrectAnswersInfo value={result?.correctAnswers ?? null} maxValue={totalPossible} />
				</TestResultsPanelTestCard.Statistic.Charts.AnalyticsBox>
			</TestResultsPanelTestCard.Footer>
		</TestPanelCard>
	);
}
