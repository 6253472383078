import {WarningAlert} from '@esgi/main/features/admins/data';

type Props = {
	onClose: VoidFunction;
};

export function NoStudentsInSchoolAlert({onClose}: Props) {
	return (
		<WarningAlert
			description='The selected school does not have any students assigned to a class. Please assign at least 1 student to a class to run the report.'
			onClose={onClose}
		/>
	);
}
