import React, {Suspense, useEffect} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {SsoTracker} from '@esgi/core/tracker';
import {lazyComponent} from '@esgi/core/react';
import {useFakeChangesCollector} from './utils';

const ItemAnalysisReportModal = lazyComponent(() => import('modules/reports/item-analysis-report'));

type Props = {
	hierarchy: HierarchyInstance,
	subjectID: number,
	subjectType: SubjectType,
	onClose: VoidFunction,
}

export default function ItemAnalysisLauncher(props: Props) {
	const changesCollector = useFakeChangesCollector(props.hierarchy);

	useEffect(() => {
		SsoTracker.trackEvent({
			trackingEvent: 'ItemAnalysisReport',
		});
	}, []);


	return <Suspense fallback={<></>}>
		<ItemAnalysisReportModal
			subjectID={props.subjectID}
			subjectType={props.subjectType}
			hierarchy={props.hierarchy.snapshot}
			changesCollector={changesCollector}
			onClose={props.onClose}
		/>
	</Suspense>;
}
