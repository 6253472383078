import {PropsWithChildren, useMemo} from 'react';
import {Container, Divider} from './index.styled';
import {Header} from './components/header';
import {HeaderProps, HeaderSortableProps, HeaderUnsortableProps} from './components/header/types';
import {useWidgetCardContext} from '../../context';
import {DataMessagesInfoBox} from './components/data-messages-info-box';
import {dataIsEmptyMessages} from './constants';
import {CountingWidgetData} from './components/counting-widget-data';

type Props<T extends string> = PropsWithChildren<HeaderProps<T>>;

export function ViewMode<T extends string>({children, ...props}: Props<T>) {
	const {totalDataLength, isDataСounting, lastUpdatedTime} = useWidgetCardContext();

	const sortableProps = useMemo(() => {
		if (!('sortableItems' in props)) {
			return {} as HeaderUnsortableProps;
		}

		return props as HeaderSortableProps<T>;
	}, [props]);

	const renderContent = () => {
		if (isDataСounting) {
			return <CountingWidgetData lastUpdatedTime={lastUpdatedTime} />;
		}

		if (!totalDataLength) {
			return <DataMessagesInfoBox messages={dataIsEmptyMessages} />;
		}

		return (
			<>
				<Divider />
				{children}
			</>
		);
	};

	return (
		<Container withDataMessagesInfoBox={isDataСounting || !totalDataLength}>
			<Header {...sortableProps} />

			{renderContent()}
		</Container>
	);
}
