import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RowDef} from '../../../../../types';
import {NoStudentsAlert, ReportDialog, Reports} from '../../../../../../_kit';

type Props = {
	teacher: RowDef;
	onClose: VoidFunction;
};

export function ReportsFeature({teacher, onClose}: Props) {
	const teacherLevelHierarchy = useMemo<Hierarchy>(
		() => ({
			schoolID: teacher?.school?.id,
			teacherID: teacher?.id,
		}),
		[teacher],
	);

	if (!teacher.students) {
		return <NoStudentsAlert onClose={onClose} />;
	}

	return (
		<ReportDialog header={`${teacher.firstName} ${teacher.lastName}`} onClose={onClose}>
			<ReportDialog.Section title='Teacher Reports'>
				<Reports.ItemAnalysis
					hierarchy={teacherLevelHierarchy}
					description='Analyze data based on least and most known items'
				/>
				<Reports.PieCharts
					hierarchy={teacherLevelHierarchy}
					description='Access a printable report of homepage pie charts'
				/>
				<Reports.UntestedStudents
					hierarchy={teacherLevelHierarchy}
					description='Identify untested students to administer assessments'
				/>
			</ReportDialog.Section>
		</ReportDialog>
	);
}
