import {isUndefined} from '@esgi/ui';
import {dateFormat} from '../constants';
import {DatePickerData, PeriodType, TrackModel} from '../types';
import {isNull} from 'underscore';
import moment from 'moment';
import {useCurrentTrackDates} from './use-current-track-dates';

type Parameters = DatePickerData & {
	currentTrack: TrackModel | null;
};

export function useCurrentDatePickerValue({datePeriod, dateRange, trackDatesID, currentTrack}: Parameters) {
	const currentTracks = useCurrentTrackDates({currentTrack});

	if (datePeriod === PeriodType.Manual && !isNull(dateRange)) {
		const dateFrom = moment(dateRange.dateFrom).format(dateFormat);
		const dateTo = moment(dateRange.dateTo).format(dateFormat);

		return `${dateFrom} - ${dateTo}`;
	}

	if (trackDatesID !== 0 && datePeriod === PeriodType.PreviousSelectedMarkingPeriod) {
		const currentTrack = currentTracks.find(({id}) => id === trackDatesID);

		if (isUndefined(currentTrack)) {
			return '';
		}

		return currentTrack.name;
	}

	switch (datePeriod) {
		case PeriodType.CurrentMarkingPeriod:
			return 'Current Marking Period';
		case PeriodType.CurrentSchoolYear:
			return 'Current School Year';
		case PeriodType.PreviousMarkingPeriod:
			return 'Previous Marking Period';
		case PeriodType.PreviousMonth:
			return 'Previous Month';
		case PeriodType.PreviousWeek:
			return 'Previous Week';
		case PeriodType.ThisMonth:
			return 'This Month';
		case PeriodType.ThisWeek:
			return 'This Week';
		case PeriodType.Today:
			return 'Today';
		case PeriodType.Yesterday:
			return 'Yesterday';
		default:
			return '';
	}
}
