import {
	ActionButtonsBox,
	DeleteButton,
	SaveButton,
	WidgetTitleBox,
	WidgetTitleIconBox,
	CardHeader,
} from './index.styled';
import {Close, Delete, Setup} from '@esgi/ui/icons';
import {FormElement} from '../form-element';
import {Input} from '@esgi/ui/form-controls';
import {ErrorTooltip, IFormControl} from '@esgi/ui/form';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';

type Props = {
	onOpenDeleteWidgetAlert: VoidFunction;
	widgetNameControl: IFormControl<string>;
	onCloseEditMode: VoidFunction;
	isSaveButtonDisabled: boolean;
	onSaveClick: VoidFunction;
};

export function Header({
	onOpenDeleteWidgetAlert,
	widgetNameControl,
	onCloseEditMode,
	isSaveButtonDisabled,
	onSaveClick,
}: Props) {
	return (
		<CardHeader>
			<WidgetTitleBox>
				<WidgetTitleIconBox>
					<Setup />
				</WidgetTitleIconBox>
				<FormElement control={widgetNameControl}>
					<Input.Base placeholder='Widget Title' variantType='mini' />

					<ErrorTooltip showOnError='required'>
						<Text size='xSmall' font='mono' color='negative'>
							Field is required
						</Text>
					</ErrorTooltip>
				</FormElement>
			</WidgetTitleBox>

			<ActionButtonsBox>
				<DeleteButton onClick={onOpenDeleteWidgetAlert}>
					<Delete />
				</DeleteButton>

				<SaveButton color='secondary' disabled={isSaveButtonDisabled} onClick={onSaveClick}>
					<Text size='medium' bold color='base'>
						Save
					</Text>
				</SaveButton>

				<Button.Icon withBackgroundHover onClick={onCloseEditMode}>
					<Close />
				</Button.Icon>
			</ActionButtonsBox>
		</CardHeader>
	);
}
