import {CheckAll, Close, Correct, Setup} from '@esgi/ui/icons';
import {GridApi} from 'ag-grid-community';
import {Drawer, FlexBox} from '@esgi/ui/layout';
import {
	CheckboxWrapper,
	Content,
	ContentTitle,
	ContentTitleWrapper,
	Header,
	HeaderActions,
	HeaderTitle,
	Item,
	ItemDescription,
	ItemHeader,
	List,
	Root,
	SelectAllButton,
} from './styled';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {useCallback, useEffect, useState} from 'react';
import {isColDef} from '../../utils';
import {SettingUpPanelButtonClickedEvent} from '../setting-up-column/events';
import {useEventEffect} from '@esgillc/events';

export * from './use-table-customization';

type ColInfo = {
	field: string,
	headerName: string,
	description: string,
	lockVisible: boolean,
	hidden: boolean,
}

type Props = {
	tableName: string;
	api?: GridApi,
	onApplyTableCustomization: (updatedColumns: { name: string, isEnabled: boolean }[]) => void;
};

export function TableCustomizationDrawer({api, onApplyTableCustomization, ...props}: Props) {
	const [colDefs, setColDefs] = useState<ColInfo[]>([]);

	const [show, setShow] = useState(false);

	useEffect(() => {
		if (api) {
			const colDefs = api.getColumnDefs();
			const out: ColInfo[] = [];
			if (colDefs) {
				for (const colDef of colDefs) {
					if (isColDef(colDef)) {
						if (!colDef.field || colDef.headerComponentParams?.uiKitCreated) {
							continue;
						}

						const info = {} as ColInfo;
						info.headerName = colDef.headerName || '';
						info.field = colDef.field || '';
						info.lockVisible = colDef.lockVisible || false;
						info.description = colDef.headerComponentParams.description || '';

						if (colDef.colId) {
							const column = api.getColumn(colDef.colId);
							if (column) {
								info.hidden = !column.isVisible();
							}
						}
						out.push(info);
					}
				}
			}
			setColDefs(out);
		}
	}, [api, show]);

	useEventEffect(SettingUpPanelButtonClickedEvent, (event) => {
		if(event.id === api?.getGridId()) {
			setShow(true);
		}
	}, []);

	const closeDrawer = useCallback(() => setShow(false), []);

	const toggleHide = useCallback((colDef: ColInfo) => setColDefs((prev) => prev.map(item => {
		if (item.field === colDef.field && !colDef.lockVisible) {
			return {
				...colDef,
				hidden: !colDef.hidden,
			};
		}
		return item;
	})), []);

	const selectAll = useCallback(() => setColDefs((prev) => prev.map(item => {
		if (!item.lockVisible) {
			return {
				...item,
				hidden: false,
			};
		}
		return item;
	})), []);

	const apply = useCallback(() => {
		if (api) {
			const updatedColumns = colDefs.map(colDef => {
				const column = api.getColumn(colDef.field);
				if (column) {
					api.setColumnVisible(column, !colDef.hidden);
				}
				return {
					name: colDef.field,
					isEnabled: !colDef.hidden,
				};
			});
			onApplyTableCustomization(updatedColumns);
		}
		closeDrawer();
	}, [api, closeDrawer, colDefs, onApplyTableCustomization]);

	return <Drawer show={show} onClickOutside={closeDrawer}>
		<Root>
			<Header>
				<HeaderTitle>
					<FlexBox>
						<Setup style={{fontSize: 24}}/>
					</FlexBox>
					<Text size='small' color='mediumContrast'>Table Customization</Text>
				</HeaderTitle>
				<HeaderActions>
					<Button onClick={apply}>
						<Text size='large'>Select</Text>
					</Button>
					<Button.Icon onClick={closeDrawer}>
						<Close/>
					</Button.Icon>
				</HeaderActions>
			</Header>
			<Content>
				<ContentTitleWrapper>
					<ContentTitle>
						<Text size='small' font='mono' color='mediumContrast'>
							{props.tableName} Data Columns
						</Text>
					</ContentTitle>
				</ContentTitleWrapper>
				<FlexBox fullWidth>
					<SelectAllButton color='tertiary' onClick={selectAll}>
						<CheckAll/>
						<Text size='medium' bold>Select All</Text>
					</SelectAllButton>
				</FlexBox>
				<List>
					{colDefs.map((def, i) => <Item key={i} disabled={def.lockVisible}
					                               active={def.lockVisible ? undefined : !def.hidden}
					                               onClick={() => toggleHide(def)}>
						<ItemHeader>
							<Text color='currentColor' size='large' bold>{def.headerName}</Text>
							<CheckboxWrapper>
								{!def.hidden && <Correct/>}
							</CheckboxWrapper>
						</ItemHeader>
						<ItemDescription>
							<Text color='currentColor' size='small'>{def.description}</Text>
						</ItemDescription>
					</Item>)}
				</List>
			</Content>
		</Root>
	</Drawer>;
}