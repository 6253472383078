import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {useService} from '@esgi/core/service';

import {CardBox} from '../../card/styled';
import {AveragePerformanceService} from './service';
import {StudentView} from './variants/student-view';
import {ClassView} from './variants/class-view';
import {Container} from '../kit';

type Props = {
	testIDs: number[],
	studentIDs: number[],
	studentID: number,
	transparent?: boolean,
};

export function AveragePerformance(props: Props) {
	const service = useService(AveragePerformanceService);

	return <CardBox transparent={props.transparent} dataCy='widget-card'>
		<Container direction='column' justify='between' noPadding={props.transparent}>
			<Text color='neutral40' size='medium' data-cy='widget-name'>Yearly Average Performance</Text>
			<FlexBox justify='start' align='center'>
				{
					props.studentID ?
						<StudentView studentID={props.studentID} studentIDs={props.studentIDs} testIDs={props.testIDs}
						             service={service}/>
						:
						<ClassView studentIDs={props.studentIDs} testIDs={props.testIDs} service={service}/>
				}
			</FlexBox>
		</Container>
	</CardBox>;
}