import {PropsWithChildren} from 'react';
import {Container} from './index.styled';
import {CounterBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

type Props = PropsWithChildren<{
	count: number;
}>;

export function CounterWithTranscript({count, children}: Props) {
	return (
		<Container>
			<CounterBox color='blue'>
				<Text size='small' font='mono' color='primaryVivid'>
					{count}
				</Text>
			</CounterBox>
			{children}
		</Container>
	);
}
