import {Root} from './components/root';
import {TestTypeFilterTabs} from './components/test-type-filter-tabs';
import {useFilterGroup} from './hooks/use-filter-group';
import {useSortingData} from './hooks/use-sorting-data';
import {SortingComparator} from './sorting-comparators';

export const Filters = {
	Root,
	TestTypeFilterTabs,
	useFilterGroup,
	useSortingData,
	SortingComparator,
};
