import {SelectableList} from '@esgi/ui/layout';
import {SubjectModel} from '../../types/subject';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {randBoolean} from '@ngneat/falso';
import {ItemContent} from './item-content';
import {listGroupCSS} from './list.styled';

export const skeletonSubjects = new Array(30).fill(null).map<SubjectModel>((_, index) => ({
	id: index,
	hidden: randBoolean(),
	level: SubjectLevel.Teacher,
	name: '',
	type: SubjectType.Personal,
}));

export function Skeleton() {
	return (
		<SelectableList>
			<SelectableList.GroupRoot type='single'>
				<SelectableList.Group css={listGroupCSS}>
					{skeletonSubjects.map(({id, name, hidden}) => (
						<SelectableList.Item
							value={String(id)}
							key={`${id}-skeleton`}
							variant='outlined'
							setTextCurrentColor={false}
						>
							<ItemContent subjectName={name} hidden={hidden} skeleton subjectID={id} />
						</SelectableList.Item>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}
