import {Observable, tap} from 'rxjs';
import {ControlValue, FormControl, FormGroup, ValidatorArgs} from '@esgi/ui/form';
import {FieldValidation, TeacherEntityGroups} from '../types';

export type LocationFormType = FormGroup<{
	schoolID: FormControl<string>,
	teacherID: FormControl<string | null>,
	teacherEntityIDs: FormControl<TeacherEntityGroups>,
	specialistGroupIDs: FormControl<string[]>,
},
	ControlValue<{
		schoolID: FormControl<string>,
		teacherID: FormControl<string | null>,
		teacherEntityIDs: FormControl<TeacherEntityGroups>,
		specialistGroupIDs: FormControl<string[]>,
	}>>

export const teacherClassAssignValidator = (teacherID: FormControl<string | null>) => {
	return (validationChain: Observable<ValidatorArgs<TeacherEntityGroups>>) => {
		return validationChain.pipe(
			tap((validatorArgs) => {
				if (teacherID.value === null) {
					return validatorArgs;
				}

				if (validatorArgs.field.currentValue.classIDs.length === 0) {
					validatorArgs.errors.push(FieldValidation.AssignRequired);
				}

				return validatorArgs;
			})
		);
	};
};

export const createLocationForm = (): LocationFormType => (
	new FormGroup({
		schoolID: new FormControl(null),
		teacherID: new FormControl(null),
		teacherEntityIDs: new FormControl({
			groupIDs: [],
			classIDs: [],
		}),
		specialistGroupIDs: new FormControl([]),
	})
);
