import * as React from 'react';
import type {SVGProps} from 'react';

export function DatePeriod(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M19.1765 17.3642C19.6313 17.3642 20 16.9804 20 16.507C20 16.0336 19.6313 15.6499 19.1765 15.6499H12.8235C12.3687 15.6499 12 16.0336 12 16.507C12 16.9804 12.3687 17.3642 12.8235 17.3642H19.1765Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.3999 7C19.8693 7 20.2499 7.38056 20.2499 7.85V8.7C22.1275 8.7 23.6499 10.2224 23.6499 12.1V20.6C23.6499 22.4776 22.1275 24 20.2499 24H11.7499C9.87212 24 8.34985 22.4776 8.34985 20.6V12.1C8.34985 10.2224 9.87212 8.7 11.7499 8.7V7.85C11.7499 7.38056 12.1304 7 12.5999 7C13.0693 7 13.4499 7.38056 13.4499 7.85V8.7H18.5499V7.85C18.5499 7.38056 18.9304 7 19.3999 7ZM18.5499 11.25V10.4H13.4499V11.25C13.4499 11.7194 13.0693 12.1 12.5999 12.1C12.1304 12.1 11.7499 11.7194 11.7499 11.25V10.4C10.8109 10.4 10.0499 11.1608 10.0499 12.1V20.6C10.0499 21.5393 10.8109 22.3 11.7499 22.3H20.2499C21.1891 22.3 21.9499 21.5393 21.9499 20.6V12.1C21.9499 11.1608 21.1891 10.4 20.2499 10.4V11.25C20.2499 11.7194 19.8693 12.1 19.3999 12.1C18.9304 12.1 18.5499 11.7194 18.5499 11.25Z'
				fill='#333333'
			/>
		</svg>
	);
}
