import {Checkbox as CheckboxUi} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';
import {Checkbox as CheckboxFE} from '@esgi/ui/form-controls';

const checkboxCss: CSS = {
	'& > div': {
		minWidth: 'unset',
		width: 16,
		height: 16,
		borderColor: '$primaryHighlight',
	},

	'& > div:has(> span[data-state=checked])': {
		backgroundColor: '$primaryBackground',
	},
};

export const Checkbox = styled(CheckboxUi, checkboxCss);
export const CheckboxFormElement = styled(CheckboxFE, checkboxCss);
