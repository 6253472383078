import {TabItem} from '../types';
import {ItemId} from './types';

export const tabItems: TabItem<ItemId>[] = [
	{
		value: ItemId.PersonalInformation,
		label: 'Personal Information',
	},
	{
		value: ItemId.District,
		label: 'School & District',
	},
	{
		value: ItemId.MarkingPeriods,
		label: 'My School Calendar',
	},
	{
		value: ItemId.Login,
		label: 'Login Information',
	},
];
