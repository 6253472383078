import {styled} from '@esgi/ui/theme';
import {Box} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';

export const SectionBox = styled(Box, {
	width: 24,
	height: 24,

	'& svg': {
		width: '100%',
		height: '100%',

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				borderRadius: '50%',
			},
		},
	},
});
