import {ComponentPropsWithoutRef, ForwardedRef, forwardRef, useCallback, useState} from 'react';
import {Checkbox} from './index.styled';
import {CheckedState} from '@radix-ui/react-checkbox';
import {GridApi} from 'ag-grid-community';
import {AgGrid} from '@esgi/ui/ag-grid';
import {TestColorIndicator} from '@esgi/main/kits/common';

type Props<T extends Record<string, unknown>> = Omit<
	ComponentPropsWithoutRef<typeof Checkbox>,
	'checked' | 'onCheckedChange'
> & {
	api: GridApi<T> | null;
};

function ShowOnlySelectedCheckboxImpl<T extends Record<string, unknown>>(
	{dataCy = 'show-only-selected-checkbox', css = {}, label = 'Show Only Selected', api, ...props}: Props<T>,
	forwardedRef: ForwardedRef<HTMLButtonElement>,
) {
	const [isChecked, setIsChecked] = useState(false);

	const updateExternalFilter = AgGrid.useRegisterExternalFilter({gridApi: api});

	const handleCheckboxClick = useCallback(
		(checked: CheckedState) => {
			const value = Boolean(checked);

			setIsChecked(value);

			updateExternalFilter({
				isExternalFilterPresent: () => value,
				doesExternalFilterPass: (node) => node.isSelected() ?? false,
			});
		},

		[updateExternalFilter],
	);

	return (
		<Checkbox
			dataCy={dataCy}
			css={css}
			label={label}
			checked={isChecked}
			onCheckedChange={handleCheckboxClick}
			ref={forwardedRef}
			{...props}
		/>
	);
}

export const ShowOnlySelectedCheckbox = forwardRef(ShowOnlySelectedCheckboxImpl) as <T extends Record<string, unknown>>(
	props: Props<T> & {ref?: ForwardedRef<HTMLButtonElement>},
) => ReturnType<typeof ShowOnlySelectedCheckboxImpl>;
