import {PropsWithChildren, useRef, useState} from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {Tooltip} from '@esgi/ui/tooltip';
import {TooltipContent, TriggerText} from './styled';
import {Text} from '@esgi/ui/typography';
import {VariantProps} from '@stitches/react';
import {FlexBox} from '@esgi/ui/layout';

type Props = PropsWithChildren<VariantProps<typeof Text>>;

export function JustifyEndOneLinedText({children, ...textProps}: Props) {
	const [isTextTruncated, setIsTextTruncated] = useState(false);

	const textRef = useRef<HTMLElement>(null);

	useResizeObserver(textRef, ({target: {scrollWidth, clientWidth, scrollHeight, clientHeight}}) => {
		setIsTextTruncated(clientWidth < scrollWidth || clientHeight < scrollHeight);
	});

	return (
		<Tooltip open={!isTextTruncated ? false : undefined}>
			<Tooltip.Trigger>
				<FlexBox justify={!isTextTruncated ? 'end' : undefined}>
					<TriggerText ref={textRef} {...textProps}>
						{children}
					</TriggerText>
				</FlexBox>
			</Tooltip.Trigger>
			<TooltipContent>
				<Text size='large'>{children}</Text>
			</TooltipContent>
		</Tooltip>
	);
}
