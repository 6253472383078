import React from 'react';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {EventBusDispatcher, EventBusManager} from '@esgillc/events';
import {SsoTracker} from '@esgi/core/tracker';
import {LandingService} from '../../../services/landing-service';
import {FeaturedSeriesEvents} from '../../filter/featured-series/events';
import {Tray} from '../tray/tray';
import {Series, SeriesModel} from './series/series';
import {userStorage} from '@esgi/core/authentication';
import {TestScreenType} from '@esgi/core/enums';
import {UserSettingsChangedEvent} from 'shared/modules/user-settings/events';
import sortBy from 'lodash/sortBy';

export class FeaturedSeriesModel {
	id: number = 0;
	name: string = '';
}

class State {
	series: SeriesModel[] = [];
	loaded: boolean = false;
	showSelfAssessOption: boolean;
}

class Props {
	landingService: LandingService;
	onLoaded: () => void;
}

export default class FeaturedSeries extends React.Component<Props, State> {
	private featuredSeriesSub: Subscription;
	private readonly eventBus = new EventBusManager();

	constructor(props) {
		super(props);
		this.state = new State();
	}

	render() {
		if (this.state.series.length === 0) {
			return null;
		}

		return <Tray title='Featured Series'>
			{this.state.series
				.filter(t => {
					if (!t.testScreenTypes || t.testScreenTypes.length === 0) {
						return true;
					}
					if (this.state.showSelfAssessOption) {
						return true;
					}
					return t.testScreenTypes.filter(t => t !== TestScreenType.SelfAssessment).length !== 0;
				})
				.map((c, key) => {
				return <Series key={key}
				               id={c.id}
				               testScreenTypes={c.testScreenTypes}
				               name={c.name}
				               color={c.color}
				               description={c.description}
				               contentArea={c.contentArea}
				               gradeLevels={c.gradeLevels}
				               onClick={() => this.seriesClicked({id: c.id, name: c.name})}/>;
			})}
		</Tray>;
	}

	componentDidMount(): void {
		this.eventBus.subscribe(UserSettingsChangedEvent, (args: UserSettingsChangedEvent) => {
			if (args.showSelfAssessOption !== this.state.showSelfAssessOption) {
				this.setState({showSelfAssessOption: args.showSelfAssessOption});
			}
		});

		if (this.state.loaded) {
			return;
		}

		this.featuredSeriesSub = this.props.landingService.featuredSeries$
			.pipe(map(r => sortBy(r, x => x.orderNum).map(t => {
				let s = new SeriesModel();
				s.color = '#' + t.color;
				s.contentArea = t.contentArea ?? 'multiple';
				s.description = t.description;
				s.gradeLevels = t.gradeLevels;
				s.name = t.name;
				s.id = t.id;
				s.testScreenTypes = t.testScreenTypes;

				return s;
			}).filter(t =>t != null))).subscribe((r => {
				this.setState({series: r, loaded: true, showSelfAssessOption: userStorage.get().showSelfAssessOption}, () => this.props.onLoaded());
			}));
	}

	public componentWillUnmount() {
		this.eventBus.destroy();
		this.featuredSeriesSub.unsubscribe();
	}

	seriesClicked(featuredSeries: FeaturedSeriesModel) {
		SsoTracker.trackEvent({
			trackingEvent: 'FeaturedSeries',
			data: {seriesName: featuredSeries.name},
		});

		EventBusDispatcher.dispatch(FeaturedSeriesEvents.SeriesClicked, FeaturedSeriesEvents.SeriesClicked(featuredSeries));
	}
}
