import React, {Suspense, Fragment} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {SubjectType} from '@esgi/core/enums';

const RubricResultsReport = lazyComponent(() => import('modules/reports/rubric-report/'));

type Props = {
	subjectID: number;
	subjectType: SubjectType;
	hierarchy: HierarchyInstance;
	onClose: VoidFunction;
}

export function RubricReportLauncher(props: Props) {
	return <Suspense fallback={<Fragment/>}>
		<RubricResultsReport
			hierarchy={props.hierarchy.snapshot}
			subjectID={props.subjectID}
			subjectType={props.subjectType}
			onClosed={props.onClose}
		/>
	</Suspense>
}