import React, {useCallback, useEffect, useState} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {ErrorText} from '@esgi/ui';
import {Plus} from '@esgi/ui/icons';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/form-controls';
import {useService} from '@esgi/core/service';
import {Form, FormElement} from '@esgi/ui/form';
import {isUndefined, useBehaviorSubject, useFlag, useStreamEffect} from '@esgi/ui/utils';
import {Drawer, UnsavedChangesConfirmation, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {InputField} from './input-field';
import {AddTeacherService} from '../service';
import {teacherTitleList} from '../model';

type Props = {
	onClose: VoidFunction;
};

export function AddTeacher({onClose}: Props) {
	const drawerRef = useDrawerRef();
	const closeDrawer = useDrawerClose(drawerRef, onClose);

	const service = useService(AddTeacherService);
	const isDataLoaded = useBehaviorSubject(service.isDataLoaded$);
	const isBusy = useBehaviorSubject(service.isBusy$);

	const [showUnsavedChangesConfirmation, openUnsavedChangesConfirmation, closeUnsavedChangesConfirmation] = useFlag();
	const [isFormTouched, setIsFormTouched] = useState(false);

	const [isActionButtonDisabled, setActionButtonDisabled] = useState<boolean>(true);

	const handleActionButtonClick = useCallback(() => {
		service.addTeacher().subscribe(closeDrawer);
	}, [service, closeDrawer]);

	useEffect(() => {
		service.init();
	}, [service]);

	useStreamEffect(
		service.form.onChanged,
		({
			currState: {
				value: {title, firstName, lastName, email, importID, exportID, accountID},
			},
		}) => {
			const isFormTouched =
				[title[0]].some((item) => !isUndefined(item)) ||
				[firstName, lastName, email, importID, exportID, accountID].some((item) => item !== '');

			setIsFormTouched(isFormTouched);

			service.form.validate(true).subscribe((form) => {
				setActionButtonDisabled(!form.valid);
			});
		},
	);

	const onDrawerClose = useCallback(() => {
		if (isFormTouched) {
			openUnsavedChangesConfirmation();

			return;
		}

		closeDrawer();
	}, [closeDrawer, isFormTouched, openUnsavedChangesConfirmation]);

	return (
		<>
			<Drawer drawerRef={drawerRef} onClickOutside={onDrawerClose}>
				<Drawer.Header
					Icon={Plus}
					sectionName='Add New Teacher'
					actionButtonText='Add'
					closeDrawer={onDrawerClose}
					withActionButton
					onActionButtonClick={handleActionButtonClick}
					actionButtonDisabled={isActionButtonDisabled || isBusy}
				/>
				<Drawer.Body>
					<OverlayScrollbarsComponent options={{scrollbars: {autoHide: 'leave'}}} defer>
						<Form controller={service.form}>
							<GridBox gap={4}>
								<Drawer.ContentBlock title='Teacher Information' withDivider>
									<FormElement control={service.form.controls.title}>
										<Select.Root>
											<Select.Field placeholder='Title' skeleton={!isDataLoaded} />
											<Select.Content>
												{teacherTitleList.map((value) => (
													<Select.Option value={value} key={value}>
														<Text size='medium' bold>
															{value}
														</Text>
													</Select.Option>
												))}
											</Select.Content>
										</Select.Root>
										<ErrorText showOnError='required'>Title is required</ErrorText>
									</FormElement>
									<FormElement control={service.form.controls.firstName} disableValidateOnBlur>
										<InputField name='First Name' required />
									</FormElement>
									<FormElement control={service.form.controls.lastName} disableValidateOnBlur>
										<InputField name='Last Name' required />
									</FormElement>
									<FormElement control={service.form.controls.email} disableValidateOnBlur>
										<InputField name='Email' required />
									</FormElement>
									<GridBox flow='column' gap={3}>
										<FormElement control={service.form.controls.importID} disableValidateOnBlur>
											<InputField name='Import ID' />
										</FormElement>
										<FormElement control={service.form.controls.exportID} disableValidateOnBlur>
											<InputField name='Export ID' />
										</FormElement>
									</GridBox>
								</Drawer.ContentBlock>

								<Drawer.ContentBlock title='Login Credentials' withDivider>
									<FormElement control={service.form.controls.accountID} disableValidateOnBlur>
										<InputField name='Username' required />
									</FormElement>
								</Drawer.ContentBlock>
							</GridBox>
						</Form>
					</OverlayScrollbarsComponent>
				</Drawer.Body>
			</Drawer>

			{showUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onClose={closeUnsavedChangesConfirmation} onCloseAnyway={closeDrawer} />
			)}
		</>
	);
}
