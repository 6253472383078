import {useState} from 'react';
import {useStreamEffect} from '@esgi/ui';
import {FormControl} from '@esgi/ui/form';
import {
	BoxplotSinglePeriodForm,
	BoxplotSinglePeriodInitialFormData,
	BoxplotTwoPeriodsForm,
	BoxplotTwoPeriodsInitialFormData,
	BoxplotTwoClassesForm,
	BoxplotTwoClassesInitialFormData,
} from '../../forms-data/types';
import {BoxplotCompareVariant, DynamicFormProps} from '../../types';
import {SinglePeriod} from './components/single-period';
import {ClassModel} from '../../../../../../../types';
import {TwoPeriods} from './components/two-periods';
import {TwoClasses} from './components/two-classes';

type Props = DynamicFormProps<{
	singlePeriodForm: BoxplotSinglePeriodForm;
	twoPeriodsForm: BoxplotTwoPeriodsForm;
	twoClassesForm: BoxplotTwoClassesForm;
	allClassesList: ClassModel[];
	boxplotCompareVariant: FormControl<BoxplotCompareVariant>;
	initialBoxplotSinglePeriodForm: BoxplotSinglePeriodInitialFormData;
	initialBoxplotTwoPeriodsForm: BoxplotTwoPeriodsInitialFormData;
	initialBoxplotTwoClassesForm: BoxplotTwoClassesInitialFormData;
}>;

export function BoxplotData({
	singlePeriodForm,
	twoPeriodsForm,
	twoClassesForm,
	allClassesList,
	boxplotCompareVariant,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	initialBoxplotSinglePeriodForm,
	initialBoxplotTwoPeriodsForm,
	initialBoxplotTwoClassesForm,
	onCurrentDynamicFormNameChange,
}: Props) {
	const [compareVariant, setCompareVariant] = useState(boxplotCompareVariant.value);

	useStreamEffect(boxplotCompareVariant.onChanged, ({currState: {value}}) => {
		setCompareVariant(value);
	});

	switch (compareVariant) {
		case BoxplotCompareVariant.SinglePeriod:
			return (
				<SinglePeriod
					singlePeriodForm={singlePeriodForm}
					setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
					setIsDynamicFormDataValid={setIsDynamicFormDataValid}
					initialBoxplotSinglePeriodForm={initialBoxplotSinglePeriodForm}
					onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
				/>
			);
		case BoxplotCompareVariant.CompareTwoPeriods:
			return (
				<TwoPeriods
					twoPeriodsForm={twoPeriodsForm}
					setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
					setIsDynamicFormDataValid={setIsDynamicFormDataValid}
					initialBoxplotTwoPeriodsForm={initialBoxplotTwoPeriodsForm}
					onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
				/>
			);
		case BoxplotCompareVariant.CompareTwoClasses:
			return (
				<TwoClasses
					twoClassesForm={twoClassesForm}
					allClassesList={allClassesList}
					setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
					setIsDynamicFormDataValid={setIsDynamicFormDataValid}
					initialBoxplotTwoClassesForm={initialBoxplotTwoClassesForm}
					onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
				/>
			);
		default:
			return null;
	}
}
