import {useDisposable} from '@esgi/core/service';
import {V2SchoolAdminsFoldersUsersController} from '@esgi/contracts/esgi';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useCallback, useEffect} from 'react';
import {FolderType} from '../types';
import {dispatchAppEvent} from '@esgillc/events';
import {FolderUpdatedEvent} from '../events';

type Parameters = {
	id: number;
	type: FolderType;
	onFolderRemoved?: VoidFunction;
};

export function useRemoveFolder({id, type, onFolderRemoved}: Parameters) {
	const foldersUsersController = useDisposable(V2SchoolAdminsFoldersUsersController);

	const [removeUserFolderData, removeUserFolder] = useCancelableRequest(foldersUsersController.remove);

	useEffect(() => {
		if (isAsyncSucceed(removeUserFolderData)) {
			onFolderRemoved?.();

			dispatchAppEvent(
				FolderUpdatedEvent,
				new FolderUpdatedEvent({
					type,
					id,
				}),
			);
		}
	}, [id, removeUserFolderData, type]);

	return useCallback(() => {
		removeUserFolder({
			groupID: id,
		});
	}, [removeUserFolder, id]);
}
