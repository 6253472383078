import {Button, ContextMenu as ContextMenuUI} from '@esgi/ui';
import {Delete, More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {useCallback, useState} from 'react';
import {DeleteGroupAlert} from './delete-group-alert';
import {Group} from '@esgi/main/libs/school-admin-store';

type Props = {
	closeDrawer: VoidFunction;
	currentGroup: Group;
};

export function ContextMenu({closeDrawer, currentGroup}: Props) {
	const [showDeleteGroupAlert, setShowDeleteGroupAlert] = useState(false);

	const openDeleteClassAlert = useCallback(() => {
		setShowDeleteGroupAlert(true);
	}, []);

	const closeDeleteClassAlert = useCallback(() => {
		setShowDeleteGroupAlert(false);
	}, []);

	return (
		<>
			<ContextMenuUI.Root open={showDeleteGroupAlert ? false : undefined}>
				<ContextMenuUI.Trigger>
					<Button.Icon withBackgroundHover>
						<More />
					</Button.Icon>
				</ContextMenuUI.Trigger>
				<ContextMenuUI.Content>
					<ContextMenuUI.Group>
						<ContextMenuUI.Item onClick={openDeleteClassAlert}>
							<Delete />
							<Text size='medium'>Delete</Text>
						</ContextMenuUI.Item>
					</ContextMenuUI.Group>
				</ContextMenuUI.Content>
			</ContextMenuUI.Root>

			{showDeleteGroupAlert && (
				<DeleteGroupAlert onClose={closeDeleteClassAlert} onDeleted={closeDrawer} currentGroup={currentGroup} />
			)}
		</>
	);
}
