export const secondStepMessage: string[] = ['The widget data will be ready shortly. Helpful data is on the way!'];

export const dataСountingMessages: string[] = [
	'Widget generation is taking a bit longer, since the data utilized by this widget is large.',
	'This should be ready in the next few minutes.',
	'Thank you for your patience as we work to bring your data to life!',
];

export const maxLoaderStepTime = {
	first: 5,
	second: 30,
};
