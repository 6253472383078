import {styled} from '@esgi/ui/theme';
import {TableRow as TableRowBase} from '../table.styled';
import {DeltaCheap} from '@esgi/main/features/admins/dashboard';
import {GridBox} from '@esgi/ui/layout';
import {cellRowGap} from './constants';

export const TableRow = styled(TableRowBase, {
	gridTemplateColumns: '1fr 1fr 125px 96px 60px',
});

export const CellBox = styled(GridBox, {
	rowGap: cellRowGap,
});

export const ResultCheap = styled(DeltaCheap, {
	width: 32,
	height: 16,
	alignItems: 'center',
	justifyContent: 'center',
	alignSelf: 'center',
	justifySelf: 'end',
});
