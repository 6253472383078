import {Suspense, useMemo} from 'react';
import {SelectedSnapshot} from 'modules/hierarchy/models';
import {SubjectType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';
import {HierarchyInstance} from 'modules/hierarchy/core/models';
import {useFakeChangesCollector} from './utils';

const StudentProgressReportLauncher = lazyComponent(() => import('modules/reports/student-progress-report/launcher'));

type Props = {
	subjectId: number;
	subjectType: SubjectType;
	hierarchy: HierarchyInstance;
	onClose: VoidFunction;
}

export function StudentProgressLauncher(props: Props) {
	const changesCollector = useFakeChangesCollector(props.hierarchy);
	const options = useMemo(() => new SelectedSnapshot(props.hierarchy.snapshot), []);

	return <Suspense fallback={<></>}>
		<StudentProgressReportLauncher subjectId={props.subjectId}
		                               subjectType={props.subjectType}
		                               classId={options.classID}
		                               groupId={options.groupID}
		                               specialistGroupID={options.specialistGroupID}
		                               studentId={options.studentID}
		                               teacherId={options.teacherID}
		                               districtID={options.districtID}
		                               schoolID={options.schoolID}
		                               changesCollector={changesCollector}
		                               hierarchy={props.hierarchy.snapshot}
		                               onClose={props.onClose}
		/>
	</Suspense>;
}
