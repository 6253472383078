import {SkeletonShape} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {PageTitle} from '../components/page-title';

export function SkeletonMode() {
	return (
		<>
			<PageTitle />

			<GridBox flow='column' gap='4' align='center'>
				<SkeletonShape shape='square' width={245} height={40} />

				<GridBox flow='column' gap='4' align='center'>
					<SkeletonShape width={24} height={24} />
					<SkeletonShape width={1.5} height={20} />
					<SkeletonShape width={24} height={24} />
					<SkeletonShape width={1.5} height={20} />
					<SkeletonShape width={24} height={24} />
					<SkeletonShape width={1.5} height={20} />
					<SkeletonShape width={28} height={28} />
				</GridBox>
			</GridBox>
		</>
	);
}
