import {BaseCheap} from './components/base-cheap';
import {CheapBox} from './components/cheap-box';
import {District} from './variant/district';
import {School} from './variant/school';
import {Stock} from './variant/stock';
import {Teacher} from './variant/teacher';

export const SubjectCheap = {
	CheapBox,
	BaseCheap,
	Variant: {
		District,
		School,
		Teacher,
		Stock,
	},
};
