import {Select} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const Option = styled(Select.Option, {
	variants: {
		withAdditionalLabel: {
			true: {
				'& > span': {
					display: 'grid',
					gridTemplateColumns: '1fr auto',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '$2',
				},
			},
		},

		hidden: {
			true: {
				display: 'none',
			},
		},
	},
});
