import {useMemo} from 'react';
import {allGradeLevel} from '../../../../constants';
import {GradeLevel, gradeLevelDictionary} from '@esgi/main/kits/common';
import {OptionItem} from '../../../select/types';
import {SortByFullItem} from './types';
import {CardsSortBy} from '../../../../types/section';

type Parameters = {
	uniqGradeLevelsIDs: number[];
	showEachStudent: boolean;
};

export function useSelectsItems({uniqGradeLevelsIDs, showEachStudent}: Parameters) {
	const gradeLevelsItems = useMemo(() => {
		if (!uniqGradeLevelsIDs.length) {
			return null;
		}

		const gradeLevels = uniqGradeLevelsIDs.map((id) => gradeLevelDictionary[id]).filter(Boolean) as GradeLevel[];
		const allGradeLevels = [allGradeLevel, ...gradeLevels];

		return allGradeLevels.map<OptionItem<string>>(({id, name}) => ({
			value: String(id),
			label: name,
		}));
	}, [uniqGradeLevelsIDs]);

	const sortByItems = useMemo(() => {
		const items: SortByFullItem[] = [
			{
				value: CardsSortBy.Default,
				label: 'Default',
				hidden: false,
			},
			{
				value: CardsSortBy.LastTested,
				label: 'Last Tested',
				hidden: !showEachStudent,
			},
			{
				value: CardsSortBy.StudentTestedPercentage,
				label: 'Student Tested Percentage',
				hidden: false,
			},
			{
				value: CardsSortBy.TestName,
				label: 'Test Name',
				hidden: false,
			},
		];

		return items.filter(({hidden}) => !hidden).map<OptionItem<CardsSortBy>>(({label, value}) => ({label, value}));
	}, [showEachStudent]);

	return {
		gradeLevelsItems,
		sortByItems,
	};
}
