export enum ReportType {
	Grade= 1,
	Growth,
	TotalStudents,
	StudentsProgress,
	StudentsDetail,
	Total,
	ItemAnalysis,
	PieCharts,
	TeacherActivity,
	ParentLetter,
	RubricResults,
	UntestedStudents,

}

export type SubjectInfo = {
	id: number,
	level: string,
	type: string,
}

export type Hierarchy = Partial<{
	districtID: number,
	schoolID: number,
	preAssessID?: number,
	teacherID?: number,
	classID?: number,
	groupID?: number,
	specialistGroupID?: number,
	specialistsID?: number,
	schoolsGroupID?: number,
	teachersGroupID?: number,
	studentID?: number,
}>