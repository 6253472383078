export function getAdaptedArraysWidgetData<
	Data extends Record<keyof Data, unknown>,
	FullData extends Record<keyof FullData, unknown>,
	FullDataKey extends keyof FullData,
>({data, fullData, iteratedKey}: {data: Data[]; fullData: FullData[]; iteratedKey: FullDataKey}) {
	if (!data?.length) {
		return fullData.map((item) => item[iteratedKey]);
	}

	return data;
}
