import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(FlexBox, {
	flexDirection: 'column',
	paddingTop: 16,
	paddingBottom: 16,
	paddingInline: 20,
	gap: '$5',
	overflow: 'hidden',
});
