import {Class, Group, School, Student, User} from '@esgi/main/libs/admin-store';
import {isUndefined} from '@esgi/ui';
import {useMemo} from 'react';

type Parameters = {
	skeleton: boolean;
	groupID: Group['id'];
	groups: Group[];
	classes: Class[];
	teachers: User[];
	schools: School[];
	studentsList: Student[];
};

type Out = {
	school: School | null;
	primaryTeacher: User | null;
	currentClass: Class | null;
	currentGroup: Group | null;
	studentsIDsInGroup: Student['id'][];
	allGroupsInCurrentClass: Group[];
};

export function useData({skeleton, groupID, groups, classes, teachers, schools, studentsList}: Parameters) {
	return useMemo<Out>(() => {
		const currentGroup = groups.find(({id}) => id === groupID);

		if (skeleton || isUndefined(currentGroup)) {
			return {
				school: null,
				primaryTeacher: null,
				currentClass: null,
				currentGroup: null,
				studentsIDsInGroup: [],
				allGroupsInCurrentClass: [],
			};
		}

		const school = schools.find(({id}) => id === currentGroup.schoolID);
		const primaryTeacher = teachers.find(({id}) => id === currentGroup.teacherID);
		const currentClass = classes.find(({id}) => id === currentGroup.classID);

		const allGroupsInCurrentClass = groups.filter(({classID}) => classID === currentClass?.id);

		const studentsIDsInGroup = studentsList
			.filter(({groupsIDs}) => groupsIDs.includes(currentGroup.id))
			.map(({id}) => id);

		if (isUndefined(school)) {
			throw new Error('school is undefined');
		}

		if (isUndefined(primaryTeacher)) {
			throw new Error('primaryTeacher is undefined');
		}

		if (isUndefined(currentClass)) {
			throw new Error('currentClass is undefined');
		}

		return {
			school,
			primaryTeacher,
			currentClass,
			currentGroup,
			studentsIDsInGroup,
			allGroupsInCurrentClass,
		};
	}, [classes, groupID, groups, schools, skeleton, studentsList, teachers]);
}
