import {Box, FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Body = styled(FlexBox, {
	gap: 20,
	flexDirection: 'column',
	paddingTop: 20,
	paddingBottom: '$6',
	paddingInline: 20,
	height: '100%',
	background: 'linear-gradient(126deg, #F0F0F0 0.11%, #EBEBEB 71.15%)',
	overflow: 'hidden',
});

export const TopBarContainer = styled(FlexBox, {
	justifyContent: 'space-between',
	paddingLeft: '$3',
});

export const SelectedEntityInfo = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'start',
	gap: 6,
});

export const RightSideContainer = styled(FlexBox, {
	alignItems: 'center',
	gap: '$4',
});

export const Separator = styled(Box, {
	width: 1,
	height: 20,
	backgroundColor: '$surface',
	borderRadius: 1,
});
