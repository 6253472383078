import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';

export const TabsControllerRoot = styled(ToggleGroup.Root, {
	padding: '2px',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '6px',
	width: '100%',
	marginBottom: '20px',
});

export const TabsControllerItem = styled(ToggleGroup.Item, {
	variants: {
		error: {
			true: {
				'&[data-state=on], &[data-state=off]': {
					[`& > ${Text}`]: {
						color: '$negative',
					},
				},
			},
		},
	},
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
	gridTemplateColumns: '1fr 1fr 1fr',
});


