import {useMemo} from 'react';
import {usePreAssessSpecialists, useDistrictSpecialists, useSchoolSpecialists} from '@esgi/main/libs/admin-store';

import {ReportDialog, Reports} from '../reports-dialog';
import {isEmpty} from 'underscore';

type Props = {
	onClose: VoidFunction;
};

export function DistrictReportsDialog({onClose}: Props) {
	const [{data: preAssessSpecialists}] = usePreAssessSpecialists();
	const [{data: districtSpecialists}] = useDistrictSpecialists();
	const [{data: schoolSpecialists}] = useSchoolSpecialists();

	const hasPreAssess = useMemo(() => !isEmpty(preAssessSpecialists), [preAssessSpecialists]);
	const hasSpecialists = useMemo(
		() => !isEmpty(districtSpecialists) || !isEmpty(schoolSpecialists),
		[districtSpecialists, schoolSpecialists],
	);

	return (
		<ReportDialog onClose={onClose} header='District'>
			<ReportDialog.Section title='District Reports'>
				<Reports.GradeScale
					name='District Grades'
					description='Apply customizable grade scales to school scores'
					hierarchy={{}}
				/>
				<Reports.Growth name='District Growth' description='View growth on tests in a Subject Tab' hierarchy={{}} />
				<Reports.TotalStudents
					name='District Students'
					description='Review and compare student scores for a subject tab at the District level'
					hierarchy={{}}
				/>
				<Reports.Totals
					name='District Totals'
					description='Review and compare school scores for a subject tab'
					hierarchy={{}}
				/>
				<Reports.ItemAnalysis hierarchy={{}} />
				<Reports.PieCharts hierarchy={{}} />
				<Reports.UsageReport name='Teacher Activity' description='View teacher usage of ESGI' hierarchy={{}} />
				{hasPreAssess && (
					<Reports.PreAssessActivityReport
						name='Pre-assess activity'
						description='View Pre-Assess usage of ESGI'
						hierarchy={{}}
					/>
				)}
				{hasSpecialists && (
					<Reports.SpecialistActivityReport
						name='Specialist Activity'
						description='View specialist usage of ESGI'
						hierarchy={{}}
					/>
				)}
			</ReportDialog.Section>
		</ReportDialog>
	);
}
