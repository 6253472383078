import {PerformanceData} from '@esgi/main/features/admins/dashboard';
import {
	DistrictPerformanceMetricData,
	DistrictPerformanceMetricDataContract,
} from '@esgi/main/features/district-admin/dashboard';
import {isNull} from 'underscore';

export function parseDistrictPerformanceData(
	data: DistrictPerformanceMetricDataContract,
): DistrictPerformanceMetricData | null {
	if (isNull(data)) {
		return null;
	}

	return {
		...data,
		periods: data.periods.map(({data, ...periodData}) => ({
			...periodData,
			data: data as Record<number, PerformanceData>,
		})),
	};
}
