import React, {useEffect, useMemo} from 'react';
import {ReportType, SubjectType} from '@esgi/core/enums';
import {lazyClass} from '@esgi/core/react';
import {
	ClassicHierarchyLevel,
	HierarchyInstance,
	HierarchyMode,
	SpecialistHierarchyLevel,
} from 'modules/hierarchy/core/models';
import {SsoTracker} from '@esgi/core/tracker';
import {UserType} from '@esgi/core/authentication';
import {useFakeChangesCollector} from './utils';

const totalReportModal = lazyClass(() => import('shared/modules/reports/total-report/total-report-modal'));

type Props = {
	hierarchy: HierarchyInstance,
	subjectId: number,
	subjectType: SubjectType,
	globalSchoolYearId: number,
	onClose: VoidFunction,
}

function resolveFunctionType(hierarchy: HierarchyInstance) {
	if (hierarchy.mode === HierarchyMode.Classic) {
		const selected = hierarchy.classic.selected;
		switch (selected.level) {
			case ClassicHierarchyLevel.Class:
				return {type: ReportType.Class, id: selected.levelID};
			case ClassicHierarchyLevel.Student:
				if (hierarchy.classic.groupID) {
					return {type: ReportType.Group, id: hierarchy.classic.groupID};
				} else {
					return {type: ReportType.Class, id: hierarchy.classic.classID};
				}
			case ClassicHierarchyLevel.Group:
				return {type: ReportType.Group, id: selected.levelID};
			case ClassicHierarchyLevel.Teacher:
				return {type: ReportType.Teacher, id: selected.levelID};
			case ClassicHierarchyLevel.School:
				return {type: ReportType.School, id: selected.levelID};
			case ClassicHierarchyLevel.District:
				return {type: ReportType.District, id: selected.levelID};
			case ClassicHierarchyLevel.SchoolsGroup:
				return {type: ReportType.SchoolsGroup, id: selected.levelID};
			case ClassicHierarchyLevel.TeachersGroup:
				return {type: ReportType.TeachersGroup, id: selected.levelID};
		}
	}

	if (hierarchy.mode === HierarchyMode.Specialist) {
		const s = hierarchy.specialist;
		if (s.userID === 0) {
			if (s.type === UserType.ISS && s.filter.schoolID > 0) {
				return {type: ReportType.SchoolSpecialist, id: s.filter.schoolID};
			} else if(s.groupOfSpecialistsID != 0) {
				return {type: ReportType.GroupOfSpecialists, id: s.groupOfSpecialistsID};
			} else {
				return {type: ReportType.DistrictSpecialist, id: s.districtID};
			}
		} else {
			return {type: ReportType.SpecialistGroup, id: s.groupID};
		}
	}

	if (hierarchy.mode === HierarchyMode.PreAssess) {
		const pa = hierarchy.preAssess;
		if (pa.userID === 0) {
			return {type: ReportType.DistrictPreassess, id: pa.districtID};
		} else {
			return {type: ReportType.SpecialistGroup, id: pa.groupID};
		}
	}

	return {type: ReportType.None, id: 0};
}

function resolvePrefix(hierarchy: HierarchyInstance) {
	if (hierarchy.mode === HierarchyMode.Classic) {
		const selected = hierarchy.classic.selected;
		switch (selected.level) {
			case ClassicHierarchyLevel.Class:
				return 'Class';
			case ClassicHierarchyLevel.Group:
				return 'Group';
			case ClassicHierarchyLevel.Teacher:
				return ''; //not supported
			case ClassicHierarchyLevel.School:
				return 'School';
			case ClassicHierarchyLevel.District:
				return 'District';
			case ClassicHierarchyLevel.SchoolsGroup:
				return 'School Group';
			case ClassicHierarchyLevel.TeachersGroup:
				return 'Teacher Group';
			case ClassicHierarchyLevel.Student:
				return hierarchy.classic.groupID > 0 ? 'Group' : 'Class';
		}
	}

	if (hierarchy.mode === HierarchyMode.Specialist) {
		const s = hierarchy.specialist;
		const selected = hierarchy.specialist.selected;

		if (s.userID === 0) {
			if(selected.level === SpecialistHierarchyLevel.GroupOfSpecialists){
				return 'Specialist Group'
			}
			if (s.type === UserType.ISS && s.filter.schoolID > 0) {
				return 'School';
			} else {
				return 'District';
			}
		} else {
			return 'Group';
		}
	}

	if (hierarchy.mode === HierarchyMode.PreAssess) {
		const pa = hierarchy.preAssess;

		if (pa.userID === 0) {
			return 'District';
		} else {
			return 'Group';
		}
	}

	return '';
}

export function TotalReportLauncher(props: Props) {
	const type = useMemo(() => resolveFunctionType(props.hierarchy), []);
	const prefix = useMemo(() => resolvePrefix(props.hierarchy), []);
	const changesCollector = useFakeChangesCollector(props.hierarchy);

	useEffect(() => {
		SsoTracker.trackEvent({
			trackingEvent: prefix + 'TotalsReport',
		});

		totalReportModal.load().then(trm => {
			const ctr = new trm({
				globalSchoolYearId: props.globalSchoolYearId,
				reportId: type.id,
				subjectId: props.subjectId,
				reportType: type.type,
				subjectType: props.subjectType,
			}, changesCollector, props.hierarchy.snapshot);

			ctr.load();

			ctr.events.onClosed(props.onClose);
		});
	}, []);

	return <></>;
}
