import {Dispatch, useCallback} from 'react';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {OneLinedText} from '@esgi/main/kits/common';
import {
	SortableState,
	SortDirection,
	StudentsSinglePeriodData,
	StudentsSinglePeriodSortableKey,
} from '../../../../../../types';
import {TableHeaderCellSortable} from '../../../table-header-cell-sortable';
import {studentsOnePeriodSortableCells} from '../../../../../../constants';
import {JustifyEndOneLinedText} from '../../../justify-end-one-lined-text';
import {TableRowCell} from '../../../table.styled';
import {Table, TableRow} from './index.styled';
import {WidgetCard} from '@esgi/main/features/admins/dashboard';

type Props = {
	sortableState: SortableState<StudentsSinglePeriodSortableKey>;
	setSortableState: Dispatch<SortableState<StudentsSinglePeriodSortableKey>>;
	disabledSortableKeys: StudentsSinglePeriodSortableKey[];
	showEmptyContentBox: boolean;
	emptyBoxHeight: number;
	onStudentClick: Dispatch<number | null>;
};

export function SinglePeriod({
	setSortableState,
	sortableState,
	disabledSortableKeys,
	showEmptyContentBox,
	emptyBoxHeight,
	onStudentClick,
}: Props) {
	const {visibleData, pageChange} = WidgetCard.useWidgetCardContext<StudentsSinglePeriodData>();

	const sortableCellClick = useCallback(
		({cellKey, nextSortDirection}: {cellKey: StudentsSinglePeriodSortableKey; nextSortDirection: SortDirection}) => {
			setSortableState({
				key: cellKey,
				direction: nextSortDirection,
			});

			pageChange(0);
		},
		[setSortableState, pageChange],
	);

	return (
		<Table>
			<TableRow>
				{studentsOnePeriodSortableCells.map(({key, label, justifyContent}) => (
					<TableHeaderCellSortable
						cellKey={key}
						cellName={label}
						onCellClick={sortableCellClick}
						isActive={sortableState.key === key}
						sortDirection={sortableState.direction}
						disableSorting={disabledSortableKeys.includes(key)}
						justifyContent={justifyContent}
						key={key}
					/>
				))}
			</TableRow>

			{visibleData.map(({studentID, student, teacher, class: classValue, grade, avg, rowID}) => (
				<TableRow key={rowID}>
					<TableRowCell onClick={() => onStudentClick(studentID)} cursor='pointer'>
						<OneLinedText size='medium' bold color='primary'>
							{student}
						</OneLinedText>
					</TableRowCell>

					<TableRowCell>
						<OneLinedText size='medium' color='base'>
							{teacher ?? <>&mdash;</>}
						</OneLinedText>
					</TableRowCell>

					<TableRowCell>
						<OneLinedText size='medium' color='base'>
							{classValue}
						</OneLinedText>
					</TableRowCell>

					<JustifyEndOneLinedText size='medium' color='base'>
						{grade ?? <>&mdash;</>}
					</JustifyEndOneLinedText>

					<TableRowCell justify='end'>
						<Text size='medium' color='base'>
							{avg.toFixed(1)}
						</Text>
					</TableRowCell>
				</TableRow>
			))}

			{showEmptyContentBox && <Box css={{height: emptyBoxHeight}} />}
		</Table>
	);
}
