import {useMemo} from 'react';
import {SelectedEntityInfo, SelectedEntityInfoItem} from '../../kit';
import {TestContentArea} from '@esgi/main/kits/common';
import {useWidgetCardContext} from '../context';
import {isUndefined} from '@esgi/ui';

type Props = {
	contentAreaIDs: number[];
};

export function SelectedContentAreasInfo({contentAreaIDs}: Props) {
	const {allContentAreas} = useWidgetCardContext();

	const contentAreaItems = useMemo(() => {
		return contentAreaIDs.map<SelectedEntityInfoItem<TestContentArea>>((contentAreaID) => {
			const contentArea = allContentAreas.find(({id}) => id === contentAreaID);

			if (isUndefined(contentArea)) {
				throw new Error('contentArea is undefined');
			}

			return {
				label: contentArea.name,
				value: contentArea.value,
			};
		});
	}, [allContentAreas, contentAreaIDs]);

	return (
		<SelectedEntityInfo
			items={contentAreaItems}
			itemsTranscript='Content Areas'
			totalPossibleItemsCount={allContentAreas.length}
		/>
	);
}
