import * as React from 'react';
import type {SVGProps} from 'react';

export function Level(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.2117 7C21.0507 7 20.8793 7.03979 20.7382 7.11842L12.2145 11.8553C11.9142 12.022 11.7409 12.3403 11.7409 12.6842V13.5035L7.52353 15.6129C7.20268 15.7734 7 16.1014 7 16.4602V24.0392C7 24.5624 7.42402 24.9866 7.94708 24.9866H12.5282C12.5802 24.9954 12.6336 25 12.688 25H16.4176C16.9404 25 17.3647 24.5756 17.3647 24.0526C17.3647 23.5297 16.9404 23.1053 16.4176 23.1053H13.6351V13.2469L21.4778 8.89474H23.1058V23.1053H20.294C19.7712 23.1053 19.3469 23.5297 19.3469 24.0526C19.3469 24.5756 19.7712 25 20.294 25H24.0529C24.5757 25 25 24.5756 25 24.0526V7.94737C25 7.42442 24.5757 7 24.0529 7H21.2117ZM8.89415 17.0457L11.7409 15.6219V23.0918H8.89415V17.0457Z'
				fill='#333333'
			/>
			<path
				d='M15.8104 21.2105C15.8104 21.4721 16.0225 21.6842 16.2841 21.6842H21.0143C21.276 21.6842 21.488 21.4721 21.488 21.2105V20.5938C21.488 20.3322 21.276 20.1201 21.0143 20.1201H19.6653V13.1579C19.6653 12.8963 19.4532 12.6842 19.1916 12.6842H18.3106L15.5395 14.2976L16.2661 15.6893L17.7563 14.8136V20.1201H16.2841C16.0225 20.1201 15.8104 20.3322 15.8104 20.5938V21.2105Z'
				fill='#333333'
			/>
		</svg>
	);
}
