import {GridProgressBar as GridProgressBarUI, GridProgressBarItem, isUndefined} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {ComponentPropsWithoutRef, forwardRef} from 'react';

type Props = ComponentPropsWithoutRef<typeof GridBox> & {
	progressBarTitle?: string;
	items: GridProgressBarItem[];
	chartSize?: number;
};

export const GridProgressBar = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'test-card-grid-progress-bar', css = {}, chartSize = 48, progressBarTitle, items, children, ...props},
		forwardedRef,
	) => (
		<GridBox dataCy={dataCy} css={css} gap={4} display='inlineGrid' ref={forwardedRef} {...props}>
			<GridBox gap='3' flow='column'>
				<GridProgressBarUI
					items={items}
					boxSize={chartSize}
					activeItemColor='positive'
					inactiveItemColor='positiveMuted'
				/>
				{children}
			</GridBox>

			{!isUndefined(progressBarTitle) && (
				<Text size='small' font='mono' color='mediumContrast'>
					{progressBarTitle}
				</Text>
			)}
		</GridBox>
	),
);
