import {
	SubjectPublishType,
	SubjectPublishTypeNumerable,
	SubjectPublishValue,
	TrackDate,
} from '@esgi/main/features/admins/subject-management';
import {InitSubjectData} from '../../../types';
import {mapToEnum} from 'shared/utils';
import moment from 'moment';

type Parameters = {
	initSubjectData: InitSubjectData;
	allTrackDates: TrackDate[];
};

export function getPublishPeriodInitValue({initSubjectData, allTrackDates}: Parameters): SubjectPublishValue {
	const mappedValue = mapToEnum(initSubjectData.publishType, SubjectPublishTypeNumerable);

	if (mappedValue === SubjectPublishTypeNumerable.Indefinitely) {
		return {
			type: SubjectPublishType.Indefinitely,
			dateFrom: null,
			dateTo: null,
			trackDatesIDs: [],
		};
	}

	if (mappedValue === SubjectPublishTypeNumerable.SchoolYear) {
		return {
			type: SubjectPublishType.SchoolYear,
			dateFrom: null,
			dateTo: null,
			trackDatesIDs: [],
		};
	}

	if (mappedValue === SubjectPublishTypeNumerable.MarkingPeriod) {
		const filteredTrackDates = allTrackDates.filter(({trackID, dateFrom, dateTo}) => {
			const trackDateFrom = moment(dateFrom);
			const trackDateTo = moment(dateTo);

			const comparedDateFrom = moment(initSubjectData.publishFromDate);
			const comparedDateTo = moment(initSubjectData.publishToDate);

			return (
				trackID === initSubjectData.publishTrackID &&
				(comparedDateFrom.isSame(trackDateFrom) || trackDateFrom.isBetween(comparedDateFrom, comparedDateTo)) &&
				(comparedDateTo.isSame(trackDateTo) || trackDateTo.isBetween(comparedDateFrom, comparedDateTo))
			);
		});

		return {
			type: SubjectPublishType.MarkingPeriod,
			dateFrom: null,
			dateTo: null,
			trackDatesIDs: filteredTrackDates.map(({trackDateID}) => trackDateID),
		};
	}

	if (mappedValue === SubjectPublishTypeNumerable.CustomDateRange) {
		return {
			type: SubjectPublishType.CustomDateRange,
			dateFrom: initSubjectData.publishFromDate,
			dateTo: initSubjectData.publishToDate,
			trackDatesIDs: [],
		};
	}

	return {
		type: SubjectPublishType.Off,
		dateFrom: null,
		dateTo: null,
		trackDatesIDs: [],
	};
}
