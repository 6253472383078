import {ColorPickerItem} from '../kit';
import {Color} from '../types';
import {WidgetCard} from '../widget-card';

function getColorPickerItem(value: Color) {
	const {activeQuartileBarColor, nonActiveQuartileBarColor} = WidgetCard.chartColor[value];

	const item: ColorPickerItem<Color> = {
		fillColor: activeQuartileBarColor,
		borderColor: nonActiveQuartileBarColor,
		value,
	};

	return item;
}

export const colorItems: ColorPickerItem<Color>[] = [
	Color.Blue,
	Color.Yellow,
	Color.Red,
	Color.Purple,
	Color.Green,
	Color.Grey,
	Color.Orange,
	Color.Indigo,
].map(getColorPickerItem);
