import {PerformanceWidgetViewType, ToggleGroupItemModel, WidgetViewSize} from '@esgi/main/features/admins/dashboard';
import {ChartCandle, ChartPie, SizeDefault, SizeFull} from '@esgi/ui/icons';

export const viewTypeItems: ToggleGroupItemModel<PerformanceWidgetViewType>[] = [
	{
		value: PerformanceWidgetViewType.Candles,
		label: 'Boxplots',
		Icon: ChartCandle,
	},
	{
		value: PerformanceWidgetViewType.Piechart,
		label: 'Pie Charts',
		Icon: ChartPie,
	},
];

export const sizeItems: ToggleGroupItemModel<WidgetViewSize>[] = [
	{
		value: WidgetViewSize.Default,
		label: 'Default',
		Icon: SizeDefault,
	},
	{
		value: WidgetViewSize.FullSize,
		label: 'Full Size',
		Icon: SizeFull,
	},
];
