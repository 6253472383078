import {TestPanel} from '@esgi/main/kits/test-results-panel';
import {styled} from '@esgi/ui/theme';

export const CardsContent = styled(TestPanel.TestCardsContent, {
	variants: {
		isEmptyContent: {
			true: {
				height: '100%',
			},
		},
	},
});
