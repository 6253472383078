import {GridStackOptions} from 'gridstack';
import {cardsGap, rearrangeHandleClassName} from '../../constants';

export const minRows = 1;

export const gridStackStaticInitOptions: GridStackOptions = {
	acceptWidgets: false,
	alwaysShowResizeHandle: false,
	animate: true,
	auto: false,
	cellHeightUnit: 'px',
	disableResize: true,
	float: false,
	margin: cardsGap,
	marginUnit: 'px',
	minRow: minRows,
	styleInHead: true,
	handle: `.${rearrangeHandleClassName}`,
};
