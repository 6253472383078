import {SubjectsSchoolController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {GradeLevel, ItemNumerable, TrackDate} from '@esgi/main/features/admins/subject-management';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useEffect, useMemo, useState} from 'react';
import {InitSubjectData} from '../types';
import {useSchoolSpecialists} from '@esgi/main/libs/school-admin-store';
import {getFullName} from '@esgi/main/kits/common';

type Parameters = {
	subjectID: number;
};

export function useInitData({subjectID}: Parameters) {
	const [{data: schoolSpecialistsStore, loaded: isSchoolSpecialistsLoaded}] = useSchoolSpecialists();

	const controller = useDisposable(SubjectsSchoolController);

	const [initData, init] = useCancelableRequest(controller.initEdit);

	const [trackID, setTrackID] = useState<number | null>(null);
	const [allTrackDates, setAllTrackDates] = useState<TrackDate[]>([]);
	const [gradeLevels, setGradeLevels] = useState<GradeLevel[]>([]);

	const [initSubjectData, setInitSubjectData] = useState<InitSubjectData | null>(null);

	useEffect(() => {
		init({
			districtID: undefined as unknown as number,
			globalSchoolYearID: undefined as unknown as number,
			schoolID: undefined as unknown as number,
			subjectID,
		});
	}, [subjectID]);

	useEffect(() => {
		if (isAsyncSucceed(initData)) {
			const {gradeLevels, trackDates, tracks} = initData.data.commonModel;

			setTrackID(tracks[0]?.id ?? null);

			setAllTrackDates(
				trackDates.map(({trackID, trackDateID, dateFrom, dateTo}) => ({trackID, trackDateID, dateFrom, dateTo})),
			);

			setGradeLevels(gradeLevels.map(({gradeLevelID, name, shortName}) => ({id: gradeLevelID, name, shortName})));
			setInitSubjectData(initData.data.editModel);
		}
	}, [initData]);

	const schoolSpecialists = useMemo<ItemNumerable[]>(
		() => schoolSpecialistsStore.map(({id, firstName, lastName}) => ({id, name: getFullName({firstName, lastName})})),
		[schoolSpecialistsStore],
	);

	return {
		trackID,
		allTrackDates,
		gradeLevels,
		schoolSpecialists,
		isDataLoaded: isAsyncSucceed(initData) && isSchoolSpecialistsLoaded,
		initSubjectData,
	};
}
