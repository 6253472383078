import {useCallback} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {GridBox} from '@esgi/ui/layout';
import {Drawer} from '@esgi/main/kits/common';
import {DisabledTrackDates} from '../../../../components/disabled-track-dates';
import {SetActivePeriodButton} from './components/set-active-period-button';
import {PanelHeaderTitle} from '../../../../components/panel-header-title';
import {ContentBox} from '../../../../components/content-box.styled';
import {DisabledInput} from '../../../../components/disabled-input';
import {PanelContent} from '../../../../components/panels.styled';
import {trackTypeTranscript} from '../../../../../../constants';
import {EntireSidebar} from './components/entire-sidebar';
import {Transcript} from './components/components.styled';
import {DrawerPanelHeader} from '../../../index.styled';
import {TrackModel} from '../../../../../../types';
import {TrackList} from './components/track-list';
import {OnTrackClick} from '../types';
import {Text} from '@esgi/ui/typography';

type Props = {
	tracks: TrackModel[];
	visibleTrack: TrackModel;
	activeUserTrackId: TrackModel['trackID'];
	onTrackClick: OnTrackClick;
	setActiveTrackId: (trackId: TrackModel['trackID']) => void;
};

export function ViewMode({tracks, activeUserTrackId, visibleTrack, onTrackClick, setActiveTrackId}: Props) {
	const handleTrackClick = useCallback((track: TrackModel) => {
		onTrackClick({track});
	}, [onTrackClick]);

	return (
		<>
			<EntireSidebar>
				<TrackList
					activeUserTrackId={activeUserTrackId}
					onTrackClick={handleTrackClick}
					tracks={tracks}
					visibleTrackId={visibleTrack.trackID}
				/>
			</EntireSidebar>

			<DrawerPanelHeader withActionButton={false}>
				<PanelHeaderTitle title={visibleTrack.name} />
			</DrawerPanelHeader>

			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<PanelContent>
					<ContentBox>
						<Drawer.ContentBlock title='Current Marking Period'>
							<SetActivePeriodButton
								activeUserTrackId={activeUserTrackId}
								currentUserTrackId={visibleTrack.trackID}
								setActiveTrackId={setActiveTrackId}
							/>
							<Transcript>
								<Text size='small'>
									Setting a Marking Period as active only affects your account and allows for easy date range selection while configuring widgets on the Dashboard page.
								</Text>
							</Transcript>
							<GridBox flow='column' gap='3'>
								<DisabledInput dataCy='current-marking-period-input' value={visibleTrack.name} placeholder='Marking Period Name' />
								<DisabledInput dataCy='current-marking-period-select' value={trackTypeTranscript[visibleTrack.schoolYearType]} placeholder='Type' />
							</GridBox>
						</Drawer.ContentBlock>

						<Drawer.ContentBlock title='Marking Period Dates'>
							<DisabledTrackDates rowGap='20' track={visibleTrack} />
						</Drawer.ContentBlock>
					</ContentBox>
				</PanelContent>
			</OverlayScrollbarsComponent>
		</>
	);
}
