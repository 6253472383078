import {createContext, JSX, PropsWithChildren, useCallback, useContext, useMemo, useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {Hierarchy, OldReport, ReportType} from '@esgi/main/features/school-admin/data';
import {ReportButton} from './report-button';
import {Alert} from '@esgi/ui/alert';
import {useUser} from '../../../../../../../../libs/core/src/authentication';
import {useFirstAdminSubject} from './use-first-admin-subject';
import {
	AssignmentIcon, Cast, PieChartIcon,
	File, Growth as GrowthIcon,
	GradeScale as GradeScaleIcon, Preview, RubricReport,
	StudentProgress as StudentProgressIcon,
	StudentCard,
	Students, Users,
} from '@esgi/ui';
import {Body, Content, ContentHeader} from './styled';


type Props = {
	header: string | JSX.Element,
	title: string,
	onClose: VoidFunction,
	loading?: boolean,
} & PropsWithChildren;

type ButtonProps = {
	name?: string,
	description?: string,
	prefix?: string,
	hierarchy: Hierarchy,
};

type Context = {
	runReport: (report: ReportType, hierarchy: Hierarchy) => void;
	loading: boolean;
}

const ReportDialogContext = createContext<Context>(null);

function ParentLetter(props: ButtonProps) {
	const {
		name = 'Parent Letter',
		description = 'Print student results with personalized messaging for parent review',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.ParentLetter, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={File} skeleton={context.loading}/>;
}
function Growth(props: ButtonProps) {
	const {
		name = 'Growth',
		description = 'View growth on tests in a Subject Tab',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.Growth, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={GrowthIcon} skeleton={context.loading}/>;
}
function TotalStudents(props: ButtonProps) {
	const {
		name = 'Total Students',
		description = 'Review and compare student scores for a subject tab at the District level',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.TotalStudents, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={Students} skeleton={context.loading}/>;
}
function StudentDetail(props: ButtonProps) {
	const {
		name = 'Student Detail',
		description = 'Analyze a comprehensive breakdown of correct/incorrect items, scoring, and student progress over time',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.StudentsDetail, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={StudentCard} skeleton={context.loading}/>;
}
function StudentProgress(props: ButtonProps) {
	const {
		name = 'Student Progress',
		description = 'Review and compare student scores for a subject tab at the District level',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.StudentsProgress, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={StudentProgressIcon} skeleton={context.loading}/>;
}
function Totals(props: ButtonProps) {
	const {
		name = `${props.prefix} Totals`,
		description = 'Review and compare student scores for a subject tab',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.Total, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={AssignmentIcon} skeleton={context.loading}/>;
}
function GradeScale(props: ButtonProps) {
	const {
		name = `${props.prefix} Grades`,
		description = 'Apply customizable grade scales to student scores',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.Grade, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={GradeScaleIcon} skeleton={context.loading}/>;
}
function ItemAnalysis(props: ButtonProps) {
	const {
		name = 'Item Analysis',
		description = 'Analyze data based on least and most known items',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.ItemAnalysis, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={Preview} skeleton={context.loading}/>;
}
function PieCharts(props: ButtonProps) {
	const {
		name = 'Pie Charts',
		description = 'Access a printable report of homepage pie charts',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.PieCharts, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={PieChartIcon} skeleton={context.loading}/>;
}
function RubricResults(props: ButtonProps) {
	const {
		name = 'Rubric Results',
		description = 'View results from rubric tests',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.RubricResults, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={RubricReport} skeleton={context.loading}/>;
}
function UntestedStudents(props: ButtonProps) {
	const {
		name = 'Untested Students',
		description = 'Identify untested students to administer assessments',
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.UntestedStudents, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={Users} skeleton={context.loading}/>;
}
function UsageReport(props: ButtonProps) {
	const {
		name,
		description,
	} = props;
	const context = useContext(ReportDialogContext);
	const handleClick = useCallback(() => context.runReport(ReportType.TeacherActivity, props.hierarchy), [props.hierarchy]);
	return <ReportButton name={name} description={description} onClick={handleClick} icon={Cast} skeleton={context.loading}/>;
}

export const Reports = {
	ParentLetter,
	StudentDetail,
	StudentProgress,
	Totals,
	GradeScale,
	ItemAnalysis,
	PieCharts,
	RubricResults,
	UntestedStudents,
	Growth,
	TotalStudents,
	UsageReport,
};

export function ReportDialog(props: Props) {
	const user = useUser();

	const [reportType, setReportType] = useState<ReportType>();
	const [currentHierarchy, setCurrentHierarchy] = useState<Hierarchy>();

	const [subject, subjectLoaded] = useFirstAdminSubject();

	const ref = Alert.useRef();
	const close = Alert.useClose(ref, props.onClose);

	const context = useMemo<Context>(() => ({
		runReport: (report, h) => {
			setCurrentHierarchy({...h, districtID: user?.districtID, schoolID: user?.schoolID});
			setReportType(report);
		},
		loading: props.loading,
	}), [props.loading]);

	if (reportType && subject) {
		return <OldReport report={reportType}
		                  onClose={() => setReportType(undefined)}
		                  firstSubject={subject}
		                  hierarchy={currentHierarchy}
		/>;
	}

	return <Alert minWidth={486} modalManagerRef={ref} skeleton={props.loading || subjectLoaded}>
		<Alert.Header onCloseIconClick={close} withBacklight={false}>
			<Text size='small' color='highContrast'>
				{props.header}
			</Text>
		</Alert.Header>
		<Alert.Body>
			<Body>
				<ContentHeader>
					<Text font='mono' color='mediumContrast' size='small'>
						{props.title}
					</Text>
				</ContentHeader>
				<Content>
					<ReportDialogContext.Provider value={context}>
						{props.children}
					</ReportDialogContext.Provider>
				</Content>
			</Body>
		</Alert.Body>
	</Alert>;
}


