import {ComponentPropsWithoutRef, PropsWithChildren, forwardRef} from 'react';
import {Container, TitleBox, TooltipContent} from './index.styled';
import {BaseComponentProps, Tooltip} from '@esgi/ui';
import {Info} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {FlexBox} from '@esgi/ui/layout';
import {usePanelContext} from '../../context';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<typeof Container>, 'skeleton'> &
	NonNullable<PropsWithChildren> & {
		sectionTitle?: string;
		tipText?: string;
	};

export const EntireSectionBlock = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'entire-section-block', css = {}, children, sectionTitle, tipText, ...props}, forwardedRef) => {
		const {skeleton} = usePanelContext();

		return (
			<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props} skeleton={skeleton}>
				{Boolean(!skeleton && (sectionTitle || tipText)) && (
					<TitleBox>
						{Boolean(sectionTitle) && (
							<Text size='xSmall' font='mono' color='mediumContrast'>
								{sectionTitle}
							</Text>
						)}

						{Boolean(tipText) && (
							<Tooltip>
								<Tooltip.Trigger>
									<FlexBox display='inlineFlex'>
										<Info height={16} width={16} />
									</FlexBox>
								</Tooltip.Trigger>
								<TooltipContent variant='default'>
									<Text size='large'>{tipText}</Text>
								</TooltipContent>
							</Tooltip>
						)}
					</TitleBox>
				)}

				{children}
			</Container>
		);
	},
);
