import {styled} from '@esgi/ui/theme';
import {animated} from 'react-spring';

export const PanelBox = styled(animated.div, {
	bottom: 0,
	left: 0,
	width: '100%',
	zIndex: 450,
	boxShadow: '0px 0px 8px 0px rgba(61, 47, 41, 0.12), 0px 0px 40px 0px rgba(61, 47, 41, 0.08)',

	variants: {
		isOpenAnimateFinish: {
			true: {
				position: 'relative',
			},

			false: {
				position: 'absolute',
			},
		},

		fullPanelHeight: {
			true: {
				position: 'absolute',
				display: 'grid',
				gridTemplateRows: 'auto 1fr',
				height: '100vh',
				overflow: 'hidden',
			},
		},
	},
});

export const Overlay = styled(animated.div, {
	width: '100%',
	height: '100%',
	backgroundColor: '$mild',
	position: 'absolute',
	top: 0,
	left: 0,
	zIndex: 400,
});
