import {
	Color,
	GradeLevel,
	WidgetViewSizeNumerable,
	ColorNumerable,
	PeriodTypeNumerable,
	PeriodType,
	ContentAreaModel,
	PerformanceWidgetViewTypeNumerable,
	PerformanceWidgetViewType,
	WidgetViewSize,
	DemographicGroupTypeNumerable,
	DemographicGroupType,
} from '@esgi/main/features/admins/dashboard';
import {
	DemographicPerformanceWidgetOptions,
	SchoolDemographicPerformanceWidgetOptionsContract,
	ClassModel,
	SubjectTab,
} from '@esgi/main/features/school-admin/dashboard';
import {isNull} from 'underscore';
import {getAdaptedArraysWidgetData} from '../../helpers/get-adapted-arrays-widget-data';
import {enumNumberToString} from '../enum-number-to-string';

export function parseSchoolDemographicPerformanceOptions({
	options,
	classes: classesList,
	subjectTabs,
	gradeLevels,
	contentAreasList,
}: {
	options: SchoolDemographicPerformanceWidgetOptionsContract | null;
	classes: ClassModel[];
	subjectTabs: SubjectTab[];
	gradeLevels: GradeLevel[];
	contentAreasList: ContentAreaModel[];
}): DemographicPerformanceWidgetOptions | null {
	if (isNull(options)) {
		return null;
	}

	const {
		contentAreaIDs,
		gradeLevelIDs,
		classIDs,
		subjectIDs,
		viewType,
		viewSize,
		demographicGroup,
		periods,
		candles,
		classes,
		...restOptions
	} = options;

	return {
		...restOptions,

		/**
		 * For the following entities, if the data array is empty, it means all entities are selected:
		 * - contentAreaIDs,
		 * - gradeLevelIDs,
		 * - classIDs,
		 * - subjectIDs
		 */

		contentAreaIDs: getAdaptedArraysWidgetData({
			data: contentAreaIDs,
			fullData: contentAreasList,
			iteratedKey: 'id',
		}),
		gradeLevelIDs: getAdaptedArraysWidgetData({
			data: gradeLevelIDs,
			fullData: gradeLevels,
			iteratedKey: 'id',
		}),
		classIDs: getAdaptedArraysWidgetData({
			data: classIDs,
			fullData: classesList,
			iteratedKey: 'classID',
		}),
		subjectIDs: getAdaptedArraysWidgetData({
			data: subjectIDs,
			fullData: subjectTabs,
			iteratedKey: 'id',
		}),
		viewType: enumNumberToString(viewType, PerformanceWidgetViewTypeNumerable) as PerformanceWidgetViewType,
		viewSize: enumNumberToString(viewSize, WidgetViewSizeNumerable) as WidgetViewSize,
		demographicGroup: enumNumberToString(demographicGroup, DemographicGroupTypeNumerable) as DemographicGroupType,
		candles: !isNull(options.candles)
			? {...candles, color: enumNumberToString(candles.color, ColorNumerable) as Color}
			: null,
		classes: classes.map(({color, id}) => ({
			id,
			color: enumNumberToString(color, ColorNumerable) as Color,
		})),
		periods: periods.map(({color, datePeriod, dateRange, trackDatesID}) => ({
			dateRange: isNull(dateRange) ? null : dateRange,
			datePeriod: enumNumberToString(datePeriod, PeriodTypeNumerable) as PeriodType,
			trackDatesID,
			color: enumNumberToString(color, ColorNumerable) as Color,
		})),
	};
}
