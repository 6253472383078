import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {HeaderBox} from './index.styled';

type Props = ComponentPropsWithoutRef<typeof HeaderBox>;

export const Header = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'data-page-header', css = {}, children, ...props}, forwardedRef) => {
		return (
			<HeaderBox dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{children}
			</HeaderBox>
		);
	},
);
