import {PerformanceData} from '@esgi/main/features/admins/dashboard';
import {
	SchoolPerformanceMetricData,
	SchoolPerformanceMetricDataContract,
} from '@esgi/main/features/school-admin/dashboard';
import {isNull} from 'underscore';

export function parseSchoolPerformanceData(
	data: SchoolPerformanceMetricDataContract,
): SchoolPerformanceMetricData | null {
	if (isNull(data)) {
		return null;
	}

	return {
		...data,
		periods: data.periods.map(({data, ...periodData}) => ({
			...periodData,
			data: data as Record<number, PerformanceData>,
		})),
	};
}
