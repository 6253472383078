import {isNull} from 'underscore';
import {SubjectPublishType} from '@esgi/main/features/admins/subject-management';
import {SubjectSettingData} from '../../../types';
import {primitiveKeys} from './constants';
import {isTwoPrimitiveArraysEqual} from './helpers/is-two-primitive-arrays-equal';
import {isTwoDateEqual} from './helpers/is-two-date-equal';
import {isTwoExpandableSelectableBlockEqual} from './helpers/is-two-expandable-selectable-block-equal';

type Parameters = {
	initData: SubjectSettingData | null;
	currentData: SubjectSettingData;
};

export function useFormTouched({initData, currentData}: Parameters) {
	if (isNull(initData)) {
		return false;
	}

	if (primitiveKeys.some((key) => initData[key] !== currentData[key])) {
		return true;
	}

	if (!isTwoPrimitiveArraysEqual(initData.selectedGradeLevels, currentData.selectedGradeLevels)) {
		return true;
	}

	if (initData.publishPeriodValue.type !== currentData.publishPeriodValue.type) {
		return true;
	}

	if (
		currentData.publishPeriodValue.type === SubjectPublishType.MarkingPeriod &&
		!isTwoPrimitiveArraysEqual(initData.publishPeriodValue.trackDatesIDs, currentData.publishPeriodValue.trackDatesIDs)
	) {
		return true;
	}

	if (
		currentData.publishPeriodValue.type === SubjectPublishType.CustomDateRange &&
		(!isTwoDateEqual(initData.publishPeriodValue.dateFrom, currentData.publishPeriodValue.dateFrom) ||
			!isTwoDateEqual(initData.publishPeriodValue.dateTo, currentData.publishPeriodValue.dateTo))
	) {
		return true;
	}

	if (!isTwoExpandableSelectableBlockEqual(initData.publishForSpecialistsData, currentData.publishForSpecialistsData)) {
		return true;
	}

	return false;
}
