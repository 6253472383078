import {Select} from '@esgi/ui/form-controls';
import {Text} from '@esgi/ui/typography';
import {useState} from 'react';

type Props = {
	label: string;
	value: string;
};

export function SelectOption({value, label}: Props) {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<Select.Option
			value={value}
			data-highlighted={isHovered ? '' : undefined}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<Text size='medium' bold>
				{label}
			</Text>
		</Select.Option>
	);
}
