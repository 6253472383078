import {Dispatch, Ref, SetStateAction} from 'react';
import {DistrictPerformanceWidgetOptions} from '../../../../types/district-performance';

export type DynamicFormDataRef = {
	getData: () => Pick<DistrictPerformanceWidgetOptions, 'periods' | 'candles'>;
};

export type DynamicFormProps<P = unknown> = P & {
	dynamicFormDataRef: Ref<DynamicFormDataRef | undefined>;
	setIsDynamicFormDataTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataValid: Dispatch<SetStateAction<boolean>>;
};

export enum BoxplotCompareVariant {
	SinglePeriod = 'SinglePeriod',
	CompareTwoPeriods = 'CompareTwoPeriods',
}
