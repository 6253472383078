import {ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback, useEffect} from 'react';
import {ButtonIconable} from './index.styled';
import {BaseComponentProps, Tooltip} from '@esgi/ui';
import {Plus} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {usePanelContext} from '../../../context';
import {TestPanelMode} from '../../../types/section';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<typeof ButtonIconable>, 'children' | 'skeleton'> & {
		tooltipText?: string;
	};

export const AddTestsButton = forwardRef<HTMLButtonElement, Props>(
	({dataCy = 'tests-panel-add-tests-button', css = {}, tooltipText, disabled, onClick, ...props}, forwardedRed) => {
		const {panelMode, skeleton, setPanelMode} = usePanelContext();

		const isAddMode = panelMode === TestPanelMode.Add;

		const handleButtonClick = useCallback(
			(event: MouseEvent<HTMLButtonElement>) => {
				setPanelMode(isAddMode ? TestPanelMode.View : TestPanelMode.Add);

				onClick?.(event);
			},
			[isAddMode, onClick, setPanelMode],
		);

		useEffect(() => {
			return () => {
				if (isAddMode) {
					setPanelMode(TestPanelMode.View);
				}
			};
		}, [isAddMode]);

		return (
			<Tooltip open={tooltipText && !skeleton ? undefined : false}>
				<Tooltip.Trigger>
					<ButtonIconable
						dataCy={dataCy}
						css={css}
						disabled={panelMode === TestPanelMode.Rearrange || disabled || skeleton}
						active={isAddMode}
						skeleton={Boolean(skeleton)}
						withBackgroundHover
						onClick={handleButtonClick}
						ref={forwardedRed}
						{...props}
					>
						<Plus />
					</ButtonIconable>
				</Tooltip.Trigger>
				<Tooltip.Content variant='secondary'>
					<Text size='small'>{tooltipText}</Text>
				</Tooltip.Content>
			</Tooltip>
		);
	},
);
