import * as React from 'react';
import type {SVGProps} from 'react';

export function MoreV(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				d='M16 21a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM16 8a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z'
			/>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16 14.5a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
