import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {Box, GridBox} from '@esgi/ui/layout';

export const SectionDescription = styled(Text, {
	padding: '8px',
	whiteSpace: 'pre-line',
});

export const SectionContent = styled(GridBox, {
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'start',
	borderRadius: '8px',
	width: '100%',
	padding: '12px',
	gap: '4px',

	'& > div': {
		marginBottom: 0,
	},
});

export const CheckboxWrapper = styled(Box, {
	width: '100%',
	padding: '4px',
});
