import * as React from 'react';
import type {SVGProps} from 'react';

export function SizeDefault(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				d='M8.5 8C7.67157 8 7 8.67157 7 9.5V14.5C7 15.3284 7.67157 16 8.5 16H13.5C14.3284 16 15 15.3284 15 14.5V9.5C15 8.67157 14.3284 8 13.5 8H8.5Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17 9.5C17 8.67157 17.6716 8 18.5 8H23.5C24.3284 8 25 8.67157 25 9.5V14.5C25 15.3284 24.3284 16 23.5 16H18.5C17.6716 16 17 15.3284 17 14.5V9.5ZM18.5 9.3H23.5C23.6105 9.3 23.7 9.38954 23.7 9.5V14.5C23.7 14.6105 23.6105 14.7 23.5 14.7H18.5C18.3895 14.7 18.3 14.6105 18.3 14.5V9.5C18.3 9.38954 18.3895 9.3 18.5 9.3Z'
				fill='#333333'
			/>
			<path
				d='M7 19.65C7 19.291 7.29101 19 7.65 19H24.35C24.709 19 25 19.291 25 19.65C25 20.009 24.709 20.3 24.35 20.3H7.65C7.29102 20.3 7 20.009 7 19.65Z'
				fill='#333333'
			/>
			<path
				d='M7.65 23C7.29101 23 7 23.291 7 23.65C7 24.009 7.29102 24.3 7.65 24.3H24.35C24.709 24.3 25 24.009 25 23.65C25 23.291 24.709 23 24.35 23H7.65Z'
				fill='#333333'
			/>
		</svg>
	);
}
