import * as React from 'react';
import type {SVGProps} from 'react';

export function StudentsList(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.889 8C10.6801 8 8.88898 9.79111 8.88898 12C8.88898 14.2089 10.6801 16 12.889 16C15.0979 16 16.889 14.2089 16.889 12C16.889 9.79111 15.0979 8 12.889 8ZM12.889 9.77778C14.1165 9.77778 15.1112 10.7724 15.1112 12C15.1112 13.2276 14.1165 14.2222 12.889 14.2222C11.6614 14.2222 10.6668 13.2276 10.6668 12C10.6668 10.7724 11.6614 9.77778 12.889 9.77778Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.83299 17.1947C8.20277 17.8676 7.11121 19.5164 7.11121 21.3333V23.1111C7.11121 23.6018 7.50943 24 8.0001 24H17.7779C18.2685 24 18.6668 23.6018 18.6668 23.1111V21.3333C18.6668 19.5591 17.569 17.9502 15.945 17.2498C15.7157 17.1511 15.4499 17.1484 15.2223 17.2498C14.4357 17.6027 13.6694 17.7778 12.889 17.7778C12.1112 17.7778 11.3423 17.5929 10.5281 17.2222C10.3041 17.12 10.0605 17.1004 9.83299 17.1947ZM10.1752 19.0133C11.0152 19.3316 12.0277 19.5556 12.889 19.5556C13.7432 19.5556 14.7139 19.3467 15.537 19.0427C16.3397 19.4996 16.889 20.3547 16.889 21.3333V22.2222H8.88898V21.3333C8.88898 20.2978 9.47209 19.4249 10.1752 19.0133Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.5557 11.5556C19.065 11.5556 18.6668 11.9535 18.6668 12.4444C18.6668 12.9354 19.065 13.3333 19.5557 13.3333H24.0001C24.4908 13.3333 24.889 12.9354 24.889 12.4444C24.889 11.9535 24.4908 11.5556 24.0001 11.5556H19.5557ZM18.6668 15.1111C18.1761 15.1111 17.7779 15.5091 17.7779 16C17.7779 16.4909 18.1761 16.8889 18.6668 16.8889H24.0001C24.4908 16.8889 24.889 16.4909 24.889 16C24.889 15.5091 24.4908 15.1111 24.0001 15.1111H18.6668ZM21.3334 18.6667C20.8428 18.6667 20.4445 19.0646 20.4445 19.5556C20.4445 20.0465 20.8428 20.4444 21.3334 20.4444H24.0001C24.4908 20.4444 24.889 20.0465 24.889 19.5556C24.889 19.0646 24.4908 18.6667 24.0001 18.6667H21.3334Z'
				fill='#333333'
			/>
		</svg>
	);
}
