import {useMemo} from 'react';
import {NoStudentsAlert, ReportDialog, Reports} from '../../../../../../_kit';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useUser} from '@esgi/core/authentication';
import {RowDef} from '../../../../../types';

type Props = {
	selectedGroup: RowDef;
	onClose: VoidFunction;
};

export function ReportsDialog({onClose, selectedGroup}: Props) {
	const user = useUser();

	const hierarchy = useMemo<Hierarchy>(
		() => ({
			districtID: user?.districtID,
			schoolID: selectedGroup.school.id,
			teacherID: selectedGroup.teacher.id,
			classID: selectedGroup.class.id,
			groupID: selectedGroup.id,
		}),
		[selectedGroup, user],
	);

	if (!selectedGroup.students) {
		return <NoStudentsAlert onClose={onClose} />;
	}

	return (
		<ReportDialog header={selectedGroup.name} onClose={onClose}>
			<ReportDialog.Section title='Student Reports'>
				<Reports.ParentLetter hierarchy={hierarchy} />
				<Reports.StudentDetail hierarchy={hierarchy} />
				<Reports.StudentProgress hierarchy={hierarchy} />
			</ReportDialog.Section>
			<ReportDialog.Section title='Group Reports'>
				<Reports.Totals prefix='Group' hierarchy={hierarchy} />
				<Reports.GradeScale prefix='Group' hierarchy={hierarchy} />
				<Reports.ItemAnalysis hierarchy={hierarchy} />
				<Reports.PieCharts hierarchy={hierarchy} />
				<Reports.RubricResults hierarchy={hierarchy} />
				<Reports.UntestedStudents hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
