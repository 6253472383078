import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CheckboxesBox = styled(GridBox, {
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$surface',
	gap: 4,
	paddingTop: 7,
	paddingBottom: 7,
	paddingLeft: 8,
	paddingRight: 8,
});
