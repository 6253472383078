import {HierarchyInstance} from 'modules/hierarchy/core/models';
import React from 'react';
import {SubjectType} from '@esgi/core/enums';
import UntestedStudentsReportModal from 'modules/reports/untested-students-report/components/modal';
import {useFakeChangesCollector} from './utils';

class Props {
	subjectID: number;
	subjectType: SubjectType;
	hierarchy: HierarchyInstance;
	onClose: VoidFunction;
}

export function UntestedStudentsButton({
	                                       subjectID,
	                                       subjectType,
	                                       hierarchy,
	                                       onClose,
                                       }: Props) {
	const changesCollector = useFakeChangesCollector(hierarchy);
	return <UntestedStudentsReportModal hierarchy={hierarchy.snapshot}
	                                    subjectType={subjectType}
	                                    subjectID={subjectID}
	                                    changesCollector={changesCollector}
	                                    onClose={onClose}/>;
}
