import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ContentBox = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr auto',
	gap: '$4',
	alignItems: 'center',
});

export const MainBox = styled(FlexBox, {
	gap: 6,
	alignItems: 'center',
});

export const ActionButtonsBox = styled(FlexBox, {
	gap: '$4',
	alignItems: 'center',
});

export const ActionButtonDivider = styled(Box, {
	width: 1,
	height: 24,
	borderRadius: 1,
	backgroundColor: '$border',
});