import {isUndefined} from '@esgi/ui';

type Options = {
	precise: number;
};

export function roundNumber(number: number, options?: Options): number {
	if (isUndefined(options)) {
		return Math.round(number);
	}

	const {precise} = options;

	return Number(number.toFixed(precise));
}
