import {Alert} from '@esgi/ui/alert';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		width: 508,
		gap: 20,
	},
};

export const AlertHeader = styled(Alert.Header, {
	height: 64,

	'& > div': {
		gridTemplateColumns: '1fr auto',
		gap: '$4',

		'& > div': {
			display: 'grid',
			gridTemplateColumns: 'auto 1fr',
			alignItems: 'center',
			gap: '$2',
			color: '$negative',
		},
	},
});

export const StudentNameBox = styled(GridBox, {
	gap: '$1',
	alignItems: 'center',
	gridTemplateColumns: 'auto 1fr',

	'& > svg': {
		width: 24,
		height: 24,
		fill: '$base',

		'& path': {
			fill: '$base',
		},
	},
});

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: 28,
});

export const DoNotShowBox = styled(FlexBox, {
	padding: 8,
	alignItems: 'center',
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$surface',
	gap: '$1',
});

export const AlertFooter = styled(Alert.Footer, {
	paddingTop: 'unset',
});
