import {Container, Divider} from './index.styled';
import {useCallback, useEffect, useState} from 'react';
import {useSubjects} from './hooks/use-subjects';
import {useTests} from './hooks/use-tests';
import {SubjectsPanel} from './components/subject-panel';
import {isNull} from 'underscore';
import {TestsInfoPanel} from './components/tests-info-panel';
import {EmptyContent} from './components/empty-content';
import {noTestsMessage, selectSubjectMessage} from './constants';
import {SelectedStudent} from './types/section';
import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';

type Props = {
	student: SelectedStudent;
};

export function FullActionPanel({student}: Props) {
	const [selectedSubject, setSelectedSubject] = useState<SubjectModel | null>(null);

	const {isSubjectsLoaded, firstSelectedSubjectID, subjectsEntities, hasSubjects, getSubjectByID} = useSubjects({
		teacherID: student.teacherID,
	});

	const {isTestsLoaded, testCards, removeTestByID, moveTestToSubject} = useTests({
		selectedSubject,
		student,
	});

	const setSelectedSubjectByID = useCallback(
		(subjectID: SubjectModel['id'] | null) => {
			setSelectedSubject(isNull(subjectID) ? null : getSubjectByID(subjectID));
		},
		[getSubjectByID],
	);

	useEffect(() => {
		if (isSubjectsLoaded && isNull(selectedSubject)) {
			setSelectedSubjectByID(firstSelectedSubjectID);
		}
	}, [firstSelectedSubjectID, isSubjectsLoaded, selectedSubject, setSelectedSubjectByID]);

	const renderTestInfoPanel = () => {
		if (!isSubjectsLoaded) {
			return null;
		}

		if (!hasSubjects) {
			return <EmptyContent message={noTestsMessage} />;
		}

		if (isNull(selectedSubject)) {
			return <EmptyContent message={[noTestsMessage, selectSubjectMessage]} />;
		}

		return (
			<TestsInfoPanel
				testCards={testCards}
				isTestsLoaded={isTestsLoaded}
				selectedSubject={selectedSubject}
				selectedStudent={student}
				schoolSubjects={subjectsEntities.schoolSubjects}
				onMoveToTest={moveTestToSubject}
				onRemoveTest={removeTestByID}
			/>
		);
	};

	return (
		<Container>
			<SubjectsPanel
				isSubjectsLoaded={isSubjectsLoaded}
				selectedSubjectID={isNull(selectedSubject) ? null : selectedSubject.id}
				subjectsEntities={subjectsEntities}
				setSelectedSubjectID={setSelectedSubjectByID}
			/>

			<Divider />

			{renderTestInfoPanel()}
		</Container>
	);
}
