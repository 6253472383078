import {BaseLine} from './components/base-line';
import {Pointer} from './components/pointer';
import {ProgressLine} from './components/progress-line';
import {Root} from './components/root';
import {VerticalLine} from './components/vertical-line';

export const BarChartHorizontal = Object.assign(Root, {
	BaseLine,
	ProgressLine,
	Pointer,
	VerticalLine,
});
