import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {specialistGroupsStore, studentsStore, SpecialistGroup, Student} from '@esgi/main/libs/admin-store';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useCallback, useEffect, useState} from 'react';
import {EditSpecialistForm} from '../form/types';
import {isNull} from 'underscore';
import {dispatchAppEvent} from '@esgillc/events';
import {SpecialistGroupUpdatedEvent} from '../../../events';

export type UpdatedGroupDataModel = Pick<SpecialistGroup, 'id' | 'name' | 'specialistID'> & {
	studentsIDs: Student['id'][];
};

type Parameters = {
	specialistGroupID: SpecialistGroup['id'];
	form: EditSpecialistForm;
	initialStudentsIDs: Student['id'][];
	closeDrawer: VoidFunction;
	specialistID: number | null
};

export function useSave({specialistGroupID, form, initialStudentsIDs, closeDrawer, specialistID}: Parameters) {
	const api = useEsgiApiGateway();

	const [updatedGroupDataModel, setUpdatedGroupDataModel] = useState<UpdatedGroupDataModel | null>(null);
	const [editedSpecialistGroupData, editSpecialistGroup] = useCancelableRequest(
		api.v2.districtAdmins.modules.specialistGroups.update,
	);

	useEffect(() => {
		if (isAsyncSucceed(editedSpecialistGroupData) && !isNull(updatedGroupDataModel)) {
			specialistGroupsStore().update((item) => {
				if (item.id === updatedGroupDataModel.id) {
					return {
						...item,
						name: updatedGroupDataModel.name,
					};
				}

				return item;
			});

			studentsStore().update((item) => {
				if (initialStudentsIDs.includes(item.id) && !updatedGroupDataModel.studentsIDs.includes(item.id)) {
					return {
						...item,
						specialistGroupsIDs: item.specialistGroupsIDs.filter(
							(specialistGroupID) =>
								specialistGroupID !== updatedGroupDataModel.id,
						),
						specialistsIDs: item.specialistsIDs.filter(
							(specialistID) =>
								specialistID !== updatedGroupDataModel.specialistID,
						),
					};
				}
				if (updatedGroupDataModel.studentsIDs.includes(item.id)) {
					const updatedStudent = {
						...item,
						specialistGroupsIDs: [
							...new Set([
								...item.specialistGroupsIDs,
								updatedGroupDataModel.id,
							]),
						],
						specialistsIDs: [
							...new Set([
								...item.specialistsIDs,
								updatedGroupDataModel.specialistID,
							]),
						],
					};
					return updatedStudent;
				}
				return item;
			});

			dispatchAppEvent(
				SpecialistGroupUpdatedEvent,
				new SpecialistGroupUpdatedEvent({
					id: updatedGroupDataModel.id,
					name: updatedGroupDataModel.name,
				}),
			);

			closeDrawer();
		}
	}, [closeDrawer, editedSpecialistGroupData, initialStudentsIDs, updatedGroupDataModel]);

	const onEditSpecialistGroup = useCallback(() => {
		if(isNull(specialistID)) {
			throw new Error('specialistID is null');
		}

		const {groupName, studentsIDs} = form.value;

		editSpecialistGroup({
			specialistGroupID,
			name: groupName,
			studentIDs: studentsIDs,
		});

		setUpdatedGroupDataModel({
			id: specialistGroupID,
			name: groupName,
			studentsIDs,
			specialistID,
		});
	}, [editSpecialistGroup, form.value, specialistGroupID, specialistID]);

	return {
		onEditSpecialistGroup,
		isDataSaving: isAsyncPending(editedSpecialistGroupData),
	};
}
