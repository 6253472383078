import {useFlag} from '@esgi/ui';
import {Entities} from '@esgi/main/features/admins/subject-management';
import {Dispatch, useCallback, useMemo} from 'react';
import {useSubjects as useSubjectsFromStore} from '@esgi/main/libs/admin-store';
import {MappedSubjects, Out} from './types';
import {updateEntities} from './update-entities';
import {useRearrangeDistrictSubjects} from './use-rearrange-district-subjects';
import {isNull} from 'underscore';

type Parameters = {
	setSelectedSubjectID: Dispatch<number | null>;
};

export function useSubjects({setSelectedSubjectID}: Parameters): Out {
	const [onceSettled, setOnceSettled] = useFlag();

	const [{data: subjectsFromStore, loaded: isSubjectsFromStoreLoaded}] = useSubjectsFromStore();

	const {isSubjectsLoaded, subjectsEntities, allDistrictSubjectsNames} = useMemo<MappedSubjects>(() => {
		const entities: Entities = {
			districtSubjects: [],
			schoolSubjects: [],
			stockSubjects: [],
			teacherSubjects: [],
		};

		if (!isSubjectsFromStoreLoaded) {
			return {
				isSubjectsLoaded: false,
				subjectsEntities: entities,
				allDistrictSubjectsNames: [],
			};
		}

		updateEntities({subjects: subjectsFromStore, entities});

		if (!onceSettled) {
			setSelectedSubjectID(entities.districtSubjects[0]?.id ?? null);
			setOnceSettled();
		}

		return {
			isSubjectsLoaded: true,
			subjectsEntities: entities,
			allDistrictSubjectsNames: entities.districtSubjects.map(({name}) => name),
		};
	}, [isSubjectsFromStoreLoaded, onceSettled, setOnceSettled, setSelectedSubjectID, subjectsFromStore]);

	const onRearrangeDistrictSubjects = useRearrangeDistrictSubjects();

	const getSubjectFromStoreByID = useCallback(
		(id: number | null) => {
			if (isNull(id)) {
				return null;
			}

			return subjectsFromStore.find((subject) => subject.id === id) ?? null;
		},
		[subjectsFromStore],
	);

	return {
		isSubjectsLoaded,
		subjectsEntities,
		onRearrangeDistrictSubjects,
		getSubjectFromStoreByID,
		allDistrictSubjectsNames,
	};
}
