import {useEffect, useImperativeHandle} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {DatePickerWidget, useDatePickerWidget, PeriodType as PeriodTypeForDatePicker} from '@esgi/main/kits/admin';
import {DynamicFormProps} from '../../types';
import {InputsBox} from '../../index.styled';
import {periodItems} from './constants';
import {enumRemap, mapToEnum} from 'shared/utils';
import {Color, ToggleGroup, WidgetPeriod} from '@esgi/main/features/admins/dashboard';

type Props = DynamicFormProps<{
	periodData: WidgetPeriod;
}>;

export function PiechartData({
	periodData,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	dynamicFormDataRef,
}: Props) {
	const {state, handleChange, isTouched} = useDatePickerWidget({
		value: {
			datePeriod: mapToEnum(periodData.datePeriod, PeriodTypeForDatePicker),
			dateRange: periodData.dateRange,
			trackDatesID: periodData.trackDatesID,
		},
	});

	useEffect(() => {
		setIsDynamicFormDataTouched(isTouched);
		setIsDynamicFormDataValid(true);
	}, [isTouched, setIsDynamicFormDataTouched, setIsDynamicFormDataValid]);

	useImperativeHandle(dynamicFormDataRef, () => ({
		getData: () => ({
			candles: null,
			periods: [
				{
					color: Color.None,
					trackDatesID: state.trackDatesID,
					dateRange: state.dateRange,
					datePeriod: enumRemap(state.datePeriod, PeriodTypeForDatePicker),
				},
			],
		}),
	}));

	return (
		<GridBox gap='3'>
			<ToggleGroup items={periodItems} disabled />
			<InputsBox>
				<DatePickerWidget {...state} onChange={handleChange} />
			</InputsBox>
		</GridBox>
	);
}
