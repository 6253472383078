import {useMemo} from 'react';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {DeployedSubject, SelectedEntityInfo, SelectedEntityInfoItem} from '@esgi/main/features/admins/dashboard';

type Props = {
	subjectIDs: number[];
	subjects: DeployedSubject[];
	itemsTranscript?: string;
};

export function SelectedSubjectsInfo({subjectIDs, subjects, itemsTranscript = 'Subjects'}: Props) {
	const subjectsItems = useMemo(() => {
		return subjectIDs
			.map<SelectedEntityInfoItem<DeployedSubject['id']> | null>((subjectID) => {
				const subject = subjects.find(({id}) => id === subjectID);

				if (isUndefined(subject)) {
					return null;
				}

				return {
					label: subject.name,
					value: subject.id,
				};
			})
			.filter((item): item is SelectedEntityInfoItem<DeployedSubject['id']> => !isNull(item));
	}, [subjects, subjectIDs]);

	return (
		<SelectedEntityInfo
			items={subjectsItems}
			itemsTranscript={itemsTranscript}
			totalPossibleItemsCount={subjects.length}
		/>
	);
}
