import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {alertRootCss} from './index.styled';
import {Button} from '@esgi/ui';

type Props = {
	onClose: VoidFunction;
	onAddWithoutPreAssess: VoidFunction;
	isOnlyPreAssessUsers: boolean;
};

export function FolderValidationAlert({onClose, onAddWithoutPreAssess, isOnlyPreAssessUsers}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const handleAddWithoutPreAssess = () => {
		onAddWithoutPreAssess();
		closeAlert();
	};

	return (
		<Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative} css={alertRootCss}>
			<Alert.Header withBacklight={false} onCloseIconClick={closeAlert}>
				<Text size='small'>Pre-assess Specialists</Text>
			</Alert.Header>

			<Alert.Body>
				<Text size='small' color='highContrast'>
					Pre-assess Specialists cannot be added to the folder. You can only add District and School Specialists to
					folders
				</Text>
			</Alert.Body>

			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					{isOnlyPreAssessUsers ? (
						<Button color='secondary' onClick={closeAlert} uppercase>
							<Text size='medium' bold color='base'>
								Ok
							</Text>
						</Button>
					) : (
						<>
							<Button color='tertiary' onClick={closeAlert}>
								<Text size='medium' bold color='base'>
									Cancel
								</Text>
							</Button>

							<Button color='secondary' onClick={handleAddWithoutPreAssess}>
								<Text size='medium' bold color='base'>
									Add without Pre-Assess
								</Text>
							</Button>
						</>
					)}
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
