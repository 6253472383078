import {useMemo} from 'react';
import {RowDef} from './types';
import {useDisposable, useEndpointResult} from '@esgi/core/service';
import {
	V2SchoolAdminsPagesDataAnalyticsController,
	V2SchoolAdminsUserAccountController,
} from '@esgi/contracts/esgi';
import {useTeachers} from '@esgi/main/libs/school-admin-store';

export function useData(): { ready: boolean, rows: RowDef[], schoolName?: string} {
	const [{data: teachers, loaded: isTeachersLoaded}] = useTeachers();
	const analyticsController = useDisposable(V2SchoolAdminsPagesDataAnalyticsController);

	const analyticData = useEndpointResult(() => {
		if (isTeachersLoaded && teachers.length) {
			return analyticsController.teachers({teachersIDs: teachers.map(t => t.id)});
		}
	}, [teachers, isTeachersLoaded]);

	const userAccountController = useDisposable(V2SchoolAdminsUserAccountController);

	const userControllerData = useEndpointResult(() => {
		if (isTeachersLoaded && teachers.length) {
			return userAccountController.init();
		}
	}, [teachers, isTeachersLoaded]);

	const schoolName = useMemo(() => {
		return userControllerData?.user.schoolName;
	}, [userControllerData]);

	const {rows} = useMemo(() => {
		const rows = teachers.map((teacher) => {
			const analytic = analyticData?.value?.teachersAnalytics?.find(t => t.teacherId === teacher.id);
			return {
				id: teacher.id,
				firstName: teacher.firstName,
				lastName: teacher.lastName,
				userName: teacher.userName,
				email: teacher.email,
				classes: analytic?.classesCount,
				groups: analytic?.groupsCount,
				students: analytic?.studentsCount,
			} as RowDef;
		});

		return {rows};
	}, [teachers, analyticData]);

	return {
		ready: isTeachersLoaded,
		rows,
		schoolName,
	};
}