import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {FormElement} from '@esgi/ui/form';
import {ToggleGroup} from '@esgi/ui/controls';
import {Drawer} from '@esgi/main/kits/common';

export const StyledDrawerWrapper = styled(Box, {
	'& [data-drawer-wrapper]': {
		display: 'flex',
		margin: 'unset',
		padding: 'unset',
	},
});

export const DrawerPanelHeader = styled(Drawer.PanelHeader, {
	height: 73,

	'& [data-panel-header-trigger]': {
		display: 'none',
	},

	'& [data-children-box]': {
		overflow: 'hidden',

		[`& > ${Text}`]: {
			wordBreak: 'break-all',
		},
	},
});

export const DrawerPanel = styled(GridBox, {
	gridTemplateAreas: `
    "sidebar header"
    "sidebar content"
  `,
	height: '100%',
	width: '100%',
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: 'auto 1fr',
});

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
	padding: '2px',
	backgroundColor: '$vivid',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '6px',
	width: '100%',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
	gridTemplateColumns: '1fr 1fr',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	flexDirection: 'column',
	justifyItems: 'center',
});

export const FormElementWrapper = styled(FormElement, {
	marginBottom: '0',

	'& > div': {
		width: '100%',
	},
});
