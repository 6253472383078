import {Dispatch, useState} from 'react';
import {SingleSelectedExpandablePanel} from '../../../../../_kit';
import {RowDef} from '../../../../types';
import {GridApi} from 'ag-grid-community';
import {QuickPanel} from './components/quick-panel';
import {ExpandedPanel} from './components/expanded-panel';

type Props = {
	gridApi: GridApi<Readonly<RowDef>> | null;
	onFolderAddClick: VoidFunction;
	editButtonDisabled: boolean;
	onEditClick: Dispatch<RowDef>;
	userCanAddAndDeleteTeachers: boolean;
};

export function SingleItemExpandablePanel({
	gridApi,
	onFolderAddClick,
	editButtonDisabled,
	onEditClick,
	userCanAddAndDeleteTeachers,
}: Props) {
	const [isPanelExpanded, setIsPanelExpanded] = useState(false);
	const [selectedRow, setSelectedRow] = useState<RowDef | null>(null);

	return (
		<SingleSelectedExpandablePanel.Root<RowDef>
			gridApi={gridApi}
			onSingleSelectedItemChange={setSelectedRow}
			onPanelExpandableValueChange={setIsPanelExpanded}
		>
			{selectedRow && (
				<>
					<QuickPanel
						selectedRow={selectedRow}
						isPanelExpanded={isPanelExpanded}
						onFolderAddClick={onFolderAddClick}
						editButtonDisabled={editButtonDisabled}
						gridApi={gridApi}
						onEditClick={onEditClick}
						userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
					/>

					<ExpandedPanel selectedRow={selectedRow} />
				</>
			)}
		</SingleSelectedExpandablePanel.Root>
	);
}
