import {SubjectPanel} from '@esgi/main/kits/subject-selection-panel';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	height: '100%',
	gap: '$5',
	gridAutoFlow: 'row',
	alignContent: 'start',
	paddingTop: 24,
	paddingBottom: 24,
	paddingInline: 20,
	overflow: 'hidden',
});

export const SubjectsTabsBar = styled(SubjectPanel.Tabs.Bar, {
	gridAutoColumns: '1fr',
});
