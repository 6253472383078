import {SelectItem, TestedOption} from './types';

export const testedOptionItems: SelectItem<TestedOption>[] = [
	{
		label: 'Any',
		value: TestedOption.Any,
	},
	{
		label: 'Yes',
		value: TestedOption.Yes,
	},
	{
		label: 'No',
		value: TestedOption.No,
	},
];
