import {BaseButton} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';

export const StyledButton = styled(BaseButton, {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderWidth: 1,
	borderStyle: 'solid',
	transition: 'background-color .3s',

	'& > svg': {
		width: 24,
		height: 24,
	},

	variants: {
		size: {
			default: {
				width: 40,
				height: 40,
			},

			small: {
				width: 36,
				height: 36,
			},
		},

		variant: {
			default: {
				borderRadius: 6,
				borderColor: '$border',
				backgroundColor: '$surface',
				transition: 'background-color .3s, border-color .3s',

				'& > svg': {
					'& *': {
						fill: '$base',
						transition: 'fill .3s',
					},
				},

				'&:hover': {
					backgroundColor: '$secondaryBackground',
					borderColor: '$secondaryMuted',

					'& > svg': {
						'& *': {
							fill: '$secondary',
						},
					},
				},
			},

			orange: {
				borderRadius: 8,
				borderColor: '$tertiaryMuted',
				backgroundColor: '$tertiarySurface',
				transition: 'background-color .3s',

				'& > svg': {
					'& *': {
						fill: '$tertiary',
					},
				},

				'&:hover': {
					backgroundColor: '$secondaryBackground',
				},
			},
		},
	},
});
