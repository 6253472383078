import {Root as SubjectPanelRoot} from './components/root';
import {Icon as SectionTitleIcon} from './components/section-title/icon';
import {Root as SectionTitleRoot} from './components/section-title/root';
import {Tabs} from '../tabs';
import {All as AllTabContent} from './components/tabs/all';
import {District as DistrictTabContent} from './components/tabs/district';
import {School as SchoolTabContent} from './components/tabs/school';
import {Teacher as TeacherTabContent} from './components/tabs/teacher';
import {SubjectName} from './components/subject-name';
import {SelectableList} from './components/lists/selectable-list';
import {District as DistrictCheap} from './components/cheap/district';
import {School as SchoolCheap} from './components/cheap/school';
import {Teacher as TeacherCheap} from './components/cheap/teacher';
import {BaseCheap} from './components/cheap/base-cheap';
import {CheapBox} from './components/cheap/cheap-box';
import {EmptyContentBox} from './components/empty-content-box';
import {Title} from './components/section-title/title';

export const SubjectPanel = {
	Root: SubjectPanelRoot,
	SectionTitle: {
		Root: SectionTitleRoot,
		Icon: SectionTitleIcon,
		Title,
	},
	Tabs: {
		Bar: Tabs.TabsBar,
		Content: {
			All: AllTabContent,
			District: DistrictTabContent,
			School: SchoolTabContent,
			Teacher: TeacherTabContent,
		},
	},
	Cheap: {
		CheapBox,
		BaseCheap,
		Variant: {
			District: DistrictCheap,
			School: SchoolCheap,
			Teacher: TeacherCheap,
		},
	},
	SubjectName,
	List: {
		SelectableList,
	},
	EmptyContentBox,
};

export * from './types/subject';
export {TabID, type Entities} from './types/section';
