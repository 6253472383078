import {Container} from './index.styled';
import {Dispatch} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Filters} from './components/filters';
import {Header} from './components/header';
import {AdditionalEntity, CardsSortBy, Student} from '../../types/section';
import {TestPanelData} from '../../types/test-panel';
import {isNull} from 'underscore';
import {ByAllStudents} from './components/by-all-students';
import {ByEachStudent} from './components/by-each-student';
import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {Hierarchy} from '@esgi/main/features/admins/utils';

type Props = {
	uniqGradeLevelsIDs: number[];
	gradeLevelID: number;
	onGradeLevelIDChange: Dispatch<number>;
	cardsSortBy: CardsSortBy;
	onCardsSortByValueChange: Dispatch<CardsSortBy>;
	showInColor: boolean;
	onShowInColorValueChange: Dispatch<boolean>;
	showEachStudent: boolean;
	onShowEachStudentValueChange: Dispatch<boolean>;
	testPanelData: TestPanelData;
	students: Student[];
	fullPanelSkeleton: boolean;
	additionalInfo: AdditionalEntity | null;
	selectedGradeLevelID: number;
	selectedSubject: SubjectModel | null;
	hierarchy: Hierarchy;
	withoutVariantByEachStudent: boolean;
};

export function TestsInfoPanel({
	uniqGradeLevelsIDs,
	gradeLevelID,
	onGradeLevelIDChange,
	cardsSortBy,
	onCardsSortByValueChange,
	showInColor,
	onShowInColorValueChange,
	showEachStudent,
	onShowEachStudentValueChange,
	testPanelData: {byEachStudentData, byAllStudentsData},
	students,
	fullPanelSkeleton,
	additionalInfo,
	selectedGradeLevelID,
	selectedSubject,
	hierarchy,
	withoutVariantByEachStudent,
}: Props) {
	return (
		<Container>
			<Header
				skeleton={fullPanelSkeleton}
				additionalInfo={additionalInfo}
				hierarchy={hierarchy}
				selectedGradeLevelID={selectedGradeLevelID}
				selectedSubject={selectedSubject}
				showEachStudent={showEachStudent}
				showInColor={showInColor}
				students={students}
			/>
			<Filters
				uniqGradeLevelsIDs={uniqGradeLevelsIDs}
				gradeLevelID={gradeLevelID}
				onGradeLevelIDChange={onGradeLevelIDChange}
				cardsSortBy={cardsSortBy}
				onCardsSortByValueChange={onCardsSortByValueChange}
				showInColor={showInColor}
				onShowInColorValueChange={onShowInColorValueChange}
				showEachStudent={showEachStudent}
				onShowEachStudentValueChange={onShowEachStudentValueChange}
				skeleton={fullPanelSkeleton}
				withoutVariantByEachStudent={withoutVariantByEachStudent}
			/>

			<OverlayScrollbarsComponent
				defer
				style={{
					height: 'calc(100% + 0px)',
				}}
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				{!isNull(byEachStudentData) && (
					<ByEachStudent
						data={byEachStudentData.data}
						showInColor={showInColor}
						skeleton={byEachStudentData.skeleton || fullPanelSkeleton}
					/>
				)}

				{!isNull(byAllStudentsData) && (
					<ByAllStudents
						allStudentsCount={students.length}
						showInColor={showInColor}
						testsCards={byAllStudentsData.testsCards}
						skeleton={byAllStudentsData.skeleton || fullPanelSkeleton}
					/>
				)}
			</OverlayScrollbarsComponent>
		</Container>
	);
}
