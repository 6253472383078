import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {BodyBox} from './index.styled';

type Props = ComponentPropsWithoutRef<typeof BodyBox>;

export const Body = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'data-page-body', css = {}, children, ...props}, forwardedRef) => {
		return (
			<BodyBox dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{children}
			</BodyBox>
		);
	},
);
