import {createContext, Dispatch, SetStateAction, useContext} from 'react';
import {isNull} from 'underscore';
import {TestPanelCard} from '../../types';
import {FilterGroupsState, SortingComparatorCb} from './types';

export type FiltersContextValue<T extends TestPanelCard, I extends string> = {
	setFilterGroups: Dispatch<SetStateAction<FilterGroupsState<T, I>>>;
	setSortableComparator: Dispatch<SetStateAction<SortingComparatorCb<T> | null>>;
};

export const FiltersContext = createContext<FiltersContextValue<any, string> | null>(null);

export function useFiltersContext<T extends TestPanelCard, I extends string = string>() {
	const context = useContext(FiltersContext);

	if (isNull(context)) {
		throw new Error('useFiltersContext is null');
	}

	return context as unknown as FiltersContextValue<T, I>;
}
