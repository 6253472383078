import {ICellRendererParams} from 'ag-grid-community';
import {useCallback, useEffect, useState} from 'react';
import {CheckedState} from '@radix-ui/react-checkbox';
import {CheckboxStyled, Container} from './index.styled';
import {AgGridContextValue} from '../../contexts/ag-grid-table';

export function CellCheckbox({api, node, context}: ICellRendererParams<any, any, AgGridContextValue>) {
	const [isHovered, setIsHovered] = useState(node.isHovered());
	const [isSelected, setIsSelected] = useState(node.isSelected() ?? false);
	const [isDisabled, setIsDisabled] = useState(!node.selectable);

	useEffect(() => {
		setIsDisabled(!node.selectable);

		const handler = () => setIsSelected(node.isSelected() ?? false);

		api.addEventListener('rowSelected', handler);

		return () => api.removeEventListener('rowSelected', handler);
	}, [node]);

	useEffect(() => {
		const updateHoverableValue = () => {
			setIsHovered(node.isHovered());
		};

		node.addEventListener('mouseEnter', updateHoverableValue);
		node.addEventListener('mouseLeave', updateHoverableValue);

		return () => {
			node.removeEventListener('mouseEnter', updateHoverableValue);
			node.removeEventListener('mouseLeave', updateHoverableValue);
		};
	}, [node]);

	const onCheckedChange = useCallback(
		(value: CheckedState) => {
			if (isDisabled) {
				return;
			}

			node.setSelected(Boolean(value));
		},
		[isDisabled, node],
	);

	const isNodeAsSelectedAsDisabled = context.selectedAsDisabledNodes.includes(node);

	return (
		<Container>
			<CheckboxStyled
				checked={isNodeAsSelectedAsDisabled ? true : isSelected}
				onCheckedChange={onCheckedChange}
				disabled={isNodeAsSelectedAsDisabled ? true : isDisabled}
				hovered={isHovered}
			/>
		</Container>
	);
}
