import {ComponentProps, forwardRef} from 'react';
import {Container} from './index.styled';

type Props = ComponentProps<typeof Container> & {
	columnsCount?: number;
};

export const AnalyticsBox = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-card-statistics-analytics-box', css = {}, columnsCount = 2, ...props}, forwardedRef) => (
		<Container
			dataCy={dataCy}
			css={{gridTemplateColumns: `repeat(${columnsCount}, auto)`, ...css}}
			ref={forwardedRef}
			{...props}
		/>
	),
);
