import {DatePicker} from '@esgi/ui/controls';
import {Dispatch} from 'react';

type Props = {
	value: Date[];
	setValue: Dispatch<Date[]>;
};

export function CustomDateRangeTab({value, setValue}: Props) {
	return (
		<DatePicker.Root value={value} onChange={setValue} rangeMode>
			<DatePicker.DropdownTrigger>
				<DatePicker.Input />
			</DatePicker.DropdownTrigger>
			<DatePicker.DropdownBody>
				<DatePicker.CalendarPanel />
			</DatePicker.DropdownBody>
		</DatePicker.Root>
	);
}
