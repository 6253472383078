import {Text} from '@esgi/ui/typography';
import {DatesPeriodModel} from '../../../types';
import {SelectTitleWrapper} from '../index.styled';
import {isNull} from 'underscore';
import {calendarPast} from './constants';
import {useOptions} from './use-options';
import {CurrentTrackDate} from '../../../hooks/use-current-track-dates/types';
import {SelectOption} from '../select-option';

interface Props {
	currentTracks: CurrentTrackDate[];
	previousTrackDate: DatesPeriodModel | null;
	isUserInCurrentGlobalSchoolYear: boolean;
}

export function PastTabContent({currentTracks, previousTrackDate, isUserInCurrentGlobalSchoolYear}: Props) {
	const {currentTracksOptions, previousMarkingPeriod} = useOptions({currentTracks, previousTrackDate});

	return (
		<>
			<SelectTitleWrapper>
				<Text size='small' color='lowContrast'>
					Calendar
				</Text>
			</SelectTitleWrapper>

			{calendarPast.map(({value, label}) => (
				<SelectOption value={value} label={label} disabled={!isUserInCurrentGlobalSchoolYear} key={label} />
			))}

			<SelectTitleWrapper>
				<Text size='small' color='lowContrast'>
					{`Marking Periods ${isUserInCurrentGlobalSchoolYear ? 'Current' : 'Previous'} Year`}
				</Text>
			</SelectTitleWrapper>

			{!isNull(previousMarkingPeriod) && (
				<SelectOption
					value={previousMarkingPeriod.value}
					label='Previous Marking Period'
					additionalLabel={previousMarkingPeriod.label}
				/>
			)}

			{currentTracksOptions.map(({value, label}) => (
				<SelectOption value={value} label={label} key={label} />
			))}
		</>
	);
}
