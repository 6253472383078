import {isNull} from 'underscore';
import {SortDirection} from '../types';

export function sortData<T extends Record<keyof T, unknown>, K extends keyof T>({
	items,
	key,
	additionalKey,
	direction,
}: {
	items: T[];
	key: K;
	additionalKey: K;
	direction: SortDirection;
}) {
	function compareStringItemsByKey({
		aValue,
		bValue,
		direction,
	}: {
		aValue: string;
		bValue: string;
		direction: SortDirection;
	}) {
		const aUpperValue = aValue.toLocaleUpperCase();
		const bUpperValue = bValue.toLocaleUpperCase();

		return direction === SortDirection.Asc
			? aUpperValue.localeCompare(bUpperValue)
			: bUpperValue.localeCompare(aUpperValue);
	}

	function compareNumbers({a, b, direction}: {a: number; b: number; direction: SortDirection}) {
		return direction === SortDirection.Asc ? a - b : b - a;
	}

	return [...items].sort((a, b) => {
		const aValue = a[key];
		const bValue = b[key];

		const aAdditionalValue = a[additionalKey];
		const bAdditionalValue = b[additionalKey];

		if (isNull(aValue) && !isNull(bValue)) {
			return direction === SortDirection.Asc ? 1 : -1;
		}

		if (isNull(bValue) && !isNull(aValue)) {
			return direction === SortDirection.Asc ? -1 : 1;
		}

		if (aValue === bValue && typeof aAdditionalValue === 'string' && typeof bAdditionalValue === 'string') {
			return compareStringItemsByKey({
				aValue: aAdditionalValue,
				bValue: bAdditionalValue,
				direction,
			});
		}

		if (typeof aValue === 'string' && typeof bValue === 'string') {
			const aNumberedValue = Number(a[key]);
			const bNumberedValue = Number(b[key]);

			if (!Number.isNaN(aNumberedValue) && !Number.isNaN(bNumberedValue)) {
				return compareNumbers({
					a: aNumberedValue,
					b: bNumberedValue,
					direction,
				});
			}

			return compareStringItemsByKey({
				aValue,
				bValue,
				direction,
			});
		}

		if (typeof aValue === 'number' && typeof bValue === 'number') {
			return compareNumbers({
				a: aValue,
				b: bValue,
				direction,
			});
		}

		return 0;
	});
}
