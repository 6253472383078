import '@esgi/deprecated/old-libs';
import {createRoot} from 'react-dom/client';
import {ReactNode, useMemo} from 'react';
import {DesignVersion, RoutesMap, useDesignVersion} from '@esgi/main/libs/core';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';
import {getRedesignAppRoutes, NotFoundRedesign} from '@esgi/main/router';
import {
	Agreements,
	ByClassLink,
	ByCrm,
	ByLinkedClever,
	ByNotLinkedClever,
	ByRenew,
	DevLogin,
} from '@esgi/main/pages/common';
import {
	Agreement,
	Autoquoter,
	CleverRegistration,
	CompleteImport,
	CompleteRegistration,
	Invitation,
	Login,
	NotFoundRoute,
	OldRoutes,
	PrivacyPolicy,
	QuotePayment,
	Renewal,
	RepAutoquoter,
	RequestAQuoteForm,
	ResetPassword,
	SignUp,
	StudentScreen,
} from '@esgi/main/old';
import {ErrorBoundary} from '@esgi/core/react';
import {EnvironmentLayer} from './layers/environment-layer';
import {PageRoot} from './page-root';
import {withErrorHandling} from '@esgillc/ui-kit/core';
import {useCheckViewportContent} from './use-check-viewport-content';
import {UserType, useUser} from '@esgi/core/authentication';
import {IEnvironment} from '@esgi/core/environments';

const WrappedPageRoot = withErrorHandling(PageRoot);
const WrappedRenewal = withErrorHandling(Renewal);
const WrappedCompleteImport = withErrorHandling(CompleteImport);
const WrappedCompleteRegistration = withErrorHandling(CompleteRegistration);
const WrappedByCrm = withErrorHandling(ByCrm);
const WrappedLogin = withErrorHandling(Login);
const WrappedSignUp = withErrorHandling(SignUp);
const WrappedByRenew = withErrorHandling(ByRenew);
const WrappedDevLogin = withErrorHandling(DevLogin);
const WrappedAutoquoter = withErrorHandling(Autoquoter);
const WrappedAgreements = withErrorHandling(Agreements);
const WrappedInvitation = withErrorHandling(Invitation);
const WrappedByClassLink = withErrorHandling(ByClassLink);
const WrappedAgreement = withErrorHandling(Agreement);
const WrappedQuotePayment = withErrorHandling(QuotePayment);
const WrappedPrivacyPolicy = withErrorHandling(PrivacyPolicy);
const WrappedResetPassword = withErrorHandling(ResetPassword);
const WrappedByLinkedClever = withErrorHandling(ByLinkedClever);
const WrappedByNotLinkedClever = withErrorHandling(ByNotLinkedClever);
const WrappedRequestAQuoteForm = withErrorHandling(RequestAQuoteForm);
const WrappedCleverRegistration = withErrorHandling(CleverRegistration);
const WrappedStudentScreen = withErrorHandling(StudentScreen);

const baseMessage = `Uh oh. Something seems to be wrong on our end. Please reload the page. If you continue to see this message - please let us know via 'Contact us' button.`;

function useDesignResolver(design: DesignVersion, userType: UserType, environment: IEnvironment): [ReactNode, JSX.Element] {
	return useMemo<[ReactNode, JSX.Element]>(() => {
		const legacyApplication: [ReactNode, JSX.Element] = [OldRoutes(), <NotFoundRoute/>];
		const redesignApplication: [ReactNode, JSX.Element] = [getRedesignAppRoutes(userType), <NotFoundRedesign/>];

		if ([UserType.D, UserType.C].includes(userType)) {
			if (environment.country === 'ca') {
				return legacyApplication;
			} else {
				return redesignApplication;
			}
		}

		if (design === DesignVersion.Old) {
			return legacyApplication;
		} else {
			return redesignApplication;
		}
	}, [design, userType, environment]);
}

export function Router(props: { environment: IEnvironment }) {
	const currentUser = useUser();
	const [design] = useDesignVersion();

	useCheckViewportContent({designVersion: design});
	const [applicationRoutes, notFound] = useDesignResolver(design, currentUser.userType, props.environment);

	const commonRoutes = createRoutesFromElements(
		<Route element={<WrappedPageRoot/>}>
			<Route path={RoutesMap.renewal} element={<WrappedRenewal/>}/>
			<Route path={RoutesMap.completeImport} element={<WrappedCompleteImport/>}/>
			<Route path={RoutesMap.completeRegistration} element={<WrappedCompleteRegistration/>}/>
			<Route path={RoutesMap.byCRM} element={<WrappedByCrm/>}/>
			<Route path={RoutesMap.login} element={<WrappedLogin/>}/>,
			<Route path={RoutesMap.signUp} element={<WrappedSignUp/>}/>,
			<Route path={RoutesMap.byRenew} element={<WrappedByRenew/>}/>
			<Route path={RoutesMap.devLogin} element={<WrappedDevLogin/>}/>
			<Route path={RoutesMap.autoquoter} element={<WrappedAutoquoter/>}/>
			<Route path={RoutesMap.repautoquoter} element={<RepAutoquoter/>}/>
			<Route path={RoutesMap.agreements} element={<WrappedAgreements/>}/>
			<Route path={RoutesMap.invitation} element={<WrappedInvitation/>}/>
			<Route path={RoutesMap.byClassLink} element={<WrappedByClassLink/>}/>
			<Route path={RoutesMap.esgiAgreement} element={<WrappedAgreement/>}/>
			<Route path={RoutesMap.quotePayment} element={<WrappedQuotePayment/>}/>
			<Route path={RoutesMap.privacyPolicy} element={<WrappedPrivacyPolicy/>}/>
			<Route path={RoutesMap.resetPassword} element={<WrappedResetPassword/>}/>
			<Route path={RoutesMap.byLinkedClever} element={<WrappedByLinkedClever/>}/>
			<Route path={RoutesMap.byNotLinkedClever} element={<WrappedByNotLinkedClever/>}/>
			<Route path={RoutesMap.requestAQuoteForm} element={<WrappedRequestAQuoteForm/>}/>
			<Route path={RoutesMap.cleverRegistration} element={<WrappedCleverRegistration/>}/>
			<Route path={RoutesMap.studentsScreen} element={<WrappedStudentScreen/>}/>
			{applicationRoutes}
			<Route path='*' element={notFound}/>
		</Route>,
	);

	const router = createBrowserRouter(commonRoutes);
	return <RouterProvider router={router}/>;

}

function Application() {
	return (
		<ErrorBoundary fillSpace message={baseMessage}>
			<EnvironmentLayer>
				{(environment) => <Router environment={environment}/>}
			</EnvironmentLayer>
		</ErrorBoundary>
	);
}

const root = createRoot(document.getElementById('app'));

root.render(<Application/>);
