import * as React from 'react';
import type {SVGProps} from 'react';

export function ChartPie(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				d='M15 9C15 8.44772 15.4477 8 16 8C17.0506 8 18.0909 8.20693 19.0615 8.60896C20.0321 9.011 20.914 9.60028 21.6569 10.3431C22.3997 11.086 22.989 11.9679 23.391 12.9385C23.7931 13.9091 24 14.9494 24 16C24 16.5523 23.5523 17 23 17C22.4477 17 22 16.5523 22 16C22 15.9712 22.0012 15.9428 22.0036 15.9146C21.9928 15.1552 21.838 14.4045 21.5472 13.7023C21.2454 12.9738 20.8032 12.3119 20.2456 11.7544C19.6881 11.1968 19.0262 10.7546 18.2977 10.4528C17.5955 10.162 16.8448 10.0072 16.0854 9.99641C16.0572 9.99879 16.0288 10 16 10C15.4477 10 15 9.55228 15 9Z'
				fill='#333333'
			/>
			<path
				d='M8 16C8 15.4477 8.44772 15 9 15C9.55228 15 10 15.4477 10 16C10 16.5523 9.55228 17 9 17C8.44772 17 8 16.5523 8 16Z'
				fill='#333333'
			/>
			<path
				d='M11 10C10.4477 10 10 10.4477 10 11C10 11.5523 10.4477 12 11 12C11.5523 12 12 11.5523 12 11C12 10.4477 11.5523 10 11 10Z'
				fill='#333333'
			/>
			<path
				d='M10 21C10 20.4477 10.4477 20 11 20C11.5523 20 12 20.4477 12 21C12 21.5523 11.5523 22 11 22C10.4477 22 10 21.5523 10 21Z'
				fill='#333333'
			/>
			<path
				d='M21 20C20.4477 20 20 20.4477 20 21C20 21.5523 20.4477 22 21 22C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20Z'
				fill='#333333'
			/>
			<path
				d='M16 22C15.4477 22 15 22.4477 15 23C15 23.5523 15.4477 24 16 24C16.5523 24 17 23.5523 17 23C17 22.4477 16.5523 22 16 22Z'
				fill='#333333'
			/>
		</svg>
	);
}
