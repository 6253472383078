import * as React from 'react';
import type {SVGProps} from 'react';

export function School(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M15.7778 13.6667C16.2073 13.6667 16.5556 13.3184 16.5556 12.8889C16.5556 12.4593 16.2073 12.1111 15.7778 12.1111C15.3482 12.1111 15 12.4593 15 12.8889C15 13.3184 15.3482 13.6667 15.7778 13.6667Z'
				fill='#333333'
			/>
			<path
				d='M18.8889 13.6667C19.3184 13.6667 19.6667 13.3184 19.6667 12.8889C19.6667 12.4593 19.3184 12.1111 18.8889 12.1111C18.4593 12.1111 18.1111 12.4593 18.1111 12.8889C18.1111 13.3184 18.4593 13.6667 18.8889 13.6667Z'
				fill='#333333'
			/>
			<path
				d='M16.5556 16C16.5556 16.4296 16.2073 16.7778 15.7778 16.7778C15.3482 16.7778 15 16.4296 15 16C15 15.5704 15.3482 15.2222 15.7778 15.2222C16.2073 15.2222 16.5556 15.5704 16.5556 16Z'
				fill='#333333'
			/>
			<path
				d='M18.8889 16.7778C19.3184 16.7778 19.6667 16.4296 19.6667 16C19.6667 15.5704 19.3184 15.2222 18.8889 15.2222C18.4593 15.2222 18.1111 15.5704 18.1111 16C18.1111 16.4296 18.4593 16.7778 18.8889 16.7778Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.1104 9.77738C11.1104 9.34826 11.4586 9 11.8881 9H22.777C23.2064 9 23.5548 9.34826 23.5548 9.77738C23.5548 10.2065 23.2064 10.5548 22.777 10.5548V22.2154C22.777 22.6445 22.4286 22.9928 21.9992 22.9928H18.8881C18.4588 22.9928 18.1104 22.6445 18.1104 22.2154V20.6607C18.1104 20.2315 17.7619 19.8833 17.3326 19.8833C16.9032 19.8833 16.5548 20.2315 16.5548 20.6607V22.2154C16.5548 22.6445 16.2064 22.9928 15.777 22.9928L11.8889 23H9.55556C9.126 23 8.77778 22.6518 8.77778 22.2222V13.6667C8.34822 13.6667 8 13.3184 8 12.8889C8 12.4593 8.34822 12.1111 8.77778 12.1111H11.8878C11.888 11.5923 11.8881 11.0735 11.8881 10.5548C11.4586 10.5548 11.1104 10.2065 11.1104 9.77738ZM11.887 13.6667H10.3333V21.4444H11.8863C11.8835 19.1555 11.885 16.8664 11.8864 14.5774C11.8866 14.2739 11.8868 13.9703 11.887 13.6667ZM21.2215 10.5548H13.4437V21.438H14.9992V20.6607C14.9992 19.3725 16.0438 18.3285 17.3326 18.3285C18.6214 18.3285 19.6659 19.3725 19.6659 20.6607V21.438H21.2215V10.5548Z'
				fill='#333333'
			/>
		</svg>
	);
}
