import {createContext, useContext} from 'react';
import {isNull} from 'underscore';
import {ReportType} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';

export type ReportsDialogContextValue = {
	runReport: (report: ReportType, hierarchy: Hierarchy) => void;
	skeleton: boolean;
};

export const ReportsDialogContext = createContext<ReportsDialogContextValue | null>(null);

export function useReportsDialogContext() {
	const context = useContext(ReportsDialogContext);

	if (isNull(context)) {
		throw new Error('useReportsDialogContext is null');
	}

	return context;
}
