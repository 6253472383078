import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Box = styled(GridBox, {
	minHeight: 40,
	backgroundColor: '$background',
	borderRadius: 6,
	borderStyle: 'solid',
	borderColor: '$border',
	borderWidth: 1,
	paddingTop: 5,
	paddingBottom: 5,
	paddingLeft: 12,
	paddingRight: 12,
});
