import * as React from 'react';
import type {SVGProps} from 'react';

export function Move(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M16.5147 2.1903C16.2304 1.93657 15.7696 1.93657 15.4853 2.1903L13.2132 4.88376C12.9289 5.13749 12.9289 5.54887 13.2132 5.8026C13.4478 6.01202 13.6608 6.00535 13.9389 5.99664C13.9978 5.9948 14.0595 5.99286 14.125 5.99287L15.5 5.99291V15.5H5.99294L5.99297 14.5C5.99297 14.4413 5.99452 14.3764 5.99617 14.3077C6.00504 13.937 6.01662 13.453 5.8026 13.2132C5.54887 12.9289 5.13749 12.9289 4.88376 13.2132L2.1903 15.4853C1.93657 15.7696 1.93657 16.2304 2.1903 16.5147L4.88376 18.7868C5.13749 19.0711 5.54887 19.0711 5.8026 18.7868C6.01202 18.5522 6.00535 18.3392 5.99664 18.0611C5.9948 18.0022 5.99286 17.9405 5.99287 17.875L5.99291 16.5H15.5V26.0071L14.5 26.007C14.4413 26.007 14.3764 26.0055 14.3077 26.0038C13.937 25.995 13.453 25.9834 13.2132 26.1974C12.9289 26.4511 12.9289 26.8625 13.2132 27.1162L15.4853 29.8097C15.7696 30.0634 16.2304 30.0634 16.5147 29.8097L18.7868 27.1162C19.0711 26.8625 19.0711 26.4511 18.7868 26.1974C18.5522 25.988 18.3392 25.9947 18.0611 26.0034C18.0023 26.0052 17.9405 26.0071 17.875 26.0071L16.5 26.0071V16.5H26.0071L26.007 17.5C26.007 17.5587 26.0055 17.6236 26.0038 17.6923C25.995 18.063 25.9834 18.547 26.1974 18.7868C26.4511 19.0711 26.8625 19.0711 27.1162 18.7868L29.8097 16.5147C30.0634 16.2304 30.0634 15.7696 29.8097 15.4853L27.1162 13.2132C26.8625 12.9289 26.4511 12.9289 26.1974 13.2132C25.988 13.4478 25.9947 13.6608 26.0034 13.9389C26.0052 13.9978 26.0071 14.0595 26.0071 14.125L26.0071 15.5H16.5V5.99294L17.5 5.99297C17.5587 5.99297 17.6236 5.99452 17.6923 5.99617C18.063 6.00504 18.547 6.01662 18.7868 5.8026C19.0711 5.54887 19.0711 5.13749 18.7868 4.88376L16.5147 2.1903Z'
				fill='#333333'
			/>
		</svg>
	);
}
