import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {cartesianGridBoxHeight, cartesianGridItemsGap} from '../../constants';

export const CartesianGridValuesBox = styled(GridBox, {
	alignItems: 'center',
	justifyItems: 'end',
	gridRowStart: 1,
	gap: cartesianGridItemsGap,
	position: 'relative',
});

export const CartesianGridValueBox = styled(FlexBox, {
	height: cartesianGridBoxHeight,
	variants: {
		variant: {
			default: {
				alignItems: 'center',
			},

			valued: {
				width: '100%',
				position: 'absolute',
				justifyContent: 'end',
			},
		},

		hidden: {
			true: {
				opacity: 0,
			},
		},
	},
});
