import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {AlertBody, AlertFooter, AlertHeader, alertRootCss, FolderNameBox} from './index.styled';
import {Button} from '@esgi/ui';
import {Delete} from '@esgi/ui/icons';
import {FolderManagement, FolderType} from '../kit';
import {folderEntityName} from './constants';
import {useCallback, useState} from 'react';
import {OneLinedText} from '@esgi/main/kits/common';

type Props = {
	onClose: VoidFunction;
	folderType: FolderType;
	folderID: number;
	folderName: string;
	onFolderRemoved?: VoidFunction;
};

export function RemoveFolder({onClose, folderID, folderType, folderName, onFolderRemoved}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [isFolderRemoving, setIsFolderRemoving] = useState(false);

	const handleFolderRemoved = useCallback(() => {
		onFolderRemoved?.();
		closeAlert();
	}, [closeAlert, onFolderRemoved]);

	const removeFolder = FolderManagement.useRemoveFolder({
		id: folderID,
		type: folderType,
		onFolderRemoved: handleFolderRemoved,
	});

	const handleRemoveFolder = useCallback(() => {
		setIsFolderRemoving(true);
		removeFolder();
	}, [removeFolder]);

	const folderEntityNameLowerCase = folderEntityName[folderType].toLocaleLowerCase();

	return (
		<Alert modalManagerRef={alertRef} css={alertRootCss} colorConfig={alertColorsConfig.negative}>
			<AlertHeader withBacklight={false} onCloseIconClick={closeAlert}>
				<Text size='medium'>Folder Deletion Warning</Text>
			</AlertHeader>

			<AlertBody>
				<Text size='small' color='highContrast'>
					You are about to remove all {folderEntityNameLowerCase} from this folder. This folder cannot exist without{' '}
					{folderEntityNameLowerCase} and will be deleted.
				</Text>

				<FolderNameBox>
					<Delete />
					<OneLinedText size='large' color='negative'>
						{folderName}
					</OneLinedText>
				</FolderNameBox>
			</AlertBody>

			<AlertFooter>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>

					<Button color='warn' onClick={handleRemoveFolder} disabled={isFolderRemoving}>
						<Text size='medium' bold color='negative'>
							Remove {folderEntityName[folderType]} & Delete Folder
						</Text>
					</Button>
				</GridBox>
			</AlertFooter>
		</Alert>
	);
}
