import {NavigationBar, NavigationBarOrientation} from '@esgi/ui/layout';
import {PanelHeader} from './components/panel-header';
import {navigateItems} from '../constants';
import {AdditionalElementType, NavigationPanelVariantProps} from '../types';
import {NavigationPageItem} from '../../components/navigation-page-item';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {contentCSS, rootCSS} from './index.styled';
import {navigationBarHeadHeight} from './components/panel-header/index.styled';
import {SchoolYearSelect} from '@esgi/main/features/common/school-year-select';

export function HorizontalPanel({
	onSchoolYearChanged,
	onSettingsClick,
	userFirstName,
	userLastName,
	userAvatarSrc,
	onAvatarClick,
	onHelpClick,
}: NavigationPanelVariantProps) {
	return (
		<NavigationBar.Root orientation={NavigationBarOrientation.Horizontal} css={rootCSS}>
			<PanelHeader
				onSettingsClick={onSettingsClick}
				userFirstName={userFirstName}
				userLastName={userLastName}
				userAvatarSrc={userAvatarSrc}
				onAvatarClick={onAvatarClick}
				onHelpClick={onHelpClick}
			/>

			<OverlayScrollbarsComponent
				defer
				style={{
					height: `calc(100% - ${navigationBarHeadHeight}px)`,
				}}
				options={{
					scrollbars: {
						autoHide: 'leave',
					},
				}}
			>
				<NavigationBar.Content css={contentCSS}>
					<SchoolYearSelect onSchoolYearChanged={onSchoolYearChanged} />
					{navigateItems.map((item, index) =>
						item === AdditionalElementType.Divider ? (
							<NavigationBar.Divider key={index} />
						) : (
							<NavigationPageItem
								Icon={item.Icon}
								label={item.label}
								to={item.to}
								linkWidth='max-content'
								key={index}
							/>
						),
					)}
				</NavigationBar.Content>
			</OverlayScrollbarsComponent>
		</NavigationBar.Root>
	);
}
