import {Select} from '@esgi/ui/controls';
import {ColorPickerItem} from './types';
import {ItemColorBorder, ItemBox, ItemColorFill, PlaceholderItem, PlaceholderItemCircle} from './index.styled';
import {getThemeColor} from '@esgi/ui';
import {Dispatch, useCallback, useId} from 'react';
import {isNull} from 'underscore';

export {type ColorPickerItem} from './types';

export type ColorPickerProps<T extends string> = {
	items: ColorPickerItem<T>[];
	value: T | null;
	setValue: Dispatch<T | null>;
	disabled?: boolean;
};

export function ColorPicker<T extends string>({items, value, setValue, disabled}: ColorPickerProps<T>) {
	const placeholderItemID = useId();

	const onValueChange = useCallback(
		(value: T | string) => {
			if (value === placeholderItemID) {
				setValue(null);
				return;
			}

			setValue(value as T);
		},
		[placeholderItemID, setValue],
	);

	return (
		<Select.Root
			value={isNull(value) || Boolean(disabled) ? placeholderItemID : value}
			onValueChange={onValueChange}
			disabled={Boolean(disabled)}
		>
			<Select.Field />

			<Select.Content>
				<PlaceholderItem value={placeholderItemID}>
					<PlaceholderItemCircle />
				</PlaceholderItem>

				{items.map(({borderColor, fillColor, value}) => (
					<Select.Option value={value} key={value}>
						<ItemBox>
							<ItemColorBorder css={{backgroundColor: getThemeColor(borderColor)}}>
								<ItemColorFill css={{backgroundColor: getThemeColor(fillColor)}} />
							</ItemColorBorder>
						</ItemBox>
					</Select.Option>
				))}
			</Select.Content>
		</Select.Root>
	);
}
