import {ColDef} from 'ag-grid-community';
import {StudentColID, StudentRowModel} from '../types';
import {CellType} from '@esgi/ui/ag-grid';
import {StudentSort, UserInfo} from '@esgi/core/authentication';

type Parameters = Omit<ColDef<StudentRowModel>, 'field' | 'colId'> & {
	currentUser: Readonly<UserInfo> | null;
};

export function getFirstNameColumnDef<T extends StudentRowModel>({currentUser, ...params}: Parameters): ColDef<T> {
	return {
		// @ts-ignore
		field: 'firstName',
		colId: StudentColID.FirstName,
		sortable: true,
		filter: false,
		flex: 1,
		headerName: 'First Name',
		resizable: false,
		cellDataType: 'text',
		type: CellType.Primary,
		sort: currentUser?.studentSort === StudentSort.FirstName ? 'asc' : null,
		...params,
	};
}
