import {isNull} from 'underscore';
import {useDataPageContext} from '../layout/context';
import {useColumnDefinitions} from './use-column-definitions';
import {useData} from './use-data';
import {Base} from './variant/base';
import {Folder} from './variant/folder';

export default function () {
	const {activeFolder} = useDataPageContext();
	const {ready, rows} = useData();

	const colDefs = useColumnDefinitions(rows);

	if (!isNull(activeFolder)) {
		return (
			<Folder
				columnDefs={colDefs}
				allTableRows={rows}
				activeFolder={activeFolder}
				isDataLoaded={ready}
			/>
		);
	}

	return <Base columnDefs={colDefs} allTableRows={rows} isDataLoaded={ready} />;
}
