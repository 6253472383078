import {SpecialistGroup, Student} from '@esgi/main/libs/school-admin-store';
import {useStreamEffect} from '@esgi/ui';
import {FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {Dispatch, useEffect, useRef, useState} from 'react';
import {isNull} from 'underscore';
import {groupNameMaxLength} from '../../../components/group-name-input/constants';
import {EditSpecialistForm} from './types';

type Parameters = {
	specialistGroupsList: SpecialistGroup[];
	specialistGroup: SpecialistGroup | null;
	setIsFormValid: Dispatch<boolean>;
	setIsFormTouched: Dispatch<boolean>;
	studentsList: Student[];
};

export function useEditForm({
	specialistGroupsList,
	specialistGroup,
	studentsList,
	setIsFormTouched,
	setIsFormValid,
}: Parameters) {
	const [, forceUpdate] = useState({});
	const initialSpecialistGroupName = useRef('');
	const initialStudentsIDs = useRef<Student['id'][]>([]);

	const form: EditSpecialistForm = useForm(
		() =>
			new FormGroup({
				groupName: new FormControl(''),
				studentsIDs: new FormControl<Student['id'][]>([]),
			}),
	);

	useEffect(() => {
		if (isNull(specialistGroup)) {
			return;
		}

		const studentsIDsInSpecialistGroup = studentsList
			.filter(({specialistGroupsIDs}) => specialistGroupsIDs.includes(specialistGroup.id))
			.map(({id}) => id);

		initialSpecialistGroupName.current = specialistGroup.name;
		initialStudentsIDs.current = studentsIDsInSpecialistGroup;

		form.controls.studentsIDs.value = studentsIDsInSpecialistGroup;

		form.controls.groupName.value = specialistGroup.name;
		form.controls.groupName.validators.length = 0;

		form.controls.groupName.validators.push(
			Validators.required(),
			Validators.length.max(groupNameMaxLength),
			Validators.isDublicateValue(
				specialistGroupsList
					.map(({name, id}) => (id === specialistGroup.id ? null : name))
					.filter((item): item is string => !isNull(item)),
			),
		);

		forceUpdate({});
	}, [specialistGroup, specialistGroupsList, studentsList]);

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {groupName, studentsIDs},
			},
		}) => {
			const isFormTouched =
				groupName !== initialSpecialistGroupName.current ||
				studentsIDs.length !== initialStudentsIDs.current.length ||
				studentsIDs.some((id) => !initialStudentsIDs.current.includes(id));

			setIsFormTouched(isFormTouched);

			form.validate(true).subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		},
	);

	return {
		form,
		initialSpecialistGroupName: initialSpecialistGroupName.current,
		initialStudentsIDs: initialStudentsIDs.current,
	};
}
