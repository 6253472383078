import {useMemo} from 'react';
import {useUser} from '@esgi/core/authentication';
import {useClasses} from '@esgi/main/libs/school-admin-store';
import {Hierarchy} from '@esgi/main/features/school-admin/data';
import {ReportDialog, Reports} from '../../../../../../_kit';
import {RowDef} from '../../../../../types';

type Props = {
	teacher: RowDef;
	onClose: VoidFunction;
};

export function ReportsFeature({teacher, onClose}: Props) {
	const [{loaded: isClassesLoaded}] = useClasses();
	const user = useUser();

	const teacherLevelHierarchy = useMemo<Hierarchy>(() => ({
		teacherID: teacher?.id,
		schoolID: user?.schoolID,
	}), [teacher, user]);

	return (
		<ReportDialog
			header={`${teacher.firstName} ${teacher.lastName}`}
			title='Class Reports'
			loading={!isClassesLoaded}
			onClose={onClose}
		>
			<Reports.ItemAnalysis hierarchy={teacherLevelHierarchy} />
			<Reports.PieCharts hierarchy={teacherLevelHierarchy} />
			<Reports.UntestedStudents hierarchy={teacherLevelHierarchy} />
		</ReportDialog>
	);
}
