import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {useTestCardContext} from '../context';
import {TestColorLineIndicator} from '@esgi/main/kits/common';

type Props = BaseComponentProps & Omit<ComponentPropsWithoutRef<'div'>, 'children' | 'color'>;

export const TestIndicator = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-card-indicator', css = {}, ...props}, forwardedRef) => {
		const {testColor, contentArea} = useTestCardContext();

		return (
			<TestColorLineIndicator
				dataCy={dataCy}
				css={css}
				color={testColor}
				contentArea={contentArea}
				ref={forwardedRef}
				{...props}
			/>
		);
	},
);

// do not delete (uses at the root component)
TestIndicator.displayName = 'TestIndicator';
