import {DemographicGroupType} from '@esgi/main/features/admins/dashboard';
import {studentLunchStatusLabel, studentRaceLabel, studentSpecialProgramLabel} from './constants';
import {TemporaryWidgetDataModel} from './types';

export function getTemporaryData({isMultiplePeriods, type}: {isMultiplePeriods: boolean; type: DemographicGroupType}) {
	const iteratedData = (function () {
		if (type === DemographicGroupType.RaceEthnicity) {
			return studentRaceLabel;
		}

		if (type === DemographicGroupType.LunchStatus) {
			return studentLunchStatusLabel;
		}

		if (type === DemographicGroupType.SpecialProgram) {
			return studentSpecialProgramLabel;
		}

		throw new Error('type is not correspond with DemographicGroupType');
	})();

	return Object.entries(iteratedData).reduce<Record<number, TemporaryWidgetDataModel>>((currentData, [id, label]) => {
		currentData[Number(id)] = {
			label,
			data: isMultiplePeriods ? [null, null] : [null],
		};

		return currentData;
	}, {});
}
