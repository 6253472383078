import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {TabInfo} from '../../../../tabs';
import {SelectableList} from '../../lists/selectable-list';
import {BaseComponentProps, isUndefined} from '@esgi/ui';
import {TabID} from '../../../types/section';
import {SubjectModel} from '../../../types/subject';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {TabContent} from './index.styled';
import {EmptyContentBox} from '../../empty-content-box';
import {Text} from '@esgi/ui/typography';
import {useSubjectPanelContext} from '../../../context';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'disabled'> &
	TabInfo<TabID> & {
		subjects: SubjectModel[];
	};

export const BaseTabContent = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'base-tab-content',
			css = {},
			tabID,
			tabContent,
			description,
			disabled,
			applyDisabledStyles,
			subjects,
			children,
			...boxProps
		},
		forwardedRef,
	) => {
		const {skeleton} = useSubjectPanelContext();

		const renderContent = () => {
			if (!isUndefined(children)) {
				return children;
			}

			if (!subjects.length && !skeleton) {
				return (
					<EmptyContentBox justify='center'>
						<Text size='small' font='mono' color='mediumContrast'>
							No subjects to display
						</Text>
					</EmptyContentBox>
				);
			}

			return <SelectableList subjects={subjects} />;
		};

		return (
			<TabContent
				dataCy={dataCy}
				css={css}
				tabID={tabID}
				tabContent={tabContent}
				description={description}
				applyDisabledStyles={applyDisabledStyles}
				disabled={disabled}
				ref={forwardedRef}
				{...boxProps}
			>
				<OverlayScrollbarsComponent
					defer
					options={{
						scrollbars: {autoHide: 'leave'},
					}}
					style={{height: 'calc(100% + 0px)'}}
				>
					{renderContent()}
				</OverlayScrollbarsComponent>
			</TabContent>
		);
	},
);
