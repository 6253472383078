import {TestPanel} from '@esgi/main/kits/test-results-panel';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const FiltersSelectsContainer = styled(GridBox, {
	gap: '$3',
	gridAutoFlow: 'column',
	justifyContent: 'space-between',
	gridTemplateColumns: 'repeat(2, minmax(max-content, 174px))',
});

export const FiltersRoot = styled(TestPanel.Filters.Root, {
	gridTemplateColumns: 'auto 1fr',
});
