import {Card} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardRoot = styled(Card, {
	display: 'block',
	height: '100%',
	boxSizing: 'border-box',
	boxShadow: 'none',
	paddingTop: 16,
	paddingBottom: 16,
	paddingLeft: 20,
	paddingRight: 20,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$surface',
	backgroundColor: '$border',
});

export const CardBody = styled(Card.Body, {
	display: 'grid',
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
});
