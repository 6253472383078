import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';

export const Content = styled(GridBox, {
	padding: '0px 20px',
	gap: '$3',

	variants: {
		gridView: {
			true: {
				alignItems: 'start',
				gridTemplateColumns: 'repeat(2, 1fr)',
			},
		},
	},
});

export const SkeletonCard = styled(Box, {
	...SkeletonStyles,
	width: '100%',
	height: 200,
	borderRadius: 4,
});
