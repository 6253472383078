import {ToggleGroup} from '@esgi/ui/controls';
import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(Box, {
	paddingInline: 20,
});

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
	display: 'grid',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	gridAutoColumns: '1fr',
	backgroundColor: '$background',
});

export const IconWrapper = styled(Box, {
	display: 'inline-flex',
	color: '$base',

	'& svg': {
		width: 24,
		height: 24,

		'& path': {
			fill: 'currentColor',
		},
	},

	variants: {
		isItemSelected: {
			true: {
				color: '$primary',
			},
		},
	},
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	display: 'grid',
	gap: '$1',
	justifyItems: 'center',
	paddingTop: 8,
	paddingBottom: 12,
	paddingInline: 6,

	'&[data-state="off"]': {
		'&:hover': {
			[`> ${IconWrapper}`]: {
				color: '$secondary',
			},
		},
	},
});
