import {
	ExpandableSelectableBlockTab,
	ItemNumerable,
	PublishToSpecialistsNumerable,
} from '@esgi/main/features/admins/subject-management';
import {PublishForSpecialistsTabID} from '../../../../../types';
import {InitSubjectData} from '../../../types';
import {mapToEnum} from 'shared/utils';
import {publishToSpecialistsTitle} from '../../../../constants';

type Parameters = {
	schoolSpecialistsSchools: ItemNumerable[];
	districtSpecialists: ItemNumerable[];
	initSubjectData: InitSubjectData;
};

export function getPublishForSpecialistsInit({
	schoolSpecialistsSchools,
	districtSpecialists,
	initSubjectData,
}: Parameters): ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[] {
	const mappedvalue = mapToEnum(initSubjectData.publishToSpecialists, PublishToSpecialistsNumerable);

	const isBySchool = mappedvalue === PublishToSpecialistsNumerable.IndividualISS;
	const isBySpecialist = mappedvalue === PublishToSpecialistsNumerable.IndividualISD;

	return [
		{
			value: PublishForSpecialistsTabID.All,
			label: publishToSpecialistsTitle[PublishForSpecialistsTabID.All],
			isActive: mappedvalue === PublishToSpecialistsNumerable.All || mappedvalue === PublishToSpecialistsNumerable.None,
			allItems: [],
			selectedItems: [],
		},
		{
			value: PublishForSpecialistsTabID.BySchool,
			label: publishToSpecialistsTitle[PublishForSpecialistsTabID.BySchool],
			isActive: isBySchool,
			allItems: schoolSpecialistsSchools,
			selectedItems: isBySchool
				? initSubjectData.selectedSchoolSpecialists
				: schoolSpecialistsSchools.map(({id}) => id),
		},
		{
			value: PublishForSpecialistsTabID.BySpecialist,
			label: publishToSpecialistsTitle[PublishForSpecialistsTabID.BySpecialist],
			isActive: isBySpecialist,
			allItems: districtSpecialists,
			selectedItems: isBySpecialist
				? initSubjectData.selectedDistrictSpecialists
				: districtSpecialists.map(({id}) => id),
		},
	];
}
