import {useMemo} from 'react';
import {ExcludedSchoolIDs} from './types';
import {SchoolModel, WidgetFormSelectItem} from '@esgi/main/features/admins/dashboard';

export function useSchoolItems({
	allSchoolsList,
	excludedSchoolIDs,
}: {
	allSchoolsList: SchoolModel[];
	excludedSchoolIDs: ExcludedSchoolIDs;
}) {
	return useMemo(() => {
		const firstSchoolItems: WidgetFormSelectItem<string>[] = [];
		const secondSchoolItems: WidgetFormSelectItem<string>[] = [];

		allSchoolsList.forEach(({id, name}) => {
			const idString = String(id);
			const formSchoolItem: WidgetFormSelectItem<string> = {
				value: idString,
				label: name,
			};

			if (idString !== excludedSchoolIDs.secondSchoolID) {
				firstSchoolItems.push(formSchoolItem);
			}

			if (idString !== excludedSchoolIDs.firstSchoolID) {
				secondSchoolItems.push(formSchoolItem);
			}
		});

		return {
			firstSchoolItems,
			secondSchoolItems,
		};
	}, [allSchoolsList, excludedSchoolIDs]);
}
