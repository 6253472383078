export {type OutModel as SettingsData} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/district-admin/init/out-model';
export {type OutModel as ParentLetterData} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/user-settings/district-admin/parent-letter/out-model';

export enum SettingsTab {
	SystemSettings = 'systemSettings',
	Teacher = 'teacher',
	Reports = 'reports',
	CustomizeParentLetter = 'customizeParentLetter',
	ExportData = 'exportData',
}

export enum ShowStudentIDType {
	None = 'None',
	StudentID = 'StudentID',
	ExportID = 'ExportID',
}

export interface TabItem {
	value: SettingsTab;
	name: string;
}

export interface TabItemsGroup {
	general: TabItem[];
	teachersAndStudents: TabItem[];
	export: TabItem[];
}

export type SettingsTabTouchedData = {
	[tab in SettingsTab]: boolean;
}
