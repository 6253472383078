import {useCallback, useState} from 'react';
import {useUser} from '@esgi/core/authentication';
import {AddEditStudent} from '@esgi/main/features/school-admin/student-profile-drawer';
import {useColumnDefinitions} from './use-column-definitions';
import {Base} from './variant/base';
import {useData} from './use-data';

export default function () {
	const user = useUser();
	const {ready, rows, ...filterData} = useData();

	const colDefs = useColumnDefinitions(filterData);
	const [isOpenAddDrawer, setIsOpenAddDrawer] = useState(false);

	const onOpenAddDrawer = useCallback(() => {
		setIsOpenAddDrawer(true);
	}, []);

	const onCloseAddDrawer = useCallback(() => {
		setIsOpenAddDrawer(false);
	}, []);

	return (
		<>
			<Base
				columnDefs={colDefs}
				allTableRows={rows}
				isDataLoaded={ready}
				addButtonDisabled={!user?.canAddStudents}
				onOpenAddDrawer={onOpenAddDrawer}
			/>
			{isOpenAddDrawer && <AddEditStudent studentID={null} onClose={onCloseAddDrawer} />}
		</>
	);
}
