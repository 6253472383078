import {SubjectsSchoolController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {GradeLevel, ItemNumerable, TrackDate} from '@esgi/main/features/admins/subject-management';
import {getFullName} from '@esgi/main/kits/common';
import {useSchoolSpecialists} from '@esgi/main/libs/school-admin-store';
import {isAsyncIdle, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useEffect, useMemo, useState} from 'react';

export function useInitData() {
	const [{data: schoolSpecialistsStore, loaded: isSchoolSpecialistsLoaded}] = useSchoolSpecialists();

	const controller = useDisposable(SubjectsSchoolController);

	const [initData, init] = useCancelableRequest(controller.init);

	const [trackID, setTrackID] = useState<number | null>(null);
	const [allTrackDates, setAllTrackDates] = useState<TrackDate[]>([]);
	const [gradeLevels, setGradeLevels] = useState<GradeLevel[]>([]);

	useEffect(() => {
		if (isAsyncIdle(initData)) {
			init();
			return;
		}

		if (isAsyncSucceed(initData)) {
			const {gradeLevels, trackDates, tracks} = initData.data;

			setTrackID(tracks[0]?.id ?? null);

			setAllTrackDates(
				trackDates.map(({trackID, trackDateID, dateFrom, dateTo}) => ({trackID, trackDateID, dateFrom, dateTo})),
			);

			setGradeLevels(gradeLevels.map(({gradeLevelID, name, shortName}) => ({id: gradeLevelID, name, shortName})));
		}
	}, [init, initData]);

	const schoolSpecialists = useMemo<ItemNumerable[]>(
		() => schoolSpecialistsStore.map(({id, firstName, lastName}) => ({id, name: getFullName({firstName, lastName})})),
		[schoolSpecialistsStore],
	);

	return {
		trackID,
		allTrackDates,
		gradeLevels,
		schoolSpecialists,
		isDataLoaded: isAsyncSucceed(initData) && isSchoolSpecialistsLoaded,
	};
}
