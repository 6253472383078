import {TransferStudentForm} from './form';
import {stringArrayToNumberArray} from './utils';

export {type School} from '@esgi/contracts/esgi/types/esgi.schools/schools/get/by-district-id/school';
export {type ClassModel} from '@esgi/contracts/esgi/types/esgi.students/queries/esgi.apigateway/modules/forms/student/class-model';
export {type UserModel} from '@esgi/contracts/esgi/types/esgi.accounts/queries/esgi.apigateway/modules/forms/student/users/user-model';
export {type Response as StudentData} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/modules/students/profile/update/init/response';
export {type SchoolSpecialist} from '@esgi/contracts/esgi/types/sso.accounts/features/esgi.districtadmin/schools-specialist/get-by-school-id/school-specialist';

export enum FieldValidation {
	Required = 'required',
	AssignRequired = 'AssignRequired',
}

export enum AssignStudentType {
	Teacher = 'Teacher',
	Specialist = 'Specialist',
}

export interface TeacherEntityGroups {
	groupIDs: string[];
	classIDs: string[]
}

export class Location {
	public districtID: number = undefined;
	public schoolID: number;
	public globalSchoolYearID: number = undefined;
	public teacherID: number;
	public specialistID: number = undefined;
	public classIDs: number[];
	public groupIDs: number[];
	public specialistGroupIDs: number[];

	constructor(location: TransferStudentForm['value']) {
		this.schoolID = location.schoolID ? Number(location.schoolID) : undefined;
		this.teacherID = location.teacherID ? Number(location.teacherID) : undefined;
		this.classIDs = stringArrayToNumberArray(location.teacherEntityIDs.classIDs);
		this.groupIDs = stringArrayToNumberArray(location.teacherEntityIDs.groupIDs);
		this.specialistGroupIDs = stringArrayToNumberArray(location.specialistGroupIDs);
	}
}