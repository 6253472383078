import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {HttpClient} from '@esgi/api';
import {dispatchAppEvent, useEventEffect} from '@esgillc/events';
import {BaseService} from '@esgi/core/service';
import {ModalDOMHolder} from '@esgi/deprecated/react';
import {getUser, LogoutEvent, UserInfoProvider} from '@esgi/core/authentication';
import {registerDefaultErrorHandler} from '@esgillc/ui-kit/core';
import {ErrorReporterLayer, ReportErrorEvent} from '@esgi/core/react';

import {HttpClientBuilder} from './http-client/http-client-builder';
import {closeWindowAndRedirectTo, getMetaValue} from './utils';
import GlobalVariables from './global';
import {NotAuthorizedAlertLayer} from './layers/not-authorized-layer';
import {LoadAlertsLayer} from './layers/load-alerts-layer';
import {CookiesBannerLayer} from './layers/cookies-banner-layer';
import {ClassLinkMessagesLayer} from './layers/class-link-messages-layer';
import {useLocalStorageWatcher, useLogRocket, useVersionCompatibility} from './hooks';
import {ReportLayerHandler} from './layers/report-layer-handler';
import {registerContracts} from './http-client/register-contracts';
import {Outlet, useNavigate} from 'react-router-dom';
import AppHistory from 'shared/tools/history/history';
import {useMoveUserHandler} from './hooks/use-move-user-handler';
import {UserMoveAlertLayer} from './layers/move-user-layers';

BaseService.httpClientBuilder = HttpClientBuilder.build;
GlobalVariables.FrontendVersion = getMetaValue('esgi-version');


export function PageRoot() {
	const [contractsRegistered, setContractsRegistered] = useState(false);

	useLogRocket();
	useLocalStorageWatcher();
	useVersionCompatibility();
	useMoveUserHandler();

	useEventEffect(LogoutEvent, () => closeWindowAndRedirectTo());

	useEffect(() => {
		registerDefaultErrorHandler(() => dispatchAppEvent(ReportErrorEvent, new ReportErrorEvent('Uh oh. Something went wrong with the UI on our end.')));

		HttpClient.default = HttpClientBuilder.build();
		axios.interceptors.request.use((request) => {
			const user = getUser();
			if(user) {
				request.headers['esgi-global-school-year-id'] = user.globalSchoolYearID;
			}

			request.headers['esgi-frontend-version'] = GlobalVariables.FrontendVersion;
			return request;
		});

		registerContracts();
		setContractsRegistered(true);
	}, []);

	const navigate = useNavigate();

	useEffect(() => {
		AppHistory.navigate = navigate;
	}, [navigate]);

	if (!contractsRegistered) {
		return null;
	}


	return <UserInfoProvider>
		<Outlet/>
		<ModalDOMHolder/>
		<UserMoveAlertLayer />
		<NotAuthorizedAlertLayer/>
		<ErrorReporterLayer>
			{(error, next) => <ReportLayerHandler error={error} next={next}/>}
		</ErrorReporterLayer>
		<LoadAlertsLayer/>
		<CookiesBannerLayer/>
		<ClassLinkMessagesLayer/>
	</UserInfoProvider>;
}