import {createContext, useContext} from 'react';
import {SvgValue, UpdateShapeOffset} from './types';
import {noop} from 'underscore';

export type LoadingContextValue = {
	svgValue: SvgValue;
	updateShapeOffset: UpdateShapeOffset;
	maxShapeOffset: number;
};

export const LoadingContext = createContext<LoadingContextValue>({
	svgValue: {
		radius: 0,
		size: 0,
		center: {
			x: 0,
			y: 0,
		},
	},
	updateShapeOffset: noop,
	maxShapeOffset: 0,
});

export function useLoadingContext() {
	return useContext(LoadingContext);
}
