import {useEffect, useState} from 'react';
import {DatesPeriodModel, TrackModel} from '../../types';
import {V2TracksController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {isAsyncIdle, isAsyncPending, isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';
import {getCurrentTrackDate} from './helpers';

export function useUserCurrentTrack() {
	const [currentTrack, setCurrentTrack] = useState<TrackModel | null>(null);
	const [currentTrackDate, setCurrentTrackDate] = useState<DatesPeriodModel | null>(null);

	const tracksController = useDisposable(V2TracksController);

	const [tracksInitData, fetchTracksInitData] = useCancelableRequest(tracksController.init);

	useEffect(() => {
		if (isAsyncIdle(tracksInitData)) {
			fetchTracksInitData();
			return;
		}

		if (isAsyncSucceed(tracksInitData)) {
			const {selectedTrackID, tracks} = tracksInitData.data;

			const selectedTrack = tracks.find(({trackID}) => trackID === selectedTrackID);

			if (isUndefined(selectedTrack)) {
				return;
			}

			const currentTrackDate = getCurrentTrackDate(selectedTrack.trackDates);

			setCurrentTrack(selectedTrack);
			setCurrentTrackDate(currentTrackDate);

			return;
		}
	}, [fetchTracksInitData, tracksInitData]);

	return {
		currentTrack,
		currentTrackDate,
		skeleton: isAsyncPending(tracksInitData),
	};
}
