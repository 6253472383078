import {AppRoot} from './components/app-root';
import {Header} from './components/header';
import {Panel} from './components/panel';
import {QuickPanel} from './components/quick-panel';
import {Root} from './components/root';
import {useClose} from './hooks/use-close';
import {useCollapse} from './hooks/use-collapse';
import {useExpandPanel} from './hooks/use-expand-panel';
import {usePanelRef} from './hooks/use-panel-ref';

export const CollapsiblePanel = {
	AppRoot,
	Root,
	Header,
	QuickPanel,
	Panel,
	useRef: usePanelRef,
	useClose,
	useExpandPanel,
	useCollapse,
};

export {type ManagerRef} from './types';
