import {ReportDialog, Reports} from '../reports-dialog';

type Props = {
	onClose: VoidFunction,
}

export function SchoolReportsDialog({onClose}: Props) {
	return <ReportDialog onClose={onClose} header='School' title='School Reports'>
		<Reports.GradeScale name='School Grades' description='Apply customizable grade scales to school scores' hierarchy={{}}/>
		<Reports.TotalStudents name='School Students' description='Review and compare student scores for a subject tab at the School level' hierarchy={{}}/>
		<Reports.Totals name='School Totals' description='Review and compare school scores for a subject tab' hierarchy={{}}/>
		<Reports.ItemAnalysis hierarchy={{}}/>
		<Reports.PieCharts hierarchy={{}}/>
	</ReportDialog>;
}