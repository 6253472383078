import {Page} from '@esgi/main/features/admins/dashboard';
import {Body, SkeletonCard} from './index.styled';

export function PageSkeletonMode() {
	return (
		<>
			<Page.Header>
				<Page.Header.Skeleton />
			</Page.Header>
			<Body>
				{new Array(10).fill(null).map((_, index) => (
					<SkeletonCard width='100%' height={400} key={index} />
				))}
			</Body>
		</>
	);
}
