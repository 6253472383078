import * as React from 'react';
import type {SVGProps} from 'react';

export function CompareDates(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				d='M16 4.5C15.5858 4.5 15.25 4.83579 15.25 5.25V26.75C15.25 27.1642 15.5858 27.5 16 27.5C16.4142 27.5 16.75 27.1642 16.75 26.75V5.25C16.75 4.83579 16.4142 4.5 16 4.5Z'
				fill='#333333'
			/>
			<path
				d='M12.1 7.85009C12.1 7.38065 11.7195 7.00009 11.25 7.00009C10.7805 7.00009 10.4 7.38065 10.4 7.85009V8.70009C8.52227 8.70009 7 10.2224 7 12.1001V20.6001C7 22.4777 8.52227 24.0001 10.4 24.0001H13.1397C13.2501 24.0001 13.3397 23.9105 13.3397 23.8001V22.5001C13.3397 22.3896 13.2501 22.3001 13.1397 22.3001H10.4C9.46109 22.3001 8.7 21.5393 8.7 20.6001V12.1001C8.7 11.1608 9.46109 10.4001 10.4 10.4001V11.2501C10.4 11.7195 10.7805 12.1001 11.25 12.1001C11.7195 12.1001 12.1 11.7195 12.1 11.2501V10.4001H13.1397C13.2501 10.4001 13.3397 10.3105 13.3397 10.2001V8.90009C13.3397 8.78963 13.2501 8.70009 13.1397 8.70009H12.1V7.85009Z'
				fill='#333333'
			/>
			<path
				d='M12.3833 16.3501C12.3833 15.8806 12.0028 15.5001 11.5333 15.5001C11.0639 15.5001 10.6833 15.8806 10.6833 16.3501C10.6833 16.8195 11.0639 17.2001 11.5333 17.2001C12.0028 17.2001 12.3833 16.8195 12.3833 16.3501Z'
				fill='#333333'
			/>
			<path
				d='M19.8999 7.85009C19.8999 7.38065 20.2804 7.00009 20.7499 7.00009C21.2193 7.00009 21.5999 7.38065 21.5999 7.85009V8.70009C23.4776 8.70009 24.9999 10.2224 24.9999 12.1001V20.6001C24.9999 22.4777 23.4776 24.0001 21.5999 24.0001H18.8602C18.7498 24.0001 18.6602 23.9105 18.6602 23.8001V22.5001C18.6602 22.3896 18.7498 22.3001 18.8602 22.3001H21.5999C22.5388 22.3001 23.2999 21.5393 23.2999 20.6001V12.1001C23.2999 11.1608 22.5388 10.4001 21.5999 10.4001V11.2501C21.5999 11.7195 21.2193 12.1001 20.7499 12.1001C20.2804 12.1001 19.8999 11.7195 19.8999 11.2501V10.4001H18.8602C18.7498 10.4001 18.6602 10.3105 18.6602 10.2001V8.90009C18.6602 8.78963 18.7498 8.70009 18.8602 8.70009H19.8999V7.85009Z'
				fill='#333333'
			/>
			<path
				d='M19.6165 16.3501C19.6165 15.8806 19.9971 15.5001 20.4665 15.5001C20.936 15.5001 21.3165 15.8806 21.3165 16.3501C21.3165 16.8195 20.936 17.2001 20.4665 17.2001C19.9971 17.2001 19.6165 16.8195 19.6165 16.3501Z'
				fill='#333333'
			/>
		</svg>
	);
}
