import {TestContentArea} from '@esgi/main/kits/common';
import {WidgetViewSize} from '../types';

export {PerformanceWidgetViewType as PerformanceWidgetViewTypeNumerable} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/performance-widget-view-type';

export enum PerformanceWidgetViewType {
	None = 'None',
	Candles = 'Candles',
	Piechart = 'Piechart',
}

export enum WidgetMode {
	View = 'View',
	Edit = 'Edit',
}

export type SortOption<T extends string> = {
	value: T;
	label: string;
};

export type WidgetModel<T = unknown> = T & {
	id: number;
	title: string;
	size: WidgetViewSize;
};

export type ContentAreaItem = {
	value: TestContentArea;
	label: string;
};
