export enum SchoolYearType {
	Semester = 1,
	Trimester = 2,
	Quarter = 3,
	Periods5 = 4,
	Periods6 = 5,
	Periods7 = 6,
	Periods8 = 7,
	Periods9 = 8,
}

export type CurrentTrackDate = {
	id: number;
	name: string;
};
