import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container} from './index.styled';

type Props = BaseComponentProps & ComponentPropsWithoutRef<typeof Container>;

export const Root = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'subjects-panel-section-title', css = {}, children, ...gridProps}, forwardedRef) => {
		return (
			<Container dataCy={dataCy} css={css} ref={forwardedRef} {...gridProps}>
				{children}
			</Container>
		);
	},
);
