import {InputsBox} from '../../../../index.styled';
import {BoxplotTwoPeriodsForm, BoxplotTwoPeriodsInitialFormData} from '../../../../forms-data/types';
import {useStreamEffect} from '@esgi/ui';
import {useCallback, useEffect, useState} from 'react';
import {ExcludedColors} from './types';
import {DynamicFormProps} from '../../../../types';
import {PeriodRow} from './components/period-row';
import {isPeriodRowTouched} from './helpers/is-period-row-touched';
import {Color} from '@esgi/main/features/admins/dashboard';

type Props = DynamicFormProps<{
	twoPeriodsForm: BoxplotTwoPeriodsForm;
	initialBoxplotTwoPeriodsForm: BoxplotTwoPeriodsInitialFormData;
}>;

export function TwoPeriods({
	twoPeriodsForm,
	initialBoxplotTwoPeriodsForm,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	onCurrentDynamicFormNameChange,
}: Props) {
	const [excludedColors, setExcludedColors] = useState<ExcludedColors>({
		firstPeriodColor: twoPeriodsForm.controls.firstPeriodColor.value[0] ?? null,
		secondPeriodColor: twoPeriodsForm.controls.secondPeriodColor.value[0] ?? null,
	});

	const updateExcludedColors = useCallback(({key, newValue}: {key: keyof ExcludedColors; newValue: Color | null}) => {
		setExcludedColors((currentState) => ({
			...currentState,
			[key]: newValue,
		}));
	}, []);

	useStreamEffect(twoPeriodsForm.controls.firstPeriodColor.onChanged, ({currState: {value}}) => {
		updateExcludedColors({
			key: 'firstPeriodColor',
			newValue: value[0] ?? null,
		});
	});

	useStreamEffect(twoPeriodsForm.controls.secondPeriodColor.onChanged, ({currState: {value}}) => {
		updateExcludedColors({
			key: 'secondPeriodColor',
			newValue: value[0] ?? null,
		});
	});

	const handleValidate = useCallback(() => {
		const {firstPeriodColor, secondPeriodColor, firstPeriodDate, secondPeriodDate} = twoPeriodsForm.value;

		const isFirstPeriodTouched = isPeriodRowTouched({
			initialColor: initialBoxplotTwoPeriodsForm.firstPeriod?.color,
			initialDates: initialBoxplotTwoPeriodsForm.firstPeriod,
			currentColor: firstPeriodColor[0],
			currentDates: firstPeriodDate,
		});

		const isSecondPeriodTouched = isPeriodRowTouched({
			initialColor: initialBoxplotTwoPeriodsForm.secondPeriod?.color,
			initialDates: initialBoxplotTwoPeriodsForm.secondPeriod,
			currentColor: secondPeriodColor[0],
			currentDates: secondPeriodDate,
		});

		setIsDynamicFormDataTouched(isFirstPeriodTouched || isSecondPeriodTouched);

		twoPeriodsForm.validate(true).subscribe(({valid}) => {
			setIsDynamicFormDataValid(valid);
		});
	}, [initialBoxplotTwoPeriodsForm, setIsDynamicFormDataTouched, setIsDynamicFormDataValid, twoPeriodsForm]);

	useEffect(() => {
		handleValidate();
		onCurrentDynamicFormNameChange('boxplotCompareTwoPeriods');
	}, [handleValidate, onCurrentDynamicFormNameChange]);

	useStreamEffect(twoPeriodsForm.onChanged, handleValidate);

	return (
		<InputsBox>
			<PeriodRow
				form={twoPeriodsForm}
				colorControl={twoPeriodsForm.controls.firstPeriodColor}
				dateRangeControl={twoPeriodsForm.controls.firstPeriodDate}
				excludedColor={excludedColors.secondPeriodColor}
			/>
			<PeriodRow
				form={twoPeriodsForm}
				colorControl={twoPeriodsForm.controls.secondPeriodColor}
				dateRangeControl={twoPeriodsForm.controls.secondPeriodDate}
				excludedColor={excludedColors.firstPeriodColor}
			/>
		</InputsBox>
	);
}
