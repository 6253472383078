import {concatMap} from 'rxjs';
import {isNull} from 'underscore';
import {useCallback, useEffect, useState} from 'react';
import {useBehaviorSubject} from '@esgi/ui';
import {useService} from '@esgi/core/service';
import {useEventEffect} from '@esgillc/events';
import {useUser} from '@esgi/core/authentication';
import {useUserCurrentTrack} from '@esgi/main/kits/admin';
import {Page} from '@esgi/main/features/admins/dashboard';
import {WidgetCreated} from '@esgi/main/features/school-admin/dashboard/events';
import {PageSkeletonMode} from './variant-mode/page-skeleton-mode';
import {PageContent} from './variant-mode/page-content';
import {PageService} from './page-service';
import {useAppSync} from './use-app-sync';

export default function DashboardPage() {
	const currentUser = useUser();

	const [isPageDataLoaded, setIsPageDataLoaded] = useState(false);
	const [lastNewWidgetsID, setLastNewWidgetsID] = useState<number | null>(null);

	const {skeleton: currentTrackLoading, currentTrack} = useUserCurrentTrack();

	const pageService = useService(PageService);

	const widgets = useBehaviorSubject(pageService.widgets$);
	const widgetsMetricData = useBehaviorSubject(pageService.widgetsMetricData$);

	const teachers = useBehaviorSubject(pageService.teachers$);
	const classes = useBehaviorSubject(pageService.classes$);
	const subjectTabs = useBehaviorSubject(pageService.subjectTabs$);
	const gradeLevels = useBehaviorSubject(pageService.gradeLevels$);

	const lastUpdatedDate = useBehaviorSubject(pageService.lastUpdatedDate$);
	const contentAreas = useBehaviorSubject(pageService.contentAreas$);

	const initWidgetsData = useCallback(pageService.initWidgetsData.bind(pageService), [pageService]);
	const updateLastUpdatedDate = useCallback(pageService.updateLastUpdatedDate.bind(pageService), [pageService]);
	const fetchWidgetDataByID = useCallback(pageService.fetchWidgetDataByID.bind(pageService), [pageService]);

	useEffect(() => {
		if (!isNull(currentUser)) {
			pageService
				.fetchContentAreas()
				.pipe(
					concatMap((contentAreas) =>
						pageService.init({globalSchoolYearID: currentUser.globalSchoolYearID, contentAreasList: contentAreas}),
					),
					concatMap(({value: {dashboardVersionID}}) => {
						return initWidgetsData({dashboardVersionID});
					}),
				)
				.subscribe(() => {
					setIsPageDataLoaded(true);
				});
		}
	}, [currentUser, initWidgetsData, pageService]);

	useAppSync({
		initWidgetsData,
		updateLastUpdatedDate,
		fetchWidgetDataByID,
	});

	useEventEffect(WidgetCreated, (data) => {
		pageService.addNewWidgetInList(data);
		setLastNewWidgetsID(data.id);
	});

	const resetLastNewWidgetsID = useCallback(() => {
		setLastNewWidgetsID(null);
	}, []);

	return (
		<Page>
			{!isPageDataLoaded || currentTrackLoading ? (
				<PageSkeletonMode />
			) : (
				<PageContent
					subjects={subjectTabs}
					widgetsList={widgets}
					lastUpdatedDate={lastUpdatedDate}
					allClassesList={classes}
					allTeachersList={teachers}
					updateSchoolPerformanceWidget={pageService.updateSchoolPerformanceWidget.bind(pageService)}
					updateSchoolDemographicPerformanceWidget={pageService.updateSchoolDemographicPerformanceWidget.bind(pageService)}
					updateSchoolStudentsNeedingSupportWidget={pageService.updateSchoolStudentsNeedingSupportWidget.bind(pageService)}
					deleteWidgetByID={pageService.deleteWidgetByID.bind(pageService)}
					updateWidgetsOrdering={pageService.updateWidgetsOrdering.bind(pageService)}
					lastNewWidgetsID={lastNewWidgetsID}
					onResetLastNewWidgetsID={resetLastNewWidgetsID}
					widgetsMetricData={widgetsMetricData}
					gradeLevels={gradeLevels}
					currentTrack={currentTrack}
					contentAreas={contentAreas}
				/>
			)}
		</Page>
	);
}
