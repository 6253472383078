import {Dispatch, PropsWithChildren, useCallback, useState} from 'react';
import {useWidgetCardContext} from '../../context';
import {FormControl, FormGroup, Validators, useForm} from '@esgi/ui/form';
import {Header} from './components/header';
import {DeleteWidgetAlert} from './components/delete-widget-alert';
import {FormRoot} from './index.styled';
import {Card} from '@esgi/ui/layout';
import {useStreamEffect} from '@esgi/ui';
import {OnUpdateWidget} from './types';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';

type Props = PropsWithChildren<{
	onDeleteWidgetByID: Dispatch<number>;
	isChildrenFormValid: boolean;
	isChildrenFormTouched: boolean;
	onUpdateWidget: OnUpdateWidget;
}>;

export function EditMode({
	onDeleteWidgetByID,
	isChildrenFormTouched,
	isChildrenFormValid,
	onUpdateWidget,
	children,
}: Props) {
	const {setViewMode, id, name: widgetName} = useWidgetCardContext();

	const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(false);
	const [isUnsavedChangesConfirmation, setIsUnsavedChangesConfirmation] = useState(false);

	const [isControlledFormTouched, setIsControlledFormTouched] = useState(false);
	const [isControlledFormValid, setIsControlledFormValid] = useState(true);

	const controlledForm = useForm(
		() =>
			new FormGroup({
				name: new FormControl(widgetName, {
					validators: [Validators.required()],
				}),
			}),
	);

	const isFormTouched = isControlledFormTouched || isChildrenFormTouched;
	const isFormValid = isControlledFormValid && isChildrenFormValid;

	useStreamEffect(controlledForm.controls.name.onChanged, ({currState: {value}}) => {
		const isFormTouched = value !== widgetName;

		setIsControlledFormTouched(isFormTouched);

		controlledForm.controls.name.validate(true).subscribe((validators) => {
			setIsControlledFormValid(!validators.length);
		});
	});

	const onOpenDeleteWidgetAlert = useCallback(() => {
		setIsOpenDeleteConfirmation(true);
	}, []);

	const onCloseDeleteWidgetAlert = useCallback(() => {
		setIsOpenDeleteConfirmation(false);
	}, []);

	const onCloseUnsavedChangesConfirmation = useCallback(() => {
		setIsUnsavedChangesConfirmation(false);
	}, []);

	const onCloseEditMode = useCallback(() => {
		if (isFormTouched) {
			setIsUnsavedChangesConfirmation(true);

			return;
		}

		setViewMode();
	}, [isFormTouched, setViewMode]);

	const onDeleteWidget = useCallback(() => {
		onDeleteWidgetByID(id);
	}, [onDeleteWidgetByID, id]);

	const onSaveWidgetData = useCallback(() => {
		onUpdateWidget({
			id,
			name: controlledForm.controls.name.value,
		});

		setIsControlledFormTouched(false);
		setIsControlledFormValid(true);

		setViewMode();
	}, [controlledForm.controls.name.value, id, onUpdateWidget, setViewMode]);

	return (
		<>
			<FormRoot controller={controlledForm}>
				<Header
					onOpenDeleteWidgetAlert={onOpenDeleteWidgetAlert}
					onCloseEditMode={onCloseEditMode}
					widgetNameControl={controlledForm.controls.name}
					isSaveButtonDisabled={!(isFormTouched && isFormValid)}
					onSaveClick={onSaveWidgetData}
				/>

				<Card.Separator />

				{children}
			</FormRoot>

			{isOpenDeleteConfirmation && (
				<DeleteWidgetAlert onDeleteWidget={onDeleteWidget} onCloseAlert={onCloseDeleteWidgetAlert} />
			)}

			{isUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onCloseAnyway={setViewMode} onClose={onCloseUnsavedChangesConfirmation} />
			)}
		</>
	);
}
