import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RowDef} from '../../../../../types';
import {ReportDialog, Reports} from '../../../../../../_kit';

type Props = {
	student: RowDef;
	onClose: VoidFunction;
};

export function ReportsFeature({student, onClose}: Props) {
	const hierarchy = useMemo<Hierarchy>(
		() => ({
			schoolID: student.school?.id,
			teacherID: student.teacher?.id,
			classID: student.class[0]?.id,
			groupID: student.group[0]?.id,
			specialistsID: student.specialist[0]?.id,
			specialistGroupID: student.specialistGroups[0]?.id,
			studentID: student.id,
		}),
		[student],
	);

	return (
		<ReportDialog onClose={onClose} header={`${student.firstName} ${student.lastName}`}>
			<ReportDialog.Section title='Student Reports'>
				<Reports.ParentLetter hierarchy={hierarchy} />
				<Reports.StudentDetail hierarchy={hierarchy} />
				<Reports.StudentProgress hierarchy={hierarchy} />
			</ReportDialog.Section>
		</ReportDialog>
	);
}
