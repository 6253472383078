import {useCallback} from 'react';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';

type Props = {
	onProceed: VoidFunction;
	onClose: VoidFunction;
};

export function UnselectAlert({onProceed, onClose}: Props) {
	const confirmationAlert = Alert.useRef();

	const closeAlert = Alert.useClose(confirmationAlert, onClose);

	const handleProceed = useCallback(() => {
		closeAlert();
		onProceed();
	}, [closeAlert, onProceed]);

	return (
		<Alert colorConfig={alertColorsConfig.negative} modalManagerRef={confirmationAlert}>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='negative'>
					Remove Selected Tests
				</Text>
			</Alert.Header>
			<Alert.Body>
				<GridBox>
					<Text size='medium' color='highContrast'>
						This will remove selection for all selected tests. Do you wish to proceed?
					</Text>
				</GridBox>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold>
							Cancel
						</Text>
					</Button>
					<Button color='warn' onClick={handleProceed}>
						<Text size='medium' bold>
							Remove
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
