import {FlexBox} from '@esgi/ui/layout';
import {FiltersPaginationContainer, InputSearch, SearchInfoBox} from './index.styled';
import {CounterWithTranscript} from '../counter-with-transcript';
import {Text} from '@esgi/ui/typography';
import {AppliedFiltersInfo} from '../../../applied-filters-info';
import {GridApi} from 'ag-grid-community';
import {isNull} from 'underscore';
import {Pagination} from '@esgi/ui/ag-grid';
import {useEffect, useState} from 'react';

type Props = {
	api: GridApi | null;
};

export function TableControl({api}: Props) {
	const [rowsCount, setRowsCount] = useState(api?.getDisplayedRowCount() ?? 0);

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		const updateRowsCount = () => {
			setRowsCount(api.getDisplayedRowCount());
		};

		updateRowsCount();

		api.addEventListener('modelUpdated', updateRowsCount);

		return () => {
			api.removeEventListener('modelUpdated', updateRowsCount);
		};
	}, [api]);

	return (
		<FlexBox align='center' justify='between'>
			<SearchInfoBox>
				<InputSearch gridApi={api} />

				<CounterWithTranscript count={rowsCount}>
					<Text size='xSmall' font='mono' color='mediumContrast'>
						Students
					</Text>
				</CounterWithTranscript>
			</SearchInfoBox>

			{!isNull(api) && (
				<FiltersPaginationContainer>
					<AppliedFiltersInfo gridApi={api} />
					<Pagination api={api} />
				</FiltersPaginationContainer>
			)}
		</FlexBox>
	);
}
