import {Datepicker, CompareDates} from '@esgi/ui/icons';
import {BoxplotCompareVariant} from '../../types';
import {ToggleGroupItemModel} from '@esgi/main/features/admins/dashboard';

export const periodItems: ToggleGroupItemModel<BoxplotCompareVariant>[] = [
	{
		value: BoxplotCompareVariant.SinglePeriod,
		label: 'Single Period',
		Icon: Datepicker,
	},
	{
		value: BoxplotCompareVariant.CompareTwoPeriods,
		label: 'Two Periods',
		Icon: CompareDates,
	},
];
