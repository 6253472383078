import {RemoveTeacherImpl} from './component';
import {useManagerRef} from './hooks/use-manager-ref';

export const RemoveTeacher = Object.assign(RemoveTeacherImpl, {
	useManagerRef,
});

export {type OnTeacherRemoved} from './types';

export {TeacherRemovedEvent} from './events';
