import {Button} from '@esgi/ui';
import {Close} from '@esgi/ui/icons';
import {Container, Content, Divider, Header} from './index.styled';
import {SkeletonShape} from '@esgi/main/kits/common';

type Props = {
	onClose: VoidFunction;
};

export function DrawerSkeleton({onClose}: Props) {
	return (
		<Container>
			<Header>
				<SkeletonShape shape='round' width={24} height={24} />
				<SkeletonShape width={100} />
				<Button.Icon onClick={onClose} withBackgroundHover>
					<Close />
				</Button.Icon>
			</Header>
			<Divider />
			<Content>
				{new Array(6).fill(null).map((_, index) => (
					<SkeletonShape width='100%' height={42} key={index} />
				))}
			</Content>
		</Container>
	);
}
