import {ErrorTooltipItem} from '../error-tooltip';

export const groupNameMaxLength = 128;

export const errorItems: ErrorTooltipItem<string>[] = [
	{
		showOnError: 'required',
		message: 'Not less than 1 character',
	},
	{
		showOnError: 'isDublicateValue',
		message: 'This group name is already taken. Please enter a unique group name',
	},
	{
		showOnError: 'length-max',
		message: `No more than ${groupNameMaxLength} characters`,
	},
];
