import {useMemo} from 'react';
import {unique} from 'underscore';

import {useDisposable, useEndpointResult} from '@esgi/core/service';
import {V2SchoolAdminsPagesDataAnalyticsController, V2SchoolAdminsUserAccountController} from '@esgi/contracts/esgi';

import {RowDef} from './types';
import {useSchoolSpecialists, useSpecialistGroups} from '@esgi/main/libs/school-admin-store';

export function useData() {
	const [{data: schoolSpecialists}] = useSchoolSpecialists();
	const [{data: specialistGroups, loaded: specialistGroupsLoaded}] = useSpecialistGroups();

	const analyticController = useDisposable(V2SchoolAdminsPagesDataAnalyticsController);
	const analyticsData = useEndpointResult(() => {
		if(specialistGroupsLoaded) {
			return analyticController.specialistGroups({specialistGroupIDs: specialistGroups.map(g => g.id)});
		}
	}, [specialistGroups, specialistGroupsLoaded]);

	const userAccountController = useDisposable(V2SchoolAdminsUserAccountController);

	const userControllerData = useEndpointResult(() => {
		if (specialistGroupsLoaded) {
			return userAccountController.init();
		}
	}, [specialistGroups, specialistGroupsLoaded]);

	const schoolName = useMemo(() => {
		return userControllerData?.user.schoolName;
	}, [userControllerData]);

	const {rows, specialists} = useMemo(() => {
		const allSpecialists = schoolSpecialists;
		const rows = specialistGroups
			.filter(group => (group.userType as any) !== 'PA')
			.map(group => {
				const specialist = allSpecialists.find(s => s.id === group.specialistID);
				const analytics = analyticsData?.value?.specialistGroupsAnalytics?.find(g => g.groupID === group.id);

				return {
					id: group.id,
					name: group.name,
					specialist: specialist,
					students: analytics?.studentsCount,
				} as RowDef;
			});
		return {
			rows,
			specialists: unique(rows.map(r => r.specialist)).filter(Boolean),
		};
	}, [schoolSpecialists, specialistGroups, analyticsData]);

	return {
		ready: specialistGroupsLoaded,
		rows,
		schoolName,
		specialists,
	};
}