import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const HeaderBox = styled(GridBox, {
	'&::after': {
		content: '',
		width: '100%',
		height: '100%',
		background: 'repeating-linear-gradient(45deg, $vivid, $vivid 3px, transparent 0, transparent 10px)',
		gridColumnStart: 1,
		gridColumnEnd: -1,
		gridRowStart: 1,
		gridRowEnd: 1,
	},
});

export const ContentWrapper = styled(Box, {
	gridColumnStart: 1,
	gridColumnEnd: -1,
	gridRowStart: 1,
	gridRowEnd: 1,
	zIndex: 2,
	paddingLeft: 28,
	paddingRight: 20,
	paddingTop: 16,
	paddingBottom: 16,
});
