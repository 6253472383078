import {Drawer} from '@esgi/main/kits/common';
import {PanelContent} from '../../components/panels.styled';
import {DisabledInput} from '../../components/disabled-input';
import {ContentBox} from '../../components/content-box.styled';
import {PanelHeaderTitle} from '../../components/panel-header-title';
import {DrawerPanelHeader} from '../index.styled';

interface Props {
	schoolName: string;
	districtName: string;
}

export function DistrictContent({schoolName, districtName}: Props) {
	return (
		<>
			<DrawerPanelHeader>
				<PanelHeaderTitle title='School & District' />
			</DrawerPanelHeader>

			<PanelContent>
				<ContentBox gap='60'>
					<Drawer.ContentBlock title='District'>
						<DisabledInput value={districtName} placeholder='District' dataCy='district-disabled-input' />
					</Drawer.ContentBlock>
					<Drawer.ContentBlock title='School'>
						<DisabledInput value={schoolName} placeholder='School' dataCy='school-disabled-input' />
					</Drawer.ContentBlock>
				</ContentBox>
			</PanelContent>
		</>
	);
}
