import {DeleteButton} from '../../_kit';

type Props = {
	selectionType: 'single' | 'multiple';
};

export function DeleteSchoolsButton({selectionType}: Props) {
	return (
		<DeleteButton
			disabled
			tooltipMessage={
				selectionType === 'multiple'
					? 'Select only one item for deletion.'
					: 'To delete the school, please contact Customer Support support@esgisoftware.com'
			}
			whenShowTooltip='disabled'
		/>
	);
}
