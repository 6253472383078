import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgi/ui';
import {Service} from './service';
import {useCallback} from 'react';
import {StudentSort, useUser} from '@esgi/core/authentication';
import {allGradeLevel} from '../../../../../constants';
import {Extension} from '../types';
import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {isNull} from 'underscore';
import {AdditionalEntity, Student} from '../../../../../types/section';
import {Hierarchy, useHierarchyInstance} from '@esgi/main/features/admins/utils';

type Parameters = {
	showEachStudent: boolean;
	printInColor: boolean;
	selectedGradeLevelID: number;
	selectedSubject: SubjectModel | null;
	students: Student[];
	additionalInfo: AdditionalEntity | null;
	hierarchy: Hierarchy;
};

export function useDownload({
	showEachStudent,
	printInColor,
	selectedGradeLevelID,
	selectedSubject,
	hierarchy,
	students,
	additionalInfo,
}: Parameters) {
	const currentUser = useUser();
	const hierarchyInstance = useHierarchyInstance({hierarchy});

	const service = useService(Service);

	const isDownloading = useBehaviorSubject(service.isDownloading$);

	const onDownload = useCallback(
		(extension: Extension) => {
			if (isNull(selectedSubject)) {
				throw new Error('selectedSubject is null');
			}

			service.download({
				options: {
					testResultsCorrectVerbiage: currentUser?.testResultsCorrectVerbiage ?? 'Correct',
					testResultsIncorrectVerbiage: currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect',
					sortBy: currentUser?.studentSort ?? StudentSort.FirstName,
					showEachStudent,
					printInColor,
					gradeLevelID: selectedGradeLevelID === allGradeLevel.id ? 0 : selectedGradeLevelID,
					hierarchyInstance,
					extension,
					selectedSubject,
					students,
					additionalInfo,
				},
			});
		},
		[
			selectedSubject,
			service,
			currentUser,
			showEachStudent,
			printInColor,
			selectedGradeLevelID,
			hierarchyInstance,
			students,
			additionalInfo,
		],
	);

	return {
		onDownload,
		isDownloading,
	};
}
