import {IFormControl} from '@esgi/ui/form';
import {Select} from '@esgi/ui/form-controls';
import {Text} from '@esgi/ui/typography';
import {WidgetFormSelectItem} from './types';
import {WidgetCard} from '../../../widget-card';

type Props<T extends string> = {
	placeholder: string;
	control: IFormControl<(T | null)[]>;
	items: WidgetFormSelectItem<T>[];
};

export function FormSelect<T extends string>({placeholder, control, items}: Props<T>) {
	return (
		<WidgetCard.EditMode.FormElement control={control}>
			<Select.Root>
				<Select.Field placeholder={placeholder} />
				<Select.Content>
					{items.map(({value, label}) => (
						<Select.Option value={value} key={value}>
							<Text size='medium' bold>
								{label}
							</Text>
						</Select.Option>
					))}
				</Select.Content>
			</Select.Root>
		</WidgetCard.EditMode.FormElement>
	);
}
