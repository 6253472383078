import React, {useCallback, useEffect, useState} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Edit, ErrorText, KeyDiscard} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/form-controls';
import {useService} from '@esgi/core/service';
import {Form, FormElement} from '@esgi/ui/form';
import {GridBox, InfoBlock} from '@esgi/ui/layout';
import {SnackbarLayout} from '@esgillc/ui-kit/snackbar';
import {useBehaviorSubject, useFlag, useStreamEffect} from '@esgi/ui/utils';
import {Drawer, UnsavedChangesConfirmation, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {StyledButton, StyledInfoBlockContainer} from './index.styled';
import {MoreOptions} from './components/more-options';
import {InputField} from './components/input-field';
import {Timestamp} from './components/timestamp';
import {EditTeacherService} from './service';
import {teacherTitleList} from './model';

type Props = {
	teacherID: number;
	onClose: VoidFunction;
};

export function EditTeacher({teacherID, onClose}: Props) {
	const drawerRef = useDrawerRef();
	const closeDrawer = useDrawerClose(drawerRef, onClose);

	const service = useService(EditTeacherService);
	const isDataLoaded = useBehaviorSubject(service.isDataLoaded$);
	const isBusy = useBehaviorSubject(service.isBusy$);

	const [showUnsavedChangesConfirmation, openUnsavedChangesConfirmation, closeUnsavedChangesConfirmation] = useFlag();
	const [isFormTouched, setIsFormTouched] = useState(false);

	const [isActionButtonDisabled, setActionButtonDisabled] = useState<boolean>(true);

	const teacherFullName = `${service.form.value.firstName} ${service.form.value.lastName}`;

	const handleActionButtonClick = useCallback(() => {
		service.editTeacher().subscribe(closeDrawer);
	}, [service, closeDrawer]);

	const handleResetPassword = useCallback(() => {
		service.resetPassword().subscribe();
	}, [service]);

	useEffect(() => {
		service.init(teacherID).subscribe();
	}, [service, teacherID]);

	useStreamEffect(service.form.onChanged, () => {
		const isTouched = service.compareStatesForEquality();

		setIsFormTouched(isTouched);

		service.form.validate().subscribe((form) => {
			setActionButtonDisabled(!form.valid || !isTouched);
		});
	});

	const onDrawerClose = useCallback(() => {
		if (isFormTouched) {
			openUnsavedChangesConfirmation();

			return;
		}

		closeDrawer();
	}, [closeDrawer, isFormTouched, openUnsavedChangesConfirmation]);

	return (
		<>
			<Drawer drawerRef={drawerRef} onClickOutside={onDrawerClose}>
				<Drawer.Header
					Icon={Edit}
					actionButtonText='Save'
					closeDrawer={onDrawerClose}
					sectionName={teacherFullName}
					onActionButtonClick={handleActionButtonClick}
					actionButtonDisabled={isActionButtonDisabled || isBusy}
					withActionButton
				>
					<MoreOptions isInitialized={isDataLoaded} closeDrawer={closeDrawer} teacherID={teacherID} />
				</Drawer.Header>
				<Drawer.Body>
					<OverlayScrollbarsComponent options={{scrollbars: {autoHide: 'leave'}}} defer>
						<Form controller={service.form}>
							<GridBox gap={4}>
								<StyledInfoBlockContainer>
									<InfoBlock>
										<InfoBlock.Group>
											<InfoBlock.Item title='Expiration Date:'>
												{isDataLoaded && <Timestamp date={service.form.value.expirationDate} />}
											</InfoBlock.Item>
											<InfoBlock.Item title='Max Students:'>
												<Text size='small' font='mono' color='base'>
													{service.form.value.maxStudents}
												</Text>
											</InfoBlock.Item>
										</InfoBlock.Group>
									</InfoBlock>
								</StyledInfoBlockContainer>
								<Drawer.ContentBlock title='Location' withDivider>
									<FormElement control={service.form.controls.school}>
										<InputField name='School' loading={!isDataLoaded} disabled />
									</FormElement>
								</Drawer.ContentBlock>
								<Drawer.ContentBlock title='Teacher Information' withDivider>
									<FormElement control={service.form.controls.title}>
										<Select.Root>
											<Select.Field placeholder='Title' skeleton={!isDataLoaded} />
											<Select.Content>
												{teacherTitleList.map((item) => (
													<Select.Option value={item} key={item}>
														<Text size='medium' bold>
															{item}
														</Text>
													</Select.Option>
												))}
											</Select.Content>
										</Select.Root>
										<ErrorText showOnError='required'>Title is required</ErrorText>
									</FormElement>
									<FormElement control={service.form.controls.firstName} disableValidateOnBlur>
										<InputField name='First Name' loading={!isDataLoaded} />
									</FormElement>
									<FormElement control={service.form.controls.lastName} disableValidateOnBlur>
										<InputField name='Last Name' loading={!isDataLoaded} />
									</FormElement>
									<FormElement control={service.form.controls.email} disableValidateOnBlur>
										<InputField name='Email' loading={!isDataLoaded} />
									</FormElement>
									<GridBox flow='column' gap={3}>
										<FormElement control={service.form.controls.importID} disableValidateOnBlur>
											<InputField name='Import ID' loading={!isDataLoaded} />
										</FormElement>
										<FormElement control={service.form.controls.exportID} disableValidateOnBlur>
											<InputField name='Export ID' loading={!isDataLoaded} />
										</FormElement>
									</GridBox>
								</Drawer.ContentBlock>
								<Drawer.ContentBlock title='Login Credentials' withDivider>
									<FormElement control={service.form.controls.accountID} disableValidateOnBlur>
										<InputField name='Username' loading={!isDataLoaded} disabled />
									</FormElement>
									<StyledButton color='secondary' onClick={handleResetPassword} disabled={!isDataLoaded || isBusy}>
										<KeyDiscard height={24} width={24} />
										<Text size='medium' color='base' bold>
											Initiate password reset
										</Text>
									</StyledButton>
								</Drawer.ContentBlock>
							</GridBox>
						</Form>
					</OverlayScrollbarsComponent>
				</Drawer.Body>
				<SnackbarLayout />
			</Drawer>

			{showUnsavedChangesConfirmation && (
				<UnsavedChangesConfirmation onClose={closeUnsavedChangesConfirmation} onCloseAnyway={closeDrawer} />
			)}
		</>
	);
}
