import {Card, GridBox} from '@esgi/ui/layout';
import {TestGroupStudentsModel} from '../../../../../../../../types';
import {Dispatch} from 'react';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {CardRoot, ActionButtonsDivider} from '../../components/components.styled';
import {CardHeader} from '../../components/card-header';
import {CardContextMenu} from '../../../../components/card-context-menu';
import {SessionsContextMenu} from '../../../../components/sessions-context-menu';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {CardFooter} from './components/card-footer';
import {CardBody} from './components/card-body';
import {onCardHoverText} from '../../../constants';

type Props = Pick<
	TestGroupStudentsModel,
	'testInfo' | 'lastTestSessions' | 'analyticsData' | 'classResult' | 'studentsResult'
> & {
	onTestCardClick: VoidFunction;
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	subjectID: SubjectTab['id'];
	openTestDetails: VoidFunction;
	userCanEditTest: boolean;
	userCanRemove?: boolean;
	classID: Class['id'];
};

export function GroupStudentsCard({
	testInfo,
	lastTestSessions,
	analyticsData,
	classResult,
	studentsResult,
	onTestCardClick,
	onTestMoveTo,
	onRemoveTest,
	subjectID,
	openTestDetails,
	userCanEditTest,
	userCanRemove,
	classID,
}: Props) {
	return (
		<CardRoot onClick={onTestCardClick} clicable>
			<Tooltip>
				<Tooltip.Trigger>
					<GridBox gap={3}>
						<CardHeader testID={testInfo.id} testName={testInfo.name} testType={testInfo.type} standards={testInfo.stateStandards}/>

						<Card.Separator />

						<CardBody classResult={classResult} studentsResult={studentsResult} testInfo={testInfo} />

						<Card.Separator />
					</GridBox>
				</Tooltip.Trigger>
				<Tooltip.Content side='top'>
					<Text size='large' color='tertiary'>
						{onCardHoverText}
					</Text>
				</Tooltip.Content>
			</Tooltip>

			<CardFooter testInfo={testInfo}>
				<CardContextMenu
					onRemoveTest={onRemoveTest}
					onTestMoveTo={onTestMoveTo}
					subjectID={subjectID}
					testInfo={testInfo}
					openTestDetails={openTestDetails}
					userCanEditTest={userCanEditTest}
					userCanRemoveTest={userCanRemove}
				/>

				<ActionButtonsDivider />

				<SessionsContextMenu
					classID={classID}
					lastTestSessions={lastTestSessions}
					maxScore={testInfo.totalPossible}
					sessionsCount={analyticsData?.sessionCount ?? 0}
					subjectID={subjectID}
					testID={testInfo.id}
				/>
			</CardFooter>
		</CardRoot>
	);
}
