import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const HeaderContent = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	gap: '$4',
	alignItems: 'center',
});

export const AdditionalHeaderContent = styled(GridBox, {
	paddingInline: 20,
	gap: 20,
});
