import {useMemo} from 'react';
import {LowestAchievementStudentData} from '../../../types/lowest-achievement';
import {StudentsTwoPeriodData} from '../types';
import {getFullName} from '@esgi/main/kits/common';
import {isNull} from 'underscore';
import {roundNumber} from '../utils/round-number';
import {GradeLevel} from '@esgi/main/features/admins/dashboard';

export function useStudentsTwoPeriodsItems({
	studentsData,
	getSchoolName,
	getGradeShortName,
}: {
	studentsData: LowestAchievementStudentData[];
	getSchoolName: (schoolID: number) => string;
	getGradeShortName: (gradeID: GradeLevel['id']) => string | null;
}) {
	return useMemo(() => {
		return studentsData.map<StudentsTwoPeriodData>(({student, teacher, avgResults, delta}) => {
			const avgFirst = avgResults[0] ? roundNumber(avgResults[0], {precise: 1}) : null;
			const avgSecond = avgResults[1] ? roundNumber(avgResults[1], {precise: 1}) : null;

			const deltaValue = isNull(avgFirst) || isNull(avgSecond) ? null : roundNumber(delta);

			return {
				rowID: student.id,
				studentID: student.id,
				student: getFullName({
					firstName: student.firstName,
					lastName: student.lastName,
				}),
				teacher: !isNull(teacher)
					? getFullName({
							firstName: teacher.firstName,
							lastName: teacher.lastName,
						})
					: null,
				school: getSchoolName(student.schoolID),
				grade: getGradeShortName(student.gradeLevelID),
				avgFirst,
				avgSecond,
				delta: deltaValue,
			};
		});
	}, [getGradeShortName, getSchoolName, studentsData]);
}
