import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const EmptyContentBox = styled(GridBox, {
	borderRadius: 6,
	backgroundColor: '$background',
	paddingTop: 11,
	paddingBottom: 11,
	paddingInline: 19,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$vivid',
});
