import {useMemo} from 'react';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui';
import {
	BoxplotSinglePeriodInitialFormData,
	BoxplotTwoPeriodsInitialFormData,
	BoxplotTwoClassesInitialFormData,
	CommonInitialFormData,
	PiechartsInitialFormData,
} from './types';
import {DemographicPerformanceWidgetOptions} from '../../../../../types/demographic-performance';
import {BoxplotCompareVariant, Period, Class} from '../types';
import {datePickerDataForInitialization} from './constants';
import {mapToEnum} from 'shared/utils';
import {PeriodType as PeriodTypeForDatePicker} from '@esgi/main/kits/admin';
import {PerformanceWidgetViewType} from '@esgi/main/features/admins/dashboard';

export function useInitialCommonForm({widgetOptions}: {widgetOptions: DemographicPerformanceWidgetOptions}) {
	return useMemo<CommonInitialFormData>(() => {
		if (widgetOptions.viewType === PerformanceWidgetViewType.Piechart) {
			return {
				boxplotCompareVariant: BoxplotCompareVariant.None,
			};
		}

		if (widgetOptions.periods.length === 1 && !widgetOptions.classes.length) {
			return {
				boxplotCompareVariant: BoxplotCompareVariant.SinglePeriod,
			};
		}

		if (widgetOptions.periods.length === 2 && !widgetOptions.classes.length) {
			return {
				boxplotCompareVariant: BoxplotCompareVariant.CompareTwoPeriods,
			};
		}

		if (widgetOptions.periods.length === 1 && widgetOptions.classes.length === 2) {
			return {
				boxplotCompareVariant: BoxplotCompareVariant.CompareTwoClasses,
			};
		}

		return {
			boxplotCompareVariant: BoxplotCompareVariant.SinglePeriod,
		};
	}, [widgetOptions]);
}

export function useInitialPiechartsForm({widgetOptions}: {widgetOptions: DemographicPerformanceWidgetOptions}) {
	return useMemo<PiechartsInitialFormData>(() => {
		const period = widgetOptions.periods[0];

		return {
			date: isUndefined(period)
				? datePickerDataForInitialization
				: {
						datePeriod: mapToEnum(period.datePeriod, PeriodTypeForDatePicker),
						trackDatesID: period.trackDatesID,
						dateRange: period.dateRange,
					},
		};
	}, [widgetOptions]);
}

export function useInitialBoxplotSinglePeriodForm({
	widgetOptions,
}: {
	widgetOptions: DemographicPerformanceWidgetOptions;
}) {
	return useMemo<BoxplotSinglePeriodInitialFormData>(() => {
		const firstPeriod = widgetOptions.periods[0];

		const widgetOptionsCandles = widgetOptions.candles;

		return {
			date: isUndefined(firstPeriod)
				? datePickerDataForInitialization
				: {
						datePeriod: mapToEnum(firstPeriod.datePeriod, PeriodTypeForDatePicker),
						trackDatesID: firstPeriod.trackDatesID,
						dateRange: firstPeriod.dateRange,
					},
			highlight: !isNull(widgetOptionsCandles)
				? {color: widgetOptionsCandles.color, highlightIfAbove: widgetOptionsCandles.highlightIfAbove}
				: null,
		};
	}, [widgetOptions]);
}

export function useInitialBoxplotTwoPeriodsForm({widgetOptions}: {widgetOptions: DemographicPerformanceWidgetOptions}) {
	return useMemo<BoxplotTwoPeriodsInitialFormData>(() => {
		const [firstPeriod, secondPeriod] = [widgetOptions.periods[0], widgetOptions.periods[1]].map((item) => {
			if (isUndefined(item)) {
				return null;
			}

			const {color, datePeriod, dateRange, trackDatesID} = item;

			return {
				dateRange,
				trackDatesID,
				datePeriod: mapToEnum(datePeriod, PeriodTypeForDatePicker),
				color,
			};
		}) as [Period | null, Period | null];

		return {
			firstPeriod,
			secondPeriod,
		};
	}, [widgetOptions]);
}

export function useInitialBoxplotTwoClassesForm({widgetOptions}: {widgetOptions: DemographicPerformanceWidgetOptions}) {
	return useMemo<BoxplotTwoClassesInitialFormData>(() => {
		const firstPeriod = widgetOptions.periods[0];

		const [firstClass, secondClass] = [widgetOptions.classes[0], widgetOptions.classes[1]].map((item) => {
			if (isUndefined(item)) {
				return null;
			}

			const {color, id} = item;

			return {
				classID: id,
				color,
			};
		}) as [Class | null, Class | null];

		return {
			date: isUndefined(firstPeriod)
				? datePickerDataForInitialization
				: {
						datePeriod: mapToEnum(firstPeriod.datePeriod, PeriodTypeForDatePicker),
						trackDatesID: firstPeriod.trackDatesID,
						dateRange: firstPeriod.dateRange,
					},
			firstClass,
			secondClass,
		};
	}, [widgetOptions]);
}
