import {ContextMenu as ContextMenuUI} from '@esgi/ui';
import {Root} from './components/root';
import {ContextMenuItem} from './components/context-menu-item';

export const ContextMenu = {
	Root,
	Trigger: ContextMenuUI.Trigger,
	Content: ContextMenuUI.Content,
	Group: ContextMenuUI.Group,
	Item: ContextMenuItem,
};
