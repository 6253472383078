import {useEffect, useRef} from 'react';
import {isNull} from 'underscore';

type CbHandler = (...args: any[]) => void;

export const useInterval = (callback: CbHandler, delay: number | null) => {
	const callbackRef = useRef<CbHandler>();

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	useEffect(() => {
		if (!isNull(delay)) {
			const intervalID = setInterval(callbackRef.current!, delay);

			return () => clearInterval(intervalID);
		}
	}, [delay]);
};
