import {ObjectEntries} from '@esgi/main/features/admins/dashboard';

export function getDisabledSortableKeys<T extends Partial<Record<keyof T, unknown>>, K extends keyof T>({
	data,
	keys,
}: {
	data: T[];
	keys: K[];
}) {
	const state = {} as Record<K, Set<unknown>>;

	keys.forEach((key) => {
		state[key] = new Set();
	});

	data.forEach((item) => {
		keys.forEach((key) => {
			state[key].add(item[key]);
		});
	});

	return (Object.entries(state) as ObjectEntries<typeof state>)
		.filter(([_, data]) => data.size <= 1)
		.map(([key]) => key);
}
