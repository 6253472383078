import React, {ReactNode} from 'react';
import {HttpClient} from '@esgi/api';
import {Loader} from '@esgillc/ui-kit/loader';
import {TemplateModel} from '../common/types';
import OverviewDialog from './components/overview-dialog/overview-dialog';
import RubricCreator from './components/own-path-dialog/rubric-creator';
import TemplatePicker from './components/template-picker-dialog/template-picker';

enum CreatingPath {
	None,
	Own,
	Template,
}

class State {
	creatingPath: CreatingPath = CreatingPath.None;
	loaded: boolean = false;
	templates: TemplateModel[] = [];
	initialTemplateID: number;
}

interface Props {
	onClose: (testCreated?: boolean) => void;
	canAddToSubject: boolean;
	disableNewSubjectBtn?: boolean;
}

export default class RubricCreatingFlow extends React.PureComponent<Props, State> {
	public state = new State();

	public componentDidMount() {
		HttpClient.default.ESGIApi
			.get<{ templates: TemplateModel[] }>('assets/rubric', 'templates/init')
			.subscribe(r => this.setState({templates: r.templates, loaded: true}), this.props.onClose);
	}

	public render() {
		return <>
			{this.renderOverviewDialog()}
			{this.renderOwnCreator()}
			{this.renderTemplatePath()}
			<Loader show={!this.state.loaded} fullscreen/>
		</>;
	}

	private renderOverviewDialog(): ReactNode {
		if (this.state.loaded) {
			return <OverviewDialog ownPathClicked={() => this.setState({creatingPath: CreatingPath.Own})}
			                       templateClicked={(templateID) => this.setState({
				                       creatingPath: CreatingPath.Template,
				                       initialTemplateID: templateID,
			                       })}
			                       onCloseClicked={this.props.onClose}
			                       templates={this.state.templates}
			                       hidden={this.state.creatingPath !== CreatingPath.None}/>;
		}
	}

	private renderOwnCreator(): ReactNode {
		if (this.state.creatingPath === CreatingPath.Own) {
			return <RubricCreator canAddToSubject={this.props.canAddToSubject}
			                      onCloseClicked={() => this.props.onClose()}
			                      onBackClicked={() => this.setState({creatingPath: CreatingPath.None})}
			                      onSaveClicked={() => this.props.onClose(true)}
														disableNewSubjectBtn={this.props.disableNewSubjectBtn}/>;
		}
	}

	private renderTemplatePath() {
		if (this.state.creatingPath === CreatingPath.Template) {
			return <TemplatePicker canAddToSubject={this.props.canAddToSubject}
			                       initialTemplateID={this.state.initialTemplateID}
			                       templates={this.state.templates}
			                       onCloseClicked={() => this.setState({creatingPath: CreatingPath.None})}
			                       onSaveClicked={() => this.props.onClose(true)}
														 disableNewSubjectBtn={this.props.disableNewSubjectBtn}/>;
		}
	}
}
