import {V2SchoolYearsController} from '@esgi/contracts/esgi';
import {useUser} from '@esgi/core/authentication';
import {useDisposable} from '@esgi/core/service';
import {isAsyncPending, isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';
import {Dispatch, useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {SchoolYearFullModel} from '../types';
import {dispatchAppEvent} from '@esgillc/events';
import {CurrentSchoolYearEvent} from 'modules/school-year';

type Parameters = {
	setActiveSchoolYearID: Dispatch<number | null>;
};

export function useInitData({setActiveSchoolYearID}: Parameters) {
	const currentUser = useUser();
	const schoolYearController = useDisposable(V2SchoolYearsController);

	const [schoolYearsInitData, fetchSchoolYears] = useCancelableRequest(schoolYearController.init);

	const [currentGlobalSchoolYearID, setCurrentGlobalSchoolYearID] = useState<number | null>(null);
	const [schoolYears, setSchoolYears] = useState<SchoolYearFullModel[]>([]);

	useEffect(() => {
		fetchSchoolYears();
	}, []);

	useEffect(() => {
		if (isAsyncSucceed(schoolYearsInitData) && !isNull(currentUser)) {
			const {schoolYears, currentGlobalSchoolYearID} = schoolYearsInitData.data;
			const selectedUserGlobalSchoolYearID = currentUser.globalSchoolYearID;

			const userSchoolYear = schoolYears.find((year) => year.globalSchoolYearID === selectedUserGlobalSchoolYearID);

			setSchoolYears(
				schoolYears.map<SchoolYearFullModel>(({globalSchoolYearID, name}) => {
					// name has template "20XX-20XX" (2024-2025)
					const shortName = name.replace(/^20(\d{2})-20(\d{2})$/, '$1-$2');

					return {
						globalSchoolYearID,
						name,
						shortName,
					};
				}),
			);

			if (!isUndefined(userSchoolYear)) {
				setActiveSchoolYearID(selectedUserGlobalSchoolYearID);
				setCurrentGlobalSchoolYearID(currentGlobalSchoolYearID);

				dispatchAppEvent(CurrentSchoolYearEvent, {
					schoolYear: {
						name: userSchoolYear.name,
						globalSchoolYearID: userSchoolYear.globalSchoolYearID,
					},
				});
			}
		}
	}, [currentUser, schoolYearsInitData, setActiveSchoolYearID]);

	return {
		loading: isAsyncPending(schoolYearsInitData),
		schoolYears,
		currentGlobalSchoolYearID,
	};
}
