import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {ToggleGroup} from '@esgi/ui/controls';

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
	width: '100%',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	gridAutoColumns: 'minmax(0, 1fr)',
	width: '100%',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	height: 40,
});

export const Transcript = styled(Box, {
	paddingLeft: 12,
});