import {BehaviorSubject, from, timer, concatMap} from 'rxjs';

type QueueItem = {
	imageUrl: string;
	imageElement: HTMLImageElement;
};

const minimumDelay = 1_000;

export class ImageQueueService {
	private imageQueue$ = new BehaviorSubject<QueueItem[]>([]);

	constructor() {
		this.imageQueue$.pipe(concatMap(() => {
			if (this.imageQueue$.value.length === 0) {
				return timer(0);
			}

			const startTime = performance.now();

			return from(this.loadImage(this.imageQueue$.value[0])).pipe(
				concatMap(() => {
					const loadTime = performance.now() - startTime;
					const remainingTime = Math.max(minimumDelay - loadTime, 0);

					this.imageQueue$.next(this.imageQueue$.value.slice(1));
					return timer(remainingTime);
				}),
			);
		})).subscribe();
	}

	public addToQueue(imageUrl: string, imageElement: HTMLImageElement) {
		this.imageQueue$.next([...this.imageQueue$.value, {imageUrl, imageElement}]);
	}

	public destroy() {
		this.imageQueue$.complete();
	}

	public clear() {
		this.imageQueue$.next([]);
	}

	private loadImage(item: QueueItem): Promise<void> {
		return new Promise((resolve) => {
			const {imageElement, imageUrl} = item;

			const onComplete = () => {
				imageElement.onload = null;
				imageElement.onerror = null;

				resolve();
			};

			imageElement.src = imageUrl;
			imageElement.onload = onComplete;
			imageElement.onerror = onComplete;
		});
	}
}

export const imageQueueService = new ImageQueueService();