import {useMemo} from 'react';
import {isNull, isUndefined} from 'underscore';
import {DatesPeriodModel, PeriodType, SelectOptionModel} from '../../../types';
import {enumRemap} from 'shared/utils';
import {CurrentTrackDate} from '../../../hooks/use-current-track-dates/types';
import {generateTrackDateOption} from '../../../utils/select-options/generate-track-date-option';

type Parameters = {
	currentTracks: CurrentTrackDate[];
	previousTrackDate: DatesPeriodModel | null;
};

export function useOptions({currentTracks, previousTrackDate}: Parameters) {
	const currentTracksOptions = useMemo(
		() =>
			currentTracks.map<SelectOptionModel>(({id, name}) => ({
				value: generateTrackDateOption(id),
				label: name,
			})),
		[currentTracks],
	);

	const previousMarkingPeriod = useMemo<SelectOptionModel | null>(() => {
		if (isNull(previousTrackDate)) {
			return null;
		}

		const previousTrack = currentTracks.find(({id}) => previousTrackDate.trackDateID === id);

		if (isUndefined(previousTrack)) {
			return null;
		}

		return {
			value: enumRemap(PeriodType.PreviousMarkingPeriod, PeriodType),
			label: previousTrack.name,
		};
	}, [currentTracks, previousTrackDate]);

	return {
		currentTracksOptions,
		previousMarkingPeriod,
	};
}
