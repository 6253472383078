import {TestsPanelContainer} from './index.styled';
import {useMemo} from 'react';
import {AdaptedTestCard} from './types';
import {TestCardsContent} from './components/test-cards-content';
import {TestCardsFilters} from './components/test-cards-filters';
import {TestCard} from '../../types/test-panel';
import {OnMoveTestToSubject, OnRemoveTest, SelectedStudent} from '../../types/section';
import {SchoolSubject, SubjectModel} from '@esgi/main/kits/subject-selection-panel';

type Props = {
	isTestsLoaded: boolean;
	testCards: TestCard[];
	selectedSubject: SubjectModel;
	selectedStudent: SelectedStudent;
	schoolSubjects: SchoolSubject[];
	onMoveToTest: OnMoveTestToSubject;
	onRemoveTest: OnRemoveTest;
};

export function TestsInfoPanel({
	testCards,
	selectedSubject,
	isTestsLoaded,
	selectedStudent,
	schoolSubjects,
	onMoveToTest,
	onRemoveTest,
}: Props) {
	const adaptedtestCards = useMemo(
		() =>
			testCards.map<AdaptedTestCard>((item) => ({
				id: item.testInfo.id,
				type: item.testInfo.type,
				name: item.testInfo.name,
				contentArea: item.testInfo.contentArea,
				totalPossible: item.testInfo.totalPossible,
				...item,
			})),
		[testCards],
	);

	return (
		<TestsPanelContainer testCards={adaptedtestCards} skeleton={!isTestsLoaded} skeletonTestCard={{count: 10}}>
			<TestCardsFilters />
			<TestCardsContent
				selectedSubject={selectedSubject}
				selectedStudent={selectedStudent}
				schoolSubjects={schoolSubjects}
				onMoveToTest={onMoveToTest}
				onRemoveTest={onRemoveTest}
			/>
		</TestsPanelContainer>
	);
}
