import {SelfAssess, Tooltip} from '@esgi/ui';
import {IconWrapper} from '../../index.styled';
import {CardViewType, TestModel, TestScreenType} from '../../../../service/types';
import {Text} from '@esgi/ui/typography';
import React from 'react';
import {InfoItem} from '../info-item';
import {LabelBox} from '../../../test-type-label/index.styled';

type Props = {
	cardView?: CardViewType
	testScreenTypes: TestModel['testScreenTypes']
}

export function SpecialInfo({cardView, testScreenTypes}: Props) {
	const isSelfAssess = testScreenTypes.find(item => TestScreenType[item] === TestScreenType.SelfAssessment);

	if (!isSelfAssess && cardView === CardViewType.Grid) {
		return (
			<InfoItem dataCy='special-info' title='Special Info' viewType='grid'>
				<LabelBox>
					<Text data-cy='special-info-value' size='small' font='mono' color='mediumContrast'>None</Text>
				</LabelBox>
			</InfoItem>
		);
	}

	if (!isSelfAssess) {
		return;
	}

	return (
		<InfoItem dataCy='special-info' title='Special Info' viewType={cardView === CardViewType.Grid ? 'grid' : undefined}>
			<Tooltip>
				<Tooltip.Trigger>
					<IconWrapper>
						<SelfAssess/>
					</IconWrapper>
				</Tooltip.Trigger>
				<Tooltip.Content variant='secondary'>
					<Text data-cy='special-info-value' size='small'>Self-Assess</Text>
				</Tooltip.Content>
			</Tooltip>
		</InfoItem>
	);
}