import {useLocation, useNavigate} from 'react-router-dom';
import {DataBase} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {routes} from '@esgi/main/libs/core';
import {Fragment, useEffect, useState} from 'react';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Divider, EntityItemsBox, PanelBox, NotVisibleItemsDivider, Title, PanelContent} from './index.styled';
import {useData} from './hooks/use-data';
import {usePanelAnimation} from './hooks/use-panel-animation';
import {ActiveEntity, EntityProperty, ObjectEntries} from './types';
import {isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {FoldersSection} from './components/folders-section';
import {NavigationItem} from './components/navigation-item';

type Props = {
	onDistrictNameChanged: (value: string | null) => void
}
export function DataPanel({onDistrictNameChanged}: Props) {
	const navigate = useNavigate();
	const {pathname} = useLocation();

	const [activeEntity, setActiveEntity] = useState<ActiveEntity | null>(null);

	const {entitiesGroups, folders, districtName} = useData({
		activeEntityType: isNull(activeEntity) ? null : activeEntity.type,
	});

	useEffect(() => {
		onDistrictNameChanged(districtName);
	}, [districtName, onDistrictNameChanged]);

	const panelStyles = usePanelAnimation();

	useEffect(() => {
		if (pathname === routes.districtAdmin.data.root) {
			navigate(routes.districtAdmin.data.schools);
		}
	}, [pathname]);

	useEffect(() => {
		const dataRouteEntity = (
			Object.entries(routes.districtAdmin.data) as ObjectEntries<typeof routes.districtAdmin.data>
		).find(([key, path]) => key !== 'root' && pathname.includes(path));

		const allEntities = entitiesGroups.flatMap<EntityProperty>((group) => group.map((entity) => entity));
		const entity = allEntities.find(({type}) => type === dataRouteEntity?.[0]);

		if (isUndefined(entity)) {
			setActiveEntity(null);

			return;
		}

		setActiveEntity({
			type: entity.type,
			name: entity.name,
		});
	}, [entitiesGroups, pathname]);

	return (
		<PanelBox style={panelStyles}>
			<OverlayScrollbarsComponent
				defer
				options={{
					scrollbars: {autoHide: 'leave'},
				}}
				style={{height: '100%'}}
			>
				<PanelContent>
					<Title>
						<DataBase />
						<Text size='small' color='mediumContrast'>
							Data
						</Text>
					</Title>
					<EntityItemsBox>
						{entitiesGroups.map((group, groupIndex, iteratedEntitiesGroups) => {
							return (
								<Fragment key={groupIndex}>
									{group.map(({type, name, count}, entityIndex) => {
										const path = routes.districtAdmin.data[type];

										return (
											<NavigationItem
												name={name}
												count={count}
												isActive={pathname === path}
												onClick={() => navigate(path)}
												key={`${groupIndex}-${entityIndex}-${type}`}
											/>
										);
									})}

									{groupIndex !== iteratedEntitiesGroups.length - 1 && <NotVisibleItemsDivider />}
								</Fragment>
							);
						})}
					</EntityItemsBox>

					{!isNull(folders) && !isNull(activeEntity) && Boolean(folders.length) && (
						<>
							<Divider />
							<FoldersSection entityName={activeEntity.name} folders={folders} />
						</>
					)}
				</PanelContent>
			</OverlayScrollbarsComponent>
		</PanelBox>
	);
}
