import {ComponentPropsWithoutRef, forwardRef, useCallback, useMemo, useState} from 'react';
import {Delete} from '@esgi/ui/icons';
import {NegativeButton} from './negative-icon-button';
import {isUndefined, Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {isNull} from 'underscore';

type TooltipedButton = {
	tooltipMessage: string;
	whenShowTooltip: 'disabled';
};

type NonTooltipedButton = {
	tooltipMessage?: undefined;
};

type Props = Omit<ComponentPropsWithoutRef<typeof NegativeButton>, 'children'> & (TooltipedButton | NonTooltipedButton);

export const DeleteButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			dataCy = 'delete-button',
			css = {},
			withBackgroundHover = true,
			onClick = () => alert('Delete clicked'),
			disabled,
			...props
		},
		forwardedRef,
	) => {
		const [isTooltipOpen, setIsTooltipOpen] = useState(false);

		const {restProps, tooltipMessage, whenShowTooltip} = useMemo(() => {
			if (!isUndefined(props.tooltipMessage) && 'whenShowTooltip' in props) {
				const {tooltipMessage, whenShowTooltip, ...restProps} = props;

				return {
					restProps,
					tooltipMessage,
					whenShowTooltip,
				};
			}

			const {tooltipMessage, ...restProps} = props;

			return {
				restProps,
				tooltipMessage: null,
				whenShowTooltip: null,
			};
		}, [props]);

		const onTooltipOpenChanged = useCallback(
			(value: boolean) => {
				if (isNull(tooltipMessage) || isNull(whenShowTooltip)) {
					setIsTooltipOpen(false);
					return;
				}

				if (disabled && whenShowTooltip === 'disabled') {
					setIsTooltipOpen(value);
					return;
				}

				setIsTooltipOpen(false);
			},
			[disabled, tooltipMessage, whenShowTooltip],
		);

		return (
			<Tooltip open={isTooltipOpen} onOpenChange={onTooltipOpenChanged}>
				<Tooltip.Trigger>
					<NegativeButton
						dataCy={dataCy}
						css={css}
						withBackgroundHover={withBackgroundHover}
						onClick={onClick}
						disabled={disabled}
						ref={forwardedRef}
						{...restProps}
					>
						<Delete />
					</NegativeButton>
				</Tooltip.Trigger>
				<Tooltip.Content>
					<Text size='large' color='tertiary'>
						{tooltipMessage}
					</Text>
				</Tooltip.Content>
			</Tooltip>
		);
	},
);
