import {PublishToSpecialistsNumerable} from '@esgi/main/features/admins/subject-management';
import {User} from '@esgi/main/libs/admin-store';

type Parameters = {
	publishToSpecialists: PublishToSpecialistsNumerable;
	allSpecialistsIDs: number[];
	selectedDistrictSpecialistsIDs: number[];
	selectedSchoolSpecialistsSchoolsIDs: number[];
	allSchoolSpecialists: User[];
};

export function getPublishForSpecialistsStoreData({
	publishToSpecialists,
	allSpecialistsIDs,
	selectedDistrictSpecialistsIDs,
	selectedSchoolSpecialistsSchoolsIDs,
	allSchoolSpecialists,
}: Parameters): number[] {
	if (publishToSpecialists === PublishToSpecialistsNumerable.All) {
		return [...allSpecialistsIDs];
	}

	if (publishToSpecialists === PublishToSpecialistsNumerable.IndividualISS) {
		return allSchoolSpecialists
			.filter(({schoolID}) => selectedSchoolSpecialistsSchoolsIDs.includes(schoolID))
			.map(({id}) => id);
	}

	if (publishToSpecialists === PublishToSpecialistsNumerable.IndividualISD) {
		return [...selectedDistrictSpecialistsIDs];
	}

	return [];
}
