import {DistrictPerformanceWidgetOptions} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/district-performance-widget-options';
import {DemographicPerformanceWidgetOptions} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/demographic-performance-widget-options';
import {LowestAchievementWidgetOptions} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/lowest-achievement-widget-options';

export {LowestAchievementWidgetViewType as LowestAchievementWidgetViewTypeNumerable} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/lowest-achievement-widget-view-type';

export {type DistrictPerformanceWidgetOptions};
export {type DemographicPerformanceWidgetOptions};
export {type LowestAchievementWidgetOptions};

export enum WidgetType {
	DistrictPerformance = 'DistrictPerformance',
	DemographicPerformance = 'DemographicPerformance',
	LowestAchievement = 'LowestAchievement',
}

export type AddWidgetEventOptions = {
	districtPerformanceWidgetOptions: DistrictPerformanceWidgetOptions | null;
	demographicPerformanceWidgetOptions: DemographicPerformanceWidgetOptions | null;
	lowestAchievementWidgetOptions: LowestAchievementWidgetOptions | null;
};
