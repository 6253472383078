import {TestScreenType} from '@esgi/contracts/esgi/types/esgi.enums/test-screen-type';
import {TestTypeTabId} from '../components/tabs/types';
import {FilterClientProps, Test} from './types';
import {TestScreenTypeMap} from './model';

export function getClientSortedTests({tests, keyword, testType, scope, isSelfAssess}: FilterClientProps): Test[] {
	let sortedTests = [...tests];
	if (isSelfAssess) {
		sortedTests = sortedTests
			.filter((item) => (item.testScreenTypes as unknown[]).includes(TestScreenTypeMap[TestScreenType.SelfAssessment]));
	}

	if (testType !== TestTypeTabId.All) {
		sortedTests = sortedTests
			.filter((item) => (item.testType as unknown as TestTypeTabId) === testType);
	}

	if (keyword) {
		sortedTests = sortedTests.filter((item) => item.name.toLowerCase().includes(keyword.toLowerCase()));
	}

	return sortedTests;
}