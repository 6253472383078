import {TestContentArea} from '@esgi/main/kits/common';

export const testContentAreasMap: Record<number, TestContentArea> = {
	56: TestContentArea.LanguageArts,
	57: TestContentArea.Math,
	93: TestContentArea.Other,
	100: TestContentArea.Spanish,
	101: TestContentArea.Science,
	102: TestContentArea.SocialStudies,
	105: TestContentArea.SEL,
};
