import {styled} from '@esgi/ui/theme';
import {ToggleGroup} from '@esgi/ui/controls';

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
	marginBottom: '24px',
	width: '100%',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
});

export const ToggleGroupTitle = styled(ToggleGroup.Title, {
	marginBottom: '12px',
	marginRight: '12px',
	marginLeft: '12px',
	paddingBottom: '12px',
	borderBottom: '1px solid $border',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	justifyContent: 'flex-start',
	minWidth: '228px',
	padding: '10px 12px',

	'&[data-state=off] + [data-state=off]': {
		'&[data-orientation=vertical]::before': {
			width: 'calc(100% - 24px)',
			height: 1,
			top: -2,
			left: 12,
		},
	},
});
