import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {ToggleGroupItem} from './index.styled';
import {useTabsContext} from '../../context';
import {useTestPanelContext} from '../../../../context';

type Props = Omit<ComponentPropsWithoutRef<typeof ToggleGroupItem>, 'skeleton'>;

export const Item = forwardRef<HTMLButtonElement, Props>(
	({dataCy = 'test-panel-tab-item', css = {}, children, value, ...props}, forwardedRef) => {
		const {skeleton} = useTestPanelContext();
		const {activeValue} = useTabsContext();

		return (
			<ToggleGroupItem
				dataCy={dataCy}
				css={css}
				value={value}
				disabled={activeValue === value || skeleton}
				applyDisabledStyles={false}
				ref={forwardedRef}
				{...props}
			>
				{children}
			</ToggleGroupItem>
		);
	},
);
