import {ByEachStudentTest} from '../../../../../types/test-panel';
import {TestCard as TestResultsPanelTestCard} from '@esgi/main/kits/test-results-panel';
import {Text} from '@esgi/ui/typography';
import {TestPanelCard} from '../../../../../test-panel-card';
import {CorrectAnswersInfo} from '../../correct-answers-info';
import {DateFormatText, TestContentArea, TestType} from '@esgi/main/kits/common';
import {isNull} from 'underscore';

type Props = Pick<ByEachStudentTest, 'result'> & {
	showInColor: boolean;
	color: string;
	contentArea: TestContentArea;
	type: TestType;
	name: string;
	totalPossible: number;
};

export function TestCard({showInColor, color, contentArea, type, name, totalPossible, result}: Props) {
	return (
		<TestPanelCard
			color={color}
			contentArea={contentArea}
			type={type}
			name={name}
			correctAnswersPercentage={result?.correctScore ?? null}
			showInColor={showInColor}
		>
			<TestResultsPanelTestCard.Footer>
				<TestResultsPanelTestCard.Statistic.Charts.AnalyticsBox>
					<TestResultsPanelTestCard.Statistic.InfoWithTranscript transcript='Last Test Date' justifyItems='start'>
						{!isNull(result) ? (
							<DateFormatText
								date={result.lastTestDate}
								dateFormat='MM/DD/YYYY'
								size='medium'
								font='mono'
								bold
								color='base'
							/>
						) : (
							<Text size='medium' font='mono' bold color='base'>
								-
							</Text>
						)}
					</TestResultsPanelTestCard.Statistic.InfoWithTranscript>

					<CorrectAnswersInfo value={result?.correctAnswers ?? null} maxValue={totalPossible} />
				</TestResultsPanelTestCard.Statistic.Charts.AnalyticsBox>
			</TestResultsPanelTestCard.Footer>
		</TestPanelCard>
	);
}
