import {PropsWithChildren, useCallback, useMemo, useState} from 'react';
import {AppCollapsiblePanelContext, AppCollapsiblePanelContextValue} from '../contexts/app-collapsible-panel-context';

export function AppRoot({children}: PropsWithChildren) {
	const [queue, setQueue] = useState<string[]>([]);

	const enqueue = useCallback((panelID: string) => {
		setQueue((currentQueue) => [...currentQueue, panelID]);
	}, []);

	const dequeue = useCallback((panelID: string) => {
		setQueue((currentQueue) => currentQueue.filter((id) => id !== panelID));
	}, []);

	const value = useMemo<AppCollapsiblePanelContextValue>(
		() => ({
			queue,
			enqueue,
			dequeue,
		}),
		[dequeue, enqueue, queue],
	);

	return <AppCollapsiblePanelContext.Provider value={value}>{children}</AppCollapsiblePanelContext.Provider>;
}
