import {BehaviorSubject, catchError, map, tap, throwError} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {userStorage, UserType} from '@esgi/core/authentication';
import {teachersStore} from '@esgi/main/libs/admin-store';
import {V2DistrictAdminsStorageController, V2DistrictAdminsTeacherController} from '@esgi/contracts/esgi';
import {createAddTeacherForm, teacherExportIDNValidator, teacherImportIDNValidator, teacherNameValidator} from './form';
import {SchoolResults} from './types';
import {enumRemap} from 'shared/utils';

export class AddTeacherService extends BaseService {
	public readonly schools$ = new BehaviorSubject<SchoolResults[]>(null);
	public readonly isDataLoaded$ = new BehaviorSubject<boolean>(false);
	public readonly isBusy$ = new BehaviorSubject<boolean>(false);

	public form = createAddTeacherForm();

	private user = userStorage.get();
	private controller = new V2DistrictAdminsStorageController();
	private teacherController = new V2DistrictAdminsTeacherController();

	public init() {
		this.pushValidators();
		return this.getSchools();
	}

	public addTeacher() {
		this.isBusy$.next(true);
		return this.teacherController.create({
			districtID: this.user.districtID,
			schoolID: Number(this.form.value.school[0]),
			title: this.form.value.title[0],
			firstName: this.form.value.firstName,
			lastName: this.form.value.lastName,
			email: this.form.value.email,
			userName: this.form.value.accountID,
			importID: this.form.value.importID,
			exportID: this.form.value.exportID,
		}).pipe(map((response) => {
			this.isBusy$.next(false);
			return teachersStore().add([{
				id: response.value.teacherID,
				firstName: this.form.value.firstName,
				lastName: this.form.value.lastName,
				userName: this.form.value.accountID,
				email: this.form.value.email,
				schoolID: Number(this.form.value.school[0]),
				userType: enumRemap(UserType.T, UserType),
			}]);
		}), catchError((error) => {
			this.isBusy$.next(false);
			return throwError(error);
		}));
	}

	public override dispose() {
		this.controller.dispose();
		this.teacherController.dispose();
	}

	private pushValidators() {
		this.form.controls.importID.validators.push(teacherImportIDNValidator(this.teacherController, this.user.districtID));
		this.form.controls.exportID.validators.push(teacherExportIDNValidator(this.teacherController, this.user.districtID));
		this.form.controls.accountID.validators.push(teacherNameValidator(this.teacherController));
	}

	private getSchools() {
		return this.controller.schools().pipe(tap((response) => {
			this.schools$.next(response.value.schools);
			this.isDataLoaded$.next(true);
		}));
	}
}