import {useDatePickerWidget} from './use-date-picker-widget';
import {DatePickerData} from '../types';

type Parameters = {
	initialData: [DatePickerData | null, DatePickerData | null];
};

export function useDatePickerWidgetMultiple({initialData: [w1, w2]}: Parameters) {
	const {isTouched: isFirstWidgetTouched, ...firstWidget} = useDatePickerWidget({
		value: w1,
	});

	const {isTouched: isSecondWidgetTouched, ...secondWidget} = useDatePickerWidget({
		value: w2,
	});

	return {
		firstWidget,
		secondWidget,
		isDatesTouched: isFirstWidgetTouched || isSecondWidgetTouched,
	};
}
