import {Dispatch} from 'react';
import {LowestAchievementWidgetOptions, LowestAchievementWidgetUpdatedValue} from '../../../types/lowest-achievement';
import {ViewMode} from './view-mode';
import {Widget} from '../../../types/widget';
import {ViewTableInfo} from '../types';
import {EditMode} from './edit-mode';
import {DeployedSubject, GradeLevel, SchoolModel, WidgetCard, WidgetMode} from '@esgi/main/features/admins/dashboard';

type Props = {
	widgetOptions: LowestAchievementWidgetOptions;
	subjects: DeployedSubject[];
	allSchoolsList: SchoolModel[];
	onDeleteWidgetByID: Dispatch<Widget['id']>;
	onUpdateWidget: Dispatch<LowestAchievementWidgetUpdatedValue>;
	tableInfo: ViewTableInfo;
	gradeLevels: GradeLevel[];
	rootPageContainer: Element | DocumentFragment | null;
};

export function Content({
	widgetOptions,
	subjects,
	allSchoolsList,
	onDeleteWidgetByID,
	tableInfo,
	onUpdateWidget,
	gradeLevels,
	rootPageContainer,
}: Props) {
	const {widgetMode} = WidgetCard.useWidgetCardContext();

	if (widgetMode === WidgetMode.Edit) {
		return (
			<EditMode
				allSchoolsList={allSchoolsList}
				onDeleteWidgetByID={onDeleteWidgetByID}
				subjects={subjects}
				widgetOptions={widgetOptions}
				onUpdateWidget={onUpdateWidget}
				gradeLevels={gradeLevels}
			/>
		);
	}

	return (
		<WidgetCard.ViewMode>
			<ViewMode
				allSchoolsList={allSchoolsList}
				subjects={subjects}
				contentAreaIDs={widgetOptions.contentAreaIDs}
				gradeLevelIDs={widgetOptions.gradeLevelIDs}
				schoolIDs={widgetOptions.schoolIDs}
				subjectIDs={widgetOptions.subjectIDs}
				periods={widgetOptions.periods}
				tableInfo={tableInfo}
				gradeLevels={gradeLevels}
				rootPageContainer={rootPageContainer}
			/>
		</WidgetCard.ViewMode>
	);
}
