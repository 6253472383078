import {Text} from '@esgi/ui/typography';
import {useTabs} from './use-tabs';
import {TabID} from './types';
import {useTestPanelContext} from '../../../../context';
import {Tabs} from '../../../tabs';

export function TestTypeFilterTabs() {
	const {allTestCards} = useTestPanelContext();

	const {activeItem, setActiveItem, items} = useTabs({testCards: allTestCards});

	if (!items.length) {
		return null;
	}

	return (
		<Tabs.Root value={activeItem} onValueChange={setActiveItem}>
			<Tabs.Content>
				<Tabs.Item value={TabID.All}>
					<Text size='medium' bold>
						All
					</Text>
				</Tabs.Item>

				{items.map(({value, label}) => (
					<Tabs.Item value={value} key={value}>
						<Text size='medium' bold>
							{label}
						</Text>
					</Tabs.Item>
				))}
			</Tabs.Content>
		</Tabs.Root>
	);
}
