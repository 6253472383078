import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {QuickPanelContainer} from './index.styled';
import {useSingleSelectedExpandablePanelContext} from '../../context';

type Props = ComponentPropsWithoutRef<typeof QuickPanelContainer>;

export const QuickPanel = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'single-selected-expandable-panel-quick-panel', css = {}, children, ...props}, forwardedRef) => {
		const {isPanelExpanded} = useSingleSelectedExpandablePanelContext();

		return (
			<QuickPanelContainer dataCy={dataCy} css={css} ref={forwardedRef} {...props} isPanelExpanded={isPanelExpanded}>
				{children}
			</QuickPanelContainer>
		);
	},
);
