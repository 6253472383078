import {SkeletonShape} from '@esgi/main/kits/common';
import {styled} from '@esgi/ui/theme';
import {widgetCardMinWidth} from '../constants';
import {Page} from '@esgi/main/features/admins/dashboard';

export const SkeletonCard = styled(SkeletonShape, {
	borderRadius: 12,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$surface',
});

export const Body = styled(Page.Body, {
	display: 'grid',
	gap: 20,
	gridTemplateColumns: `repeat(auto-fill, minmax(${widgetCardMinWidth}px, 1fr))`,
});
