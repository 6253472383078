import React, {ReactNode} from 'react';
import {userStorage} from '@esgi/core/authentication';
import TestDetails from 'shared/modules/test-details/test-details';
import RubricCreatingFlow from '../rubric/creator/rubric-creating-flow';
import TestTypeSelector from './components/test-type-selector/test-type-selector';
import {ViewMode} from 'shared/modules/test-details/models';
import {ExtendedTestType} from './models';
import {TestType} from '@esgi/core/enums';

class State {
	selectedTestType: ExtendedTestType;
}

interface Props {
	disableAttachToSubject?: boolean;
	onTestCreated: () => void;
	onClose: () => void;
	disableNewSubjectBtn?: boolean;
}

export default class TestCreatorLauncher extends React.PureComponent<Props, State> {
	public readonly state = new State();

	private handleClose(testCreated: boolean) {
		if (testCreated) {
			this.props.onTestCreated();
		}
		this.props.onClose();
	}

	private renderTypeSelector(): ReactNode {
		return <TestTypeSelector onSelected={(type) => this.setState({selectedTestType: type})}
		                         onClose={this.props.onClose}/>;
	}

	private renderYN(): ReactNode {
		const user = userStorage.get();
		return <TestDetails firstName={user.firstName}
		                    lastName={user.lastName}
		                    userID={user.userID}
		                    typeInitially={TestType.YN}
		                    canAddToSubject={!this.props.disableAttachToSubject}
		                    close={this.props.onClose}
		                    onTestCreated={this.props.onTestCreated}
												disableNewSubjectBtn={this.props.disableNewSubjectBtn}/>;
	}

	private renderSelfAssess(): ReactNode {
		const user = userStorage.get();
		return <TestDetails firstName={user.firstName}
		                    lastName={user.lastName}
		                    userID={user.userID}
		                    typeInitially={TestType.YN}
							viewMode={ViewMode.SelfAssess}
		                    canAddToSubject={!this.props.disableAttachToSubject}
		                    close={this.props.onClose}
		                    onTestCreated={this.props.onTestCreated}
												disableNewSubjectBtn={this.props.disableNewSubjectBtn} />;
	}

	private renderSingleScore(): ReactNode {
		const user = userStorage.get();
		return <TestDetails firstName={user.firstName}
		                    lastName={user.lastName}
		                    userID={user.userID}
		                    typeInitially={TestType.Score}
		                    canAddToSubject={!this.props.disableAttachToSubject}
		                    close={this.props.onClose}
		                    onTestCreated={this.props.onTestCreated}
												disableNewSubjectBtn={this.props.disableNewSubjectBtn} />;
	}

	private renderRubric(): ReactNode {
		return <RubricCreatingFlow canAddToSubject={!this.props.disableAttachToSubject}
		                           onClose={this.handleClose.bind(this)}
															 disableNewSubjectBtn={this.props.disableNewSubjectBtn}/>;
	}

	public render(): ReactNode {
		switch (this.state.selectedTestType) {
			case ExtendedTestType.YN:
				return this.renderYN();
			case ExtendedTestType.Score:
				return this.renderSingleScore();
			case ExtendedTestType.Rubric:
				return this.renderRubric();
			case ExtendedTestType.SelfAssess:
				return this.renderSelfAssess();
			default:
				return this.renderTypeSelector();
		}
	}
}
