import React, {Suspense, useEffect} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {SsoTracker} from '@esgi/core/tracker';

const SpecialistActivityReportModal = lazyComponent(() => import('modules/reports/specialist-activity'));

type Props = {
	groupID: number;
	onClose: VoidFunction;
}

export function SpecialistActivityLauncher(props: Props) {
	useEffect(() => {
		SsoTracker.trackEvent({
			trackingEvent: 'SpecialistActivityReport',
		});
	}, []);
	return <Suspense fallback={<></>}>
		<SpecialistActivityReportModal
			groupOfSpecialistsID={props.groupID}
			onCloseClicked={props.onClose}/>
	</Suspense>;
}