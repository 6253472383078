import {intersection, without} from 'underscore';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {Correct, Expand} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Checkbox} from '@esgi/ui/controls';
import {GridBox, SelectableList} from '@esgi/ui/layout';
import {ClassModel} from '@esgi/contracts/esgi/types/esgi.students/queries/esgi.apigateway/modules/forms/student/class-model';
import {StyledIconBefore, StyledSelectableListItem, StyledSelectableListTrigger, StyledSelectableTriggerButton} from './index.styled';

interface Props {
	item: ClassModel;
	classIDs: string[];
	groupIDs: string[];
	onChange: (props: {classIDs: string[], groupIDs: string[]}) => void;
}

export function SelectableClass({item, classIDs, groupIDs, onChange}: Props) {
	const [isListOpen, setIsListOpen] = useState(false);

	const isClassExpandable = useMemo(() => (
		item.groups.length > 0
	), [item.groups]);

	const isClassSelected = useMemo(() => (
		classIDs.includes(String(item.classID))
	), [classIDs, item.classID]);

	const handleToggleOpenList = useCallback(() => {
		setIsListOpen((value) => !value);
	}, []);

	const hasIntersectionGroups = useCallback((selectedGroups: string[]) => {
		const groupStringIDs = item.groups.map(value => String(value.groupID));
		return intersection(groupStringIDs, selectedGroups).length > 0;
	}, [item.groups]);

	const handleCheckedChange = useCallback((classID: string) => (checked: boolean) => {
		if (checked) {
			onChange({classIDs: [...classIDs, classID], groupIDs});
			return;
		}

		if (hasIntersectionGroups(groupIDs)) {
			return;
		}

		onChange({classIDs: without(classIDs, classID), groupIDs});
	}, [classIDs, groupIDs, hasIntersectionGroups, onChange]);

	const handleChangeGroupIDs = useCallback((groups: string[]) => {
		if (isClassSelected) {
			onChange({classIDs, groupIDs: groups});
			return;
		}

		if (!hasIntersectionGroups(groups)) {
			onChange({classIDs, groupIDs: groups});
			return;
		}

		onChange({classIDs: [...classIDs, String(item.classID)], groupIDs: groups});
	}, [classIDs, hasIntersectionGroups, isClassSelected, item.classID, onChange]);

	useEffect(() => {
		if (!hasIntersectionGroups(groupIDs)) {
			setIsListOpen(false);
			return;
		}

		setIsListOpen(true);
	}, [hasIntersectionGroups]);

	return (
		<SelectableList>
			<SelectableList.GroupRoot type='multiple' value={groupIDs} onValueChange={handleChangeGroupIDs}>
				<StyledSelectableListTrigger selected={false} openList={isListOpen} onClick={handleToggleOpenList} expanded={isListOpen} custom>
					<StyledSelectableTriggerButton>
						<Checkbox checked={isClassSelected} onCheckedChange={handleCheckedChange(String(item.classID))} />
					</StyledSelectableTriggerButton>
					<Text size='small' font='mono'>
						{item.name}
					</Text>
					{isClassExpandable && (
						<Expand />
					)}
				</StyledSelectableListTrigger>
				<SelectableList.Group>
					{item.groups.map((group, index) => (
						<StyledSelectableListItem key={group.groupID} value={String(group.groupID)} withActiveBackground={false} custom>
							{(selected) => (
								<GridBox flow='column' align='center' justify='start' gap={2}>
									<StyledIconBefore checked={selected}>
										{selected && <Correct />}
									</StyledIconBefore>
									<Text size='small' font='mono'>
										{group.name}
									</Text>
								</GridBox>
							)}
						</StyledSelectableListItem>
					))}
				</SelectableList.Group>
			</SelectableList.GroupRoot>
		</SelectableList>
	);
}