import React, {useCallback} from 'react';
import {Box} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/controls';
import {useFormElementContext} from '@esgillc/ui-kit/form';
import {ErrorTooltip} from '../error-tooltip/error-tooltip';
import {errorTooltipItemList} from '../../constants';
import {StyledSelectField} from './index.styled';

interface Option {
	id: number;
	value: string;
}

interface Props<T extends Option> {
	placeholder: string;
	options: T[];
	disabled?: boolean;
}

export function SelectForm<T extends Option>({placeholder, options, disabled}: Props<T>) {
	const {value, firstError, setRef, setValue} = useFormElementContext();

	const handleValueChange = useCallback((currentValue: string) => {
		if (!currentValue || currentValue === value) {
			return;
		}

		setValue(currentValue);
	}, [value, setValue]);

	return (
		<Box ref={setRef}>
			<Select.Root errorMessage={firstError()} value={value} onValueChange={handleValueChange} disabled={disabled}>
				<StyledSelectField placeholder={placeholder} />
				<Select.Content>
					{options?.map((option) => (
						<Select.Option value={option.id.toString()} key={option.id}>
							<Text size='medium' bold>{option.value}</Text>
						</Select.Option>
					))}
				</Select.Content>
			</Select.Root>
			{errorTooltipItemList.map(({showOnError, message}, index) => (
				<ErrorTooltip showOnError={showOnError} message={message} key={index} />
			))}
		</Box>
	);
}