import {DatePickerWrapper} from './index.styled';
import {RefObject, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import moment from 'moment';
import {dateFormat, dateInFutureMessage, timeFormat, timeInFutureMessage} from './constants';
import {EditableSessionDateTimeRef, ValidateOnMinDate} from './types';
import {Meridiem, TimePicker} from '../time-picker';
import {DatePicker} from '../date-picker';

type Props = {
	initialTestDate: string | Date;
	setTouched?: (value: boolean) => void;
	setisValid: (value: boolean) => void;
	validateOnMinDate?: ValidateOnMinDate | undefined;
	editableSessionTimeRef: RefObject<EditableSessionDateTimeRef | undefined>;
	timeZone: string | undefined;
};

export function EditableSessionDateTimeInfo({
	initialTestDate,
	setTouched,
	setisValid,
	editableSessionTimeRef,
	validateOnMinDate,
	timeZone,
}: Props) {
	const {defaultSessionDate, defaultSessionTime} = useMemo(() => {
		const testDateTime = moment(initialTestDate);
		const testDate = moment(initialTestDate, 'YYYY-MM-DD');

		return {
			defaultSessionDate: testDate,
			defaultSessionTime: {
				hours: testDateTime.hours(),
				minutes: testDateTime.minutes(),
				joinedValue: testDateTime.format(timeFormat),
			},
		};
	}, [initialTestDate]);

	const [sessionDate, setSessionDate] = useState<Date | undefined>(defaultSessionDate.toDate());
	const [sessionDateError, setSessionDateError] = useState<string>();

	const [sessionTime, setSessionTime] = useState<string | undefined>(defaultSessionTime.joinedValue);
	const [sessionTimeError, setSessionTimeError] = useState<string>();

	useEffect(() => {
		const isTouched = Boolean(
			defaultSessionDate.diff(moment(sessionDate), 'days') || sessionTime !== defaultSessionTime.joinedValue,
		);

		const isValid = !sessionDateError && !sessionTimeError;

		setTouched?.(isTouched);
		setisValid(isValid);
	}, [
		defaultSessionDate,
		defaultSessionTime.joinedValue,
		sessionDate,
		sessionDateError,
		sessionTime,
		sessionTimeError,
		setTouched,
		setisValid,
	]);

	const getFullDate = useCallback(
		({sessionDate, sessionTime}: {sessionDate: Date | undefined; sessionTime: string | undefined}) => {
			const sessionMomentDate = moment(sessionDate);

			const date = sessionMomentDate.format(dateFormat);

			return moment(`${date} ${sessionTime}`, `${dateFormat} ${timeFormat}`);
		},
		[],
	);

	const handleSessionDateChange = useCallback(
		(values: Date[]) => {
			const sessionDate = values[0];

			const [mm, dd, yyyy] = moment(sessionDate).format(dateFormat).split('/');

			const momentSessionDate = moment(sessionDate);

			if (!sessionDate) {
				setSessionDateError('Date required');
			} else if (dd?.length !== 2 || mm?.length !== 2 || isNaN(Number(yyyy)) || Number(yyyy) < 1000) {
				setSessionDateError(`Use ${dateFormat} format`);
			} else if (validateOnMinDate?.value && momentSessionDate.isBefore(moment(validateOnMinDate.value))) {
				setSessionDateError(validateOnMinDate.message);
			} else {
				setSessionDateError(undefined);
			}

			setSessionDate(sessionDate);
		},
		[validateOnMinDate],
	);

	const handleSessionTimeChanged = useCallback(
		({hours, minutes, meridiem}: {hours: string; minutes: string; meridiem: Meridiem}) => {
			if (hours === '__' && minutes === '__') {
				setSessionTime(undefined);
				setSessionTimeError('Time required');

				return;
			}

			if (hours.includes('_') || minutes.includes('_')) {
				setSessionTime(undefined);
				setSessionTimeError(`Use ${timeFormat} format`);

				return;
			}

			const time = moment(`${hours}:${minutes} ${meridiem}`, 'h:mm A');

			setSessionTime(time.format(timeFormat));
			setSessionTimeError(undefined);
		},
		[],
	);

	useEffect(() => {
		const currentUserDate = new Date();
		const currentDateInTimeZone = currentUserDate.toLocaleString('en-US', {
			timeZone,
		});

		const enteredUserFullDateTime = getFullDate({sessionDate, sessionTime});
		const currentDateInTimeZoneMoment = moment(currentDateInTimeZone);

		const isEnteredDateInFuture = currentDateInTimeZoneMoment.isBefore(enteredUserFullDateTime, 'milliseconds');

		if (isEnteredDateInFuture) {
			if (!sessionDateError) {
				setSessionDateError(dateInFutureMessage);
			}

			if (!sessionTimeError) {
				setSessionTimeError(timeInFutureMessage);
			}

			return;
		}

		if (sessionDateError === dateInFutureMessage) {
			setSessionDateError(undefined);
		}

		if (sessionTimeError === timeInFutureMessage) {
			setSessionTimeError(undefined);
		}
	}, [getFullDate, sessionDate, sessionDateError, sessionTime, sessionTimeError, timeZone]);

	useImperativeHandle(editableSessionTimeRef, () => ({
		getFullDate: () => getFullDate({sessionDate, sessionTime}).format('YYYY-MM-DDTHH:mm:ss'),
	}));

	return (
		<>
			<DatePickerWrapper>
				<DatePicker.Root
					value={sessionDate ? [sessionDate] : []}
					onChange={handleSessionDateChange}
					error={Boolean(sessionDateError)}
				>
					<DatePicker.DropdownTrigger>
						<DatePicker.Input placeholder='Date' error={sessionDateError} />
					</DatePicker.DropdownTrigger>

					<DatePicker.DropdownBody>
						<DatePicker.CalendarPanel />
					</DatePicker.DropdownBody>
				</DatePicker.Root>
			</DatePickerWrapper>

			<TimePicker
				placeholder='Time'
				defaultHours={defaultSessionTime.hours}
				defaultMinutes={defaultSessionTime.minutes}
				onValueChanged={handleSessionTimeChanged}
				error={sessionTimeError}
			/>
		</>
	);
}
