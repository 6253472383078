import {
	TestsPanel as TestsPanelBase,
	TestPanelTestModel,
	TestPanelMode,
} from '@esgi/main/features/admins/subject-management';
import {Dispatch, useCallback} from 'react';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {PanelContainer, PanelDivider} from '../../../../components/panels.styled';
import {AddTestsButton, TitleContainer} from './index.styled';
import {Skeletonable} from '@esgi/ui';

type Props = Skeletonable & {
	tests: TestPanelTestModel[];
	onRearrangeTests: Dispatch<TestPanelTestModel[]>;
	onTestDeleteClick: Dispatch<TestPanelTestModel['id']>;
	onAddButtonClick: VoidFunction;
	testsPanelMode: TestPanelMode;
	setTestsPanelMode: Dispatch<TestPanelMode>;
};

export function TestsPanel({
	tests,
	skeleton,
	onRearrangeTests,
	onTestDeleteClick,
	onAddButtonClick,
	testsPanelMode,
	setTestsPanelMode,
}: Props) {
	const isRearrangeMode = testsPanelMode === TestPanelMode.Rearrange;

	const handleAddButtonClick = useCallback(() => {
		onAddButtonClick();
		setTestsPanelMode(TestPanelMode.Add);
	}, [onAddButtonClick, setTestsPanelMode]);

	return (
		<PanelContainer>
			<TestsPanelBase.Root
				skeleton={skeleton}
				tests={tests}
				panelMode={testsPanelMode}
				onPanelModeChanged={setTestsPanelMode}
			>
				<TitleContainer>
					<GridBox display='inlineGrid' flow='column' align='center' gap='2'>
						<TestsPanelBase.SectionTitle.Title size='small' color='mediumContrast'>
							Subject Tab Tests
						</TestsPanelBase.SectionTitle.Title>
						{isRearrangeMode && (
							<Text size='small' color='primary'>
								Rearrange Mode
							</Text>
						)}
					</GridBox>

					<TestsPanelBase.ActionButtons>
						<TestsPanelBase.ActionButtons.Rearrange />
						<TestsPanelBase.ActionButtons.Divider />
						<TestsPanelBase.ActionButtons.Add tooltipText='Add a test to the Subject Tab.' onClick={onAddButtonClick} />
					</TestsPanelBase.ActionButtons>
				</TitleContainer>

				<PanelDivider />

				<TestsPanelBase.Content.ViewMode onTestDeleteClick={onTestDeleteClick}>
					{!tests.length ? (
						<AddTestsButton color='secondary' onClick={handleAddButtonClick} skeleton={skeleton}>
							<Text size='medium' bold color='base'>
								Add Tests
							</Text>
						</AddTestsButton>
					) : undefined}
				</TestsPanelBase.Content.ViewMode>
				<TestsPanelBase.Content.RearrangeMode onSaveClick={onRearrangeTests} />
			</TestsPanelBase.Root>
		</PanelContainer>
	);
}
