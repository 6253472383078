import {TestType} from '@esgi/main/kits/common';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

type Props = {
	testType: TestType;
	result: number | null;
	totalPossible: number;
	resultDelta: number | null;
};

export function ClassAverageBar({totalPossible, testType, result, resultDelta}: Props) {
	const isRubricTypeTest = testType === TestType.Rubric;

	return (
		<Tooltip>
			<Tooltip.Trigger>
				<TestCard.Statistic.Charts.ProgressWithPointer
					percentageType={!isRubricTypeTest}
					value={result}
					maxValue={totalPossible}
					progressBarTitle='Class Average'
				>
					<TestCard.Statistic.Delta value={resultDelta} percentageType={!isRubricTypeTest} />
				</TestCard.Statistic.Charts.ProgressWithPointer>
			</Tooltip.Trigger>

			<Tooltip.Content variant='default'>
				<Text size='small'>Difference between class and student performance</Text>
			</Tooltip.Content>
		</Tooltip>
	);
}
