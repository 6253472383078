import {
	ExpandableSelectableBlockTab,
	GradeLevel,
	SubjectPublishType,
	SubjectPublishValue,
} from '@esgi/main/features/admins/subject-management';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui';
import {useMemo} from 'react';
import {PublishForSpecialistsTabID} from '../../../types';

type Parameters = {
	value: string;
	isDataLoaded: boolean;
	subjectNameError: string | null;
	publishPeriodValue: SubjectPublishValue;
	selectedGradeLevels: GradeLevel['id'][];
	isPublishForSpecialistsActive: boolean;
	publishForSpecialistsData: ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[];
};

export function useFormValid({
	value,
	isDataLoaded,
	subjectNameError,
	publishPeriodValue,
	selectedGradeLevels,
	isPublishForSpecialistsActive,
	publishForSpecialistsData,
}: Parameters) {
	return useMemo(() => {
		if (!isDataLoaded) {
			return false;
		}

		if (!value || !isNull(subjectNameError)) {
			return false;
		}

		if (publishPeriodValue.type === SubjectPublishType.MarkingPeriod && !publishPeriodValue.trackDatesIDs.length) {
			return false;
		}

		if (
			publishPeriodValue.type === SubjectPublishType.CustomDateRange &&
			(isNull(publishPeriodValue.dateFrom) || isNull(publishPeriodValue.dateTo))
		) {
			return false;
		}

		if (!selectedGradeLevels.length) {
			return false;
		}

		if (isPublishForSpecialistsActive) {
			const activePublishForSpecialistsItem = publishForSpecialistsData.find(
				({value, isActive}) => value !== PublishForSpecialistsTabID.All && isActive,
			);

			if (!isUndefined(activePublishForSpecialistsItem) && !activePublishForSpecialistsItem.selectedItems.length) {
				return false;
			}
		}

		return true;
	}, [
		isDataLoaded,
		isPublishForSpecialistsActive,
		publishForSpecialistsData,
		publishPeriodValue.dateFrom,
		publishPeriodValue.dateTo,
		publishPeriodValue.trackDatesIDs.length,
		publishPeriodValue.type,
		selectedGradeLevels.length,
		subjectNameError,
		value,
	]);
}
