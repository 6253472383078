import SpecialistsSubSection from './subsections/specialists';
import TeachersSubSection from './subsections/teachers';
import {DistrictFormType} from 'modules/subject-details/forms/district';
import {DistrictCommonData} from 'modules/subject-details/types';
import Section from '../../index';

interface Props {
	commonData: DistrictCommonData;
	form: DistrictFormType
	isFormValid: boolean;
}

export default function DistrictAdministratorPublishTo({
	commonData,
	form,
	isFormValid,
}: Props) {

	if (!commonData.specialistsCount) {
		return null;
	}
	return <Section data-cy={'publish-to'} title='Publish to'>
		<TeachersSubSection
			form={form}
			districtSubjectData={commonData}
			isFormValid={isFormValid}/>
		<SpecialistsSubSection
			form={form}
			districtSubjectData={commonData}
		/>
	</Section>;
}
