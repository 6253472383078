import {Alert} from '@esgi/ui/alert';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		width: 508,
		gap: 20,
	},
};

export const AlertHeader = styled(Alert.Header, {
	height: 64,

	'& > div': {
		gridTemplateColumns: '1fr auto',
		gap: '$4',

		'& > div': {
			display: 'grid',
			gridTemplateColumns: 'auto 1fr',
			alignItems: 'center',
			gap: '$2',
			color: '$negative',
		},
	},
});

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
	gap: '$1',
});

export const AlertFooter = styled(Alert.Footer, {
	paddingTop: 'unset',
});
