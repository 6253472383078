import {BaseComponentProps, ThemeColorKeys, getThemeColor} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Box} from './index.styled';

type Props = ComponentPropsWithoutRef<'div'> &
	BaseComponentProps & {
		backgroundColor: ThemeColorKeys;
	};

export const DeltaCheap = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'admin-widget-delta-cheap', css = {}, backgroundColor, children, ...props}, forwardedRef) => (
		<Box
			dataCy={dataCy}
			css={{
				...css,
				backgroundColor: getThemeColor(backgroundColor),
			}}
			ref={forwardedRef}
			{...props}
		>
			{children}
		</Box>
	),
);
