import {Item} from '@esgi/main/features/admins/subject-management';
import {RetestSubjectType} from '../../types';

export const retestItems: Item<RetestSubjectType>[] = [
	{value: RetestSubjectType.Any, label: 'All Modes'},
	{value: RetestSubjectType.TestIncorrect, label: 'Incorrect & NT'},
	{value: RetestSubjectType.TestAll, label: 'Retest All'},
];

export const retestModesTipText =
	'By selecting the Incorrect & NT or Retest All option, you are allowing teachers to use only the selected retesting option. Leaving All Modes checked allows teachersto decide how they would like to retest students.';

export const nonActiveSubjectTipText = 'Selecting this checkbox makes the Subject Tab visible to all users you select.';

export const notAvailableDeleteMessage =
	'Active Subject Tabs cannot be deleted. You must deactivate the tab before deleting.';
