import {
	ComponentPropsWithoutRef,
	forwardRef,
	useEffect,
	useId,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import {CollapsiblePanelContext, CollapsiblePanelContextValue} from '../../contexts/inner-context';
import {AnimationResult, config, useSpring} from 'react-spring';
import {ManagerRef} from '../../types';
import {Overlay, PanelBox} from './index.styled';
import {BaseComponentProps, Portal, useFlag} from '@esgi/ui';
import {overlayOpacity} from './constants';
import {useAppCollapsiblePanelContext} from '../../contexts/app-collapsible-panel-context';

type Props = BaseComponentProps &
	ComponentPropsWithoutRef<typeof PanelBox> & {
		managerRef: ManagerRef;
		overlayContainer?: Element | DocumentFragment | null | undefined;
		onOverlayClick?: VoidFunction;
	};

export const Root = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'collapsible-panel-root',
			css = {},
			managerRef,
			children,
			style,
			overlayContainer,
			onOverlayClick,
			...props
		},
		forwardedRef,
	) => {
		const panelID = useId();

		const {queue, enqueue, dequeue} = useAppCollapsiblePanelContext();
		const [wasOpened, setWasOpened] = useFlag();
		const [isOpenAnimateFinish, setIsOpenAnimateFinishTrue, setIsOpenAnimateFinishFalse] = useFlag();

		const [quickPanelHeight, setQuickPanelHeight] = useState(0);

		const [isPanelExpanded, setIsPanelExpanded] = useState(false);

		const panelTranslateRef = useRef(0);

		const [overlayStyles, overlayApi] = useSpring(() => ({
			from: {opacity: 0},
			config: {
				...config.gentle,
				clamp: true,
			},
		}));

		const [panelStyles, panelApi] = useSpring(() => ({
			from: {transform: 'translateY(100%)'},
			config: {
				...config.gentle,
				clamp: true,
			},
		}));

		useEffect(() => {
			enqueue(panelID);

			return () => {
				dequeue(panelID);
			};
		}, []);

		useEffect(() => {
			if (queue[0] === panelID && !wasOpened) {
				panelApi.start({
					from: {transform: 'translateY(100%)'},
					to: {transform: 'translateY(0%)'},
					onRest: ({finished}: AnimationResult) => {
						if (finished) {
							setIsOpenAnimateFinishTrue();
						}
					},
				});

				setWasOpened();
			}
		}, [panelApi, queue, setWasOpened, wasOpened]);

		useImperativeHandle(managerRef, () => ({
			close: (cb) => {
				setIsOpenAnimateFinishFalse();

				panelApi.start({
					from: {transform: 'translateY(0%)'},
					to: {transform: 'translateY(100%)'},
					onRest: ({finished}: AnimationResult) => {
						if (finished) {
							cb?.();
						}
					},
				});

				overlayApi.start({
					from: {opacity: overlayOpacity},
					to: {opacity: 0},
				});
			},
			expandPanel: (cb, immediate) => {
				setIsPanelExpanded(true);
				const translateStart = 100 - (quickPanelHeight / window.innerHeight) * 100;
				panelTranslateRef.current = translateStart;

				panelApi.start({
					from: {transform: `translateY(${translateStart}%)`},
					to: {transform: 'translateY(0%)'},
					onRest: ({finished}: AnimationResult) => {
						if (finished && !immediate) {
							cb?.();
						}
					},
				});

				overlayApi.start({
					from: {opacity: 0},
					to: {opacity: overlayOpacity},
				});

				if (immediate) {
					cb?.();
				}
			},
			collapsePanel: (cb) => {
				panelApi.start({
					from: {transform: 'translateY(0%)'},
					to: {transform: `translateY(${panelTranslateRef.current}%)`},
					onRest: ({finished}: AnimationResult) => {
						if (finished) {
							panelApi.start({
								to: {transform: 'translateY(0%)'},
								immediate: true,
							});
							setIsPanelExpanded(false);
							panelTranslateRef.current = 0;
							cb?.();
						}
					},
				});
				overlayApi.start({
					from: {opacity: overlayOpacity},
					to: {opacity: 0},
				});
			},
		}));

		const context = useMemo<CollapsiblePanelContextValue>(
			() => ({
				setQuickPanelHeight,
				isPanelExpanded,
			}),
			[isPanelExpanded],
		);

		return (
			<CollapsiblePanelContext.Provider value={context}>
				<PanelBox
					style={{
						...panelStyles,
						...style,
					}}
					data-cy={dataCy}
					css={css}
					isOpenAnimateFinish={isOpenAnimateFinish}
					fullPanelHeight={isPanelExpanded}
					ref={forwardedRef}
					{...props}
					data-panel-id={panelID}
				>
					{children}
				</PanelBox>

				{isPanelExpanded && (
					<Portal container={overlayContainer}>
						<Overlay onClick={() => onOverlayClick?.()} style={overlayStyles} />
					</Portal>
				)}
			</CollapsiblePanelContext.Provider>
		);
	},
);
