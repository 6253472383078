import {Button} from './index.styled';
import {ComponentPropsWithoutRef, forwardRef} from 'react';

type Props = ComponentPropsWithoutRef<typeof Button>;

export const NegativeButton = forwardRef<HTMLButtonElement, Props>(
	(
		{
			dataCy = 'negative-button',
			css = {},
			withBackgroundHover = true,
			onClick = () => alert('Button clicked'),
			children,
			...props
		},
		forwardedRef,
	) => (
		<Button
			dataCy={dataCy}
			css={css}
			withBackgroundHover={withBackgroundHover}
			onClick={onClick}
			ref={forwardedRef}
			{...props}
		>
			{children}
		</Button>
	),
);
