import {useSubjects, isDeployedSubject, DeployedSubject} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {DistrictSubject} from '@esgi/main/kits/subject-selection-panel';
import {SubjectLevel} from '@esgi/main/kits/common';
import {isNull} from '@esgi/ui';

type Parameters = {
	schoolID: number | null;
	availableGradeLevel: number;
};

type Out = [boolean, DistrictSubject[]];

export function useSubjectsFromStore({availableGradeLevel, schoolID}: Parameters): Out {
	const [{data: subjects, loaded: isSubjectsLoaded}] = useSubjects();

	return useMemo<Out>(() => {
		if (!isSubjectsLoaded) {
			return [false, []];
		}

		const filteredDeployedSubjects = subjects.filter((item): item is DeployedSubject => {
			if (isDeployedSubject(item)) {
				const {isPublished, tests, hidden, gradeLevels, allowedSchools} = item;

				const filterByAllowedSchools = !isNull(schoolID) ? allowedSchools.includes(schoolID) : true;

				return (
					isPublished &&
					Boolean(tests.length) &&
					!hidden &&
					gradeLevels.includes(availableGradeLevel) &&
					filterByAllowedSchools
				);
			}

			return false;
		});

		const activeSubjects = filteredDeployedSubjects.map<DistrictSubject>(({id, name, subjectType, gradeLevels}) => ({
			id,
			name,
			type: subjectType,
			level: SubjectLevel.District,
			gradeLevels,
			hidden: false,
		}));

		return [true, activeSubjects];
	}, [availableGradeLevel, isSubjectsLoaded, schoolID, subjects]);
}
