import {Student} from '@esgi/main/libs/store';
import {FormControl} from '@esgi/ui/form';

export {UserType} from '@esgi/contracts/esgi/types/esgi.enums/user-type';

export enum TabID {
	GroupInformation = 'GroupInformation',
	Students = 'Students',
}

export type StudentsIDsControl = FormControl<Student['id'][]>;
