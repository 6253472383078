import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';

export const StyledDaysContainer = styled(FlexBox, {
	gap: 4,
	alignItems: 'center',
	padding: '2px 4px 2px 2px',
	backgroundColor: '$primaryVivid',
	borderRadius: 3,
});

export const DaysCount = styled(Text, {
	color: '$primary',
	display: 'flex',
	alignItems: 'center',
	height: 16,
	padding: '2px 4px',
	backgroundColor: '$surface',
	borderRadius: '1px 0px 0px 1px',
});
