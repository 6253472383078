import {ToggleGroup} from '@esgi/ui/controls';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	padding: 3,

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
			},
		},
	},
});

export const ToggleGroupTitle = styled(ToggleGroup.Title, {
	marginTop: 6,

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				width: '50%',
				height: 12,
				borderRadius: 6,
				left: 0,
				right: 0,
				marginInline: 'auto',
			},
		},
	},
});
