import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';
import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {isNull} from 'underscore';
import {useEffect, useMemo} from 'react';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {DistrictSubject} from '@esgi/main/kits/subject-selection-panel';

type Out = [boolean, DistrictSubject[]];

export function useSubjectsByDistrictID(): Out {
	const currentUser = useUser();
	const api = useEsgiApiGateway();

	const [subjectsData, fetchSubjects] = useCancelableRequest(api.v2.schoolAdmins.modules.subjects.byDistrictId);

	const withFetchedData = !isNull(currentUser);

	useEffect(() => {
		if (withFetchedData) {
			fetchSubjects({
				globalSchoolYearID: currentUser.globalSchoolYearID,
				districtID: currentUser.districtID,
				inDistrictLevelOnly: true,
			});
		}
	}, [currentUser, fetchSubjects, withFetchedData]);

	return useMemo<Out>(() => {
		if (!withFetchedData) {
			return [true, []];
		}

		const isSubjectsLoaded = isAsyncSucceed(subjectsData);

		if (!isSubjectsLoaded) {
			return [false, []];
		}

		const filteredSubjects = subjectsData.data.value.deployedSubjects.filter(
			({published, hasTests, hidden, allowedSchools}) => {
				const filterByAllowedSchools = !isNull(currentUser) ? allowedSchools.includes(currentUser.schoolID) : true;

				return published && hasTests && !hidden && filterByAllowedSchools;
			},
		);

		const activeSubjects = filteredSubjects.map<DistrictSubject>(({id, name, gradeLevels}) => ({
			id,
			name,
			level: SubjectLevel.District,
			type: SubjectType.Deployed,
			gradeLevels,
			hidden: false,
		}));

		return [true, activeSubjects];
	}, [currentUser, subjectsData, withFetchedData]);
}
