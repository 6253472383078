import {useMemo} from 'react';
import {useUser} from '@esgi/core/authentication';
import {getFullName} from '@esgi/main/kits/common';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useStudents} from '@esgi/main/libs/school-admin-store';
import {TestResultsAdditionalEntity, TestResultsStudent} from '@esgi/main/kits/admin';
import {RowDef} from '../../../../../../types';
import {useTestResultsSubject} from '../../../../../../../_kit';
import {isNull} from 'underscore';

type Parameters = {
	selectedRow: RowDef;
};

export function useTestResultsData({selectedRow}: Parameters) {
	const user = useUser();

	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const filteredStudents = useMemo(
		() =>
			students
				.filter(({classesIDs}) => classesIDs.includes(selectedRow.id))
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {isReady: isTestResultsSubjectReady, subjects: testResultsSubjects} = useTestResultsSubject({
		...(!isNull(selectedRow.teacher) && {
			userID: selectedRow.teacher.id,
			additionalStoreSubjectsFilter: 'checkOnAllowedToAllTeachers',
		}),
		students: filteredStudents,
	});

	const hierarchy = useMemo<Hierarchy>(
		() => ({
			classID: selectedRow.id,
		}),
		[selectedRow],
	);

	const additionalInfo = useMemo<TestResultsAdditionalEntity>(
		() => ({
			class: {
				id: selectedRow.id,
				name: selectedRow.name,
			},
			...(!isNull(user) && {
				id: user.schoolID,
				name: '',
			}),
			...(!isNull(selectedRow.teacher) && {
				teacher: {
					id: selectedRow.teacher.id,
					name: getFullName({
						firstName: selectedRow.teacher.firstName,
						lastName: selectedRow.teacher.lastName,
					}),
				},
			}),
		}),
		[selectedRow, user?.schoolID],
	);

	return {
		subjects: testResultsSubjects,
		students: filteredStudents,
		skeleton: !isTestResultsSubjectReady || !isStudentsLoaded,
		hierarchy,
		additionalInfo,
	};
}
