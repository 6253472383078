import {ArrowButton, Container, Dot, PageInfoBox, PageInfoContainer, UnderlinedText} from './index.styled';
import {Enter} from '@esgi/ui/icons';
import {ComponentPropsWithoutRef, forwardRef, useEffect, useState} from 'react';
import {GridApi} from 'ag-grid-community';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';

type Props = ComponentPropsWithoutRef<typeof Container> & {
	api: GridApi;
};

export const Pagination = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'ag-grid-pagination', css = {}, api, ...props}, forwardedRef) => {
		const [currentPageIndex, setCurrentPageIndex] = useState(0);
		const [totalPages, setTotalPages] = useState(Math.max(api.paginationGetTotalPages(), 1));

		useEffect(() => {
			const handler = () => {
				const currentPageIndex = api.paginationGetCurrentPage();

				setTotalPages(Math.max(api.paginationGetTotalPages(), 1));
				setCurrentPageIndex(currentPageIndex);
			};

			api.addEventListener('paginationChanged', handler);

			return () => {
				api.removeEventListener('paginationChanged', handler);
			};
		}, []);

		return (
			<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				<PageInfoContainer>
					<Text size='xSmall' font='mono' color='mediumContrast'>
						Page
					</Text>

					<PageInfoBox>
						{totalPages === 1 ? (
							<Text size='small' font='mono' bold color='base'>
								1
							</Text>
						) : (
							<UnderlinedText size='small' font='mono' bold color='primary'>
								{currentPageIndex + 1}
							</UnderlinedText>
						)}

						<Dot />
						<Text size='small' font='mono' bold color='mediumContrast'>
							{totalPages}
						</Text>
					</PageInfoBox>
				</PageInfoContainer>

				<GridBox flow='column' gap='3'>
					<ArrowButton
						color='secondary'
						onClick={api.paginationGoToPreviousPage}
						disabled={currentPageIndex === 0}
						buttonType='previous'
					>
						<Enter />
					</ArrowButton>

					<ArrowButton
						color='secondary'
						onClick={api.paginationGoToNextPage}
						disabled={currentPageIndex === totalPages - 1}
					>
						<Enter />
					</ArrowButton>
				</GridBox>
			</Container>
		);
	},
);
