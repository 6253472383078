import {Root} from './components/root';

import {Root as ActionButtonsRoot} from './components/action-buttons/root.styled';
import {Divider as ActionButtonsDivider} from './components/action-buttons/divider.styled';
import {AddTestsButton} from './components/action-buttons/add-tests-button';
import {RearrangeButton} from './components/action-buttons/rearrange-button';

import {Root as SectionTitleRoot} from './components/section-title/root';
import {Title} from './components/section-title/title';
import {RearrangeMode} from './variant/rearrange-mode';
import {ViewMode} from './variant/view-mode';

import {EmptyContentBox} from './components/empty-content-box';

export const TestsPanel = {
	Root,
	ActionButtons: Object.assign(ActionButtonsRoot, {
		Rearrange: RearrangeButton,
		Add: AddTestsButton,
		Divider: ActionButtonsDivider,
	}),
	SectionTitle: {
		Root: SectionTitleRoot,
		Title,
	},
	Content: {
		ViewMode,
		RearrangeMode,
	},
	EmptyContentBox,
};

export * from './types/section';
export * from './types/tests';
