import {Logo} from '@esgi/ui/icons';
import {Container} from './index.styled';
import {AdminCaptionIcon} from './admin-caption-icon';

export function LogoWithCaption() {
	return (
		<Container>
			<Logo/>
			<AdminCaptionIcon />
		</Container>
	);
}
