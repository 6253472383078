export type SelectItem<T extends string> = {
	value: T;
	label: string;
};

export enum TestedOption {
	Any = 'Any',
	Yes = 'Yes',
	No = 'No',
}
