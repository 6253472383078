import {Dispatch, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {isUndefined, Skeletonable, useFlag} from '@esgi/ui';
import {Tabs} from '../../tabs';
import {TabID, Entities, PanelMode} from '../types/section';
import {SubjectModel} from '../types/subject';
import {PanelContext, PanelContextValue} from '../context';
import {UnsavedChangesConfirmation} from '@esgi/main/kits/common';

type Props = PropsWithChildren<
	Skeletonable & {
		entities: Entities;
		initialTab?: TabID;
		onActiveTabChanged?: Dispatch<TabID | null>;
		selectedSubjectID?: SubjectModel['id'] | undefined | null;
		onSelectedSubjectIDChanged?: Dispatch<SubjectModel['id'] | null>;
		panelMode?: PanelMode;
		onPanelModeChanged?: Dispatch<PanelMode>;
		isSubjectHasUnsavedChanges?: boolean;
	}
>;

export function Root({
	skeleton,
	children,
	entities,
	initialTab,
	onActiveTabChanged,
	selectedSubjectID: controlledSelectedSubjectID,
	onSelectedSubjectIDChanged,
	panelMode: controlledPanelMode,
	onPanelModeChanged,
	isSubjectHasUnsavedChanges,
}: Props) {
	const [activeTab, setActiveTab] = useState(initialTab ?? null);
	const [panelMode, setPanelMode] = useState(controlledPanelMode ?? PanelMode.View);

	const [selectedSubjectID, setSelectedSubjectID] = useState<SubjectModel['id'] | null>(
		controlledSelectedSubjectID ?? null,
	);

	const [tabsIDsWithRearrangeContent, setTabsIDsWithRearrangeContent] = useState<TabID[]>([]);

	const [isUnsavedChangesAlertOpen, openUnsavedChangesAlert, closeUnsavedChangesAlert] = useFlag();
	const heldAnywayCbRef = useRef<VoidFunction | null>(null);

	useEffect(() => {
		if (!isUndefined(controlledSelectedSubjectID)) {
			setSelectedSubjectID(controlledSelectedSubjectID);
		}
	}, [controlledSelectedSubjectID]);

	useEffect(() => {
		if (!isUndefined(controlledPanelMode)) {
			setPanelMode(controlledPanelMode);
		}
	}, [controlledPanelMode]);

	const cbWithValidation = useCallback(
		(cb: VoidFunction) => {
			if (isSubjectHasUnsavedChanges) {
				openUnsavedChangesAlert();
				heldAnywayCbRef.current = cb;
				return;
			}

			cb();
			heldAnywayCbRef.current = null;
		},
		[isSubjectHasUnsavedChanges, openUnsavedChangesAlert],
	);

	const onSetSelectedSubjectID = useCallback(
		(value: SubjectModel['id'] | null) => {
			cbWithValidation(() => {
				setSelectedSubjectID(value);
				onSelectedSubjectIDChanged?.(value);
			});
		},
		[cbWithValidation, onSelectedSubjectIDChanged],
	);

	const onSetPanelMode = useCallback(
		(mode: PanelMode) => {
			const cb = () => {
				setPanelMode(mode);
				onPanelModeChanged?.(mode);
			};

			if (mode === PanelMode.Rearrange || panelMode === PanelMode.Rearrange) {
				cb();
				return;
			}

			cbWithValidation(cb);
		},
		[cbWithValidation, onPanelModeChanged, panelMode],
	);

	const handleActiveTabChanged = useCallback(
		(activeTab: TabID | null) => {
			cbWithValidation(() => {
				onSetPanelMode(PanelMode.View);
				setActiveTab(activeTab);
				onActiveTabChanged?.(activeTab);
			});
		},
		[cbWithValidation, onActiveTabChanged, onSetPanelMode],
	);

	const addTabWithRearrangeMode = useCallback((tabID: TabID) => {
		setTabsIDsWithRearrangeContent((currentState) => [...currentState, tabID]);
	}, []);

	const removeTabWithRearrangeMode = useCallback((tabID: TabID) => {
		setTabsIDsWithRearrangeContent((currentState) => currentState.filter((tab) => tab !== tabID));
	}, []);

	const onCloseAnywayUnsavedChangesAlert = useCallback(() => {
		heldAnywayCbRef.current?.();
		closeUnsavedChangesAlert();

		heldAnywayCbRef.current = null;
	}, [closeUnsavedChangesAlert]);

	const context = useMemo<PanelContextValue>(
		() => ({
			entities,
			selectedSubjectID,
			setSelectedSubjectID: onSetSelectedSubjectID,
			skeleton: Boolean(skeleton),
			panelMode,
			setPanelMode: onSetPanelMode,
			tabsIDsWithRearrangeContent,
			addTabWithRearrangeMode,
			removeTabWithRearrangeMode,
		}),
		[
			addTabWithRearrangeMode,
			entities,
			onSetPanelMode,
			onSetSelectedSubjectID,
			panelMode,
			removeTabWithRearrangeMode,
			selectedSubjectID,
			skeleton,
			tabsIDsWithRearrangeContent,
		],
	);

	return (
		<PanelContext.Provider value={context}>
			<Tabs.Root activeTab={activeTab} setActiveTab={handleActiveTabChanged} skeleton={skeleton}>
				{children}

				{isUnsavedChangesAlertOpen && (
					<UnsavedChangesConfirmation
						onCloseAnyway={onCloseAnywayUnsavedChangesAlert}
						onClose={closeUnsavedChangesAlert}
					/>
				)}
			</Tabs.Root>
		</PanelContext.Provider>
	);
}
