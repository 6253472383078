import {useCallback, useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject, useStreamEffect} from '@esgillc/ui-kit/utils';
import {Loader} from '@esgillc/ui-kit/loader';
import {ElementStatus, Form} from '@esgillc/ui-kit/form';
import {Modal, Title, useCloseModal, useModal} from '@esgillc/ui-kit/modal';
import {Buttons} from '@esgillc/ui-kit/button';
import {DistrictDetailsService} from './service';
import {SaveResult} from '../../../types';
import ForceTestTypeSection from '../../sections/force-test-type';
import GradeLevelSection from '../../sections/grade-level';
import DistrictAdministratorPublishTo from '../../sections/publish-to/district';
import PublishSection from '../../sections/publish';
import ShuffleQuestionsSection from '../../sections/shuffle-questions';
import SubjectTabNameSection from '../../sections/subject-tab-name';
import styles from './styles.module.less';
import {combineLatest, map} from 'rxjs';

class Props {
	subjectID?: number;
	editMode: boolean;
	onClosed: () => void;
	onSaved: (response: SaveResult, gradeLevels: number[]) => void;
}

export default function DistrictTabDetails({
	subjectID,
	editMode,
	onClosed,
	onSaved,
}: Props) {

	const modalRef = useModal();
	const handleClose = useCloseModal(modalRef, onClosed);

	const service = useService(DistrictDetailsService);
	const {form} = service;

	const commonData = useBehaviorSubject(service.commonData);
	const gradeLevelsIDs = useBehaviorSubject(service.gradeLevelsIDs);

	const [isInitialized, setInitialized] = useState(false);
	const [isLoading, setLoading] = useState(true);

	const [isFormValid, setIsFormValid] = useState(true);

	useEffect(() => {
		service.init(subjectID).subscribe({
			complete: () => {
				setInitialized(true);
				setLoading(false);

				form.validate().subscribe(errors => setIsFormValid(!errors.length));
			},
		});
	}, []);

	useEffect(() => {
		const observables = Object.values(form.controls).map((control) => control.onChanged);

		const subscription = combineLatest(observables)
			.pipe(
				map((results) => {
					return results.every(({currState}) => currState.status !== ElementStatus.invalid);
				}),
			)
			.subscribe((valid) => {
				setIsFormValid(valid);
			});

		return () => {
			subscription.unsubscribe();
		};
	}, [form]);

	const onSubmit = () => {
		setLoading(true);
		service.save().subscribe({
			next: (res) => {
				onSaved(res, gradeLevelsIDs);
				handleClose();
			},
			complete: () => {
				setLoading(false);
			},
		});
	};

	return <div data-cy={'district-details'}>
		<Loader show={isLoading} fullscreen/>
		{isInitialized &&
			<Modal onCatchError={handleClose} modalManagerRef={modalRef}>
				<Modal.Header>
					<Title>{editMode ? 'Edit Subject Tab' : 'Create New Subject Tab'}</Title>
				</Modal.Header>

				<Modal.Body className={styles.body}>
					<Form controller={form}>
						<SubjectTabNameSection form={form}/>
						<GradeLevelSection form={form} commonData={commonData}/>
						<DistrictAdministratorPublishTo form={form} commonData={commonData} isFormValid={isFormValid}/>
						<PublishSection form={form} commonData={commonData}/>
						<ForceTestTypeSection form={form}/>
						<ShuffleQuestionsSection form={form}/>
					</Form>
				</Modal.Body>

				<Modal.Footer>
					<Buttons.Gray onClick={handleClose}>Cancel</Buttons.Gray>
					<Buttons.Contained disabled={!isFormValid} onClick={onSubmit}>
						Save
					</Buttons.Contained>
				</Modal.Footer>
			</Modal>}
	</div>;
}
