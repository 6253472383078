import {FolderAdd, FolderRemove, Teacher} from '@esgi/ui/icons';
import {ActionPanel, NegativeButton, RunReportButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button, useFlag} from '@esgi/ui';
import {Folder, OnTeacherRemoved} from '@esgi/main/features/admins/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {getFullName} from '@esgi/main/kits/common';
import {DeleteTeacherButtonSingleSelect} from '../../../../../../components/delete-teacher-button-single-select';
import {GridApi} from 'ag-grid-community';
import {ReportsFeature} from '../reports';
import {useCallback} from 'react';
import {useDataPageContext} from '../../../../../../../layout/context';
import {OpenPanelButton} from '../../../../../../components/open-panel-button';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	onRemoveFromFolderClick: VoidFunction;
	activeFolder: Folder;
	onDeselectFolder: VoidFunction;
	userCanAddAndDeleteTeachers: boolean;
	isPanelExpanded: boolean;
	gridApi: GridApi<Readonly<RowDef>> | null;
};

export function QuickPanel({
	activeFolder,
	onDeselectFolder,
	onFolderAddClick,
	onRemoveFromFolderClick,
	selectedRow,
	userCanAddAndDeleteTeachers,
	isPanelExpanded,
	gridApi,
}: Props) {
	const [showReportsDialog, openReport, closeReport] = useFlag();
	const {onDecreaseFolderCount} = useDataPageContext();

	const onTeacherRemoved = useCallback<OnTeacherRemoved>(
		({removedFoldersIDs}) => {
			if (removedFoldersIDs.includes(activeFolder.id)) {
				onDeselectFolder();
				return;
			}

			onDecreaseFolderCount(activeFolder.id);
			gridApi?.deselectAll();
		},
		[activeFolder, gridApi, onDecreaseFolderCount, onDeselectFolder],
	);

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<Teacher />

							<Text size='large' color='highContrast'>
								{getFullName({
									firstName: selectedRow.firstName,
									lastName: selectedRow.lastName,
								})}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteTeacherButtonSingleSelect
								onRemoved={onTeacherRemoved}
								selectedRow={selectedRow}
								userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
							/>
							<ActionPanel.ActionButtonDivider />

							<Button.Icon withBackgroundHover onClick={onFolderAddClick}>
								<FolderAdd />
							</Button.Icon>

							<NegativeButton onClick={onRemoveFromFolderClick}>
								<FolderRemove />
							</NegativeButton>

							<RunReportButton onClick={openReport} />
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<OpenPanelButton canExpandPanel={!!selectedRow.students} />
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showReportsDialog && <ReportsFeature onClose={closeReport} teacher={selectedRow} folder={activeFolder} />}
		</>
	);
}
