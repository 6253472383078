import {useLocation, useNavigate} from 'react-router-dom';
import {useCallback} from 'react';
import {routes} from '@esgi/main/libs/core';
import {
	ClassesNavigate,
	GroupsNavigate,
	SpecialistGroupsNavigate,
	SpecialistsNavigate,
	StudentsNavigate,
	TeachersNavigate,
} from '../types/navigation-states';

type Page = keyof typeof routes.schoolAdmin.data;

function useNavigateTo<T extends unknown>(page: Page) {
	const navigate = useNavigate();

	return useCallback(
		(state?: Partial<T>) => {
			const pagePath = routes.schoolAdmin.data[page];
			navigate(pagePath, {state: state ?? null});
		},
		[navigate, page],
	);
}

export function useNavigateToTeachersPage() {
	return useNavigateTo<TeachersNavigate>('teachers');
}

export function useNavigateToSpecialistsPage() {
	return useNavigateTo<SpecialistsNavigate>('specialists');
}

export function useNavigateToClassesPage() {
	return useNavigateTo<ClassesNavigate>('classes');
}

export function useNavigateToGroupsPage() {
	return useNavigateTo<GroupsNavigate>('groups');
}

export function useNavigateToSpecialistsGroupsPage() {
	return useNavigateTo<SpecialistGroupsNavigate>('specialistGroups');
}

export function useNavigateToStudentsPage() {
	return useNavigateTo<StudentsNavigate>('students');
}

export function useLocationState<T extends unknown>() {
	const location = useLocation();

	return (location.state ?? null) as Partial<T> | null;
}
