import {firstValueFrom, map} from 'rxjs';
import {V2SchoolAdminsStorageController} from '@esgi/contracts/esgi';
import {createStoreSlice} from '../slice-utils';
import {DeployedSubject, StockSubject, SubjectLevel, SubjectType, Test} from '../../types';

export const studentsSlice = createStoreSlice('students', () =>
	firstValueFrom(new V2SchoolAdminsStorageController().students().pipe(map((r) => r.value.students))),
);

export const subjectsSlice = createStoreSlice('subjects', () =>
	firstValueFrom(
		new V2SchoolAdminsStorageController().subjects().pipe(
			map((r) => {
				const mappedDeployedSubjects = r.value.deployedSubjects.map<DeployedSubject>((item) => ({
					id: item.id,
					name: item.name,
					subjectType: SubjectType.Deployed,
					hidden: item.hidden,
					isPublished: item.isPublished,
					level: item.level as unknown as SubjectLevel,
					gradeLevels: item.gradeLevels,
					tests: item.tests as unknown as Test[],
					allowedToAllTeachers: item.allowedToAllTeachers,
					allowedSpecialists: item.allowedSpecialists,
				}));

				const mappedStockSubjects = r.value.stockSubjects.map<StockSubject>((item) => ({
					id: item.id,
					name: item.name,
					subjectType: SubjectType.Stock,
					hidden: item.hidden,
					level: item.level as unknown as SubjectLevel,
					tests: item.tests as unknown as Test[],
				}));

				return [...mappedDeployedSubjects, ...mappedStockSubjects];
			}),
		),
	),
);
