import {Dispatch, MutableRefObject, useCallback, useEffect, useRef} from 'react';
import {Folder, FolderManagement, FolderType} from '../kit';
import {isAsyncSucceed, isUndefined} from '@esgi/ui';
import {isNull} from 'underscore';
import {FoldersVocabulary} from './types';

export function useFoldersVocabulary({
	folderType,
	initialFolderID,
}: {
	folderType: FolderType;
	initialFolderID: Folder['id'] | null;
}): [FoldersVocabulary, Dispatch<Folder['id']>] {
	const [folderData, fetchFolderDataByID] = FolderManagement.useFetchFolderByID({type: folderType});

	const lastSentFolderID = useRef<Folder['id'] | null>(null);
	const vocabulary = useRef<Record<Folder['id'], number[]>>({});

	useEffect(() => {
		const folderID = lastSentFolderID.current;

		if (isAsyncSucceed(folderData) && !isNull(folderID)) {
			vocabulary.current[folderID] = folderData.data.ids;
			lastSentFolderID.current = null;
		}
	}, [folderData]);

	const fetchFolderByID = useCallback(
		(folderID: Folder['id']) => {
			if (isUndefined(vocabulary.current[folderID])) {
				lastSentFolderID.current = folderID;
				fetchFolderDataByID(folderID);
			}
		},
		[fetchFolderDataByID],
	);

	useEffect(() => {
		if (!isNull(initialFolderID)) {
			fetchFolderByID(initialFolderID);
		}
	}, []);

	return [vocabulary, fetchFolderByID];
}
