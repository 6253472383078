import {useCallback, useEffect, useState} from 'react';
import {useBehaviorSubject} from '@esgi/ui';
import {useService} from '@esgi/core/service';
import {PageService} from './page-service';
import {useEventEffect} from '@esgillc/events';
import {WidgetCreated} from '@esgi/main/features/district-admin/dashboard/events';
import {concatMap} from 'rxjs';
import {PageSkeletonMode} from './variant-mode/page-skeleton-mode';
import {PageContent} from './variant-mode/page-content';
import {useAppSync} from './use-app-sync';
import {useUser} from '@esgi/core/authentication';
import {isNull} from 'underscore';
import {useUserCurrentTrack} from '@esgi/main/kits/admin';
import {Page} from '@esgi/main/features/admins/dashboard';

export default function DashboardPage() {
	const currentUser = useUser();

	const [isPageDataLoaded, setIsPageDataLoaded] = useState(false);
	const [lastNewWidgetsID, setLastNewWidgetsID] = useState<number | null>(null);

	const {skeleton: currentTrackLoading, currentTrack} = useUserCurrentTrack();

	const pageService = useService(PageService);

	const widgets = useBehaviorSubject(pageService.widgets$);
	const widgetsMetricData = useBehaviorSubject(pageService.widgetsMetricData$);

	const schools = useBehaviorSubject(pageService.schools$);
	const lastUpdatedDate = useBehaviorSubject(pageService.lastUpdatedDate$);
	const deployedSubjects = useBehaviorSubject(pageService.deployedSubjects$);
	const gradeLevels = useBehaviorSubject(pageService.gradeLevels$);
	const contentAreas = useBehaviorSubject(pageService.contentAreas$);

	const initWidgetsData = useCallback(pageService.initWidgetsData.bind(pageService), [pageService]);
	const updateLastUpdatedDate = useCallback(pageService.updateLastUpdatedDate.bind(pageService), [pageService]);
	const fetchWidgetDataByID = useCallback(pageService.fetchWidgetDataByID.bind(pageService), [pageService]);

	useEffect(() => {
		if (!isNull(currentUser)) {
			pageService
				.fetchContentAreas()
				.pipe(
					concatMap((contentAreas) =>
						pageService.init({globalSchoolYearID: currentUser.globalSchoolYearID, contentAreasList: contentAreas}),
					),
					concatMap(({value: {dashboardVersionID}}) => {
						return initWidgetsData({dashboardVersionID});
					}),
				)
				.subscribe(() => {
					setIsPageDataLoaded(true);
				});
		}
	}, []);

	useAppSync({
		initWidgetsData,
		updateLastUpdatedDate,
		fetchWidgetDataByID,
	});

	useEventEffect(WidgetCreated, (data) => {
		pageService.addNewWidgetInList(data);
		setLastNewWidgetsID(data.id);
	});

	const resetLastNewWidgetsID = useCallback(() => {
		setLastNewWidgetsID(null);
	}, []);

	return (
		<Page>
			{!isPageDataLoaded || currentTrackLoading ? (
				<PageSkeletonMode />
			) : (
				<PageContent
					subjects={deployedSubjects}
					widgetsList={widgets}
					lastUpdatedDate={lastUpdatedDate}
					allSchoolsList={schools}
					updateDistrictPerformanceWidget={pageService.updateDistrictPerformanceWidget.bind(pageService)}
					updateDemographicPerformanceWidget={pageService.updateDemographicPerformanceWidget.bind(pageService)}
					updateLowestAchievementWidget={pageService.updateLowestAchievementWidget.bind(pageService)}
					deleteWidgetByID={pageService.deleteWidgetByID.bind(pageService)}
					updateWidgetsOrdering={pageService.updateWidgetsOrdering.bind(pageService)}
					lastNewWidgetsID={lastNewWidgetsID}
					resetLastNewWidgetsID={resetLastNewWidgetsID}
					widgetsMetricData={widgetsMetricData}
					gradeLevels={gradeLevels}
					currentTrack={currentTrack}
					contentAreas={contentAreas}
				/>
			)}
		</Page>
	);
}
