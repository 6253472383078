import {Text} from '@esgi/ui/typography';
import {FormType} from '../../types';
import {Container, IconWrapper, ToggleGroupContent, ToggleGroupItem, ToggleGroupRoot} from './index.styled';
import {items} from './constants';
import {Dispatch} from 'react';

type Props = {
	activeForm: FormType;
	setActiveForm: Dispatch<FormType>;
};

export function AlertSubHeader({activeForm, setActiveForm}: Props) {
	return (
		<Container>
			<ToggleGroupRoot value={activeForm} onValueChange={setActiveForm}>
				<ToggleGroupContent>
					{items.map(({value, label, Icon}) => {
						const isItemSelected = activeForm === value;

						return (
							<ToggleGroupItem
								value={value}
								disabled={isItemSelected}
								applyDisabledStyles={!isItemSelected}
								key={value}
							>
								<IconWrapper isItemSelected={isItemSelected}>
									<Icon />
								</IconWrapper>
								<Text size='small'>{label}</Text>
							</ToggleGroupItem>
						);
					})}
				</ToggleGroupContent>
			</ToggleGroupRoot>
		</Container>
	);
}
