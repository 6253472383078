import {BehaviorSubject, forkJoin, map, Observable, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {
	V2SchoolAdminsPagesDashboardWidgetsController,
	V2SchoolYearsController,
	V2TracksController,
} from '@esgi/contracts/esgi';
import {
	AddWidgetEventOptions,
	SchoolDashboardWidgetType,
	SchoolDemographicPerformanceWidgetOptions,
	SchoolPerformanceWidgetOptions,
	SchoolStudentsNeedingSupportWidgetOptions,
} from './types';
import {getSchoolDemographicPerformanceInitOptions} from './helpers/get-school-demographic-performance-init-options';
import {getSchoolStudentsNeedingSupportInitOptions} from './helpers/get-school-lowest-achievement-init-options';
import {getSchoolPerformanceInitOptions} from './helpers/get-school-performance-init-options';
import {isUndefined} from '@esgi/ui';
import moment from 'moment';
import {widgetDateFormat} from './constants';
import {getUser} from '@esgi/core/authentication';
import {isNull} from 'underscore';
import {AddWidgetBase, AddWidgetResponse, DatePeriod} from '@esgi/main/features/admins/dashboard';

export class Service extends BaseService {
	private readonly tracksController = new V2TracksController();
	private readonly schoolYearsController = new V2SchoolYearsController();

	private readonly widgetsController = new V2SchoolAdminsPagesDashboardWidgetsController();

	private isUserInCurrentGlobalSchoolYear$ = new BehaviorSubject(false);

	private minWidgetDate$ = new BehaviorSubject<string | null>(null);
	private maxWidgetDate$ = new BehaviorSubject<string | null>(null);

	public init() {
		const fetchTrackDates = this.tracksController.init().pipe(
			tap(({selectedTrackID, tracks}) => {
				const currentTrack = tracks.find(({trackID}) => trackID === selectedTrackID);

				if (isUndefined(currentTrack)) {
					return;
				}

				const allDatesTimes = currentTrack.trackDates.flatMap(({dateFrom, dateTo}) => [
					moment(`${dateFrom}Z`),
					moment(`${dateTo}Z`),
				]);

				const minDate = moment.min(allDatesTimes).format(widgetDateFormat);
				const maxDate = moment.max(allDatesTimes).format(widgetDateFormat);

				this.minWidgetDate$.next(minDate);
				this.maxWidgetDate$.next(maxDate);
			}),
		);

		const fetchSchoolYearsData = this.schoolYearsController.init().pipe(
			tap(({currentGlobalSchoolYearID}) => {
				const currentUser = getUser();

				this.isUserInCurrentGlobalSchoolYear$.next(currentGlobalSchoolYearID === currentUser?.globalSchoolYearID);
			}),
		);

		return forkJoin([fetchSchoolYearsData, fetchTrackDates]);
	}

	public createWidget({
		districtID,
		schoolID,
		globalSchoolYearID,
		selectedWidgetType,
	}: {
		districtID: number;
		schoolID: number;
		globalSchoolYearID: number;
		selectedWidgetType: SchoolDashboardWidgetType;
	}): Observable<{
		id: AddWidgetResponse['id'];
		name: string;
		versionID: number;
		options: AddWidgetEventOptions;
		lastUpdatedTime: string;
	}> {
		const lastUpdatedTime = new Date().toISOString();

		const isUserInCurrentGlobalSchoolYear = this.isUserInCurrentGlobalSchoolYear$.value;
		const minWidgetDate = this.minWidgetDate$.value;
		const maxWidgetDate = this.maxWidgetDate$.value;

		const withoutMinAndMaxDates = isNull(minWidgetDate) || isNull(maxWidgetDate);

		const dateRange: DatePeriod | null =
			isUserInCurrentGlobalSchoolYear || withoutMinAndMaxDates
				? null
				: {
						dateFrom: minWidgetDate,
						dateTo: maxWidgetDate,
					};

		switch (selectedWidgetType) {
			case SchoolDashboardWidgetType.SchoolPerformance:
				return this.createSchoolPerformanceWidget({districtID, schoolID, globalSchoolYearID, dateRange}).pipe(
					map(({id, name, versionID, schoolPerformanceWidgetOptions}) => ({
						id,
						name,
						versionID,
						options: {
							schoolPerformanceWidgetOptions,
							schoolDemographicPerformanceWidgetOptions: null,
							schoolStudentsNeedingSupportWidgetOptions: null,
						},
						lastUpdatedTime,
					})),
				);
			case SchoolDashboardWidgetType.SchoolDemographicPerformance:
				return this.createSchoolDemographicPerformance({districtID, schoolID, globalSchoolYearID, dateRange}).pipe(
					map(({id, name, versionID, schoolDemographicPerformanceWidgetOptions}) => ({
						id,
						name,
						versionID,
						options: {
							schoolPerformanceWidgetOptions: null,
							schoolDemographicPerformanceWidgetOptions,
							schoolStudentsNeedingSupportWidgetOptions: null,
						},
						lastUpdatedTime,
					})),
				);
			case SchoolDashboardWidgetType.SchoolStudentsNeedingSupport:
				return this.createSchoolStudentsNeedingSupportWidget({
					districtID,
					schoolID,
					globalSchoolYearID,
					dateRange,
				}).pipe(
					map(({id, name, versionID, schoolStudentsNeedingSupportWidgetOptions}) => ({
						id,
						name,
						versionID,
						options: {
							schoolPerformanceWidgetOptions: null,
							schoolDemographicPerformanceWidgetOptions: null,
							schoolStudentsNeedingSupportWidgetOptions,
						},
						lastUpdatedTime,
					})),
				);
		}
	}

	public override dispose(): void {
		this.widgetsController.dispose();
		this.tracksController.dispose();
		this.schoolYearsController.dispose();
	}

	private createSchoolPerformanceWidget({
		districtID,
		schoolID,
		globalSchoolYearID,
		dateRange,
	}: {
		districtID: number;
		schoolID: number;
		globalSchoolYearID: number;
		dateRange: DatePeriod | null;
	}): Observable<{
		id: AddWidgetResponse['id'];
		name: string;
		versionID: number;
		schoolPerformanceWidgetOptions: SchoolPerformanceWidgetOptions;
	}> {
		const widgetName = 'School Performance';
		const options = getSchoolPerformanceInitOptions({districtID, schoolID, globalSchoolYearID, dateRange});

		return this.widgetsController
			.add({
				name: widgetName,
				globalSchoolYearID,
				dashboardWidgetOptions: {
					...AddWidgetBase.dashboarWidgetOptionsAllNullable,
					schoolPerformanceWidgetOptions: options,
				},
			})
			.pipe(
				map(({value: {id, versionID}}) => ({
					id,
					name: widgetName,
					versionID,
					schoolPerformanceWidgetOptions: options,
				})),
			);
	}

	private createSchoolDemographicPerformance({
		districtID,
		schoolID,
		globalSchoolYearID,
		dateRange,
	}: {
		districtID: number;
		schoolID: number;
		globalSchoolYearID: number;
		dateRange: DatePeriod | null;
	}): Observable<{
		id: AddWidgetResponse['id'];
		versionID: number;
		name: string;
		schoolDemographicPerformanceWidgetOptions: SchoolDemographicPerformanceWidgetOptions;
	}> {
		const widgetName = 'Demographic Performance';
		const options = getSchoolDemographicPerformanceInitOptions({districtID, schoolID, globalSchoolYearID, dateRange});

		return this.widgetsController
			.add({
				name: widgetName,
				globalSchoolYearID,
				dashboardWidgetOptions: {
					...AddWidgetBase.dashboarWidgetOptionsAllNullable,
					schoolDemographicPerformanceWidgetOptions: options,
				},
			})
			.pipe(
				map(({value: {id, versionID}}) => ({
					id,
					versionID,
					name: widgetName,
					schoolDemographicPerformanceWidgetOptions: options,
				})),
			);
	}

	private createSchoolStudentsNeedingSupportWidget({
		districtID,
		schoolID,
		globalSchoolYearID,
		dateRange,
	}: {
		districtID: number;
		schoolID: number;
		globalSchoolYearID: number;
		dateRange: DatePeriod | null;
	}): Observable<{
		id: AddWidgetResponse['id'];
		name: string;
		versionID: number;
		schoolStudentsNeedingSupportWidgetOptions: SchoolStudentsNeedingSupportWidgetOptions;
	}> {
		const widgetName = 'Students Needing Support';
		const options = getSchoolStudentsNeedingSupportInitOptions({districtID, schoolID, globalSchoolYearID, dateRange});

		return this.widgetsController
			.add({
				name: widgetName,
				globalSchoolYearID,
				dashboardWidgetOptions: {
					...AddWidgetBase.dashboarWidgetOptionsAllNullable,
					schoolStudentsNeedingSupportWidgetOptions: options,
				},
			})
			.pipe(
				map(({value: {id, versionID}}) => ({
					id,
					name: widgetName,
					versionID,
					schoolStudentsNeedingSupportWidgetOptions: options,
				})),
			);
	}
}
