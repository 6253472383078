import {SchoolSubject} from '@esgi/main/features/admins/subject-management';
import {useCallback} from 'react';
import {subjectsStore, useSubjects, SubjectLevel, Subject} from '@esgi/main/libs/school-admin-store';
import {isUndefined, useCancelableRequest} from '@esgi/ui';
import {SubjectType} from '@esgi/main/kits/common';
import {useDisposable} from '@esgi/core/service';
import {ManageSubjectsAndTestsController} from '@esgi/contracts/esgi';

export function useRearrangeSchoolSubjects() {
	const subjectsController = useDisposable(ManageSubjectsAndTestsController);

	const [, reorderSubjects] = useCancelableRequest(subjectsController.reorderSubjects);

	const [{data: subjectsFromStore}] = useSubjects();

	return useCallback(
		(schoolSubjects: SchoolSubject[]) => {
			const copiedSchoolSubjects = [...schoolSubjects];

			reorderSubjects(schoolSubjects.map(({id}) => id));

			subjectsStore().update?.((item: Subject) => {
				if (item.subjectType === SubjectType.Deployed && item.level === SubjectLevel.School) {
					const subjectID = copiedSchoolSubjects.shift()?.id;

					if (isUndefined(subjectID)) {
						return item;
					}

					const subjectFronStore = subjectsFromStore.find(({id}) => id === subjectID);

					if (isUndefined(subjectFronStore)) {
						return item;
					}

					return subjectFronStore;
				}

				return item;
			});
		},
		[reorderSubjects, subjectsFromStore],
	);
}
