import {isNull} from 'underscore';
import {useCallback, useEffect, useState} from 'react';
import {TrackModel} from '../../../../../types';
import {ViewMode} from './variant/view-mode';
import {OnTrackClick} from './types';

type Props = {
	tracks: TrackModel[];
	activeUserTrackId: TrackModel['trackID'];
	onOpenNoChangesConfirmation: (onCloseAlert: VoidFunction) => void;
	setActiveTrackId: (trackId: TrackModel['trackID']) => void;
};

export function MarkingPeriods({
	activeUserTrackId,
	tracks,
	onOpenNoChangesConfirmation,
	setActiveTrackId,
}: Props) {
	const [visibleTrack, setVisibleTrack] = useState<TrackModel | null>(null);

	const setVisibleTrackById = useCallback((trackId: TrackModel['trackID']) => {
		const activeTrack = tracks.find(({trackID}) => trackID === trackId) ?? tracks[0];

		setVisibleTrack(activeTrack ?? null);
	}, [tracks]);

	useEffect(() => {
		setVisibleTrackById(activeUserTrackId);
	}, [activeUserTrackId]);

	useEffect(() => {
		setVisibleTrack((currentVisibleTrack) => (
			tracks.find(({trackID}) => (
				currentVisibleTrack?.trackID === trackID) ?? tracks[0] ?? null
			)
		));
	}, [tracks]);

	const onTrackClick = useCallback<OnTrackClick>(({track, openNoChangesConfirmation}) => {
		if (openNoChangesConfirmation) {
			onOpenNoChangesConfirmation(() => setVisibleTrack({...track}));
			return;
		}

		setVisibleTrack({...track});
	}, [onOpenNoChangesConfirmation]);

	if (isNull(visibleTrack)) {
		return null;
	}

	return (
		<ViewMode
			tracks={tracks}
			visibleTrack={visibleTrack}
			activeUserTrackId={activeUserTrackId}
			setActiveTrackId={setActiveTrackId}
			onTrackClick={onTrackClick}
		/>
	);
}
