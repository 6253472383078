import {V2SessionDetailsYNController} from '@esgi/contracts/esgi';
import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, tap} from 'rxjs';
import {AnswerStateData, QuestionModel} from './types';
import {AnswerState, Student, Test} from '../../../../../types/common';

export class Service extends BaseService {
	public questions$ = new BehaviorSubject<QuestionModel[]>([]);

	private controllerYNSession = new V2SessionDetailsYNController();

	public init({testID, studentID}: {testID: Test['id']; studentID: Student['id']}) {
		return this.controllerYNSession
			.initCreate({
				studentID,
				testID,
			})
			.pipe(
				tap(({questions}) => {
					this.questions$.next(questions);
				}),
			);
	}

	public save({
		testDate,
		testID,
		studentID,
		notes,
		answersState,
	}: {
		testID: Test['id'];
		studentID: Student['id'];
		testDate: string;
		notes: string;
		answersState: AnswerStateData[];
	}) {
		const udaptedAnswerState: Record<AnswerState, number> = {
			[AnswerState.Incorrect]: 0,
			[AnswerState.Correct]: 1,
			[AnswerState.NotTested]: 2,
		};

		return this.controllerYNSession.create({
			testDate,
			testID,
			studentID,
			notes,
			listTsa: answersState.map(({answerState, questionID, comment}) => ({
				answerState: udaptedAnswerState[answerState],
				questionID,
				comment: comment as string,
			})),
		});
	}

	public override dispose() {
		this.controllerYNSession.dispose();
	}
}
