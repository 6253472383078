import {isNull} from 'underscore';
import {useMemo} from 'react';
import {LowestAchievementStudentData} from '../../../types/lowest-achievement';
import {StudentsSinglePeriodData} from '../types';
import {getFullName} from '@esgi/main/kits/common';
import {roundNumber} from '../utils/round-number';
import {GradeLevel} from '@esgi/main/features/admins/dashboard';

export function useStudentsOnePeriodItems({
	studentsData,
	getSchoolName,
	getGradeShortName,
}: {
	studentsData: LowestAchievementStudentData[];
	getSchoolName: (schoolID: number) => string;
	getGradeShortName: (gradeID: GradeLevel['id']) => string | null;
}) {
	return useMemo(() => {
		return studentsData.map<StudentsSinglePeriodData>(({student, teacher, avgResults}) => ({
			rowID: student.id,
			studentID: student.id,
			student: getFullName({
				firstName: student.firstName,
				lastName: student.lastName,
			}),
			teacher: !isNull(teacher)
				? getFullName({
						firstName: teacher.firstName,
						lastName: teacher.lastName,
					})
				: null,
			school: getSchoolName(student.schoolID),
			grade: getGradeShortName(student.gradeLevelID),
			avg: roundNumber(avgResults[0] ?? 0, {
				precise: 1,
			}),
		}));
	}, [getGradeShortName, getSchoolName, studentsData]);
}
