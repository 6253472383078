import {useMemo} from 'react';
import {RowDef} from './types';
import {unique} from 'underscore';
import {School, useSchools, useTeachers} from '@esgi/main/libs/admin-store';
import {useDisposable, useEndpointResult} from '@esgi/core/service';
import {V2DistrictAdminsPagesDataAnalyticsController} from '@esgi/contracts/esgi';

export function useData(): { ready: boolean, rows: RowDef[], schools: School[] } {
	const [{data: allSchools, loaded: schoolsLoaded}] = useSchools();
	const [{data: teachers, loaded: teachersLoaded}] = useTeachers();
	const analyticsController = useDisposable(V2DistrictAdminsPagesDataAnalyticsController);

	const analyticData = useEndpointResult(() => {
		if (teachersLoaded && teachers.length) {
			return analyticsController.teachers({teachersIDs: teachers.map(t => t.id)});
		}
	}, [teachers, teachersLoaded]);

	const {rows, schools} = useMemo(() => {
		const rows = teachers.map((teacher) => {
			const school = allSchools.find(school => school.id === teacher.schoolID);
			const analytic = analyticData?.value?.teachersAnalytics?.find(t => t.teacherId === teacher.id);
			return {
				id: teacher.id,
				firstName: teacher.firstName,
				lastName: teacher.lastName,
				userName: teacher.userName,
				email: teacher.email,
				school: school,
				classes: analytic?.classesCount,
				groups: analytic?.groupsCount,
				students: analytic?.studentsCount,
			} as RowDef;
		});

		return {rows, schools: unique(rows.map(t => t.school)).filter(Boolean)};
	}, [teachers, allSchools, analyticData]);

	return {
		ready: teachersLoaded && schoolsLoaded,
		rows,
		schools,
	};
}