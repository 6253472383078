import {Card, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	variants: {
		withDataMessagesInfoBox: {
			true: {
				gridTemplateRows: 'auto 1fr',
				gap: 125,
			},

			false: {
				gridTemplateRows: 'auto auto 1fr',
				gap: '$4',
			},
		},
	},
});

export const CardBody = styled(Card.Body, {
	display: 'grid',
	gap: '$6',
	alignItems: 'start',
	gridTemplateRows: 'auto 1fr',
});

export const Divider = styled(Card.Separator, {
	marginLeft: 'unset',
	marginRight: 'unset',
	width: '100%',
});
