import {ThemeColorKeys} from '@esgi/ui';
import {Color} from '../types';

export const chartColor: Record<
	Exclude<Color, Color.None>,
	{activeQuartileBarColor: ThemeColorKeys; nonActiveQuartileBarColor: ThemeColorKeys}
> = {
	[Color.Blue]: {
		activeQuartileBarColor: 'blue',
		nonActiveQuartileBarColor: 'blueBackground',
	},
	[Color.Yellow]: {
		activeQuartileBarColor: 'yellow',
		nonActiveQuartileBarColor: 'yellowBackground',
	},
	[Color.Red]: {
		activeQuartileBarColor: 'red',
		nonActiveQuartileBarColor: 'redBackground',
	},
	[Color.Purple]: {
		activeQuartileBarColor: 'purple',
		nonActiveQuartileBarColor: 'purpleBackground',
	},
	[Color.Green]: {
		activeQuartileBarColor: 'green',
		nonActiveQuartileBarColor: 'greenBackground',
	},
	[Color.Grey]: {
		activeQuartileBarColor: 'mediumContrast',
		nonActiveQuartileBarColor: 'mild',
	},
	[Color.Orange]: {
		activeQuartileBarColor: 'orange',
		nonActiveQuartileBarColor: 'orangeBackground',
	},
	[Color.Indigo]: {
		activeQuartileBarColor: 'indigo',
		nonActiveQuartileBarColor: 'indigoBackground',
	},
};
