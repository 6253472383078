import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {UserSettingsSchoolAdminController} from '@esgi/contracts/esgi';
import {StudentSort} from '@esgi/contracts/esgi/types/esgi.enums/student-sort';
import {IntegrationsClassLinkController, IntegrationsCleverController} from '@esgi/contracts/sso';
import {SettingsData} from './types';

export class SettingsService extends BaseService {
	public readonly loaded$ = new BehaviorSubject(false);

	public readonly settingsData$ = new BehaviorSubject<SettingsData>(null);

	private readonly settingsController = new UserSettingsSchoolAdminController();
	private readonly cleverController = new IntegrationsCleverController();
	private readonly classLinkController = new IntegrationsClassLinkController();

	public init() {
		this.settingsController.init().subscribe((response) => {
			this.settingsData$.next(response);
			this.loaded$.next(true);
		});
	}

	public saveSettings(data: Partial<SettingsData>) {
		this.settingsData$.next({...this.settingsData$.value, ...data});

		return this.settingsController.update({
			showExpiredUsers: this.settingsData$.value.showExpiredUsers,
			sortBy: this.settingsData$.value.sortBy as unknown as StudentSort,
			timeZone: this.settingsData$.value.selectedTimeZone.id,
		});
	}

	public unlinkCleverAccount() {
		this.cleverController.linkingUnlink()
			.subscribe({
				next: () => {
					this.settingsData$.next({...this.settingsData$.value, isCleverAccountLinked: false});
				},
			});
	}

	public unlinkClassLinkAccount() {
		return this.classLinkController.linkingUnlink()
			.subscribe({
				next: () => {
					this.settingsData$.next({...this.settingsData$.value, isOneClickAccountLinked: false});
				},
			});
	}

	public override dispose() {
		super.dispose();
		this.settingsController.dispose();
		this.cleverController.dispose();
		this.classLinkController.dispose();
	}
}