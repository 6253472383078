import {ComponentProps, ComponentPropsWithoutRef, forwardRef} from 'react';
import {Text} from '@esgi/ui/typography';
import {CheapBox} from './cheap-box';

type Props = Omit<ComponentPropsWithoutRef<typeof CheapBox>, 'children'> & {
	cheapTitle: string;
	cheapTitleColor: NonNullable<ComponentProps<typeof Text>['color']>;
};

export const BaseCheap = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'cheap', css = {}, color, cheapTitle, cheapTitleColor, ...props}, forwaredRef) => (
		<CheapBox dataCy={dataCy} css={css} color={color} ref={forwaredRef} {...props}>
			<Text size='medium' bold data-cy={`${dataCy}-cheap-title`} color={cheapTitleColor}>
				{cheapTitle}
			</Text>
		</CheapBox>
	),
);
