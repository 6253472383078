import {Page, DistrictReportPanel} from '../../../../_kit';
import {RowDef} from '../../../types';
import {useDataPageContext} from '../../../../layout/context';
import {
	AddEntitiesIntoFolder,
	FolderType,
} from '@esgi/main/features/admins/data';
import {BottomPanel} from './bottom-panel';
import {noop} from 'underscore';
import {useFlag} from '@esgi/ui';
import {addButtonDisabledTooltipMessage} from '../../constants';
import {SingleItemExpandablePanel} from './single-item-expandable-panel';

export function Content() {
	const {entityFolders, districtName} = useDataPageContext();
	const {selectedItems, api, tableRows} = Page.useContext<RowDef>();

	const [
		showAddEntitiesIntoFolder,
		openAddEntitiesIntoFolder,
		closeAddEntitiesIntoFolder,
	] = useFlag();

	return (
		<>
			<Page.Header.Base
				onAddButtonClick={noop}
				addButtonDisabled
				addButtonDisabledTooltipMessage={addButtonDisabledTooltipMessage}
			/>
			<Page.Body.Base />

			<SingleItemExpandablePanel
				gridApi={api}
				onFolderAddClick={openAddEntitiesIntoFolder}
				tableRowsLength={tableRows.length}
			/>
			<BottomPanel onFolderAddClick={openAddEntitiesIntoFolder} />
			<DistrictReportPanel
				api={api}
				title={districtName}
				tableRowsLength={tableRows.length}
			/>

			{showAddEntitiesIntoFolder && (
				<AddEntitiesIntoFolder
					addEntityIDs={selectedItems.map(({id}) => id)}
					folders={(entityFolders ?? []).map(({id, name}) => ({id, name}))}
					initialFolderID={null}
					onCloseAlert={closeAddEntitiesIntoFolder}
					folderType={FolderType.Schools}
				/>
			)}
		</>
	);
}
