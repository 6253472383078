import {Avatar} from '@esgi/ui/avatar';
import {AlertHeaderStyled, avatarRootCss} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {getFullName, getInitials, OneLinedText} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';
import {Student} from '../../../../types/common';

type Props = {
	student: Student;
	onCloseAlert: VoidFunction;
};

export function AlertHeader({student: {firstName, lastName, photoUrl}, onCloseAlert}: Props) {
	return (
		<AlertHeaderStyled withBacklight={false} onCloseIconClick={onCloseAlert}>
			<GridBox flow='column' align='center' justify='between' gap='4'>
				<GridBox flow='column' gap='3' align='center' justify='start'>
					<Avatar.Root size='xs' css={avatarRootCss}>
						<Avatar.Image src={photoUrl} />
						<Avatar.Fallback>
							<Text size='small' color='neutral16'>
								{getInitials({
									firstName,
									lastName,
								})}
							</Text>
						</Avatar.Fallback>
					</Avatar.Root>

					<GridBox flow='column' gap='2' align='center' justify='start'>
						<OneLinedText size='small' color='primary' dataCy='fullname'>
							{getFullName({
								firstName,
								lastName,
							})}
						</OneLinedText>
						<Text size='small' color='base' data-cy='session-details-title'>
							Session Details
						</Text>
						<Text size='small' color='primary' data-cy='manually-title'>
							Manually Add a Session
						</Text>
					</GridBox>
				</GridBox>
			</GridBox>
		</AlertHeaderStyled>
	);
}
