import {forwardRef} from 'react';
import {BaseCheap} from '../components/base-cheap';
import {SubjectLevelCheapProps} from './types';
import {stockSubjectPrefix} from '@esgi/main/kits/common';

export const Stock = forwardRef<HTMLDivElement, SubjectLevelCheapProps>(
	({dataCy = 'subject-cheap-stock', css = {}, ...props}, forwaredRef) => (
		<BaseCheap
			dataCy={dataCy}
			css={css}
			color='secondaryMuted'
			ref={forwaredRef}
			cheapTitle={stockSubjectPrefix}
			cheapTitleColor='secondary'
			{...props}
		/>
	),
);
