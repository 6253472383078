import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const ErrorBox = styled(FlexBox, {
	paddingTop: 4,
	paddingBottom: 4,
	paddingInline: 8,
	borderRadius: 3,
	backgroundColor: '$negativeBackground',
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$negativeMuted',
	justifyContent: 'center',
});

export const CenteredText = styled(Text, {
	textAlign: 'center',
});
