import {OptionItem} from '@esgi/main/features/teacher/home';
import {NameTitle} from '../../../../../types';
import {userNameTitleTranscript} from '../../../constants';

const nameTitleList: NameTitle[] = [NameTitle.DR, NameTitle.MISS, NameTitle.MR, NameTitle.MRS, NameTitle.MS];

export const nameTitleOptions = nameTitleList.map<OptionItem<NameTitle>>((value) => ({
	value,
	label: userNameTitleTranscript[value],
}));
