import {Alert} from '@esgi/ui/alert';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		gap: '$5',
		maxWidth: 460,
	},
};

export const AlertHeader = styled(Alert.Header, {
	height: 64,

	'& > div': {
		gridTemplateColumns: '1fr auto',
		gap: '$4',

		'& > div': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			color: '$negative',
		},
	},
});

export const SelectedItemsBox = styled(FlexBox, {
	alignItems: 'center',
	gap: 6,
});

export const AlertBody = styled(Alert.Body, {
	display: 'grid',
});

export const AlertBodyContent = styled(GridBox, {
	width: '100%',
	minHeight: 48,
	gridAutoFlow: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '$4',
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	paddingTop: '$2',
	paddingBottom: '$2',
	paddingInline: '$4',
});

export const RemoveIconBox = styled(FlexBox, {
	'& > svg': {
		width: 24,
		height: 24,

		'& path': {
			fill: '$negative',
		},
	},
});

export const FolderNameBox = styled(GridBox, {
	alignItems: 'center',
	gap: 6,
	gridAutoFlow: 'column',

	'& > svg': {
		width: 24,
		height: 24,

		'& path': {
			fill: '$mediumContrast',
		},
	},
});

export const AlertFooter = styled(Alert.Footer, {
	paddingTop: 'unset',
});
