import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {FlexBox, Box} from '@esgi/ui/layout';
import {SkeletonStylesWithBorders} from '@esgi/ui/skeleton';

export const StudentInfo = styled(Box, {
	paddingLeft: '20px',
});

export const StudentName = styled(Text, {
	marginBottom: '4px',
	color: '$neutral40',

	variants: {
		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});

export const StudentInfoRow = styled(FlexBox, {
	marginTop: 4,

	variants: {
		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});

export const StudentInfoRowValue = styled(Text, {
	color: '$neutral63',
	marginLeft: '2px',
});

export const Label = styled(Text, {
	color: '$neutral40',

	variants: {
		color: {
			'lowContrast': {
				color: '$lowContrast',
			},

			'neutral24': {
				color: '$neutral24',
			},
		},
	},
});