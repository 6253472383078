import {createContext, useContext} from 'react';
import {isNull} from 'underscore';

export type SelectContextValue = {
	activeValue: string | undefined;
};

export const SelectContext = createContext<SelectContextValue | null>(null);

export function useSelectContext() {
	const context = useContext(SelectContext);

	if (isNull(context)) {
		throw new Error('useSelectContext is null');
	}

	return context;
}
