import {withErrorHandling} from '@esgillc/ui-kit/core';
import {lazyComponent} from '@esgi/core/react';

export * from './layout';

export const DataSchools = withErrorHandling(lazyComponent(() => import('./schools')));
export const DataTeachers = withErrorHandling(lazyComponent(() => import('./teachers')));
export const DataSpecialists = withErrorHandling(lazyComponent(() => import('./specialists')));
export const DataClasses = withErrorHandling(lazyComponent(() => import('./classes')));
export const DataGroups = withErrorHandling(lazyComponent(() => import('./groups')));
export const DataSpecialistGroups = withErrorHandling(lazyComponent(() => import('./specialist-groups')));
export const DataStudents = withErrorHandling(lazyComponent(() => import('./students')));
