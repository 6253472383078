import {AddTestsDrawerTestModel, TestPanelTestModel} from '@esgi/main/features/admins/subject-management';
import {TestContentArea} from '@esgi/main/kits/common';
import {Dispatch, SetStateAction, useCallback, useMemo} from 'react';

type Parameters = {
	selectedTests: AddTestsDrawerTestModel[];
	setSelectedTests: Dispatch<SetStateAction<AddTestsDrawerTestModel[]>>;
};

export function useTestsPanel({selectedTests, setSelectedTests}: Parameters) {
	const adaptedTestsPanelTests = useMemo(
		() =>
			selectedTests.map<TestPanelTestModel>(({id, name, color, testType, contentArea}) => ({
				id,
				name,
				color,
				type: testType,
				contentArea: contentArea as TestContentArea,
			})),
		[selectedTests],
	);

	const onAddTests = useCallback(
		(newTests: AddTestsDrawerTestModel[]) => {
			setSelectedTests((currentState) => [...currentState, ...newTests]);
		},
		[setSelectedTests],
	);

	const onTestDeleteClick = useCallback(
		(removedTestID: number) => {
			setSelectedTests((currentState) => currentState.filter(({id}) => id !== removedTestID));
		},
		[setSelectedTests],
	);

	const onRearrangeTests = useCallback(
		(reorderedTests: TestPanelTestModel[]) => {
			setSelectedTests((currentState) => {
				const shuffledTests = reorderedTests
					.map(({id}) => currentState.find((test) => id === test.id))
					.filter((item): item is AddTestsDrawerTestModel => !!item);

				return shuffledTests;
			});
		},
		[setSelectedTests],
	);

	return {
		adaptedTestsPanelTests,
		onRearrangeTests,
		onTestDeleteClick,
		onAddTests,
	};
}
