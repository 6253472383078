import {useEffect, useMemo} from 'react';
import {BoxplotCompareVariant} from '../types';
import {PerformanceWidgetViewType, ToggleGroupItemModel} from '@esgi/main/features/admins/dashboard';
import {ClassModel} from '../../../../../../types';
import {CompareDates, CompareSchools, Datepicker} from '@esgi/ui/icons';
import {CommonForm} from '../forms-data/types';

export function useBoxplotCompareVariantItems({
	allClassesList,
	commonForm,
}: {
	allClassesList: ClassModel[];
	commonForm: CommonForm;
}) {
	useEffect(() => {
		const {viewType, boxplotCompareVariant} = commonForm.value;
		if (
			allClassesList.length <= 1 &&
			viewType === PerformanceWidgetViewType.Candles &&
			boxplotCompareVariant === BoxplotCompareVariant.CompareTwoClasses
		) {
			commonForm.controls.boxplotCompareVariant.value = BoxplotCompareVariant.None;
		}
	}, [allClassesList, commonForm.controls.boxplotCompareVariant, commonForm.value]);

	return useMemo<ToggleGroupItemModel<BoxplotCompareVariant>[]>(
		() => [
			{
				value: BoxplotCompareVariant.SinglePeriod,
				label: 'Single Period',
				Icon: Datepicker,
			},
			{
				value: BoxplotCompareVariant.CompareTwoPeriods,
				label: 'Two Periods',
				Icon: CompareDates,
			},
			{
				value: BoxplotCompareVariant.CompareTwoClasses,
				label: 'Two Classes',
				Icon: CompareSchools,
				disabled: allClassesList.length <= 1,
			},
		],
		[allClassesList],
	);
}
