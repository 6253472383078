import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Counter} from './counter.styled';

export const NavigationItem = styled(FlexBox, {
	userSelect: 'none',
	width: '232px',
	height: '40px',
	paddingTop: 6,
	paddingBottom: 6,
	paddingInline: 12,
	gap: 6,
	cursor: 'pointer',
	color: '$base',
	backgroundColor: '$surface',
	transition: 'color .3s, background-color .3s, border-color .3s',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',
	borderRadius: 6,
	alignItems: 'center',
	position: 'relative',

	variants: {
		active: {
			true: {
				backgroundColor: '$primaryBackground',
				borderColor: '$primaryHighlight',
				color: '$primary',

				[`& > ${Counter}`]: {
					backgroundColor: '$primarySurface',
					borderColor: '$primaryMuted',
					color: '$primaryVivid',
				},
			},
		},

		disableHover: {
			false: {
				'&:hover': {
					color: '$secondary',
					backgroundColor: '$secondaryBackground',
					borderColor: '$secondaryMuted',

					[`& > ${Counter}`]: {
						backgroundColor: '$secondarySurface',
						borderColor: '$secondaryMuted',
						color: '$secondary',
					},
				},
			},
		},
	},

	defaultVariants: {
		disableHover: false,
	},
});
