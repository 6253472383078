import {Alert} from '@esgi/ui/alert';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const alertRootCss: CSS = {
	'& [data-alert-content]': {
		gap: '$5',
		maxWidth: 460,
	},
};

export const AlertBody = styled(Alert.Body, {
	display: 'grid !important',
});

export const AlertFooter = styled(Alert.Footer, {
	paddingTop: 'unset',
});
