import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {OneLinedText} from '../one-lined-text';

export const Container = styled(FlexBox, {
	width: '100%',
	height: '100%',
	alignItems: 'center',
});

export const Text = styled(OneLinedText, {
	transition: 'color .3s',
});
