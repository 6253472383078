import {Edit, FolderAdd, School} from '@esgi/ui/icons';
import {ActionPanel, RunReportButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {DeleteSchoolsButton} from '../../../../../../components/delete-schools-button';
import {Button, Tooltip, useFlag} from '@esgi/ui';
import {useClasses} from '@esgi/main/libs/admin-store';
import {useCallback, useMemo} from 'react';
import {
	AddToFolderButton,
	useNavigateToClassesPage,
	useNavigateToSpecialistsPage,
	useNavigateToStudentsPage,
	useNavigateToTeachersPage,
} from '@esgi/main/features/admins/data';
import {SchoolReportsDialog} from '../school-reports-dialog';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {OpenPanelButton} from '../../../../../../components/open-panel-button';

type Props = {
	selectedRow: RowDef;
	onFolderAddClick: VoidFunction;
	isPanelExpanded: boolean;
};

export function QuickPanel({selectedRow, onFolderAddClick, isPanelExpanded}: Props) {
	const [showSchoolReports, onReportClickHandler, closeSchoolReports] = useFlag();
	const [{data: allClasses}] = useClasses();

	const classes = useMemo(() => allClasses.filter((c) => c.schoolID === selectedRow.id), [selectedRow, allClasses]);

	const goToTeacherPage = useNavigateToTeachersPage();
	const goToSpecialistsPage = useNavigateToSpecialistsPage();
	const goToClassesPage = useNavigateToClassesPage();
	const goToStudentsPage = useNavigateToStudentsPage();

	const navigateToTeachers = useCallback(() => {
		goToTeacherPage({
			school: [selectedRow.id],
		});
	}, [goToTeacherPage, selectedRow]);

	const navigateToSpecialists = useCallback(() => {
		goToSpecialistsPage({
			schools: [selectedRow.id],
		});
	}, [goToSpecialistsPage, selectedRow]);

	const navigateToClasses = useCallback(() => {
		goToClassesPage({
			school: [selectedRow.id],
		});
	}, [goToClassesPage, selectedRow]);

	const navigateToStudents = () => {
		goToStudentsPage({
			school: [selectedRow.id],
		});
	};

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<School />
							<Text size='large' color='highContrast'>
								{selectedRow.name}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteSchoolsButton selectionType='single' />
							<ActionPanel.ActionButtonDivider />

							<AddToFolderButton onClick={onFolderAddClick}>
								<FolderAdd />
							</AddToFolderButton>
							<ActionPanel.ActionButtonDivider />

							<Tooltip>
								<Tooltip.Trigger>
									<Button.Icon disabled>
										<Edit />
									</Button.Icon>
								</Tooltip.Trigger>
								<Tooltip.Content>
									<Text size='large' color='tertiary'>
										To edit the school, please contact Customer Support support@esgisoftware.com
									</Text>
								</Tooltip.Content>
							</Tooltip>
							<RunReportButton title='School Reports' onClick={onReportClickHandler} />
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<ActionPanel.Navigation.Container>
							<ActionPanel.Navigation.Card onClick={navigateToTeachers} title='Teachers' count={selectedRow.teachers} />
							<ActionPanel.Navigation.Card
								onClick={navigateToSpecialists}
								title='Specialists'
								count={selectedRow.specialists}
							/>
							<ActionPanel.Navigation.Card onClick={navigateToClasses} title='Classes' count={classes.length} />
							<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
						</ActionPanel.Navigation.Container>

						<OpenPanelButton canExpandPanel={!!selectedRow.students} />
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showSchoolReports && <SchoolReportsDialog school={selectedRow} onClose={closeSchoolReports} />}
		</>
	);
}
