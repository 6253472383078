import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const HeaderBox = styled(Box, {
	paddingTop: '$4',
	paddingBottom: '$4',
	paddingInline: 20,
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	borderBottomColor: '$border',
	backgroundColor: '$background',
});
