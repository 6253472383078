import {ToggleGroup} from '@esgi/ui/controls';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {useTestPanelContext} from '../../../../context';
import {ContentStyled} from './index.styled';

type Props = Omit<ComponentPropsWithoutRef<typeof ToggleGroup.Content>, 'skeleton'>;

export const Content = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-panel-toggle-group-content', css = {}, children, ...props}, forwardedRef) => {
		const {skeleton} = useTestPanelContext();

		return (
			<ContentStyled dataCy={dataCy} css={css} ref={forwardedRef} {...props} skeleton={skeleton}>
				{children}
			</ContentStyled>
		);
	},
);
