import {isNull} from 'underscore';

export function isTwoDateEqual(date1: string | null, date2: string | null) {
	if (isNull(date1) && !isNull(date2)) {
		return false;
	}

	if (!isNull(date1) && isNull(date2)) {
		return false;
	}

	if (isNull(date1) && isNull(date2)) {
		return true;
	}

	const dateTime1 = new Date(date1!).getTime();
	const dateTime2 = new Date(date2!).getTime();

	return dateTime1 === dateTime2;
}
