import {Dispatch, SetStateAction} from 'react';
import {
	LowestAchievementWidgetOptions,
	LowestAchievementWidgetUpdatedValue,
} from '../../../../types/lowest-achievement';
import {DatePickerData} from '@esgi/main/kits/admin';
import {Color} from '@esgi/main/features/admins/dashboard';

export enum DistrictCutoff {
	None = 'None',
	Five = 'Five',
	Ten = 'Ten',
	Fifteen = 'Fifteen',
	Twenty = 'Twenty',
	TwentyFive = 'TwentyFive',
}

export type AdaptedWidgetOptions = Omit<LowestAchievementWidgetOptions, 'districtCutoff'> & {
	districtCutoff: DistrictCutoff;
};

export type DynamicFormName = 'studentsSinglePeriod' | 'studentsTwoPeriods' | 'schools';

export enum PeriodsVariant {
	None = 'None',
	SinglePeriod = 'SinglePeriod',
	TwoPeriods = 'TwoPeriods',
}

export type Period = DatePickerData & {
	color: Color;
};

export type DynamicFormProps<P = unknown> = P & {
	setIsDynamicFormDataTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataValid: Dispatch<SetStateAction<boolean>>;
	onCurrentDynamicFormNameChange: Dispatch<DynamicFormName>;
};

export type CommonUpdatedValue = Pick<LowestAchievementWidgetUpdatedValue, 'id' | 'name'> & {
	options: Pick<
		LowestAchievementWidgetUpdatedValue['options'],
		| 'districtID'
		| 'globalSchoolYearID'
		| 'districtCutoff'
		| 'contentAreaIDs'
		| 'subjectIDs'
		| 'gradeLevelIDs'
		| 'viewType'
	>;
};

export type CommonSelectedArraysEntity = Pick<
	LowestAchievementWidgetUpdatedValue['selectedArraysEntity'],
	'contentAreaIDs' | 'gradeLevelIDs' | 'subjectIDs'
>;
