import {TestContentArea, TestType} from '@esgi/main/kits/common';
import {createContext, useContext} from 'react';
import {isNull} from 'underscore';

export type TestCardContextValue = {
	contentArea: TestContentArea;
	testColor: string;
	testType: TestType;
	isCardHovered: boolean;
};

export const TestCardContext = createContext<TestCardContextValue | null>(null);

export function useTestCardContext() {
	const context = useContext(TestCardContext);

	if (isNull(context)) {
		throw new Error('useTestCardContext is null');
	}

	return context;
}
