import * as React from 'react';
import type {SVGProps} from 'react';

export function Online(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				fillRule='evenodd'
				d='M16 13a3 3 0 1 1 0 6 3 3 0 0 1 0-6Z'
				clipRule='evenodd'
			/>
		</svg>
	);
}
