import * as React from 'react';
import type {SVGProps} from 'react';

export function KeyDiscard(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				d='M12 6.5C12 6.22386 11.775 5.99663 11.5011 6.03158C9.66574 6.26575 8.25 7.77399 8.25 9.6C8.25 10.8738 8.93562 12.0168 10.1244 12.7128L10.125 14.625L9.22625 15.0564C9.015 15.1578 8.875 15.3726 8.875 15.6V17.4C8.875 17.7312 9.155 18 9.5 18H13.25C13.595 18 13.875 17.7312 13.875 17.4L13.88 12.711C14.8757 12.1469 15.552 11.1775 15.713 10.0982C15.7537 9.8251 15.5261 9.6 15.25 9.6H15C14.7239 9.6 14.5055 9.82654 14.4454 10.0961C14.2788 10.8437 13.7421 11.4786 12.9962 11.7936C12.7694 11.8896 12.625 12.0996 12.625 12.3372V16.8H10.125L10.125 15.975L11.0238 15.5436C11.235 15.4422 11.375 15.2274 11.375 15L11.375 12.3372C11.375 12.0996 11.2306 11.8896 11.0038 11.7936C10.0975 11.4108 9.5 10.5558 9.5 9.6C9.5 8.43798 10.3604 7.46874 11.5029 7.24744C11.774 7.19493 12 6.97614 12 6.7V6.5Z'
				fill='#333333'
			/>
			<path
				d='M12.9256 8.06976C12.6915 8.30395 12.6915 8.68409 12.9256 8.91829C13.1598 9.15248 13.54 9.15248 13.7742 8.91829L15.047 7.64549L16.3198 8.91829C16.554 9.15248 16.9341 9.15248 17.1683 8.91829C17.4025 8.68409 17.4025 8.30395 17.1683 8.06976L15.8955 6.79697L17.1683 5.52417C17.4025 5.28998 17.4025 4.90984 17.1683 4.67565C16.9341 4.44145 16.554 4.44145 16.3198 4.67565L15.047 5.94844L13.7742 4.67565C13.54 4.44145 13.1598 4.44145 12.9256 4.67565C12.6915 4.90984 12.6915 5.28998 12.9256 5.52417L14.1984 6.79697L12.9256 8.06976Z'
				fill='#333333'
			/>
		</svg>
	);
}
