import {FolderAdd, Plus} from '@esgi/ui/icons';
import {FormType} from '../../types';
import {Item} from './types';

export const items: Item[] = [
	{
		value: FormType.ExistingFolder,
		label: 'Add to Existing Folder',
		Icon: FolderAdd,
	},
	{
		value: FormType.NewFolder,
		label: 'New Folder',
		Icon: Plus,
	},
];
