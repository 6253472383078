import {FlexBox} from '@esgi/ui/layout';
import {ComponentProps, useMemo} from 'react';
import {ColDef} from 'ag-grid-community';
import {CellType} from '../types';
import {isUndefined} from '@esgi/ui/utils';
import {isNumericType} from '../helpers/is-numeric-type';

function isRightAlignedType(type: string) {
	return type === CellType.Numeric || type === CellType.RightAligned;
}

export function useCellContentAlign(colDef: ColDef | undefined) {
	return useMemo<NonNullable<ComponentProps<typeof FlexBox>['justify']>>(() => {
		if (isUndefined(colDef)) {
			return 'start';
		}

		const colDefType = colDef.type;

		if (Array.isArray(colDefType) && colDefType.some((type) => isRightAlignedType(type) || isNumericType(type))) {
			return 'end';
		}

		if (typeof colDefType === 'string' && (isRightAlignedType(colDefType) || isNumericType(colDefType))) {
			return 'end';
		}

		return 'start';
	}, [colDef]);
}
