import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	justifyItems: 'center',
	justifySelf: 'center',
	textAlign: 'center',
	gap: 6,
	borderRadius: 6,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$surface',
	paddingTop: 12,
	paddingBottom: 12,
	paddingLeft: 20,
	paddingRight: 20,
});
