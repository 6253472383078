import {Requiered} from '@esgi/ui';
import {Input} from '@esgi/ui/form-controls';
import {ElementStatus, useFormElementContext} from '@esgillc/ui-kit/form';
import {getError} from '../../utils';
import {StyledContainer} from './index.styled';

interface Props {
	name: string;
	required?: boolean;
}

export function InputField({name, required}: Props) {
	const state = useFormElementContext();

	const isAsteriskVisible = required && state.status === ElementStatus.untouched;

	return (
		<StyledContainer>
			<Input.CleanableIndicator placeholder={name} error={getError(name, state)} />
			{isAsteriskVisible && (
				<Requiered />
			)}
		</StyledContainer>
	);
}