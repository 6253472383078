import {DemographicGroupType, PerformanceData} from '@esgi/main/features/admins/dashboard';
import {
	DemographicsPerformanceMetricData,
	DemographicsPerformanceMetricDataContract,
} from '@esgi/main/features/district-admin/dashboard';
import {isNull} from 'underscore';

export function parseDemographicsPerformanceData(
	data: DemographicsPerformanceMetricDataContract,
): DemographicsPerformanceMetricData | null {
	if (isNull(data)) {
		return null;
	}

	return {
		...data,
		demographicGroup: data.demographicGroup as unknown as DemographicGroupType,
		demographicGroupData: data.demographicGroupData.map(({data, ...groupData}) => ({
			...groupData,
			data: data as Record<number, PerformanceData>,
		})),
	};
}
