import {ChangeEvent, useCallback, useState} from 'react';
import {ElementStatus, useFormElementContext} from '@esgi/ui/form';
import {Input} from '@esgi/ui/controls';

type Props = {
	placeholder: string;
};

export function FormInputPercantage({placeholder}: Props) {
	const {status, setValue, value, setRef} = useFormElementContext(
		{
			string: true,
			number: true,
		},
		FormInputPercantage,
	);

	const [isHighlightInputFocused, setIsHighlightInputFocused] = useState(false);

	const handleUpdateScoreValue = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const fullValue = event.target.value;

			const value = fullValue.replace('%', '').trim();

			const onlyNumberRegex = /^\d+$/;

			if (value === '' || onlyNumberRegex.test(value)) {
				if (value === '') {
					setValue('');

					return;
				}

				const numberedValue = Number(value);

				setValue(numberedValue);
			}
		},
		[setValue],
	);

	const handleHighlightInputFocus = useCallback(() => {
		setIsHighlightInputFocused(true);
	}, []);

	const handleHighlightInputBlur = useCallback(() => {
		setIsHighlightInputFocused(false);
	}, []);

	return (
		<Input
			placeholder={placeholder}
			value={isHighlightInputFocused || value === '' ? value : `${value}%`}
			onChange={handleUpdateScoreValue}
			error={status === ElementStatus.invalid}
			disabled={status === ElementStatus.disabled}
			onBlur={handleHighlightInputBlur}
			onFocus={handleHighlightInputFocus}
			ref={setRef}
		/>
	);
}
