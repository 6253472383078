import * as React from 'react';
import type {SVGProps} from 'react';

export function Repeat(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 32 32'
			{...props}
		>
			<path
				fill='#333'
				d='M17 9a7 7 0 1 1-6.064 10.5 1 1 0 0 0-1.731 1 9 9 0 1 0 1.403-10.836c-.399.402-1 1.086-1.484 1.647L9 11.455V9a1 1 0 0 0-2 0v5a1 1 0 0 0 1 1h5a1 1 0 1 0 0-2h-2.69l.328-.382c.49-.568 1.048-1.2 1.39-1.546A6.975 6.975 0 0 1 17 9Z'
			/>
		</svg>
	);
}
