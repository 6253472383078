import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	gridTemplateColumns: 'repeat(3, auto)',
	gap: '$2',
	alignItems: 'center',
	justifyContent: 'start',
	color: '$base',

	'&:hover': {
		color: '$secondary',
	},

	variants: {
		clicable: {
			true: {
				cursor: 'pointer',
			},
		},
	},
});
