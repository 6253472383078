import {Students, StudentsList} from '@esgi/ui/icons';
import {ToggleGroupItemModel, WidgetFormSelectItem} from '@esgi/main/features/admins/dashboard';
import {SchoolStudentsNeedingSupportWidgetViewType} from '../../../../../../types/students-needing-support';
import {SchoolCutoff} from '../../types';

export const viewTypeItems: ToggleGroupItemModel<SchoolStudentsNeedingSupportWidgetViewType>[] = [
	{
		value: SchoolStudentsNeedingSupportWidgetViewType.Students,
		label: 'Students',
		Icon: Students,
	},
	{
		value: SchoolStudentsNeedingSupportWidgetViewType.Classes,
		label: 'Classes',
		Icon: StudentsList,
	},
];

export const schoolCutoffItems: WidgetFormSelectItem<SchoolCutoff>[] = [
	{
		value: SchoolCutoff.Five,
		label: 'Lowest 5%',
	},
	{
		value: SchoolCutoff.Ten,
		label: 'Lowest 10%',
	},
	{
		value: SchoolCutoff.Fifteen,
		label: 'Lowest 15%',
	},
	{
		value: SchoolCutoff.Twenty,
		label: 'Lowest 20%',
	},
	{
		value: SchoolCutoff.TwentyFive,
		label: 'Lowest 25%',
	},
];
