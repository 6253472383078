import {CustomHeaderProps} from 'ag-grid-react';
import {useCallback} from 'react';
import {dispatchAppEvent} from '@esgillc/events';
import {SettingUpPanelButtonClickedEvent} from '../../events';
import {Button} from '@esgi/ui';
import {Container} from './index.styled';

export function HeaderCell(props: CustomHeaderProps) {
	const onClick = useCallback(
		() =>
			dispatchAppEvent(SettingUpPanelButtonClickedEvent, new SettingUpPanelButtonClickedEvent(props.api.getGridId())),
		[props.api],
	);

	return (
		<Container>
			<Button.Icon withBackgroundHover onClick={onClick}>
				<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M6.74878 17.25C6.33478 17.25 5.99878 16.9142 5.99878 16.5C5.99878 16.0858 6.33478 15.75 6.74878 15.75H14.2488C14.6628 15.75 14.9988 16.0858 14.9988 16.5C14.9988 16.9142 14.6628 17.25 14.2488 17.25H6.74878ZM9.74878 8.25C9.33478 8.25 8.99878 7.91422 8.99878 7.5C8.99878 7.08577 9.33478 6.75 9.74878 6.75L17.2488 6.75C17.6628 6.75 17.9988 7.08577 17.9988 7.5C17.9988 7.91422 17.6628 8.25 17.2488 8.25L9.74878 8.25ZM6.74878 12.75C6.33478 12.75 5.99878 12.4142 5.99878 12C5.99878 11.5858 6.33478 11.25 6.74878 11.25H8.99879C9.41279 11.25 9.74879 11.5858 9.74879 12C9.74879 12.4142 9.41279 12.75 8.99879 12.75H6.74878ZM14.9988 12.75C14.5848 12.75 14.2488 12.4142 14.2488 12C14.2488 11.5858 14.5848 11.25 14.9988 11.25L17.2488 11.25C17.6628 11.25 17.9988 11.5858 17.9988 12C17.9988 12.4142 17.6628 12.75 17.2488 12.75L14.9988 12.75Z'
						fill='#333333'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M16.4988 15.75C16.4988 15.336 16.8346 15 17.2488 15C17.663 15 17.9988 15.336 17.9988 15.75V17.25C17.9988 17.664 17.663 18 17.2488 18C16.8346 18 16.4988 17.664 16.4988 17.25V15.75ZM5.99878 6.74999C5.99878 6.33599 6.33456 5.99999 6.74878 5.99999C7.16301 5.99999 7.49878 6.33599 7.49878 6.74999L7.49878 8.25C7.49878 8.664 7.16301 9 6.74878 9C6.33456 9 5.99878 8.664 5.99878 8.25L5.99878 6.74999ZM11.2488 11.25C11.2488 10.836 11.5846 10.5 11.9988 10.5C12.413 10.5 12.7488 10.836 12.7488 11.25V12.75C12.7488 13.164 12.413 13.5 11.9988 13.5C11.5846 13.5 11.2488 13.164 11.2488 12.75V11.25Z'
						fill='#333333'
					/>
				</svg>
			</Button.Icon>
		</Container>
	);
}
