import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {PropsWithChildren} from 'react';

type Props = PropsWithChildren<{
	tooltipLabel: string;
}>;

export function TooltipedButton({tooltipLabel, children}: Props) {
	return (
		<Tooltip>
			<Tooltip.Trigger>{children}</Tooltip.Trigger>
			<Tooltip.Content variant='secondary'>
				<Text size='medium' color='mediumContrast' font='mono'>
					{tooltipLabel}
				</Text>
			</Tooltip.Content>
		</Tooltip>
	);
}
