import {forwardRef} from 'react';
import {BaseCheap} from '../components/base-cheap';
import {SubjectLevelCheapProps} from './types';
import {subjectPrefixByLevel, SubjectLevel} from '@esgi/main/kits/common';

export const Teacher = forwardRef<HTMLDivElement, SubjectLevelCheapProps>(
	({dataCy = 'subject-cheap-teacher', css = {}, ...props}, forwaredRef) => (
		<BaseCheap
			dataCy={dataCy}
			css={css}
			color='border'
			ref={forwaredRef}
			cheapTitle={subjectPrefixByLevel[SubjectLevel.Teacher]}
			cheapTitleColor='highContrast'
			{...props}
		/>
	),
);
