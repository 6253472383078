import {Dispatch, PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import {isUndefined, Skeletonable} from '@esgi/ui';
import {Tabs} from '../../tabs';
import {SubjectPanelContext, SubjectPanelContextValue} from '../context';
import {TabID, Entities} from '../types/section';
import {SubjectModel} from '../types/subject';

type Props = PropsWithChildren<
	Skeletonable & {
		entities: Entities;
		initialTab?: TabID;
		onActiveTabChanged?: Dispatch<TabID | null>;
		selectedSubjectID?: SubjectModel['id'] | undefined | null;
		onSelectedSubjectIDChanged?: Dispatch<SubjectModel['id'] | null>;
		showSubjectsCheap?: boolean;
	}
>;

export function Root({
	skeleton,
	children,
	entities,
	initialTab = TabID.All,
	onActiveTabChanged,
	selectedSubjectID: controlledSelectedSubjectID,
	onSelectedSubjectIDChanged,
	showSubjectsCheap = true,
}: Props) {
	const [selectedSubjectID, setSelectedSubjectID] = useState<SubjectModel['id'] | null>(
		controlledSelectedSubjectID ?? null,
	);

	useEffect(() => {
		if (!isUndefined(controlledSelectedSubjectID)) {
			setSelectedSubjectID(controlledSelectedSubjectID);
		}
	}, [controlledSelectedSubjectID]);

	const onSetSelectedSubjectID = useCallback(
		(value: SubjectModel['id'] | null) => {
			setSelectedSubjectID(value);
			onSelectedSubjectIDChanged?.(value);
		},
		[onSelectedSubjectIDChanged],
	);

	const context = useMemo<SubjectPanelContextValue>(
		() => ({
			entities,
			selectedSubjectID,
			setSelectedSubjectID: onSetSelectedSubjectID,
			showSubjectsCheap,
			skeleton: Boolean(skeleton),
		}),
		[entities, selectedSubjectID, onSetSelectedSubjectID, showSubjectsCheap, skeleton],
	);

	return (
		<SubjectPanelContext.Provider value={context}>
			<Tabs.Root initialTab={initialTab} onActiveTabChanged={onActiveTabChanged} skeleton={skeleton}>
				{children}
			</Tabs.Root>
		</SubjectPanelContext.Provider>
	);
}
