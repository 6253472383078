import {Dispatch} from 'react';
import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {Item, ToggleGroupContent} from './index.styled';
import {TabItem} from './types';

type TabsProps<T extends string> = {
	activeTabID: T;
	setActiveTabID: Dispatch<T>;
	items: TabItem<T>[];
	disabled?: boolean;
};

export function Tabs<T extends string>({activeTabID, setActiveTabID, items, disabled}: TabsProps<T>) {
	return (
		<ToggleGroup.Root value={activeTabID} onValueChange={setActiveTabID} disabled={Boolean(disabled)}>
			<ToggleGroupContent>
				{items.map(({value, label}) => (
					<Item value={value} disabled={activeTabID === value} applyDisabledStyles={Boolean(disabled)} key={value}>
						<Text size='medium' bold>
							{label}
						</Text>
					</Item>
				))}
			</ToggleGroupContent>
		</ToggleGroup.Root>
	);
}
