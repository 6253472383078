import {useEffect, useMemo} from 'react';
import {RowDef} from './types';
import {useSchools} from '@esgi/main/libs/admin-store';
import {useDisposable} from '@esgi/core/service';
import {V2DistrictAdminsPagesDataAnalyticsController} from '@esgi/contracts/esgi';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';

export function useData(): {ready: boolean; rows: RowDef[]} {
	const [{data: schools, loaded: schoolsLoaded}] = useSchools();

	const analyticsController = useDisposable(V2DistrictAdminsPagesDataAnalyticsController);

	const [schoolsData, fetchSchools] = useCancelableRequest(analyticsController.schools);

	useEffect(() => {
		if (schoolsLoaded && schools.length) {
			fetchSchools({schoolsIDs: schools.map((s) => s.id)});
		}
	}, [schools, schoolsLoaded]);

	const mappedData = useMemo(() => {
		if (!isAsyncSucceed(schoolsData)) {
			return [];
		}

		return schools.map<RowDef>((school) => {
			const analytic = schoolsData.data.value.schoolsData.find(({id}) => id === school.id);

			return {
				id: school.id,
				name: school.name,
				teachers: analytic?.teachersCount ?? 0,
				specialists: analytic?.schoolSpecialistsCount ?? 0,
				classRatio: analytic?.classRatio ?? 0,
				students: analytic?.studentsCount ?? 0,
			};
		});
	}, [schools, schoolsData]);

	return {
		ready: schoolsLoaded,
		rows: mappedData,
	};
}
