import {GridBox} from '@esgi/ui/layout';
import {TestName} from '../../../test-name';
import {AnswersInfo, GridProgressBar} from '../../../../../../components';
import {GridProgressBarItem} from '@esgi/ui';
import {TestInfo} from '../../../../../../types/view-edit-session-details';
import {TestType} from '@esgi/main/kits/common';

type Props = {
	testInfo: TestInfo;
	progressBarItems: GridProgressBarItem[];
	correctAnswers: number;
};

export function TestNameWithBar({testInfo, progressBarItems, correctAnswers}: Props) {
	return (
		<TestName name={testInfo.name} color={testInfo.color} contentArea={testInfo.contentArea} type={TestType.Rubric}>
			<GridBox flow='column' align='center' justify='start' gap='3'>
				<AnswersInfo value={correctAnswers} maxValue={testInfo.totalPossible} />
				<GridProgressBar boxSize={40} items={progressBarItems} />
			</GridBox>
		</TestName>
	);
}
