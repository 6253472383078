import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {Avatar} from '@esgi/ui/avatar';

export const HeaderContent = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
	gap: '$4',
	alignItems: 'center',
});

export const StudentInfoBox = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	gridTemplateRows: 'auto auto',
	columnGap: '$3',
	alignItems: 'center',
});

export const AvatarRoot = styled(Avatar.Root, {
	gridRowStart: 1,
	gridRowEnd: 3,
});

export const AdditionalInfo = styled(FlexBox, {
	gap: '$3',
});

export const IconButtonsBox = styled(FlexBox, {
	gap: '$4',
	alignItems: 'center',
});

export const AdditionalHeaderContent = styled(GridBox, {
	paddingInline: 20,
	gap: 20,
});