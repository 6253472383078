import {Dispatch, useState} from 'react';
import {SortBy, SortDirection, Sorting} from '../../../service/types';

export function useSorting(): [Sorting, Dispatch<Sorting>] {
	const [sorting, setSorting] = useState<Sorting>(() => ({
		by: SortBy.Name,
		direction: SortDirection.Asc,
	}));

	return [sorting, setSorting];
}