import {IconComponent} from '@esgi/ui';
import {To} from 'react-router-dom';

export type NavigateItem = {
	to: To;
	label: string;
	Icon: IconComponent;
};

export enum AdditionalElementType {
	Divider = 'Divider',
}

export type NavigationPanelVariantProps = {
	onSchoolYearChanged: VoidFunction;
	onSettingsClick: VoidFunction;
	userFirstName: string;
	userLastName: string;
	userAvatarSrc: string | null;
	onAvatarClick: VoidFunction;
	onHelpClick: VoidFunction;
};
