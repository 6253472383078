import {useClasses, useGroups, User, useStudents, useTeachers} from '@esgi/main/libs/admin-store';
import {Button, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {useDeleteTeacher} from './hooks/use-delete-teacher';
import {Skeleton} from './components/skeleton';
import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {useCallback, useEffect, useMemo} from 'react';
import {OnTeacherRemoved} from '../../types';

type Props = {
	onRemoved: OnTeacherRemoved;
	onClose: VoidFunction;
	potentialRemovedTeacher: User;
};

export function DeleteTeacherAlert({onClose, onRemoved, potentialRemovedTeacher}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [{loaded: isClassesLoaded}] = useClasses();
	const [{loaded: isGroupsLoaded}] = useGroups();
	const [{loaded: isTeachersLoaded}] = useTeachers();
	const [{loaded: isStudentsLoaded}] = useStudents();

	const api = useEsgiApiGateway();

	const [initFoldersData, fetchUserFoldersData] = useCancelableRequest(
		api.v2.districtAdmins.modules.folders.initDelete,
	);

	const isUserFoldersDataLoaded = isAsyncSucceed(initFoldersData);

	const handleRemoved = useCallback(() => {
		const removedFoldersIDs: number[] = isUserFoldersDataLoaded
			? initFoldersData.data.value.foldersWillBeDeleted.map(({id}) => id)
			: [];

		onRemoved({
			removedFoldersIDs,
		});
	}, [initFoldersData, isUserFoldersDataLoaded, onRemoved]);

	const [handleRemoveTeacher, isTeacherRemoving] = useDeleteTeacher({
		onClose,
		onRemoved: handleRemoved,
		potentialRemovedTeacher,
	});

	useEffect(() => {
		fetchUserFoldersData({userID: potentialRemovedTeacher.id});
	}, []);

	const skeleton =
		!isClassesLoaded || !isGroupsLoaded || !isTeachersLoaded || !isStudentsLoaded || !isUserFoldersDataLoaded;

	const additionalMessage = useMemo(() => {
		if (skeleton || !initFoldersData.data.value.foldersWillBeDeleted.length) {
			return '';
		}

		return 'This action will remove the teacher from the associated folder(s) and delete any folder(s) for which this teacher was the only member.';
	}, [initFoldersData, skeleton]);

	return (
		<Alert
			modalManagerRef={alertRef}
			colorConfig={alertColorsConfig.negative}
			css={{
				'& [data-alert-content]': {
					maxWidth: 480,
				},
			}}
		>
			{skeleton ? (
				<Skeleton closeAlert={closeAlert} />
			) : (
				<>
					<Alert.Header onCloseIconClick={closeAlert}>
						<Text size='large'>Delete Teacher?</Text>
					</Alert.Header>

					<Alert.Body>
						<Text size='medium' color='highContrast'>
							{`Are you sure you want to delete the teacher? ${additionalMessage}`}
						</Text>
					</Alert.Body>

					<Alert.Footer>
						<GridBox gap='3' flow='column'>
							<Button color='tertiary' onClick={closeAlert}>
								<Text size='medium' bold color='base'>
									Cancel
								</Text>
							</Button>

							<Button color='secondary' onClick={handleRemoveTeacher} disabled={isTeacherRemoving}>
								<Text size='medium' bold color='negative'>
									Delete
								</Text>
							</Button>
						</GridBox>
					</Alert.Footer>
				</>
			)}
		</Alert>
	);
}
