import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Box = styled(GridBox, {
	borderRadius: 2,
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,
	alignItems: 'center',
	justifyContent: 'center',
});
