import {FormControl} from '@esgi/ui/form';
import {School} from '../../types';
import {BoxplotTwoSchoolsFormColorItem, BoxplotTwoSchoolsFormSchoolIDItem} from '../types';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui';
import {SchoolModel, Widget} from '@esgi/main/features/admins/dashboard';

export function setSchoolValueForBoxplotTwoSchoolsForm({
	schoolValue,
	schoolColorControl,
	schoolIDControl,
	defaultItemsIndex,
	allSchoolsList,
}: {
	schoolValue: School | null;
	schoolColorControl: FormControl<BoxplotTwoSchoolsFormColorItem>;
	schoolIDControl: FormControl<BoxplotTwoSchoolsFormSchoolIDItem>;
	defaultItemsIndex: number;
	allSchoolsList: SchoolModel[];
}) {
	if (!isNull(schoolValue)) {
		const {color, schoolID} = schoolValue;

		schoolColorControl.value = [color];
		schoolIDControl.value = [String(schoolID)];

		return;
	}

	schoolColorControl.value = [Widget.colorItems[defaultItemsIndex]?.value ?? null];
	schoolIDControl.value = [
		!isUndefined(allSchoolsList[defaultItemsIndex]) ? String(allSchoolsList[defaultItemsIndex]!.id) : null,
	];
}
