import {CSS} from '@stitches/react';

export const listGroupCSS: CSS = {
	display: 'grid',
	gap: '$4',
	gridAutoRows: 28,
};

export const listItemCSS: CSS = {
	gap: '$2',
	gridTemplateColumns: '1fr auto',
};
