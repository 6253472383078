import {isObservable, Observable, Subscription} from 'rxjs';
import {Dispatch, useCallback, useEffect, useRef, useState} from 'react';
import {isArray, isFunction} from 'underscore';

type PossibleEndpoint<TIn, TOut> = TIn extends unknown ? (() => Observable<TOut>) : ((inModel: TIn) => Observable<TOut>);
type Executor<In, Out> = In extends unknown ? ((callback?: Dispatch<Out>) => void) : ((inModel: In, callback?: Dispatch<Out>) => void);

type Options = {
	parallel?: boolean,
}

const defaultOptions = {
	parallel: false,
};
//
// export function useEndpoint<In, Out>(endpoint: PossibleEndpoint<In, Out>, deps?: any[]): Executor<In, Out>
// export function useEndpoint<In, Out>(endpoint: PossibleEndpoint<In, Out>, options: Options | any[], deps?: any[]): Executor<In, Out> {
// 	const subRef = useRef<Subscription>();
// 	const resultOptions = isArray(options) ? defaultOptions : options;
// 	const passedDeps = [...[resultOptions.parallel], ...((isArray(options) ? options : deps) || [])];
//
// 	return useCallback<Executor<In, Out>>((inModel, callback) => {
// 		// const callback = isFunction(callbackOrModel) ? callbackOrModel : callbackOrNull
// 		// const observable = endpoint(...args);
// 		// subRef.current = observable.subscribe()
// 	}, passedDeps);
// }

export function useEndpointResult<In extends unknown, Out>(endpointExecutor: PossibleEndpoint<In, Out>, deps: any[] = []): (Out | undefined) {
	const [result, setResult] = useState<Out | undefined>();

	useEffect(() => {
		const observer = endpointExecutor();
		if(!observer) {
			return;
		}

		if(!isObservable(observer)) {
			throw new Error('Unexpected return type from endpoint executor. Endpoint executor should return null or observable.');
		}

		const sub = observer.subscribe((next) => setResult(next));
		return () => sub.unsubscribe();
	}, deps);

	return result;
}