import {useCallback, useEffect, useState} from 'react';
import {useStreamEffect} from '@esgi/ui';
import {isDateTouched} from '@esgi/main/kits/admin';
import {BoxplotTwoClassesForm, BoxplotTwoClassesInitialFormData} from '../../../../forms-data/types';
import {Color, DatePickerForm, WidgetCard} from '@esgi/main/features/admins/dashboard';
import {ClassModel} from '../../../../../../../../../types';
import {ExcludedColors, ExcludedClassIDs} from './types';
import {DynamicFormProps} from '../../../../types';
import {InputsBox} from '../../../../index.styled';
import {useClassItems} from './use-class-items';
import {ClassRow} from './components/class-row';

type Props = DynamicFormProps<{
	twoClassesForm: BoxplotTwoClassesForm;
	allClassesList: ClassModel[];
	initialBoxplotTwoClassesForm: BoxplotTwoClassesInitialFormData;
}>;

export function TwoClasses({
	twoClassesForm,
	allClassesList,
	initialBoxplotTwoClassesForm,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	onCurrentDynamicFormNameChange,
}: Props) {
	const [excludedColors, setExcludedColors] = useState<ExcludedColors>({
		firstClassColor: twoClassesForm.controls.firstClassColor.value[0] ?? null,
		secondClassColor: twoClassesForm.controls.secondClassColor.value[0] ?? null,
	});

	const [excludedClassIDs, setExcludedClassIDs] = useState<ExcludedClassIDs>({
		firstClassID: twoClassesForm.controls.firstClassID.value[0] ?? null,
		secondClassID: twoClassesForm.controls.secondClassID.value[0] ?? null,
	});

	const updateExcludedColors = useCallback(({key, newValue}: {key: keyof ExcludedColors; newValue: Color | null}) => {
		setExcludedColors((currentState) => ({
			...currentState,
			[key]: newValue,
		}));
	}, []);

	const updateExcludedClassID = useCallback(
		({key, newValue}: {key: keyof ExcludedClassIDs; newValue: string | null}) => {
			setExcludedClassIDs((currentState) => ({
				...currentState,
				[key]: newValue,
			}));
		},
		[],
	);

	const {firstClassItems, secondClassItems} = useClassItems({allClassesList, excludedClassIDs});

	useStreamEffect(twoClassesForm.controls.firstClassColor.onChanged, ({currState: {value}}) => {
		updateExcludedColors({
			key: 'firstClassColor',
			newValue: value[0] ?? null,
		});
	});

	useStreamEffect(twoClassesForm.controls.secondClassColor.onChanged, ({currState: {value}}) => {
		updateExcludedColors({
			key: 'secondClassColor',
			newValue: value[0] ?? null,
		});
	});

	useStreamEffect(twoClassesForm.controls.firstClassID.onChanged, ({currState: {value}}) => {
		updateExcludedClassID({
			key: 'firstClassID',
			newValue: value[0] ?? null,
		});
	});

	useStreamEffect(twoClassesForm.controls.secondClassID.onChanged, ({currState: {value}}) => {
		updateExcludedClassID({
			key: 'secondClassID',
			newValue: value[0] ?? null,
		});
	});

	const handleValidate = useCallback(() => {
		const {firstClassColor, secondClassColor, date, firstClassID, secondClassID} = twoClassesForm.value;

		const isPeriodTouched = isDateTouched(date, initialBoxplotTwoClassesForm.date);

		const isFirstClassTouched =
			firstClassColor[0] !== initialBoxplotTwoClassesForm.firstClass?.color ||
			firstClassID[0] !==
				String(initialBoxplotTwoClassesForm.firstClass ? initialBoxplotTwoClassesForm.firstClass.classID : '');

		const isSecondClassTouched =
			secondClassColor[0] !== initialBoxplotTwoClassesForm.secondClass?.color ||
			secondClassID[0] !==
				String(initialBoxplotTwoClassesForm.secondClass ? initialBoxplotTwoClassesForm.secondClass.classID : '');

		setIsDynamicFormDataTouched(isPeriodTouched || isFirstClassTouched || isSecondClassTouched);

		twoClassesForm.validate(true).subscribe(({valid}) => {
			setIsDynamicFormDataValid(valid);
		});
	}, [
		initialBoxplotTwoClassesForm.date,
		initialBoxplotTwoClassesForm.firstClass,
		initialBoxplotTwoClassesForm.secondClass,
		setIsDynamicFormDataTouched,
		setIsDynamicFormDataValid,
		twoClassesForm,
	]);

	useEffect(() => {
		handleValidate();
		onCurrentDynamicFormNameChange('boxplotCompareTwoClasses');
	}, [handleValidate, onCurrentDynamicFormNameChange]);

	useStreamEffect(twoClassesForm.onChanged, handleValidate);

	return (
		<InputsBox>
			<WidgetCard.EditMode.FormElement control={twoClassesForm.controls.date}>
				<DatePickerForm form={twoClassesForm} />
			</WidgetCard.EditMode.FormElement>

			<ClassRow
				colorControl={twoClassesForm.controls.firstClassColor}
				excludedColor={excludedColors.secondClassColor}
				placeholder='Class A'
				classIDControl={twoClassesForm.controls.firstClassID}
				classItems={firstClassItems}
			/>

			<ClassRow
				colorControl={twoClassesForm.controls.secondClassColor}
				excludedColor={excludedColors.firstClassColor}
				placeholder='Class B'
				classIDControl={twoClassesForm.controls.secondClassID}
				classItems={secondClassItems}
			/>
		</InputsBox>
	);
}
