import {useCallback, useMemo} from 'react';
import {SubjectTabId} from '../../../types';
import {useUser} from '@esgi/core/authentication';
import {TabItem} from '@esgi/main/features/untested-students-report';
import {SubjectLevel, subjectPrefixByLevel, subjectsDescriptionByLevel} from '@esgi/main/kits/common';

export function useTabItems({activeTab}: {activeTab: SubjectTabId}) {
	const user = useUser();

	const isUserNotLinked = user?.agreementLevelCode === 'T';

	const getCommonTabItemProps = useCallback(
		(tabId: SubjectTabId) => ({
			permitted: activeTab !== tabId,
			applyDisabledStyles: false,
		}),
		[activeTab],
	);

	const tabItems = useMemo<TabItem<SubjectTabId>[]>(() => {
		if (isUserNotLinked) {
			return [];
		}

		return [
			{
				value: SubjectTabId.All,
				label: 'All',
				valueDescription: 'All Subject Tabs',
				contentType: 'text',
				dataCy: 'subjects-panel-tab-item-all',
				...getCommonTabItemProps(SubjectTabId.All),
			},
			{
				value: SubjectTabId.District,
				label: subjectPrefixByLevel[SubjectLevel.District],
				valueDescription: subjectsDescriptionByLevel[SubjectLevel.District],
				contentType: 'cheap',
				color: 'secondary',
				dataCy: 'subjects-panel-tab-item-district-subjects',
				...getCommonTabItemProps(SubjectTabId.District),
			},
			{
				value: SubjectTabId.School,
				label: subjectPrefixByLevel[SubjectLevel.School],
				valueDescription: subjectsDescriptionByLevel[SubjectLevel.School],
				contentType: 'cheap',
				color: 'primary',
				dataCy: 'subjects-panel-tab-item-school-subjects',
				...getCommonTabItemProps(SubjectTabId.School),
			},
			{
				value: SubjectTabId.Personal,
				label: subjectPrefixByLevel[SubjectLevel.Teacher],
				valueDescription: subjectsDescriptionByLevel[SubjectLevel.Teacher],
				contentType: 'cheap',
				color: 'neutral',
				dataCy: 'subjects-panel-tab-item-teacher-subjects',
				...getCommonTabItemProps(SubjectTabId.Personal),
			},
		];
	}, [isUserNotLinked, getCommonTabItemProps]);

	return {tabItems};
}
