import {ICellRendererParams} from 'ag-grid-community';
import {Container, Text} from './index.styled';
import {ComponentProps, useLayoutEffect, useMemo, useState} from 'react';
import {OneLinedText} from '../one-lined-text';
import {useCellContentAlign} from '../../hooks/use-cell-content-align';
import {isPrimaryType} from '../../helpers/is-primary-type';
import {isUndefined} from '@esgi/ui/utils';
import {isNumericType} from '../../helpers/is-numeric-type';

type TextColor = NonNullable<ComponentProps<typeof OneLinedText>['color']>;
type TextFont = ComponentProps<typeof OneLinedText>['font'];

type Props = ICellRendererParams;

export function TableCell({value, valueFormatted, node, colDef}: Props) {
	const [cellTextColor, setCellTextColor] = useState<TextColor>('base');

	const cellJustify = useCellContentAlign(colDef);

	useLayoutEffect(() => {
		const updateCellTextColor = () => {
			setCellTextColor(() => {
				if (!node.selectable) {
					return 'mediumContrast';
				}

				if (isUndefined(colDef)) {
					return 'base';
				}

				const colDefType = colDef.type;

				if (
					(Array.isArray(colDefType) && colDefType.some(isPrimaryType)) ||
					(typeof colDefType === 'string' && isPrimaryType(colDefType))
				) {
					return node.isHovered() ? 'secondary' : 'primary';
				}

				return 'base';
			});
		};

		updateCellTextColor();

		node.addEventListener('mouseEnter', updateCellTextColor);
		node.addEventListener('mouseLeave', updateCellTextColor);

		return () => {
			node.removeEventListener('mouseEnter', updateCellTextColor);
			node.removeEventListener('mouseLeave', updateCellTextColor);
		};
	}, [colDef, node]);

	const cellTextFont = useMemo<TextFont>(() => {
		if (isUndefined(colDef)) {
			return;
		}

		const colDefType = colDef.type;

		if (Array.isArray(colDefType) && colDefType.some(isNumericType)) {
			return 'mono';
		}

		if (typeof colDefType === 'string' && isNumericType(colDefType)) {
			return 'mono';
		}

		return;
	}, [colDef]);

	const isBold = useMemo<boolean>(() => {
		if (isUndefined(colDef)) {
			return false;
		}

		const colDefType = colDef.type;

		if (Array.isArray(colDefType) && colDefType.some((type) => isNumericType(type) || isPrimaryType(type))) {
			return true;
		}

		if (typeof colDefType === 'string' && (isNumericType(colDefType) || isPrimaryType(colDefType))) {
			return true;
		}

		return false;
	}, [colDef]);

	return (
		<Container justify={cellJustify}>
			<Text size='medium' bold={isBold} font={cellTextFont} color={cellTextColor}>
				{valueFormatted ?? value}
			</Text>
		</Container>
	);
}
