import {GridApi, ColGroupDef, ColDef, GridState} from 'ag-grid-community';
import {createContext, Dispatch, useContext} from 'react';
import {isNull} from 'underscore';

export type PageContentContextValue<T extends Record<string, unknown>> = {
	api: GridApi<T> | null;
	setApi: Dispatch<GridApi<T> | null>;
	selectedItems: T[];
	setSelectedItems: Dispatch<T[]>;
	entityName: string;
	tableRows: T[];
	columnDefs: (ColDef<T> | ColGroupDef<T>)[];
	isDataLoaded: boolean;
	onSearchValueChanged: Dispatch<string>;
	initialState: GridState | null;
};

export const PageContentContext = createContext<PageContentContextValue<any> | null>(null);

export function usePageContentContext<T extends Record<string, unknown>>() {
	const context = useContext(PageContentContext);

	if (isNull(context)) {
		throw new Error('usePageContentContext is null');
	}

	return context as unknown as PageContentContextValue<T>;
}
