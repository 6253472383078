import {useMemo} from 'react';
import {LowestAchievementSchoolData} from '../../../types/lowest-achievement';
import {SchoolsData} from '../types';
import {roundNumber} from '../utils/round-number';

export function useSchoolsItems({
	schoolsData,
	getSchoolName,
}: {
	schoolsData: LowestAchievementSchoolData[];
	getSchoolName: (schoolID: number) => string;
}) {
	return useMemo(() => {
		return schoolsData.map<SchoolsData>(({schoolID, totalStudents, lowestAchievementStudents, result}) => ({
			rowID: schoolID,
			school: getSchoolName(schoolID),
			totalStudents,
			lowestAchievementStudents,
			result: roundNumber(result),
		}));
	}, [getSchoolName, schoolsData]);
}
