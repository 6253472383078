import {isDeployedSubject, isStockSubject, useSubjects} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';
import {mapToEnum} from 'shared/utils';
import {SubjectType} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';
import {SubjectInfo} from '@esgi/main/features/school-admin/data';
import {isUndefined} from '@esgi/ui';

export function useFirstAdminSubject(): [SubjectInfo | undefined, boolean] {
	const [{data: subjects, loaded: isSubjectsLoaded}] = useSubjects();

	const firstSubject = useMemo<SubjectInfo | undefined>(() => {
		if (isSubjectsLoaded) {
			const passedSubjects = subjects
				.map((s) => ({...s, subjectType: mapToEnum(s.subjectType, SubjectType)}))
				.filter((s) => {
					if (isDeployedSubject(s)) {
						return !s.hidden && s.isPublished && s.tests.length;
					}

					if (isStockSubject(s)) {
						return !s.hidden && s.tests.length;
					}

					return false;
				});

			const firstSubject = passedSubjects[0];

			if (!isUndefined(firstSubject)) {
				return {
					id: firstSubject.id,
					level: firstSubject.level,
					type: firstSubject.subjectType,
				};
			}
		}
	}, [isSubjectsLoaded, subjects]);

	return [firstSubject, isSubjectsLoaded];
}
