import {DisabledInput} from './components/disabled-input';
import {FormSelect} from './components/form-select';
import {PeriodDatesInfo} from './components/period-dates-info';
import {SelectedEntityInfoDivider} from './components/selected-entity-info-divider';
import {SelectedGradeLevelsInfo} from './components/selected-grade-levels-info';
import {SelectedSchoolsInfo} from './components/selected-schools-info';
import {colorItems} from './constants';

export const Widget = Object.assign(
	{},
	{
		DisabledInput,
		FormSelect,
		PeriodDatesInfo,
		SelectedEntityInfoDivider,
		SelectedGradeLevelsInfo,
		SelectedSchoolsInfo,
		colorItems,
	},
);

export {type WidgetFormSelectItem} from './components/form-select/types';
