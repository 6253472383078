import {StudentCard} from '@esgi/ui/icons';
import {ByEachStudentData} from '../../../../types/test-panel';
import {Container, EmptyContentContainer, StudentBox, StudentNameBox} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {getFullName} from '@esgi/main/kits/common';
import {TestCard} from './components/test-card';
import {CardsContainer} from '../cards-container.styled';
import {Skeletonable} from '@esgi/ui';
import {SkeletonData} from './components/skeleton-data';
import {EmptyContent} from '../../../empty-content';

type Props = Skeletonable<{
	data: ByEachStudentData[];
	showInColor: boolean;
}>;

export function ByEachStudent({data, showInColor, skeleton}: Props) {
	if (skeleton) {
		return (
			<Container>
				<SkeletonData />
			</Container>
		);
	}

	if (!data.length) {
		return (
			<EmptyContentContainer>
				<EmptyContent message='There is no student assessment data for this filter' />
			</EmptyContentContainer>
		);
	}

	return (
		<Container>
			{data.map(({student, testsCards}) => (
				<StudentBox key={student.id}>
					<StudentNameBox>
						<StudentCard />
						<Text size='small' color='currentColor'>
							{getFullName({
								firstName: student.firstName,
								lastName: student.lastName,
							})}
						</Text>
					</StudentNameBox>
					<CardsContainer>
						{testsCards.map(({id, color, contentArea, maxScore, name, type, result}) => (
							<TestCard
								color={color}
								contentArea={contentArea}
								name={name}
								result={result}
								totalPossible={maxScore}
								type={type}
								showInColor={showInColor}
								key={id}
							/>
						))}
					</CardsContainer>
				</StudentBox>
			))}
		</Container>
	);
}
