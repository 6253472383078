import {Form, FormControl, FormGroup, useForm} from '@esgi/ui/form';
import {Folder, FoldersVocabulary} from '../../types';
import {FormElement} from '../../components/form-element';
import {Select} from '@esgi/ui/form-controls';
import {Text} from '@esgi/ui/typography';
import {AlertBody, EmptyTextBox} from './index.styled';
import {Dispatch, useEffect, useState} from 'react';
import {isUndefined, useStreamEffect} from '@esgi/ui';
import {AlertFooter} from '../../components/alert-footer';
import {isNull} from 'underscore';
import {SelectOption} from '../../components/select-option';
import {FolderManagement, FolderType} from '../../../kit';
import {folderEntityName} from '../../constants';

type Props = {
	folderType: FolderType;
	folders: Folder[];
	initialFolderID: Folder['id'] | null;
	addEntityIDs: number[];
	onFormTouchChanged: Dispatch<boolean>;
	onAlertClose: VoidFunction;
	onCheckFormCloseAlert: VoidFunction;
	fetchFolderByID: Dispatch<Folder['id']>;
	foldersVocabulary: FoldersVocabulary;
};

export function ExistingFolder({
	folderType,
	folders,
	initialFolderID,
	addEntityIDs,
	onFormTouchChanged,
	onAlertClose,
	onCheckFormCloseAlert,
	fetchFolderByID,
	foldersVocabulary,
}: Props) {
	const [isFolderUpdating, setIsFolderUpdating] = useState(false);
	const updateFolder = FolderManagement.useUpdateFolder({type: folderType, onFolderUpdated: onAlertClose});

	const [isFormTouched, setIsFormTouched] = useState(false);

	useEffect(() => {
		onFormTouchChanged(isFormTouched);
	}, [isFormTouched]);

	const form = useForm(
		() =>
			new FormGroup({
				selectedFolderID: new FormControl([isNull(initialFolderID) ? null : String(initialFolderID)]),
			}),
	);

	useStreamEffect(form.onChanged, ({currState: {value}}) => {
		const selectedFolderID = value.selectedFolderID[0];

		const isFormTouched = selectedFolderID !== String(initialFolderID);

		setIsFormTouched(isFormTouched);

		if (typeof selectedFolderID === 'string') {
			fetchFolderByID(Number(selectedFolderID));
		}
	});

	const hasFolders = Boolean(folders.length);
	const isAddDisable = !hasFolders || !isFormTouched || isFolderUpdating;

	const onFolderUpdate = () => {
		const selectedFolderID = form.value.selectedFolderID[0];
		const folder = folders.find(({id}) => String(id) === selectedFolderID);

		if (isAddDisable || isUndefined(folder) || isUndefined(foldersVocabulary.current[folder.id])) {
			return;
		}

		setIsFolderUpdating(true);

		const entityIDs = [...new Set([...foldersVocabulary.current[folder.id]!, ...addEntityIDs])];

		updateFolder({
			entityIDs,
			id: folder.id,
			name: folder.name,
		});
	};

	return (
		<>
			<AlertBody>
				{hasFolders ? (
					<Form controller={form}>
						<FormElement control={form.controls.selectedFolderID}>
							<Select.Root>
								<Select.Field placeholder='Select Folder' />
								<Select.Content>
									{folders.map(({id, name}) => (
										<SelectOption value={String(id)} label={name} key={id} />
									))}
								</Select.Content>
							</Select.Root>
						</FormElement>
					</Form>
				) : (
					<EmptyTextBox>
						<Text size='small' color='negative'>
							No folders have been created yet. Please create at least one new folder to which&nbsp;
							{folderEntityName[folderType].toLocaleLowerCase()} can be moved.
						</Text>
					</EmptyTextBox>
				)}
			</AlertBody>

			<AlertFooter isAddDisable={isAddDisable} onCancel={onCheckFormCloseAlert} onAddClick={onFolderUpdate} />
		</>
	);
}
