import {School, Students} from '@esgi/ui/icons';
import {LowestAchievementWidgetViewType} from '../../../../../../types/lowest-achievement';
import {DistrictCutoff} from '../../types';
import {ToggleGroupItemModel, WidgetFormSelectItem} from '@esgi/main/features/admins/dashboard';

export const viewTypeItems: ToggleGroupItemModel<LowestAchievementWidgetViewType>[] = [
	{
		value: LowestAchievementWidgetViewType.Students,
		label: 'Students',
		Icon: Students,
	},
	{
		value: LowestAchievementWidgetViewType.Schools,
		label: 'Schools',
		Icon: School,
	},
];

export const districtCutoffItems: WidgetFormSelectItem<DistrictCutoff>[] = [
	{
		value: DistrictCutoff.Five,
		label: 'Lowest 5%',
	},
	{
		value: DistrictCutoff.Ten,
		label: 'Lowest 10%',
	},
	{
		value: DistrictCutoff.Fifteen,
		label: 'Lowest 15%',
	},
	{
		value: DistrictCutoff.Twenty,
		label: 'Lowest 20%',
	},
	{
		value: DistrictCutoff.TwentyFive,
		label: 'Lowest 25%',
	},
];
