import {BaseService} from '@esgi/core/service';
import {
	Widget,
	DemographicPerformanceWidgetUpdatedValue,
	DistrictPerformanceWidgetUpdatedValue,
	LowestAchievementWidgetUpdatedValue,
	AddWidgetEventOptions,
	DistrictPerformanceWidgetOptionsContract,
	LowestAchievementWidgetOptionsContract,
	DemographicPerformanceWidgetOptionsContract,
	WidgetsMetricData,
	DashboardMetricData,
	DistrictPerformancePeriod,
	DemographicsPerformancePeriod,
	SchoolPerformanceWidgetOptionsContract,
	SchoolStudentsNeedingSupportWidgetOptionsContract,
	SchoolDemographicPerformanceWidgetOptionsContract,
} from '@esgi/main/features/district-admin/dashboard';
import {BehaviorSubject, map, Observable, of, tap} from 'rxjs';
import {
	V2ContentAreasController,
	V2DistrictAdminsPagesDashboardController,
	V2DistrictAdminsPagesDashboardInitController,
	V2DistrictAdminsPagesDashboardWidgetsController,
} from '@esgi/contracts/esgi';
import {parseInitWidgetsData} from './parsers/parse-init-widgets-data';
import {getAdaptedPeriods} from './helpers/get-adapted-periods';
import {parseDemographicPerformanceOptions} from './parsers/widget-options/parse-demographic-performance-options';
import {parseDistrictPerformanceOptions} from './parsers/widget-options/parse-district-performance-options';
import {isUndefined} from '@esgi/ui';
import {parseLowestAchievementOptions} from './parsers/widget-options/parse-lowest-achievement-options';
import {isNull, sortBy} from 'underscore';
import {parseDistrictPerformanceData} from './parsers/widget-data/parse-district-performance-data';
import {parseDemographicsPerformanceData} from './parsers/widget-data/parse-demographics-performance-data';
import {parseLowestAchievementMetricData} from './parsers/widget-data/parse-lowest-achievement-metric-data';
import {
	FetchWidgetDataByIDParameters,
	FetchWidgetDataByIDResponse,
	InitWidgetsDataParameters,
	InitWidgetsDataResponse,
} from './types';
import {testContentAreasMap} from './constants';
import {
	AddWidgetResponse,
	ContentAreaModel,
	DeployedSubject,
	GradeLevel,
	SchoolModel,
} from '@esgi/main/features/admins/dashboard';

export class PageService extends BaseService {
	public schools$ = new BehaviorSubject<SchoolModel[]>([]);
	public deployedSubjects$ = new BehaviorSubject<DeployedSubject[]>([]);
	public lastUpdatedDate$ = new BehaviorSubject<Date | string>('');
	public gradeLevels$ = new BehaviorSubject<GradeLevel[]>([]);
	public contentAreas$ = new BehaviorSubject<ContentAreaModel[]>([]);

	public widgets$ = new BehaviorSubject<Widget[]>([]);
	public widgetsMetricData$ = new BehaviorSubject<WidgetsMetricData>({});

	private readonly contentAreasController = new V2ContentAreasController();
	private readonly dashboardInitController = new V2DistrictAdminsPagesDashboardInitController();
	private readonly pagesDashboardController = new V2DistrictAdminsPagesDashboardController();
	private readonly widgetsController = new V2DistrictAdminsPagesDashboardWidgetsController();

	public fetchContentAreas(): Observable<ContentAreaModel[]> {
		return this.contentAreasController.all().pipe(
			map(({contentAreas}) => {
				const testContentAreasMapKey = Object.keys(testContentAreasMap);
				const testContentAreasResponseIDs = contentAreas.map(({id}) => id);

				if (
					testContentAreasMapKey.length !== contentAreas.length ||
					testContentAreasMapKey.some(
						(testContentAreaID) => !testContentAreasResponseIDs.includes(Number(testContentAreaID)),
					)
				) {
					throw new Error('contentAreas is not contain all appropriate keys. Please refresh testContentAreasMap');
				}

				const contentAreasData = contentAreas.map(({id, name}) => {
					const value = testContentAreasMap[id];

					if (isUndefined(value)) {
						throw new Error('value is undefined. Please refresh testContentAreasMap');
					}

					return {
						id,
						name,
						value,
					};
				});

				this.contentAreas$.next(contentAreasData);

				return contentAreasData;
			}),
		);
	}

	public init({
		globalSchoolYearID,
		contentAreasList,
	}: {
		globalSchoolYearID: number;
		contentAreasList: ContentAreaModel[];
	}) {
		return this.dashboardInitController.init({globalSchoolYearID}).pipe(
			tap(({value: {deployedSubjects, schools, timestamp, widgets, gradeLevels}}) => {
				const sortableSchools = sortBy([...schools], ({name}) => name);

				this.deployedSubjects$.next(deployedSubjects);
				this.schools$.next(sortableSchools);
				this.gradeLevels$.next(gradeLevels);

				this.updateLastUpdatedDate(timestamp);

				this.widgets$.next(
					parseInitWidgetsData({
						widgets,
						deployedSubjects,
						schools,
						gradeLevels,
						contentAreasList,
					}),
				);
			}),
		);
	}

	public initWidgetsData({dashboardVersionID}: InitWidgetsDataParameters): Observable<InitWidgetsDataResponse> {
		return this.pagesDashboardController.getData({dashboardVersionID}).pipe(
			tap(({value: {metrics}}) => {
				const widgetsData = metrics.reduce((currentData, {widgetID, data}) => {
					currentData[widgetID] = !isNull(data)
						? {
								schoolPerformanceMetricData: null,
								schoolStudentsNeedingSupportMetricData: null,
								schoolDemographicsPerformanceMetricData: null,
								districtPerformanceMetricData: parseDistrictPerformanceData(data.districtPerformanceMetricData),
								demographicsPerformanceMetricData: parseDemographicsPerformanceData(
									data.demographicsPerformanceMetricData,
								),
								lowestAchievementMetricData: parseLowestAchievementMetricData(data.lowestAchievementMetricData),
							}
						: null;

					return currentData;
				}, {} as WidgetsMetricData);

				const widgets = this.widgets$.value;

				widgets.forEach(({id}) => {
					const widgetData = widgetsData[id];

					if (isUndefined(widgetData)) {
						widgetsData[id] = null;
					}
				});

				this.widgetsMetricData$.next(widgetsData);
			}),
		);
	}

	public fetchWidgetDataByID({id, versionID}: FetchWidgetDataByIDParameters): Observable<FetchWidgetDataByIDResponse> {
		const widgets = this.widgets$.value;

		const widget = widgets.find((widget) => widget.id === id && widget.versionID === versionID);

		if (isUndefined(widget)) {
			return of();
		}

		return this.widgetsController.byId({widgetID: id}).pipe(
			tap(({value, isSuccess, errors}) => {
				if (!isSuccess && errors[0]) {
					const {type} = errors[0];

					const emptyDistrictPerformancePeriod: DistrictPerformancePeriod = {data: {}};
					const emptyDemographicsPerformancePeriod: DemographicsPerformancePeriod = {data: {}};

					/**
					 * potential error on empty data
					 * e.g. - if the request sent during calculating widget data
					 */
					if (type === 'no_data') {
						this.setNewWidgetData({
							id,
							data: {
								schoolPerformanceMetricData: null,
								schoolStudentsNeedingSupportMetricData: null,
								schoolDemographicsPerformanceMetricData: null,
								districtPerformanceMetricData: !isNull(widget.options.districtPerformanceWidgetOptions)
									? {
											allSchoolsAvg: 0,
											periods: new Array(widget.options.districtPerformanceWidgetOptions.periods.length).fill(
												emptyDistrictPerformancePeriod,
											),
										}
									: null,
								demographicsPerformanceMetricData: !isNull(widget.options.demographicPerformanceWidgetOptions)
									? {
											demographicGroup: widget.options.demographicPerformanceWidgetOptions.demographicGroup,
											allAvgValue: 0,
											demographicGroupData: new Array(
												widget.options.demographicPerformanceWidgetOptions.periods.length,
											).fill(emptyDemographicsPerformancePeriod),
										}
									: null,
								lowestAchievementMetricData: !isNull(widget.options.lowestAchievementWidgetOptions)
									? {
											schoolsData: [],
											studentsData: [],
										}
									: null,
							},
						});

						return;
					}
				}

				if (isSuccess && !isNull(value)) {
					const {data, id} = value;

					this.setNewWidgetData({
						id,
						data: !isNull(data)
							? {
									schoolPerformanceMetricData: null,
									schoolStudentsNeedingSupportMetricData: null,
									schoolDemographicsPerformanceMetricData: null,
									districtPerformanceMetricData: parseDistrictPerformanceData(data.districtPerformanceMetricData),
									demographicsPerformanceMetricData: parseDemographicsPerformanceData(
										data.demographicsPerformanceMetricData,
									),
									lowestAchievementMetricData: parseLowestAchievementMetricData(data.lowestAchievementMetricData),
								}
							: null,
					});
				}
			}),
		);
	}

	public addNewWidgetInList({
		id,
		name,
		versionID,
		options: {districtPerformanceWidgetOptions, demographicPerformanceWidgetOptions, lowestAchievementWidgetOptions},
		lastUpdatedTime,
	}: {
		id: AddWidgetResponse['id'];
		name: string;
		versionID: AddWidgetResponse['versionID'];
		options: AddWidgetEventOptions;
		lastUpdatedTime: string;
	}) {
		const widgets = this.widgets$.value;
		const deployedSubjects = this.deployedSubjects$.value;
		const schools = this.schools$.value;
		const gradeLevels = this.gradeLevels$.value;
		const contentAreas = this.contentAreas$.value;

		const newWidget: Widget = {
			id,
			name,
			versionID,
			lastUpdatedTime,
			options: {
				schoolPerformanceWidgetOptions: null,
				schoolStudentsNeedingSupportWidgetOptions: null,
				schoolDemographicPerformanceWidgetOptions: null,
				districtPerformanceWidgetOptions: parseDistrictPerformanceOptions({
					options: districtPerformanceWidgetOptions,
					deployedSubjects,
					schools,
					gradeLevels,
					contentAreasList: contentAreas,
				}),

				demographicPerformanceWidgetOptions: parseDemographicPerformanceOptions({
					options: demographicPerformanceWidgetOptions,
					deployedSubjects,
					schools,
					gradeLevels,
					contentAreasList: contentAreas,
				}),

				lowestAchievementWidgetOptions: parseLowestAchievementOptions({
					options: lowestAchievementWidgetOptions,
					deployedSubjects,
					schools,
					gradeLevels,
					contentAreasList: contentAreas,
				}),
			},
		};

		this.widgets$.next([...widgets, newWidget]);
		this.setNewWidgetData({id, data: null});
	}

	public updateDistrictPerformanceWidget({
		id,
		name,
		options,
		selectedArraysEntity,
	}: DistrictPerformanceWidgetUpdatedValue) {
		const adaptedPeriods = getAdaptedPeriods(options.periods);

		this.widgetsController
			.update({
				widgetID: id,
				name,
				dashboardWidgetOptions: {
					schoolPerformanceWidgetOptions: null as unknown as SchoolPerformanceWidgetOptionsContract,
					schoolStudentsNeedingSupportWidgetOptions: null as unknown as SchoolStudentsNeedingSupportWidgetOptionsContract,
					schoolDemographicPerformanceWidgetOptions:
						null as unknown as SchoolDemographicPerformanceWidgetOptionsContract,
					districtPerformanceWidgetOptions: {
						...options,
						periods: adaptedPeriods,
						schoolIDs: selectedArraysEntity.schoolIDs === 'all' ? [] : options.schoolIDs,
						contentAreaIDs: selectedArraysEntity.contentAreaIDs === 'all' ? [] : options.contentAreaIDs,
						gradeLevelIDs: selectedArraysEntity.gradeLevelIDs === 'all' ? [] : options.gradeLevelIDs,
						subjectIDs: selectedArraysEntity.subjectIDs === 'all' ? [] : options.subjectIDs,
					} as unknown as DistrictPerformanceWidgetOptionsContract,
					demographicPerformanceWidgetOptions: null as unknown as DemographicPerformanceWidgetOptionsContract,
					lowestAchievementWidgetOptions: null as unknown as LowestAchievementWidgetOptionsContract,
				},
			})
			.subscribe(({value: {versionID, lastUpdatedTime}}) => {
				this.updateWidget({
					id,
					name,
					versionID,
					lastUpdatedTime,
					options: {
						schoolPerformanceWidgetOptions: null,
						schoolStudentsNeedingSupportWidgetOptions: null,
						schoolDemographicPerformanceWidgetOptions: null,
						districtPerformanceWidgetOptions: {
							...options,
							periods: adaptedPeriods,
						},
						demographicPerformanceWidgetOptions: null,
						lowestAchievementWidgetOptions: null,
					},
				});
			});
	}

	public updateDemographicPerformanceWidget({
		id,
		name,
		options,
		selectedArraysEntity,
	}: DemographicPerformanceWidgetUpdatedValue) {
		const adaptedPeriods = getAdaptedPeriods(options.periods);

		this.widgetsController
			.update({
				widgetID: id,
				name,
				dashboardWidgetOptions: {
					schoolPerformanceWidgetOptions: null as unknown as SchoolPerformanceWidgetOptionsContract,
					schoolStudentsNeedingSupportWidgetOptions: null as unknown as SchoolStudentsNeedingSupportWidgetOptionsContract,
					schoolDemographicPerformanceWidgetOptions:
						null as unknown as SchoolDemographicPerformanceWidgetOptionsContract,
					districtPerformanceWidgetOptions: null as unknown as DistrictPerformanceWidgetOptionsContract,
					demographicPerformanceWidgetOptions: {
						...options,
						periods: adaptedPeriods,
						schoolIDs: selectedArraysEntity.schoolIDs === 'all' ? [] : options.schoolIDs,
						contentAreaIDs: selectedArraysEntity.contentAreaIDs === 'all' ? [] : options.contentAreaIDs,
						gradeLevelIDs: selectedArraysEntity.gradeLevelIDs === 'all' ? [] : options.gradeLevelIDs,
						subjectIDs: selectedArraysEntity.subjectIDs === 'all' ? [] : options.subjectIDs,
					} as unknown as DemographicPerformanceWidgetOptionsContract,
					lowestAchievementWidgetOptions: null as unknown as LowestAchievementWidgetOptionsContract,
				},
			})
			.subscribe(({value: {versionID, lastUpdatedTime}}) => {
				this.updateWidget({
					id,
					name,
					versionID,
					lastUpdatedTime,
					options: {
						schoolPerformanceWidgetOptions: null,
						schoolStudentsNeedingSupportWidgetOptions: null,
						schoolDemographicPerformanceWidgetOptions: null,
						districtPerformanceWidgetOptions: null,
						demographicPerformanceWidgetOptions: {
							...options,
							periods: adaptedPeriods,
						},
						lowestAchievementWidgetOptions: null,
					},
				});
			});
	}

	public updateLowestAchievementWidget({id, name, options, selectedArraysEntity}: LowestAchievementWidgetUpdatedValue) {
		const adaptedPeriods = getAdaptedPeriods(options.periods);

		this.widgetsController
			.update({
				widgetID: id,
				name,
				dashboardWidgetOptions: {
					schoolPerformanceWidgetOptions: null as unknown as SchoolPerformanceWidgetOptionsContract,
					schoolStudentsNeedingSupportWidgetOptions: null as unknown as SchoolStudentsNeedingSupportWidgetOptionsContract,
					schoolDemographicPerformanceWidgetOptions:
						null as unknown as SchoolDemographicPerformanceWidgetOptionsContract,
					districtPerformanceWidgetOptions: null as unknown as DistrictPerformanceWidgetOptionsContract,
					demographicPerformanceWidgetOptions: null as unknown as DemographicPerformanceWidgetOptionsContract,
					lowestAchievementWidgetOptions: {
						...options,
						periods: adaptedPeriods,
						schoolIDs: selectedArraysEntity.schoolIDs === 'all' ? [] : options.schoolIDs,
						contentAreaIDs: selectedArraysEntity.contentAreaIDs === 'all' ? [] : options.contentAreaIDs,
						gradeLevelIDs: selectedArraysEntity.gradeLevelIDs === 'all' ? [] : options.gradeLevelIDs,
						subjectIDs: selectedArraysEntity.subjectIDs === 'all' ? [] : options.subjectIDs,
					} as unknown as LowestAchievementWidgetOptionsContract,
				},
			})
			.subscribe(({value: {versionID, lastUpdatedTime}}) => {
				this.updateWidget({
					id,
					name,
					versionID,
					lastUpdatedTime,
					options: {
						schoolPerformanceWidgetOptions: null,
						schoolStudentsNeedingSupportWidgetOptions: null,
						schoolDemographicPerformanceWidgetOptions: null,
						districtPerformanceWidgetOptions: null,
						demographicPerformanceWidgetOptions: null,
						lowestAchievementWidgetOptions: {
							...options,
							periods: adaptedPeriods,
						},
					},
				});
			});
	}

	public deleteWidgetByID(id: Widget['id']) {
		const filteredWidgets = this.widgets$.value.filter((widget) => widget.id !== id);

		this.widgets$.next(filteredWidgets);
		this.widgetsController.makeDelete({widgetID: id}).subscribe();
	}

	public updateWidgetsOrdering(newOrderIDs: Widget['id'][]) {
		const currentWidgets = this.widgets$.value;

		const currentWidgetsByID = currentWidgets.reduce<Record<Widget['id'], Widget>>((state, widget) => {
			state[widget.id] = widget;

			return state;
		}, {});

		const newWidgetsList = newOrderIDs.map((id) => {
			const widget = currentWidgetsByID[id];

			if (isUndefined(widget)) {
				throw new Error('widget is undefined');
			}

			return widget;
		});

		this.widgets$.next(newWidgetsList);

		this.widgetsController.reorder({widgetIDs: newOrderIDs}).subscribe();
	}

	public updateLastUpdatedDate(timestamp: string | Date) {
		this.lastUpdatedDate$.next(timestamp);
	}

	public override dispose(): void {
		this.dashboardInitController.dispose();
		this.pagesDashboardController.dispose();
		this.widgetsController.dispose();
	}

	private updateWidget(widget: Widget) {
		const currentWidgets = this.widgets$.value;

		this.widgets$.next(
			currentWidgets.map((iteratedWidget) => (iteratedWidget.id === widget.id ? widget : iteratedWidget)),
		);

		this.setNewWidgetData({id: widget.id, data: null});
	}

	private setNewWidgetData({id, data}: {id: Widget['id']; data: DashboardMetricData | null}) {
		const widgetsMetricData = this.widgetsMetricData$.value;

		this.widgetsMetricData$.next({
			...widgetsMetricData,
			[id]: data,
		});
	}
}
