import {FormControl} from '@esgi/ui/form';
import {BoxplotTwoSchoolsFormColorItem, BoxplotTwoSchoolsFormSchoolIDItem} from '../../../../../forms-data/types';
import {useFilteredColorItems} from '../../../use-filtered-color-items';
import {Color, FormColorPicker, Widget, WidgetCard, WidgetFormSelectItem} from '@esgi/main/features/admins/dashboard';

type Props = {
	excludedColor: Color | null;
	colorControl: FormControl<BoxplotTwoSchoolsFormColorItem>;
	schoolIDControl: FormControl<BoxplotTwoSchoolsFormSchoolIDItem>;
	placeholder: string;
	schoolItems: WidgetFormSelectItem<string>[];
};

export function SchoolRow({excludedColor, colorControl, schoolIDControl, placeholder, schoolItems}: Props) {
	const filteredColorItems = useFilteredColorItems({excludedColor});

	return (
		<WidgetCard.EditMode.PeriodColorBox>
			<WidgetCard.EditMode.FormElement control={colorControl}>
				<FormColorPicker items={filteredColorItems} />
			</WidgetCard.EditMode.FormElement>

			<Widget.FormSelect placeholder={placeholder} items={schoolItems} control={schoolIDControl} />
		</WidgetCard.EditMode.PeriodColorBox>
	);
}
