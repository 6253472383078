import {BaseService} from '@esgi/core/service';
import {LoginResponse, Authenticator} from '@esgi/core/authentication';
import {FormControl, FormGroup, Validators} from '@esgillc/ui-kit/form';
import {map, Observable, concatMap, from} from 'rxjs';
import {getUrlVars} from './utils';
import {environment} from '@esgi/core/environments';

export class LoginService extends BaseService {
	public readonly form = new FormGroup({
		username: new FormControl('', {validators: [Validators.required()]}),
		password: new FormControl('', {validators: [Validators.required()]}),
	});

	public init() {
		try {
			const message = getUrlVars().message;
			if (message) {
				return this.httpClient.ESGIApi.post<LoginResponse>('Login', 'Message', {
					data: {guid: message},
					async: false,
				}).pipe(concatMap(r => {
					return from(new Authenticator().authenticate(r)).pipe(map((authenticated) => {
						if (authenticated) {
							if (r.user.type === 'C' || r.user.type === 'D') {
								document.location.href = '/admin-home';
							} else {
								document.location.href = '/';
							}
						}
					}));
				})).asObservable();
			}
		} catch (e) {
			console.log(e);
		}
	}

	public makeLogin(ignoreMaintenanceMode): Observable<void> {
		const {username, password} = this.form.value;
		const data = new FormData();
		data.set('userName', username);
		data.set('password', password);
		data.set('ignoreMaintenance', ignoreMaintenanceMode);
		return this.httpClient.ESGIApi.post<LoginResponse>('Login', 'Login', data)
			.pipe(concatMap((r) => from(this.afterAuthentication(r)))).asObservable();
	}

	private async afterAuthentication(response: LoginResponse, isLoggedByCredentials?: boolean) {
		const authResult = await new Authenticator().authenticate(response, isLoggedByCredentials);

		const params = new URLSearchParams(window.location.search);
		const returnTo = params.get('return_to');
		const activationCode = params.get('ActivationCode');

		if (authResult.valid || (authResult.redirect && activationCode)) {
			try { // we don't want interrupt standard login.
				if (returnTo) {
					document.location.href = activationCode
						? `${returnTo}?ActivationCode=${activationCode}`
						: returnTo;
					return;
				}
			} catch (e) {
				console.error(e);
			}

			let href = '/';
			if (response.user.type === 'C' || response.user.type === 'D') {
				if (environment?.country === 'ca') {
					href = '/admin-home';
				} else {
					href = '/dashboard';
				}
			}

			document.location.href = href;
		}
	}
}
