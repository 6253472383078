import {TestPanelTestModel} from '@esgi/main/features/admins/subject-management';
import {TestContentArea, TestType} from '@esgi/main/kits/common';
import {Subject} from '@esgi/main/libs/school-admin-store';
import {useMemo} from 'react';

type Parameters = {
	subjectFromStore: Subject;
};

export function useTestsPanelTests({subjectFromStore}: Parameters) {
	return useMemo(
		() =>
			subjectFromStore.tests.map<TestPanelTestModel>(({id, name, color, type, contentArea}) => ({
				id,
				name,
				color,
				type: type as unknown as TestType,
				contentArea: contentArea as TestContentArea,
			})),
		[subjectFromStore],
	);
}
