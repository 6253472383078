import moment from 'moment';
import {ContentWrapperBox} from './content-wrapper-box.styled';
import {Text} from '@esgi/ui/typography';

type Props = {
	date: Date | null;
};

export function ExpirationDate({date}: Props) {
	return (
		<ContentWrapperBox dataCy='expiration-date-box' gap='4' textAlign='center'>
			{date && (
				<Text data-cy='expiration-date-text' size='medium' font='mono' bold color='base'>
					{moment(date).format('MM/DD/YYYY')}
				</Text>
			)}
		</ContentWrapperBox>
	);
}
