import {Cheap} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';
import {Box, GridBox} from '@esgi/ui/layout';

export const StyledContainer = styled(GridBox, {
	gap: 20,
});

export const StyledCheap = styled(Cheap, {
	variants: {
		custom: {
			true: {
				marginLeft: 8,
				padding: '2px 4px',
				backgroundColor: '$surface',
				borderColor: '$primaryMuted',
			},
		},
	},
});

export const StyledPanel = styled(GridBox, {
	gap: 6,
	padding: 6,
	backgroundColor: '$surface',
	borderColor: 'transparent',
	borderStyle: 'solid',
	borderWidth: 1,
	borderRadius: 8,

	variants: {
		isError: {
			true: {
				borderColor: '$negativeHighlight',
			},
		},
	},
});

export const StyledPanelHeader = styled(Box, {
	padding: '6px 6px 12px 6px',
	borderWidth: 0,
	borderBottomWidth: 1,
	borderStyle: 'solid',
	borderColor: '$border',
});