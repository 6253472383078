import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {notSelectableClassName, selectableClassName} from '../../constants';

export const Container = styled(Box, {
	height: '100%',

	'& .ag-filter-menu': {
		borderRadius: 12,
	},

	'& .ag-react-container': {
		width: '100%',
		height: '100%',
	},

	[`& .${selectableClassName}`]: {
		cursor: 'pointer',
	},

	[`& .${notSelectableClassName}.ag-row-hover`]: {
		'&::before': {
			content: 'none',
		},
	},

	'& .ag-row-hover.ag-row-selected:before': {
		backgroundImage: 'unset',
	},

	variants: {
		withoutFirstColumnPadding: {
			true: {
				'& .ag-column-first': {
					paddingLeft: 'unset !important',
				},
			},
		},

		withoutLastColumnPadding: {
			true: {
				'& .ag-column-last': {
					paddingRight: 'unset !important',
				},
			},
		},
	},
});
