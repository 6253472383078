import {TestCard} from '@esgi/main/kits/test-results-panel';
import {Text} from '@esgi/ui/typography';
import {isNull} from 'underscore';
import {TestPanelCard} from '../../../test-panel-card';
import {TestType} from '@esgi/main/kits/common';
import {useMemo} from 'react';

type Props = {
	value: number | null;
	maxValue: number;
};

export function CorrectAnswersInfo({value, maxValue}: Props) {
	const {testResultsCorrectVerbiage, testType} = TestPanelCard.useContext();

	const correctAnswersTranscript = useMemo<string>(() => {
		if (testType === TestType.YN) {
			return `${testResultsCorrectVerbiage} Answers`;
		}

		if (testType === TestType.Rubric) {
			return 'Avg Score';
		}

		if (testType === TestType.Score) {
			return 'Score';
		}

		return '';
	}, [testResultsCorrectVerbiage, testType]);

	if (isNull(value)) {
		return (
			<TestCard.Statistic.InfoWithTranscript transcript={correctAnswersTranscript} justifyItems='start'>
				<Text size='medium' font='mono' bold color='mediumContrast'>
					-
				</Text>
			</TestCard.Statistic.InfoWithTranscript>
		);
	}

	return <TestCard.AnswersInfo value={value} maxValue={maxValue} transcriptText={correctAnswersTranscript} />;
}
