import {useEffect} from 'react';
import {isNull} from 'underscore';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {Drawer, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {PrimaryAccountPanel} from './components/panels';
import {UserAccountService} from './user-account-service';
import {StyledDrawerWrapper} from './index.styled';

type Props = {
	onClose: VoidFunction;
};

export function UserAccountDrawer(props: Props) {
	const drawerRef = useDrawerRef();
	const close = useDrawerClose(drawerRef, props.onClose);
	const dataService = useService(UserAccountService);

	const user = useBehaviorSubject(dataService.user$);
	const states = useBehaviorSubject(dataService.states$);
	const tracks = useBehaviorSubject(dataService.tracks$);
	const countries = useBehaviorSubject(dataService.countries$);
	const activeUserTrackId = useBehaviorSubject(dataService.activeUserTrackId$);
	const isUserDataLoading = useBehaviorSubject(dataService.isUserDataLoading$);
	const schoolYearTypes = useBehaviorSubject(dataService.schoolYearTypes$);

	useEffect(() => {
		dataService.init().subscribe();
	}, [dataService]);

	return (
		<StyledDrawerWrapper>
			<Drawer width='100%' drawerRef={drawerRef} onClickOutside={close}>
				{(!isNull(user) && !isNull(schoolYearTypes)) && (
					<PrimaryAccountPanel
						dataService={dataService}
						user={user}
						countries={countries}
						states={states}
						tracks={tracks}
						schoolYearTypes={schoolYearTypes}
						isUserDataLoading={isUserDataLoading}
						activeUserTrackId={activeUserTrackId}
					/>
				)}
			</Drawer>
		</StyledDrawerWrapper>
	);
}
