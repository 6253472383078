import {useSchools, useTeachers, useStudents} from '@esgi/main/libs/admin-store';
import {useCallback, useEffect, useMemo} from 'react';
import {EntityProperty} from '../types';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {entitiesWithFolders} from '../constants';
import {isNull} from 'underscore';
import {useDataPageContext} from '../../../context';
import {EntityType} from '../../../types';
import {
	ClassCreatedEvent,
	ClassDeletedEvent,
	FolderCreatedEvent,
	FolderManagement,
	FolderType,
	FolderUpdatedEvent,
	GroupCreatedEvent,
	GroupDeletedEvent,
	SpecialistGroupCreatedEvent,
	SpecialistGroupRemovedEvent,
	TeacherRemovedEvent,
} from '@esgi/main/features/admins/data';
import {useEventEffect} from '@esgillc/events';
import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {RefreshDataPanelEvent} from '../../../events';
import {StudentDeletedEvent} from '@esgi/main/features/admins/student-profile-drawer/events';

export function useData({activeEntityType}: {activeEntityType: EntityType | null}) {
	const {entityFolders, setEntityFolders} = useDataPageContext();

	const [foldersData, fetchFolders] = FolderManagement.useFetchFolders();

	const api = useEsgiApiGateway();
	const [initData, fetchInitData] = useCancelableRequest(api.v2.districtAdmins.pages.data.init);

	const [{data: schools, loaded: isSchoolsLoaded}] = useSchools();
	const [{data: teachers, loaded: isTeachersLoaded}] = useTeachers();
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const fetchFoldersData = useCallback(() => {
		const folderType = (() => {
			switch (activeEntityType) {
				case 'schools':
					return FolderType.Schools;
				case 'teachers':
					return FolderType.Teacher;
				case 'specialists':
					return FolderType.Specialist;

				default:
					return null;
			}
		})();

		if (isNull(folderType)) {
			return;
		}

		fetchFolders(folderType);
	}, [activeEntityType, fetchFolders]);

	useEffect(fetchFoldersData, [activeEntityType]);
	useEffect(fetchInitData, []);

	useEffect(() => {
		if (!isNull(activeEntityType) && entitiesWithFolders.includes(activeEntityType) && isAsyncSucceed(foldersData)) {
			setEntityFolders(foldersData.data.folders);
			return;
		}

		setEntityFolders([]);
	}, [activeEntityType, foldersData, setEntityFolders]);

	useEventEffect(RefreshDataPanelEvent, fetchInitData);

	useEventEffect(ClassCreatedEvent, fetchInitData);
	useEventEffect(ClassDeletedEvent, fetchInitData);

	useEventEffect(GroupCreatedEvent, fetchInitData);
	useEventEffect(GroupDeletedEvent, fetchInitData);

	useEventEffect(SpecialistGroupCreatedEvent, fetchInitData);
	useEventEffect(SpecialistGroupRemovedEvent, fetchInitData);

	useEventEffect(StudentDeletedEvent, fetchInitData);

	useEventEffect(TeacherRemovedEvent, () => {
		fetchInitData();
		fetchFoldersData();
	});

	useEventEffect(FolderCreatedEvent, fetchFoldersData);
	useEventEffect(FolderUpdatedEvent, fetchFoldersData);

	const entitiesGroups = useMemo<EntityProperty[][]>(
		() => [
			[
				{
					name: 'Schools',
					type: 'schools',
					count: isSchoolsLoaded ? schools.length : null,
				},
			],
			[
				{
					name: 'Teachers',
					type: 'teachers',
					count: isTeachersLoaded ? teachers.length : null,
				},
				{
					name: 'Specialists',
					type: 'specialists',
					count: isAsyncSucceed(initData)
						? initData.data.value.schoolSpecialistsCount +
							initData.data.value.districtSpecialistsCount +
							initData.data.value.preAssessCount
						: null,
				},
			],
			[
				{
					name: 'Classes',
					type: 'classes',
					count: isAsyncSucceed(initData) ? initData.data.value.classesCount : null,
				},
				{
					name: 'Groups',
					type: 'groups',
					count: isAsyncSucceed(initData) ? initData.data.value.groupsCount : null,
				},
				{
					name: 'Specialist Groups',
					type: 'specialistGroups',
					count: isAsyncSucceed(initData) ? initData.data.value.specialistGroupsCount : null,
				},
			],
			[
				{
					name: 'Students',
					type: 'students',
					count: isStudentsLoaded ? students.length : null,
				},
			],
		],
		[initData, isSchoolsLoaded, isStudentsLoaded, isTeachersLoaded, schools.length, students.length, teachers.length],
	);

	return {
		entitiesGroups,
		folders: entityFolders,
		districtName: isAsyncSucceed(initData) ? initData.data.value.districtName : null,
	};
}
