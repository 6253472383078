import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	subjectName: string;
	testName: string;
	onClose: VoidFunction;
	onConfirm: VoidFunction;
};

export function RemoveAlert({subjectName, testName, onClose, onConfirm}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const confirmAndClose = () => {
		closeAlert();
		onConfirm();
	};

	return (
		<Alert
			colorConfig={alertColorsConfig.negative}
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					width: 510,
				},
			}}
		>
			<Alert.Header onCloseIconClick={closeAlert}>
				<Text size='large' color='negative'>
					Remove Test from Subject
				</Text>
			</Alert.Header>
			<Alert.Body>
				<GridBox>
					<Text size='medium' color='neutral40'>
						Are you sure you want to delete the test "{testName}" from the subject {subjectName}?
					</Text>
				</GridBox>
			</Alert.Body>
			<Alert.Footer>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>
					<Button color='secondary' onClick={confirmAndClose}>
						<Text size='medium' bold color='base'>
							Confirm
						</Text>
					</Button>
				</GridBox>
			</Alert.Footer>
		</Alert>
	);
}
