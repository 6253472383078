import {isUndefined, School, useFlag} from '@esgi/ui';
import {RunReportButton} from '../run-report-button';
import {ActionPanel} from '../action-panel';
import {Text} from '@esgi/ui/typography';
import {DistrictReportsDialog} from '../district-reports-dialog';
import {GridApi} from 'ag-grid-community';
import {CollapsiblePanel} from '@esgi/main/features/admins/data';
import {HeaderContent} from './index.styled';
import {useEffect, useState} from 'react';
import {isNull} from 'underscore';

type Props<T extends Record<string, unknown>> = {
	api: GridApi<T> | null;
	title: string | null;
	tableRowsLength?: number;
};

export function DistrictReportPanel<T extends Record<string, unknown>>({title, api, tableRowsLength}: Props<T>) {
	const [showDistrictReport, openDistrictReport, closeDistrictReports] = useFlag();
	const [showPanel, openPanel, closePanel] = useFlag();

	const [selectedItemsCount, setSelectedItems] = useState(0);

	const managerRef = CollapsiblePanel.useRef();
	const closeActionPanel = CollapsiblePanel.useClose(managerRef, () => {
		closePanel();
	});

	useEffect(() => {
		if (isNull(api)) {
			return;
		}

		const handler = () => {
			const selectedRows = api.getSelectedRows();
			setSelectedItems(selectedRows?.length || 0);
		};

		api.addEventListener('selectionChanged', handler);

		return () => api.removeEventListener('selectionChanged', handler);
	}, [api]);

	useEffect(() => {
		if (!selectedItemsCount || (!isUndefined(tableRowsLength) && selectedItemsCount === tableRowsLength)) {
			openPanel();
			return;
		}

		closeActionPanel();
	}, [closeActionPanel, openPanel, selectedItemsCount, tableRowsLength]);

	if (!showPanel) {
		return null;
	}

	return (
		<>
			<CollapsiblePanel.Root managerRef={managerRef}>
				<CollapsiblePanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<School />
							<Text size='large' color='highContrast'>
								{title}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<RunReportButton title='District Reports' onClick={openDistrictReport} />
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</CollapsiblePanel.Header>
			</CollapsiblePanel.Root>

			{showDistrictReport && <DistrictReportsDialog onClose={closeDistrictReports} />}
		</>
	);
}
