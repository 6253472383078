import {Dispatch, useState} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {useStreamEffect} from '@esgi/ui';
import {ContentArea, Subject} from '@esgi/ui/icons';
import {MainSettingsBox} from './index.styled';
import {ClassModel, TeacherModel, SubjectTab} from '../../../../../../../types';
import {FormToggleGroup, GradeLevel, SelectDrawerable, Widget, WidgetCard} from '@esgi/main/features/admins/dashboard';
import {demographicGroups, sizeItems, viewTypeItems} from './constants';
import {isCompareTwoClassesValue} from './is-compare-two-classes-value';
import {useDynamicAdaptedItems} from './use-dynamic-adapted-items';
import {CommonForm} from '../../forms-data/types';
import {SelectDrawerableFilterable} from '../../../../../../components/select-drawerable-filterable';

type Props = {
	commonForm: CommonForm;
	subjects: SubjectTab[];
	selectedContentArea: string[];
	setSelectedContentArea: Dispatch<string[]>;
	selectedSubjectIDs: string[];
	setSelectedSubjectIDs: Dispatch<string[]>;
	selectedGradeLevels: string[];
	setSelectedGradeLevels: Dispatch<string[]>;
	classes: ClassModel[];
	teachers: TeacherModel[];
	selectedClassesIDs: string[];
	setSelectedClassesIDs: Dispatch<string[]>;
	gradeLevels: GradeLevel[];
};

export function MainSettings({
	commonForm,
	subjects,
	selectedContentArea,
	selectedGradeLevels,
	selectedSubjectIDs,
	setSelectedContentArea,
	setSelectedGradeLevels,
	setSelectedSubjectIDs,
	classes,
	teachers,
	selectedClassesIDs,
	setSelectedClassesIDs,
	gradeLevels,
}: Props) {
	const [isCompareTwoClasses, setIsCompareTwoClasses] = useState(() =>
		isCompareTwoClassesValue(commonForm.value.boxplotCompareVariant),
	);

	const {
		selectedTeacherID,
		setSelectedTeacherID,
		selectedSubjectLevel,
		setSelectedSubjectLevel,
		classesAdaptedItems,
		classesFilteredAdaptedItems,
		teachersAdaptedItems,
		subjectsAdaptedItems,
		subjectsFilteredAdaptedItems,
		gradeLevelsAdaptedItems,
		contentAreasAdaptedItems,
	} = useDynamicAdaptedItems({
		classes,
		teachers,
		subjects,
		gradeLevels,
	});

	useStreamEffect(commonForm.controls.boxplotCompareVariant.onChanged, ({currState: {value}}) => {
		setIsCompareTwoClasses(isCompareTwoClassesValue(value));
	});

	return (
		<MainSettingsBox>
			<GridBox flow='column' gap='2'>
				<WidgetCard.EditMode.FormElement control={commonForm.controls.viewType}>
					<FormToggleGroup items={viewTypeItems} />
				</WidgetCard.EditMode.FormElement>

				<WidgetCard.EditMode.FormElement control={commonForm.controls.size}>
					<FormToggleGroup items={sizeItems} />
				</WidgetCard.EditMode.FormElement>
			</GridBox>

			<GridBox gap='2'>
				<Widget.FormSelect
					placeholder='Demographic Group'
					items={demographicGroups}
					control={commonForm.controls.demographicGroup}
				/>

				<SelectDrawerableFilterable
					placeholder='Classes'
					sectionName='Classes'
					value={selectedClassesIDs}
					onSaveSelectedEntity={setSelectedClassesIDs}
					selectedValueTranscript='Classes'
					items={classesFilteredAdaptedItems}
					allItems={classesAdaptedItems}
					filterWidget={
						<SelectDrawerableFilterable.TeacherFilter
							teachers={teachersAdaptedItems}
							selectedTeacher={selectedTeacherID}
							onTeacherSelect={setSelectedTeacherID}
						/>
					}
					EntityIcon={Subject}
					disabled={isCompareTwoClasses}
					disabledMessage='Compare Two Classes'
				/>

				<SelectDrawerable
					placeholder='Content Areas'
					sectionName='Content Areas'
					value={selectedContentArea}
					onSaveSelectedEntity={setSelectedContentArea}
					selectedValueTranscript='Content Areas'
					allItems={contentAreasAdaptedItems}
					EntityIcon={ContentArea}
				/>

				<SelectDrawerableFilterable
					placeholder='Subject Tabs'
					sectionName='Subjects'
					value={selectedSubjectIDs}
					onSaveSelectedEntity={setSelectedSubjectIDs}
					selectedValueTranscript='Subjects'
					items={subjectsFilteredAdaptedItems}
					allItems={subjectsAdaptedItems}
					filterWidget={
						<SelectDrawerableFilterable.SubjectLevelFilter
							selectedSubjectLevel={selectedSubjectLevel}
							onSubjectLevelSelect={setSelectedSubjectLevel}
						/>
					}
					EntityIcon={Subject}
				/>

				<SelectDrawerable
					placeholder='Grade Levels'
					sectionName='Grade Levels'
					value={selectedGradeLevels}
					onSaveSelectedEntity={setSelectedGradeLevels}
					selectedValueTranscript='Grade Levels'
					allItems={gradeLevelsAdaptedItems}
					EntityIcon={Subject}
				/>
			</GridBox>
		</MainSettingsBox>
	);
}
