import React, {createRef, ReactNode, RefObject} from 'react';
import {PrimaryButton, TransparentButton} from '@esgi/deprecated/ui-kit/buttons';
import {Modal, ModalBody, ModalFooter, ModalHeader, ModalManagerRef, Title} from '@esgi/deprecated/ui-kit/modal';
import RubricCreatingService from '../../services/rubric-creating-service';
import Complete from './components/complete-form/complete';
import styles from './complete.module.less';

interface Props {
	canAddToSubject: boolean;
	testDataService: RubricCreatingService;
	backClicked: () => void;
	confirmClicked: () => void;
	disableNewSubjectBtn?: boolean;
}

export default class CompleteDialog extends React.PureComponent<Props> {
	private completeStepRef: RefObject<Complete> = createRef();
	private modalManagerRef: ModalManagerRef = createRef();

	public render(): ReactNode {
		return <Modal modalManagerRef={this.modalManagerRef}>
			<ModalHeader>
				<Title>Select Settings</Title>
			</ModalHeader>
			<ModalBody className={styles.body}>
				<Complete canAddToSubject={this.props.canAddToSubject}
				          ref={this.completeStepRef}
				          testDataService={this.props.testDataService}
				          maxFormWidth={400}
				          maxWidth={440}
				          maxHeight={540}
									disableNewSubjectBtn={this.props.disableNewSubjectBtn}/>
			</ModalBody>
			<ModalFooter className={styles.footer}>
				<TransparentButton onClick={this.props.backClicked}>BACK</TransparentButton>
				<div className={styles.rightSection}>
					<PrimaryButton onClick={this.confirmClicked}>SAVE AND CLOSE</PrimaryButton>
				</div>
			</ModalFooter>
		</Modal>;
	}

	private confirmClicked = () => {
		this.completeStepRef.current?.validate().subscribe(r => {
			if (r) {
				this.props.confirmClicked();
			}
		});
	};
}
