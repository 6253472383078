import {Observable, takeUntil, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2SchoolAdminsUserAccountController} from '@esgi/contracts/esgi';
import {FormControl, FormGroup, ValidatorArgs, Validators} from '@esgi/ui/form';
import {ChangePasswordRequest, CurrentPasswordStatus} from '../../../../types';

export class ChangePasswordService extends BaseService {

	public form = new FormGroup({
		currentPassword: new FormControl('', {validators: [Validators.required()]}),
		currentPasswordIsValid: new FormControl(CurrentPasswordStatus.Valid),
		newPassword: new FormControl('', {validators: [Validators.required(), Validators.length.min(8)]}),
		confirmPassword: new FormControl('', {validators: [Validators.required()]}),
	});

	private controller = new V2SchoolAdminsUserAccountController();

	constructor() {
		super();

		const newPasswordValidator = (validationChain: Observable<ValidatorArgs<string>>) => {
			return validationChain.pipe(tap(args => {
				const currentPasswordIsValid = this.form.controls.currentPasswordIsValid.value === CurrentPasswordStatus.Valid;
				if (currentPasswordIsValid) {
					const currentPassword = this.form.controls.currentPassword.value;
					if (currentPassword === args.field.currentValue) {
						args.errors.push('oldAndNewPasswordEquals');
					}
				}
			}));
		};

		this.form.controls.newPassword.validators.push(newPasswordValidator);

		const confirmValidator = (validationChain: Observable<ValidatorArgs<string>>) => {
			return validationChain.pipe(tap(args => {
				const value = args.field.currentValue;
				const origin = this.form.controls.newPassword.value;
				if (value !== origin) {
					args.errors.push('confirm');
				}
			}));
		};

		this.form.controls.confirmPassword.validators.push(confirmValidator);

		const currentPasswordValidator = (validationChain: Observable<ValidatorArgs<string>>) => {
			return validationChain.pipe(tap(args => {
				const currentPasswordIsValid = this.form.controls.currentPasswordIsValid.value === CurrentPasswordStatus.Valid;
				if (!currentPasswordIsValid) {
					args.errors.push('currentPassword');
				}
			}));
		};

		this.form.controls.currentPassword.validators.push(currentPasswordValidator);

		const currentPasswordIsValidValidator = (validationChain: Observable<ValidatorArgs<CurrentPasswordStatus>>) => {
			return validationChain.pipe(tap(args => {
				const value = args.field.currentValue;
				if (value === CurrentPasswordStatus.NotValid) {
					args.errors.push('currentPassword');
				}
			}));
		};

		this.form.controls.currentPasswordIsValid.validators.push(currentPasswordIsValidValidator);

		this.form.controls.currentPassword.onChanged
			.pipe(takeUntil(this.destroy$))
			.subscribe(event => {
				if (event.prevState.value !== event.currState.value) {
					this.form.controls.currentPasswordIsValid.value = CurrentPasswordStatus.Valid;
				}
			});
	}

	public changePassword(data: ChangePasswordRequest) {
		return this.controller.tabsLoginChangePassword(data);
	}

	public override dispose() {
		this.controller.dispose();
	}
}