import {useState} from 'react';
import {currentTabPeriods, pastTabPeriods} from './constants';
import {PeriodType, SelectTabs} from '../../types';

type Parameters = {
	selectedTrackDateID: number;
	selectedPeriod: PeriodType;
};

export function useTabs({selectedTrackDateID, selectedPeriod}: Parameters) {
	const [activeTab, setActiveTab] = useState(() => {
		if (selectedTrackDateID !== 0 || pastTabPeriods.includes(selectedPeriod)) {
			return SelectTabs.Past;
		}

		if (currentTabPeriods.includes(selectedPeriod)) {
			return SelectTabs.Current;
		}

		return SelectTabs.DatePicker;
	});

	return {activeTab, setActiveTab};
}
