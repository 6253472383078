import {Dispatch, PropsWithChildren} from 'react';
import {TabID} from '../../types';
import {Drawer} from '@esgi/main/kits/common';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Content} from './index.styled';
import {Tabs} from './components/tabs';

type Props = PropsWithChildren<{
	activeTabID: TabID;
	setActiveTabID: Dispatch<TabID>;
}>;

export {contentRowGap} from './index.styled';

export function Body({activeTabID, setActiveTabID, children}: Props) {
	return (
		<Drawer.Body>
			<OverlayScrollbarsComponent
				defer
				style={{height: 'calc(100% + 0px)'}}
				options={{
					scrollbars: {autoHide: 'leave'},
				}}
			>
				<Content>
					<Tabs activeTabID={activeTabID} setActiveTabID={setActiveTabID} />

					{children}
				</Content>
			</OverlayScrollbarsComponent>
		</Drawer.Body>
	);
}
