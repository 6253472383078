import {ComponentPropsWithoutRef, Dispatch, forwardRef, useCallback, useLayoutEffect, useState} from 'react';
import {BaseComponentProps, Button} from '@esgi/ui';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Content} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {usePanelContext} from '../../context';
import {TestPanelMode} from '../../types/section';
import {TestPanelTestModel} from '../../types/tests';
import {Rearrange} from '../../components/lists/rearrange';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<typeof Content>, 'children'> & {
		onSaveClick: Dispatch<TestPanelTestModel[]>;
	};

export const RearrangeMode = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'tests-panel-rearrange-mode', css = {}, onSaveClick, ...contentProps}, forwardedRef) => {
		const [controlledSubjects, setControlledSubjects] = useState<TestPanelTestModel[]>([]);

		const {panelMode, setPanelMode, withRearrangeMode, tests} = usePanelContext();

		const isRearrangeMode = withRearrangeMode && panelMode === TestPanelMode.Rearrange;

		useLayoutEffect(() => {
			setControlledSubjects(isRearrangeMode ? tests : []);
		}, [isRearrangeMode, tests]);

		const handleSaveClick = useCallback(() => {
			onSaveClick(controlledSubjects);
			setPanelMode(TestPanelMode.View);
		}, [onSaveClick, controlledSubjects, setPanelMode]);

		if (isRearrangeMode) {
			return (
				<Content dataCy={dataCy} css={css} ref={forwardedRef} {...contentProps}>
					<OverlayScrollbarsComponent
						defer
						options={{
							scrollbars: {autoHide: 'leave'},
						}}
						style={{height: 'calc(100% + 0px)'}}
					>
						<Rearrange tests={controlledSubjects} onDragEnd={setControlledSubjects} />
					</OverlayScrollbarsComponent>

					<Button color='secondary' onClick={handleSaveClick}>
						<Text size='medium' bold color='base'>
							Save Changes
						</Text>
					</Button>
				</Content>
			);
		}

		return null;
	},
);
