import {Text} from '@esgi/ui/typography';
import {Button, Counter} from './index.styled';
import {FC, useCallback, useMemo} from 'react';
import {AnswerState} from '../../../../../../../types/common';
import {useUser} from '@esgi/core/authentication';
import {CorrectAnswerIcon} from '../correct-answer-icon';
import {IncorrectAnswerIcon} from '../incorrect-answer-icon';
import {NotTestedAnswerIcon} from '../not-tested-answer-icon';

type Disabled = {
	disabled: true;
};

type NotDisabled = {
	disabled: false;
	onClick: (state: AnswerState) => void;
	isFilterActive: boolean;
};

type Props = {
	answerState: AnswerState;
	countAnswers: number;
} & (Disabled | NotDisabled);

type FilterButtonStateModel = {
	Icon: FC;
	label: string;
};

export function FilterButton({countAnswers, answerState, ...props}: Props) {
	const currentUser = useUser();

	const isDisabled = props.disabled === true;

	const handleClick = useCallback(() => {
		if (!isDisabled) {
			props.onClick(answerState);
		}
	}, [answerState, isDisabled, props]);

	const {Icon, label} = useMemo<FilterButtonStateModel>(() => {
		const buttonInfoByAnswerState: Record<AnswerState, FilterButtonStateModel> = {
			[AnswerState.Correct]: {
				Icon: CorrectAnswerIcon,
				label: currentUser?.testResultsCorrectVerbiage ?? 'Correct',
			},
			[AnswerState.Incorrect]: {
				Icon: IncorrectAnswerIcon,
				label: currentUser?.testResultsIncorrectVerbiage ?? 'Incorrect',
			},
			[AnswerState.NotTested]: {
				Icon: NotTestedAnswerIcon,
				label: 'Not Tested',
			},
		};

		return buttonInfoByAnswerState[answerState];
	}, [currentUser, answerState]);

	return (
		<Button
			color='secondary'
			disabled={isDisabled}
			isActive={!isDisabled ? props.isFilterActive : undefined}
			onClick={handleClick}
			dataCy='filter-button'
		>
			<Icon />
			<Text size='large'>{label}</Text>
			<Counter dataCy='counter'>
				<Text size='small' font='mono'>
					{countAnswers}
				</Text>
			</Counter>
		</Button>
	);
}
