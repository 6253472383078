import {Test as TestContract} from '@esgi/contracts/esgi/types/esgi.assets/features/esgi.schooladminstorage/subjects/get/test';

export type {Class} from '@esgi/contracts/esgi/types/esgi.apigateway/api/endpoints/school-admins/storage/teachers-bucket/class';
export type {SpecialistGroupInformation as SpecialistGroup} from '@esgi/contracts/esgi/types/esgi.students/features/esgi.data/specialists-groups/information/specialist-group-information';
export type {GroupInformation as Group} from '@esgi/contracts/esgi/types/esgi.students/features/esgi.data/groups/information/group-information';
export type {User} from '@esgi/contracts/esgi/types/sso.accounts/features/esgi.data/users/user';
export type {Student} from '@esgi/contracts/esgi/types/esgi.students/features/esgi.data/students/student';
export type {School} from '@esgi/contracts/esgi/types/esgi.schools/schools/get/by-district-id/school';

export {SubjectType as SubjectTypeContract} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';

export enum TestContentArea {
	LanguageArts = 'Language Arts',
	Science = 'Science',
	SocialStudies = 'Social Studies',
	Math = 'Mathematics',
	Spanish = 'Spanish',
	SEL = 'SEL',
	Other = 'Other',
}

export enum TestType {
	None = 'None',
	YN = 'YN',
	Rubric = 'Rubric',
	Score = 'Score',
	General = 'General',
}

export enum TestScreenType {
	None = 'None',
	OneToOne = 'OneToOne',
	SelfAssessment = 'SelfAssessment',
}

export type Test = Omit<TestContract, 'contentArea' | 'type' | 'testScreenTypes'> & {
	contentArea: TestContentArea;
	type: TestType;
	testScreenTypes: TestScreenType[];
};

export enum SubjectType {
	Personal = 'Personal',
	Stock = 'Stock',
	Deployed = 'Deployed',
}

export enum SubjectLevel {
	Teacher = 'Teacher',
	School = 'School',
	District = 'District',
}

type SubjectTabModel<T extends SubjectType, R = unknown> = R & {
	id: number;
	name: string;
	hidden: boolean;
	level: SubjectLevel;
	tests: Test[];
	subjectType: T;
};

export type DeployedSubject = SubjectTabModel<SubjectType.Deployed> & {
	isPublished: boolean;
	allowedToAllTeachers: boolean;
	gradeLevels: number[];
	allowedSpecialists: number[];
};

export type StockSubject = SubjectTabModel<SubjectType.Stock>;

export type Subject = DeployedSubject | StockSubject;
