import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {CustomFilterProps} from 'ag-grid-react';
import {isNull, without} from 'underscore';

import {Input} from '@esgi/ui/controls';
import {Button, Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Close, Search} from '@esgi/ui/icons';

import {
	RootContainer,
	ContentContainer,
	ActionsContainer,
	HeaderContainer,
	ListContainer,
	ScrollInnerContainer,
	FiltersBox,
} from '../common.styled';
import {KeyType} from './types';
import {useEntitiesFilter} from './hook';
import {useAgGridFilter} from '../use-ag-grid-filter';
import {AllCheckedIcon, CheckboxStyled, EntityItem, NoItemsContainer} from './styled';

type Props = CustomFilterProps<any, any, KeyType[] | null | undefined>;

export function entitiesFilterParams<T extends {id: number}>(entities: T[]) {
	return {
		entities: entities,
	};
}

export function EntitiesFilter(props: Props) {
	const {
		entities,
		searchKeyWord,
		setSearchKeyWord,
		doesFilterPass,
		applyFilter,
		getDisplayName,
		emptyEntity,
	} = useEntitiesFilter(props);

	const withEmptyEntity = !isNull(emptyEntity);

	const {entitiesIDs, allEntitiesIDs} = useMemo(() => {
		const listEntitiesIDs = entities.map(e => e.id).sort();
		const allEntitiesIDs = withEmptyEntity ? [...listEntitiesIDs, emptyEntity.id] : listEntitiesIDs;

		return {
			entitiesIDs: listEntitiesIDs,
			allEntitiesIDs,
		};
	}, [emptyEntity, entities, withEmptyEntity]);

	const {filterPopupVisible, closePopup, columnName} = useAgGridFilter(props, {
		doesFilterPass,
	});

	const [selectedIDs, setSelectedIDs] = useState<KeyType[]>(allEntitiesIDs);

	useEffect(() => {
		if (props.model) {
			setSelectedIDs([...props.model]);
		} else {
			setSelectedIDs(allEntitiesIDs);
		}
	}, [filterPopupVisible]);

	const updateSelected = useCallback((entity: KeyType) => setSelectedIDs((prev) => prev.includes(entity) ? without(prev, entity) : [...prev, entity]), []);

	const selectAll = useCallback(() => setSelectedIDs([...allEntitiesIDs]), [allEntitiesIDs]);

	const onAllCheckedClicked = useCallback(() => setSelectedIDs((prev) => {
		const filteredPreviousState = prev.filter((id) => id !== emptyEntity?.id);
		const previousStateWithEmptyEntityID = prev.some((id) => id === emptyEntity?.id);

		if (filteredPreviousState.length === entitiesIDs.length) {
			return previousStateWithEmptyEntityID ? [emptyEntity!.id] : [];
		}

		return previousStateWithEmptyEntityID ? [...allEntitiesIDs] : [...entitiesIDs];
	}), [allEntitiesIDs, emptyEntity, entitiesIDs]);

	const apply = useCallback(() => {
		applyFilter(selectedIDs);
		closePopup();
	}, [applyFilter, selectedIDs, closePopup]);

	const allChecked = selectedIDs.length === allEntitiesIDs.length;
	const asMinimumOneSelected = selectedIDs.length >= 1;

	return <RootContainer>
		<ContentContainer>
			<HeaderContainer>
				<Button.Icon disabled={!entities.length} onClick={onAllCheckedClicked}>
					<AllCheckedIcon checked={allChecked}/>
				</Button.Icon>
				<Text size='small' color='mediumContrast'>{columnName}</Text>
				<Button.Icon onClick={closePopup}>
					<Close/>
				</Button.Icon>
			</HeaderContainer>
			<Input.Iconable placeholder='Search...' value={searchKeyWord}
			                onChange={(e) => setSearchKeyWord(e.target.value)}>
				<Search/>
			</Input.Iconable>
				<FiltersBox>
					<ListContainer>
						{entities.length ? (
							<OverlayScrollbarsComponent defer style={{height: 'calc(100% + 0px)', width: '100%'}}>
								<ScrollInnerContainer>
									{entities.map((entry) => (
										<EntityItem key={entry.id}>
											<Tooltip>
												<Tooltip.Trigger>
													<CheckboxStyled
														checked={selectedIDs.includes(entry.id)}
														onCheckedChange={() => updateSelected(entry.id)}
														label={getDisplayName(entry)}
													/>
												</Tooltip.Trigger>
												<Tooltip.Content>
													<Text size='large' color='tertiary'>
														{getDisplayName(entry)}
													</Text>
												</Tooltip.Content>
											</Tooltip>
										</EntityItem>
									))}
								</ScrollInnerContainer>
							</OverlayScrollbarsComponent>
						) : (
							<NoItemsContainer>
								<Text color='neutral56' size='small'>
									No entities to show
								</Text>
							</NoItemsContainer>
						)}
					</ListContainer>

					{!isNull(emptyEntity) && (
						<ListContainer>
							<CheckboxStyled
								checked={selectedIDs.includes(emptyEntity.id)}
								onCheckedChange={() => updateSelected(emptyEntity.id)}
								label={getDisplayName(emptyEntity)}
							/>
						</ListContainer>
					)}
				</FiltersBox>
			<ActionsContainer>
				<Button color='tertiary' disabled={allChecked} onClick={selectAll}>
					<Text size='medium' bold>Reset</Text>
				</Button>
				<Button color='secondary' onClick={apply} disabled={!asMinimumOneSelected}>
					<Text size='medium' bold>Apply</Text>
				</Button>
			</ActionsContainer>
		</ContentContainer>
	</RootContainer>;
}