import {useCallback, useMemo} from 'react';
import {WidgetDataModel, WidgetSortBy} from './types';

export function useSortItems({items, widgetSortBy}: {items: WidgetDataModel[]; widgetSortBy: WidgetSortBy}) {
	const compareItemsByName = useCallback((a: WidgetDataModel, b: WidgetDataModel) => {
		const aName = a.label.toLocaleLowerCase();
		const bName = b.label.toLocaleLowerCase();

		return aName.localeCompare(bName);
	}, []);

	return useMemo(() => {
		const copiedItems = [...items];

		if (widgetSortBy === WidgetSortBy.Alphabetical) {
			return copiedItems.sort(compareItemsByName);
		}

		if (widgetSortBy === WidgetSortBy.ConsistencyPerformance) {
			return copiedItems.sort((a, b) => {
				const aHeight = a.data[0].interquartileRange;
				const bHeight = b.data[0].interquartileRange;

				if (aHeight === bHeight) {
					return compareItemsByName(a, b);
				}

				return aHeight - bHeight;
			});
		}

		if (widgetSortBy === WidgetSortBy.Delta) {
			return copiedItems.sort((a, b) => {
				const aDelta = a.delta ?? Infinity;
				const bDelta = b.delta ?? Infinity;

				if (aDelta === bDelta) {
					return compareItemsByName(a, b);
				}

				return aDelta - bDelta;
			});
		}

		if (widgetSortBy === WidgetSortBy.MedianPerformance) {
			return copiedItems.sort((a, b) => {
				const aMedian = a.data[0].median;
				const bMedian = b.data[0].median;

				if (aMedian === bMedian) {
					return compareItemsByName(a, b);
				}

				return aMedian - bMedian;
			});
		}

		if (widgetSortBy === WidgetSortBy.ScoreSpread) {
			return copiedItems.sort((a, b) => {
				const aHeight = a.data[0].max - a.data[0].min;
				const bHeight = b.data[0].max - b.data[0].min;

				if (aHeight === bHeight) {
					return compareItemsByName(a, b);
				}

				return aHeight - bHeight;
			});
		}

		return copiedItems;
	}, [compareItemsByName, items, widgetSortBy]);
}
