import {GridBox} from '@esgi/ui/layout';
import {ButtonsDivider, CardHeader, SortingOption} from './index.styled';
import {Setup, SortDescending} from '@esgi/ui/icons';
import {Button, ContextMenu} from '@esgi/ui';
import {OneLinedText} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';
import {useMemo} from 'react';
import {Pagination} from '../../../../../kit/pagination';
import {useWidgetCardContext} from '../../../../context';
import {HeaderProps, SortableData} from './types';

export function Header<T extends string>(props: HeaderProps<T>) {
	const {name, setEditMode, totalPages, currentPageIndex, nextPage, previousPage, totalDataLength} =
		useWidgetCardContext();

	const withPagination = totalPages >= 2;

	const sortableData = useMemo<SortableData<T>>(() => {
		if (!('sortableItems' in props)) {
			return {
				withSortableData: false,
			};
		}

		const {setWidgetSortBy, sortableItems, widgetSortBy} = props;

		return {
			withSortableData: true,
			withSortableItems: !!sortableItems.length,
			setWidgetSortBy,
			sortableItems,
			widgetSortBy,
		};
	}, [props]);

	const withButtonsDivider = withPagination && sortableData.withSortableData && sortableData.withSortableItems;

	return (
		<CardHeader>
			<GridBox flow='column' gap='4' align='center'>
				<Button.Icon withBackgroundHover onClick={setEditMode}>
					<Setup />
				</Button.Icon>

				<OneLinedText size='medium' bold color='base'>
					{name}
				</OneLinedText>
			</GridBox>

			{totalDataLength >= 1 && (
				<GridBox flow='column' align='center' gap='4'>
					{withPagination && (
						<Pagination
							currentPageIndex={currentPageIndex}
							nextPage={nextPage}
							previousPage={previousPage}
							totalPages={totalPages}
						/>
					)}

					{withButtonsDivider && <ButtonsDivider />}

					{sortableData.withSortableData && sortableData.withSortableItems && (
						<ContextMenu.Root>
							<ContextMenu.Trigger>
								<Button.Icon withBackgroundHover>
									<SortDescending />
								</Button.Icon>
							</ContextMenu.Trigger>
							<ContextMenu.Content>
								{sortableData.sortableItems.map(({value, label}) => (
									<SortingOption
										onClick={() => sortableData.setWidgetSortBy(value)}
										isActive={sortableData.widgetSortBy === value}
										key={value}
									>
										<Text size='medium' color='base'>
											{label}
										</Text>
									</SortingOption>
								))}
							</ContextMenu.Content>
						</ContextMenu.Root>
					)}
				</GridBox>
			)}
		</CardHeader>
	);
}
