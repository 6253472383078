import {BaseComponentProps} from '@esgi/ui';
import {CSS} from '@stitches/react';
import {ComponentPropsWithoutRef, forwardRef, useCallback, useMemo, useState} from 'react';
import {LoadingContext, LoadingContextValue} from '../../context';
import {Svg} from './index.styled';
import {ShapeOffsetState, SvgValue, UpdateShapeOffset} from '../../types';

type Props = ComponentPropsWithoutRef<'svg'> &
	Pick<BaseComponentProps, 'dataCy'> & {
		css?: Omit<CSS, 'width' | 'height'>;
		size?: number;
	};

export const Root = forwardRef<SVGSVGElement, Props>(
	({dataCy = 'loading-root', css = {}, size = 64, children, ...svgProps}, forwardedRef) => {
		const [shapeSizes, setShapeSizes] = useState<ShapeOffsetState>({
			ring: 0,
			arc: 0,
		});

		const updateShapeOffset = useCallback<UpdateShapeOffset>(({shape, offset}) => {
			setShapeSizes((currentState) => ({
				...currentState,
				[shape]: offset,
			}));
		}, []);

		const svgValue = useMemo<SvgValue>(
			() => ({
				size,
				radius: size / 2,
				center: {
					x: size / 2,
					y: size / 2,
				},
			}),
			[size],
		);

		const cssProps = useMemo<CSS>(
			() => ({
				width: size,
				height: size,
				...css,
			}),
			[css, size],
		);

		const contextValue = useMemo<LoadingContextValue>(
			() => ({
				svgValue,
				maxShapeOffset: Math.max(...Object.values(shapeSizes)),
				updateShapeOffset,
			}),
			[shapeSizes, svgValue, updateShapeOffset],
		);

		return (
			<LoadingContext.Provider value={contextValue}>
				<Svg
					data-cy={dataCy}
					xmlns='http://www.w3.org/2000/svg'
					viewBox={`0 0 ${size} ${size}`}
					css={cssProps}
					ref={forwardedRef}
					{...svgProps}
				>
					{children}
				</Svg>
			</LoadingContext.Provider>
		);
	},
);
