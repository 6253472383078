import {configureStore} from '@reduxjs/toolkit';
import {subjectsSlice, studentsSlice} from './flat-slices';
import {
	teachersSlice,
	classesSlice,
	groupsSlice,
	specialistGroupsSlice,
	schoolSpecialistsSlice,
} from './bucket-slices';
import {createReactHook} from './react';
import {fetchMiddleware, State} from './slice-utils';
import {EventBusManager, AppEvent} from '@esgillc/events';
import {
	Class,
	Group,
	SpecialistGroup,
	Student,
	Subject,
	User,
} from '../types';
import {serviceAdapter} from './rx-js';

@AppEvent()
export class ClearStorageEvent {}

const reducers = {
	subjects: subjectsSlice.slice.reducer,
	teachers: teachersSlice.slice.reducer,
	classes: classesSlice.slice.reducer,
	groups: groupsSlice.slice.reducer,
	specialistGroups: specialistGroupsSlice.slice.reducer,
	schoolSpecialists: schoolSpecialistsSlice.slice.reducer,
	students: studentsSlice.slice.reducer,
};

const slices = {
	subjects: subjectsSlice,
	teachers: teachersSlice,
	classes: classesSlice,
	groups: groupsSlice,
	specialistGroups: specialistGroupsSlice,
	schoolSpecialists: schoolSpecialistsSlice,
	students: studentsSlice,
};

export const reduxStore = configureStore({
	reducer: reducers,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoreActions: true,
			},
		}),
});

const subjectsMiddleware = fetchMiddleware(() =>
	reduxStore.dispatch(subjectsSlice.fetch({invalidateCache: false})),
);
const studentsMiddleware = fetchMiddleware(() =>
	reduxStore.dispatch(studentsSlice.fetch({invalidateCache: false})),
);
const teachersMiddleware = fetchMiddleware(() =>
	reduxStore.dispatch(teachersSlice.fetch({invalidateCache: false})),
);
const classesMiddleware = fetchMiddleware(() =>
	reduxStore.dispatch(classesSlice.fetch({invalidateCache: false})),
);
const groupsMiddleware = fetchMiddleware(() =>
	reduxStore.dispatch(groupsSlice.fetch({invalidateCache: false})),
);
const specialistGroupsMiddleware = fetchMiddleware(() =>
	reduxStore.dispatch(specialistGroupsSlice.fetch({invalidateCache: false})),
);
const schoolSpecialistsMiddleware = fetchMiddleware(() =>
	reduxStore.dispatch(schoolSpecialistsSlice.fetch({invalidateCache: false})),
);

export const useSubjects = createReactHook<
	State<Subject>,
	typeof slices.subjects.slice
>(slices.subjects.slice, subjectsMiddleware);
export const useStudents = createReactHook<
	State<Student>,
	typeof slices.students.slice
>(slices.students.slice, studentsMiddleware);
export const useTeachers = createReactHook<
	State<User>,
	typeof slices.teachers.slice
>(slices.teachers.slice, teachersMiddleware);
export const useClasses = createReactHook<
	State<Class>,
	typeof slices.classes.slice
>(slices.classes.slice, classesMiddleware);
export const useGroups = createReactHook<
	State<Group>,
	typeof slices.groups.slice
>(slices.groups.slice, groupsMiddleware);
export const useSpecialistGroups = createReactHook<
	State<SpecialistGroup>,
	typeof slices.specialistGroups.slice
>(slices.specialistGroups.slice, specialistGroupsMiddleware);
export const useSchoolSpecialists = createReactHook<
	State<User>,
	typeof slices.schoolSpecialists.slice
>(slices.schoolSpecialists.slice, schoolSpecialistsMiddleware);

export const subjectsStore = serviceAdapter<Subject>(
	// @ts-ignore
	slices.subjects.slice,
	reduxStore,
	subjectsMiddleware,
);
export const studentsStore = serviceAdapter<Student>(
	slices.students.slice,
	reduxStore,
	studentsMiddleware,
);
export const teachersStore = serviceAdapter<User>(
	slices.teachers.slice,
	reduxStore,
	teachersMiddleware,
);
export const classesStore = serviceAdapter<Class>(
	slices.classes.slice,
	reduxStore,
	classesMiddleware,
);
export const groupsStore = serviceAdapter<Group>(
	slices.groups.slice,
	reduxStore,
	groupsMiddleware,
);
export const specialistGroupsStore = serviceAdapter<SpecialistGroup>(
	slices.specialistGroups.slice,
	reduxStore,
	specialistGroupsMiddleware,
);
export const schoolSpecialistsStore = serviceAdapter<User>(
	slices.schoolSpecialists.slice,
	reduxStore,
	schoolSpecialistsMiddleware,
);

export class ClearSpecificStoragesEvent {
	constructor(public storeNames: (keyof typeof slices)[]) {}
}

new EventBusManager().subscribe(ClearStorageEvent, () => {
	reduxStore.dispatch(subjectsSlice.slice.actions.invalidateCache());
	reduxStore.dispatch(subjectsSlice.fetch({invalidateCache: true}));
	reduxStore.dispatch(studentsSlice.slice.actions.invalidateCache());
	reduxStore.dispatch(studentsSlice.fetch({invalidateCache: true}));
	reduxStore.dispatch(teachersSlice.slice.actions.invalidateCache());
	reduxStore.dispatch(teachersSlice.fetch({invalidateCache: true}));
	reduxStore.dispatch(classesSlice.slice.actions.invalidateCache());
	reduxStore.dispatch(classesSlice.fetch({invalidateCache: true}));
	reduxStore.dispatch(groupsSlice.slice.actions.invalidateCache());
	reduxStore.dispatch(groupsSlice.fetch({invalidateCache: true}));
	reduxStore.dispatch(specialistGroupsSlice.slice.actions.invalidateCache());
	reduxStore.dispatch(specialistGroupsSlice.fetch({invalidateCache: true}));
	reduxStore.dispatch(schoolSpecialistsSlice.slice.actions.invalidateCache());
	reduxStore.dispatch(schoolSpecialistsSlice.fetch({invalidateCache: true}));
});

new EventBusManager().subscribe(
	ClearSpecificStoragesEvent,
	({storeNames}: { storeNames: (keyof typeof slices)[] }) => {
		for (const storeName of storeNames) {
			const currentSlice = slices[storeName];
			reduxStore.dispatch(currentSlice.slice.actions.invalidateCache());
			// @ts-ignore
			reduxStore.dispatch(currentSlice.fetch({invalidateCache: true}));
		}
	},
);

export type RootState = ReturnType<typeof reduxStore.getState>;
export type StoreDispatch = typeof reduxStore.dispatch;
