import {styled} from '@esgi/ui/theme';
import {Button, Cheap, Online} from '@esgi/ui';

export const StyledCheap = styled(Cheap, {
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,
	backgroundColor: '$surface',
	border: '1px solid $primaryMuted',

	variants: {
		color: {
			neutral: {
				color: '$primaryVivid',
				backgroundColor: '$surface',
			},
		},
	},
});

export const StyledIconDot = styled(Online, {
	'& > path': {
		fill: '$mediumContrast',
	},
});

export const StyledButton = styled(Button, {
	minWidth: 0,
	padding: 4,

	'& > svg:first-child': {
		margin: 0,
	},

	'& > svg:last-child': {
		margin: 0,
	},
});