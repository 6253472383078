import {AgGrid} from './components/ag-grid';
import {Body} from './components/body';
import {Base as BaseBody} from './components/body/variant/base';
import {Header} from './components/header';
import {Base as BaseHeader} from './components/header/variant/base';
import {Root} from './components/root';
import {SubHeader} from './components/sub-header';
import {Folder as SubHeaderFolder} from './components/sub-header/variant/folder';
import {usePageContentContext} from './context';

export const Page = {
	Root,
	Header: Object.assign(Header, {
		Base: BaseHeader,
	}),
	SubHeader: Object.assign(SubHeader, {
		Folder: SubHeaderFolder,
	}),
	Body: Object.assign(Body, {
		Base: BaseBody,
	}),
	AgGrid,
	useContext: usePageContentContext,
};
