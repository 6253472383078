import {useUser} from '@esgi/core/authentication';
import {ClassicHierarchyLevel, HierarchyMode} from 'modules/hierarchy/core/models';
import {HierarchySnapshot} from 'modules/hierarchy/models';
import {useMemo} from 'react';
import {isNull} from 'underscore';
import {SelectedStudent} from '../../types/section';

export function useHierarchy({id, teacherID, schoolID, classIDs, groupsIDs}: SelectedStudent) {
	const currentUser = useUser();

	return useMemo(() => {
		if (isNull(currentUser) || isNull(teacherID)) {
			return null;
		}

		const districtID = currentUser.districtID;

		const hierarchy: HierarchySnapshot = {
			districtID,
			mode: HierarchyMode.Classic,
			studentSort: currentUser.studentSort,
			classic: {
				districtID,
				schoolID: schoolID ?? 0,
				teacherID: teacherID,
				classID: classIDs.length === 1 ? classIDs[0]! : 0,
				groupID: groupsIDs.length === 1 ? groupsIDs[0]! : 0,
				schoolsGroupID: 0,
				teachersGroupID: 0,
				studentID: id,
				selected: {
					level: ClassicHierarchyLevel.Student,
					levelID: id,
				},
			},
			specialist: {
				studentID: 0,
				filter: {
					schoolID: 0,
					teacherID: 0,
				},
				type: 0,
				groupID: 0,
				userID: 0,
				districtID: 0,
				groupOfSpecialistsID: 0,
				selected: {
					level: 0,
					levelID: 0,
				},
			},
			preAssess: {
				studentID: 0,
				groupID: 0,
				userID: 0,
				districtID: 0,
				selected: {
					level: 0,
					levelID: 0,
				},
			},
		};

		return hierarchy;
	}, [classIDs, currentUser, groupsIDs, id, schoolID, teacherID]);
}
