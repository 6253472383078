import {Container, EmptyContentBox} from './index.styled';
import {Text} from '@esgi/ui/typography';

export function NoRows() {
	return (
		<Container>
			<EmptyContentBox>
				<Text size='small' font='mono' color='neutral40'>
					No data to display
				</Text>
			</EmptyContentBox>
		</Container>
	);
}
