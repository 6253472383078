import {useMemo} from 'react';
import {FormControl} from '@esgi/ui/form';
import {DatePickerData} from '@esgi/main/kits/admin';
import {StudentsTwoPeriodsForm, StudentsTwoPeriodsFormColorItem} from '../../../../../forms-data/types';
import {Color, DatePickerForm, FormColorPicker, Widget, WidgetCard} from '@esgi/main/features/admins/dashboard';

type Props = {
	form: StudentsTwoPeriodsForm;
	dateRangeControl: FormControl<DatePickerData>;
	colorControl: FormControl<StudentsTwoPeriodsFormColorItem>;
	excludedColor: Color | null;
};

export function PeriodRow({form, colorControl, excludedColor, dateRangeControl}: Props) {
	const filteredColorItems = useMemo(
		() => Widget.colorItems.filter(({value}) => value !== excludedColor),
		[excludedColor],
	);

	return (
		<WidgetCard.EditMode.PeriodColorBox>
			<WidgetCard.EditMode.FormElement control={colorControl}>
				<FormColorPicker items={filteredColorItems} />
			</WidgetCard.EditMode.FormElement>
			<WidgetCard.EditMode.FormElement control={dateRangeControl}>
				<DatePickerForm form={form} />
			</WidgetCard.EditMode.FormElement>
		</WidgetCard.EditMode.PeriodColorBox>
	);
}
