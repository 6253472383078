import {
	ActionsBox,
	Header,
	SelfAssessButton,
	SelfAssessIconBox,
} from './index.styled';
import {Iep, SelfAssess} from '@esgi/ui/icons';
import {TestSessionsContextMenu} from '../test-sessions-context-menu';
import {TestCardContextMenu} from '../test-card-context-menu';
import {useUser} from '@esgi/core/authentication';
import {Test, TestSession} from '../../../../types/test-panel';
import {isNull} from 'underscore';
import {SelectedStudent} from '../../../../types/section';
import {
	DistrictSubject,
	SubjectModel,
} from '@esgi/main/kits/subject-selection-panel';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {OnMoveToTest} from '../../types';
import {MultipleLabels} from '../../../../../multiple-labels';
import {useMemo} from 'react';

type Props = {
	testInfo: Test;
	testSessions: TestSession[];
	selectedSubject: SubjectModel;
	selectedStudent: SelectedStudent;
	districtSubjects: DistrictSubject[];
	onMoveToTest: OnMoveToTest;
	onRemoveTest: VoidFunction;
	userCanAddTestSession: boolean;
	openAddSessionDialog: VoidFunction;
};

export function CardHeader({
	testInfo,
	testSessions,
	selectedSubject,
	selectedStudent,
	districtSubjects,
	onMoveToTest,
	onRemoveTest,
	userCanAddTestSession,
	openAddSessionDialog,
}: Props) {
	const currentUser = useUser();

	const stateStandarts = useMemo(() => {
		if (!testInfo.stateStandards.length) {
			return null;
		}

		return testInfo.stateStandards.map(({name}) => name) as [string, ...string[]];
	}, [testInfo.stateStandards]);

	return (
		<Header withStateStandarts={!isNull(stateStandarts)}>
			<TestCard.Title size='xxLarge' color='base'>
				{testInfo.name}
			</TestCard.Title>

			<TestCard.TestLabel />

			{!isNull(stateStandarts) && <MultipleLabels items={stateStandarts} />}

			<ActionsBox>
				{testInfo.hasIEPGoals && (
					<>
						<TestCard.IconBox>
							<Iep />
						</TestCard.IconBox>
						<TestCard.ActionButtonsDivider />
					</>
				)}

				<TestSessionsContextMenu
					testInfo={testInfo}
					testSessions={testSessions}
					selectedStudent={selectedStudent}
					selectedSubject={selectedSubject}
					userCanAddTestSession={userCanAddTestSession}
					openAddSessionDialog={openAddSessionDialog}
				/>

				{!isNull(currentUser) && (
					<>
						<TestCard.ActionButtonsDivider />

						<TestCardContextMenu
							currentUser={currentUser}
							testInfo={testInfo}
							selectedSubject={selectedSubject}
							districtSubjects={districtSubjects}
							onMoveToTest={onMoveToTest}
							onRemoveTest={onRemoveTest}
						/>
					</>
				)}

				{Boolean(currentUser?.showSelfAssessOption) && testInfo.selfAssessmentTestAllowed && (
					<SelfAssessButton>
						<SelfAssessIconBox>
							<SelfAssess />
						</SelfAssessIconBox>
					</SelfAssessButton>
				)}
			</ActionsBox>
		</Header>
	);
}
