import {TestInfo, Answer} from '../../../../../types/view-edit-session-details';
import {AnswerState} from '../../../../../types/common';

export function concatNotTestedQuestions(testInfo: TestInfo, answers: Answer[]): Answer[] {
	const parentTestSessionID = answers[0]?.parentTestSessionID ?? null;
	const mapAnswer = ({id: questionId}: {id: number}): Answer =>
		answers.find((answer) => answer.questionID === questionId) ?? {
			answerState: AnswerState.NotTested,
			comment: '',
			questionID: questionId,
			parentTestSessionID: parentTestSessionID,
		};
	return testInfo.questions.map(mapAnswer);
}
