import {SchoolCutoff} from './types';

export function getDistrictCutoffEnumValue(value: number) {
	switch (value) {
		case 5:
			return SchoolCutoff.Five;
		case 10:
			return SchoolCutoff.Ten;
		case 15:
			return SchoolCutoff.Fifteen;
		case 20:
			return SchoolCutoff.Twenty;
		case 25:
			return SchoolCutoff.TwentyFive;
		default:
			return SchoolCutoff.None;
	}
}
