import {CaseReducerActions, Draft, Slice, SliceCaseReducers, SliceSelectors} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useMemo} from 'react';

export function createReactHook<
	State,
	SliceType extends Slice,
>(
	slice: SliceType,
	...hookMiddlewares: HookMiddleware<State, SliceType['actions']>[]
): () => [State, CaseReducerActions<SliceCaseReducers<State>, string>] {
	return createReactHookInner(slice, hookMiddlewares as any) as any;
}

export function createReactHookInner<
	State,
	CaseReducers extends SliceCaseReducers<State> = SliceCaseReducers<State>,
	Name extends string = string,
	ReducerPath extends string = Name,
	Selectors extends SliceSelectors<State> = SliceSelectors<State>,
>(slice: Slice<State, CaseReducers, Name, ReducerPath, Selectors>, hookMiddlewares: HookMiddleware<State, CaseReducerActions<CaseReducers, Name>>[]) {
	function useStoreHook(): [State, CaseReducerActions<CaseReducers, Name>] {
		const state = useSelector((state: any) => state[slice.name]) as State;
		const dispatch = useDispatch();
		const actions = useMemo(() => {
			const out: CaseReducerActions<CaseReducers, Name> = {} as any;
			for (const actionKey in slice.actions) {
				const action = slice.actions[actionKey];
				// @ts-ignore
				out[actionKey] = function () {
					// @ts-ignore
					dispatch(action(...arguments));
				};
			}
			return out;
		}, [dispatch]);

		useEffect(() => {
			for (const hookMiddleware of hookMiddlewares) {
				try {
					hookMiddleware(state, actions);
				} catch (e) {
					console.error(e);
				}
			}
		}, [state]);

		return [state, actions];
	}
	return useStoreHook;
}

export type HookMiddleware<State, Actions> = (state: State, actions: Actions) => void;