import {Drawer} from '@esgi/main/kits/common';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Header = styled(GridBox, {
	gap: '$4',
	gridTemplateColumns: '1fr auto',
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingTop: 20,
});

export const HeaderTitle = styled(GridBox, {
	gridTemplateColumns: 'auto 1fr',
	gap: '$1',
	alignItems: 'center',

	'& > svg': {
		width: 24,
		height: 24,
	},
});

export const HeaderButtons = styled(FlexBox, {
	gap: '$4',
	alignItems: 'center',
});

export const Divider = styled(Box, {
	height: 1,
	backgroundColor: '$border',
	marginInline: -Drawer.drawerPaddingInline,
	gridColumn: '1 / -1',
});
