import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CSS} from '@stitches/react';

export const navigationBarCSS: CSS = {
	width: '100%',
	paddingTop: 12,
	paddingLeft: 8,
	paddingRight: 8,
	display: 'grid',
	gridTemplateRows: 'auto 1fr auto',
	justifyContent: 'center',
	justifyItems: 'center',
	alignItems: 'start',
	gap: 20,
	zIndex: 10,
};

export const HeaderContentBox = styled(GridBox, {
	gap: '$4',
	maxWidth: 80,
});

export const navigationBarContentCSS: CSS = {
	display: 'grid',
	gap: 8,
};

export const FooterContentBox = styled(GridBox, {
	gap: 8,
	justifyItems: 'center',

	'& > button:last-child': {
		boxShadow: '0px 4px 8px 0px rgba(230, 232, 239, 0.48), 0px 2px 12px 0px rgba(0, 0, 0, 0.06)',
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
	},
});
