import {isEqual} from 'underscore';
import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {createReportsSettingsForm} from './form';
import {SettingsData, ShowStudentIDType} from '../../../../../types';

export class ReportsSettingsService extends BaseService {
	public form = createReportsSettingsForm();

	private readonly initData = new BehaviorSubject<SettingsData>(null);

	public init(data: SettingsData) {
		this.initData.next(data);
		this.form.value = {
			reportShowStudentID: data.reportShowStudentID as unknown as ShowStudentIDType,
		};
	}

	public compareStatesForEquality() {
		const {reportShowStudentID} = this.initData.value;

		const initialState = {
			reportShowStudentID,
		};

		const currentState = {
			reportShowStudentID: this.form.controls.reportShowStudentID.value,
		};

		return !isEqual(currentState, initialState);
	}
}
