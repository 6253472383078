import {V2SessionDetailsRubricController} from '@esgi/contracts/esgi';
import {BaseService} from '@esgi/core/service';
import {Student, Test} from '../../../../../types/common';
import {BehaviorSubject, tap} from 'rxjs';
import {CriteriaModel, RubricScoreState} from './types';

export class Service extends BaseService {
	public rubricCriteria$ = new BehaviorSubject<CriteriaModel[]>([]);

	private controllerRubricSession = new V2SessionDetailsRubricController();

	public init({testID, studentID}: {testID: Test['id']; studentID: Student['id']}) {
		return this.controllerRubricSession
			.initCreate({
				studentID,
				testID,
			})
			.pipe(
				tap(({criteriaModels}) => {
					this.rubricCriteria$.next(criteriaModels);
				}),
			);
	}

	public save({
		testID,
		testDate,
		studentID,
		summaryNotes,
		rubricScoreState,
	}: {
		testID: Test['id'];
		studentID: Student['id'];
		testDate: string;
		summaryNotes: string;
		rubricScoreState: RubricScoreState[];
	}) {
		return this.controllerRubricSession.create({
			testDate,
			rubricID: testID,
			studentID,
			summaryNotes,
			answers: rubricScoreState.map(({comment, criteriaID, score}) => ({
				criteriaID,
				score,
				notes: comment as string,
			})),
		});
	}

	public override dispose() {
		this.controllerRubricSession.dispose();
	}
}
