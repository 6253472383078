import {createContext, Dispatch, useContext} from 'react';
import {isNull} from 'underscore';
import {Entities, PanelMode, TabID} from './types/section';
import {SubjectModel} from './types/subject';

export type PanelContextValue = {
	entities: Entities;
	selectedSubjectID: SubjectModel['id'] | null;
	setSelectedSubjectID: Dispatch<number | null>;
	panelMode: PanelMode;
	setPanelMode: Dispatch<PanelMode>;
	skeleton: boolean;
	tabsIDsWithRearrangeContent: TabID[];
	addTabWithRearrangeMode: Dispatch<TabID>;
	removeTabWithRearrangeMode: Dispatch<TabID>;
};

export const PanelContext = createContext<PanelContextValue | null>(null);

export function usePanelContext() {
	const context = useContext(PanelContext);

	if (isNull(context)) {
		throw new Error('usePanelPanelContext is null');
	}

	return context;
}
