import {V2DistrictAdminsGroupsController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useCallback, useEffect, useRef, useState} from 'react';
import {EditGroupForm} from '../form-data/types';
import {groupsStore, studentsStore, Group, Student} from '@esgi/main/libs/admin-store';
import {isNull} from 'underscore';
import {dispatchAppEvent} from '@esgillc/events';
import {GroupUpdatedEvent} from '../../../events';

export type UpdatedGroupDataModel = Pick<Group, 'id' | 'name'> & {
	studentsIDs: Student['id'][];
};

type Parameters = {
	form: EditGroupForm;
	currentGroup: Group | null;
	initialStudentsIDs: Student['id'][];
	closeDrawer: VoidFunction;
};

export function useSave({form, currentGroup, initialStudentsIDs, closeDrawer}: Parameters) {
	const groupsController = useDisposable(V2DistrictAdminsGroupsController);

	const [updatedGroupDataModel, setUpdatedGroupDataModel] = useState<UpdatedGroupDataModel | null>(null);
	const [updatedGroupData, updateGroup] = useCancelableRequest(groupsController.update);

	const updated = useRef(false);

	useEffect(() => {
		if (isAsyncSucceed(updatedGroupData) && !isNull(updatedGroupDataModel) && !updated.current) {
			groupsStore().update?.((item) => {
				if (item.id === updatedGroupDataModel.id) {
					return {
						...item,
						name: updatedGroupDataModel.name,
					};
				}

				return item;
			});

			studentsStore().update?.((item) => {
				if (initialStudentsIDs.includes(item.id) && !updatedGroupDataModel.studentsIDs.includes(item.id)) {
					return {
						...item,
						groupsIDs: item.groupsIDs.filter((id) => id !== updatedGroupDataModel.id),
					};
				}

				if (updatedGroupDataModel.studentsIDs.includes(item.id)) {
					return {
						...item,
						groupsIDs: [...new Set([...item.groupsIDs, updatedGroupDataModel.id])],
					};
				}

				return item;
			});

			dispatchAppEvent(
				GroupUpdatedEvent,
				new GroupUpdatedEvent({
					id: updatedGroupDataModel.id,
					name: updatedGroupDataModel.name,
				}),
			);

			updated.current = true;
			closeDrawer();
		}
	}, [closeDrawer, initialStudentsIDs, updatedGroupData, updatedGroupDataModel]);

	const onEditGroup = useCallback(() => {
		if (isNull(currentGroup)) {
			throw new Error('currentGroup is null');
		}

		const {groupName, studentsIDs} = form.value;

		updateGroup({
			groupID: currentGroup.id,
			name: groupName,
			studentIDs: studentsIDs,
		});

		setUpdatedGroupDataModel({
			id: currentGroup.id,
			name: groupName,
			studentsIDs,
		});

		updated.current = false;
	}, [currentGroup, form, updateGroup]);

	return {
		onEditGroup,
		isDataSaving: isAsyncPending(updatedGroupData),
	};
}
