import {FlexBox, NavigationBar, useNavigationBarContext} from '@esgi/ui/layout';
import {LogoBeta} from '@esgi/ui';
import {SchoolYearSelect} from '@esgi/main/features/common/school-year-select';

interface Props {
	onSchoolYearChanged: VoidFunction;
}

export function VerticalPanelHead({onSchoolYearChanged}: Props) {
	const {expanded} = useNavigationBarContext();

	return (
		<NavigationBar.Head
			css={{
				padding: '16px 20px 14px 20px',
			}}
		>
			<NavigationBar.Item
				withHoverEffects={false}
				css={{paddingBottom: expanded ? 24 : '30px', width: '100%', cursor: 'auto'}}
			>
				{<LogoBeta width={80} height={32} />}
			</NavigationBar.Item>

			<FlexBox css={{height: '40px', alignItems: 'center'}}>
				{expanded && (
					<FlexBox align='center'>
						<SchoolYearSelect onSchoolYearChanged={onSchoolYearChanged} />
					</FlexBox>
				)}
			</FlexBox>
		</NavigationBar.Head>
	);
}
