import {Dispatch, useCallback, useEffect, useMemo, useState} from 'react';
import {ExpandableSelectableBlockTab} from './types';
import {Checkbox} from '../checkbox.styled';
import {Container, Divider, SelectableItemsBox} from './index.styled';
import {CheckedState} from '@radix-ui/react-checkbox';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {TabItem, Tabs} from '../../../kit';
import {isNull} from 'underscore';

type Props<T extends string> = {
	sectionName: string;
	isSectionActive: boolean;
	onSectionActiveChanged: Dispatch<boolean>;
	value: ExpandableSelectableBlockTab<T>[];
	setValue: Dispatch<ExpandableSelectableBlockTab<T>[]>;
	withoutIdenticalTabsItems?: boolean;
	disabled?: boolean;
};

export function ExpandableSelectableBlock<T extends string>({
	sectionName,
	isSectionActive,
	onSectionActiveChanged,
	value,
	setValue,
	withoutIdenticalTabsItems = false,
	disabled,
}: Props<T>) {
	const onSectionCheckedChanged = useCallback(
		(value: CheckedState) => {
			onSectionActiveChanged(!!value);
		},
		[onSectionActiveChanged],
	);

	const [tabs, setTabs] = useState<TabItem<T>[]>([]);

	useEffect(() => {
		const possibleTabs = value.map<TabItem<T>>(({value, label}) => ({value, content: label}));

		const possibleTabsValues = possibleTabs.map(({value}) => value);

		setTabs((currentTabs) => {
			if (
				currentTabs.length !== possibleTabs.length ||
				possibleTabsValues.some((tabID) => !currentTabs.find(({value}) => value === tabID))
			) {
				return possibleTabs;
			}

			return currentTabs;
		});
	}, [value]);

	const {activeTabID, visibleItems, selectedItems} = useMemo(() => {
		const activeTab = value.find(({isActive}) => isActive);

		return {
			activeTabID: activeTab?.value ?? null,
			visibleItems: activeTab?.allItems ?? [],
			selectedItems: activeTab?.selectedItems ?? [],
		};
	}, [value]);

	const onActiveTabChanged = (tabID: T) => {
		setValue(value.map((item) => ({...item, isActive: item.value === tabID})));
	};

	const onSelectedItemChanged = (id: number, checked: boolean) => {
		setValue(
			value.map((item) => {
				if (item.value === activeTabID) {
					return {
						...item,
						selectedItems: checked
							? [...item.selectedItems, id]
							: item.selectedItems.filter((selectedID) => selectedID !== id),
					};
				}

				return item;
			}),
		);
	};

	return (
		<Container>
			<Checkbox
				checked={isSectionActive}
				onCheckedChange={onSectionCheckedChanged}
				label={sectionName}
				disabled={disabled}
			/>
			<Divider />

			{!isNull(activeTabID) && (
				<Tabs
					value={activeTabID}
					setValue={onActiveTabChanged}
					items={tabs}
					withoutIdenticalItems={withoutIdenticalTabsItems}
					disabled={!isSectionActive || disabled}
				/>
			)}

			{!!visibleItems.length && isSectionActive && (
				<OverlayScrollbarsComponent
					defer
					options={{
						scrollbars: {autoHide: 'leave'},
					}}
					style={{height: 'calc(100% + 0px)'}}
				>
					<SelectableItemsBox>
						{visibleItems.map(({id, name}) => (
							<Checkbox
								checked={selectedItems.includes(id)}
								onCheckedChange={(value) => onSelectedItemChanged(id, Boolean(value))}
								label={name}
								disabled={disabled}
								key={id}
							/>
						))}
					</SelectableItemsBox>
				</OverlayScrollbarsComponent>
			)}
		</Container>
	);
}
