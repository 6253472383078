import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {PanelDivider} from '../../../components/panels.styled';
import {GridBox} from '@esgi/ui/layout';
import {SettingPanel} from '@esgi/main/features/admins/subject-management';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {Container, MainContentBox} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Subject} from '@esgi/main/libs/admin-store';
import {useStoreSubjectVisability} from '../../hooks/use-store-subject-visability';

type Props = {
	subjectFromStore: Subject;
};

export function SettingsPanel({subjectFromStore}: Props) {
	const {isActive, setIsActive, isVisabilityUpdating} = useStoreSubjectVisability({subjectFromStore});

	return (
		<Container>
			<SettingPanel.Root
				subjectLevel={SubjectLevel.District}
				subjectName={subjectFromStore.name}
				subjectType={SubjectType.Stock}
			>
				<GridBox display='inlineGrid' flow='column' align='center' justify='start' gap='2'>
					<SettingPanel.SubjectName />
					<SettingPanel.Title size='small' color='mediumContrast'>
						Subject Tab Settings
					</SettingPanel.Title>
				</GridBox>

				<PanelDivider />

				<OverlayScrollbarsComponent
					defer
					options={{
						scrollbars: {autoHide: 'leave'},
					}}
					style={{height: 'calc(100% + 0px)'}}
				>
					<MainContentBox>
						<SettingPanel.SectionBlock.EntireBlock>
							<SettingPanel.Checkbox
								checked={isActive}
								onCheckedChange={setIsActive}
								label='Show for Users'
								disabled={isVisabilityUpdating}
							/>
						</SettingPanel.SectionBlock.EntireBlock>

						<Text size='small' color='highContrast'>
							Stock subject tabs cannot be modified or customized. No tests can be added, and existing tests cannot be
							edited.
						</Text>
					</MainContentBox>
				</OverlayScrollbarsComponent>
			</SettingPanel.Root>
		</Container>
	);
}
