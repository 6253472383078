import {Text} from '@esgi/ui/typography';
import {Container, DurationBox, TimeTextBox} from './index.styled';
import {isNull} from 'underscore';
import {DateFormatText, Timestamp, TimeText} from '@esgi/main/kits/common';

type Props = {
	testDate: string;
	duration: number | null;
};

export function SessionTimeInfo({testDate, duration}: Props) {
	return (
		<Container>
			<DateFormatText
				date={testDate}
				dateFormat='MM/DD/YY'
				size='medium'
				font='mono'
				bold
				color='base'
				data-cy='date'
			/>
			<Timestamp date={testDate} />

			{!isNull(duration) && duration !== 0 && (
				<DurationBox>
					<Text size='small' font='mono' color='lowContrast' data-cy='duration-title'>
						Duration:
					</Text>
					<TimeTextBox dataCy='duration'>
						<TimeText
							time={duration / 1000}
							timeFormat='mm:ss'
							size='small'
							font='mono'
							bold
							color='tertiary'
							doRound
						/>
					</TimeTextBox>
				</DurationBox>
			)}
		</Container>
	);
}
