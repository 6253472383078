import {GridBox} from '@esgi/ui/layout';
import {SkeletonShape} from '@esgi/main/kits/common';
import {listGroupCSS, listItemCSS} from './list.styled';
import {Container as TestInfoItemContainer} from './test-info-item/index.styled';

export function Skeleton() {
	return (
		<GridBox css={listGroupCSS}>
			{new Array(30).fill(null).map((_, index) => (
				<GridBox key={`skeleton-${index}`} css={listItemCSS}>
					<TestInfoItemContainer>
						<SkeletonShape width={12} />
						<SkeletonShape width={110} />
						<SkeletonShape width={62} />
					</TestInfoItemContainer>

					<SkeletonShape shape='round' />
				</GridBox>
			))}
		</GridBox>
	);
}
