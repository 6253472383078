import {V2SchoolAdminsActionPanelController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {
	Class,
	classesStore,
	Group,
	groupsStore,
	Student,
	studentsStore,
	teachersStore,
	User,
} from '@esgi/main/libs/school-admin-store';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {dispatchAppEvent} from '@esgillc/events';
import {useCallback, useEffect, useState} from 'react';
import {TeacherRemovedEvent} from '../../../events';

type Parameters = {
	onRemoved: VoidFunction;
	onClose: VoidFunction;
	potentialRemovedTeacher: User;
};

export function useDeleteTeacher({onClose, onRemoved, potentialRemovedTeacher}: Parameters): [VoidFunction, boolean] {
	const actionPanelController = useDisposable(V2SchoolAdminsActionPanelController);

	const [isTeacherRemoving, setIsTeacherRemoving] = useState(false);

	const [deletedTeacherData, deleteTeacher] = useCancelableRequest(actionPanelController.teachersDelete);

	useEffect(() => {
		if (isAsyncSucceed(deletedTeacherData)) {
			const classesStorage = classesStore();
			const groupsStorage = groupsStore();
			const teachersStorage = teachersStore();
			const studentsStorage = studentsStore();

			const removedClassesIDsFromStorage: Class['id'][] = [];
			const removedGroupsIDsFromStorage: Group['id'][] = [];

			classesStorage.remove?.(({teacherID, id}: Class): boolean => {
				if (teacherID === potentialRemovedTeacher.id) {
					removedClassesIDsFromStorage.push(id);

					return true;
				}

				return false;
			});

			groupsStorage.remove?.(({teacherID, id}: Group): boolean => {
				if (teacherID === potentialRemovedTeacher.id) {
					removedGroupsIDsFromStorage.push(id);

					return true;
				}

				return false;
			});

			teachersStorage.remove?.(({id}: User): boolean => id === potentialRemovedTeacher.id);

			studentsStorage.update?.((item: Student): Student => {
				if (item.teacherID === potentialRemovedTeacher.id) {
					return {
						...item,
						teacherID: null,
						classesIDs: item.classesIDs.filter((classID) => !removedClassesIDsFromStorage.includes(classID)),
						groupsIDs: item.groupsIDs.filter((groupID) => !removedGroupsIDsFromStorage.includes(groupID)),
					};
				}

				return item;
			});

			dispatchAppEvent(
				TeacherRemovedEvent,
				new TeacherRemovedEvent({
					id: potentialRemovedTeacher.id,
					firstName: potentialRemovedTeacher.firstName,
					lastName: potentialRemovedTeacher.lastName,
				}),
			);

			onClose();
			onRemoved();
			setIsTeacherRemoving(false);
		}
	}, [deletedTeacherData, onClose, onRemoved, potentialRemovedTeacher]);

	const handleRemoveTeacher = useCallback(() => {
		setIsTeacherRemoving(true);

		deleteTeacher({
			iDs: [potentialRemovedTeacher.id],
		});
	}, [deleteTeacher, potentialRemovedTeacher]);

	return [handleRemoveTeacher, isTeacherRemoving];
}
