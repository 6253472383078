import {ComponentPropsWithoutRef, useMemo} from 'react';
import {Select} from '@esgi/ui/controls';
import {SelectContext, SelectContextValue} from '../context';

type Props = ComponentPropsWithoutRef<typeof Select.Root>;

export function Root({dataCy = 'test-panel-select-root', value, onValueChange, ...props}: Props) {
	const context = useMemo<SelectContextValue>(() => ({activeValue: value}), [value]);

	return (
		<SelectContext.Provider value={context}>
			<Select.Root dataCy={dataCy} value={value} onValueChange={onValueChange} {...props} />
		</SelectContext.Provider>
	);
}
