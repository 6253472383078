import {cartesianGridBoxHeight, cartesianGridValues} from '../../constants';
import {CartesianGridBox, CartesianGridLineBox} from './index.styled';
import {ItemsAverageInfo} from './components/items-average-info';
import {CartesianLine} from './components/cartesian-line';
import {HoveredCandleData} from '../../types';
import {isNull} from 'underscore';
import {ObjectEntries} from '../../../../../types';

type Props = {
	showAllAverageLine: boolean;
	allAverageValue: number;
	hoveredCandleData: HoveredCandleData | null;
};

export function CartesianGrid({showAllAverageLine, allAverageValue, hoveredCandleData}: Props) {
	const withHoveredData = !isNull(hoveredCandleData);

	return (
		<CartesianGridBox>
			{cartesianGridValues.map((_, index) => (
				<CartesianGridLineBox variant='default' hidden={withHoveredData} key={index}>
					<CartesianLine strokeColor='mild' />
				</CartesianGridLineBox>
			))}

			{showAllAverageLine && !withHoveredData && <ItemsAverageInfo value={allAverageValue} />}

			{withHoveredData &&
				(Object.entries(hoveredCandleData) as ObjectEntries<HoveredCandleData>).map(([key, value]) => {
					if (key === 'periodIndex' || key === 'itemCellIndex') {
						return null;
					}

					return (
						<CartesianGridLineBox
							variant='valued'
							css={{
								bottom: `calc(${value}% - ${(cartesianGridBoxHeight * value) / 100}px)`,
							}}
							key={`${key}-${value}`}
						>
							<CartesianLine strokeColor={key === 'quartile2' ? 'secondaryVivid' : 'secondaryMuted'} />
						</CartesianGridLineBox>
					);
				})}
		</CartesianGridBox>
	);
}
