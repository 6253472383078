import {Text} from '@esgi/ui/typography';
import {Container, TextContainer} from './index.styled';

export function NoTests() {
	return (
		<Container>
			<TextContainer>
				<Text size='small' color='neutral40'>
					No tests
				</Text>
			</TextContainer>
		</Container>
	);
}