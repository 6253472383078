import {ColorNumerable, DatePeriod, PeriodTypeNumerable} from '@esgi/main/features/admins/dashboard';
import {LowestAchievementWidgetOptions, LowestAchievementWidgetViewTypeNumerable} from '../types';
import {isNull} from 'underscore';

export function getLowestAchievementInitOptions({
	districtID,
	globalSchoolYearID,
	dateRange,
}: {
	districtID: number;
	globalSchoolYearID: number;
	dateRange: DatePeriod | null;
}) {
	const options: LowestAchievementWidgetOptions = {
		districtID,
		globalSchoolYearID,
		districtCutoff: 25,
		viewType: LowestAchievementWidgetViewTypeNumerable.Students,
		periods: [
			{
				color: ColorNumerable.None,
				datePeriod: isNull(dateRange) ? PeriodTypeNumerable.CurrentMarkingPeriod : PeriodTypeNumerable.Manual,
				dateRange: dateRange as unknown as DatePeriod,
				trackDatesID: 0,
			},
		],
		schoolIDs: [],
		contentAreaIDs: [],
		subjectIDs: [],
		gradeLevelIDs: [],
	};

	return options;
}
