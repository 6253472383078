import {DeployedSubject, isDeployedSubject, useSubjects} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {TestResultsSubject} from '@esgi/main/kits/admin';
import {isNull} from 'underscore';
import {AdditionalStoreSubjectsFilter} from '../types';

type Parameters = {
	targetGradeLevels: number[];
	schoolID: number | null;
	additionalStoreSubjectsFilter: AdditionalStoreSubjectsFilter | null;
	userID: number | null;
};

type Out = [boolean, TestResultsSubject[]];

export function useSubjectsFromStore({
	targetGradeLevels,
	schoolID,
	additionalStoreSubjectsFilter,
	userID,
}: Parameters): Out {
	const [{data: subjects, loaded: isSubjectsLoaded}] = useSubjects();

	return useMemo<Out>(() => {
		if (!isSubjectsLoaded) {
			return [false, []];
		}

		const filteredDeployedSubjects = subjects.filter((item): item is DeployedSubject => {
			if (isDeployedSubject(item)) {
				const {isPublished, tests, hidden, gradeLevels, allowedSchools, allowedSpecialists} = item;

				const filterByAllowedSchools =
					additionalStoreSubjectsFilter === 'schools' && !isNull(schoolID) ? allowedSchools.includes(schoolID) : true;

				const filterByAllowedSpecialists =
					additionalStoreSubjectsFilter === 'userIDAsspecialists' && !isNull(userID)
						? allowedSpecialists.includes(userID)
						: true;

				return (
					isPublished &&
					Boolean(tests.length) &&
					!hidden &&
					gradeLevels.some((gradeLevel) => targetGradeLevels.includes(gradeLevel)) &&
					filterByAllowedSchools &&
					filterByAllowedSpecialists
				);
			}

			return false;
		});

		const activeSubjects = filteredDeployedSubjects.map<TestResultsSubject>(
			({id, name, subjectType, level, gradeLevels}) => ({
				id,
				name,
				type: subjectType,
				level,
				gradeLevels,
			}),
		);

		return [true, activeSubjects];
	}, [additionalStoreSubjectsFilter, isSubjectsLoaded, schoolID, subjects, targetGradeLevels, userID]);
}
