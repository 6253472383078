import {CheckAll} from '@esgi/ui';
import {styled} from '@esgi/ui/theme';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {Text as TextUI} from '@esgi/ui/typography';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';

export const Container = styled(GridBox, {
	gridTemplateRows: 'auto 1fr',
	overflow: 'hidden',
});

export const Row = styled(GridBox, {
	gap: 12,
	gridAutoFlow: 'column',
});

export const HeaderCell = styled(FlexBox, {
	height: 24,
	alignItems: 'center',
});

export const SkeletonRow = styled(Box, {
	...SkeletonStyles,
	height: 48,
	width: '100%',
	borderRadius: 4,
});


export const CheckAllIcon = styled(CheckAll, {
	marginRight: 12,
	cursor: 'pointer',
	variants: {
		allChecked: {
			true: {
				'& path': {
					fill: '$primary',
				},
			},
		},
	},
});

export const Text = styled(TextUI, {
	cursor: 'pointer',
	userSelect: 'none',
	variants: {
		disabled: {
			true: {
				cursor: 'auto',
			},
		},
	},
});