import {AppSyncService} from '@esgi/aws';
import {useUser} from '@esgi/core/authentication';
import {useDisposable} from '@esgi/core/service';
import {Dispatch, useEffect} from 'react';
import {isNull} from 'underscore';
import {
	AppSyncType,
	DashboardVersionCompleted,
	DashboardWidgetRecalculated,
	FetchWidgetDataByIDParameters,
	FetchWidgetDataByIDResponse,
	InitWidgetsDataParameters,
	InitWidgetsDataResponse,
} from './types';
import {Observable} from 'rxjs';

export const userType = 'district-admin';

type Props = {
	initWidgetsData: (params: InitWidgetsDataParameters) => Observable<InitWidgetsDataResponse>;
	updateLastUpdatedDate: Dispatch<string | Date>;
	fetchWidgetDataByID: (params: FetchWidgetDataByIDParameters) => Observable<FetchWidgetDataByIDResponse>;
};

export function useAppSync({initWidgetsData, updateLastUpdatedDate, fetchWidgetDataByID}: Props) {
	const currentUser = useUser();
	const appSyncService = useDisposable(AppSyncService);

	useEffect(() => {
		if (isNull(currentUser)) {
			return;
		}

		const {userID} = currentUser;

		appSyncService
			.channel<DashboardVersionCompleted>({
				userID,
				userType,
				messageType: AppSyncType.DashboardVersionCompleted,
			})
			.subscribe(({DashboardVersionID, Timestamp}) => {
				initWidgetsData({dashboardVersionID: DashboardVersionID}).subscribe(() => {
					updateLastUpdatedDate(Timestamp);
				});
			});

		appSyncService
			.channel<DashboardWidgetRecalculated>({
				userID,
				userType,
				messageType: AppSyncType.DashboardWidgetRecalculated,
			})
			.subscribe(({WidgetID, WidgetVersionID}) => {
				fetchWidgetDataByID({id: WidgetID, versionID: WidgetVersionID}).subscribe();
			});
	}, [currentUser]);
}
