import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';
import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {isNull} from 'underscore';
import {useEffect, useMemo} from 'react';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {TeacherSubject} from '@esgi/main/kits/subject-selection-panel';

type Parameters = {
	teacherID: number | null;
};

type Out = [boolean, TeacherSubject[]];

export function useSubjectsByUserID({teacherID}: Parameters): Out {
	const currentUser = useUser();
	const api = useEsgiApiGateway();

	const [subjectsData, fetchSubjects] = useCancelableRequest(api.v2.districtAdmins.modules.subjects.personal.byUserId);

	const withFetchedData = !isNull(currentUser) && !isNull(teacherID);

	useEffect(() => {
		if (withFetchedData) {
			fetchSubjects({
				globalSchoolYearID: currentUser.globalSchoolYearID,
				userID: teacherID,
			});
		}
	}, [currentUser, fetchSubjects, teacherID, withFetchedData]);

	return useMemo<Out>(() => {
		if (!withFetchedData) {
			return [true, []];
		}

		const isSubjectsLoaded = isAsyncSucceed(subjectsData);

		if (!isSubjectsLoaded) {
			return [false, []];
		}

		const filteredSubjects = subjectsData.data.value.subjects.filter(({isHidden}) => !isHidden);

		const activeSubjects = filteredSubjects.map<TeacherSubject>(({id, name}) => ({
			id,
			name,
			level: SubjectLevel.Teacher,
			type: SubjectType.Personal,
			hidden: false,
		}));

		return [true, activeSubjects];
	}, [subjectsData, withFetchedData]);
}
