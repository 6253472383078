import {isNull} from 'underscore';
import {DeployedSubject, SubjectType, SubjectTypeContract} from '../types';

export function isDeployedSubject(item: unknown): item is DeployedSubject {
	if (typeof item === 'object' && !isNull(item) && 'subjectType' in item) {
		const subjectType = item.subjectType;

		if (typeof subjectType === 'string') {
			return subjectType === SubjectType.Deployed;
		}

		if (typeof subjectType === 'number') {
			return subjectType === SubjectTypeContract.Deployed;
		}
	}

	return false;
}
