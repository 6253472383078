import {Card} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {widgetCardHeight, widgetCardPaddingVertical} from './constants';

export const WidgetCardRoot = styled(Card, {
	height: widgetCardHeight,
	boxShadow: 'none',
	backgroundColor: '$background',
	borderColor: '$surface',
	paddingTop: widgetCardPaddingVertical,
	paddingBottom: widgetCardPaddingVertical,
	paddingLeft: 20,
	paddingRight: 20,
	boxSizing: 'border-box',
});
