import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Footer} from './index.styled';

type Props = {
	isAddDisable: boolean;
	onAddClick: VoidFunction;
	onCancel: VoidFunction;
};

export function AlertFooter({isAddDisable, onCancel, onAddClick}: Props) {
	return (
		<Footer>
			<GridBox gap='3' flow='column'>
				<Button color='tertiary' onClick={onCancel}>
					<Text size='medium' bold color='base'>
						Cancel
					</Text>
				</Button>
				<Button color='secondary' disabled={isAddDisable} onClick={onAddClick}>
					<Text size='medium' bold color='base'>
						Add
					</Text>
				</Button>
			</GridBox>
		</Footer>
	);
}
