import {ComponentPropsWithoutRef, Dispatch, forwardRef} from 'react';
import {BaseComponentProps, isUndefined} from '@esgi/ui';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {Text} from '@esgi/ui/typography';
import {Content} from './index.styled';
import {usePanelContext} from '../../context';
import {TestPanelMode} from '../../types/section';
import {EmptyContentBox} from '../../components/empty-content-box';
import {Default as DefaultList} from '../../components/lists/default';
import {Skeleton as SkeletonList} from '../../components/lists/skeleton';
import {TestPanelTestModel} from '../../types/tests';

type Props = BaseComponentProps &
	ComponentPropsWithoutRef<typeof Content> & {
		noTestsNode?: React.ReactNode;
		onTestDeleteClick?: Dispatch<TestPanelTestModel['id']>;
	};

export const ViewMode = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'tests-panel-view-mode', css = {}, noTestsNode, onTestDeleteClick, children, ...contentProps},
		forwardedRef,
	) => {
		const {skeleton, panelMode, tests} = usePanelContext();

		const renderList = () => {
			if (!isUndefined(children)) {
				return children;
			}

			if (skeleton) {
				return <SkeletonList />;
			}

			if (!tests.length) {
				return (
					noTestsNode || (
						<EmptyContentBox justify='center'>
							<Text size='small' font='mono' color='mediumContrast'>
								No Tests to display
							</Text>
						</EmptyContentBox>
					)
				);
			}

			return <DefaultList onTestDeleteClick={onTestDeleteClick} />;
		};

		if (panelMode === TestPanelMode.Rearrange) {
			return null;
		}

		return (
			<Content dataCy={dataCy} css={css} ref={forwardedRef} {...contentProps}>
				<OverlayScrollbarsComponent
					defer
					options={{
						scrollbars: {autoHide: 'leave'},
					}}
					style={{height: 'calc(100% + 0px)'}}
				>
					{renderList()}
				</OverlayScrollbarsComponent>
			</Content>
		);
	},
);
