import {Drawer, getFullName} from '@esgi/main/kits/common';
import {FormElementSelect} from '../../../../components/form-element-select';
import {Class, School, User} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {FormSelectItem} from '../../../../components/form-element-select/types';
import {schoolSelectErrorItems, teacherClassesSelectErrorItems, teacherSelectErrorItems} from './constants';
import {AddGroupForm} from '../../form-data/types';
import {GroupNameInput} from '../../../../components/group-name-input';

type Props = {
	form: AddGroupForm;
	schools: School[];
	activePrimaryTeachersList: User[];
	primaryTeacherUniqueKey: string;
	activeClassesList: Class[];
	classListUniqueKey: string;
};

export function GroupInformationTabContent({
	form,
	schools,
	activePrimaryTeachersList,
	primaryTeacherUniqueKey,
	activeClassesList,
	classListUniqueKey,
}: Props) {
	const schoolSelectItem = useMemo(
		() => schools.map<FormSelectItem<string>>(({id, name}) => ({value: String(id), label: name})),
		[schools],
	);

	const teachersSelectItem = useMemo(
		() =>
			activePrimaryTeachersList.map<FormSelectItem<string>>(({id, firstName, lastName}) => ({
				value: String(id),
				label: getFullName({firstName, lastName}),
			})),
		[activePrimaryTeachersList],
	);

	const classesSelectItem = useMemo(
		() =>
			activeClassesList.map<FormSelectItem<string>>(({id, name}) => ({
				value: String(id),
				label: name,
			})),
		[activeClassesList],
	);

	return (
		<>
			<Drawer.ContentBlock title='Location' withDivider>
				<FormElementSelect
					placeholder='School'
					control={form.controls.schoolID}
					items={schoolSelectItem}
					errorItems={schoolSelectErrorItems}
				/>

				<FormElementSelect
					placeholder='Primary Teacher'
					control={form.controls.primaryTeacherID}
					items={teachersSelectItem}
					errorItems={teacherSelectErrorItems}
					key={primaryTeacherUniqueKey}
				/>

				<FormElementSelect
					placeholder='Class'
					control={form.controls.teacherClass}
					items={classesSelectItem}
					errorItems={teacherClassesSelectErrorItems}
					key={classListUniqueKey}
				/>
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Group Information' withDivider>
				<GroupNameInput groupNameControl={form.controls.groupName} />
			</Drawer.ContentBlock>
		</>
	);
}
