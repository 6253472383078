import {useCallback, useState} from 'react';
import {ExternalFilter, ExternalFilterState} from '../../../types';
import {IsExternalFilterPresentParams, IRowNode} from 'ag-grid-community';
import {AgGridReactProps} from 'ag-grid-react';

type Parameters = {
	isExternalFilterPresent: AgGridReactProps['isExternalFilterPresent'] | undefined;
	doesExternalFilterPass: AgGridReactProps['doesExternalFilterPass'] | undefined;
};

export function useExternalFilters({isExternalFilterPresent, doesExternalFilterPass}: Parameters) {
	const [externalFilters, setExternalFilters] = useState<ExternalFilterState[]>([]);

	const onIsExternalFilterPresent = useCallback(
		(params: IsExternalFilterPresentParams): boolean =>
			externalFilters.some(({isExternalFilterPresent}) => isExternalFilterPresent()) ||
			(isExternalFilterPresent?.(params) ?? false),
		[externalFilters, isExternalFilterPresent],
	);

	const onDoesExternalFilterPass = useCallback(
		(node: IRowNode) => {
			const filteredNode = externalFilters.every(({isExternalFilterPresent, doesExternalFilterPass}) =>
				isExternalFilterPresent() ? doesExternalFilterPass(node) : true,
			);

			return filteredNode && (doesExternalFilterPass?.(node) ?? true);
		},
		[doesExternalFilterPass, externalFilters],
	);

	const setExternalFilter = useCallback((value: ExternalFilterState) => {
		setExternalFilters((currentFilters) => [...currentFilters, value]);
	}, []);

	const updateExternalFilter = useCallback((id: string, value: ExternalFilter) => {
		setExternalFilters((currentFilters) => currentFilters.map((item) => (item.id === id ? {id, ...value} : item)));
	}, []);

	const removeExternalFilter = useCallback((id: string) => {
		setExternalFilters((currentFilters) => currentFilters.filter((item) => item.id !== id));
	}, []);

	return {
		onIsExternalFilterPresent,
		onDoesExternalFilterPass,
		setExternalFilter,
		updateExternalFilter,
		removeExternalFilter,
	};
}
