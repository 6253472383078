import {Edit} from './mode/edit';
import {Add} from './mode/add';
import {Dispatch} from 'react';
import {Subject} from '@esgi/main/libs/school-admin-store';

type EditModeProps = {
	type: 'edit';
	subjectFromStore: Subject;
	onSubjectRemoved: VoidFunction;
};

type AddModeProps = {
	type: 'add';
	onSubjectCreated: Dispatch<number>;
};

type Props = {
	allSchoolSubjectsNames: string[];
	onIsFormTouchedValueChanged: Dispatch<boolean>;
} & (AddModeProps | EditModeProps);

export function School({allSchoolSubjectsNames, onIsFormTouchedValueChanged, ...props}: Props) {
	if (props.type === 'add') {
		return (
			<Add
				allSchoolSubjectsNames={allSchoolSubjectsNames}
				onSubjectCreated={props.onSubjectCreated}
				onIsFormTouchedValueChanged={onIsFormTouchedValueChanged}
			/>
		);
	}

	return (
		<Edit
			subjectFromStore={props.subjectFromStore}
			allSchoolSubjectsNames={allSchoolSubjectsNames}
			onSubjectRemoved={props.onSubjectRemoved}
			onIsFormTouchedValueChanged={onIsFormTouchedValueChanged}
		/>
	);
}
