import {Page} from '../../../_kit';
import {BottomPanel} from './components/bottom-panel';
import {SingleItemExpandablePanel} from './components/single-item-expandable-panel';
import {SchoolReportPanel} from '../../../_kit/school-reports-panel';
import {RowDef} from '../../../teachers/types';
import {useData} from '../../../students/use-data';
import {useStudents} from '@esgi/main/libs/school-admin-store';

type Props = {
	openAddClassDrawer: VoidFunction;
};

export function Base({openAddClassDrawer}: Props) {
	const {selectedItems, api} = Page.useContext<RowDef>();
	const {schoolName} = useData();
	const [{data: students}] = useStudents();

	return (
		<>
			<Page.Header.Base onAddButtonClick={openAddClassDrawer} />
			<Page.Body.Base />

			<SingleItemExpandablePanel />
			<SchoolReportPanel api={api} schoolName={schoolName} totalStudents={students.length}/>
			<BottomPanel />
		</>
	);
}
