import {BaseComponentProps} from '@esgi/ui';
import {
	ComponentPropsWithoutRef,
	Dispatch,
	ForwardedRef,
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import {WidgetMode} from '../../types';
import {WidgetCardContext, WidgetCardContextValue} from '../../context';
import {Pagination} from '../../../kit/pagination';
import {WidgetCardRoot} from './index.styled';
import {TrackModel} from '@esgi/main/kits/admin';
import {ContentAreaModel, WidgetViewSize} from '../../../types';

type Props<T extends Partial<Record<keyof T, unknown>>> = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<typeof WidgetCardRoot>, 'id'> & {
		name: string;
		id: number;
		maxPossibleCardWidth: number;
		widgetData: T[];
		viewSize?: WidgetViewSize;
		dataPageSize?: number;
		defaultWidgetMode?: WidgetMode | undefined;
		viewModeChanged: Dispatch<{id: number; mode: WidgetMode}>;
		isDataСounting: boolean;
		currentTrack: TrackModel | null;
		allContentAreas: ContentAreaModel[];
		lastUpdatedTime: string;
	};

export const Root = forwardRef(
	<T extends Partial<Record<keyof T, unknown>>>(
		{
			dataCy = 'district-admin-widget-card-root',
			css = {},
			maxPossibleCardWidth,
			id,
			name,
			widgetData,
			viewSize,
			dataPageSize = 10,
			defaultWidgetMode = WidgetMode.View,
			viewModeChanged,
			isDataСounting,
			children,
			currentTrack,
			allContentAreas,
			lastUpdatedTime,
			...componentRootProps
		}: Props<T>,
		forwardedRef: ForwardedRef<HTMLDivElement>,
	) => {
		const [widgetMode, setWidgetMode] = useState(defaultWidgetMode);
		const [widgetDataPageSize, setWidgetDataPageSize] = useState(dataPageSize);

		const setEditMode = useCallback(() => {
			const newMode = WidgetMode.Edit;

			setWidgetMode(newMode);
			viewModeChanged({id, mode: newMode});
		}, [id, viewModeChanged]);

		const setViewMode = useCallback(() => {
			const newMode = WidgetMode.View;

			setWidgetMode(newMode);
			viewModeChanged({id, mode: newMode});
		}, [id, viewModeChanged]);

		const {currentPageIndex, nextPage, pageChange, previousPage, totalPages} = Pagination.usePagination({
			totalElements: widgetData.length,
			pageSize: widgetDataPageSize,
		});

		useEffect(() => {
			setWidgetDataPageSize(dataPageSize);
			pageChange(0);
		}, [dataPageSize]);

		const visibleWidgetData = useMemo(() => {
			const nextNotVisibleIndex = currentPageIndex * widgetDataPageSize;

			return widgetData.slice(nextNotVisibleIndex, nextNotVisibleIndex + widgetDataPageSize);
		}, [currentPageIndex, widgetDataPageSize, widgetData]);

		const widgetCardContextValue = useMemo<WidgetCardContextValue<T>>(
			() => ({
				currentPageIndex,
				id,
				nextPage,
				pageChange,
				previousPage,
				setEditMode,
				setViewMode,
				name,
				totalPages,
				setWidgetDataPageSize,
				visibleData: visibleWidgetData,
				widgetDataPageSize,
				widgetMode,
				totalDataLength: widgetData.length,
				isDataСounting,
				currentTrack,
				allContentAreas,
				lastUpdatedTime,
			}),
			[
				currentPageIndex,
				id,
				nextPage,
				pageChange,
				previousPage,
				setEditMode,
				setViewMode,
				name,
				totalPages,
				visibleWidgetData,
				widgetData.length,
				widgetDataPageSize,
				widgetMode,
				isDataСounting,
				currentTrack,
				allContentAreas,
				lastUpdatedTime,
			],
		);

		return (
			<WidgetCardRoot
				dataCy={dataCy}
				css={{
					...css,
					...(widgetMode === WidgetMode.Edit
						? {
								width: maxPossibleCardWidth,
						  }
						: {}),
				}}
				ref={forwardedRef}
				{...componentRootProps}
			>
				<WidgetCardContext.Provider value={widgetCardContextValue}>{children}</WidgetCardContext.Provider>
			</WidgetCardRoot>
		);
	},
);
