import {isNull} from 'underscore';
import {EsgiComponent} from './renewal';

export class Page {
	renewal: EsgiComponent;

	private container: HTMLDivElement | null = null;

	public init(container: HTMLDivElement) {
		this.renewal = new EsgiComponent();
		this.container = container;

		ko.applyBindings(this, container);

		return this.renewal.load();
	}

	public destroy() {
		if (!isNull(this.container)) {
			ko.cleanNode(this.container);
		}
	}
}
