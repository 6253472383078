import {isNull} from 'underscore';
import {DashboardWidgetOptions} from '@esgi/main/features/school-admin/dashboard';
import {getGridstackItemPosition} from '../../../utils/get-gridstack-item-position';
import {WidgetViewSize} from '@esgi/main/features/admins/dashboard';

export function getItemPosition({
	options: {
		schoolPerformanceWidgetOptions,
		schoolDemographicPerformanceWidgetOptions,
		schoolStudentsNeedingSupportWidgetOptions,
	},
	itemIndex,
	calculateItemPosition,
}: {
	options: DashboardWidgetOptions;
	itemIndex: number;
	calculateItemPosition: ReturnType<typeof getGridstackItemPosition>;
}) {
	if (!isNull(schoolPerformanceWidgetOptions)) {
		return calculateItemPosition({
			itemIndex,
			isFullSize: schoolPerformanceWidgetOptions.viewSize === WidgetViewSize.FullSize,
		});
	}

	if (!isNull(schoolDemographicPerformanceWidgetOptions)) {
		return calculateItemPosition({
			itemIndex,
			isFullSize: schoolDemographicPerformanceWidgetOptions.viewSize === WidgetViewSize.FullSize,
		});
	}

	if (!isNull(schoolStudentsNeedingSupportWidgetOptions)) {
		return calculateItemPosition({itemIndex});
	}

	throw new Error('Option type is not unattainable');
}
