import React, {Dispatch} from 'react';
import {useSpring, config, animated} from 'react-spring';
import {CardHeaderBox, TestLabelsBox} from './index.styled';
import {CardTitle} from '../card-title';
import {TestColorIndicator} from '@esgi/main/kits/common';
import {TestTypeLabel} from '../../../../../../../../kit';
import {TestSession, TestSingleStudentModel} from '@esgi/main/features/teacher/home';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {CardActionButtons} from '../card-action-buttons';
import {ClassGroupActionButtonsProps, StudentActionButtonsProps} from '../card-action-buttons/types';
import {useUser} from '@esgi/core/authentication';
import {MultipleLabels} from '../../../../components/multiple-labels';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {onCardHoverText} from '../../../constants';
import {GridBox} from '@esgi/ui/layout';

type Props = {
	lastTestSessions: TestSession[];
	sessionsCount: number;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	openTestDetails: VoidFunction;
	isSmallScreen: boolean;
	testInfo: TestSingleStudentModel['testInfo'],
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	classID: Class['id'];
	subjectID: SubjectTab['id'];
} & (StudentActionButtonsProps | ClassGroupActionButtonsProps);

export function CardHeader({
	testInfo,
	lastTestSessions,
	sessionsCount,
	userCanEditTest,
	userCanRemoveTest,
	openTestDetails,
	isSmallScreen,
	onTestMoveTo,
	onRemoveTest,
	classID,
	subjectID,
	...props
}: Props) {
	const animatedIndicatorStyles = useSpring({
		height: `calc(100% + ${isSmallScreen ? 0 : 96}px)`,
		marginBottom: isSmallScreen ? 0 : -96,
		config: {
			...config.gentle,
			clamp: true,
		},
	});

	const user = useUser();

	const userStateStandard = testInfo.stateStandards.filter(({stateID}) => stateID === user.stateID);
	const commonStandards = testInfo.stateStandards.filter(({stateID}) => stateID === 0);

	const standards = userStateStandard.length ? userStateStandard : commonStandards;

	return (
		<CardHeaderBox>
			<animated.div style={animatedIndicatorStyles}>
				<TestColorIndicator testID={testInfo.id} />
			</animated.div>

			<Tooltip>
				<Tooltip.Trigger>
					 <GridBox>
						 <CardTitle titleText={testInfo.name} />
					 </GridBox>
				</Tooltip.Trigger>
				<Tooltip.Content side='top'>
					<Text size='large' color='tertiary'>
						{onCardHoverText}
					</Text>
				</Tooltip.Content>
			</Tooltip>
			<TestLabelsBox>
				<TestTypeLabel testType={testInfo.type} />
				{standards.length > 0 && <MultipleLabels items={standards.map(s => s.name)}/>}
			</TestLabelsBox>
			{!isSmallScreen && (
				<CardActionButtons
					lastTestSessions={lastTestSessions}
					sessionsCount={sessionsCount}
					testInfo={testInfo}
					openTestDetails={openTestDetails}
					userCanEditTest={userCanEditTest}
					userCanRemoveTest={userCanRemoveTest}
					onTestMoveTo={onTestMoveTo}
					onRemoveTest={onRemoveTest}
					classID={classID}
					subjectID={subjectID}
					{...props}
				/>
			)}
		</CardHeaderBox>
	);
}
