import {Drawer} from '@esgi/main/kits/common';
import {PanelContent} from '../../components/panels.styled';
import {DisabledInput} from '../../components/disabled-input';
import {ContentBox} from '../../components/content-box.styled';
import {PanelHeaderTitle} from '../../components/panel-header-title';
import {DrawerPanelHeader} from '../index.styled';

type Props = {
	districtName: string;
};

export function DistrictContent({districtName}: Props) {
	return (
		<>
			<DrawerPanelHeader>
				<PanelHeaderTitle title='District' />
			</DrawerPanelHeader>

			<PanelContent>
				<ContentBox gap='60'>
					<Drawer.ContentBlock title='District'>
						<DisabledInput value={districtName} placeholder='District' dataCy='district-disabled-input' />
					</Drawer.ContentBlock>
				</ContentBox>
			</PanelContent>
		</>
	);
}
