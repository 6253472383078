import {isNull} from 'underscore';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {isUndefined} from '@esgi/ui';
import {enumRemap} from 'shared/utils';
import {PeriodType} from '@esgi/main/kits/admin';
import {
	SchoolStudentsNeedingSupportWidgetUpdatedValue,
	SchoolStudentsNeedingSupportWidgetViewType,
} from '../../../../types/students-needing-support';
import {CommonSelectedArraysEntity, CommonUpdatedValue, SchoolCutoff, PeriodsVariant} from './types';
import {CommonForm, StudentsTwoPeriodsForm, DatePickerSingleForm} from './forms-data/types';
import {Color, GradeLevel, OnUpdateWidget, WidgetCard} from '@esgi/main/features/admins/dashboard';
import {ClassModel, SubjectTab, TeacherModel} from '../../../../../types';
import {districtCutoffNumberByType} from './constants';
import {Widget} from '../../../../types/widget';

export function useSaveData({
	schoolID,
	districtID,
	globalSchoolYearID,
	commonForm,
	classesForm,
	studentsSinglePeriodForm,
	studentsTwoPeriodsForm,
	selectedContentArea,
	selectedGradeLevels,
	selectedClassesIDs,
	selectedTeachersIDs,
	selectedSubjectIDs,
	onUpdateWidget,
	setIsCommonFormTouched,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	subjects,
	allClassesList,
	allTeachersList,
	setSelectedClassesIDs,
	gradeLevels,
}: {
	schoolID: number;
	districtID: number;
	globalSchoolYearID: number;
	commonForm: CommonForm;
	classesForm: DatePickerSingleForm;
	studentsSinglePeriodForm: DatePickerSingleForm;
	studentsTwoPeriodsForm: StudentsTwoPeriodsForm;
	selectedContentArea: string[];
	selectedSubjectIDs: string[];
	selectedGradeLevels: string[];
	selectedClassesIDs: string[];
	selectedTeachersIDs: string[];
	onUpdateWidget: Dispatch<SchoolStudentsNeedingSupportWidgetUpdatedValue>;
	setIsCommonFormTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataValid: Dispatch<SetStateAction<boolean>>;
	allClassesList: ClassModel[];
	allTeachersList: TeacherModel[];
	subjects: SubjectTab[];
	setSelectedClassesIDs: Dispatch<SetStateAction<string[]>>;
	gradeLevels: GradeLevel[];
}) {
	const {allContentAreas} = WidgetCard.useWidgetCardContext();

	const handleUpdateWidget = useCallback(
		(value: SchoolStudentsNeedingSupportWidgetUpdatedValue) => {
			onUpdateWidget(value);

			setIsCommonFormTouched(false);

			setIsDynamicFormDataTouched(false);
			setIsDynamicFormDataValid(true);
		},
		[onUpdateWidget, setIsCommonFormTouched, setIsDynamicFormDataTouched, setIsDynamicFormDataValid],
	);

	const getCommonUpdatedValue = useCallback(
		({id, name}: {id: Widget['id']; name: string}) => {
			const {viewType, districtCutoff} = commonForm.value;

			const viewTypeValue = viewType[0];
			const districtCutoffValue = districtCutoff[0];

			if (isUndefined(viewTypeValue)) {
				throw new Error('View Type is undefined');
			}

			if (isUndefined(districtCutoffValue)) {
				throw new Error('School Cutoff is undefined');
			}

			if (isNull(districtCutoffValue)) {
				throw new Error('School Cutoff is null');
			}

			if (districtCutoffValue === SchoolCutoff.None) {
				throw new Error('School Cutoff is None');
			}

			const contentAreaIDs = selectedContentArea.map(Number);
			const subjectIDs = selectedSubjectIDs.map(Number);
			const gradeLevelIDs = selectedGradeLevels.map(Number);

			const commonUpdatedValue: CommonUpdatedValue = {
				id,
				name,
				options: {
					schoolID,
					districtID,
					globalSchoolYearID,
					districtCutoff: districtCutoffNumberByType[districtCutoffValue],
					contentAreaIDs,
					subjectIDs,
					gradeLevelIDs,
					viewType,
				},
			};

			const commonSelectedArraysEntity: CommonSelectedArraysEntity = {
				contentAreaIDs: allContentAreas.length === contentAreaIDs.length ? 'all' : 'part',
				gradeLevelIDs: gradeLevels.length === gradeLevelIDs.length ? 'all' : 'part',
				subjectIDs: subjects.length === subjectIDs.length ? 'all' : 'part',
			};

			return {commonUpdatedValue, commonSelectedArraysEntity};
		},
		[
			commonForm.value,
			selectedContentArea,
			selectedSubjectIDs,
			selectedGradeLevels,
			schoolID,
			districtID,
			globalSchoolYearID,
			allContentAreas.length,
			gradeLevels.length,
			subjects.length,
		],
	);

	const saveStudentsSinglePeriodForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {date} = studentsSinglePeriodForm.value;

			const classIDs = selectedClassesIDs.map(Number);
			const teachersIDs = allTeachersList.map(({id}) => id);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					classIDs,
					teachersIDs,
					periods: [
						{
							color: Color.None,
							trackDatesID: date.trackDatesID,
							dateRange: date.dateRange,
							datePeriod: enumRemap(date.datePeriod, PeriodType),
						},
					],
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					classIDs: allClassesList.length === classIDs.length ? 'all' : 'part',
					teachersIDs: 'all',
				},
			});
		},
		[allClassesList.length, allTeachersList, handleUpdateWidget, selectedClassesIDs, studentsSinglePeriodForm.value],
	);

	const saveStudentsTwoPeriodsForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {firstPeriodColor, firstPeriodDate, secondPeriodColor, secondPeriodDate} = studentsTwoPeriodsForm.value;

			const firstPeriodColorValue = firstPeriodColor[0];
			const secondPeriodColorValue = secondPeriodColor[0];

			if (isUndefined(firstPeriodColorValue)) {
				throw new Error('WidgetViewType.Students -> Two Periods: firstPeriodColorValue is undefined');
			}

			if (isNull(firstPeriodColorValue)) {
				throw new Error('WidgetViewType.Students -> Two Periods: firstPeriodColorValue is null');
			}

			if (isUndefined(secondPeriodColorValue)) {
				throw new Error('WidgetViewType.Students -> Two Periods: secondPeriodColorValue is undefined');
			}

			if (isNull(secondPeriodColorValue)) {
				throw new Error('WidgetViewType.Students -> Two Periods: secondPeriodColorValue is null');
			}

			const classIDs = selectedClassesIDs.map(Number);
			const teachersIDs = allTeachersList.map(({id}) => id);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					classIDs,
					teachersIDs,
					periods: [
						{
							color: firstPeriodColorValue,
							trackDatesID: firstPeriodDate.trackDatesID,
							dateRange: firstPeriodDate.dateRange,
							datePeriod: enumRemap(firstPeriodDate.datePeriod, PeriodType),
						},
						{
							color: secondPeriodColorValue,
							trackDatesID: secondPeriodDate.trackDatesID,
							dateRange: secondPeriodDate.dateRange,
							datePeriod: enumRemap(secondPeriodDate.datePeriod, PeriodType),
						},
					],
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					classIDs: allClassesList.length === classIDs.length ? 'all' : 'part',
					teachersIDs: 'all',
				},
			});
		},
		[allClassesList.length, allTeachersList, handleUpdateWidget, selectedClassesIDs, studentsTwoPeriodsForm.value],
	);

	const saveClassesForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {date} = classesForm.value;

			const teachersIDs = selectedTeachersIDs.map(Number);
			const classIDs = allClassesList.map(({classID}) => classID);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					classIDs,
					teachersIDs,
					periods: [
						{
							color: Color.None,
							trackDatesID: date.trackDatesID,
							dateRange: date.dateRange,
							datePeriod: enumRemap(date.datePeriod, PeriodType),
						},
					],
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					classIDs: 'all',
					teachersIDs: allTeachersList.length === teachersIDs.length ? 'all' : 'part',
				},
			});

			setSelectedClassesIDs(classIDs.map(String));
		},
		[
			allClassesList,
			allTeachersList.length,
			classesForm.value,
			handleUpdateWidget,
			selectedTeachersIDs,
			setSelectedClassesIDs,
		],
	);

	return useCallback<OnUpdateWidget>(
		({id, name}) => {
			const {commonUpdatedValue, commonSelectedArraysEntity} = getCommonUpdatedValue({id, name});

			const {viewType, periodsVariant} = commonForm.value;

			if (viewType === SchoolStudentsNeedingSupportWidgetViewType.Students) {
				if (periodsVariant === PeriodsVariant.SinglePeriod) {
					saveStudentsSinglePeriodForm({commonUpdatedValue, commonSelectedArraysEntity});
				}

				if (periodsVariant === PeriodsVariant.TwoPeriods) {
					saveStudentsTwoPeriodsForm({commonUpdatedValue, commonSelectedArraysEntity});
				}
			}

			if (viewType === SchoolStudentsNeedingSupportWidgetViewType.Classes) {
				saveClassesForm({commonUpdatedValue, commonSelectedArraysEntity});
			}
		},
		[
			commonForm.value,
			getCommonUpdatedValue,
			saveClassesForm,
			saveStudentsSinglePeriodForm,
			saveStudentsTwoPeriodsForm,
		],
	);
}
