import {isNull} from 'underscore';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {isUndefined} from '@esgi/ui';
import {
	BoxplotSinglePeriodForm,
	BoxplotTwoPeriodsForm,
	BoxplotTwoSchoolsForm,
	CommonForm,
	PiechartsForm,
} from '../forms-data/types';
import {DemographicPerformanceWidgetUpdatedValue} from '../../../../../types/demographic-performance';
import {BoxplotCompareVariant, CommonSelectedArraysEntity, CommonUpdatedValue} from '../types';
import {Widget} from '../../../../../types/widget';
import {enumRemap} from 'shared/utils';
import {PeriodType} from '@esgi/main/kits/admin';
import {
	Color,
	DeployedSubject,
	GradeLevel,
	OnUpdateWidget,
	PerformanceWidgetViewType,
	SchoolModel,
	WidgetCard,
} from '@esgi/main/features/admins/dashboard';

export function useSaveWidget({
	onUpdateWidget,
	districtID,
	globalSchoolYearID,
	setIsCommonFormTouched,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	selectedContentArea,
	selectedGradeLevels,
	selectedSubjectIDs,
	selectedSchoolsIDs,
	commonForm,
	boxplotSinglePeriodForm,
	boxplotTwoPeriodsForm,
	boxplotTwoSchoolsForm,
	piechartsForm,
	setSelectedSchoolsIDs,
	allSchoolsList,
	subjects,
	gradeLevels,
}: {
	onUpdateWidget: Dispatch<DemographicPerformanceWidgetUpdatedValue>;
	districtID: number;
	globalSchoolYearID: number;
	setIsCommonFormTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataTouched: Dispatch<SetStateAction<boolean>>;
	setIsDynamicFormDataValid: Dispatch<SetStateAction<boolean>>;
	selectedContentArea: string[];
	selectedSubjectIDs: string[];
	selectedGradeLevels: string[];
	selectedSchoolsIDs: string[];
	commonForm: CommonForm;
	boxplotSinglePeriodForm: BoxplotSinglePeriodForm;
	boxplotTwoPeriodsForm: BoxplotTwoPeriodsForm;
	boxplotTwoSchoolsForm: BoxplotTwoSchoolsForm;
	piechartsForm: PiechartsForm;
	setSelectedSchoolsIDs: Dispatch<SetStateAction<string[]>>;
	subjects: DeployedSubject[];
	allSchoolsList: SchoolModel[];
	gradeLevels: GradeLevel[];
}) {
	const {allContentAreas} = WidgetCard.useWidgetCardContext();

	const handleUpdateWidget = useCallback(
		(value: DemographicPerformanceWidgetUpdatedValue) => {
			onUpdateWidget(value);

			setIsCommonFormTouched(false);

			setIsDynamicFormDataTouched(false);
			setIsDynamicFormDataValid(true);
		},
		[onUpdateWidget, setIsCommonFormTouched, setIsDynamicFormDataTouched, setIsDynamicFormDataValid],
	);

	const getCommonUpdatedValue = useCallback(
		({id, name}: {id: Widget['id']; name: string}) => {
			const {viewType, isShowAverageValue, size, demographicGroup} = commonForm.value;

			const demographicGroupValue = demographicGroup[0];

			if (isUndefined(demographicGroupValue)) {
				throw new Error('Demographic Group is undefined');
			}

			if (isNull(demographicGroupValue)) {
				throw new Error('Demographic Group is null');
			}

			const contentAreaIDs = selectedContentArea.map(Number);
			const subjectIDs = selectedSubjectIDs.map(Number);
			const gradeLevelIDs = selectedGradeLevels.map(Number);

			const commonUpdatedValue: CommonUpdatedValue = {
				id,
				name,
				options: {
					districtID,
					globalSchoolYearID,
					contentAreaIDs,
					subjectIDs,
					gradeLevelIDs,
					showAvg: isShowAverageValue,
					viewType,
					viewSize: size,
					demographicGroup: demographicGroupValue,
				},
			};

			const commonSelectedArraysEntity: CommonSelectedArraysEntity = {
				contentAreaIDs: allContentAreas.length === contentAreaIDs.length ? 'all' : 'part',
				gradeLevelIDs: gradeLevels.length === gradeLevelIDs.length ? 'all' : 'part',
				subjectIDs: subjects.length === subjectIDs.length ? 'all' : 'part',
			};

			return {commonUpdatedValue, commonSelectedArraysEntity};
		},
		[
			commonForm,
			selectedContentArea,
			selectedSubjectIDs,
			selectedGradeLevels,
			districtID,
			globalSchoolYearID,
			allContentAreas,
			gradeLevels,
			subjects,
		],
	);

	const saveBoxplotSinglePeriodForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {date, highlightColor, highlightValue, isHighlightCandles} = boxplotSinglePeriodForm.value;

			const highlightColorValue = highlightColor[0];

			if (isUndefined(highlightColorValue)) {
				throw new Error('BoxplotCompareVariant.SinglePeriod: highlightColorValue is undefined');
			}

			const schoolIDs = selectedSchoolsIDs.map(Number);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					schoolIDs,
					periods: [
						{
							color: Color.None,
							trackDatesID: date.trackDatesID,
							dateRange: date.dateRange,
							datePeriod: enumRemap(date.datePeriod, PeriodType),
						},
					],
					schools: [],
					candles:
						isHighlightCandles && !isNull(highlightColorValue)
							? {color: highlightColorValue, highlightIfAbove: highlightValue}
							: null,
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					schoolIDs: allSchoolsList.length === schoolIDs.length ? 'all' : 'part',
				},
			});
		},
		[allSchoolsList, boxplotSinglePeriodForm.value, handleUpdateWidget, selectedSchoolsIDs],
	);

	const saveBoxplotTwoPeriodsForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {firstPeriodColor, firstPeriodDate, secondPeriodColor, secondPeriodDate} = boxplotTwoPeriodsForm.value;

			const firstPeriodColorValue = firstPeriodColor[0];
			const secondPeriodColorValue = secondPeriodColor[0];

			if (isUndefined(firstPeriodColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoPeriods: firstPeriodColorValue is undefined');
			}

			if (isNull(firstPeriodColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoPeriods: firstPeriodColorValue is null');
			}

			if (isUndefined(secondPeriodColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoPeriods: secondPeriodColorValue is undefined');
			}

			if (isNull(secondPeriodColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoPeriods: secondPeriodColorValue is null');
			}

			const schoolIDs = selectedSchoolsIDs.map(Number);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					schoolIDs,
					periods: [
						{
							color: firstPeriodColorValue,
							trackDatesID: firstPeriodDate.trackDatesID,
							dateRange: firstPeriodDate.dateRange,
							datePeriod: enumRemap(firstPeriodDate.datePeriod, PeriodType),
						},
						{
							color: secondPeriodColorValue,
							trackDatesID: secondPeriodDate.trackDatesID,
							dateRange: secondPeriodDate.dateRange,
							datePeriod: enumRemap(secondPeriodDate.datePeriod, PeriodType),
						},
					],
					schools: [],
					candles: null,
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					schoolIDs: allSchoolsList.length === schoolIDs.length ? 'all' : 'part',
				},
			});
		},
		[allSchoolsList, boxplotTwoPeriodsForm.value, handleUpdateWidget, selectedSchoolsIDs],
	);

	const saveBoxplotTwoSchoolsForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {date, firstSchoolColor, firstSchoolID, secondSchoolColor, secondSchoolID} = boxplotTwoSchoolsForm.value;

			const firstSchoolColorValue = firstSchoolColor[0];
			const firstSchoolIDValue = firstSchoolID[0];

			const secondSchoolColorValue = secondSchoolColor[0];
			const secondSchoolIDValue = secondSchoolID[0];

			if (isUndefined(firstSchoolColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoSchools: firstSchoolColorValue is undefined');
			}

			if (isNull(firstSchoolColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoSchools: firstSchoolColorValue is null');
			}

			if (isUndefined(secondSchoolColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoSchools: secondSchoolColorValue is undefined');
			}

			if (isNull(secondSchoolColorValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoSchools: secondSchoolColorValue is null');
			}

			if (isUndefined(firstSchoolIDValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoSchools: firstSchoolIDValue is undefined');
			}

			if (isNull(firstSchoolIDValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoSchools: firstSchoolIDValue is null');
			}

			if (isUndefined(secondSchoolIDValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoSchools: secondSchoolIDValue is undefined');
			}

			if (isNull(secondSchoolIDValue)) {
				throw new Error('BoxplotCompareVariant.CompareTwoSchools: secondSchoolIDValue is null');
			}

			const firstSchoolIDNumberedValue = Number(firstSchoolIDValue);
			const secondSchoolIDNumberedValue = Number(secondSchoolIDValue);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					schoolIDs: [firstSchoolIDNumberedValue, secondSchoolIDNumberedValue],
					periods: [
						{
							color: Color.None,
							trackDatesID: date.trackDatesID,
							dateRange: date.dateRange,
							datePeriod: enumRemap(date.datePeriod, PeriodType),
						},
					],
					schools: [
						{
							id: firstSchoolIDNumberedValue,
							color: firstSchoolColorValue,
						},
						{
							id: secondSchoolIDNumberedValue,
							color: secondSchoolColorValue,
						},
					],
					candles: null,
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					schoolIDs: 'part',
				},
			});

			setSelectedSchoolsIDs([firstSchoolIDValue, secondSchoolIDValue]);
		},
		[boxplotTwoSchoolsForm, handleUpdateWidget, setSelectedSchoolsIDs],
	);

	const savePiechartsForm = useCallback(
		({
			commonUpdatedValue,
			commonSelectedArraysEntity,
		}: {
			commonUpdatedValue: CommonUpdatedValue;
			commonSelectedArraysEntity: CommonSelectedArraysEntity;
		}) => {
			const {date} = piechartsForm.value;

			const schoolIDs = selectedSchoolsIDs.map(Number);

			handleUpdateWidget({
				...commonUpdatedValue,
				options: {
					...commonUpdatedValue.options,
					schoolIDs,
					periods: [
						{
							color: Color.None,
							trackDatesID: date.trackDatesID,
							dateRange: date.dateRange,
							datePeriod: enumRemap(date.datePeriod, PeriodType),
						},
					],
					schools: [],
					candles: null,
				},
				selectedArraysEntity: {
					...commonSelectedArraysEntity,
					schoolIDs: allSchoolsList.length === schoolIDs.length ? 'all' : 'part',
				},
			});
		},
		[allSchoolsList, handleUpdateWidget, piechartsForm.value, selectedSchoolsIDs],
	);

	return useCallback<OnUpdateWidget>(
		({id, name}) => {
			const {commonUpdatedValue, commonSelectedArraysEntity} = getCommonUpdatedValue({id, name});

			const {viewType, boxplotCompareVariant} = commonForm.value;

			if (viewType === PerformanceWidgetViewType.Candles) {
				if (boxplotCompareVariant === BoxplotCompareVariant.SinglePeriod) {
					saveBoxplotSinglePeriodForm({commonUpdatedValue, commonSelectedArraysEntity});
				}

				if (boxplotCompareVariant === BoxplotCompareVariant.CompareTwoPeriods) {
					saveBoxplotTwoPeriodsForm({commonUpdatedValue, commonSelectedArraysEntity});
				}

				if (boxplotCompareVariant === BoxplotCompareVariant.CompareTwoSchools) {
					saveBoxplotTwoSchoolsForm({commonUpdatedValue, commonSelectedArraysEntity});
				}
			}

			if (viewType === PerformanceWidgetViewType.Piechart) {
				savePiechartsForm({commonUpdatedValue, commonSelectedArraysEntity});
			}
		},
		[
			commonForm,
			getCommonUpdatedValue,
			saveBoxplotSinglePeriodForm,
			saveBoxplotTwoPeriodsForm,
			saveBoxplotTwoSchoolsForm,
			savePiechartsForm,
		],
	);
}
