import {enumRemap} from 'shared/utils';
import {PeriodType, SelectOptionModel} from '../../../types';

export const calendarCurrent: SelectOptionModel[] = [
	{
		value: enumRemap(PeriodType.Today, PeriodType),
		label: 'Today',
	},
	{
		value: enumRemap(PeriodType.ThisWeek, PeriodType),
		label: 'This Week',
	},
	{
		value: enumRemap(PeriodType.ThisMonth, PeriodType),
		label: 'This Month',
	},
];
