import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container} from './index.styled';
import {useTestPanelContext} from '../../context';
import {Box} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';

type Props = ComponentPropsWithoutRef<typeof Container>;

export const TestCardsContent = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-panel-test-cards-content', css = {}, children, ...props}, forwardedRef) => {
		const {skeletonTestCard, skeleton} = useTestPanelContext();

		return (
			<OverlayScrollbarsComponent
				defer
				style={{
					height: 'calc(100% + 0px)',
				}}
				options={{
					scrollbars: {autoHide: 'leave'},
					paddingAbsolute: true,
				}}
			>
				<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
					{!skeleton
						? children
						: new Array(skeletonTestCard.count).fill(null).map((_, index) => (
								<Box
									css={{
										height: skeletonTestCard.maxHeight,
										maxHeight: skeletonTestCard.maxHeight,
										borderRadius: 12,
										...SkeletonStyles,
									}}
									key={`skeleton-card-${index}`}
								/>
						  ))}
				</Container>
			</OverlayScrollbarsComponent>
		);
	},
);
