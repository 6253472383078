import {ObjectEntries} from '@esgi/main/features/admins/dashboard';
import {ControlValue, Controls, ElementStatus, FormGroup} from '@esgi/ui/form';

export function setFormControlsValidStatus<T extends Controls, S extends ControlValue<T>>(formGroup: FormGroup<T, S>) {
	(Object.entries(formGroup.controls) as ObjectEntries<typeof formGroup.controls>).forEach(([key]) => {
		const control = formGroup.controls[key];

		if (control) {
			control.status = ElementStatus.valid;
		}
	});
}
