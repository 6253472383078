import {ElementStatus, FormElementContextState} from '@esgillc/ui-kit/form';
import {teacherNamesMaxLength} from './model';

export function getError(name: string, state: FormElementContextState) {
	if (state.status === ElementStatus.pending) {
		return null;
	}

	switch (state.firstError()) {
		case 'required':
			return `${name} is required.`;
		case 'email':
			return 'Invalid email format.';
		case 'length-max':
			return `You’ve exceeded the max ${teacherNamesMaxLength} symbols allowed.`;
		case 'import-idn-exist':
			return `ImportID '${state.value}' already exist.`;
		case 'export-idn-exist':
			return `ExportID '${state.value}' already exist.`;
		case 'username-exist':
			return `Username '${state.value}' already exists.`;
		default:
			return null;
	}
}