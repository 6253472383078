import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	width: '100%',
	height: '100%',
	alignItems: 'center',
	justifyContent: 'center',
});

export const ContentBox = styled(GridBox, {
	width: 'max-content',
	height: 'max-content',
	gridAutoRows: 'max-content',
	justifyItems: 'center',

	'& > svg': {
		width: '4em',
		height: '4em',
	},
});
