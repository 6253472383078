import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Content} from './index.styled';
import {CollapsiblePanel} from '@esgi/main/features/admins/data';
import {ClosePanelButton} from '../close-panel-button';
import {useSingleSelectedExpandablePanelContext} from '../../context';

type Props = ComponentPropsWithoutRef<typeof CollapsiblePanel.Header> & {
	withoutClosePanelButton?: boolean;
};

export const QuickPanelHeader = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'single-selected-expandable-panel-quick-panel-header',
			css = {},
			children,
			withoutClosePanelButton = false,
			...props
		},
		forwardedRef,
	) => {
		const {isPanelExpanded} = useSingleSelectedExpandablePanelContext();

		const closePanelButtonTooltipText = isPanelExpanded ? 'Close' : 'Deselect all';

		return (
			<CollapsiblePanel.Header dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				<Content data-quick-panel-header-content>
					{!withoutClosePanelButton && <ClosePanelButton tooltipText={closePanelButtonTooltipText} />}
					{children}
				</Content>
			</CollapsiblePanel.Header>
		);
	},
);
