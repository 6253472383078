import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {CheckboxGroup, Textarea} from '@esgi/ui/form-controls';

export const CheckboxGroupItem = styled(CheckboxGroup.Checkbox, {
	justifyContent: 'flex-start',

	'& > span': {
		display: 'inline-flex',
	},
});

export const CheckboxGroupRoot = styled(CheckboxGroup.Root, {
	display: 'grid',
	gridTemplateRows: '1fr auto',
	gap: '4px',
});

export const SpecialProgramsContainer = styled(Box, {
	padding: '8px',
	border: '1px solid $border',
	backgroundColor: '$vivid',
	borderRadius: '8px',
});

export const CommentTextarea = styled(Textarea, {
	width: '100%',
});
