import {Class, Student, User} from '@esgi/main/libs/school-admin-store';
import {isUndefined, useStreamEffect} from '@esgi/ui';
import {
	ElementStatus,
	FormControl,
	FormGroup,
	useForm,
	Validators,
} from '@esgi/ui/form';
import {Dispatch, useState} from 'react';
import {classNameMaxLength} from '../../constants';
import {AddClassForm} from './types';

type Parameters = {
	classes: Class[];
	setIsFormValid: Dispatch<boolean>;
	setIsFormTouched: Dispatch<boolean>;
};

export function useAddForm({
	classes,
	setIsFormValid,
	setIsFormTouched,
}: Parameters) {
	const [selectedPrimaryTeacherID, setSelectedPrimaryTeacherID] = useState<
		User['id'] | null
	>(null);

	const form: AddClassForm = useForm(
		() =>
			new FormGroup({
				primaryTeacherID: new FormControl<string[]>([], {
					validators: [Validators.required()],
				}),
				className: new FormControl('', {
					initialValidateStatus: ElementStatus.disabled,
				}),
				studentsIDs: new FormControl<Student['id'][]>([]),
			})
	);

	useStreamEffect(
		form.controls.primaryTeacherID.onChanged,
		({currState: {value}, reason}) => {
			const primaryTeacherID = value[0];

			if (!isUndefined(primaryTeacherID) && reason === 'value') {
				const numberedPrimaryTeacherID = Number(primaryTeacherID);

				const teacherClasses = classes.filter(
					({teacherID}) => teacherID === numberedPrimaryTeacherID
				);

				form.controls.className.validators.length = 0;

				form.controls.className.validators.push(
					Validators.required(),
					Validators.length.max(classNameMaxLength),
					Validators.isDublicateValue(teacherClasses.map(({name}) => name))
				);

				form.controls.className.status = ElementStatus.untouched;

				form.validate().subscribe(({valid}) => {
					setIsFormValid(valid);
				});

				setSelectedPrimaryTeacherID(numberedPrimaryTeacherID);
			}
		}
	);

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {studentsIDs, className, ...value},
			},
		}) => {
			const primaryTeacherID = value.primaryTeacherID[0];

			const isFormTouched =
				className !== '' ||
				!isUndefined(primaryTeacherID) ||
				Boolean(studentsIDs.length);

			setIsFormTouched(isFormTouched);

			form.validate(true).subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		}
	);

	return {
		form,
		selectedPrimaryTeacherID,
	};
}
