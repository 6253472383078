import {ErrorTooltipItem} from '../error-tooltip';

export const groupNameMaxLength = 128;

export const errorItems: ErrorTooltipItem<string>[] = [
	{
		showOnError: 'required',
		message: 'Not less than 1 character',
	},
	{
		showOnError: 'isDublicateValue',
		message: 'The spec.group name should be unique within the groups of the specialist',
	},
	{
		showOnError: 'length-max',
		message: `No more than ${groupNameMaxLength} characters`,
	},
];
