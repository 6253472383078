import React from 'react';
import {UserType, useUser} from '@esgi/core/authentication';
import {RedesignRoutesTeacher} from './teacher';
import {NotFound as NotFoundTeacherRoute} from '@esgi/main/pages/teacher';
import {isNull} from 'underscore';
import {RedesignRoutesDistrictAdmin} from './district-admin';
import {NotFound as NotFoundDistrictAdminRoute} from '@esgi/main/pages/district-admin';
import {RedesignRoutesSchoolAdmin} from './school-admin';
import {NotFound as NotFoundSchoolAdminRoute} from '@esgi/main/pages/school-admin';

export function getRedesignAppRoutes(userType: UserType): JSX.Element {
	switch (userType) {
		case UserType.T:
			return RedesignRoutesTeacher();
		case UserType.D:
			return RedesignRoutesDistrictAdmin();
		case UserType.C:
			return RedesignRoutesSchoolAdmin();
		default:
			return <React.Fragment />;
	}
}

export function NotFoundRedesign() {
	const currentUser = useUser();

	if (isNull(currentUser)) {
		return null;
	}

	switch (currentUser.userType) {
		case UserType.T:
			return <NotFoundTeacherRoute />;
		case UserType.D:
			return <NotFoundDistrictAdminRoute />;
		case UserType.C:
			return <NotFoundSchoolAdminRoute />;
		default:
			return <React.Fragment />;
	}
}
