import {GridBox} from '@esgi/ui/layout';
import {SkeletonStyles} from '@esgi/ui/skeleton';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const SubjectGridBox = styled(GridBox, {
	width: '100%',
	alignItems: 'center',
	gap: 6,

	'& [data-cheap-box]': {
		alignSelf: 'start',
	},

	variants: {
		withCheapBox: {
			true: {},
			false: {},
		},

		withHideIcon: {
			true: {},
			false: {},
		},
	},

	compoundVariants: [
		{
			withCheapBox: true,
			withHideIcon: true,
			css: {
				gridTemplateColumns: 'auto auto 1fr',
			},
		},
		{
			withCheapBox: true,
			withHideIcon: false,
			css: {
				gridTemplateColumns: 'auto 1fr',
			},
		},
		{
			withCheapBox: false,
			withHideIcon: true,
			css: {
				gridTemplateColumns: 'auto 1fr',
			},
		},
		{
			withCheapBox: false,
			withHideIcon: false,
			css: {
				gridTemplateColumns: '1fr',
			},
		},
	],
});

export const HideIconBox = styled(GridBox, {
	alignSelf: 'start',

	'& svg': {
		width: 20,
		height: 20,

		'& path': {
			fill: '$muted',
		},
	},

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				borderRadius: '50%',
			},
		},
	},
});

export const SubjectText = styled(Text, {
	wordBreak: 'break-word',

	variants: {
		skeleton: {
			true: {
				...SkeletonStyles,
				width: '100%',
				height: 12,
				borderRadius: 6,
			},
		},
	},
});
