import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Cell = styled(FlexBox, {
	width: '100%',
	height: '100%',
	gap: 2,
	alignItems: 'center',
	userSelect: 'none',
});

export const SortBox = styled(FlexBox, {
	'& > svg': {
		width: 12,
		height: 12,
	},
});

export const MainBox = styled(FlexBox, {
	gap: 2,
	alignItems: 'center',

	[`& > ${Text}`]: {
		color: '$mediumContrast',
	},

	variants: {
		sortable: {
			true: {
				cursor: 'pointer',

				[`& > ${Text}`]: {
					transition: 'сolor .3s',
				},

				[`& > ${SortBox}`]: {
					'& path': {
						transition: 'fill .3s',
					},
				},

				'&:hover': {
					[`& > ${Text}`]: {
						color: '$secondary',
					},
				},
			},
		},

		activeSortDirection: {
			asc: {
				[`& > ${Text}`]: {
					color: '$base',
				},

				[`& > ${SortBox}`]: {
					'& path:first-child': {
						fill: '$mediumContrast',
					},

					'& path:last-child': {
						fill: '$base',
					},
				},

				'&:hover': {
					[`& > ${SortBox}`]: {
						'& path:last-child': {
							fill: '$secondary',
						},
					},
				},
			},

			desc: {
				[`& > ${Text}`]: {
					color: '$base',
				},

				[`& > ${SortBox}`]: {
					'& path:first-child': {
						fill: '$base',
					},

					'& path:last-child': {
						fill: '$mediumContrast',
					},
				},

				'&:hover': {
					[`& > ${SortBox}`]: {
						'& path:first-child': {
							fill: '$secondary',
						},
					},
				},
			},

			none: {
				[`& > ${SortBox}`]: {
					'& path:first-child': {
						fill: '$mediumContrast',
					},

					'& path:last-child': {
						fill: '$mediumContrast',
					},
				},
			},
		},
	},
});

export const FilterBox = styled(FlexBox, {
	cursor: 'pointer',

	'& > svg': {
		width: 12,
		height: 12,
		fill: '$mediumContrast',
		transition: 'fill .3s',

		'& path': {
			fill: '$mediumContrast',
			transition: 'fill .3s',
		},
	},

	'&:hover': {
		'& > svg': {
			fill: '$secondary',

			'& path': {
				fill: '$secondary',
			},
		},
	},

	variants: {
		isActive: {
			true: {
				'& > svg': {
					fill: '$primary',

					'& path': {
						fill: '$primary',
					},
				},
			},
		},
	},
});
