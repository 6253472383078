import {useCallback, useState} from 'react';
import {More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {ContextMenu, Button, Tooltip} from '@esgi/ui';
import {ContextMenuItem} from './index.styled';
import {useUser} from '@esgi/core/authentication';
import {RemoveTeacher} from '../../../../remove-teacher';

interface Props {
	teacherID: number;
	isInitialized: boolean;
	closeDrawer: VoidFunction;
}

export function MoreOptions({isInitialized, closeDrawer, teacherID}: Props) {
	const currentUser = useUser();

	const removeTeacherManager = RemoveTeacher.useManagerRef();

	const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

	const handleDeleteClick = useCallback(() => {
		setIsContextMenuOpen(false);

		removeTeacherManager.current?.runRemove({teacherID});
	}, [removeTeacherManager, teacherID]);

	const userCanAddAndDeleteTeachers = Boolean(currentUser?.canAddAndDeleteTeachers);

	return (
		<>
			<ContextMenu.Root open={isContextMenuOpen} onOpenChange={setIsContextMenuOpen}>
				<ContextMenu.Trigger>
					<Button.Icon skeleton={!isInitialized} withBackgroundHover>
						<More width={24} height={24} />
					</Button.Icon>
				</ContextMenu.Trigger>
				<ContextMenu.Content>
					<ContextMenu.Group>
						<Tooltip open={userCanAddAndDeleteTeachers ? false : undefined}>
							<Tooltip.Trigger>
								<ContextMenuItem onClick={handleDeleteClick} disabled={!userCanAddAndDeleteTeachers}>
									<Text size='medium'>Delete</Text>
								</ContextMenuItem>
							</Tooltip.Trigger>
							<Tooltip.Content>
								<Text size='large' color='tertiary'>
									To delete the Teacher, please contact Customer Support support@esgisoftware.com
								</Text>
							</Tooltip.Content>
						</Tooltip>
					</ContextMenu.Group>
				</ContextMenu.Content>
			</ContextMenu.Root>

			<RemoveTeacher managerRef={removeTeacherManager} onRemoved={closeDrawer} />
		</>
	);
}
