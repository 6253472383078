import React from 'react';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Question, Setting} from '@esgi/ui/icons';
import {NavigationBar, NavigationBarOrientation} from '@esgi/ui/layout';
import {navigateItems} from '../constants';
import {AdditionalElementType, NavigationPanelVariantProps} from '../types';
import {NavigationPageItem} from '../../components/navigation-page-item';
import {LogoWithCaption} from '../../components/logo-with-caption';
import {PanelButton} from '../../components/panel-button';
import {UserAccountButton} from '../../components/user-account-button';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {FooterContentBox, HeaderContentBox, navigationBarCSS, navigationBarContentCSS} from './index.styled';
import {SchoolYearSelect} from '@esgi/main/features/common/school-year-select';

export function VerticalPanel({
	onSchoolYearChanged,
	onSettingsClick,
	userFirstName,
	userLastName,
	userAvatarSrc,
	onAvatarClick,
	onHelpClick,
}: NavigationPanelVariantProps) {
	return (
		<NavigationBar.Root orientation={NavigationBarOrientation.Vertical} css={navigationBarCSS}>
			<HeaderContentBox>
				<LogoWithCaption />
				<SchoolYearSelect onSchoolYearChanged={onSchoolYearChanged} />
			</HeaderContentBox>

			<OverlayScrollbarsComponent
				defer
				style={{
					height: 'calc(100% - 0px)',
				}}
				options={{
					scrollbars: {
						autoHide: 'leave',
					},
				}}
			>
				<NavigationBar.Content css={navigationBarContentCSS}>
					{navigateItems.map((item, index) =>
						item === AdditionalElementType.Divider ? (
							<NavigationBar.Divider key={index} />
						) : (
							<NavigationPageItem Icon={item.Icon} label={item.label} to={item.to} key={index} />
						),
					)}
				</NavigationBar.Content>
			</OverlayScrollbarsComponent>

			<FooterContentBox>
				<Tooltip>
					<Tooltip.Trigger>
						<PanelButton onClick={onSettingsClick}>
							<Setting />
						</PanelButton>
					</Tooltip.Trigger>
					<Tooltip.Content variant='secondary'>
						<Text size='small'>System Settings</Text>
					</Tooltip.Content>
				</Tooltip>
				<UserAccountButton
					size='lg'
					firstName={userFirstName}
					lastName={userLastName}
					avatarSrc={userAvatarSrc}
					onClick={onAvatarClick}
				/>
				<PanelButton variant='orange' onClick={onHelpClick}>
					<Question />
				</PanelButton>
			</FooterContentBox>
		</NavigationBar.Root>
	);
}
