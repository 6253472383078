import {
	HierarchyInstance,
} from 'modules/hierarchy/core/models';
import React, {Suspense} from 'react';
import {SubjectType} from '@esgi/core/enums';
import {lazyComponent} from '@esgi/core/react';

const GrowthReportModal = lazyComponent(() => import('modules/reports/growth-report'));

type Props = {
	subjectType: SubjectType,
	subjectID: number,
	hierarchy: HierarchyInstance,
	onClose: VoidFunction,
}

export function GrowthReportLauncher(props: Props) {
	return <Suspense fallback={<></>}>
		<GrowthReportModal hierarchy={props.hierarchy}
		                   onCloseClicked={props.onClose}
		                   showNumberOfStudents=''
		                   trackId={0}
		                   subjectId={props.subjectID}
		                   subjectType={props.subjectType as any as string}
		/>
	</Suspense>;
}