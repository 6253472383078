import {SubjectGridBox, SubjectText} from './index.styled';
import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {usePanelContext} from '../../context';
import {SubjectCheap} from '../../../kit';

type Props = BaseComponentProps & Omit<ComponentPropsWithoutRef<typeof SubjectGridBox>, 'children' | 'skeleton'>;

export const SubjectName = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'subject-name', css = {}, ...props}, forwardedRef) => {
		const {skeleton, subjectLevel, subjectType, subjectName} = usePanelContext();

		const renderCheap = () => {
			if (subjectType === SubjectType.Stock) {
				return <SubjectCheap.Variant.Stock skeleton={skeleton} />;
			}

			switch (subjectLevel) {
				case SubjectLevel.District:
					return <SubjectCheap.Variant.District skeleton={skeleton} />;
				case SubjectLevel.School:
					return <SubjectCheap.Variant.School skeleton={skeleton} />;
				case SubjectLevel.Teacher:
					return <SubjectCheap.Variant.Teacher skeleton={skeleton} />;
			}
		};

		return (
			<SubjectGridBox dataCy={dataCy} css={css} ref={forwardedRef} {...props}>
				{renderCheap()}

				<SubjectText data-cy={`${dataCy}-title`} size='small' color='highContrast' skeleton={skeleton}>
					{subjectName}
				</SubjectText>
			</SubjectGridBox>
		);
	},
);
