export function getDateTimeWithGMT({dateTime, timeZone}: {dateTime: string; timeZone?: string | undefined}) {
	const date = new Date(dateTime);

	const dateTimeWithGMT = date.toLocaleDateString('en-US', {
		timeZone,
		timeZoneName: 'longOffset',
	});

	const defaultGMT = '+00:00';
	const gmtTime = RegExp(/GMT(.*)/)
		.exec(dateTimeWithGMT)?.[1]
		?.trim();

	// gmtTime is empty string, if timeZone is UTC
	if (gmtTime === '') {
		return `${dateTime}${defaultGMT}`;
	}

	return `${dateTime}${gmtTime ?? defaultGMT}`;
}
