import {PropsWithChildren, useMemo} from 'react';
import {Skeletonable} from '@esgi/ui';
import {PanelContext, PanelContextValue} from '../context';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';

type Props = PropsWithChildren<
	Skeletonable & {
		subjectLevel: SubjectLevel;
		subjectType: SubjectType;
		subjectName: string;
	}
>;

export function Root({skeleton, children, subjectType, subjectName, subjectLevel}: Props) {
	const context = useMemo<PanelContextValue>(
		() => ({
			skeleton: Boolean(skeleton),
			subjectType,
			subjectName,
			subjectLevel,
		}),
		[skeleton, subjectLevel, subjectName, subjectType],
	);

	return <PanelContext.Provider value={context}>{children}</PanelContext.Provider>;
}
