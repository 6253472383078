import {Portal, Skeletonable} from '@esgi/ui';
import {Drawer, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {AddWidget} from '@esgi/ui/icons';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {GridBox} from '@esgi/ui/layout';
import {useState} from 'react';
import {isNull} from 'underscore';
import {useUser} from '@esgi/core/authentication';
import {OnWidgetCreate, AddWidgetModel} from './types';
import {WidgetCard} from './components/widget-card';

type Props<T extends string> = Skeletonable<{
	container?: Element | DocumentFragment | null | undefined;
	onClose: VoidFunction;
	widgets: AddWidgetModel<T>[];
	onWidgetCreate: OnWidgetCreate<T>;
}>;

export function AddWidgetBase<T extends string>({container, onClose, widgets, onWidgetCreate, skeleton}: Props<T>) {
	const currentUser = useUser();

	const drawerRef = useDrawerRef();
	const closeDrawer = useDrawerClose(drawerRef, onClose);

	const [isWidgetSaving, setIsWidgetSaving] = useState(false);
	const [selectedWidgetType, setSelectedWidgetType] = useState<T | null>(null);

	const handleSaveWidget = () => {
		if (isNull(currentUser)) {
			throw new Error('currentUser is null');
		}

		if (isNull(selectedWidgetType)) {
			throw new Error('selectedWidgetType is null');
		}

		const {districtID, globalSchoolYearID, schoolID} = currentUser;

		setIsWidgetSaving(true);

		onWidgetCreate({districtID, schoolID, globalSchoolYearID, selectedWidgetType}).subscribe({
			complete: () => {
				setIsWidgetSaving(true);
				closeDrawer();
			},
		});
	};

	return (
		<Portal container={container}>
			<Drawer drawerRef={drawerRef} onClickOutside={closeDrawer}>
				<Drawer.Header
					Icon={AddWidget}
					sectionName='Add Widget'
					actionButtonText='Add'
					closeDrawer={closeDrawer}
					withActionButton
					onActionButtonClick={handleSaveWidget}
					actionButtonDisabled={isNull(selectedWidgetType) || isWidgetSaving || skeleton}
				/>

				<Drawer.Body>
					<OverlayScrollbarsComponent
						defer
						options={{
							scrollbars: {autoHide: 'leave'},
						}}
					>
						<GridBox gap='3'>
							{widgets.map(({title, description, labels, type}) => (
								<WidgetCard
									title={title}
									description={description}
									labels={labels}
									isSelected={selectedWidgetType === type}
									onClick={() => setSelectedWidgetType(type)}
									key={type}
								/>
							))}
						</GridBox>
					</OverlayScrollbarsComponent>
				</Drawer.Body>
			</Drawer>
		</Portal>
	);
}
