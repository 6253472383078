import {forwardRef} from 'react';
import {TabProps} from '../types';
import {useSubjectPanelContext} from '../../../context';
import {SelectableList} from '../../lists/selectable-list';
import {isUndefined} from '@esgi/ui';
import {ListsWrapper} from './index.styled';
import {TabID} from '../../../types/section';
import {BaseTabContent} from '../base-tab-content';
import {EmptyContentBox} from '../../empty-content-box';
import {Text} from '@esgi/ui/typography';

export const All = forwardRef<HTMLDivElement, TabProps>(
	(
		{
			dataCy = 'all-tab-content',
			css = {},
			tabContent = 'All',
			tabDisabled,
			tabDescription = 'All Subject Tabs',
			tabApplyDisabledStyles,
			children,
			...boxProps
		},
		forwardedRef,
	) => {
		const {entities, skeleton} = useSubjectPanelContext();

		const renderContent = () => {
			if (!isUndefined(children)) {
				return children;
			}

			if (skeleton) {
				return <SelectableList subjects={[]} />;
			}

			const notEmptySubjectsLists = [
				entities.districtSubjects,
				entities.schoolSubjects,
				entities.teacherSubjects,
			].filter((subjects) => Boolean(subjects.length));

			if (!notEmptySubjectsLists.length) {
				return (
					<EmptyContentBox justify='center'>
						<Text size='small' font='mono' color='mediumContrast'>
							No subjects to display
						</Text>
					</EmptyContentBox>
				);
			}

			return (
				<ListsWrapper>
					{notEmptySubjectsLists.map((subjects, index) => (
						<SelectableList subjects={subjects} key={subjects[0]?.level ?? index} />
					))}
				</ListsWrapper>
			);
		};

		return (
			<BaseTabContent
				dataCy={dataCy}
				css={css}
				tabID={TabID.All}
				tabContent={tabContent}
				description={tabDescription}
				applyDisabledStyles={tabApplyDisabledStyles}
				disabled={tabDisabled}
				subjects={[]}
				ref={forwardedRef}
				{...boxProps}
			>
				{renderContent()}
			</BaseTabContent>
		);
	},
);
