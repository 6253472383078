import {TimeText} from '@esgi/main/kits/common';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {Text} from '@esgi/ui/typography';
import {isNull} from 'underscore';

type Props = {
	averageSessionDuration: number | null;
};

export function AverageSessionAnalytic({averageSessionDuration}: Props) {
	return (
		<TestCard.Statistic.InfoWithTranscript transcript='Av. Session'>
			{!isNull(averageSessionDuration) ? (
				<TimeText time={averageSessionDuration} timeFormat='mm:ss' size='medium' font='mono' color='base' />
			) : (
				<Text size='medium' font='mono' color='base'>
					-
				</Text>
			)}
		</TestCard.Statistic.InfoWithTranscript>
	);
}
