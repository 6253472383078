import React, {Suspense, useEffect} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {SsoTracker} from '@esgi/core/tracker';

const PreassessActivityReportModal = lazyComponent(() => import('modules/reports/preassess-activity'));

type Props = {
	onClose: VoidFunction;
}

export function PreAssessActivityLauncher(props: Props) {
	useEffect(() => {
		SsoTracker.trackEvent({
			trackingEvent: 'PreassessActivityReport',
		});
	}, []);

	return <Suspense fallback={<div/>}>
		<PreassessActivityReportModal onCloseClicked={props.onClose}/>
	</Suspense>;
}