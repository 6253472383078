import {styled} from '@esgi/ui/theme';
import {PanelContainer} from '../../../components/panels.styled';
import {GridBox} from '@esgi/ui/layout';

export const Container = styled(PanelContainer, {
	borderRightStyle: 'solid',
	borderRightWidth: 1,
	borderRightColor: '$border',
});

export const MainContentBox = styled(GridBox, {
	gap: 20,
});
