import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useUser} from '@esgi/core/authentication';
import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {isNull} from 'underscore';
import {useEffect, useMemo} from 'react';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {TestResultsSubject} from '@esgi/main/kits/admin';

type Parameters = {
	targetGradeLevels: number[];
	isUserIDAsSpecialist: boolean;
	userID: number | null;
};

type Out = [boolean, TestResultsSubject[]];

export function useSubjectsByDistrictID({targetGradeLevels, userID, isUserIDAsSpecialist}: Parameters): Out {
	const currentUser = useUser();
	const api = useEsgiApiGateway();

	const [districtSubjectsData, fetchDistrictSubjects] = useCancelableRequest(
		api.v2.schoolAdmins.modules.subjects.byDistrictId,
	);

	const withFetchedData = !isNull(currentUser);

	useEffect(() => {
		if (withFetchedData) {
			fetchDistrictSubjects({
				globalSchoolYearID: currentUser.globalSchoolYearID,
				districtID: currentUser.districtID,
				inDistrictLevelOnly: true,
			});
		}
	}, [currentUser, fetchDistrictSubjects, withFetchedData]);

	return useMemo<Out>(() => {
		if (!withFetchedData) {
			return [true, []];
		}

		const isSubjectsLoaded = isAsyncSucceed(districtSubjectsData);

		if (!isSubjectsLoaded) {
			return [false, []];
		}

		const filteredSubjects = districtSubjectsData.data.value.deployedSubjects.filter(
			({published, hasTests, hidden, gradeLevels, allowedSchools, allowedSpecialists}) => {
				const filterByAllowedSchools = !isNull(currentUser) ? allowedSchools.includes(currentUser.schoolID) : true;

				const filterByAllowedSpecialists =
					isUserIDAsSpecialist && !isNull(userID) ? allowedSpecialists.includes(userID) : true;

				return (
					published &&
					hasTests &&
					!hidden &&
					gradeLevels.some((gradeLevel) => targetGradeLevels.includes(gradeLevel)) &&
					filterByAllowedSchools &&
					filterByAllowedSpecialists
				);
			},
		);

		const activeSubjects = filteredSubjects.map<TestResultsSubject>(({id, name}) => ({
			id,
			name,
			level: SubjectLevel.District,
			type: SubjectType.Deployed,
			gradeLevels: [],
		}));

		return [true, activeSubjects];
	}, [currentUser, districtSubjectsData, isUserIDAsSpecialist, targetGradeLevels, userID, withFetchedData]);
}
