import {isNull} from 'underscore';
import {useCallback, useMemo, useState} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {useUser} from '@esgi/core/authentication';
import {Test, TestType} from '../../../../service/types';
import {TestRow} from '../row';
import {Skeleton} from '../skeleton';
import {TestDetailsState} from './types';
import {StyledScrollBody} from './index.styled';

const TestDetails = lazyComponent(() => import('shared/modules/test-details/test-details'));
const RubricDetails = lazyComponent(() => import('modules/assets/tests/rubric/details/root'));

type Props = {
	loaded: boolean;
	tests: Test[];
	selectedTests: Test[];
	onTestSelect: (test: Test) => void;
};

export function Body({loaded, tests, selectedTests, onTestSelect}: Props) {
	const user = useUser();

	const [testDetailsState, setTestDetailsState] = useState<TestDetailsState | null>(null);

	const selectedTestIDs = useMemo(() => (
		selectedTests.map((item) => item.id)
	), [selectedTests]);

	const closeTestDetails = useCallback(() => {
		setTestDetailsState(null);
	}, []);

	return (
		<>
			<StyledScrollBody>
				{loaded ? (tests.map((test) => (
					<TestRow
						key={test.id + test.created}
						testInfo={test}
						selected={selectedTestIDs?.includes(test.id) || test.disabled}
						onTestSelect={onTestSelect}
						onTestNameClick={setTestDetailsState}
						stateStandards={test.stateStandards}
					/>
				))) : (
					<Skeleton />
				)}
			</StyledScrollBody>

			{!isNull(testDetailsState) && (
				(testDetailsState.testType === TestType.Score || testDetailsState.testType === TestType.YN) && (
					<TestDetails
						testID={testDetailsState.testId}
						firstName={user.firstName}
						lastName={user.lastName}
						userID={user.userID}
						close={closeTestDetails}
						disableCopyTest
					/>
				)
			)}

			{!isNull(testDetailsState) && testDetailsState.testType === TestType.Rubric && (
				<RubricDetails testID={testDetailsState.testId} disableCopy disableAddToSubject close={closeTestDetails} />
			)}
		</>
	);
}
