import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {ChangeEvent, PropsWithChildren, Ref, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useState} from 'react';
import {Input} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {Text} from '@esgi/ui/typography';
import {Drawer} from '@esgi/main/kits/common';
import {markingPeriodItems} from '../../../constants';
import {PanelContent} from '../../../../../../components/panels.styled';
import {ContentBox} from '../../../../../../components/content-box.styled';
import {TrackDatesWithSlider} from '../../../../../../../track-dates-with-slider';
import {isTrackDatesValid} from '../../../../../../../../utils/is-track-dates-valid';
import {useDateValuesChanged} from '../../../../../../../../hooks/use-date-values-changed';
import {ToggleGroupContent, ToggleGroupItem, ToggleGroupRoot, Transcript} from '../../components/components.styled';
import {BaseTrackDateModel, SchoolYearModel, TrackDateModelState, TrackType} from '../../../../../../../../types';
import {AddEditModeContentRef, Mode} from '../types';

type Props = PropsWithChildren<{
	mode: Mode;
	defaultMarkingPeriodName: string;
	defaultMarkingPeriodType: TrackType;
	schoolYearTypes: SchoolYearModel[] | null;
	defaultTrackDates: BaseTrackDateModel[];
	setIsMarkingPeriodTouched?: React.Dispatch<React.SetStateAction<boolean>>;
	setIsMarkingPeriodValid: React.Dispatch<React.SetStateAction<boolean>>;
	addEditModeContentRef: Ref<AddEditModeContentRef | undefined>;
}>;

export function AddEditModeContent({
	mode,
	defaultMarkingPeriodName,
	defaultMarkingPeriodType,
	schoolYearTypes,
	defaultTrackDates,
	setIsMarkingPeriodTouched,
	setIsMarkingPeriodValid,
	addEditModeContentRef,
	children,
}: Props) {
	const [markingPeriodName, setMarkingPeriodName] = useState(defaultMarkingPeriodName);
	const [markingPeriodNameError, setMarkingPeriodNameError] = useState<string>();
	const [markingPeriodType, setMarkingPeriodType] = useState(defaultMarkingPeriodType);
	const [datesInRowsState, setDatesInRowsState] = useState<TrackDateModelState[]>([]);

	const isDatesChanged = useDateValuesChanged({
		datesInRowsState,
		currentTrackDates: defaultTrackDates,
	});

	const dataIsValid = useMemo(() => isTrackDatesValid(datesInRowsState), [datesInRowsState]);

	const isMarkingPeriodValid = dataIsValid && !markingPeriodNameError;
	const isMarkingPeriodTouched = isDatesChanged || defaultMarkingPeriodName !== markingPeriodName;

	const handleMarkingPeriodNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;

		setMarkingPeriodName(value);

		if (!value) {
			setMarkingPeriodNameError('Marking Period Name is required');
			return;
		}

		if (!value.trim()) {
			setMarkingPeriodNameError('Incorrect Marking Name');
			return;
		}

		setMarkingPeriodNameError(undefined);
	}, []);

	const handleMarkingPeriodNumberChange = useCallback((tabId: string) => {
		if (!tabId) {
			setMarkingPeriodType(markingPeriodItems[0].value);
			return;
		}

		setMarkingPeriodType(tabId as TrackType);
	}, []);

	useEffect(() => {
		setIsMarkingPeriodTouched?.(isMarkingPeriodTouched);
	}, [isMarkingPeriodTouched]);

	useEffect(() => {
		setIsMarkingPeriodValid(isMarkingPeriodValid);
	}, [isMarkingPeriodValid]);

	useLayoutEffect(() => {
		setMarkingPeriodName(defaultMarkingPeriodName);
	}, [defaultMarkingPeriodName]);

	useLayoutEffect(() => {
		setMarkingPeriodType(defaultMarkingPeriodType);
	}, [defaultMarkingPeriodType]);

	useImperativeHandle(addEditModeContentRef, () => ({
		getContentData: () => ({
			markingPeriodName: markingPeriodName.trim(),
			markingPeriodType,
			datesInRowsState,
		}),
		isDatesChanged,
	}));

	return (
		<OverlayScrollbarsComponent
			defer
			options={{
				scrollbars: {autoHide: 'leave'},
				paddingAbsolute: true,
			}}
		>
			<PanelContent>
				<ContentBox>
					<Drawer.ContentBlock title='Current Marking Period'>
						{children}
						<GridBox dataCy='marking-period-name'>
							<Input
								value={markingPeriodName}
								onChange={handleMarkingPeriodNameChange}
								placeholder='Marking Period Name'
								error={markingPeriodNameError}
							/>
						</GridBox>
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Number of Marking Periods'>
						<ToggleGroupRoot
							dataCy='number-of-marking-periods-box'
							onValueChange={handleMarkingPeriodNumberChange}
							value={markingPeriodType}
							disabled={mode === Mode.Edit}
						>
							<ToggleGroupContent>
								{markingPeriodItems.map(({label, value}) => (
									<ToggleGroupItem dataCy='number-of-marking-periods-item' value={value} key={value}>
										<Text size='medium' bold color='base'>
											{label}
										</Text>
									</ToggleGroupItem>
								))}
							</ToggleGroupContent>
						</ToggleGroupRoot>
						{mode === Mode.Edit && (
							<Transcript>
								<Text size='small' color='base'>
									Please contact support@esgisoftware.com if you would like to change the number of marking periods.
								</Text>
							</Transcript>
						)}
					</Drawer.ContentBlock>

					<Drawer.ContentBlock title='Marking Period Dates'>
						{schoolYearTypes && (
							<TrackDatesWithSlider
								schoolYearTypes={schoolYearTypes}
								defaultTrackDates={defaultTrackDates}
								defaultTrackType={defaultMarkingPeriodType}
								selectedTrackType={markingPeriodType}
								datesInRowsState={datesInRowsState}
								setDatesInRowsState={setDatesInRowsState}
								withDateSlider
							/>
						)}
					</Drawer.ContentBlock>
				</ContentBox>
			</PanelContent>
		</OverlayScrollbarsComponent>
	);
}
