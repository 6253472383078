import {Box} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {OneLinedText} from '@esgi/main/kits/common';

type Props = {
	value: string;
	placeholder: string;
};

export function DisabledInput({value, placeholder}: Props) {
	return (
		<Box>
			<Text size='xSmall' font='mono' color='lowContrast'>
				{placeholder}
			</Text>
			<OneLinedText size='medium' bold color='mediumContrast'>
				{value}
			</OneLinedText>
		</Box>
	);
}
