import {ExpandableSelectableBlockTab, ItemNumerable} from '@esgi/main/features/admins/subject-management';
import {PublishForSpecialistsTabID} from '../../../../types';

type Parameters = {
	schoolSpecialists: ItemNumerable[];
};

export function getPublishForSpecialistsInit({
	schoolSpecialists,
}: Parameters): ExpandableSelectableBlockTab<PublishForSpecialistsTabID>[] {
	return [
		{
			value: PublishForSpecialistsTabID.All,
			label: 'All',
			isActive: true,
			allItems: [],
			selectedItems: [],
		},
		{
			value: PublishForSpecialistsTabID.BySpecialist,
			label: 'By Specialist',
			isActive: false,
			allItems: schoolSpecialists,
			selectedItems: schoolSpecialists.map(({id}) => id),
		},
	];
}
