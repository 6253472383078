import {styled} from '@esgi/ui/theme';
import {Alert} from '@esgi/ui/alert';
import {CSS} from '@stitches/react';
import {GridBox} from '@esgi/ui/layout';

export const rootCSS: CSS = {
	'& [data-alert-content]': {
		width: 508,
		maxHeight: 'calc(100% - 20px)',
		gridTemplateRows: 'auto 1fr',
		overflow: 'hidden',
	},
};

export const AlertHeader = styled(Alert.Header, {
	height: 64,
});

export const BodyContent = styled(GridBox, {
	gap: '$4',
	overflow: 'hidden',
});
