import {useUser} from '@esgi/core/authentication';
import {useDisposable} from '@esgi/core/service';
import {V2DistrictAdminsClassesController} from '@esgi/contracts/esgi';
import {useCallback, useEffect, useState} from 'react';
import {isAsyncPending, isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';
import {classesStore, studentsStore, Class, Student} from '@esgi/main/libs/admin-store';
import {dispatchAppEvent} from '@esgillc/events';
import {isNull} from 'underscore';
import {ClassCreatedEvent} from '../../events';
import {AddClassForm} from './form-data/types';

type NewClassDataModel = Omit<Class, 'id'> & {
	studentsIDs: Student['id'][];
};

type Parameters = {
	closeDrawer: VoidFunction;
	form: AddClassForm;
};

export function useSave({closeDrawer, form}: Parameters) {
	const currentUser = useUser();

	const classesController = useDisposable(V2DistrictAdminsClassesController);

	const [newClassDataModel, setNewClassDataModel] = useState<NewClassDataModel | null>(null);
	const [createClassData, createClass] = useCancelableRequest(classesController.create);

	useEffect(() => {
		if (isAsyncSucceed(createClassData) && !isNull(newClassDataModel)) {
			const classesStorage = classesStore();
			const studentsStorage = studentsStore();

			const newClassID = createClassData.data.classID;

			const addClassToStore: Class = {
				id: newClassID,
				name: newClassDataModel.name,
				schoolID: newClassDataModel.schoolID,
				teacherID: newClassDataModel.teacherID,
			};

			classesStorage.add?.(addClassToStore);

			studentsStorage.update?.((item: Student): Student => {
				if (newClassDataModel.studentsIDs.includes(item.id)) {
					return {
						...item,
						classesIDs: [...item.classesIDs, newClassID],
						teacherID: newClassDataModel.teacherID,
					};
				}

				return item;
			});

			dispatchAppEvent(
				ClassCreatedEvent,
				new ClassCreatedEvent({
					id: newClassID,
					name: newClassDataModel.name,
				}),
			);

			closeDrawer();
		}
	}, [closeDrawer, createClassData, newClassDataModel]);

	const saveData = useCallback(() => {
		if (isNull(currentUser)) {
			return;
		}

		const {primaryTeacherID, className, studentsIDs, schoolID} = form.value;
		const schoolIDValue = schoolID[0];
		const teacherID = primaryTeacherID[0];

		if (isUndefined(schoolIDValue)) {
			throw new Error('schoolIDValue is undefined');
		}

		if (isUndefined(teacherID)) {
			throw new Error('teacherID is undefined');
		}

		const numberedTeacherID = Number(teacherID);

		createClass({
			teacherID: numberedTeacherID,
			name: className,
			globalSchoolYearID: currentUser.globalSchoolYearID,
			studentIDs: studentsIDs,
		});

		setNewClassDataModel({
			name: className,
			schoolID: Number(schoolIDValue),
			studentsIDs,
			teacherID: numberedTeacherID,
		});
	}, [createClass, currentUser, form]);

	return {
		saveData,
		isDataSaving: isAsyncPending(createClassData),
	};
}
