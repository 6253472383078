import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container} from './index.styled';

type Props = BaseComponentProps & ComponentPropsWithoutRef<typeof Container>;

export const Root = forwardRef<HTMLDivElement, Props>(
	(
		{dataCy = 'subject-selection-panel-section-title', css = {}, align = 'center', children, ...gridProps},
		forwardedRef,
	) => {
		return (
			<Container dataCy={dataCy} css={css} align={align} ref={forwardedRef} {...gridProps}>
				{children}
			</Container>
		);
	},
);
