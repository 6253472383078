import {useCallback, useState} from 'react';
import {More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {ContextMenu, Button} from '@esgi/ui';
import {ContextMenuItem} from './index.styled';

interface Props {
	onDeleteStudent?: VoidFunction;
	isInitialized: boolean;
}

export function MoreOptions({onDeleteStudent, isInitialized}: Props) {
	const [isOpen, setOpen] = useState(false);

	const handleDelete = useCallback(() => {
		setOpen(false);
		onDeleteStudent();
	}, [onDeleteStudent]);

	return (
		<ContextMenu.Root open={isOpen} onOpenChange={setOpen}>
			<ContextMenu.Trigger>
				<Button.Icon skeleton={!isInitialized} withBackgroundHover>
					<More width={24} height={24}/>
				</Button.Icon>
			</ContextMenu.Trigger>
			<ContextMenu.Content>
				<ContextMenu.Group>
					<ContextMenuItem onClick={handleDelete}>
						<Text size='medium'>Delete</Text>
					</ContextMenuItem>
				</ContextMenu.Group>
			</ContextMenu.Content>
		</ContextMenu.Root>
	);
}

