import {useState} from 'react';
import {useInterval} from './use-interval';
import {DataMessagesInfoBox} from '../data-messages-info-box';
import {secondStepMessage, dataСountingMessages, maxLoaderStepTime} from './constants';
import {ContainerWithLoader} from './index.styled';
import {Loader} from './components/loader';
import moment from 'moment';

type Props = {
	lastUpdatedTime: string;
};

export function CountingWidgetData({lastUpdatedTime}: Props) {
	const [time, setTime] = useState(() => {
		const lastUpdatedDateMoment = moment(`${lastUpdatedTime}Z`);
		const currentUTCDateMoment = moment(new Date().toISOString());

		const diffInSec = currentUTCDateMoment.diff(lastUpdatedDateMoment, 'seconds');

		return Math.max(diffInSec, 0);
	});

	const isFirstTimeVariant = time <= maxLoaderStepTime.first;
	const isSecondTimeVariant = time > maxLoaderStepTime.first && time <= maxLoaderStepTime.second;

	useInterval(
		() => {
			setTime((currentTime) => currentTime + 1);
		},
		!isFirstTimeVariant && !isSecondTimeVariant ? null : 1000,
	);

	if (isFirstTimeVariant) {
		return (
			<ContainerWithLoader>
				<Loader />
			</ContainerWithLoader>
		);
	}

	return (
		<ContainerWithLoader>
			<DataMessagesInfoBox messages={isSecondTimeVariant ? secondStepMessage : dataСountingMessages} />
			<Loader />
		</ContainerWithLoader>
	);
}
