import {useCallback, useMemo, useState} from 'react';
import {Setting} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Drawer, UnsavedChangesConfirmation} from '@esgi/main/kits/common';
import {tabItems} from './constants';
import {SidebarHeader} from './index.styled';
import {SettingsService} from '../../service';
import {DrawerPanel} from '../../index.styled';
import {Reports} from './components/tabs/reports';
import {Teacher} from './components/tabs/teacher';
import {ExportData} from './components/tabs/export-data';
import {SidebarMenu} from './components/settings-tab-menu';
import {ParentLetter} from './components/tabs/parent-letter';
import {SettingsTab, SettingsTabTouchedData} from '../../types';
import {SystemSettings} from './components/tabs/system-settings';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

interface Props {
	service: SettingsService;
	onCloseDrawer: (isTouched: boolean) => void;
	tabsTouchedData: SettingsTabTouchedData;
	onChangeTabsTouchedData: (tab: SettingsTabTouchedData) => void;
}

export function Panel({service, onCloseDrawer, tabsTouchedData, onChangeTabsTouchedData}: Props) {
	const [selectedTab, setSelectedTab] = useState<SettingsTab>(SettingsTab.SystemSettings);
	const [nextTab, setNextTab] = useState<SettingsTab>(SettingsTab.SystemSettings);
	const [isUnsavedChangesTabAlertOpen, setUnsavedChangesTabAlertOpen] = useState(false);

	const handleCloseDrawer = useCallback(() => {
		onCloseDrawer(tabsTouchedData[selectedTab]);
	}, [onCloseDrawer, tabsTouchedData, selectedTab]);

	const handleChangeTabData = useCallback((tab: SettingsTab, isTouched: boolean) => {
		onChangeTabsTouchedData({...tabsTouchedData, [tab]: isTouched});
	}, [onChangeTabsTouchedData, tabsTouchedData]);

	const handleChangeTab = useCallback((tab: SettingsTab) => {
		if (selectedTab === tab){
			return null;
		}

		setNextTab(tab);

		const isTouched = tabsTouchedData[selectedTab];

		if (isTouched) {
			setUnsavedChangesTabAlertOpen(true);
			return;
		}

		setSelectedTab(tab);
	}, [selectedTab, tabsTouchedData]);

	const renderTabContent = useMemo(() => {
		switch (selectedTab) {
			case SettingsTab.SystemSettings:
				return (
					<SystemSettings
						service={service}
						onCloseDrawer={handleCloseDrawer}
						onUpdateTabTouchedData={handleChangeTabData}
					/>
				);

			case SettingsTab.Teacher:
				return (
					<Teacher
						service={service}
						onCloseDrawer={handleCloseDrawer}
						onUpdateTabTouchedData={handleChangeTabData}
					/>
				);

			case SettingsTab.Reports:
				return (
					<Reports
						service={service}
						onCloseDrawer={handleCloseDrawer}
						onUpdateTabTouchedData={handleChangeTabData}
					/>
				);

			case SettingsTab.CustomizeParentLetter:
				return (
					<ParentLetter
						service={service}
						onCloseDrawer={handleCloseDrawer}
						onUpdateTabTouchedData={handleChangeTabData}
					/>
				);

			case SettingsTab.ExportData:
				return (
					<ExportData service={service} onCloseDrawer={handleCloseDrawer} />
				);
			default:
				return null;
		}
	}, [selectedTab, service, tabsTouchedData]);

	return (
		<>
			<DrawerPanel data-cy='settings-panel'>
				<Drawer.PanelSidebar data-cy='settings-tab-bar'>
					<SidebarHeader>
						<Setting/>
						<Text size='small' color='neutral56' data-cy='settings-title'>
							Settings
						</Text>
					</SidebarHeader>
					<SidebarMenu
						selectedTab={selectedTab}
						onChange={handleChangeTab}
						tabItems={tabItems}
					/>
				</Drawer.PanelSidebar>
				<OverlayScrollbarsComponent
					defer
					style={{height: 'calc(100% + 0px)'}}
					options={{scrollbars: {autoHide: 'leave'}}}
				>
					{renderTabContent}
				</OverlayScrollbarsComponent>
			</DrawerPanel>

			{isUnsavedChangesTabAlertOpen &&
				<UnsavedChangesConfirmation
					onCloseAnyway={() => {
						onChangeTabsTouchedData(({...tabsTouchedData, [selectedTab]: false}));
						setSelectedTab(nextTab);
					}}
					onClose={() => setUnsavedChangesTabAlertOpen(false)}
				/>
			}
		</>
	);
}
