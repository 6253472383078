import {SchoolSubject} from '@esgi/main/features/admins/subject-management';
import {SubjectLevel} from '@esgi/main/kits/common';
import {
	useSubjects,
	SubjectLevel as SubjectLevelStore,
	SubjectType as SubjectTypeStore,
	SubjectType,
	Subject as SubjectStore,
} from '@esgi/main/libs/school-admin-store';
import {useCallback, useMemo} from 'react';
import {isNull} from 'underscore';

type Out = {
	isStoreSubjectsLoaded: boolean;
	storeSubjects: SchoolSubject[];
	allSchoolSubjectsNames: string[];
	getSubjectFromStoreByID: (id: number | null) => SubjectStore | null;
};

export function useStoreSubjects(): Out {
	const [{data: subjectsData, loaded: isSubjectsDataLoaded}] = useSubjects();

	const [isStoreSubjectsLoaded, storeSubjects, allSchoolSubjectsNames] = useMemo<
		[boolean, SchoolSubject[], string[]]
	>(() => {
		if (!isSubjectsDataLoaded) {
			return [false, [], []];
		}

		const outSubjects = subjectsData
			.map<SchoolSubject | null>((subject) =>
				subject.level === SubjectLevelStore.School && subject.subjectType === SubjectTypeStore.Deployed
					? {
							id: subject.id,
							name: subject.name,
							hidden: subject.hidden,
							level: SubjectLevel.School,
							type: SubjectType.Deployed,
						}
					: null,
			)
			.filter((item): item is SchoolSubject => !isNull(item));

		const allSubjectsNames = outSubjects.map(({name}) => name);

		return [true, outSubjects, allSubjectsNames];
	}, [isSubjectsDataLoaded, subjectsData]);

	const getSubjectFromStoreByID = useCallback(
		(id: number | null) => {
			if (isNull(id)) {
				return null;
			}

			return subjectsData.find((subject) => subject.id === id) ?? null;
		},
		[subjectsData],
	);

	return {
		isStoreSubjectsLoaded,
		storeSubjects,
		allSchoolSubjectsNames,
		getSubjectFromStoreByID,
	};
}
