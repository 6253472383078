import {TestCard} from '@esgi/main/kits/test-results-panel';
import {Box} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const TestCardRoot = styled(TestCard.Root, {
	gap: 10,
});

export const Header = styled(TestCard.Header, {
	height: 32,
});

export const ChartsInfoDivider = styled(Box, {
	width: 1,
	height: 20,
	backgroundColor: '$border',
	borderRadius: 1,
});
