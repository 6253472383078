import {styled} from '@esgi/ui/theme';
import {SingleSelectedExpandablePanel} from '../../../_kit';
import {CSS} from '@stitches/react';
import {Button} from '@esgi/ui';

const buttonCSS: CSS = {
	justifySelf: 'start',
};

export const PanelButton = styled(SingleSelectedExpandablePanel.OpenPanelButton, buttonCSS);
export const NotPanelButton = styled(Button, buttonCSS);
