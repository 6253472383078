import {styled} from '@esgi/ui/theme';
import {GridBox} from '@esgi/ui/layout';
import {Checkbox as CheckboxUi} from '@esgi/ui/controls';

export const CheckboxBox = styled(GridBox, {
	borderRadius: 8,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	backgroundColor: '$surface',
	gap: 4,
	padding: 7,
	width: 125,
});

export const Checkbox = styled(CheckboxUi, {
	'& > div': {
		minWidth: 'unset',
		width: 16,
		height: 16,
		borderColor: '$neutral72',
	},

	variants: {
		selected: {
			true: {
				'& > div': {
					background: 'linear-gradient(272deg, $primaryBackground 48.17%, $primarySurface 100%)',

					'& svg': {
						width: 24,
						height: 24,
					},
				},
			},
		},
	},
});