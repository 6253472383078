import {useMemo} from 'react';
import {SpecialistGroup, Student, User} from '@esgi/main/libs/school-admin-store';
import {isUndefined} from '@esgi/ui';
import {mapToEnum} from 'shared/utils';
import {UserType} from '../../types';

type Parameters = {
	skeleton: boolean;
	specialistGroups: SpecialistGroup[];
	specialistGroupID: SpecialistGroup['id'];
	schoolSpecialists: User[];
	studentsList: Student[];
};

type Out = {
	currentSpecialist: User | null;
	specialistGroup: SpecialistGroup | null;
	specialistGroupsList: SpecialistGroup[];
	allStudents: Student[];
};

export function useData({
	skeleton,
	specialistGroups,
	specialistGroupID,
	schoolSpecialists,
	studentsList,
}: Parameters) {
	return useMemo<Out>(() => {
		const specialistGroup = specialistGroups.find(({id}) => id === specialistGroupID);

		if (skeleton || isUndefined(specialistGroup)) {
			return {
				currentSpecialist: null,
				specialistGroup: null,
				specialistGroupsList: [],
				allStudents: [],
			};
		}

		const specialistGroupsList = specialistGroups.filter(
			({specialistID}) => specialistID === specialistGroup.specialistID,
		);

		if (mapToEnum(specialistGroup.userType, UserType) === UserType.ISS) {
			const specialist = schoolSpecialists.find(({id}) => id === specialistGroup.specialistID);

			if (isUndefined(specialist)) {
				throw new Error('specialist is not found in schoolSpecialists store');
			}

			return {
				currentSpecialist: specialist,
				specialistGroup,
				specialistGroupsList,
				allStudents: studentsList.filter(({schoolID}) => schoolID === specialist.schoolID),
			};
		}

		throw new Error('userType is not ISD or ISS');
	}, [schoolSpecialists, skeleton, specialistGroupID, specialistGroups, studentsList]);
}
