import {createContext, useContext} from 'react';
import {isNull} from 'underscore';

export type SingleSelectedExpandablePanelContextValue = {
	expandPanel: VoidFunction;
	closePanel: VoidFunction;
	isPanelExpanded: boolean;
};

export const SingleSelectedExpandablePanelContext = createContext<SingleSelectedExpandablePanelContextValue | null>(
	null,
);

export function useSingleSelectedExpandablePanelContext() {
	const context = useContext(SingleSelectedExpandablePanelContext);

	if (isNull(context)) {
		throw new Error('useSingleSelectedExpandablePanelContext is null');
	}

	return context;
}
