import {useEffect, useMemo, useState} from 'react';
import {isUndefined} from '@esgi/ui';
import {getDisabledSortableKeys} from '../utils/get-disabled-sortable-keys';
import {SortableState, SortDirection} from '../types';
import {sortData} from '../utils/sort-data';

export function useTableDataState<T extends Record<string, unknown>, Key extends keyof T & string>({
	initialSortableKey,
	items,
	sortableCells,
	additionalSortDataKey,
}: {
	initialSortableKey: Key;
	additionalSortDataKey: Key;
	sortableCells: ({key: Key} & unknown)[];
	items: T[];
}) {
	const [sortableState, setSortableState] = useState<SortableState<Key>>({
		key: initialSortableKey,
		direction: SortDirection.Asc,
	});

	const disabledSortableKeys = useMemo(() => {
		return getDisabledSortableKeys({
			data: items,
			keys: sortableCells.map(({key}) => key),
		});
	}, [items, sortableCells]);

	const sortableItems = useMemo(() => {
		const {key, direction} = sortableState;

		return sortData({
			items,
			key,
			direction,
			additionalKey: additionalSortDataKey,
		});
	}, [items, sortableState, additionalSortDataKey]);

	useEffect(() => {
		const firstNotDisabledKey = sortableCells.find(({key}) => !disabledSortableKeys.includes(key))?.key;

		if (disabledSortableKeys.includes(initialSortableKey) && !isUndefined(firstNotDisabledKey)) {
			setSortableState({
				key: firstNotDisabledKey,
				direction: SortDirection.Asc,
			});
		}
	}, [disabledSortableKeys, initialSortableKey, sortableCells]);

	return {
		sortableState,
		setSortableState,
		disabledSortableKeys,
		items: sortableItems,
	};
}
