import {ToggleGroupItemModel} from '../../types';
import {Text} from '@esgi/ui/typography';
import {ToggleGroupContent, ToggleGroupItem, ToggleGroupRoot} from './index.styled';
import {ElementStatus, useFormElementContext} from '@esgi/ui/form';
import {IconWrapper} from '../../index.styled';

type Props<T extends string> = {
	items: ToggleGroupItemModel<T>[];
};

export function FormToggleGroup<T extends string>({items}: Props<T>) {
	const {value: activeValue, status} = useFormElementContext();

	return (
		<ToggleGroupRoot>
			<ToggleGroupContent>
				{items.map(({value, label, Icon, disabled}) => {
					const isItemSelected = activeValue === value;
					const isItemDisabled = status === ElementStatus.disabled || Boolean(disabled);

					return (
						<ToggleGroupItem
							value={value}
							disabled={isItemSelected || disabled}
							applyDisabledStyles={isItemDisabled ? true : !isItemSelected}
							key={value}
						>
							<IconWrapper isItemSelected={isItemSelected} isItemDisabled={isItemDisabled}>
								<Icon />
							</IconWrapper>
							<Text size='small'>{label}</Text>
						</ToggleGroupItem>
					);
				})}
			</ToggleGroupContent>
		</ToggleGroupRoot>
	);
}
