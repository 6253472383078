import {TabID} from '../../variant/types';
import {TabItem} from '../tabs/types';

export const tabItems: TabItem<TabID>[] = [
	{
		value: TabID.GroupInformation,
		label: 'Spec. Group Information',
	},
	{
		value: TabID.Students,
		label: 'Students',
	},
];
