import {Dispatch} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {ContentArea, Subject, Teacher, StudentsList} from '@esgi/ui/icons';
import {FormToggleGroup, GradeLevel, SelectDrawerable, Widget, WidgetCard} from '@esgi/main/features/admins/dashboard';
import {ClassModel, SubjectTab, TeacherModel} from '../../../../../../../types';
import {useDynamicAdaptedItems} from './use-dynamic-adapted-items';
import {schoolCutoffItems, viewTypeItems} from './constants';
import {CommonForm} from '../../forms-data/types';
import {MainSettingsBox} from './index.styled';
import {DynamicFormName} from '../../types';
import {SelectDrawerableFilterable} from '../../../../../../components/select-drawerable-filterable';

type Props = {
	commonForm: CommonForm;
	subjects: SubjectTab[];
	selectedContentArea: string[];
	setSelectedContentArea: Dispatch<string[]>;
	selectedSubjectIDs: string[];
	setSelectedSubjectIDs: Dispatch<string[]>;
	selectedGradeLevels: string[];
	setSelectedGradeLevels: Dispatch<string[]>;
	classes: ClassModel[];
	teachers: TeacherModel[];
	selectedClassesIDs: string[];
	setSelectedClassesIDs: Dispatch<string[]>;
	selectedTeachersIDs: string[];
	setSelectedTeachersIDs: Dispatch<string[]>;
	currentDynamicFormName: DynamicFormName | undefined;
	gradeLevels: GradeLevel[];
};

export function MainSettings({
	commonForm,
	subjects,
	selectedContentArea,
	selectedGradeLevels,
	selectedSubjectIDs,
	setSelectedContentArea,
	setSelectedGradeLevels,
	setSelectedSubjectIDs,
	classes,
	teachers,
	selectedClassesIDs,
	setSelectedClassesIDs,
	selectedTeachersIDs,
	setSelectedTeachersIDs,
	currentDynamicFormName,
	gradeLevels,
}: Props) {
	const {
		selectedTeacherID,
		setSelectedTeacherID,
		selectedSubjectLevel,
		setSelectedSubjectLevel,
		classesAdaptedItems,
		classesFilteredAdaptedItems,
		teachersAdaptedItems,
		subjectsAdaptedItems,
		subjectsFilteredAdaptedItems,
		gradeLevelsAdaptedItems,
		contentAreasAdaptedItems,
	} = useDynamicAdaptedItems({classes, teachers, subjects, gradeLevels});

	return (
		<MainSettingsBox>
			<GridBox flow='column' gap='2'>
				<WidgetCard.EditMode.FormElement control={commonForm.controls.viewType}>
					<FormToggleGroup items={viewTypeItems} />
				</WidgetCard.EditMode.FormElement>
			</GridBox>

			<GridBox gap='2'>
				<Widget.FormSelect
					placeholder='School Cutoff'
					items={schoolCutoffItems}
					control={commonForm.controls.districtCutoff}
				/>

				{currentDynamicFormName !== 'classes' && (
					<SelectDrawerableFilterable
						placeholder='Classes'
						sectionName='Classes'
						value={selectedClassesIDs}
						onSaveSelectedEntity={setSelectedClassesIDs}
						selectedValueTranscript='Classes'
						items={classesFilteredAdaptedItems}
						allItems={classesAdaptedItems}
						filterWidget={
							<SelectDrawerableFilterable.TeacherFilter
								teachers={teachersAdaptedItems}
								selectedTeacher={selectedTeacherID}
								onTeacherSelect={setSelectedTeacherID}
							/>
						}
						EntityIcon={StudentsList}
					/>
				)}

				{currentDynamicFormName === 'classes' && (
					<SelectDrawerable
						placeholder='Teachers'
						sectionName='Teachers'
						value={selectedTeachersIDs}
						onSaveSelectedEntity={setSelectedTeachersIDs}
						selectedValueTranscript='Teachers'
						allItems={teachersAdaptedItems}
						EntityIcon={Teacher}
					/>
				)}

				<SelectDrawerable
					placeholder='Content Areas'
					sectionName='Content Areas'
					value={selectedContentArea}
					onSaveSelectedEntity={setSelectedContentArea}
					selectedValueTranscript='Content Areas'
					allItems={contentAreasAdaptedItems}
					EntityIcon={ContentArea}
				/>

				<SelectDrawerableFilterable
					placeholder='Subject Tabs'
					sectionName='Subjects'
					value={selectedSubjectIDs}
					onSaveSelectedEntity={setSelectedSubjectIDs}
					selectedValueTranscript='Subjects'
					items={subjectsFilteredAdaptedItems}
					allItems={subjectsAdaptedItems}
					filterWidget={
						<SelectDrawerableFilterable.SubjectLevelFilter
							selectedSubjectLevel={selectedSubjectLevel}
							onSubjectLevelSelect={setSelectedSubjectLevel}
						/>
					}
					EntityIcon={Subject}
				/>

				<SelectDrawerable
					placeholder='Grade Levels'
					sectionName='Grade Levels'
					value={selectedGradeLevels}
					onSaveSelectedEntity={setSelectedGradeLevels}
					selectedValueTranscript='Grade Levels'
					allItems={gradeLevelsAdaptedItems}
					EntityIcon={Subject}
				/>
			</GridBox>
		</MainSettingsBox>
	);
}
