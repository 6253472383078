import {FolderType} from '@esgi/main/features/school-admin/data';
import {AdditionalFolderData} from './types';

export const additionalFolderData: AdditionalFolderData = {
	[FolderType.Teacher]: {
		hierarchyKey: 'teachersGroupID',
		title: 'Teacher Group Reports',
		noStudentsSinglePrefix: 'class',
		noStudentsMultiplePrefix: 'classes',
	},
	[FolderType.Specialist]: {
		hierarchyKey: 'specialistGroupID',
		title: 'Specialist Group Reports',
		noStudentsSinglePrefix: 'group',
		noStudentsMultiplePrefix: 'groups',
	},
};
