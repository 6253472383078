import {useCallback, useEffect, useMemo} from 'react';
import {Enter} from '@esgi/ui';
import {useService} from '@esgi/core/service';
import {useBehaviorSubject} from '@esgi/ui/utils';
import {Drawer, useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {DrawerBody, ScrollableWrapper, FormWrapper} from './index.styled';
import {StudentDetails} from './components/student-details';
import {AssignStudent} from './components/assign-student';
import {FormElement} from './components/form-element';
import {SelectForm} from './components/select-form';
import {TransferStudentService} from './service';
import {useStudents} from '@esgi/main/libs/admin-store';

interface Props {
	studentID: number;
	onClose: VoidFunction;
	onTransfered: VoidFunction;
}

export function TransferStudent({studentID, onClose, onTransfered}: Props) {
	const drawerRef = useDrawerRef();
	const closeDrawer = useDrawerClose(drawerRef, onClose);

	const service = useService(TransferStudentService);

	const [, {update}] = useStudents();

	const student = useBehaviorSubject(service.student$);
	const schools = useBehaviorSubject(service.schools$);
	const teachers = useBehaviorSubject(service.teachers$);
	const teacher = useBehaviorSubject(service.teacher$);
	const classes = useBehaviorSubject(service.classes$);
	const schoolSpecialists = useBehaviorSubject(service.schoolSpecialists$);
	const districtSpecialists = useBehaviorSubject(service.districtSpecialists$);

	const isBusy = useBehaviorSubject(service.isBusy$);
	const isLoaded = useBehaviorSubject(service.isLoaded$);
	const isFormPristine = useBehaviorSubject(service.isFormPristine$);

	const schoolOptions = useMemo(() => (
		schools?.map((item) => ({id: item.id, value: item.name})) ?? []
	), [schools]);

	const teacherOptions = useMemo(() => (
		teachers?.map((item) => ({
			id: item.userID,
			value: `${item.firstName} ${item.lastName}`,
		})) ?? []
	), [teachers]);

	const handleSave = useCallback(() => {
		service.form.validate().subscribe((value) => {
			if (!value.valid) {
				return;
			}

			service.save().subscribe(() => {
				onTransfered();
				closeDrawer();
			});
		});
	}, [service, onTransfered, closeDrawer]);

	useEffect(() => {
		service.init(studentID).subscribe();
	}, [service, studentID]);

	return (
		<Drawer drawerRef={drawerRef} onClickOutside={closeDrawer}>
			<Drawer.Header
				Icon={Enter}
				actionButtonText='Transfer'
				sectionName='Student Transfer'
				actionButtonDisabled={isFormPristine || isBusy || !isLoaded}
				onActionButtonClick={handleSave}
				closeDrawer={closeDrawer}
				withActionButton
			/>
			<DrawerBody>
				<StudentDetails
					schools={schools}
					schoolID={student?.location?.schoolID}
					studentId={student?.studentID}
					firstName={student?.profile?.firstName}
					lastName={student?.profile?.lastName}
					imageURL={student?.profile?.photo?.imageUrl}
					isLoaded={isLoaded}
				/>
				<ScrollableWrapper>
					<FormWrapper controller={service.form}>
						<Drawer.ContentBlock title='Location' withDivider>
							<FormElement control={service.form.controls.schoolID}>
								<SelectForm placeholder='School' options={schoolOptions} />
							</FormElement>
							<FormElement control={service.form.controls.teacherID}>
								<SelectForm placeholder='Primary Teacher' options={teacherOptions} />
							</FormElement>
						</Drawer.ContentBlock>
						<Drawer.ContentBlock title='Assigned To Classes & Groups' withDivider>
							<AssignStudent
								form={service.form}
								teacher={teacher}
								classes={classes}
								schoolSpecialists={schoolSpecialists}
								districtSpecialists={districtSpecialists}
							/>
						</Drawer.ContentBlock>
					</FormWrapper>
				</ScrollableWrapper>
			</DrawerBody>
		</Drawer>
	);
}