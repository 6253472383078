import {WidgetCard} from '@esgi/main/features/admins/dashboard';
import {Box, Card, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const CardBody = styled(Card.Body, {
	display: 'grid',
	gap: '$4',
	gridTemplateColumns: '1fr auto 1fr',
});

export const CardBodyDivider = styled(Box, {
	width: 1,
	backgroundColor: '$border',
	borderRadius: 1,
	marginTop: -WidgetCard.EditMode.formRowGap,
	marginBottom: -WidgetCard.widgetCardPaddingVertical,
});

export const AdditionalSettingBox = styled(GridBox, {
	height: '100%',
	rowGap: '$2',
});

export const InputsBox = styled(GridBox, {
	gridTemplateRows: 'repeat(4, 40px)',
	rowGap: '$2',
});
