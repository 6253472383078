import React from 'react';
import {ErrorText} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {FormElement} from '@esgi/ui/form';
import {Drawer} from '@esgi/main/features/teacher/home';
import {DatePicker, Input, Select} from '@esgi/ui/form-controls';
import {FormElementWrapper, FormWrapper, ScrollableWrapper} from '../../../index.styled';
import {ProfileFormType} from '../../../forms/profile';
import {IdsContainer} from './index.styled';
import {Dictionary} from '../../../types';

interface ProfileProps {
	initDictionaryData: Dictionary;
	canViewExportID: boolean;
	form: ProfileFormType;
	isInitialized: boolean;
}

export function Profile({initDictionaryData, form, canViewExportID, isInitialized}: ProfileProps) {
	return (
		<ScrollableWrapper>
			<FormWrapper controller={form}>
				<Drawer.ContentBlock title='Personal Details' withDivider>
					<FormElementWrapper>
						<FormElement control={form.controls.firstName}>
							<Input.Base placeholder='First Name' skeleton={!isInitialized} dataCy='first-name'/>
							<ErrorText showOnError='required'>Enter first name</ErrorText>
							<ErrorText showOnError='length-min'>The first name is too short</ErrorText>
							<ErrorText showOnError='length-max'>The first name is too long</ErrorText>
						</FormElement>
					</FormElementWrapper>

					<FormElementWrapper>
						<FormElement control={form.controls.lastName}>
							<Input.Base placeholder='Last Name' skeleton={!isInitialized} dataCy='last-name' />
							<ErrorText showOnError='required'>Enter last name</ErrorText>
							<ErrorText showOnError='length-min'>The last name is too short</ErrorText>
							<ErrorText showOnError='length-max'>The first name is too long</ErrorText>
						</FormElement>
					</FormElementWrapper>

					<FormElementWrapper>
						<FormElement control={form.controls.birthDate}>
							<DatePicker.Root skeleton={!isInitialized} dataCy='date'>
								<DatePicker.DropdownTrigger>
									<DatePicker.Input placeholder='Date of Birth'/>
								</DatePicker.DropdownTrigger>
								<DatePicker.DropdownBody>
									<DatePicker.CalendarPanel/>
								</DatePicker.DropdownBody>
							</DatePicker.Root>
							<ErrorText showOnError='invalid-date'>Please select valid date</ErrorText>
						</FormElement>
					</FormElementWrapper>

					<IdsContainer>
						<FormElementWrapper>
							<FormElement control={form.controls.studentIDN}>
								<Input.Base placeholder='Student ID' skeleton={!isInitialized} dataCy='student-id'/>
								<ErrorText showOnError='idn-in-use'>Student ID is already being used</ErrorText>
							</FormElement>
						</FormElementWrapper>
						{canViewExportID && <FormElementWrapper>
							<FormElement control={form.controls.exportIDN}>
								<Input.Base placeholder='Export ID' skeleton={!isInitialized} dataCy='export-id'/>
							</FormElement>
						</FormElementWrapper>}
					</IdsContainer>
				</Drawer.ContentBlock>

				<Drawer.ContentBlock title='Additional Details' withDivider>
					<FormElementWrapper>
						<FormElement control={form.controls.gradeLevel}>
							<Select.Root dataCy='grade-select'>
								<Select.Field placeholder='Grade' skeleton={!isInitialized}/>
								<Select.Content>
									{initDictionaryData?.gradeLevels?.map(g => <Select.Option value={g.id} key={g.id}>
										<Text size='medium' bold>{g.name}</Text>
									</Select.Option>)}
								</Select.Content>
							</Select.Root>
							<ErrorText showOnError='required'> Select grade level</ErrorText>
						</FormElement>
					</FormElementWrapper>

					<FormElementWrapper>
						<FormElement control={form.controls.language}>
							<Select.Root dataCy='language-select'>
								<Select.Field placeholder='Language' skeleton={!isInitialized}/>
								<Select.Content>
									{initDictionaryData?.languages?.map(l => <Select.Option value={l.id} key={l.id}>
										<Text size='medium' font='mono' bold>{l.name}</Text>
									</Select.Option>)}
								</Select.Content>
							</Select.Root>
							<ErrorText showOnError='required'> Select language</ErrorText>
						</FormElement>
					</FormElementWrapper>

					<FormElementWrapper>
						<FormElement control={form.controls.gender}>
							<Select.Root dataCy='gender-select'>
								<Select.Field placeholder='Gender' skeleton={!isInitialized}/>
								<Select.Content>
									{initDictionaryData?.genders?.map(g => <Select.Option value={g.id} key={g.id}>
										<Text size='medium' bold>{g.name}</Text>
									</Select.Option>)}
								</Select.Content>
							</Select.Root>
							<ErrorText showOnError='required'> Select gender</ErrorText>
						</FormElement>
					</FormElementWrapper>
				</Drawer.ContentBlock>
			</FormWrapper>
		</ScrollableWrapper>
	);
}


