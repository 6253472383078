import {ComponentPropsWithoutRef, Dispatch, forwardRef, useEffect, useMemo, useState} from 'react';
import {BaseComponentProps, isUndefined} from '@esgi/ui';
import {NonNullableTemporaryWidgetData, TemporaryWidgetDataModel, WidgetDataModel, WidgetSortBy} from './types';
import {multipleChartSortOption, singleChartSortOption} from './constants';
import {Content} from './components/content';
import {Widget} from '../../types/widget';
import {
	DistrictPerformanceMetricData,
	DistrictPerformanceWidgetOptions,
	DistrictPerformanceWidgetUpdatedValue,
} from '../../types/district-performance';
import {useSortItems} from './use-sort-items';
import {isNull} from 'underscore';
import {TrackModel} from '@esgi/main/kits/admin';
import {
	ContentAreaModel,
	DeployedSubject,
	GradeLevel,
	PerformanceData,
	PerformanceWidgetViewType,
	SchoolModel,
	WidgetCard,
	WidgetMode,
} from '@esgi/main/features/admins/dashboard';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'id'> &
	Pick<Widget, 'id' | 'name'> & {
		widgetData: DistrictPerformanceMetricData | 'dataCounting';
		widgetOptions: DistrictPerformanceWidgetOptions;
		subjects: DeployedSubject[];
		maxPossibleCardWidth: number;
		allSchoolsList: SchoolModel[];
		onUpdateWidget: Dispatch<DistrictPerformanceWidgetUpdatedValue>;
		onDeleteWidgetByID: Dispatch<Widget['id']>;
		defaultWidgetMode?: WidgetMode;
		viewModeChanged: Dispatch<{id: Widget['id']; mode: WidgetMode}>;
		gradeLevels: GradeLevel[];
		currentTrack: TrackModel | null;
		allContentAreas: ContentAreaModel[];
		lastUpdatedTime: string;
	};

export const DistrictPerformance = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'district-admin-district-performance-widget-view-edit',
			css = {},
			title,
			id,
			name,
			widgetData,
			widgetOptions,
			subjects,
			maxPossibleCardWidth,
			allSchoolsList,
			onUpdateWidget,
			onDeleteWidgetByID,
			defaultWidgetMode,
			viewModeChanged,
			gradeLevels,
			currentTrack,
			allContentAreas,
			lastUpdatedTime,
			...componentRootProps
		},
		forwardedRef,
	) => {
		const isDataCounting = widgetData === 'dataCounting';

		const [widgetSortBy, setWidgetSortBy] = useState(WidgetSortBy.Alphabetical);
		const [sortableItems, setSortableItems] = useState(singleChartSortOption);

		const allSchoolsAvg = useMemo(
			() => (!isDataCounting ? Math.round(widgetData.allSchoolsAvg) : 0),
			[isDataCounting, widgetData],
		);

		const adaptedWidgetData = useMemo(() => {
			if (isDataCounting) {
				return [];
			}

			const isMultiplePeriods = widgetData.periods.length >= 2;

			const temporaryPeriodsData = widgetOptions.schoolIDs.reduce<Record<number, TemporaryWidgetDataModel>>(
				(currentData, schoolID) => {
					const schoolName = allSchoolsList.find(({id}) => id === schoolID)?.name ?? '';

					currentData[schoolID] = {
						label: schoolName,
						data: isMultiplePeriods ? [null, null] : [null],
					};

					return currentData;
				},
				{},
			);

			widgetData.periods.forEach(({data}, periodIndex) => {
				Object.entries(data).forEach(
					([dataID, {avg, firstQuartile, interquartileRange, max, median, min, thirdQuartile}]) => {
						const dataIDNumber = Number(dataID);
						const temporaryData = temporaryPeriodsData[dataIDNumber];

						if (isUndefined(temporaryData)) {
							return;
						}

						const dataModel: PerformanceData = {
							avg: Number(avg.toFixed(1)),
							firstQuartile: Math.round(firstQuartile),
							interquartileRange: Math.round(interquartileRange),
							max: Math.round(max),
							median: Math.round(median),
							min: Math.round(min),
							thirdQuartile: Math.round(thirdQuartile),
						};

						const data: TemporaryWidgetDataModel['data'] = [...temporaryData.data];

						data[periodIndex] = dataModel;

						temporaryPeriodsData[dataIDNumber] = {
							...temporaryData,
							data,
						};
					},
				);
			});

			return Object.entries(temporaryPeriodsData)
				.filter((item): item is [string, NonNullableTemporaryWidgetData] => item[1].data.every((item) => !isNull(item)))
				.map<WidgetDataModel>(([dataID, {label, data}]) => ({
					id: Number(dataID),
					label,
					data,
					delta: isMultiplePeriods ? data[0].median - data[1]!.median : null,
				}));
		}, [allSchoolsList, isDataCounting, widgetData, widgetOptions.schoolIDs]);

		const sortableWidgetData = useSortItems({
			items: adaptedWidgetData,
			widgetSortBy,
		});

		useEffect(() => {
			if (isDataCounting) {
				setWidgetSortBy(WidgetSortBy.None);
				setSortableItems([]);

				return;
			}

			if (widgetData.periods.length === 2 && widgetOptions.viewType === PerformanceWidgetViewType.Candles) {
				setSortableItems(multipleChartSortOption);

				setWidgetSortBy((currentValue) => {
					if (multipleChartSortOption.some((item) => item.value === currentValue)) {
						return currentValue;
					}

					return WidgetSortBy.Alphabetical;
				});

				return;
			}

			setSortableItems(singleChartSortOption);

			setWidgetSortBy((currentValue) => {
				if (singleChartSortOption.some((item) => item.value === currentValue)) {
					return currentValue;
				}

				return WidgetSortBy.Alphabetical;
			});
		}, [widgetData, widgetOptions.viewType, isDataCounting]);

		return (
			<WidgetCard
				dataCy={dataCy}
				css={css}
				ref={forwardedRef}
				id={id}
				name={name}
				maxPossibleCardWidth={maxPossibleCardWidth}
				widgetData={sortableWidgetData}
				viewSize={widgetOptions.viewSize}
				defaultWidgetMode={defaultWidgetMode}
				viewModeChanged={viewModeChanged}
				isDataСounting={isDataCounting}
				currentTrack={currentTrack}
				allContentAreas={allContentAreas}
				lastUpdatedTime={lastUpdatedTime}
				{...componentRootProps}
			>
				<Content
					allSchoolsAvg={allSchoolsAvg}
					widgetOptions={widgetOptions}
					allSchoolsList={allSchoolsList}
					subjects={subjects}
					widgetSortBy={widgetSortBy}
					setWidgetSortBy={setWidgetSortBy}
					sortableItems={sortableItems}
					onDeleteWidgetByID={onDeleteWidgetByID}
					onUpdateWidget={onUpdateWidget}
					gradeLevels={gradeLevels}
				/>
			</WidgetCard>
		);
	},
);
