import {styled} from '@esgi/ui/theme';
import {NavigationItem} from '../../../navigation-item.styled';
import {Button, ContextMenu} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';

export const Item = styled(NavigationItem, {
	color: 'currentColor',

	'& > svg': {
		width: 24,
		height: 24,

		'& path': {
			transition: 'fill .3s',
			fill: 'currentColor',
		},
	},

	variants: {
		display: {
			grid: {
				display: 'grid',
				gridTemplateColumns: 'repeat(3, auto) 1fr',
			},
		},
	},

	defaultVariants: {
		display: 'grid',
	},
});

export const ItemNameText = styled(Text, {
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

export const ActionButton = styled(Button.Icon, {
	justifySelf: 'end',
});

export const ContextMenuItem = styled(ContextMenu.Item, {
	paddingTop: 6,
	paddingBottom: 6,
	paddingLeft: 6,
	paddingRight: 12,
	display: 'grid',
	gridAutoFlow: 'column',
	gap: 6,
	justifyContent: 'start',
});
