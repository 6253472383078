import {Observable, switchMap, debounceTime, map} from 'rxjs';
import {V2DistrictAdminsTeacherController} from '@esgi/contracts/esgi';
import {ControlValue, FormControl, FormGroup, ValidatorArgs, Validators} from '@esgi/ui/form';
import {teacherNamesMaxLength} from './model';

export type AddTeacherType = FormGroup<{
	school: FormControl<string[]>,
	title: FormControl<string[]>,
	accountID: FormControl<string>,
	firstName: FormControl<string>,
	lastName: FormControl<string>,
	email: FormControl<string>,
	importID: FormControl<string>,
	exportID: FormControl<string>,
},
	ControlValue<{
		school: FormControl<string[]>,
		title: FormControl<string[]>,
		accountID: FormControl<string>,
		firstName: FormControl<string>,
		lastName: FormControl<string>,
		email: FormControl<string>,
		importID: FormControl<string>,
		exportID: FormControl<string>,
	}>
>;

export const createAddTeacherForm = (): AddTeacherType => new FormGroup({
	school: new FormControl([], {validators: [Validators.required()]}),
	title: new FormControl([], {validators: [Validators.required()]}),
	accountID: new FormControl('', {validators: [Validators.required()]}),
	firstName: new FormControl('', {
		validators: [
			Validators.required(),
			Validators.length.max(teacherNamesMaxLength),
		],
	}),
	lastName: new FormControl('', {
		validators: [
			Validators.required(),
			Validators.length.max(teacherNamesMaxLength),
		],
	}),
	email: new FormControl('', {validators: [Validators.required(), Validators.email()]}),
	importID: new FormControl(''),
	exportID: new FormControl(''),
});

export const teacherImportIDNValidator = (controller: V2DistrictAdminsTeacherController, districtID: number) => {
	return (validationChain: Observable<ValidatorArgs<string>>) => validationChain.pipe(
		debounceTime(300),
		switchMap((args) => {
			if (!args.field.currentValue) {
				return new Observable<ValidatorArgs<string>>((subscriber) => (
					subscriber.next(args)
				));
			}

			return controller.checkTeacherImportIdn({userID: 0, teacherIDN: args.field.currentValue, districtID}).pipe(
				map((response) => {
					if (response.exists) {
						args.errors.push('import-idn-exist');
					}

					return args;
				}));
		}),
	);
};

export const teacherExportIDNValidator = (controller: V2DistrictAdminsTeacherController, districtID: number) => {
	return (validationChain: Observable<ValidatorArgs<string>>) => validationChain.pipe(
		debounceTime(300),
		switchMap((args) => {
			if (!args.field.currentValue) {
				return new Observable<ValidatorArgs<string>>((subscriber) => (
					subscriber.next(args)
				));
			}

			return controller.checkTeacherExportIdn({userID: 0, exportID: args.field.currentValue, districtID}).pipe(
				map((response) => {
					if (response.exists) {
						args.errors.push('export-idn-exist');
					}

					return args;
				}));
		}),
	);
};

export const teacherNameValidator = (controller: V2DistrictAdminsTeacherController) => {
	return (validationChain: Observable<ValidatorArgs<string>>) => validationChain.pipe(
		debounceTime(300),
		switchMap((args) => {
			if (!args.field.currentValue) {
				return new Observable<ValidatorArgs<string>>((subscriber) => (
					subscriber.next(args)
				));
			}

			return controller.checkUserName({userID: 0, userName: args.field.currentValue}).pipe(
				map((response) => {
					if (response.exists) {
						args.errors.push('username-exist');
					}

					return args;
				}));
		}),
	);
};