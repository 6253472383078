import {styled} from '@esgi/ui/theme';
import {FlexBox, SelectableList} from '@esgi/ui/layout';

export const StyledSelectableListTrigger = styled(SelectableList.Trigger, {
	variants: {
		custom: {
			true: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				borderWidth: 0,
				borderBottomWidth: 1,
				borderColor: 'transparent',
				borderRadius: 4,
				background: 'unset',

				'& > button > button': {
					padding: 0,
				},
			},
		},
		expanded: {
			true: {
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
				borderStyle: 'solid',
				borderColor: '$border',

				'& > svg': {
					transform: 'scale3d(1, -1, 1)',
				},
			},
		},
	},
});

export const StyledSelectableListItem = styled(SelectableList.Item, {
	variants: {
		custom: {
			true: {
				padding: '8px 12px 8px 4px',
				border: 'none',
				borderRadius: 0,
				background: 'unset',
			},
		},
	},
});

export const StyledIconBefore = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'center',
	width: '20px',
	height: '20px',
	borderRadius: '4px',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: '$muted',

	variants: {
		checked: {
			true: {
				borderColor: '$primaryMuted',

				'& > svg > path': {
					fill: '$primary',
					stroke: '$primary',
				},
			},
		},
	},
});