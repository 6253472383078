import {
	DemographicPerformanceWidgetUpdatedValue,
	Widget,
	DistrictPerformanceWidgetUpdatedValue,
	AddWidget,
	LowestAchievementWidgetUpdatedValue,
	DistrictAdminWidget,
	WidgetsMetricData,
} from '@esgi/main/features/district-admin/dashboard';
import {isNull} from 'underscore';
import {Dispatch, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {isUndefined} from '@esgi/ui';
import {GridStackContainer, GridStackItem, GridStackItemContent, PageBody} from './index.styled';
import {getWidgetData} from './utils/get-widget-data';
import {useWidgetsInfo} from './hooks/use-widgets-info';
import {useGridStack} from './hooks/use-grid-stack';
import {RearrangeWidgetSubstrate} from './components/rearrange-widget-substrate';
import {noWidgetsMessage, someWidgetDataLoadingMessage, someWidgetInEditModeMessage} from './constants';
import {AddWidgetCard} from './components/add-widget-card';
import {TrackModel} from '@esgi/main/kits/admin';
import {
	ContentAreaModel,
	DeployedSubject,
	GradeLevel,
	Page,
	PageMode,
	RearrangeNotActiveMessage,
	SchoolModel,
	WidgetMode,
} from '@esgi/main/features/admins/dashboard';
import {useRootPageContext} from '@esgi/main/kits/common';

type Props = {
	subjects: DeployedSubject[];
	widgetsList: Widget[];
	lastUpdatedDate: string | Date;
	allSchoolsList: SchoolModel[];
	updateDistrictPerformanceWidget: Dispatch<DistrictPerformanceWidgetUpdatedValue>;
	updateDemographicPerformanceWidget: Dispatch<DemographicPerformanceWidgetUpdatedValue>;
	updateLowestAchievementWidget: Dispatch<LowestAchievementWidgetUpdatedValue>;
	deleteWidgetByID: Dispatch<Widget['id']>;
	updateWidgetsOrdering: Dispatch<Widget['id'][]>;
	widgetsMetricData: WidgetsMetricData;
	gradeLevels: GradeLevel[];
	currentTrack: TrackModel | null;
	lastNewWidgetsID: number | null;
	resetLastNewWidgetsID: VoidFunction;
	contentAreas: ContentAreaModel[];
};

export function PageContent({
	subjects,
	widgetsList,
	lastUpdatedDate,
	allSchoolsList,
	updateDistrictPerformanceWidget,
	updateDemographicPerformanceWidget,
	updateLowestAchievementWidget,
	deleteWidgetByID,
	updateWidgetsOrdering,
	widgetsMetricData,
	gradeLevels,
	currentTrack,
	lastNewWidgetsID,
	resetLastNewWidgetsID,
	contentAreas,
}: Props) {
	const {rootPageContentContainerRef} = useRootPageContext();

	const {pageMode} = Page.usePageContext();
	const isRearrangeMode = pageMode === PageMode.Rearrange;

	const [currentWidgetsMode, setCurrentWidgetsMode] = useState(
		widgetsList.reduce<Record<Widget['id'], WidgetMode>>((state, {id}) => {
			state[id] = WidgetMode.View;

			return state;
		}, {}),
	);
	const [isOpenAddWidgetDrawer, setIsOpenAddWidgetDrawer] = useState(false);

	const pageBodyManagerRef = Page.Body.useManagerRef();
	const addWidgetCardRef = useRef<HTMLDivElement>(null);

	const {pageBodyRef, itemsCountInRow, maxPossibleWidgetWidth} = useWidgetsInfo();

	const {
		gridStackContainerRef,
		itemsIndexPosition,
		widgetItemsWithRef,
		draggedWidgetID,
		rearrangeHandleClassName,
		isItemsPositionsChanged,
		onSaveWidgetsOrdering,
		addCardPosition,
	} = useGridStack({
		widgetsList,
		itemsCountInRow,
		isRearrangeMode,
		updateWidgetsOrdering,
	});

	useEffect(() => {
		if (!isNull(lastNewWidgetsID)) {
			const lastAddedWidgetItem = widgetItemsWithRef.find(({id}) => id === lastNewWidgetsID);

			if (!isUndefined(lastAddedWidgetItem)) {
				setCurrentWidgetsMode((currentState) => ({...currentState, [lastNewWidgetsID]: WidgetMode.Edit}));

				pageBodyManagerRef.current?.goToBottom(lastAddedWidgetItem.itemRef.current?.offsetTop ?? 0);

				resetLastNewWidgetsID();
			}
		}
	}, [widgetItemsWithRef, pageBodyManagerRef, lastNewWidgetsID, resetLastNewWidgetsID]);

	const openAddWidgetDrawer = useCallback(() => {
		setIsOpenAddWidgetDrawer(true);
	}, []);

	const closeAddWidgetDrawer = useCallback(() => {
		setIsOpenAddWidgetDrawer(false);
	}, []);

	const getDefaultWidgetMode = useCallback(
		(id: number) => (lastNewWidgetsID === id ? WidgetMode.Edit : WidgetMode.View),
		[lastNewWidgetsID],
	);

	const widgetViewModeChanged = useCallback(({id, mode}: {id: Widget['id']; mode: WidgetMode}) => {
		setCurrentWidgetsMode((currentState) => ({
			...currentState,
			[id]: mode,
		}));
	}, []);

	const rearrangeNotActiveMessages = useMemo<RearrangeNotActiveMessage[]>(() => {
		const isNoWidgets = !widgetItemsWithRef.length;
		const isSomeWidgetDataLoading = Object.values(widgetsMetricData).some(isNull);
		const isSomeWidgetInEditMode = widgetItemsWithRef.some(({id}) => currentWidgetsMode[id] === WidgetMode.Edit);

		const messages = [
			isNoWidgets && noWidgetsMessage,
			isSomeWidgetDataLoading && someWidgetDataLoadingMessage,
			isSomeWidgetInEditMode && someWidgetInEditModeMessage,
		].filter((item) => !!item);

		return messages;
	}, [currentWidgetsMode, widgetItemsWithRef, widgetsMetricData]);

	return (
		<>
			<Page.Header>
				{isRearrangeMode ? (
					<Page.Header.RearrangeMode onSaveClick={onSaveWidgetsOrdering} isSaveDisabled={!isItemsPositionsChanged} />
				) : (
					<Page.Header.ViewMode
						onAddClick={openAddWidgetDrawer}
						lastDateUpdate={lastUpdatedDate}
						rearrangeNotActiveMessages={rearrangeNotActiveMessages}
					/>
				)}
			</Page.Header>

			<PageBody managerRef={pageBodyManagerRef} ref={pageBodyRef}>
				<GridStackContainer ref={gridStackContainerRef}>
					{widgetItemsWithRef.map(
						({
							id,
							name,
							options: {
								districtPerformanceWidgetOptions,
								demographicPerformanceWidgetOptions,
								lowestAchievementWidgetOptions,
							},
							lastUpdatedTime,
							itemRef,
						}) => (
							<GridStackItem key={id} ref={itemRef}>
								<GridStackItemContent>
									{(() => {
										if (!isNull(districtPerformanceWidgetOptions)) {
											const widgetData = getWidgetData({
												allData: widgetsMetricData[id],
												dataKey: 'districtPerformanceMetricData',
											});

											if (isNull(widgetData)) {
												return null;
											}

											return (
												<DistrictAdminWidget.DistrictPerformance
													subjects={subjects}
													maxPossibleCardWidth={maxPossibleWidgetWidth}
													allSchoolsList={allSchoolsList}
													id={id}
													name={name}
													widgetData={widgetData === 'isDataCounting' ? 'dataCounting' : widgetData}
													widgetOptions={districtPerformanceWidgetOptions}
													onDeleteWidgetByID={deleteWidgetByID}
													onUpdateWidget={updateDistrictPerformanceWidget}
													defaultWidgetMode={getDefaultWidgetMode(id)}
													viewModeChanged={widgetViewModeChanged}
													gradeLevels={gradeLevels}
													currentTrack={currentTrack}
													allContentAreas={contentAreas}
													lastUpdatedTime={lastUpdatedTime}
												/>
											);
										}

										if (!isNull(demographicPerformanceWidgetOptions)) {
											const widgetData = getWidgetData({
												allData: widgetsMetricData[id],
												dataKey: 'demographicsPerformanceMetricData',
											});

											if (isNull(widgetData)) {
												return null;
											}

											return (
												<DistrictAdminWidget.DemographicPerformance
													subjects={subjects}
													maxPossibleCardWidth={maxPossibleWidgetWidth}
													allSchoolsList={allSchoolsList}
													id={id}
													name={name}
													widgetData={widgetData === 'isDataCounting' ? 'dataCounting' : widgetData}
													widgetOptions={demographicPerformanceWidgetOptions}
													onDeleteWidgetByID={deleteWidgetByID}
													onUpdateWidget={updateDemographicPerformanceWidget}
													defaultWidgetMode={getDefaultWidgetMode(id)}
													viewModeChanged={widgetViewModeChanged}
													gradeLevels={gradeLevels}
													currentTrack={currentTrack}
													allContentAreas={contentAreas}
													lastUpdatedTime={lastUpdatedTime}
												/>
											);
										}

										if (!isNull(lowestAchievementWidgetOptions)) {
											const widgetData = getWidgetData({
												allData: widgetsMetricData[id],
												dataKey: 'lowestAchievementMetricData',
											});

											if (isNull(widgetData)) {
												return null;
											}

											return (
												<DistrictAdminWidget.LowestAchievement
													subjects={subjects}
													maxPossibleCardWidth={maxPossibleWidgetWidth}
													allSchoolsList={allSchoolsList}
													id={id}
													name={name}
													widgetData={widgetData === 'isDataCounting' ? 'dataCounting' : widgetData}
													widgetOptions={lowestAchievementWidgetOptions}
													onDeleteWidgetByID={deleteWidgetByID}
													onUpdateWidget={updateLowestAchievementWidget}
													defaultWidgetMode={getDefaultWidgetMode(id)}
													viewModeChanged={widgetViewModeChanged}
													gradeLevels={gradeLevels}
													currentTrack={currentTrack}
													allContentAreas={contentAreas}
													lastUpdatedTime={lastUpdatedTime}
													rootPageContainer={rootPageContentContainerRef.current}
												/>
											);
										}

										return null;
									})()}

									{isRearrangeMode && !isUndefined(itemsIndexPosition[id]) && (
										<RearrangeWidgetSubstrate
											isDragging={draggedWidgetID === id}
											itemNumber={itemsIndexPosition[id] + 1}
											rearrangeHandleClassName={rearrangeHandleClassName}
										/>
									)}
								</GridStackItemContent>
							</GridStackItem>
						),
					)}

					{!isRearrangeMode && (
						<AddWidgetCard
							onAddWidgetClick={openAddWidgetDrawer}
							x={addCardPosition.x}
							y={addCardPosition.y}
							ref={addWidgetCardRef}
						/>
					)}
				</GridStackContainer>
			</PageBody>

			{isOpenAddWidgetDrawer && <AddWidget onClose={closeAddWidgetDrawer} />}
		</>
	);
}
