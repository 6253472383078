import * as React from 'react';
import type {SVGProps} from 'react';

export function Teacher(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M5.5 9.26923C5.5 7.73983 6.72104 6.5 8.22727 6.5H23.7727C25.279 6.5 26.5 7.73983 26.5 9.26923V16.7308C26.5 18.2602 25.279 19.5 23.7727 19.5H22.8636C22.3616 19.5 21.9545 19.0867 21.9545 18.5769C21.9545 18.0671 22.3616 17.6538 22.8636 17.6538H23.7727C24.2748 17.6538 24.6818 17.2406 24.6818 16.7308V9.26923C24.6818 8.75943 24.2748 8.34615 23.7727 8.34615H8.22727C7.7252 8.34615 7.31818 8.75943 7.31818 9.26923V16.7308C7.31818 17.2406 7.7252 17.6538 8.22727 17.6538H9.13636C9.63844 17.6538 10.0455 18.0671 10.0455 18.5769C10.0455 19.0867 9.63844 19.5 9.13636 19.5H8.22727C6.72104 19.5 5.5 18.2602 5.5 16.7308V9.26923Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.1923 14.25C12.1923 12.1792 13.8971 10.5 16 10.5C18.1029 10.5 19.8077 12.1792 19.8077 14.25C19.8077 16.3208 18.1029 18 16 18C13.8971 18 12.1923 16.3208 12.1923 14.25ZM18.1154 14.25C18.1154 13.0992 17.1683 12.1667 16 12.1667C14.8317 12.1667 13.8846 13.0992 13.8846 14.25C13.8846 15.4008 14.8317 16.3333 16 16.3333C17.1683 16.3333 18.1154 15.4008 18.1154 14.25Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.5 23C10.5 21.2967 11.5394 19.7508 13.0913 19.12C13.3078 19.0317 13.5393 19.05 13.7524 19.1458C14.5275 19.4933 15.2594 19.6667 16 19.6667C16.7431 19.6667 17.4725 19.5025 18.2212 19.1717C18.4374 19.0767 18.691 19.0792 18.9087 19.1717C20.4548 19.8283 21.5 21.3367 21.5 23V24.6667C21.5 25.1267 21.1212 25.5 20.6538 25.5H11.3462C10.8788 25.5 10.5 25.1267 10.5 24.6667V23ZM16 21.3333C15.18 21.3333 14.2161 21.1233 13.4167 20.825C12.7473 21.2108 12.1923 22.0292 12.1923 23V23.8333H19.8077V23C19.8077 22.0825 19.2846 21.2808 18.5209 20.8525C17.7376 21.1375 16.8136 21.3333 16 21.3333Z'
				fill='#333333'
			/>
		</svg>
	);
}
