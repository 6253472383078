import {routes} from '@esgi/main/libs/core';
import {
	Root,
	Dashboard,
	DataLayout,
	SubjectManagement,
	TestExplorer,
	StudentManager,
	NotFound,
	DataTeachers,
	DataSpecialists,
	DataClasses,
	DataGroups,
	DataSpecialistGroups,
	DataStudents,
} from '@esgi/main/pages/school-admin';
import {Navigate, Route} from 'react-router-dom';
import {withErrorHandling} from '@esgillc/ui-kit/core';
import {useUser} from '@esgi/core/authentication';

const WrappedDataLayout = withErrorHandling(DataLayout);

export function RedesignRoutesSchoolAdmin() {
	const {canExploreStudents} = useUser();

	return (
		<Route element={<Root />}>
			<Route index element={<Navigate to={routes.schoolAdmin.dashboard} />} />
			<Route path={routes.schoolAdmin.dashboard} element={<Dashboard />} />
			<Route
				path={routes.schoolAdmin.data.root}
				element={<WrappedDataLayout />}
			>
				<Route
					path={routes.schoolAdmin.data.teachers}
					element={<DataTeachers />}
				/>
				<Route
					path={routes.schoolAdmin.data.specialists}
					element={<DataSpecialists />}
				/>
				<Route
					path={routes.schoolAdmin.data.classes}
					element={<DataClasses />}
				/>
				<Route path={routes.schoolAdmin.data.groups} element={<DataGroups />} />
				<Route
					path={routes.schoolAdmin.data.specialistGroups}
					element={<DataSpecialistGroups />}
				/>
				<Route
					path={routes.schoolAdmin.data.students}
					element={<DataStudents />}
				/>
			</Route>
			<Route
				path={routes.schoolAdmin.subjectManagement}
				element={<SubjectManagement />}
			/>
			<Route
				path={routes.schoolAdmin.testExplorer}
				element={<TestExplorer />}
			/>
			{canExploreStudents && (
				<Route
					path={routes.schoolAdmin.studentManager}
					element={<StudentManager />}
				/>
			)}
			<Route path='*' element={<NotFound />} />
		</Route>
	);
}
