import {Drawer, getFullName} from '@esgi/main/kits/common';
import {FormElementSelect} from '../../../../components/form-element-select';
import {School, User} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {FormSelectItem} from '../../../../components/form-element-select/types';
import {classNameErrorItems, schoolSelectErrorItems, teacherSelectErrorItems} from './constants';
import {FormElement, IFormControl} from '@esgi/ui/form';
import {ErrorTooltip} from '../../../../components/error-tooltip';
import {Input as FormInput} from '@esgi/ui/form-controls';

type Props = {
	schools: School[];
	activePrimaryTeachersList: User[];
	primaryTeacherUniqueKey: string;
	schoolIDControl: IFormControl<string[]>;
	primaryTeacherIDControl: IFormControl<string[]>;
	classNameControl: IFormControl<string>;
};

export function ClassInformationTabContent({
	schools,
	activePrimaryTeachersList,
	primaryTeacherUniqueKey,
	classNameControl,
	primaryTeacherIDControl,
	schoolIDControl,
}: Props) {
	const schoolSelectItem = useMemo(
		() => schools.map<FormSelectItem<string>>(({id, name}) => ({value: String(id), label: name})),
		[schools],
	);

	const teachersSelectItem = useMemo(
		() =>
			activePrimaryTeachersList.map<FormSelectItem<string>>(({id, firstName, lastName}) => ({
				value: String(id),
				label: getFullName({firstName, lastName}),
			})),
		[activePrimaryTeachersList],
	);

	return (
		<>
			<Drawer.ContentBlock title='Location' withDivider>
				<FormElementSelect
					placeholder='School'
					control={schoolIDControl}
					items={schoolSelectItem}
					errorItems={schoolSelectErrorItems}
				/>

				<FormElementSelect
					placeholder='Primary Teacher'
					control={primaryTeacherIDControl}
					items={teachersSelectItem}
					errorItems={teacherSelectErrorItems}
					key={primaryTeacherUniqueKey}
				/>
			</Drawer.ContentBlock>

			<Drawer.ContentBlock title='Class Information' withDivider>
				<FormElement control={classNameControl}>
					<FormInput.CleanableIndicator placeholder='Class Name' />

					{classNameErrorItems.map(({showOnError, message}) => (
						<ErrorTooltip showOnError={showOnError} message={message} key={showOnError} />
					))}
				</FormElement>
			</Drawer.ContentBlock>
		</>
	);
}
