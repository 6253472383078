import {GroupCUDEventData} from './types';

export class GroupCreatedEvent {
	constructor(public data: GroupCUDEventData) {}
}

export class GroupUpdatedEvent {
	constructor(public data: GroupCUDEventData) {}
}

export class GroupDeletedEvent {
	constructor(public data: GroupCUDEventData) {}
}
