import {useCallback} from 'react';
import {RowClassParams} from 'ag-grid-community';
import {AgGridReactProps} from 'ag-grid-react';
import {isNull} from 'underscore';
import {notSelectableClassName, selectableClassName as rowSelectableClassName} from '../../../constants';

type Parameters = {
	getRowClass: AgGridReactProps['getRowClass'] | undefined;
};

export function useGetRowClass({getRowClass}: Parameters) {
	return useCallback(
		(params: RowClassParams) => {
			const passedRowClass = getRowClass?.(params);

			const selectableClassName = params.node.selectable ? rowSelectableClassName : notSelectableClassName;

			if (isNull(selectableClassName)) {
				return passedRowClass;
			}

			if (Array.isArray(passedRowClass)) {
				passedRowClass.push(selectableClassName);

				return passedRowClass;
			}

			if (typeof passedRowClass === 'string') {
				return [passedRowClass, selectableClassName];
			}

			return selectableClassName;
		},
		[getRowClass],
	);
}
