import {ComponentPropsWithoutRef, Dispatch, forwardRef, useEffect, useMemo, useState} from 'react';
import {BaseComponentProps, isUndefined} from '@esgi/ui';
import {
	ContentAreaModel,
	GradeLevel,
	PerformanceData,
	PerformanceWidgetViewType,
	WidgetCard,
	WidgetMode,
} from '@esgi/main/features/admins/dashboard';
import {ClassModel, SubjectTab, TeacherModel} from '../../../types';

import {NonNullableTemporaryWidgetData, TemporaryWidgetDataModel, WidgetDataModel, WidgetSortBy} from './types';
import {Content} from './components/content';
import {multipleChartSortOption, singleChartSortOption} from './constants';
import {useSortItems} from './use-sort-items';
import {
	DemographicsPerformanceMetricData,
	DemographicPerformanceWidgetOptions,
	DemographicPerformanceWidgetUpdatedValue,
} from '../../types/demographic-performance';
import {Widget} from '../../types/widget';
import {getTemporaryData} from './get-temporary-data';
import {isNull} from 'underscore';
import {TrackModel} from '@esgi/main/kits/admin';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'id'> &
	Pick<Widget, 'id' | 'name'> & {
		widgetData: DemographicsPerformanceMetricData | 'dataCounting';
		widgetOptions: DemographicPerformanceWidgetOptions;
		subjects: SubjectTab[];
		maxPossibleCardWidth: number;
		allClassesList: ClassModel[];
		allTeachersList: TeacherModel[];
		onDeleteWidgetByID: Dispatch<Widget['id']>;
		onUpdateWidget: Dispatch<DemographicPerformanceWidgetUpdatedValue>;
		defaultWidgetMode?: WidgetMode;
		viewModeChanged: Dispatch<{id: Widget['id']; mode: WidgetMode}>;
		gradeLevels: GradeLevel[];
		currentTrack: TrackModel | null;
		allContentAreas: ContentAreaModel[];
		lastUpdatedTime: string;
	};

export const DemographicPerformance = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'school-admin-demographic-performance-widget-view-edit',
			css = {},
			id,
			name,
			widgetData,
			widgetOptions,
			subjects,
			maxPossibleCardWidth,
			allClassesList,
			allTeachersList,
			onDeleteWidgetByID,
			onUpdateWidget,
			defaultWidgetMode,
			viewModeChanged,
			gradeLevels,
			currentTrack,
			allContentAreas,
			lastUpdatedTime,
			...componentRootProps
		},
		forwardedRef,
	) => {
		const [widgetSortBy, setWidgetSortBy] = useState(WidgetSortBy.Alphabetical);
		const [sortableItems, setSortableItems] = useState(singleChartSortOption);

		const isDataCounting = widgetData === 'dataCounting';

		const allClassesAvgValue = useMemo(
			() => (!isDataCounting ? Math.round(widgetData.allClassesAvg) : 0),
			[isDataCounting, widgetData],
		);

		const adaptedWidgetData = useMemo(() => {
			if (isDataCounting) {
				return [];
			}

			const isMultiplePeriods = widgetData.demographicGroupData.length >= 2;
			const temporaryPeriodsData = getTemporaryData({type: widgetOptions.demographicGroup, isMultiplePeriods});

			widgetData.demographicGroupData.forEach(({data}, periodIndex) => {
				if (periodIndex >= 2) {
					return;
				}

				Object.entries(data).forEach(
					([dataID, {avg, firstQuartile, interquartileRange, max, median, min, thirdQuartile}]) => {
						const dataIDNumber = Number(dataID);
						const temporaryData = temporaryPeriodsData[dataIDNumber];

						if (isUndefined(temporaryData)) {
							return;
						}

						const dataModel: PerformanceData = {
							avg: Number(avg.toFixed(1)),
							firstQuartile: Math.round(firstQuartile),
							interquartileRange: Math.round(interquartileRange),
							max: Math.round(max),
							median: Math.round(median),
							min: Math.round(min),
							thirdQuartile: Math.round(thirdQuartile),
						};

						const data: TemporaryWidgetDataModel['data'] = [...temporaryData.data];

						data[periodIndex] = dataModel;

						temporaryPeriodsData[dataIDNumber] = {
							...temporaryData,
							data,
						};
					},
				);
			});

			return Object.entries(temporaryPeriodsData)
				.filter((item): item is [string, NonNullableTemporaryWidgetData] => item[1].data.every((item) => !isNull(item)))
				.map<WidgetDataModel>(([dataID, {label, data}]) => ({
					id: Number(dataID),
					label,
					data,
					delta: isMultiplePeriods ? data[0].median - data[1]!.median : null,
				}));
		}, [widgetData, widgetOptions.demographicGroup, isDataCounting]);

		const sortableWidgetData = useSortItems({
			items: adaptedWidgetData,
			widgetSortBy,
		});

		useEffect(() => {
			if (isDataCounting) {
				setWidgetSortBy(WidgetSortBy.None);
				setSortableItems([]);

				return;
			}

			if (
				widgetOptions.viewType === PerformanceWidgetViewType.Candles &&
				(widgetOptions.classes.length === 2 || widgetData.demographicGroupData.length >= 2)
			) {
				setSortableItems(multipleChartSortOption);

				setWidgetSortBy((currentValue) => {
					if (multipleChartSortOption.some((item) => item.value === currentValue)) {
						return currentValue;
					}

					return WidgetSortBy.Alphabetical;
				});

				return;
			}

			setSortableItems(singleChartSortOption);

			setWidgetSortBy((currentValue) => {
				if (singleChartSortOption.some((item) => item.value === currentValue)) {
					return currentValue;
				}

				return WidgetSortBy.Alphabetical;
			});
		}, [widgetData, widgetOptions, isDataCounting]);

		return (
			<WidgetCard
				dataCy={dataCy}
				css={css}
				ref={forwardedRef}
				id={id}
				name={name}
				maxPossibleCardWidth={maxPossibleCardWidth}
				widgetData={sortableWidgetData}
				viewSize={widgetOptions.viewSize}
				defaultWidgetMode={defaultWidgetMode}
				viewModeChanged={viewModeChanged}
				isDataСounting={isDataCounting}
				currentTrack={currentTrack}
				allContentAreas={allContentAreas}
				lastUpdatedTime={lastUpdatedTime}
				{...componentRootProps}
			>
				<Content
					widgetOptions={widgetOptions}
					allClassesList={allClassesList}
					allTeachersList={allTeachersList}
					allClassesAvgValue={allClassesAvgValue}
					subjects={subjects}
					widgetSortBy={widgetSortBy}
					setWidgetSortBy={setWidgetSortBy}
					sortableItems={sortableItems}
					onDeleteWidgetByID={onDeleteWidgetByID}
					onUpdateWidget={onUpdateWidget}
					gradeLevels={gradeLevels}
				/>
			</WidgetCard>
		);
	},
);
