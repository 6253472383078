import * as React from 'react';
import type {SVGProps} from 'react';

export function Iep(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path d='M7.34789 14.5179C7.24285 14.5179 7.15152 14.4785 7.07389 14.3997C7.00082 14.3209 6.96429 14.2283 6.96429 14.1217V9.84064C6.96429 9.73407 7.00082 9.64141 7.07389 9.56264C7.15152 9.48388 7.24285 9.4445 7.34789 9.4445H7.85479C7.95982 9.4445 8.04887 9.48388 8.12194 9.56264C8.19957 9.64141 8.23839 9.73407 8.23839 9.84064V14.1217C8.23839 14.2283 8.19957 14.3209 8.12194 14.3997C8.04887 14.4785 7.95982 14.5179 7.85479 14.5179H7.34789Z' fill='#666666'/>
			<path d='M9.76706 14.5179C9.66203 14.5179 9.5707 14.4785 9.49306 14.3997C9.42 14.3209 9.38346 14.2283 9.38346 14.1217V9.84064C9.38346 9.73407 9.42 9.64141 9.49306 9.56264C9.5707 9.48388 9.66203 9.4445 9.76706 9.4445H12.192C12.297 9.4445 12.386 9.48388 12.4591 9.56264C12.5367 9.64141 12.5756 9.73407 12.5756 9.84064V10.0005C12.5756 10.107 12.5367 10.1997 12.4591 10.2785C12.386 10.3572 12.297 10.3966 12.192 10.3966H10.6028C10.5662 10.3966 10.548 10.4152 10.548 10.4522V11.3696C10.548 11.4113 10.5662 11.4321 10.6028 11.4321H12.055C12.16 11.4321 12.249 11.4715 12.3221 11.5503C12.3997 11.6291 12.4386 11.7217 12.4386 11.8283V11.9325C12.4386 12.0391 12.3997 12.1318 12.3221 12.2105C12.249 12.2893 12.16 12.3287 12.055 12.3287H10.6028C10.5662 12.3287 10.548 12.3472 10.548 12.3843V13.5101C10.548 13.5472 10.5662 13.5657 10.6028 13.5657H12.192C12.297 13.5657 12.386 13.6051 12.4591 13.6839C12.5367 13.7626 12.5756 13.8553 12.5756 13.9619V14.1217C12.5756 14.2283 12.5367 14.3209 12.4591 14.3997C12.386 14.4785 12.297 14.5179 12.192 14.5179H9.76706Z' fill='#666666'/>
			<path d='M14.6949 10.3758V11.8908C14.6949 11.9233 14.7155 11.9441 14.7566 11.9534C14.9119 11.9719 15.0352 11.9812 15.1265 11.9812C15.4553 11.9812 15.7087 11.9047 15.8868 11.7518C16.0695 11.5943 16.1609 11.3765 16.1609 11.0986C16.1609 10.5472 15.8161 10.2715 15.1265 10.2715C15.0123 10.2715 14.8867 10.2831 14.7497 10.3063C14.7132 10.3155 14.6949 10.3387 14.6949 10.3758ZM13.9414 14.5179C13.8364 14.5179 13.7451 14.4785 13.6674 14.3997C13.5898 14.3209 13.551 14.2283 13.551 14.1217V9.87539C13.551 9.76419 13.5875 9.66458 13.6606 9.57654C13.7337 9.48851 13.825 9.43986 13.9346 9.4306C14.3228 9.39353 14.6972 9.375 15.058 9.375C16.5193 9.375 17.25 9.92635 17.25 11.0291C17.25 11.6267 17.0788 12.0808 16.7362 12.3912C16.3983 12.697 15.9005 12.8499 15.2429 12.8499C15.0694 12.8499 14.9073 12.843 14.7566 12.8291C14.7155 12.8291 14.6949 12.8476 14.6949 12.8847V14.1217C14.6949 14.2283 14.6561 14.3209 14.5785 14.3997C14.5009 14.4785 14.4095 14.5179 14.3045 14.5179H13.9414Z' fill='#666666'/>
			<path fillRule='evenodd' clipRule='evenodd' d='M12 20.0357C16.438 20.0357 20.0357 16.438 20.0357 12C20.0357 7.562 16.438 3.96429 12 3.96429C7.562 3.96429 3.96429 7.562 3.96429 12C3.96429 16.438 7.562 20.0357 12 20.0357ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z' fill='#666666'/>
			<path fillRule='evenodd' clipRule='evenodd' d='M12 19.6875C16.2457 19.6875 19.6875 16.2457 19.6875 12C19.6875 7.75431 16.2457 4.3125 12 4.3125C7.75431 4.3125 4.3125 7.75431 4.3125 12C4.3125 16.2457 7.75431 19.6875 12 19.6875ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z' fill='#666666'/>
		</svg>
	);
}
