import {RowDef} from './types';
import {useData} from './use-data';
import {useColumnDefinitions} from './use-column-definitions';
import {useDataPageContext} from '../layout/context';
import {isNull} from 'underscore';
import {Base} from './variant/base';
import {Folder} from './variant/folder';
import {useUser} from '@esgi/core/authentication';
import React, {useCallback, useState} from 'react';
import {AddTeacher, EditTeacher} from '@esgi/main/features/school-admin/data';

export default function () {
	const user = useUser();
	const [teacherEditID, setTeacherEditID] = useState<RowDef['id'] | null>(null);

	const {activeFolder} = useDataPageContext();
	const {ready, rows} = useData();

	const colDefs = useColumnDefinitions(rows);
	const [isOpenAddDrawer, setIsOpenAddDrawer] = useState(false);

	const onOpenEditDrawer = useCallback((entity: RowDef) => {
		setTeacherEditID(entity.id);
	}, []);

	const onCloseEditDrawer = useCallback(() => {
		setTeacherEditID(null);
	}, []);

	const onOpenAddDrawer = useCallback(() => {
		setIsOpenAddDrawer(true);
	}, []);

	const onCloseAddDrawer = useCallback(() => {
		setIsOpenAddDrawer(false);
	}, []);

	return (
		<React.Fragment>
			{!isNull(activeFolder) ? (
				<Folder
					columnDefs={colDefs}
					allTableRows={rows}
					activeFolder={activeFolder}
					isDataLoaded={ready}
					userCanAddAndDeleteTeachers={user?.canAddAndDeleteTeachers}
					onOpenAddDrawer={onOpenAddDrawer}
				/>
			) : (
				<Base
					columnDefs={colDefs}
					allTableRows={rows}
					isDataLoaded={ready}
					userCanAddAndDeleteTeachers={user?.canAddAndDeleteTeachers}
					editButtonDisabled={!user?.canViewAndModifyTeachers}
					onOpenAddDrawer={onOpenAddDrawer}
					onEditClick={onOpenEditDrawer}
				/>
			)}
			{isOpenAddDrawer && <AddTeacher onClose={onCloseAddDrawer} />}
			{!isNull(teacherEditID) && (
				<EditTeacher teacherID={teacherEditID} onClose={onCloseEditDrawer} />
			)}
		</React.Fragment>
	);
}
