import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, tap} from 'rxjs';
import {
	V2SessionDetailsController,
	V2SessionDetailsRubricController,
	V2SessionDetailsScoreController,
	V2SessionDetailsYNController,
} from '@esgi/contracts/esgi';
import {isNull} from 'underscore';
import moment from 'moment';
import {dispatchAppEvent} from '@esgillc/events';
import {userStorage} from '@esgi/core/authentication';
import {
	Question,
	RubricCriterion,
	TestSession,
	UpdateRubricSessionRequestParams,
	UpdateScoreSessionRequestParams,
	UpdateYNSessionRequestParams,
	UpdateYNSessionRequestParamsContract,
} from '../../types/view-edit-session-details';
import {TestSessionUpdated} from '../../events';
import {getDateTimeWithGMT} from '../../utils/get-date-time-with-gmt';
import {AnswerState, Student, Subject} from '../../types/common';
import {SubjectType} from '@esgi/main/kits/common';

export class Service extends BaseService {
	public testSession$ = new BehaviorSubject<TestSession | null>(null);
	public questions$ = new BehaviorSubject<Question[]>([]);
	public rubricCriteria$ = new BehaviorSubject<RubricCriterion[]>([]);

	private controller = new V2SessionDetailsController();
	private controllerYNSession = new V2SessionDetailsYNController();
	private controllerScoreSession = new V2SessionDetailsScoreController();
	private controllerRubricSession = new V2SessionDetailsRubricController();

	public init({testSessionID}: {testSessionID: number}) {
		return this.controller.init({testSessionID}).pipe(
			tap(({testSession, questions, rubricCriteria}) => {
				this.testSession$.next(testSession);
				this.questions$.next(questions);
				this.rubricCriteria$.next(rubricCriteria);
			}),
		);
	}

	public deleteSessionRequest({testSessionID}: {testSessionID: number}) {
		return this.controller.sessionsDelete({testSessionID}).pipe(
			tap(() => {
				const currentTestSessionValue = this.testSession$.value;

				if (!isNull(currentTestSessionValue)) {
					const newTestSessionData: TestSession = {
						...currentTestSessionValue,
						isDeleted: true,
					};

					this.testSession$.next(newTestSessionData);

					dispatchAppEvent(TestSessionUpdated, new TestSessionUpdated(newTestSessionData));
				}
			}),
		);
	}

	public restoreSession({testSessionID}: {testSessionID: number}) {
		return this.controller.sessionsRestore({testSessionID}).subscribe(() => {
			const currentTestSessionValue = this.testSession$.value;

			if (!isNull(currentTestSessionValue)) {
				const newTestSessionData: TestSession = {
					...currentTestSessionValue,
					isDeleted: false,
				};

				this.testSession$.next(newTestSessionData);

				dispatchAppEvent(TestSessionUpdated, new TestSessionUpdated(newTestSessionData));
			}
		});
	}

	public downloadYNScoreSessionDetails({
		testName,
		studentFirstName,
		studentLastName,
		classID,
		studentID,
		testID,
		sessionID,
		subjectName,
	}: {
		testName: string;
		studentFirstName: string;
		studentLastName: string;
		classID: number;
		studentID: Student['id'];
		testID: number;
		sessionID: number;
		subjectName: string;
	}) {
		const formattedDate = moment().format('YYYY-MM-DD');

		const filename = [
			'Test_Session_Details',
			testName,
			[studentFirstName, studentLastName].filter(Boolean).join('_'),
			`${formattedDate}.pdf`,
		]
			.join('_')
			.replace(/ /g, '_');

		return this.httpClient.ESGIApi.file('/v2/modules/session-details/download', '', filename, {
			classID,
			specialistGroupID: 0,
			studentID,
			testID,
			sessionID,
			subject: subjectName,
		});
	}

	public downloadRubricSessionDetails({
		studentID,
		subjectID,
		classID,
		sessionID,
		subjectType,
		testID,
	}: {
		studentID: Student['id'];
		subjectID: Subject['id'];
		classID: number;
		sessionID: number;
		subjectType: Subject['type'];
		testID: number;
	}) {
		const formattedDate = moment().format('YYYY_MM_DD');

		const filename = `Test_Session_Details_${formattedDate}.pdf`;

		const udaptedSubjectType: Record<SubjectType, number> = {
			[SubjectType.Personal]: 0,
			[SubjectType.Stock]: 1,
			[SubjectType.Deployed]: 2,
		};

		return this.httpClient.ESGIApi.file('/v2/modules/session-details/rubric/export', '', filename, {
			rubricID: testID,
			studentID,
			subjectID,
			subjectType: udaptedSubjectType[subjectType],
			classID,
			filename,
			testSessionIDs: [sessionID],
		});
	}

	public updateYNSession({answers: inputAnswers, testDate, ...params}: UpdateYNSessionRequestParams) {
		const currentUser = userStorage.get();

		const testDateForSaving = getDateTimeWithGMT({
			dateTime: testDate,
			timeZone: currentUser.timeZone,
		});

		const udaptedAnswerState: Record<AnswerState, number> = {
			[AnswerState.Incorrect]: 0,
			[AnswerState.Correct]: 1,
			[AnswerState.NotTested]: 2,
		};

		const udaptedparams: UpdateYNSessionRequestParamsContract = {
			...params,
			testDate: testDateForSaving,
			listTsa: inputAnswers.map(({questionID, answerState, comment}) => ({
				questionID,
				answerState: udaptedAnswerState[answerState],
				comment: comment as string,
			})),
		};

		return this.controllerYNSession.update(udaptedparams).pipe(
			tap((response) => {
				if (!response.isSuccess) {
					return;
				}

				const currentTestSessionValue = this.testSession$.value;
				const questionsIDs = currentTestSessionValue.answers.map(({questionID}) => questionID);
				const parentTestSessionID = currentTestSessionValue.answers[0]?.parentTestSessionID;
				const notTestedQuestions = inputAnswers
					.filter((answer) => !questionsIDs.includes(answer.questionID))
					.map((answer) => ({...answer, parentTestSessionID}));

				if (!isNull(currentTestSessionValue)) {
					const newTestSessionData: TestSession = {
						...currentTestSessionValue,
						testDate,
						notes: params.notes,
						answers: [...currentTestSessionValue.answers, ...notTestedQuestions].map((answer) => {
							const newAnswer = inputAnswers.find(({questionID}) => questionID === answer.questionID);

							return {
								...answer,
								comment: newAnswer ? newAnswer.comment : answer.comment,
								answerState: newAnswer ? newAnswer.answerState : answer.answerState,
							};
						}),
						correctAnswers: inputAnswers.reduce((currentSum, {answerState}) => {
							if (answerState === AnswerState.Correct) {
								return currentSum + 1;
							}

							return currentSum;
						}, 0),
					};

					this.testSession$.next(newTestSessionData);
					dispatchAppEvent(TestSessionUpdated, new TestSessionUpdated(newTestSessionData));
				}
			}),
		);
	}

	public updateScoreSession({testDate, ...params}: UpdateScoreSessionRequestParams) {
		const currentUser = userStorage.get();

		const testDateForSaving = getDateTimeWithGMT({
			dateTime: testDate,
			timeZone: currentUser.timeZone,
		});

		return this.controllerScoreSession
			.update({
				...params,
				notes: params.notes as string,
				testDate: testDateForSaving,
			})
			.pipe(
				tap((response) => {
					if (!response.isSuccess) {
						return;
					}

					const currentTestSessionValue = this.testSession$.value;

					if (!isNull(currentTestSessionValue)) {
						const newTestSessionData: TestSession = {
							...currentTestSessionValue,
							testDate,
							notes: params.notes,
							correctAnswers: params.score,
						};

						this.testSession$.next(newTestSessionData);

						dispatchAppEvent(TestSessionUpdated, new TestSessionUpdated(newTestSessionData));
					}
				}),
			);
	}

	public updateRubricSession({testID, answers, ...params}: UpdateRubricSessionRequestParams) {
		return this.controllerRubricSession
			.update({
				...params,
				rubricID: testID,
				answers,
			})
			.pipe(
				tap(() => {
					const currentTestSessionValue = this.testSession$.value;

					if (!isNull(currentTestSessionValue)) {
						const newTestSessionData: TestSession = {
							...currentTestSessionValue,
							testDate: params.testDate,
							notes: params.summaryNotes,
							rubricAnswers: answers,
							correctAnswers: answers.reduce((currentSum, {score}) => currentSum + score, 0),
						};

						this.testSession$.next(newTestSessionData);

						dispatchAppEvent(TestSessionUpdated, new TestSessionUpdated(newTestSessionData));
					}
				}),
			);
	}

	public override dispose() {
		this.controller.dispose();
		this.controllerYNSession.dispose();
		this.controllerScoreSession.dispose();
		this.controllerRubricSession.dispose();
	}
}
