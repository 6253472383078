import {PropsWithChildren, useMemo, useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {OldReport, ReportType} from '@esgi/main/features/admins/data';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {Alert} from '@esgi/ui/alert';
import {useFirstAdminSubject} from './use-first-admin-subject';
import {AlertHeader, BodyContent, rootCSS} from './component.styled';
import {ReportsDialogContext, ReportsDialogContextValue} from './context';
import {isNull} from 'underscore';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

type Props = PropsWithChildren<{
	header: string;
	onClose: VoidFunction;
	loading?: boolean;
}>;

export function ReportDialog({header, onClose, loading, children}: Props) {
	const [reportType, setReportType] = useState<ReportType | null>(null);
	const [currentHierarchy, setCurrentHierarchy] = useState<Hierarchy | null>();

	const [subject, subjectLoaded] = useFirstAdminSubject();

	const ref = Alert.useRef();
	const close = Alert.useClose(ref, onClose);

	const skeleton = Boolean(loading) || !subjectLoaded;

	const context = useMemo<ReportsDialogContextValue>(
		() => ({
			runReport: (report, h) => {
				setCurrentHierarchy({...h});
				setReportType(report);
			},
			skeleton,
		}),
		[skeleton],
	);

	if (!isNull(reportType) && !isNull(subject)) {
		return (
			<OldReport
				report={reportType}
				onClose={() => setReportType(null)}
				firstSubject={subject}
				hierarchy={currentHierarchy ?? {}}
			/>
		);
	}

	return (
		<Alert modalManagerRef={ref} skeleton={skeleton} css={rootCSS}>
			<AlertHeader onCloseIconClick={close} withBacklight={false}>
				<Text size='small' color='highContrast'>
					{header}
				</Text>
			</AlertHeader>
			<Alert.Body>
				<ReportsDialogContext.Provider value={context}>
					<OverlayScrollbarsComponent
						defer
						style={{
							height: 'calc(100% - 0px)',
						}}
						options={{
							scrollbars: {
								autoHide: 'leave',
							},
						}}
					>
						<BodyContent>{children}</BodyContent>
					</OverlayScrollbarsComponent>
				</ReportsDialogContext.Provider>
			</Alert.Body>
		</Alert>
	);
}
