import {Class, Student} from '@esgi/main/libs/school-admin-store';
import {isUndefined, useStreamEffect} from '@esgi/ui';
import {FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {Dispatch, useEffect, useRef, useState} from 'react';
import {isNull} from 'underscore';
import {EditClassForm} from './types';
import {classNameMaxLength} from '../../constants';

type Parameters = {
	teacherClasses: Class[];
	students: Student[];
	activeClassID: Class['id'];
	setIsFormValid: Dispatch<boolean>;
	setIsFormTouched: Dispatch<boolean>;
	skeleton: boolean;
};

export function useEditForm({
	teacherClasses,
	students,
	activeClassID,
	setIsFormTouched,
	setIsFormValid,
	skeleton,
}: Parameters) {
	const [, forceUpdate] = useState({});
	const initialClassName = useRef('');
	const initialStudentsIDs = useRef<Student['id'][]>([]);

	const form: EditClassForm = useForm(
		() =>
			new FormGroup({
				className: new FormControl(''),
				studentsIDs: new FormControl<Student['id'][]>([]),
			}),
	);

	useEffect(() => {
		if (!skeleton) {
			const currentClass = teacherClasses.find(({id}) => id === activeClassID);

			const isCurrentClassUndef = isUndefined(currentClass);

			const className = isCurrentClassUndef ? '' : currentClass.name;
			const studentsIDsInCurrentClass: Student['id'][] = isCurrentClassUndef
				? []
				: students.filter(({classesIDs}) => classesIDs.includes(currentClass.id)).map(({id}) => id);

			initialClassName.current = className;
			initialStudentsIDs.current = studentsIDsInCurrentClass;

			form.controls.studentsIDs.value = studentsIDsInCurrentClass;

			form.controls.className.value = className;
			form.controls.className.validators.length = 0;

			form.controls.className.validators.push(
				Validators.required(),
				Validators.length.max(classNameMaxLength),
				Validators.isDublicateValue(
					teacherClasses
						.map(({name, id}) => (id === activeClassID ? null : name))
						.filter((item): item is string => !isNull(item)),
				),
			);

			forceUpdate({});
		}
	}, [activeClassID, skeleton, students, teacherClasses]);

	useStreamEffect(
		form.onChanged,
		({
			currState: {
				value: {className, studentsIDs},
			},
		}) => {
			const isFormTouched =
				className !== initialClassName.current ||
				studentsIDs.length !== initialStudentsIDs.current.length ||
				studentsIDs.some((id) => !initialStudentsIDs.current.includes(id));

			setIsFormTouched(isFormTouched);

			form.validate(true).subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		},
	);

	return {
		form,
		initialClassName: initialClassName.current,
		initialStudentsIDs: initialStudentsIDs.current,
	};
}
