import {
	SubjectPublishType,
	SubjectPublishTypeNumerable,
	SubjectPublishValue,
	TrackDate,
} from '@esgi/main/features/admins/subject-management';
import moment from 'moment';
import {isNull} from 'underscore';

type Parameters = {
	publishPeriodValue: SubjectPublishValue;
	allTrackDates: TrackDate[];
};

type Out = {
	publishType: SubjectPublishTypeNumerable;
	publishFromDate: string;
	publishToDate: string;
};

export function getPublishDatesSaveData({publishPeriodValue, allTrackDates}: Parameters): Out {
	if (publishPeriodValue.type === SubjectPublishType.Off) {
		return {
			publishType: SubjectPublishTypeNumerable.Off,
			publishFromDate: null as unknown as string,
			publishToDate: null as unknown as string,
		};
	}
	if (publishPeriodValue.type === SubjectPublishType.Indefinitely) {
		return {
			publishType: SubjectPublishTypeNumerable.Indefinitely,
			publishFromDate: null as unknown as string,
			publishToDate: null as unknown as string,
		};
	}

	if (publishPeriodValue.type === SubjectPublishType.CustomDateRange) {
		if (isNull(publishPeriodValue.dateFrom)) {
			throw new Error('publishPeriodValue.dateFrom is null');
		}

		if (isNull(publishPeriodValue.dateTo)) {
			throw new Error('publishPeriodValue.dateTo is null');
		}

		return {
			publishType: SubjectPublishTypeNumerable.CustomDateRange,
			publishFromDate: moment(`${publishPeriodValue.dateFrom}Z`).toISOString(),
			publishToDate: moment(`${publishPeriodValue.dateTo}Z`).toISOString(),
		};
	}

	if (publishPeriodValue.type === SubjectPublishType.SchoolYear) {
		return {
			publishType: SubjectPublishTypeNumerable.SchoolYear,
			publishFromDate: null as unknown as string,
			publishToDate: null as unknown as string,
		};
	}

	if (publishPeriodValue.type === SubjectPublishType.MarkingPeriod) {
		const filteredTrackDates = allTrackDates
			.filter(({trackDateID}) => publishPeriodValue.trackDatesIDs.includes(trackDateID))
			.flatMap(({dateFrom, dateTo}) => [moment(`${dateFrom}Z`), moment(`${dateTo}Z`)]);

		const minDate = moment.min(filteredTrackDates);
		const maxDate = moment.max(filteredTrackDates);

		return {
			publishType: SubjectPublishTypeNumerable.MarkingPeriod,
			publishFromDate: minDate.toISOString(),
			publishToDate: maxDate.toISOString(),
		};
	}

	throw new Error('publishPeriodValue.type is not correspond the SubjectPublishType enum');
}
