import {Edit, PieChartIcon, Students} from '@esgi/ui/icons';
import {
	ActionPanel,
	DeleteButton,
	RunReportButton,
	SingleSelectedExpandablePanel,
	useEntityDeletionState,
} from '../../../../../../../_kit';
import {Text} from '@esgi/ui/typography';
import {RowDef} from '../../../../../../types';
import {Button, isUndefined, Portal, useFlag} from '@esgi/ui';
import {EntityType, GroupDrawer, RemoveEntity, useNavigateToStudentsPage} from '@esgi/main/features/school-admin/data';
import {AdditionalHeaderContent, HeaderContent} from './index.styled';
import {useDataPageContext} from '../../../../../../../layout/context';
import {ReportsDialog} from '../reports-dialog';
import {useCallback} from 'react';
import {useRemoveGroupInStore} from '@esgi/main/features/school-admin/utils';
import {dispatchAppEvent} from '@esgillc/events';
import {RefreshDataPanelEvent} from '../../../../../../../layout/events';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
};

export function QuickPanel({selectedRow, isPanelExpanded}: Props) {
	const {rootPageContentContainerRef} = useDataPageContext();

	const [showReports, openReports, closeReports] = useFlag();
	const [showEditDrawer, openEditDrawer, closeEditDrawer] = useFlag();

	const {entityIDs, showRemoveEntity, onOpenRemoveEntity, onCloseRemoveEntity} = useEntityDeletionState({
		selectedItems: [selectedRow],
	});

	const goToStudentsPage = useNavigateToStudentsPage();

	const removeGroupInStore = useRemoveGroupInStore();

	const navigateToStudents = useCallback(() => {
		goToStudentsPage({
			group: [selectedRow.id],
		});
	}, [goToStudentsPage, selectedRow]);

	const handleEntityRemove = useCallback(
		(entityIDs: number[]) => {
			if (entityIDs.length >= 2) {
				throw new Error('entityIDs.length >= 2');
			}

			const removedGroupID = entityIDs[0];

			if (!isUndefined(removedGroupID)) {
				removeGroupInStore(removedGroupID, () => {
					dispatchAppEvent(RefreshDataPanelEvent, new RefreshDataPanelEvent());
				});
			}

			onCloseRemoveEntity();
		},
		[onCloseRemoveEntity, removeGroupInStore],
	);

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<ActionPanel.MainBox>
							<Students />
							<Text size='large' color='highContrast'>
								{selectedRow.name}
							</Text>
						</ActionPanel.MainBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteButton onClick={onOpenRemoveEntity} />
							<ActionPanel.ActionButtonDivider />

							<Button.Icon onClick={openEditDrawer}>
								<Edit />
							</Button.Icon>

							<RunReportButton onClick={openReports} />
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<ActionPanel.Navigation.Container>
							<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
						</ActionPanel.Navigation.Container>

						<SingleSelectedExpandablePanel.OpenPanelButton
							Icon={PieChartIcon}
							transcript='View Tests Results'
							warningDescription='No students have been added to your group. Please add some before running the report.'
							canExpandPanel={!!selectedRow.students}
						/>
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showReports && <ReportsDialog onClose={closeReports} selectedGroup={selectedRow} />}

			{showEditDrawer && (
				<Portal container={rootPageContentContainerRef.current}>
					<GroupDrawer.Edit onClose={closeEditDrawer} groupID={selectedRow.id} />
				</Portal>
			)}

			{showRemoveEntity && (
				<RemoveEntity
					entityIDs={entityIDs}
					entityType={EntityType.Group}
					onEntityRemoved={handleEntityRemove}
					onClose={onCloseRemoveEntity}
				/>
			)}
		</>
	);
}
