import {Portal, useFlag} from '@esgi/ui';
import {useDataPageContext} from '../../../layout/context';
import {CollapsiblePanel} from '@esgi/main/features/admins/data';
import {PropsWithChildren, Dispatch, useState, useCallback, useMemo, useEffect} from 'react';
import {SingleSelectedExpandablePanelContext, SingleSelectedExpandablePanelContextValue} from '../context';
import {GridApi} from 'ag-grid-community';
import {isNull} from 'underscore';

type Props<T extends Record<string, unknown>> = PropsWithChildren<{
	gridApi: GridApi<T> | null;
	onSingleSelectedItemChange?: Dispatch<T | null>;
	onPanelExpandableValueChange?: Dispatch<boolean>;
}>;

export function Root<T extends Record<string, unknown>>({
	gridApi,
	onPanelExpandableValueChange,
	onSingleSelectedItemChange,
	children,
}: Props<T>) {
	const {pageContentContainerRef, rootPageContentContainerRef} = useDataPageContext();
	const managerRef = CollapsiblePanel.useRef();

	const [selectedItems, setSelectedItems] = useState<T[]>([]);
	const [singleSelectedItem, setSingleSelectedItem] = useState<T | null>(null);

	const [isPanelExpanded, unFoldPanel, collapsePanelFlag] = useFlag();

	const handleSetSingleSelectedItem = useCallback(
		(item: T | null) => {
			setSingleSelectedItem(item);
			onSingleSelectedItemChange?.(item);
		},
		[onSingleSelectedItemChange],
	);

	const expandPanel = CollapsiblePanel.useExpandPanel(
		managerRef,
		() => {
			unFoldPanel();
			onPanelExpandableValueChange?.(true);
		},
		true,
	);

	const closePanel = CollapsiblePanel.useClose(managerRef, () => {
		handleSetSingleSelectedItem(null);
		collapsePanelFlag();
		onPanelExpandableValueChange?.(false);
	});

	const collapsePanel = CollapsiblePanel.useCollapse(managerRef, () => {
		collapsePanelFlag();
		onPanelExpandableValueChange(false);
	});

	const closePanelContext = useCallback(() => {
		isPanelExpanded ? collapsePanel() : gridApi?.deselectAll();
	}, [collapsePanel, gridApi, isPanelExpanded]);

	useEffect(() => {
		if (isNull(gridApi)) {
			return;
		}

		const onSelectHandler = () => setSelectedItems(gridApi.getSelectedRows());

		gridApi.addEventListener('selectionChanged', onSelectHandler);

		return () => {
			gridApi.removeEventListener('selectionChanged', onSelectHandler);
		};
	}, [gridApi]);

	useEffect(() => {
		if (selectedItems.length === 1) {
			handleSetSingleSelectedItem(selectedItems[0]!);
			return;
		}
		closePanel();
	}, [closePanel, handleSetSingleSelectedItem, selectedItems]);

	const context = useMemo<SingleSelectedExpandablePanelContextValue>(
		() => ({
			isPanelExpanded,
			expandPanel,
			closePanel: closePanelContext,
		}),
		[closePanelContext, expandPanel, isPanelExpanded],
	);

	return (
		singleSelectedItem && (
			<Portal container={pageContentContainerRef.current}>
				<SingleSelectedExpandablePanelContext.Provider value={context}>
					<CollapsiblePanel.Root
						managerRef={managerRef}
						overlayContainer={rootPageContentContainerRef.current}
						onOverlayClick={closePanelContext}
					>
						{children}
					</CollapsiblePanel.Root>
				</SingleSelectedExpandablePanelContext.Provider>
			</Portal>
		)
	);
}
