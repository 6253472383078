import {HideIconBox, SubjectGridBox, SubjectText} from './index.styled';
import {Hide} from '@esgi/ui/icons';
import {BaseComponentProps, Skeletonable, Tooltip, useFlag} from '@esgi/ui';
import {ComponentProps, ComponentPropsWithoutRef, forwardRef, MouseEvent, useCallback, useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {usePanelContext} from '../../../context';

type Props = BaseComponentProps &
	Skeletonable &
	Omit<ComponentPropsWithoutRef<typeof SubjectGridBox>, 'skeleton'> & {
		subjectID: number;
		subjectName: string;
		hidden: boolean;
		hiddenIconText?: string | undefined;
	};

export const ItemContent = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'subject-name',
			css = {},
			subjectName,
			hidden,
			skeleton,
			children,
			hiddenIconText,
			subjectID,
			onMouseEnter,
			onMouseLeave,
			...props
		},
		forwardedRef,
	) => {
		const {selectedSubjectID} = usePanelContext();

		const [isItemHovered, hoverItem, unHoverItem] = useFlag();
		const withChildren = Boolean(children);

		const isActiveSubject = selectedSubjectID === subjectID;

		const itemTextColor = useMemo<NonNullable<ComponentProps<typeof SubjectText>['color']>>(() => {
			if (isActiveSubject || isItemHovered) {
				return 'currentColor';
			}

			return hidden ? 'mediumContrast' : 'base';
		}, [hidden, isActiveSubject, isItemHovered]);

		const handleMouseEnter = useCallback(
			(event: MouseEvent<HTMLDivElement>) => {
				hoverItem();
				onMouseEnter?.(event);
			},
			[hoverItem, onMouseEnter],
		);

		const handleMouseLeave = useCallback(
			(event: MouseEvent<HTMLDivElement>) => {
				unHoverItem();
				onMouseLeave?.(event);
			},
			[onMouseLeave, unHoverItem],
		);

		return (
			<SubjectGridBox
				dataCy={dataCy}
				css={css}
				withHideIcon={hidden}
				withChildren={withChildren}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				ref={forwardedRef}
				{...props}
			>
				<SubjectText data-cy={`${dataCy}-title`} size='large' color={itemTextColor} skeleton={Boolean(skeleton)}>
					{!skeleton && subjectName}
				</SubjectText>

				{hidden && (
					<Tooltip open={hiddenIconText ? undefined : false}>
						<Tooltip.Trigger>
							<HideIconBox skeleton={Boolean(skeleton)} isActiveSubject={isActiveSubject}>
								<Hide />
							</HideIconBox>
						</Tooltip.Trigger>
						<Tooltip.Content variant='secondary'>
							<Text size='small'>{hiddenIconText}</Text>
						</Tooltip.Content>
					</Tooltip>
				)}

				{withChildren && children}
			</SubjectGridBox>
		);
	},
);
