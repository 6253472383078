export {Color as ColorNumerable} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/color';
export {PeriodType as PeriodTypeNumerable} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/period-type';
export {WidgetViewSize as WidgetViewSizeNumerable} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/widget-view-size';
export {StudentRace as StudentRaceNumerable} from '@esgi/contracts/esgi/types/esgi.students/enums/student-race';
export {StudentLunchStatus as StudentLunchStatusNumerable} from '@esgi/contracts/esgi/types/esgi.students/enums/student-lunch-status';
export {StudentSpecialProgram as StudentSpecialProgramNumerable} from '@esgi/contracts/esgi/types/esgi.students/enums/student-special-program';
export {DemographicGroup as DemographicGroupTypeNumerable} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/demographic-group';

export enum Color {
	None = 'None',
	Blue = 'Blue',
	Yellow = 'Yellow',
	Red = 'Red',
	Purple = 'Purple',
	Green = 'Green',
	Grey = 'Grey',
	Orange = 'Orange',
	Indigo = 'Indigo',
}

export enum PeriodType {
	None = 'None',
	Manual = 'Manual',
	Today = 'Today',
	ThisWeek = 'ThisWeek',
	ThisMonth = 'ThisMonth',
	CurrentMarkingPeriod = 'CurrentMarkingPeriod',
	CurrentSchoolYear = 'CurrentSchoolYear',
	Yesterday = 'Yesterday',
	PreviousWeek = 'PreviousWeek',
	PreviousMonth = 'PreviousMonth',
	PreviousMarkingPeriod = 'PreviousMarkingPeriod',
	PreviousSelectedMarkingPeriod = 'PreviousSelectedMarkingPeriod',
}

export enum WidgetViewSize {
	None = 'None',
	Default = 'Default',
	FullSize = 'FullSize',
}

export enum DemographicGroupType {
	None = 'None',
	LunchStatus = 'LunchStatus',
	SpecialProgram = 'SpecialProgram',
	RaceEthnicity = 'RaceEthnicity',
}

export enum StudentSpecialProgram {
	GiftedTalented = 'GiftedTalented',
	SPED = 'SPED',
	RTI = 'RTI',
	ELLESL = 'ELLESL',
	FiveOuFour = 'FiveOuFour',
}

export enum StudentLunchStatus {
	Free = 'Free',
	Reduced = 'Reduced',
	Other = 'Other',
}

export enum StudentRace {
	Asian = 'Asian',
	BlackOrAfricanAmerican = 'BlackOrAfricanAmerican',
	Multiethnic = 'Multiethnic',
	NativeAmericanOrAlaskaNative = 'NativeAmericanOrAlaskaNative',
	NativeHawaiianOrPacificIslander = 'NativeHawaiianOrPacificIslander',
	WhiteOrCaucasian = 'WhiteOrCaucasian',
	HispanicOrLatino = 'HispanicOrLatino',
	MiddleEasternOrNorthAfrican = 'MiddleEasternOrNorthAfrican',
	Other = 'Other',
}
