import {useCallback, useEffect} from 'react';
import {useStreamEffect} from '@esgi/ui';
import {PiechartsForm} from '../forms-data/types';
import {DynamicFormProps} from '../types';
import {InputsBox} from '../index.styled';
import {DatePickerForm, WidgetCard} from '@esgi/main/features/admins/dashboard';

type Props = DynamicFormProps<{
	piechartsForm: PiechartsForm;
}>;

export function PiechartsData({
	piechartsForm,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	onCurrentDynamicFormNameChange,
}: Props) {
	const handleValidate = useCallback(() => {
		piechartsForm.validate(true).subscribe(({valid}) => {
			setIsDynamicFormDataValid(valid);
		});
	}, [piechartsForm, setIsDynamicFormDataValid]);

	useEffect(() => {
		handleValidate();
		onCurrentDynamicFormNameChange('piecharts');
	}, [handleValidate, onCurrentDynamicFormNameChange]);

	useStreamEffect(piechartsForm.onChanged, handleValidate);

	return (
		<InputsBox>
			<WidgetCard.EditMode.FormElement control={piechartsForm.controls.date}>
				<DatePickerForm form={piechartsForm} onTouchedChange={setIsDynamicFormDataTouched} />
			</WidgetCard.EditMode.FormElement>
		</InputsBox>
	);
}
