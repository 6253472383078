import {randBoolean} from '@ngneat/falso';
import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {SubjectModel} from '../../types/subject';

export const skeletonableMockSubjects = new Array(30).fill(null).map<SubjectModel>((_, index) => ({
	id: index,
	hidden: randBoolean(),
	level: SubjectLevel.Teacher,
	name: '',
	type: SubjectType.Personal,
}));
