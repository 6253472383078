import {BaseService} from '@esgi/core/service';
import {Student} from '../../types';
import {InitEditClassResponse} from './types';
import {BehaviorSubject, map} from 'rxjs';
import {Class} from '@esgi/main/libs/store';
import {V2TeachersClassesController} from '@esgi/contracts/esgi';
import {AddStudent} from '../../../drawer-with-student-via-profile/events';

export class EditDataService extends BaseService {
	public students$ = new BehaviorSubject<Student[] | null>(null);
	public initialSelectedStudentsIds$ = new BehaviorSubject<Student['id'][] | null>(null);
	public initialClassName$ = new BehaviorSubject<string | null>(null);

	private controller = 'v2/teachers/modules/classes';
	private teachersClassesController = new V2TeachersClassesController();

	public init({classID}: {classID: Class['id']}) {
		return this.httpClient.ESGIApi.get<InitEditClassResponse>(this.controller, 'update', {classID})
			.pipe(
				map(({availableStudents, studentsInClass, name}) => {
					this.students$.next([...availableStudents, ...studentsInClass]);

					const initialSelectedStudentsIds = studentsInClass.map(({id}) => id);
					this.initialSelectedStudentsIds$.next(initialSelectedStudentsIds);
					this.initialClassName$.next(name);

					return {initialSelectedStudentsIds};
				}),
			)
			.asObservable();
	}

	public updateClass({classID, name, studentIDs}: {classID: Class['id']; name: string; studentIDs: Student['id'][]}) {
		return this.httpClient.ESGIApi.post(this.controller, 'update', {classID, name, studentIDs});
	}

	public deleteClass({classID}: {classID: Class['id']}) {
		return this.teachersClassesController.remove({classID});
	}

	public addStudent(student: AddStudent) {
		const {id, firstName, lastName} = student.studentsData.student;
		this.students$.next([...this.students$.value, {id, firstName, lastName}]);
	}

	public override dispose() {
		super.dispose();
		this.teachersClassesController.dispose();
	}
}
