/* eslint-disable @typescript-eslint/naming-convention */
import {routes} from '@esgi/main/libs/core';
import {AdditionalElementType, NavigateItem} from '../types';
import {menuIconByPath, menuLabelByRoute} from '../constants';
import {useMemo} from 'react';

export function useNavigateItems(canExploreStudents: boolean) {
	return useMemo<(NavigateItem | AdditionalElementType)[]>(() => {
		const navigateItems = [
			{
				to: routes.schoolAdmin.dashboard,
				label: menuLabelByRoute.dashboard,
				Icon: menuIconByPath.dashboard,
			},
			{
				to: routes.schoolAdmin.data.root,
				label: menuLabelByRoute.data,
				Icon: menuIconByPath.data,
			},

			{
				to: routes.schoolAdmin.subjectManagement,
				label: menuLabelByRoute.subjectManagement,
				Icon: menuIconByPath.subjectManagement,
			},

			AdditionalElementType.Divider,

			{
				to: routes.schoolAdmin.testExplorer,
				label: menuLabelByRoute.testExplorer,
				Icon: menuIconByPath.testExplorer,
			},
		];

		if (canExploreStudents) {
			navigateItems.push(AdditionalElementType.Divider);
			navigateItems.push({
				to: routes.schoolAdmin.studentManager,
				label: menuLabelByRoute.studentManager,
				Icon: menuIconByPath.studentManager,
			});
		}

		return navigateItems;
	}, [canExploreStudents]);
}
