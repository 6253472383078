import {Widget} from '@esgi/main/features/district-admin/dashboard';
import {parseDistrictPerformanceOptions} from './widget-options/parse-district-performance-options';
import {parseDemographicPerformanceOptions} from './widget-options/parse-demographic-performance-options';
import {parseLowestAchievementOptions} from './widget-options/parse-lowest-achievement-options';
import {
	ContentAreaModel,
	DeployedSubject,
	GradeLevel,
	SchoolModel,
	WidgetContract,
} from '@esgi/main/features/admins/dashboard';

export function parseInitWidgetsData({
	widgets,
	deployedSubjects,
	schools,
	gradeLevels,
	contentAreasList,
}: {
	widgets: WidgetContract[];
	deployedSubjects: DeployedSubject[];
	schools: SchoolModel[];
	gradeLevels: GradeLevel[];
	contentAreasList: ContentAreaModel[];
}) {
	return widgets.map<Widget>(
		({
			id,
			name,
			options: {districtPerformanceWidgetOptions, demographicPerformanceWidgetOptions, lowestAchievementWidgetOptions},
			versionID,
			lastUpdatedTime,
		}) => ({
			id,
			name,
			versionID,
			lastUpdatedTime,
			options: {
				schoolPerformanceWidgetOptions: null,
				schoolStudentsNeedingSupportWidgetOptions: null,
				schoolDemographicPerformanceWidgetOptions: null,
				districtPerformanceWidgetOptions: parseDistrictPerformanceOptions({
					options: districtPerformanceWidgetOptions,
					deployedSubjects,
					schools,
					gradeLevels,
					contentAreasList,
				}),

				demographicPerformanceWidgetOptions: parseDemographicPerformanceOptions({
					options: demographicPerformanceWidgetOptions,
					deployedSubjects,
					schools,
					gradeLevels,
					contentAreasList,
				}),

				lowestAchievementWidgetOptions: parseLowestAchievementOptions({
					options: lowestAchievementWidgetOptions,
					deployedSubjects,
					schools,
					gradeLevels,
					contentAreasList,
				}),
			},
		}),
	);
}
