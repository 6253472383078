import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Property} from '@stitches/react/types/css';

type Props = ComponentPropsWithoutRef<typeof Container> & {
	transcript: string;
	justifyItems?: Property.JustifyItems;
};

export const InfoWithTranscript = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'test-card-statistics-info-with-transcript',
			css = {},
			transcript,
			justifyItems = 'end',
			children,
			...props
		},
		forwardedRef,
	) => (
		<Container dataCy={dataCy} css={{justifyItems, ...css}} ref={forwardedRef} {...props}>
			{children}
			<Text size='small' font='mono' color='neutral56'>
				{transcript}
			</Text>
		</Container>
	),
);
