import {MutableRefObject} from 'react';

export type Folder = {
	id: number;
	name: string;
};

export enum FormType {
	ExistingFolder = 'ExistingFolder',
	NewFolder = 'NewFolder',
}

export type FoldersVocabulary = MutableRefObject<Record<Folder['id'], number[]>>;
