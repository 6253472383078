import {dashboarWidgetOptionsAllNullable} from './constants';

import {AddWidgetBase as Component} from './component';

export const AddWidgetBase = Object.assign(Component, {
	dashboarWidgetOptionsAllNullable,
});

export {
	type AddWidgetModel,
	type OnWidgetCreate,
	type DatePeriod,
	DemographicGroupNumerable,
	PerformanceWidgetViewTypeNumerable,
	type AddWidgetResponse,
} from './types';
