import {ThemeColorKeys} from 'libs/ui/src/types';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {ContentWrapper, HeaderBox} from './index.styled';
import {getThemeColor} from '@esgi/ui/utils';

type AlertHeaderProps = ComponentPropsWithoutRef<typeof HeaderBox> & {
	headerBgColor?: ThemeColorKeys | undefined;
};

export const Header = forwardRef<HTMLDivElement, AlertHeaderProps>(
	({dataCy = 'collapsible-panel-header', css = {}, headerBgColor = 'background', children, ...props}, forwardedRef) => {
		return (
			<HeaderBox
				dataCy={dataCy}
				css={{
					background: `linear-gradient(180deg, ${getThemeColor(headerBgColor)} 0%, $vivid 100%)`,
					...css,
				}}
				{...props}
				ref={forwardedRef}
			>
				<ContentWrapper data-header-content-wrapper=''>{children}</ContentWrapper>
			</HeaderBox>
		);
	},
);
