import {ManageSubjectsAndTestsController} from '@esgi/contracts/esgi';
import {useDisposable} from '@esgi/core/service';
import {Subject, subjectsStore} from '@esgi/main/libs/admin-store';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useCallback, useEffect} from 'react';

type Parameters = {
	subjectFromStore: Subject;
	onSubjectRemoved: VoidFunction;
};

export function useDeleteSubject({subjectFromStore, onSubjectRemoved}: Parameters) {
	const subjectsController = useDisposable(ManageSubjectsAndTestsController);

	const [removeSubjectData, removeSubject] = useCancelableRequest(subjectsController.remove);

	useEffect(() => {
		if (isAsyncSucceed(removeSubjectData)) {
			subjectsStore().remove?.((item) => item.id === subjectFromStore.id);

			onSubjectRemoved();
		}
	}, [removeSubjectData]);

	const handleRemoveSubject = useCallback(() => {
		removeSubject({
			userID: 0,
			subjectID: subjectFromStore.id,
		});
	}, [removeSubject, subjectFromStore]);

	return {
		isSubjectRemoving: isAsyncPending(removeSubjectData),
		handleRemoveSubject,
	};
}
