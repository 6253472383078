import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';

export type Subject = Omit<SubjectModel, 'hidden'> & {
	gradeLevels: number[];
};

export type Student = {
	id: number;
	firstName: string;
	lastName: string;
	gradeLevelID: number;
};

export enum CardsSortBy {
	Default = 'Default',
	TestName = 'TestName',
	LastTested = 'LastTested',
	StudentTestedPercentage = 'StudentTestedPercentage',
}

export type EntityModel = {
	id: number;
	name: string;
};

export type AdditionalEntityType = 'school' | 'teacher' | 'class' | 'group' | 'specialistGroup';

export type AdditionalEntity = Partial<Record<AdditionalEntityType, EntityModel>>;
