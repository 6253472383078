import {ClassCUDEventData} from './types';

export class ClassCreatedEvent {
	constructor(public data: ClassCUDEventData) {}
}

export class ClassUpdatedEvent {
	constructor(public data: ClassCUDEventData) {}
}

export class ClassDeletedEvent {
	constructor(public data: ClassCUDEventData) {}
}
