export enum TabID {
	Indefinitely = 'Indefinitely',
	Period = 'Period',
	CustomDateRange = 'CustomDateRange',
	None = 'None',
}

export enum PeriodTabID {
	SchoolYear = 'SchoolYear',
	MarkingPeriod = 'MarkingPeriod',
}
