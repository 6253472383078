import {PublishToTeachersNumerable} from '@esgi/main/features/admins/subject-management';

type Parameters = {
	publishToTeachers: PublishToTeachersNumerable;
	selectedSchoolsIDs: number[];
	allSchoolsIDs: number[];
};

export function getPublishForTeachersStoreData({
	publishToTeachers,
	selectedSchoolsIDs,
	allSchoolsIDs,
}: Parameters): number[] {
	if (publishToTeachers === PublishToTeachersNumerable.All) {
		return [...allSchoolsIDs];
	}

	if (publishToTeachers === PublishToTeachersNumerable.Schools) {
		return [...selectedSchoolsIDs];
	}

	return [];
}
