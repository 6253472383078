import {tap} from 'rxjs/operators';
import {BehaviorSubject, Subscription} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2TestsController} from '@esgi/contracts/esgi';
import {SubjectType} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';
import {InitRequestModel, Scope, Test, TestType} from './types';
import {getClientSortedTests} from './utils';
import {countPerPage} from '../model';

export class DataService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public initialized$ = new BehaviorSubject(false);

	public tests$ = new BehaviorSubject<Test[]>([]);
	public selectedTests$ = new BehaviorSubject<Test[]>([]);

	public pageIndex$ = new BehaviorSubject<number>(1);
	public pageCount$ = new BehaviorSubject<number>(0);
	public testsCount$ = new BehaviorSubject<number>(0);
	public subjectTestIDs$ = new BehaviorSubject<number[]>([]);

	private testController = new V2TestsController();

	public getSubjectTestIDs(subjectID: number, subjectType: SubjectType) {
		this.loaded$.next(false);

		return this.testController.addToSubjectInit({subjectType, subjectID})
			.pipe(tap(({testIDs}) => {
				this.setSubjectTestIDs(testIDs);
			})).subscribe();
	}

	public setSubjectTestIDs(testIDs: number[]) {
		this.subjectTestIDs$.next(testIDs);
	}

	public setInitPage() {
		this.pageIndex$.next(1);
	}

	public setNextPage() {
		const nextPage = this.pageIndex$.value + 1;
		if (this.pageCount$.value < nextPage) {
			return;
		}

		this.pageIndex$.next(nextPage);
	}

	public setPreviousPage() {
		const previousPage = this.pageIndex$.value - 1;
		if (previousPage < 1) {
			return;
		}

		this.pageIndex$.next(previousPage);
	}

	public setSelectedTests(list: Test[]) {
		this.selectedTests$.next(list);
	}

	public addSelectedTabs(subjectID: number, subjectType: SubjectType) {
		if (!this.selectedTests$.value.length) {
			return;
		}

		this.loaded$.next(false);
		const testIDs = this.selectedTests$.value.map((item) => item.id);

		return this.testController
			.addToSubjectSave({
				subjectID,
				subjectType,
				testIDs,
			})
			.pipe(
				tap(() => {
					const selectedTests = [...this.subjectTestIDs$.value, ...testIDs];
					const updatedTests = this.tests$.value.map((item) => ({
						...item,
						disabled: selectedTests.includes(item.id),
					}));

					this.tests$.next(updatedTests);
					this.loaded$.next(true);
				}),
			);
	}

	public fetchData({
		sorting,
		keyword,
		onlySelfAssess,
		onlySelected,
		testType,
		scope,
	}: Pick<
		InitRequestModel,
		'sorting' | 'keyword' | 'onlySelfAssess' | 'onlySelected' | 'testType' | 'scope'
	>): Subscription {
		this.loaded$.next(false);
		if (onlySelected) {
			const page = this.pageIndex$.value - 1;
			const selectedTests = getClientSortedTests({
				tests: this.selectedTests$.value,
				isSelfAssess: onlySelfAssess,
				testType,
				keyword,
				scope,
			});


			this.tests$.next(selectedTests.slice(page * countPerPage, (page + 1) * countPerPage));
			this.testsCount$.next(selectedTests.length);
			this.pageCount$.next(Math.ceil(selectedTests.length / countPerPage));
			this.loaded$.next(true);
			return;
		}

		return this.testController.search({
			pageIndex: this.pageIndex$.value,
			keyword,
			onlySelfAssess,
			sorting,
			itemsPerPage: countPerPage,
			contentAreaID: null,
			testType: TestType[testType],
			scope: Scope[scope],
		})
			.pipe(tap(({count, tests}) => {
				if (tests.length === 0 && this.pageIndex$.value > 1) {
					this.loaded$.next(true);
					return;
				}

				const newTests: Test[] = tests.map((item) => ({
					...item,
					testType: TestType[item.testType],
					disabled: this.subjectTestIDs$.value.includes(item.id),
				}));

				this.tests$.next(newTests);
				this.testsCount$.next(count);
				this.pageCount$.next(Math.ceil(count / countPerPage));
				this.loaded$.next(true);
			})).subscribe();
	}

	public override dispose() {
		this.testController.dispose();
	}
}