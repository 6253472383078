import {useUser} from '@esgi/core/authentication';
import {useDisposable} from '@esgi/core/service';
import {V2SchoolAdminsClassesController} from '@esgi/contracts/esgi';
import {useCallback, useEffect, useState} from 'react';
import {isAsyncPending, isAsyncSucceed, isUndefined, useCancelableRequest} from '@esgi/ui';
import {classesStore, studentsStore, Class, Student} from '@esgi/main/libs/school-admin-store';
import {dispatchAppEvent} from '@esgillc/events';
import {isNull} from 'underscore';
import {ClassCreatedEvent} from '../../events';
import {AddClassForm} from './form-data/types';

type NewClassDataModel = Omit<Class, 'id'> & {
	studentsIDs: Student['id'][];
};

type Parameters = {
	closeDrawer: VoidFunction;
	form: AddClassForm;
};

export function useSave({closeDrawer, form}: Parameters) {
	const currentUser = useUser();

	const classesController = useDisposable(V2SchoolAdminsClassesController);

	const [newClassDataModel, setNewClassDataModel] = useState<NewClassDataModel | null>(null);
	const [createClassData, createClass] = useCancelableRequest(classesController.create);

	useEffect(() => {
		if (isAsyncSucceed(createClassData) && !isNull(newClassDataModel)) {
			const classesStorage = classesStore();
			const studentsStorage = studentsStore();

			const newClassID = createClassData.data.classID;

			classesStorage.add({
				id: newClassID,
				schoolID: currentUser.schoolID,
				name: newClassDataModel.name,
				teacherID: newClassDataModel.teacherID,
			});

			studentsStorage.update((item) => {
				if (newClassDataModel.studentsIDs.includes(item.id)) {
					return {
						...item,
						classesIDs: [...item.classesIDs, newClassID],
					};
				}

				return item;
			});

			dispatchAppEvent(
				ClassCreatedEvent,
				new ClassCreatedEvent({
					id: newClassID,
					name: newClassDataModel.name,
				}),
			);

			closeDrawer();
		}
	}, [closeDrawer, createClassData, currentUser.schoolID, newClassDataModel]);

	const saveData = useCallback(() => {
		if (isNull(currentUser)) {
			return;
		}

		const {primaryTeacherID, className, studentsIDs} = form.value;
		const teacherID = primaryTeacherID[0];

		if (isUndefined(teacherID)) {
			throw new Error('teacherID is undefined');
		}

		const numberedTeacherID = Number(teacherID);

		createClass({
			teacherID: numberedTeacherID,
			name: className,
			globalSchoolYearID: currentUser.globalSchoolYearID,
			studentIDs: studentsIDs,
		});

		setNewClassDataModel({
			schoolID: currentUser.schoolID,
			name: className,
			studentsIDs,
			teacherID: numberedTeacherID,
		});
	}, [createClass, currentUser, form]);

	return {
		saveData,
		isDataSaving: isAsyncPending(createClassData),
	};
}
