import {GridBox} from '@esgi/ui/layout';
import {PropsWithChildren} from 'react';
import {Content, Title} from './index.styled';
import {Text} from '@esgi/ui/typography';

type Props = PropsWithChildren<{
	title: string;
}>;

export function ReportSection({title, children}: Props) {
	return (
		<GridBox gapY='4'>
			<Title>
				<Text size='small' font='mono' color='mediumContrast'>
					{title}
				</Text>
			</Title>

			<Content>{children}</Content>
		</GridBox>
	);
}
