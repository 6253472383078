import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Container} from './index.styled';

type Props = ComponentPropsWithoutRef<'div'> & BaseComponentProps;

export const PageHeaderRoot = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'district-admin-dashboard-page-header-root', css = {}, ...props}, forwardedRef) => (
		<Container dataCy={dataCy} css={css} ref={forwardedRef} {...props} />
	),
);
