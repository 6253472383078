import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {getThemeColor, ThemeColorKeys} from '@esgi/ui';
import {Property} from '@stitches/react/types/css';
import {FirstRowColumnAnimatedBox, FirstRowColumnBox} from './first-row-column-box';
import {useLineAnimation} from '../hooks/use-line-animation';

type Props = Omit<ComponentPropsWithoutRef<typeof FirstRowColumnBox>, 'children'> & {
	height: Property.Height;
	color?: ThemeColorKeys;
	borderRadius?: Property.BorderRadius;
};

export const BaseLine = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'bar-chart-base-line', css = {}, height, color = 'mild', borderRadius = 1, ...divProps}, forwardedRef) => {
		const animationProps = useLineAnimation({width: 100});

		return (
			<FirstRowColumnAnimatedBox
				data-cy={dataCy}
				style={animationProps}
				css={{
					height,
					backgroundColor: getThemeColor(color),
					borderRadius,
					...css,
				}}
				ref={forwardedRef}
				{...divProps}
			/>
		);
	},
);
