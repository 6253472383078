import {TestResultsAdditionalEntity, TestResultsStudent} from '@esgi/main/kits/admin';
import {useStudents} from '@esgi/main/libs/admin-store';
import {useMemo} from 'react';
import {RowDef} from '../../../../../../types';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {useTestResultsSubject} from '../../../../../../../_kit';
import {mapToEnum} from 'shared/utils';
import {UserType} from '@esgi/core/authentication';

type Parameters = {
	selectedRow: RowDef;
};

export function useTestResultsData({selectedRow}: Parameters) {
	const mappedUserType = mapToEnum(selectedRow.type.id, UserType);
	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const filteredStudents = useMemo(
		() =>
			students
				.filter(({specialistGroupsIDs}) => specialistGroupsIDs.includes(selectedRow.id))
				.map<TestResultsStudent>(({id, firstName, lastName, gradeLevelID}) => ({
					id,
					firstName,
					lastName,
					gradeLevelID,
				})),
		[selectedRow, students],
	);

	const {isReady: isTestResultsSubjectReady, subjects: testResultsSubjects} = useTestResultsSubject({
		userID: selectedRow.specialist.id,
		schoolID: selectedRow.school?.id,
		students: filteredStudents,
		additionalStoreSubjectsFilter: mappedUserType === UserType.PA ? null : 'userIDAsspecialists',
	});

	const hierarchy = useMemo<Hierarchy>(
		() => ({
			specialistGroupID: selectedRow.id,
			specialistsID: selectedRow.specialist.id,
		}),
		[selectedRow],
	);

	const additionalInfo = useMemo<TestResultsAdditionalEntity>(
		() => ({
			...(selectedRow.school && {
				school: {
					id: selectedRow.school.id,
					name: selectedRow.school.name,
				},
			}),
			specialistGroup: {
				id: selectedRow.id,
				name: selectedRow.name,
			},
		}),
		[selectedRow],
	);

	return {
		subjects: testResultsSubjects,
		students: filteredStudents,
		skeleton: !isTestResultsSubjectReady || !isStudentsLoaded,
		hierarchy,
		additionalInfo,
	};
}
