export function AdminCaptionIcon() {
	return (
		<svg width='31' height='7' viewBox='0 0 31 7' fill='none'>
			<path fill-rule='evenodd' clip-rule='evenodd'
			      d='M3.08189 0C2.80141 0 2.55304 0.0755868 2.33676 0.226761C2.12049 0.374648 1.96673 0.571831 1.87549 0.81831L0.0709646 5.76761C0.0236549 5.89249 0 6.0108 0 6.12253C0 6.36901 0.0861712 6.5777 0.258514 6.74859C0.434236 6.9162 0.648819 7 0.902264 7C1.11516 7 1.29933 6.94084 1.45477 6.82253C1.61022 6.70423 1.72005 6.54648 1.78425 6.3493L1.96673 5.79718H4.19705L4.37953 6.3493C4.44374 6.54648 4.55356 6.70423 4.70901 6.82253C4.86446 6.94084 5.04863 7 5.26152 7C5.51496 7 5.72786 6.9162 5.9002 6.74859C6.07592 6.5777 6.16378 6.36901 6.16378 6.12253C6.16378 6.01408 6.14013 5.89577 6.09282 5.76761L4.28829 0.81831C4.19705 0.571831 4.04329 0.374648 3.82702 0.226761C3.61075 0.0755868 3.36237 0 3.08189 0ZM2.42293 4.40704L3.08189 2.38592L3.74085 4.40704H2.42293Z'
			      fill='#333333'/>
			<path
				d='M17.0387 5.43239C17.3867 5.43239 17.6571 5.26479 17.8497 4.92958L18.6202 3.56901V6.15211C18.6202 6.38873 18.7047 6.5892 18.8736 6.75352C19.046 6.91784 19.2555 7 19.5022 7C19.7489 7 19.9567 6.91784 20.1256 6.75352C20.298 6.5892 20.3842 6.38873 20.3842 6.15211V0.847888C20.3842 0.611268 20.298 0.410798 20.1256 0.246479C19.9567 0.0821597 19.7489 0 19.5022 0C19.1507 0 18.8871 0.15446 18.7114 0.46338L17.0387 3.4507L15.3659 0.46338C15.1902 0.15446 14.9266 0 14.5752 0C14.3285 0 14.119 0.0821597 13.9467 0.246479C13.7777 0.410798 13.6932 0.611268 13.6932 0.847888V6.15211C13.6932 6.38873 13.7777 6.5892 13.9467 6.75352C14.119 6.91784 14.3285 7 14.5752 7C14.8219 7 15.0297 6.91784 15.1987 6.75352C15.371 6.5892 15.4572 6.38873 15.4572 6.15211V3.56901L16.2277 4.92958C16.4203 5.26479 16.6906 5.43239 17.0387 5.43239Z'
				fill='#333333'/>
			<path
				d='M23.8112 0.877465V6.12253C23.8112 6.36901 23.7233 6.5777 23.5476 6.74859C23.3753 6.9162 23.1624 7 22.9089 7C22.6555 7 22.4409 6.9162 22.2652 6.74859C22.0928 6.5777 22.0067 6.36901 22.0067 6.12253V0.877465C22.0067 0.630986 22.0928 0.423944 22.2652 0.256338C22.4409 0.0854461 22.6555 0 22.9089 0C23.1624 0 23.3753 0.0854461 23.5476 0.256338C23.7233 0.423944 23.8112 0.630986 23.8112 0.877465Z'
				fill='#333333'/>
			<path
				d='M27.1983 3.2831V6.15211C27.1983 6.38873 27.1122 6.5892 26.9398 6.75352C26.7708 6.91784 26.563 7 26.3163 7C26.0697 7 25.8601 6.91784 25.6878 6.75352C25.5188 6.5892 25.4343 6.38873 25.4343 6.15211V0.877465C25.4343 0.634272 25.5256 0.42723 25.7081 0.256338C25.8905 0.0854461 26.1102 0 26.367 0C26.7252 0 26.9989 0.15446 27.1882 0.46338L29.236 3.7169V0.847888C29.236 0.611268 29.3205 0.410798 29.4895 0.246479C29.6618 0.0821597 29.8713 0 30.118 0C30.3647 0 30.5725 0.0821597 30.7415 0.246479C30.9138 0.410798 31 0.611268 31 0.847888V6.12253C31 6.36573 30.9088 6.57277 30.7263 6.74366C30.5438 6.91455 30.3241 7 30.0673 7C29.7091 7 29.4354 6.84554 29.2462 6.53662L27.1983 3.2831Z'
				fill='#333333'/>
			<path fill-rule='evenodd' clip-rule='evenodd'
			      d='M9.64756 6.95051H8.05593C7.78221 6.95051 7.56762 6.87657 7.41218 6.72868C7.26011 6.57751 7.18408 6.36882 7.18408 6.10262V0.896991C7.18408 0.630794 7.26011 0.423751 7.41218 0.275864C7.56762 0.12469 7.78221 0.0491032 8.05593 0.0491032H9.64756C10.4856 0.0491032 11.1496 0.289009 11.6396 0.768822C12.1296 1.24535 12.3746 1.89934 12.3746 2.73079V4.26882C12.3746 5.10028 12.1296 5.75591 11.6396 6.23572C11.1496 6.71225 10.4856 6.95051 9.64756 6.95051ZM10.5701 4.15051V2.8491C10.5701 2.39558 10.4958 2.07187 10.3471 1.87798C10.2018 1.68408 9.96859 1.58713 9.64756 1.58713H8.98861V5.41248H9.64756C9.96859 5.41248 10.2018 5.31554 10.3471 5.12164C10.4958 4.92774 10.5701 4.60403 10.5701 4.15051Z'
			      fill='#333333'/>
		</svg>
	);
}