import {isNull} from 'underscore';
import {useCallback, useEffect} from 'react';
import {DatePickerData} from '@esgi/main/kits/admin';
import {ElementStatus, FormControl, FormGroup, useForm} from '@esgi/ui/form';
import {
	BoxplotSinglePeriodForm,
	BoxplotSinglePeriodFormColorItem,
	BoxplotTwoPeriodsForm,
	BoxplotTwoPeriodsFormColorItem,
	BoxplotTwoSchoolsForm,
	BoxplotTwoSchoolsFormColorItem,
	BoxplotTwoSchoolsFormSchoolIDItem,
	CommonForm,
	CommonFormDemographicGroupItem,
	PiechartsForm,
} from './types';
import {
	useInitialBoxplotSinglePeriodForm,
	useInitialBoxplotTwoPeriodsForm,
	useInitialBoxplotTwoSchoolsForm,
	useInitialCommonForm,
	useInitialPiechartsForm,
} from './use-initial-form-data';
import {setPeriodsValueForBoxplotTwoPeriodsForm} from './helpers/set-periods-value-for-boxplot-two-periods-form';
import {setSchoolValueForBoxplotTwoSchoolsForm} from './helpers/set-school-value-for-boxplot-two-schools-form';
import {DemographicPerformanceWidgetOptions} from '../../../../../types/demographic-performance';
import {setFormControlsValidStatus} from './helpers/set-form-controls-valid-status';
import {highlightValueValidation} from './validators';
import {BoxplotCompareVariant} from '../types';
import {highlightMaxValue} from '../constants';
import {datePickerDataForInitialization} from './constants';
import {PerformanceWidgetViewType, SchoolModel, Widget} from '@esgi/main/features/admins/dashboard';

export function useFormsInit({
	widgetOptions,
	allSchoolsList,
}: {
	widgetOptions: DemographicPerformanceWidgetOptions;
	allSchoolsList: SchoolModel[];
}) {
	const initialCommonForm = useInitialCommonForm({widgetOptions});
	const initialBoxplotSinglePeriodForm = useInitialBoxplotSinglePeriodForm({widgetOptions});
	const initialBoxplotTwoPeriodsForm = useInitialBoxplotTwoPeriodsForm({widgetOptions});
	const initialBoxplotTwoSchoolsForm = useInitialBoxplotTwoSchoolsForm({widgetOptions});

	const initialPiechartsForm = useInitialPiechartsForm({widgetOptions});

	const commonForm: CommonForm = useForm(
		() =>
			new FormGroup({
				viewType: new FormControl(widgetOptions.viewType),
				size: new FormControl(widgetOptions.viewSize),
				isShowAverageValue: new FormControl(widgetOptions.showAvg),
				demographicGroup: new FormControl<CommonFormDemographicGroupItem>([widgetOptions.demographicGroup]),
				boxplotCompareVariant: new FormControl<BoxplotCompareVariant>(BoxplotCompareVariant.None, {
					initialValidateStatus:
						widgetOptions.viewType === PerformanceWidgetViewType.Piechart
							? ElementStatus.disabled
							: ElementStatus.valid,
				}),
			}),
	);

	const boxplotSinglePeriodForm: BoxplotSinglePeriodForm = useForm(
		() =>
			new FormGroup({
				date: new FormControl<DatePickerData>(datePickerDataForInitialization),
				isHighlightCandles: new FormControl(false),
				highlightColor: new FormControl<BoxplotSinglePeriodFormColorItem>([]),
				highlightValue: new FormControl<number | ''>('', {
					validators: [highlightValueValidation(highlightMaxValue)],
				}),
			}),
	);

	const boxplotTwoPeriodsForm: BoxplotTwoPeriodsForm = useForm(
		() =>
			new FormGroup({
				firstPeriodColor: new FormControl<BoxplotTwoPeriodsFormColorItem>([null]),
				firstPeriodDate: new FormControl<DatePickerData>(datePickerDataForInitialization),
				secondPeriodColor: new FormControl<BoxplotTwoPeriodsFormColorItem>([null]),
				secondPeriodDate: new FormControl<DatePickerData>(datePickerDataForInitialization),
			}),
	);

	const boxplotTwoSchoolsForm: BoxplotTwoSchoolsForm = useForm(
		() =>
			new FormGroup({
				date: new FormControl<DatePickerData>(datePickerDataForInitialization),
				firstSchoolID: new FormControl<BoxplotTwoSchoolsFormSchoolIDItem>([null]),
				firstSchoolColor: new FormControl<BoxplotTwoSchoolsFormColorItem>([null]),
				secondSchoolID: new FormControl<BoxplotTwoSchoolsFormSchoolIDItem>([null]),
				secondSchoolColor: new FormControl<BoxplotTwoSchoolsFormColorItem>([null]),
			}),
	);

	const piechartsForm: PiechartsForm = useForm(
		() =>
			new FormGroup({
				date: new FormControl<DatePickerData>(datePickerDataForInitialization),
			}),
	);

	const setBoxplotSinglePeriodFormInitialData = useCallback(() => {
		setFormControlsValidStatus(boxplotSinglePeriodForm);

		const {date, isHighlightCandles, highlightColor, highlightValue} = boxplotSinglePeriodForm.controls;
		const {date: dateInitial, highlight} = initialBoxplotSinglePeriodForm;

		const isHighlighed = !isNull(highlight);
		const highlightDataStatus = isHighlighed ? ElementStatus.valid : ElementStatus.disabled;

		date.value = dateInitial;
		isHighlightCandles.value = isHighlighed;

		highlightColor.status = highlightDataStatus;
		highlightValue.status = highlightDataStatus;

		if (isHighlighed) {
			const {color, highlightIfAbove} = highlight;

			highlightColor.value = [color];
			highlightValue.value = highlightIfAbove;
		} else {
			highlightColor.value = [Widget.colorItems[0]?.value ?? null];
			highlightValue.value = 50;
		}
	}, [boxplotSinglePeriodForm, initialBoxplotSinglePeriodForm]);

	const setBoxplotTwoPeriodsFormInitialData = useCallback(() => {
		setFormControlsValidStatus(boxplotTwoPeriodsForm);

		const {firstPeriodColor, firstPeriodDate, secondPeriodColor, secondPeriodDate} = boxplotTwoPeriodsForm.controls;
		const {firstPeriod, secondPeriod} = initialBoxplotTwoPeriodsForm;

		setPeriodsValueForBoxplotTwoPeriodsForm({
			periodValue: firstPeriod,
			colorControl: firstPeriodColor,
			dateRangeControl: firstPeriodDate,
			defaultColorItemsIndex: 0,
		});

		setPeriodsValueForBoxplotTwoPeriodsForm({
			periodValue: secondPeriod,
			colorControl: secondPeriodColor,
			dateRangeControl: secondPeriodDate,
			defaultColorItemsIndex: 1,
		});
	}, [boxplotTwoPeriodsForm, initialBoxplotTwoPeriodsForm]);

	const setBoxplotTwoSchoolsFormInitialData = useCallback(() => {
		setFormControlsValidStatus(boxplotTwoSchoolsForm);

		const {date, firstSchoolColor, firstSchoolID, secondSchoolColor, secondSchoolID} = boxplotTwoSchoolsForm.controls;
		const {date: dateValue, firstSchool, secondSchool} = initialBoxplotTwoSchoolsForm;

		date.value = dateValue;

		setSchoolValueForBoxplotTwoSchoolsForm({
			schoolValue: firstSchool,
			schoolColorControl: firstSchoolColor,
			schoolIDControl: firstSchoolID,
			defaultItemsIndex: 0,
			allSchoolsList,
		});

		setSchoolValueForBoxplotTwoSchoolsForm({
			schoolValue: secondSchool,
			schoolColorControl: secondSchoolColor,
			schoolIDControl: secondSchoolID,
			defaultItemsIndex: 1,
			allSchoolsList,
		});
	}, [allSchoolsList, boxplotTwoSchoolsForm, initialBoxplotTwoSchoolsForm]);

	const setPiechartsFormInitialData = useCallback(() => {
		setFormControlsValidStatus(piechartsForm);

		const {date} = piechartsForm.controls;
		const {date: dateValue} = initialPiechartsForm;

		date.value = dateValue;
	}, [initialPiechartsForm, piechartsForm]);

	useEffect(() => {
		const {boxplotCompareVariant} = commonForm.controls;

		boxplotCompareVariant.value = initialCommonForm.boxplotCompareVariant;
	}, [commonForm.controls, initialCommonForm]);

	useEffect(setBoxplotSinglePeriodFormInitialData, [initialBoxplotSinglePeriodForm]);
	useEffect(setBoxplotTwoPeriodsFormInitialData, [initialBoxplotTwoPeriodsForm]);
	useEffect(setBoxplotTwoSchoolsFormInitialData, [initialBoxplotTwoSchoolsForm]);
	useEffect(setPiechartsFormInitialData, [initialPiechartsForm]);

	return {
		commonForm,
		boxplotSinglePeriodForm,
		boxplotTwoPeriodsForm,
		boxplotTwoSchoolsForm,
		piechartsForm,
		initialCommonForm,
		initialBoxplotSinglePeriodForm,
		initialBoxplotTwoPeriodsForm,
		initialBoxplotTwoSchoolsForm,
		setBoxplotSinglePeriodFormInitialData,
		setBoxplotTwoPeriodsFormInitialData,
		setBoxplotTwoSchoolsFormInitialData,
		setPiechartsFormInitialData,
	};
}
