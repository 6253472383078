import {styled} from '@esgi/ui/theme';
import {FirstRowColumnBox} from '../first-row-column-box';
import {animated} from 'react-spring';

export const Container = styled(FirstRowColumnBox, {
	width: '100%',
	position: 'relative',
});

export const ProgressBox = styled(animated.div, {
	position: 'relative',
	height: '100%',
});
