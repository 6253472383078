import {GridBox} from '@esgi/ui/layout';
import {ContentBox, IconBox} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {IconComponent} from '@esgi/ui';
import {More} from '@esgi/ui/icons';
import {JSX, Dispatch, useCallback, useMemo, useState} from 'react';
import {OneLinedText} from '@esgi/main/kits/common';
import {SelectableEntityDrawer} from './components/selectable-entity-drawer';
import {SelectableListItemModel} from './types';

type Props<T extends string> = {
	placeholder: string;
	sectionName: string;
	value: T[];
	onSaveSelectedEntity: Dispatch<T[]>;
	selectedValueTranscript: string;
	items: SelectableListItemModel<T>[];
	allItems: SelectableListItemModel<T>[];
	filterWidget: JSX.Element;
	EntityIcon: IconComponent;
} & (
	| {
			disabled: true;
			disabledMessage: string;
	  }
	| {
			disabled?: false;
	  }
);

export function SelectDrawerableFilterable<T extends string>({
	placeholder,
	sectionName,
	value,
	onSaveSelectedEntity,
	selectedValueTranscript,
	items,
	allItems,
	filterWidget,
	EntityIcon,
	...disabledData
}: Props<T>) {
	const isDisabled = disabledData.disabled;

	const [isOpenSelectableDrawer, setIsOpenSelectableDrawer] = useState(false);

	const openSelectableDrawer = useCallback(() => {
		if (isDisabled) {
			return;
		}

		setIsOpenSelectableDrawer(true);
	}, [isDisabled]);

	const onCloseSelectableDrawer = useCallback(() => {
		setIsOpenSelectableDrawer(false);
	}, []);

	const valueTranscript = useMemo(() => {
		if (isDisabled) {
			return disabledData.disabledMessage;
		}

		if (value.length === allItems.length) {
			return 'All';
		}

		if (value.length === 1) {
			return allItems.find((item) => item.value === value[0])?.label ?? '';
		}

		return `${value.length} ${selectedValueTranscript}`;
	}, [allItems, selectedValueTranscript, value, disabledData, isDisabled]);

	return (
		<>
			<ContentBox isDisabled={Boolean(isDisabled)} onClick={openSelectableDrawer}>
				<GridBox>
					<Text size='xSmall' font='mono' data-placeholder=''>
						{placeholder}
					</Text>
					<OneLinedText size='medium' bold data-transcript=''>
						{valueTranscript}
					</OneLinedText>
				</GridBox>

				{!isDisabled && (
					<IconBox>
						<More />
					</IconBox>
				)}
			</ContentBox>

			{isOpenSelectableDrawer && (
				<SelectableEntityDrawer
					EntityIcon={EntityIcon}
					items={items}
					allItems={allItems}
					filterWidget={filterWidget}
					sectionName={sectionName}
					onClose={onCloseSelectableDrawer}
					value={value}
					onSaveSelectedEntity={onSaveSelectedEntity}
				/>
			)}
		</>
	);
}
