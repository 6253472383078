import {Dispatch, PropsWithChildren, useCallback, useMemo, useState} from 'react';
import {TabsContext, TabsContextValue} from '../context';
import {TabInfo, UpdateTab} from '../types';
import {Skeletonable} from '@esgi/ui';

type Props<Tab extends string> = Skeletonable &
	PropsWithChildren<{
		activeTab: Tab | null;
		setActiveTab: Dispatch<Tab | null>;
	}>;

export function Root<Tab extends string>({activeTab, setActiveTab, children, skeleton}: Props<Tab>) {
	const [availableTabs, setAvailableTabs] = useState<TabInfo<Tab>[]>([]);

	const addTab = useCallback((newTab: TabInfo<Tab>) => {
		setAvailableTabs((currentTabs) => [...currentTabs, newTab]);
	}, []);

	const removeTab = useCallback((tabID: Tab) => {
		setAvailableTabs((currentTabs) => currentTabs.filter((tab) => tab.tabID !== tabID));
	}, []);

	const updateTab = useCallback<UpdateTab<Tab>>(({tabID, data}) => {
		setAvailableTabs((currentTabs) =>
			currentTabs.map((currentTab) => (currentTab.tabID === tabID ? {...currentTab, ...data} : currentTab)),
		);
	}, []);

	const context = useMemo<TabsContextValue<Tab>>(
		() => ({
			activeTab,
			setActiveTab,
			addTab,
			removeTab,
			updateTab,
			availableTabs,
			skeleton: Boolean(skeleton),
		}),
		[activeTab, addTab, availableTabs, removeTab, setActiveTab, skeleton, updateTab],
	);

	return <TabsContext.Provider value={context}>{children}</TabsContext.Provider>;
}
