import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';

export const Item = styled(GridBox, {
	minWidth: 80,
	minHeight: 54,
	gap: 6,
	justifyItems: 'center',
	cursor: 'pointer',
	paddingTop: 6,
	paddingBottom: 6,
	transition: 'border-radius .3s, background-color .3s',

	'& > svg': {
		width: 24,
		height: 24,

		'& *': {
			transition: 'fill .3s',
			fill: '$base',
		},
	},

	[`& > ${Text}`]: {
		transition: 'color .3s',
		color: '$base',
	},

	'&:hover': {
		borderRadius: 8,
		backgroundColor: '$secondaryBackground',

		'& > svg': {
			'& *': {
				fill: '$secondary',
			},
		},

		[`& > ${Text}`]: {
			color: '$secondary',
		},
	},

	variants: {
		isActive: {
			true: {
				'& > svg': {
					'& *': {
						fill: '$primary',
					},
				},

				[`& > ${Text}`]: {
					color: '$primary',
				},
			},
		},
	},
});
