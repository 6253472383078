import {Page} from '@esgi/main/features/admins/dashboard';
import {styled} from '@esgi/ui/theme';
import {cardsGap} from './constants';
import {Box} from '@esgi/ui/layout';
import {widgetCardMinWidth} from '../constants';
import {GridStackItemContent as GridStackItemContentBase} from '@esgi/ui/gridstack';

const pageBodyPaddingInline = Page.Body.paddingInline - cardsGap;

export const PageBody = styled(Page.Body, {
	minWidth: 2 * (widgetCardMinWidth + 2 * cardsGap) + 2 * pageBodyPaddingInline,
	marginTop: -cardsGap,
	paddingInline: pageBodyPaddingInline,
	paddingBottom: Page.Body.paddingBottom - cardsGap,

	'& .grid-stack-placeholder': {
		display: 'none',
	},
});

export const GridStackContainer = styled(Box, {
	width: '100%',
});

export const GridStackItem = styled(Box, {
	userSelect: 'none',
});

export const GridStackItemContent = styled(GridStackItemContentBase, {
	opacity: 'unset !important',
	boxShadow: 'unset !important',

	'& > div:first-of-type': {
		display: 'block',
		height: '100%',
	},

	variants: {
		isRearrangeMode: {
			true: {
				'& > div:first-of-type': {
					pointerEvents: 'none',
				},
			},
		},
	},
});
