import {ComponentPropsWithoutRef, Dispatch, forwardRef, useMemo} from 'react';
import {BaseComponentProps} from '@esgi/ui';
import {Content} from './components/content';
import {Widget} from '../../types/widget';
import {
	LowestAchievementMetricData,
	LowestAchievementWidgetOptions,
	LowestAchievementWidgetUpdatedValue,
	LowestAchievementWidgetViewType,
} from '../../types/lowest-achievement';
import {ViewTableInfo, ViewTableType} from './types';
import {useViewTableInfo} from './hooks/use-view-table-info';
import {schoolsViewDataPageSize, studentsViewDataPageSize} from './constants';
import {TrackModel} from '@esgi/main/kits/admin';
import {
	ContentAreaModel,
	DeployedSubject,
	GradeLevel,
	SchoolModel,
	WidgetCard,
	WidgetMode,
} from '@esgi/main/features/admins/dashboard';

type Props = BaseComponentProps &
	Omit<ComponentPropsWithoutRef<'div'>, 'id'> &
	Pick<Widget, 'id' | 'name'> & {
		widgetData: LowestAchievementMetricData | 'dataCounting';
		widgetOptions: LowestAchievementWidgetOptions;
		subjects: DeployedSubject[];
		maxPossibleCardWidth: number;
		allSchoolsList: SchoolModel[];
		onDeleteWidgetByID: Dispatch<Widget['id']>;
		onUpdateWidget: Dispatch<LowestAchievementWidgetUpdatedValue>;
		defaultWidgetMode?: WidgetMode;
		viewModeChanged: Dispatch<{id: Widget['id']; mode: WidgetMode}>;
		gradeLevels: GradeLevel[];
		currentTrack: TrackModel | null;
		allContentAreas: ContentAreaModel[];
		lastUpdatedTime: string;
		rootPageContainer: Element | DocumentFragment | null;
	};

export const LowestAchievement = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'district-admin-lowest-achievement-widget-view-edit',
			css = {},
			id,
			name,
			widgetData,
			widgetOptions,
			subjects,
			maxPossibleCardWidth,
			allSchoolsList,
			onDeleteWidgetByID,
			onUpdateWidget,
			defaultWidgetMode,
			viewModeChanged,
			gradeLevels,
			currentTrack,
			allContentAreas,
			lastUpdatedTime,
			rootPageContainer,
			...componentRootProps
		},
		forwardedRef,
	) => {
		const isDataCounting = widgetData === 'dataCounting';

		const {studentsOnePeriodInfo, studentsTwoPeriodsInfo, schoolsInfo} = useViewTableInfo({
			widgetData: isDataCounting ? null : widgetData,
			allSchoolsList,
			gradeLevels,
		});

		const {items, tableInfo, dataPageSize} = useMemo(() => {
			if (widgetOptions.viewType === LowestAchievementWidgetViewType.Students && widgetOptions.periods.length === 1) {
				const tableInfo: ViewTableInfo = {
					type: ViewTableType.StudentsOnePeriod,
					disabledSortableKeys: studentsOnePeriodInfo.disabledSortableKeys,
					sortableState: studentsOnePeriodInfo.sortableState,
					setSortableState: studentsOnePeriodInfo.setSortableState,
				};

				return {
					tableInfo,
					items: studentsOnePeriodInfo.items,
					dataPageSize: studentsViewDataPageSize,
				};
			}

			if (widgetOptions.viewType === LowestAchievementWidgetViewType.Students && widgetOptions.periods.length === 2) {
				const tableInfo: ViewTableInfo = {
					type: ViewTableType.StudentsTwoPeriods,
					disabledSortableKeys: studentsTwoPeriodsInfo.disabledSortableKeys,
					sortableState: studentsTwoPeriodsInfo.sortableState,
					setSortableState: studentsTwoPeriodsInfo.setSortableState,
				};

				return {
					tableInfo,
					items: studentsTwoPeriodsInfo.items,
					dataPageSize: studentsViewDataPageSize,
				};
			}

			if (widgetOptions.viewType === LowestAchievementWidgetViewType.Schools) {
				const tableInfo: ViewTableInfo = {
					type: ViewTableType.Schools,
					disabledSortableKeys: schoolsInfo.disabledSortableKeys,
					sortableState: schoolsInfo.sortableState,
					setSortableState: schoolsInfo.setSortableState,
				};

				return {
					tableInfo,
					items: schoolsInfo.items,
					dataPageSize: schoolsViewDataPageSize,
				};
			}

			throw new Error('view type is not correspond with existed variants');
		}, [schoolsInfo, studentsOnePeriodInfo, studentsTwoPeriodsInfo, widgetOptions]);

		return (
			<WidgetCard
				dataCy={dataCy}
				css={css}
				ref={forwardedRef}
				id={id}
				name={name}
				maxPossibleCardWidth={maxPossibleCardWidth}
				widgetData={items}
				dataPageSize={dataPageSize}
				defaultWidgetMode={defaultWidgetMode}
				viewModeChanged={viewModeChanged}
				isDataСounting={isDataCounting}
				currentTrack={currentTrack}
				allContentAreas={allContentAreas}
				lastUpdatedTime={lastUpdatedTime}
				{...componentRootProps}
			>
				<Content
					widgetOptions={widgetOptions}
					allSchoolsList={allSchoolsList}
					onDeleteWidgetByID={onDeleteWidgetByID}
					subjects={subjects}
					tableInfo={tableInfo}
					onUpdateWidget={onUpdateWidget}
					gradeLevels={gradeLevels}
					rootPageContainer={rootPageContainer}
				/>
			</WidgetCard>
		);
	},
);
