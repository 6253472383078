import {useCallback} from 'react';
import {Box} from '@esgi/ui/layout';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {FormGroup, useFormElementContext} from '@esgi/ui/form';
import {DatePickerWidget, useDatePickerWidget} from '@esgi/main/kits/admin';

interface Props {
	form: FormGroup<any, any>;
	onTouchedChange?: (isTouched: boolean) => void;
}

export function DatePickerForm({form, onTouchedChange}: Props) {
	const {value, setRef, setValue} = useFormElementContext();

	const {state, handleChange, isTouched} = useDatePickerWidget({value, onChanged: setValue});

	const handleValidate = useCallback(() => {
		onTouchedChange?.(isTouched);
	}, [isTouched, onTouchedChange]);

	useStreamEffect(form.onChanged, handleValidate);

	return (
		<Box ref={setRef}>
			<DatePickerWidget
				datePeriod={state.datePeriod}
				dateRange={state.dateRange}
				trackDatesID={state.trackDatesID}
				onChange={handleChange}
			/>
		</Box>
	);
}
