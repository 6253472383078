import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {cartesianGridBoxHeight, cartesianGridItemsGap} from '../../constants';

export const CartesianGridBox = styled(GridBox, {
	gridRowStart: 1,
	gridColumn: '2 / auto',
	gap: cartesianGridItemsGap,
	position: 'relative',
});

export const CartesianGridLineBox = styled(GridBox, {
	height: cartesianGridBoxHeight,
	alignItems: 'center',

	variants: {
		variant: {
			default: {},

			valued: {
				width: '100%',
				position: 'absolute',
			},
		},

		hidden: {
			true: {
				opacity: 0,
			},
		},
	},
});
