import {useCallback, useEffect} from 'react';
import {useStreamEffect} from '@esgillc/ui-kit/utils';
import {DatePickerSingleForm} from '../forms-data/types';
import {DynamicFormProps} from '../types';
import {InputsBox} from '../index.styled';
import {DatePickerForm, WidgetCard} from '@esgi/main/features/admins/dashboard';

type Props = DynamicFormProps<{
	form: DatePickerSingleForm;
}>;

export function SchoolsData({
	form,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
	onCurrentDynamicFormNameChange,
}: Props) {
	const handleValidate = useCallback(() => {
		form.validate(true).subscribe(({valid}) => {
			setIsDynamicFormDataValid(valid);
		});
	}, [form, setIsDynamicFormDataValid]);

	useEffect(() => {
		handleValidate();
		onCurrentDynamicFormNameChange('schools');
	}, []);

	useStreamEffect(form.onChanged, handleValidate);

	return (
		<InputsBox>
			<WidgetCard.EditMode.FormElement control={form.controls.date}>
				<DatePickerForm form={form} onTouchedChange={setIsDynamicFormDataTouched} />
			</WidgetCard.EditMode.FormElement>
		</InputsBox>
	);
}
