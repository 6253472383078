import React, {Dispatch, useCallback, useEffect, useMemo, useState} from 'react';
import {format} from 'date-fns';
import {isUndefined} from '@esgi/ui';
import {enumRemap} from 'shared/utils';
import {DatePickerCalendarPanel} from './index.styled';
import {DatePeriod, DatePickerData, PeriodType, TrackModel} from '../../../types';
import {DatePicker} from '@esgi/ui/controls';
import {isNull} from 'underscore';
import {SelectOption} from '../select-option';

type Props = {
	dateRange: DatePeriod | null;
	onChange: Dispatch<DatePickerData>;
	setSelectValue: Dispatch<string>;
	closeSelectContent: VoidFunction;
	currentTrack: TrackModel | null;
};

export const DatePickerTabContent = ({
	dateRange,
	onChange,
	setSelectValue,
	closeSelectContent,
	currentTrack,
}: Props) => {
	const [isSetManualType, setIsSetManualType] = useState(false);

	const [value, setValue] = useState<Date[]>(
		isNull(dateRange) ? [] : [new Date(dateRange.dateFrom), new Date(dateRange.dateTo)],
	);

	const handleValueChange = useCallback(([dateFrom, dateTo]: Date[]) => {
		if (isUndefined(dateFrom) || isUndefined(dateTo)) {
			return;
		}

		setValue([dateFrom, dateTo]);

		onChange({
			datePeriod: PeriodType.Manual,
			dateRange: {
				dateFrom: String(dateFrom),
				dateTo: String(dateTo),
			},
			trackDatesID: 0,
		});

		setIsSetManualType(true);
	}, []);

	useEffect(() => {
		if (isSetManualType) {
			setSelectValue(enumRemap(PeriodType.Manual, PeriodType));

			setIsSetManualType(false);
			closeSelectContent();
		}
	}, [isSetManualType]);

	const {minDate, maxDate} = useMemo<{minDate: Date | undefined; maxDate: Date | undefined}>(() => {
		if (isNull(currentTrack)) {
			return {
				minDate: undefined,
				maxDate: undefined,
			};
		}

		const allDatesTimes = currentTrack.trackDates.flatMap(({dateFrom, dateTo}) => [
			new Date(dateFrom).getTime(),
			new Date(dateTo).getTime(),
		]);

		const minDate = new Date(Math.min(...allDatesTimes));
		const maxDate = new Date(Math.max(...allDatesTimes));

		return {
			minDate,
			maxDate,
		};
	}, [currentTrack]);

	return (
		<>
			{value && !isUndefined(value[0]) && !isUndefined(value[1]) && (
				<SelectOption
					value={enumRemap(PeriodType.Manual, PeriodType)}
					label={`${format(value[0], 'MM/dd/yyyy')}-${format(value[1], 'MM/dd/yyyy')}`}
					hidden
				/>
			)}

			<DatePicker.Root
				value={value}
				onChange={handleValueChange}
				rangeMode
				minCalendarDate={minDate}
				maxCalendarDate={maxDate}
			>
				<DatePickerCalendarPanel />
			</DatePicker.Root>
		</>
	);
};
