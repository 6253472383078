import {useUser} from '@esgi/core/authentication';
import {useDisposable} from '@esgi/core/service';
import {V2DistrictAdminsClassesController} from '@esgi/contracts/esgi';
import {useCallback, useEffect} from 'react';
import {isAsyncPending, isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {Class} from '@esgi/main/libs/admin-store';
import {dispatchAppEvent} from '@esgillc/events';
import {isNull} from 'underscore';
import {ClassDeletedEvent} from '../../../../events';
import {useRemoveClassInStore} from '@esgi/main/features/admins/utils';

type Parameters = {
	className: string;
	classID: Class['id'];
	onRemoved: VoidFunction;
};

export function useRemove({onRemoved, classID, className}: Parameters) {
	const currentUser = useUser();

	const classesController = useDisposable(V2DistrictAdminsClassesController);
	const [removeClassData, removeClass] = useCancelableRequest(classesController.remove);

	const removeClassInStore = useRemoveClassInStore();

	useEffect(() => {
		if (isAsyncSucceed(removeClassData)) {
			removeClassInStore(classID, () => {
				dispatchAppEvent(
					ClassDeletedEvent,
					new ClassDeletedEvent({
						id: classID,
						name: className,
					}),
				);

				onRemoved();
			});
		}
	}, [classID, className, onRemoved, removeClassData, removeClassInStore]);

	const onRemoveClass = useCallback(() => {
		if (isNull(currentUser)) {
			return;
		}

		removeClass({
			classID,
			globalSchoolYearID: currentUser.globalSchoolYearID,
		});
	}, [currentUser, removeClass, classID]);

	return {
		onRemoveClass,
		isClassRemoving: isAsyncPending(removeClassData),
	};
}
