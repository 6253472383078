import {CustomLoadingOverlayProps} from 'ag-grid-react';
import {useEffect, useState} from 'react';
import {isNull} from 'underscore';
import {isUndefined} from '@esgi/ui/utils';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {SkeletonShape} from './index.styled';

type Props = CustomLoadingOverlayProps & {
	cellHorizontalPadding: number;
	withoutFirstColumnPadding: boolean;
	withoutLastColumnPadding: boolean;
	headerHeight: number;
	rowHeight: number;
};

export function Skeleton({
	api,
	cellHorizontalPadding,
	withoutFirstColumnPadding,
	withoutLastColumnPadding,
	headerHeight,
	rowHeight,
}: Props) {
	const [columnsWidth, setColumnsWidth] = useState<number[]>([]);

	useEffect(() => {
		const handler = () => {
			const columnState = api.getColumnState() || [];

			const columnsWidth = columnState
				.map(({width}, index) => {
					if (isUndefined(width)) {
						return null;
					}

					if (
						(index === 0 && withoutFirstColumnPadding) ||
						(index === columnState.length - 1 && withoutLastColumnPadding)
					) {
						return width - cellHorizontalPadding;
					}

					return width - 2 * cellHorizontalPadding;
				})
				.filter((item) => !isNull(item));

			setColumnsWidth(columnsWidth);
		};

		api.addEventListener('displayedColumnsChanged', handler);

		return () => {
			api.addEventListener('displayedColumnsChanged', handler);
		};
	}, [api, cellHorizontalPadding, withoutFirstColumnPadding, withoutLastColumnPadding]);

	return (
		<GridBox
			css={{
				height: `calc(100% - ${headerHeight}px)`,
				marginTop: headerHeight,
				paddingLeft: withoutFirstColumnPadding ? undefined : cellHorizontalPadding,
				paddingRight: withoutLastColumnPadding ? undefined : cellHorizontalPadding,
			}}
		>
			{new Array(api.paginationGetPageSize()).fill(null).map((_, index) => (
				<FlexBox
					align='center'
					css={{
						gap: '$3',
						height: rowHeight,
					}}
					key={index}
				>
					{columnsWidth.map((width, index) => (
						<SkeletonShape css={{width}} key={`${width}-${index}`} />
					))}
				</FlexBox>
			))}
		</GridBox>
	);
}
