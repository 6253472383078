import {FlexBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Counter = styled(FlexBox, {
	paddingTop: 2,
	paddingBottom: 2,
	paddingLeft: 4,
	paddingRight: 4,
	backgroundColor: '$surface',
	color: '$mediumContrast',
	userSelect: 'none',
	appearance: 'none',
	borderRadius: 4,
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: '$border',
	transition: 'color .3s, background-color .3s, border-color .3s',
});
