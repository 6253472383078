import {styled} from '@esgi/ui/theme';
import {SelectableList} from '@esgi/ui/layout';
import {Drawer} from '@esgi/main/kits/common';
import {Button} from '@esgi/ui';

export const DrawerBody = styled(Drawer.Body, {
	display: 'grid !important',
	gridTemplateRows: 'auto 1fr',
	gap: '$3',
	overflow: 'hidden',
});

export const SelectAllButton = styled(Button, {
	justifySelf: 'start',
	minWidth: 'unset',
});

export const SelectableListGroup = styled(SelectableList.Group, {
	display: 'grid',
	gridTemplateColumns: '1fr 1fr',
	gap: '$2',
});

export const SelectableItem = styled(SelectableList.Item, {
	display: 'inline-grid',
	paddingTop: 9,
	paddingBottom: 9,
});
