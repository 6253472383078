import {AnalyticsData, StudentResult, StudentsResult, Test, TestSession} from '../../types/test-panel';
import {CardHeader} from './components/card-header';
import {CardBody} from './components/card-body';
import {Tooltip} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Fragment, Suspense, useCallback, useMemo, useState} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {isNull} from 'underscore';
import {SubjectType as SubjectTypeNumerable, TestType as TestTypeNumerable} from '@esgi/core/enums';
import {StudentNotTestedDialog} from './components/student-not-tested-dialog';
import {SelectedStudent} from '../../types/section';
import {useHierarchy} from './use-hierarchy';
import {SchoolSubject, SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {TestCard} from '@esgi/main/kits/test-results-panel';
import {mapToEnum} from 'shared/utils';
import {AddSessionDetails} from '@esgi/main/features/test-session-details';
import {OnMoveToTest} from './types';
import {Box} from '@esgi/ui/layout';
import {useUser} from '@esgi/core/authentication';

type Props = {
	testInfo: Test;
	lastTestSessions: TestSession[];
	analyticsData: AnalyticsData | null;
	studentResult: StudentResult | null;
	studentsResult: StudentsResult | null;
	selectedSubject: SubjectModel;
	selectedStudent: SelectedStudent;
	schoolSubjects: SchoolSubject[];
	onMoveToTest: OnMoveToTest;
	onRemoveTest: VoidFunction;
};

const TestHistoryModal = lazyComponent(() => import('modules/reports/test-history'));

export function ExpandedTestCard({
	testInfo,
	lastTestSessions,
	analyticsData,
	studentResult,
	studentsResult,
	selectedSubject,
	selectedStudent,
	schoolSubjects,
	onMoveToTest,
	onRemoveTest,
}: Props) {
	const currentUser = useUser();

	const hierarchy = useHierarchy(selectedStudent);

	const [showTestHistory, setShowTestHistory] = useState(false);
	const [showStudentNotTestedDialog, setShowStudentNotTestedDialog] = useState(false);
	const [showAddSessionDialog, setShowAddSessionDialog] = useState(false);

	const canShowTestHistoryDialog = !isNull(hierarchy);
	const hasTestSessions = Boolean(lastTestSessions.length);
	const isTestCardClicable = canShowTestHistoryDialog || !hasTestSessions;

	const userCanAddTestSession = useMemo(() => {
		if (isNull(currentUser)) {
			return false;
		}

		return currentUser.canAddEditTestSessions;
	}, [currentUser]);

	const handleCardClick = () => {
		if (!hasTestSessions) {
			setShowStudentNotTestedDialog(true);

			return;
		}

		if (!canShowTestHistoryDialog) {
			return;
		}

		setShowTestHistory(true);
	};

	const closeStudentNotTestedDialog = useCallback(() => {
		setShowStudentNotTestedDialog(false);
	}, []);

	const openAddSessionDialog = useCallback(() => {
		if (userCanAddTestSession) {
			setShowAddSessionDialog(true);
		}
	}, [userCanAddTestSession]);

	const closeAddSessionDialog = useCallback(() => {
		setShowAddSessionDialog(false);
	}, []);

	const closeTestHistory = useCallback(() => {
		setShowTestHistory(false);
	}, []);

	return (
		<>
			<Tooltip open={!isTestCardClicable ? false : undefined}>
				<Tooltip.Trigger>
					<TestCard.Root
						contentArea={testInfo.contentArea}
						testColor={testInfo.color}
						testType={testInfo.type}
						onClick={handleCardClick}
						isCardClicable={isTestCardClicable}
					>
						<TestCard.TestIndicator />

						<CardHeader
							testInfo={testInfo}
							testSessions={lastTestSessions}
							selectedSubject={selectedSubject}
							selectedStudent={selectedStudent}
							schoolSubjects={schoolSubjects}
							onMoveToTest={onMoveToTest}
							onRemoveTest={onRemoveTest}
							userCanAddTestSession={userCanAddTestSession}
							openAddSessionDialog={openAddSessionDialog}
						/>

						<CardBody
							analyticsData={analyticsData}
							testType={testInfo.type}
							totalPossible={testInfo.totalPossible}
							testSessions={lastTestSessions}
							rubricCriteria={testInfo.rubricCriteria}
							studentResult={studentResult}
							studentsResult={studentsResult}
						/>
					</TestCard.Root>
				</Tooltip.Trigger>
				<Tooltip.Content side='top'>
					<Text size='large' color='tertiary'>
						Show Test History
					</Text>
				</Tooltip.Content>
			</Tooltip>

			<Box
				onClick={(event) => event.stopPropagation()}
				css={{
					width: 0,
					height: 0,
					position: 'absolute',
				}}
			>
				{showStudentNotTestedDialog && (
					<StudentNotTestedDialog
						avatarUrl={selectedStudent.avatarUrl}
						firstName={selectedStudent.firstName}
						lastName={selectedStudent.lastName}
						testColor={testInfo.color}
						testContentArea={testInfo.contentArea}
						testName={testInfo.name}
						testType={testInfo.type}
						onClose={closeStudentNotTestedDialog}
						userCanAddTestSession={userCanAddTestSession}
						onAddSessionClick={openAddSessionDialog}
					/>
				)}

				{showAddSessionDialog && (
					<AddSessionDetails
						student={{
							id: selectedStudent.id,
							firstName: selectedStudent.firstName,
							lastName: selectedStudent.lastName,
							photoUrl: selectedStudent.avatarUrl,
						}}
						test={{
							id: testInfo.id,
							contentArea: testInfo.contentArea,
							name: testInfo.name,
							type: testInfo.type,
							color: testInfo.color,
							maxScore: testInfo.totalPossible,
						}}
						onAlertClose={closeAddSessionDialog}
					/>
				)}

				{showTestHistory && canShowTestHistoryDialog && (
					<Suspense fallback={<Fragment />}>
						<TestHistoryModal
							hierarchy={hierarchy}
							subjectID={selectedSubject.id}
							subjectType={mapToEnum(selectedSubject.type, SubjectTypeNumerable)}
							testId={testInfo.id}
							testType={mapToEnum(testInfo.type, TestTypeNumerable)}
							onClose={closeTestHistory}
						/>
					</Suspense>
				)}
			</Box>
		</>
	);
}
