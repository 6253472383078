import {EntityType} from './types';
import {V2DistrictAdminsActionPanelController} from '@esgi/contracts/esgi';

export const entityControllerMap: Record<
	EntityType,
	Exclude<keyof V2DistrictAdminsActionPanelController, 'dispose'>
> = {
	[EntityType.Class]: 'classesDelete',
	[EntityType.Group]: 'groupsDelete',
	[EntityType.Student]: 'studentsDelete',
};
