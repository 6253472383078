import {Text} from '@esgi/ui/typography';
import {cartesianGridBoxHeight, cartesianGridValues} from '../../constants';
import {CartesianGridValueBox, CartesianGridValuesBox} from './index.styled';
import {HoveredCandleData} from '../../types';
import {isNull} from 'underscore';
import {ObjectEntries} from '../../../../../types';

type Props = {
	hoveredCandleData: HoveredCandleData | null;
};

export function CartesianGridValues({hoveredCandleData}: Props) {
	const showHoveredValue = !isNull(hoveredCandleData);

	return (
		<CartesianGridValuesBox>
			{cartesianGridValues.map((value, index) => (
				<CartesianGridValueBox variant='default' hidden={showHoveredValue} key={index}>
					<Text size='xSmall' font='mono' color='mediumContrast'>
						{value}
					</Text>
				</CartesianGridValueBox>
			))}

			{showHoveredValue &&
				(Object.entries(hoveredCandleData) as ObjectEntries<HoveredCandleData>).map(([key, value]) => {
					if (key === 'periodIndex' || key === 'itemCellIndex') {
						return null;
					}

					return (
						<CartesianGridValueBox
							variant='valued'
							css={{
								bottom: `calc(${value}% - ${(cartesianGridBoxHeight * value) / 100}px)`,
							}}
							key={`${key}-${value}`}
						>
							<Text size='xSmall' font='mono' color='secondary'>
								{String(value).padStart(2, '0')}%
							</Text>
						</CartesianGridValueBox>
					);
				})}
		</CartesianGridValuesBox>
	);
}
