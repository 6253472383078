import {styled} from '@esgi/ui/theme';
import {FlexBox} from '@esgi/ui/layout';
import {OneLinedText} from '@esgi/main/kits/common';

export const Footer = styled(FlexBox, {
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingTop: 2,
	paddingBottom: 2,
	color: '$base',

	'& > svg': {
		width: 24,
		height: 24,
		fill: 'currentColor',

		'& path': {
			fill: 'currentColor',
		},
	},
});

export const Body = styled(FlexBox, {
	width: 130,
	height: '100%',
	gap: 16,
	justifyContent: 'space-between',
	flexDirection: 'column',
	paddingTop: 12,
	paddingInline: 12,
	paddingBottom: 4,
	borderRadius: 8,
	cursor: 'pointer',
	color: '$highContrast',
	backgroundColor: '$background',
	borderStyle: 'solid',
	borderWidth: 1,
	borderColor: 'transparent',
	transition: 'background-color .3s, border-color .3s, color .3s, box-shadow .3s',

	'&:hover': {
		backgroundColor: '$secondaryBackground',
		borderColor: '$secondaryMuted',
		boxShadow: '0px 4px 12px 0px rgba(230, 219, 238, 0.40), 0px 4px 12px 0px rgba(0, 0, 0, 0.06)',
		color: '$secondary',

		[`& ${Footer}`]: {
			color: '$secondary',
		},
	},
});
