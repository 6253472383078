import {isNull} from 'underscore';
import {useCallback, useEffect, useState} from 'react';
import {TestCard} from '../types/test-panel';
import {useEventEffect} from '@esgillc/events';
import {useDisposable} from '@esgi/core/service';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {TestContentArea, TestType} from '@esgi/main/kits/common';
import {useStudents} from '@esgi/main/libs/school-admin-store';
import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {V2SchoolAdminsTestCardsController, V2PersonalSubjectsController} from '@esgi/contracts/esgi';
import {TestSessionRubricCreatedEvent, TestSessionScoreCreatedEvent, TestSessionUpdated, TestSessionYNCreatedEvent} from '@esgi/main/features/test-session-details/events';
import {OnMoveTestToSubject, OnRemoveTest, SelectedStudent} from '../types/section';

export function useTests({selectedSubject, student}: {
	selectedSubject: SubjectModel | null;
	student: SelectedStudent;
}) {
	const controller = useDisposable(V2SchoolAdminsTestCardsController);
	const personalSubjectsController = useDisposable(V2PersonalSubjectsController);

	const [{data: students, loaded: isStudentsLoaded}] = useStudents();

	const [testsCardsData, fetchTestCards] = useCancelableRequest(controller.byStudent);
	const [, removeTestFromSubject] = useCancelableRequest(personalSubjectsController.testsRemove);
	const [, moveTestToSubjectRequest] = useCancelableRequest(personalSubjectsController.testsMove);

	const [testCards, setTestCards] = useState<TestCard[]>([]);

	const loadTestCards = useCallback(() => {
		if (!isNull(selectedSubject) && isStudentsLoaded) {
			const studentsIDs: number[] = [];

			if (student.classIDs.length === 1) {
				students.reduce((currentValue, {classesIDs, id}) => {
					const classID: number = student.classIDs[0]!;

					if (classesIDs.includes(classID)) {
						currentValue.push(id);
					}

					return currentValue;
				}, studentsIDs);
			}

			if (student.classIDs.length >= 2) {
				const classes = student.classIDs.reduce((currentClasses, classID) => {
					currentClasses[classID] = [];

					return currentClasses;
				}, {} as Record<number, number[]>);

				students.forEach(({classesIDs, id}) => {
					classesIDs.forEach((classID) => {
						if (classes[classID]) {
							const studentsIDs = classes[classID];

							classes[classID] = [...studentsIDs, id];
						}
					});
				});

				const maxCountStudents = Object.values(classes).reduce(
					(currentResult, iteratedStudentsIDs) =>
						iteratedStudentsIDs.length > currentResult.length ? iteratedStudentsIDs : currentResult,
					[],
				);

				studentsIDs.push(...maxCountStudents);
			}

			fetchTestCards({
				studentID: student.id,
				studentsIDs,
				subjectID: selectedSubject.id,

				//@ts-ignore
				subjectType: selectedSubject.type,
			});
		}
	}, [fetchTestCards, isStudentsLoaded, selectedSubject, student.classIDs, student.id, students]);

	useEffect(() => {
		loadTestCards();
	}, [isStudentsLoaded, selectedSubject, students]);

	useEventEffect(TestSessionUpdated, loadTestCards);
	useEventEffect(TestSessionYNCreatedEvent, loadTestCards);
	useEventEffect(TestSessionScoreCreatedEvent, loadTestCards);
	useEventEffect(TestSessionRubricCreatedEvent, loadTestCards);

	const isTestsLoaded = isAsyncSucceed(testsCardsData);

	useEffect(() => {
		if (isTestsLoaded) {
			setTestCards(
				testsCardsData.data.testCards.map<TestCard>((testCard) => ({
					...testCard,
					testInfo: {
						...testCard.testInfo,
						contentArea: testCard.testInfo.contentArea as TestContentArea,
						type: testCard.testInfo.type as unknown as TestType,
					},
				})),
			);
		}
	}, [isTestsLoaded, testsCardsData]);

	const removeTestByID = useCallback<OnRemoveTest>(
		({testID, subjectID, subjectType}) => {
			setTestCards((currentState) => currentState.filter(({testInfo: {id}}) => id !== testID));
			removeTestFromSubject({
				subjectID,
				//@ts-ignore
				subjectType,
				testID,
			});
		},
		[removeTestFromSubject],
	);

	const moveTestToSubject = useCallback<OnMoveTestToSubject>(
		({previousSubjectID, newSubjectID, testID, subjectType}) => {
			setTestCards((currentState) => currentState.filter(({testInfo: {id}}) => id !== testID));
			moveTestToSubjectRequest({
				sourceSubjectID: previousSubjectID,
				destinationSubjectID: newSubjectID,
				testID,
				//@ts-ignore
				subjectType,
				orderNumber: -1,
			});
		},
		[moveTestToSubjectRequest],
	);

	return {
		isTestsLoaded,
		testCards,
		removeTestByID,
		moveTestToSubject,
	};
}
