import {useState} from 'react';
import {useStreamEffect} from '@esgi/ui';
import {FormControl} from '@esgi/ui/form';
import {DatePickerSingleForm, StudentsTwoPeriodsForm, StudentsTwoPeriodsInitialFormData} from '../../forms-data/types';
import {DynamicFormProps, PeriodsVariant} from '../../types';
import {SinglePeriod} from './components/single-period';
import {TwoPeriods} from './components/two-periods';
import {InputsBox} from '../../index.styled';

type Props = DynamicFormProps<{
	periodsVariantControl: FormControl<PeriodsVariant>;
	studentsSinglePeriodForm: DatePickerSingleForm;
	studentsTwoPeriodsForm: StudentsTwoPeriodsForm;
	initialStudentsTwoPeriodsForm: StudentsTwoPeriodsInitialFormData;
}>;

export function StudentsData({
	periodsVariantControl,
	studentsSinglePeriodForm,
	studentsTwoPeriodsForm,
	initialStudentsTwoPeriodsForm,
	onCurrentDynamicFormNameChange,
	setIsDynamicFormDataTouched,
	setIsDynamicFormDataValid,
}: Props) {
	const [periodsVariant, setPeriodsVariant] = useState(periodsVariantControl.value);

	useStreamEffect(periodsVariantControl.onChanged, ({currState: {value}}) => {
		setPeriodsVariant(value);
	});

	return (
		<InputsBox>
			{periodsVariant === PeriodsVariant.SinglePeriod && (
				<SinglePeriod
					form={studentsSinglePeriodForm}
					onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
					setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
					setIsDynamicFormDataValid={setIsDynamicFormDataValid}
				/>
			)}
			{periodsVariant === PeriodsVariant.TwoPeriods && (
				<TwoPeriods
					form={studentsTwoPeriodsForm}
					initialForm={initialStudentsTwoPeriodsForm}
					onCurrentDynamicFormNameChange={onCurrentDynamicFormNameChange}
					setIsDynamicFormDataTouched={setIsDynamicFormDataTouched}
					setIsDynamicFormDataValid={setIsDynamicFormDataValid}
				/>
			)}
		</InputsBox>
	);
}
