import React, {useCallback, useMemo} from 'react';
import {SortBy, SortDirection, Sorting, Test} from '../../service/types';
import {Body} from './components/body';
import {Header} from './components/header';
import {NoTests} from './components/no-tests';
import {Container} from './index.styled';

type Props = {
	tests: Test[],
	selectedTests: Test[],
	loaded: boolean,
	toggleAll: VoidFunction,
	sorting: Sorting,
	disabledSorting: boolean;
	setSorting: React.Dispatch<Sorting>
	onTestSelect: (test: Test) => void,
	keyword: string,
}

export function Table({
	tests,
	loaded,
	selectedTests,
	onTestSelect,
	toggleAll,
	sorting,
	disabledSorting,
	setSorting,
	keyword,
}: Props) {
	const onCellClicked = useCallback((by: SortBy) => {
		if (by === sorting.by) {
			setSorting({
				by,
				direction: sorting.direction === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc,
			});
			return;
		}

		setSorting({by, direction: SortDirection.Asc});
	}, [sorting, setSorting]);

	const allSelected = useMemo(() => {
		const testIDs = tests?.map(s => s.id);
		const selectedTestIDs = selectedTests?.map((item) => item.id);

		return testIDs.every(s => selectedTestIDs?.includes(s));
	}, [selectedTests, tests]);

	return (
		<Container>
			<Header
				sorting={sorting}
        allSelected={allSelected}
        onSelectAllClicked={toggleAll}
        onCellClicked={onCellClicked}
				disabled={disabledSorting || Boolean(keyword)}
			/>
			{loaded && !tests.length && <NoTests/>}
			<Body
				loaded={loaded}
				tests={tests}
				selectedTests={selectedTests}
				onTestSelect={onTestSelect}
			/>
		</Container>
	);
}