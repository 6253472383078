import * as React from 'react';
import type {SVGProps} from 'react';

export function StudentProgress(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 24 24'
			{...props}
		>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M8.16678 6C6.51011 6 5.16678 7.34333 5.16678 9C5.16678 10.6567 6.51011 12 8.16678 12C9.82345 12 11.1668 10.6567 11.1668 9C11.1668 7.34333 9.82345 6 8.16678 6ZM8.16678 7.33333C9.08745 7.33333 9.83345 8.07933 9.83345 9C9.83345 9.92067 9.08745 10.6667 8.16678 10.6667C7.24611 10.6667 6.50011 9.92067 6.50011 9C6.50011 8.07933 7.24611 7.33333 8.16678 7.33333Z'
				fill='#333333'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M5.79134 12.896C4.56867 13.4007 3.75 14.6373 3.75 16V17.3333C3.75 17.7013 4.04867 18 4.41667 18H11.75C12.118 18 12.4167 17.7013 12.4167 17.3333V16C12.4167 14.6693 11.5933 13.4627 10.3753 12.9373C10.2033 12.8633 10.004 12.8613 9.83333 12.9373C9.24333 13.202 8.66867 13.3333 8.08333 13.3333C7.5 13.3333 6.92333 13.1947 6.31266 12.9167C6.14466 12.84 5.96201 12.8253 5.79134 12.896ZM6.04799 14.26C6.67799 14.4987 7.43733 14.6667 8.08333 14.6667C8.724 14.6667 9.452 14.51 10.0693 14.282C10.6713 14.6247 11.0833 15.266 11.0833 16V16.6667H5.08333V16C5.08333 15.2233 5.52066 14.5687 6.04799 14.26Z'
				fill='#333333'
			/>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M13.8865 10.7307C14.3864 10.1594 15.2652 10.13 15.8021 10.6667L16.9203 11.9543C16.9963 12.0302 17.1204 12.027 17.1922 11.9471L19.8313 9.05253C20.0379 8.81661 20.3976 8.79524 20.6307 9.00504C20.8594 9.21085 20.8802 9.56222 20.6775 9.79367L18.0284 12.6997C17.5255 13.2586 16.6568 13.2815 16.125 12.75L15.0068 11.4624C14.9301 11.3857 14.8045 11.3899 14.7331 11.4715L13.5 12.9373C13.2954 13.1711 12.9401 13.1948 12.7063 12.9903C12.4725 12.7857 12.4488 12.4303 12.6533 12.1965L13.8865 10.7307Z'
				fill='#333333'
			/>
		</svg>
	);
}