import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import React from 'react';
import {Observable, Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {
	Form,
	FormControl,
	FormGroup,
	Validators,
} from '@esgillc/ui-kit/form';
import {AutoSize} from '@esgillc/ui-kit/core';
import SubjectDetails from 'modules/subject-details';
import {CompleteStepInfo, SubjectModel} from '../../../../../common/types';
import RubricCreatingService from '../../../../services/rubric-creating-service';
import ContentAreaField from './components/content-area-field';
import DescriptionField from './components/description-field';
import GradeField from './components/grade-field';
import NameField from './components/name-field';
import ShareField from './components/share-field';
import StateStandardField from './components/state-standard-field';
import SubjectField from './components/subject-field';
import {Field} from './type';
import styles from './confirm.module.less';

class State {
	showAddSubjectTab = false;
	completeStepInfo: CompleteStepInfo;
}

interface Props {
	canAddToSubject: boolean;
	testDataService: RubricCreatingService;
	maxWidth: number;
	maxHeight: number;

	maxFormWidth?: number;
	disableNewSubjectBtn?: boolean;
}

export default class Complete extends React.PureComponent<Props, State> {
	private onDestroy$: Subject<void> = new Subject();
	public state = new State();

	private get testInfo() {
		return this.props.testDataService.testInfo$.value;
	}

	public componentDidMount() {
		this.form.onChanged
			.pipe(takeUntil(this.onDestroy$), filter(c => c.reason === 'value'))
			.subscribe(c => {
				const {name, description, grade, subject, share, contentArea} = c.currState.value;
				const testInfo = this.props.testDataService.testInfo$.value;
				this.props.testDataService.updateTestInfo({
					...testInfo,
					name,
					description,
					shared: share,
					gradeLevelIDs: grade.map(g => g.id),
					contentAreaID: contentArea[0]?.id,
				});
				this.props.testDataService.selectedSubjects$.next(subject);
			});
		this.props.testDataService.completeStepInfo$
			.pipe(takeUntil(this.onDestroy$))
			.subscribe((info) => this.setState({completeStepInfo: info}));
	}

	public validate(): Observable<boolean> {
		return this.form.validate().pipe(map(v => v.valid));
	}

	private form = new FormGroup({
		name: new FormControl<string>('', {validators: [Validators.required()]}),
		description: new FormControl<string>('', {validators: [Validators.required()]}),
		subject: new FormControl<SubjectModel[]>([]),
		contentArea: new FormControl<Field>([]),
		grade: new FormControl<Field>([]),
		share: new FormControl(false),
	});

	public render() {
		return <AutoSize maxWidth={this.props.maxWidth}
		                 maxHeight={this.props.maxHeight}>
			{bounds => <OverlayScrollbarsComponent style={{
				maxWidth: this.props.maxWidth + 20,
				height: bounds.height,
			}}>
				<div className={styles.body}>
					{this.renderForm()}
					{this.renderAddSubjectTab()}
				</div>
			</OverlayScrollbarsComponent>}
		</AutoSize>;
	}

	private renderForm() {
		if(this.state.completeStepInfo) {
			return <Form controller={this.form} className={styles.form} style={{maxWidth: this.props.maxFormWidth || 'auto'}} onSubmit={(e) => {
				e.preventDefault();
				e.stopPropagation();
			}}>
				<div className={styles.container}>
					<NameField control={this.form.controls.name}
					           initialValue={this.testInfo.name}/>
					<DescriptionField control={this.form.controls.description}
					                  initialValue={this.testInfo.description}/>

				</div>
				<div className={styles.additionalFields}>
					<ContentAreaField control={this.form.controls.contentArea}
					                  onColorChanged={(color) => this.props.testDataService.updateTestInfo({color: color})}
					                  contentAreas={this.state.completeStepInfo.contentAreas}
					                  initialValue={this.testInfo.contentAreaID}
					                  initialColor={this.testInfo.color}/>
					<GradeField control={this.form.controls.grade}
					            initialValue={this.testInfo.gradeLevelIDs}/>
					{this.props.testDataService.testInfo$.value.stateStandard && <StateStandardField rubricService={this.props.testDataService}
					                    contentAreaControl={this.form.controls.contentArea}/>}
					{this.state.completeStepInfo.canShare && <ShareField control={this.form.controls.share}
					                                                     initialValue={this.testInfo.shared}/>}
					{this.props.canAddToSubject && <SubjectField control={this.form.controls.subject}
					                                             subjects={this.state.completeStepInfo.subjects}
					                                             initialValue={this.props.testDataService.selectedSubjects$.value}
					                                             addSubjectClicked={() => this.setState({showAddSubjectTab: true})}
																											 disableNewSubjectBtn={this.props.disableNewSubjectBtn}/>}
				</div>
			</Form>;
		}
	}

	private renderAddSubjectTab() {
		if (this.state.showAddSubjectTab) {
			return <SubjectDetails onClosed={() => this.setState({showAddSubjectTab: false})}
			                       onSaved={(id, name, type) => this.onSubjectAdded(id, name, type)}/>;
		}
	}

	private onSubjectAdded(id, name, type) {
		const subject = {
			id: id,
			name: name,
			type: type,
		};

		const control = this.form.controls.subject;
		const value = control.value;
		control.value = [...value, subject];
		this.setState({showAddSubjectTab: false});
	}

	public componentWillUnmount() {
		this.onDestroy$.next();
	}
}
