import {SchoolDemographicPerformanceWidgetOptions} from '../types';
import {isNull} from 'underscore';
import {
	ColorNumerable,
	DatePeriod,
	DemographicGroupNumerable,
	PerformanceWidgetViewTypeNumerable,
	PeriodTypeNumerable,
	WidgetViewSizeNumerable,
} from '@esgi/main/features/admins/dashboard';

export function getSchoolDemographicPerformanceInitOptions({
	districtID,
	schoolID,
	globalSchoolYearID,
	dateRange,
}: {
	districtID: number;
	schoolID: number;
	globalSchoolYearID: number;
	dateRange: DatePeriod | null;
}): SchoolDemographicPerformanceWidgetOptions {
	return {
		districtID,
		schoolID,
		globalSchoolYearID,
		demographicGroup: DemographicGroupNumerable.RaceEthnicity,
		showAvg: false,
		viewType: PerformanceWidgetViewTypeNumerable.Candles,
		viewSize: WidgetViewSizeNumerable.Default,
		candles: {
			color: ColorNumerable.Green,
			highlightIfAbove: 50,
		},
		periods: [
			{
				color: ColorNumerable.None,
				datePeriod: isNull(dateRange) ? PeriodTypeNumerable.CurrentMarkingPeriod : PeriodTypeNumerable.Manual,
				dateRange: dateRange as unknown as DatePeriod,
				trackDatesID: 0,
			},
		],
		classes: [],
		classIDs: [],
		contentAreaIDs: [],
		subjectIDs: [],
		gradeLevelIDs: [],
	};
}
