import {EditMode} from './components/edit-mode';
import {Root} from './components/root';
import {ViewMode} from './components/view-mode';
import {chartColor} from './constants';
import {useWidgetCardContext} from './context';
import {DatePeriod} from './components/date-period';
import {ChartCandle} from './components/chart-candles';
import {ChartPiechart} from './components/chart-piechart';
import {formRowGap} from './components/edit-mode/index.styled';
import {widgetCardPaddingVertical} from './components/root/constants';
import {FormElement} from './components/edit-mode/components/form-element';
import {PeriodColorBox} from './components/edit-mode/components/period-color-box.styled';
import {SelectedContentAreasInfo} from './components/selected-content-areas-info';
import {AverageHint} from './components/edit-mode/components/average-hint';

export const WidgetCard = Object.assign(Root, {
	ViewMode: Object.assign(ViewMode, {
		DatePeriod,
		SelectedContentAreasInfo,
		Chart: {
			PieChart: ChartPiechart,
			Candle: ChartCandle,
		},
	}),
	EditMode: Object.assign(EditMode, {
		FormElement,
		PeriodColorBox,
		formRowGap,
		AverageHint,
	}),
	useWidgetCardContext,
	chartColor,
	widgetCardPaddingVertical,
});

export * from './types';
export {type PieChartItem} from './components/chart-piechart/types';
export {type ChartCandleItem} from './components/chart-candles/types';
export {type OnUpdateWidget} from './components/edit-mode/types';
export {widgetCardHeight} from './components/root/constants';
