import {Checkbox as CheckboxUi} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const Checkbox = styled(CheckboxUi, {
	'& > div': {
		minWidth: 'unset',
		width: 16,
		height: 16,
		borderColor: '$primaryHighlight',
	},

	'& > div:has(> span[data-state=checked])': {
		backgroundColor: '$primaryBackground',
	},
});
