import {Text} from '@esgi/ui/typography';
import {ArrowsIcon, SortableCell} from './index.styled';
import {SortDirection} from '../../../../types';
import {Dispatch} from 'react';
import {Property} from '@stitches/react/types/css';

type Props<T extends string> = {
	cellKey: T;
	cellName: string;
	isActive: boolean;
	sortDirection: SortDirection;
	onCellClick: Dispatch<{cellKey: T; nextSortDirection: SortDirection}>;
	disableSorting: boolean;
	justifyContent?: Property.JustifyContent | undefined;
};

export * from './types';

export function TableHeaderCellSortable<T extends string>({
	cellName,
	sortDirection,
	isActive,
	cellKey,
	onCellClick,
	disableSorting,
	justifyContent,
}: Props<T>) {
	const handleCellClick = () => {
		onCellClick({
			cellKey,
			nextSortDirection: sortDirection === SortDirection.Desc || !isActive ? SortDirection.Asc : SortDirection.Desc,
		});
	};

	if (disableSorting) {
		return (
			<SortableCell
				css={{
					justifyContent,
				}}
			>
				<Text size='small' color='mediumContrast'>
					{cellName}
				</Text>
			</SortableCell>
		);
	}

	return (
		<SortableCell
			isActive={isActive}
			sortDirection={sortDirection}
			onClick={handleCellClick}
			sortable
			css={{
				justifyContent,
			}}
		>
			<Text size='small'>{cellName}</Text>
			<ArrowsIcon />
		</SortableCell>
	);
}
