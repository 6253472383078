import {Text} from '@esgi/ui/typography';
import {AlertHeader, ClassNameBox} from './index.styled';
import {Entity} from '../../types';
import {isNull} from 'underscore';
import {OneLinedText} from '@esgi/main/kits/common';

type Props = {
	closeAlert: VoidFunction;
	entity: Entity | null;
};

export function Header({closeAlert, entity}: Props) {
	return (
		<AlertHeader withBacklight={false} onCloseIconClick={closeAlert}>
			<Text size='small'>Add Students</Text>

			{!isNull(entity) && (
				<ClassNameBox>
					<entity.Icon />
					<OneLinedText size='small' color='highContrast'>
						{entity.name}
					</OneLinedText>
				</ClassNameBox>
			)}
		</AlertHeader>
	);
}
