import {SettingPanel} from '@esgi/main/features/admins/subject-management';
import {FlexBox, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {PanelContainer} from '../../../../components/panels.styled';
import {Tooltip} from '@esgi/ui';

export const Container = styled(PanelContainer, {
	borderRightStyle: 'solid',
	borderRightWidth: 1,
	borderRightColor: '$border',
});

export const TitleBox = styled(FlexBox, {
	gap: '$2',
	alignItems: 'center',
	justifyContent: 'space-between',
});

export const MainContentBox = styled(GridBox, {
	gap: 20,
});

export const MainSettingsBox = styled(GridBox, {
	gap: 20,
});

export const CheckboxAndNameBox = styled(GridBox, {
	alignItems: 'center',
	gridTemplateColumns: 'auto 1fr',
	gap: '$3',
});

export const PublishOptionsSection = styled(SettingPanel.SectionBlock, {
	'& [data-children-box]': {
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: '$3',
	},
});

export const TooltipContent = styled(Tooltip.Content, {
	maxWidth: 250,
});
