import {useMemo} from 'react';
import {Hierarchy} from '@esgi/main/features/admins/utils';
import {RowDef} from '../types';
import {NoStudentsAlert, ReportDialog, Reports} from '../../_kit';
import {useSpecialistGroups} from '@esgi/main/libs/admin-store';
import {UserType} from '@esgi/core/authentication';

type Props = {
	specialist: RowDef;
	onClose: VoidFunction;
};

export function ReportsFeature({specialist, onClose}: Props) {
	const [{data: specialistGroup}] = useSpecialistGroups();

	const firstGroup = useMemo(
		() => specialistGroup.find((s) => s.specialistID === specialist.id),
		[specialist, specialistGroup],
	);

	const hierarchy = useMemo<Hierarchy>(() => {
		const specialistType = specialist.type.id;

		if (specialistType === UserType.PA) {
			return {
				preAssessID: specialist.id,
				preAssessGroupID: firstGroup?.id,
			};
		}

		const isSpecislistType = specialistType === UserType.ISS || specialistType === UserType.ISD;

		return {
			schoolID: specialist.schools[0]?.id,
			specialistsID: specialist.id,
			specialistGroupID: firstGroup?.id,
			...(isSpecislistType && {
				specialistType,
			}),
		};
	}, [specialist, firstGroup]);

	if (!specialist.students) {
		return <NoStudentsAlert onClose={onClose} prefix={`${specialist.firstName} ${specialist.lastName} group`} />;
	}

	return (
		<ReportDialog onClose={onClose} header={`${specialist.firstName} ${specialist.lastName}`}>
			<ReportDialog.Section title='Specialist Reports'>
				<Reports.ItemAnalysis hierarchy={hierarchy} description='Analyze data based on least and most known items' />
				<Reports.PieCharts hierarchy={hierarchy} description='Access a printable report of homepage pie charts' />
				<Reports.UntestedStudents
					hierarchy={hierarchy}
					description='Identify untested students to administer assessments'
				/>
			</ReportDialog.Section>
		</ReportDialog>
	);
}
