import {getFullName} from '@esgi/main/kits/common';
import {Text} from '@esgi/ui/typography';

type Props = {
	isEditMode: boolean;
	firstName: string;
	lastName: string;
};

export function HeaderTitle({isEditMode, firstName, lastName}: Props) {
	return (
		<>
			<Text size='small' color='primary' data-cy='fullname'>
				{getFullName({
					firstName,
					lastName,
				})}
			</Text>
			<Text size='small' color='base' data-cy='session-details-title'>
				Session Details
			</Text>
			{isEditMode && (
				<Text size='small' color='primary' data-cy='edit-mode-title'>
					Edit Mode
				</Text>
			)}
		</>
	);
}
