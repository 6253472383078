import {useMemo, useRef, useState} from 'react';
import {Text} from '@esgi/ui/typography';
import {CreateTrackRequest, SchoolYearModel, TrackModel} from '../../../../../../../../../types';
import {PotentialDataImpactAlert} from '../../../../../../../components/potential-data-impact';
import {PanelHeaderTitle} from '../../../../../../../components/panel-header-title';
import {defaultNewMarkingPeriodName, defaultTrackType} from './constants';
import {Transcript} from '../../../components/components.styled';
import {DrawerPanelHeader} from '../../../../../../index.styled';
import {CheckboxStyled, SetActiveBox} from './index.styled';
import {AddEditModeContent} from '../add-edit-mode-content';
import {AddEditModeContentRef, Mode} from '../../types';

type Props = {
	schoolYearTypes: SchoolYearModel[] | null;
	createTrackRequest: CreateTrackRequest;
	setActiveTrackId: (trackId: TrackModel['trackID']) => void;
	setVisibleTrack: (track: TrackModel) => void;
};

export function AddMode({schoolYearTypes, createTrackRequest, setVisibleTrack, setActiveTrackId}: Props) {
	const [isPotentialDataImpactOpen, setIsPotentialDataImpactOpen] = useState(false);

	const [isDataCreating, setIsDataCreating] = useState(false);
	const [isMarkingPeriodValid, setIsMarkingPeriodValid] = useState(true);

	const {defaultTrackDates} = useMemo(() => {
		const defaultSchoolYearType = schoolYearTypes?.find(({name}) => name === defaultTrackType) ?? schoolYearTypes?.[0];

		return {
			defaultTrackDates: defaultSchoolYearType?.defaultTrackDates,
		};
	}, [schoolYearTypes]);

	const [asDefault, setAsDefault] = useState<boolean | 'indeterminate'>(true);

	const addEditModeContentRef = useRef<AddEditModeContentRef>();

	const handleCreateTrack = () => {
		const contentData = addEditModeContentRef.current?.getContentData();

		if (!contentData) {
			return;
		}

		const {datesInRowsState, markingPeriodName, markingPeriodType} = contentData;

		setIsDataCreating(true);

		createTrackRequest({
			trackDates: datesInRowsState,
			trackName: markingPeriodName,
			trackType: markingPeriodType,
		}).subscribe({
			next: (createdTrack) => {
				setVisibleTrack({
					...createdTrack,
					schoolYearType: markingPeriodType,
				});

				if (asDefault) {
					setActiveTrackId(createdTrack.trackID);
				}

				setIsDataCreating(false);
			},
		});
	};

	const onCreateButtonClick = () => {
		if (asDefault) {
			setIsPotentialDataImpactOpen(true);
			return;
		}

		handleCreateTrack();
	};

	return (
		<>
			<DrawerPanelHeader
				withActionButton
				actionButtonText='Create'
				onActionButtonClick={onCreateButtonClick}
				actionButtonDisabled={!isMarkingPeriodValid || isDataCreating}
			>
				<PanelHeaderTitle title={defaultNewMarkingPeriodName} titleColor='primary' />
			</DrawerPanelHeader>

			<AddEditModeContent
				mode={Mode.Add}
				defaultMarkingPeriodName={defaultNewMarkingPeriodName}
				defaultMarkingPeriodType={defaultTrackType}
				defaultTrackDates={defaultTrackDates ?? []}
				schoolYearTypes={schoolYearTypes}
				setIsMarkingPeriodValid={setIsMarkingPeriodValid}
				addEditModeContentRef={addEditModeContentRef}
			>
				<>
					<SetActiveBox>
						<CheckboxStyled checked={asDefault} label='Set As Active' onCheckedChange={setAsDefault} />
					</SetActiveBox>
					<Transcript>
						<Text size='small' color='base'>
							Setting a Marking Period as active only affects your account and allows for easy date range selection while configuring widgets on the Dashboard page.
						</Text>
					</Transcript>
				</>
			</AddEditModeContent>

			{isPotentialDataImpactOpen && (
				<PotentialDataImpactAlert onClose={() => setIsPotentialDataImpactOpen(false)} onProceed={handleCreateTrack} />
			)}
		</>
	);
}
