import {PeriodType} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/period-type';
import {type DatePeriod} from '@esgi/contracts/esgi/types/sso.accounts/dashboards/widgets/date-period';

export {type TrackModel} from '@esgi/contracts/esgi/types/esgi.schools/queries/esgi.apigateway/profiles/tracks/track-model';
export {type SchoolYearModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/home/school-year-selector/init/school-year-model';
export {type DatesPeriodModel} from '@esgi/contracts/esgi/types/esgi.schools/queries/esgi.apigateway/profiles/tracks/dates-period-model';
export {PeriodType, type DatePeriod};

export enum SelectTabs {
	Past = 'Past',
	Current = 'Current',
	DatePicker = 'DatePicker',
}

export type SchoolYear = {
	globalSchoolYearID: number;
	name: string;
};

export type DatePickerData = {
	dateRange: DatePeriod | null;
	datePeriod: PeriodType;
	trackDatesID: number;
};

export type SelectOptionModel = {
	value: string;
	label: string;
};
