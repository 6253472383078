import {BaseComponentProps} from '@esgi/ui';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {Divider} from './index.styled';

type Props = BaseComponentProps & Omit<ComponentPropsWithoutRef<'div'>, 'children'>;

export const ActionButtonsDivider = forwardRef<HTMLDivElement, Props>(
	({dataCy = 'test-card-action-buttons-divider', css = {}, ...props}, forwardedRef) => (
		<Divider dataCy={dataCy} css={css} ref={forwardedRef} {...props} />
	),
);
