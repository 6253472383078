import {BehaviorSubject, filter, firstValueFrom, map, Observable, skip, Subscription, tap} from 'rxjs';

export class BucketApi<T extends object> {
	private valueStream: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);
	private initialized: boolean = false;
	private loading: boolean = false;
	private sub: Subscription | null = null;

	constructor(private endpoint: () => Observable<T>) {

	}

	public async fetch<Key extends keyof T>(key: Key, invalidateCache: boolean): Promise<T[Key]> {
		if (!this.loading && this.initialized && this.valueStream.value && !invalidateCache) {
			return this.valueStream.value[key];
		}

		if (invalidateCache || !this.initialized) {
			this.initialized = false;
			this.sub?.unsubscribe?.();
			this.innerFetch();
		}

		return firstValueFrom(this.valueStream.pipe(skip(1), filter(Boolean), map(v => v[key])));

	}

	private innerFetch() {
		this.loading = true;
		this.sub = this.endpoint()
			.pipe(
				tap((res) => this.valueStream.next(res)),
				tap((_) => this.loading = false),
				tap((_) => this.initialized = true),
			).subscribe();
	}
}