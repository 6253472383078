export const cartesianGridValues = new Array(11)
	.fill(null)
	.map((_, index) => `${String(100 - index * 10).padStart(2, '0')}%`);

export const cartesianGridBoxHeight = 12;
export const cartesianGridItemsGap = 6;

export const chartCellWidth = 43;
export const chartCellsGap = 12;

const candleChartTailsHeight = 10;

export const candleChartHeight = 200;

export const tailChartValuePercentage =
	(candleChartTailsHeight / (candleChartHeight - 2 * candleChartTailsHeight)) * 100;
