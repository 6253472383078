import {Select} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const Option = styled(Select.Option, {
	variants: {
		displayDefaultLabel: {
			true: {
				display: 'flex',
				justifyContent: 'space-between',

				'&::after': {
					content: 'Default',
					color: '$primaryVivid',
				},
			},
		},
	},
});
