import {Edit} from '@esgi/ui/icons';
import {ActionPanel, DeleteButton, RunReportButton, SingleSelectedExpandablePanel} from '../../../../../../../_kit';
import {RowDef} from '../../../../../../types';
import {Button, Portal, useFlag} from '@esgi/ui';
import {RemoveSpecialistGroup, SpecialistGroupDrawer, useNavigateToStudentsPage} from '@esgi/main/features/admins/data';
import {AdditionalHeaderContent, AdditionalInfo, HeaderContent, InfoBox} from './index.styled';
import {useDataPageContext} from '../../../../../../../layout/context';
import {getFullName, OneLinedText} from '@esgi/main/kits/common';
import {TitleWithTranscription} from '../title-with-transcription';
import {ReportsFeature} from '../../reports';
import {useCallback} from 'react';
import {OpenPanelButton} from './components/open-panel-button';

type Props = {
	selectedRow: RowDef;
	isPanelExpanded: boolean;
	deselectedAllItems: VoidFunction;
};

export function QuickPanel({selectedRow, isPanelExpanded, deselectedAllItems}: Props) {
	const removeSpecialistGroupManagerRef = RemoveSpecialistGroup.useManagerRef();
	const {rootPageContentContainerRef} = useDataPageContext();

	const [showReports, openReports, closeReports] = useFlag();
	const [showSpecialistGroupDrawer, openSpecialistGroupDrawer, closeSpecialistGroupDrawer] = useFlag();

	const goToStudentsPage = useNavigateToStudentsPage();

	const navigateToStudents = useCallback(() => {
		goToStudentsPage({
			specialistGroups: [selectedRow.id],
		});
	}, [goToStudentsPage, selectedRow]);

	const runRemoveItem = useCallback(() => {
		removeSpecialistGroupManagerRef.current?.runRemove({specialistGroupID: selectedRow.id});
	}, [removeSpecialistGroupManagerRef, selectedRow]);

	return (
		<>
			<SingleSelectedExpandablePanel.QuickPanel>
				<SingleSelectedExpandablePanel.QuickPanel.Header>
					<HeaderContent>
						<InfoBox>
							<OneLinedText size='large' color='highContrast'>
								{selectedRow.name}
							</OneLinedText>

							<AdditionalInfo>
								<TitleWithTranscription
									title='Specialist'
									transcript={getFullName({
										firstName: selectedRow.specialist.firstName,
										lastName: selectedRow.specialist.lastName,
									})}
								/>
								<TitleWithTranscription title='School' transcript={selectedRow.school?.name ?? '-'} />
							</AdditionalInfo>
						</InfoBox>

						<ActionPanel.ActionButtonsBox>
							<DeleteButton onClick={runRemoveItem} />
							<ActionPanel.ActionButtonDivider />

							<Button.Icon onClick={openSpecialistGroupDrawer}>
								<Edit />
							</Button.Icon>

							<RunReportButton onClick={openReports} />
						</ActionPanel.ActionButtonsBox>
					</HeaderContent>
				</SingleSelectedExpandablePanel.QuickPanel.Header>

				{!isPanelExpanded && (
					<AdditionalHeaderContent>
						<ActionPanel.Navigation.Container>
							<ActionPanel.Navigation.Card onClick={navigateToStudents} title='Students' count={selectedRow.students} />
						</ActionPanel.Navigation.Container>

						<OpenPanelButton canExpandPanel={!!selectedRow.students} />
					</AdditionalHeaderContent>
				)}
			</SingleSelectedExpandablePanel.QuickPanel>

			{showSpecialistGroupDrawer && (
				<Portal container={rootPageContentContainerRef.current}>
					<SpecialistGroupDrawer.Edit onClose={closeSpecialistGroupDrawer} specialistGroupID={selectedRow.id} />
				</Portal>
			)}

			<RemoveSpecialistGroup managerRef={removeSpecialistGroupManagerRef} onRemoved={deselectedAllItems} />

			{showReports && <ReportsFeature onClose={closeReports} group={selectedRow} />}
		</>
	);
}
