import {isNull} from 'underscore';
import {BehaviorSubject, map, Observable, of, tap} from 'rxjs';
import {isUndefined} from '@esgi/ui';
import {BaseService} from '@esgi/core/service';
import {
	Widget,
	DemographicPerformanceWidgetUpdatedValue,
	SchoolPerformanceWidgetUpdatedValue,
	SchoolStudentsNeedingSupportWidgetUpdatedValue,
	AddWidgetEventOptions,
	WidgetsMetricData,
	DashboardMetricData,
	ClassModel,
	SubjectTab,
	TeacherModel,
	SchoolStudentsNeedingSupportWidgetViewTypeNumerable,
} from '@esgi/main/features/school-admin/dashboard';
import {
	V2ContentAreasController,
	V2SchoolAdminsPagesDashboardController,
	V2SchoolAdminsPagesDashboardInitController,
	V2SchoolAdminsPagesDashboardWidgetsController,
} from '@esgi/contracts/esgi';
import {
	FetchWidgetDataByIDParameters,
	FetchWidgetDataByIDResponse,
	InitWidgetsDataParameters,
	InitWidgetsDataResponse,
} from './types';
import {parseSchoolDemographicPerformanceOptions} from './parsers/widget-options/parse-school-demographic-performance-options';
import {parseSchoolDemographicsPerformanceData} from './parsers/widget-data/parse-school-demographics-performance-data';
import {parseSchoolStudentsNeedingSupportOptions} from './parsers/widget-options/parse-school-students-needing-support-options';
import {parseSchoolStudentsNeedingSupportData} from './parsers/widget-data/parse-school-students-needing-support-data';
import {parseSchoolPerformanceOptions} from './parsers/widget-options/parse-school-performance-options';
import {parseSchoolPerformanceData} from './parsers/widget-data/parse-school-performance-data';
import {parseInitWidgetsData} from './parsers/parse-init-widgets-data';
import {getAdaptedPeriods} from './helpers/get-adapted-periods';
import {testContentAreasMap} from './constants';
import {
	AddWidgetResponse,
	ContentAreaModel,
	DemographicGroupTypeNumerable,
	GradeLevel,
} from '@esgi/main/features/admins/dashboard';
import {SubjectLevel} from '@esgi/main/kits/common';

export class PageService extends BaseService {
	public classes$ = new BehaviorSubject<ClassModel[]>([]);
	public teachers$ = new BehaviorSubject<TeacherModel[]>([]);
	public subjectTabs$ = new BehaviorSubject<SubjectTab[]>([]);

	public lastUpdatedDate$ = new BehaviorSubject<Date | string>('');
	public gradeLevels$ = new BehaviorSubject<GradeLevel[]>([]);
	public contentAreas$ = new BehaviorSubject<ContentAreaModel[]>([]);

	public widgets$ = new BehaviorSubject<Widget[]>([]);
	public widgetsMetricData$ = new BehaviorSubject<WidgetsMetricData>({});

	private readonly contentAreasController = new V2ContentAreasController();
	private readonly dashboardInitController = new V2SchoolAdminsPagesDashboardInitController();
	private readonly pagesDashboardController = new V2SchoolAdminsPagesDashboardController();
	private readonly widgetsController = new V2SchoolAdminsPagesDashboardWidgetsController();

	public fetchContentAreas(): Observable<ContentAreaModel[]> {
		return this.contentAreasController.all().pipe(
			map(({contentAreas}) => {
				const testContentAreasMapKey = Object.keys(testContentAreasMap);
				const testContentAreasResponseIDs = contentAreas.map(({id}) => id);

				if (
					testContentAreasMapKey.length !== contentAreas.length ||
					testContentAreasMapKey.some(
						(testContentAreaID) => !testContentAreasResponseIDs.includes(Number(testContentAreaID)),
					)
				) {
					throw new Error('contentAreas is not contain all appropriate keys. Please refresh testContentAreasMap');
				}

				const contentAreasData = contentAreas.map(({id, name}) => {
					const value = testContentAreasMap[id];

					if (isUndefined(value)) {
						throw new Error('value is undefined. Please refresh testContentAreasMap');
					}

					return {
						id,
						name,
						value,
					};
				});

				this.contentAreas$.next(contentAreasData);

				return contentAreasData;
			}),
		);
	}

	public init({
		globalSchoolYearID,
		contentAreasList,
	}: {
		globalSchoolYearID: number;
		contentAreasList: ContentAreaModel[];
	}) {
		return this.dashboardInitController.init({globalSchoolYearID}).pipe(
			tap(({value: {classes, subjectTabs, teachers, timestamp, widgets, gradeLevels}}) => {
				const adaptedSubjectTabs = subjectTabs.map<SubjectTab>(({id, name, level}) => ({
					id,
					name,
					level: level as unknown as SubjectLevel,
				}));

				this.classes$.next(classes);
				this.teachers$.next(teachers);
				this.subjectTabs$.next(adaptedSubjectTabs);
				this.gradeLevels$.next(gradeLevels);

				this.updateLastUpdatedDate(timestamp);

				this.widgets$.next(
					parseInitWidgetsData({
						widgets,
						classes,
						teachers,
						subjectTabs: adaptedSubjectTabs,
						gradeLevels,
						contentAreasList,
					}),
				);
			}),
		);
	}

	public initWidgetsData({dashboardVersionID}: InitWidgetsDataParameters): Observable<InitWidgetsDataResponse> {
		return this.pagesDashboardController.getData({dashboardVersionID}).pipe(
			tap(({value: {metrics}}) => {
				const widgetsData = metrics.reduce((currentData, {widgetID, data}) => {
					currentData[widgetID] = !isNull(data)
						? {
								schoolPerformanceMetricData: parseSchoolPerformanceData(data.schoolPerformanceMetricData),
								schoolStudentsNeedingSupportMetricData: parseSchoolStudentsNeedingSupportData(
									data.schoolStudentsNeedingSupportMetricData,
								),
								schoolDemographicsPerformanceMetricData: parseSchoolDemographicsPerformanceData(
									data.schoolDemographicsPerformanceMetricData,
								),
								districtPerformanceMetricData: null,
								demographicsPerformanceMetricData: null,
								lowestAchievementMetricData: null,
							}
						: null;

					return currentData;
				}, {} as WidgetsMetricData);

				const widgets = this.widgets$.value;

				widgets.forEach(({id}) => {
					const widgetData = widgetsData[id];

					if (isUndefined(widgetData)) {
						widgetsData[id] = null;
					}
				});

				this.widgetsMetricData$.next(widgetsData);
			}),
		);
	}

	public fetchWidgetDataByID({id, versionID}: FetchWidgetDataByIDParameters): Observable<FetchWidgetDataByIDResponse> {
		const widgets = this.widgets$.value;

		const widget = widgets.find((widget) => widget.id === id && widget.versionID === versionID);

		if (isUndefined(widget)) {
			return of();
		}

		return this.widgetsController.byId({widgetID: id}).pipe(
			tap(({value, isSuccess, errors}) => {
				if (!isSuccess && errors[0]) {
					const {type} = errors[0];
					const emptyPeriod = {data: {}};

					/**
					 * potential error on empty data
					 * e.g. - if the request sent during calculating widget data
					 */
					if (type === 'no_data') {
						this.setNewWidgetData({
							id,
							data: {
								schoolPerformanceMetricData: !isNull(widget.options.schoolPerformanceWidgetOptions)
									? {
											allTeachersAvg: 0,
											periods: new Array(widget.options.schoolPerformanceWidgetOptions.periods.length).fill(
												emptyPeriod,
											),
										}
									: null,

								schoolStudentsNeedingSupportMetricData: !isNull(
									widget.options.schoolStudentsNeedingSupportWidgetOptions,
								)
									? {
											classesData: [],
											studentsData: [],
										}
									: null,

								schoolDemographicsPerformanceMetricData: !isNull(
									widget.options.schoolDemographicPerformanceWidgetOptions,
								)
									? {
											allClassesAvg: 0,
											demographicGroup: widget.options.schoolDemographicPerformanceWidgetOptions.demographicGroup,
											demographicGroupData: new Array(
												widget.options.schoolDemographicPerformanceWidgetOptions.periods.length,
											).fill(emptyPeriod),
										}
									: null,

								districtPerformanceMetricData: null,
								demographicsPerformanceMetricData: null,
								lowestAchievementMetricData: null,
							},
						});

						return;
					}
				}

				if (isSuccess && !isNull(value)) {
					const {data, id} = value;

					this.setNewWidgetData({
						id,
						data: !isNull(data)
							? {
									schoolPerformanceMetricData: parseSchoolPerformanceData(data.schoolPerformanceMetricData),
									schoolStudentsNeedingSupportMetricData: parseSchoolStudentsNeedingSupportData(
										data.schoolStudentsNeedingSupportMetricData,
									),
									schoolDemographicsPerformanceMetricData: parseSchoolDemographicsPerformanceData(
										data.schoolDemographicsPerformanceMetricData,
									),
									districtPerformanceMetricData: null,
									demographicsPerformanceMetricData: null,
									lowestAchievementMetricData: null,
								}
							: null,
					});
				}
			}),
		);
	}

	public addNewWidgetInList({
		id,
		name,
		versionID,
		options: {
			schoolPerformanceWidgetOptions,
			schoolStudentsNeedingSupportWidgetOptions,
			schoolDemographicPerformanceWidgetOptions,
		},
		lastUpdatedTime,
	}: {
		id: AddWidgetResponse['id'];
		name: string;
		versionID: AddWidgetResponse['versionID'];
		options: AddWidgetEventOptions;
		lastUpdatedTime: string;
	}) {
		const widgets = this.widgets$.value;
		const classes = this.classes$.value;
		const teachers = this.teachers$.value;
		const subjectTabs = this.subjectTabs$.value;
		const gradeLevels = this.gradeLevels$.value;
		const contentAreas = this.contentAreas$.value;

		const newWidget: Widget = {
			id,
			name,
			versionID,
			lastUpdatedTime,
			options: {
				schoolPerformanceWidgetOptions: parseSchoolPerformanceOptions({
					options: schoolPerformanceWidgetOptions,
					subjectTabs,
					teachers,
					gradeLevels,
					contentAreasList: contentAreas,
				}),
				schoolStudentsNeedingSupportWidgetOptions: parseSchoolStudentsNeedingSupportOptions({
					options: schoolStudentsNeedingSupportWidgetOptions,
					subjectTabs,
					classes,
					teachers,
					gradeLevels,
					contentAreasList: contentAreas,
				}),
				schoolDemographicPerformanceWidgetOptions: parseSchoolDemographicPerformanceOptions({
					options: schoolDemographicPerformanceWidgetOptions,
					subjectTabs,
					classes,
					gradeLevels,
					contentAreasList: contentAreas,
				}),
				districtPerformanceWidgetOptions: null,
				demographicPerformanceWidgetOptions: null,
				lowestAchievementWidgetOptions: null,
			},
		};

		this.widgets$.next([...widgets, newWidget]);
		this.setNewWidgetData({id, data: null});
	}

	public updateSchoolPerformanceWidget({id, name, options, selectedArraysEntity}: SchoolPerformanceWidgetUpdatedValue) {
		const adaptedPeriods = getAdaptedPeriods(options.periods);

		this.widgetsController
			.update({
				widgetID: id,
				name,
				dashboardWidgetOptions: {
					schoolPerformanceWidgetOptions: {
						...options,
						periods: adaptedPeriods,
						teachersIDs: selectedArraysEntity.teachersIDs === 'all' ? [] : options.teachersIDs,
						contentAreaIDs: selectedArraysEntity.contentAreaIDs === 'all' ? [] : options.contentAreaIDs,
						gradeLevelIDs: selectedArraysEntity.gradeLevelIDs === 'all' ? [] : options.gradeLevelIDs,
						subjectIDs: selectedArraysEntity.subjectIDs === 'all' ? [] : options.subjectIDs,
					},
					schoolStudentsNeedingSupportWidgetOptions: null,
					schoolDemographicPerformanceWidgetOptions: null,
					districtPerformanceWidgetOptions: null,
					demographicPerformanceWidgetOptions: null,
					lowestAchievementWidgetOptions: null,
				},
			})
			.subscribe(({value: {versionID, lastUpdatedTime}}) => {
				this.updateWidget({
					id,
					name,
					versionID,
					lastUpdatedTime,
					options: {
						schoolPerformanceWidgetOptions: {
							...options,
							periods: adaptedPeriods,
						},
						schoolStudentsNeedingSupportWidgetOptions: null,
						schoolDemographicPerformanceWidgetOptions: null,
						districtPerformanceWidgetOptions: null,
						demographicPerformanceWidgetOptions: null,
						lowestAchievementWidgetOptions: null,
					},
				});
			});
	}

	public updateSchoolDemographicPerformanceWidget({
		id,
		name,
		options,
		selectedArraysEntity,
	}: DemographicPerformanceWidgetUpdatedValue) {
		const adaptedPeriods = getAdaptedPeriods(options.periods);

		this.widgetsController
			.update({
				widgetID: id,
				name,
				dashboardWidgetOptions: {
					schoolPerformanceWidgetOptions: null,
					schoolStudentsNeedingSupportWidgetOptions: null,
					schoolDemographicPerformanceWidgetOptions: {
						...options,
						periods: adaptedPeriods,
						demographicGroup: options.demographicGroup as unknown as DemographicGroupTypeNumerable,
						contentAreaIDs: selectedArraysEntity.contentAreaIDs === 'all' ? [] : options.contentAreaIDs,
						gradeLevelIDs: selectedArraysEntity.gradeLevelIDs === 'all' ? [] : options.gradeLevelIDs,
						subjectIDs: selectedArraysEntity.subjectIDs === 'all' ? [] : options.subjectIDs,
						classIDs: selectedArraysEntity.classIDs === 'all' ? [] : options.classIDs,
					},
					districtPerformanceWidgetOptions: null,
					demographicPerformanceWidgetOptions: null,
					lowestAchievementWidgetOptions: null,
				},
			})
			.subscribe(({value: {versionID, lastUpdatedTime}}) => {
				this.updateWidget({
					id,
					name,
					versionID,
					lastUpdatedTime,
					options: {
						schoolPerformanceWidgetOptions: null,
						schoolStudentsNeedingSupportWidgetOptions: null,
						schoolDemographicPerformanceWidgetOptions: {
							...options,
							periods: adaptedPeriods,
						},
						districtPerformanceWidgetOptions: null,
						demographicPerformanceWidgetOptions: null,
						lowestAchievementWidgetOptions: null,
					},
				});
			});
	}

	public updateSchoolStudentsNeedingSupportWidget({
		id,
		name,
		options,
		selectedArraysEntity,
	}: SchoolStudentsNeedingSupportWidgetUpdatedValue) {
		const adaptedPeriods = getAdaptedPeriods(options.periods);

		this.widgetsController
			.update({
				widgetID: id,
				name,
				dashboardWidgetOptions: {
					schoolPerformanceWidgetOptions: null,
					schoolStudentsNeedingSupportWidgetOptions: {
						...options,
						periods: adaptedPeriods,
						classIDs: selectedArraysEntity.classIDs === 'all' ? [] : options.classIDs,
						teachersIDs: selectedArraysEntity.teachersIDs === 'all' ? [] : options.teachersIDs,
						viewType: options.viewType as unknown as SchoolStudentsNeedingSupportWidgetViewTypeNumerable,
						contentAreaIDs: selectedArraysEntity.contentAreaIDs === 'all' ? [] : options.contentAreaIDs,
						gradeLevelIDs: selectedArraysEntity.gradeLevelIDs === 'all' ? [] : options.gradeLevelIDs,
						subjectIDs: selectedArraysEntity.subjectIDs === 'all' ? [] : options.subjectIDs,
					},
					schoolDemographicPerformanceWidgetOptions: null,
					districtPerformanceWidgetOptions: null,
					demographicPerformanceWidgetOptions: null,
					lowestAchievementWidgetOptions: null,
				},
			})
			.subscribe(({value: {versionID, lastUpdatedTime}}) => {
				this.updateWidget({
					id,
					name,
					versionID,
					lastUpdatedTime,
					options: {
						schoolPerformanceWidgetOptions: null,
						schoolStudentsNeedingSupportWidgetOptions: {
							...options,
							periods: adaptedPeriods,
						},
						schoolDemographicPerformanceWidgetOptions: null,
						districtPerformanceWidgetOptions: null,
						demographicPerformanceWidgetOptions: null,
						lowestAchievementWidgetOptions: null,
					},
				});
			});
	}

	public deleteWidgetByID(id: Widget['id']) {
		const filteredWidgets = this.widgets$.value.filter((widget) => widget.id !== id);

		this.widgets$.next(filteredWidgets);
		this.widgetsController.makeDelete({widgetID: id}).subscribe();
	}

	public updateWidgetsOrdering(newOrderIDs: Widget['id'][]) {
		const currentWidgets = this.widgets$.value;

		const currentWidgetsByID = currentWidgets.reduce<Record<Widget['id'], Widget>>((state, widget) => {
			state[widget.id] = widget;

			return state;
		}, {});

		const newWidgetsList = newOrderIDs.map((id) => {
			const widget = currentWidgetsByID[id];

			if (isUndefined(widget)) {
				throw new Error('widget is undefined');
			}

			return widget;
		});

		this.widgets$.next(newWidgetsList);

		this.widgetsController.reorder({widgetIDs: newOrderIDs}).subscribe();
	}

	public updateLastUpdatedDate(timestamp: string | Date) {
		this.lastUpdatedDate$.next(timestamp);
	}

	public override dispose(): void {
		this.dashboardInitController.dispose();
		this.pagesDashboardController.dispose();
		this.widgetsController.dispose();
	}

	private updateWidget(widget: Widget) {
		const currentWidgets = this.widgets$.value;

		this.widgets$.next(
			currentWidgets.map((iteratedWidget) => (iteratedWidget.id === widget.id ? widget : iteratedWidget)),
		);

		this.setNewWidgetData({id: widget.id, data: null});
	}

	private setNewWidgetData({id, data}: {id: Widget['id']; data: DashboardMetricData | null}) {
		const widgetsMetricData = this.widgetsMetricData$.value;

		this.widgetsMetricData$.next({
			...widgetsMetricData,
			[id]: data,
		});
	}
}
