import {BoxplotChart, BoxplotChartCandleProps, Tooltip} from '@esgi/ui';
import {BoxplotChartRoot, tooltipContentCss} from './index.styled';
import {Dispatch, SetStateAction, useCallback} from 'react';
import {candleChartHeight, tailChartValuePercentage} from '../../constants';
import {isNull} from 'underscore';
import {Text} from '@esgi/ui/typography';
import {HoveredCandleData} from '../../types';

type Props = {
	hoveredCandleData: HoveredCandleData | null;
	setHoveredCandleData: Dispatch<SetStateAction<HoveredCandleData | null>>;
	onHoverTranscriptText: string;
	activeQuartileBarColor: BoxplotChartCandleProps['activeQuartileBarColor'];
	nonActiveQuartileBarColor: BoxplotChartCandleProps['nonActiveQuartileBarColor'];
	itemCellIndex: number;
	periodIndex: number;
	chartMinValue: number;
	chartMaxValue: number;
	quartile1: number;
	quartile2: number;
	quartile3: number;
};

export function CandleChartItem({
	hoveredCandleData,
	setHoveredCandleData,
	chartMinValue,
	chartMaxValue,
	quartile1,
	quartile2,
	quartile3,
	onHoverTranscriptText,
	activeQuartileBarColor,
	nonActiveQuartileBarColor,
	itemCellIndex,
	periodIndex,
}: Props) {
	const onToolpipOpenChanged = useCallback(
		(value: boolean) => {
			if (value) {
				return setHoveredCandleData({
					chartMinValue,
					chartMaxValue,
					quartile1,
					quartile2,
					quartile3,
					itemCellIndex,
					periodIndex,
				});
			}

			setHoveredCandleData(null);
		},
		[chartMaxValue, chartMinValue, quartile1, quartile2, quartile3, setHoveredCandleData, itemCellIndex, periodIndex],
	);

	return (
		<Tooltip onOpenChange={onToolpipOpenChanged}>
			<Tooltip.Trigger>
				<BoxplotChartRoot
					minValue={0 - tailChartValuePercentage}
					maxValue={100 + tailChartValuePercentage}
					chartMinValue={chartMinValue}
					chartMaxValue={chartMaxValue}
					quartile1={quartile1}
					quartile2={quartile2}
					quartile3={quartile3}
					withOpacity={
						!isNull(hoveredCandleData) &&
						(hoveredCandleData.itemCellIndex !== itemCellIndex || hoveredCandleData.periodIndex !== periodIndex)
					}
				>
					{() => {
						const itemIsHovered =
							!isNull(hoveredCandleData) &&
							hoveredCandleData.itemCellIndex === itemCellIndex &&
							hoveredCandleData.periodIndex === periodIndex;

						return (
							<BoxplotChart.Candle
								width={1}
								height={candleChartHeight}
								activeQuartileBarColor={itemIsHovered ? 'secondary' : activeQuartileBarColor}
								nonActiveQuartileBarColor={itemIsHovered ? 'secondaryVivid' : nonActiveQuartileBarColor}
							/>
						);
					}}
				</BoxplotChartRoot>
			</Tooltip.Trigger>
			<Tooltip.Content sideOffset={12} css={tooltipContentCss}>
				<Text size='xSmall' font='mono' color='secondary'>
					{onHoverTranscriptText}
				</Text>
			</Tooltip.Content>
		</Tooltip>
	);
}
