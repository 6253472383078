import {SubjectModel} from '@esgi/main/kits/subject-selection-panel';
import {CardsSortBy, Student} from '../../types/section';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {useEsgiApiGateway} from '@esgi/contracts/esgi';
import {useEffect, useMemo} from 'react';
import {mapToEnum} from 'shared/utils';
import {ByEachStudentData, ByEachStudentTest, ByEachStudentTestPanelData, SubjectTypeNumerable} from '../../types/test-panel';
import {StudentSort, UserType, useUser} from '@esgi/core/authentication';
import {isNull} from 'underscore';
import {TestContentArea} from '@esgi/main/kits/common';
import {sortTestCards} from '../../utils/sort-test-cards';
import {sortTestsCardsConfig} from './constants';
import {mapTestTypeNumerableToString} from '../../utils/map-test-type-numerable-to-string';

type Parameters = {
	canRunRequest: boolean;
	students: Student[];
	selectedSubject: SubjectModel | null;
	isShowThisData: boolean;
	activeCardsSortBy: CardsSortBy;
};

export function useByEachStudentTestsCards({
	canRunRequest,
	students,
	selectedSubject,
	isShowThisData,
	activeCardsSortBy,
}: Parameters) {
	const currentUser = useUser();

	const api = useEsgiApiGateway();

	const request = useMemo(() => {
		if (currentUser.userType === UserType.C) {
			return api.v2.schoolAdmins.modules.testResults.byEachStudent;
		}

		if (currentUser.userType === UserType.D) {
			return api.v2.districtAdmins.modules.testResults.byEachStudent;
		}

		throw new Error('There is no matching user specified to proceed with the testResults request');
	}, [api, currentUser]);

	const [data, fetchData] = useCancelableRequest(request);

	useEffect(() => {
		if (!canRunRequest || isNull(selectedSubject) || !isShowThisData) {
			return;
		}

		fetchData({
			studentIDs: students.map(({id}) => id),
			subjectID: selectedSubject.id,
			subjectType: mapToEnum(selectedSubject.type, SubjectTypeNumerable),
		});
	}, [canRunRequest, fetchData, isShowThisData, selectedSubject, students]);

	const sortedStudents = useMemo<Student[]>(() => {
		return [...students].sort((a, b) => {
			if (currentUser?.studentSort === StudentSort.LastName) {
				const aLastNameLowerCase = a.lastName.toLocaleLowerCase();
				const bLastNameLowerCase = b.lastName.toLocaleLowerCase();

				return aLastNameLowerCase.localeCompare(bLastNameLowerCase);
			}

			const aFirstNameLowerCase = a.firstName.toLocaleLowerCase();
			const bFirstNameLowerCase = b.firstName.toLocaleLowerCase();

			return aFirstNameLowerCase.localeCompare(bFirstNameLowerCase);
		});
	}, [currentUser, students]);

	return useMemo<ByEachStudentTestPanelData | null>(() => {
		if (!isShowThisData) {
			return null;
		}

		const isDataLoaded = isAsyncSucceed(data);

		if (!isDataLoaded) {
			return {
				skeleton: true,
				data: [],
			};
		}

		const availableTestsIDs = new Set<number>();

		for (const {results} of data.data.value.students) {
			for (const {testID} of results) {
				availableTestsIDs.add(testID);
			}
		}

		if (!availableTestsIDs.size) {
			return {
				skeleton: false,
				data: [],
			};
		}

		const availableTests = data.data.value.tests.filter(({id}) => availableTestsIDs.has(id));

		const studentsData: ByEachStudentData[] = sortedStudents.map<ByEachStudentData>((student) => {
			const responsedStudentInfo = data.data.value.students.find(({id}) => id === student.id) ?? null;

			const testsCards = availableTests.map<ByEachStudentTest>(({id, type, name, maxScore, color, contentArea}) => {
				const testResult: ByEachStudentTest['result'] = isNull(responsedStudentInfo)
					? null
					: responsedStudentInfo.results.find(({testID}) => testID === id) ?? null;

				return {
					id,
					type: mapTestTypeNumerableToString(type),
					name,
					maxScore,
					color,
					contentArea: contentArea as TestContentArea,
					result: testResult,
				};
			});

			const sortedTestsCards = sortTestCards({
				testCards: testsCards,
				activeSorting: activeCardsSortBy,
				config: sortTestsCardsConfig,
			});

			return {
				student,
				testsCards: sortedTestsCards,
			};
		});

		return {
			skeleton: false,
			data: studentsData,
		};
	}, [activeCardsSortBy, data, isShowThisData, sortedStudents]);
}
