import {Dispatch, PropsWithChildren, useLayoutEffect, useRef, useState} from 'react';
import {Comment, CommentAdd} from '@esgi/ui/icons';
import {GridBox} from '@esgi/ui/layout';
import {AddEditComment} from './components/add-edit-comment';
import {CommentButton, Container, Divider} from './index.styled';
import {Text} from '@esgi/ui/typography';

type Props = PropsWithChildren<{
	questionNumber: number;
	questionTitle: string;
	comment?: string | null;
	withQuestionUnderline: boolean;
	onAddComment: Dispatch<string>;
	onDeleteComment: VoidFunction;
	maxCommentLength?: number | undefined;
}>;

const defaultQuestionTitleHeight = 12;

export function QuestionRowEditable({
	questionNumber,
	questionTitle,
	children,
	comment,
	withQuestionUnderline,
	onAddComment,
	onDeleteComment,
	maxCommentLength,
}: Props) {
	const [isOpenAddEditComment, setIsOpenAddEditComment] = useState(false);

	const [questionTitleHeight, setQuestionTitleHeight] = useState(defaultQuestionTitleHeight);

	const questionTitleRef = useRef<HTMLElement>(null);

	useLayoutEffect(() => {
		setQuestionTitleHeight(questionTitleRef.current?.clientHeight ?? 0);
	}, []);

	const moreThanOneLineTitle = questionTitleHeight > defaultQuestionTitleHeight;

	return (
		<>
			<Container moreThanOneLineTitle={moreThanOneLineTitle} dataCy='question-row'>
				<Text size='xSmall' font='mono' color='lowContrast' data-cy='question-number'>
					{questionNumber}
				</Text>

				<GridBox justify='between' flow='column' gap='3' align={moreThanOneLineTitle ? 'start' : 'center'}>
					<Text size='small' color='neutral40' ref={questionTitleRef} data-cy='question-title'>
						{questionTitle}
					</Text>

					<CommentButton
						editComment={Boolean(comment)}
						onClick={() => setIsOpenAddEditComment(true)}
						dataCy='comment-button'
					>
						{comment ? <Comment /> : <CommentAdd />}
					</CommentButton>
				</GridBox>

				<GridBox>{children}</GridBox>
			</Container>

			{withQuestionUnderline && <Divider />}

			{isOpenAddEditComment && (
				<AddEditComment
					defaultComment={comment ?? ''}
					questionTitle={questionTitle}
					onAlertClose={() => setIsOpenAddEditComment(false)}
					onAddComment={onAddComment}
					onDeleteComment={onDeleteComment}
					maxCommentLength={maxCommentLength}
				/>
			)}
		</>
	);
}
