import {Button} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {ComponentPropsWithoutRef, forwardRef} from 'react';
import {usePanelContext} from '../context';

type Props = Omit<ComponentPropsWithoutRef<typeof Button>, 'children' | 'skeleton'> & {
	buttonText: string;
};

export const SaveChangesButton = forwardRef<HTMLButtonElement, Props>(
	({dataCy = '', css = {}, color = 'secondary', onClick, disabled, buttonText, ...props}, forwardedRef) => {
		const {skeleton} = usePanelContext();

		return (
			<Button
				dataCy={dataCy}
				css={css}
				color={color}
				onClick={onClick}
				disabled={disabled}
				skeleton={skeleton}
				ref={forwardedRef}
				{...props}
			>
				<Text size='medium' bold color='base'>
					{buttonText}
				</Text>
			</Button>
		);
	},
);
