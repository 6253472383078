import {SubjectLevel, SubjectType} from '@esgi/main/kits/common';
import {createContext, useContext} from 'react';
import {isNull} from 'underscore';

export type PanelContextValue = {
	subjectLevel: SubjectLevel;
	subjectType: SubjectType;
	subjectName: string;
	skeleton: boolean;
};

export const PanelContext = createContext<PanelContextValue | null>(null);

export function usePanelContext() {
	const context = useContext(PanelContext);

	if (isNull(context)) {
		throw new Error('usePanelPanelContext is null');
	}

	return context;
}
