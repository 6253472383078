import {isNull} from 'underscore';
import {FormControl} from '@esgi/ui/form';
import {DatePickerData} from '@esgi/main/kits/admin';
import {StudentsTwoPeriodsFormColorItem} from '../types';
import {datePickerDataForInitialization} from '../constants';
import {Period} from '../../types';
import {Color, Widget} from '@esgi/main/features/admins/dashboard';

export function setPeriodsValueForStudentsTwoPeriodsForm({
	periodValue,
	colorControl,
	dateRangeControl,
	defaultColorItemsIndex,
}: {
	periodValue: Period | null;
	colorControl: FormControl<StudentsTwoPeriodsFormColorItem>;
	dateRangeControl: FormControl<DatePickerData>;
	defaultColorItemsIndex: number;
}) {
	if (!isNull(periodValue)) {
		const {color, ...rest} = periodValue;

		colorControl.value = color !== Color.None ? [color] : [Widget.colorItems[defaultColorItemsIndex]?.value ?? null];
		dateRangeControl.value = rest;

		return;
	}

	colorControl.value = [Widget.colorItems[defaultColorItemsIndex]?.value ?? null];
	dateRangeControl.value = datePickerDataForInitialization;
}
