import {SpecialistGroup, Student, User} from '@esgi/main/libs/school-admin-store';
import {isUndefined, useStreamEffect} from '@esgi/ui';
import {ElementStatus, FormControl, FormGroup, useForm, Validators} from '@esgi/ui/form';
import {UserType} from '../../types';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {SchoolSpecialistForm} from './types';
import {groupNameMaxLength} from '../../../components/group-name-input/constants';
import {enumRemap} from 'shared/utils';

type Parameters = {
	schoolSpecialists: User[];
	specialistGroups: SpecialistGroup[];
	setIsFormValid: Dispatch<boolean>;
	setIsFormTouched: Dispatch<boolean>;
};

export function useSchoolSpecialistForm({
	schoolSpecialists,
	specialistGroups,
	setIsFormTouched,
	setIsFormValid,
}: Parameters) {
	const [formInactiveMessage, setFormInactiveMessage] = useState<string | null>(null);

	const form: SchoolSpecialistForm = useForm(
		() =>
			new FormGroup({
				schoolSpecialistID: new FormControl<string[]>([], {
					validators: [Validators.required()],
					initialValidateStatus: ElementStatus.untouched,
				}),
				groupName: new FormControl('', {
					initialValidateStatus: ElementStatus.untouched,
				}),
				studentsIDs: new FormControl<Student['id'][]>([]),
			}),
	);

	useEffect(() => {
		if (!schoolSpecialists?.length) {
			form.status = ElementStatus.disabled;

			setFormInactiveMessage('There are no school specialists in the school');
		}
	}, [form, schoolSpecialists]);

	useStreamEffect(form.controls.schoolSpecialistID.onChanged, ({currState: {value}, reason}) => {
		const schoolSpecialistID = value[0];

		if (!isUndefined(schoolSpecialistID) && reason === 'value') {
			const numberedSchoolSpecialistID = Number(schoolSpecialistID);

			const schoolSpecialistGroups = specialistGroups.filter(
				({specialistID, userType}) =>
					enumRemap(userType, UserType) === UserType.ISS && specialistID === numberedSchoolSpecialistID,
			);

			form.controls.groupName.validators.length = 0;

			form.controls.groupName.validators.push(
				Validators.required(),
				Validators.length.max(groupNameMaxLength),
				Validators.isDublicateValue(schoolSpecialistGroups.map(({name}) => name)),
			);

			form.controls.groupName.status = ElementStatus.untouched;

			form.validate().subscribe(({valid}) => {
				setIsFormValid(valid);
			});
		}
	});

	const checkFormOnTouchValid = useCallback(() => {
		const {groupName, schoolSpecialistID, studentsIDs} = form.value;

		const isFormTouched =
			groupName !== '' ||
			!isUndefined(schoolSpecialistID[0]) ||
			Boolean(studentsIDs.length);

		setIsFormTouched(isFormTouched);

		form.validate(true).subscribe(({valid}) => {
			setIsFormValid(valid);
			if (
				form.controls.schoolSpecialistID.status === ElementStatus.disabled ||
				isUndefined(form.value.schoolSpecialistID[0])
			) {
				form.controls.groupName.status = ElementStatus.disabled;
			}
		});
	}, [form, setIsFormTouched, setIsFormValid]);

	useStreamEffect(form.onChanged, checkFormOnTouchValid);

	return {
		form,
		checkFormOnTouchValid,
		formInactiveMessage,
	};
}
