import React, {Suspense, useEffect} from 'react';
import {lazyComponent} from '@esgi/core/react';
import {SsoTracker} from '@esgi/core/tracker';

const TeacherActivityReportModal = lazyComponent(() => import('shared/modules/reports/teacher-activity/modal'));

class Props {
	schoolsGroupID: number;
	teachersGroupID: number;
	onClose: VoidFunction;
}

export function TeacherActivityLauncher(props: Props) {
	useEffect(() => {
		SsoTracker.trackEvent({
			trackingEvent: 'UsageReport',
		});
	}, []);

	return <Suspense fallback={<div/>}>
		<TeacherActivityReportModal
			teachersGroupID={props.teachersGroupID}
			schoolsGroupID={props.schoolsGroupID}
			onCloseClicked={props.onClose}/>
	</Suspense>;
}