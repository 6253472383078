import {
	ExpandableSelectableBlockTab,
	ItemNumerable,
	PublishToTeachersNumerable,
} from '@esgi/main/features/admins/subject-management';
import {PublishForTeacherTabID} from '../../../../../types';
import {InitSubjectData} from '../../../types';
import {mapToEnum} from 'shared/utils';
import {publishToTeachetsTitle} from '../../../../constants';

type Parameters = {
	teachersSchools: ItemNumerable[];
	initSubjectData: InitSubjectData;
};

export function getPublishForTeacherInit({
	teachersSchools,
	initSubjectData,
}: Parameters): ExpandableSelectableBlockTab<PublishForTeacherTabID>[] {
	const mappedvalue = mapToEnum(initSubjectData.publishToTeachers, PublishToTeachersNumerable);

	const isBySchools = mappedvalue === PublishToTeachersNumerable.Schools;

	return [
		{
			value: PublishForTeacherTabID.All,
			label: publishToTeachetsTitle[PublishForTeacherTabID.All],
			isActive: mappedvalue === PublishToTeachersNumerable.All || mappedvalue === PublishToTeachersNumerable.None,
			allItems: [],
			selectedItems: [],
		},
		{
			value: PublishForTeacherTabID.BySchool,
			label: publishToTeachetsTitle[PublishForTeacherTabID.BySchool],
			isActive: isBySchools,
			allItems: teachersSchools,
			selectedItems: isBySchools ? [...initSubjectData.selectedSchools] : teachersSchools.map(({id}) => id),
		},
	];
}
