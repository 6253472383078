import {BaseService} from '@esgi/core/service';
import {BehaviorSubject, tap} from 'rxjs';
import {Student} from '../../types';
import {InitCreateClassResponse} from './types';

export class ClassDataService extends BaseService {
	public students$ = new BehaviorSubject<Student[] | null>(null);

	private controller = 'v2/teachers/modules/classes';

	public init() {
		return this.httpClient.ESGIApi.get<InitCreateClassResponse>(this.controller, 'create')
			.pipe(
				tap(({students}) => {
					this.students$.next(students);
				}),
			)
			.asObservable();
	}

	public createClass({name, studentIDs}: {name: string; studentIDs: Student['id'][]}) {
		return this.httpClient.ESGIApi.post<{classID: number}>(this.controller, 'create', {name, studentIDs});
	}
}
