import {useEffect, useState} from 'react';
import {useService} from '@esgi/core/service';
import {Loader} from '@esgillc/ui-kit/loader';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {AutoTestCreatorService} from './service';
import {AddTestToSubject, State as AddTestToSubjectState} from 'shared/modules/add-test-to-subject/component';
import {TestCreatorModal} from './components/test-creator-modal';
import {PreviewState, Subject} from './types';

export interface Props {
	subjects?: Subject[];
	onTestCreated?: () => void;
	close: () => void;
	disableNewSubjectBtn?: boolean;
}

export default function AutoTestCreator({close, subjects, onTestCreated, disableNewSubjectBtn}: Props) {

	const service = useService(AutoTestCreatorService);
	const dictionary = useBehaviorSubject(service.dictionary);
	const colorPicker = useBehaviorSubject(service.colorPicker);
	const previewState = useBehaviorSubject<PreviewState>(service.previewState);

	const form = service.form;
	const [testID, setTestID] = useState<number>(0);
	const [addToSubject, setAddToSubject] = useState<AddTestToSubjectState>(null);

	const [isLoading, setLoading] = useState(true);
	const [isInitialized, setInitialized] = useState(false);
	const [isTopicSelected, setTopicSelected] = useState(false);

	useEffect(() => {
		service.init().subscribe({
			complete: () => {
				setInitialized(true);
				setLoading(false);
			},
		});
	}, []);

	const onSave = () => {
		service.save().subscribe(r => {
			setTestID(r.testID);
			setAddToSubject(new AddTestToSubjectState());
		});
	};

	const onChangeTopic = (id) => {
		service.onChangeTopic(id);
		setTopicSelected(true);
	};

	return <>
		<Loader show={isLoading} fullscreen/>
		{isInitialized && addToSubject &&
			<AddTestToSubject state={addToSubject}
												title={() => <span data-cy='add-to-subject-title'>Add {form.controls.testName.value} to a Subject Tab</span>}
												onChange={setAddToSubject}
												testName={form.controls.testName.value}
												close={() => {
													onTestCreated && onTestCreated();
													close();
												}}
												testId={testID}
												mySubjects={subjects}
												saved={() => {
													onTestCreated && onTestCreated();
													close();
												}}
												disableNewSubjectBtn={disableNewSubjectBtn}/>}

		{isInitialized && !addToSubject &&
			<TestCreatorModal
				form={form}
				preview={() => service.preview()}
				previewState={previewState}
				onChangeColor={(c) => service.colorPicker.next(c)}
				onChangePreviewState={(p) => service.previewState.next({...previewState, tiles: p})}
				onChangeTopic={onChangeTopic}
				isTopicSelected={isTopicSelected}
				dictionary={dictionary}
				colorPicker={colorPicker}
				onClose={close}
				onSave={onSave}
			/>}
	</>;
}
