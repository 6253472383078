import {PropsWithChildren} from 'react';
import {Checkbox} from '@esgi/ui/controls';
import {SectionContent, SectionDescription, CheckboxWrapper} from './index.styled';

interface Props extends PropsWithChildren {
	checked: boolean;
	onValueChange: (value: boolean) => void;
	description?: string;
	dataCy?: string;
}

export function SingleCheckboxSectionState({checked, onValueChange, description, dataCy, children}: Props) {
	return (
		<SectionContent dataCy={dataCy}>
			<CheckboxWrapper>
				<Checkbox checked={checked} onCheckedChange={onValueChange} label={checked ? 'Enabled' : 'Disabled'} />
			</CheckboxWrapper>
			<SectionDescription data-cy='description' size='small' font='mono' color='neutral24'>
				{description}
			</SectionDescription>
			{children}
		</SectionContent>
	);
}
