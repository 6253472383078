import {Dispatch, useCallback, useState} from 'react';
import {AddEntitiesIntoFolder, FolderType} from '@esgi/main/features/admins/data';
import {DistrictReportPanel, Page} from '../../../../_kit';
import {useDataPageContext} from '../../../../layout/context';
import {RowDef} from '../../../types';
import {BottomPanel} from './bottom-panel';
import {addButtonDisabledTooltipMessage} from '../../constants';
import {SingleItemExpandablePanel} from './single-item-expandable-panel';

type Props = {
	userCanAddAndDeleteTeachers: boolean;
	editButtonDisabled: boolean;
	onOpenAddDrawer: VoidFunction;
	onEditClick: Dispatch<RowDef>;
};

export function Content({userCanAddAndDeleteTeachers, editButtonDisabled, onOpenAddDrawer, onEditClick}: Props) {
	const {entityFolders, districtName} = useDataPageContext();
	const {selectedItems, api} = Page.useContext<RowDef>();

	const [showAddEntitiesIntoFolder, setShowAddEntitiesIntoFolder] = useState(false);

	const openAddEntitiesIntoFolder = useCallback(() => {
		setShowAddEntitiesIntoFolder(true);
	}, []);

	const closeAddEntitiesIntoFolder = useCallback(() => {
		setShowAddEntitiesIntoFolder(false);
	}, []);

	return (
		<>
			<Page.Header.Base
				onAddButtonClick={onOpenAddDrawer}
				addButtonDisabled={!userCanAddAndDeleteTeachers}
				addButtonDisabledTooltipMessage={addButtonDisabledTooltipMessage}
			/>
			<Page.Body.Base />

			<SingleItemExpandablePanel
				editButtonDisabled={editButtonDisabled}
				onFolderAddClick={openAddEntitiesIntoFolder}
				onEditClick={onEditClick}
				userCanAddAndDeleteTeachers={userCanAddAndDeleteTeachers}
				gridApi={api}
			/>
			<DistrictReportPanel api={api} title={districtName} />

			<BottomPanel onFolderAddClick={openAddEntitiesIntoFolder} />

			{showAddEntitiesIntoFolder && (
				<AddEntitiesIntoFolder
					addEntityIDs={selectedItems.map(({id}) => id)}
					folders={(entityFolders ?? []).map(({id, name}) => ({id, name}))}
					initialFolderID={null}
					onCloseAlert={closeAddEntitiesIntoFolder}
					folderType={FolderType.Teacher}
				/>
			)}
		</>
	);
}
