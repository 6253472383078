import {HierarchyNavigationCard} from '@esgi/main/features/admins/data';
import {CloseButton} from './components/close-button';
import {CounterBox} from './components/counter-box';
import {Root} from './components/root';
import {ShowOnlySelectedCheckbox} from './components/show-only-selected-checkbox';
import {ActionButtonDivider, ActionButtonsBox, ContentBox, MainBox} from './components/styled';
import {Header} from './components/header';

export const ActionPanel = {
	Root,
	Header,
	ContentBox,
	MainBox,
	ActionButtonsBox,
	ActionButtonDivider,
	CloseButton,
	CounterBox,
	ShowOnlySelectedCheckbox,
	Navigation: HierarchyNavigationCard,
};
