import * as React from 'react';
import type {SVGProps} from 'react';

export function DataBase(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='1em'
			height='1em'
			fill='none'
			viewBox='0 0 24 24'
			{...props}
		>
			<path fillRule='evenodd' clipRule='evenodd'
						d='M7.5 9.48437C6.56645 8.95552 6 8.26083 6 7.5V16.5C6 17.0643 6.27002 17.5391 6.62876 17.9026C6.98432 18.2629 7.46164 18.5529 7.99225 18.7803C9.05614 19.2363 10.4736 19.5 12 19.5C13.5264 19.5 14.9439 19.2363 16.0078 18.7803C16.5384 18.5529 17.0157 18.2629 17.3712 17.9026C17.73 17.5391 18 17.0643 18 16.5V7.5C18 8.26083 17.4335 8.95552 16.5 9.48437V12C16.5 12.057 16.4762 12.1741 16.3036 12.349C16.1278 12.5271 15.8363 12.7219 15.4169 12.9016C14.5806 13.26 13.3731 13.5 12 13.5C10.6269 13.5 9.41936 13.26 8.58313 12.9016C8.16368 12.7219 7.87215 12.5271 7.69638 12.349C7.52379 12.1741 7.5 12.057 7.5 12V9.48437ZM7.5 16.5V14.0417C7.65708 14.1279 7.82192 14.2074 7.99225 14.2803C9.05614 14.7363 10.4736 15 12 15C13.5264 15 14.9439 14.7363 16.0078 14.2803C16.1781 14.2074 16.3429 14.1279 16.5 14.0417V16.5C16.5 16.557 16.4762 16.6741 16.3036 16.849C16.1278 17.0271 15.8363 17.2219 15.4169 17.4016C14.5806 17.76 13.3731 18 12 18C10.6269 18 9.41936 17.76 8.58313 17.4016C8.16368 17.2219 7.87215 17.0271 7.69638 16.849C7.52379 16.6741 7.5 16.557 7.5 16.5Z'
						fill='#7A7A7A' />
			<path fillRule='evenodd' clipRule='evenodd'
						d='M16.3833 7.30103C16.2465 7.1393 15.9897 6.92926 15.5718 6.72032C14.7352 6.30202 13.4745 6 12 6C10.5255 6 9.26479 6.30202 8.42818 6.72032C8.01029 6.92926 7.75353 7.1393 7.61672 7.30103C7.48801 7.45318 7.5 7.49339 7.5 7.5C7.5 7.50661 7.48801 7.54682 7.61672 7.69897C7.75353 7.8607 8.01029 8.07074 8.42818 8.27968C9.26479 8.69798 10.5255 9 12 9C13.4745 9 14.7352 8.69798 15.5718 8.27968C15.9897 8.07074 16.2465 7.8607 16.3833 7.69897C16.4489 7.62142 16.4999 7.56041 16.5 7.5C16.5001 7.43863 16.4483 7.37788 16.3833 7.30103ZM12 10.5C15.3137 10.5 18 9.15685 18 7.5C18 5.84315 15.3137 4.5 12 4.5C8.68629 4.5 6 5.84315 6 7.5C6 9.15685 8.68629 10.5 12 10.5Z'
						fill='#7A7A7A' />
		</svg>

	);
}
