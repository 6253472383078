import {TestType} from '@esgi/main/kits/common';
import {createContext, useContext} from 'react';
import {isNull} from 'underscore';

export type TestPanelCardContextValue = {
	testResultsCorrectVerbiage: string;
	testType: TestType;
};

export const TestPanelCardContext = createContext<TestPanelCardContextValue | null>(null);

export function useTestPanelCardContext() {
	const context = useContext(TestPanelCardContext);

	if (isNull(context)) {
		throw new Error('useTestPanelCardContext is null');
	}

	return context;
}
