import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {AlertBody, AlertFooter, AlertHeader, alertRootCss, ClassNameBox} from './index.styled';
import {Button} from '@esgi/ui';
import {Students} from '@esgi/ui/icons';
import {OneLinedText} from '@esgi/main/kits/common';
import {Class} from '@esgi/main/libs/admin-store';
import {useRemove} from './use-remove-class';

type Props = {
	onDeleted: VoidFunction;
	onClose: VoidFunction;
	className: string;
	classID: Class['id'];
};

export function DeleteClassAlert({onClose, onDeleted, className, classID}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const {isClassRemoving, onRemoveClass} = useRemove({
		classID,
		className,
		onRemoved: onDeleted,
	});

	return (
		<Alert modalManagerRef={alertRef} colorConfig={alertColorsConfig.negative} css={alertRootCss}>
			<AlertHeader withBacklight={false} onCloseIconClick={closeAlert}>
				<Text size='small'>Delete Class?</Text>

				<ClassNameBox>
					<Students />
					<OneLinedText size='small' color='highContrast'>
						{className}
					</OneLinedText>
				</ClassNameBox>
			</AlertHeader>

			<AlertBody>
				<Text size='small' color='highContrast'>
					Are you sure you want to delete the following class?
				</Text>
				<Text size='small' color='highContrast'>
					This will NOT delete students and their data. The students still will be available and can be added to another
					class.
				</Text>
			</AlertBody>

			<AlertFooter>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>

					<Button color='warn' onClick={onRemoveClass} disabled={isClassRemoving}>
						<Text size='medium' bold color='negative'>
							Delete
						</Text>
					</Button>
				</GridBox>
			</AlertFooter>
		</Alert>
	);
}
