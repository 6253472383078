import {PublishForSpecialistsTabID, PublishForTeacherTabID} from '../types';

export const publishToTeachetsTitle: Record<PublishForTeacherTabID, string> = {
	[PublishForTeacherTabID.All]: 'All',
	[PublishForTeacherTabID.BySchool]: 'By School',
};

export const publishToSpecialistsTitle: Record<PublishForSpecialistsTabID, string> = {
	[PublishForSpecialistsTabID.All]: 'All',
	[PublishForSpecialistsTabID.BySchool]: 'School',
	[PublishForSpecialistsTabID.BySpecialist]: 'District',
};
