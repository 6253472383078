import {Card} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';
import {LabelBox} from '../label/index.styled';

export const CardRoot = styled(Card, {
	display: 'grid',
	gap: '$3',
	cursor: 'pointer',
	userSelect: 'none',

	variants: {
		isSelected: {
			true: {
				borderColor: '$primaryHighlight',
				backgroundColor: '$primarySurface',
				boxShadow: 'none',
			},

			false: {
				borderColor: '$border',
				backgroundColor: '$surface',
			},
		},
	},
});

export const CardHeader = styled(Card.Header, {
	display: 'grid',
	gap: '$2',
});

export const CardBody = styled(Card.Body, {
	display: 'block',
	marginBottom: '-$2',

	[`> ${LabelBox}`]: {
		marginRight: '$2',
		marginBottom: '$2',
	},
});

export const CardSeparator = styled(Card.Separator, {
	width: '100%',
	marginLeft: 'unset',
	marginRight: 'unset',

	variants: {
		backgroundColor: {
			border: {
				backgroundColor: '$border',
			},

			primaryMuted: {
				backgroundColor: '$primaryMuted',
			},
		},
	},
});
