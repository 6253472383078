import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {GridBox} from '@esgi/ui/layout';
import {AlertBody, AlertFooter, AlertHeader, alertRootCss, DoNotShowBox, StudentNameBox} from './index.styled';
import {Button} from '@esgi/ui';
import {Student} from '@esgi/ui/icons';
import {colorConfig} from './constants';
import {getFullName, OneLinedText} from '@esgi/main/kits/common';
import {Dispatch} from 'react';
import {CheckedState} from '@radix-ui/react-checkbox';
import {Checkbox} from '../checkbox.styled';

type Props = {
	onRemove: VoidFunction;
	onClose: VoidFunction;
	firstName: string;
	lastName: string;
	checkboxValue: boolean;
	setCheckboxValue: Dispatch<boolean>;
};

export function ResetSingleStudentAlert({
	onClose,
	onRemove,
	firstName,
	lastName,
	checkboxValue,
	setCheckboxValue,
}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const handleRemoveClick = () => {
		onRemove();
		closeAlert();
	};

	const handleDoNotShowValueChanged = (value: CheckedState) => {
		setCheckboxValue(Boolean(value));
	};

	return (
		<Alert modalManagerRef={alertRef} colorConfig={colorConfig} css={alertRootCss}>
			<AlertHeader withBacklight={false} onCloseIconClick={closeAlert}>
				<Text size='small'>Remove student</Text>

				<StudentNameBox>
					<Student />
					<OneLinedText size='small' color='highContrast'>
						{getFullName({firstName, lastName})}
					</OneLinedText>
				</StudentNameBox>
			</AlertHeader>

			<AlertBody>
				<Text size='small' color='highContrast'>
					You are about to remove this student from the list.This cannot be undone when the changes are saved.
				</Text>

				<DoNotShowBox>
					<Checkbox checked={checkboxValue} onCheckedChange={handleDoNotShowValueChanged} />
					<Text size='small' font='mono' color='highContrast'>
						Don’t remind me again until the next opening of the panel
					</Text>
				</DoNotShowBox>
			</AlertBody>

			<AlertFooter>
				<GridBox gap='3' flow='column'>
					<Button color='tertiary' onClick={closeAlert}>
						<Text size='medium' bold color='base'>
							Cancel
						</Text>
					</Button>

					<Button color='warn' onClick={handleRemoveClick}>
						<Text size='medium' bold color='negative'>
							Remove
						</Text>
					</Button>
				</GridBox>
			</AlertFooter>
		</Alert>
	);
}
