import {routes} from '@esgi/main/libs/core';
import {
	Root,
	Dashboard,
	DataLayout,
	SubjectManagement,
	TestExplorer,
	StudentManager,
	NotFound,
	DataSchools,
	DataTeachers,
	DataSpecialists,
	DataClasses,
	DataGroups,
	DataSpecialistGroups,
	DataStudents,
} from '@esgi/main/pages/district-admin';
import {Navigate, Route} from 'react-router-dom';
import {withErrorHandling} from '@esgillc/ui-kit/core';

const WrappedDataLayout = withErrorHandling(DataLayout);

export function RedesignRoutesDistrictAdmin() {
	return (
		<Route element={<Root />}>
			<Route index element={<Navigate to={routes.districtAdmin.dashboard} />} />
			<Route path={routes.districtAdmin.dashboard} element={<Dashboard />} />
			<Route path={routes.districtAdmin.data.root} element={<WrappedDataLayout />}>
				<Route path={routes.districtAdmin.data.schools} element={<DataSchools />} />
				<Route path={routes.districtAdmin.data.teachers} element={<DataTeachers />} />
				<Route path={routes.districtAdmin.data.specialists} element={<DataSpecialists />} />
				<Route path={routes.districtAdmin.data.classes} element={<DataClasses />} />
				<Route path={routes.districtAdmin.data.groups} element={<DataGroups />} />
				<Route path={routes.districtAdmin.data.specialistGroups} element={<DataSpecialistGroups />} />
				<Route path={routes.districtAdmin.data.students} element={<DataStudents />} />
			</Route>
			<Route path={routes.districtAdmin.subjectManagement} element={<SubjectManagement />} />
			<Route path={routes.districtAdmin.testExplorer} element={<TestExplorer />} />
			<Route path={routes.districtAdmin.studentManager} element={<StudentManager />} />
			<Route path='*' element={<NotFound />} />
		</Route>
	);
}
