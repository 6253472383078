import {useDrawerClose, useDrawerRef} from '@esgi/main/kits/common';
import {useCallback, useState} from 'react';
import {useSpecialistGroups, useDistrictSpecialists, useSchoolSpecialists, useSchools, useStudents} from '@esgi/main/libs/admin-store';

export function useDrawerState({onClose}: {onClose: VoidFunction}) {
	const [{data: specialistGroups, loaded: isSpecialistGroupsLoaded}] = useSpecialistGroups();
	const [{data: districtSpecialists, loaded: isDistrictSpecialistsLoaded}] = useDistrictSpecialists();
	const [{data: schoolSpecialists, loaded: isSchoolSpecialistsLoaded}] = useSchoolSpecialists();
	const [{data: schools, loaded: isSchoolsLoaded}] = useSchools();
	const [{data: studentsList, loaded: isStudentsListLoaded}] = useStudents();

	const drawerRef = useDrawerRef();
	const closeDrawer = useDrawerClose(drawerRef, onClose);

	const [withoutResetSingleStudentAlert, setWithoutResetSingleStudentAlert] = useState(false);

	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isFormValid, setIsFormValid] = useState(true);

	const [showUnsavedChangesConfirmation, setShowUnsavedChangesConfirmation] = useState(false);

	const openUnsavedChangesConfirmation = useCallback(() => {
		setShowUnsavedChangesConfirmation(true);
	}, []);

	const closeUnsavedChangesConfirmation = useCallback(() => {
		setShowUnsavedChangesConfirmation(false);
	}, []);

	const onDrawerClose = useCallback(() => {
		if (isFormTouched) {
			openUnsavedChangesConfirmation();

			return;
		}

		closeDrawer();
	}, [closeDrawer, isFormTouched, openUnsavedChangesConfirmation]);

	return {
		drawerRef,
		isFormTouched,
		setIsFormTouched,
		isFormValid,
		setIsFormValid,
		showUnsavedChangesConfirmation,
		closeUnsavedChangesConfirmation,
		closeDrawer,
		onDrawerClose,
		withoutResetSingleStudentAlert,
		setWithoutResetSingleStudentAlert,
		specialistGroups,
		schoolSpecialists,
		districtSpecialists,
		isDistrictSpecialistsLoaded,
		schools,
		isSchoolsLoaded,
		studentsList,
		skeleton:
			!isSpecialistGroupsLoaded ||
			!isSchoolSpecialistsLoaded ||
			!isDistrictSpecialistsLoaded ||
			!isSchoolsLoaded ||
			!isStudentsListLoaded,
	};
}
