import {ComponentPropsWithoutRef, forwardRef, useMemo} from 'react';
import {getThemeColor, ThemeColorKeys} from '@esgi/ui';
import {Property} from '@stitches/react/types/css';
import {useBarChartContext} from '../../context';
import {Container, PointerBox} from './index.styled';
import {useOpacityAnimation} from '../../hooks/use-opacity-animation';

type Props = Omit<ComponentPropsWithoutRef<typeof Container>, 'children'> & {
	size: number;
	value: number;
	backgroundColor?: ThemeColorKeys;
	borderColor?: ThemeColorKeys;
	borderWidth?: Property.BorderWidth;
};

export const Pointer = forwardRef<HTMLDivElement, Props>(
	(
		{
			dataCy = 'bar-chart-pointer',
			css = {},
			size,
			backgroundColor = 'surface',
			borderColor = 'negative',
			value,
			borderWidth = 1.5,
			...divProps
		},
		forwardedRef,
	) => {
		const {getValueProgress} = useBarChartContext();

		const {valueProgress} = useMemo(() => {
			const valueProgress = getValueProgress(value);

			return {
				valueProgress,
			};
		}, [getValueProgress, value]);

		const animationProps = useOpacityAnimation();

		return (
			<Container
				dataCy={dataCy}
				css={{
					height: size,
					...css,
				}}
				ref={forwardedRef}
				{...divProps}
			>
				<PointerBox
					style={animationProps}
					css={{
						left: `calc(${valueProgress * 100}% - ${size / 2}px)`,
						width: size,
						height: size,
						backgroundColor: getThemeColor(backgroundColor),
						borderWidth,
						borderColor: getThemeColor(borderColor),
					}}
				/>
			</Container>
		);
	},
);
