import {FieldValidation} from './types';

export const errorTooltipItemList = [
	{
		showOnError: FieldValidation.Required,
		message: 'Field is required.',
	},
	{
		showOnError: FieldValidation.AssignRequired,
		message: 'You have to assign the student to at least 1 class',
	},
];

export const photo = {
	crop: undefined,
	imageBase64: undefined,
	imageCropBase64: undefined,
	contentType: undefined,
	remove: false,
};