import {ExpandableSelectableBlockTab} from '@esgi/main/features/admins/subject-management';
import {isTwoPrimitiveArraysEqual} from './is-two-primitive-arrays-equal';

export function isTwoExpandableSelectableBlockEqual<T extends string>(
	block1: ExpandableSelectableBlockTab<T>[],
	block2: ExpandableSelectableBlockTab<T>[],
) {
	const block1SelectedTab = block1.find(({isActive}) => isActive);
	const block2SelectedTab = block2.find(({isActive}) => isActive);

	if (block1SelectedTab?.value !== block2SelectedTab?.value) {
		return false;
	}

	return isTwoPrimitiveArraysEqual(block1SelectedTab?.selectedItems ?? [], block2SelectedTab?.selectedItems ?? []);
}
