import {Button, ContextMenu as ContextMenuUI} from '@esgi/ui';
import {Delete, More} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {useCallback, useState} from 'react';
import {SpecialistGroup} from '@esgi/main/libs/admin-store';
import {RemoveSpecialistGroup} from '../../../../../remove-specialist-group';
import {isNull} from 'underscore';

type Props = {
	closeDrawer: VoidFunction;
	specialistGroupID: SpecialistGroup['id'];
};

export function ContextMenu({closeDrawer, specialistGroupID}: Props) {
	const removeSpecialistGroupManagerRef = RemoveSpecialistGroup.useManagerRef();
	const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

	const openDeleteAlert = useCallback(() => {
		if (!isNull(removeSpecialistGroupManagerRef.current)) {
			removeSpecialistGroupManagerRef.current.runRemove({specialistGroupID});
			setIsContextMenuOpen(false);
		}
	}, [removeSpecialistGroupManagerRef, specialistGroupID]);

	return (
		<>
			<ContextMenuUI.Root open={isContextMenuOpen} onOpenChange={setIsContextMenuOpen}>
				<ContextMenuUI.Trigger>
					<Button.Icon withBackgroundHover>
						<More />
					</Button.Icon>
				</ContextMenuUI.Trigger>
				<ContextMenuUI.Content>
					<ContextMenuUI.Group>
						<ContextMenuUI.Item onClick={openDeleteAlert}>
							<Delete />
							<Text size='medium'>Delete</Text>
						</ContextMenuUI.Item>
					</ContextMenuUI.Group>
				</ContextMenuUI.Content>
			</ContextMenuUI.Root>

			<RemoveSpecialistGroup managerRef={removeSpecialistGroupManagerRef} onRemoved={closeDrawer} />
		</>
	);
}
