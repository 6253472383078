import {ReportDialog, Reports} from '../../../../../../_kit';
import {useMemo} from 'react';
import {RowDef} from '../../../../../types';
import {Hierarchy} from '@esgi/main/features/school-admin/data';

type Props = {
	selectedClass: RowDef;
	onClose: VoidFunction;
};

export function ClassesReportsDialog({selectedClass, onClose}: Props) {
	const hierarchy = useMemo<Hierarchy>(
		() => ({
			teacherID: selectedClass.teacher.id,
			classID: selectedClass.id,
			schoolID: selectedClass.teacher?.schoolID,
		}),
		[selectedClass]
	);

	return (
		<ReportDialog
			header={selectedClass.name}
			title='Student and Class Reports'
			onClose={onClose}
		>
			<Reports.ParentLetter hierarchy={hierarchy} />
			<Reports.StudentDetail hierarchy={hierarchy} />
			<Reports.StudentProgress hierarchy={hierarchy} />
			<Reports.Totals prefix='Class' hierarchy={hierarchy} />
			<Reports.GradeScale prefix='Class' hierarchy={hierarchy} />
			<Reports.ItemAnalysis hierarchy={hierarchy} />
			<Reports.PieCharts hierarchy={hierarchy} />
			<Reports.RubricResults hierarchy={hierarchy} />
			<Reports.UntestedStudents hierarchy={hierarchy} />
		</ReportDialog>
	);
}
