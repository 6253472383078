import React, {forwardRef, ReactNode, useEffect, useMemo, useState} from 'react';
import {DPCalendar, DPCalendarConfig, useDatePicker} from '@rehookify/datepicker';
import {BaseComponentProps, Skeletonable} from '../../../../types';
import {SkeletonStylesWithBorders} from '../../../../skeleton';
import {AddOffset, DayButtonType, DateFormat, SubtractOffset} from '../../types';
import {DatePickerContext, DatePickerContextValue} from '../../context';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {parseDateRangeToString} from '../../utils';
import {styled} from '@esgi/ui/theme';
import {Box} from '../../../../layout/box';
import {isUndefined} from '../../../../utils';
import {isEqual} from 'underscore';

type DatePickerRootProps = {
	/** Optional. Callback for when selected dates change. */
	onChange?: (values: Date[]) => void;

	/** Optional. If true, enables range date selection.
	 * @default false
	 * */
	rangeMode?: boolean;

	/** Optional. Change date format.
	 * @default mM/dD/YYYY
	 * */
	format?: DateFormat;

	/** Optional. Initial date(s) for the date picker. */
	value?: Date[];

	/** Optional. Indicates an error state for the date picker.
	 * @default false
	 * */
	error?: boolean;

	/** Optional. Date Picker Dates Display Mode.
	 * See docs (Configuration -> Calendar -> mode)
	 * @default 'static'
	 */
	displayMode?: DPCalendarConfig['mode'],

	/** Optional. Date picker disable state.
	 * @default false
	 * */
	disabled?: boolean;

	/**
	 * Min date for calendar panel.
	 */
	minCalendarDate?: Date | undefined

	/**
	 * Max date for calendar panel.
	 */
	maxCalendarDate?: Date | undefined

	/** Optional. Child components or a function returning a JSX element. */
	children?:
		| ReactNode
		| ((args: {
		calendar: DPCalendar;
		weekDays: string[];
		dayButton: DayButtonType;
		addOffset: AddOffset;
		subtractOffset: SubtractOffset;
	}) => React.JSX.Element);
} & BaseComponentProps & Skeletonable


export const DatePickerRoot = forwardRef<HTMLDivElement, DatePickerRootProps>(
	(
		{
			children,
			rangeMode = false,
			format = 'mM/dD/YYYY',
			value,
			onChange,
			error = false,
			displayMode = 'static',
			dataCy = 'ui-kit-date-picker-root',
			disabled,
			minCalendarDate,
			maxCalendarDate,
			...props
		},
		forwardedRef,
	) => {

		const [selectedDates, setSelectedDates] = useState<Date[]>([]);
		const [expanded, setExpanded] = useState<boolean>(false);
		const [dateValue, setDateValue] = useState<string>('');
		const [approvedDate, setApprovedDate] = useState<Date[]>(value || []);
		const [isCalendarRendered, setCalendarRendered] = useState<boolean>(false);

		const [offsetDate, onOffsetChange] = useState<Date>(() => {
			const currentDate = new Date();
			const currentDateTime = currentDate.getTime();

			const isCurrentDateBeforeMinDate = !isUndefined(minCalendarDate) && minCalendarDate.getTime() > currentDateTime;
			const isCurrentDateAfterMaxDate = !isUndefined(maxCalendarDate) && maxCalendarDate.getTime() < currentDateTime;

			if(!isUndefined(minCalendarDate) && isCurrentDateAfterMaxDate) {
				return minCalendarDate;
			}

			if(isCurrentDateBeforeMinDate) {
				return minCalendarDate;
			}

			if(isCurrentDateAfterMaxDate) {
				return maxCalendarDate;
			}

			return currentDate;
		});

		const {
			data: {calendars, weekDays, selectedDates: dateObjects},
			propGetters: {dayButton, addOffset, subtractOffset},
		} = useDatePicker({
			offsetDate,
			onOffsetChange,
			selectedDates,
			onDatesChange: setSelectedDates,
			dates: {
				toggle: true,
				mode: rangeMode ? 'range' : 'single',
				minDate: minCalendarDate,
				maxDate: maxCalendarDate,
			},
			calendar: {
				startDay: 1,
				mode: displayMode,
			},
		});

		useEffect(() => {
			if(!isEqual(approvedDate, value)) {
				onChange?.(approvedDate);
			}
		}, [approvedDate]);

		useEffect(() => {
			if (value !== undefined) {
				setDateValue(parseDateRangeToString(value, format));
				setSelectedDates(value);
				setApprovedDate(value);
			}
		}, [value]);

		const context = useMemo<DatePickerContextValue>(
			() => ({
				expanded,
				calendars,
				weekDays,
				dayButton,
				addOffset,
				subtractOffset,
				setExpanded,
				rangeMode,
				dateValue,
				setDateValue,
				setSelectedDates,
				isCalendarRendered,
				setCalendarRendered,
				approvedDate,
				setApprovedDate,
				error,
				format,
				dateObjects,
				disabled,
			}),
			[
				expanded,
				setExpanded,
				rangeMode,
				setSelectedDates,
				dateValue,
				setDateValue,
				approvedDate,
				setApprovedDate,
				isCalendarRendered,
				setCalendarRendered,
				addOffset,
				subtractOffset,
				dayButton,
				weekDays,
				calendars,
				error,
				format,
				dateObjects,
				disabled,
			],
		);
		return <DatePickerContext.Provider value={context}>
		<Root data-cy={dataCy} {...props} ref={forwardedRef}>
			<DropdownMenu.Root open={expanded} onOpenChange={(open) => {
				setExpanded(open);
				if (approvedDate?.filter(Boolean)?.length) {
					setSelectedDates(approvedDate);
					onOffsetChange(approvedDate[0]);
				}
			}
			}>
				{typeof children === 'function'
					? children({
						calendar: calendars[0],
						weekDays,
						dayButton,
						addOffset,
						subtractOffset,
					})
					: children}
			</DropdownMenu.Root>
		</Root>

	</DatePickerContext.Provider>;
	});

const Root = styled(Box, {
	position: 'relative',

	variants: {
		skeleton: {
			true: {
				...SkeletonStylesWithBorders,
			},
		},
	},
});
