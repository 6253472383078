import {isNull} from 'underscore';
import {StockSubject, SubjectType, SubjectTypeContract} from '../types';

export function isStockSubject(item: unknown): item is StockSubject {
	if (typeof item === 'object' && !isNull(item) && 'subjectType' in item) {
		const subjectType = item.subjectType;

		if (typeof subjectType === 'string') {
			return subjectType === SubjectType.Stock;
		}

		if (typeof subjectType === 'number') {
			return subjectType === SubjectTypeContract.Stock;
		}
	}

	return false;
}
