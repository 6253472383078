import {useCallback, useEffect, useMemo, useState} from 'react';
import {TestPanelCard} from '../../../types';
import {SortingComparatorCb} from '../types';
import {useFiltersContext} from '../context';

type SortableItem<T extends TestPanelCard> = {
	label: string;
	comparator?: SortingComparatorCb<T> | undefined;
};

type Props<T extends TestPanelCard, I extends string> = {
	defaultItem: I;
	items: Record<I, SortableItem<T>>;
};

export function useSortingData<T extends TestPanelCard, I extends string>({defaultItem, items}: Props<T, I>) {
	const {setSortableComparator} = useFiltersContext<T>();

	const [activeItem, setActiveItem] = useState<I>(defaultItem);

	useEffect(() => {
		const comparator = items[defaultItem].comparator;

		setSortableComparator(comparator ?? null);

		return () => {
			setSortableComparator(null);
		};
	}, []);

	const updateActiveItem = useCallback(
		(itemValue: I) => {
			setActiveItem(itemValue);

			const comparator = items[itemValue].comparator;

			setSortableComparator(() => comparator ?? null);
		},
		[items, setSortableComparator],
	);

	const outItems = useMemo(
		() =>
			Object.entries<SortableItem<T>>(items).map(([value, {label}]) => ({
				value,
				label,
			})),
		[items],
	);

	return {
		activeItem,
		setActiveItem: updateActiveItem,
		sortableItems: outItems,
	};
}
