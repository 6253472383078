import {Button, isUndefined} from '@esgi/ui';
import {Close} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {AlertFooter, SelectedStudentsBox} from './index.styled';
import {CounterWithTranscript} from '../counter-with-transcript';
import {GridApi, IRowNode} from 'ag-grid-community';
import {Dispatch, useCallback} from 'react';
import {StudentRowModel} from '../../../students-table-utils';

type Props<T extends StudentRowModel> = {
	api: GridApi | null;
	disabledRowsAsSelectedIDs: StudentRowModel['id'][];
	onAdd: Dispatch<StudentRowModel['id'][]>;
	closeAlert: VoidFunction;
	selectedRows: T[];
};

export function Footer<T extends StudentRowModel>({
	api,
	disabledRowsAsSelectedIDs,
	onAdd,
	closeAlert,
	selectedRows,
}: Props<T>) {
	const deselectAllSelectedItems = useCallback(() => {
		api?.deselectAll();
	}, [api]);

	const handleAddClick = useCallback(() => {
		const selectedRowsIDs: StudentRowModel['id'][] = [...disabledRowsAsSelectedIDs];

		api?.forEachNode((node: IRowNode) => {
			const data = node.data;

			if (!isUndefined(data) && node.isSelected()) {
				selectedRowsIDs.push(data.id);
			}
		});

		onAdd(selectedRowsIDs);
		closeAlert();
	}, [api, closeAlert, disabledRowsAsSelectedIDs, onAdd]);

	const hasSelectedRows = Boolean(selectedRows.length);

	return (
		<AlertFooter>
			<SelectedStudentsBox>
				<Button.Icon withBackgroundHover onClick={deselectAllSelectedItems} disabled={!hasSelectedRows}>
					<Close />
				</Button.Icon>

				<CounterWithTranscript count={selectedRows.length}>
					<Text size='xSmall' font='mono' color='primary'>
						Students Selected
					</Text>
				</CounterWithTranscript>
			</SelectedStudentsBox>

			<Button color='secondary' onClick={handleAddClick} disabled={!hasSelectedRows}>
				<Text size='medium' bold color='base'>
					Add
				</Text>
			</Button>
		</AlertFooter>
	);
}
