import * as React from 'react';
import type {SVGProps} from 'react';

export function Subject(props: SVGProps<SVGSVGElement>): JSX.Element {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none' {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.88889 10.3333C8.88889 8.76853 10.1574 7.5 11.7222 7.5H21.1667C22.7315 7.5 24 8.76853 24 10.3333V21.6667C24 23.2315 22.7315 24.5 21.1667 24.5H11.7222C10.1574 24.5 8.88889 23.2315 8.88889 21.6667V10.3333ZM11.7222 9.38889C11.2006 9.38889 10.7778 9.81173 10.7778 10.3333V21.6667C10.7778 22.1883 11.2006 22.6111 11.7222 22.6111H21.1667C21.6883 22.6111 22.1111 22.1883 22.1111 21.6667V10.3333C22.1111 9.81173 21.6883 9.38889 21.1667 9.38889H11.7222Z'
				fill='#333333'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.94444 19.8958H8.88889V18.0069H7.94444C7.42284 18.0069 7 18.4298 7 18.9514C7 19.473 7.42284 19.8958 7.94444 19.8958ZM8.88889 13.9931V12.1042H7.94444C7.42284 12.1042 7 12.527 7 13.0486C7 13.5702 7.42284 13.9931 7.94444 13.9931H8.88889ZM16.1678 18.5567L19.9456 14.7789C20.3144 14.4101 20.3144 13.8121 19.9456 13.4433C19.5768 13.0745 18.9788 13.0745 18.61 13.4433L15.5 16.5532L14.2789 15.3322C13.9101 14.9633 13.3121 14.9633 12.9433 15.3322C12.5745 15.701 12.5745 16.299 12.9433 16.6678L14.8322 18.5567C15.201 18.9255 15.799 18.9255 16.1678 18.5567Z'
				fill='#333333'
			/>
		</svg>
	);
}
