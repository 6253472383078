import {SubjectPanel, Entities, TabID, PanelMode, DistrictSubject} from '@esgi/main/features/admins/subject-management';
import {Subject} from '@esgi/ui/icons';
import {Container, TitleContainer} from './index.styled';
import {Dispatch} from 'react';
import {Text} from '@esgi/ui/typography';

type Props = {
	skeleton: boolean;
	entities: Entities;
	onActiveTabChanged: Dispatch<TabID | null>;
	selectedSubjectID: number | null;
	setSelectedSubjectID: Dispatch<number | null>;
	subjectsPanelMode: PanelMode;
	onSubjectsPanelModeChange: Dispatch<PanelMode>;
	onRearrangeDistrictSubjects: Dispatch<DistrictSubject[]>;
	isSubjectHasUnsavedChanges: boolean;
};

export function SubjectsPanel({
	entities,
	skeleton,
	onActiveTabChanged,
	selectedSubjectID,
	setSelectedSubjectID,
	subjectsPanelMode,
	onSubjectsPanelModeChange,
	onRearrangeDistrictSubjects,
	isSubjectHasUnsavedChanges,
}: Props) {
	const isRearrangeMode = subjectsPanelMode === PanelMode.Rearrange;

	return (
		<Container>
			<SubjectPanel.Root
				skeleton={skeleton}
				entities={entities}
				initialTab={TabID.District}
				onActiveTabChanged={onActiveTabChanged}
				selectedSubjectID={selectedSubjectID}
				onSelectedSubjectIDChanged={setSelectedSubjectID}
				panelMode={subjectsPanelMode}
				onPanelModeChanged={onSubjectsPanelModeChange}
				isSubjectHasUnsavedChanges={isSubjectHasUnsavedChanges}
			>
				<TitleContainer isRearrangeMode={isRearrangeMode}>
					<SubjectPanel.SectionTitle.Icon fill='mediumContrast' Component={Subject} />
					<SubjectPanel.SectionTitle.Title size='small' color='mediumContrast'>
						Subject Tab Manager
					</SubjectPanel.SectionTitle.Title>
					{isRearrangeMode && (
						<Text size='small' color='primary'>
							Rearrange Mode
						</Text>
					)}
					<SubjectPanel.RearrangeButton />
				</TitleContainer>

				<SubjectPanel.Tabs.Bar />

				<SubjectPanel.Tabs.Content.Root tabID={TabID.District} tabContent='District'>
					<SubjectPanel.Tabs.Content.AddSubjectButton />
					<SubjectPanel.Tabs.Content.ViewMode
						hiddenIconText='To activate this tab, select it, then check the Active box in Subject Tab Settings.'
						subjects={entities.districtSubjects}
					/>
					<SubjectPanel.Tabs.Content.RearrangeMode
						subjects={entities.districtSubjects}
						onSaveClick={(subjects) => onRearrangeDistrictSubjects(subjects as DistrictSubject[])}
					/>
				</SubjectPanel.Tabs.Content.Root>

				<SubjectPanel.Tabs.Content.Root tabID={TabID.Stock} tabContent='Stock'>
					<SubjectPanel.Tabs.Content.ViewMode subjects={entities.stockSubjects} />
				</SubjectPanel.Tabs.Content.Root>
			</SubjectPanel.Root>
		</Container>
	);
}
