import React, {ComponentProps, MouseEventHandler, useMemo} from 'react';
import {isNull} from 'underscore';
import {Tooltip} from '@esgi/ui';
import {Avatar} from '@esgi/ui/avatar';
import {Profile} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {AvatarFallback, AvatarFallbackContentBox, AvatarHoverLayer, avatarRootCSS} from './index.styled';

type Props = {
	onClick: MouseEventHandler<HTMLDivElement> | undefined;
	size: ComponentProps<typeof Avatar.Root>['size'];
	firstName: string;
	lastName: string;
	avatarSrc: string | null;
};

export function UserAccountButton({onClick, size, avatarSrc, firstName, lastName}: Props) {
	const initials = useMemo(() => `${firstName[0]}${lastName[0]}`.toUpperCase(), [firstName, lastName]);

	return (
		<Tooltip>
			<Tooltip.Trigger>
				<Avatar.Root onClick={onClick} size={size} borderType='solid' css={avatarRootCSS}>
					<Avatar.Image src={avatarSrc ?? undefined} />
					<AvatarFallback>
						<AvatarFallbackContentBox>
							<Profile />
							<Text size='small' color='base'>
								{initials}
							</Text>
						</AvatarFallbackContentBox>
					</AvatarFallback>
					{!isNull(avatarSrc) && <AvatarHoverLayer />}
				</Avatar.Root>
			</Tooltip.Trigger>
			<Tooltip.Content variant='secondary'>
				<Text size='small'>My Account</Text>
			</Tooltip.Content>
		</Tooltip>
	);
}
