import {useDisposable} from '@esgi/core/service';
import {V2SchoolAdminsPagesDataController} from '@esgi/contracts/esgi';
import {useCallback, useEffect, useMemo} from 'react';
import {EntityProperty} from '../types';
import {isAsyncSucceed, useCancelableRequest} from '@esgi/ui';
import {entitiesWithFolders} from '../constants';
import {isNull} from 'underscore';
import {useDataPageContext} from '../../../context';
import {EntityType} from '../../../types';
import {
	ClassCreatedEvent,
	ClassDeletedEvent,
	FolderCreatedEvent,
	FolderManagement,
	FolderType,
	FolderUpdatedEvent,
	GroupCreatedEvent,
	GroupDeletedEvent,
	SpecialistGroupCreatedEvent,
	SpecialistGroupRemovedEvent,
	TeacherCreatedEvent,
	TeacherDeletedEvent,
	TeacherRemovedEvent,
} from '@esgi/main/features/school-admin/data';
import {StudentCreatedEvent, StudentDeletedEvent} from '@esgi/main/features/school-admin/student-profile-drawer/events';
import {useEventEffect} from '@esgillc/events';
import {RefreshDataPanelEvent} from '../../../events';

export function useData({activeEntityType}: {activeEntityType: EntityType | null}) {
	const {entityFolders, setEntityFolders} = useDataPageContext();

	const [foldersData, fetchFolders] = FolderManagement.useFetchFolders();

	const controller = useDisposable(V2SchoolAdminsPagesDataController);

	const [initData, fetchInitData] = useCancelableRequest(controller.init);

	const fetchFoldersData = useCallback(() => {
		const folderType = (() => {
			switch (activeEntityType) {
				case 'teachers':
					return FolderType.Teacher;
				case 'specialists':
					return FolderType.Specialist;

				default:
					return null;
			}
		})();

		if (isNull(folderType)) {
			return;
		}

		fetchFolders(folderType);
	}, [activeEntityType, fetchFolders]);

	useEffect(fetchFoldersData, [activeEntityType]);
	useEffect(fetchInitData, []);

	useEffect(() => {
		if (!isNull(activeEntityType) && entitiesWithFolders.includes(activeEntityType) && isAsyncSucceed(foldersData)) {
			setEntityFolders(foldersData.data.folders);
			return;
		}

		setEntityFolders([]);
	}, [activeEntityType, foldersData, setEntityFolders]);

	useEventEffect(RefreshDataPanelEvent, fetchInitData);

	useEventEffect(ClassCreatedEvent, fetchInitData);
	useEventEffect(ClassDeletedEvent, fetchInitData);

	useEventEffect(TeacherCreatedEvent, fetchInitData);
	useEventEffect(TeacherDeletedEvent, fetchInitData);

	useEventEffect(StudentCreatedEvent, fetchInitData);
	useEventEffect(StudentDeletedEvent, fetchInitData);

	useEventEffect(GroupCreatedEvent, fetchInitData);
	useEventEffect(GroupDeletedEvent, fetchInitData);

	useEventEffect(SpecialistGroupCreatedEvent, fetchInitData);
	useEventEffect(SpecialistGroupRemovedEvent, fetchInitData);

	useEventEffect(FolderCreatedEvent, fetchFoldersData);
	useEventEffect(FolderUpdatedEvent, fetchFoldersData);

	useEventEffect(TeacherRemovedEvent, () => {
		fetchInitData();
		fetchFoldersData();
	});

	const entities = useMemo<EntityProperty[]>(
		() => [
			{
				name: 'Teachers',
				type: 'teachers',
				count: isAsyncSucceed(initData) ? initData.data.value.teachersCount : null,
			},
			{
				name: 'School Specialists',
				type: 'specialists',
				count: isAsyncSucceed(initData) ? initData.data.value.schoolSpecialistsCount : null,
			},
			{
				name: 'Classes',
				type: 'classes',
				count: isAsyncSucceed(initData) ? initData.data.value.classesCount : null,
			},
			{
				name: 'Groups',
				type: 'groups',
				count: isAsyncSucceed(initData) ? initData.data.value.groupsCount : null,
			},
			{
				name: 'Specialist Groups',
				type: 'specialistGroups',
				count: isAsyncSucceed(initData) ? initData.data.value.specialistGroupsCount : null,
			},
			{
				name: 'Students',
				type: 'students',
				count: isAsyncSucceed(initData) ? initData.data.value.studentsCount : null,
			},
		],
		[initData],
	);

	return {
		entities,
		folders: entityFolders,
	};
}
