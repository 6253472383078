import {Checkbox as CheckboxUi} from '@esgi/ui/controls';
import {styled} from '@esgi/ui/theme';

export const Checkbox = styled(CheckboxUi, {
	paddingRight: '$1',

	'& > div': {
		minWidth: 'unset',
		width: 16,
		height: 16,
		borderColor: '$primaryHighlight',
		backgroundColor: '$background',
	},

	'& > div:has(> span[data-state=checked])': {
		backgroundColor: '$primaryBackground',
	},

	'& > div:has(> span[data-state=checked][data-disabled])': {
		backgroundColor: '$surface',

		'& svg': {
			fill: '$lowContrast',

			'& path': {
				fill: '$lowContrast',
			},
		},
	},
});
