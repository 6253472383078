import {ToggleGroup} from '@esgi/ui/controls';
import {GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	minHeight: 40,

	variants: {
		withoutIdenticalItems: {
			false: {
				gridAutoColumns: '1fr',
			},
		},
	},
});

export const IconBox = styled(GridBox, {
	width: 24,
	height: 24,
	padding: 2,

	'& > svg': {
		width: '100%',
		height: '100%',
	},
});
