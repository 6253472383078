import {Drawer} from '@esgi/main/kits/common';
import {Box, GridBox} from '@esgi/ui/layout';
import {styled} from '@esgi/ui/theme';

export const Container = styled(GridBox, {
	overflow: 'hidden',
	height: '100%',
	gridTemplateRows: 'auto auto 1fr',
	gap: 20,
});

export const Header = styled(GridBox, {
	paddingTop: 18,
	gridTemplateColumns: 'auto 1fr auto',
	gap: '$1',
	alignItems: 'center',
});

export const Divider = styled(Box, {
	height: 1,
	backgroundColor: '$border',
	marginInline: -Drawer.drawerPaddingInline,
	gridColumn: '1 / -1',
});

export const Content = styled(GridBox, {
	gridAutoFlow: 'row',
	gridAutoRows: 'max-content',
	gap: '$4',
});
