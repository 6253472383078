import {AnalyticsBox} from './components/analytics-box';
import {ChartsBox} from './components/charts-box';
import {ChartsDivider} from './components/charts-divider';
import {Delta} from './components/delta';
import {InfoWithTranscript} from './components/info-with-transcript';
import {GridProgressBar} from './components/progress-bar/grid-progress-bar';
import {ProgressWithPointer} from './components/progress-bar/progress-with-pointer';
import {RingFullFilled} from './components/progress-bar/ring-full-filled';

export const Statistic = {
	Charts: {
		ChartsBox,
		Divider: ChartsDivider,
		AnalyticsBox,
		GridProgressBar,
		ProgressWithPointer,
		RingFullFilled,
	},
	InfoWithTranscript,
	Delta,
};
