import {ErrorText} from '@esgi/ui';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/form-controls';
import {FormElement} from '@esgillc/ui-kit/form';
import {Drawer} from '@esgi/main/features/teacher/home';
import {SpecialProgramsContainer, CheckboxGroupItem, CheckboxGroupRoot, CommentTextarea} from './index.styled';
import {FormElementWrapper, FormWrapper, ScrollableWrapper} from '../../../index.styled';
import {InformationFormType} from '../../../forms/information';
import {Dictionary} from '../../../types';

export interface InformationProps {
	initDictionaryData: Dictionary;
	form: InformationFormType,
}

export function Information({initDictionaryData, form}: InformationProps) {
	return (
		<ScrollableWrapper>
			<FormWrapper controller={form}>
				<Drawer.ContentBlock title='Additional Information' withDivider>
					<FormElementWrapper>
						<FormElement control={form.controls.race}>
							<Select.Root dataCy='race-select'>
								<Select.Field placeholder='Race and Ethnicity'/>
								<Select.Content>
									{initDictionaryData?.races.map(g => (
										<Select.Option value={g.id.toString()} key={g.id}>
											<Text size='medium' bold>{g.name}</Text>
										</Select.Option>
									))}
								</Select.Content>
							</Select.Root>
						</FormElement>
					</FormElementWrapper>

					<FormElementWrapper>
						<FormElement control={form.controls.lunchStatus}>
							<Select.Root dataCy='lunch-status-select'>
								<Select.Field placeholder='Lunch Status'/>
								<Select.Content>
									{initDictionaryData?.lunchStatuses.map(ls => <Select.Option value={ls.id.toString()} key={ls.id}>
										<Text size='medium' bold>{ls.name}</Text>
									</Select.Option>)}
								</Select.Content>
							</Select.Root>
						</FormElement>
					</FormElementWrapper>
				</Drawer.ContentBlock>

				<Drawer.ContentBlock title='Special Programs' withDivider>
					<SpecialProgramsContainer dataCy='special-programs-list'>
						<FormElementWrapper>
							<FormElement control={form.controls.studentSpecialPrograms}>
								<CheckboxGroupRoot>
									{initDictionaryData?.specialPrograms.map(p => (
										<CheckboxGroupItem label={p.name} value={p.id} key={p.id}/>
									))}
								</CheckboxGroupRoot>
							</FormElement>
						</FormElementWrapper>
					</SpecialProgramsContainer>
				</Drawer.ContentBlock>

				<Drawer.ContentBlock title='Comments' withDivider>
					<FormElementWrapper>
						<FormElement control={form.controls.comments}>
							<CommentTextarea placeholder='Comment' dataCy='comment'/>
							<ErrorText showOnError='length-max'>
								You’ve exceeded the max 500 symbols allowed. Keep it short!
							</ErrorText>
						</FormElement>
					</FormElementWrapper>
				</Drawer.ContentBlock>
			</FormWrapper>
		</ScrollableWrapper>
	);
}

