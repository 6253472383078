import {GridBox} from '@esgi/ui/layout';
import {TestName} from '../../../test-name';
import {ProgressWithPointer, AnswersInfo} from '../../../../../../components';
import {TestInfo} from '../../../../../../types/view-edit-session-details';
import {TestType} from '@esgi/main/kits/common';

type Props = {
	testInfo: TestInfo;
	correctAnswers: number;
};

export function TestNameWithBar({testInfo, correctAnswers}: Props) {
	return (
		<TestName name={testInfo.name} color={testInfo.color} contentArea={testInfo.contentArea} type={TestType.YN}>
			<GridBox flow='column' align='center' justify='start' gap='3'>
				<AnswersInfo value={correctAnswers} maxValue={testInfo.questions.length} />
				<ProgressWithPointer
					progressBarSize={40}
					progressBarCircleStrokeWidth={5}
					value={(correctAnswers / testInfo.questions.length) * 100}
					percentageType
				/>
			</GridBox>
		</TestName>
	);
}
