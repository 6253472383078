import {Text} from '@esgi/ui/typography';
import {ErrorTooltip as FormErrorTooltip} from '@esgi/ui/form';

type Props<T extends string> = {
	showOnError: T;
	message: string;
};

export function ErrorTooltip<T extends string>({showOnError, message}: Props<T>) {
	return (
		<FormErrorTooltip
			showOnError={showOnError}
			css={{
				maxWidth: 'calc(var(--radix-tooltip-trigger-width) - 10px)',
				justifyContent: 'flex-start',
			}}
		>
			<Text size='xSmall' font='mono' color='negativeVivid'>
				{message}
			</Text>
		</FormErrorTooltip>
	);
}
