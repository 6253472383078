import {BehaviorSubject, tap} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {V2SchoolYearsController} from '@esgi/contracts/esgi';
import {SchoolYearModel} from '@esgi/contracts/esgi/types/esgi.apigateway/queries/home/school-year-selector/init/school-year-model';

export class ExportDataService extends BaseService {
	public readonly currentSchoolYear$ = new BehaviorSubject<number>(null);
	public readonly schoolYears$ = new BehaviorSubject<SchoolYearModel[]>([]);

	private readonly controller = new V2SchoolYearsController();

	public init() {
		this.controller.init().pipe(
			tap(({schoolYears, currentGlobalSchoolYearID}) => {
				this.currentSchoolYear$.next(currentGlobalSchoolYearID);
				this.schoolYears$.next(schoolYears);
			}),
		).subscribe();
	}

	public setSchoolYear(id: string) {
		this.currentSchoolYear$.next(Number(id));
	}

	public override dispose() {
		this.controller.dispose();
	}
}
