import React, {Dispatch, useEffect, useMemo} from 'react';
import {Text} from '@esgi/ui/typography';
import {ToggleGroup} from '@esgi/ui/controls';
import {SelectTabs} from '../../types';
import {StyledContainer, StyledToggleGroupContent, ToggleGroupItem} from './index.styled';
import {TabItem} from './types';
import {Datepicker} from '@esgi/ui/icons';

interface Props {
	activeTab: SelectTabs;
	setActiveTab: Dispatch<SelectTabs>;
	isUserInCurrentGlobalSchoolYear: boolean;
}

export function Tabs({activeTab, setActiveTab, isUserInCurrentGlobalSchoolYear}: Props) {
	const tabItems = useMemo<TabItem[]>(
		() => [
			{
				value: SelectTabs.Past,
				Content: 'Past',
			},
			{
				value: SelectTabs.Current,
				Content: 'Current',
				disabled: !isUserInCurrentGlobalSchoolYear,
			},
			{
				value: SelectTabs.DatePicker,
				Content: Datepicker,
			},
		],
		[isUserInCurrentGlobalSchoolYear],
	);

	useEffect(() => {
		if (!isUserInCurrentGlobalSchoolYear && activeTab === SelectTabs.Current) {
			setActiveTab(SelectTabs.Past);
		}
	}, [activeTab, isUserInCurrentGlobalSchoolYear, setActiveTab]);

	return (
		<StyledContainer>
			<ToggleGroup.Root value={activeTab} onValueChange={setActiveTab}>
				<StyledToggleGroupContent>
					{tabItems.map(({value, Content, disabled}) => (
						<ToggleGroupItem
							value={value}
							disabled={activeTab === value || disabled}
							applyDisabledStyles={Boolean(disabled)}
							key={value}
						>
							{typeof Content === 'string' ? (
								<Text size='medium' bold>
									{Content}
								</Text>
							) : (
								<Content />
							)}
						</ToggleGroupItem>
					))}
				</StyledToggleGroupContent>
			</ToggleGroup.Root>
		</StyledContainer>
	);
}
